import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Certificate } from '../types';
import { CertificateSaveForm } from './CertificateSaveForm';

type Props = {
  onClose: () => void;
  onSave: () => void;
  show: boolean;
  certificate: Certificate | null;
};

export const CertificateSaveModal: React.FC<Props> = ({
  show,
  onClose,
  onSave,
  certificate,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      enforceFocus={false}
      show={show}
      centered
      onHide={onClose}
      header={t('certificates.newCertificate')}
      dialogClassName="break-modal-440">
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">
          {certificate
            ? t('certificates.createCertificateModal.editCertificateTitle')
            : t('certificates.createCertificateModal.newCertificateTitle')}
        </h4>
      </Modal.Header>

      <CertificateSaveForm
        onSave={onSave}
        onClose={onClose}
        certificate={certificate}
      />
    </Modal>
  );
};
