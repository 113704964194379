import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import SkeletonGraph from '../../../components/Skeleton/Graph';
import { SkeletonTable } from '../../../components/Skeleton/Table';

const Loading: React.FC = () => (
  <>
    <Card.Body>
      <Row>
        <Col>
          <SkeletonGraph height={240} />
        </Col>
      </Row>
    </Card.Body>
    <SkeletonTable className="card-table" columns={5} rows={15} />
  </>
);

export default Loading;
