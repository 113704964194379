import * as React from 'react';

const SmileOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M5.74862 8.00163C6.16369 8.00163 6.50017 7.66515 6.50017 7.25008C6.50017 6.83501 6.16369 6.49854 5.74862 6.49854C5.33355 6.49854 4.99707 6.83501 4.99707 7.25008C4.99707 7.66515 5.33355 8.00163 5.74862 8.00163Z"
        fill="currentColor"
      />
      <path
        d="M5.68652 9.99756C5.9787 10.863 6.90189 11.4972 7.99957 11.4972C9.09724 11.4972 10.0207 10.863 10.3104 9.99756"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
      />
      <path
        d="M10.2496 8.00163C10.6647 8.00163 11.0011 7.66515 11.0011 7.25008C11.0011 6.83501 10.6647 6.49854 10.2496 6.49854C9.83453 6.49854 9.49805 6.83501 9.49805 7.25008C9.49805 7.66515 9.83453 8.00163 10.2496 8.00163Z"
        fill="currentColor"
      />
      <path
        d="M7.99944 14.4964C11.5884 14.4964 14.4979 11.587 14.4979 7.99798C14.4979 4.40897 11.5884 1.49951 7.99944 1.49951C4.41044 1.49951 1.50098 4.40897 1.50098 7.99798C1.50098 11.587 4.41044 14.4964 7.99944 14.4964Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default SmileOutlineIcon;
