import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Redirect } from '../../../../Router';
import { ToggleContext, useWorkflowsViewMode } from './useWorkflowsViewMode';

export const WorkflowsRouter: React.FC = () => {
  const { pathname } = useLocation();
  const splits = pathname.split('/');
  const lastPath = splits[splits.length - 1];

  const { getLastRouteToRedirect } = useWorkflowsViewMode();

  return (
    <Switch>
      <Route path="*">
        <Redirect to={getLastRouteToRedirect(getToggleContext(lastPath))} />
      </Route>
    </Switch>
  );
};

const getToggleContext = (lastPath: string): ToggleContext => {
  switch (lastPath) {
    case 'all':
      return 'forAll';
    case 'me':
      return 'forMe';
    case 'others':
      return 'forOthers';
    default:
      return 'forAll';
  }
};
