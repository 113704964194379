import React, { useCallback } from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import {
  EmployeeOrTeamOption,
  EmployeeOrTeamOptionLabel,
} from './EmployeeOrTeamOptionLabel';
import {
  SelectMultipleBase,
  Props as SelectMultipleBaseProps,
} from './SelectMultipleBase';
import { RenderOptionParams } from './SelectSingleBase';

export type Props = Omit<
  SelectMultipleBaseProps<EmployeeOrTeamOption>,
  'renderOption' | 'renderMode' | 'values' | 'onChange'
> & {
  options: EmployeeOrTeamOption[];
  values: EmployeeOrTeamOption[];
  onChange: (values: EmployeeOrTeamOption[]) => void;
};

export const SelectMultipleEmployeeOrTeam: React.FC<Props> = ({
  values,
  onChange,
  options,
  className,
  ...props
}) => {
  const t = useNamespacedTranslation('select.selectMultipleEmployeeOrTeam');

  const renderOption = useCallback(
    ({ option, isInsideSelect }: RenderOptionParams<EmployeeOrTeamOption>) => {
      return (
        <EmployeeOrTeamOptionLabel
          employeeOrTeam={option}
          isInsideSelect={isInsideSelect}
        />
      );
    },
    [],
  );

  // tood: use callback
  const handleOnChange = (values: string[]) => {
    const selectedOptions = values
      .map((value) => options.find((o) => o.value === value))
      .filter((x): x is EmployeeOrTeamOption => x !== undefined);
    onChange(selectedOptions);
  };

  // todo: create SelectMultipleBaseAsync or SelectMultipleEmployeeOrTeamAsync
  return (
    <SelectMultipleBase
      {...props}
      options={options}
      values={values.map((x) => x.value)}
      onChange={handleOnChange}
      renderOption={renderOption}
      renderMode="block"
      className={className}
      placeholder={props.placeholder ?? t('placeholder')}
      noOptionsMessage={props.noOptionsMessage}
    />
  );
};
