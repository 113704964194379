import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ControlledDateInput } from '../../../../components/form/Datepicker';
import { ControlledInput } from '../../../../components/form/Input';
import useYupValidationResolver from '../../../../components/form/useYupValidationResolver';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { currentYearPeriod, toMonthRange } from '../../../../utils/date';
import { yupTimeSchema } from '../../../../initializers/yup';
import {
  useEmployeeAbsencesRefetchOption,
  useMyAbsencesRefetchOption,
} from '../../pages/Absences/useMyAbsencesRefetchOption';
import {
  AbsenceType,
  FileInput,
  RequestAbsenceInput,
  useRequestAbsenceMutation,
  useValidateRequestAbsenceMutation,
} from '../../__generated__/graphql';
import { ModalSidebarFormContent } from '../ModalSidebar';
import { AbsenceFormProps } from './AbsenceFormProps';
import FormBody from './FormBody';
import FormButtons from './FormButtons';
import OverlappingAbsences from './OverlappingAbsences';
import RequestingAmountResult from './RequestingAmountResult';
import SpecialRulesCard from './SpecialRulesCard';
import { isDocumentUploadVisible } from './helper';
import { AbsenceFileField } from './AbsenceFileField';
import { isValidTimeStr } from '../../../../utils/time';
import { Maybe } from '../../../../utils/maybe';
import { RecordedLoomVideo } from '../LoomVideo';
import { useUserInfo } from '../../context/UserInfo';

type FormData = {
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  note?: string;
  loomVideo?: Maybe<RecordedLoomVideo>;
};

type AbsenceFormContext = {
  requireNote: boolean;
};

const absenceFormContext: AbsenceFormContext = {
  requireNote: false,
};

const defaultValues = {
  startTime: '',
  endTime: '',
};

const validationSchema = yup.object().shape<FormData>({
  startDate: yup.date().required(),
  startTime: yupTimeSchema().required(),
  endDate: yup
    .date()
    .required()
    .when(
      'startDate',
      (startDate: FormData['startDate'], schema: yup.DateSchema) => {
        if (startDate) {
          return schema.min(startDate);
        }

        return schema;
      },
    ),
  endTime: yupTimeSchema()
    .required()
    .when(
      ['startDate', 'startTime', 'endDate'],
      (
        startDate: FormData['startDate'],
        startTime: FormData['startTime'],
        endDate: FormData['endDate'],
        schema: yup.StringSchema,
      ) => {
        if (isSameDate(startDate, endDate) && startTime) {
          return schema.test({
            name: 'moreThan',
            message: (values) => ({
              key: 'validations.number.moreThan',
              values,
            }),
            params: { more: startTime },
            test: (current) => {
              return current > startTime;
            },
          });
        }

        return schema;
      },
    ),
  note: yup
    .string()
    .notRequired()
    .when('$requireNote', (requireNote, schema) => {
      if (requireNote) {
        return schema.required();
      }
      return schema;
    }),
});

const isSameDate = (d1: Date, d2: Date) => moment(d1).isSame(d2, 'day');

const mapFormToInput = ({
  categoryId,
  policyId,
  startDate,
  startTime,
  endDate,
  endTime,
  note,
  attachedFile,
  loomVideo,
}: FormData & { attachedFile?: FileInput | null } & Pick<
    AbsenceFormProps,
    'categoryId' | 'policyId'
  >): RequestAbsenceInput => ({
  flair__Employee_Absence_Category__c: categoryId,
  flair__Employee_Absence_Request_Policy__c: policyId,
  flair__Start_Date__c: moment(startDate).format('YYYY-MM-DD'),
  flair__Start_Time__c: startTime,
  flair__End_Date__c: moment(endDate).format('YYYY-MM-DD'),
  flair__End_Time__c: endTime,
  flair__Comment__c: note ?? null,
  attachedFile: attachedFile ?? null,
  loomVideo: loomVideo ?? null,
});

const HourlyAbsenceForm: React.FC<AbsenceFormProps> = ({
  categoryId,
  policyId,
  unlimited,
  onClose,
  specialRules,
  absenceCategoriesGroupSelect,
  absenceCategorySelect,
  accrualPolicyInterval,
  absenceRequestPolicySelect,
  absenceRequestPolicyRules,
  requireDocumentUpload,
  minAmountForDocumentUpload,
  requireNote,
  notePlaceholder,
  onSuccess,
  refetchQueries,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useToasts();
  const { id: meId } = useUserInfo();
  const { month, year: yearStr } = useQueryParams();
  const period = month ? toMonthRange(month) : undefined;
  const year = yearStr ? parseInt(yearStr) : undefined;
  const yearPeriod = Number.isInteger(year)
    ? currentYearPeriod(year)
    : undefined;
  const myAbsencesRefetchQuery = useMyAbsencesRefetchOption(period);
  const [requestAbsence] = useRequestAbsenceMutation({
    awaitRefetchQueries: true,
    /* eslint-disable-next-line no-restricted-syntax */
    refetchQueries: [
      myAbsencesRefetchQuery,
      ...useEmployeeAbsencesRefetchOption(meId, yearPeriod),
      ...(refetchQueries ?? []),
    ],
  });
  const errorHandler = useMutationErrorHandler(() =>
    t('requestAbsence.errorMessage'),
  );

  absenceFormContext.requireNote = requireNote;

  const form = useForm<FormData>({
    mode: 'onChange',
    defaultValues,
    validationResolver: useYupValidationResolver(
      validationSchema,
      absenceFormContext,
    ),
  });
  const { watch } = form;
  const { startDate, endDate, startTime, endTime } = watch();
  const [absenceFile, setAbsenceFile] = useState<FileInput | null>(null);
  const [loomVideo, setLoomVideo] = useState<Maybe<RecordedLoomVideo>>();

  const [
    validateRequestAbsence,
    { loading: validating, data: validateRequestAbsenceData },
  ] = useValidateRequestAbsenceMutation();

  useEffect(() => {
    if (
      categoryId &&
      startDate &&
      startTime &&
      endDate &&
      endTime &&
      isValidTimeStr(startTime) &&
      isValidTimeStr(endTime)
    ) {
      validateRequestAbsence({
        variables: {
          input: mapFormToInput({
            categoryId,
            policyId,
            startDate,
            startTime,
            endDate,
            endTime,
          }),
        },
      });
    }
  }, [
    validateRequestAbsence,
    categoryId,
    policyId,
    startDate,
    startTime,
    endDate,
    endTime,
  ]);
  const validationResult =
    validateRequestAbsenceData?.absence.validateAbsenceRequest;

  const overlappingAbsences =
    validationResult?.error?.__typename ===
    'ValidateAbsenceRequestOverlappingAbsencesError'
      ? validationResult.error.overlappingAbsences
      : [];

  const documentUploadVisible = isDocumentUploadVisible(
    requireDocumentUpload,
    minAmountForDocumentUpload,
    validationResult?.requestedAmount,
  );

  const hasOverlappingAbsences = overlappingAbsences?.length > 0;
  const hasError = !!validationResult?.error;
  const hasInvalidFile = !!(documentUploadVisible && !absenceFile);
  const error = overlappingAbsences?.length
    ? t('requestAbsence.errors.overlappingAbsences', {
        count: overlappingAbsences.length,
      })
    : undefined;

  const onSubmit = useCallback(
    async (values: FormData) => {
      // block submit action
      if (hasError) {
        return;
      }

      await requestAbsence({
        variables: {
          input: mapFormToInput({
            ...values,
            categoryId,
            policyId,
            attachedFile: absenceFile,
          }),
        },
      })
        .then(() => {
          addSuccess(t('requestAbsence.successMessage'));
          onSuccess && onSuccess();
          onClose();
        })
        .catch(errorHandler);
    },
    [
      hasError,
      requestAbsence,
      categoryId,
      policyId,
      absenceFile,
      errorHandler,
      addSuccess,
      t,
      onSuccess,
      onClose,
    ],
  );

  return (
    <ModalSidebarFormContent
      form={form}
      onSubmit={onSubmit}
      body={
        <FormBody
          absenceCategoriesGroupSelect={absenceCategoriesGroupSelect}
          absenceCategorySelect={absenceCategorySelect}
          absenceRequestPolicySelect={absenceRequestPolicySelect}
          absenceRequestPolicyRules={absenceRequestPolicyRules}
          specialRules={
            specialRules ? (
              <SpecialRulesCard richText={specialRules} />
            ) : undefined
          }
          startDateInput={<ControlledDateInput name="startDate" />}
          startDateTimeInput={<ControlledInput type="time" name="startTime" />}
          endDateInput={
            <ControlledDateInput
              name="endDate"
              options={{
                minDate: startDate,
              }}
            />
          }
          endDateTimeInput={<ControlledInput name="endTime" type="time" />}
          fileInput={
            documentUploadVisible ? (
              <AbsenceFileField
                file={absenceFile}
                saving={validating}
                onSelectFile={setAbsenceFile}
              />
            ) : null
          }
          requireNote={requireNote}
          noteInput={
            <ControlledInput
              name="note"
              type="textarea"
              placeholder={notePlaceholder ?? ''}
            />
          }
          requestingAmountBadge={
            <RequestingAmountResult
              data={validationResult}
              loading={validating}
              type={AbsenceType.Hourly}
              accrualPolicyInterval={accrualPolicyInterval}
              unlimited={unlimited}
            />
          }
          overlappingAbsences={
            hasOverlappingAbsences ? (
              <OverlappingAbsences
                absences={overlappingAbsences}
                loading={validating}
              />
            ) : undefined
          }
          loomVideo={loomVideo}
          onRecordLoomVideo={setLoomVideo}
          onDeleteLoomVideo={() => setLoomVideo(null)}
        />
      }
      footer={
        <FormButtons
          disabled={hasError || hasInvalidFile || validating}
          error={error}
        />
      }
    />
  );
};

export default HourlyAbsenceForm;
