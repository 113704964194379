import React from 'react';
import styled from '@emotion/styled';
import { times } from 'lodash';
import { Theme } from '../../../../../theme';

type Props = {
  target: number;
  tracked: number;
};

type DotProps = {
  variant: 'worked' | 'notWorked';
};

const DotContainer = styled('span')<DotProps>(
  {
    fontSize: Theme.font.size.lg,
  },
  ({ variant }) => ({
    color:
      variant === 'worked'
        ? Theme.chart.timeTracking.tracked.color
        : Theme.chart.timeTracking.deficit.color,
  }),
);

const Dot: React.FC<DotProps> = (props) => (
  <DotContainer {...props}>●</DotContainer>
);

const Container = styled('div')({
  display: 'flex',
});

const DaysWorked: React.FC<Props> = ({ target, tracked }) => {
  const totalDaysTracked = Math.max(target, tracked);
  const daysLeft = totalDaysTracked - tracked;

  return (
    <Container>
      {times(tracked, String).map((i) => (
        <Dot variant="worked" key={i} />
      ))}
      {times(daysLeft, String).map((i) => (
        <Dot variant="notWorked" key={i} />
      ))}
    </Container>
  );
};

export default DaysWorked;
