import * as React from 'react';

const DocFileIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M5.4375 20.25C6.03424 20.25 6.60653 20.0129 7.02849 19.591C7.45045 19.169 7.6875 18.5967 7.6875 18C7.6875 17.4033 7.45045 16.831 7.02849 16.409C6.60653 15.9871 6.03424 15.75 5.4375 15.75H4.125V20.25H5.4375Z"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1875 20.25C13.3266 20.25 14.25 19.2426 14.25 18C14.25 16.7574 13.3266 15.75 12.1875 15.75C11.0484 15.75 10.125 16.7574 10.125 18C10.125 19.2426 11.0484 20.25 12.1875 20.25Z"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.875 19.7344C19.5162 20.0624 19.0487 20.2461 18.5625 20.25C17.4188 20.25 16.5 19.2469 16.5 18C16.5 16.7531 17.4188 15.75 18.5625 15.75C19.0487 15.7539 19.5162 15.9376 19.875 16.2656"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 12V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V12"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.25 3V8.25H19.5"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default DocFileIcon;
