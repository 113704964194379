import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { EmptyState } from '../../../components/EmptyStateCard';
import { WorkloadType } from '../../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  workloadType?: string;
};

const NoTimeSheet: React.FC<Props> = ({
  workloadType = WorkloadType.Weekly,
}) => {
  const t = useNamespacedTranslation('timeTracking.overview.emptyState');

  const type = workloadType === WorkloadType.Weekly ? 'week' : 'month';

  return (
    <Row className="text-center">
      <Col className="pb-4 pt-4">
        <EmptyState title={t(`title_${type}`)} />
      </Col>
    </Row>
  );
};

export default NoTimeSheet;
