import { PureQueryOptions } from '@apollo/client';
import styled from '@emotion/styled';
import { range } from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../../theme';
import InvitedEmployeeIcon from '../Common/InvitedEmployeeIcon';
import { hourStr } from '../shiftsLogic';
import { mapMultiRowShifts } from './dailyShiftsLogic';
import { DailyEmployeeShiftInfo, DailyOpenShiftInfo } from './dailyShiftsTypes';
import './ShiftDailyCalendar.css';
import ShiftRow from './ShiftRow';

type Props = {
  day: Moment;
  minHour: number;
  maxHour: number;
  employeeShifts: DailyEmployeeShiftInfo[];
  allOpenShifts: DailyOpenShiftInfo[];
  refetchQueries: Array<PureQueryOptions>;
};

const HeaderCell = styled.div({
  fontSize: '12px',
  padding: '13px 5px',
  fontWeight: 'normal',
  color: Theme.color.dark,
  position: 'sticky',
  top: '67px',
  background: Theme.color.white,
  zIndex: 2,
});

const Grid = styled.div<{ hoursCount: number }>(
  {
    display: 'grid',
  },
  ({ hoursCount }) => ({
    gridTemplateColumns: `200px repeat(${hoursCount}, 1fr)`,
  }),
);

const OpenShiftTitle = styled.div<{ height: number }>(
  {
    fontSize: '15px',
    color: Theme.color.dark,
    display: 'flex',
    flexDirection: 'column',
    paddingInlineStart: '15px',
    paddingBlockStart: '22px',
    height: '68px',
    borderTop: `1px solid ${Theme.color.paper3}`,
    borderBottom: `3px solid ${Theme.color.paper3}`,
    position: 'sticky',
    top: '115px',
    background: Theme.color.white,
    zIndex: 1,
  },
  ({ height }) => ({
    height: height ? `${height}px` : 'unset',
  }),
);

const ShiftsContainer = styled.div<{
  isOpenShift?: boolean;
  height?: number;
}>(
  {
    gridColumn: '2 / -1',
    background: Theme.color.white,
  },
  ({ isOpenShift, height }) => ({
    borderTop: isOpenShift ? `1px solid ${Theme.color.paper3}` : 'none',
    position: isOpenShift ? 'sticky' : 'relative',
    top: isOpenShift ? '115px' : 'unset',
    zIndex: isOpenShift ? 2 : 1,
    borderBottom: isOpenShift
      ? `3px solid ${Theme.color.paper3}`
      : `1px solid ${Theme.color.paper3}`,
    height: height ? `${height}px` : 'unset',
    lineHeight: `${height}px`,
  }),
);

const EmployeeContainer = styled.div<{
  isCurrentEmployee: boolean | undefined;
  height: number;
}>(
  {
    fontSize: 13,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInlineStart: '15px',
    borderBottom: `1px solid ${Theme.color.paper3}`,
    height: '68px',
    backgroundColor: Theme.color.white,
    color: Theme.color.dark,
  },
  ({ height }) => ({
    height: `${height}px`,
  }),
);

const EmployeeName = styled.div<{ isCurrentEmployee: boolean | undefined }>(
  {
    fontSize: '15px',
    lineHeight: '19px',
  },
  ({ isCurrentEmployee }) => ({
    color: isCurrentEmployee ? Theme.color.primary : Theme.color.black,
    fontWeight: isCurrentEmployee ? 600 : 400,
  }),
);

const EmployeeRole = styled.div({
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '15px',
  color: Theme.color.dark,
});

const ShiftsDailyCalendar: React.FC<Props> = ({
  day,
  minHour,
  maxHour,
  employeeShifts,
  allOpenShifts,
  refetchQueries,
}) => {
  const hoursCount = maxHour - minHour;
  const { t } = useTranslation();

  const renderHeader = () => {
    return (
      <>
        <HeaderCell key="empty-header"></HeaderCell>
        {range(minHour, maxHour).map((hour, index) => (
          <HeaderCell key={index}>{hourStr(hour)}</HeaderCell>
        ))}
      </>
    );
  };

  const renderOpenShifts = (openShifts: DailyOpenShiftInfo[]) => {
    const { shifts, shiftsContainerHeight } = mapMultiRowShifts(openShifts);
    return (
      <>
        <OpenShiftTitle height={shiftsContainerHeight}>
          {t('shiftPlanner.openShifts.title')}
        </OpenShiftTitle>
        <ShiftsContainer isOpenShift={true} height={shiftsContainerHeight}>
          {shifts.map((openShift) => (
            <ShiftRow
              key={openShift.id}
              shift={openShift}
              minHour={minHour}
              maxHour={maxHour}
              hoursCount={hoursCount}
              day={day}
              refetchQueries={refetchQueries}
            />
          ))}
          {!shifts.length && (
            <span>{t('shifts.personalShifts.noOpenShifts')}</span>
          )}
        </ShiftsContainer>
      </>
    );
  };

  const renderEmployeeShiftRow = ({
    shifts,
    employee,
    isMy,
  }: DailyEmployeeShiftInfo) => {
    const multiRowShifts = mapMultiRowShifts(shifts);

    return (
      <>
        <EmployeeContainer
          isCurrentEmployee={isMy}
          height={multiRowShifts.shiftsContainerHeight}>
          <EmployeeName isCurrentEmployee={isMy}>
            {employee.name}
            {employee.isInvited && employee.location && (
              <InvitedEmployeeIcon location={employee.location} />
            )}
          </EmployeeName>
          <EmployeeRole>{employee.position}</EmployeeRole>
        </EmployeeContainer>
        <ShiftsContainer>
          {multiRowShifts.shifts.map((shift) => (
            <ShiftRow
              shift={shift}
              minHour={minHour}
              maxHour={maxHour}
              isCurrentEmployee={isMy}
              day={day}
              refetchQueries={refetchQueries}
              hoursCount={hoursCount}
            />
          ))}
        </ShiftsContainer>
      </>
    );
  };

  return (
    <React.Fragment>
      <Grid hoursCount={hoursCount}>
        {renderHeader()}
        {renderOpenShifts(allOpenShifts)}
        {employeeShifts.map(renderEmployeeShiftRow)}
      </Grid>
    </React.Fragment>
  );
};

export default ShiftsDailyCalendar;
