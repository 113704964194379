import React, { useCallback } from 'react';
import { InventoryItem } from '../../types';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import {
  LoomVideo,
  LoomVideoPreview,
} from '../../../../../components/LoomVideo';
import { EmployeeWithAvatar } from '../../../../../components/Employee';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import StringToHtml from '../../../../../../../components/StringToHtml';

type Props = {
  data: InventoryItem;
};
const i18Path = 'myData.inventory.sidebarModal.support';
export const InventorySupportTab: React.FC<Props> = ({ data }) => {
  const t = useNamespacedTranslation(i18Path);
  const { inventory, attachments } = data;

  const renderSupportVideo = () => {
    const previewHeight = 285;
    const previewWidth = 450;
    if (!inventory.supportVideoUrl) {
      return null;
    }

    const getLoomVideo = (url: string): LoomVideo => {
      return {
        sharedUrl: url,
        embedUrl: url,
        width: previewWidth,
        height: previewHeight,
      };
    };

    return (
      <LoomVideoPreview
        previewHeight={previewHeight}
        previewWidth={previewWidth}
        loomVideo={getLoomVideo(inventory.supportVideoUrl)}></LoomVideoPreview>
    );
  };

  const handleDownload = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const renderAttachments = useCallback(() => {
    if (attachments)
      return (
        <StyledAttachmentsWrapper className="mb-3 bg-white d-flex flex-column align-items-center gap-3 w-100 position-fixed px-4 pb-3 ">
          {attachments.map((item: any) => {
            return (
              <>
                <Button
                  onClick={() => {
                    handleDownload(item?.downloadPublicUrl);
                  }}
                  className="w-100"
                  variant="outline-primary">
                  {item.fileName}
                </Button>
              </>
            );
          })}
        </StyledAttachmentsWrapper>
      );
  }, [attachments]);

  return (
    <div className="d-flex flex-column gap-4">
      {renderSupportVideo()}
      {inventory.supportInstruction && (
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('howToSupport')}</h4>
          <div className="card-header-title fw-normal">
            <StringToHtml text={inventory.supportInstruction} />
          </div>
        </div>
      )}
      {inventory.supportAdvisor && (
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('supportResponsible')}</h4>
          <EmployeeWithAvatar
            employee={{
              name: inventory.supportAdvisor?.name,
              avatarUrl: inventory.supportAdvisor?.avatarUrl,
            }}></EmployeeWithAvatar>
        </div>
      )}
      {renderAttachments()}
    </div>
  );
};

const StyledAttachmentsWrapper = styled.div`
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  bottom: -2rem;
`;
