import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Button from '../../../../../components/button';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { SkillLevelSelect } from '../common/SkillLevelSelect';
import { SkillSingleSelect } from '../common/SkillSingleSelect';
import { SkillLevel } from '../common/types';
import { Skill } from '../common/types';
import { Col, Row } from 'react-bootstrap';
import { CancelButton } from '../../../../../components/button/CancelButton';
import { DataWithLoadingState } from '../../../../../utils/dataWithLoadingState';

export type Props = {
  show: boolean;
  inProgress: boolean;
  onClose: () => void;
  onSkillAdded: (skillId: string, level: SkillLevel) => void;
  availableSkills: DataWithLoadingState<Skill>;
};

export const AddEmployeeSkillDialog: React.FC<Props> = ({
  show,
  onClose,
  availableSkills,
  inProgress,
  onSkillAdded,
}) => {
  const t = useNamespacedTranslation('skills.addEmployeeSkill');

  const [selectedSkillId, setSelectedSkillId] = useState<string | null>(null);
  const [selectedSkillLevel, setSelectedSkillLevel] =
    useState<SkillLevel>(null);

  const handleSubmit = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (selectedSkillId) {
        onSkillAdded(selectedSkillId, selectedSkillLevel);
      }
    },
    [onSkillAdded, selectedSkillId, selectedSkillLevel],
  );

  const selectedSkill: Skill | undefined = Array.isArray(availableSkills)
    ? availableSkills.find((x) => x.id === selectedSkillId)
    : undefined;

  const isSkillLevelVisible = selectedSkill && selectedSkill.isRateable;

  const isLoading = availableSkills === 'loading';

  const renderContent = () => {
    if (availableSkills === 'error') {
      return <ServerError />;
    }
    return (
      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <div>{t('selectSkillText')}</div>
        <Row className="mt-3">
          <Col sm={6} md={true}>
            <Label>{t('skill')}</Label>
            <div>
              {!isLoading ? (
                <SkillSingleSelect
                  allSkills={availableSkills}
                  value={selectedSkillId}
                  autoFocus={true}
                  onChange={setSelectedSkillId}
                />
              ) : (
                <Skeleton width={'100%'} height={40} />
              )}
            </div>
          </Col>
          <Col sm={6} md={true}>
            {isSkillLevelVisible && (
              <>
                <Label>{t('level')}</Label>
                <NegativeMargin>
                  <SkillLevelSelect
                    value={selectedSkillLevel}
                    onChange={setSelectedSkillLevel}
                  />
                </NegativeMargin>
              </>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <CancelButton
            className="mt-4 me-3"
            disabled={isLoading || inProgress}
            onClick={onClose}
          />
          <Button
            className="mt-4"
            disabled={isLoading || selectedSkillId === null}
            label={t('save')}
            type="submit"
            isProcessing={inProgress}
          />
        </div>
      </form>
    );
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t('title')}</h4>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
    </Modal>
  );
};

const Label = styled.div({
  fontWeight: 600,
  marginBottom: '0.25rem',
});

const NegativeMargin = styled(Label)({
  marginLeft: '-10px',
});
