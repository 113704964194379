import styled from '@emotion/styled';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/button';
import {
  EmployeeByTeamSelectConnected,
  EmployeeInfo,
} from '../../../../../components/EmployeeByTeamSelect';
import { DeleteOptionIcon } from '../../../../../components/Selects/DeleteOptionIcon';
import { EmployeeOptionLabel } from '../../../../../components/Selects/EmployeeOptionLabel';

export type Props = {
  show: boolean;
  goalName: string;
  isCloning: boolean;
  isManager: boolean;
  onClose: () => void;
  onSubmit: (employeeIds: string[]) => void;
};

const i18prefix = 'performanceReview.goals2.cloneModal';

export const GoalCloneModal: React.FC<Props> = ({
  goalName,
  show,
  isManager,
  isCloning,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const [employeeInfos, setEmployeeInfos] = useState<EmployeeInfo[]>([]);

  const employeeIds = useMemo(
    () => employeeInfos.map((x) => x.id),
    [employeeInfos],
  );

  const handleClone = useCallback(() => {
    onSubmit(employeeIds);
  }, [onSubmit, employeeIds]);

  const handleEmployeeDelete = useCallback(
    (idToDelete) => {
      if (isCloning) {
        return;
      }
      setEmployeeInfos(employeeInfos.filter((x) => x.id !== idToDelete));
    },
    [setEmployeeInfos, employeeInfos, isCloning],
  );

  const handleOnAddEmployees = useCallback(
    (newEmployeeInfos: EmployeeInfo[]) => {
      if (isCloning) {
        return;
      }
      setEmployeeInfos(
        _.uniqBy([...employeeInfos, ...newEmployeeInfos], (x) => x.id),
      );
    },
    [setEmployeeInfos, employeeInfos, isCloning],
  );

  const handleRemoveAll = useCallback(() => {
    setEmployeeInfos([]);
  }, [setEmployeeInfos]);

  const getContent = () => {
    return (
      <>
        <div>{t(`${i18prefix}.content`)}</div>
        <div className="mt-3">
          <div>
            {employeeInfos.length > 0 && (
              <>
                <div>{t(`${i18prefix}.assignedTo`)}</div>

                <EmployeesContainer className="d-flex flex-wrap align-items-center">
                  {employeeInfos.map((employeeInfo) => (
                    <div
                      key={employeeInfo.id}
                      className="d-flex align-items-center"
                      style={{ height: 40 }}>
                      <EmployeeOptionLabel
                        employee={{
                          value: employeeInfo.id,
                          label: employeeInfo.name,
                          initials: employeeInfo.initials,
                        }}
                        isNameVisible={false}
                      />
                      <DeleteOptionIcon
                        onClick={() => handleEmployeeDelete(employeeInfo.id)}
                        className="ms-2 me-2"
                      />
                    </div>
                  ))}
                </EmployeesContainer>

                <Label>
                  {t(`${i18prefix}.employees`, {
                    count: employeeInfos.length,
                  })}
                </Label>
              </>
            )}
            <EmployeeByTeamSelectConnected
              selectAllReportsVisible={isManager}
              employeeIds={employeeIds}
              disabled={isCloning}
              removeAllDisabled={employeeInfos.length === 0}
              onAddEmployees={handleOnAddEmployees}
              onRemoveAll={handleRemoveAll}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            label={t('form.general.cancelButton')}
            className="me-3"
            disabled={isCloning}
            variant="outline-primary"
            onClick={onClose}
          />
          <Button
            disabled={employeeIds.length === 0}
            isProcessing={isCloning}
            label={t(`${i18prefix}.clone`)}
            onClick={handleClone}
          />
        </div>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">
          {t(`${i18prefix}.headerTitle`, { name: goalName })}
        </h4>
      </Modal.Header>
      <Modal.Body>{getContent()}</Modal.Body>
    </Modal>
  );
};

const Label = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLSpanElement>) => (
  <span {...rest} className={classNames('text-dark', 'fs-5', className)} />
);

const EmployeesContainer = styled('div')({
  maxHeight: 120,
  overflowY: 'auto',
});
