import React from 'react';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { MultiValue } from 'react-select';
import { routes } from '../../../../routes';
import { DirectoryFilter, DirectoryFiltersKeys } from './types';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

type Props = Omit<ReactSelectProps<FilterSelectOption, true>, 'options'> & {
  options: FilterSelectOption[];
  filter: DirectoryFilter;
  filterKey: DirectoryFiltersKeys;
};

const DirectoryMultipleFilter: React.FC<Props & ReactSelectProps> = ({
  filter,
  filterKey,
  options,
  ...rest
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const selectedOptionsString = filter[filterKey];

  const handleChange = (selectedOptions: MultiValue<FilterSelectOption>) => {
    history.push(
      routes.peopleDirectory.route
        .withQueryParams({
          ...queryParams,
          [filterKey]: selectedOptions.map((o) => o.value).join(','),
        })
        .create({}),
    );
  };

  return (
    <FlairMultiSelectDropdown
      options={options}
      selectedOptions={selectedOptionsString}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default DirectoryMultipleFilter;
