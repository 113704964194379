import {
  UpdateEmployeeObjectiveFieldsInput,
  UpdateTeamObjectiveFieldsInput,
} from '../../../../__generated__/graphql';
import {
  GoalId,
  GoalKeyResultType,
  GoalRate,
  GoalStatus,
  GoalType,
  GoalVisibility,
} from '../types';

export type GoalSidebarMode = 'create' | 'update' | 'view';

export type ObjectiveFormData = ObjectiveFormDataBase & {
  type: GoalType;
  teamId?: string | undefined;
  employeeId?: string | undefined;
};

export type EmployeeObjectiveFormData = ObjectiveFormDataBase & {
  type: 'employee';
  employeeId: string;
  teamId?: undefined;
  manualProgress: boolean;
  keyResults: GoalKeyResult[];
  parentObjectiveId: GoalId | null;
  statusWithRate: GoalStatusWithRate;
};

export type TeamObjectiveFormData = ObjectiveFormDataBase & {
  type: 'team';
  employeeId?: undefined;
  teamId: string;
  manualProgress: boolean;
  keyResults: GoalKeyResult[];
  parentObjectiveId: GoalId | null;
  statusWithRate: GoalStatusWithRate;
};

export type CompanyObjectiveFormData = ObjectiveFormDataBase & {
  type: 'company';
};

export type ObjectiveFormDataBase = {
  visibility: GoalVisibility;
  sharedWithEmployeeIds: string[];
  timePeriodId: string | null;
  startDate: Date | null;
  dueDate: Date | null;
  name: string;
  description: string;
  tagIds: string[];
  keyResults?: GoalKeyResult[] | undefined;
  parentObjectiveId?: GoalId | null;
  statusWithRate?: GoalStatusWithRate;
  manualProgress?: boolean | undefined;
  goalOnTrackAutoState?: undefined | boolean;
};

export type AutoGoalInfo = {
  isAuto: boolean;
  goalOnTrackAutoState?: undefined | boolean;
};

export type GoalKeyResult = {
  id?: string;
  name: string;
  type: GoalKeyResultType;
  initialValue: number;
  targetValue: number;
  currentValue: number;
};

export type ObjectiveFormSaveInfo = Partial<
  Record<keyof ObjectiveFormData, SaveInfo>
>;

export type GoalStatusWithRate = {
  status: GoalStatusEx;
  rate: GoalRate;
  cancelReason?: string;
};

export type GoalStatusEx = GoalStatus | 'BEHIND_OR_ON_TRACK';

export type SaveInfo = {
  loading?: boolean;
  completed?: boolean;
};

export const updateEmployeeObjectiveFieldsInputKeys: (keyof UpdateEmployeeObjectiveFieldsInput)[] =
  [
    'name',
    'description',
    'parentObjectiveId',
    'visibility',
    'sharedWithEmployeeIds',
    'timePeriodId',
    'startDate',
    'dueDate',
    'tagIds',
    'keyResults',
    'manualProgress',
    'employeeId',
  ];

export const updateTeamObjectiveFieldsInputKeys: (keyof UpdateTeamObjectiveFieldsInput)[] =
  [
    'name',
    'description',
    'parentObjectiveId',
    'visibility',
    'sharedWithEmployeeIds',
    'timePeriodId',
    'dueDate',
    'startDate',
    'tagIds',
    'keyResults',
    'manualProgress',
    'teamId',
  ];
