import * as React from 'react';

const CheckmarkCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.9972 7.99883C13.9972 4.68711 11.3103 2.00024 7.99859 2.00024C4.68687 2.00024 2 4.68711 2 7.99883C2 11.3105 4.68687 13.9974 7.99859 13.9974C11.3103 13.9974 13.9972 11.3105 13.9972 7.99883Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9957 5.49927L6.79665 10.4981L4.99707 8.49856"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default CheckmarkCircleOutlineIcon;
