import {
  EmptyProjectId,
  ProjectsTimeSheet,
  ProjectsTimeSheetEmployee,
  ProjectsTimeSheetPeriodInfo,
} from '../types';
import { uniqBy } from 'lodash';
import { ProjectsTimeSheetTableFragment } from '../../../../__generated__/graphql';
import { ProjectsTimeSheetsFilter } from '../Filters/types';
import { EmptyProjectName } from '../../helpers';

export const mapTimeSheet = (
  src: ProjectsTimeSheetTableFragment,
): ProjectsTimeSheet => {
  return {
    id: src.Id,
    type: src.flair__Type__c,
    period: mapPeriod(src),
    employee: mapEmployee(src.employee),
    projects: mapProjects(src.projectsTimeEntries),
    approveStatus: src.flair__Approval_Status__c,
    targetMinutes: src.flair__Target_Hours__c * 60,
    trackedMinutes: src.flair__Time_Entry_Hours__c * 60,
    commentsCount: src.timeSheetDays.reduce(
      (sum, curTimeSheetDay) => sum + curTimeSheetDay.commentsCount,
      0,
    ),
    timesheetDays: mapTimeSheetDays(src.timeSheetDays),
  };
};

export const isMatchClientFilter = (
  filter: Pick<ProjectsTimeSheetsFilter, 'onlyWithComments'>,
  item: ProjectsTimeSheet,
): boolean => {
  return filter.onlyWithComments === 'true' ? item.commentsCount > 0 : true;
};

const mapTimeSheetDays = (
  src: ProjectsTimeSheetTableFragment['timeSheetDays'],
): ProjectsTimeSheet['timesheetDays'] => {
  return src.map((timeSheetDay) => ({
    id: timeSheetDay.Id,
    day: timeSheetDay.flair__Day__c,
    trackedMinutes: timeSheetDay.flair__Project_Time_Entries_Minutes__c,
  }));
};

const mapPeriod = (
  src: ProjectsTimeSheetTableFragment,
): ProjectsTimeSheetPeriodInfo => ({
  id: `${src.flair__Type__c}_${src.flair__Start_Date__c}`,
  type: src.flair__Type__c,
  startDay: src.flair__Start_Date__c,
  endDay: src.flair__End_Date__c,
});

const mapEmployee = (
  src: ProjectsTimeSheetTableFragment['employee'],
): ProjectsTimeSheetEmployee => ({
  id: src.Id,
  name: src.Name,
  avatarUrl: src.avatar?.url ?? undefined,
});

const mapProjects = (
  timeEntriesInput: ProjectsTimeSheetTableFragment['projectsTimeEntries'],
): ProjectsTimeSheet['projects'] => {
  const projects: ProjectsTimeSheet['projects'] = uniqBy(
    timeEntriesInput,
    'project.Id',
  ).map((projectTimeEntry) => {
    const project = projectTimeEntry.project;
    if (project) {
      return {
        id: project.Id,
        name: project.flair__Name__c,
        isEmpty: false,
        startDay: project.flair__Start_Date__c,
        endDay: project.flair__End_Date__c,
        estimatedInMin: !project.flair__Estimation__c
          ? null
          : project.flair__Estimation__c * 60,
        billable: project.flair__Billable__c,
        timeTrackedInMin: project.timeTracked,
        timeEntries: mapTimeEntriesForProject(timeEntriesInput, project.Id),
      };
    } else {
      return {
        id: EmptyProjectId,
        name: EmptyProjectName,
        isEmpty: true,
        startDay: null,
        endDay: null,
        estimatedInMin: null,
        billable: false,
        timeTrackedInMin: 0,
        timeEntries: mapTimeEntriesForProject(timeEntriesInput, EmptyProjectId),
      };
    }
  });
  return projects;
};

const mapTimeEntriesForProject = (
  timeEntriesInput: ProjectsTimeSheetTableFragment['projectsTimeEntries'],
  projectId: string,
) => {
  return timeEntriesInput
    .map((te) => ({
      id: te.Id,
      projectId: te.project?.Id ?? EmptyProjectId,
      trackedInMin: te.flair__Minutes__c ?? 0,
    }))
    .filter((te) => te.projectId === projectId);
};
