import * as React from 'react';
import CommentCard from '../CommentCard/CommentCard';
import { Comment, UpsertComment } from '../types';
import './CommentList.css';
import { isLastIndex } from '../../../../../utils/array';
import CommentInput from '../CommentInput/CommentInput';
import { EmptyStateCardBody } from '../../EmptyStateCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import EmptyStateImg from '../../../../../vendor/img/empty-state-bubbles-3.png';
import { useState } from 'react';

type Props = {
  comments: Comment[];
  onSubmit?: (c: UpsertComment) => Promise<void> | void;
  onUpdate?: (c: UpsertComment) => Promise<void> | void;
  onDelete?: (id: string) => Promise<void> | void;
  submitting?: boolean;
  scrollToEnd: () => void;
  onHighlighted?: (commentId: string, htmlElement: HTMLElement) => void;
};

const i18Path = 'comments';

const CommentList = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    comments,
    onSubmit,
    submitting = false,
    onUpdate,
    onDelete,
    scrollToEnd,
    onHighlighted,
  } = props;
  const t = useNamespacedTranslation(i18Path);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  let commentListContainerHeight = isInputFocused ? '10rem' : '8rem';

  const handleOnFocusChange = (isFocused: boolean) => {
    setIsInputFocused(isFocused);
    scrollToEnd && scrollToEnd();
  };

  return (
    <div
      className="comment-list-container"
      style={{ height: `calc(100% - ${commentListContainerHeight}` }}>
      <div ref={ref} className="comments-container">
        {comments.map((c, i) => (
          <React.Fragment key={i}>
            <CommentCard
              onHighlighted={onHighlighted}
              comment={c}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
            {!isLastIndex(i, comments) && <hr />}
          </React.Fragment>
        ))}
        {comments.length === 0 && (
          <div className="h-100 d-flex align-items-center">
            <EmptyStateCardBody title={t('noComment')} img={EmptyStateImg} />
          </div>
        )}
      </div>
      <div className="comments-input-container">
        <hr className="comments-input-splitter" />
        <div className="comments-input">
          <CommentInput
            onSubmit={onSubmit}
            disabledInput={submitting}
            loading={submitting}
            onFocusChange={handleOnFocusChange}
          />
        </div>
      </div>
    </div>
  );
});

export default CommentList;
