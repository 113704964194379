import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../../theme';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  label: string;
  onClick: () => void;
};

const Container = styled.div({
  backgroundColor: Theme.color.bgBlue,
  borderRadius: Theme.border.radius,
  //  maxWidth: '10rem',
  textAlign: 'center',
  color: Theme.color.black,
  display: 'flex',
  justifyContent: 'space-between',
});

const Text = styled.span({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Icon = styled.span({
  // fontSize: '0.8rem',
  color: Theme.color.paper1,
  display: 'flex',
  alignItems: 'center',
});

export const SkillChartLabel: React.FC<Props> = ({ label, onClick }) => {
  return (
    <Container className="small py-0 px-2">
      <Text>{label}</Text>
      <Icon>
        <FlairIcon
          className="ms-2"
          icon="close-outline"
          aria-label="Edit"
          role="button"
          onClick={onClick}
        />
      </Icon>
    </Container>
  );
};
