import * as React from 'react';
import TableModal from '../../../../../../atomic/organisms/TableModal';
import {
  EmployeeScopeFilter,
  useDashboardBirthdayColleaguesQuery,
} from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { mapBirthdayForModal } from './mappings';
import { startOfYear } from 'date-fns';

type Props = {
  onClose: () => void;
  filterBy: EmployeeScopeFilter;
};

const i18Path = 'dashboard.birthdaysWidget.modal';

const BirthdaysModal: React.FC<Props> = ({ onClose, filterBy }) => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDashboardBirthdayColleaguesQuery({
    variables: {
      filter: {
        limit: null,
        filterBy,
        startDate: startOfYear(new Date()).toLocaleDateString(),
      },
    },
  });

  const headers = [
    {
      key: 'employee',
      title: t('employee'),
    },
    {
      key: 'department',
      title: t('department'),
    },
    {
      key: 'location',
      title: t('location'),
    },
    {
      key: 'birthdayDate',
      title: t('birthday'),
    },
  ];

  if (error) {
    return <ServerError />;
  }

  const birthdays = data?.me.birthdaysColleagues.map(mapBirthdayForModal) ?? [];

  return (
    <TableModal
      title={t('title')}
      onClose={onClose}
      headers={headers}
      data={birthdays}
      loading={loading}
    />
  );
};

export default BirthdaysModal;
