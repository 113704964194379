import classNames from 'classnames';
import React from 'react';
import { Theme } from '../../../../../theme';
import { SkillNameWithIcon } from '../common/SkillNameWithIcon';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type SkillEditorProps = {
  id: string;
  name: string;
  rateable: boolean;
  isEditable: boolean;
  onUpdateClick: (id: string) => void;
};

export const SkillEditor: React.FC<SkillEditorProps> = ({
  id,
  name,
  rateable,
  isEditable,
  onUpdateClick,
}) => {
  return (
    <div className="d-flex align-items-center">
      <SkillNameWithIcon rateable={rateable} name={name} />
      {isEditable && (
        <FlairIcon
          icon="pencil-outline"
          aria-label="Edit"
          className={classNames('ms-3', 'fs-5')}
          color={Theme.color.primary}
          role="button"
          onClick={() => onUpdateClick(id)}
        />
      )}
    </div>
  );
};
