import * as React from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  count: number;
  onClick?: () => void;
};

const CommentCount: React.FC<Props> = ({ count, onClick }) => {
  return (
    <div
      className="d-flex gap-2 align-items-center text-primary"
      role="button"
      onClick={onClick}>
      <FlairIcon icon="comments-outline" />
      <span>({count})</span>
    </div>
  );
};

export default CommentCount;
