import React, { ChangeEvent, useCallback } from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import AutoSaveInputLabel from '../../../../../../components/form/AutoSaveInputLabel';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import Hint from '../../../../../../components/hint';
import { EvaluationTemplateQuestionType } from '../../../../__generated__/graphql';
import { EvaluationQuestionParams } from './types';

type FreeTextParams = EvaluationQuestionParams & {
  defaultValue: string;
};

const FreeText: React.FC<FreeTextParams> = ({
  question,
  disabled,
  defaultValue,
  loading,
  onUpdateAnswer,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onUpdateAnswer({
        questionType: EvaluationTemplateQuestionType.FreeText,
        value: event.target.value,
      });
    },
    [onUpdateAnswer],
  );

  return (
    <FormGroup>
      <AutoSaveInputLabel
        label={question.flair__Name__c}
        required={question.flair__Required__c}
        loading={loading}
        completed={!!defaultValue}
        hint={
          question.flair__Hint__c && (
            <Hint id={`hint_${question.Id}`} text={question.flair__Hint__c} />
          )
        }
      />
      <TextareaAutosize
        className="form-control"
        defaultValue={defaultValue}
        onChange={handleChange}
        readOnly={disabled}
        disabled={disabled}
        minRows={4}
      />
    </FormGroup>
  );
};

export default FreeText;
