import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ServerError from '../../../../../components/ServerError';
import _ from 'lodash';
import { useState } from 'react';
import Button from '../../../../../components/button';
import { mapProbation } from './mapping';
import Sections from '../CommonComponents/WidgetSections/Sections';
import {
  SectionCategory,
  SectionType,
} from '../CommonComponents/WidgetSections/types';
import ProbationsModal from './EndOfProbationModal';
import {
  ManagerRole,
  useDashboardEndOfProbationQuery,
} from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';
import { toISODateOnly } from '../../../../../utils/dateUtils';

const i18Path = 'dashboard.probations';

const EndOfProbationWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { managerRoles } = useUserInfo();
  const isDirectManager = managerRoles.includes(ManagerRole.DirectManager);

  const { data, loading, error } = useDashboardEndOfProbationQuery({
    variables: {
      filter: {
        limit: 3,
        startDate: toISODateOnly(new Date()),
      },
    },
  });

  if (error) {
    return <ServerError />;
  }

  if (!isDirectManager) {
    return null;
  }

  const employees = data?.probations.map(mapProbation) ?? [];

  const today = new Date().toLocaleDateString();

  const [todayProbations, upcomingProbations] = _.partition(
    employees,
    (i) => i.date.toLocaleDateString() === today,
  );

  const sections = [
    {
      key: SectionCategory.TODAY,
      label: t('today'),
      items: todayProbations,
    },
    {
      key: SectionCategory.UPCOMING,
      label: t('upcoming'),
      items: upcomingProbations,
    },
  ].filter((i) => i.items.length > 0);

  const footerActions = (
    <Button
      className="p-0"
      label={t('seeMore')}
      variant="link"
      onClick={() => setIsModalVisible(true)}
    />
  );

  return (
    <>
      <FlairCard
        cardTitle={t('title')}
        cardContainerClassName="probations-widget"
        footerActions={footerActions}>
        <Sections
          sections={sections}
          type={SectionType.PROBATION}
          emptyStateTitle={t('title')}
          loading={loading}
        />
      </FlairCard>
      {isModalVisible && (
        <ProbationsModal onClose={() => setIsModalVisible(false)} />
      )}
    </>
  );
};

export default EndOfProbationWidget;
