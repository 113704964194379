import React from 'react';
import styled from '@emotion/styled';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../theme';
import { Breadcrumb } from './types';
type Props = {
  breadcrumbs: Breadcrumb[];
  navigateByFolder: (breadcrumb: Breadcrumb) => void;
  navigateBack: () => void;
};
export const DocumentsPathBreadcrumbs: React.FC<Props> = ({
  breadcrumbs,
  navigateBack,
  navigateByFolder,
}) => {
  return (
    <>
      <BreadcrumbArrowIcon icon="left-arrow" onClick={navigateBack} />
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={index}>
          <BreadcrumbName
            onClick={() => navigateByFolder(item)}
            isActive={index === breadcrumbs.length - 1}
            className="d-none small d-md-block cu-pointer">
            {item.name}
          </BreadcrumbName>
          {index < breadcrumbs.length - 1 && (
            <BreadcrumbName
              className="small me-2 ms-2"
              isActive={index === breadcrumbs.length - 1}>
              \
            </BreadcrumbName>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const BreadcrumbArrowIcon = styled(FlairIcon)`
  & path {
    fill: ${Theme.color.blue3};
  }
  margin-right: 1.375rem;
  cursor: pointer;
`;

const BreadcrumbName = styled.span<{ isActive: boolean }>(({ isActive }) => ({
  color: isActive ? Theme.color.gray1 : Theme.color.paper1,
  fontWeight: 500,
}));
