import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SingleFilter from '../../../../components/SelectFilters/SingleFilter';
import ClearFiltersLink from '../ClearFiltersLink';
import { uniq } from 'lodash';
import PerformanceReviewCardBody from './PerformanceReviewCardBody';
import { EmployeeFeedbackProps } from './types';
import { useSelectOptions } from '../../../../components/SelectFilters/helpers';
import {
  FiltrationRoute,
  SelectOption,
} from '../../../../atomic/molecules/FlairDropdown/types';

type Props = {
  feedbacks: EmployeeFeedbackProps[];
  detailsLink: (feedbackId: string, cycleName: string) => React.ReactNode;
  showReviewers?: boolean;
  showEmployees?: boolean;
  filtrationRoute: FiltrationRoute;
};

const useReviewerFilterOptions = (reviewers: string[]): SelectOption[] => {
  return useMemo(
    () =>
      reviewers.map((reviewer) => ({
        value: reviewer,
        label: reviewer,
      })),
    [reviewers],
  );
};

const useEmployeeFilterOptions = (employees: string[]): SelectOption[] => {
  return useMemo(
    () =>
      employees.map((employee) => ({
        value: employee,
        label: employee,
      })),
    [employees],
  );
};

const FEEDBACK_REVIEWER_FILTER = 'reviewer';
const useSelectReviewerOptions = (
  reviewers: string[],
  filtrationRoute: FiltrationRoute,
) => ({
  ...useSelectOptions(FEEDBACK_REVIEWER_FILTER, filtrationRoute),
  options: useReviewerFilterOptions(reviewers),
});

const FEEDBACK_EMPLOYEE_FILTER = 'employee';
const useSelectEmployeeOptions = (
  employees: string[],
  filtrationRoute: FiltrationRoute,
) => ({
  ...useSelectOptions(FEEDBACK_EMPLOYEE_FILTER, filtrationRoute),
  options: useEmployeeFilterOptions(employees),
});

const useDueDateYearOptions = (): SelectOption[] => {
  const currentYear = new Date().getFullYear();

  return useMemo(
    () =>
      [currentYear - 1, currentYear, currentYear + 1].map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
    [currentYear],
  );
};

const FEEDBACK_DUE_DATE_YEAR_FILTER = 'dueDateYear';
const useSelectDueDateYearOptions = (filtrationRoute: FiltrationRoute) => {
  return {
    ...useSelectOptions(FEEDBACK_DUE_DATE_YEAR_FILTER, filtrationRoute),
    options: useDueDateYearOptions(),
  };
};

const ClearFilters: React.FC<{ filtrationRoute: FiltrationRoute }> = ({
  filtrationRoute,
}) => {
  const history = useHistory();
  const handleClearFilters = () => {
    history.push(filtrationRoute({}));
  };
  return <ClearFiltersLink onClick={handleClearFilters} />;
};

export const PastPerformanceReviewCard: React.FC<Props> = ({
  feedbacks,
  detailsLink,
  showReviewers = false,
  showEmployees = false,
  filtrationRoute,
}) => {
  const { t } = useTranslation();

  const {
    options: reviewerOptions,
    selectedOption: selectedReviewer,
    onChange: onReviewerChange,
  } = useSelectReviewerOptions(
    uniq(feedbacks.map((feedback) => feedback.outcome.reviewer.Name)),
    filtrationRoute,
  );

  const {
    options: employeeOptions,
    selectedOption: selectedEmployee,
    onChange: onEmployeeChange,
  } = useSelectEmployeeOptions(
    uniq(feedbacks.map((feedback) => feedback.reviewee.Name)),
    filtrationRoute,
  );

  const {
    options: dueDateYearOptions,
    selectedOption: selectedDueDateYear,
    onChange: onDueDateYearChange,
  } = useSelectDueDateYearOptions(filtrationRoute);

  if (!feedbacks.length) {
    return null;
  }

  return (
    <div className="mt-3">
      <h2>
        {t('employeePage.performanceReview.pastPerformanceReviews.title')}
      </h2>

      <Card>
        <Card.Header className="small">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="me-3">
                {t('performanceReview.goals.filterBy')}
              </span>

              {showReviewers && (
                <SingleFilter
                  className="me-3"
                  placeholder={t(
                    'employeePage.performanceReview.pastPerformanceReviews.filters.reviewer',
                  )}
                  options={reviewerOptions}
                  value={selectedReviewer}
                  onChange={onReviewerChange}
                />
              )}

              {showEmployees && (
                <SingleFilter
                  className="me-3"
                  placeholder={t(
                    'employeePage.performanceReview.pastPerformanceReviews.filters.employee',
                  )}
                  options={employeeOptions}
                  value={selectedEmployee}
                  onChange={onEmployeeChange}
                />
              )}

              <SingleFilter
                className="me-3"
                placeholder={t(
                  'employeePage.performanceReview.pastPerformanceReviews.filters.year',
                )}
                options={dueDateYearOptions}
                value={selectedDueDateYear}
                onChange={onDueDateYearChange}
              />
              <ClearFilters filtrationRoute={filtrationRoute} />
            </div>
          </div>
        </Card.Header>
        <PerformanceReviewCardBody
          detailsLink={detailsLink}
          feedbacks={feedbacks}
          selectedDueDateYear={
            selectedDueDateYear ? parseInt(selectedDueDateYear) : null
          }
          selectedReviewer={selectedReviewer}
          selectedEmployee={selectedEmployee}
          showReviewers={showReviewers}
          showEmployees={showEmployees}
        />
      </Card>
    </div>
  );
};
