import React from 'react';
import styled from '@emotion/styled';
import DurationFormat from '../DurationFormat';

type Props = {
  minutes: number;
  className?: string;
};

const XSmall = styled.span`
  font-size: 0.65rem;
`;

const DurationDiff: React.FC<Props> = ({ minutes, className }) => {
  if (minutes === 0) {
    return null;
  }

  if (minutes < 0) {
    return (
      <XSmall className={[className, 'text-danger'].join(' ')}>
        <DurationFormat minutes={minutes}></DurationFormat>
      </XSmall>
    );
  }

  return (
    <XSmall className={[className, 'text-success'].join(' ')}>
      +<DurationFormat minutes={minutes}></DurationFormat>
    </XSmall>
  );
};

export default DurationDiff;
