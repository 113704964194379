import * as React from 'react';

const CaretDownOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M3.06162 5.939L7.42872 11.0353C7.49911 11.1174 7.58643 11.1833 7.68469 11.2285C7.78295 11.2737 7.88981 11.2971 7.99796 11.2971C8.10611 11.2971 8.21298 11.2737 8.31123 11.2285C8.40949 11.1833 8.49681 11.1174 8.5672 11.0353L12.9343 5.939C13.3511 5.45256 13.0055 4.70117 12.3651 4.70117H3.62962C2.98914 4.70117 2.6436 5.45256 3.06162 5.939Z"
        fill="black"
      />
    </svg>
  );
});

export default CaretDownOutlineIcon;
