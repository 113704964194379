import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../components/form/FormGroup';
import ScorecardGroup from '../../components/ScorecardGroup';
import { Score } from './EvaluationTemplate/Question/ScoreCard';

type Params = {
  required?: boolean;
  defaultValue?: Score;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  className?: string;
  onChange?: (value: Score) => void;
};

const FinalScore: React.FC<Params> = ({
  required = false,
  defaultValue,
  disabled,
  label,
  className,
  loading,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <FormGroup className={className}>
      {required && <abbr className="text-danger me-1">*</abbr>}
      <Form.Label>{label || t('evaluations.overallRecommendation')}</Form.Label>
      <div>
        <ScorecardGroup
          name="finalScore"
          disabled={disabled || loading}
          selectedScore={defaultValue}
          loading={loading}
          isComplete={defaultValue !== undefined && defaultValue !== null}
          onChange={onChange}
          allowUnselect={false}
        />
      </div>
    </FormGroup>
  );
};

export default FinalScore;
