import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Column, CellProps } from 'react-table';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { TableContent } from '../../components/TableContent';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  TYPE_FILTER,
  COLLEAGUES_IDS_FILTER,
  AbsenceRequestFiltersType,
  AbsenceTypeMap,
} from './types';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import AbsenceCategoryIcon from '../../../components/AbsenceCategoryIcon';
import AbsenceStatus from '../../../pages/Absences/components/AbsenceStatus';
import Avatar from '../../../pages/Absences/components/Avatar';
import AbsenceDateTimeRange from '../../../pages/Absences/components/AbsenceDateTimeRange';
import Filters from './Filters';
import { useAbsenceRequestFilters } from './Filters/useAbsenceRequestFilters';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import { EmployeeWithAvatar } from '../../../components/Employee';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { useApproveAbsenceRequestTable } from '../useAbsencesRequestsTable';
import { ApproverCommentModal } from '../../../pages/Absences/components/ApproverCommentModal';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { LoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';
import LoomVideoPreviewInModal from '../../../components/LoomVideo/LoomVideoPreviewInModal';
import { IndeterminateCheckbox } from '../../../components/Checkbox/IndeterminateCheckbox';
import { TableBottomBanner } from './TableBottomBanner';
import { SelectionBottomPanel } from '../../../components/SelectionBottomPanel/SelectionBottomPanel';
import { AbsenceRequest } from '../types';
import { EmptyAbsenceRequestsCard } from '../EmptyAbsenceRequestsCard';
import { MOBILE_BREAKPOINT } from '../common';
import { ReviewActionsCell } from '../ReviewActionsCell';

const Detail = styled.div({
  marginLeft: 32,
});

const Header = styled(Card.Header)({
  height: 'auto',
  minHeight: 60,
});

const i18Path = 'absences.myAbsences.table';

type Props = {
  requests: AbsenceRequest[];
};

const getQueryFiltersData = (
  query: URLSearchParams,
): AbsenceRequestFiltersType => {
  return {
    type: query.get(TYPE_FILTER) || '',
    colleaguesIds: query.get(COLLEAGUES_IDS_FILTER) || '',
  };
};

export const AbsenceRequestsTable: React.FC<Props> = ({ requests }) => {
  const t = useNamespacedTranslation(i18Path);
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filterDataFromUrl = getQueryFiltersData(query);
  const filteredAbsences = useAbsenceRequestFilters(
    filterDataFromUrl,
    requests,
  );
  const [selectedLoomVideo, setSelectedLoomVideo] =
    useState<Maybe<LoomVideo>>(null);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const {
    isAccepting,
    isRejecting,
    isInProgress,
    handleAccept,
    handleReject,
    handleMassAccept,
    handleMassReject,
    handleRejectButtonClick,
    onModalClose,
    rejectingModalId,
    getSelectedRowsCount,
    addSelectedRow,
    resetSelectedRows,
    selectAllRows,
    setSelectAllRows,
    setResetSelectedRows,
  } = useApproveAbsenceRequestTable();
  const reviewCell = (
    props: React.PropsWithChildren<CellProps<AbsenceRequest, string>>,
  ) => {
    if (props.row.original.approvalStatus === 'PENDING') {
      return (
        <ReviewActionsCell
          id={props.row.original.id}
          isAccepting={isAccepting}
          isRejecting={isRejecting}
          isInProgress={isInProgress}
          handleAccept={handleAccept}
          handleRejectButtonClick={handleRejectButtonClick}
        />
      );
    } else {
      const row = props.row.original;
      const reviewedRequest = row.approvalRequests.find((item) =>
        ['APPROVED', 'REJECTED'].includes(item.approvalStatus),
      );
      const avatar = reviewedRequest?.approver.avatar;
      const firstName = reviewedRequest?.approver.firstName;
      const lastName = reviewedRequest?.approver.lastName;
      const initials = `${firstName?.[0]}${lastName?.[0]}`;
      const fullName = reviewedRequest?.approver.name;
      return (
        <div className="d-flex align-items-center gap-2 ps-3">
          <Avatar
            avatar={avatar}
            initials={initials}
            fullName={fullName || ''}
          />
          <FormattedDate day={row.lastModifiedDate} format="short" />
        </div>
      );
    }
  };

  const desktopColumns: Column<AbsenceRequest>[] = [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: (
        props: React.PropsWithChildren<CellProps<AbsenceRequest, string>>,
      ) => <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />,
      disableSortBy: true,
    },
    {
      Header: t('employee'),
      accessor: 'id',
      Cell: (props) => (
        <Link
          to={routes.absenceRequestToMe.route}
          absenceRequestId={props.row.original.id}>
          <EmployeeWithAvatar employee={props.row.original.requester} />
        </Link>
      ),
    },
    {
      Header: t('duration'),
      accessor: 'endDate',
      Cell: (props) => (
        <Link
          to={routes.absenceRequestToMe.route}
          absenceRequestId={props.row.original.id}>
          <AbsenceDateTimeRange absence={props.row.original} />
          {props.row.original.comment && (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip`}>{props.row.original.comment}</Tooltip>
              }>
              <span>
                <FlairIcon
                  role="button"
                  className={'ms-2 cursor-auto'}
                  icon="chatbubble-outline"
                />
              </span>
            </OverlayTrigger>
          )}
          {props.row.original.attachments.length > 0 && (
            <span>
              <FlairIcon
                role="button"
                className={'ms-2'}
                icon="document-outline"
              />
            </span>
          )}
        </Link>
      ),
    },
    {
      Header: t('type'),
      accessor: 'categoryName',
      Cell: (props) => (
        <div className="d-flex gap-2">
          <AbsenceCategoryIcon icon={props.row.original.categoryIcon} />
          <span>{props.row.original.categoryName}</span>
        </div>
      ),
    },
    {
      Header: t('workingTime'),
      accessor: 'workingAmount',
      Cell: (props) =>
        `${props.row.original.workingAmount} ${
          AbsenceTypeMap[props.row.original.type]
        }`,
    },
    {
      Header: t('calendarTime'),
      accessor: 'amount',
      Cell: (props) =>
        `${props.row.original.amount} ${
          AbsenceTypeMap[props.row.original.type]
        }`,
    },
    {
      Header: t('comments'),
      accessor: 'recordId',
      Cell: ({ value, row }) => (
        <CommentsPopover
          recordId={value}
          relatedObjectName={RelatedObjectNames.Absence}
          commentsCount={row.original.commentsCount}
          mode="icon"
        />
      ),
    },
    {
      Header: t('loom'),
      accessor: 'loomVideo',
      Cell: ({ value }) => (
        <div className="pt-2">
          {value ? (
            <FlairIcon
              icon="loom"
              className="text-primary"
              onClick={() => setSelectedLoomVideo(value)}
            />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: t('status'),
      accessor: 'approvalStatus',
      Cell: (props) => (
        <AbsenceStatus status={props.row.original.approvalStatus} />
      ),
    },
    {
      Header: t('requestedAt'),
      accessor: 'createdDate',
      Cell: (props) => (
        <FormattedDateTime
          dateTime={props.row.original.createdDate}
          format="long"
        />
      ),
    },
    {
      Header: t('review'),
      accessor: 'absenceId',
      Cell: reviewCell,
    },
  ];

  const mobileColumns: Column<AbsenceRequest>[] = [
    {
      Header: t('employee'),
      accessor: 'id',
      Cell: (props) => (
        <Col>
          <Link
            to={routes.absenceRequestToMe.route}
            absenceRequestId={props.row.original.id}>
            <EmployeeWithAvatar employee={props.row.original.requester} />
          </Link>
          <Detail>
            <Link
              to={routes.absenceRequestToMe.route}
              absenceRequestId={props.row.original.id}>
              <AbsenceDateTimeRange absence={props.row.original} />
            </Link>
            <div>
              <span className="me-1">{t('mobile.workingTime')}</span>
              {`${props.row.original.workingAmount} ${
                AbsenceTypeMap[props.row.original.type]
              }`}
            </div>
            <div>
              <span className="me-1">{t('mobile.type')}</span>
              {props.row.original.categoryName}
            </div>
            <div>
              <span className="me-1">{t('mobile.requestedAt')}</span>
              <FormattedDateTime
                dateTime={props.row.original.createdDate}
                format="long"
              />
            </div>
          </Detail>
        </Col>
      ),
    },
    {
      Header: t('review'),
      accessor: 'absenceId',
      Cell: reviewCell,
    },
  ];

  const columns = isMobile ? mobileColumns : desktopColumns;

  return (
    <Card>
      <Header className="h-auto">
        <Row className="align-items-center">
          <Col className="small">
            <Filters data={requests} filterData={filterDataFromUrl} />
          </Col>
        </Row>
      </Header>

      {filteredAbsences.length > 0 ? (
        <>
          <TableContent
            selectAllRows={selectAllRows}
            resetSelectedRows={resetSelectedRows}
            onRowSelection={addSelectedRow}
            columns={columns}
            data={filteredAbsences}
            onRowClick={() => {}}
            pageSize={50}
            initialSortBy={[{ id: 'createdDate', desc: true }]}
          />
          <ApproverCommentModal
            requestId={rejectingModalId}
            onClose={onModalClose}
            onRejected={handleReject}
          />
          <SelectionBottomPanel
            selectedCount={getSelectedRowsCount()}
            onSelectAll={() => setSelectAllRows(true)}
            onCancel={() => {
              setResetSelectedRows(true);
            }}>
            <TableBottomBanner
              onAccept={handleMassAccept}
              onReject={handleMassReject}></TableBottomBanner>
          </SelectionBottomPanel>
        </>
      ) : (
        <EmptyAbsenceRequestsCard />
      )}

      {selectedLoomVideo && (
        <LoomVideoPreviewInModal
          loomVideo={selectedLoomVideo}
          onHide={() => setSelectedLoomVideo(null)}
        />
      )}
    </Card>
  );
};
