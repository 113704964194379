import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';
import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import { TimeSince } from '../../../../../components/TimeSince';
import classNames from 'classnames';
import { Comment, UpsertComment } from '../types';
import DocumentItem from '../DocumentItem/DocumentItem';
import './CommentCard.css';
import Actions from './Actions/Actions';
import { Action } from './Actions/types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useContext, useRef, useState } from 'react';
import CommentInput from '../CommentInput/CommentInput';
import DeleteConfirmationModal from './DeleteConfirmationModal/DeleteConfirmationModal';
import { useUserInfo } from '../../../context/UserInfo';
import PreviewImage from '../../PreviewImage';
import { ReactionsRelatedObjectNames } from '../../Reactions/ConnectedReactions/types';
import ConnectedReactions from '../../Reactions/ConnectedReactions';
import { InnerModalContext } from '../../../../../context/InnerModalContext';
import { replaceTagMentionsWithSpan } from '../logic';
import useEmployeeMentionSpansHook from '../Hooks/useEmployeeMentionSpansHook';
import StringToHtml from '../../../../../components/StringToHtml';
import { useCommentIdFromUrl } from '../CommentsPopover/useCommentsPopoverVisible';

type Props = {
  comment: Comment;
  className?: string;
  onUpdate?: (comment: UpsertComment) => Promise<void> | void;
  onDelete?: (id: string) => Promise<void> | void;
  enableReactions?: boolean;
  onHighlighted?: (commentId: string, htmlElement: HTMLElement) => void;
};

const i18Path = 'comments';
const CommentCard: React.FC<Props> = ({
  comment,
  className = '',
  onUpdate,
  onDelete,
  enableReactions = true,
  onHighlighted,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const commentCardRef = useRef<HTMLElement>(null);
  const commentCardTextContainerRef = useRef<HTMLDivElement>(null);
  const {
    id,
    employee,
    text: textFromServer,
    createdDate,
    files,
    images,
    isNew = false,
    reactions,
    mentionedEmployees,
  } = comment;

  const { id: currentEmployeeId } = useUserInfo();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isHighlightedCalled, setIsHighlightedCalled] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { setIsInnerModalOpen } = useContext(InnerModalContext);

  const text = replaceTagMentionsWithSpan(
    id ?? '',
    textFromServer,
    mentionedEmployees,
  );

  useEmployeeMentionSpansHook(
    commentCardTextContainerRef,
    id,
    mentionedEmployees,
    text,
  );

  const { commentPointer } = useCommentIdFromUrl();
  const higlightCommentId =
    commentPointer?.commentId === comment.id ? comment.id : null;

  React.useEffect(() => {
    const ref = commentCardRef.current;
    if (onHighlighted && higlightCommentId && ref && !isHighlightedCalled) {
      // if the url contains a pointer to this commentId
      // then we need to highlight and scroll into it
      // It is a good place to get the comment's DOM reference
      // then onHighlighted event is processed in a parent component which make scroll into it
      onHighlighted(higlightCommentId, ref);
      setIsHighlightedCalled(true);
    }
  }, [
    higlightCommentId,
    commentCardRef,
    onHighlighted,
    isHighlightedCalled,
    setIsHighlightedCalled,
  ]);

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsInnerModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setIsInnerModalOpen(false);
  };

  const actions: Action[] | null =
    currentEmployeeId === employee.id
      ? [
          {
            label: t('actions.edit'),
            onClick: () => setIsEditMode(true),
          },
          {
            label: t('actions.delete'),
            onClick: handleOpenDeleteModal,
          },
        ]
      : null;

  const handleOnUpdate = async (comment: UpsertComment) => {
    if (onUpdate) {
      setIsUpdating(true);
      try {
        await onUpdate(comment);
      } finally {
        setIsUpdating(false);
        setIsEditMode(false);
      }
    }
  };

  const handleDelete = async () => {
    if (id && onDelete) {
      setIsDeleting(true);
      try {
        await onDelete(id);
      } finally {
        setIsDeleting(false);
        handleCloseDeleteModal();
      }
    }
  };

  return (
    <Row
      className={classNames('px-3 py-3', className, {
        'highlight-comment': higlightCommentId,
      })}
      ref={commentCardRef}>
      {isEditMode ? (
        <CommentInput
          comment={comment}
          onSubmit={handleOnUpdate}
          loading={isUpdating}
          onCancel={() => setIsEditMode(false)}
          hideSecondaryActions={true}
        />
      ) : (
        <div className="d-flex gap-3">
          {isNew && <div className="new-comment-indicator" />}
          <div>
            <Avatar
              avatarUrl={employee?.avatarUrl}
              size="sm"
              initials={getEmployeeInitialsFromName(employee.name)}
            />
          </div>
          <div className="flex-grow-1">
            <div>
              <div className="p-0">
                <div className="mb-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">{employee.name}</h4>
                    <div>{actions && <Actions actions={actions} />}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between secondary-text-size">
                    <Col className="text-secondary">{employee.position}</Col>
                    <Col className="text-end">
                      <TimeSince
                        date={createdDate}
                        className={classNames('text-secondary')}
                      />
                    </Col>
                  </div>
                </div>
                <div className="text-break comment-card-text-container">
                  {text && (
                    <div ref={commentCardTextContainerRef}>
                      <StringToHtml text={text} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*Multiple images and files will be handled later once the mockups are ready*/}
            {images.length > 0 && images[0].link && (
              <PreviewImage
                className="w-75 rounded my-3"
                src={images[0].link}
                alt={images[0].fileName}
              />
            )}
            {files?.length > 0 && (
              <Row className="my-3">
                <Col>
                  <DocumentItem
                    document={files[0]}
                    onClick={() => window.open(files[0].link)}
                  />
                </Col>
              </Row>
            )}
            {enableReactions && id && (
              <Row className="mt-3">
                <Col>
                  <ConnectedReactions
                    reactions={reactions}
                    recordId={id}
                    relatedObjectName={ReactionsRelatedObjectNames.Comment}
                  />
                </Col>
              </Row>
            )}
          </div>
          {isDeleteModalOpen && (
            <DeleteConfirmationModal
              onClose={handleCloseDeleteModal}
              loading={isDeleting}
              onSubmit={handleDelete}
            />
          )}
        </div>
      )}
    </Row>
  );
};

export default CommentCard;
