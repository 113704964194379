import styled from '@emotion/styled';
import React from 'react';
import i18next from 'i18next';
import { WorkflowItemStatus } from '../WorkflowItems/types';
import { getWorkflowItemStatusColor } from '../WorkflowItems/mappings';

type Props = {
  status: WorkflowItemStatus;
};

export const WorkflowItemStatusComponent: React.FC<Props> = ({ status }) => {
  const info = getRenderInfo(status);
  return (
    <div className="d-flex align-items-center">
      <Dot className="me-2" backgroundColor={info.color} />
      <span>{info.text}</span>
    </div>
  );
};

const Dot = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
  borderRadius: '50%',
  width: '5px',
  height: '5px',
  backgroundColor: backgroundColor,
}));

const getRenderInfo = (status: WorkflowItemStatus) => {
  const text = i18next.t(`workflows.statuses.${status}`);
  const color = getWorkflowItemStatusColor(status);
  switch (status) {
    case 'NOT_STARTED':
      return {
        text,
        color,
      };
    case 'IN_PROGRESS':
      return {
        text,
        color,
      };
    case 'PENDING_APPROVAL':
      return {
        text,
        color,
      };
    case 'COMPLETED':
      return {
        text,
        color,
      };
    case 'REJECTED':
      return {
        text,
        color,
      };
  }
};
