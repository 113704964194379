import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ServerError from '../../../../../components/ServerError';
import Loading from '../../../pages/Absences/components/Loading';
import { mapAbsenceRequests, filterOutNotPending } from './mappings';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../../hooks/useManagerReportsMode';
import { usePendingAbsenceRequests } from './usePendingAbsenceRequests';
import { AbsenceRequestsTable } from './AbsenceRequestsTable';

export const PendingRequests: React.FC = () => {
  const [reportsMode] = useManagerReportsMode('absences');
  const { pendingAbsenceRequestToMe, loading, error } =
    usePendingAbsenceRequests(mapManagerReportsType(reportsMode));

  if (loading) return <Loading />;

  if (error) return <ServerError />;
  return (
    <>
      <Row>
        <Col>
          <AbsenceRequestsTable
            requests={mapAbsenceRequests(
              filterOutNotPending(pendingAbsenceRequestToMe),
            )}
          />
        </Col>
      </Row>
    </>
  );
};
