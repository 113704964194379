import { t } from 'i18next';
import { NotificationTypes } from 'server/src/shared/NotificationTypes';

import {
  NotificationIconType,
  NotificationItemData,
  NotificationQueryItem,
} from '../types';
import { routes } from '../../../routes';

const i18prefix = 'notifications.workflow';

const iconsByType = new Map<string, NotificationIconType>([
  [NotificationTypes.WORKFLOW_ASSIGNED_PENDING_ITEMS, 'checkbox-outline'],
  [
    NotificationTypes.WORKFLOW_DIGEST_EXPIRING_WORKFLOW_ITEMS,
    'checkbox-outline',
  ],
]);

const descriptionsByType = new Map([
  [
    NotificationTypes.WORKFLOW_ASSIGNED_PENDING_ITEMS,
    t(`${i18prefix}.pendingitems`),
  ],
  [
    NotificationTypes.WORKFLOW_DIGEST_EXPIRING_WORKFLOW_ITEMS,
    t(`${i18prefix}.expiringitems`),
  ],
]);

export const mapWorkflowItemNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };
  data.icon = iconsByType.get(src.flair__Type__c);
  data.description = descriptionsByType.get(src.flair__Type__c) || '';
  data.url = routes.workflowsAllWorkflows.route.create({});
  data.title = t(`${i18prefix}.notificationTitle`);

  if (src.renderInfo.__typename === 'WorkflowItemNotificationRenderInfo') {
    if (
      src.flair__Type__c === NotificationTypes.WORKFLOW_ASSIGNED_PENDING_ITEMS
    ) {
      // Check required fields. If not present, the notification won't be rendered.
      if (!src.renderInfo.workflow?.Id) {
        return null;
      }
      const { Id, Name } = src.renderInfo.workflow;
      data.url = routes.workflowsAllWorkflowItems.route.create({
        workflowId: Id,
      });
      data.urlLabel = Name;
    }
  }

  return data;
};
