import React from 'react';
import { useTimeSheetDetailsQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import {
  TimeSheetChartsPopover,
  TimeSheetChartsPopoverLoading,
} from './TimeSheetChartsPopover';

type Props = {
  timeSheetId: string;
};

export const TimeSheetChartsPopoverConnected: React.FC<Props> = ({
  timeSheetId,
}) => {
  const { data, error, loading } = useTimeSheetDetailsQuery({
    variables: {
      id: timeSheetId,
    },
  });
  if (!data || loading) {
    return <TimeSheetChartsPopoverLoading />;
  }
  if (error) {
    return <ServerError />;
  }
  const timeSheet = data.timeSheet;
  return <TimeSheetChartsPopover timeSheet={timeSheet} />;
};
