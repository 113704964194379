import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CancelButton from '../../../../../components/form/CancelButton';
import { Form } from '../../../../../components/form/Form';
import { Input } from '../../../../../components/form/Input';
import SubmitButton from '../../../../../components/form/SubmitButton';
import { IdName } from '../common/types';
import { CreateUpdateFormData, skillValidationSchema } from './common';

type Props = {
  show: boolean;
  onClose: () => void;
  skill: IdName;
  onSubmit: (name: string) => void;
  allSkills: IdName[];
};

export const UpdateSkillDialog: React.FC<Props> = ({
  show,
  onClose,
  onSubmit,
  skill,
  allSkills,
}) => {
  const { t } = useTranslation();
  const defaultValues: CreateUpdateFormData = {
    rateable: '',
    name: skill.name,
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form
        defaultValues={defaultValues}
        onSubmit={(form) => onSubmit(form.name)}
        validationSchema={skillValidationSchema(
          allSkills,
          t('skills.alreadyExists'),
          skill.id,
        )}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`skills.updateSkill.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(`skills.updateSkill.description`, { skillName: skill.name })}
          </p>
          <Input
            label={t(`skills.updateSkill.input.label`)}
            placeholder={t(`skills.updateSkill.input.placeholder`)}
            name="name"
            autoFocus={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={onClose}>
            {t('skills.updateSkill.cancel')}
          </CancelButton>
          <SubmitButton>{t('skills.updateSkill.save')}</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
