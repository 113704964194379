import { DashboardColleagueFieldsFragment } from '../../../__generated__/graphql';
import { Employee } from '../../People/types';

export const mapCommonColleagueFields = (
  colleague: DashboardColleagueFieldsFragment,
): Employee => {
  return {
    id: colleague.Id,
    name: colleague.Name,
    avatarUrl: colleague.avatar?.url ?? '',
    companyEmail: colleague.flair__Company_Email__c ?? '',
    department: colleague.department?.Name ?? '',
    position: colleague.flair__Position__c ?? '',
    location: colleague.location?.Name ?? '',
    phone: colleague.flair__Company_Phone_Number__c ?? '',
    manager: colleague.manager
      ? { id: colleague.manager.Id, name: colleague.manager.Name }
      : null,
  };
};
