import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RedirectToHome from '../../components/RedirectToHome';
import ShiftsHeader from './ShiftsHeader';
import { routes } from './ShiftsHeader/routes';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

const Settings: React.FC = () => {
  return (
    <>
      <ShiftsHeader />
      <Content />
    </>
  );
};

export default Settings;
