import React from 'react';
import ModalSidebar from '../../../../components/ModalSidebar';
import { GoalId } from '../types';
import { canUpdateGoal } from '../logic';
import { GoalsDropdownActionsConnected } from './GoalDropdownActionsConnected';
import { GoalSidebarUpdateContent } from './GoalSidebarUpdateContent';
import { getHeaderText } from './mappings';
import { useIsManagerMode } from '../../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../../__generated__/graphql';

type Props = {
  visible: boolean;
  goalId: GoalId;
  onClose: () => void;
};

export const GoalSidebarUpdate: React.FC<Props> = ({
  visible,
  goalId,
  onClose,
}) => {
  const { hasManagerRole } = useIsManagerMode();

  const viewMode = canUpdateGoal(
    hasManagerRole(ManagerRole.DirectManager),
    goalId.type,
  )
    ? 'update'
    : 'view';

  const header = getHeaderText(viewMode);

  return (
    <ModalSidebar
      show={visible}
      onClose={onClose}
      header={header}
      headerActions={
        <GoalsDropdownActionsConnected goalId={goalId} onComplete={onClose} />
      }
      content={
        <GoalSidebarUpdateContent
          goalId={goalId}
          mode={viewMode}
          onClose={onClose}
        />
      }
    />
  );
};
