import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterProject, i18prefix } from '../helpers';
import ServerError from '../../../../../components/ServerError';
import { QueryString } from '../../../../../utils/router';
import EmptyStateCard from '../../../components/EmptyStateCard';
import { routes } from '../../../routes';

import { useProjectsOverviewQuery } from '../../../__generated__/graphql';

import Loading from '../Loading';
import { mapProject } from '../mapping';

import { ProjectDetailsSidebar } from '../ProjectSidebar/ProjectDetailsSidebar';
import ProjectsTable from '../ProjectsTable';
import ProjectsTopPanel from '../ProjectsTopPanel';
import { Project, ProjectsFilter } from '../types';
import ProjectsHeader from '../ProjectsHeader';
import ProjectsManagerHeader from '../ProjectsManagerHeader';
import { useFlairPersistentFilter } from '../../../../../hooks/useFlairPersistentFilter';
import { projectsDefaultFilter } from '../ProjectsFiltration';

const ProjectsOverview: React.FC = () => {
  return (
    <>
      <ProjectsHeader />
      <Content
        createRouteUrl={(queryParams) =>
          routes.projectsOverview.route.withQueryParams(queryParams).create({})
        }
      />
    </>
  );
};

export const ProjectsManagerOverview: React.FC = () => {
  return (
    <>
      <ProjectsManagerHeader />
      <Content
        createRouteUrl={(queryParams) =>
          routes.projectsOverviewManager.route
            .withQueryParams(queryParams)
            .create({})
        }
      />
    </>
  );
};

const Content: React.FC<{
  createRouteUrl: (params: QueryString) => string;
}> = ({ createRouteUrl }) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [filter, setFilter] = useFlairPersistentFilter<ProjectsFilter>({
    defaultFilter: projectsDefaultFilter,
    storageKey: 'projectsOverview',
    createRouteUrl,
  });

  const { data, loading, error } = useProjectsOverviewQuery();

  const handleItemClick = useCallback((project: Project) => {
    setSelectedItemId(project.id);
  }, []);

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  if (!data.projects.length) {
    return <EmptyProjects />;
  }

  const projects = data.projects
    .map(mapProject)
    .filter((project) => filterProject(project, filter));

  const selectedItem = projects.find(
    (project) => project.id === selectedItemId,
  );

  return (
    <>
      <ProjectsTopPanel filter={filter} onFiltrationChange={setFilter} />
      <ProjectsTable projects={projects} onItemClick={handleItemClick} />
      <ProjectDetailsSidebar
        project={selectedItem || null}
        timeTracked={selectedItem?.timeTrackedInMinutes || 0}
        viewMode={'MANAGER_VIEW'}
        onClose={() => setSelectedItemId(null)}
      />
    </>
  );
};

const EmptyProjects: React.FC = () => {
  const { t } = useTranslation();

  return <EmptyStateCard title={t(`${i18prefix}.emptyState`)} />;
};

export default ProjectsOverview;
