import React, { useMemo } from 'react';

import { useTimeSheetDays } from '../../../components/TimeSheet/DayCard/useTimeSheetDays';
import { mapToDayWithTimeEntries } from './logic/mapping';
import DayTimeEntriesSmart from './DayTimeEntries/DayTimeEntriesSmart';
import { EmployeeType, getReadonlyReasons } from './logic';
import { PureQueryOptions } from '@apollo/client';
import { useUserInfo } from '../../../context/UserInfo';
import { TimeSheetProps } from '../../../components/TimeSheet/DayCard/types';
import { CostCenter } from '../../../__generated__/graphql';
import { useRestrictFutureEntries } from '../../../hooks/timeTrackingSettings';

export type CostCenterProps = Pick<CostCenter, 'Id' | 'Name'>;

type Props = {
  timeSheet: TimeSheetProps;
  costCenters: readonly CostCenterProps[];
  refetchQueriesForEmployeeMode?: PureQueryOptions[];
  isManager?: boolean;
};

const TimeTrackingEntries: React.FC<Props> = ({
  timeSheet,
  costCenters,
  isManager,
  refetchQueriesForEmployeeMode,
}) => {
  const { restrictFutureEntriesForADay } = useRestrictFutureEntries();

  const days = useTimeSheetDays(timeSheet);
  const userInfo = useUserInfo();
  const timeSheetEmployeeId = timeSheet.flair__Employee__c;

  const employeeType: EmployeeType = useMemo(() => {
    if (isManager) {
      return 'manager';
    } else if (
      userInfo.canChangeOwnTimeEntries &&
      timeSheetEmployeeId === userInfo.id
    ) {
      return 'employeeWithCanChangeOwnTimeEntries';
    }
    return 'employee';
  }, [timeSheetEmployeeId, isManager, userInfo]);

  return (
    <>
      {days
        .map((x) => mapToDayWithTimeEntries(x, employeeType))
        .map((dayItem) => {
          const readonlyReasons = getReadonlyReasons(
            timeSheet,
            dayItem.timeEntries,
            dayItem.day.toDate(),
            restrictFutureEntriesForADay,
          );
          return (
            <DayTimeEntriesSmart
              key={dayItem.day.toISOString()}
              costCenters={costCenters}
              day={dayItem}
              employeeId={timeSheet.flair__Employee__c}
              readonlyReasons={readonlyReasons}
              refetchQueriesForEmployeeMode={refetchQueriesForEmployeeMode}
            />
          );
        })}
    </>
  );
};

export default TimeTrackingEntries;
