import { isToday } from 'date-fns';
import { Theme } from '../../../../theme';
import {
  ContentDocumentLink,
  DocumentFile,
  Expense,
  expenseSF,
  ExpenseStatus,
} from './types';

export const mapExpense = (exp: expenseSF): Expense => ({
  id: exp.Id,
  description: exp.flair__Description__c ?? null,
  amount: exp.flair__Amount__c,
  currency: exp.CurrencyIsoCode,
  expenseDate: new Date(exp.flair__Expense_Date__c),
  project: exp.project?.flair__Name__c || null,
  merchant: exp.merchant?.Name,
  category: exp.expenseCategory,
  status: exp.flair__Status__c,
  employee: exp.employee,
  documents: exp.ContentDocumentLinks
    ? exp.ContentDocumentLinks.map(mapDocumentFile)
    : [],
  declineReason: exp.flair__Decline_Reason__c || null,
  name: exp.Name,
  isNew: isToday(new Date(exp.CreatedDate)),
  commentsCount: exp.commentsCount,
});

export const mapDocumentFile = (link: ContentDocumentLink): DocumentFile => ({
  id: link.Id,
  fileName: link.ContentDocument.Title
    ? `${link.ContentDocument.Title}.${link.ContentDocument.FileExtension}`
    : link.ContentDocument.Title,
  downloadPublicUrl: link.downloadUrl,
});

export const getExpenseStatusColor = (status: ExpenseStatus): string => {
  if (status === 'UNKNOWN') {
    return Theme.expenses.status['PENDING'].color;
  }

  return Theme.expenses.status[status].color;
};
