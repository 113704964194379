import * as React from 'react';
import './SearchedEmployeesPopup.css';
import { Overlay, Popover } from 'react-bootstrap';
import { EmployeeWithAvatar } from '../../../Employee';
import { MentionEmployee } from '../../types';
import { HTMLAttributes, RefObject, useContext } from 'react';
import { InnerModalContext } from '../../../../../../context/InnerModalContext';
import Skeleton from 'react-loading-skeleton';
import { ApolloError } from '@apollo/client';
import ServerError from '../../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = HTMLAttributes<HTMLDivElement> & {
  startDetectingUsersInput: boolean;
  mentionEmployees: MentionEmployee[];
  onMentionUser: (employee: MentionEmployee) => void;
  isLoading: boolean;
  error: ApolloError | undefined;
};

const i18Path = 'comments.input.searchedEmployeesPopup';

const SearchEmployeesPopup = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      mentionEmployees,
      onMentionUser,
      isLoading,
      startDetectingUsersInput,
      error,
    },
    ref,
  ) => {
    const { setIsInnerModalOpen } = useContext(InnerModalContext);

    const show =
      startDetectingUsersInput && (mentionEmployees.length > 0 || isLoading);

    if (show) {
      setIsInnerModalOpen(true);
    }

    if (error) {
      return <ServerError />;
    }

    //TODO:: onHide, stop detecting user input + reset search term, will be handled in the next PR
    return (
      <Overlay
        target={ref as RefObject<HTMLDivElement>}
        show={show}
        onHide={() => setIsInnerModalOpen(false)}>
        <Popover
          id="searched-employees-popover"
          data-testid="searched-employees-popover">
          <div className="d-flex flex-column gap-4 pb-4 px-3 search-employees-popover-inner-container">
            {isLoading ? (
              <Loading />
            ) : (
              mentionEmployees.map((e) => (
                <EmployeeWithAvatar
                  key={e.id}
                  employee={e}
                  additionalInfo={e.position ?? ''}
                  size="sm"
                  onClick={() => {
                    onMentionUser(e);
                  }}
                  rightSideInfo={e.hasAccess ? null : <NoAccess />}
                  dataTestId={`searched-employee-${e.id}`}
                />
              ))
            )}
          </div>
        </Popover>
      </Overlay>
    );
  },
);

const Loading: React.FC = () => {
  return (
    <>
      {[...Array(3).keys()].map((_, i) => (
        <Skeleton key={i} width={253} height={40} />
      ))}
    </>
  );
};

const NoAccess: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <FlairIcon
      icon="alert-circle-outline"
      className="ms-5"
      tooltip={t('noAccess')}
      data-testid="no-access-icon"
    />
  );
};

export default SearchEmployeesPopup;
