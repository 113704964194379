import React from 'react';
import { useHistory } from 'react-router-dom';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';
import { routes } from '../../../../routes';
import { filterDefaultData } from './types';
import useGetRouteJobId from './useGetRouteJobId';

const ClearFilters: React.FC = () => {
  const history = useHistory();
  const jobId = useGetRouteJobId();

  const handleClearFilters = () => {
    history.push(
      routes.jobCandidates.route
        .withQueryParams({ ...filterDefaultData })
        .create({ jobId }),
    );
  };
  return <ClearFiltersLink onClick={handleClearFilters} />;
};

export default ClearFilters;
