/**
 *
 * a function to check if a value is null, empty string, undefined but not zero
 * 0 => false
 * null => true
 * undefined => true
 * '' => true
 */
export const falsyExceptZero = (value: any) => {
  return !(value || value === 0);
};
