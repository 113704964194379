import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { useUserInfo } from '../../../context/UserInfo';
import { TableContent } from '../../../manager/components/TableContent';
import { ManagerSkill } from './types';
import { SkillCreatedBy } from './SkillCreatedBy';
import { SkillDeletor } from './SkillDeletor';
import { SkillEditor } from './SkillEditor';

type SkillRow = {
  id: string;
  name: string;
  rateable: boolean;
  employees: number;
  median: number;
  createdBy: { id: string; name: string; avatarUrl: string | null } | null;
  createdDate: string;
};

type Props = {
  filter: string;
  data: ManagerSkill[];
  onDeleteClick: (id: string) => void;
  onUpdateClick: (id: string) => void;
};

export const ManageSkillsTable: React.FC<Props> = ({
  filter,
  data,
  onDeleteClick,
  onUpdateClick,
}) => {
  const { t } = useTranslation();
  const { id: userId } = useUserInfo();

  const columns: Column<SkillRow>[] = useMemo(
    () => [
      {
        Header: <Trans t={t} i18nKey="skills.manager.table.skill" />,
        accessor: 'name',
        sortType: 'caseInsensitive',
        Cell: ({ value, row }: { value: string; row: Row<SkillRow> }) => (
          <SkillEditor
            id={row.original.id}
            name={value}
            rateable={row.original.rateable}
            isEditable={
              row.original.createdBy
                ? row.original.createdBy.id === userId
                : false
            }
            onUpdateClick={onUpdateClick}
          />
        ),
      },
      {
        Header: <Trans t={t} i18nKey="skills.manager.table.employees" />,
        accessor: 'employees',
      },
      {
        Header: <Trans t={t} i18nKey="skills.manager.table.median" />,
        accessor: 'median',
      },
      {
        Header: <Trans t={t} i18nKey="skills.manager.table.createdBy" />,
        accessor: 'createdBy',
        Cell: ({
          value,
          row,
        }: {
          value: {
            id: string;
            name: string;
            initials: string;
            avatarUrl: string;
          } | null;
          row: Row<SkillRow>;
        }) => {
          return value ? (
            <div className="d-flex flex-nowrap justify-content-between">
              <SkillCreatedBy
                name={value.name}
                initials={value.initials}
                avatarUrl={value.avatarUrl}
                date={row.original.createdDate}
              />
              {value.id === userId && (
                <div className="ms-2">
                  <SkillDeletor
                    id={row.original.id}
                    onDeleteClick={onDeleteClick}
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          );
        },
      },
    ],
    [t, userId, onDeleteClick, onUpdateClick],
  );

  const dataMemo = useMemo(() => transformData(data), [data]);

  return (
    <TableContent
      columns={columns}
      data={dataMemo}
      initialSortBy={[{ id: 'name' }]}
      filter={filter}
    />
  );
};

const transformData = (data: ReadonlyArray<ManagerSkill>): SkillRow[] => {
  return data.map((s) => ({
    id: s.id,
    name: s.name,
    rateable: s.rateable,
    employees: s.employeesCount,
    median: s.median,
    createdBy: s.createdBy
      ? {
          id: s.createdBy?.id,
          name: s.createdBy.name,
          initials: s.createdBy.initials,
          avatarUrl: s.createdBy.avatarUrl ? s.createdBy.avatarUrl : null,
        }
      : null,
    createdDate: s.createdDate,
  }));
};
