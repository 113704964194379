import { useParams } from 'react-router-dom';

type Params = {
  jobId: string;
};

export const useGetRouteJobId = () => {
  const { jobId } = useParams<Params>();
  return jobId;
};
export default useGetRouteJobId;
