import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useContext, useState } from 'react';
import { DashboardContext } from '../../context';
import { routes } from '../../../../routes';
import DashboardEmployeeWithAvatar from '../DashboardEmployeeWithAvatar/DashboardEmployeeWithAvatar';
import { CelebrationCreateModal } from '../../../Celebrations/createOrEditCelebration/CelebrationCreateModal';
import { useUserInfo } from '../../../../context/UserInfo';
import { FlairLabel } from '../../../../../../atomic/atoms/Flairlabel/FlairLabel';
import { Section, SectionType } from './types';
import { mapCelebration } from './mappings';
import { Employee } from '../../../People/types';
import './Sections.css';
import RightSideElement from './RightSideElement';
import WidgetSpinner from '../WidgetSpinner';
import EmptyStateContent from '../EmptyStateContent';
import classNames from 'classnames';

const SectionLabel: React.FC<{ text: string; className?: string }> = ({
  text,
  className = '',
}) => {
  return (
    <FlairLabel
      text={text}
      className={classNames('h4-medium d-block mb-3-5', className)}
    />
  );
};

type SectionsProps = {
  sections: Section[];
  type: SectionType;
  loading: boolean;
  emptyStateTitle: string;
};

const Sections: React.FC<SectionsProps> = ({
  sections,
  type,
  loading,
  emptyStateTitle,
}) => {
  const { id: userId } = useUserInfo();
  const history = useHistory();
  const { setSelectedEmployee } = useContext(DashboardContext);
  const [celebrationEmployee, setCelebrationEmployee] =
    useState<Employee | null>(null);

  const handleOnCheerSuccess = () => {
    setCelebrationEmployee(null);
    history.push(routes.celebrationsFeed.route.create({}));
  };

  if (loading) {
    return <WidgetSpinner />;
  }

  const isEmpty = sections.every((section) => section.items.length === 0);

  if (isEmpty) {
    return <EmptyStateContent title={emptyStateTitle} />;
  }

  return (
    <div className="widget-sections d-flex flex-column gap-3-5">
      {sections.map((section) => {
        return (
          <div key={section.key} data-testid={`widget-section-${section.key}`}>
            <SectionLabel text={section.label} />
            <div className="d-flex flex-column gap-3-5">
              {section.items.map((colleague) => {
                return (
                  <div
                    className="d-flex justify-content-between"
                    key={colleague.id}>
                    <DashboardEmployeeWithAvatar
                      key={colleague.id}
                      employee={{
                        name: colleague.name,
                        avatarUrl: colleague.avatarUrl,
                      }}
                      additionalInfo={colleague.additionalInfo}
                      onClick={() => setSelectedEmployee(colleague)}
                    />
                    <RightSideElement
                      section={section}
                      type={type}
                      employee={colleague}
                      userId={userId}
                      setCelebrationEmployee={setCelebrationEmployee}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <CelebrationCreateModal
        show={celebrationEmployee !== null}
        onClose={() => setCelebrationEmployee(null)}
        onSuccess={handleOnCheerSuccess}
        celebration={mapCelebration(celebrationEmployee, type)}
      />
    </div>
  );
};

export default Sections;
