import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { i18Path } from '../../constants';
import InputLabel from '../../../../../../../../../components/form/InputLabel';
import { SelectDropdownControlled } from '../../../../../../../../../components/form/Selects';
import {
  InventoryItemResponsibleListQuery,
  useInventoryItemResponsibleListQuery,
} from '../../../../../../../__generated__/graphql';
import ServerError from '../../../../../../../../../components/ServerError';
import { OptionBase } from '../../../../../../../../../components/Select/types';
import { useUserInfo } from '../../../../../../../context/UserInfo';

type Props = {
  onValueChange?: (val: string) => void;
};

export const ResponsibleFormField: React.FC<Props> = ({ onValueChange }) => {
  const t = useNamespacedTranslation(i18Path);

  const {
    data: employeesResponsible,
    loading: employeesResponsibleLoading,
    error: employeesResponsibleError,
  } = useInventoryItemResponsibleListQuery();

  const { responsibleEmployees } =
    useMapEmployeesResponsibleOptions(employeesResponsible);

  const handleSetResponsibleEmployeeName = (employeeId: string | null) => {
    if (employeeId) {
      const employee = responsibleEmployees.find(
        (item) => item.value === employeeId,
      );
      if (employee && onValueChange) {
        onValueChange(employee.label);
      }
    }
  };

  if (employeesResponsibleError) return <ServerError />;
  return (
    <>
      <InputLabel label={t('form.fields.responsible')} />
      <SelectDropdownControlled
        onValueChange={handleSetResponsibleEmployeeName}
        isLoading={employeesResponsibleLoading}
        name="responsible"
        options={responsibleEmployees}
        heightMode="large"
        placeholder={t('form.fields.responsiblePlaceholder')}
      />
    </>
  );
};

const useMapEmployeesResponsibleOptions = (
  data: InventoryItemResponsibleListQuery | undefined,
): { responsibleEmployees: OptionBase[] } => {
  const t = useNamespacedTranslation(i18Path);
  const { id: currentUserId } = useUserInfo();
  const defaultEmployeesResponsibleOptions = [
    { value: currentUserId, label: t('options.ME') },
  ];

  const res = { responsibleEmployees: defaultEmployeesResponsibleOptions };
  if (!data || !data.activeEmployees?.length) {
    return res;
  }
  return data.activeEmployees?.reduce((acc, { Id, Name }) => {
    acc.responsibleEmployees.push({
      value: Id,
      label: Name,
    });
    return acc;
  }, res);
};
