import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Dasboard from './apps/dashboard/App';
import Auth from './apps/auth/App';

import { paths as dashboardPaths } from './apps/dashboard/routes';
import { pathTemplates as authPaths } from './apps/auth/routes';
import { useCurrentLocale } from './context/I18n';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { AuthProvider } from './context/AuthContext';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Theme } from './theme';

const App: React.FC = () => {
  const locale = useCurrentLocale();
  moment.locale(locale);

  return (
    <>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <SkeletonTheme
        color={Theme.color.paper4}
        highlightColor={Theme.color.paper5}>
        <Router>
          <AuthProvider>
            <Switch>
              <Route path={authPaths}>
                <Auth />
              </Route>
              <Route path={dashboardPaths}>
                <Dasboard />
              </Route>
            </Switch>
          </AuthProvider>
        </Router>
      </SkeletonTheme>
    </>
  );
};

export default App;
