import React from 'react';
import moment, { Moment } from 'moment';
import DurationFormat from '../../DurationFormat';

type Props = {
  start: Moment;
  end: Moment;
};

const DurationString: React.FC<Props> = ({ start, end }) => (
  <DurationFormat minutes={moment.duration(end.diff(start)).asMinutes()} />
);

export default DurationString;
