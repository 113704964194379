import * as React from 'react';
import { useUserInfo } from '../../../context/UserInfo';
import {
  EmployeeFeedbackState,
  useHomePerformaceReviewCardQuery,
} from '../../../__generated__/graphql';
import {
  mapFeedbacks,
  mapMeetings,
  onGoingFeedback,
} from '../../Home/PerformanceReviewCard/helpers';
import LoadingWidget from '../CommonComponents/LoadingWidget';
import NextMeetingCard from './NextMeetingCard/NextMeetingCard';

const NextMeetingsWidget: React.FC = () => {
  const { calendarToken } = useUserInfo();
  const { data, loading, error } = useHomePerformaceReviewCardQuery({
    variables: {
      filter: {
        state: EmployeeFeedbackState.Draft,
      },
    },
  });

  if (loading) {
    return <LoadingWidget height={98} />;
  }

  if (error || !data || !calendarToken) {
    return null;
  }

  const nextMeetings = mapMeetings([
    ...mapFeedbacks(data.me.feedbacks).filter(onGoingFeedback),
    ...mapFeedbacks(data.me.feedbacksAsReviewer).filter(onGoingFeedback),
  ]);

  return (
    <>
      {nextMeetings.map((m) => (
        <NextMeetingCard
          key={m.meetingId}
          {...m}
          calendarToken={calendarToken}
        />
      ))}
    </>
  );
};

export default NextMeetingsWidget;
