import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import AutoSaveInputLabel from '../../../../components/form/AutoSaveInputLabel';
import { FormGroup } from '../../../../components/form/FormGroup';

type Params = {
  defaultValue: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (value: string) => void;
};

const FinalComment: React.FC<Params> = ({
  disabled,
  required,
  defaultValue,
  loading,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FormGroup>
      <AutoSaveInputLabel
        label={t('evaluations.overallComment')}
        required={required}
        loading={loading}
        completed={!!defaultValue}
      />
      <TextareaAutosize
        className="form-control"
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabled}
        readOnly={disabled}
        minRows={4}
      />
    </FormGroup>
  );
};

export default FinalComment;
