import React from 'react';
import { useTranslation } from 'react-i18next';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';
import { Theme } from '../../../../../theme';

const AnswerContainer = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
});

const HiddenAnswerSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50px',
  height: '100%',
  backgroundColor: Theme.color.gray5,
  borderRadius: Theme.border.radius,
  color: Theme.color.gray2,
});

const HiddenText = (props: React.HTMLAttributes<HTMLSpanElement>) => (
  <span {...props} />
);

const Label: React.FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation();
  return (
    <label className="mb-2">
      {t('questionnaire.notRequiredAnswer.employeeNameAnswerLabel', {
        employeeName: name,
        interpolation: { escapeValue: false },
      })}
    </label>
  );
};

export const NotRequiredAnswer: React.FC<{ employeeName?: string }> = ({
  employeeName,
}) => {
  const { t } = useTranslation();
  return (
    <AnswerContainer>
      {employeeName && <Label name={employeeName} />}
      <HiddenAnswerSection>
        <FlairIcon icon="lock-open-outline" />
        <HiddenText className="ms-2">
          {t('questionnaire.notRequiredAnswer.label')}
        </HiddenText>
      </HiddenAnswerSection>
    </AnswerContainer>
  );
};
