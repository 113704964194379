import * as React from 'react';

const ArrowDownFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.375C8.41421 2.375 8.75 2.71079 8.75 3.125V11.0643L11.9697 7.84467C12.2626 7.55178 12.7374 7.55178 13.0303 7.84467C13.3232 8.13756 13.3232 8.61244 13.0303 8.90533L8.53033 13.4053C8.23744 13.6982 7.76256 13.6982 7.46967 13.4053L2.96967 8.90533C2.67678 8.61244 2.67678 8.13756 2.96967 7.84467C3.26256 7.55178 3.73744 7.55178 4.03033 7.84467L7.25 11.0643V3.125C7.25 2.71079 7.58579 2.375 8 2.375Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ArrowDownFilledIcon;
