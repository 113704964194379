import React from 'react';
import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import styled from '@emotion/styled';
import DurationFormat from '../../components/DurationFormat';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {
  trackedTime: number;
  projectEstimation: number | null;
};

const ProgressBarStyled = styled(ProgressBar)({
  width: '6rem',
  height: '0.375rem',
});

const ProjectTrackingPopoverContent: React.FC<Props> = ({
  projectEstimation,
  trackedTime,
}) => {
  const t = useNamespacedTranslation('timeTracking.projects');

  if (projectEstimation !== null && trackedTime > projectEstimation) {
    return (
      <div className="popover-body d-flex align-items-center justify-content-center">
        <span className="popover-body-label me-auto">
          {t('trackedTimeExceeded')}
        </span>

        <span className="popover-body-label ms-3">
          <DurationFormat minutes={trackedTime - projectEstimation} />
        </span>
      </div>
    );
  } else {
    return <></>;
  }
};

const overlayDelay = { show: 250, hide: 400 };
export const ProjectTrackingProgressBar: React.FC<Props> = ({
  trackedTime,
  projectEstimation,
}) => {
  if (projectEstimation !== null) {
    const isExceeded = trackedTime > projectEstimation;

    return (
      <OverlayTrigger
        placement="top"
        delay={overlayDelay}
        overlay={
          isExceeded ? (
            <Popover id="popover">
              <ProjectTrackingPopoverContent
                projectEstimation={projectEstimation}
                trackedTime={trackedTime}
              />
            </Popover>
          ) : (
            <></>
          )
        }>
        <ProgressBarStyled>
          <ProgressBar
            variant="success"
            now={isExceeded ? projectEstimation : trackedTime}
            max={isExceeded ? undefined : projectEstimation}
            key={1}
          />
          {isExceeded && (
            <ProgressBar
              variant="danger"
              now={trackedTime - projectEstimation}
              key={2}
            />
          )}
        </ProgressBarStyled>
      </OverlayTrigger>
    );
  } else {
    return null;
  }
};
