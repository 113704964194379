import * as React from 'react';

const BarChartOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_1586_43558)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 0.5C1.27614 0.5 1.5 0.723858 1.5 1V14.5H15C15.2761 14.5 15.5 14.7239 15.5 15C15.5 15.2761 15.2761 15.5 15 15.5H1.5C1.23478 15.5 0.98043 15.3946 0.792893 15.2071C0.605356 15.0196 0.5 14.7652 0.5 14.5V1C0.5 0.723858 0.723858 0.5 1 0.5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.625 7.5C3.55596 7.5 3.5 7.55596 3.5 7.625V12.375C3.5 12.444 3.55596 12.5 3.625 12.5H4.875C4.94404 12.5 5 12.444 5 12.375V7.625C5 7.55596 4.94404 7.5 4.875 7.5H3.625ZM2.5 7.625C2.5 7.00368 3.00368 6.5 3.625 6.5H4.875C5.49632 6.5 6 7.00368 6 7.625V12.375C6 12.9963 5.49632 13.5 4.875 13.5H3.625C3.00368 13.5 2.5 12.9963 2.5 12.375V7.625Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.125 6C8.05596 6 8 6.05596 8 6.125V12.375C8 12.444 8.05596 12.5 8.125 12.5H9.375C9.44404 12.5 9.5 12.444 9.5 12.375V6.125C9.5 6.05596 9.44404 6 9.375 6H8.125ZM7 6.125C7 5.50368 7.50368 5 8.125 5H9.375C9.99632 5 10.5 5.50368 10.5 6.125V12.375C10.5 12.9963 9.99632 13.5 9.375 13.5H8.125C7.50368 13.5 7 12.9963 7 12.375V6.125Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6133 4C12.5442 4 12.4883 4.05596 12.4883 4.125V12.375C12.4883 12.444 12.5442 12.5 12.6133 12.5H13.8633C13.9323 12.5 13.9883 12.444 13.9883 12.375V4.125C13.9883 4.05596 13.9323 4 13.8633 4H12.6133ZM11.4883 4.125C11.4883 3.50368 11.992 3 12.6133 3H13.8633C14.4846 3 14.9883 3.50368 14.9883 4.125V12.375C14.9883 12.9963 14.4846 13.5 13.8633 13.5H12.6133C11.992 13.5 11.4883 12.9963 11.4883 12.375V4.125Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1586_43558">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default BarChartOutlineIcon;
