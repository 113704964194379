import { constant, times } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Nav from '../Nav';
import Table from '../Table';

const DATE_PARAM = 'startDate';

const dateFromParam = (dayFromQueryString: string | null): moment.Moment =>
  moment(dayFromQueryString ? dayFromQueryString : undefined);

const buildWeekDays = (dayFromQueryString: string | null): moment.Moment[] =>
  times(7, constant(dateFromParam(dayFromQueryString).startOf('isoWeek'))).map(
    (day, i) => moment(day).add(i, 'days'),
  );

const AllShiftsWeeklyView: React.FC = () => {
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const startDate = query.get(DATE_PARAM);
  const weekDays: moment.Moment[] = buildWeekDays(startDate);

  return (
    <Card>
      <Card.Header>
        <Nav startDay={weekDays[0]} dateParamName={DATE_PARAM} />
      </Card.Header>
      <Table days={weekDays} />
    </Card>
  );
};

export default AllShiftsWeeklyView;
