import React from 'react';
import { InfoHint } from '../../../../components/hint/InfoHint';

export type Props = {
  label: string;
  hint?: string;
  value: string;
};

export const TimeBalanceDetailsRow: React.FC<Props> = ({
  label,
  hint,
  value,
}) => {
  return (
    <div className="d-flex justify-content-between py-1">
      <div className="d-flex align-items-center">
        {label}
        {hint && <InfoHint className="ms-2 d-flex" text={hint} />}
      </div>
      <div>{value}</div>
    </div>
  );
};
