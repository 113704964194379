import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';

type Props = {
  error: Error | null;
  resetError: () => void;
};

export const FallbackUI: React.FC<Props> = ({ error, resetError }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center m-4 p-4">
      <h1 className="display-4 mb-3">{t('errors.client.title')}</h1>
      <p className="text-muted mb-4">{t('errors.client.description')}</p>
      {error && <p className="text-muted mb-4">{error.message}</p>}
      <p>
        <Button
          label={t('errors.client.resetBtn')}
          onClick={resetError}></Button>
      </p>
    </div>
  );
};
