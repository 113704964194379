import React from 'react';
import { OpenShiftBlock, OpenShiftState } from '../../Common/types';

import { getWeeklyShiftBlockStylingInfo } from '../weeklyShiftsLogic';
import ShiftMainLabel from './MainLabel';
import ShiftWeeklyBlockContainer, {
  ZPriority,
} from './ShiftWeeklyBlockContainer';

type Props = {
  shift: OpenShiftBlock;
  isRequesting: boolean;
  onRequestShift: (shiftId: string) => void;
  onDeleteAssignmentRequest: (requestId: string) => void;
};

const OpenShiftWeeklyBlockDumb: React.FC<Props> = ({ shift, isRequesting }) => {
  let weeklyShiftBlockStylingInfo = getWeeklyShiftBlockStylingInfo(shift);

  return (
    <ShiftWeeklyBlockContainer
      weeklyShiftBlockStylingInfo={weeklyShiftBlockStylingInfo}
      zPriority={getZPriority(shift.state)}
      spinnerVisible={isRequesting}>
      <ShiftMainLabel {...shift} />
    </ShiftWeeklyBlockContainer>
  );
};

export default OpenShiftWeeklyBlockDumb;

const getZPriority = (state: OpenShiftState): ZPriority => {
  switch (state) {
    case 'confirmed':
      return 5;
    case 'requested':
      return 4;
    case 'available':
      return 3;
    case 'not-available':
      return 1;
  }
};
