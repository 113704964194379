import './ProjectTimesheetsByProjects.css';
import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState } from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { i18prefix, timesheetsRange } from '../../helpers';
import SubmitButton from '../../../TimeTracking/TimeTrackingEntries/components/SubmitButton';
import {
  Project,
  ProjectDay,
  ProjectTimeTrackingMap,
  Timesheet,
} from '../../types';
import { ProjectTimesheet } from './ProjectTimesheet';
import { collectTimesTracked, removeEmptyTimeEntries } from '../helpers';
import { buildProjectsTimeTrackings, mapToTimeEntryInput } from '../mappings';
import { useToasts } from '../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { useSaveProjectTimeEntriesByProjectsMutation } from '../../../../__generated__/graphql';
import CancelButton from './CancelButton';
import { isEqual } from 'lodash';
import { ProjectTitleCol } from './ProjectTitleCol';
import { TotalHoursCol } from './TotalHoursCol';
import Hint from '../../../../../../components/hint';
import { DaysTotalsRow } from './DaysTotalsRow';
import { useTimeSheetDays } from '../ProjectTimesheetsByDays/useTimeSheetDays';
import HoursHeader from './HoursHeader';

type Props = {
  timesheet: Timesheet;
  projects: Project[];
  onProjectClick: (project: Project) => void;
};

const StyledHours = styled.div({
  fontWeight: 'bold',
  fontSize: '13px',
});

export const ProjectTimesheetByProjects: React.FC<Props> = ({
  timesheet,
  projects,
  onProjectClick,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const [exceededTimeLimit, setExceededTimeLimit] = useState(false);

  const [saveProjectTimeEntriesMutation, { loading: isSubmitting }] =
    useSaveProjectTimeEntriesByProjectsMutation();
  const timeSheetsDays = useTimeSheetDays(timesheet, projects);

  const initialProjectTimeTracking = useMemo(
    () => buildProjectsTimeTrackings(projects, timesheet),
    [timesheet, projects],
  );

  const [projectTimeTrackingsMap, setProjectTimeTrackingsMap] =
    useState<ProjectTimeTrackingMap>(initialProjectTimeTracking);

  const isDirty = useMemo(
    () => !isEqual(initialProjectTimeTracking, projectTimeTrackingsMap),
    [initialProjectTimeTracking, projectTimeTrackingsMap],
  );

  const handleChange = (projectId: string, days: ProjectDay[]) => {
    setProjectTimeTrackingsMap((prev) => ({ ...prev, [projectId]: days }));
  };

  const handleCancel = useCallback(() => {
    setProjectTimeTrackingsMap(() =>
      buildProjectsTimeTrackings(projects, timesheet),
    );
  }, [projects, timesheet]);

  const handleSave = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const timeEntriesToSave = removeEmptyTimeEntries(
        collectTimesTracked(projectTimeTrackingsMap || {}),
      );

      if (!timeEntriesToSave.length) {
        return;
      }

      return saveProjectTimeEntriesMutation({
        variables: {
          input: {
            timesheetId: timesheet.id,
            timeEntries: timeEntriesToSave.map(mapToTimeEntryInput),
          },
          ...timesheetsRange(),
        },
      })
        .then(() => {
          addSuccess(t(`${i18prefix}.projectTimeEntries.saveSuccess`));
        })
        .catch(errorHandler);
    },
    [
      addSuccess,
      errorHandler,
      projectTimeTrackingsMap,
      saveProjectTimeEntriesMutation,
      t,
      timesheet.id,
    ],
  );

  if (!projectTimeTrackingsMap) {
    return <></>;
  }

  return (
    <Card>
      <Form onSubmit={handleSave}>
        <TableInfoDiv className="d-flex px-3 py-3 align-items-center justify-content-between">
          <div className="d-flex ms-3 align-items-center gap-2">
            <div className="">{t(`${i18prefix}.projectsView.header`)}</div>
            <div className="color-paper-1">
              <Hint
                id={`project-entry-hint`}
                text={t(`${i18prefix}.hoursHint`)}
                flairIconName={'alert-circle-outline'}></Hint>
            </div>
          </div>

          {isDirty && (
            <div className="d-flex">
              <CancelButton
                disabled={isSubmitting}
                onClick={handleCancel}
                title={t('timeTrackingNew.dayCard.cancel')}
              />
              <SubmitButton
                disabled={isSubmitting || exceededTimeLimit}
                isProcessing={isSubmitting}
                title={t('timeTrackingNew.dayCard.saveChanges')}
              />
            </div>
          )}
        </TableInfoDiv>
        <TableContainer>
          {/*Projects Rows*/}
          <Table size="sm" className="mb-0">
            {/*Table header*/}
            <thead>
              <TrSticked className="highlighted">
                {/*The word 'Project' in the header row*/}
                <ProjectTitleCol header={true}>
                  {t(`${i18prefix}.fields.project`)}
                </ProjectTitleCol>
                {/*The day label(like Mon 22th) with the comments icon*/}
                <HoursHeader timesheet={timesheet} projects={projects} />
                {/*The word 'Total' in the header row*/}
                <TotalHoursCol className="cell-bl">
                  <StyledHours>{t(`${i18prefix}.totalHours`)}</StyledHours>
                </TotalHoursCol>
              </TrSticked>
            </thead>
            <tbody>
              {/*The row for every project*/}
              {projects.map((project) => (
                <ProjectTimesheet
                  project={project}
                  timesheet={timesheet}
                  days={projectTimeTrackingsMap[project.id] || []}
                  onChange={handleChange}
                  onProjectClick={onProjectClick}
                  key={project.id}
                  isSubmitting={isSubmitting}
                />
              ))}
              {/*The totals row for every day*/}
              <DaysTotalsRow
                projects={projects}
                projectTimeTrackingsMap={projectTimeTrackingsMap}
                timeSheetsDays={timeSheetsDays}
                setExceededTimeLimit={(exceeded) =>
                  setExceededTimeLimit(exceeded)
                }></DaysTotalsRow>
            </tbody>
          </Table>
        </TableContainer>
      </Form>
    </Card>
  );
};

export const TableInfoDiv = styled.div({
  height: '4rem',
});

// maxHeight rem value should be calculated manually based on the height of the remaining header components
const TableContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 20.5rem)',
});

const TrSticked = styled.tr({
  position: 'sticky',
  top: 0,
  zIndex: 2,
});
