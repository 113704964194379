import classNames from 'classnames';
import React, { CSSProperties, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../theme';
import { EmployeeOption } from '../Selects/EmployeeOptionLabel';
import { SelectSingle } from '../Selects/SelectSingle';
import { SelectSingleEmployee } from '../Selects/SelectSingleEmployee';
import { TeamWithMembersOption } from './types';

export type Props = {
  employeeOptions: EmployeeOption[];
  teamWithMembersOptions: TeamWithMembersOption[];
  employeeIds: string[];
  onAddEmployees: (employeeIds: string[]) => void;
};

type Mode = 'employee' | 'team';

export const EmployeeByTeamSelect: React.FC<Props> = ({
  employeeIds,
  employeeOptions,
  teamWithMembersOptions,
  onAddEmployees,
}) => {
  const t = useNamespacedTranslation('components.employeeByTeamSelect');

  const [mode, setMode] = useState<Mode>('employee');

  const filteredEmployeeOptions = employeeOptions.filter((x) =>
    employeeIds.every((id) => id !== x.value),
  );

  const handleEmployeeSelected = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }
      onAddEmployees([value]);
    },
    [onAddEmployees],
  );

  const handleTeamSelected = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }
      const teamOption = teamWithMembersOptions.find((x) => x.value === value);
      if (!teamOption) {
        return;
      }
      onAddEmployees(teamOption.employeeIds);
    },
    [onAddEmployees, teamWithMembersOptions],
  );

  const handleEmployeeClick = useCallback(() => {
    setMode('employee');
  }, [setMode]);

  const handleTeamClick = useCallback(() => {
    setMode('team');
  }, [setMode]);

  return (
    <div className="d-flex align-items-center">
      <Label className="text-nowrap">{t('searchBy')}</Label>
      <Button
        variant="link"
        className="ms-2"
        onClick={handleEmployeeClick}
        style={mode === 'employee' ? selectedStyle : undefined}>
        {t('employee')}
      </Button>
      <Button
        variant="link"
        className="ms-2 me-3"
        onClick={handleTeamClick}
        style={mode === 'team' ? selectedStyle : undefined}>
        {t('team')}
      </Button>
      <LastColumn>
        {mode === 'employee' ? (
          <SelectSingleEmployee
            options={filteredEmployeeOptions}
            value={null}
            onChange={handleEmployeeSelected}
          />
        ) : (
          <SelectSingle
            options={teamWithMembersOptions}
            value={null}
            placeholder={t('teamPlaceholder')}
            onChange={handleTeamSelected}
          />
        )}
      </LastColumn>
    </div>
  );
};

const selectedStyle: CSSProperties = {
  border: 0,
  borderRadius: 0,
  borderBottom: `2px solid ${Theme.color.primary}`,
};

const Label = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLSpanElement>) => (
  <span {...rest} className={classNames('fs-5', 'text-dark', classNames)} />
);

const LastColumn = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('flex-grow-1')} />
);
