import React from 'react';
import { useTranslation } from 'react-i18next';
import Hint from '../../../../../../components/hint';
import { Theme } from '../../../../../../theme';
import {
  TimeEntryStats,
  TimeEntryStatsByLaw,
} from '../../../../components/AutoBreaks/shared';
import { isLegal } from '../../../../components/AutoBreaks/shared/autoBreaksCalculation/helpers';
import DurationFormat from '../../../../components/DurationFormat';
import { AutoBreakDeductionStatus } from '../logic';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = {
  timeEntryId: string;
  timeStats: TimeEntryStats;
  breaksDeductionStatus: AutoBreakDeductionStatus;
};
export const TimeEntryWorkingTimeIndicator: React.FC<Props> = ({
  timeEntryId,
  timeStats,
  breaksDeductionStatus,
}) => {
  return (
    <div className="d-flex align-items-center">
      <div className="me-1 d-flex align-items-center">
        <BreakTimeIndicator timeEntryId={timeEntryId} timeStats={timeStats} />
        <BreakDeductionStatus
          timeEntryId={timeEntryId}
          breaksDeductionStatus={breaksDeductionStatus}
        />
      </div>
      <div className="ms-3 me-1">
        <DurationFormat minutes={timeStats.workingMinutes} />
      </div>
    </div>
  );
};

const BreakDeductionStatus: React.FC<{
  timeEntryId: string;
  breaksDeductionStatus: AutoBreakDeductionStatus;
}> = ({ timeEntryId, breaksDeductionStatus }) => {
  const { t } = useTranslation();

  switch (breaksDeductionStatus) {
    case 'success':
      return (
        <Hint
          containerClassName="d-flex align-items-center"
          id={`deduction-success-${timeEntryId}`}
          text={t('autoBreaks.hintDeductionSuccess')}>
          <FlairIcon
            icon="remove-square-outline"
            className="ms-2"
            color={Theme.color.success}
          />
        </Hint>
      );
    case 'failed':
      return (
        <Hint
          containerClassName="d-flex align-items-center"
          id={`deduction-failed-${timeEntryId}`}
          text={t('autoBreaks.hintDeductionFailed')}>
          <FlairIcon
            icon="alert-circle-outline"
            className="ms-2"
            color={Theme.color.danger}
          />
        </Hint>
      );
    default:
      return null;
  }
};

const BreakTimeIndicator: React.FC<Omit<Props, 'breaksDeductionStatus'>> = ({
  timeStats,
  timeEntryId,
}) => {
  const { t } = useTranslation();

  const renderBreakIcon = (minutes: number, isRed: boolean = false) => {
    return (
      <div
        className="d-flex align-items-center"
        style={{ color: isRed ? Theme.color.danger : undefined }}>
        <FlairIcon icon="cafe-outline" className="me-1" />
        <DurationFormat minutes={minutes} />
      </div>
    );
  };

  const renderBreakIconWithHint = (
    minutes: number,
    hintText: string,
    isRed: boolean,
  ) => {
    return (
      <Hint id={`break-unused-minutes=${timeEntryId}`} text={hintText}>
        <div>{renderBreakIcon(minutes, isRed)}</div>
      </Hint>
    );
  };

  const getHint = (
    timeEntryStats: TimeEntryStatsByLaw,
    smallBreaks: boolean,
  ) => {
    if (smallBreaks && !isLegal(timeEntryStats)) {
      return t('autoBreaks.hintNotLegalWithSmallBreaks', {
        total: timeEntryStats.breakingMinutesUnusedByLaw,
        required: timeEntryStats.breakingMinutesRequiredByLaw,
      });
    } else if (!smallBreaks && !isLegal(timeEntryStats)) {
      return t('autoBreaks.hintNotLegalWithoutSmallBreaks', {
        total: timeEntryStats.breakingMinutesUnusedByLaw,
        required: timeEntryStats.breakingMinutesRequiredByLaw,
      });
    } else {
      return t('autoBreaks.hintLegalWithSmallBreaks');
    }
  };

  const renderBreakCountedByLawIcon = (
    lawRegulated: TimeEntryStatsByLaw,
    smallBreaks: boolean,
  ) => {
    if (isLegal(lawRegulated) && !smallBreaks) {
      return renderBreakIcon(lawRegulated.breakingMinutesCountedByLaw);
    }
    return renderBreakIconWithHint(
      lawRegulated.breakingMinutesCountedByLaw,
      getHint(lawRegulated, smallBreaks),
      !isLegal(lawRegulated),
    );
  };

  if (!timeStats.timeEntryStatsByLaw) {
    return renderBreakIcon(timeStats.breakingMinutesTotal);
  }

  const smallBreaksDuration =
    timeStats.breakingMinutesTotal -
    timeStats.timeEntryStatsByLaw.breakingMinutesCountedByLaw;

  const smallBreaks = smallBreaksDuration > 0;

  if (!smallBreaks) {
    return renderBreakCountedByLawIcon(
      timeStats.timeEntryStatsByLaw,
      smallBreaks,
    );
  }
  return (
    <div className="d-flex">
      {renderBreakCountedByLawIcon(timeStats.timeEntryStatsByLaw, smallBreaks)}
      {smallBreaks && (
        <Hint
          id={`asterik-hint-${timeEntryId}`}
          text={t('autoBreaks.hintAsterisk')}>
          <div className="ms-1">{`(+${smallBreaksDuration}m*)`}</div>
        </Hint>
      )}
    </div>
  );
};
