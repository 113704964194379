import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  label: string;
  pendingApproval: boolean;
};

export const FieldLabel: React.FC<Props> = ({
  label,
  name,
  pendingApproval,
}) => {
  const { t } = useTranslation();

  return pendingApproval ? (
    <OverlayTrigger
      overlay={
        <Tooltip id={`pending-approval-tooltip-${name}`}>
          {t('personalData.pendingApprovalTooptip')}
        </Tooltip>
      }>
      <span>
        <span className="text-warning me-1">●</span>
        {label}:
      </span>
    </OverlayTrigger>
  ) : (
    <>{label}:</>
  );
};
