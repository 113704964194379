import { useState, useCallback } from 'react';
import { PageSize } from './CardHeaderFilter';

export const useTableContentPageSize = (
  initialPageSize: PageSize,
): [PageSize, (pageSize: PageSize) => void] => {
  const [pageSize, setPageSize] = useState<PageSize>(initialPageSize);
  const onPageSizeChange = useCallback(
    (pageSize: PageSize) => setPageSize(pageSize),
    [setPageSize],
  );
  return [pageSize, onPageSizeChange];
};

export const useTableContentFiltration = (
  initialFilter: string = '',
): [string, (value: string) => void] => {
  const [filter, setFilter] = useState<string>(initialFilter);
  const onFilterChange = useCallback(
    (value: string) => setFilter(value),
    [setFilter],
  );
  return [filter, onFilterChange];
};
