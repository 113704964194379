import React from 'react';
import FlairIcon, { SizeProp } from '../../../../atomic/atoms/FlairIcon';
import { HolidayPopoverFragment } from '../../__generated__/graphql';
import HolidayPopover from '../HolidayPopover';

type Props = {
  size?: SizeProp;
  holidayPopoverInfo?: HolidayPopoverFragment;
};

const HolidayIcon: React.FC<Props> = ({ size, holidayPopoverInfo }) => (
  <FlairIcon
    icon="calendar-clear-outline"
    size={size}
    popover={
      holidayPopoverInfo ? (
        <HolidayPopover holiday={holidayPopoverInfo} />
      ) : undefined
    }
  />
);

export default HolidayIcon;
