import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = Pick<React.HTMLProps<HTMLAnchorElement>, 'className' | 'onClick'>;

const ClearFiltersLink: React.FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }}
      href=""
      className={['text-nowrap', className].join(' ')}>
      {t('employeePage.teamCalendar.clearFilters')}
    </a>
  );
};

export default ClearFiltersLink;
