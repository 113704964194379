import { Theme } from '../../../../../theme';
import {
  InventoryListDataFragment,
  InventoryDataFragment,
  InventoryDictionaryDataFragment,
  Location,
  InventoryListQuery,
  RecordType,
  SupportAdvisorFragment,
  InventoryItemSortInput,
  AscDescEnum,
  InventoryItemSortFieldEnum,
} from '../../../__generated__/graphql';
import {
  Category,
  DictionaryItem,
  Inventory,
  InventoryListItem,
  InventoryLocation,
  InventoryStatus,
  InventorySupportAdvisor,
} from './types';
import { SortingRule } from 'react-table';
import { InventoryItemResponse } from 'server/src/apps/internal/__generated__/graphql';

export const getInventoryItemStatusColor = (status: InventoryStatus): string =>
  Theme.inventory.status[status].color;

export const mapInventoryList = (
  src: InventoryListQuery,
): InventoryListItem[] => {
  return src.inventoryItemsPagination.items.map((x) => mapInventoryListData(x));
};

export const mapInventoryListData = (
  src: InventoryListDataFragment,
): InventoryListItem => ({
  id: src.Id,
  name: src.inventory.Name,
  inventory: mapInventory(src.inventory),
  location: src.location ? mapLocation(src.location) : null,
  createdOn: src.CreatedDate,
  category: { name: src.inventory.flair__Type__c },
  status: src.flair__Approval_Status__c as InventoryStatus,
  commentsCount: src.commentsCount,
});

export const mapCategory = (src: RecordType): Category => ({
  name: src.Name,
});

export const mapInventory = (inventory: InventoryDataFragment): Inventory => ({
  id: inventory.Id,
  name: inventory.Name,
  manufacturer: inventory.manufacturer
    ? mapManufacturer(inventory.manufacturer)
    : null,
  model: inventory.flair__Model__c ?? null,
  supportVideoUrl: inventory.flair__Support_Video__c,
  supportInstruction: inventory.flair__Support_Instruction__c,
  supportAdvisor: inventory.supportAdvisor
    ? mapSupportAdvisor(inventory.supportAdvisor)
    : null,
});

export const mapManufacturer = (
  manufacturer: InventoryDictionaryDataFragment,
): DictionaryItem => ({
  id: manufacturer.Id,
  name: manufacturer.Name,
  label: manufacturer.label,
});

export const mapLocation = (location: Location): InventoryLocation => ({
  id: location.Id,
  name: location.Name,
});
const defaultSorting: InventoryItemSortInput = {
  field: InventoryItemSortFieldEnum.InventoryItem,
  order: AscDescEnum.Desc,
};

const mapSortField = (field: string): InventoryItemSortFieldEnum => {
  switch (field) {
    case 'name':
      return InventoryItemSortFieldEnum.InventoryItem;
    case 'category':
      return InventoryItemSortFieldEnum.Category;
    case 'location':
      return InventoryItemSortFieldEnum.Location;
    case 'addedOn':
      return InventoryItemSortFieldEnum.CreatedDate;
    case 'status':
      return InventoryItemSortFieldEnum.Status;
    default:
      return InventoryItemSortFieldEnum.InventoryItem;
  }
};

export const mapSortBy = (
  sortBy: SortingRule<InventoryItemResponse>[],
): InventoryItemSortInput => {
  if (sortBy.length < 1) {
    return defaultSorting;
  }

  return {
    field: mapSortField(sortBy[0].id),
    order: sortBy[0].desc ? AscDescEnum.Desc : AscDescEnum.Asc,
  };
};

export const mapSupportAdvisor = (
  advisor: SupportAdvisorFragment,
): InventorySupportAdvisor => ({
  id: advisor.Id,
  name: advisor.Name,
  avatarUrl: advisor.avatar?.url ?? undefined,
});
