import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownActionItem } from '../../../../../../atomic/molecules/DropdownActionItem';
import { GoalId } from '../types';
import { GoalDeleteConfirmation } from './DeleteConfirmations';
import { GoalCloneModalConnected } from './GoalCloneModal/GoalCloneModalConnected';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';

type Props = {
  goalId: GoalId;
  goalName: string;
  canClone: boolean;
  canDelete: boolean;
  onDelete: () => void;
};

export const GoalsDropdownActions: React.FC<Props> = ({
  onDelete,
  goalId,
  goalName,
  canClone,
  canDelete,
}) => {
  const { t } = useTranslation();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [cloneModalVisible, setCloneModalVisible] = useState(false);

  const handleDeleteClose = () => setDeleteModalVisible(false);

  const handleDeleteSubmit = () => {
    setDeleteModalVisible(false);
    onDelete();
  };

  const handleDeleteItemClick = () => {
    setDeleteModalVisible(true);
  };

  const handleCloneItemClick = () => {
    setCloneModalVisible(true);
  };

  const handleCloneClose = () => {
    setCloneModalVisible(false);
  };

  if (!canClone && !canDelete) {
    return null;
  }

  return (
    <>
      <DropdownActions id="goal-actions">
        {canDelete && (
          <DropdownActionItem onClick={handleDeleteItemClick}>
            {t('performanceReview.goals2.delete')}
          </DropdownActionItem>
        )}
        {canClone && (
          <DropdownActionItem onClick={handleCloneItemClick}>
            {t('performanceReview.goals2.clone')}
          </DropdownActionItem>
        )}
      </DropdownActions>
      <GoalDeleteConfirmation
        name={goalName}
        visible={deleteModalVisible}
        onClose={handleDeleteClose}
        onSubmit={handleDeleteSubmit}
      />
      <GoalCloneModalConnected
        show={cloneModalVisible}
        goalId={goalId}
        goalName={goalName}
        onClose={handleCloneClose}
      />
    </>
  );
};
