import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';

type Props = {
  className?: string;
};

const WidgetSpinner: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={classNames('w-100 text-center', className)}>
      <Spinner animation="border" size="sm" />
    </div>
  );
};

export default WidgetSpinner;
