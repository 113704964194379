import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';

type Props = {
  createBtnVisible: boolean;
  onCreateGoal: () => void;
};

export const EmptyGoals: React.FC<Props> = ({
  createBtnVisible,
  onCreateGoal,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <EmptyStateCardBody
        title={t('performanceReview.goals2.emptyState')}
        action={
          createBtnVisible ? (
            <Button
              variant="primary"
              onClick={onCreateGoal}
              label={t('performanceReview.goals2.createGoal')}
            />
          ) : undefined
        }
      />
    </Card>
  );
};
