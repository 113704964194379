import { FetchResult, PureQueryOptions } from '@apollo/client';
import {
  TimeTrackingMutationsContext,
  useRejectTimeEntryChangeRequest,
} from '../../../../hooks/timeTracking';
import {
  SubmitTimeEntryInput,
  SubmitTimeEntryMutation,
  useDeleteTimeEntryMutation,
  useSubmitTimeEntryMutation,
} from '../../../../__generated__/graphql';
import React from 'react';

type Props = {
  changeRequestId: string | null;
  refetchQueries?: PureQueryOptions[];
};

type HookReturnValue = {
  mutations: TimeEntryMutations;
  mutationsProgress: MutationsProgress;
};

type TimeEntryMutations = {
  saveChanges: (
    input: SubmitTimeEntryInput,
  ) => Promise<FetchResult<SubmitTimeEntryMutation>>;
  rejectChangeRequest: (changeRequestId: string) => Promise<void>;
  deleteTimeEntry: (timeEntryId: string) => Promise<void>;
  approve: (
    changeRequestId: string,
    onCompleted?: (id: string) => void,
  ) => void;
};

type MutationsProgress = {
  isRejecting: boolean;
  isApproving: boolean;
  isSaving: boolean;
  isDeleting: boolean;
};

export const useTimeEntryManagersMutations = ({
  changeRequestId,
  refetchQueries,
}: Props): HookReturnValue => {
  const mutationOptions = refetchQueries
    ? {
        awaitRefetchQueries: true,
        refetchQueries,
      }
    : undefined;

  const { approveMutation } = React.useContext(TimeTrackingMutationsContext);
  const isApproving = changeRequestId
    ? approveMutation.processingIds.includes(changeRequestId)
    : false;

  const [rejectChangeRequest, { loading: isRejecting }] =
    useRejectTimeEntryChangeRequest(mutationOptions);
  const [deleteTimeEntry, { loading: isDeleting }] =
    useDeleteTimeEntryMutation(mutationOptions);
  const [saveChanges, { loading: isSaving }] =
    useSubmitTimeEntryMutation(mutationOptions);

  const mutations = {
    saveChanges: (input: SubmitTimeEntryInput) =>
      saveChanges({
        variables: {
          input,
        },
      }).then((resp) => resp),
    rejectChangeRequest: (changeRequestId: string) =>
      rejectChangeRequest({
        variables: {
          input: { ids: [changeRequestId] },
        },
      }).then(() => {}),
    deleteTimeEntry: (timeEntryId: string) =>
      deleteTimeEntry({
        variables: { id: timeEntryId },
      }).then(() => {}),
    approve: (changeRequestId: string, onCompleted?: (id: string) => void) =>
      approveMutation.processItem(changeRequestId, onCompleted),
  };

  return {
    mutations,
    mutationsProgress: { isApproving, isDeleting, isRejecting, isSaving },
  };
};
