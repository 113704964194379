import styled from '@emotion/styled';
import classNames from 'classnames';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Theme } from '../../../../../theme';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';
import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import { OrgChartEmployee } from '../types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import './EmployeeCard.css';

export type Props = {
  data: {
    employee: OrgChartEmployee;
    selectedSettings: string[];
  };
};

export const EmployeeCard: React.FC<Props> = ({
  data: {
    employee: {
      name,
      position,
      department,
      noOfSubEmployees,
      isCollapsed,
      avatarUrl,
    },
    selectedSettings,
  },
}) => {
  return (
    <Card className="m-0" role="button">
      <Card.Body className="p-3">
        <div className={classNames('d-flex', 'align-item-center', 'gap-3')}>
          {selectedSettings.includes('avatar') && (
            <Avatar
              avatarUrl={avatarUrl ?? ''}
              initials={getEmployeeInitialsFromName(name)}
              size="sm"
            />
          )}
          <div className={classNames('d-flex', 'flex-column')}>
            {selectedSettings.includes('name') && <Name>{name}</Name>}
            {selectedSettings.includes('position') && (
              <DetailsText>{position}</DetailsText>
            )}
            {selectedSettings.includes('department') && (
              <DetailsText>{department}</DetailsText>
            )}
            <div
              data-id="no-of-employees-container"
              className="no-of-employees-container align-item-center d-flex mt-2">
              {noOfSubEmployees > 0 ? (
                <>
                  <span>{noOfSubEmployees}</span>
                  <FlairIcon
                    size="xxs"
                    icon={
                      isCollapsed
                        ? 'chevron-down-outline'
                        : 'chevron-up-outline'
                    }
                    className="ms-1 chevron-icon"
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const Name = styled.div({
  color: Theme.color.black,
  fontSize: 10,
  fontWeight: 600,
  width: 90,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const DetailsText = styled.div({
  color: Theme.color.gray2,
  fontSize: 6,
  fontWeight: 600,
});
