import { parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column, SortingRule } from 'react-table';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { Link, Redirect } from '../../../../../Router';
import { Theme } from '../../../../../theme';
import { Maybe } from '../../../../../utils/maybe';
import { recruitmentRoutes } from '../../routes';
import { Params } from '../types';
import { JobCandidate } from './types';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import ChooseTemplatePopup from './ChooseTemplatePopup/ChooseTemplatePopup';
import FlairIcon, {
  FlairIconName,
} from '../../../../../atomic/atoms/FlairIcon';
import { RelatedObjectNames } from '../../../components/Comment/types';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';
import {
  AiProcessingState,
  useJobCandidatesQuery,
} from '../../../__generated__/graphql';
import Hint from '../../../../../components/hint';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Spinner } from 'react-bootstrap';
import { flairAIMatchingScoreColor } from '../CandidateDetails/FlairAI/types';
import { useFlairAiFeature } from '../../../../../context/FeaturesContext';
import { TableContentManual } from '../../../manager/components/TableContentManual';
import { mapSortBy } from './logic';
import { CandidatesFilter } from './CandidatesListFilter/types';
import { TableWithPaginationState } from '../../../../../hooks/useTableWithPagination';
import Loading from './Loading';

type Props = {
  tableState: TableWithPaginationState<any, CandidatesFilter>;
  pageSize: number;
  handleSortByChanged: (sortBy: SortingRule<JobCandidateRow>[]) => void;
  tableStatePageIndex: number;
  handlePageIndexChanged: (pageIndex: number) => void;
  initialSortBy: Array<SortingRule<unknown>>;
};

export type JobCandidateRow = {
  id: string;
  jobId: string;
  name: string;
  score: Maybe<number>;
  overallComment: string;
  funnelStage: string;
  applicationSource: string;
  applicationDate: Date;
  recordId: string;
  commentsCount: number;
  flairAIScore?: number;
  flairAIScoreStatus: AiProcessingState;
  flairAIMatchInfoJSON?: string;
};

const transformData = (
  jobId: string,
  data: ReadonlyArray<JobCandidate>,
): JobCandidateRow[] =>
  data.map((candidate) => ({
    id: candidate.Id,
    jobId,
    name: candidate.flair__Full_Name__c,
    score: candidate.flair__Final_Score__c,
    overallComment: candidate.flair__Overall_Comment__c || '-',
    funnelStage: candidate.flair__Funnel_Stage__c || '-',
    applicationSource: candidate.flair__Application_Source__c || '-',
    applicationDate: parseISO(candidate.CreatedDate),
    recordId: candidate.Id,
    commentsCount: candidate.commentsCount,
    flairAIScore:
      candidate.flair__FlairAI_Total_KR_Matching_Score__c ?? undefined,
    flairAIScoreStatus: candidate.flair__FlairAI_KR_Matching_Status__c,
    flairAIMatchInfoJSON:
      candidate.flair__FlairAI_KR_Matching_Info_JSON__c || undefined,
  }));

type ScoreProp = {
  icon: FlairIconName;
  color: string;
};

const DisplayScore: React.FC<Pick<JobCandidateRow, 'score'>> = ({ score }) => {
  if (score == null) {
    return <span>-</span>;
  }

  const { icon, color }: ScoreProp =
    score >= 50
      ? {
          icon: 'thumbs-up-outline',
          color: Theme.color.teal1,
        }
      : {
          icon: 'thumbs-up-outline',
          color: Theme.color.red1,
        };

  return (
    <span style={{ color }}>
      {score + '%'} <FlairIcon icon={icon} />
    </span>
  );
};

const JobCandidatesTable: React.FC<Props> = ({
  tableState,
  pageSize,
  handleSortByChanged,
  tableStatePageIndex,
  handlePageIndexChanged,
  initialSortBy,
}) => {
  const t = useNamespacedTranslation('recruitment.jobCandidatesPage.table');
  const { jobId } = useParams<Params>();
  const hasFlairAIRecruitingEnabled = useFlairAiFeature();
  const [selectedCandidate, setSelectedCandidate] =
    useState<Maybe<JobCandidateRow>>(null);

  const {
    data: response,
    loading,
    error,
  } = useJobCandidatesQuery({
    variables: {
      id: jobId,
      pagination: {
        offset: tableState.pageIndex * pageSize,
        limit: pageSize,
      },
      filter: {
        searchTerm: tableState.filter.searchTerm,
        stages: tableState.filter.stages,
        source: tableState.filter.source,
      },
      sort: mapSortBy(tableState.sortBy),
    },
  });

  const columns: Column<JobCandidateRow>[] = useMemo(() => {
    const columns: Column<JobCandidateRow>[] = [
      {
        Header: t('headers.name'),
        accessor: 'name',
        Cell: (props) => (
          <Link
            to={recruitmentRoutes.candidateDetails.route}
            candidateId={props.row.original.id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: t('headers.applicationDate'),
        accessor: 'applicationDate',
        sortType: 'datetime',
        Cell: ({ row }) => (
          <FormattedDateTime
            dateTime={row.original.applicationDate}
            format="readable"
          />
        ),
      },
      {
        Header: t('headers.score'),
        accessor: 'score',
        Cell: ({ row }) => <DisplayScore score={row.original.score} />,
      },
      {
        Header: t('headers.flairAIScore'),
        accessor: 'flairAIScore',
        Cell: ({ value, row }) => {
          const rowData = row.original;

          if (rowData.flairAIScoreStatus === AiProcessingState.Processing) {
            return (
              <span className="color-paper-1">
                <Hint
                  id={`${rowData.id}_processing`}
                  text={t('candidateAiScoreProcessing')}>
                  <Spinner animation={'border'} size={'sm'} />
                </Hint>
              </span>
            );
          } else if (rowData.flairAIScoreStatus === AiProcessingState.Error) {
            return (
              <span className="color-red-1">
                <Hint
                  id={`${rowData.id}_error`}
                  text={t('candidateAiScoreError')}
                  flairIconName={'exclamation-triangle'}
                  flairIconSize={'xl'}></Hint>
              </span>
            );
          } else {
            return value || value === 0 ? (
              <span style={{ color: flairAIMatchingScoreColor(value) }}>
                {value}
              </span>
            ) : (
              <span>-</span>
            );
          }
        },
      },
      {
        Header: t('headers.overallComment'),
        accessor: 'overallComment',
      },
      {
        Header: t('headers.comment'),
        accessor: 'recordId',
        Cell: ({ value, row }) => (
          <CommentsPopover
            recordId={value}
            relatedObjectName={RelatedObjectNames.Candidate}
            commentsCount={row.original.commentsCount}
            mode="icon"
          />
        ),
      },
      {
        Header: t('headers.funnelStage'),
        accessor: 'funnelStage',
      },
      {
        Header: t('headers.applicationSource'),
        accessor: 'applicationSource',
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="text-end">
            <DropdownActions id="skill">
              <DropdownActionItem
                onClick={() => setSelectedCandidate(row.original)}>
                {t('evaluateCandidate')}
              </DropdownActionItem>
            </DropdownActions>
          </div>
        ),
      },
    ];

    if (!hasFlairAIRecruitingEnabled) {
      return columns.filter((c) => c.accessor !== 'flairAIScore');
    }

    return columns;
  }, [t, hasFlairAIRecruitingEnabled]);

  const data = response?.hiringManager?.job?.candidates;

  const dataMemo = useMemo(() => {
    if (data) {
      return transformData(jobId, data.items);
    } else {
      return [];
    }
  }, [jobId, data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !response?.hiringManager?.job) {
    return <Redirect to={recruitmentRoutes.jobsList.route} />;
  }

  const itemsCount = data?.paginationInfo.totalCount;

  return (
    <>
      <TableContentManual
        columns={columns}
        data={dataMemo}
        pageSize={pageSize}
        itemsCount={itemsCount}
        onSortByChanged={handleSortByChanged}
        initialPageIndex={tableStatePageIndex}
        onPageIndexChanged={handlePageIndexChanged}
        initialSortBy={initialSortBy}
      />
      {selectedCandidate ? (
        <ChooseTemplatePopup
          selectedCandidate={selectedCandidate}
          onCancel={() => setSelectedCandidate(null)}
        />
      ) : null}
    </>
  );
};

export default JobCandidatesTable;
