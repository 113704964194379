import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { times } from 'lodash';
import styled from '@emotion/styled';

type Props = {
  items?: number;
};

const Container = styled.div({
  marginBottom: '1.5rem',
});

const SkeletonAccordion: React.FC<Props> = ({ items = 1 }) => (
  <Container>
    {times(items, (i) => (
      <Skeleton key={`skeleton-accordion-item-${i}`} height={60} />
    ))}
  </Container>
);

export default SkeletonAccordion;
