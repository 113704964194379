import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { useTranslation } from 'react-i18next';
import { BreakStarted } from '../../../hooks/useCurrentTimeTrackingTimeline';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  step: BreakStarted;
};

const BreakStartedStep: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <ListGroup.Item>
      <Row>
        <Col className="col-auto">
          <div className="avatar avatar-sm">
            <div className="avatar-title fs-3 bg-primary-soft rounded-circle text-primary">
              <FlairIcon icon="arrow-back-outline" className="me-2" />
            </div>
          </div>
        </Col>
        <Col className="ms-n2">
          <h5 className="mb-1">
            {t('timeTracking.current.steps.breakStarted.title')}
          </h5>

          <small className="text-dark">
            <FormattedDateTime dateTime={step.time} format="time" />
          </small>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default BreakStartedStep;
