import { useCallback, useMemo, useState } from 'react';
import { useUpdateEmployeeFeedbackAnswerMutation } from '../../../__generated__/graphql';
import {
  useMutationErrorHandler,
  useSystemErrorMessageHandler,
} from '../../../../../hooks/useMutationErrorHandler';
import { debounce } from 'lodash';
import { EmployeeFeedbackAnswerProps } from '../../../components/EmployeeFeedbackQuestionnaire/types';

type Props = {
  answer: EmployeeFeedbackAnswerProps;
};

export const useUpdateEmployeeFeedbackAnswer = ({ answer }: Props) => {
  const [value, setValue] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [updateAnswer, { loading }] = useUpdateEmployeeFeedbackAnswerMutation();
  const errorHandler = useMutationErrorHandler(useSystemErrorMessageHandler());
  const [isCompleted, setIsCompeted] = useState<boolean>(false);

  const saveChanges = useMemo(
    () =>
      debounce(
        ({ value, notes }: { value: string; notes: string }) =>
          updateAnswer({
            variables: {
              input: {
                id: answer.Id,
                answer: value,
                notes: notes,
              },
            },
          })
            .then(() => setIsCompeted(!!value))
            .catch(errorHandler),
        500,
      ),
    [answer, updateAnswer, errorHandler],
  );

  const onChange = useCallback(
    (value: string) => {
      setValue(value);
      saveChanges({ value, notes });
    },
    [setValue, saveChanges, notes],
  );
  const onChangeNotes = useCallback(
    (notes: string) => {
      setNotes(notes);
      saveChanges({ value, notes });
    },
    [setNotes, saveChanges, value],
  );

  return {
    onChange,
    onChangeNotes,
    saveChanges,
    value,
    notes,
    loading,
    isCompleted,
  };
};
