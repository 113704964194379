import React from 'react';
import { InputGroup } from 'react-bootstrap';
import styled from '@emotion/styled';
import { Theme } from '../../../../../../../theme';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';

type Props = {
  value?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
  isDisabled?: boolean;
};

// TODO this is a new search input for the ticket category step if we will have more like this need to extract.
export const CategorySearch: React.FC<Props> = ({
  value,
  onSearch,
  placeholder,
  isDisabled = false,
}) => {
  return (
    <InputWrapper className="input-group-flush border rounded d-flex gap-3 input-group-merge">
      <InputGroup.Text className="d-flex align-items-center p-0">
        <FlairIcon icon="search-filled-new" />
      </InputGroup.Text>
      <input
        disabled={isDisabled}
        className="list-search form-control"
        type="search"
        onChange={(e) => onSearch(e.currentTarget.value)}
        placeholder={placeholder}
        defaultValue={value ? value : ''}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled(InputGroup)`
  border-color: ${Theme.color.paper1};
  padding-left: 1rem;
`;
