import React from 'react';
import { EmployeeWithAvatarAndPopup } from '../../../components/Employee';

type Props = {
  avatarUrl?: string;
  name: string;
  position?: string;
  department?: string;
};

export const EmployeeCell: React.FC<Props> = ({
  name,
  avatarUrl,
  position,
  department,
}) => {
  const positionInfo = {
    position,
    department,
  };
  return (
    <EmployeeWithAvatarAndPopup
      avatarUrl={avatarUrl}
      name={name}
      popupInfo={positionInfo}
    />
  );
};
