import React from 'react';
import TimesheetDayCommentsPopover from '../../ProjectsTimesheets/TimesheetDayCommentsPopover';

type Props = {
  timeSheetDayId: string;
  commentsCount: number;
};

export const DayCommentsCell: React.FC<Props> = ({
  timeSheetDayId,
  commentsCount,
}) => {
  return (
    <TimesheetDayCommentsPopover
      timesheetDayId={timeSheetDayId}
      commentsCount={commentsCount}
    />
  );
};
