import {
  EmployeeFeedbackState,
  MyPerformanceReviewsDocument,
  ReviewsListDocument,
  useAcceptEmployeeFeedbackOutcomeObjectivesMutation,
  useFinishEmployeeFeedbackOutcomeMutation,
} from '../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { FeedbackProps } from './types';
import { FeedbackStatusTypes } from './Reviews/types';

export const useReviewerSignAndFinish = () => {
  const errorHandler = useMutationErrorHandler();
  const [finishReview, { loading: isFinishing }] =
    useFinishEmployeeFeedbackOutcomeMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [
        {
          query: ReviewsListDocument,
        },
      ],
    });

  const signAndFinish = (outcomeId: string) => {
    return finishReview({
      variables: {
        input: {
          id: outcomeId,
          //TODO: Check this
          goals: [],
          // goals: openObjectives.map((openObjective) => ({
          //   id: openObjective.Id,
          //   outcomeId: outcomeId,
          // })),
        },
      },
    })
      .then(() => {
        return Promise.resolve();
      })
      .catch(errorHandler);
  };

  return [isFinishing, signAndFinish] as const;
};

export const useEmployeeSignAndFinish = () => {
  const errorHandler = useMutationErrorHandler();

  const [acceptGoals, { loading: isFinishing }] =
    useAcceptEmployeeFeedbackOutcomeObjectivesMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [{ query: MyPerformanceReviewsDocument }],
    });

  const signAndFinish = (outcomeId: string) => {
    return acceptGoals({
      variables: {
        input: {
          employeeFeedbackOutcomeId: outcomeId,
        },
      },
    })
      .then(() => {
        return Promise.resolve();
      })
      .catch(errorHandler);
  };

  return [isFinishing, signAndFinish] as const;
};

export const isFeedbackEnded = (feedback: FeedbackProps): boolean => {
  const isStateSubmitted =
    feedback.flair__State__c === EmployeeFeedbackState.Submitted;

  if (
    !feedback.feedbackCycle.flair__Signature_required__c &&
    isStateSubmitted &&
    feedback.outcome.flair__Ended_At__c
  ) {
    return true;
  }

  return !!(
    feedback.outcome.flair__Ended_At__c &&
    feedback.outcome.flair__Accepted_At__c
  );
};

export const isFeedbackInProgress = (feedback: FeedbackProps): boolean => {
  return (
    feedback.flair__Progress__c !== 0 ||
    feedback.employeeFeedback?.flair__Progress__c !== 0
  );
};

export function feedbackStatus(feedback: FeedbackProps): FeedbackStatusTypes {
  const isStateSubmitted =
    feedback.flair__State__c === EmployeeFeedbackState.Submitted;

  if (isFeedbackEnded(feedback)) {
    return FeedbackStatusTypes.Completed;
  } else if (
    feedback.feedbackCycle.flair__Meeting_Required__c &&
    feedback.flair__State__c === EmployeeFeedbackState.Submitted &&
    feedback.employeeFeedback?.flair__State__c ===
      EmployeeFeedbackState.Submitted
  ) {
    return FeedbackStatusTypes.Meeting;
  } else if (
    feedback.feedbackCycle.flair__Without_Reviewer__c &&
    isStateSubmitted
  ) {
    return FeedbackStatusTypes.Completed;
  } else if (isFeedbackInProgress(feedback)) {
    return FeedbackStatusTypes.InProgress;
  } else {
    return FeedbackStatusTypes.NotStarted;
  }
}
