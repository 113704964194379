import classNames from 'classnames';
import React from 'react';

export const SubNav = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={classNames('nav', 'nav-bar', 'flex-column', className)}
  />
);
