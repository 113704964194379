import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ServerError from '../../../../../components/ServerError';
import { useState } from 'react';
import Button from '../../../../../components/button';
import { mapAbsences } from './mapping';
import AbsentTodayModal from './AbsentTodayModal';
import RelativeDateLabel from '../../../../../atomic/molecules/RelativeDateLabel';
import DashboardEmployeeWithAvatar from '../CommonComponents/DashboardEmployeeWithAvatar/DashboardEmployeeWithAvatar';
import { DashboardContext } from '../context';
import WidgetFilter from '../CommonComponents/WidgetFilter';
import { getFilterOption } from '../CommonComponents/WidgetFilter/logic';
import useAbsentTodayData from './logic';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';
import WidgetSpinner from '../CommonComponents/WidgetSpinner';
import EmptyStateContent from '../CommonComponents/EmptyStateContent';
import { WidgetEmployee } from '../CommonComponents/WidgetSections/types';

const i18Path = 'dashboard.absentTodayWidget';

const AbsentTodayWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { data, loading, error, filterBy, handleChangeFilter } =
    useAbsentTodayData();

  if (error) {
    return <ServerError />;
  }

  const employees =
    data?.allAbsences
      .filter((a) => a.employee !== null)
      .map((absence) => mapAbsences(absence, t)) ?? [];

  const headerActions = (
    <WidgetFilter
      selectedFilter={getFilterOption(filterBy)}
      onChange={handleChangeFilter}
    />
  );

  const footerActions = (
    <Button
      className="p-0"
      label={t('seeMore')}
      variant="link"
      onClick={() => setIsModalVisible(true)}
    />
  );

  return (
    <>
      <FlairCard
        cardTitle={t('title')}
        cardContainerClassName="absent-today-widget"
        headerActions={headerActions}
        footerActions={footerActions}>
        <Content employees={employees} loading={loading} />
      </FlairCard>
      {isModalVisible && (
        <AbsentTodayModal
          onClose={() => setIsModalVisible(false)}
          filterBy={filterBy}
        />
      )}
    </>
  );
};

type ContentProps = {
  employees: WidgetEmployee[];
  loading: boolean;
};

const Content: React.FC<ContentProps> = ({ loading, employees }) => {
  const t = useNamespacedTranslation(i18Path);
  const { setSelectedEmployee } = React.useContext(DashboardContext);

  if (loading) {
    return <WidgetSpinner />;
  }

  if (employees.length === 0) {
    return (
      <EmptyStateContent
        title={t('noAbsencesToday')}
        helperText={t('noAbsencesTodayHelperTxt')}
        icon="smile-outline"
      />
    );
  }

  return (
    <>
      {employees.map((i) => {
        return (
          <div className="d-flex justify-content-between" key={i.id}>
            <DashboardEmployeeWithAvatar
              key={i.id}
              employee={{ name: i.name, avatarUrl: i.avatarUrl }}
              additionalInfo={i.additionalInfo}
              onClick={() => setSelectedEmployee(i)}
            />
            <RelativeDateLabel
              endDate={i.date}
              className="relative-date-label"
              displayFormat={FlairDateFormat.DayNumberWithMonth}
            />
          </div>
        );
      })}
    </>
  );
};

export default AbsentTodayWidget;
