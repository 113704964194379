import * as React from 'react';

const NavigateCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M10.5239 5.03138L4.69487 7.60482C4.36768 7.75482 4.47768 8.25076 4.83768 8.25076H7.62518C7.65833 8.25076 7.69013 8.26392 7.71357 8.28737C7.73701 8.31081 7.75018 8.3426 7.75018 8.37576V11.1626C7.75018 11.5226 8.25018 11.6314 8.39956 11.3051L10.9689 5.47638C10.9977 5.4141 11.0067 5.3445 10.9946 5.27696C10.9826 5.20943 10.9501 5.14721 10.9016 5.0987C10.8531 5.05019 10.7909 5.01773 10.7234 5.00569C10.6558 4.99365 10.5862 5.00262 10.5239 5.03138Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 4.41136 4.41136 1.5 8 1.5C11.5886 1.5 14.5 4.41136 14.5 8C14.5 11.5886 11.5886 14.5 8 14.5C4.41136 14.5 1.5 11.5886 1.5 8ZM8 2.5C4.96364 2.5 2.5 4.96364 2.5 8C2.5 11.0364 4.96364 13.5 8 13.5C11.0364 13.5 13.5 11.0364 13.5 8C13.5 4.96364 11.0364 2.5 8 2.5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default NavigateCircleOutlineIcon;
