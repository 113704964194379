import React, { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import { InventoryModalContext } from './InventoryModalContext';

type TStepForm = {
  list: JSX.Element[];
};

export const StepForm = ({ list }: TStepForm) => {
  const { currentStep = 0 } = useContext(InventoryModalContext);

  return (
    <Container fluid>
      <Row className="align-items-center justify-content-center">
        {list[currentStep]}
      </Row>
    </Container>
  );
};
