import { Button, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { ExtractProps } from '../../../../../utils/react';
import ModalSidebar from '../../../components/ModalSidebar';
import { PersonalDocumentInfoFields } from './PersonalDocumentInfoFields';
import CommentListWrapper from '../../../components/Comment/CommentListWrapper/CommentListWrapper';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { DocumentPreview } from '../types';
import { CorporateDocumentInfoFields } from './CorporateDocumentInfoFields';
import { OptionBase } from '../../../../../components/Select/types';

type ModalSidebarProps = Pick<ExtractProps<typeof ModalSidebar>, 'onClose'>;

type Props = ModalSidebarProps & {
  document: DocumentPreview | null;
  isPersonal: boolean;
  visible: boolean;
  isManager?: boolean;
  onDownload: (url?: string) => void;
  onDelete?: () => void;
  documentCategories?: OptionBase[];
};
const tabKeys = {
  INFORMATION: 'information',
  COMMENTS: 'comments',
};
const i18Path = 'documents2.documentInfoSidebar';

export const DocumentInfoSidebar: React.FC<Props> = ({
  onClose,
  visible,
  isPersonal,
  isManager = false,
  onDownload,
  onDelete,
  document,
  documentCategories = [],
}) => {
  const [selectedTabKey, setSelectedTabKey] = useState<string | null>(
    tabKeys.INFORMATION,
  );
  const t = useNamespacedTranslation(i18Path);
  const isSelectedTab = (tabKey: string) => tabKey === selectedTabKey;

  const renderInfoContent = useCallback(() => {
    if (isPersonal) {
      return (
        <>
          <PersonalDocumentInfoFields
            document={document}
            isManager={isManager}
            documentCategories={documentCategories}
            category={document?.category}
            categoryId={document?.categoryId}
            employee={document?.employee!}
            type={document?.type!}
            size={document?.size!}
            status={document?.status!}
            location={document?.location!}
            note={document?.note!}></PersonalDocumentInfoFields>
          <div className="bg-white d-flex flex-column align-items-center gap-3 w-100 position-fixed pe-5 pb-3 bottom-0">
            <Button
              className="w-100"
              onClick={() => {
                onDownload(document?.downloadLink);
              }}
              variant="primary">
              {t('buttons.download')}
            </Button>
            <Button
              onClick={() => (onDelete ? onDelete() : null)}
              className="w-100"
              variant="outline-primary">
              {t('buttons.delete')}
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <CorporateDocumentInfoFields
            employee={document?.employee!}
            size={document?.size!}
            type={document?.type!}
            note={document?.note!}
          />
          <div className="d-flex flex-column align-items-center w-100 position-absolute pe-5 pb-3 bottom-0">
            <Button
              className="w-100"
              onClick={() => {
                onDownload(document?.downloadLink);
              }}
              variant="primary">
              {t('buttons.download')}
            </Button>
          </div>
        </>
      );
    }
  }, [
    isManager,
    document,
    documentCategories,
    isPersonal,
    onDelete,
    onDownload,
    t,
  ]);
  const handleClose = () => {
    setSelectedTabKey(tabKeys.INFORMATION);
    onClose();
  };
  return (
    <ModalSidebar
      show={visible}
      onClose={handleClose}
      header={document?.title}
      content={
        <Tabs
          className="mx-4"
          activeKey={selectedTabKey ?? tabKeys.INFORMATION}
          onSelect={setSelectedTabKey}>
          <Tab
            eventKey={tabKeys.INFORMATION}
            className="m-4 mt-4"
            title={t('tabs.information')}>
            {renderInfoContent()}
          </Tab>
          {isPersonal && (
            <Tab
              eventKey={tabKeys.COMMENTS}
              title={t('tabs.comments')}
              className="h-100 comments-tab">
              {isSelectedTab(tabKeys.COMMENTS) && (
                <CommentListWrapper
                  recordId={document?.recordId!}
                  relatedObjectName={RelatedObjectNames.Document}
                />
              )}
            </Tab>
          )}
        </Tabs>
      }></ModalSidebar>
  );
};
