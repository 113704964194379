import styled from '@emotion/styled';
import React from 'react';
import { Button } from 'react-bootstrap';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { loomBrandingTheme } from './loomBranding';

type Props = {
  id: string;
  disabled?: boolean;
  className?: string;
  isTransparent?: boolean;
  buttonText?: string;
};

const i18prefix = 'components.loomVideo';
const colors = loomBrandingTheme.colors;

export const LoomStartRecordButton: React.FC<Props> = ({
  disabled,
  id,
  className = '',
  isTransparent,
  buttonText = '',
}) => {
  const t = useNamespacedTranslation(i18prefix);
  const StyledElement = isTransparent ? StyledTransparentButton : StyledButton;

  return (
    <StyledElement
      disabled={disabled}
      id={id}
      size="sm"
      className={`text-nowrap d-flex align-items-center justify-content-center ${className}`}>
      <FlairIcon
        icon="loom"
        className="me-2"
        color={isTransparent ? colors.main : colors.white}
      />
      {isTransparent ? buttonText : t('startRecordingBtn')}
    </StyledElement>
  );
};

export const StyledButton = styled(Button)({
  background: colors.main,
  color: colors.white,
  ':hover': {
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${colors.main};`,
    borderColor: colors.main,
    outlineColor: colors.main,
  },
  ':focus': {
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), ${colors.main};`,
    borderColor: colors.main,
    outlineColor: colors.main,
  },
  ':disabled': {
    color: colors.second,
    borderColor: colors.second,
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), ${colors.main};`,
  },
});

export const StyledTransparentButton = styled(Button)({
  backgroundColor: 'transparent',
  color: colors.main,
  border: 'none',
  ':hover': {
    backgroundColor: 'transparent',
    color: colors.second,
  },
  ':focus': {
    backgroundColor: 'transparent',
    color: colors.second,
    boxShadow: 'none',
  },
  ':disabled': {
    backgroundColor: 'transparent',
    color: colors.second,
  },
});
