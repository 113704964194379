import {
  CategoryAccordion_AbsenceFragment,
  CategoryAccordion_AllowanceFragment,
  CategoryAccordion_CarriedOverFragment,
  CategoryAccordion_EmployeeAbsenceCategoryForYearlySummaryFragment,
} from '../../../../__generated__/graphql';
import {
  AbsenceCategorySummary,
  AbsenceInfo,
  AllowanceInfo,
  CarryOver,
} from './types';

export const mapSummary = ({
  currentYearSummary: summary,
}: CategoryAccordion_EmployeeAbsenceCategoryForYearlySummaryFragment): AbsenceCategorySummary => {
  return {
    year: summary.year,
    isUnlimited: summary.isUnlimited,
    hasYearlyBasedAccrualPolicy: summary.hasYearlyBasedAccrualPolicy,
    customYearStartDay: summary.customYearStartDay,
    prevPeriod: {
      accrued: summary.previousPeriod.accrued,
      allowances: mapAllowances(summary.previousPeriod.allowances),
      taken: summary.previousPeriod.taken,
      takenAbsences: summary.previousPeriod.takenAbsences.map(mapAbsence),
    },
    curPeriod: {
      available: summary.currentPeriod.available,
      accrued: summary.currentPeriod.accrued,
      allowances: mapAllowances(summary.currentPeriod.allowances),
      taken: summary.currentPeriod.taken,
      scheduled: summary.currentPeriod.planned,
      carriedOverFromPrevYear:
        summary.currentPeriod.carriedOverFromPrevYear.map(mapCarryOver),
      takenAbsences: summary.currentPeriod.takenAbsences.map(mapAbsence),
      scheduledAbsences: summary.currentPeriod.plannedAbsences.map(mapAbsence),
    },
    nextPeriod: {
      willAccrue: summary.nextPeriod.willAccrue,
      allowances: mapAllowances(summary.nextPeriod.allowances),
      scheduled: summary.nextPeriod.planned,
      upcoming: summary.nextPeriod.willAccrue
        ? summary.nextPeriod.willAccrue - summary.nextPeriod.planned
        : 0,
      scheduledAbsences: summary.nextPeriod.plannedAbsences.map(mapAbsence),
    },
  };
};

const mapCarryOver = (
  carryOver: CategoryAccordion_CarriedOverFragment,
): CarryOver => ({
  amount: carryOver.amount,
  used: carryOver.used,
  lost: carryOver.lost,
  expired: carryOver.expired,
  expireOn: carryOver.expireOn,
});

const mapAllowances = (
  allowances: readonly CategoryAccordion_AllowanceFragment[] | null,
): AllowanceInfo[] | null => {
  if (!allowances) {
    return null;
  }
  return mergeAllowancesByAccrualPolicyId(
    allowances.filter((x) => !!x.accrued).map(mapAllowance),
  );
};

const mergeAllowancesByAccrualPolicyId = (
  src: AllowanceInfo[],
): AllowanceInfo[] => {
  const mergedAccrualPoliciesMap = new Map<string, AllowanceInfo>();
  return src.reduce((acc, cur) => {
    const { accrualPolicyId } = cur;
    if (!accrualPolicyId) {
      acc.push(cur);
      return acc;
    }
    if (!mergedAccrualPoliciesMap.has(accrualPolicyId)) {
      mergedAccrualPoliciesMap.set(accrualPolicyId, cur);
      acc.push(cur);
    } else {
      mergedAccrualPoliciesMap.get(accrualPolicyId)!.amount += cur.amount;
    }
    return acc;
  }, new Array<AllowanceInfo>());
};

const mapAllowance = (
  src: CategoryAccordion_AllowanceFragment,
): AllowanceInfo => ({
  absenceAllowanceId: src.absenceAllowanceId ?? 'system',
  accrualPolicyId: src.accrualPolicyId,
  amount: src.accrued,
  title: src.title,
});

const mapAbsence = (
  absence: CategoryAccordion_AbsenceFragment,
): AbsenceInfo => ({
  absenceId: absence.Id,
  startDay: absence.flair__Start_Date__c,
  endDay: absence.flair__End_Date__c,
  workindDays: absence.flair__Working_Amount__c,
});
