import moment from 'moment';
import {
  Feedback,
  FeedbackCycle,
  FeedbackMeeting,
  FeedbackOutcome,
  FeedbackProps,
  MeetingInfo,
} from './types';

export const mapFeedbacks = (
  feedbacks: ReadonlyArray<FeedbackProps>,
): Feedback[] =>
  feedbacks.map((feedback) => ({
    id: feedback.Id,
    state: feedback.flair__State__c,
    feedbackCycle: mapFeedbackCycle(feedback.feedbackCycle),
    outcome: mapOutcome(feedback.outcome),
  }));

const mapOutcome = (outcome: FeedbackProps['outcome']): FeedbackOutcome => ({
  id: outcome.Id,
  acceptedAt: outcome.flair__Accepted_At__c
    ? new Date(outcome.flair__Accepted_At__c)
    : null,
  endedAt: outcome.flair__Ended_At__c
    ? new Date(outcome.flair__Ended_At__c)
    : null,
  meeting: outcome.meeting ? mapMeeting(outcome.meeting) : null,
});

const isMeetingUpcoming = (meeting: FeedbackMeeting | null) =>
  !meeting || (meeting.startDate && moment(meeting.startDate).isAfter());

export const onGoingFeedback = (feedback: Feedback) =>
  (!feedback.outcome.acceptedAt || !feedback.outcome.endedAt) &&
  isMeetingUpcoming(feedback.outcome.meeting);

const mapMeeting = (
  meeting: FeedbackProps['outcome']['meeting'],
): FeedbackMeeting | null =>
  meeting
    ? {
        id: meeting.Id,
        startDate: meeting.flair__Start_Datetime__c
          ? new Date(meeting.flair__Start_Datetime__c)
          : null,
      }
    : null;

const mapFeedbackCycle = (
  feedbackCycle: FeedbackProps['feedbackCycle'],
): FeedbackCycle => ({ id: feedbackCycle.Id, name: feedbackCycle.Name });

export const mapMeetings = (feedbacks: Feedback[]): MeetingInfo[] =>
  feedbacks
    .map((feedback) =>
      feedback.outcome.meeting
        ? {
            cycleName: feedback.feedbackCycle.name,
            meetingId: feedback.outcome.meeting.id,
            startDate: feedback.outcome.meeting.startDate,
          }
        : null,
    )
    .filter((meeting): meeting is MeetingInfo => !!meeting);
