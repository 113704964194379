import { t } from 'i18next';
import { NotificationTypes } from 'server/src/shared/NotificationTypes';

import {
  NotificationIconType,
  NotificationItemData,
  NotificationQueryItem,
} from '../types';
import { routes } from '../../../routes';
import {
  TimeEntryChangeRequestNotificationJsonBody,
  safeJsonParse,
} from './NotificationJSONBodyParser';
import { formatTimeEntryChangeRequestDateRange } from './DataFormatters';

const i18prefix = 'notifications.timeEntry';

const iconsByType = new Map<string, NotificationIconType>([
  [NotificationTypes.TIME_ENTRY_CHANGES_APPROVED, 'checkmark-outline'],
  [NotificationTypes.TIME_ENTRY_CHANGES_CREATED, 'stopwatch-outline'],
  [NotificationTypes.TIME_ENTRY_CHANGES_REJECTED, 'close-outline'],
]);

const descriptionsByType = new Map([
  [
    NotificationTypes.TIME_ENTRY_CHANGES_APPROVED,
    t(`${i18prefix}.changeApproved`),
  ],
  [
    NotificationTypes.TIME_ENTRY_CHANGES_CREATED,
    t(`${i18prefix}.changeCreated`),
  ],
  [
    NotificationTypes.TIME_ENTRY_CHANGES_REJECTED,
    t(`${i18prefix}.changeRejected`),
  ],
]);

export const mapTimeEntryChangeRequestNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };
  data.icon = iconsByType.get(src.flair__Type__c);

  if (
    src.renderInfo.__typename === 'TimeEntryChangeRequestNotificationRenderInfo'
  ) {
    // Check required fields. If not present, the notification won't be rendered.
    if (!src.renderInfo.timeEntryChangeRequest?.employee?.Id) {
      return null;
    }
    data.url = routes.timeTracking.route.create({});
    if (src.flair__Body__c) {
      const body = safeJsonParse<TimeEntryChangeRequestNotificationJsonBody>(
        src.flair__Body__c,
      );
      if (body && body.endDatetime && body.startDatetime) {
        const startDatetime = new Date(body.startDatetime);
        const endDatetime = new Date(body.endDatetime);
        data.urlLabel =
          formatTimeEntryChangeRequestDateRange(startDatetime, endDatetime) ||
          '';
      }
    }

    switch (src.flair__Type__c) {
      case NotificationTypes.TIME_ENTRY_CHANGES_CREATED:
        data.title = t(`${i18prefix}.notificationTitle`);
        data.url = routes.pendingTimeEntryChangeRequests.route.create({});
        if (src.renderInfo.timeEntryChangeRequest?.employee?.Name) {
          data.description =
            src.renderInfo.timeEntryChangeRequest.employee.Name;
        }
        break;
      default:
        data.description = descriptionsByType.get(src.flair__Type__c) || '';
    }
  }

  return data;
};
