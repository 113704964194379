import styled from '@emotion/styled';
import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { DropdownActionItem } from '../../../../../../atomic/molecules/DropdownActionItem';
import { PolarityIcon } from '../../../../components/PolarityIcon';
import {
  ManagerEmployeeNote,
  ManagerEmployeeNotePolarity,
  useEmployeePageDeleteEmployeeNoteMutation,
} from '../../../../__generated__/graphql';
import { useUserInfo } from '../../../../context/UserInfo';
import { Reference } from '@apollo/client';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';

type ManagerEmployeeNoteProps = Pick<
  ManagerEmployeeNote,
  'Id' | 'flair__Polarity__c' | 'text' | 'CreatedDate'
>;

type Props = {
  employeeId: string;
  employeeNote: ManagerEmployeeNoteProps;
};

const CardHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
});

const CardTitle = styled(Card.Title)({
  marginBottom: '0',
});

const CardActions = styled.div({
  display: 'flex',
  alignItems: 'center',
  '> *': {
    marginLeft: '0.75rem',
  },
});

type ActionsProps = {
  employeeId: string;
  noteId: string;
};

const Actions: React.FC<ActionsProps> = ({ employeeId, noteId }) => {
  const { t } = useTranslation();

  const { id: meId } = useUserInfo();

  const [deleteNote, { loading: deleting }] =
    useEmployeePageDeleteEmployeeNoteMutation({
      optimisticResponse: ({ id }) => {
        return {
          employeeData: {
            deleteEmployeeNote: {
              recordId: id,
            },
          },
        };
      },
      update: (cache, response) => {
        if (!response.data) {
          return;
        }
        const managerCacheId = cache.identify({
          __typename: 'Manager',
          Id: meId,
        });
        const idToRemove = noteId;

        cache.modify({
          id: managerCacheId,
          fields: {
            employeeNotes(existent: readonly Reference[], { readField }) {
              return existent.filter(
                (noteRef) => idToRemove !== readField('Id', noteRef),
              );
            },
          },
        });
      },
    });
  const errorHandler = useMutationErrorHandler();

  const handleDelete = async () =>
    await deleteNote({ variables: { id: noteId } }).catch(errorHandler);

  return deleting ? (
    <Spinner animation="border" size="sm" className="text-muted" />
  ) : (
    <DropdownActions id={`dropdown-employee-note-card-${noteId}`}>
      <DropdownActionItem onClick={handleDelete}>
        {t('managerEmployeeNotes.card.actions.delete')}
      </DropdownActionItem>
    </DropdownActions>
  );
};

const polarityIconClassName = (polarity: ManagerEmployeeNotePolarity) => {
  switch (polarity) {
    case ManagerEmployeeNotePolarity.Positive: {
      return 'text-success';
    }
    case ManagerEmployeeNotePolarity.Neutral: {
      return 'text-warning';
    }
    case ManagerEmployeeNotePolarity.Negative: {
      return 'text-danger';
    }
    case ManagerEmployeeNotePolarity.Unknown: {
      return undefined;
    }
  }
};

const Text = styled(Card.Text)({ whiteSpace: 'pre-wrap' });

export const EmployeeNoteCard: React.FC<Props> = ({
  employeeId,
  employeeNote,
}) => {
  return (
    <Card>
      <Card.Body>
        <CardHeaderContainer>
          <CardTitle as="h4">
            <FormattedDate day={employeeNote.CreatedDate} format="short" />
          </CardTitle>
          <CardActions>
            <PolarityIcon
              polarity={employeeNote.flair__Polarity__c}
              className={polarityIconClassName(employeeNote.flair__Polarity__c)}
            />
            <Actions employeeId={employeeId} noteId={employeeNote.Id} />
          </CardActions>
        </CardHeaderContainer>
        <Text>{employeeNote.text}</Text>
      </Card.Body>
    </Card>
  );
};
