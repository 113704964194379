import { Moment } from 'moment';
import {
  Project,
  ProjectDayTimeTrackedToSave,
  ProjectStatus,
  ProjectTimeTrackingMap,
} from '../types';
import { minutesToHoursAndMinutes } from '../../../../../utils/time';
import i18next from '../../../../../initializers/i18next';
import { i18prefix } from '../helpers';

export const UNIQUE_ID_PREFFIX = 'project_time_entry_temp_';

export const removeEmptyTimeEntries = (
  projectTimeEntries: ProjectDayTimeTrackedToSave[],
): ProjectDayTimeTrackedToSave[] =>
  projectTimeEntries.filter(
    (projectTimeEntry) => projectTimeEntry.id || projectTimeEntry.minutes,
  );

export const getProjectStatus = (
  project: Project,
  currDate: Moment,
): ProjectStatus => {
  if (project.startDate && currDate.isBefore(project.startDate, 'day')) {
    return 'NOT_STARTED';
  } else if (project.endDate && currDate.isAfter(project.endDate, 'day')) {
    return 'FINISHED';
  }

  return 'ACTIVE';
};

export const collectTimesTracked = (
  projectTimeTrackingsMap: ProjectTimeTrackingMap,
) =>
  Object.values(projectTimeTrackingsMap || {})
    .flat()
    .map((projectDay) => projectDay.timesTracked)
    .flat();

export const minToHoursInput = (minutes: number): number => {
  return +(minutes / 60).toFixed(2);
};

export const formatHoursAndMinutes = (minutesToFormat: number) => {
  const [hours, minutes] = minutesToHoursAndMinutes(minutesToFormat);
  return i18next.t(`${i18prefix}.sidebar.timeTrackedFormat`, {
    hours,
    minutes,
  });
};

export const convertToHours = (minutes?: number): number | undefined => {
  if (minutes === undefined || minutes === null || minutes < 0) {
    return undefined;
  }
  return minToHoursInput(minutes);
};

export const convertToMinutes = (hours?: number): number | undefined => {
  if (hours === undefined || hours === null || isNaN(hours) || hours < 0) {
    return undefined;
  }
  return Math.round(hours * 60);
};

export const hoursInputStep = '0.01';
