import React from 'react';
import { useMyEnpsSurveysQuery } from '../../../__generated__/graphql';
import { EnpsSurvey } from './EnpsSurvey';
import Loading from './Loading';

const EnpsSurveys: React.FC = () => {
  const { data, loading, error } = useMyEnpsSurveysQuery();

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    // todo: This is quick fix because we have "You do not have access to the Apex class named: ApiEnpsSurveysController". Revert it later
    return null;
  }

  const surveys = data.me.enpsSurveys;
  if (surveys.length === 0) {
    return null;
  }

  return (
    <>
      {surveys.map((survey) =>
        survey.cycles.map((cycle) => (
          <EnpsSurvey
            key={survey.id + cycle.id}
            survey={survey}
            cycle={cycle}
          />
        )),
      )}
    </>
  );
};

export default EnpsSurveys;
