import React from 'react';
import { formatDate } from '../../../utils/dateUtils';
import { useNamespacedTranslation } from '../../../hooks/useNamespacedTranslation';
import { FlairDateFormat, FormattedDateProps } from './types';

const FlairFormattedDate: React.FC<FormattedDateProps> = ({
  day,
  format = FlairDateFormat.NumericDate,
  className,
}) => {
  const t = useNamespacedTranslation('date');

  //Translation is being used to consider locale in formatting dates
  const formattedDateStr = formatDate(day, t(format));
  return <span className={className}>{formattedDateStr}</span>;
};

export default FlairFormattedDate;
