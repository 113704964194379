import React, { useState } from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';
import { DocumentPreview, emptyCorporateDocumentFilter, File } from './types';
import { routes as mainRoutes } from '../../routes';
import { Card } from 'react-bootstrap';
import { CorporateDocumentsTable } from './Tables/CorporateDocumentsTable';
import { useCorporateDocumentsQuery } from '../../__generated__/graphql';
import { SkeletonTable } from '../../components/Skeleton/Table';
import ServerError from '../../../../components/ServerError';
import { mapCorporateDocumentPreview, mapCorporateDocuments } from './mappings';
import { RowFileTypeEnums } from './Enums/RowFileTypeEnums';
import { DocumentPreviewModal } from './DocumentInfo/DocumentPreviewModal';
import { DocumentInfoSidebar } from './DocumentInfo/DocumentInfoSidebar';
import {
  applyCorporateDocumentsFilter,
  hasCorporateDocumentFilter,
} from './Fliters/filters';
import GlobalTableFilter from '../../components/GlobalTableFilter';

const i18Path = 'documents2.corporate';
export const CorporateDocuments: React.FC = () => {
  const { data, loading, error } = useCorporateDocumentsQuery();
  const [row, setDocumentRow] = useState<DocumentPreview | null>(null);
  const [previewVisible, setDocumentPreview] = useState<boolean>(false);
  const [sidebarVisible, setDocumentSidebar] = useState<boolean>(false);

  const t = useNamespacedTranslation(i18Path);
  const [filter, setFilter] = useFlairPersistentFilter({
    defaultFilter: emptyCorporateDocumentFilter,
    storageKey: 'corporate_document_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.documentsCompany.route.withQueryParams(queryParams).create({}),
  });

  if (error) {
    return <ServerError />;
  }

  const onClick = (file?: File) => {
    const document = allDocuments?.find((d) => d.id === file?.documentId);
    if (file && document) {
      switch (file.type) {
        case RowFileTypeEnums.Pdf:
        case RowFileTypeEnums.Png:
        case RowFileTypeEnums.Jpg:
        case RowFileTypeEnums.Jpeg:
        case RowFileTypeEnums.Gif:
          setDocumentRow(mapCorporateDocumentPreview(document, file));
          setDocumentPreview(true);
          break;
        default:
          setDocumentRow(mapCorporateDocumentPreview(document, file));
          setDocumentSidebar(true);
      }
    }
  };

  const handleDownload = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handlePreviewClose = () => {
    setDocumentPreview(false);
  };

  const handleSideBarClose = () => {
    setDocumentSidebar(false);
  };

  const onSearch = (val: string) => setFilter({ ...filter, documentName: val });

  const allDocuments = data ? mapCorporateDocuments(data) : [];
  const renderContent = () => {
    if (loading || !data) {
      return <Loading />;
    }
    const documents = applyCorporateDocumentsFilter(filter, allDocuments);
    return (
      <CorporateDocumentsTable
        hasCorporateDocumentFilter={hasCorporateDocumentFilter(filter)}
        documents={documents}
        onClick={onClick}></CorporateDocumentsTable>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="h-auto">
          <GlobalTableFilter
            placeholder={t('search')}
            onFilterChange={onSearch}
          />
        </Card.Header>
        <Card.Header>
          <div className="d-flex gap-3  align-items-center small">
            <div className="d-flex flex-wrap align-items-center">
              <span className="d-none mr-2 d-md-block">{t('table.home')}</span>
            </div>
          </div>
        </Card.Header>
        {renderContent()}
      </Card>

      <DocumentPreviewModal
        visible={previewVisible}
        isPersonal={false}
        onDownload={handleDownload}
        document={row}
        onClose={handlePreviewClose}></DocumentPreviewModal>
      <DocumentInfoSidebar
        visible={sidebarVisible}
        isPersonal={false}
        onDownload={handleDownload}
        document={row}
        onClose={() => {
          handleSideBarClose();
        }}></DocumentInfoSidebar>
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={3} rows={5} />;
};
