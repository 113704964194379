import moment, { Moment } from 'moment';
import { DateInterval } from '../../../../utils/dateInterval';

import {
  calculateShiftState,
  getEmployeeShifts,
  mapInterval,
  OpenShiftOnlyRequiredFields,
} from '../../components/Shifts/shiftsMappings';
import {
  OpenShiftNotAvailableReason,
  OpenShiftState,
} from '../../components/Shifts/Common/types';
import { CalendarQueryQuery } from '../../__generated__/graphql';

export type OpenShiftUI = DateInterval & {
  id: string;
  assignmentRequestId: string | null;
  totalBreakPeriod: number;
  state: OpenShiftState;
  notAvailableReason?: OpenShiftNotAvailableReason;
};

export const getOpenShifts = (queryData: CalendarQueryQuery): OpenShiftUI[] => {
  const myEmployeeShifts = getEmployeeShifts(queryData.me.Id, queryData);
  const dayStart = moment().startOf('day');
  const filteredOpenShifts = queryData.me.openShifts.filter((x) =>
    moment(x.flair__Start_Datetime__c).isSameOrAfter(dayStart),
  );
  return filteredOpenShifts.map((x) =>
    mapOpenShift(x, queryData.me.Id, myEmployeeShifts),
  );
};

type OpenShiftUINullable = OpenShiftUI | null;
// used for improve shifts layout
// generate fixed columns matrix (kind of):
// Day1   Day2   Day3
// null   shift  shift
// null   shift  null
export const generateOpenShiftsMatrix = (
  days: Moment[],
  openShifts: OpenShiftUI[],
): OpenShiftUINullable[][] => {
  const shiftsPool = [...openShifts];
  const daysCount = days.length;
  const result: OpenShiftUINullable[][] = [];
  while (shiftsPool.length) {
    const shiftsRow: OpenShiftUINullable[] = [];
    for (let i = 0; i < daysCount; i++) {
      const foundIndex = shiftsPool.findIndex((shift) =>
        isThatDayShift(days[i], shift),
      );
      if (foundIndex !== -1) {
        shiftsRow.push(shiftsPool[foundIndex]);
        shiftsPool.splice(foundIndex, 1);
      } else {
        shiftsRow.push(null);
      }
    }
    result.push(shiftsRow);
  }
  return result;
};

const mapOpenShift = (
  src: OpenShiftOnlyRequiredFields,
  meId: string,
  employeeShifts: DateInterval[],
): OpenShiftUI => {
  const openShiftInterval = mapInterval(src);
  let { state, notAvailableReason } = calculateShiftState(
    src,
    meId,
    employeeShifts,
  );
  return {
    ...openShiftInterval,
    id: src.Id,
    assignmentRequestId:
      state === 'requested'
        ? src.AssignmentRequests.find(
            (x) =>
              x.flair__Employee__c === meId &&
              x.flair__Confirmed_At__c === null,
          )!.Id
        : null,
    totalBreakPeriod: src.flair__Total_Break_Period_in_Minutes__c,
    state,
    notAvailableReason,
  };
};

const isThatDayShift = (day: Moment, shift: OpenShiftUI): boolean =>
  shift.start.isSame(day, 'day');
