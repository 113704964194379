import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../../components/button';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

type FolderModalProps = {
  visible: boolean;
  onClose: () => void;
};

const i18Path = 'timeTracking.warningModal';

export const WarningModal: React.FC<FolderModalProps> = ({
  visible,
  onClose,
}) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      dialogClassName="break-modal-440">
      <Modal.Header closeButton className="card-header border-0">
        <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
          <FlairIcon
            size="xxl"
            icon="alert-circle-outline"
            className="text-warning"
          />
          <h2 className="mt-2 card-header-title text-center">
            {t('restrictionTitle')}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body className={'pt-4'}>
        <div>
          <div>
            <p className="text-center">{t('restrictionDescription')}</p>
          </div>
          <div className={'d-flex justify-content-center gap-3'}>
            <Button
              variant="outline-primary"
              label={t('close')}
              onClick={() => onClose()}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
