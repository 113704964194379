import React from 'react';
import DayHeader from '../../../../components/TimeSheet/DayCard/DayHeader';

import { Project, ProjectDayTimeTracked, Timesheet } from '../../types';
import DayTimeEntries from './DayTimeEntries/DayTimeEntries';
import { useTimeSheetDays } from './useTimeSheetDays';
import { CostCenter } from '../../../../__generated__/graphql';

type Props = {
  timesheet: Timesheet;
  projects: Project[];
  costCenters: ReadonlyArray<CostCenter>;
};

export const ProjectTimesheetByDays: React.FC<Props> = ({
  timesheet,
  projects,
  costCenters,
}) => {
  const days = useTimeSheetDays(timesheet, projects);

  return (
    <>
      {days.map((day) => {
        return (
          <div key={day.day.toISOString()}>
            {/*The header above the card that shows the day, the comments and loom*/}
            <DayHeader
              dayInfo={day}
              dayMinutes={getTotalMinutes(day.timesTracked)}
              absences={day.absences}
              holidays={day.holidays}
              calendarPeriods={day.calendarPeriods}
              commentsCount={day.commentsCount}
            />
            <DayTimeEntries
              day={day}
              timesheet={timesheet}
              costCenters={costCenters}
            />
          </div>
        );
      })}
    </>
  );
};

function getTotalMinutes(timesTracked: ProjectDayTimeTracked[]) {
  return timesTracked.reduce(
    (totalMinutes, timeTracked) => totalMinutes + (timeTracked.minutes || 0),
    0,
  );
}
