import * as React from 'react';
import { ReactNode, useState } from 'react';
import CommentListWrapper from '../CommentListWrapper/CommentListWrapper';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Placement } from 'react-bootstrap/types';
import { RelatedObjectName } from '../types';
import { Popover } from '../../../../../components/popover';
import styled from '@emotion/styled';
import { InnerModalContext } from '../../../../../context/InnerModalContext';
import { DataSingleWithLoadingState } from '../../../../../utils/dataWithLoadingState';

export type Props = {
  recordId: string;
  popupPlacement?: Placement;
  children: ReactNode;
  headerCommentsCount: DataSingleWithLoadingState<number>;
  relatedObjectName: RelatedObjectName;
  // Bad practice (don't copy/pase it)
  // It is not good approach, better to use apollo reactive queries
  onCommentsChanged?: () => void;
  visible: boolean;
  visibleChanged: (visible: boolean, recordId: string) => void;
  ref?: React.Ref<HTMLDivElement>;
};

const i18Path = 'comments';

const CommentsPopoverDumb: React.FC<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const {
    recordId,
    relatedObjectName,
    popupPlacement = 'auto',
    headerCommentsCount,
    onCommentsChanged,
    visible,
    visibleChanged,
    children,
  } = props;

  const t = useNamespacedTranslation(i18Path);

  const [isInnerModalOpen, setIsInnerModalOpen] = useState<boolean>(false);

  // Please do not add any tag here, it could break comments scrolling (or use forwardRef)
  const popoverContent = (
    <CommentListWrapper
      recordId={recordId}
      relatedObjectName={relatedObjectName}
      onCommentsChanged={onCommentsChanged}
    />
  );

  const getHeaderCommentsCountText = () => {
    if (typeof headerCommentsCount === 'number') {
      return t('commentsCount', { count: headerCommentsCount });
    }
    return t('gettingComments');
  };

  return (
    <InnerModalContext.Provider
      value={{ isInnerModalOpen, setIsInnerModalOpen }}>
      <StyledPopover
        className="p-0"
        headerText={getHeaderCommentsCountText()}
        popoverContent={popoverContent}
        show={visible}
        onToggle={() => visibleChanged(!visible, recordId)}
        rootClose={!isInnerModalOpen}
        placement={popupPlacement}>
        <div ref={ref} role="button">
          {children}
        </div>
      </StyledPopover>
    </InnerModalContext.Provider>
  );
});

const StyledPopover = styled(Popover)({
  height: '31.25rem',
  width: '24.375rem',
});

export default CommentsPopoverDumb;
