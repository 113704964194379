import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AbsenceType } from '../../../__generated__/graphql';
import AbsenceAmount from '../../../pages/Absences/components/AbsenceAmount';
import { WithdrawalAllowance } from './types';

type WithdrawalAllowancesCardTableProps = {
  allowances: WithdrawalAllowance[];
  absenceType: AbsenceType;
};

export const WithdrawalAllowancesCardTable: React.FC<
  WithdrawalAllowancesCardTableProps
> = ({ allowances, absenceType }) => {
  const { t } = useTranslation();

  return (
    <Card className="mb-0 shadow-none">
      <Table className="card-table" size="xs" hover responsive>
        <thead>
          <tr>
            <th>{t('requestAbsence.requestingAmount.allowances.period')}</th>
            <th className="text-end">
              {t('requestAbsence.requestingAmount.allowances.before')}
            </th>
            <th className="text-end">
              {t('requestAbsence.requestingAmount.allowances.requesting')}
            </th>
            <th className="text-end">
              {t('requestAbsence.requestingAmount.allowances.after')}
            </th>
          </tr>
        </thead>
        <tbody>
          {allowances.map((a) => (
            <tr
              key={a.period}
              className={a.after < 0 ? 'text-danger' : undefined}>
              <td>{a.period}</td>
              <td className="text-end">
                <AbsenceAmount
                  amount={a.before}
                  type={absenceType}
                  variant="normal"
                />
              </td>
              <td className="text-end">
                <AbsenceAmount
                  amount={a.requesting}
                  type={absenceType}
                  variant="normal"
                />
              </td>
              <td className="text-end">
                <AbsenceAmount
                  amount={a.after}
                  type={absenceType}
                  variant="normal"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
