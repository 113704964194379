import * as React from 'react';
import { intervalToDuration } from 'date-fns';
import { useNow } from '../../hooks/useNow';
import { useTranslation } from 'react-i18next';

type Props = {
  date: Date;
  className?: string;
};

const i18Path = 'date.unites';

export const TimeSince: React.FC<Props> = ({ date, className }) => {
  const { t } = useTranslation();
  const { now } = useNow(1000 * 60);

  let humanizeString = '';

  const duration = intervalToDuration({ start: date, end: now });

  if (duration.years) {
    humanizeString = `${t(`${i18Path}.year`, { count: duration.years })}`;
  } else if (duration.months) {
    humanizeString = `${t(`${i18Path}.month`, { count: duration.months })}`;
  } else if (duration.days) {
    humanizeString = `${t(`${i18Path}.day`, { count: duration.days })}`;
  } else if (duration.minutes) {
    const hours = duration.hours ? `${duration.hours}h` : '';
    const minutes = duration.minutes ? `${duration.minutes}m` : '';
    humanizeString = `${hours}${hours ? ' ' : ''}${minutes}`;
  } else {
    return <span className={className}>{t(`${i18Path}.justNow`)}</span>;
  }

  return (
    <span className={className}>{`${humanizeString} ${t(
      `${i18Path}.ago`,
    )}`}</span>
  );
};
