import React, { useEffect } from 'react';
import { LoomVideoSdk } from '@loomhq/record-sdk';
import { RecordedLoomVideo } from './types';
import { useLoomContext } from './LoomContext';
import i18next from 'i18next';
import { LoomStartRecordButton } from './LoomStartRecordButton';
import { loomBrandingTheme } from './loomBranding';
import styled from '@emotion/styled';
import { Theme } from '../../../../theme';
import { Spinner } from 'react-bootstrap';

type Props = {
  text: string;
  onVideoRecorded: (loomVideo: RecordedLoomVideo) => void;
  className?: string;
  isTransparent?: boolean;
  id?: string; // used to identify the button in case there are multiple buttons in the same page
  buttonText?: string;
};

const i18prefix = 'components.loomVideo';
export const BUTTON_ID = 'loom-record-button';

export const LoomVideoRecord: React.FC<Props> = ({
  text,
  onVideoRecorded,
  className = '',
  isTransparent = false,
  id = '',
  buttonText,
}) => {
  const { loomState } = useLoomContext();
  const WrapperElement = isTransparent ? 'div' : Container;

  useEffect(() => {
    if (!loomState || !loomState.supported) {
      return;
    }
    const button = document.getElementById(BUTTON_ID + id);
    if (!button) {
      return;
    }

    const sdkButton = loomState.configureButton({ element: button });
    sdkButton.on('insert-click', async (video: LoomVideoSdk) => {
      onVideoRecorded({
        loomPublicAppId: loomState.publicAppId,
        loomId: video.id,
        embedUrl: video.embedUrl,
        sharedUrl: video.sharedUrl,
        width: video.width,
        height: video.height,
      });
    });
  }, [loomState, onVideoRecorded, id]);

  return (
    <WrapperElement
      className={`d-flex align-items-center justify-content-between p-2 ps-3 ${className}`}>
      {loomState === undefined ? (
        <Spinner
          animation="border"
          size="sm"
          style={{ color: loomBrandingTheme.colors.main }}
        />
      ) : (
        <>
          <small>
            {loomState.supported
              ? text
              : getUnsupportReasonText(loomState.unsupportReason)}
          </small>

          <LoomStartRecordButton
            disabled={!loomState.supported}
            id={BUTTON_ID + id}
            className={isTransparent ? 'px-0 py-0' : ''}
            isTransparent={isTransparent}
            buttonText={buttonText}
          />
        </>
      )}
    </WrapperElement>
  );
};

const getUnsupportReasonText = (reason: string): string => {
  return i18next.t(`${i18prefix}.unsupportedReasons.${reason}`);
};

export const Container = styled.div({
  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), ${loomBrandingTheme.colors.main};`,
  borderRadius: Theme.border.radius,
  color: loomBrandingTheme.colors.main,
});
