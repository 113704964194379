import { PureQueryOptions } from '@apollo/client';
import React from 'react';
import { useOpenShiftsActions } from '../../components/Shifts/useOpenShiftsActions';
import OpenShiftBlock from './OpenShiftBlock';
import { OpenShiftUI } from './openShiftsLogic';

type Props = {
  shift: OpenShiftUI;
  refetchQueries?: Array<PureQueryOptions>;
};

const OpenShiftBlockSmart: React.FC<Props> = ({ shift, refetchQueries }) => {
  const [createAssignmentRequest, deleteAssignmentRequest, isRequesting] =
    useOpenShiftsActions(refetchQueries);

  return (
    <OpenShiftBlock
      shift={shift}
      onRequestShift={createAssignmentRequest}
      onDeleteAssignmentRequest={deleteAssignmentRequest}
      isRequesting={isRequesting}></OpenShiftBlock>
  );
};

export default OpenShiftBlockSmart;
