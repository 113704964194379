import { addMinutes, differenceInMinutes } from 'date-fns';

export type Gap = {
  left: number;
  right: number;
  startOffset: number;
  duration: number;
};

export type DateInterval = { start: Date; end: Date };

export const getIntervalInMiddle = (
  interval: DateInterval,
  durationMinutes: number,
): DateInterval | null => {
  const intervalDuration = differenceInMinutes(interval.end, interval.start);
  if (intervalDuration < durationMinutes) {
    return null;
  }

  const minutesOffset = Math.floor((intervalDuration - durationMinutes) / 2);
  const start = addMinutes(interval.start, minutesOffset);

  return {
    start,
    end: addMinutes(start, durationMinutes),
  };
};

export const durationMinutes = (interval: DateInterval): number =>
  differenceInMinutes(interval.end, interval.start);

// get gaps for each intervals inside {start, end}

export const getGapMinutes = (
  start: Date,
  end: Date,
  intervals: DateInterval[],
): Gap[] => {
  const result: Gap[] = new Array<Gap>(intervals.length);
  for (let i = 0; i < intervals.length; i++) {
    if (intervals[i].end > end || intervals[i].start < start) {
      throw new Error('All breaks must be inside start and end');
    }
    const prevIntervalEndDate = i > 0 ? intervals[i - 1].end : start;
    result[i] = {
      startOffset: differenceInMinutes(intervals[i].start, start),
      duration: differenceInMinutes(intervals[i].end, intervals[i].start),
      left: differenceInMinutes(intervals[i].start, prevIntervalEndDate),
      right:
        i === intervals.length - 1
          ? differenceInMinutes(end, intervals[i].end)
          : differenceInMinutes(intervals[i + 1].start, intervals[i].end),
    };
  }
  return result;
};
