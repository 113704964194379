import styled from '@emotion/styled';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../components/button';
import '../BreakModal.css';
import { AllowToEndBreakResultFail } from '../shared';
import i18next from 'i18next';
import { differenceInMinutes } from 'date-fns';
import { mapBreakScheduledToInterval } from '../shared/timeTrackingHelper';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: boolean;
  now: Date;
  allowEndBreakResult: AllowToEndBreakResultFail;
  onClose: () => void;
  onEndBreak: () => void;
};

const i18nprefix = 'autoBreaks.breakEarlierModal.';

const StyledButton = styled(Button)({
  minWidth: 110,
});

export const BreakEarlierModal: React.FC<Props> = ({
  visible,
  onClose,
  onEndBreak,
  now,
  allowEndBreakResult,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      contentClassName="border"
      dialogClassName="break-modal-440">
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{getTitle(allowEndBreakResult)}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div>{getContent(allowEndBreakResult, now)}</div>
          <div className="d-flex justify-content-end">
            <StyledButton
              className="mt-4 me-3"
              variant="outline-primary"
              label={t(i18nprefix + 'endBreakAnyway')}
              onClick={onEndBreak}
            />
            <StyledButton
              className="mt-4"
              label={t(i18nprefix + 'keepResting')}
              onClick={onClose}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const getTitle = (allowEndBreakResult: AllowToEndBreakResultFail): string => {
  switch (allowEndBreakResult.result) {
    case 'breakLessThanMinBreak':
      const needToBreakMinutes = Math.max(
        0,
        allowEndBreakResult.targetMinutes - allowEndBreakResult.breakingMinutes,
      );
      return i18next.t(i18nprefix + 'titleBreakLessThanMinBreak', {
        count: needToBreakMinutes,
      });
    case 'insideScheduledBreak':
      return i18next.t(i18nprefix + 'titleInsideScheduledBreak');
  }
};

const getContent = (
  allowEndBreakResult: AllowToEndBreakResultFail,
  now: Date,
): string => {
  switch (allowEndBreakResult.result) {
    case 'breakLessThanMinBreak':
      return i18next.t(i18nprefix + 'contentBreakLessThanMinBreak', {
        minutes: allowEndBreakResult.breakingMinutes,
      });
    case 'insideScheduledBreak':
      const scheduledInterval = mapBreakScheduledToInterval(
        allowEndBreakResult.breakScheduled,
        now,
      );
      const restMinutes = Math.max(
        0,
        differenceInMinutes(scheduledInterval.end, now),
      );
      return i18next.t(i18nprefix + 'contentInsideScheduledBreak', {
        startTime: allowEndBreakResult.breakScheduled.startTime,
        endTime: allowEndBreakResult.breakScheduled.endTime,
        minutes: restMinutes,
      });
  }
};
