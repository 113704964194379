import React from 'react';
import { formatDate, parseDate } from '../../../../../utils/dateUtils';
import classNames from 'classnames';

type Props = {
  day: string;
  className?: string;
  popover?: React.ReactElement;
};

export const DayRowCell: React.FC<Props> = ({ className, day, popover }) => {
  const dayStr = formatDate(parseDate(day), 'EEEE - PPP');
  return (
    <div className={classNames(className, 'd-flex align-items-center gap-2')}>
      <div>{dayStr}</div>
      {popover}
    </div>
  );
};
