import * as React from 'react';
import FlairIcon, { FlairIconProps } from '../../atoms/FlairIcon';
import './FlairButtonIcon.css';

type Variant = 'primary' | 'text';

type Props = FlairIconProps & {
  isActive?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  variant?: Variant;
};

const FlairButtonIcon = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { isActive, onClick, dataTestId, variant = 'primary' } = props;

  const classNames = [
    'flair-btn-icon-container',
    variant,
    isActive ? 'active' : '',
    'd-flex',
    'align-items-center',
    'rounded-1',
    'p-1',
    props.className,
  ].join(' ');

  return (
    <div
      className={classNames}
      role="button"
      onClick={onClick}
      data-testid={dataTestId}>
      <FlairIcon {...props} ref={ref} data-testid="flair-icon" />
    </div>
  );
});

export default FlairButtonIcon;
