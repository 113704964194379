import React, { useContext, useMemo } from 'react';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import ServerError from '../../../../../components/ServerError';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import {
  ProjectTimeEntriesFilterInput,
  TimeSheetFilterInput,
  useTimeSheetsControllingProjectsQuery,
} from '../../../__generated__/graphql';
import {
  createDefaultSortInput,
  isMatchClientFilter,
  mapTimeSheet,
  mapToTableRows,
  updateTableRows,
} from './mappings';
import { ProjectsTimeSheetsTable } from './ProjectsTimeSheetsTable';
import { TimeSheetsBulkActions } from '../../../manager/TimeSheetsControlling/Tables/TimeSheetsBulkActions';
import { useProjectsTimeSheetDetailsPrefetch } from './hooks/useProjectsTimeSheetDetailsPrefetch';
import { useProjectsTimeSheetsTableSelection } from './hooks/useProjectsTimeSheetsTableSelection';
import { ProjectsTimeSheetsFilter } from './Filters/types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { TimeSheetApproveContext } from '../../../components/TimeSheetApprove';
import { useTimeSheetsExpand } from '../../../components/TimeSheetsTable/useTimeSheetsExpand';

type Props = {
  filter: ProjectsTimeSheetsFilter;
  reportsMode: ManagerReportsMode;
};

const useProjectsTimeSheetQueryParams = (
  reportsMode: ManagerReportsMode,
  filter: ProjectsTimeSheetsFilter,
) => {
  const viewBy = useMemo(() => filter.viewBy ?? 'employee', [filter.viewBy]);

  const projectsTimeEntriesQueryFilter: ProjectTimeEntriesFilterInput = useMemo(
    () => ({
      projectsIds: filter.projectsIds,
    }),
    [filter],
  );

  const timeSheetQueryFilter: TimeSheetFilterInput = useMemo(
    () => ({
      managerReportsType: mapManagerReportsType(reportsMode),
      employeeName: null,
      employeeIds: filter.employeeIds,
      withWarnings: null,
      year: null,
      month: null,
      status: filter.status,
      projectTimeEntriesFilter: projectsTimeEntriesQueryFilter,
    }),
    [reportsMode, filter, projectsTimeEntriesQueryFilter],
  );

  const sort = useMemo(() => createDefaultSortInput(viewBy), [viewBy]);

  return { timeSheetQueryFilter, projectsTimeEntriesQueryFilter, sort, viewBy };
};

const PAGE_SIZE = 30;

export const ProjectsControllingTimeSheetTable: React.FC<Props> = ({
  reportsMode,
  filter,
}) => {
  const t = useNamespacedTranslation('timeTracking.projects.controlling.table');

  const { projectsTimeEntriesQueryFilter, timeSheetQueryFilter, sort, viewBy } =
    useProjectsTimeSheetQueryParams(reportsMode, filter);

  const { data, loading, error, fetchMore } =
    useTimeSheetsControllingProjectsQuery({
      variables: {
        limit: PAGE_SIZE,
        filter: timeSheetQueryFilter,
        offset: 0,
        sort,
        skipPaginationInfo: false,
        projectTimeEntriesFilter: projectsTimeEntriesQueryFilter,
      },
      fetchPolicy: 'network-only',
    });

  const { expandedRowIds, handleExpandedChanged } = useTimeSheetsExpand();
  const expandedRowIdSet = useMemo(
    () => new Set(expandedRowIds),
    [expandedRowIds],
  );

  const timeSheets = useMemo(
    () =>
      data
        ? data.manager.timeSheetsPagination.items
            .map(mapTimeSheet)
            .filter((item) => isMatchClientFilter(filter, item))
        : [],
    [data, filter],
  );

  const tableRows = useMemo(
    () => mapToTableRows(timeSheets, viewBy),
    [timeSheets, viewBy],
  );

  const { approvingIds, approveTimeSheets } = useContext(
    TimeSheetApproveContext,
  );

  const { selectedRowIds, selectedTimeSheetIds, onSelectAll, onSelectRow } =
    useProjectsTimeSheetsTableSelection(tableRows, approvingIds);

  const handleBulkActionsClose = () => {
    onSelectAll(tableRows, false);
  };

  const paginationInfo = useMemo(() => {
    return data?.manager.timeSheetsPagination;
  }, [data]);

  const handleBulkActionsApprove = (timeSheetIds: string[]) => {
    onSelectAll(tableRows, false);
    approveTimeSheets(timeSheetIds);
  };

  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: paginationInfo,
    loadingMessage: t('loadingMore'),
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo: true,
        },
      }).then();
    },
  });

  const tableRowsWithState = useMemo(() => {
    return updateTableRows(tableRows, approvingIds, selectedRowIds);
  }, [tableRows, approvingIds, selectedRowIds]);

  // Each time we get new timeSheets from server (at firstRequest and on every fetchMore)
  // we prefetch timeSheet details which are required for showing expanding days
  // The code will work without it, but it hugly improves the UI responsiveness
  useProjectsTimeSheetDetailsPrefetch(
    tableRowsWithState,
    projectsTimeEntriesQueryFilter,
  );

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <ProjectsTimeSheetsTable
        tableRows={tableRowsWithState}
        expandedRowIdSet={expandedRowIdSet}
        onApprove={(timesheet) => approveTimeSheets([timesheet.timeSheetId])}
        onExpandedChanged={handleExpandedChanged}
        onSelectAll={onSelectAll}
        onSelectRow={onSelectRow}
        viewBy={viewBy}
        highlightIfHasComments={filter.onlyWithComments === 'true'}
        projectsTimeEntriesQueryFilter={projectsTimeEntriesQueryFilter}
      />
      {LoadingMore}
      <TimeSheetsBulkActions
        visible={selectedTimeSheetIds.length > 0}
        onClose={handleBulkActionsClose}
        timeSheetIds={selectedTimeSheetIds}
        onApprove={handleBulkActionsApprove}
      />
    </>
  );
};

const Loading: React.FC = () => (
  <SkeletonTable className="card-table" columns={6} rows={15} />
);
