import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import ServerError from '../../../../../../../components/ServerError';
import { QuestionLabel } from '../../../../../components/EmployeeFeedback/QuestionLabel';
import { GoalsSection } from '../../Goals2/GoalsSection';
import { SideBySideAnswers } from '../../../../../components/EmployeeFeedback/SideBySideAnswers';
import { FeedbackCard } from '../../../../../components/EmployeeFeedbackQuestionnaire/EmployeeFeedbackQuestion';
import { EmployeeFeedbackAccordion } from '../../../../../components/EmployeeFeedbackQuestionnaire/EmployeeFeedbackAccordion';
import { useUserInfo } from '../../../../../context/UserInfo';
import {
  ComparedFeedbackSection,
  useComparedFeedbackSectionsMapper,
} from '../../../../../hooks/useComparedFeedbackSectionsMapper';
import {
  EmployeeFeedbackState,
  EmployeePerformanceReviewMeetingComparedFeedbackPageQuery,
  FeedbackQuestionVisibility,
  useEmployeePerformanceReviewMeetingComparedFeedbackPageQuery,
} from '../../../../../__generated__/graphql';
import { EmployeeProps } from '../types';
import { LoadingComparedFeedback } from './LoadingComparedFeedback';
import { ResolutionInput } from './ResolutionInput';
import {
  EmployeeAnswerProps,
  EmployeeFeedbackResolutionProps,
  FeedbackQuestionProps,
  ManagerAnswerProps,
} from './types';

import { isViewer } from '../../EmployeeFeedbacks/utils';
import FeedbackStatusSection from '../FeedbackStatusSection';
import { useReviewerSignAndFinish } from '../../../helpers';
import FeedbackMeetingSection from '../../FeedbackMeetingSection';
import ConfirmButton from '../../../../../../../components/button/ConfirmButton';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import PeerReviews from '../../EmployeeEvaluation/PeerReviews';
import { usePeerReviewsHook } from '../../hooks/usePeerReviewsHook';

type Params = {
  employeeId: string;
  feedbackId: string;
};

type ContentProps = {
  feedbackId: string;
  outcomeId: string;
  ended: boolean;
  managerName: string;
  managerId: string;
  employee: EmployeeProps;
  employeeAnswers: ReadonlyArray<EmployeeAnswerProps>;
  managerAnswers: ReadonlyArray<ManagerAnswerProps>;
  resolutions: ReadonlyArray<EmployeeFeedbackResolutionProps>;
  questions: ReadonlyArray<FeedbackQuestionProps>;
  readonly: boolean;
  manageGoals: boolean;
  meetingId: string | null;
  meetingStartTime: string | null;
  feedback: EmployeePerformanceReviewMeetingComparedFeedbackPageQuery['employeeFeedback'];
};
const i18Path = 'employeePage.performanceReview.meeting';
const Content: React.FC<ContentProps> = ({
  employeeAnswers,
  managerAnswers,
  resolutions,
  managerId,
  managerName,
  employee,
  feedbackId,
  outcomeId,
  questions,
  ended,
  readonly,
  manageGoals,
  meetingId,
  meetingStartTime,
  feedback,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const {
    isPeerReviewsEnabled,
    isPeerReviewer,
    hasAnyNonFinishedPeerFeedback,
    peerFeedbacks,
  } = usePeerReviewsHook(feedback);

  const isSignatureRequired =
    feedback.feedbackCycle.flair__Signature_required__c;

  const renderSignAndCompleteBtn = !isPeerReviewer && !ended && !readonly;
  const signAndCompleteBtnLbl = t(
    isSignatureRequired ? 'signComplete' : 'complete',
  );
  const isSignAndCompleteBtnDisabled =
    feedback.feedbackCycle.flair__With_Peers__c &&
    hasAnyNonFinishedPeerFeedback;

  const disabledTooltip = isSignAndCompleteBtnDisabled
    ? t('disabledTooltipForPeers')
    : undefined;

  const isManagerSubmitted =
    feedback.flair__State__c === EmployeeFeedbackState.Submitted;
  const isEmployeeSubmitted =
    feedback.employeeFeedback?.flair__State__c ===
    EmployeeFeedbackState.Submitted;

  const mapSections = useComparedFeedbackSectionsMapper();
  const [sections, setSections] = useState<ComparedFeedbackSection[]>(() =>
    mapSections({
      questions,
      employeeAnswers,
      managerAnswers,
      resolutions,
    }),
  );

  const filteredSections = useMemo(() => {
    return sections.filter((section) => {
      if (isPeerReviewer && section.isEmployeeSatisfactionSection) {
        return false;
      }
      return true;
    });
  }, [isPeerReviewer, sections]);

  const [isFinishing, signAndFinish] = useReviewerSignAndFinish();

  const onUpdateResolution = useCallback(
    ({ resolutionId, value }: { resolutionId: string; value: string }) => {
      setSections((sections) => {
        const sectionIndex = sections.findIndex((s) =>
          s.resolutions.find((r) => r.id === resolutionId),
        );

        if (sectionIndex === -1) {
          return sections;
        }

        const section = sections[sectionIndex];

        const resolutionIndex = section.resolutions.findIndex(
          (r) => r.id === resolutionId,
        );
        if (resolutionIndex === -1) {
          return sections;
        }

        const resolution = section.resolutions[resolutionIndex];
        const updatedResolutions = [...section.resolutions];

        updatedResolutions[resolutionIndex] = {
          ...resolution,
          value,
        };

        const updatedSections = [...sections];
        updatedSections[sectionIndex] = {
          ...section,
          resolutions: updatedResolutions,
        };

        return updatedSections;
      });
    },
    [setSections],
  );

  const handleFinish = () => {
    return signAndFinish(outcomeId);
  };

  return (
    <Row>
      <Col
        lg={8}
        style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 15.5rem)' }}>
        {filteredSections.map((section) => (
          <EmployeeFeedbackAccordion key={section.name} title={section.name}>
            {section.resolutions.map((resolution) => (
              <FeedbackCard key={resolution.id}>
                <QuestionLabel>{resolution.question.value}</QuestionLabel>
                <SideBySideAnswers
                  employeeId={employee.Id}
                  employeeName={employee.Name}
                  employeeAnswer={resolution.employeeAnswer}
                  managerId={managerId}
                  managerName={managerName}
                  managerAnswer={resolution.managerAnswer}
                  questionVisibility={resolution.question.questionVisibility}
                  answerVisibility={resolution.question.answerVisibility}
                  isEmployeeSubmitted={isEmployeeSubmitted}
                  isManagerSubmitted={isManagerSubmitted}
                />
                <ResolutionInput
                  resolutionId={resolution.id}
                  value={resolution.value}
                  disabled={readonly || ended}
                  onUpdateResolution={onUpdateResolution}
                />
              </FeedbackCard>
            ))}
          </EmployeeFeedbackAccordion>
        ))}
      </Col>
      <Col lg={4} style={{ position: 'sticky', top: 0, height: '75vh' }}>
        {meetingId != null && (
          <FeedbackMeetingSection
            meetingId={meetingId}
            startDateTime={meetingStartTime}
            feedbackId={feedbackId}
            readonly={readonly || ended}
          />
        )}

        {manageGoals && (
          <GoalsSection employeeId={employee.Id} employeeName={employee.Name} />
        )}

        <FeedbackStatusSection
          ended={ended}
          readonly={readonly}
          signatureRequired={isSignatureRequired}
        />

        {isPeerReviewsEnabled && (
          <PeerReviews
            maxPeers={feedback.feedbackCycle.flair__Number_of_Peers__c}
            outcomeId={feedback.outcome.Id}
            peerFeedbacks={peerFeedbacks}
            currentFeedbackRequestId={feedbackId}
            revieweeId={feedback.reviewee.Id}
            isEditingEnabled={
              !feedback.employeeFeedback?.flair__Submitted_At__c
            }
          />
        )}

        {renderSignAndCompleteBtn && (
          <Row className="submit-button">
            <Col className="text-end">
              <ConfirmButton
                variant="primary"
                className="w-100 mb-3"
                onConfirm={handleFinish}
                isProcessing={isFinishing}
                disabled={isSignAndCompleteBtnDisabled}
                tooltip={disabledTooltip}
                label={signAndCompleteBtnLbl}
                confirmTitle={t('confirmTitle')}
                confirmText={t('confirmText')}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export const ComparedFeedback: React.FC = () => {
  const { id: myId } = useUserInfo();
  const { feedbackId } = useParams<Params>();
  const { data, loading, error } =
    useEmployeePerformanceReviewMeetingComparedFeedbackPageQuery({
      variables: {
        feedbackId,
      },
    });

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingComparedFeedback />;
  }

  const { employeeFeedback: feedback } = data;
  const { reviewee: employee } = feedback;

  if (!feedback.employeeFeedback) {
    return null;
  }
  const feedbackCycleQuestions = feedback.feedbackCycle.questions.filter(
    (item) =>
      item.flair__Feedback_Question_Type__c !== 'YES_NO' &&
      item.flair__Feedback_Question_Visibility__c !==
        FeedbackQuestionVisibility.Peers,
  );

  return (
    <Content
      questions={feedbackCycleQuestions}
      employeeAnswers={feedback.employeeFeedback.answers}
      managerAnswers={feedback.answers}
      resolutions={feedback.outcome.resolutions}
      employee={employee}
      managerId={feedback.reviewer.Id}
      managerName={feedback.reviewer.Name}
      feedbackId={feedbackId}
      outcomeId={feedback.outcome.Id}
      ended={!!feedback.outcome.flair__Ended_At__c}
      readonly={isViewer(feedback.flair__Feedback_From__c, myId)}
      manageGoals={feedback.feedbackCycle.flair__Manage_Goals__c}
      meetingId={feedback.outcome.meeting ? feedback.outcome.meeting.Id : null}
      meetingStartTime={
        feedback.outcome.meeting
          ? feedback.outcome.meeting.flair__Start_Datetime__c
          : null
      }
      feedback={feedback}
    />
  );
};
