import React from 'react';
import { FlairLabel } from '../../atoms/Flairlabel/FlairLabel';
import Avatar from '../avatar/Avatar';
import { AvatarSize } from '../../atoms/avatar/AvatarContainer/types';

export type CompanyAvatarProps = {
  title: string;
  subtitle: string;
  avatarSrc?: string;
  className?: string;
  logoClass?: string;
  titleClass?: string;
  subtitleClass?: string;
  avatarSize?: AvatarSize;
};

const getCompanyInitialsFromTitle = (name: string): string => {
  return name
    .split(' ')
    .slice(0, 2)
    .reduce((initials, name) => `${initials}${name[0]}`, '')
    .toUpperCase();
};

// TODO: Rename the component to AvatarWithInfo and make it generic (atomic design) to support multiple types (employee, company etc.)
export const CompanyAvatar: React.FC<CompanyAvatarProps> = ({
  avatarSrc,
  title,
  subtitle,
  className,
  logoClass,
  titleClass,
  subtitleClass,
  avatarSize,
}) => {
  return (
    <div
      className={`d-flex align-items-center company--info-card-container ${className}`}>
      <div className="me-2">
        <Avatar
          initials={getCompanyInitialsFromTitle(title)}
          avatarUrl={avatarSrc}
          size={avatarSize}
          className={`${logoClass}`}
        />
      </div>
      <div className="d-flex flex-column text-start text-wrap">
        <FlairLabel text={title} className={titleClass} type={'primary'} />
        <FlairLabel
          text={subtitle}
          className={subtitleClass}
          type={'secondary'}
        />
      </div>
    </div>
  );
};
