import { PureQueryOptions } from '@apollo/client';
import React from 'react';
import { Button, Popover, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import { Theme } from '../../../../../../theme';
import { toFormattedDateInterval } from '../../../../../../utils/dateInterval';
import { getBreakIntervalString } from '../../ShiftsDaily/dailyShiftsLogic';
import { DailyShiftInfo } from '../../ShiftsDaily/dailyShiftsTypes';
import { useOpenShiftsActions } from '../../useOpenShiftsActions';
import DurationString from '../DurationString';
import InvitedEmployeeIcon from '../InvitedEmployeeIcon';
import { getNotAvailableIcon, notAvailableReasonText } from '../logic';
import { EmployeeShiftInfo, OpenShiftInfo, ShiftInfo } from '../types';
import './ShiftsPopover.css';
import FlairIcon, {
  FlairIconName,
  FlairIconProps,
} from '../../../../../../atomic/atoms/FlairIcon';

const colors = Theme.color;

type Props = {
  shift: DailyShiftInfo | OpenShiftInfo | ShiftInfo;
  refetchQueries: Array<PureQueryOptions> | undefined;
};

const getShiftEmployees = (shift: OpenShiftInfo | EmployeeShiftInfo) => {
  if (shift.type === 'open-shift') {
    return shift.openShiftEmployees.reduce(
      (t, c, i) => (i === 0 ? c.name : t + ', ' + c.name),
      '',
    );
  } else {
    if (!shift.isMy) {
      let location = null;
      if (shift.employee.location) {
        location = <InvitedEmployeeIcon location={shift.employee.location!} />;
      }
      return (
        <span>
          {shift.employee.name}
          {location}
        </span>
      );
    }
  }

  return null;
};

const ItemList = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={['mb-n3', 'pb-2', className].join(' ')} {...props} />
);

const ItemContainer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={['d-flex', 'mb-3', className].join(' ')} {...props}></div>
);

const ButtonContainer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <ItemContainer
    className={['justify-content-center', className].join(' ')}
    {...props}
  />
);

const ItemIcon = ({ className, ...props }: FlairIconProps) => (
  <FlairIcon
    className={['me-3', 'flex-shrink-0', className].join(' ')}
    size="lg"
    {...props}
    ref={null}
  />
);

const ItemContentContainer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={['d-flex', 'flex-column', className].join(' ')}
    {...props}></div>
);

type ShiftRenderInfo = {
  title: string;
  icon: FlairIconName;
  color?: string;
  button?: React.ReactNode;
};

const ShiftPopover: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ shift, refetchQueries, ...rest }, ref) => {
    const { t } = useTranslation();

    const popoverProps = {
      ref,
      ...rest,
    };

    const [
      createAssignmentRequest,
      handleDeleteAssignmentRequest,
      isRequesting,
    ] = useOpenShiftsActions(refetchQueries);

    const getShiftRenderInfo = (shift: Props['shift']): ShiftRenderInfo => {
      const state = 'state' in shift ? shift.state : undefined;

      switch (state) {
        case 'available':
          return {
            title: t('shiftPlanner.openShifts.available'),
            icon: 'ellipse-filled',
            color: colors.gray3,
            button: (
              <Button
                size="sm"
                variant="primary"
                onClick={() => createAssignmentRequest(shift.id)}>
                {isRequesting && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                <span>{t('shiftPlanner.openShifts.requestShift')}</span>
              </Button>
            ),
          };
        case 'requested':
          return {
            title: t('shiftPlanner.openShifts.waitingApproval'),
            icon: 'ellipse-filled',
            color: colors.honey1,
            button: (
              <Button
                size="sm"
                variant="outline-danger"
                onClick={() =>
                  handleDeleteAssignmentRequest(
                    'assignmentRequestId' in shift
                      ? shift.assignmentRequestId!
                      : '',
                  )
                }>
                {isRequesting && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                <span>{t('shiftPlanner.openShifts.cancelShiftRequest')}</span>
              </Button>
            ),
          };
        case 'confirmed':
        case undefined:
          return {
            title: t('shiftPlanner.openShifts.approved'),
            icon: 'ellipse-filled',
            color: colors.blue3,
            button: null,
          };
        case 'not-available':
          if ('notAvailableReason' in shift && shift.notAvailableReason) {
            return {
              title: notAvailableReasonText(shift.notAvailableReason),
              icon: getNotAvailableIcon(shift.notAvailableReason),
              button: null,
            };
          }
          return {
            title: t('shiftPlanner.openShifts.notAvailable'),
            icon: 'ellipse-filled',
            color: colors.honey1,
            button: null,
          };
      }
    };

    const shiftRenderInfo = getShiftRenderInfo(shift);

    const renderInterval = () => {
      const formattedInterval = toFormattedDateInterval(shift);

      return (
        <div>
          <time>{formattedInterval.start.time}</time>
          &nbsp;-&nbsp;
          <time>{formattedInterval.end.time}</time>
        </div>
      );
    };

    const renderShiftLabels = () => {
      return (
        <span className="text-dark">
          {shift.shiftLabels.map((s) => s.name).join(', ')}
        </span>
      );
    };

    const shiftEmployees = getShiftEmployees(shift);
    const notes = 'employeeNotes' in shift ? shift.employeeNotes : null;

    return (
      <Popover {...popoverProps} id="shift-popover">
        <Popover.Header>
          <div className="d-flex justify-content-end align-items-center">
            <FlairIcon
              icon="close-outline"
              onClick={() => document.body.click()}
              role="button"
            />
          </div>
        </Popover.Header>
        <Popover.Body>
          <ItemList>
            <ItemContainer>
              <ItemIcon icon="calendar-clear-outline" />
              <ItemContentContainer>
                <span>
                  <FormattedDate day={shift.start} format="day-with-weekday" />
                  {shift.end.isAfter(shift.start, 'day') ? (
                    <>
                      {' '}
                      -{' '}
                      <FormattedDate day={shift.end} format="day-with-month" />
                    </>
                  ) : null}
                </span>
                <span>{renderInterval()}</span>
                <span className="text-dark">
                  (<DurationString start={shift.start} end={shift.end} />
                  &nbsp;
                  {t('shifts.personalShifts.ofWorkingTime')})
                </span>
              </ItemContentContainer>
            </ItemContainer>
            {shift.breaks.length > 0 && (
              <ItemContainer>
                <ItemIcon icon="pause-circle-outline" />
                <ItemContentContainer>
                  <span>
                    {shift.breaks.length > 1
                      ? t('shifts.personalShifts.breaks')
                      : t('shifts.personalShifts.break')}
                  </span>
                  <span className="text-dark">
                    {shift.breaks.reduce(
                      (t, c, i) =>
                        i === 0
                          ? getBreakIntervalString(c)
                          : t + ', ' + getBreakIntervalString(c),
                      '',
                    )}
                  </span>
                </ItemContentContainer>
              </ItemContainer>
            )}

            {!!shift.shiftLabels.length && (
              <ItemContainer>
                <ItemIcon icon="mic-outline" />
                <ItemContentContainer>
                  <span>{t('shifts.personalShifts.labels')}</span>
                  {renderShiftLabels()}
                </ItemContentContainer>
              </ItemContainer>
            )}

            {shiftEmployees && (
              <ItemContainer>
                <ItemIcon icon="person-circle-outline" />
                <ItemContentContainer>{shiftEmployees}</ItemContentContainer>
              </ItemContainer>
            )}
            {!!shift.location && (
              <ItemContainer>
                <ItemIcon icon="navigate-circle-outline" />
                <ItemContentContainer>
                  <span>{t('shifts.personalShifts.location')}</span>
                  <span className="text-dark">{shift.location.name}</span>
                </ItemContentContainer>
              </ItemContainer>
            )}
            {notes && (
              <ItemContainer>
                <ItemIcon icon="sticky-note-outline" />
                <ItemContentContainer>
                  <span>{notes}</span>
                </ItemContentContainer>
              </ItemContainer>
            )}
            <ItemContainer>
              <ItemIcon
                icon={shiftRenderInfo.icon}
                color={shiftRenderInfo.color}
              />
              <ItemContentContainer>
                {shiftRenderInfo.title}
              </ItemContentContainer>
            </ItemContainer>
            {shiftRenderInfo.button && (
              <ButtonContainer>{shiftRenderInfo.button}</ButtonContainer>
            )}
          </ItemList>
        </Popover.Body>
      </Popover>
    );
  },
);

export default ShiftPopover;
