import React, { createContext, useState } from 'react';

type MenuBarContextType = {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MenuBarContext = createContext<MenuBarContextType>({
  isExpanded: false,
  setIsExpanded: () => {},
});

export const MenuBarProvider: React.FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <MenuBarContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children}
    </MenuBarContext.Provider>
  );
};
