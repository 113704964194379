import i18next from 'i18next';
import { OptionBase } from '../../../../components/Select/types';
import { statusValues, viewByValues } from './types';

const i18Prefix = 'timeSheetsControlling.filter';

export function getViewByOptions() {
  return viewByValues.map((value) => ({
    value,
    label: i18next.t(`${i18Prefix}.viewByOptions.${value}`),
  }));
}

export function createYearOptions(now: Date) {
  const currentYear = now.getFullYear();
  return [currentYear - 1, currentYear].map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
}

export function getTimeSheetStatusOptions() {
  return statusValues.map((value) => ({
    value,
    label: i18next.t(`${i18Prefix}.statusOptions.${value}`),
  }));
}

export function createMonthOptions(dates: OptionBase[]) {
  return dates.map((date) => ({ value: date.value, label: date.label }));
}
