export type AbsenceRequestFilter = {
  type: string[];
  colleaguesIds: string[];
};

export const TYPE_FILTER = 'type';
export const COLLEAGUES_IDS_FILTER = 'colleaguesIds';

export const AbsenceRequestFiltersKeysArray = [
  TYPE_FILTER,
  COLLEAGUES_IDS_FILTER,
] as const;

export type AbsenceRequestFiltersKeys =
  (typeof AbsenceRequestFiltersKeysArray)[number];

export type AbsenceRequestFiltersType = Record<
  AbsenceRequestFiltersKeys,
  string
>;

export const filterDefaultData: AbsenceRequestFiltersType = {
  type: '',
  colleaguesIds: '',
};

export const AbsenceTypeMap: Record<string, string> = {
  DAILY: 'days',
  HOURLY: 'hours',
  UNKNOWN: '',
};
