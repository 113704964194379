import React from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';

type Props = {
  label: React.ReactNode;
  value: React.ReactNode;
};

const ListGroupItem: React.FC<Props> = ({ label, value }) => (
  <ListGroup.Item>
    <Row className="align-items-center">
      <Col>{label}</Col>
      <Col className="col-auto">{value}</Col>
    </Row>
  </ListGroup.Item>
);

export default ListGroupItem;
