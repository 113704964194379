import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import {
  emptyWorkflowItemFilterForMe,
  emptyWorkflowItemFilterForOthers,
  WorkflowItemFilterBase,
} from '../Filters/types';
import {
  useWorkflowsItemsQuery,
  WorkflowContext,
} from '../../../__generated__/graphql';
import {
  mapItemsSortBy,
  mapWorkflowItems,
  mapWorkflowItemsFilterInput2,
} from '../WorkflowItems/mappings';
import React, { useCallback, useEffect, useState } from 'react';
import { FlairLocalStorage } from '../../../hooks/useFlairLocalStorage';
import { Route } from '../../../../../utils/router';
import { WorkflowItemsTableConnected } from '../WorkflowItems/WorkflowItemsTableConnected';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { Maybe } from '../../../../../utils/maybe';
import { Item } from '../WorkflowItems/WorkflowItemsTable';
import { useUserInfo } from '../../../context/UserInfo';
import { useSelectedItemWithComments } from '../../../components/Comment';

type WorkflowConfig = {
  storageKey: keyof FlairLocalStorage;
  routeKey: string;
  includeWhereIAmResponsible: boolean;
  defaultFilter: WorkflowItemFilterBase;
};

const configs: Record<WorkflowContext, WorkflowConfig> = {
  [WorkflowContext.All]: {
    storageKey: 'workflow_items_filter_for_all',
    routeKey: 'workflowsAllWorkflowItemsAll',
    includeWhereIAmResponsible: true,
    defaultFilter: emptyWorkflowItemFilterForOthers,
  },
  [WorkflowContext.My]: {
    storageKey: 'workflow_items_filter_for_me',
    routeKey: 'workflowsMeWorkflowItemsAll',
    includeWhereIAmResponsible: true,
    defaultFilter: emptyWorkflowItemFilterForMe,
  },
  [WorkflowContext.Other]: {
    storageKey: 'workflow_items_filter_for_others',
    routeKey: 'workflowsOthersWorkflowItemsAll',
    includeWhereIAmResponsible: false,
    defaultFilter: emptyWorkflowItemFilterForOthers,
  },
};

const getConfigByTab = (workflowContext: WorkflowContext): WorkflowConfig => {
  return configs[workflowContext];
};

const PAGE_SIZE = 50;

export const useAllWorkflowsItems = ({
  workflowContext,
  route,
}: {
  workflowContext: WorkflowContext;
  route: Route<['workflows']>;
}) => {
  const configs: WorkflowConfig = getConfigByTab(workflowContext);

  const { defaultSelectedItem } = useSelectedItemWithComments();
  const [selectedItem, setSelectedItem] =
    useState<Maybe<Item>>(defaultSelectedItem);
  const { id: meId } = useUserInfo();

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: configs.defaultFilter,
    storageKey: configs.storageKey,
    createRouteUrl: (queryParams) =>
      route.withQueryParams(queryParams).create({}),
  });

  const { data, error, loading } = useWorkflowsItemsQuery({
    variables: {
      workflowContext,
      filter: mapWorkflowItemsFilterInput2(tableState.filter),
      sort: mapItemsSortBy(tableState.sortBy) || null,
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });

  useEffect(() => {
    const totalCount = data?.workflowItems.paginationInfo?.totalCount;
    setTotalItemsCount(totalCount);
  }, [setTotalItemsCount, data]);

  const allWorkflowItems = data ? mapWorkflowItems(data) : undefined;

  const renderContent = useCallback(() => {
    if (loading || !allWorkflowItems || !meId) {
      return <Loading />;
    }
    return (
      <WorkflowItemsTableConnected
        workflowItems={allWorkflowItems}
        employeeColumnVisible={true}
        onItemClick={setSelectedItem}
        pageSize={PAGE_SIZE}
        itemsCount={tableState.totalItemsCount}
        initialSortBy={tableState.sortBy}
        onSortByChanged={handleSortByChanged}
        initialPageIndex={tableState.pageIndex}
        onPageIndexChanged={handlePageIndexChanged}
      />
    );
  }, [
    loading,
    allWorkflowItems,
    meId,
    handleSortByChanged,
    handlePageIndexChanged,
    tableState,
  ]);

  return {
    tableState,
    handleFilterChange,
    data,
    error,
    pageSize: PAGE_SIZE,
    renderContent,
    selectedItem,
    setSelectedItem,
    allWorkflowItems,
  };
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5} />;
};
