import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RedirectToHome from '../../components/RedirectToHome';
import { DocumentsHeader } from './DocumentsHeader';
import { routes } from './routes';
import { TableSortProvider } from '../../../../context/TableSortingContext';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

export const DocumentsPage: React.FC = () => {
  return (
    <div className="position-relative">
      <DocumentsHeader />
      <TableSortProvider storeAgeKey="personal-documents">
        <Content />
      </TableSortProvider>
    </div>
  );
};
