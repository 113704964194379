import React from 'react';
import { Col } from 'react-bootstrap';
import { PageHeaderRow } from '../../../components/PageHeader';
import JobNav from './JobNav';
import { JobTabProps } from '../types';
import PageHeader from '../../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { recruitmentRoutes } from '../../routes';

const JobPageHeader: React.FC<JobTabProps> = ({ job }) => {
  const { t } = useTranslation();
  const tRecruitmentNavigation = useNamespacedTranslation(
    'navigation.menuItems.recruitment',
  );

  useFlairBreadcrumbHook([
    { label: tRecruitmentNavigation('title') },
    {
      label: tRecruitmentNavigation('subMenuItems.jobs.title'),
      url: recruitmentRoutes.jobsList.route.create({}),
    },
    { label: t(job.Name) },
  ]);

  return (
    <PageHeader
      title={t(job.Name)}
      subtitle={t('recruitment.jobCandidatesPage.pageTitle')}>
      <PageHeaderRow>
        <Col>
          <JobNav job={job} />
        </Col>
      </PageHeaderRow>
    </PageHeader>
  );
};

export default JobPageHeader;
