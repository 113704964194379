import {
  WorkflowItemStatus as WorkflowItemStatusGraphql,
  WorkflowStatus as WorkflowStatusGraphql,
  WorkflowStepActivatingTrigger,
  WorkflowType,
} from '../../__generated__/graphql';
import { WorkflowItem, WorkflowItemBase } from './WorkflowItems/types';

export type Workflow = {
  id: string;
  name: string;
  workflowType: WorkflowType;
  workflowTemplateId: string | null;
  closestItemDueDate: string | null;
  employee: WorkflowEmployee;
  stepStatusInfos: WorkflowStepStatusInfo[];
};

export type WorkflowEmployee = {
  type: 'employee';
  id: string;
  name: string;
  position: string | null;
  department: string | null;
  avatarUrl?: string;
  startDate: string | null;
  endDate: string | null;
};

export type WorkflowFilterByEmployeeOption = {
  type: 'employee';
  value: string;
  label: string;
};

export type WorkflowTeam = {
  type: 'team';
  id: string;
  name: string;
  employees: WorkflowTeamEmployeeInfo[];
};

export type WorkflowStep = {
  id: string;
  name: string;
  order: number;
  activatingTrigger: WorkflowStepActivatingTrigger;
  activationDate: string | null;
  isActive: boolean;
  workflowItems: WorkflowItem[];
};

export type WorkflowTeamEmployeeInfo = {
  id: string;
  name: string;
  avatarUrl?: string;
};

export type WorkflowItemStatusInfo = Pick<
  WorkflowItemBase,
  'id' | 'name' | 'status'
>;

export type WorkflowStepStatusInfo = {
  id: string;
  name: string;
  itemsCount: number;
  completedItemsCount: number;
};

export const isWorkflowType = (src: string | null): src is WorkflowType => {
  switch (src) {
    case WorkflowType.Onboarding:
    case WorkflowType.Offboarding:
    case WorkflowType.Employee:
    case WorkflowType.EmployeeEquipmentRequest:
    case WorkflowType.ExpenseReport:
    case WorkflowType.Other:
      return true;
    default:
      return false;
  }
};

export const isWorkflowStatusGraphql = (
  src: string | null,
): src is WorkflowStatusGraphql => {
  switch (src) {
    case WorkflowStatusGraphql.NotStarted:
    case WorkflowStatusGraphql.InProgress:
    case WorkflowStatusGraphql.Completed:
      return true;
    default:
      return false;
  }
};

export const isWorkflowItemStatusGraphql = (
  src: string | null,
): src is WorkflowItemStatusGraphql => {
  switch (src) {
    case WorkflowItemStatusGraphql.NotStarted:
    case WorkflowItemStatusGraphql.InProgress:
    case WorkflowItemStatusGraphql.PendingApproval:
    case WorkflowItemStatusGraphql.Completed:
    case WorkflowItemStatusGraphql.Rejected:
      return true;
    default:
      return false;
  }
};
