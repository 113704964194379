import {
  EmployeeDataFieldState,
  MyEmployeeDataRelatedListFragment,
  EmployeeDataRelatedListRecord,
  MyEmployeeDataFragment,
} from '../../__generated__/graphql';
import {
  EmployeeSection,
  EmployeeFieldFull,
  FieldInfo,
  EmployeeRelatedList,
  EmployeeRelatedListRecord,
} from './types';

type Section = MyEmployeeDataFragment['sections'][0];
type Field = Section['fields'][0];
type RelatedListRecord = Pick<EmployeeDataRelatedListRecord, 'id'> & {
  fields: ReadonlyArray<Field>;
};

export const mapEmployeeSections = (
  src: MyEmployeeDataFragment,
): EmployeeSection[] => src.sections.map(mapEmployeeSection);

export const mapEmployeeSection = (
  src: MyEmployeeDataFragment['sections'][0],
): EmployeeSection => ({
  label: src.label,
  fields: src.fields.map(mapEmployeeField),
});

export const mapEmployeeField = (src: Field): EmployeeFieldFull => ({
  fieldInfo: mapFieldInfo(src),
  value: src.value,
  oldValue: src.oldValue,
  state: src.state,
  pendingApproval: src.pendingApproval,
});

export const mapFieldInfo = (src: Field): FieldInfo => ({
  name: src.fieldInfo.name,
  type: src.fieldInfo.type,
  label: src.fieldInfo.label,
  disabled: src.state === EmployeeDataFieldState.Readonly,
  required: src.state === EmployeeDataFieldState.Required,
  options: src.fieldInfo.picklistValues.map((x) => ({
    label: x.label,
    value: x.value,
  })),
});

export const mapEmployeeRelatedList = (
  src: MyEmployeeDataRelatedListFragment,
): EmployeeRelatedList => ({
  label: src.label,
  records: src.records.map(mapEmployeeRelatedListRecord),
});

const mapEmployeeRelatedListRecord = (
  src: RelatedListRecord,
): EmployeeRelatedListRecord => ({
  id: src.id,
  fields: src.fields.map(mapEmployeeField),
});
