import { isEqual, orderBy, uniqueId } from 'lodash';
import { ReportingError } from '../../../../../components/ErrorHandling';
import {
  addNewBreakInterval,
  AdjustBreakResult,
  autoBreaksCalculationService,
  AutoBreaksRules,
} from '../shared';
import { mapToAdjustTimeEntryRequest } from './mappings';

export type TimeTrackingStateWithEndedBreaks = {
  start: Date;
  breaks: TimeTrackingBreak[];
};

export type TimeTrackingStateWithBreaks = {
  start: Date;
  breaks: TimeTrackingBreak[];
};

export type TimeTrackingBreak = {
  uniqueId: string;
  breakId: string | null; // null if we want to create the break
  start: Date;
  end: Date;
  isBreakAutoFixed?: boolean;
};

type ReducerProps = {
  breakRules: AutoBreaksRules;
  initialBreaks: TimeTrackingBreak[];
};

export type ClockoutBreaksState = {
  // start is constant, but it just easy to have it there instead of ReducerProps
  // because then ClockoutBreaksState is compartible with TimeTrackingStateWithEndedBreaks
  start: Date;
  clockout: Date;
  breaks: TimeTrackingBreak[];
  isAutoAdjusted: boolean;
};

export const createInitialState = (
  start: Date,
  breaks: TimeTrackingBreak[],
  clockout: Date,
): ClockoutBreaksState => ({
  start,
  breaks: [...breaks],
  clockout,
  isAutoAdjusted: false,
});

type ClockoutBreaksAction =
  | {
      type: 'updateBreak';
      breakItem: TimeTrackingBreak;
    }
  | { type: 'deleteBreak'; uniqueId: string }
  | { type: 'newBreak' }
  | { type: 'autoAdjust' }
  | { type: 'undo' }
  | { type: 'updateClockout'; clockout: Date };

export const isDirty = (
  initialBreaks: TimeTrackingBreak[],
  breaks: TimeTrackingBreak[],
) => !isEqual(initialBreaks, breaks);

export const createClockoutBreaksReducer =
  ({ breakRules, initialBreaks }: ReducerProps) =>
  (
    state: ClockoutBreaksState,
    action: ClockoutBreaksAction,
  ): ClockoutBreaksState => {
    try {
      switch (action.type) {
        case 'updateBreak': {
          const updatedBreaks = state.breaks.map((x) =>
            x.uniqueId === action.breakItem.uniqueId ? action.breakItem : x,
          );
          return { ...state, isAutoAdjusted: false, breaks: updatedBreaks };
        }
        case 'deleteBreak':
          return {
            ...state,
            breaks: state.breaks.filter((x) => x.uniqueId !== action.uniqueId),
          };
        case 'newBreak':
          const { breakingMinutesUnusedByLaw } =
            autoBreaksCalculationService.getStats(
              breakRules,
              mapToAdjustTimeEntryRequest(state),
            );
          const newBreakInterval = addNewBreakInterval(
            state.start,
            state.clockout,
            state.breaks,
            breakingMinutesUnusedByLaw,
          );
          if (newBreakInterval === null) {
            return state;
          }
          return {
            ...state,
            isAutoAdjusted: false,
            breaks: orderBy(
              [
                ...state.breaks,
                {
                  ...newBreakInterval,
                  breakId: null,
                  uniqueId: uniqueId(),
                },
              ],
              'start',
            ),
          };
        case 'autoAdjust':
          const fixBreaksResult = autoBreaksCalculationService.adjustBreaks(
            breakRules,
            mapToAdjustTimeEntryRequest(state),
          );
          if (fixBreaksResult.nonComplientReason !== null) {
            // We can add isAutoAdjust error there, if required
            return state;
          }
          return {
            ...state,
            isAutoAdjusted: true,
            breaks: applyBreakFixes(state.breaks, fixBreaksResult.breaks),
          };
        case 'undo':
          return createInitialState(state.start, initialBreaks, state.clockout);
        case 'updateClockout':
          return { ...state, clockout: action.clockout };
        default:
          return state;
      }
    } catch (e) {
      throw new ReportingError({ state, action }, e);
    }
  };

export const clockoutBreaksActions = {
  updateBreak: (breakItem: TimeTrackingBreak): ClockoutBreaksAction => ({
    type: 'updateBreak',
    breakItem,
  }),
  deleteBreak: (uniqueId: string): ClockoutBreaksAction => ({
    type: 'deleteBreak',
    uniqueId,
  }),
  newBreak: (): ClockoutBreaksAction => ({
    type: 'newBreak',
  }),
  autoAdjust: (): ClockoutBreaksAction => ({
    type: 'autoAdjust',
  }),
  undo: (): ClockoutBreaksAction => ({
    type: 'undo',
  }),
  updateClockout: (clockout: Date): ClockoutBreaksAction => ({
    type: 'updateClockout',
    clockout,
  }),
};

const applyBreakFixes = (
  srcBreaks: TimeTrackingBreak[],
  fixedBreaks: AdjustBreakResult[],
): TimeTrackingBreak[] => {
  // Breaks can only be added or increases, no deduction

  // 1 Adjusting breaks
  let adjustedBreaks: TimeTrackingBreak[] = srcBreaks.map((srcBreak) => {
    const fixedBreak = fixedBreaks.find(
      (x) => x.breakId === srcBreak.uniqueId && x.isAutoAdjusted,
    );
    if (!fixedBreak) {
      return srcBreak;
    }
    return {
      ...srcBreak,
      start: fixedBreak.start,
      end: fixedBreak.end,
      isBreakAutoFixed: true,
    };
  });

  // 2 Adding new break if required

  const newFixedBreak = fixedBreaks.find((fixedBreak) =>
    srcBreaks.every((src) => src.uniqueId !== fixedBreak.breakId),
  );

  if (newFixedBreak) {
    adjustedBreaks = [
      ...adjustedBreaks,
      {
        breakId: null,
        uniqueId: uniqueId(),
        start: newFixedBreak.start,
        end: newFixedBreak.end,
        isBreakAutoFixed: true,
      },
    ];
  }

  return adjustedBreaks;
};
