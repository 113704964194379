import React from 'react';
import {
  AbsenceRequestFilterType,
  defaultFilter,
  getApprovalStatusOptions,
} from '../types';
import { Col, Row } from 'react-bootstrap';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { AbsenceRequestMonthFilter } from './AbsenceRequestMonthFilter';
import { OptionBase } from '../../../../../../components/Select/types';
import { MultiValue } from 'react-select';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

export type Props = {
  employeeOptions: EmployeeOption[];
  categoryOptions: OptionBase[];
  filter: AbsenceRequestFilterType;
  onChange: (filter: AbsenceRequestFilterType) => void;
};

const i18Path = 'absences.myAbsences.table';

export const AbsenceRequestFilters: React.FC<Props> = ({
  filter,
  onChange,
  employeeOptions,
  categoryOptions,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const approvalStatusOptions = getApprovalStatusOptions();

  const handleEmployeeChanged = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onChange({
      ...filter,
      employeeIds: selectedOptions.map((x) => x.value),
    });
  };

  const handleCategoryChanged = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onChange({
      ...filter,
      categoryIds: selectedOptions.map((x) => x.value),
    });
  };

  const handleApprovalStatusChanged = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onChange({
      ...filter,
      approvalStatuses: selectedOptions.map((x) => x.value),
    });
  };

  const handleClearFilter = () => onChange(defaultFilter);

  const handleMonthChanged = (month: string | null) => {
    onChange({
      ...filter,
      month,
    });
  };

  return (
    <Row className={'align-items-center row-cols-auto g-2'}>
      <Col>
        <span className={'text-nowrap'}>{t('filterBy')}</span>
      </Col>
      <Col>
        <FlairMultiSelectDropdown
          placeholder={t('filterType')}
          options={categoryOptions}
          selectedOptions={filter.categoryIds}
          onChange={handleCategoryChanged}
        />
      </Col>
      <Col>
        <AbsenceRequestMonthFilter
          placeholder={t('absenceMonth')}
          value={filter.month}
          onChange={handleMonthChanged}
        />
      </Col>
      <Col>
        <FlairMultiSelectDropdown
          placeholder={t('employee')}
          options={employeeOptions}
          isSearchable={true}
          selectedOptions={filter.employeeIds}
          onChange={handleEmployeeChanged}
        />
      </Col>
      <Col>
        <FlairMultiSelectDropdown
          placeholder={t('status')}
          options={approvalStatusOptions}
          selectedOptions={filter.approvalStatuses}
          onChange={handleApprovalStatusChanged}
        />
      </Col>
      <Col>
        <ClearFiltersLink onClick={handleClearFilter} />
      </Col>
    </Row>
  );
};
