import React from 'react';
import { OptionBase } from '../../../../components/Select/types';
import { EmployeeOptionLabel } from './EmployeeOptionLabel';
import { TeamOptionLabel } from './TeamOptionLabel';

export type EmployeeOption = OptionBase & {
  type: 'employee';
  avatarUrl?: string | null;
  initials: string;
};

export type TeamOption = OptionBase & {
  type: 'team';
};

export type EmployeeOrTeamOption = EmployeeOption | TeamOption;

type Props = {
  employeeOrTeam: EmployeeOrTeamOption;
  className?: string;
  isInsideSelect: boolean;
};

export const EmployeeOrTeamOptionLabel: React.FC<Props> = ({
  employeeOrTeam,
  className,
  isInsideSelect,
}) => {
  if (employeeOrTeam.type === 'employee') {
    return (
      <EmployeeOptionLabel
        employee={employeeOrTeam}
        className={`small ${className}`}
        isNameVisible={true}
      />
    );
  } else if (employeeOrTeam.type === 'team') {
    return (
      <TeamOptionLabel
        option={employeeOrTeam}
        isInsideSelect={isInsideSelect}
      />
    );
  }
  return null;
};
