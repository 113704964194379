import {
  BreakLegislationRuleFragment,
  BreakScheduledFragment,
  TimeFrameworkFragment,
  WorkloadDayFragment,
  WorkloadWeekFragment,
} from '../../../__generated__/graphql';
import {
  getWorkloadDay,
  IsoWeekDay,
  mapBreakLegislationRule,
} from '../mappings';
import {
  BreakReminder,
  BreakReminderScheduled,
  BreakReminderWorkingTime,
} from '../shared';

export const mapBreakReminders = (
  timeFramework: TimeFrameworkFragment,
  workloadWeek: WorkloadWeekFragment | null,
  timeEntryId: string,
  timeEntryIsoWeekDayNumber: IsoWeekDay,
): BreakReminder[] => {
  const remindersScheduled: BreakReminderScheduled[] = workloadWeek
    ? getRemindersScheduled(
        getWorkloadDay(workloadWeek, timeEntryIsoWeekDayNumber),
        timeEntryId,
      )
    : [];
  const breakLegislationReminders: BreakReminderWorkingTime[] =
    timeFramework.breakLegislationRules.map((x) =>
      mapReminderWorkingTime(x, timeEntryId),
    );
  const result: BreakReminder[] = [];
  return result.concat(remindersScheduled).concat(breakLegislationReminders);
};

const getRemindersScheduled = (
  src: WorkloadDayFragment | undefined,
  timeEntryId: string,
): BreakReminderScheduled[] => {
  return (
    src?.breaksScheduled?.map((x) => mapReminderScheduled(x, timeEntryId)) ?? []
  );
};

const mapReminderScheduled = (
  src: BreakScheduledFragment,
  timeEntryId: string,
): BreakReminderScheduled => ({
  reminderType: 'scheduled',
  reminderId: `${timeEntryId}_${src.id}`,
  startTime: src.startTime,
  endTime: src.endTime,
});

const mapReminderWorkingTime = (
  src: BreakLegislationRuleFragment,
  timeEntryId: string,
): BreakReminderWorkingTime => ({
  ...mapBreakLegislationRule(src),
  reminderType: 'working-time',
  reminderId: `${timeEntryId}_${src.Id}`,
});
