import {
  AbsencePopoverFragment,
  HolidayPopoverFragment,
  TimeBalanceWithdrawalType,
  TimeSheetStatus,
  WorkloadType,
} from '../../__generated__/graphql';

export const viewByValues = ['week', 'employee'] as const;
export type ViewBy = (typeof viewByValues)[number];
export const isViewByType = (src: string | null): src is ViewBy => {
  return viewByValues.some((x) => x === src);
};

export const statusValues = [TimeSheetStatus.Approved, TimeSheetStatus.Pending];

export type TimeSheet = {
  id: string;
  type: WorkloadType;
  period: TimeSheetPeriodInfo;
  employee: TimeSheetEmployee;
  targetMinutes: number;
  workedMinutes: number;
  trackedMinutes: number;
  differenceMinutes: number;
  workedDifferenceMinutes: number;
  approveStatus: TimeSheetStatus;
  warnings?: TimesheetWarning[];
  badges?: TimesheetBadge[];
  // answer Evgenii: Yes, and if approved, you can use "Given" values. We can discuss how to create a good abstraction for this value. We used timetac as our reference.
  compensatoryOvertimeMinutes: number;
  commentsCount: number;
};

export type TimesheetWarning = {
  id: string;
  message: string;
};

type TimesheetBadge = {
  type: 'absence';
  // todo: Implement in next PR.  add absence category icon and amount
  amount: number;
};

export type TimeSheetColumn = {
  Header: (props: { allRows: TableRow[] }) => JSX.Element | string | null;
  Cell?: (props: {
    value: any;
    allRows: TableRow[];
    row: TableRow;
    isExpanded: boolean;
  }) => JSX.Element | null;
  accessor: keyof TableRow;
};

export type TableRow =
  | TableRowHeaderWeek
  | TableRowHeaderEmployee
  | TableRowTimeSheet
  | TableDayAnySubRow;

export type RowType = TableRow['rowType'];

export type TimeSheetEmployee = {
  id: string;
  name: string;
  avatarUrl?: string;
};

export type TimeSheetPeriodInfo = {
  type: WorkloadType;
  id: string;
  startDay: string;
  endDay: string;
};

type TableRowBase = {
  id: string;
  commentsCount: number;
} & TableRowState;

export type TableRowHeaderWeek = TableRowBase &
  TimeSheetFieldsUndefined & {
    rowType: 'header-week';
    headerWeek: TimeSheetPeriodInfo;
  };

export type TableRowHeaderEmployee = TableRowBase &
  TimeSheetFieldsUndefined & {
    rowType: 'header-employee';
    headerEmployee: TimeSheetEmployee;
  };

export type TableRowTimeSheet = TableRowBase &
  TimeSheetFields & {
    rowType: 'row-employee' | 'row-period';
    headerId: string;
  };

export const isTableRowTimeSheet = (src: TableRow): src is TableRowTimeSheet =>
  src.rowType === 'row-employee' || src.rowType === 'row-period';

export type TableDayAnySubRow =
  | TableDaySubRow
  | TableDaySubRowWithdraw
  | TableDaySubRowLoading
  | TableDaySubRowLoadingError;
export const isTableDayAnySubRow = (src: TableRow): src is TableDayAnySubRow =>
  src.rowType === 'subrow-day' ||
  src.rowType === 'subrow-day-withdraw' ||
  src.rowType === 'subrow-day-loading' ||
  src.rowType === 'subrow-day-loading-error';

export const isTableDaySubRow = (src: TableRow): src is TableDaySubRow =>
  src.rowType === 'subrow-day';

export type TableDaySubRow = Omit<
  TimeSheetFieldsUndefined,
  | 'startTime'
  | 'endTime'
  | 'breakMinutes'
  | 'targetMinutes'
  | 'trackedMinutes'
  | 'workedMinutes'
  | 'differenceMinutes'
  | 'workedDifferenceMinutes'
  | 'timeBalanceAccumulatedMinutes'
  | 'timeSheetId'
> & {
  rowType: 'subrow-day';
  id: string;
  timeSheetId: string;
  employeeId: string;
  timeSheetDayId: string | null;
  commentsCount: number;
  day: string;
  badges: TableDayBadges;
  originalDayStartDateTime: Date;
  startTime: DateTimeValueWithChangeRequest;
  endTime: DateTimeValueWithChangeRequest;
  dayTimeEntryIds: ReadonlyArray<string>;
  hasEntriesWithNotes: boolean;
  dayChangeRequestIds: ReadonlyArray<string>;
  breakMinutes: MinutesWithChangeRequest;
  targetMinutes: number;
  trackedMinutes: number;
  workedMinutes: number;
  trackedMinutesWithChanges: number | null;
  workedMinutesWithChanges: number | null;
  trackedDifferenceMinutes: number;
  trackedDifferenceMinutesWithChanges: number | null;
  workedDifferenceMinutes: number;
  workedDifferenceMinutesWithChanges: number | null;
  timeBalanceAccumulatedMinutes: number;
};

export type DayChanges = {
  startTime: DateTimeValueWithChangeRequest;
  endTime: DateTimeValueWithChangeRequest;
  breakMinutes: MinutesWithChangeRequest;
};

// timeEntryId from which the value was taken
// changeRequestId from which the changeTo was taken
export type DateTimeValueWithChangeRequest = (
  | { timeEntryId: null; value: null }
  | { timeEntryId: string; value: Date }
) &
  ChangeRequestValue<Date>;

// it is tricky to calculate timeEntryIds here, but we can use TableDaySubRow.dayTimeEntryIds
export type MinutesWithChangeRequest = {
  value: number;
} & ChangeRequestsValue<number>;

// Support to be changed by change request
export type ChangeRequestValue<T> =
  | { changeRequestId: null; changeTo?: undefined }
  | { changeRequestId: string; changeTo: T };

export type ChangeRequestsValue<T> = {
  changeRequestIds: ReadonlyArray<string>;
  changeTo?: T;
};

export type TableDaySubRowLoading = TimeSheetFieldsUndefined & {
  rowType: 'subrow-day-loading';
  id: string;
  commentsCount: number;
};

export type TableDaySubRowLoadingError = TimeSheetFieldsUndefined & {
  rowType: 'subrow-day-loading-error';
  id: string;
  commentsCount: number;
  error: string;
};

export type TableDaySubRowWithdraw = TimeSheetFieldsUndefined & {
  rowType: 'subrow-day-withdraw';
  id: string;
  commentsCount: number;
  day: string;
  withdraw: TimeBalanceWithdrawRowInfo;
};

export type TimeBalanceWithdrawRowInfo = {
  amount: number;
  withdrawalType: TimeBalanceWithdrawalType;
  absenceCategoryName?: string;
};

export type TableDayBadges = {
  absences: ReadonlyArray<AbsencePopoverFragment>;
  holidays: ReadonlyArray<HolidayPopoverFragment>;
  absenceMinutes: number;
  holidayMinutes: number;
};

export type TableRowHeader = TableRowHeaderEmployee | TableRowHeaderWeek;

export const isTableRowHeader = (src: TableRow): src is TableRowHeader =>
  src.rowType === 'header-employee' || src.rowType === 'header-week';

export type TableRowState = {
  approving?: boolean;
  selected: boolean;
  expanded: boolean;
  selectDisabled: boolean;
};

export type TimeSheetFields = {
  timeSheetId: string;
  period: TimeSheetPeriodInfo;
  employee: TimeSheetEmployee;
  approveStatus: TimeSheetStatus;
  targetMinutes: number;
  trackedMinutes: number;
  workedMinutes: number;
  differenceMinutes: number;
  workedDifferenceMinutes: number;
  timeBalanceAccumulatedMinutes: undefined;
  overtimeMinutes: number;
  startTime: undefined;
  endTime: undefined;
  breakMinutes: undefined;
  balance: number;
  actions: undefined;
  warnings: string[];
};

type TimeSheetFieldsUndefined = Record<keyof TimeSheetFields, undefined>;

export const timeSheetTrackFieldsUndefined: TimeSheetFieldsUndefined = {
  timeSheetId: undefined,
  period: undefined,
  employee: undefined,
  approveStatus: undefined,
  targetMinutes: undefined,
  workedMinutes: undefined,
  trackedMinutes: undefined,
  differenceMinutes: undefined,
  workedDifferenceMinutes: undefined,
  overtimeMinutes: undefined,
  timeBalanceAccumulatedMinutes: undefined,
  startTime: undefined,
  endTime: undefined,
  balance: undefined,
  actions: undefined,
  breakMinutes: undefined,
  warnings: undefined,
};
