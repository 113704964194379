import classNames from 'classnames';
import * as React from 'react';
import { ProjectActivity } from '../types';
import { ProjectActivityItem } from './ProjectActivityItem';

type Props = {
  activities: ProjectActivity[];
};

export const ProjectActivities: React.FC<Props> = ({ activities }) => {
  return (
    <div className={classNames('d-flex', 'flex-column', 'gap-2')}>
      {activities.map((activity: ProjectActivity) => (
        <ProjectActivityItem key={activity.id} activity={activity} />
      ))}
    </div>
  );
};
