import React from 'react';

type Props = {
  id: string;
  className?: string;
  label?: string;
  value?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
  labelClassName?: string;
};

const Checkbox: React.FC<Props> = ({
  id,
  label,
  labelClassName,
  className,
  ...rest
}) => {
  return (
    <div className={`form-check ${className}`}>
      <input id={id} type="checkbox" className="form-check-input" {...rest} />
      <label
        className={['form-check-label', labelClassName].join(' ')}
        htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
