import React from 'react';
import { FilterProps, LOCATIONS_FILTER } from './types';
import { useTranslation } from 'react-i18next';
import TeamCalendarMultipleFilter from './TeamCalendarMultipleFilter';

const OfficeFilter: React.FC<FilterProps> = ({ options, filterData }) => {
  const { t } = useTranslation();

  return (
    <TeamCalendarMultipleFilter
      options={options}
      filterData={filterData}
      filterKey={LOCATIONS_FILTER}
      placeholder={t('employeePage.teamCalendar.filters.locations.placeholder')}
    />
  );
};

export default OfficeFilter;
