import React from 'react';
import { DateAndTime, toDateStr } from '../logic';
import DateInput from '../../../../../../components/DateInput/DateInput';
import TimeInput from './TimeInput';
import DateAndTimeReadonly from './DateAndTimeReadonly';
import moment from 'moment';

type Props = {
  name: string;
  value: DateAndTime | null;
  oldValue: DateAndTime | null;
  readonly: boolean;
  isDateVisible: boolean;
  error: string | null;
  onChange: (value: DateAndTime) => void;
  isHighlighted?: boolean;
  ariaLabelledBy?: string;
};

const DateAndTimeInput: React.FC<Props> = ({
  name,
  isDateVisible,
  readonly,
  oldValue,
  value,
  error,
  onChange,
  isHighlighted,
  ariaLabelledBy,
}) => {
  const handleTimeChange = (time: string) => {
    const date = value ? value.date : toDateStr(moment());
    onChange({ date, time });
  };

  const handleDateChange = (date: string) => {
    const time = value ? value.time : '';
    onChange({ date, time });
  };

  if (readonly && value) {
    return (
      <DateAndTimeReadonly
        value={value}
        oldValue={oldValue}
        isDateVisible={isDateVisible}
      />
    );
  }

  return (
    <div className="d-flex">
      {isDateVisible && (
        <div className="me-2">
          <DateInput
            name={`${name}_date`}
            readonly={readonly}
            value={value ? value.date : toDateStr(moment())}
            onChange={handleDateChange}
          />
        </div>
      )}
      <div>
        <TimeInput
          isHighlighted={isHighlighted}
          name={`${name}_time`}
          readonly={readonly}
          error={error}
          value={value ? value.time : ''}
          onChange={handleTimeChange}
          ariaLabelledBy={ariaLabelledBy}
        />
      </div>
    </div>
  );
};

export default React.memo(DateAndTimeInput);
