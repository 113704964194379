import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../../theme';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  rateable: boolean;
  className?: string;
};

export const SkillIcon: React.FC<Props> = ({ rateable, className }) => {
  const t = useNamespacedTranslation('skills');

  return (
    <FlairIcon
      className="fs-5"
      icon={rateable ? 'hashtag-outline' : 'stop-circle-outline'}
      tooltip={rateable ? t('rateableHint') : t('nonRateableHint')}
      color={Theme.color.gray2}
    />
  );
};
