import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../../../theme';
import { AutoBreaksRules } from '../../../../components/AutoBreaks/shared';
import DateAndTimeInput from '../components/DateAndTimeInput';
import Notes from '../components/Notes';
import TimeBreakInputs from '../components/TimeBreakInputs';
import {
  calculateDurationInMinutes,
  DateAndTime,
  DateAndTimeIntervalType,
  TimeEntryBreakItem,
  TimeEntryItem,
} from '../logic';
import { getFirstErrorFromPath, ValidationResult } from '../logic/validation';
import { Actions } from './actions';
import { BreakDuration } from './BreakDuration';
import { CostCenterProps } from '..';
import { SelectSingle } from '../../../../components/Selects/SelectSingle';

type Props = {
  timeEntry: TimeEntryItem;
  costCenters: readonly CostCenterProps[];
  autoBreakRules: AutoBreaksRules | null;
  readonly: boolean;
  readonlyIntervalToCompare?: DateAndTimeIntervalType;
  notesVisible: boolean;
  costCenterVisible: boolean;
  addNotesButtonVisible: boolean;
  isEditNotesAllowed: boolean;
  addAssignCostCenterButtonVisible: boolean;
  forceShowEndDate: boolean;
  isDeleteBreakAllowed: boolean;
  validationErrors: ValidationResult;
  dispatch: React.Dispatch<Actions>;
};

const Row = styled.div({
  display: 'flex',
  marginBottom: 10,
  position: 'relative',
});

const LeftColumn = styled.div({
  minWidth: 100,
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 576px)': {
    minWidth: 70,
  },
});

const RightColumn = styled.div({
  flexGrow: 1,
});

const DurationColumn = styled.div<{ error?: boolean }>(({ error }) => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  color: error ? Theme.input.border.errorColor : Theme.color.dark,
}));

const TimeEntryDumbContent: React.FC<Props> = ({
  timeEntry,
  costCenters,
  validationErrors,
  autoBreakRules,
  readonly,
  notesVisible,
  isEditNotesAllowed,
  costCenterVisible,
  forceShowEndDate,
  isDeleteBreakAllowed,
  dispatch,
}) => {
  const {
    start,
    end,
    isMultiday,
    breaks,
    notes,
    timeEntryOldInterval,
    costCenterId,
  } = timeEntry;

  const notesReadonly = readonly || !isEditNotesAllowed;

  const { t } = useTranslation();

  const handleStartChange = useCallback(
    (start: DateAndTime) => dispatch({ type: 'setStart', start }),
    [dispatch],
  );

  const handleEndChange = useCallback(
    (end: DateAndTime) => dispatch({ type: 'setEnd', end }),
    [dispatch],
  );

  const handleNotesChanged = (notes: string) => {
    dispatch({ type: 'setNotes', notes });
  };

  const handleCostCenterChanged = (value: string | null) => {
    dispatch({ type: 'setCostCenter', costCenterId: value });
  };

  const handleBreakItemChange = useCallback(
    (value: TimeEntryBreakItem) =>
      dispatch({ type: 'updateBreak', breakItem: value }),
    [dispatch],
  );

  const handleDeleteBreak = useCallback(
    (value: TimeEntryBreakItem) =>
      dispatch({ type: 'deleteBreak', uniqueId: value.uniqueId }),
    [dispatch],
  );

  const breakDurations: number[] = useMemo(
    () => breaks.map(calculateDurationInMinutes),
    [breaks],
  );

  return (
    <>
      <Row className="flex-xs-wrap">
        <LeftColumn id="start-label">
          {t('timeTrackingNew.dayCard.startTime')}
        </LeftColumn>
        <div>
          <DateAndTimeInput
            name="start"
            value={start}
            oldValue={timeEntryOldInterval?.start || null}
            readonly={readonly}
            error={getFirstErrorFromPath(validationErrors, 'start')}
            isDateVisible={false}
            onChange={handleStartChange}
            ariaLabelledBy="start-label"
          />
        </div>
      </Row>
      {breaks.map((breakItem: TimeEntryBreakItem, breakIndex: number) => (
        <BreakRow
          key={breakItem.uniqueId}
          breakItem={breakItem}
          isMultidayTimeEntry={timeEntry.isMultiday}
          isReadonly={readonly}
          isDeleteVisible={
            !readonly && (isDeleteBreakAllowed || breakItem.isNew)
          }
          breakDuration={breakDurations[breakIndex]}
          minBreakDuration={autoBreakRules?.minumumBreakDuration ?? 0}
          validationErrors={validationErrors}
          onChange={handleBreakItemChange}
          onDelete={handleDeleteBreak}
        />
      ))}
      {(end || forceShowEndDate) && (
        <Row className="flex-xs-wrap">
          <LeftColumn id="end-label">
            {t('timeTrackingNew.dayCard.endTime')}
          </LeftColumn>
          <RightColumn>
            <DateAndTimeInput
              name="end"
              readonly={readonly}
              error={getFirstErrorFromPath(validationErrors, 'end')}
              value={end}
              oldValue={timeEntryOldInterval?.end || null}
              isDateVisible={isMultiday}
              onChange={handleEndChange}
              ariaLabelledBy="end-label"
            />
          </RightColumn>
        </Row>
      )}
      {notesVisible && (
        <Row className="flex-xs-wrap">
          <LeftColumn className="align-self-start">
            {t('timeTrackingNew.dayCard.notes')}
          </LeftColumn>
          <RightColumn>
            <Notes
              name="notes"
              readonly={notesReadonly}
              value={notes}
              error={getFirstErrorFromPath(validationErrors, 'notes')}
              onChange={handleNotesChanged}
            />
          </RightColumn>
        </Row>
      )}
      {costCenterVisible && (
        <Row className="flex-xs-wrap">
          <LeftColumn>{t('timeTrackingNew.dayCard.costCenter')}</LeftColumn>
          <RightColumn>
            <SelectSingle
              options={costCenters.map((costCenter) => ({
                label: costCenter.Name,
                value: costCenter.Id,
              }))}
              value={costCenterId}
              heightMode="normal"
              onChange={handleCostCenterChanged}
            />
          </RightColumn>
        </Row>
      )}
    </>
  );
};

type BreakRowProps = {
  breakItem: TimeEntryBreakItem;
  isMultidayTimeEntry: boolean;
  isReadonly: boolean;
  isDeleteVisible: boolean;
  breakDuration: number;
  minBreakDuration: number;
  validationErrors: ValidationResult;
  onChange: (value: TimeEntryBreakItem) => void;
  onDelete: (value: TimeEntryBreakItem) => void;
};

const BreakRow: React.FC<BreakRowProps> = ({
  breakItem,
  isMultidayTimeEntry,
  isReadonly,
  isDeleteVisible,
  validationErrors,
  breakDuration,
  minBreakDuration,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="flex-xs-wrap" key={breakItem.uniqueId}>
      <LeftColumn>{t('timeTrackingNew.dayCard.break')}</LeftColumn>
      <RightColumn>
        <TimeBreakInputs
          isMultiday={isMultidayTimeEntry}
          readonly={isReadonly}
          validationErrors={validationErrors}
          pathPrefix={`breaks.${breakItem.uniqueId}.`}
          breakItem={breakItem}
          isDeleteVisible={isDeleteVisible}
          isHighlighted={breakItem.isBreakAutoFixed}
          onChange={onChange}
          onDelete={onDelete}
        />
      </RightColumn>
      <DurationColumn>
        <BreakDuration
          actualBreakDuration={breakDuration}
          minBreakDuration={minBreakDuration}
        />
      </DurationColumn>
    </Row>
  );
};

export default TimeEntryDumbContent;
