import React from 'react';
import { TableDayBadges } from '../types';
import classNames from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';
import HolidayIcon from '../../HolidayIcon';
import DurationFormat from '../../DurationFormat';
import { AbsencePopoverFragment } from '../../../__generated__/graphql';
import AbsenceCategoryIcon from '../../AbsenceCategoryIcon';
import AbsencePopover from '../../AbsencePopover';

type Props = {
  badges: TableDayBadges;
};

export const BadgesCell: React.FC<Props> = ({ badges }) => {
  return (
    <div
      className={classNames(
        'd-flex',
        'flex-wrap',
        'gap-2',
        'align-items-center',
      )}>
      {badges.holidays.length > 0 && <Holidays badges={badges} />}
      {badges.absences.length > 0 && <Absences badges={badges} />}
    </div>
  );
};

const Holidays: React.FC<Props> = ({ badges }) => {
  return (
    <Badge durationMinutes={badges.holidayMinutes}>
      {badges.holidays.map((x) => (
        <HolidayIcon key={x.Id} holidayPopoverInfo={x} size="sm" />
      ))}
    </Badge>
  );
};

const Absences: React.FC<Props> = ({ badges }) => {
  return (
    <Badge durationMinutes={badges.absenceMinutes}>
      {badges.absences.map((x) => (
        <AbsenceIcon key={x.Id} absence={x} />
      ))}
    </Badge>
  );
};

const Badge: React.FC<{ durationMinutes: number }> = ({
  durationMinutes,
  children,
}) => {
  return (
    <div className="d-flex align-items-center p-2 justify-content-start icon-secondary rounded-1 gap-2">
      {children}
      <span className="h9-regular">
        <DurationFormat minutes={durationMinutes} />
      </span>
    </div>
  );
};

const AbsenceIcon: React.FC<{ absence: AbsencePopoverFragment }> = ({
  absence,
}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={(props) => <AbsencePopover absence={absence} {...props} />}>
      <span>
        <AbsenceCategoryIcon icon={absence.flair__Category_Icon__c} />
      </span>
    </OverlayTrigger>
  );
};
