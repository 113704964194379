import wellDone from './wellDone.png';
import teamPlayer from './teamPlayer.png';
import thankYou from './thankYou.png';
import problemSolver from './problemSolver.png';
import happyBirthday from './happyBirthday.png';
import appreciateYou from './appreciateYou.png';
import visionary from './visionary.png';
import congrats from './congrats.png';
import greatCommunication from './greatCommunication.png';
import greatJob from './greatJob.png';
import extraMile from './extraMile.png';
import greatTeamwork from './greatTeamwork.png';

export const IMAGE_WIDTH_REM = 21.75; // in rem, in px = 348 px
export const IMAGE_HEIGHT_REM = 14.31; // in rem, in px = 229px

export const allImageIds = [
  // 1 row
  'wellDone',
  'teamPlayer',
  'thankYou',
  'problemSolver',
  // 2 row
  'happyBirthday',
  'appreciateYou',
  'visionary',
  'congrats',
  // 3 row
  'greatCommunication',
  'greatJob',
  'extraMile',
  'greatTeamwork',
] as const;
export type ImageId = (typeof allImageIds)[number];

export const DEFAULT_IMAGE_ID: ImageId = allImageIds[0];

export const isImageId = (src: string | null | undefined): src is ImageId => {
  if (src === null || src === undefined) {
    return false;
  }
  return allImageIds.some((x) => x === src);
};

export const allImages: Record<ImageId, string> = {
  wellDone,
  teamPlayer,
  thankYou,
  problemSolver,
  happyBirthday,
  appreciateYou,
  visionary,
  congrats,
  greatCommunication,
  greatJob,
  extraMile,
  greatTeamwork,
};

export const getPredefinedImageUrl = (imageId: ImageId): string => {
  return allImages[imageId];
};
