import React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { NotificationsContentType } from '../../types';
import EmptyNotificationsImage from '../../../../../../vendor/img/notification-empty-state.svg';
import styled from '@emotion/styled';

const NotificationsContentConnectedEmptyState: React.FC<{
  type: NotificationsContentType;
}> = ({ type }) => {
  const t = useNamespacedTranslation('notifications.emptyState');

  const noNotificationsText = type === 'task' ? t('task') : t('notification');
  return (
    <div className="d-flex flex-column py-3 justify-content-center align-items-center">
      <EmptyNotifications
        src={EmptyNotificationsImage}
        alt={noNotificationsText}
      />
      <div className="w-50 text-center">{noNotificationsText}</div>
    </div>
  );
};

const EmptyNotifications = styled.img({
  width: '18.75rem',
  height: '12.5rem',
});

export default NotificationsContentConnectedEmptyState;
