import * as React from 'react';

const ChartBarHorizontalFilled = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      ref={ref}>
      <path
        d="M17.5 8.75V11.25C17.5 11.4158 17.4342 11.5747 17.3169 11.6919C17.1997 11.8092 17.0408 11.875 16.875 11.875H3.75V13.125H10.625C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75V15.625C11.25 15.7908 11.1842 15.9497 11.0669 16.0669C10.9497 16.1842 10.7908 16.25 10.625 16.25H3.75V16.875C3.75 17.0408 3.68415 17.1997 3.56694 17.3169C3.44973 17.4342 3.29076 17.5 3.125 17.5C2.95924 17.5 2.80027 17.4342 2.68306 17.3169C2.56585 17.1997 2.5 17.0408 2.5 16.875V3.125C2.5 2.95924 2.56585 2.80027 2.68306 2.68306C2.80027 2.56585 2.95924 2.5 3.125 2.5C3.29076 2.5 3.44973 2.56585 3.56694 2.68306C3.68415 2.80027 3.75 2.95924 3.75 3.125V3.75H13.125C13.2908 3.75 13.4497 3.81585 13.5669 3.93306C13.6842 4.05027 13.75 4.20924 13.75 4.375V6.25C13.75 6.41576 13.6842 6.57473 13.5669 6.69194C13.4497 6.80915 13.2908 6.875 13.125 6.875H3.75V8.125H16.875C17.0408 8.125 17.1997 8.19085 17.3169 8.30806C17.4342 8.42527 17.5 8.58424 17.5 8.75Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ChartBarHorizontalFilled;
