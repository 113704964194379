import React, { useState } from 'react';
import ServerError from '../../../../../components/ServerError';
import PageHeader from '../../../components/PageHeader';
import SkeletonGraph from '../../../components/Skeleton/Graph';
import SkeletonPageHeader from '../../../components/Skeleton/PageHeader';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { EmployeeOvertimeTable } from './components/EmployeeOvertimeTable';
import { Card } from 'react-bootstrap';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { EmployeeOvertimePeriodFilter } from './components/EmployeeOvertimePeriodFilter';
import { useMyEmployeeOvertimeReportData, prepareChartData } from './logic';
import { FlairLabel } from '../../../../../atomic/atoms/Flairlabel/FlairLabel';
import BarChart from '../charts/BarChart';
import { managerRoutes } from '../../../manager/routes';
import { useDurationFormatter } from '../../../../../hooks/useDurationFormatter';
import i18next from 'i18next';
import { toISODateOnly } from '../../../../../utils/dateUtils';

const i18NavigationPath = 'navigation.menuItems.analytics';
const i18Path = 'analytics.employeeOvertimePage';

export const EmployeeOvertimePage: React.FC = () => {
  const tNavigation = useNamespacedTranslation(i18NavigationPath);

  useFlairBreadcrumbHook([
    {
      label: tNavigation('title'),
      url: managerRoutes.analytics.route.create({}),
    },
    { label: tNavigation('submenuItems.employeeOvertimeRate') },
  ]);

  const t = useNamespacedTranslation(i18Path);

  const today = new Date();

  const currentMonth = toISODateOnly(
    new Date(today.getFullYear(), today.getMonth(), 1),
  );

  const durationFormatter: (value: number) => string = useDurationFormatter(
    i18next.t,
  );

  const [period, setPeriod] = useState<string>(currentMonth);

  const { data, error, loading } = useMyEmployeeOvertimeReportData(period);

  if (error) {
    return <ServerError />;
  }

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!data?.reports?.employeeOvertime) {
    return null;
  }

  const { categories, seriesData } = prepareChartData(
    data.reports.employeeOvertime,
  );

  return (
    <>
      <PageHeader title={t('title')} />
      <Card>
        <Card.Header className="small">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FlairLabel text={t('month')} className="h6-regular me-2-5" />
              <EmployeeOvertimePeriodFilter
                value={period}
                onChange={setPeriod}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <BarChart
            seriesName={t('hours')}
            seriesData={seriesData}
            categories={categories}
            height={520}
            dataFormatter={durationFormatter}
          />
          <EmployeeOvertimeTable
            categories={categories}
            turnoverRates={seriesData}
            dataFormatter={durationFormatter}
          />
        </Card.Body>
      </Card>
    </>
  );
};

const LoadingSkeleton = () => (
  <>
    <SkeletonPageHeader />
    <SkeletonGraph height={520} />
    <SkeletonTable rows={2} columns={6} />
  </>
);
