import React from 'react';
import { CancelButton as CancelButtonBase } from '../button/CancelButton';
import { useFormContext } from 'react-hook-form';

type Props = {
  className?: string;
  size?: 'sm' | 'lg';
  onClick?: () => void;
};

const CancelButton: React.FC<Props> = ({
  size,
  className,
  children,
  onClick,
}) => {
  const {
    reset,
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <CancelButtonBase
      disabled={isSubmitting}
      size={size}
      label={undefined}
      className={['lift', className].join(' ')}
      onClick={(e) => {
        e.preventDefault();
        onClick ? onClick() : reset();
      }}>
      {children}
    </CancelButtonBase>
  );
};

export default CancelButton;
