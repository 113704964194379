import React from 'react';

export const CardTitle: React.FC = ({ children }) => (
  <h4 className="card-header-title">{children}</h4>
);

export const SectionTitle: React.FC = ({ children }) => (
  <span className="h5">{children}</span>
);

export const CardSubtitle: React.FC = ({ children }) => (
  <span className="small text-secondary">{children}</span>
);
