import { padStart } from 'lodash';
import moment, { Moment } from 'moment';
import {
  DateInterval,
  isOverlap,
  isValidInterval,
} from '../../../../utils/dateInterval';
import { OpenShiftOnlyRequiredFields } from './shiftsMappings';
import { minutesToHours } from '../../../../utils/time';

export type ViewByType = 'day' | 'week';

export type BaseFilter = {
  viewBy: ViewByType;
};

type MinMaxHours = {
  min: number;
  max: number;
};

export const defaultShiftHours: MinMaxHours = { min: 9, max: 18 };

export const calculateMinMaxHours = (
  today: Moment,
  intervals: DateInterval[],
): MinMaxHours => {
  const dayInterval = getDayInterval(today);
  const validIntervals = intervals.filter(
    (x) => isValidInterval(dayInterval) && isOverlap(dayInterval, x),
  );
  if (!validIntervals.length) {
    return defaultShiftHours;
  }
  return validIntervals.reduce(
    (res, currentInterval) => {
      if (currentInterval.start.isSameOrBefore(dayInterval.start)) {
        res.min = 0;
      }
      if (currentInterval.end.isSameOrAfter(dayInterval.end)) {
        res.max = 24;
      }
      res.min = Math.floor(
        Math.min(getDecimalHours(currentInterval.start), res.min),
      );
      res.max = Math.ceil(
        Math.max(getDecimalHours(currentInterval.end), res.max),
      );
      return res;
    },
    { min: 24, max: 0 },
  );
};

export const hourStr = (hour: number) =>
  padStart(hour.toString(), 2, '0') + ':00';

export const notLeastThanDefault = (src: MinMaxHours): MinMaxHours => ({
  min: Math.min(src.min, defaultShiftHours.min),
  max: Math.max(src.max, defaultShiftHours.max),
});

const getDayInterval = (day: Moment): DateInterval => ({
  start: day.clone().startOf('day'),
  end: day.clone().endOf('day'),
});

const getDecimalHours = (date: Moment): number =>
  date.hours() + minutesToHours(date.minutes());

export const canRequestOpenShift = (
  myEmployeeShiftsIntervals: DateInterval[],
  shift: OpenShiftOnlyRequiredFields,
): boolean => {
  let openShiftStart = moment(shift.flair__Start_Datetime__c);
  let openShiftEnd = moment(shift.flair__End_Datetime__c);

  let isOverlapped = myEmployeeShiftsIntervals.some((employeeShiftInterval) =>
    isOverlap(employeeShiftInterval, {
      start: openShiftStart,
      end: openShiftEnd,
    }),
  );

  return !isOverlapped;
};

export const isPastShift = (shift: OpenShiftOnlyRequiredFields): boolean => {
  let shiftEnd = moment(shift.flair__End_Datetime__c);
  let day = moment();
  return shiftEnd.isBefore(day, 'minutes');
};
