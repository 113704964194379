import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalSidebar, {
  ModalSidebarContent,
} from '../../../components/ModalSidebar';
import { ExpenseSaveForm } from './ExpenseSaveForm';

type Props = {
  onClose: () => void;
  onSave: () => void;
  show: boolean;
};

export const ExpenseSaveSidebar: React.FC<Props> = ({
  show,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  return (
    <ModalSidebar
      show={show}
      onClose={onClose}
      header={t('expenses.newExpense')}
      content={
        <ModalSidebarContent
          body={<ExpenseSaveForm onSave={onSave} />}></ModalSidebarContent>
      }></ModalSidebar>
  );
};
