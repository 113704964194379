import * as React from 'react';
import CommentsPopoverDumb, {
  Props as CommentsPopoverDumbProps,
} from './CommentsPopoverDumb';
import { useCommentsQuery } from '../../../__generated__/graphql';
import { DataSingleWithLoadingState } from '../../../../../utils/dataWithLoadingState';
import { useCommentsPopoverVisible } from './useCommentsPopoverVisible';

export type Props = Omit<
  CommentsPopoverDumbProps,
  'visible' | 'visibleChanged' | 'headerCommentsCount'
>;

const CommentsPopoverConnected: React.FC<Props> = (props) => {
  const { visible, setVisible, containerRef } = useCommentsPopoverVisible(
    props.recordId,
  );

  const { data, loading, error } = useCommentsQuery({
    variables: {
      commentableId: {
        recordId: props.recordId,
        objectName: props.relatedObjectName,
      },
    },
    skip: !visible,
  });

  const getCommentsCount = (): DataSingleWithLoadingState<number> => {
    if (loading) {
      return 'loading';
    }
    if (error) {
      return 'error';
    }
    return data?.commentable.commentsCount ?? 0;
  };

  return (
    <CommentsPopoverDumb
      {...props}
      ref={containerRef}
      headerCommentsCount={getCommentsCount()}
      visible={visible}
      visibleChanged={setVisible}
    />
  );
};

export default CommentsPopoverConnected;
