import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../../context/Toast';
import { useUserInfo } from '../../../context/UserInfo';
import { TeamCalendarFiltersType } from '../TeamCalendar/Table/TeamCalendarFilters/types';
import { DropdownActionItem, DropdownActions } from './DropdownActions';
import { generateGoogleLink, generateIcsLink } from './ICalendarUtils';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  filterData: TeamCalendarFiltersType;
};

type ContentProperties = Props & {
  calendarToken: string;
};

const Content: React.FC<ContentProperties> = ({
  calendarToken,
  filterData,
}) => {
  const { addSuccess } = useToasts();
  const { t, i18n } = useTranslation();

  const handleGoogleCalendar = () => {
    window.location.href = generateGoogleLink(
      generateIcsLink(calendarToken, filterData, i18n.language),
    );
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      generateIcsLink(calendarToken, filterData, i18n.language),
    );
    addSuccess(t('absences.icalendar.copiedIcsUrl'));
  };

  const handleDownloadIcs = () => {
    window.location.href = generateIcsLink(
      calendarToken,
      filterData,
      i18n.language,
    );
  };

  return (
    <DropdownActions>
      <DropdownActionItem onClick={handleGoogleCalendar} size="sm">
        <FlairIcon icon="document-outline" className="me-2" size="sm" />
        <span>{t('absences.icalendar.addToGoogleCalendar')}</span>
      </DropdownActionItem>
      <DropdownActionItem onClick={handleCopyUrl} size="sm">
        <FlairIcon icon="link-outline" className="me-2" size="sm" />
        <span>{t('absences.icalendar.copyToClipboard')}</span>
      </DropdownActionItem>
      <DropdownActionItem onClick={handleDownloadIcs} size="sm">
        <FlairIcon icon="download-outline" className="me-2" />
        <span>{t('absences.icalendar.downloadIcs')}</span>
      </DropdownActionItem>
    </DropdownActions>
  );
};

const ICalendar: React.FC<Props> = ({ filterData }) => {
  const { calendarToken } = useUserInfo();

  return calendarToken ? (
    <Content filterData={filterData} calendarToken={calendarToken} />
  ) : (
    <></>
  );
};

export default ICalendar;
