import { Reference } from '@apollo/client';
import { useDeleteTimeEntryChangeRequestMutation } from '../../__generated__/graphql';

export const useDeleteTimeEntryChangeRequest = (timeSheetId: string) => {
  return useDeleteTimeEntryChangeRequestMutation({
    update(cache, response) {
      const idToRemove =
        response.data?.timeTracking.deleteTimeEntryChangeRequest.recordId;
      if (!timeSheetId || !idToRemove) {
        return;
      }

      const timeSheetCacheId = cache.identify({
        Id: timeSheetId,
        __typename: 'MyTimeSheet',
      });
      cache.modify({
        id: timeSheetCacheId,
        fields: {
          timeEntryChangeRequests(
            existent: readonly Reference[],
            { readField },
          ) {
            return existent.filter(
              (changeReqRef) => readField('Id', changeReqRef) !== idToRemove,
            );
          },
        },
      });
    },
  });
};
