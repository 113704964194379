import React, { useContext } from 'react';
import CalendarMultipleFilter from '../CalendarMultipleFilter';
import { DEPARTMENTS_FILTER, SKILLS_FILTER } from '../types';
import ClearFilters from '../../../components/ClearFilters';
import { ShiftsContext } from '../index';
import Skeleton from 'react-loading-skeleton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Route } from '../../../../../utils/router';
import { VIEW_BY_PARAM } from '../../../components/Shifts/shiftsQueryUtils';
import classNames from 'classnames';

type Props = {
  route: Route<['shifts', 'all-shifts']>;
};

const i18Path = 'shiftPlanner.nav';

const ShiftsFilter: React.FC<Props> = ({ route }) => {
  const t = useNamespacedTranslation(i18Path);
  const { filtersLoading, filtersOptions, filter, viewBy } =
    useContext(ShiftsContext);

  return (
    <div
      className={classNames(
        'd-none',
        'd-md-flex',
        'align-items-center',
        'gap-3',
      )}>
      <span className="text-nowrap">{t('filterBy')}:</span>
      {filtersLoading ? (
        <Loading />
      ) : (
        <>
          <CalendarMultipleFilter
            options={filtersOptions.departments}
            filter={filter}
            filterKey={DEPARTMENTS_FILTER}
            placeholder={t('departments')}
            route={route}
          />
          <CalendarMultipleFilter
            options={filtersOptions.skills}
            filter={filter}
            filterKey={SKILLS_FILTER}
            placeholder={t('skills')}
            route={route}
          />
          <ClearFilters
            route={route
              .withQueryParams({ [VIEW_BY_PARAM]: viewBy })
              .create({})}
          />
        </>
      )}
    </div>
  );
};

const Loading: React.FC = () => {
  return (
    <>
      <Skeleton width={125} height={25} />
      <Skeleton width={125} height={25} />
    </>
  );
};

export default ShiftsFilter;
