import React, { ChangeEvent, useCallback, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../../../hooks/useNamespacedTranslation';
import TextareaAutosize from 'react-textarea-autosize';
import './styles.css';
import FlairIcon from '../../../../../../../../atomic/atoms/FlairIcon';
import { Maybe } from 'graphql/jsutils/Maybe';
import { AutoSaveIndicator } from '../../../../../../../../components/form/AutoSaveIndicator';
export type RatingInputType = {
  questionText: Maybe<string>;
  onChange?: (value: string) => void;
  onChangeNotes: (value: string) => void;
  showOptionalComment?: boolean;
  isOptional: boolean;
  isLoading: boolean;
};

const i18Path = 'performanceReview.employeeSatisfaction';

export const YesOrNoQuestionCard: React.FC<RatingInputType> = ({
  onChange,
  onChangeNotes,
  isLoading,
  showOptionalComment,
  isOptional,
  questionText,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [selectedChoice, setSelectedChoice] = useState(null);

  const handleChange = useCallback(
    (value) => {
      setSelectedChoice(value);
      onChange && onChange(String(value));
    },
    [onChange],
  );

  const handleAddComment = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeNotes(event.target.value);
    },
    [onChangeNotes],
  );
  const isYesSelected = selectedChoice === t('choices.yes');

  return (
    <div>
      <div className="mb-4">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <h3 className="mb-0">
            {!isOptional && <abbr className="text-danger me-1">*</abbr>}
            <span>{questionText}</span>
          </h3>
          <AutoSaveIndicator loading={isLoading} />
        </div>
        <div className="mb-4 d-flex gap-3 flex-row justify-content-center align-items-center">
          <div
            onClick={() => handleChange(t('choices.yes'))}
            className={`choice-btn gap-2 rounded cu-pointer d-flex align-items-center ${
              isYesSelected ? 'selected' : ''
            }`}>
            <FlairIcon icon="like" />
            <span>{t('choices.yes')}</span>
          </div>
          <div
            onClick={() => handleChange(t('choices.no'))}
            className={`choice-btn gap-2 rounded cu-pointer d-flex align-items-center ${
              selectedChoice && !isYesSelected ? 'selected' : ''
            }`}>
            <FlairIcon icon="unlike" />
            <span>{t('choices.no')}</span>
          </div>
        </div>
      </div>
      <Col xs={12}>
        {showOptionalComment && (
          <TextareaAutosize
            placeholder="Add a comment"
            className="form-control"
            onChange={handleAddComment}
          />
        )}
      </Col>
    </div>
  );
};
