import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObjectiveQuery } from '../../../../__generated__/graphql';
import { mapEmployeeOption, toObjectiveIdInput } from '../mappings';
import { GoalId } from '../types';
import { useGoalSelectOptions } from '../useGoalSelectOptions';
import { GoalCommonFieldsSkeleton } from './GoalCommonFieldsSkeleton';
import { GoalSidebarMode } from './types';
import { mapToObjectiveFormData } from './mappings';
import { mapActivities } from '../GoalsActivities/mappings';
import { GoalUpdateForm } from './GoalUpdateForm';

type Props = {
  goalId: GoalId;
  mode: GoalSidebarMode;
  onClose: () => void;
};

export const GoalSidebarUpdateContent: React.FC<Props> = ({
  goalId,
  mode,
  onClose,
}) => {
  const { t } = useTranslation();

  const {
    options,
    loading: optionsLoading,
    error: optionsError,
  } = useGoalSelectOptions();

  const { data, error, loading } = useObjectiveQuery({
    variables: {
      objectiveId: toObjectiveIdInput(goalId),
    },
  });

  if (error || optionsError) {
    return <div>{t(`performanceReview.goals2.errorLoading`)}</div>;
  }

  const isLoading = optionsLoading || loading || !data || !options;

  if (isLoading) {
    return <GoalCommonFieldsSkeleton />;
  }
  if (!data?.objective) {
    return <div>{t(`performanceReview.goals2.goalNotFound`)}</div>;
  }

  const formData = mapToObjectiveFormData(data.objective);

  const owner = data.objective.owner
    ? mapEmployeeOption(data.objective.owner)
    : undefined;

  const activities = mapActivities(data.objective, options);

  //This is done to handle the case when some goals have already inactive periods
  const periodsToShow = (options?.allPeriods ?? []).filter((p) => {
    if (p.active) {
      return true;
    } else {
      return formData.timePeriodId === p.value;
    }
  });

  return (
    <GoalUpdateForm
      goalId={goalId}
      activities={activities}
      mode={mode}
      owner={owner}
      formData={formData}
      onClose={onClose}
      options={{ ...options, periods: periodsToShow }}
    />
  );
};
