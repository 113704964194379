import { parseISO } from 'date-fns';
import {
  ObjectiveDetailsFragment,
  ObjectiveHistoryItemFragment,
  KeyResultUpdateFragment,
  EmployeeObjectiveDetailsFragment,
  TeamObjectiveDetailsFragment,
  KeyResultFragment,
  CompanyObjectiveDetailsFragment,
  ObjectiveReferenceHistoryItemFragment,
  ObjectiveReferenceType,
  ObjectiveReferenceChangeEvent,
} from '../../../../__generated__/graphql';
import { mapEmployee, mapKeyResult } from '../mappings';
import { GoalActivity, GoalRate, GoalStatus } from '../types';
import { sortBy } from 'lodash';
import { OptionBase } from '../../../../../../components/Select/types';
import { getKeyResultCurrentValueStr } from '../translations';

type Options = {
  employees: OptionBase[];
  teams: OptionBase[];
};

export const mapActivities = (
  src:
    | TeamObjectiveDetailsFragment
    | EmployeeObjectiveDetailsFragment
    | CompanyObjectiveDetailsFragment,
  options: Options,
): GoalActivity[] => {
  const keyResultUpdateActivities =
    'keyResultUpdates' in src
      ? src.keyResultUpdates.map((x) =>
          activityFromKeyResultUpdate(x, src.keyResults),
        )
      : [];

  const allObjectiveHistoryItems = src.allHistoryItems.filter(
    (x) => x.__typename === 'ObjectiveHistoryItem',
  ) as ObjectiveHistoryItemFragment[];
  const items = [
    activityCreate(src),
    ...src.allHistoryItems.map((x) =>
      activityFromHistory(x, options, allObjectiveHistoryItems),
    ),
    ...keyResultUpdateActivities,
  ];

  return sortBy(
    items.filter((x): x is GoalActivity => x !== null),
    'updatedAt',
  );
};

const activityFromKeyResultUpdate = (
  src: KeyResultUpdateFragment,
  keyResults: readonly KeyResultFragment[],
): GoalActivity | null => {
  const keyResultFragment = keyResults.find(
    (x) => x.Id === src.flair__Objective_Key_Result__c,
  );
  if (!keyResultFragment) {
    return null;
  }
  const keyResult = mapKeyResult(keyResultFragment);
  const keyResultValue = src.flair__Value__c
    ? getKeyResultCurrentValueStr(
        keyResult.type,
        parseInt(src.flair__Value__c),
        keyResult.targetValue,
      )
    : '';

  return {
    id: src.Id,
    type: 'KEY_RESULT_UPDATE',
    employee: src.createdBy ? mapEmployee(src.createdBy) : undefined,
    updatedAt: parseISO(src.CreatedDate),
    keyResultName: keyResult.name,
    comment: src.flair__Comment__c ?? undefined,
    keyResultValue,
  };
};

const activityFromStatusChanges = (
  src: ObjectiveHistoryItemFragment,
  allItems: readonly ObjectiveHistoryItemFragment[],
): GoalActivity | null => {
  if (src.newValue === 'CANCELED') {
    const cancelReasonItem = allItems.find(
      (x) =>
        x.requestId === src.requestId &&
        x.fieldApiName === 'flair__Status_Reason__c',
    );
    return {
      ...mapCommonActivityFields(src),
      type: 'CHANGE_STATUS_CANCELED',
      status: src.newValue as GoalStatus,
      cancelReason: cancelReasonItem?.newValue ?? undefined,
    };
  }
  if (src.newValue === 'DONE') {
    const rateItem = allItems.find(
      (x) =>
        x.requestId === src.requestId &&
        x.fieldApiName === 'flair__Rate__c' &&
        x.newValue &&
        x.newValue !== 'OPEN',
    );
    if (rateItem) {
      return {
        ...mapCommonActivityFields(src),
        type: 'CHANGE_STATUS_DONE_AND_RATE',
        status: src.newValue as GoalStatus,
        rate: rateItem.newValue as GoalRate,
      };
    }
  }
  return {
    ...mapCommonActivityFields(src),
    type: 'CHANGE_STATUS',
    status: src.newValue as GoalStatus,
  };
};

const activityFromHistory = (
  src: ObjectiveHistoryItemFragment | ObjectiveReferenceHistoryItemFragment,
  options: Options,
  allItems: readonly ObjectiveHistoryItemFragment[],
): GoalActivity | null => {
  if (src.__typename === 'ObjectiveHistoryItem') {
    return activityFromHistoryItem(src, options, allItems);
  }
  if (src.__typename === 'ObjectiveReferenceHistoryItem') {
    return activityFromReferenceHistoryItem(src);
  }
  return null;
};

const activityFromHistoryItem = (
  src: ObjectiveHistoryItemFragment,
  options: Options,
  allItems: readonly ObjectiveHistoryItemFragment[],
): GoalActivity | null => {
  switch (src.fieldApiName) {
    case 'flair__Status__c':
      return activityFromStatusChanges(src, allItems);
    case 'Name':
    case 'flair__Description__c':
      return {
        ...mapCommonActivityFields(src),
        type: src.fieldApiName === 'Name' ? 'SET_TITLE' : 'SET_DESCRIPTION',
        value: src.newValue ?? '',
      };
    case 'flair__Responsible__c':
      return {
        ...mapCommonActivityFields(src),
        type: 'CHANGE_RESPONSIBLE',
        from: getEmployeeName(options, src.oldValue),
        to: getEmployeeName(options, src.newValue),
      };
    case 'flair__Team__c':
      return {
        ...mapCommonActivityFields(src),
        type: 'CHANGE_RESPONSIBLE_TEAM',
        from: getTeamName(options, src.oldValue),
        to: getTeamName(options, src.newValue),
      };
    case 'flair__Is_Draft__c':
      return activityFromDraftChanged(src);
    default:
      return null;
  }
};

const activityFromReferenceHistoryItem = (
  src: ObjectiveReferenceHistoryItemFragment,
): GoalActivity | null => {
  switch (src.reference.type) {
    case ObjectiveReferenceType.KeyResult:
      return {
        ...mapCommonActivityFields(src),
        type:
          src.changeEvent === ObjectiveReferenceChangeEvent.Insert
            ? 'KEY_RESULT_ADD'
            : 'KEY_RESULT_DELETE',
        keyResultName: src.reference.name ?? '',
      };
    case ObjectiveReferenceType.Share:
      return {
        ...mapCommonActivityFields(src),
        type:
          src.changeEvent === ObjectiveReferenceChangeEvent.Insert
            ? 'SHARED_WITH_ADD'
            : 'SHARED_WITH_DELETE',
        name: src.reference.name ?? '',
      };
    case ObjectiveReferenceType.Tag:
      return {
        ...mapCommonActivityFields(src),
        type:
          src.changeEvent === ObjectiveReferenceChangeEvent.Insert
            ? 'TAG_ADD'
            : 'TAG_DELETE',
        name: src.reference.name ?? '',
      };
    default:
      return null;
  }
};

const activityFromDraftChanged = (
  src: ObjectiveHistoryItemFragment,
): GoalActivity | null => {
  if (src.newValue === 'true') {
    return {
      ...mapCommonActivityFields(src),
      type: 'MARK_AS_DRAFT',
    };
  } else if (src.oldValue === 'true' && src.newValue === 'false') {
    return {
      ...mapCommonActivityFields(src),
      type: 'REMOVE_DRAFT',
    };
  }
  return null;
};

const mapCommonActivityFields = (
  src: Pick<ObjectiveHistoryItemFragment, 'id' | 'changedBy' | 'date'>,
) => ({
  id: src.id,
  employee: src.changedBy ? mapEmployee(src.changedBy) : undefined,
  updatedAt: parseISO(src.date),
});

const getEmployeeName = (options: Options, id: string | null): string => {
  return options.employees.find((x) => x.value === id)?.label ?? '';
};

const getTeamName = (options: Options, id: string | null): string => {
  return options.teams.find((x) => x.value === id)?.label ?? '';
};

const activityCreate = (src: ObjectiveDetailsFragment): GoalActivity | null => {
  if (!src.CreatedDate) {
    return null;
  }
  return {
    id: src.Id,
    type: 'CREATE',
    employee: src.createdBy ? mapEmployee(src.createdBy) : undefined,
    updatedAt: parseISO(src.CreatedDate),
  };
};
