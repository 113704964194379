import i18next from 'i18next';
import {
  formatDate,
  parseDate,
  toISODateOnly,
} from '../../../../../utils/dateUtils';
import {
  AbsenceRateReportPayload,
  useMyAbsenceRateReportQuery,
} from '../../../__generated__/graphql';
import { Period } from './components/AbsenceRatePeriodFilter';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';

export const useMyAbsenceRateReportData = (period: Period) => {
  const currentDate = new Date();
  const startDate = getStartDate(period, currentDate);

  const { data, error, loading } = useMyAbsenceRateReportQuery({
    variables: {
      filter: {
        startDate: toISODateOnly(startDate),
        endDate: toISODateOnly(currentDate),
      },
    },
  });

  return {
    data,
    loading,
    error,
  };
};

export const prepareChartData = (data: AbsenceRateReportPayload) => {
  const categories = data.months.map((item) =>
    formatDate(
      parseDate(item.monthDate),
      i18next.t(`date.${FlairDateFormat.MonthNameShortYear}`),
    ),
  );

  const seriesData = data.months.map((item) => item.absenceRate);
  const overallAbsenceRate = data.overallAbsenceRate;

  return {
    categories,
    seriesData,
    overallAbsenceRate,
  };
};

const getStartDate = (period: Period, currentDate: Date) => {
  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );

  if (period === 'last6Months') {
    return new Date(
      firstDayOfCurrentMonth.setMonth(firstDayOfCurrentMonth.getMonth() - 5),
    );
  } else {
    return new Date(
      firstDayOfCurrentMonth.setMonth(firstDayOfCurrentMonth.getMonth() - 11),
    );
  }
};
