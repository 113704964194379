import { useEffect, useState } from 'react';
import { Job, JobsFiltersType } from '../types';
import { split } from '../../../../../utils/string';

const useJobsFiltration = (filterData: JobsFiltersType, jobs: Job[]): Job[] => {
  const [filteredJobs, setFilteredJobs] = useState<Job[]>(jobs);

  useEffect(() => {
    const filterByLocation = (job: Job) => {
      const locationsIds = split(filterData.locations);
      return !!locationsIds.length
        ? locationsIds.includes(job.location?.Id || '')
        : true;
    };
    const filterByDepartment = (job: Job) => {
      const departmentIds = split(filterData.departments);
      return !!departmentIds.length
        ? departmentIds.includes(job.department?.Id || '')
        : true;
    };

    const filterByName = (job: Job) => {
      return job.Name.toLowerCase().includes(filterData.name.toLowerCase());
    };

    const filteredJobs = jobs
      .filter(filterByLocation)
      .filter(filterByDepartment)
      .filter(filterByName);

    setFilteredJobs(filteredJobs);
  }, [
    jobs,
    setFilteredJobs,
    filterData.name,
    filterData.locations,
    filterData.departments,
  ]);

  return filteredJobs;
};

export default useJobsFiltration;
