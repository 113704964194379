import * as React from 'react';

const GiftOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.99918 3.25325V5.00284H9.74876C10.0948 5.00284 10.4331 4.90023 10.7208 4.70798C11.0085 4.51573 11.2327 4.24248 11.3652 3.92279C11.4976 3.60309 11.5322 3.25131 11.4647 2.91192C11.3972 2.57254 11.2306 2.26079 10.9859 2.01611C10.7412 1.77142 10.4295 1.60479 10.0901 1.53728C9.7507 1.46977 9.39892 1.50442 9.07923 1.63684C8.75953 1.76926 8.48628 1.99351 8.29403 2.28123C8.10179 2.56895 7.99918 2.90721 7.99918 3.25325V3.25325ZM7.99918 3.25325V5.00284H6.24959C5.90355 5.00284 5.56529 4.90023 5.27757 4.70798C4.98985 4.51573 4.7656 4.24248 4.63318 3.92279C4.50076 3.60309 4.46611 3.25131 4.53362 2.91192C4.60113 2.57254 4.76776 2.26079 5.01244 2.01611C5.25713 1.77142 5.56887 1.60479 5.90826 1.53728C6.24765 1.46977 6.59943 1.50442 6.91913 1.63684C7.23882 1.76926 7.51207 1.99351 7.70432 2.28123C7.89656 2.56895 7.99918 2.90721 7.99918 3.25325V3.25325Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.9964 5.00293H2.99879C2.44663 5.00293 1.99902 5.45054 1.99902 6.00269V7.50234C1.99902 8.05449 2.44663 8.5021 2.99879 8.5021H12.9964C13.5486 8.5021 13.9962 8.05449 13.9962 7.50234V6.00269C13.9962 5.45054 13.5486 5.00293 12.9964 5.00293Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9976 8.5021V13.001C12.9976 13.3988 12.8396 13.7802 12.5584 14.0615C12.2772 14.3427 11.8957 14.5007 11.498 14.5007H4.49965C4.10192 14.5007 3.72047 14.3427 3.43924 14.0615C3.158 13.7802 3 13.3988 3 13.001V8.5021M7.99882 5.00293V14.5007"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default GiftOutlineIcon;
