import { Theme } from '../../../../../theme';
import {
  TicketAssignee,
  TicketDictionaryItem,
  TicketEmployee,
  TicketPolicy,
  TicketPolicyVisibility,
  TicketsListItem,
  TicketStatus,
} from './types';
import {
  TicketsDictionaryDataFragment,
  TicketSortInput,
  TicketSortFieldEnum,
  AscDescEnum,
  TicketListQuery,
  TicketEmployeeDataFragment,
  TicketListDataFragment,
  TicketPolicyDataFragment,
} from '../../../__generated__/graphql';
import { SortingRule } from 'react-table';
import { TicketResponse } from 'server/src/apps/internal-shared/repositoriesShared/__generated__/repositories';
import i18next from 'i18next';

export const getTicketItemStatusColor = (status: TicketStatus): string =>
  Theme.ticket.status[status].color;

export const mapTicketSubject = (
  item: TicketsDictionaryDataFragment,
): TicketDictionaryItem => ({
  id: item.Id,
  name: item.Name,
  label: item.label,
});

const defaultSorting: TicketSortInput = {
  field: TicketSortFieldEnum.TicketNumber,
  ticket: AscDescEnum.Desc,
};

const mapSortField = (field: string): TicketSortFieldEnum => {
  switch (field) {
    case 'ticketNumber':
      return TicketSortFieldEnum.TicketNumber;
    case 'subject':
      return TicketSortFieldEnum.Subject;
    case 'category':
      return TicketSortFieldEnum.Category;
    case 'requested':
      return TicketSortFieldEnum.Requested;
    case 'lastUpdate':
      return TicketSortFieldEnum.LastUpdate;
    case 'assignee':
      return TicketSortFieldEnum.Assignee;
    case 'status':
      return TicketSortFieldEnum.Status;
    default:
      return TicketSortFieldEnum.TicketNumber;
  }
};

export const mapSortBy = (
  sortBy: SortingRule<TicketResponse>[],
): TicketSortInput => {
  if (sortBy.length < 1) {
    return defaultSorting;
  }

  return {
    field: mapSortField(sortBy[0].id),
    ticket: sortBy[0].desc ? AscDescEnum.Desc : AscDescEnum.Asc,
  };
};

export const mapTicketCategory = (
  item: TicketsDictionaryDataFragment,
): TicketDictionaryItem => ({
  id: item.Id,
  name: item.Name,
  label: item.label,
});

export const mapTicketList = (src: TicketListQuery): TicketsListItem[] => {
  return src.ticketPagination.items.map((x) => mapTicketListData(x));
};

export const mapTicketAssignee = (
  src: TicketEmployeeDataFragment,
): TicketAssignee => ({
  id: src.Id,
  name: src.Name,
  avatarUrl: src.avatar?.url ?? undefined,
});

export const mapTicketEmployee = (
  src: TicketEmployeeDataFragment | null,
  anonymous: boolean,
  employeeId: string,
): TicketEmployee => {
  if (!anonymous && src)
    return {
      id: src.Id,
      name: src.Name,
      avatarUrl: src.avatar?.url ?? undefined,
    };

  return {
    id: employeeId,
    name: i18next.t('support.tickets.anonymousUser'),
    avatarUrl: undefined,
  };
};

export const mapTicketListData = (
  src: TicketListDataFragment,
): TicketsListItem => ({
  id: src.Id,
  status: src.flair__Status__c as TicketStatus,
  ticketNumber: src.flair__Ticket_Number__c,
  category: mapTicketCategory(src.category),
  requested: src.CreatedDate,
  lastUpdate: src.LastModifiedDate,
  assignee: src.assignee?.Id ? mapTicketAssignee(src.assignee) : null,
  subject: mapTicketSubject(src.subject),
  employee: mapTicketEmployee(
    src.employee,
    src.flair__Anonymous__c,
    src.flair__Employee__c,
  ),
});

export const mapTicketPolicy = (
  ticketPolicy: TicketPolicyDataFragment | undefined,
): TicketPolicy | null => {
  if (!ticketPolicy) return null;

  return {
    id: ticketPolicy?.Id,
    categoryKey: ticketPolicy?.flair__Category_Key__c,
    ticketVisibility: mapTicketPolicyVisibility(
      ticketPolicy?.flair__Ticket_Visibility__c,
    ),
    isWhistleblowerActive: ticketPolicy?.flair__Whistleblower_Active__c,
  };
};

const mapTicketPolicyVisibility = (
  visibility: string,
): TicketPolicyVisibility => {
  switch (visibility) {
    case 'always_anonymous':
      return 'ALWAYS_ANONYMOUS';
    case 'employee_choice':
    default:
      return 'EMPLOYEE_CHOICE';
  }
};
