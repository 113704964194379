import { DirectoryFilter, DirectoryFiltersKeysArray } from './types';

export const filterFromQuery = (query: URLSearchParams): DirectoryFilter => {
  let filterFromQuery: DirectoryFilter = {
    locations: [],
    teams: [],
    departments: [],
  };
  DirectoryFiltersKeysArray.forEach((f) => {
    const filterStr = query.get(f);
    filterFromQuery[f] = filterStr ? filterStr.split(',') : [];
  });
  return filterFromQuery;
};
