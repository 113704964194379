import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import { useTranslation } from 'react-i18next';

import { addDateByUnit, formatDate } from '../../../../../utils/dateUtils';
import { getISOWeek } from 'date-fns';
import { WorkloadType } from '../../../__generated__/graphql';

type Props = {
  fromDate: Date;
  dateFromName: string;
  workloadType?: string;
};

const Nav: React.FC<Props> = ({
  fromDate,
  dateFromName,
  workloadType = WorkloadType.Weekly,
}) => {
  const { t } = useTranslation();

  const isWorkloadTypeWeekly = workloadType !== 'Monthly';

  const previous = addDateByUnit(
    fromDate,
    -1,
    isWorkloadTypeWeekly ? 'week' : 'month',
  );

  const next = addDateByUnit(
    fromDate,
    1,
    isWorkloadTypeWeekly ? 'week' : 'month',
  );

  const formatParamLink = (date: Date) => formatDate(date, 'yyyy-MM-dd');

  return (
    <div className="text-end">
      <ButtonGroup size="sm" className="bg-gray rounded p-1">
        <Link
          to={routes.timeTracking.route.withQueryParams({
            [dateFromName]: formatParamLink(previous),
            workloadType,
          })}
          className="btn btn-gray">
          ←
        </Link>
        <Link
          to={routes.timeTracking.route.withQueryParams({
            [dateFromName]: formatParamLink(fromDate),
            workloadType,
          })}
          className="btn btn-sm btn-white rounded">
          {isWorkloadTypeWeekly
            ? t('calendarWeek', { week: getISOWeek(fromDate) })
            : formatDate(fromDate, 'LLLL')}
        </Link>
        <Link
          to={routes.timeTracking.route.withQueryParams({
            [dateFromName]: formatParamLink(next),
            workloadType,
          })}
          className="btn btn-gray">
          →
        </Link>
      </ButtonGroup>
    </div>
  );
};

export default Nav;
