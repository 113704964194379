import * as React from 'react';

const QuestionCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.8125 11.5C8.15768 11.5 8.4375 11.2202 8.4375 10.875C8.4375 10.5298 8.15768 10.25 7.8125 10.25C7.46732 10.25 7.1875 10.5298 7.1875 10.875C7.1875 11.2202 7.46732 11.5 7.8125 11.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28126 6.37514C9.28126 5.86647 8.90292 5.60936 8.62172 5.47107L7.16062 5.64387C6.94219 5.82457 6.83191 6.02356 6.77536 6.17356C6.74687 6.24916 6.73217 6.31209 6.72478 6.35292C6.72111 6.37324 6.71931 6.38772 6.71852 6.39511C6.71828 6.3974 6.71813 6.39898 6.71806 6.39984C6.70463 6.63848 6.50175 6.82264 6.26225 6.81223C6.02086 6.80173 5.83367 6.59753 5.84417 6.35613L6.26854 6.37459C5.84417 6.35613 5.84418 6.35593 5.84419 6.35573L5.84421 6.35531L5.84425 6.35443L5.84434 6.35249C5.84441 6.35112 5.8445 6.3496 5.84459 6.34794C5.84479 6.3446 5.84504 6.34067 5.84537 6.33616C5.84604 6.32714 5.84702 6.31581 5.84845 6.30235C5.85132 6.27546 5.85603 6.23991 5.86375 6.1972C5.87916 6.112 5.90682 5.99699 5.95661 5.86492C6.05663 5.59959 6.2466 5.2644 6.60288 4.96967C7.04928 4.60041 7.57133 4.50604 7.99401 4.50025L7.99432 4.50025L7.99462 4.50024C8.36824 4.49564 8.74349 4.55653 9.00654 4.68523L9.0069 4.68541L9.00726 4.68559C9.33868 4.84849 10.1563 5.32446 10.1563 6.37514C10.1563 7.43707 9.44406 7.92841 8.87035 8.32421L8.85097 8.33759C8.59431 8.51471 8.46333 8.66927 8.39252 8.80137C8.32324 8.93062 8.29428 9.0704 8.29428 9.25C8.29428 9.49162 8.0984 9.6875 7.85678 9.6875C7.61515 9.6875 7.41928 9.49162 7.41928 9.25C7.41928 8.9689 7.46623 8.67736 7.62132 8.388C7.77489 8.1015 8.01675 7.85016 8.35396 7.61744C8.96478 7.1959 9.28126 6.9424 9.28126 6.37514ZM8.0057 5.37517C8.30231 5.37155 8.52622 5.42445 8.62172 5.47107L7.16062 5.64387C7.39663 5.44865 7.69409 5.37948 8.0057 5.37517Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66658 3.01118C5.65328 2.35189 6.81331 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.18669 13.6481 10.3467 12.9888 11.3334C12.3295 12.3201 11.3925 13.0892 10.2961 13.5433C9.19975 13.9974 7.99335 14.1162 6.82946 13.8847C5.66557 13.6532 4.59648 13.0818 3.75736 12.2426C2.91825 11.4035 2.3468 10.3344 2.11529 9.17054C1.88378 8.00666 2.0026 6.80026 2.45673 5.7039C2.91085 4.60754 3.67989 3.67047 4.66658 3.01118ZM8 3C7.0111 3 6.0444 3.29324 5.22215 3.84265C4.39991 4.39206 3.75904 5.17295 3.3806 6.08658C3.00217 7.00021 2.90315 8.00555 3.09608 8.97545C3.289 9.94536 3.76521 10.8363 4.46447 11.5355C5.16373 12.2348 6.05465 12.711 7.02455 12.9039C7.99446 13.0969 8.99979 12.9978 9.91342 12.6194C10.8271 12.241 11.6079 11.6001 12.1574 10.7779C12.7068 9.95561 13 8.98891 13 8C13 6.67392 12.4732 5.40215 11.5355 4.46447C10.5979 3.52678 9.32608 3 8 3Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default QuestionCircleOutlineIcon;
