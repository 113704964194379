import React from 'react';
import { useParams } from 'react-router-dom';
import { OrganizationInfo } from '../../../../components/OrganizationInfo';
import ServerError from '../../../../components/ServerError';
import { useOrganizationInfoQuery } from '../../__generated__/graphql';

type RouteParams = {
  organizationId: string;
};

export const OrganizationInfoPublicPage: React.FC = () => {
  const { organizationId } = useParams<RouteParams>();
  const { data, loading, error } = useOrganizationInfoQuery({
    variables: {
      organizationId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  return (
    <OrganizationInfo
      salesforceUserId={data.organizationInfo.salesforceUserId}
      flairHubPermissionSet={data.organizationInfo.flairHubPermissionSet}
    />
  );
};

const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border" role="status"></div>
  </div>
);
