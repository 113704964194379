import { Col, Row } from 'react-bootstrap';
import { getChangeType, getIcon } from './utils';
import React from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../theme';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { useTranslation } from 'react-i18next';
import { EmployeeHistory, HistoryFieldType } from './types';
import './EmployeeHistoryTable.css';
import { formatDateShort } from '../../../../../utils/dateUtils';

type Props = {
  historyData: EmployeeHistory[];
};

const EmployeeHistoryTable: React.FC<Props> = ({ historyData }) => {
  const { t } = useTranslation();

  if (!historyData.length) {
    return <EmptyStateCardBody />;
  }

  return (
    <>
      {historyData.map((item, index) => (
        <Row key={index}>
          <Col xs={8} className="d-flex align-items-center">
            <div className="d-flex align-items-center flex-column">
              <div className={getTopLineClassName(index)}></div>
              <FlairIcon
                size={'2x'}
                icon={getIcon(item.Name as HistoryFieldType)}
                color={Theme.color.blue3}
              />
              <div
                className={getBottomLineClassName(
                  index,
                  historyData.length - 1,
                )}></div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <span className="ms-3 h4-medium">
                {t(getChangeType(item.Name as HistoryFieldType))}
              </span>
              <span className="change-value ms-3 h5-regular">
                {item?.flair__New_Value__c ?? item?.flair__Old_Value__c}
              </span>
            </div>
          </Col>
          <Col xs={4} className="d-flex justify-content-end align-items-center">
            <div className="text-end">
              <span
                className="ms-3 h5-regular"
                style={{ color: Theme.employeeHistory.date }}>
                {formatDateShort(item.CreatedDate)}
              </span>
            </div>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default EmployeeHistoryTable;

const getTopLineClassName = (index: number): string => {
  if (index === 0) {
    return 'vertical-line first-line';
  }
  return 'vertical-line';
};

const getBottomLineClassName = (index: number, lastIndex?: number): string => {
  if (index === lastIndex) {
    return 'vertical-line last-line';
  }

  return 'vertical-line';
};
