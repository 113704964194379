import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import FormattedTime from '../../../../components/time/FormattedTime';
import FormattedDate from '../../../../components/date/FormattedDate';

type Props = {
  start: string | Date | moment.Moment;
  end: string | Date | moment.Moment;
};

const Container = styled.div({
  paddingLeft: 5,
  paddingRight: 5,
});

export const ShiftLabel: React.FC<Props> = ({ start, end }) => {
  const isMultiday = !moment(start).isSame(end, 'day');
  return (
    <Container>
      <FormattedTime time={start} />
      {' – '}
      <FormattedTime time={end} />
      {isMultiday && (
        <span>
          {' '}
          (<FormattedDate day={end} format="short" />)
        </span>
      )}
    </Container>
  );
};
