import React from 'react';
import { CustomizableItem } from '../../../../../components/CustomizeItems/CustomizeItems';
import { CustomizeItemsModal } from '../../../../../components/CustomizeItems';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { TimeSheetColumnId, TimeSheetColumnSetting } from './types';

type Props = {
  visible: boolean;
  timeSheetColumnSettings: TimeSheetColumnSetting[];
  onSave: (value: TimeSheetColumnSetting[]) => void;
  onClose: () => void;
};

export const CustomizeTableColumnsModal: React.FC<Props> = ({
  timeSheetColumnSettings,
  onSave,
  visible,
  onClose,
}) => {
  const t = useNamespacedTranslation('timeSheetsControlling.table');

  const customizableItems = timeSheetColumnSettings.map((columnSetting) => ({
    id: columnSetting.id,
    checked: columnSetting.checked,
    name: t('header.' + columnSetting.id),
  }));

  const handleOnSave = (items: CustomizableItem[]) => {
    onSave(
      items.map((x) => ({ id: x.id as TimeSheetColumnId, checked: x.checked })),
    );
    onClose();
  };

  return (
    <CustomizeItemsModal
      items={customizableItems}
      header={t('customizeColumns')}
      onSave={handleOnSave}
      visible={visible}
      onClose={onClose}
      isProcessing={false}
    />
  );
};
