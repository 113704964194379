import React, { useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useNamespacedTranslation } from '../../../../../../../../hooks/useNamespacedTranslation';
import { Maybe } from 'graphql/jsutils/Maybe';

export type RatingInputType = {
  value: string;
  questionText: Maybe<string>;
  onChange: (value: string) => void;
  isOptional: boolean;
};

const i18Path = 'performanceReview.employeeSatisfaction';

export const SatisfactionFreeTextInput: React.FC<RatingInputType> = ({
  value,
  onChange,
  questionText,
  isOptional,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <div>
      <div className="mb-4">
        <div className="mb-4">
          <h3 className="mb-0">
            {!isOptional && <abbr className="text-danger me-1">*</abbr>}
            <span>{questionText}</span>
          </h3>
        </div>
        <div className="mb-4 d-flex gap-3 flex-row justify-content-center align-items-center">
          <TextareaAutosize
            placeholder={t('placeholder')}
            className="form-control"
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
