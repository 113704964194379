import React from 'react';
import FlairFormattedDate from '../../atoms/FlairFormattedDate';
import { FlairDateFormat } from '../../atoms/FlairFormattedDate/types';

export type FlairDateRangeProps = {
  dateFrom: string | Date;
  dateTo: string | Date;
  format?: FlairDateFormat;
  className?: string;
  dateClassName?: string;
};

const FlairDateRange: React.FC<FlairDateRangeProps> = ({
  dateFrom,
  dateTo,
  format,
  className,
  dateClassName,
}) => {
  return (
    <span className={className}>
      <FlairFormattedDate
        day={dateFrom}
        format={format}
        className={dateClassName}
      />
      {' - '}
      <FlairFormattedDate
        day={dateTo}
        format={format}
        className={dateClassName}
      />
    </span>
  );
};

export default FlairDateRange;
