import React from 'react';

type Props = {
  title: string;
  prevValue: string | null;
  nextValue: string;
};

export const ValueChangeRow: React.FC<Props> = ({
  title,
  prevValue,
  nextValue,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <span className="h7-medium">{title}</span>
      <div className="d-flex gap-1">
        {prevValue && (
          <>
            <span>{prevValue}</span>
            <span>→</span>
          </>
        )}
        <span>{nextValue}</span>
      </div>
    </div>
  );
};
