import React, { useState, useEffect, useMemo } from 'react';
import { SortingRule } from 'react-table';

type SortState = {
  sortBy: SortingRule<string>[] | undefined;
  onSortChange: (value: SortingRule<string>[]) => void;
};

type ContextProps = {
  storeAgeKey: string;
};

const TableSortContext = React.createContext<SortState>({
  sortBy: undefined,
  onSortChange: () => null,
});

export const TableSortProvider: React.FC<ContextProps> = (props) => {
  const { storeAgeKey } = props;
  const [sortBy, setSortBy] = useState<SortingRule<string>[] | undefined>(
    undefined,
  );
  const defaultSortValues = localStorage.getItem(storeAgeKey);
  const activeSortOptions = useMemo(() => sortBy, [sortBy]);
  const handleSortChange = (value: SortingRule<string>[]) => {
    setSortBy(value);
    localStorage.setItem(storeAgeKey, JSON.stringify(value));
  };

  useEffect(() => {
    if (defaultSortValues) {
      setSortBy(JSON.parse(defaultSortValues));
    }
  }, [defaultSortValues]);

  useEffect(() => {
    return localStorage.removeItem(storeAgeKey);
  }, [storeAgeKey]);

  return (
    <TableSortContext.Provider
      value={{ sortBy: activeSortOptions, onSortChange: handleSortChange }}
      {...props}
    />
  );
};

export const useTableSort = () => React.useContext(TableSortContext);
