import { isDayInFuture, toISODateOnly } from '../../../../utils/dateUtils';
import {
  TimeBalanceSettingsFragment,
  TimeFrameworkFragment,
  TimeLimitSettingsFragment,
  TimeTrackingSettings_WorkloadFragment,
  useTimeTrackingSettingsQuery,
  WorkloadWeekFragment,
} from '../../__generated__/graphql';

export type TimeTrackingSettings = {
  workload: TimeTrackingSettings_WorkloadFragment | null;
  workloadWeek: WorkloadWeekFragment | null;
  timeFramework: TimeFrameworkFragment | null;
  timeBalanceSettings: TimeBalanceSettingsFragment | null;
  timeLimitSettings: TimeLimitSettingsFragment | null;
};

export const useTimeTrackingSettings = (): TimeTrackingSettings & {
  loading: boolean;
} => {
  const now = new Date();
  const today = toISODateOnly(now);
  const { data, loading } = useTimeTrackingSettingsQuery({
    variables: {
      today,
    },
  });
  const workload = data?.me.workloads[0];

  return {
    ...mapTimeTrackingSettings(workload ?? null),
    loading,
  };
};

export const useRestrictFutureEntries = () => {
  const { timeLimitSettings } = useTimeTrackingSettings();

  const shouldRestrictFutureEntries =
    timeLimitSettings?.flair__Is_Restrict_Future_Entries_Enabled__c === true;
  return {
    shouldRestrictFutureEntries,
    restrictFutureEntriesForADay: (date: Date): boolean => {
      const today = new Date();
      return shouldRestrictFutureEntries && isDayInFuture(today, date);
    },
  };
};

export const mapTimeTrackingSettings = (
  workload: TimeTrackingSettings_WorkloadFragment | null,
): TimeTrackingSettings => ({
  workload: workload ?? null,
  workloadWeek: workload?.workloadWeeks[0] ?? null,
  timeFramework: workload?.timeFramework ?? null,
  timeBalanceSettings: workload?.timeFramework?.timeBalanceSettings ?? null,
  timeLimitSettings: workload?.timeFramework?.timeLimitSettings ?? null,
});
