import i18next from 'i18next';
import { Theme } from '../../../../../theme';
import { median } from '../../../../../utils/array';
import { isSkillLevel, SkillLevel } from './types';

type SkillLevelRenderInfo = {
  color: string;
  label: string; // N/A, 1, 2 ...
  title: string; // N/A, Beginner, Elementary ...
};

const prefix = 'skills.levels';

const theme = Theme.skills;
export const getSkillLevelRenderInfo = (
  value: SkillLevel | undefined,
): SkillLevelRenderInfo => {
  const label = getSkillLevelLabel(value);
  const title = getSkillLevelTitle(value);
  switch (value) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return { color: theme.level[value].bgColor, title, label };
    case null:
      return { color: theme.level['na'].bgColor, title, label };
    case undefined:
      return { color: theme.level['none'].bgColor, title, label };
  }
};

export const getSkillLevelTitle = (value: SkillLevel | undefined): string => {
  switch (value) {
    case null:
      return i18next.t(`${prefix}.notSet`);
    case undefined:
      return i18next.t(`${prefix}.notDefined`);
    default:
      return i18next.t(`${prefix}.${value}`);
  }
};

export const getSkillLevelLabel = (value: SkillLevel | undefined): string => {
  switch (value) {
    case null:
      return i18next.t(`${prefix}.na`);
    case undefined:
      return '-';
    default:
      return value.toString();
  }
};

export const getSkillLevelMedian = (
  levels: SkillLevel[],
): number | undefined => {
  const notNullLevels: Exclude<SkillLevel, null>[] = levels.filter(
    (x): x is Exclude<SkillLevel, null> => x !== null,
  );
  return median(notNullLevels);
};

export const getClosestSkillLevel = (
  value: number,
): Exclude<SkillLevel, null> | undefined => {
  const result = Math.floor(value);
  return isSkillLevel(result) ? result : undefined;
};
