import styled from '@emotion/styled';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  value: string;
  onChange: (value: string) => void;
  readOnly: boolean;
};

const LabelRow = styled(Row)({
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const ButtonLink = styled(Button)({
  padding: 0,
});

export const EmployeeFeedbackNotes: React.FC<Props> = ({
  value,
  onChange,
  readOnly,
}) => {
  const { t } = useTranslation();
  const [showNotes, setShowNotes] = useState<boolean>(!!value);
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );
  const handleAddNotes = useCallback(() => setShowNotes(true), [setShowNotes]);
  const handleRemoveNotes = useCallback(() => {
    if (value !== '') {
      onChange('');
    }

    setShowNotes(false);
  }, [setShowNotes, onChange, value]);

  return (
    <div className="mt-2">
      {showNotes ? (
        <>
          <LabelRow>
            <Col>
              <span>{t('employeeFeedbackQuestionnaire.notes.label')}</span>
            </Col>
            {!readOnly && (
              <Col className="col-auto">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="remove-notes">
                      {t('employeeFeedbackQuestionnaire.notes.removeNotes')}
                    </Tooltip>
                  }>
                  <ButtonLink variant="link" onClick={handleRemoveNotes}>
                    <FlairIcon icon="trash-outline" className="text-dark" />
                  </ButtonLink>
                </OverlayTrigger>
              </Col>
            )}
          </LabelRow>
          <TextareaAutosize
            className="form-control"
            value={value}
            onChange={handleChange}
            readOnly={readOnly}
            disabled={readOnly}
          />
        </>
      ) : readOnly ? null : (
        <ButtonLink variant="link" onClick={handleAddNotes}>
          {t('employeeFeedbackQuestionnaire.notes.addNotes')}
        </ButtonLink>
      )}
    </div>
  );
};
