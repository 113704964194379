import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { NavLink } from '../../../../Router';
import { NavLinkIcon } from '../../../atoms/navigation/NavLinkIcon';
import { NavLinkLabel } from '../../../atoms/navigation/NavLinkLabel';
import classNames from 'classnames';
import { MenuItemType } from '../types';
import { NavItemWithSubItems } from '../NavItemWithSubItems/NavItemWithSubItems';

export type NavItemProps = {
  menuItem: MenuItemType;
  //Navigational Item is each item that will navigate to a new route
  onNavigationalItemClick?: () => void;
};

export const NavItem: React.FC<NavItemProps> = ({
  menuItem,
  onNavigationalItemClick,
}) => {
  const location = useLocation();
  const isActive = menuItem.route?.template() === location.pathname;

  if (!menuItem.shouldRender) {
    return null;
  }

  if (menuItem.subItems) {
    return (
      <NavItemWithSubItems
        menuItem={menuItem}
        onSubNavItemClick={onNavigationalItemClick}
      />
    );
  }

  return (
    <Nav.Item onClick={onNavigationalItemClick}>
      <NavLink
        exact={menuItem.route?.template() === '/'}
        to={menuItem.route}
        className={classNames('nav-link', 'd-flex', 'align-items-center')}>
        <NavLinkIcon isActive={isActive}>
          {isActive ? menuItem.activeIcon : menuItem.icon}
        </NavLinkIcon>
        <NavLinkLabel data-testid={menuItem.label}>
          {menuItem.label}
        </NavLinkLabel>
        {menuItem.suffix}
      </NavLink>
    </Nav.Item>
  );
};
