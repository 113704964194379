import { ChartData, ChartOptions } from 'chart.js';
import { times } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Bar from '../../../../../components/charts/Bar';
import { Theme } from '../../../../../theme';
import { hoursToMinutes } from '../../../../../utils/time';
import { formattedDuration } from '../../DurationFormat';
import { TrackedTime } from '../../TrackedTime';
import { addDays, format, startOfISOWeek } from 'date-fns';
import { buildBarData } from './logic';

export type Props = {
  startDate: Date;
  tracked: TrackedTime;
};

const theme = Theme.chart.timeTracking;

const chartOptions = (
  durationFormatter: (n: number) => string,
): ChartOptions<'bar'> => ({
  plugins: {
    tooltip: {
      filter: (context) => {
        // Planned
        if (context.datasetIndex === 0) {
          return true;
        }

        const hours = context.dataset.data[context.dataIndex];
        return hours > 0;
      },
      callbacks: {
        label: (context) => {
          const label = context.dataset.label;
          const hours = context.dataset.data[context.dataIndex];
          return `<div class="d-flex align-items-center justify-content-between gap-2">
          <div>${label}</div>
          <div>
          ${durationFormatter(hoursToMinutes(hours))}
          </div>
          </div>
          `;
        },
      },
    },
  },
  scales: {
    y: {
      stacked: true,
      suggestedMax: 8,
      ticks: {
        stepSize: 4,
        color: theme.ticks.color,
      },
      grid: {
        color: theme.gridLines.color,
      },
    },
    x: {
      stacked: true,
      ticks: {
        color: theme.ticks.color,
      },
      grid: {
        color: theme.gridLines.color,
      },
    },
  },
});

const WEEKDAY_FORMAT = 'E';

const WeekChart: React.FC<Props> = ({ startDate, tracked }) => {
  const { t } = useTranslation();
  const durationFormatter = formattedDuration(t);

  const weekdays = times(7, (i) => addDays(startOfISOWeek(startDate), i));

  const barData = buildBarData(tracked, weekdays);

  const chartData: ChartData<'bar'> = {
    labels: weekdays.map((w) => format(w, WEEKDAY_FORMAT)),
    datasets: [
      {
        backgroundColor: theme.absence.color,
        label: t('timeTracking.graphs.week.absence'),
        data: barData.absence,
      },
      {
        backgroundColor: theme.holiday.color,
        label: t('timeTracking.graphs.week.holiday'),
        data: barData.holiday,
      },
      {
        backgroundColor: theme.tracked.color,
        label: t('timeTracking.graphs.week.regular'),
        data: barData.regular,
      },
      {
        backgroundColor: theme.deficit.color,
        label: t('timeTracking.graphs.week.deficit'),
        data: barData.deficit,
      },
      {
        backgroundColor: theme.overtime.color,
        label: t('timeTracking.graphs.week.overtime'),
        data: barData.overtime,
      },
    ],
  };

  return <Bar data={chartData} options={chartOptions(durationFormatter)} />;
};

export default WeekChart;
