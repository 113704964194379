import React, { useCallback, useEffect, useState } from 'react';
import { useToasts } from '../../../../context/Toast';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { toBase64 } from '../../../../utils/file';
import { useUserInfo } from '../../context/UserInfo';
import { useUpdateMyAvatarMutation } from '../../__generated__/graphql';
import { EmployeeAvatarInputModal } from './EmployeeAvatarInputModal';

type Props = {
  show: boolean;
  onClose: () => void;
};

type VisibleModal = null | 'updateModal' | 'deleteConfirmation';

const i18prefix = 'errors.toast';

export const EmployeeAvatarInputModalConnected: React.FC<Props> = ({
  show,
  onClose,
}) => {
  const { avatarUrl } = useUserInfo();
  const [visibleModal, setVisibleModal] = useState<VisibleModal>(null);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const { addError } = useToasts();
  const t = useNamespacedTranslation(i18prefix);

  const [updateMyAvatar] = useUpdateMyAvatarMutation();

  useEffect(() => {
    setVisibleModal(show ? 'updateModal' : null);
  }, [show, setVisibleModal]);

  const handleUpdate = useCallback(
    async (file?: File) => {
      setUpdateInProgress(true);
      try {
        await updateMyAvatar({
          variables: {
            input: file
              ? {
                  fileName: file.name,
                  fileContentBase64: await toBase64(file),
                }
              : null,
          },
        });
      } catch {
        addError(t('genericError'));
      } finally {
        onClose();
        setUpdateInProgress(false);
      }
    },
    [addError, onClose, t, updateMyAvatar],
  );

  return (
    <EmployeeAvatarInputModal
      show={visibleModal === 'updateModal'}
      avatarUrl={avatarUrl}
      onClose={onClose}
      updateInProgress={updateInProgress}
      onUpdate={handleUpdate}
    />
  );
};
