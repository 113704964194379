import React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { EmptyStateCardBody } from '../../../../components/EmptyStateCard';
import { EmptyReason } from '../types';

const i18Path = 'myData.inventory.empty';

type Props = {
  emptyReason: EmptyReason;
};
export const EmptyInventory: React.FC<Props> = ({ emptyReason }) => {
  const t = useNamespacedTranslation(i18Path);

  return emptyReason === 'empty' ? (
    <EmptyStateCardBody title={t('initial')} />
  ) : (
    <EmptyStateCardBody
      title={t('filtered.title')}
      subtitle={t('filtered.subTitle')}
    />
  );
};
