import { Moment } from 'moment';
import { Theme } from '../../../../../theme';
import {
  DateInterval,
  isOverlap,
  sortBlockInfoIntervals,
} from '../../../../../utils/dateInterval';
import { getShiftBlockColorsInfo } from '../Common/logic';
import { ShiftInfo, ShiftInfoBlock } from '../Common/types';
import { BaseFilter } from '../shiftsLogic';
import { BorderInfo, WeeklyShiftStylingInfo } from './weeklyShiftsTypes';

export type WeeklyInfo = {
  podsGroupedByDay: Map<Moment, WeeklyShiftPod[]>;
};

// start and end of Pod is not necessary, but nice to have while creating
export type WeeklyShiftPod = DateInterval & {
  day: Moment;
  shifts: ShiftInfoBlock[];
};

export type WeeklyFilter = BaseFilter & {
  weekStart: Moment;
  additionalEmployeeIds: string[];
};

export const groupByPods = (shifts: ShiftInfoBlock[]): WeeklyShiftPod[] => {
  if (!shifts.length) {
    return [];
  }
  const sortedShifts = sortBlockInfoIntervals(shifts);
  const firstPod = createPod(sortedShifts[0]);

  return sortedShifts.reduce(
    (resultPods, currentShift, i) => {
      // skip first element
      if (i === 0) {
        return resultPods;
      }
      const lastPod: WeeklyShiftPod = resultPods[resultPods.length - 1];
      const currentShiftStartOfDay = currentShift.blockInfo.start
        .clone()
        .startOf('day');
      if (
        !isOverlap(lastPod, currentShift.blockInfo) ||
        !currentShiftStartOfDay.isSame(lastPod.day)
      ) {
        resultPods.push(createPod(currentShift));
      } else {
        lastPod.end = getMaxEnd([currentShift, ...lastPod.shifts]);
        lastPod.shifts.push(currentShift);
      }
      return resultPods;
    },
    [firstPod],
  );
};

export const groupPodsByDay = (
  pods: WeeklyShiftPod[],
): Map<string, WeeklyShiftPod[]> =>
  pods.reduce((resMap, cur) => {
    if (resMap.has(toKey(cur.day))) {
      resMap.get(toKey(cur.day))!.push(cur);
    } else {
      resMap.set(toKey(cur.day), [cur]);
    }
    return resMap;
  }, new Map<string, WeeklyShiftPod[]>());

export const toKey = (day: Moment): string => day.toISOString();

const createPod = (shift: ShiftInfoBlock): WeeklyShiftPod => {
  const currentShiftStartOfDay = shift.blockInfo.start.clone().startOf('day');
  const currentShiftEndOfDay = currentShiftStartOfDay.clone().add(1, 'day');
  return {
    day: currentShiftStartOfDay,
    start: shift.blockInfo.start.clone(),
    end: shift.blockInfo.end.isBefore(currentShiftEndOfDay)
      ? shift.blockInfo.end.clone()
      : currentShiftEndOfDay,
    shifts: [shift],
  };
};

// [WeeklyShiftInfo, ...WeeklyShiftInfo[]] is for define array of one or more elements,
// if you know better way please let me know
const getMaxEnd = (shifts: [ShiftInfo, ...ShiftInfo[]]): Moment => {
  return shifts.reduce(
    (maxEnd, x) => (x.end.isAfter(maxEnd) ? x.end : maxEnd),
    shifts[0].end,
  );
};

export const getWeeklyShiftBlockBorderInfo = (
  shift: ShiftInfoBlock,
): BorderInfo => {
  const { blockInfo } = shift;

  if (blockInfo.end.isBefore(shift.end, 'day')) {
    return {
      isTopBorderVisible: true,
      isBottomBorderVisible: false,
    };
  } else if (blockInfo.start.isAfter(shift.start, 'day')) {
    return {
      isTopBorderVisible: false,
      isBottomBorderVisible: true,
    };
  }

  return {
    isTopBorderVisible: true,
    isBottomBorderVisible: true,
  };
};

export const getWeeklyShiftBlockStylingInfo = (
  shift: ShiftInfoBlock,
): WeeklyShiftStylingInfo => {
  const shiftBlockColorsInfo = getShiftBlockColorsInfo(
    shift,
    'isMy' in shift ? shift.isMy : false,
  );

  const { isTopBorderVisible, isBottomBorderVisible } =
    getWeeklyShiftBlockBorderInfo(shift);

  const noBorder = '0 0';
  const borderWidth = Theme.border.radius;

  return {
    ...shiftBlockColorsInfo,
    borderTop: isTopBorderVisible ? shiftBlockColorsInfo.border : 'unset',
    borderBottom: isBottomBorderVisible ? shiftBlockColorsInfo.border : 'unset',
    borderRadius: `${isTopBorderVisible ? borderWidth : noBorder} ${
      isBottomBorderVisible ? borderWidth : noBorder
    }`,
  };
};
