import * as React from 'react';

const LinkOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54964)">
        <path
          d="M6.4998 10.9979H4.50027C3.70481 10.9979 2.94193 10.6819 2.37945 10.1194C1.81697 9.5569 1.50098 8.79402 1.50098 7.99856C1.50098 7.2031 1.81697 6.44022 2.37945 5.87774C2.94193 5.31526 3.70481 4.99927 4.50027 4.99927H6.4998M9.49909 4.99927H11.4986C12.2941 4.99927 13.057 5.31526 13.6194 5.87774C14.1819 6.44022 14.4979 7.2031 14.4979 7.99856C14.4979 8.79402 14.1819 9.5569 13.6194 10.1194C13.057 10.6819 12.2941 10.9979 11.4986 10.9979H9.49909M5.10294 7.99856H10.9584"
          stroke="currentColor"
          strokeWidth="1.12473"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54964">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000732422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default LinkOutlineIcon;
