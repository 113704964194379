import React, { useCallback } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes';
import { SidebarBadge } from '../../SidebarBadge';
import {
  InboxNotificationsContentConnected,
  ArchivedNotificationsContentConnected,
  TaskNotificationsContentConnected,
} from '../NotificationsContentConnected';
import ServerError from '../../../../../components/ServerError';
import {
  useTaskNotificationsUnreadCountQuery,
  useTaskNotificationsTotalCountQuery,
} from '../../../__generated__/graphql';

const Container = styled.div({
  minWidth: '43.75rem',
  maxWidth: '43.75rem',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: Theme.color.white,
  borderColor: Theme.color.paper3,
});

const Header = styled(Nav)({
  borderColor: Theme.color.paper3,
});

// empty styled div for the readability
const HeaderItem = styled.div();

const NavLinkStyled = styled(Nav.Link)({
  color: Theme.color.paper1,
  borderBottom: '2px solid',
  fontWeight: 500,
  borderColor: `transparent!important`,
  '&.active': {
    borderColor: `${Theme.color.blue3} !important`,
  },
});

const NavLinkIconStyled = styled(Nav.Link)();

const NotificationsContent: React.FC<{
  inboxUnreadCount: number;
  onTasksViewed: (viewed: boolean) => void;
  onClose: () => void;
}> = ({ inboxUnreadCount, onTasksViewed, onClose }) => {
  const history = useHistory();
  const handleSettingsOnClick = useCallback(() => {
    history.push(routes.notificationSettings.route.create({}));
    onClose();
  }, [history, onClose]);
  const { t } = useTranslation();

  const { data: taskUnreadCountData, error: taskUnreadCountError } =
    useTaskNotificationsUnreadCountQuery();

  const taskUnreadCount =
    taskUnreadCountData?.me.taskNotifications.unreadCount ?? 0;

  const { data: taskCountData, error: taskCountError } =
    useTaskNotificationsTotalCountQuery();

  const taskCount =
    taskCountData?.me.taskNotifications.paginationInfo.totalCount ?? 0;

  if (taskUnreadCountError || taskCountError) return <ServerError />;

  return (
    <Container className="border rounded-3">
      <Tab.Container defaultActiveKey="inbox">
        <Header className="d-flex flex-row justify-content-between navbar-nav border-bottom px-3 w-100">
          <HeaderItem className="d-flex flex-row gap-3 align-items-center">
            <Nav.Item>
              <NavLinkStyled
                eventKey="inbox"
                className="py-3 d-flex flex-row gap-2">
                {t('notifications.tabs.inbox')}
                {inboxUnreadCount > 0 && (
                  <SidebarBadge type="attention" loading={false}>
                    {inboxUnreadCount}
                  </SidebarBadge>
                )}
              </NavLinkStyled>
            </Nav.Item>
            <Nav.Item>
              <NavLinkStyled
                eventKey="task"
                onClick={() => {
                  onTasksViewed(true);
                }}
                className="py-3 d-flex flex-row gap-2">
                {t('notifications.tabs.tasks')}
                {taskUnreadCount > 0 ? (
                  <SidebarBadge type="attention" loading={false}>
                    {taskUnreadCount}
                  </SidebarBadge>
                ) : (
                  taskCount > 0 && (
                    <SidebarBadge type="attention-gray" loading={false}>
                      {taskCount}
                    </SidebarBadge>
                  )
                )}
              </NavLinkStyled>
            </Nav.Item>
          </HeaderItem>
          <HeaderItem className="d-flex flex-row gap-3 align-items-center">
            <Nav.Item>
              <NavLinkStyled
                eventKey="archived"
                className="py-3 d-flex flex-row gap-2">
                {t('notifications.tabs.archived')}
              </NavLinkStyled>
            </Nav.Item>
            <Nav.Item>
              <NavLinkIconStyled
                onClick={handleSettingsOnClick}
                className="d-flex py-3">
                <FlairIcon icon="settings-outline" />
              </NavLinkIconStyled>
            </Nav.Item>
            <Nav.Item>
              <NavLinkIconStyled onClick={onClose} className="d-flex py-3">
                <FlairIcon icon="close-outline" />
              </NavLinkIconStyled>
            </Nav.Item>
          </HeaderItem>
        </Header>
        <Tab.Content>
          <Tab.Pane eventKey="inbox">
            <InboxNotificationsContentConnected />
          </Tab.Pane>
          <Tab.Pane eventKey="task">
            <TaskNotificationsContentConnected />
          </Tab.Pane>
          <Tab.Pane eventKey="archived">
            <ArchivedNotificationsContentConnected />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default NotificationsContent;
