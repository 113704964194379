import { ApolloCache, FetchResult } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import {
  useCloneEmployeeObjectiveMutation,
  useCreateEmployeeObjectiveMutation,
  useCreateTeamObjectiveMutation,
  useDeleteObjectiveMutation,
  useUpdateEmployeeObjectiveFieldsMutation,
  useUpdateObjectiveStatusMutation,
  useUpdateTeamObjectiveFieldsMutation,
} from '../../../../__generated__/graphql';
import { GoalId } from '../types';
import {
  isEmployeeObjectiveFieldsUpdate,
  isTeamObjectiveFieldsUpdate,
  mapToCreateEmployeeObjectiveInput,
  mapToCreateTeamObjectiveInput,
  mapToUpdateEmployeeObjectiveFieldsInput,
  mapToUpdateObjectiveStatusInput,
  mapToUpdateTeamObjectiveFieldsInput,
} from './mappings';
import {
  EmployeeObjectiveFormData,
  ObjectiveFormData,
  TeamObjectiveFormData,
} from './types';

const i18prefix = 'performanceReview.goals2';

export const useCreateObjective = (meId: string) => {
  const { t } = useTranslation();
  const errorHandler = useMutationErrorHandler();
  const { addSuccess } = useToasts();
  const [createEmployeeObjective] = useCreateEmployeeObjective(meId);
  const [createTeamObjective] = useCreateTeamObjective(meId);

  const createObjective = useCallback(
    (formData: ObjectiveFormData) => {
      switch (formData.type) {
        case 'employee':
          return createEmployeeObjective({
            variables: {
              input: mapToCreateEmployeeObjectiveInput(
                formData as EmployeeObjectiveFormData,
              ),
            },
          })
            .then((data) => {
              if (data.data?.objectives.createEmployeeObjective.error) {
                throw new Error(
                  data.data?.objectives.createEmployeeObjective.error,
                );
              }
              addSuccess(t(`${i18prefix}.toastCreateSuccess`));
            })
            .catch(errorHandler);
        case 'team':
          return createTeamObjective({
            variables: {
              input: mapToCreateTeamObjectiveInput(
                formData as TeamObjectiveFormData,
              ),
            },
          })
            .then((data) => {
              if (data.data?.objectives.createTeamObjective.error) {
                throw new Error(
                  data.data?.objectives.createTeamObjective.error,
                );
              }
              addSuccess(t(`${i18prefix}.toastCreateSuccess`));
            })
            .catch(errorHandler);
        default:
          throw new Error('Not supported');
      }
    },
    [createEmployeeObjective, createTeamObjective, t, errorHandler, addSuccess],
  );

  return [createObjective];
};

export const useUpdateObjective = (meId: string, goalId: GoalId) => {
  const [updateFields] = useUpdateObjectiveFields(meId, goalId);
  const [updateStatus] = useUpdateObjectiveStatus(meId);

  const updateObjective = useCallback(
    async (formData: Partial<ObjectiveFormData>) => {
      if (
        isEmployeeObjectiveFieldsUpdate(formData) ||
        isTeamObjectiveFieldsUpdate(formData)
      ) {
        await updateFields(formData);
      }
      if ('statusWithRate' in formData) {
        await updateStatus({
          variables: {
            input: mapToUpdateObjectiveStatusInput(
              goalId,
              formData['statusWithRate']!,
            ),
          },
        });
      }
    },
    [updateFields, updateStatus, goalId],
  );

  return [updateObjective];
};

const useCreateEmployeeObjective = (meId: string) => {
  return useCreateEmployeeObjectiveMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
    awaitRefetchQueries: true,
  });
};

const useCreateTeamObjective = (meId: string) => {
  return useCreateTeamObjectiveMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
  });
};

const useUpdateObjectiveFields = (meId: string, goalId: GoalId) => {
  const [updateEmployeeFields] = useUpdateEmployeeObjectiveFields(meId);
  const [updateTeamFields] = useUpdateTeamObjectiveFields(meId);

  const updateObjectiveFields = (formData: Partial<ObjectiveFormData>) => {
    switch (goalId.type) {
      case 'employee':
        return updateEmployeeFields({
          variables: {
            input: mapToUpdateEmployeeObjectiveFieldsInput(
              goalId.id,
              formData as EmployeeObjectiveFormData,
            ),
          },
        });
      case 'team':
        return updateTeamFields({
          variables: {
            input: mapToUpdateTeamObjectiveFieldsInput(
              goalId.id,
              formData as TeamObjectiveFormData,
            ),
          },
        });
    }
    throw new Error('Not supported');
  };
  return [updateObjectiveFields];
};

export const useCloneEmployeeObjective = (meId: string) => {
  return useCloneEmployeeObjectiveMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
  });
};

const useUpdateEmployeeObjectiveFields = (meId: string) => {
  return useUpdateEmployeeObjectiveFieldsMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
  });
};

const useUpdateTeamObjectiveFields = (meId: string) => {
  return useUpdateTeamObjectiveFieldsMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
  });
};

const useUpdateObjectiveStatus = (meId: string) => {
  return useUpdateObjectiveStatusMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
  });
};

export const useDeleteObjective = (meId: string) => {
  return useDeleteObjectiveMutation({
    update: (cache, response) => evictObjectiveCache(meId, cache, response),
  });
};

const evictObjectiveCache = <T>(
  meId: string,
  cache: ApolloCache<T>,
  response: FetchResult<T>,
) => {
  if (response.errors || !response.data) {
    return;
  }
  const meCacheId = cache.identify({
    __typename: 'Me',
    Id: meId,
  });
  const managerId = cache.identify({
    __typename: 'Manager',
    Id: meId,
  });
  cache.evict({
    id: meCacheId,
    fieldName: 'myObjectives',
  });
  cache.evict({
    id: meCacheId,
    fieldName: 'colleaguesObjectives',
  });
  cache.evict({
    id: managerId,
    fieldName: 'employeesObjectives',
  });
  // Query.allObjectives
  cache.evict({
    fieldName: 'allObjectives',
  });
};
