import React from 'react';
import PageHeader from '../../components/PageHeader';
import { Container } from 'react-bootstrap';
import { routes } from '../../routes';
import { SlackStatusAutoUpdateSettings } from '../Settings/SlackStatusAutoUpdateSettings/SlackStatusAutoUpdateSettings';

type Props = {};

export const SlackDebug: React.FC<Props> = () => {
  return (
    <>
      <PageHeader title={'Slack Debug'}></PageHeader>
      <Container>
        <SlackStatusAutoUpdateSettings
          pageUrl={routes.slackDebug.route.create({})}
        />
      </Container>
    </>
  );
};
