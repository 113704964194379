import React, { useMemo } from 'react';
import { TableContent } from '../../manager/components/TableContent';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { Expense, ExpenseStatusInfo } from './types';
import FormattedDate from '../../../../components/date/FormattedDate';
import { ExpenseStatusCell } from './Common/ExpenseStatusCell';
import { useCurrencyFormatter } from '../../../../hooks/useCurrencyFormatter';
import { ExpenseCategory, ExpenseStatus } from '../../__generated__/graphql';
import { DropdownActionItem } from '../../../../atomic/molecules/DropdownActionItem';
import styled from '@emotion/styled';
import { categorySort } from './Common/sorting';
import { EmptyExpenses } from './EmptyExpenses';
import CommentCount from '../../components/Comment/CommentCount/CommentCount';
import { DropdownActions } from '../../../../atomic/templates/DropdownActions';

export type Item = {
  id: string;
  showComments?: boolean;
};

type TableItem = {
  id: string;
  name: string;
  merchant: string;
  project: string | null;
  currency: string;
  category: ExpenseCategory | null;
  amount: number;
  expenseDate: Date;
  status: string;
  commentsCount: number;
};

type Props = {
  onItemClick: (item: Item) => void;
  expenses: Expense[];
  onDelete: (id: string) => void;
};

const EMPTY_VALUE = '---';

export const MyExpensesTable: React.FC<Props> = ({
  onItemClick,
  expenses,
  onDelete,
}) => {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormatter();

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.name" />,
        accessor: 'name',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) => (
          <ExpenseNameContainer title={value} className="text-truncate">
            <a
              href="stub"
              onClick={(e) => {
                e.preventDefault();
                onItemClick({ id: row.original.id });
              }}>
              {value}
            </a>
          </ExpenseNameContainer>
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.merchant" />,
        accessor: 'merchant',
        Cell: ({ value }: { value: string }) => <div>{value}</div>,
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.project" />,
        accessor: 'project',
        Cell: ({ value }: { value: string | null }) => (
          <div>{value ? value : EMPTY_VALUE}</div>
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.category" />,
        accessor: 'category',
        Cell: ({ value }: { value: ExpenseCategory | null }) => {
          return <div>{value?.Name ?? EMPTY_VALUE}</div>;
        },
        sortType: (rowA: Row<TableItem>, rowB: Row<TableItem>) =>
          categorySort(rowA.original.category, rowB.original.category),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.amount" />,
        accessor: 'amount',
        Cell: ({ value, row }: { value: number; row: Row<TableItem> }) => (
          <div>
            {formatCurrency({
              value: value,
              currency: row.original.currency,
            })}
          </div>
        ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="expenses.table.header.expenseDate" />
        ),
        accessor: 'expenseDate',
        Cell: ({ value }: { value: Date }) => (
          <FormattedDate day={value} format="short" />
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.comments" />,
        accessor: 'commentsCount',
        Cell: ({ value, row }) => (
          <CommentCount
            count={value}
            onClick={() =>
              onItemClick({ id: row.original.id, showComments: true })
            }
          />
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.status" />,
        accessor: 'status',
        Cell: ({
          value,
          row,
        }: {
          value: ExpenseStatus;
          row: Row<TableItem>;
        }) => {
          const readonly = true;
          const id = row.original.id;
          const statusInfo: ExpenseStatusInfo = {
            expenseId: id,
            status: value,
            declineReason: null,
          };
          return (
            <ExpenseStatusCell
              value={statusInfo}
              readonly={readonly}
              updatingToStatus={null}
            />
          );
        },
      },

      {
        accessor: 'id',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) =>
          row.original.status === ExpenseStatus.Pending && (
            <>
              <DropdownActions id="expense-actions">
                <DropdownActionItem
                  onClick={() => {
                    onDelete(value);
                  }}>
                  {t('expenses.deleteModal.button')}
                </DropdownActionItem>
              </DropdownActions>
            </>
          ),
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [formatCurrency, onDelete, onItemClick, t]);

  if (!expenses.length) {
    return <EmptyExpenses />;
  }

  return (
    <TableContent
      columns={columns}
      data={expenses}
      getRowProps={() => ({ role: 'button' })}
    />
  );
};

const ExpenseNameContainer = styled.div({
  maxWidth: '270px',
});
