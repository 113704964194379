import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SelectableRowSize, SelectableRowType } from './types';
import { SelectableRow } from './SelectableRow';

export type SelectableRowGroupProps = {
  activeButton?: string;
  list: SelectableRowType[];
  isValid: boolean;
  onClick?: (rowInfo: SelectableRowType) => void;
  size?: SelectableRowSize;
};

const SelectableRowGroup: React.FC<SelectableRowGroupProps> = ({
  activeButton,
  list,
  onClick = () => {},
  size,
  isValid,
}) => {
  return (
    <Row className="justify-content-center w-100 flex-column gap-3 g-0">
      {list.map((row: SelectableRowType) => (
        <Col key={row.value} className="p-0">
          <SelectableRow
            size={size}
            isActive={activeButton === row.value}
            hasError={!isValid}
            onClick={() => onClick(row)}
            {...row}
          />
        </Col>
      ))}
    </Row>
  );
};

export default SelectableRowGroup;
