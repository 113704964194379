import React from 'react';
import { formatDate, parseDate } from '../../../../../utils/dateUtils';
import { Popover } from '../../../../../components/popover';
import { useSingleVisiblePopoverContext } from '../../../../../context/SingleVisiblePopoverContext';
import { Theme } from '../../../../../theme';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { DayTimeEntriesPopoverConnected } from '../DayTimeEntries/DayTimeEntriesPopoverConnected';
import { DayRowCell } from '../../../components/TimeSheetsTable/Cells/DayRowCell';

type Props = {
  timeSheetId: string;
  hasTimeEntryChangeRequests: boolean;
  hasEntriesWithNotes: boolean;
  day: string;
  originalDayStartDateTime: Date;
  className?: string;
};

export const ManagerDayRowCell: React.FC<Props> = ({
  className,
  timeSheetId,
  hasTimeEntryChangeRequests,
  hasEntriesWithNotes,
  day,
  originalDayStartDateTime,
}) => {
  const dayStr = formatDate(parseDate(day), 'EEEE - PPP');
  const popoverId = `${timeSheetId}-${day}-timeEntries`;

  const { visiblePopoverId, onPopoverToggle } =
    useSingleVisiblePopoverContext();

  const popoverContent = (
    <DayTimeEntriesPopoverConnected
      day={day}
      dayStartDateTime={originalDayStartDateTime}
      timeSheetId={timeSheetId}
    />
  );

  const color = !hasTimeEntryChangeRequests
    ? Theme.color.blue3
    : Theme.color.warning;

  const popover = (
    <Popover
      headerText={dayStr}
      show={visiblePopoverId === popoverId}
      onToggle={(visible) => onPopoverToggle(popoverId, visible)}
      popoverContent={popoverContent}>
      <div className="d-flex gap-2" role="button">
        <FlairIcon icon="stopwatch-outline" size="sm" color={color} />
        {hasEntriesWithNotes && (
          <FlairIcon icon="sticky-note-outline" size="sm" color={color} />
        )}
      </div>
    </Popover>
  );

  return <DayRowCell className={className} day={day} popover={popover} />;
};
