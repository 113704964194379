import * as React from 'react';

const MultipleFilesIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clip-path="url(#clip0_47_12715)">
        <path
          d="M20.0344 6.21564L16.2844 2.46564C16.1415 2.32635 15.9495 2.24889 15.75 2.25001H8.25C7.85218 2.25001 7.47064 2.40805 7.18934 2.68935C6.90804 2.97066 6.75 3.35219 6.75 3.75001V5.25001H5.25C4.85218 5.25001 4.47064 5.40805 4.18934 5.68935C3.90804 5.97066 3.75 6.35219 3.75 6.75001V20.25C3.75 20.6478 3.90804 21.0294 4.18934 21.3107C4.47064 21.592 4.85218 21.75 5.25 21.75H15.75C16.1478 21.75 16.5294 21.592 16.8107 21.3107C17.092 21.0294 17.25 20.6478 17.25 20.25V18.75H18.75C19.1478 18.75 19.5294 18.592 19.8107 18.3107C20.092 18.0294 20.25 17.6478 20.25 17.25V6.75001C20.2511 6.55048 20.1737 6.35851 20.0344 6.21564ZM12.75 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H12.75C12.9489 16.5 13.1397 16.579 13.2803 16.7197C13.421 16.8603 13.5 17.0511 13.5 17.25C13.5 17.4489 13.421 17.6397 13.2803 17.7803C13.1397 17.921 12.9489 18 12.75 18ZM12.75 15H8.25C8.05109 15 7.86032 14.921 7.71967 14.7803C7.57902 14.6397 7.5 14.4489 7.5 14.25C7.5 14.0511 7.57902 13.8603 7.71967 13.7197C7.86032 13.579 8.05109 13.5 8.25 13.5H12.75C12.9489 13.5 13.1397 13.579 13.2803 13.7197C13.421 13.8603 13.5 14.0511 13.5 14.25C13.5 14.4489 13.421 14.6397 13.2803 14.7803C13.1397 14.921 12.9489 15 12.75 15ZM18.75 17.25H17.25V9.75001C17.2511 9.55048 17.1737 9.35851 17.0344 9.21564L13.2844 5.46564C13.1415 5.32635 12.9495 5.24889 12.75 5.25001H8.25V3.75001H15.4406L18.75 7.05939V17.25Z"
          fill="#196879"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_12715">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default MultipleFilesIcon;
