import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardHeader from '../../../components/CardHeader';
import { EmployeeSalary } from '../../../__generated__/graphql';
import { useCurrencyFormatter } from '../../../../../hooks/useCurrencyFormatter';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { orderBy } from 'lodash';

type Salary = Pick<
  EmployeeSalary,
  | 'Id'
  | 'flair__Amount__c'
  | 'flair__Salary_Type__c'
  | 'flair__Start_Date__c'
  | 'flair__End_Date__c'
  | 'CurrencyIsoCode'
>;

type Props = {
  salaries: ReadonlyArray<Salary>;
};

const SalariesCard: React.FC<Props> = ({ salaries }) => {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormatter();
  return (
    <Card>
      <CardHeader>{t('employeePage.overview.salariesCard.title')}</CardHeader>
      <Table className="card-table text-nowrap" bordered size="sm" responsive>
        <thead>
          <tr>
            <th>{t('employeePage.overview.salariesCard.amount')}</th>
            <th>{t('employeePage.overview.salariesCard.type')}</th>
            <th>{t('employeePage.overview.salariesCard.startDate')}</th>
            <th>{t('employeePage.overview.salariesCard.endDate')}</th>
          </tr>
        </thead>
        <tbody>
          {orderBy(salaries, 'flair__Start_Date__c', 'desc').map((salary) => (
            <tr key={salary.Id}>
              <td>
                {formatCurrency({
                  value: salary.flair__Amount__c,
                  currency: salary.CurrencyIsoCode,
                })}
              </td>
              <td>{salary.flair__Salary_Type__c}</td>
              <td>
                <FormattedDate
                  day={salary.flair__Start_Date__c}
                  format="short"
                />
              </td>
              <td>
                {salary.flair__End_Date__c && (
                  <FormattedDate
                    day={salary.flair__End_Date__c}
                    format="short"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default SalariesCard;
