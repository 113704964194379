import { endOfMonth, startOfMonth } from 'date-fns';
import { SortingRule } from 'react-table';
import { parseDate, toISODateOnly } from '../../../../../utils/dateUtils';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import {
  AbsenceApprovalRequestToMeFragment,
  AbsenceApprovalStatus,
  AbsenceRequestFilterInput,
  AbsenceRequestSortFieldEnum,
  AbsenceRequestSortInput,
  AscDescEnum,
} from '../../../__generated__/graphql';
import { mapDocumentFile } from '../../../pages/Absences/mappings';
import { mapApprovalRequests } from '../../../pages/Absences/MyAbsences/mappings';
import { AbsenceRequestFilterType, isAbsenceApprovalStatus } from './types';
import { mapLoomVideo } from '../../../components/LoomVideo';

import { AbsenceRequest } from '../types';

export const mapAbsenceRequests = (
  src: ReadonlyArray<AbsenceApprovalRequestToMeFragment>,
): AbsenceRequest[] => {
  return src.map((item) => ({
    id: item.Id,
    absenceId: item.absence.Id,
    approvalStatus: item.absence.flair__Approval_Status__c,
    categoryName: item.absence.flair__Category_Name__c,
    categoryIcon: item.absence.flair__Category_Icon__c,
    amount: item.absence.flair__Amount__c,
    workingAmount: item.absence.flair__Working_Amount__c,
    startDate: item.absence.flair__Start_Date__c,
    startTime: item.absence.flair__Start_Time__c,
    startDateHalfDay: item.absence.flair__Start_Date_Half_Day__c,
    endDate: item.absence.flair__End_Date__c,
    endTime: item.absence.flair__End_Time__c,
    endDateHalfDay: item.absence.flair__End_Date_Half_Day__c,
    type: item.absence.flair__Type__c,
    requester: {
      id: item.absence.employee.Id,
      name: item.absence.employee.Name,
      avatarUrl: item.absence.employee.avatar?.url,
    },
    approvalRequests: mapApprovalRequests(item.absence.approvalRequests),
    comment: item.absence.flair__Comment__c,
    commentsCount: item.absence.commentsCount,
    createdDate: item.CreatedDate,
    lastModifiedDate: item.LastModifiedDate,
    attachments:
      item.absence.ContentDocumentLinks__r?.map(mapDocumentFile) ?? [],
    recordId: item.absence.Id,
    loomVideo: mapLoomVideo(item.absence.loomVideo),
  }));
};

export const mapSortBy = (
  sortBy: SortingRule<AbsenceRequest>[],
): AbsenceRequestSortInput => {
  if (sortBy.length < 1) {
    return defaultSorting;
  }
  return {
    field: mapSortField(sortBy[0].id),
    order: sortBy[0].desc === true ? AscDescEnum.Desc : AscDescEnum.Asc,
  };
};

const mapSortField = (sortId: string): AbsenceRequestSortFieldEnum => {
  switch (sortId) {
    case 'requester':
      return AbsenceRequestSortFieldEnum.EmployeeName;
    case 'startDate':
      return AbsenceRequestSortFieldEnum.StartDate;
    case 'categoryName':
      return AbsenceRequestSortFieldEnum.CategoryName;
    case 'workingAmount':
      return AbsenceRequestSortFieldEnum.WorkingTime;
    case 'amount':
      return AbsenceRequestSortFieldEnum.CalendarTime;
    case 'approvalStatus':
      return AbsenceRequestSortFieldEnum.ApprovalStatus;
    default:
      return AbsenceRequestSortFieldEnum.StartDate;
  }
};

const defaultSorting: AbsenceRequestSortInput = {
  field: AbsenceRequestSortFieldEnum.StartDate,
  order: AscDescEnum.Desc,
};

export const mapAbsenceRequestFilterInput = (
  filter: AbsenceRequestFilterType,
  reportsMode: ManagerReportsMode,
): AbsenceRequestFilterInput => ({
  managerReportsType: mapManagerReportsType(reportsMode),
  startDateFrom: filter.month
    ? toISODateOnly(startOfMonth(parseDate(filter.month)))
    : null,
  startDateTo: filter.month
    ? toISODateOnly(endOfMonth(parseDate(filter.month)))
    : null,
  categoryIds: filter.categoryIds.length > 0 ? filter.categoryIds : null,
  employeeIds: filter.employeeIds.length > 0 ? filter.employeeIds : null,
  approvalStatuses:
    filter.approvalStatuses.length > 0
      ? filter.approvalStatuses.filter((x): x is AbsenceApprovalStatus =>
          isAbsenceApprovalStatus(x),
        )
      : null,
});
