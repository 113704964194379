/* eslint-disable no-unused-vars */
export enum FlairDateFormat {
  MonthNameLong = 'month-name-long', // September
  MonthShort = 'month-short', // Sep
  NumericDate = 'numeric-date', // 9/4/2023
  NumericDateHyphen = 'numeric-date-hyphen', // 2023-09-08
  MonthLong = 'month-long', // September
  DayMonthLongYear = 'day-month-long-year', // 4th September 2023
  DayMonthLongYearFull = 'day-month-long-year-full', // 04 September 2023
  NumericDateDots = 'numeric-date-dots', // 04.09.2023
  MonthLongDaySuffix = 'month-long-day-suffix', // September 4th
  WeekdayShortMonthDay = 'weekday-short-month-day', // Thu Sep 4th
  YearOnly = 'year-only', // 2023
  MonthLongYear = 'month-long-year', // September 2023
  WeekdayLong = 'weekday-long', // Thursday
  DayMonthYearTime = 'day-month-year-time', // 04/09/2023 20:30
  WeekdayLongDateSuffix = 'weekday-long-date-suffix', // Thursday - September 4th, 2023
  WeekdayShortMonthDayYear = 'weekday-short-month-day-year', // Wed. Sep 4, 2023
  NumericDayMonth = 'numeric-day-month', // 03.10
  DayNumberWithMonth = 'dayNumber-with-month', // 22 July
  MonthNameShortYear = 'month-name-short-year', // Jul 2023
}

export type FormattedDateProps = {
  day: string | Date | number;
  format?: FlairDateFormat;
  className?: string;
};
