import React from 'react';
import { Modal } from 'react-bootstrap';
import { Theme } from '../../../../../theme';
import Button from '../../../../../components/button';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { FeedbackQuestionType } from '../../../__generated__/graphql';
import {
  SatisfactionFreeTextInput,
  YesOrNoQuestionCard,
} from '../../../components/Questionnaire/QuestionnaireInput/questions/satisfactionQuestions';
import { EmployeeFeedbackAnswerProps } from '../../../components/EmployeeFeedbackQuestionnaire/types';
import { useUpdateEmployeeFeedbackAnswer } from '../../../hooks/performanceReview/feedbackQuestionnaire/useUpdateEmployeeFeedbackAnswer';

type FolderModalProps = {
  visible: boolean;
  onClose: () => void;
  answer: EmployeeFeedbackAnswerProps;
  isSignStep: boolean;
  onSubmit: () => void;
  isLoading?: boolean;
};

const i18Path = 'performanceReview.employeeSatisfaction';

export const EmployeeSatisfactionModal: React.FC<FolderModalProps> = ({
  visible,
  onClose,
  answer,
  onSubmit,
  isSignStep,
  isLoading,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const questionType = answer.question.flair__Feedback_Question_Type__c;
  const { onChange, onChangeNotes, value, loading, isCompleted } =
    useUpdateEmployeeFeedbackAnswer({ answer });

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      dialogClassName="break-modal-494">
      <Modal.Header closeButton className="card-header border-0">
        <div className="d-flex align-items-center justify-content-center">
          <div className="p-2 d-flex justify-content-center align-items-center rounded-circle bg-bg-red-2">
            <FlairIcon
              size="lg"
              icon="alert-outline"
              color={Theme.color.gray2}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="px-0  pt-3">
        <div className="mb-2 mb-4 px-4 d-flex flex-column align-items-center justify-content-center">
          <h2 className="card-header-title text-center mb-3">
            {isSignStep ? t('modalTitle') : t('modalTitleNoSign')}
          </h2>
          <h4 className="mb-3 h4-regular text-center">
            {isSignStep ? t('modalDescription') : t('modalDescriptionNoSign')}
          </h4>
          <h5 className="mt-2 my-0 h5-regular color-gray-2 text-center">
            {t('modalSubDescription')}
          </h5>
        </div>
        <div className="bg-paper-7 py-3-5 px-4 mb-4">
          <div className="py-3-5 px-3-5 border rounded-3 border-color-paper-2 bg-white">
            {questionType === FeedbackQuestionType.YesNo && (
              <YesOrNoQuestionCard
                questionText={answer.question.flair__Question__c}
                isOptional={answer.question.flair__Optional__c}
                onChange={onChange}
                isLoading={loading}
                onChangeNotes={onChangeNotes}
                showOptionalComment={answer.question.flair__Optional_Comment__c}
              />
            )}
            {questionType === FeedbackQuestionType.FreeText && (
              <SatisfactionFreeTextInput
                value={value}
                questionText={answer.question.flair__Question__c}
                onChange={onChange}
                isOptional={answer.question.flair__Optional__c}
              />
            )}
          </div>
        </div>
        <div className={'d-flex justify-content-center gap-3'}>
          <Button
            variant="outline-primary"
            label={t('buttons.cancel')}
            onClick={() => onClose()}
          />
          <Button
            variant="primary"
            label={t('buttons.signAndSubmit')}
            onClick={onSubmit}
            disabled={!isCompleted}
            isProcessing={isLoading}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
