import React from 'react';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import MyAbsenceMultipleFilter from './MyAbsenceMultipleFilter';
import {
  TYPE_FILTER,
  STATUS_FILTER,
  MyAbsenceFiltersType,
  Absence,
  filterDefaultData,
} from './types';
import ClearFilters from '../../../../components/ClearFilters';
import { routes } from '../../../../routes';

type MyAbsenceFilterProps = {
  filterData: MyAbsenceFiltersType;
  data: Absence[];
};

const MyAbsenceFilter: React.FC<MyAbsenceFilterProps> = ({
  filterData,
  data,
}) => {
  const { t } = useTranslation();
  const route = routes.myAbsences.route
    .withQueryParams({ ...filterDefaultData })
    .create({});

  const typeOptions = uniqBy(
    data
      .map((c) => c.categoryName)
      .map((s) => ({
        label: s || '',
        value: s || '',
      }))
      .filter((r) => !!r.value),
    'value',
  );

  const statusOptions = uniqBy(
    data
      .map((c) => c.approvalStatus)
      .map((s) => ({
        label: t('absences.status.' + s.toLowerCase()) || '',
        value: s || '',
      }))
      .filter((r) => !!r.value),
    'value',
  );

  return (
    <div className="d-none d-md-flex align-items-center">
      <span className="me-3 text-nowrap">
        {t('absences.myAbsences.table.filterBy')}
      </span>
      <MyAbsenceMultipleFilter
        className="me-3"
        options={typeOptions}
        filterData={filterData}
        filterKey={TYPE_FILTER}
        placeholder={t('absences.myAbsences.table.filterType')}
      />
      <MyAbsenceMultipleFilter
        className="me-3"
        options={statusOptions}
        filterData={filterData}
        filterKey={STATUS_FILTER}
        placeholder={t('absences.myAbsences.table.status')}
      />
      <ClearFilters route={route} />
    </div>
  );
};

export default MyAbsenceFilter;
