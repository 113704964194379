import React from 'react';
import { Card } from 'react-bootstrap';

const Section: React.FC = ({ children }) => {
  return (
    <Card>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default Section;
