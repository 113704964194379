//sfdx-source/flair/main/default/classes/enums/EmployeeNotificationMessageType.cls

export const NotificationTypes = {
  ABSENCE_APPROVED: 'absence.approved',
  ABSENCE_CREATED: 'absence.created',
  ABSENCE_REJECTED: 'absence.rejected',
  ABSENCE_DELETED: 'absence.deleted',
  ABSENCE_REQUEST_CREATED: 'absence-request.created',
  TIME_ENTRY_CHANGES_APPROVED: 'time-entry-change-request.approved',
  TIME_ENTRY_CHANGES_CREATED: 'time-entry-change-request.created',
  TIME_ENTRY_CHANGES_REJECTED: 'time-entry-change-request.rejected',
  TIME_ENTRY_DELETED: 'time-entry.deleted',
  TIME_ENTRY_ADDED: 'time-entry.added',
  TIME_SHEET_APPROVED: 'time-sheet.approved',
  TIME_TRACKING_CLOCK_OUT_LIMIT: 'time-tracking.clock-out-limit',
  TIME_TRACKING_CLOCK_OUT_LIMIT_MANAGER:
    'time-tracking.clock-out-limit.manager',
  CELEBRATION_RECEIVED: 'celebration.received',
  COMMENT_CREATED: 'comment.created',
  CANDIDATE_EVALUATION_REQUESTED: 'candidate-evaluation.requested',
  CANDIDATE_EVALUATION_COMPLETED: 'candidate-evaluation.completed',
  WORKFLOW_ASSIGNED_PENDING_ITEMS: 'workflow.assigned.pending-items',
  WORKFLOW_DIGEST_EXPIRING_WORKFLOW_ITEMS:
    'workflow.digest.expiring-workflow-items',
  TICKET_SUBMITTED: 'ticket.submitted',
  TICKET_SHARED: 'ticket.shared',
  TICKET_STATUS_CHANGED: 'ticket.status-changed',
  TICKET_CLOSED: 'ticket.closed',
  // Time balance
  TIME_BALANCE_WITHDRAWAL_CREATED: 'time-balance-withdrawal.created',
};
