import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../../theme';
import FlairIcon, {
  FlairIconProps,
} from '../../../../../atomic/atoms/FlairIcon';

type Props = Pick<FlairIconProps, 'icon' | 'onClick'>;

export const OrgChartIconButton: React.FC<Props> = ({ icon, onClick }) => (
  <StytledIcon
    role="button"
    size="lg"
    color={Theme.color.paper1}
    icon={icon}
    onClick={onClick}
  />
);

const StytledIcon = styled(FlairIcon)({
  ':hover': {
    color: Theme.color.black,
  },
});
