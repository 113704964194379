import styled from '@emotion/styled';
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PolarityIcon } from '../../../apps/dashboard/components/PolarityIcon';
import { ManagerEmployeeNotePolarity } from '../../../apps/dashboard/__generated__/graphql';

type Props = {
  name: string;
  size?: 'sm' | 'lg';
};

const IconContainer = styled.span({
  marginRight: '0.375rem',
});

const ButtonTextContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
});

type ButtonTextProps = {
  text: string;
  icon: React.ReactNode;
};

const ButtonText: React.FC<ButtonTextProps> = ({ text, icon }) => {
  return (
    <ButtonTextContainer>
      <IconContainer>{icon}</IconContainer>
      <span>{text}</span>
    </ButtonTextContainer>
  );
};

export const PolarityInput: React.FC<Props> = ({ name, size = 'sm' }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      as={<ToggleButtonGroup type="radio" name={name} className="w-100" />}>
      <ToggleButton
        id={`${name}-positive-polarity`}
        size={size}
        value={ManagerEmployeeNotePolarity.Positive}
        variant="outline-success">
        <ButtonText
          text={t('managerEmployeeNotes.form.polarityInput.options.positive')}
          icon={
            <PolarityIcon polarity={ManagerEmployeeNotePolarity.Positive} />
          }
        />
      </ToggleButton>
      <ToggleButton
        id={`${name}-neutral-polarity`}
        size={size}
        value={ManagerEmployeeNotePolarity.Neutral}
        variant="outline-warning">
        <ButtonText
          text={t('managerEmployeeNotes.form.polarityInput.options.neutral')}
          icon={<PolarityIcon polarity={ManagerEmployeeNotePolarity.Neutral} />}
        />
      </ToggleButton>
      <ToggleButton
        id={`${name}-negative-polarity`}
        size={size}
        value={ManagerEmployeeNotePolarity.Negative}
        variant="outline-danger">
        <ButtonText
          text={t('managerEmployeeNotes.form.polarityInput.options.negative')}
          icon={
            <PolarityIcon polarity={ManagerEmployeeNotePolarity.Negative} />
          }
        />
      </ToggleButton>
    </Controller>
  );
};
