import React from 'react';
import StatusIndicator from '../StatusIndicator';
import { TimeSheetStatus } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';

type Props = {
  status: TimeSheetStatus;
};

const TimeSheetStatusIndicator: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case TimeSheetStatus.Pending: {
      return (
        <StatusIndicator
          variant="warning"
          text={t('timeSheet.status.pending')}
        />
      );
    }
    case TimeSheetStatus.Skipped: {
      return (
        <StatusIndicator variant="info" text={t('timeSheet.status.skipped')} />
      );
    }
    case TimeSheetStatus.Approved: {
      return (
        <StatusIndicator
          variant="success"
          text={t('timeSheet.status.approved')}
        />
      );
    }
    case TimeSheetStatus.Unknown: {
      return <StatusIndicator text={status} />;
    }
  }
};

export default TimeSheetStatusIndicator;
