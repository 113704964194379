import React, { useEffect } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import styled from '@emotion/styled';

type Props = {
  data: ChartData<'doughnut'>;
  options?: ChartOptions<'doughnut'>;
  width?: string;
  height?: string;
};

const Doughnut: React.FC<Props> = ({
  data,
  options = {},
  children,
  width,
  height,
}) => {
  const chartRef = React.createRef<HTMLCanvasElement>();
  const chart = React.useRef<Chart<'doughnut'>>();

  useEffect(() => {
    if (chartRef.current) {
      const myChartRef = chartRef.current.getContext('2d');

      if (myChartRef) {
        chart.current = new Chart<'doughnut'>(myChartRef, {
          type: 'doughnut',
          data: data,
          options: options,
        });
      }
    }

    return () => {
      chart.current?.destroy();
    };
  }, [chartRef, data, options, chart]);

  return (
    <StyledChart width={width} height={height} className="chart">
      <canvas className="chart-canvas" ref={chartRef} />
      <InsideText>{children}</InsideText>
    </StyledChart>
  );
};

const StyledChart = styled.div<{ width?: string; height?: string }>(
  {
    position: 'relative',
    height: '200px',
  },
  ({ width }) =>
    width && {
      width: width,
    },
  ({ height }) =>
    height && {
      height: height,
    },
);

const InsideText = styled.span`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Doughnut;
