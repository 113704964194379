import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SkeletonCard from '../../../components/Skeleton/Card';

const Loading: React.FC = () => {
  return (
    <Row>
      <Col xl={6}>
        <SkeletonCard height={545} />
        <SkeletonCard height={230} />
      </Col>
      <Col xl={6}>
        <SkeletonCard height={180} />
        <SkeletonCard height={490} />
      </Col>
    </Row>
  );
};

export default Loading;
