import moment from 'moment';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ServerError from '../../../../../components/ServerError';
import { useYearFromQueryString } from '../../../../../hooks/useYearFromQueryString';
import EmployeeAbsenceCalendarCard from '../../../components/EmployeeAbsenceCalendarCard';
import PageHeader from '../../../components/PageHeader';
import { routes } from '../../../routes';
import { useAbsencesCalendarQuery } from '../../../__generated__/graphql';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

const YEAR_PARAM_NAME = 'year';

const buildPath = (year: number) =>
  routes.absencesCalendar.route
    .withQueryParams({
      [YEAR_PARAM_NAME]: String(year),
    })
    .create({});

const Content: React.FC<{ year: number }> = ({ year }) => {
  const history = useHistory();

  const goToPrevYear = useCallback(() => {
    history.push(buildPath(year - 1));
  }, [year, history]);

  const goToNextYear = useCallback(() => {
    history.push(buildPath(year + 1));
  }, [year, history]);

  const { data, loading, error } = useAbsencesCalendarQuery({
    variables: {
      from: moment().year(year).startOf('year').toISOString(),
      to: moment().year(year).endOf('year').toISOString(),
    },
  });

  if (error) return <ServerError />;

  return (
    <EmployeeAbsenceCalendarCard
      absenceCategories={data?.me.absenceCategories}
      absences={data?.me.absences}
      workloads={data?.me.workloads}
      holidays={data?.me.holidays}
      year={year}
      goToNextYear={goToNextYear}
      goToPrevYear={goToPrevYear}
      loading={loading}
    />
  );
};

const i18Path = 'absences';

const AbsencesCalendar: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const year = useYearFromQueryString(YEAR_PARAM_NAME);

  useFlairBreadcrumbHook([
    { label: t('title') },
    { label: t('subMenuItems.calendar.title') },
  ]);

  return (
    <>
      <PageHeader title={t('subMenuItems.calendar.title')} />
      <Content year={year} />
    </>
  );
};

export default AbsencesCalendar;
