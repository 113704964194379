import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { DocumentEmployee } from '../types';
import { EmployeeWithAvatar } from '../../../components/Employee';
import { fileSizeFormatter } from '../../../../../utils/file';

type Props = {
  note: string;
  employee: DocumentEmployee;
  type: string;
  size: number;
};

const i18Path = 'documents2.documentInfoFields';
export const CorporateDocumentInfoFields: React.FC<Props> = ({
  note,
  size,
  employee,
  type,
}) => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <>
      <div className="d-flex flex-column gap-4">
        {note && (
          <div className="d-flex flex-column gap-2">
            <h4 className="card-header-title">{t('note')}</h4>
            <h4 className="card-header-title fw-normal text-break">{note}</h4>
          </div>
        )}
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('uploadedBy')}</h4>
          <EmployeeWithAvatar
            employee={{
              name: employee.name,
              avatarUrl: employee.avatarUrl,
            }}></EmployeeWithAvatar>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('type')}</h4>
          <h4 className="card-header-title fw-normal">{type}</h4>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('size')}</h4>
          <h4 className="card-header-title fw-normal">
            {fileSizeFormatter(size)}
          </h4>
        </div>
      </div>
    </>
  );
};
