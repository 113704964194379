import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Hint from '../../../../../../components/hint';
import { Theme } from '../../../../../../theme';
import DurationFormat from '../../../../components/DurationFormat';

type Props = {
  actualBreakDuration: number;
  minBreakDuration: number;
};

export const BreakDuration: React.FC<Props> = ({
  actualBreakDuration,
  minBreakDuration,
}) => {
  const { t } = useTranslation();

  if (actualBreakDuration >= minBreakDuration) {
    return (
      <Container>
        <DurationFormat minutes={actualBreakDuration} />
      </Container>
    );
  }
  return (
    <Hint
      id="hintBreakIsNotCounted"
      text={t('timeTrackingNew.hintBreakIsNotCounted', {
        count: minBreakDuration,
      })}>
      <Container>
        <DurationFormat minutes={actualBreakDuration} />
        <Asterisk>*</Asterisk>
      </Container>
    </Hint>
  );
};

const Container = styled('div')({
  color: Theme.color.dark,
  position: 'relative',
});

const Asterisk = styled('span')({
  position: 'absolute',
});
