import React from 'react';
import { RowFileTypeEnums } from './Enums/RowFileTypeEnums';
import FlairIcon, { SizeProp } from '../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../theme';
import styled from '@emotion/styled';

type Props = {
  fileType: string | undefined;
  size?: SizeProp;
  color?: string;
};
export const DocumentFileTypeIcon: React.FC<Props> = ({
  fileType,
  size = 'xl',
  color,
}) => {
  const icon = () => {
    switch (fileType) {
      case RowFileTypeEnums.Pdf:
        return (
          <StyledIcon icon="pdf-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Doc:
        return (
          <StyledIcon icon="doc-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Multi:
        return (
          <StyledIcon
            icon="multiple-files"
            size={size}
            color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Excel:
      case RowFileTypeEnums.Csv:
        return (
          <StyledIcon icon="xls-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Mov:
      case RowFileTypeEnums.Mp4:
      case RowFileTypeEnums.Avi:
        return (
          <StyledIcon icon="video-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.PowerPoint:
      case RowFileTypeEnums.PowerPointLocal:
      case RowFileTypeEnums.PowerPointXLocal:
      case RowFileTypeEnums.PowerPointX:
        return (
          <StyledIcon icon="ppt-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Png:
      case RowFileTypeEnums.Jpg:
      case RowFileTypeEnums.Jpeg:
        return (
          <StyledIcon icon="image-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Folder:
        return (
          <StyledIcon icon="folder" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Mp3:
      case RowFileTypeEnums.Ogg:
      case RowFileTypeEnums.Wav:
        return (
          <StyledIcon icon="audio-file" size={size} color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Root:
        return (
          <StyledIcon
            icon="home-folder-icon"
            size={size}
            color={color}></StyledIcon>
        );
      case RowFileTypeEnums.Zip:
        return (
          <StyledIcon icon="zip-file" size={size} color={color}></StyledIcon>
        );
      default:
        return <StyledIcon icon="file" size={size} color={color}></StyledIcon>;
    }
  };
  return <>{icon()}</>;
};

const StyledIcon = styled(FlairIcon)`
  & path {
    stroke: ${(props) => (props.color ? props.color : Theme.color.blue3)};
  }
`;
