import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useMyEngagementSurveysQuery } from '../../../__generated__/graphql';
import { routes } from '../../../routes';
import PageHeader from '../../../components/PageHeader';
import { Link } from '../../../../../Router';
import Loading from './Loading';
import { EngagementSurveysEmptyStateCard } from './EngagementSurveysEmptyStateCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  EmployeeEngagementSurvey,
  isDeadlineOverSurvey,
  isResponseDismissed,
  isResponseSubmitted,
} from './utils';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

type EngagementSurveyOneProps = {
  survey: EmployeeEngagementSurvey;
};

const GoToSurveyResponsePage: React.FC<{ surveyId: string }> = ({
  surveyId,
  children,
}) => (
  <Link to={routes.engagementSurveyResponsePage.route} surveyId={surveyId}>
    {children}
  </Link>
);

const EngagementSurveyItem: React.FC<EngagementSurveyOneProps> = ({
  survey,
}) => {
  const t = useNamespacedTranslation('engagementSurvey.listingPage.surveyCard');

  const isSubmitted = isResponseSubmitted(survey);
  const isDismissed = isResponseDismissed(survey);

  const getCardColor = () => {
    if (isSubmitted) {
      return 'info-soft';
    } else if (isDismissed) {
      return 'danger-soft';
    } else {
      return 'warning-soft';
    }
  };

  return (
    <Card bg={getCardColor()}>
      <Card.Body>
        <h3>{survey.name}</h3>

        {!isSubmitted && !isDismissed && <Card.Text>{t('text')}</Card.Text>}
        {!isDismissed && (
          <GoToSurveyResponsePage surveyId={survey.id}>
            {t(isSubmitted ? 'viewResponseButton' : 'goToResponsePageButton')}
          </GoToSurveyResponsePage>
        )}
        {isDismissed && <p>{t('answerDismissedMessage')}</p>}
      </Card.Body>
    </Card>
  );
};

export const Content: React.FC<{
  surveys: ReadonlyArray<EmployeeEngagementSurvey>;
}> = ({ surveys }) => {
  const t = useNamespacedTranslation('engagementSurvey.listingPage.surveyCard');

  const activeSurveys = surveys.filter((s) => !isDeadlineOverSurvey(s));
  // const pastSurveys = surveys.filter(isDeadlineOverSurvey);
  return (
    <>
      {!!activeSurveys.length && (
        <div>
          <h2>{t('activeSurveysHeader')}</h2>
          {activeSurveys.map((survey) => (
            <EngagementSurveyItem key={survey.id} survey={survey} />
          ))}
        </div>
      )}

      {/*TODO check if we need that*/}
      {/*{!!pastSurveys.length && (*/}
      {/*  <div className="mt-2">*/}
      {/*    <h2>{t('pastSurveysHeader')}</h2>*/}
      {/*    {pastSurveys.map((survey) => (*/}
      {/*      <EngagementSurveyItem key={survey.id} survey={survey} />*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};
export const EngagementSurveysListPage: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems.engagement');
  const { data, loading, error } = useMyEngagementSurveysQuery();
  const title = t('subMenuItems.engagementSurveys.fullTitle');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return null;
  }

  return (
    <>
      <PageHeader title={title} />
      <Row>
        <Col xl={8}>
          {data.me.engagementSurveys.length === 0 ? (
            <EngagementSurveysEmptyStateCard />
          ) : (
            <Content surveys={data.me.engagementSurveys} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default EngagementSurveysListPage;
