import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../../hooks/useMutationErrorHandler';
import { notEmpty } from '../../../../../../../utils/array';
import { toISODateOnly } from '../../../../../../../utils/dateUtils';
import {
  CostCenter,
  TimeSheetStatus,
  useSaveProjectTimeEntriesByDaysMutation,
} from '../../../../../__generated__/graphql';
import { i18prefix, timesheetsRange } from '../../../helpers';
import { Day, ProjectTimeEntry, Timesheet } from '../../../types';

import {
  mapToProjectDayTimeTracked,
  mapToTimeEntryInput,
} from '../../mappings';

import DayTimeEntriesForm from './DayTimeEntriesForm';
import {
  useMaxDailyTimeLimitTracking,
  useRestrictFutureEntries,
} from '../../../../../hooks/timeTrackingSettings';
import { formatHoursAndMinutes } from '../../helpers';

type Props = {
  timesheet: Timesheet;
  costCenters: ReadonlyArray<CostCenter>;
  day: Day;
};

const DayTimeEntriesSmart: React.FC<Props> = ({
  timesheet,
  costCenters,
  day,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();
  const { restrictFutureEntriesForADay } = useRestrictFutureEntries();
  const { getMaxDailyMinutesLimitForADay } = useMaxDailyTimeLimitTracking();

  const [dayTotalTrackedInHours, setDayTotalTrackedInHours] =
    useState<number>(0);

  const { maxDailyLimitEnabled, maxDailyLimitMinutesInMinutes } =
    getMaxDailyMinutesLimitForADay(day.day.toDate());

  const exceededMaxDailyLimit =
    maxDailyLimitEnabled &&
    dayTotalTrackedInHours * 60 > maxDailyLimitMinutesInMinutes;
  const [saveProjectTimeEntriesMutation, { loading: isSubmitting }] =
    useSaveProjectTimeEntriesByDaysMutation();

  const handleSaveChanges = useCallback(
    (timesTracked: ProjectTimeEntry[]) => {
      const timeEntriesToSave = timesTracked
        .map((timeTracked) => mapToProjectDayTimeTracked(timeTracked, day.day))
        .filter(notEmpty);

      return saveProjectTimeEntriesMutation({
        variables: {
          input: {
            date: toISODateOnly(day.day.toDate()),
            timesheetId: timesheet.id,
            timeEntries: timeEntriesToSave.map(mapToTimeEntryInput),
          },
          ...timesheetsRange(),
        },
      })
        .then(() => {
          addSuccess(t(`${i18prefix}.projectTimeEntries.saveSuccess`));
        })
        .catch(errorHandler);
    },
    [
      addSuccess,
      day.day,
      errorHandler,
      saveProjectTimeEntriesMutation,
      t,
      timesheet,
    ],
  );

  const restrictedFutureEntries = restrictFutureEntriesForADay(
    day.day.toDate(),
  );
  const disableForm =
    isSubmitting ||
    timesheet.approvalStatus !== TimeSheetStatus.Pending ||
    restrictedFutureEntries;

  return useMemo(
    () => (
      <>
        {exceededMaxDailyLimit && (
          <p className="fw-bold text-danger">
            {t(`${i18prefix}.projectTable.exceededMaxDailyLimit`, {
              timeFormatted: formatHoursAndMinutes(
                maxDailyLimitMinutesInMinutes,
              ),
            })}
          </p>
        )}
        <DayTimeEntriesForm
          day={day.day.toDate()}
          disabled={disableForm}
          disableSubmit={exceededMaxDailyLimit}
          restrictedFutureEntries={restrictedFutureEntries}
          projects={day.projects}
          costCenters={costCenters}
          timesTracked={day.timesTracked}
          onSave={handleSaveChanges}
          setDayTotalTracked={(hours) => setDayTotalTrackedInHours(hours)}
        />
      </>
    ),
    [
      day.projects,
      day.day,
      costCenters,
      day.timesTracked,
      handleSaveChanges,
      disableForm,
      restrictedFutureEntries,
      maxDailyLimitMinutesInMinutes,
      exceededMaxDailyLimit,
      t,
    ],
  );
};

export default DayTimeEntriesSmart;
