import _ from 'lodash';
import {
  NavigatableFragment,
  NavigatableIdInput,
  PersonalDocumentFolderDataFragment,
  PersonalDocumentsFoldersQuery,
} from '../../../__generated__/graphql';
import { RowFileTypeEnums } from '../Enums/RowFileTypeEnums';
import { DocumentFilesType } from '../types';

export type DocumentRow = {
  id: string;
  name: string;
  isEmpty: boolean;
  fullPath: string;
  type: DocumentFilesType;
};

export const mapDocumentsFolders = (
  src: PersonalDocumentsFoldersQuery,
  rowsToMove: NavigatableIdInput[],
) => {
  if (!src.navigatable) {
    return [];
  }
  const navigatables = src.navigatable.children
    .map((item) => mapNavigatableFolders(item as NavigatableFragment))
    .filter(
      (item) => item !== undefined && !rowsToMove.find((x) => x.id === item.id),
    );

  return _.orderBy(navigatables, 'name', 'desc');
};
const mapNavigatableFolders = (src: NavigatableFragment) => {
  switch (src.__typename) {
    case 'EmployeeDocumentFolder':
      return mapPersonalDocumentFolders(src);
  }
};
const mapPersonalDocumentFolders = (
  src: PersonalDocumentFolderDataFragment,
): DocumentRow => ({
  id: src.Id,
  name: src.Name,
  isEmpty: !src.children.length,
  fullPath: src.fullPath,
  type: { filesCount: 0, type: RowFileTypeEnums.Folder },
});
