import React from 'react';

import {
  NoteFormField,
  WarrantyForm,
  ElectronicDeviceForm,
  OfficeEquipmentForm,
  OtherForm,
  ResponsibleForm,
} from '../components/InventoryModal/Forms';

import { SupportedInventoryCategory } from '../types';
import { InventoryItemPreview } from '../components/InventoryModal/Forms/InventoryItemPreview';
import { inventoryDetailsSchema } from '../components/InventoryModal/schemas';
import { ObjectSchema, Shape } from 'yup';
import {
  CategorySchemaType,
  InventoryDetailsSchemaType,
  InventorySchemaType,
  StepNamesType,
} from '../components/InventoryModal/types';

export type FormItemType = {
  form: JSX.Element;
  validationScheme?: ObjectSchema<
    Shape<
      object,
      InventoryDetailsSchemaType | CategorySchemaType | InventorySchemaType
    >
  >;
  isVisibleExpanded: boolean;
  titleKey: string;
  stepName: StepNamesType;
};

export const useFormList = (category: SupportedInventoryCategory | null) => {
  const electronicDeviceFormList: FormItemType[] = [
    {
      form: <ElectronicDeviceForm />,
      validationScheme: inventoryDetailsSchema,
      isVisibleExpanded: true,
      titleKey: 'electronicDeviceInfo',
      stepName: 'DETAILS',
    },
    {
      form: <WarrantyForm />,
      isVisibleExpanded: true,
      titleKey: 'warrantyInfo',
      stepName: 'WARRANTY',
    },
    {
      form: <ResponsibleForm />,
      isVisibleExpanded: true,
      titleKey: 'responsibleInfo',
      stepName: 'RESPONSIBLE',
    },
    {
      form: <NoteFormField />,
      isVisibleExpanded: true,
      titleKey: 'note',
      stepName: 'NOTE',
    },
    {
      form: <InventoryItemPreview />,
      isVisibleExpanded: false,
      titleKey: 'preview',
      stepName: 'PREVIEW',
    },
  ];
  const otherFormList = [
    {
      form: <OtherForm />,
      validationScheme: inventoryDetailsSchema,
      isVisibleExpanded: true,
      titleKey: 'otherFormInfo',
      stepName: 'DETAILS',
    },
    {
      form: <ResponsibleForm />,
      isVisibleExpanded: true,
      titleKey: 'responsibleInfo',
      stepName: 'RESPONSIBLE',
    },
    {
      form: <NoteFormField />,
      isVisibleExpanded: true,
      titleKey: 'otherFormNote',
      stepName: 'NOTE',
    },
    {
      form: <InventoryItemPreview />,
      isVisibleExpanded: false,
      titleKey: 'preview',
      stepName: 'PREVIEW',
    },
  ];
  const officeEquipmentFormList = [
    {
      form: <OfficeEquipmentForm />,
      validationScheme: inventoryDetailsSchema,
      isVisibleExpanded: true,
      titleKey: 'officeEquipmentInfo',
      stepName: 'DETAILS',
    },
    {
      form: <WarrantyForm />,
      isVisibleExpanded: true,
      titleKey: 'officeEquipmentWarrantyInfo',
      stepName: 'WARRANTY',
    },
    {
      form: <ResponsibleForm />,
      isVisibleExpanded: true,
      titleKey: 'responsibleInfo',
      stepName: 'RESPONSIBLE',
    },
    {
      form: <NoteFormField />,
      isVisibleExpanded: true,
      titleKey: 'officeEquipmentNote',
      stepName: 'NOTE',
    },
    {
      form: <InventoryItemPreview />,
      isVisibleExpanded: false,
      titleKey: 'preview',
      stepName: 'PREVIEW',
    },
  ];
  const getCategoryFormList = () => {
    switch (category) {
      case 'Electronic Device':
        return electronicDeviceFormList;
      case 'Office Equipment':
        return officeEquipmentFormList;
      case 'Other':
      default:
        return otherFormList;
    }
  };

  return { categoryFormList: getCategoryFormList() as FormItemType[] };
};
