import styled from '@emotion/styled';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Theme } from '../../../../../../theme';
import Skeleton from 'react-loading-skeleton';
import { ButtonsContainer } from './ButtonsContainer';

export type Props = {
  highlight?: boolean;
  isProcessing?: boolean;
  buttons?: React.ReactNode;
  children: React.ReactNode;
};

const highlightStyle = {
  backgroundColor: Theme.color.paper5,
  borderColor: Theme.color.paper2,
};

export const TimeEntryCardLayout: React.FC<Props> = ({
  highlight,
  isProcessing,
  children,
  buttons = null,
}) => {
  return (
    <StyledCard
      className="position-relative"
      style={highlight ? highlightStyle : undefined}>
      <Card.Body>{children}</Card.Body>
      {buttons && (
        <ButtonsContainer className="d-flex">{buttons}</ButtonsContainer>
      )}
      {isProcessing && (
        <ProcessingContainer>
          <Skeleton width="100%" height="100%"></Skeleton>
        </ProcessingContainer>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)({
  fontSize: Theme.font.size.sm,
});

const ProcessingContainer = styled.div({
  lineHeight: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0.5,
  zIndex: 1,
});
