import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Modal, ModalFooter } from 'react-bootstrap';
import Button from '../../../../components/button';
import styled from '@emotion/styled';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../theme';
import { DeletedItem } from './hooks';
import { isDocumentMulti, isFolder } from './helpers';

type Props = {
  visible: boolean;
  submitInProgress: boolean;
  onSubmit: () => void;
  onClose: () => void;
  selectedItems: DeletedItem[];
};

const i18Prefix = 'documents2.personal.deleteModal';

export const DocumentDeleteConfirmation = ({
  visible,
  submitInProgress,
  onClose,
  onSubmit,
  selectedItems,
}: Props) => {
  const t = useNamespacedTranslation(i18Prefix);

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      className="confirm-modal"
      contentClassName="border"
      backdropClassName="confirm-modal-backdrop">
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
        <Modal.Body className="pb-0">
          <div className="d-flex justify-content-center flex-column align-items-center gap-3">
            <IconContainer className="d-flex justify-content-center p-3 align-items-center">
              <FlairIcon icon="alert-outline" />
            </IconContainer>
            <h2 className="card-header-title">
              {t(`${getConfirmationMessage(selectedItems)}ConfirmTitle`)}
            </h2>
            <h4 className="card-header-title text-center fw-normal lh-sm">
              {t(`${getConfirmationMessage(selectedItems)}ConfirmText`)}
            </h4>
          </div>
        </Modal.Body>
        <ModalFooter className="border-top-0 pt-0 d-flex flex-row flex-nowrap gap-3">
          <Button
            variant="outline-secondary"
            onClick={onClose}
            label={t('button.cancel')}
          />
          <Button
            isProcessing={submitInProgress}
            onClick={onSubmit}
            variant="primary"
            label={t('button.delete')}
          />
        </ModalFooter>
      </div>
    </Modal>
  );
};

const getConfirmationMessage = (rows: DeletedItem[]) => {
  return rows.length === 1
    ? isFolder(rows[0].rowType)
      ? 'singleFolder'
      : isDocumentMulti(rows[0].rowType)
      ? 'singleDocument'
      : 'singleFile'
    : rows.length > 1
    ? 'multiDocument'
    : 'singleFile';
};

const IconContainer = styled.div`
  border-radius: 50%;
  background-color: ${Theme.color.bgRed};
`;
