import { DashboardLastUpdate_ColleagueFragment } from '../../../../__generated__/graphql';
import SimpleUpdateCard from '../SimpleUpdateCard';
import { mapColleague } from '../mapping';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import IconAvatar from '../../../../../../atomic/atoms/IconAvatar';
import { useContext } from 'react';
import { DashboardContext } from '../../context';
import StringToHtml from '../../../../../../components/StringToHtml';
import React from 'react';

type Props = {
  newJoinerFragment: DashboardLastUpdate_ColleagueFragment;
};

const i18Path = 'dashboard.lastUpdates.newJoiner';

const NewJoinerUpdateCard: React.FC<Props> = ({ newJoinerFragment }) => {
  const t = useNamespacedTranslation(i18Path);
  const { setSelectedEmployee } = useContext(DashboardContext);

  if (newJoinerFragment.flair__Start_Date__c === null) {
    return null;
  }

  const colleague = mapColleague(newJoinerFragment);

  const avatar = (
    <IconAvatar
      iconName="user-circle-plus"
      title={t('title')}
      subTitle={t('celebration')}
    />
  );

  let content = t('text', {
    name: colleague.name,
    position: colleague.position,
    startDate: colleague.startDate,
    department: colleague.department,
    location: colleague.location,
  });

  let parts = content.split(colleague.name);

  const colleagueName = (
    <span
      role="button"
      className="h4-medium text-primary"
      onClick={() => setSelectedEmployee(colleague)}>
      <StringToHtml text={colleague.name} />
    </span>
  );

  return (
    <SimpleUpdateCard
      key={colleague.id}
      createdDate={new Date(colleague.startDate)}
      avatar={avatar}
      contentClassName="py-3-5">
      <StringToHtml text={parts[0]} />
      {colleagueName}
      <StringToHtml text={parts[1]} />
    </SimpleUpdateCard>
  );
};

export default NewJoinerUpdateCard;
