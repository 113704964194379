import * as React from 'react';
import TableModal from '../../../../../../atomic/organisms/TableModal';
import { useDashboardEndOfProbationQuery } from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { mapProbationsForModal } from './mapping';
import { addMonths } from 'date-fns';
import { toISODateOnly } from '../../../../../../utils/dateUtils';

type Props = {
  onClose: () => void;
};

const i18Path = 'dashboard.probations.modal';

const ProbationsModal: React.FC<Props> = ({ onClose }) => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDashboardEndOfProbationQuery({
    variables: {
      filter: {
        limit: null,
        startDate: toISODateOnly(addMonths(new Date(), -1)),
      },
    },
  });

  const headers = [
    {
      key: 'employee',
      title: t('employee'),
    },
    {
      key: 'department',
      title: t('department'),
    },
    {
      key: 'location',
      title: t('location'),
    },
    {
      key: 'additionalInfo',
      title: t('endOfProbation'),
    },
  ];

  if (error) {
    return <ServerError />;
  }

  const probations =
    data?.probations
      .filter((a) => a.flair__End_Of_Probation_Period__c !== null)
      .map(mapProbationsForModal) ?? [];

  return (
    <TableModal
      title={t('title')}
      onClose={onClose}
      headers={headers}
      data={probations}
      loading={loading}
    />
  );
};

export default ProbationsModal;
