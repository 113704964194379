import styled from '@emotion/styled';
import { isEqual } from 'lodash';
import React from 'react';
import { Theme } from '../../../../../../theme';
import { DateAndTime, TimeEntryBreakItem } from '../logic';
import { getFirstErrorFromPath, ValidationResult } from '../logic/validation';
import DateAndTimeInput from './DateAndTimeInput';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = {
  breakItem: TimeEntryBreakItem;
  validationErrors: ValidationResult;
  pathPrefix: string;
  isMultiday: boolean;
  readonly: boolean;
  onChange: (value: TimeEntryBreakItem) => void;
  isDeleteVisible?: boolean;
  onDelete?: (value: TimeEntryBreakItem) => void;
  isHighlighted?: boolean;
};

const DeleteButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: Theme.color.dark,
  transition: 'color 0.15s',
  marginLeft: '0.75rem',
  ':hover': {
    color: Theme.color.black,
  },
});

const TimeBreakInputs: React.FC<Props> = ({
  breakItem,
  readonly,
  validationErrors,
  pathPrefix,
  isMultiday,
  onChange,
  isDeleteVisible,
  onDelete,
  isHighlighted,
}) => {
  const handleStartChange = (start: DateAndTime) => {
    onChange({ ...breakItem, start });
  };

  const handleEndChange = (end: DateAndTime) => {
    onChange({ ...breakItem, end });
  };

  const delimeter = () => {
    if (readonly) {
      return <div className="ms-1 me-1">-</div>;
    } else if (isMultiday) {
      return <div className="ms-1 me-1 d-none d-sm-block">-</div>;
    } else {
      return <div className="ms-2 me-2"></div>;
    }
  };

  return (
    <div className="d-flex align-items-center flex-wrap">
      <DateAndTimeInput
        name={pathPrefix + 'start'}
        value={breakItem.start}
        oldValue={breakItem.oldInterval?.start || null}
        readonly={readonly}
        error={getFirstErrorFromPath(validationErrors, pathPrefix + 'start')}
        isDateVisible={isMultiday}
        onChange={handleStartChange}
        isHighlighted={isHighlighted}
        ariaLabelledBy={`break-${breakItem.uniqueId}-start`}
      />
      {delimeter()}
      <DateAndTimeInput
        name={pathPrefix + 'end'}
        value={breakItem.end}
        oldValue={breakItem.oldInterval?.end || null}
        readonly={readonly}
        error={getFirstErrorFromPath(validationErrors, pathPrefix + 'end')}
        isDateVisible={isMultiday}
        onChange={handleEndChange}
        isHighlighted={isHighlighted}
        ariaLabelledBy={`break-${breakItem.uniqueId}-end`}
      />
      {isDeleteVisible && (
        <DeleteButton onClick={() => onDelete && onDelete(breakItem)}>
          <FlairIcon icon="trash-outline" />
        </DeleteButton>
      )}
    </div>
  );
};

export default React.memo(TimeBreakInputs, isEqual);
