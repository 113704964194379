import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import {
  filterAbsences,
  filterCalendarPeriods,
  filterHolidays,
} from '../../../../components/TimeSheet/DayCard/useTimeSheetDays';
import { TimeSheetDay } from '../../../../components/TimeSheet/DayCard/types';
import {
  Day,
  Project,
  ProjectDayTimeTracked,
  ProjectWithStatus,
  Timesheet,
} from '../../types';
import { getProjectStatus } from '../helpers';
import { mapLoomRecordVideo } from '../../../../components/LoomVideo/mappings';
import { toDateId } from '../../helpers';

export const useTimeSheetDays = (
  timeSheet: Timesheet,
  projects: Project[],
): Day[] =>
  useMemo(() => calculateDays(timeSheet, projects), [timeSheet, projects]);

const calculateDays = (timeSheet: Timesheet, projects: Project[]): Day[] => {
  const days: Day[] = [];
  var currDate = moment(timeSheet.startDate);
  var lastDate = moment(timeSheet.endDate).startOf('day');

  const dateToTimeSheetDayIdMap: Record<string, TimeSheetDay> = {};
  timeSheet.timeSheetDays.forEach(
    (timeSheetDay) =>
      (dateToTimeSheetDayIdMap[timeSheetDay.flair__Day__c] = {
        id: timeSheetDay.Id,
        commentsCount: timeSheetDay.commentsCount,
        loomVideo: timeSheetDay.loomVideo,
      }),
  );

  while (!currDate.isAfter(lastDate)) {
    const projectsData = mapProjects(projects, currDate);
    const projectIds = projectsData.map((project) => project.id);

    const timeSheetDay = dateToTimeSheetDayIdMap[currDate.format('YYYY-MM-DD')];

    days.push({
      timeSheetDayId: timeSheetDay.id,
      commentsCount: timeSheetDay.commentsCount,
      day: currDate.clone(),
      holidays: filterHolidays(timeSheet.holidays, currDate),
      absences: filterAbsences(timeSheet.paidAbsences, currDate),
      calendarPeriods: filterCalendarPeriods(
        timeSheet.calendarPeriods,
        currDate,
      ),
      timesTracked: filterProjectTrackedTimes(
        timeSheet.projectTimeTracked,
        projectIds,
        currDate,
      ),
      projects: projectsData,
      loomVideo: mapLoomRecordVideo(timeSheetDay.loomVideo),
    });
    currDate.add(1, 'days');
  }
  return days;
};

function mapProjects(
  projects: Project[],
  currDate: Moment,
): ProjectWithStatus[] {
  return projects.map((project) => ({
    ...project,
    status: getProjectStatus(project, currDate),
  }));
}

function filterProjectTrackedTimes(
  projectsTimeTracked: ProjectDayTimeTracked[],
  projectIds: string[],
  currDate: Moment,
): ProjectDayTimeTracked[] {
  return projectsTimeTracked.filter(
    (timeTracked) =>
      timeTracked.dateId === toDateId(currDate) &&
      projectIds.includes(timeTracked.projectId),
  );
}
