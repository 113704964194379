import React from 'react';
import {
  MyTimeSheetGoalChartFragment,
  TimeSheetDistChartFragment,
  TimeSheetGoalChartFragment,
} from '../../../__generated__/graphql';
import { parseDate } from '../../../../../utils/dateUtils';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../../theme';
import Skeleton from 'react-loading-skeleton';
import GoalChart from '../GoalChart';
import WeekChart from '../WeekChart';
import TimeDistChart from '../TimeDistChart';

type TimeSheet = (TimeSheetGoalChartFragment | MyTimeSheetGoalChartFragment) &
  TimeSheetDistChartFragment;

type Props = {
  timeSheet: TimeSheet;
};

export const TimeSheetChartsPopover: React.FC<Props> = ({ timeSheet }) => {
  const charts = [
    <GoalChart timeSheet={timeSheet}></GoalChart>,
    <WeekChart
      startDate={parseDate(timeSheet.flair__Start_Date__c)}
      tracked={timeSheet.trackedTime}></WeekChart>,
    <TimeDistChart timeSheet={timeSheet} />,
  ];

  return <Layout charts={charts} />;
};

export const TimeSheetChartsPopoverLoading: React.FC = () => {
  const charts = [
    <Skeleton width={280} height={200} className="ms-3 me-3" />,
    <Skeleton width={280} height={200} />,
    <Skeleton width={280} height={200} className="ms-3 me-3" />,
  ];

  return <Layout charts={charts} />;
};

const Layout: React.FC<{ charts: React.ReactNode[] }> = ({ charts }) => {
  const t = useNamespacedTranslation('timeSheetsControlling.popovers.charts');
  if (charts.length !== 3) {
    throw new Error('Only 3 charts supported');
  }
  return (
    <Container className="d-flex py-3 pe-3">
      <div>
        {charts[0]}
        <Label text={t('goal')}></Label>
      </div>
      <WithBorders className="px-2">
        {charts[1]}
        <Label text={t('dalyProgress')}></Label>
      </WithBorders>
      <div>
        {charts[2]}
        <Label text={t('timeDistribution')}></Label>
      </div>
    </Container>
  );
};

const Container = styled('div')({
  position: 'relative', // just for zIndex works
  zIndex: 101,
});

const Label: React.FC<{ text: string }> = ({ text }) => (
  <LabelDiv className="h4-medium text-center mt-2">{text}</LabelDiv>
);

const WithBorders = styled('div')({
  borderLeft: `1px solid ${Theme.color.paper3}`,
  borderRight: `1px solid ${Theme.color.paper3}`,
});

const LabelDiv = styled('div')({
  color: Theme.color.gray2,
});
