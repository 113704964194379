import React from 'react';
import { Form, FormGroupProps } from 'react-bootstrap';

type Props = FormGroupProps;

export const FormGroup = React.forwardRef<HTMLDivElement, Props>(
  ({ className, ...rest }, ref) => (
    <Form.Group ref={ref} className={['mb-4', className].join(' ')} {...rest} />
  ),
);
