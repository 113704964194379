import React from 'react';
import { TimeSheetColumnSetting } from '../../../components/TimeSheetsTable/CustomizeTableColumns/types';
import { TableRow, TableRowTimeSheet } from './types';
import { Table } from 'react-bootstrap';
import { useTimeSheetsTableColumns } from './useTimeSheetsTableColumns';
import { TimeSheetsTableRowConnected } from '../../../components/TimeSheetsTable/TimeSheetsTableRowConnected';
import './TimeSheetsTable.css';

type Props = {
  columnSettings: TimeSheetColumnSetting[];
  tableRows: TableRow[];
  isTimeBalanceEnabled: boolean;
  highlightIfHasComments: boolean;
  // important to memorize all handlers, so please use useCallback
  onApprove: (timeSheetRow: TableRowTimeSheet) => void;
  onExpandedChanged: (
    timeSheetRow: TableRowTimeSheet,
    expanded: boolean,
  ) => void;
  onSelectRow: (
    allTableRows: TableRow[],
    tableRow: TableRow,
    selected: boolean,
  ) => void;
  onSelectAll: (allTableRows: TableRow[], selected: boolean) => void;
};

export const TimeSheetsTable: React.FC<Props> = ({
  tableRows,
  highlightIfHasComments,
  ...columnProperties
}) => {
  const columns = useTimeSheetsTableColumns(columnProperties);

  return (
    <Table className="card-table" size="sm" hover responsive>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.accessor}>
              {column.Header({ allRows: tableRows })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row) => (
          <TimeSheetsTableRowConnected
            key={row.id}
            columns={columns}
            tableRows={tableRows}
            highlightIfHasComments={highlightIfHasComments}
            row={row}
          />
        ))}
      </tbody>
    </Table>
  );
};
