import React from 'react';
import Button from '../../../../components/button';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/Overlay';
import { Theme } from '../../../../theme';
import styled from '@emotion/styled';

type ReactionProps = {
  counter?: number;
  emoji: string;
  onClick?: (emoji: string) => void;
  isSelected: boolean;
  popover?: OverlayChildren;
  popoverTrigger?: 'click';
  withBackground?: boolean;
};

const ReactionButton: React.FC<ReactionProps> = ({
  counter,
  emoji,
  onClick,
  isSelected,
  popover,
  popoverTrigger,
  withBackground = true,
}) => {
  const style = {
    border: isSelected
      ? `${Theme.border.width} solid ${Theme.color.blue4}`
      : `${Theme.border.width} solid transparent`,
    backgroundColor: withBackground ? Theme.color.blue6 : 'unset',
    width: '3rem',
  };

  const content = (
    <StyledButton
      size="sm"
      variant="white"
      onClick={(e) => {
        e.currentTarget.blur();
        onClick && onClick(emoji);
      }}
      label={`${emoji}${counter ? ` ${counter.toString()}` : ''}`}
      style={style}
      className="flex-shrink-0"
    />
  );

  if (popover) {
    return (
      <OverlayTrigger
        placement="top"
        rootClose
        trigger={popoverTrigger}
        overlay={
          <Popover id="employees-reacted-popover">
            <Popover.Body>{popover}</Popover.Body>
          </Popover>
        }>
        {content}
      </OverlayTrigger>
    );
  }

  return content;
};

const StyledButton = styled(Button)({
  ':hover': {
    border: `${Theme.border.width} solid transparent !important`,
  },
});

export default ReactionButton;
