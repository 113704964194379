import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useSerialProcessing } from '../../../../hooks/useSerialProcessing';
import { useApproveTimeEntryChangeRequest } from './useApproveRejectTimeEntryChangeRequestMutations';

export const useApproveTimeEntryChangeRequestSerial = () => {
  const [batchApprove] = useApproveTimeEntryChangeRequest();
  const errorHandler = useMutationErrorHandler();

  return useSerialProcessing(
    (ids: string[]) => {
      return batchApprove({
        variables: {
          input: { ids },
        },
      })
        .catch(errorHandler)
        .then();
    },
    {
      batchSize: 5,
    },
  );
};
