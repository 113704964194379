import React, { useEffect } from 'react';
import { useLogout } from '../../../../hooks/useLogout';

const Logout: React.FC = () => {
  const logout = useLogout();

  useEffect(() => {
    logout();
  });

  return <></>;
};

export default Logout;
