import { keyBy } from 'lodash';
import { useCallback } from 'react';
import {
  EmployeeFeedbackAnswer,
  EmployeeFeedbackResolution,
  FeedbackAnswerVisibility,
  FeedbackQuestion,
  FeedbackQuestionType,
  FeedbackQuestionVisibility,
} from '../__generated__/graphql';

export type ComparedFeedbackSection = {
  id: string;
  name: string;
  isEmployeeSatisfactionSection: boolean;
  resolutions: ComparedFeedbackResolution[];
};

export type ComparedFeedbackResolution = {
  id: string;
  value: string | null;
  employeeAnswer: ComparedFeedbackAnswer;
  managerAnswer: ComparedFeedbackAnswer;
  question: ComparedFeedbackQuestion;
};

export type ComparedFeedbackQuestion = {
  id: string;
  value: string | null;
  questionVisibility: FeedbackQuestionVisibility;
  answerVisibility: FeedbackAnswerVisibility;
};

export type ComparedFeedbackAnswer =
  | FreeTextAnswer
  | SingleChoiceAnswer
  | MultipleChoiceAnswer
  | FreeSliderAnswer
  | DropdownAnswer
  | ScorecardAnswer
  | RatingTextAnswer
  | YesOrNOAnswer;

/* eslint-disable no-unused-vars */
export enum ComparedFeedbackAnswerType {
  FREE_TEXT,
  MULTIPLE_CHOICE,
  SINGLE_CHOICE,
  SCORECARD,
  FREE_SLIDER,
  DROPDOWN,
  RATING,
  YES_NO,
}

/* eslint-enable no-unused-vars */

type FreeTextAnswer = {
  type: ComparedFeedbackAnswerType.FREE_TEXT;
  id: string;
  value: string | null;
};

type YesOrNOAnswer = {
  type: ComparedFeedbackAnswerType.YES_NO;
  id: string | null;
  value: string | null;
};

type MultipleChoiceAnswer = {
  type: ComparedFeedbackAnswerType.MULTIPLE_CHOICE;
  id: string;
  value: string[] | null;
  note: string | null;
  choices: ReadonlyArray<string>;
};

type RatingTextAnswer = {
  type: ComparedFeedbackAnswerType.RATING;
  id: string;
  value: string | null;
  note: string | null;
  choices: ReadonlyArray<string>;
};

type SingleChoiceAnswer = {
  type: ComparedFeedbackAnswerType.SINGLE_CHOICE;
  id: string;
  value: string | null;
  note: string | null;
  choices: ReadonlyArray<string>;
};

type DropdownAnswer = {
  type: ComparedFeedbackAnswerType.DROPDOWN;
  id: string;
  value: string | null;
  note: string | null;
  choices: ReadonlyArray<string>;
};

type FreeSliderAnswer = {
  type: ComparedFeedbackAnswerType.FREE_SLIDER;
  id: string;
  value: string | null;
  note: string | null;
};

type ScorecardAnswer = {
  type: ComparedFeedbackAnswerType.SCORECARD;
  id: string;
  value: string;
  note: string | null;
  choices: ReadonlyArray<string>;
};

export type AnswerProps = Pick<
  EmployeeFeedbackAnswer,
  | 'Id'
  | 'flair__Employee_Feedback_Question__c'
  | 'feedbackAnswer'
  | 'flair__Note__c'
>;

export type FeedbackQuestionProps = Pick<
  FeedbackQuestion,
  | 'Id'
  | 'flair__Employee_Feedback_Question_Section__c'
  | 'flair__Section_Name__c'
  | 'flair__Question__c'
  | 'flair__Description__c'
  | 'flair__Choices__c'
  | 'flair__Feedback_Question_Type__c'
  | 'flair__Optional__c'
  | 'flair__Optional_Comment__c'
  | 'flair__Position__c'
  | 'flair__Required_Choice__c'
  | 'flair__Hint__c'
  | 'flair__Answer_Visibility__c'
  | 'flair__Feedback_Question_Visibility__c'
  | 'flair__Is_Satisfaction_Section__c'
>;

export type EmployeeFeedbackResolutionProps = Pick<
  EmployeeFeedbackResolution,
  'Id' | 'flair__Resolution__c' | 'flair__Employee_Feedback_Question__c'
>;

type Params = {
  questions: ReadonlyArray<FeedbackQuestionProps>;
  employeeAnswers: ReadonlyArray<AnswerProps>;
  managerAnswers: ReadonlyArray<AnswerProps>;
  resolutions: ReadonlyArray<EmployeeFeedbackResolutionProps>;
};

export const mapToComparedFeedbackAnswer = (
  answer: AnswerProps,
  question: FeedbackQuestionProps,
): ComparedFeedbackAnswer => {
  switch (question.flair__Feedback_Question_Type__c) {
    case FeedbackQuestionType.Unknown:
    case FeedbackQuestionType.FreeText: {
      return {
        type: ComparedFeedbackAnswerType.FREE_TEXT,
        id: answer.Id,
        value: answer.feedbackAnswer,
      };
    }
    case FeedbackQuestionType.SingleChoice: {
      return {
        type: ComparedFeedbackAnswerType.SINGLE_CHOICE,
        id: answer.Id,
        value: answer.feedbackAnswer,
        note: answer.flair__Note__c,
        choices: question.flair__Choices__c,
      };
    }
    case FeedbackQuestionType.MultipleChoice: {
      return {
        type: ComparedFeedbackAnswerType.MULTIPLE_CHOICE,
        id: answer.Id,
        value: answer.feedbackAnswer?.split(';') ?? null,
        note: answer.flair__Note__c,
        choices: question.flair__Choices__c,
      };
    }
    case FeedbackQuestionType.Rating: {
      return {
        type: ComparedFeedbackAnswerType.RATING,
        id: answer.Id,
        value: answer.feedbackAnswer ?? null,
        note: answer.flair__Note__c,
        choices: question.flair__Choices__c,
      };
    }
    case FeedbackQuestionType.Dropdown: {
      return {
        type: ComparedFeedbackAnswerType.DROPDOWN,
        id: answer.Id,
        value: answer.feedbackAnswer,
        note: answer.flair__Note__c,
        choices: question.flair__Choices__c,
      };
    }
    case FeedbackQuestionType.Scorecard: {
      return {
        type: ComparedFeedbackAnswerType.SCORECARD,
        id: answer.Id,
        value: answer.feedbackAnswer || '',
        note: answer.flair__Note__c,
        choices: question.flair__Choices__c,
      };
    }
    case FeedbackQuestionType.FreeSlider: {
      return {
        type: ComparedFeedbackAnswerType.FREE_SLIDER,
        id: answer.Id,
        value: answer.feedbackAnswer,
        note: answer.flair__Note__c,
      };
    }
    // Question type YES_NO answers should be not visible at this moment
    case FeedbackQuestionType.YesNo: {
      return {
        type: ComparedFeedbackAnswerType.YES_NO,
        id: '',
        value: '',
      };
    }
  }
};

export const mapToComparedFeedbackQuestion = (
  question: FeedbackQuestionProps,
): ComparedFeedbackQuestion => ({
  id: question.Id,
  value: question.flair__Question__c,
  answerVisibility: question.flair__Answer_Visibility__c,
  questionVisibility: question.flair__Feedback_Question_Visibility__c,
});

export const useComparedFeedbackSectionsMapper = () => {
  return useCallback(
    ({ questions, employeeAnswers, managerAnswers, resolutions }: Params) => {
      const resolutionByQuestion = keyBy<EmployeeFeedbackResolutionProps>(
        resolutions,
        (r) => r.flair__Employee_Feedback_Question__c,
      );
      const employeeAnswerByQuestion = keyBy<AnswerProps>(
        employeeAnswers,
        (a) => a.flair__Employee_Feedback_Question__c,
      );
      const managerAnswerByQuestion = keyBy<AnswerProps>(
        managerAnswers,
        (a) => a.flair__Employee_Feedback_Question__c,
      );

      return questions.reduce((result, question) => {
        const employeeResolution = resolutionByQuestion[question.Id];
        const employeeAnswer = employeeAnswerByQuestion[question.Id];
        const managerAnswer = managerAnswerByQuestion[question.Id];

        if (!employeeResolution || !employeeAnswer || !managerAnswer) {
          return result;
        }

        const resolution = {
          id: employeeResolution.Id,
          value: employeeResolution.flair__Resolution__c,
          employeeAnswer: mapToComparedFeedbackAnswer(employeeAnswer, question),
          managerAnswer: mapToComparedFeedbackAnswer(managerAnswer, question),
          question: mapToComparedFeedbackQuestion(question),
        };

        const sectionId = question.flair__Employee_Feedback_Question_Section__c;
        if (!sectionId) {
          return result;
        }

        const sectionIndex = result.findIndex((s) => s.id === sectionId);
        if (sectionIndex !== -1) {
          const section = result[sectionIndex];

          result[sectionIndex] = {
            ...section,
            resolutions: [...section.resolutions, resolution],
          };

          return result;
        }

        return [
          ...result,
          {
            id: sectionId,
            name: question.flair__Section_Name__c ?? '',
            isEmployeeSatisfactionSection:
              question.flair__Is_Satisfaction_Section__c || false,
            resolutions: [resolution],
          },
        ];
      }, [] as ComparedFeedbackSection[]);
    },
    [],
  );
};
