import React from 'react';
import { Emoji } from '../../../Announcement';
import './EmployeeEmojiList.css';
import { useUserInfo } from '../../../../context/UserInfo';
import ReactionButton from '../../../ReactionButton';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { findEmployeeEmoji, getNamesOfEmployeesReacted } from '../../logic';

type EmployeeEmojiListProps = {
  emojis: Emoji[][];
  onRemoveEmoji: (e: Emoji) => void;
  onSelectEmoji: (name: string) => void;
  handleClose: () => void;
};

const i18Path = 'reaction';

const EmployeeEmojiList: React.FC<EmployeeEmojiListProps> = ({
  emojis,
  onSelectEmoji,
  onRemoveEmoji,
  handleClose,
}) => {
  const { id } = useUserInfo();
  const t = useNamespacedTranslation(i18Path);

  return (
    <div className="collapsed-employees-reactions">
      <div className="reactions-popover-header">
        <div>{t('title')}</div>
        <FlairIcon icon="close-outline" onClick={handleClose} />
      </div>
      <div className="overflow-auto selected-emojis-popover-list pt-4">
        {emojis.map((e) => {
          const emoji = findEmployeeEmoji(e, id);
          const isSelected = !!emoji;
          return (
            <div
              key={e[0].name}
              className="item d-flex gap-3 align-items-start">
              <ReactionButton
                key={e[0].name}
                isSelected={isSelected}
                onClick={() => {
                  isSelected ? onRemoveEmoji(emoji) : onSelectEmoji(e[0].name);
                }}
                counter={e.length}
                emoji={e[0].name}
              />
              <div>{getNamesOfEmployeesReacted(e)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmployeeEmojiList;
