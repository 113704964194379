import moment from 'moment';

export type DateInterval = {
  from: Date;
  to: Date;
};

export const currentWeek = (): DateInterval => ({
  from: moment(new Date()).startOf('isoWeek').toDate(),
  to: moment(new Date()).endOf('isoWeek').toDate(),
});

export const currentYearPeriod = (yearParam?: number) => {
  const year = yearParam ?? new Date().getFullYear();
  return {
    from: moment().year(year).startOf('year').toISOString(),
    to: moment().year(year).endOf('year').toISOString(),
  };
};

export const currentMonthPeriod = () => {
  return {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
  };
};

export const toMonthRange = (date: string) => ({
  from: moment(date).startOf('month').format('YYYY-MM-DD'),
  to: moment(date).endOf('month').format('YYYY-MM-DD'),
});

export const eachDayOfInterval = (interval: DateInterval): Date[] => {
  const result: Date[] = [];
  for (
    let start = moment(interval.from);
    start.isSameOrBefore(interval.to);
    start.add(1, 'day')
  ) {
    result.push(start.toDate());
  }

  return result;
};

export const isoWeekFromDateString = (date: string) => moment(date).isoWeek();

export const firstDateOfIsoYear = (year: number) =>
  moment().year(year).isoWeek(1).startOf('isoWeek');

export const lastDateOfIsoYear = (year: number) =>
  moment()
    .year(year)
    .isoWeek(moment().year(year).weeksInYear())
    .endOf('isoWeek');
