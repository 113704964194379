import React, { useCallback, useState } from 'react';
import { LoomVideoRecord } from '../LoomVideoRecord';
import { LoomVideoPreview } from '../LoomVideoPreview';
import { Spinner } from 'react-bootstrap';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { StyledTransparentButton } from '../LoomStartRecordButton';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { loomBrandingTheme } from '../loomBranding';
import { Popover } from '../../../../../components/popover';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import {
  CreateLoomVideoInput,
  useAddLoomVideoMutation,
  useRemoveLoomVideoMutation,
} from '../../../__generated__/graphql';
import { RecordedLoomVideo } from '../types';
import { Maybe } from '../../../../../utils/maybe';
import './LoomVideoWithPopoverEditor.css';

type Props = {
  entityId: string;
  loomVideo: Maybe<RecordedLoomVideo>;
  allowActions?: boolean;
  previewWidth?: number;
  previewHeight?: number;
};

const DEFAULT_PREVIEW_WIDTH = 330;
const DEFAULT_PREVIEW_HEIGHT = 200;

const i18Path = 'components.loomVideo';

const LoomVideoWithPopoverEditor: React.FC<Props> = ({
  loomVideo,
  entityId,
  allowActions = true,
  previewWidth = DEFAULT_PREVIEW_WIDTH,
  previewHeight = DEFAULT_PREVIEW_HEIGHT,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [localLoomVideo, setLocalLoomVideo] =
    useState<Maybe<CreateLoomVideoInput>>(loomVideo);
  const isServerLoom = !!loomVideo;

  const [addLoomVideo, { loading: isAddingLoomLoading }] =
    useAddLoomVideoMutation();
  const [removeLoomVideo, { loading: isRemovingLoomLoading }] =
    useRemoveLoomVideoMutation();

  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();
  const [show, setShow] = useState<boolean>(false);

  const handleSaveLoomVideo = useCallback(async () => {
    localLoomVideo &&
      (await addLoomVideo({
        variables: {
          input: {
            loomVideo: localLoomVideo,
            relatedObjectName: 'flair__Timesheet_Day__c',
            relatedId: entityId,
          },
        },
      })
        .then(() => {
          addSuccess(t('addSuccessNotificationMessage'));
        })
        .catch(errorHandler)
        .finally(() => setShow(false)));
  }, [addSuccess, addLoomVideo, errorHandler, entityId, localLoomVideo, t]);

  const handleDeleteLoomVideo = useCallback(async () => {
    loomVideo &&
      (await removeLoomVideo({
        variables: {
          input: {
            id: loomVideo.loomId,
            relatedObjectName: 'flair__Timesheet_Day__c',
            relatedId: entityId,
          },
        },
      })
        .then(() => {
          addSuccess(t('deleteSuccessNotificationMessage'));
          setLocalLoomVideo(null);
        })
        .catch(errorHandler)
        .finally(() => setShow(false)));
  }, [addSuccess, removeLoomVideo, errorHandler, entityId, loomVideo, t]);

  const LoomVideoPreviewActions = !isServerLoom ? (
    <div className="d-flex position-relative gap-2">
      <Button
        label={t('cancel')}
        variant="outline-primary"
        className="delete-loom-btn"
        size="sm"
        onClick={() => setLocalLoomVideo(null)}
      />
      <Button
        label={t('save')}
        variant="primary"
        className="delete-loom-btn"
        size="sm"
        onClick={handleSaveLoomVideo}
      />
    </div>
  ) : (
    allowActions && (
      <Button
        label={t('delete')}
        variant="outline-primary"
        className="delete-loom-btn w-25"
        size="sm"
        onClick={handleDeleteLoomVideo}
      />
    )
  );

  if (isAddingLoomLoading || isRemovingLoomLoading) {
    return (
      <StyledTransparentButton>
        <div className="d-flex position-relative ms-2 gap-2">
          <Spinner
            animation="border"
            size="sm"
            style={{ color: loomBrandingTheme.colors.main }}
          />
        </div>
      </StyledTransparentButton>
    );
  }

  return localLoomVideo ? (
    <Popover
      className="loom-popover"
      headerText={t('description')}
      popoverContent={
        <div className="d-flex flex-column align-items-center gap-2">
          <LoomVideoPreview
            loomVideo={localLoomVideo}
            previewWidth={previewWidth}
            previewHeight={previewHeight}
          />
          {LoomVideoPreviewActions}
        </div>
      }
      show={show}
      onToggle={() => setShow(!show)}
      rootClose={true}
      placement={'right'}>
      <StyledTransparentButton
        id={'loom-video-preview-button'}
        size="sm"
        className="text-nowrap d-flex align-items-center justify-content-center">
        <div className="d-flex position-relative ms-2 gap-2">
          <FlairIcon
            icon="loom"
            className="mt-1 mb-1 me-0"
            color={`${loomBrandingTheme.colors.main}`}
          />
          <div>{isServerLoom ? t('viewStateText') : t('saveStateText')}</div>
        </div>
      </StyledTransparentButton>
    </Popover>
  ) : allowActions ? (
    <LoomVideoRecord
      text={''}
      onVideoRecorded={(v) => setLocalLoomVideo(v)}
      isTransparent={true}
      id={entityId}
      buttonText={t('startRecordButtonText')}
    />
  ) : (
    <></>
  );
};

export default LoomVideoWithPopoverEditor;
