import React from 'react';
import { useEmployeeNotificationSettingsQuery } from '../../../__generated__/graphql';
import NotificationSettingsLoadingSkeleton from './NotificationSettingsLoadingSkeleton';
import ServerError from '../../../../../components/ServerError';
import { Col, Row } from 'react-bootstrap';
import { useNotificationSettingsData } from './useNotificationSettingsData';
import NotificationSettingsCategoryCard from './NotificationSettingsCategoryCard';
import NotificationSettingsHeader from './NotificationSettingsHeader';
import { SlackStatusAutoUpdateSettings } from '../SlackStatusAutoUpdateSettings/SlackStatusAutoUpdateSettings';
import { routes } from '../../../routes';

const NotificationSettings2: React.FC = () => {
  const [notificationSettingsInfoByCategory] = useNotificationSettingsData();
  const { data, loading, error } = useEmployeeNotificationSettingsQuery();

  if (loading || !data) {
    return <NotificationSettingsLoadingSkeleton />;
  }

  if (error) {
    return <ServerError />;
  }
  return (
    <>
      <Row>
        <Col>
          <SlackStatusAutoUpdateSettings
            pageUrl={routes.notificationSettings.route.create({})}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NotificationSettingsHeader data={data} />
          {notificationSettingsInfoByCategory
            .filter(
              (notificationSettingsCategory) =>
                notificationSettingsCategory.showCondition ?? true,
            )
            .map((notificationSettingsCategory, index) => (
              <NotificationSettingsCategoryCard
                key={index}
                data={data}
                categoryItem={notificationSettingsCategory}
              />
            ))}
        </Col>
      </Row>
    </>
  );
};
export default NotificationSettings2;
