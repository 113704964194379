import React, { useCallback } from 'react';
import { InventoryFilter } from './types';
import { OptionBase } from '../../../../../../components/Select/types';

import { getMonthsOptions } from '../../../../../../utils/dateUtils';
import styled from '@emotion/styled';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';
import {
  createInventoryStatusOptions,
  createMonthOptions,
  createYearOptions,
} from './mappings';
import { emptyInventoryFilter } from '../../common';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import GlobalTableFilter from '../../../../components/GlobalTableFilter';
import { debounce } from 'lodash';

export type Props = {
  filter: InventoryFilter;
  onChange: (filter: InventoryFilter) => void;
  categoryOptions: OptionBase[];
  locationOptions: OptionBase[];
};
const i18Prefix = 'myData.inventory.table.filter';
export const InventoryFilters: React.FC<Props> = ({
  filter,
  onChange,
  categoryOptions,
  locationOptions,
}) => {
  const t = useNamespacedTranslation(i18Prefix);
  const yearOptions = createYearOptions(new Date());
  const monthOptions = createMonthOptions(getMonthsOptions());
  const SingleFilterStyled = styled(SingleFilter)({
    maxWidth: '12.5rem',
  });

  const handleCategoryChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'category'),
    [filter, onChange],
  );

  const handleLocationChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'location'),
    [filter, onChange],
  );

  const handleMonthChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'month'),
    [filter, onChange],
  );

  const handleYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  const handleStatusChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );

  const handleSearch = useCallback(
    (value) => {
      debounce(handleFilterChange, 300)(value, filter, onChange, 'searchTerm');
    },
    [filter, onChange],
  );

  const handleClearFilter = useCallback(() => {
    onChange(emptyInventoryFilter);
  }, [onChange]);

  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <div className="d-flex flex-wrap align-items-center">
        <GlobalTableFilter
          value={filter.searchTerm || ''}
          placeholder={t('search')}
          onFilterChange={handleSearch}
        />
      </div>
      <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
        <span className="d-none me-3 d-md-block">{t('filterBy')}</span>
        <SingleFilterStyled
          placeholder={t('category')}
          isSearchable={true}
          options={categoryOptions}
          value={filter.category}
          onChange={handleCategoryChanged}
        />
        <SingleFilterStyled
          placeholder={t('location')}
          isSearchable={true}
          options={locationOptions}
          value={filter.location}
          onChange={handleLocationChanged}
        />

        <SingleFilterStyled
          placeholder={t('month')}
          isSearchable={true}
          options={monthOptions}
          value={filter.month}
          onChange={handleMonthChanged}
        />
        <SingleFilterStyled
          placeholder={t('year')}
          isSearchable={true}
          options={yearOptions}
          value={filter.year}
          onChange={handleYearChanged}
        />
        <SingleFilterStyled
          placeholder={t('status')}
          isSearchable={true}
          options={createInventoryStatusOptions()}
          value={filter.status}
          onChange={handleStatusChanged}
        />
        <ClearFiltersLink className="ms-3" onClick={handleClearFilter} />
      </div>
    </div>
  );
};

const handleFilterChange = (
  value: string | null,
  filter: InventoryFilter,
  onChange: (filter: InventoryFilter) => void,
  filterKey: keyof InventoryFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
