import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeOption, EmployeeOptionLabel } from './EmployeeOptionLabel';
import {
  SelectMultipleBase,
  Props as SelectMultipleBaseProps,
} from './SelectMultipleBase';
import { RenderOptionParams } from './SelectSingleBase';

export type Props = Omit<
  SelectMultipleBaseProps<EmployeeOption>,
  'renderOption'
> & {
  options: EmployeeOption[];
};
export const SelectMultipleEmployees: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const renderOption = useCallback(
    ({ option, isInsideSelect }: RenderOptionParams<EmployeeOption>) => {
      return (
        <EmployeeOptionLabel employee={option} isNameVisible={isInsideSelect} />
      );
    },
    [],
  );

  return (
    <SelectMultipleBase
      {...props}
      renderOption={renderOption}
      placeholder={
        props.placeholder ?? t('select.selectSingleEmployee.placeholder')
      }
      noOptionsMessage={
        props.noOptionsMessage ?? t('select.selectSingleEmployee.noOptions')
      }
    />
  );
};
