import React from 'react';
import * as yup from 'yup';
import { Modal, ModalFooter } from 'react-bootstrap';
import { Form } from '../../../../../../components/form/Form';
import { Input } from '../../../../../../components/form/Input';
import Button from '../../../../../../components/button';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../../theme';

const MAX_COMMENT_LENGTH = 254;

type Props = {
  visible: boolean;
  onClose: () => void;
  inProgress?: boolean;
  onSubmit: (reason: string | null) => void;
};

type FormData = {
  reason?: string;
};

const validationSchema = yup.object().shape<FormData>({
  reason: yup.string().notRequired(),
});

const i18Path = 'support.tickets.closeTicketModal';

export const CloseTicketModal: React.FC<Props> = ({
  visible,
  onClose,
  inProgress,
  onSubmit,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const handleSubmit = (form: FormData) => {
    onSubmit(form.reason ?? null);
  };

  return (
    <Modal show={visible} onHide={onClose} centered contentClassName="border">
      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Header
          closeButton
          className="pb-0 mt-4 d-flex flex-column-reverse align-items-center border-bottom-0">
          <h2 className="card-header-title h2-medium mt-3">{t('title')}</h2>
          <IconContainer className="d-flex justify-content-center p-3 align-items-center">
            <FlairIcon icon="alert-outline" />
          </IconContainer>
        </Header>
        <Modal.Body className="py-0">
          <Input
            className="mb-0"
            type="textarea"
            name="reason"
            minRows={2}
            maxLength={MAX_COMMENT_LENGTH}
            placeholder={t('placeholder')}
          />
        </Modal.Body>
        <ModalFooter className="border-top-0 d-flex pt-0 justify-content-center align-items-center">
          <Button
            type="submit"
            label={t('buttons.cancel')}
            disabled={inProgress}
            onClick={() => onClose()}
            variant="outline-primary"
            size="lg"></Button>
          <Button
            type="submit"
            label={t('buttons.submit')}
            disabled={inProgress}
            isProcessing={inProgress}
            variant="primary"
            size="lg"></Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Header = styled(Modal.Header)`
  height: 5rem;
`;

const IconContainer = styled.div`
  border-radius: 50%;
  background-color: ${Theme.color.bgRed};
`;
