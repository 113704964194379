import React from 'react';
import { FormGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

type Props = {
  lines?: number;
};

const InputHeight = 40;

const SkeletonInput: React.FC<Props> = ({ lines = 1 }) => {
  return (
    <FormGroup>
      <label>
        <Skeleton width={50} />
      </label>
      <Skeleton height={lines * InputHeight} />
    </FormGroup>
  );
};

export default SkeletonInput;
