import * as React from 'react';

const CloseOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.3587 3.51151C13.5989 3.27129 13.5989 2.88181 13.3587 2.64159C13.1184 2.40137 12.729 2.40137 12.4887 2.64159L8.00038 7.12997L3.512 2.64159C3.27178 2.40137 2.8823 2.40137 2.64208 2.64159C2.40186 2.88181 2.40186 3.27129 2.64208 3.51151L7.13046 7.99989L2.64208 12.4883C2.40186 12.7285 2.40186 13.118 2.64208 13.3582C2.8823 13.5984 3.27178 13.5984 3.512 13.3582L8.00038 8.86981L12.4888 13.3582C12.729 13.5984 13.1184 13.5984 13.3587 13.3582C13.5989 13.118 13.5989 12.7285 13.3587 12.4883L8.8703 7.99989L13.3587 3.51151Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default CloseOutlineIcon;
