import { MutationResult } from '@apollo/client';
import { useCallback } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import {
  ResumeMyTimeEntryMutation,
  useResumeMyTimeEntryMutation,
} from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';

type Result = [() => Promise<void>, MutationResult<ResumeMyTimeEntryMutation>];

export const useResumeTimeTracking = (timeEntryId: string): Result => {
  const [resumeTimeEntry, mutationResult] = useResumeMyTimeEntryMutation({});
  const errorHandler = useMutationErrorHandler();

  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const handleOnResume = useCallback(() => {
    return resumeTimeEntry({
      variables: { id: timeEntryId },
    })
      .then((response) => {
        const errors =
          response.data?.timeTracking.resumeMyTimeEntry.errors2 || [];
        showErrorsAndWarningsToasts({ errors });
      })
      .catch(errorHandler);
  }, [resumeTimeEntry, timeEntryId, errorHandler, showErrorsAndWarningsToasts]);

  return [handleOnResume, mutationResult];
};
