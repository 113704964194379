import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { CardItem } from '../../../../../../components/SelectableCardsGroup';
import { i18Path } from '../components/InventoryModal/constants';

export const useGetCategoryList = () => {
  const t = useNamespacedTranslation(`${i18Path}.category`);

  const categoryList: CardItem[] = [
    {
      iconName: 'electronic-device-icon',
      label: t('electronicDevice'),
      buttonKey: 'Electronic Device',
    },
    {
      iconName: 'office-equipment-icon',
      label: t('officeEquipment'),
      buttonKey: 'Office Equipment',
    },
    {
      iconName: 'inventory-other-icon',
      label: t('other'),
      buttonKey: 'Other',
    },
  ];

  return { categoryList };
};
