import { useState } from 'react';
import { createRootFolderId } from '../types';
import { useLocation } from 'react-router-dom';

const usePathFromQueryString = (paramName: string) =>
  new URLSearchParams(useLocation().search).get(paramName);
const PATH_PARAM_NAME = 'path';

export const useFolder = ({ employeeId }: { employeeId: string }) => {
  const paramPath = usePathFromQueryString(PATH_PARAM_NAME);

  const rootFolderId = createRootFolderId(employeeId);
  const [currentFolderId, setCurrentFolderId] = useState<string>(
    paramPath ? paramPath : rootFolderId,
  );

  return {
    currentFolderId,
    setCurrentFolderId,
    rootFolderId,
  };
};
