import React from 'react';
import { Container } from 'react-bootstrap';
import SkeletonPageHeader from '../../components/Skeleton/PageHeader';

const Loading: React.FC = () => {
  return (
    <Container>
      <SkeletonPageHeader />
    </Container>
  );
};

export default Loading;
