import { toISODateOnly } from '../../../../../utils/dateUtils';
import {
  EmployeeScopeFilter,
  useDashboardBirthdayColleaguesQuery,
} from '../../../__generated__/graphql';
import { FilterOption } from '../CommonComponents/WidgetFilter/types';
import { useState } from 'react';

const useBirthdaysData = (initialFilterBy = EmployeeScopeFilter.MyTeam) => {
  const [filterBy, setFilterBy] = useState(initialFilterBy);
  const { data, loading, error } = useDashboardBirthdayColleaguesQuery({
    variables: {
      filter: {
        limit: 3,
        filterBy,
        startDate: toISODateOnly(new Date()),
      },
    },
  });

  const handleChangeFilter = (filter: string) => {
    if (filter === FilterOption.MY_TEAM.toString()) {
      setFilterBy(EmployeeScopeFilter.MyTeam);
    } else {
      setFilterBy(EmployeeScopeFilter.AllCompany);
    }
  };

  return {
    data,
    loading,
    error,
    filterBy,
    handleChangeFilter,
  };
};

export default useBirthdaysData;
