import React from 'react';
import { EmptyStateCardBody } from '../../components/EmptyStateCard';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

const i18Path = 'documents2.empty';

export const FilteredEmptyDocuments: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <EmptyStateCardBody
      title={t('filtered.title')}
      subtitle={t('filtered.subTitle')}
    />
  );
};
