import * as React from 'react';

const TrendingUpOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M11 4C10.7239 4 10.5 4.22386 10.5 4.5C10.5 4.77614 10.7239 5 11 5H13.2929L9.35363 8.93926L9.35359 8.9393C9.30716 8.98574 9.25204 9.02258 9.19137 9.04772C9.1307 9.07285 9.06567 9.08579 9 9.08579C8.93433 9.08579 8.8693 9.07285 8.80863 9.04772C8.74796 9.02258 8.69284 8.98574 8.64641 8.9393L7.06078 7.35367C6.92149 7.21435 6.75611 7.10383 6.5741 7.02843C6.39209 6.95302 6.19701 6.91421 6 6.91421C5.80299 6.91421 5.60791 6.95302 5.4259 7.02843C5.24389 7.10383 5.07851 7.21435 4.93922 7.35367L1.14645 11.1464C0.951184 11.3417 0.951184 11.6583 1.14645 11.8536C1.34171 12.0488 1.65829 12.0488 1.85355 11.8536L5.64641 8.0607C5.69284 8.01426 5.74796 7.97742 5.80863 7.95228C5.8693 7.92715 5.93433 7.91421 6 7.91421C6.06567 7.91421 6.1307 7.92715 6.19137 7.95228C6.25204 7.97742 6.30716 8.01426 6.35359 8.0607L7.93922 9.64633C8.07851 9.78565 8.24388 9.89617 8.4259 9.97157C8.60791 10.047 8.80299 10.0858 9 10.0858C9.19701 10.0858 9.39209 10.047 9.5741 9.97157C9.7561 9.89618 9.92146 9.78567 10.0607 9.64637L14 5.70711V8C14 8.27614 14.2239 8.5 14.5 8.5C14.7761 8.5 15 8.27614 15 8V4.5C15 4.22386 14.7761 4 14.5 4H11Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default TrendingUpOutlineIcon;
