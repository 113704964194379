import { NotificationItemData, NotificationQueryItem } from '../../types';
import { routes } from '../../../../routes';
import { t } from 'i18next';

const i18prefix = 'notifications.tickets.manager';
export const mapTicketSharedNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };

  if (src.renderInfo.__typename === 'TicketSharedNotificationRenderInfo') {
    if (!src.renderInfo.ticket?.Id) {
      return null;
    }
    data.title = t(`${i18prefix}.shared`);
    data.icon = 'help-desk-outline';

    const employee = src.renderInfo.ticket?.employee;
    if (employee && employee.Name) {
      data.description = src.renderInfo.ticket?.employee?.Name;
    }

    const requestId = src.renderInfo.ticket?.Id;
    if (requestId) {
      data.url = routes.managerTickets.route.create({
        id: requestId,
      });
    }

    const ticket = src.renderInfo.ticket;
    const subject = ticket?.subject;
    if (subject && subject.label) {
      data.urlLabel = subject.label;
    }
  }
  return data;
};
