import React from 'react';
import { AvatarProps } from '../../../molecules/avatar/Avatar';

export const Initials: React.FC<
  Pick<AvatarProps, 'initials' | 'shape' | 'className'>
> = ({ initials, shape = 'rounded-circle', className = '' }) => (
  <span className={['avatar-title', shape, className].join(' ')}>
    {initials}
  </span>
);
