import * as React from 'react';

const LeftArrowIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
      width="18"
      height="18"
      viewBox="0 0 26 26"
      fill="none">
      <path
        d="M18.5607 2.46026C19.1464 3.07394 19.1464 4.06891 18.5607 4.6826L10.6213 13L18.5607 21.3174C19.1464 21.9311 19.1464 22.9261 18.5607 23.5397C17.9749 24.1534 17.0251 24.1534 16.4393 23.5397L7.43934 14.1112C6.85355 13.4975 6.85355 12.5025 7.43934 11.8888L16.4393 2.46026C17.0251 1.84658 17.9749 1.84658 18.5607 2.46026Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default LeftArrowIcon;
