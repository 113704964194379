import styled from '@emotion/styled';
import React from 'react';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import Hint from '../../../../../../components/hint';
import { formatDateLong } from '../../../../../../utils/dateUtils';
import { Project, Timesheet } from '../../types';
import { useTimeSheetDays } from '../ProjectTimesheetsByDays/useTimeSheetDays';
import TimesheetDayCommentsPopover from '../TimesheetDayCommentsPopover';

type Props = {
  timesheet: Timesheet;
  projects: Project[];
};

const DayCol = styled.td({
  minWidth: `6.25rem`,
});

const HoursHeader: React.FC<Props> = ({ timesheet, projects }) => {
  const days = useTimeSheetDays(timesheet, projects);

  return (
    <>
      {days.map((day) => (
        <DayCol className="cell-bl" key={day.timeSheetDayId}>
          <div className="d-flex justify-content-between">
            <Hint
              id="project-date-hint"
              mode="popover"
              text={formatDateLong(day.day.toDate())}>
              <FormattedDate day={day.day.toDate()} format="day-with-weekday" />
            </Hint>
            <TimesheetDayCommentsPopover
              timesheetDayId={day.timeSheetDayId}
              commentsCount={day.commentsCount}></TimesheetDayCommentsPopover>
          </div>
        </DayCol>
      ))}
    </>
  );
};

export default HoursHeader;
