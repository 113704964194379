export const getEmployeeInitials = ({
  flair__First_Name__c,
  flair__Last_Name__c,
}: {
  flair__First_Name__c: string | null;
  flair__Last_Name__c: string | null;
}): string => {
  const firstName = flair__First_Name__c || '';
  const lastName = flair__Last_Name__c || '';
  const firstInitial = firstName.length > 0 ? firstName[0] : '';
  const lastInitial = lastName.length > 0 ? lastName[0] : '';
  return `${firstInitial}${lastInitial}`;
};

export const getEmployeeInitialsFromName = (name: string): string => {
  return name
    .split(' ')
    .reduce(
      (initials, name) => `${initials}${name.length > 0 ? name[0] : ''}`,
      '',
    );
};
