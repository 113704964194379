import { FilterSelectOption } from '../../../../atomic/molecules/FlairDropdown/types';

export type CalendarFilter = {
  departments: string[];
  skills: string[];
};

export type FilterOptions = {
  departments: FilterSelectOption[];
  skills: FilterSelectOption[];
};

export const DEPARTMENTS_FILTER = 'departments';
export const SKILLS_FILTER = 'skills';

export const CalendarFiltersKeysArray = [
  DEPARTMENTS_FILTER,
  SKILLS_FILTER,
] as const;

export type CalendarFiltersKeys = (typeof CalendarFiltersKeysArray)[number];
