import { compact, sortBy, sumBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { ManagerTimeSheetFragment } from '../../../__generated__/graphql';
import {
  ClockedOutBadge,
  OvertimeBadge,
  TrackingBadge,
} from './CurrentTimeEntryBadge';
import { hoursToMinutes } from '../../../../../utils/time';
import { isHourlyBasedWeek } from '../../../utils/timesheetHelper';

type Props = {
  timeSheet: ManagerTimeSheetFragment;
};

type CurrentTimeEntry = {
  trackedHours: number;
  startDate: moment.Moment;
  endDate: moment.Moment | null;
};

type TimeEntry = ManagerTimeSheetFragment['timeEntries'][0];

const buildCurrentTimeEntry = (
  timeSheet: ManagerTimeSheetFragment,
): CurrentTimeEntry | null => {
  const today = new Date();
  const currentTimeEntries = timeSheet.timeEntries.filter(
    (entry) =>
      !entry.flair__End_Datetime__c ||
      moment(entry.flair__Start_Datetime__c).isSame(today, 'day'),
  );

  const mergeTimeEntries = (entries: TimeEntry[]) =>
    sortBy(entries, 'flair__Start_Datetime__c').reduce<CurrentTimeEntry | null>(
      (result, timeEntry) => ({
        trackedHours:
          (result?.trackedHours ?? 0) +
          timeEntry.flair__Working_Period_In_Hours__c,
        startDate:
          result?.startDate ?? moment(timeEntry.flair__Start_Datetime__c),
        endDate: timeEntry.flair__End_Datetime__c
          ? moment(timeEntry.flair__End_Datetime__c)
          : null,
      }),
      null,
    );

  const currentTrackedEntry = mergeTimeEntries(
    currentTimeEntries.filter((t) => t.flair__End_Datetime__c),
  );

  const currentRunnningEntry = mergeTimeEntries(
    currentTimeEntries.filter((t) => !t.flair__End_Datetime__c),
  );

  return compact([
    currentTrackedEntry,
    currentRunnningEntry,
  ]).reduce<CurrentTimeEntry | null>(
    (result, entry) => ({
      trackedHours: (result?.trackedHours ?? 0) + entry.trackedHours,
      startDate: result?.startDate ?? entry.startDate,
      endDate: entry.endDate,
    }),
    null,
  );
};

const calcDailyHours = (timeSheet: ManagerTimeSheetFragment): number =>
  sumBy(
    timeSheet.workloads.filter((workload) =>
      moment(new Date()).isBetween(
        workload.flair__Start_Date__c,
        workload.flair__End_Date__c ?? new Date(),
        'day',
        '[]',
      ),
    ),
    'flair__Hours_Per_Day__c',
  );

const TimeSheetEntryCurrentTimeEntry: React.FC<Props> = ({ timeSheet }) => {
  const timeEntry = buildCurrentTimeEntry(timeSheet);

  if (!timeEntry) {
    return <></>;
  }

  const dailyHours = calcDailyHours(timeSheet);
  const overtime = getDailyOvertime(
    timeSheet,
    timeEntry.trackedHours,
    dailyHours,
  );

  if (timeEntry.endDate) {
    return <ClockedOutBadge overtime={overtime} />;
  }

  if (overtime > 0) {
    return <OvertimeBadge overtime={overtime} />;
  }

  const trackingProgress = timeEntry.trackedHours / dailyHours;

  return (
    <TrackingBadge
      duration={hoursToMinutes(timeEntry.trackedHours)}
      clockedInAt={timeEntry.startDate}
      percentage={trackingProgress * 100}
    />
  );
};

const getDailyOvertime = (
  timeSheet: ManagerTimeSheetFragment,
  trackedHours: number,
  dailyHours: number,
): number => {
  return isHourlyBasedWeek(timeSheet)
    ? 0
    : hoursToMinutes(trackedHours - dailyHours);
};

export default TimeSheetEntryCurrentTimeEntry;
