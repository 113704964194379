import React, { useCallback } from 'react';
import _ from 'lodash';
import { SkillsMatrix, Props as SkillsMatrixProps } from './SkillsMatrix';
import { SkillSingleSelect } from '../common/SkillSingleSelect';
import Skeleton from 'react-loading-skeleton';
import ServerError from '../../../../../components/ServerError';
import styled from '@emotion/styled';
import { useSkillsMatrix } from './useSkillsMatrix';
import { SelectSingleEmployeeConnected } from '../../../components/Selects/SelectSingleEmployeeConnected';
import Button from '../../../../../components/button';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = Pick<SkillsMatrixProps, 'onEmployeeClick'>;

export const SkillsMatrixConnected: React.FC<Props> = (props) => {
  const t = useNamespacedTranslation('skills.matrix');
  const {
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectedSkillIds,
    setSelectedSkillIds,
    notSelectedSkills,
    selectedEmployeeInfos,
    selectedSkills,
    employeeSkills,
    error,
    resetSelection,
  } = useSkillsMatrix();

  const handleAddEmployee = useCallback(
    (employeeId: string | null) => {
      if (selectedEmployeeIds === null) {
        return;
      }
      if (employeeId) {
        setSelectedEmployeeIds(_.uniq([...selectedEmployeeIds, employeeId]));
      }
    },
    [selectedEmployeeIds, setSelectedEmployeeIds],
  );

  const handleResetClick = useCallback(() => {
    resetSelection();
  }, [resetSelection]);

  const handleRemoveEmployee = useCallback(
    (employeeId) => {
      if (selectedEmployeeIds === null) {
        return;
      }
      setSelectedEmployeeIds(
        selectedEmployeeIds.filter((x) => x !== employeeId),
      );
    },
    [setSelectedEmployeeIds, selectedEmployeeIds],
  );

  const handleAddSkill = useCallback(
    (skillId) => {
      if (selectedSkillIds === null) {
        return;
      }
      setSelectedSkillIds(_.uniq([...selectedSkillIds, skillId]));
    },
    [setSelectedSkillIds, selectedSkillIds],
  );

  const handleRemoveSkill = useCallback(
    (skillId) => {
      if (selectedSkillIds === null) {
        return;
      }
      setSelectedSkillIds(selectedSkillIds.filter((x) => x !== skillId));
    },
    [setSelectedSkillIds, selectedSkillIds],
  );

  if (error) {
    return <ServerError />;
  }

  const renderSkillSelect = () => {
    return notSelectedSkills ? (
      <SkillSingleSelect
        value={null}
        placeholder={t('addSkillPlaceholder')}
        allSkills={notSelectedSkills}
        onChange={handleAddSkill}
        className="small"
      />
    ) : (
      <Skeleton width={100} height={40} />
    );
  };

  const renderEmployeeSelect = () => {
    return (
      <SelectSingleEmployeeConnected
        value={null}
        heightMode="normal"
        employeeOptionLabelClassName="small"
        className="small"
        placeholder={t('addEmployeePlaceholder')}
        excludeIds={selectedEmployeeIds ?? []}
        onChange={handleAddEmployee}
      />
    );
  };

  if (
    !selectedEmployeeIds ||
    !selectedSkillIds ||
    !selectedSkills ||
    !selectedEmployeeInfos
  ) {
    return <Loading />;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center px-4">
        <div>
          <Button
            label={t('resetMatrix')}
            onClick={handleResetClick}
            size="sm"
            variant="outline-secondary"></Button>
          <InfoHint text={t('resetMatrixHint')} className="ms-2" />
        </div>
        <SkillSelectContainer>{renderSkillSelect()}</SkillSelectContainer>
      </div>
      <SkillsMatrixContainer className="py-4">
        <SkillsMatrix
          {...props}
          employees={selectedEmployeeInfos}
          skills={selectedSkills}
          employeeSkills={employeeSkills ?? 'loading'}
          onRemoveEmployeeClick={handleRemoveEmployee}
          onRemoveSkillClick={handleRemoveSkill}
          topLeftCell={renderEmployeeSelect()}
        />
      </SkillsMatrixContainer>
    </div>
  );
};

const Loading = () => {
  return (
    <div className="px-4 pb-4">
      <div className="d-flex justify-content-between align-items-center">
        <Skeleton width="5rem" height="1.5rem" />
        <Skeleton width="7.5rem" height="1.5rem" />
      </div>
      <Skeleton className="mt-4" width="100%" height={400} />
    </div>
  );
};

const SkillsMatrixContainer = styled.div({
  maxWidth: '100%',
  overflowY: 'auto',
});

const SkillSelectContainer = styled.div({
  minWidth: '12rem',
});
