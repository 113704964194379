import React, { useMemo } from 'react';
import StepItem, { StepProp } from './components/StepItem';

export type Props = {
  steps: StepProp[];
  activeStep: number;
  onClick?: (stepIndex: number) => void;
};

export const SimpleStep: React.FC<Props> = ({
  steps,
  activeStep,
  onClick = () => {},
}) => {
  const stepsList = useMemo(() => {
    return steps.map((stepItem, index) => (
      <StepItem
        key={stepItem.title}
        {...stepItem}
        stepNumber={index}
        onClick={onClick}
        isActive={activeStep === index}
      />
    ));
  }, [steps, activeStep, onClick]);

  return <div className="d-flex gap-2">{stepsList}</div>;
};

export type { StepProp };
