import React, { useMemo } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import FormattedDate from '../../../../components/date/FormattedDate';
import { DropdownActionItem } from '../../../../atomic/molecules/DropdownActionItem';
import { TableContent } from '../../manager/components/TableContent';
import { EmptyCertificates } from './EmptyCertificates';
import { Certificate, CertificateStatus, DocumentFile } from './types';
import { CertificateStatusCell } from './Common/CertificateStatusCell';
import Button from '../../../../components/button';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import CommentsPopover from '../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../components/Comment/types';
import { DropdownActions } from '../../../../atomic/templates/DropdownActions';

type TableItem = {
  id: string;
  name: string;
  issuedBy: string;
  issueDate: Date | null;
  expirationDate: Date | null;
  status: string;
  certificateUrl: string | null;
  plannedDate: Date | null;
  documents: DocumentFile[] | [];
  recordId: string;
  commentsCount: number;
};

type Props = {
  certificates: Certificate[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
};

export const MyCertificatesTable: React.FC<Props> = ({
  certificates,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation();

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => <Trans t={t} i18nKey="certificates.table.header.name" />,
        accessor: 'name',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) => {
          if (row.original.certificateUrl) {
            return (
              <a
                target="_blank"
                rel="noreferrer"
                href={row.original.certificateUrl}>
                {value}
                <span className="ml-2">
                  <FlairIcon
                    size="xs"
                    className="mx-1"
                    icon="external-link-outline"
                  />
                </span>
              </a>
            );
          } else if (
            !row.original.certificateUrl &&
            row.original.documents.length
          ) {
            return row.original.documents.map((document: DocumentFile) => (
              <div key={document.id}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={document.downloadPublicUrl}>
                  {value}
                  <span className="ml-2">
                    <FlairIcon
                      className="mx-1"
                      size="xs"
                      icon="external-link-outline"
                    />
                  </span>
                </a>
              </div>
            ));
          } else {
            return (
              <Button
                variant="link"
                size="sm"
                className="p-0"
                label={value}
                onClick={(e) => {
                  e.preventDefault();
                  onEdit(row.original.id);
                }}
              />
            );
          }
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="certificates.table.header.status" />
        ),
        accessor: 'status',
        Cell: ({ value }: { value: string }) => (
          <div>
            {
              <CertificateStatusCell
                value={value as CertificateStatus}></CertificateStatusCell>
            }
          </div>
        ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="certificates.table.header.issuedBy" />
        ),
        accessor: 'issuedBy',
        Cell: ({ value }: { value: string }) => <div>{value}</div>,
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="certificates.table.header.issueDate" />
        ),
        accessor: 'issueDate',
        Cell: ({ value, row }: { value: Date; row: Row<TableItem> }) => {
          if (value) {
            return <FormattedDate day={value} format="short" />;
          } else if (!value && row.original.plannedDate) {
            return (
              <FormattedDate day={row.original.plannedDate} format="short" />
            );
          } else {
            return '---';
          }
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="certificates.table.header.expirationDate" />
        ),
        accessor: 'expirationDate',
        Cell: ({ value, row }: { value: Date; row: Row<TableItem> }) => {
          if (value) {
            return <FormattedDate day={value} format="short" />;
          } else if (!value && row.original.issueDate) {
            return t('certificates.table.noExpirationDate');
          } else {
            return '---';
          }
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="certificates.table.header.comments" />
        ),
        accessor: 'recordId',
        Cell: ({ value, row }) => (
          <div className="mt-2">
            <CommentsPopover
              recordId={value}
              relatedObjectName={RelatedObjectNames.EmployeeCertificate}
              commentsCount={row.original.commentsCount}
              mode="icon"
            />
          </div>
        ),
      },
      {
        accessor: 'id',
        Cell: ({ value }: { value: string; row: Row<TableItem> }) => (
          <DropdownActions id="certificate-actions" className="mt-2">
            <DropdownActionItem
              onClick={() => {
                onEdit(value);
              }}>
              {t('certificates.table.action.edit')}
            </DropdownActionItem>
            <DropdownActionItem
              onClick={() => {
                onDelete(value);
              }}>
              {t('certificates.table.action.delete')}
            </DropdownActionItem>
          </DropdownActions>
        ),
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [onDelete, onEdit, t]);

  if (!certificates.length) {
    return <EmptyCertificates />;
  }

  return (
    <TableContent
      columns={columns}
      data={certificates}
      getRowProps={() => ({ role: 'button' })}
    />
  );
};
