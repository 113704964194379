import React from 'react';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import { formatTime24h } from '../../../../../utils/dateUtils';
import classNames from 'classnames';
import { DateTimeValueWithChangeRequest } from '../types';

type Props = {
  value: DateTimeValueWithChangeRequest;
  infoText?: string | null;
  popover?: React.ReactElement;
};

export const StartEndTimeCell: React.FC<Props> = ({
  value,
  infoText,
  popover,
}) => {
  const valueToRender = value.changeTo ? value.changeTo : value.value;
  return valueToRender ? (
    <div
      className={classNames('d-flex gap-1 align-items-center', {
        'text-warning': value.changeTo !== undefined,
      })}>
      {infoText && <InfoHint className="d-flex" text={infoText} />}
      <div>{formatTime24h(valueToRender)}</div>
      {popover}
    </div>
  ) : (
    <span>-</span>
  );
};
