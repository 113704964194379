import React from 'react';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../theme';
import { CancelButton } from '../../../../components/button/CancelButton';

type Props = {
  onCancel: () => void;
  selectedCount: number;
  onSelectAll: () => void;
  children: React.ReactElement;
};

const i18Prefix = 'components.selectionBottomPanel';

export const SelectionBottomPanel: React.FC<Props> = ({
  selectedCount = 0,
  onSelectAll,
  onCancel,
  children,
}) => {
  const t = useNamespacedTranslation(i18Prefix);
  if (selectedCount)
    return (
      <PanelWrapper className="position-fixed">
        <SelectionPanel>
          <div className="d-flex flex-row justify-content-between align-items-center h-100 p-4">
            <div className="gap-2 d-flex flex-row align-items-center">
              <CountBadge className="d-flex justify-content-center align-items-center m-0">
                {selectedCount}
              </CountBadge>
              <PanelText className="m-0">{t(`texts.selected`)}</PanelText>
              <PanelText className="m-0">·</PanelText>
              <SelectAllWrapper
                onClick={onSelectAll}
                className="fw-semibold m-0">
                {t(`buttons.selectAll`)}
              </SelectAllWrapper>
            </div>
            <div className="d-flex flex-row gap-2">{children}</div>
            <div>
              <BannerCancelButton
                label={t(`buttons.cancel`)}
                variant="transparent"
                onClick={onCancel}
              />
            </div>
          </div>
        </SelectionPanel>
      </PanelWrapper>
    );
  return null;
};

const PanelWrapper = styled.div({
  padding: '0 1.25rem',
  bottom: '1.5rem',
  left: '18.75rem',
  width: 'calc(100% - 21.875rem)',
  height: '3.875rem',
});

const SelectionPanel = styled.div({
  background: Theme.color.accentBlueDark,
  width: '100%',
  borderRadius: '0.625rem',
  height: '3.875rem',
});

const CountBadge = styled.h4({
  background: Theme.color.blue6,
  borderRadius: '0.313rem',
  width: '1.25rem',
  height: '1.25rem',
});

const PanelText = styled.div({
  color: Theme.color.white,
});

const SelectAllWrapper = styled(PanelText)({
  cursor: 'pointer',
});

const BannerCancelButton = styled(CancelButton)`
  &:hover,
  &:focus {
    border-color: ${Theme.color.accentBlueDark};
    background: ${Theme.color.accentBlueDark};
    color: ${Theme.color.white};
    opacity: 0.8;
  }
  background: ${Theme.color.accentBlueDark};
  width: 6.25rem;
  height: 2.375rem;
  color: ${Theme.color.white};
`;
