import React, { useContext } from 'react';
import { useNamespacedTranslation } from '../../../../../../../../hooks/useNamespacedTranslation';

import { InventoryModalContext } from '../InventoryModalContext';
import Button from '../../../../../../../../components/button';
import { i18Path } from '../constants';

type Props = {
  isLoading: boolean;
};
export const StandardFooter: React.FC<Props> = ({ isLoading }) => {
  const { onCancel } = useContext(InventoryModalContext);
  const t = useNamespacedTranslation(i18Path);

  return (
    <div className="d-flex justify-content-between w-100">
      <Button
        onClick={onCancel}
        variant="outline-primary"
        label={t('buttons.cancel')}
        className="d-flex align-items-center"></Button>

      <Button
        type="submit"
        variant="primary"
        isProcessing={isLoading}
        label={t('buttons.add')}
        className="d-flex align-items-center"></Button>
    </div>
  );
};
