import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { Datepicker } from '../../../../../../../../../components/form/Datepicker';
import { i18Path } from '../../constants';
import { formatDate } from '../../../../../../../../../utils/dateUtils';

export const WarrantyDateFormField: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <Datepicker
      type="date"
      label={t('form.fields.warrantyDate')}
      name="warrantyDate"
      placeholder={formatDate(new Date(), 'dd.MM.yyyy')}
    />
  );
};
