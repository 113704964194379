import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { FormContext } from 'react-hook-form';
import { ModalSidebarContent } from '../../../../components/ModalSidebar';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { useUserInfo } from '../../../../context/UserInfo';
import { useIsManagerMode } from '../../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../../__generated__/graphql';
import { GoalOptions } from '../useGoalSelectOptions';
import { GoalCommonFields } from './GoalCommonFields';
import { GoalCreateTypeField } from './GoalCreateTypeField';
import { GoalSaveButton } from './GoalSaveButton';
import { ObjectiveFormData } from './types';
import { useCreateObjectiveForm } from './useObjectiveForm';
import { useCreateObjective } from './useObjectiveMutations';

type Props = {
  owner?: EmployeeOption;
  employeeId?: string;
  options: GoalOptions;
  onClose: () => void;
};

export const GoalCreateForm: React.FC<Props> = ({
  onClose,
  employeeId,
  ...restProps
}) => {
  const userInfo = useUserInfo();

  const { id: meId } = userInfo;
  const { hasManagerRole } = useIsManagerMode();

  const defaultSelectedEmployee = employeeId || meId;
  const form = useCreateObjectiveForm(defaultSelectedEmployee);
  const [createObjective] = useCreateObjective(defaultSelectedEmployee);

  const handleSubmit = useCallback(
    (formData: ObjectiveFormData) => {
      return createObjective(formData).then(() => {
        onClose();
      });
    },
    [createObjective, onClose],
  );

  return (
    <FormContext {...form}>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <ModalSidebarContent
          body={
            <div>
              {hasManagerRole(ManagerRole.DirectManager) ? (
                <GoalCreateTypeField isDisabled={form.formState.isSubmitting} />
              ) : (
                <input type="hidden" name="type" ref={form.register} />
              )}
              <GoalCommonFields {...restProps} mode="create" />
            </div>
          }
          footer={<GoalSaveButton />}
        />
      </Form>
    </FormContext>
  );
};

const Form = styled('form')({
  display: 'contents',
});
