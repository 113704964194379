import { useCallback, useState } from 'react';
import { isTableRowTimeSheet, TableRow } from '../types';
import { uniq } from 'lodash';
import { isSelectDisabled } from '../mappings';

export const useProjectsTimeSheetsTableSelection = (
  allTableRows: TableRow[],
  approvingIds: string[],
) => {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const onSelectAll = useCallback(
    (allTableRows: TableRow[], selected: boolean) => {
      const approvingIdsSet = new Set(approvingIds);
      if (selected) {
        setSelectedRowIds(
          allTableRows
            .filter((x) => !isSelectDisabled(x, approvingIdsSet))
            .map((x) => x.id),
        );
      } else {
        setSelectedRowIds([]);
      }
    },
    [setSelectedRowIds, approvingIds],
  );

  const onSelectRow = useCallback(
    (allTableRows: TableRow[], selectedRow: TableRow, selected: boolean) => {
      const selectedId = selectedRow.id;
      const approvingIdsSet = new Set(approvingIds);
      const affectedRowIds: string[] = [];

      const pushTableRowToToggle = (curRow: TableRow) => {
        affectedRowIds.push(curRow.id);
      };

      for (const curRow of allTableRows) {
        if (isSelectDisabled(curRow, approvingIdsSet)) {
          continue;
        }
        //select the same row since we click on
        if (curRow.id === selectedId) {
          pushTableRowToToggle(curRow);
        }
        if (curRow.id.includes(selectedId)) {
          pushTableRowToToggle(curRow);
        }
      }
      if (selected) {
        setSelectedRowIds((prev) => uniq([...prev, ...affectedRowIds]));
      } else {
        setSelectedRowIds((prev) =>
          prev.filter((curId) => !affectedRowIds.includes(curId)),
        );
      }
    },
    [setSelectedRowIds, approvingIds],
  );

  const selectedTimeSheetIds: string[] = allTableRows
    .filter((x) => isTableRowTimeSheet(x) && selectedRowIds.includes(x.id))
    .map((x) => x.timeSheetId!);

  return { selectedRowIds, selectedTimeSheetIds, onSelectAll, onSelectRow };
};
