import { orderBy } from 'lodash';
import React from 'react';
import ServerError from '../../../../../components/ServerError';
import {
  EmployeeOption,
  mapEmployeeOption,
} from '../../../components/Selects/EmployeeOptionLabel';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import { useTimeSheetFiltersQuery } from '../../../__generated__/graphql';
import FiltersLoading from './FiltersLoading';
import {
  TimeSheetFilters,
  Props as TimeSheetFiltersProps,
} from './TimeSheetFilters';

type Props = Omit<TimeSheetFiltersProps, 'employeeOptions'> & {
  reportsMode: ManagerReportsMode;
};

export const TimeSheetFiltersConnected: React.FC<Props> = ({
  reportsMode,
  ...restProps
}) => {
  const { data, loading, error } = useTimeSheetFiltersQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });

  if (!data || loading) {
    return <FiltersLoading />;
  }

  if (error) {
    return <ServerError />;
  }

  const employeeOptions: EmployeeOption[] = orderBy(
    data.manager.employees.map(mapEmployeeOption),
    'label',
  );

  return <TimeSheetFilters employeeOptions={employeeOptions} {...restProps} />;
};
