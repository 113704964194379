import React, { useCallback, useState } from 'react';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  AbsenceType,
  AccrualPolicyInterval,
} from '../../__generated__/graphql';
import { AbsenceCategoryAccrualInterval } from './AbsenceCategoryAccrualInterval';
import RequestingAmountBadge from './RequestingAmountBadge';
import { AbsenceRequestValidationResult } from './types';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import {
  calculateWithdrawalAllowancesForYearlyPeriods,
  WithdrawalAllowancesCardTable,
} from './withdrawalAllowances';

type Props = {
  data?: AbsenceRequestValidationResult;
  loading: boolean;
  type: AbsenceType;
  accrualPolicyInterval: AbsenceCategoryAccrualInterval;
  unlimited: boolean;
};

const MoreDetailsLink: React.FC<{
  handleShowDetails: () => void;
  showDetailsOpened: boolean;
}> = ({ handleShowDetails, showDetailsOpened }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={handleShowDetails}
      variant="link"
      size="sm"
      className="p-0 b-0">
      <span className="d-flex align-items-center">
        <span>
          {showDetailsOpened
            ? t('requestAbsence.requestingAmount.allowances.showLess')
            : t('requestAbsence.requestingAmount.allowances.showMore')}
        </span>
        <FlairIcon
          icon={
            showDetailsOpened ? 'chevron-up-outline' : 'chevron-down-outline'
          }
        />
      </span>
    </Button>
  );
};

const RequestingAmountResult: React.FC<Props> = ({
  data,
  loading,
  type,
  accrualPolicyInterval,
  unlimited,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = useCallback(() => setOpen((open) => !open), [setOpen]);

  const monthly = accrualPolicyInterval === AccrualPolicyInterval.Monthly;

  const withdrawalAllowances =
    data && !monthly
      ? calculateWithdrawalAllowancesForYearlyPeriods(
          data.allowances ?? [],
          data.employeeAbsenceCategory.currentYearSummary,
          new Date(),
        )
      : [];

  const showAllowances =
    !unlimited &&
    !monthly &&
    withdrawalAllowances.length > 0 &&
    data &&
    (data.error === null ||
      data.error?.__typename ===
        'ValidateAbsenceRequestInsufficientCreditsError' ||
      data.error?.__typename ===
        'ValidateAbsenceRequestOverlappingAbsencesError');

  if (!data && !loading) {
    return null;
  }

  return (
    <Row>
      <Col>
        <Row className="align-items-center">
          <Col>
            <RequestingAmountBadge
              amount={data?.requestedAmount ?? undefined}
              loading={loading}
              type={type}
              error={data?.error ?? undefined}
              allowances={withdrawalAllowances}
              unlimited={unlimited}
            />
          </Col>
          {showAllowances && (
            <Col className="col-auto">
              <MoreDetailsLink
                handleShowDetails={toggleOpen}
                showDetailsOpened={open}
              />
            </Col>
          )}
        </Row>
        {showAllowances && (
          <Row>
            <Col>
              <Collapse in={open}>
                <div className="mt-3">
                  <WithdrawalAllowancesCardTable
                    allowances={withdrawalAllowances}
                    absenceType={type}
                  />
                </div>
              </Collapse>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default RequestingAmountResult;
