import React from 'react';

import Select, { Props as ReactSelectProps } from 'react-select';
import './FlairMultiSelectDropdown.css';
import {
  components as selectComponents,
  GroupBase,
  SelectComponentsConfig,
} from 'react-select';
import { FilterSelectOption } from '../types';
import SoftBadge from '../../../../apps/dashboard/components/SoftBadge';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

export type FlairMultiSelectDropdownProps = {
  options: FilterSelectOption[];
  selectedOptions: string[];
  className?: string;
  components?: SelectComponentsConfig<
    FilterSelectOption,
    true,
    GroupBase<FilterSelectOption>
  >;
} & ReactSelectProps<FilterSelectOption, true>;

export const FlairMultiSelectDropdown: React.FC<
  FlairMultiSelectDropdownProps
> = ({ options, selectedOptions, className, components, ...rest }) => {
  const t = useNamespacedTranslation('flairComponents.dropdown');

  const filteredOptions = options.filter((o) => o.showCondition ?? true);

  const defaultComponents: SelectComponentsConfig<
    FilterSelectOption,
    true,
    GroupBase<FilterSelectOption>
  > = {
    IndicatorSeparator: null,

    ValueContainer: ({ children, ...props }) => {
      const value = props.getValue();
      let currentValues: ReadonlyArray<FilterSelectOption> = [];
      if (value != null && Array.isArray(value)) {
        currentValues = value;
      }
      return (
        <selectComponents.ValueContainer {...props}>
          {!props.selectProps.menuIsOpen && (
            <div>
              {props.selectProps.placeholder}{' '}
              {!!currentValues.length && (
                <SoftBadge variant="primary">{currentValues.length}</SoftBadge>
              )}
            </div>
          )}
          {/*children[1] will contain the search functionality for the ValueContainer */}
          {Array.isArray(children) && children.length > 1 && children[1]}
        </selectComponents.ValueContainer>
      );
    },
    Option: (props) => (
      <div>
        <selectComponents.Option {...props}>
          <input
            type="checkbox"
            className="form-check-input"
            defaultChecked={props.isSelected}
          />
          <span className="ms-2">{props.data.label}</span>
        </selectComponents.Option>
      </div>
    ),
  };

  return (
    <Select
      classNamePrefix="flair-select"
      className={className}
      isMulti
      options={filteredOptions}
      value={filteredOptions.filter((o) =>
        selectedOptions.includes(o.value.toString()),
      )}
      components={{ ...defaultComponents, ...components }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isSearchable={true}
      noOptionsMessage={() => t('noOptionsMessage')}
      menuPortalTarget={document.body}
      menuShouldBlockScroll={true}
      {...rest}
    />
  );
};
