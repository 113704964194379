import { StatusDotVariant } from '../../../components/StatusDot';
import { Maybe } from '../../../../../utils/maybe';

export type Evaluation = {
  id: string;
  candidate: Candidate;
  score: Score;
  scoreRange: Score;
  overallComment: string | null;
  evaluator: Evaluator;
  stage: string;
  dueDate?: string;
  status: Status;
  jobName: Maybe<string>;
  isEvaluated: boolean;
};

export type Status = {
  value: 'pending' | 'overdue' | 'completed';
  label: string;
  variant: StatusDotVariant;
};
export type Score = number | null;

export type Candidate = {
  id: string;
  name: string;
  job: Maybe<Job>;
};

export type Job = {
  id: string;
  name: string;
};

export type Evaluator = {
  id: string;
  name: string;
  avatar: string | null;
};

export type EvaluationsFilterParams = {
  stages: string;
  scores: string;
  statuses: string;
};

export type EvaluationsFilter = {
  stages: string[];
  scores: string[];
  statuses: string[];
};

export const STAGES_FILTER = 'stages';
export const SCORES_FILTER = 'scores';
export const STATUSES_FILTER = 'statuses';
export const EvaluationsFiltersKeysArray = [
  STAGES_FILTER,
  SCORES_FILTER,
  STATUSES_FILTER,
] as const;

export type EvaluationsFiltersKeys =
  (typeof EvaluationsFiltersKeysArray)[number];
export type EvaluationsFiltersType = Record<EvaluationsFiltersKeys, string>;

export const filterDefaultData: EvaluationsFilterParams = {
  stages: '',
  scores: '',
  statuses: '',
};
