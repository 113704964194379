import CommentsPopoverConnected, {
  Props as CommentsPopoverConnectedProps,
} from './CommentsPopoverConnected';
import {
  CommentsPopoverButton,
  Props as CommentsPopoverButtonProps,
} from './CommentsPopoverButton';
import React from 'react';

export type Props = Omit<CommentsPopoverConnectedProps, 'children'> &
  CommentsPopoverButtonProps;

export const CommentsPopoverConnectedWithButton: React.FC<Props> = ({
  children,
  mode,
  commentsCount,
  ...props
}) => {
  return (
    <CommentsPopoverConnected
      {...props}
      children={
        <CommentsPopoverButton mode={mode} commentsCount={commentsCount} />
      }
    />
  );
};
