import _ from 'lodash';
import { useCallback } from 'react';
import { useUserInfo } from '../../context/UserInfo';
import {
  MeCurrentTimeEntryReadCacheFragment,
  MeCurrentTimeEntryReadCacheFragmentDoc,
} from '../../__generated__/graphql';
import { useApolloCache } from '../useApolloCache';
import { TimeEntryPayload } from '../webSocket/webSocketMessages';

type ReloadTimeEntryArgs =
  | { timeEntry: TimeEntryPayload | null; force?: undefined }
  | { timeEntry?: undefined; force: true };

export const useReloadCurrentTimeTracking = () => {
  const cache = useApolloCache();
  const { id: meId } = useUserInfo();

  const meCacheId: string | undefined = cache.identify({
    __typename: 'Me',
    Id: meId,
  });

  const invalidateCache = useCallback(() => {
    if (!meCacheId) {
      return;
    }
    cache.evict({
      id: meCacheId,
      fieldName: 'currentTimeEntry',
    });
  }, [cache, meCacheId]);

  const reloadCurrentTimeTracking = useCallback(
    (args: ReloadTimeEntryArgs) => {
      if (!meCacheId) {
        return;
      }
      if (args.force === true) {
        invalidateCache();
        return;
      }
      const cacheRes = cache.readFragment<MeCurrentTimeEntryReadCacheFragment>({
        id: meCacheId,
        fragment: MeCurrentTimeEntryReadCacheFragmentDoc,
        fragmentName: 'MeCurrentTimeEntryReadCache',
      });
      if (!cacheRes) {
        return;
      }
      if (isSameTimeTrackingInCache(cacheRes, args.timeEntry)) {
        return;
      }
      invalidateCache();
    },
    [cache, meCacheId, invalidateCache],
  );

  return { reloadCurrentTimeTracking };
};

const isSameTimeTrackingInCache = (
  cache: MeCurrentTimeEntryReadCacheFragment,
  timeEntry: TimeEntryPayload | null,
): boolean => {
  const cacheTimeEntry = cache.currentTimeEntry;
  if (cacheTimeEntry === null && timeEntry === null) {
    return true;
  }
  if (cacheTimeEntry === null || timeEntry === null) {
    return false;
  }

  const cacheObject = {
    id: cacheTimeEntry.Id,
    lastModifiedDate: cacheTimeEntry.LastModifiedDate,
    breaks: cacheTimeEntry.breaks.map((b) => ({
      id: b.Id,
      lastModifiedId: b.LastModifiedDate,
    })),
  };
  const payloadObject = {
    id: timeEntry.id,
    lastModifiedDate: timeEntry.lastModifiedDate,
    breaks: timeEntry.breaks.map((b) => ({
      id: b.id,
      lastModifiedId: b.lastModifiedDate,
    })),
  };
  return _.isEqual(cacheObject, payloadObject);
};
