import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  useWorkflowItemsFilterQuery,
  WorkflowContext,
} from '../../../__generated__/graphql';
import {
  Props as WorkflowsItemsFilterProps,
  WorkflowItemsFilter,
} from './WorkflowItemsFilter';
import { WorkflowItemsFilterLoading } from './WorkflowItemsFilterLoading';
import { mapCommonOption } from './mappings';

type Props = Omit<
  WorkflowsItemsFilterProps,
  | 'workflowTemplateOptions'
  | 'workflowItemTemplateOptions'
  | 'responsibleOptions'
> & {
  workflowContext: WorkflowContext;
};

export const WorkflowItemsFilterConnected: React.FC<Props> = ({
  workflowContext,
  ...props
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  const { data, loading, error } = useWorkflowItemsFilterQuery({
    variables: {
      workflowContext,
    },
  });

  if (error) {
    return <div>{t('errorLoadingOptions')}</div>;
  }

  if (loading || !data) {
    return <WorkflowItemsFilterLoading workflowContext={workflowContext} />;
  }
  const {
    workflowTemplates,
    workflowItemTemplates,
    employees,
    responsibleEmployees,
    responsibleTeams,
  } = data.workflowItemsFilterOptions;

  const workflowTemplateOptions = workflowTemplates.map(mapCommonOption);
  const workflowItemTemplateOptions =
    workflowItemTemplates.map(mapCommonOption);
  const employeeOptions = employees.map(mapCommonOption);
  const responsibleOptions = [...responsibleEmployees, ...responsibleTeams].map(
    mapCommonOption,
  );

  return (
    <WorkflowItemsFilter
      {...props}
      responsibleOptions={responsibleOptions}
      workflowItemTemplateOptions={workflowItemTemplateOptions}
      employeeOptions={employeeOptions}
      yearVisible={true}
      onlyMyTasksVisible={false}
      workflowTemplateOptions={workflowTemplateOptions}></WorkflowItemsFilter>
  );
};
