import * as React from 'react';

const UmbrellaOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0625 3.9375H2.1875C1.95544 3.9375 1.73288 4.02969 1.56878 4.19378C1.40469 4.35788 1.3125 4.58044 1.3125 4.8125V10.9375C1.3125 11.1696 1.40469 11.3921 1.56878 11.5562C1.73288 11.7203 1.95544 11.8125 2.1875 11.8125H10.0625C10.2946 11.8125 10.5171 11.7203 10.6812 11.5562C10.8453 11.3921 10.9375 11.1696 10.9375 10.9375V4.8125C10.9375 4.58044 10.8453 4.35788 10.6812 4.19378C10.5171 4.02969 10.2946 3.9375 10.0625 3.9375ZM10.0625 10.9375H2.1875V4.8125H10.0625V10.9375ZM12.6875 3.0625V9.625C12.6875 9.74103 12.6414 9.85231 12.5594 9.93436C12.4773 10.0164 12.366 10.0625 12.25 10.0625C12.134 10.0625 12.0227 10.0164 11.9406 9.93436C11.8586 9.85231 11.8125 9.74103 11.8125 9.625V3.0625H3.5C3.38397 3.0625 3.27269 3.01641 3.19064 2.93436C3.10859 2.85231 3.0625 2.74103 3.0625 2.625C3.0625 2.50897 3.10859 2.39769 3.19064 2.31564C3.27269 2.23359 3.38397 2.1875 3.5 2.1875H11.8125C12.0446 2.1875 12.2671 2.27969 12.4312 2.44378C12.5953 2.60788 12.6875 2.83044 12.6875 3.0625Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default UmbrellaOutlineIcon;
