import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { AvatarContainer } from '../../../atoms/avatar/AvatarContainer';
import { AvatarSize } from '../../../atoms/avatar/AvatarContainer/types';

export const AvatarSkeleton: React.FC<{ size?: AvatarSize }> = ({ size }) => {
  return (
    <AvatarContainer size={size}>
      <Skeleton
        width="100%"
        height="100%"
        style={{ borderRadius: '50%', lineHeight: 'inherit' }}
      />
    </AvatarContainer>
  );
};
