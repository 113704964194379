import React from 'react';
import { DocumentStatus } from '../types';
import { StatusCircle } from '../../../components/StatusCircle';
import { getDocumentItemStatusColor } from '../mappings';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  status: DocumentStatus;
};
const i18Prefix = 'documents2.personal.table.documentStatus';
export const DocumentItemStatus: React.FC<Props> = ({ status }) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <div className="d-flex flex-row align-items-center gap-sm-2">
      <StatusCircle color={getDocumentItemStatusColor(status)} size={5} />
      <span>{t(`${status}`)}</span>
    </div>
  );
};
