import * as React from 'react';

const ProfileFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.4375 9.375C13.4375 10.0549 13.2359 10.7195 12.8582 11.2848C12.4805 11.8501 11.9436 12.2907 11.3155 12.5508C10.6874 12.811 9.99619 12.8791 9.32938 12.7464C8.66257 12.6138 8.05007 12.2864 7.56932 11.8057C7.08858 11.3249 6.76119 10.7124 6.62855 10.0456C6.49592 9.37881 6.56399 8.68765 6.82417 8.05953C7.08434 7.4314 7.52494 6.89454 8.09023 6.51682C8.65552 6.13911 9.32013 5.9375 10 5.9375C10.9114 5.93853 11.7851 6.30103 12.4295 6.94546C13.074 7.58989 13.4365 8.46364 13.4375 9.375ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.874 9.07464 16.6864 8.15898 16.3234 7.30778C15.9604 6.45658 15.4295 5.68734 14.7624 5.04604C14.0952 4.40475 13.3056 3.90457 12.4408 3.57544C11.5759 3.2463 10.6536 3.09496 9.72891 3.13047C6.04922 3.27266 3.11485 6.3375 3.125 10.0195C3.12853 11.6957 3.74674 13.3124 4.8625 14.5633C5.3169 13.9042 5.8941 13.339 6.5625 12.8984C6.61949 12.8608 6.68724 12.8429 6.75539 12.8474C6.82353 12.8519 6.88832 12.8786 6.93985 12.9234C7.78921 13.6581 8.87466 14.0624 9.99766 14.0624C11.1207 14.0624 12.2061 13.6581 13.0555 12.9234C13.107 12.8786 13.1718 12.8519 13.2399 12.8474C13.3081 12.8429 13.3758 12.8608 13.4328 12.8984C14.1021 13.3387 14.6801 13.904 15.1352 14.5633C16.2564 13.3078 16.8758 11.6833 16.875 10Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ProfileFilledIcon;
