import React, { useCallback, useContext } from 'react';
import InputLabel from '../../../../../../../../../components/form/InputLabel';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { CreatableDropdownControlled } from '../../../../../../../../../components/form/Selects/CreatableDropdownControlled';
import { InventoryModalContext } from '../../InventoryModalContext';
import ServerError from '../../../../../../../../../components/ServerError';
import { useGetInventoryOptions } from '../../../../hooks/useGetInventoryOptions';
import { i18Path } from '../../constants';

export const NameFormField: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  const { onChange, onChangeAdditionalValues } = useContext(
    InventoryModalContext,
  );
  const { inventoryOptions, findInventoryById, loading, error, setOptions } =
    useGetInventoryOptions();

  const handleAddInventory = useCallback(
    (value) => {
      setOptions([...inventoryOptions, { label: value, value }]);
      onChange({ name: value });
    },
    [setOptions, inventoryOptions, onChange],
  );

  const handleSelectInventory = (value: string | null) => {
    if (value) {
      const isAvailable = findInventoryById(value);
      onChange({ name: value });
      onChangeAdditionalValues({
        isNewInventory: !isAvailable,
        inventoryName: isAvailable ? isAvailable.Name : value,
      });
    }
  };

  if (error) return <ServerError />;

  return (
    <>
      <InputLabel label={t('form.fields.name')} required />
      <CreatableDropdownControlled
        isLoading={loading}
        name="name"
        options={inventoryOptions}
        placeholder={t('form.fields.namePlaceHolder')}
        heightMode="large"
        onCreate={handleAddInventory}
        onSelect={handleSelectInventory}
      />
    </>
  );
};
