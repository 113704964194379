import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { routes } from '../../../routes';
import { JobsFiltersKeys, JobsFiltersType } from '../types';
import { FormControl, InputGroup } from 'react-bootstrap';

type Props = {
  placeholder: string;
  filterData: JobsFiltersType;
  filterKey: JobsFiltersKeys;
};

const JobsTextFilter: React.FC<Props> = ({
  filterData,
  filterKey,
  placeholder,
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleChange = (value: string) => {
    history.push(
      routes.jobsList.route
        .withQueryParams({
          ...queryParams,
          [filterKey]: value,
        })
        .create({}),
    );
  };
  return (
    <InputGroup className="">
      <FormControl
        value={filterData[filterKey]}
        size="sm"
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
      />
    </InputGroup>
  );
};

export default JobsTextFilter;
