import React, { useCallback, useState } from 'react';
import ServerError from '../../../../../../components/ServerError';
import Section from '../../../../components/Section';
import { useUserInfo } from '../../../../context/UserInfo';
import { useIsManagerMode } from '../../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../../__generated__/graphql';
import { useObjectives } from '../../Goals2/useObjectives';
import {
  GoalsSectionContent,
  GoalsSectionContentLoading,
} from './GoalsSectionContent';
import { getDefaultFilter } from './logic';
import { ReviewGoalsSidebar } from './ReviewGoalsSidebar';

type Props = {
  employeeId: string;
  employeeName: string;
};

export const GoalsSection: React.FC<Props> = ({ employeeId, employeeName }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const { id: currentEmployeeId } = useUserInfo();
  const { hasManagerRole } = useIsManagerMode();

  const { goals, loading, error } = useObjectives(
    getDefaultFilter(
      hasManagerRole(ManagerRole.DirectManager),
      false,
      currentEmployeeId,
      employeeId,
    ),
    'all',
  );

  const handleClick = useCallback(
    () => setSidebarVisible(true),
    [setSidebarVisible],
  );

  const handleClose = useCallback(
    () => setSidebarVisible(false),
    [setSidebarVisible],
  );

  const getContent = () => {
    if (loading || goals === undefined) {
      return <GoalsSectionContentLoading />;
    }
    if (error) {
      return <ServerError />;
    }

    const activeGoals = goals.filter(
      (x) => x.status === 'BEHIND' || x.status === 'ON_TRACK',
    );
    return (
      <GoalsSectionContent
        employeeName={employeeName}
        activeGoalsCount={activeGoals.length}
        onClick={handleClick}
      />
    );
  };

  return (
    <Section>
      {getContent()}
      <ReviewGoalsSidebar
        visible={sidebarVisible}
        onClose={handleClose}
        employeeId={employeeId}
        employeeName={employeeName}
      />
    </Section>
  );
};
