import React from 'react';
import { SerialProcessingHookReturnType } from '../../../../hooks/useSerialProcessing';
import { useApproveTimeEntryChangeRequestSerial } from './useApproveTimeEntryChangeRequestSerial';

type ContextType = {
  approveMutation: SerialProcessingHookReturnType;
};

const defaultValueSerialProcessing: SerialProcessingHookReturnType = {
  processItem: (id) => {
    throw new Error('TimeTrackingContextProvider is not setup');
  },
  processingIds: [],
};

const defaultValue: ContextType = {
  approveMutation: defaultValueSerialProcessing,
};

export const TimeTrackingMutationsContext =
  React.createContext<ContextType>(defaultValue);

export const TimeTrackingMutationsContextProvider: React.FC = (props) => {
  const approveMutation = useApproveTimeEntryChangeRequestSerial();

  return (
    <TimeTrackingMutationsContext.Provider
      value={{
        approveMutation,
      }}
      {...props}></TimeTrackingMutationsContext.Provider>
  );
};
