import {
  NavigatableType,
  useUpdateDocumentStatusMutation,
} from '../../../__generated__/graphql';
import { DocumentStatus } from '../types';
import { useToasts } from '../../../../../context/Toast';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useUserInfo } from '../../../context/UserInfo';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';

const i18Path = 'documents2.documentInfoFields';

export const useUpdateDocumentStatus = () => {
  const { id: meId } = useUserInfo();
  const [updateStatus, { loading }] = useUpdateDocumentStatusMutation();
  const { addSuccess, addError } = useToasts();
  const t = useNamespacedTranslation(i18Path);
  const errorHandler = useMutationErrorHandler();
  const updateDocumentStatus = (documentId: string, status: DocumentStatus) => {
    if (!documentId || !status) {
      return;
    }

    updateStatus({
      variables: {
        employeeId: meId,
        input: {
          status,
          navigatableId: {
            id: documentId,
            type: NavigatableType.EmployeeDocument,
          },
        },
      },
    })
      .then(() => {
        addSuccess(t('success'));
      })
      .catch((e) => {
        errorHandler(e);
        addError(t('error'));
      });
  };

  return {
    loading,
    updateDocumentStatus,
  };
};
