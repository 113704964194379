import React from 'react';
import {
  AbsencePopoverFragment,
  AbsencePopoverHolidayFragment,
  AbsencePopoverOverlappingFragment,
} from '../../__generated__/graphql';
import { Popover, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import AbsenceDuration from '../AbsenceCalendar/AbsenceDuration';
import AbsenceAmount from '../../pages/Absences/components/AbsenceAmount';
import { isOverlapped } from '../AbsenceCalendar/Utils';
import { CalendarAbsence } from '../AbsenceCalendar/types';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import AbsenceIcon from '../../pages/Absences/components/AbsenceIcon';

type Props = {
  absence: AbsencePopoverFragment;
  holiday?: AbsencePopoverHolidayFragment;
  absences?: AbsencePopoverOverlappingFragment[];
  isMultiAbsence?: boolean;
};

const OverlappingAbsencesWarning: React.FC<{
  absences: AbsencePopoverOverlappingFragment[];
}> = ({ absences }) => {
  const { t } = useTranslation();
  const overlappings = absences.slice(1);

  return (
    <>
      {absences.length > 1 && (
        <div className="mt-3 mb-0 p-3 alert alert-warning">
          <Row>
            <Col className="col-auto">
              <FlairIcon icon="alert-circle-outline" />
            </Col>
            <Col className="col-auto ps-0">
              {t('absences.calendar.hasOverlapping', {
                count: overlappings.length,
              })}
              <br />
              {overlappings.map((absence) => (
                <div key={absence.Id}>
                  <strong>{absence.flair__Category_Name__c}</strong> (
                  <AbsenceDuration absence={absence} />)
                </div>
              ))}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

const AbsenceTitle: React.FC<{
  absence: AbsencePopoverFragment;
}> = ({ absence }) => (
  <div>
    <AbsenceIcon icon={absence.flair__Category_Icon__c} variant="plain" />
    <span className="ms-2">{absence.flair__Category_Name__c}</span>
  </div>
);

const AbsenceContent: React.FC<Props> = ({
  absence,
  holiday,
  isMultiAbsence,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isMultiAbsence && <AbsenceTitle absence={absence} />}
      <div className={isMultiAbsence ? 'ms-2' : ''}>
        <AbsenceDuration absence={absence} />
        <div>
          <AbsenceAmount
            type={absence.flair__Type__c}
            amount={absence.flair__Working_Amount__c}
            variant="work"
          />
        </div>
        {absence.flair__Comment__c && <div>{absence.flair__Comment__c}</div>}
        {holiday && (
          <div className="mt-2 mb-0">
            <Trans
              t={t}
              i18nKey="absences.calendar.holidayOverlapped"
              values={{
                holiday: holiday.Name,
                interpolation: { escapeValue: false },
              }}
              components={[<strong />]}
            />
          </div>
        )}
      </div>
    </>
  );
};

const AbsencePopover: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ absence, holiday, absences, ...rest }, ref) => {
    const isOverlappedAbsences =
      absences && isOverlapped(absences as CalendarAbsence[]);

    const popoverProps = {
      ref,
      ...rest,
    };
    const isMultiAbsence = absences && absences.length > 1;
    return (
      <Popover id={absence.Id} {...popoverProps}>
        {!isMultiAbsence && (
          <Popover.Header>
            <AbsenceTitle absence={absence} />
          </Popover.Header>
        )}
        <Popover.Body>
          {isMultiAbsence ? (
            absences.map((absenceI) => (
              <AbsenceContent
                key={absenceI.Id}
                absence={absenceI as AbsencePopoverFragment}
                holiday={holiday}
                isMultiAbsence={isMultiAbsence}
              />
            ))
          ) : (
            <AbsenceContent absence={absence} holiday={holiday} />
          )}

          {isOverlappedAbsences && (
            <OverlappingAbsencesWarning
              absences={absences as AbsencePopoverOverlappingFragment[]}
            />
          )}
        </Popover.Body>
      </Popover>
    );
  },
);

export default AbsencePopover;
