import React from 'react';
import { Link } from '../../../../../../../../Router';
import { routes } from '../../../../../../routes';
import { EmployeeFeedbackState } from '../../../../../../__generated__/graphql';
import { FeedbackProps } from '../../../../types';

type PerformanceReviewLinkProps = {
  feedback: FeedbackProps;
  className?: string;
};

export const PerformanceReviewLink: React.FC<PerformanceReviewLinkProps> = ({
  feedback,
  className,
  children,
}) =>
  feedback.outcome?.flair__Ended_At__c ? (
    <Link
      to={routes.performanceReviewerPreviousReviews.route}
      feedbackId={feedback.Id}
      className={className}>
      {children}
    </Link>
  ) : feedback.flair__State__c === EmployeeFeedbackState.Submitted &&
    feedback.employeeFeedback?.flair__State__c ===
      EmployeeFeedbackState.Submitted &&
    feedback.feedbackCycle.flair__Meeting_Required__c ? (
    <Link
      to={routes.performanceReviewerMeeting.route}
      feedbackId={feedback.Id}
      className={className}>
      {children}
    </Link>
  ) : (
    <Link
      to={routes.performanceReviewerEvaluate.route}
      feedbackId={feedback.Id}
      className={className}>
      {children}
    </Link>
  );
