import { EmployeeGroup } from './types';

export const getRecipientName = (recipient: EmployeeGroup): string => {
  switch (recipient.type) {
    case 'employee':
      return recipient.employee.name;
    case 'team':
      return recipient.team.name;
  }
};
