import { useMarkAllTaskNotificationsAsReadMutation } from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';

export const useMarkAllTaskNotificationsAsRead = () => {
  const { id: meId } = useUserInfo();

  return useMarkAllTaskNotificationsAsReadMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const recordIdsModified =
        response.data.notifications.markAllTaskNotificationsAsRead.recordIds;
      if (!recordIdsModified || recordIdsModified.length === 0) {
        return;
      }

      const meCacheId: string | undefined = cache.identify({
        __typename: 'Me',
        Id: meId,
      });
      // uncache inbox notifications
      cache.evict({
        id: meCacheId,
        fieldName: 'taskNotifications',
      });

      cache.gc();
    },
    optimisticResponse: () => {
      return {
        notifications: {
          markAllTaskNotificationsAsRead: {
            recordIds: [],
            __typename: 'AllNotificationsActionPayload',
          },
        },
      };
    },
  });
};
