import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useEmployeeSelectQuery } from '../../__generated__/graphql';
import { EmployeeOption, mapEmployeeOption } from './EmployeeOptionLabel';
import { ContainerWithHeight } from './SelectSingleBase';
import {
  SelectSingleEmployee,
  Props as SelectSingleEmployeeProps,
} from './SelectSingleEmployee';

export type Props = Omit<SelectSingleEmployeeProps, 'options'> & {
  excludeIds?: string[];
};

export const SelectSingleEmployeeConnected: React.FC<Props> = ({
  excludeIds,
  ...props
}) => {
  const { data, loading, error } = useEmployeeSelectQuery();

  const allEmployeeOptions: EmployeeOption[] = useMemo(() => {
    if (!data) {
      return [];
    }
    let allOptions = data.activeEmployees.map(mapEmployeeOption);
    if (excludeIds && excludeIds.length) {
      return allOptions.filter((option) => !excludeIds.includes(option.value));
    }
    return allOptions;
  }, [data, excludeIds]);

  if (error) {
    return (
      <ContainerWithHeight heightMode={props.heightMode}>
        <p className="text-danger">{error}</p>
      </ContainerWithHeight>
    );
  }

  if (loading || !data) {
    return (
      <ContainerWithHeight heightMode={props.heightMode}>
        <Skeleton
          width={200}
          height={props.heightMode === 'normal' ? '2rem' : '2.5rem'}
        />
      </ContainerWithHeight>
    );
  }

  return (
    <SelectSingleEmployee
      {...props}
      menuPortalTarget={document.querySelector('body')}
      menuPosition="fixed"
      options={allEmployeeOptions}></SelectSingleEmployee>
  );
};
