import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmployeeRelatedList } from './types';
import CardHeader from '../CardHeader';
import { FieldValue } from './FieldValue';

type Props = {
  relatedList: EmployeeRelatedList;
};

export const RelatedList: React.FC<Props> = ({ relatedList }) => {
  const { t } = useTranslation();
  const sampleRecord = relatedList.records[0];

  return (
    <Card>
      <CardHeader>{relatedList.label}</CardHeader>
      {sampleRecord ? (
        <Table size="sm" className="card-table" responsive>
          <thead>
            <tr>
              {sampleRecord.fields.map((field) => (
                <th key={field.fieldInfo.label}>{field.fieldInfo.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {relatedList.records.map((record) => (
              <tr key={record.id}>
                {record.fields.map((field, i) => (
                  <td key={`${record.id}-${field.fieldInfo.label}-${i}`}>
                    <FieldValue
                      value={field.value}
                      type={field.fieldInfo.type}
                      options={field.fieldInfo.options}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Card.Body className="text-center">
          <span>{t('general.emptyState.table')}</span>
        </Card.Body>
      )}
    </Card>
  );
};
