import React from 'react';
import { Card } from 'react-bootstrap';

import ProjectsFiltration from '../ProjectsFiltration';
import { ProjectsFilter } from '../types';

type Props = {
  filter: ProjectsFilter;
  onFiltrationChange: (changes: ProjectsFilter) => void;
};

const ProjectsTopPanel: React.FC<Props> = ({
  filter,
  onFiltrationChange,
  children,
}) => (
  <Card>
    <Card.Header className="small h-auto">
      <div className="d-flex align-items-center justify-content-between">
        <ProjectsFiltration
          filter={filter}
          onChange={onFiltrationChange}></ProjectsFiltration>
        {children}
      </div>
    </Card.Header>
  </Card>
);

export default ProjectsTopPanel;
