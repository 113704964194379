import React from 'react';
import { routes as globalRoutes } from '../../routes';
import { TicketsConnected } from './Tickets/TicketsConnected';
import { ManagerTicketsConnected } from '../../manager/Support/ManagerTicketsConnected';

export const routes = () => ({
  tickets: {
    route: globalRoutes.tickets.route,
    render: () => <TicketsConnected />,
  },
  managerTickets: {
    route: globalRoutes.managerTickets.route,
    render: () => <ManagerTicketsConnected />,
  },
});
