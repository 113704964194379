import React from 'react';
import { Moment } from 'moment';
import moment from 'moment';
import { routes } from '../../routes';
import { ButtonGroup } from 'react-bootstrap';
import { Link } from '../../../../Router';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import ShiftsViewBy from '../../components/Shifts/ViewBy';
import ShiftsFilters from './ShiftsFilters';
import classNames from 'classnames';

type Props = {
  startDay: Moment;
  dateParamName: string;
};

const buildDateUrlParam = (date: moment.Moment, offset: number): string =>
  moment(date).add(offset, 'days').format('YYYY-MM-DD');

const i18Path = 'shiftPlanner.nav';

const Nav: React.FC<Props> = ({ startDay, dateParamName }) => {
  const t = useNamespacedTranslation(i18Path);
  const route = routes.allShifts.route;

  return (
    <div className="d-flex justify-content-between align-items-center small">
      <ShiftsFilters route={route} />
      <div
        className={classNames(
          'd-none',
          'd-md-flex',
          'align-items-center',
          'gap-3',
        )}>
        <ShiftsViewBy />
        <small>{t('changeDate')}:</small>
        <ButtonGroup size="sm">
          <Link
            to={routes.allShifts.route.withQueryParams({
              [dateParamName]: buildDateUrlParam(startDay, -7),
            })}
            className="btn btn-light">
            {'←'}
          </Link>
          <Link to={routes.allShifts.route} className="btn btn-light">
            {t('today')}
          </Link>
          <Link
            to={routes.allShifts.route.withQueryParams({
              [dateParamName]: buildDateUrlParam(startDay, 7),
            })}
            className="btn btn-light">
            {'→'}
          </Link>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Nav;
