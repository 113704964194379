import * as React from 'react';
import { useFlairBreadcrumbHook } from '../../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { useHistory, useParams } from 'react-router-dom';
import ServerError from '../../../../../../components/ServerError';
import Detail from '../../../AbsenceApprovalRequestToMe/Detail';
import { useRequestedAbsenceQuery } from '../../../../__generated__/graphql';
import Loading from '../../../AbsenceApprovalRequestToMe/Loading';
import { mapAbsenceToDateTime } from '../../../AbsenceApprovalRequestToMe/logic';
import AbsenceDateTimeRange from '../../components/AbsenceDateTimeRange';
import AbsenceStatus from '../../components/AbsenceStatus';
import CommentsPopover from '../../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import PageHeader from '../../../../components/PageHeader';
import { routes } from '../../../../routes';

type RouteParams = {
  absenceId: string;
};

const MyAbsenceDetail: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems.absences');
  const { absenceId } = useParams<RouteParams>();
  const history = useHistory();

  const { data, loading, error } = useRequestedAbsenceQuery({
    variables: { id: absenceId },
  });

  const dateTimeProps = mapAbsenceToDateTime(data?.absence);

  useFlairBreadcrumbHook(
    [
      { label: t('title') },
      {
        label: t('submenuItems.myAbsences'),
        url: routes.myAbsences.route.create({}),
      },
      { label: `${dateTimeProps.startDate} - ${dateTimeProps.endDate}` },
    ],
    loading,
  );

  if (loading) return <Loading />;
  if (error) return <ServerError />;
  if (!data) return <></>;

  const absence = data.absence;

  const title = (
    <AbsenceDateTimeRange
      dateFormat="short"
      dateTimeFormat="short"
      absence={dateTimeProps}
    />
  );

  const status = <AbsenceStatus status={absence?.flair__Approval_Status__c} />;

  const actions = (
    <CommentsPopover
      mode="button"
      commentsCount={absence.commentsCount}
      recordId={absence.Id}
      relatedObjectName={RelatedObjectNames.Absence}></CommentsPopover>
  );

  return (
    <>
      <PageHeader
        title={title}
        alignBackButton
        goBackButtonAction={history.goBack}
        leftComponent={status}
        actions={actions}
      />
      <Detail absence={absence} />
    </>
  );
};

export default MyAbsenceDetail;
