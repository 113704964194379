import { DailyFilter } from './ShiftsDaily/dailyShiftsLogic';
import { ViewByType } from './shiftsLogic';
import moment from 'moment';
import { WeeklyFilter } from './ShiftsWeekly/weeklyShiftsLogic';

export const VIEW_BY_PARAM = 'viewBy';
export const DAY_PARAM = 'day';
export const WEEK_START_PARAM = 'weekStart';
export const EMPLOYEES_PARAM = 'employees';

export const weeklyFilterFromQuery = (query: URLSearchParams): WeeklyFilter => {
  const weekStartStr = query.get(WEEK_START_PARAM);
  const employeeStr = query.get(EMPLOYEES_PARAM);
  return {
    viewBy: 'week',
    weekStart: (weekStartStr ? moment(weekStartStr) : moment()).startOf(
      'isoWeek',
    ),
    additionalEmployeeIds: employeeStr ? employeeStr.split(',') : [],
  };
};

export const weeklyFilterToQueryParams = (
  weeklyFilter: WeeklyFilter,
): Record<string, string> => {
  const res: Record<string, string> = {
    ...viewByToQueryParams(weeklyFilter.viewBy),
    [WEEK_START_PARAM]: weeklyFilter.weekStart.format('YYYY-MM-DD'),
    [EMPLOYEES_PARAM]: weeklyFilter.additionalEmployeeIds.join(','),
  };
  return res;
};

export const dayFilterFromQuery = (query: URLSearchParams): DailyFilter => {
  const dayStr = query.get(DAY_PARAM);
  const employeeStr = query.get(EMPLOYEES_PARAM);
  return {
    viewBy: 'day',
    day: (dayStr ? moment(dayStr) : moment()).startOf('day'),
    employee: employeeStr,
  };
};

export const dayFilterToQueryParams = (
  dayFilter: DailyFilter,
): Record<string, string> => {
  let res: Record<string, string> = {
    ...viewByToQueryParams('day'),
    [DAY_PARAM]: dayFilter.day.format('YYYY-MM-DD'),
  };
  if (dayFilter.employee) {
    res = { ...res, [EMPLOYEES_PARAM]: dayFilter.employee };
  }
  return res;
};

export const getViewByFromQuery = (query: URLSearchParams): ViewByType => {
  const viewByStr = query.get(VIEW_BY_PARAM);
  return viewByStr === 'day' ? 'day' : 'week';
};

export const viewByToQueryParams = (viewBy: ViewByType) => ({
  [VIEW_BY_PARAM]: viewBy,
});
