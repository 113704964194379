import { PureQueryOptions } from '@apollo/client';
import React, { useReducer } from 'react';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { AutoBreaksRules } from '../../../../components/AutoBreaks/shared';

import TimeEntryForm from '../components/TimeEntryForm';
import {
  EmployeeType,
  isDirtyTimeEntryItem,
  TimeEntryItem,
  TimeEntryReadOnlyReason,
} from '../logic';
import { mapToSubmitTimeEntryInput } from '../logic/mapping';
import { validateTimeEntry } from '../logic/validation';
import { getInitialState, timeEntryStateReducer } from './state';
import TimeEntryDumb from './TimeEntryDumb';
import { useTimeEntryManagersMutations } from './useTimeEntryManagersMutations';
import { TimeEntryManagerButtons } from './TimeEntryManagerButtons';
import {
  Props as RenderLayoutProps,
  TimeEntryCardLayout,
} from '../components/TimeEntryCardLayout';
import { CostCenterProps } from '..';
import { TimeTrackingErrorFragment } from '../../../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from '../../../../hooks/timeTracking/useTimeTrackingErrorsAndWarnings';

export type Props = {
  costCenters: readonly CostCenterProps[];
  autoBreakRules: AutoBreaksRules | null;
  value: TimeEntryItem;
  employeeId: string;
  employeeType: EmployeeType;
  readonlyReasons: TimeEntryReadOnlyReason[];
  onCancelCreatingNewTimeEntry: () => void;
  // Why we have that method (problem): We have 2 parallel hierarchy of MyTimeSheet/TimeSheet MyTimeEntry/TimeEntry so it is hard to update cache for 2 branches of entries
  // The solution: Merge MyTimeSheet and TimeSheet and so on.. https://linear.app/flair/issue/FLA-2661/merge-timetracking-graphql-schema-mytimeentry-timeentry-and-so-on
  refetchQueriesForEmployeeMode?: PureQueryOptions[];
  // Why we have that method (problem): We use react-table expanding feature for expanding day rows.
  // useTimeSheetDetails makes direct call to apolloClient.query. It doesn't support reactive reloads.
  // The solution: Start use useQuery from apollo to allow auto update changed values.
  // But for this we need to implement custom rendering of react-table rows and custom expanding feature
  onChanged?: () => void;
  renderLayout?: (renderProps: RenderLayoutProps) => React.ReactNode;
};

const TimeEntryManagerSmart: React.FC<Props> = ({
  costCenters,
  autoBreakRules,
  value,
  employeeId,
  employeeType,
  refetchQueriesForEmployeeMode,
  readonlyReasons,
  onCancelCreatingNewTimeEntry,
  onChanged,
  renderLayout,
}) => {
  const { mutations, mutationsProgress } = useTimeEntryManagersMutations({
    changeRequestId: value.changeRequestId,
    /* eslint-disable-next-line no-restricted-syntax */
    refetchQueries: refetchQueriesForEmployeeMode,
  });

  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const readonly = readonlyReasons.length > 0;

  const { isSaving, isRejecting, isApproving, isDeleting } = mutationsProgress;

  const isInProgress = isSaving || isRejecting || isApproving || isDeleting;

  const [state, dispatch] = useReducer(
    timeEntryStateReducer,
    getInitialState(value),
  );
  const { current, validationErrors, notesVisible, costCenterVisible } = state;

  const fireOnChanged = () => onChanged && onChanged();

  const errorHandler = useMutationErrorHandler();

  const isDirty = isDirtyTimeEntryItem(value, current);

  const isApproveMode = current.changeRequestId !== null;
  const readonlyIntervalToCompare = isApproveMode
    ? current.timeEntryOldInterval!
    : null;

  const isEditNotesAllowed = employeeType !== 'manager';

  const handleCancel = () => {
    if (value.isNew) {
      onCancelCreatingNewTimeEntry();
    } else {
      dispatch({ type: 'reset', initialTimeEntry: value });
    }
  };

  const handleSubmit = () => {
    if (isApproveMode) {
      handleApprove();
    } else {
      handleSaveChanges();
    }
  };

  const handleSaveChanges = () => {
    const { current } = state;
    if (isInProgress) {
      return;
    }
    const errors = validateTimeEntry(current);

    if (errors.size !== 0) {
      return;
    }
    const input = mapToSubmitTimeEntryInput(current, employeeId);
    mutations
      .saveChanges(input)
      .then((result) => {
        const errors: ReadonlyArray<TimeTrackingErrorFragment> =
          result.data?.timeTracking.submitTimeEntry.errors2 || [];
        showErrorsAndWarningsToasts({ errors });
        onCancelCreatingNewTimeEntry();
        fireOnChanged();
      })
      .catch(errorHandler);
  };

  const handleApprove = () => {
    if (isInProgress || !current.changeRequestId) {
      return;
    }
    fireOnChanged();
    mutations.approve(current.changeRequestId, () => fireOnChanged());
  };

  const handleReject = () => {
    if (isInProgress || !current.changeRequestId) {
      return;
    }
    mutations
      .rejectChangeRequest(current.changeRequestId)
      .then(fireOnChanged)
      .catch(errorHandler);
  };

  const handleTimeEntryDelete = () => {
    if (isInProgress || !current.timeEntryId) {
      return;
    }
    mutations
      .deleteTimeEntry(current.timeEntryId)
      .catch(errorHandler)
      .then(fireOnChanged);
  };

  const newTimeEntry = current.timeEntryId === null;

  const getButtonsMode = () => {
    if (!isApproveMode && !readonly && isDirty) {
      return 'submit';
    }
    if (isApproveMode) {
      return 'approve';
    }
    return null;
  };

  const buttonsMode = getButtonsMode();

  const timeEntryButtons =
    buttonsMode !== null ? (
      <TimeEntryManagerButtons
        mode={buttonsMode}
        onCancel={handleCancel}
        onReject={handleReject}
        isInProgress={isInProgress}
        isApproving={isApproving}
        isSaving={isSaving}
        isRejecting={isRejecting}
        submitDisabled={isInProgress || validationErrors.size > 0}
      />
    ) : null;

  const timeEntryContent = (
    <TimeEntryDumb
      autoBreakRules={autoBreakRules}
      timeEntry={current}
      costCenters={costCenters}
      validationErrors={validationErrors}
      readonly={readonly || isApproveMode}
      readonlyReasons={readonlyReasons}
      readonlyIntervalToCompare={readonlyIntervalToCompare || undefined}
      notesVisible={notesVisible}
      costCenterVisible={costCenterVisible}
      addNotesButtonVisible={!notesVisible}
      addAssignCostCenterButtonVisible={
        costCenters.length > 0 && !costCenterVisible
      }
      isDeleteBreakAllowed={!isApproveMode}
      isEditNotesAllowed={isEditNotesAllowed}
      isDeleteTimeEntryAllowed={!readonly && !isApproveMode && !newTimeEntry}
      isTimeEntryDeleting={isDeleting}
      forceShowEndDate={true}
      onTimeEntryDelete={handleTimeEntryDelete}
      dispatch={dispatch}
    />
  );

  const renderLayoutProps = {
    isProcessing: isInProgress,
    highlight: isDirty,
    buttons: timeEntryButtons,
    children: timeEntryContent,
  };

  return (
    <TimeEntryForm onSubmit={handleSubmit} onCancel={handleCancel}>
      {renderLayout ? (
        renderLayout(renderLayoutProps)
      ) : (
        <TimeEntryCardLayout {...renderLayoutProps} />
      )}
    </TimeEntryForm>
  );
};

export default TimeEntryManagerSmart;
