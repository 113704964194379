import { useUnsnoozeNotificationMutation } from '../../../../../__generated__/graphql';
import { useUserInfo } from '../../../../../context/UserInfo';

export const useUnsnoozeNotification = () => {
  const { id: meId } = useUserInfo();

  return useUnsnoozeNotificationMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const recordId =
        response.data.notifications.unsnoozeNotification.recordId;
      if (!recordId) {
        return;
      }
      const meCacheId: string | undefined = cache.identify({
        __typename: 'Me',
        Id: meId,
      });
      // uncache inbox notifications
      cache.evict({
        id: meCacheId,
        fieldName: 'inboxNotifications',
      });
      // uncache task notifications
      cache.evict({
        id: meCacheId,
        fieldName: 'taskNotifications',
      });
      cache.gc();

      // remove from snoozed notifications
      cache.modify({
        id: meCacheId,
        fields: {
          snoozedNotifications: (existing, { readField }) => {
            if (!existing?.items) return existing;

            const newItems = existing.items.filter(
              (notificationRef: any) =>
                readField('Id', notificationRef) !== recordId,
            );
            return {
              ...existing,
              items: newItems,
              paginationInfo: {
                ...existing.paginationInfo,
                totalCount: existing.paginationInfo.totalCount - 1,
              },
            };
          },
        },
      });
    },
    optimisticResponse: ({ id }) => {
      return {
        notifications: {
          unsnoozeNotification: {
            recordId: id,
            error: null,
            __typename: 'NotificationPayload',
          },
        },
      };
    },
  });
};
