import styled from '@emotion/styled';
import classNames from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';
import { Form, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Controller, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/button';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import { ControlledInput } from '../../../../../../components/form/Input';
import { RichtextInput } from '../../../../../../components/form/RichtextInput';
import SubmitButton from '../../../../../../components/form/SubmitButton';
import { Theme } from '../../../../../../theme';
import { GoalKeyResult } from '../types';
import {
  UpdateKeyResultFormData,
  useUpdateKeyResultForm,
} from './useUpdateKeyResultForm';

export type UpdateKeyResultData = {
  keyResultId: string;
  currentValue: number;
  comments: string | null;
};

type Props = {
  keyResult: GoalKeyResult;
  show?: boolean;
  onClose?: () => void;
  onGoalKeyResultUpdate: (data: UpdateKeyResultData) => Promise<void>;
};

const i18prefix = 'performanceReview.goals2.keyResults';

export const UpdateKeyResultsModal: React.FC<Props> = ({
  keyResult,
  show = true,
  onClose,
  onGoalKeyResultUpdate,
}) => {
  const { t } = useTranslation();
  const { form, commentsField, currentValueField } = useUpdateKeyResultForm({
    comments: '',
    currentValue: keyResult.currentValue,
  });

  const { watch } = form;
  const { currentValue } = watch();

  const handleSubmit = useCallback(
    (formData: UpdateKeyResultFormData) => {
      return onGoalKeyResultUpdate({
        ...formData,
        keyResultId: keyResult.id,
      });
    },
    [onGoalKeyResultUpdate, keyResult],
  );

  const renderValueInput = () => {
    switch (keyResult.type) {
      case 'NUMERIC':
      case 'PERCENTAGE':
        return (
          <HorizontalFlexContainer>
            <NumericInput name={currentValueField} value={currentValue} />
            <span className="fw-bold">/</span>
            <span className="fw-bold">
              {keyResult.targetValue}
              {keyResult.type === 'PERCENTAGE' ? '%' : null}
            </span>
          </HorizontalFlexContainer>
        );
      case 'BOOLEAN':
        return (
          <HorizontalFlexContainer>
            <Controller
              control={form.control}
              name={currentValueField}
              as={<ToggleButtonGroup type="radio" name={currentValueField} />}>
              <CustomizedToggleButton value={0} id={`${currentValueField}-no`}>
                {t(`${i18prefix}.no`)}
              </CustomizedToggleButton>
              <CustomizedToggleButton
                value={100}
                id={`${currentValueField}-yes`}>
                {t(`${i18prefix}.yes`)}
              </CustomizedToggleButton>
            </Controller>
          </HorizontalFlexContainer>
        );
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">
          {t(`${i18prefix}.updateTitle`, { item: keyResult.name })}
        </h4>
      </Modal.Header>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Body>
            <FormGroup>
              <Form.Label>{t(`${i18prefix}.value`)}</Form.Label>
              {renderValueInput()}
            </FormGroup>
            <div>
              <Form.Label>{t(`${i18prefix}.comment`)}</Form.Label>
              <Optional className="ms-2">{t(`${i18prefix}.optional`)}</Optional>
            </div>
            <RichtextInput name={commentsField} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={onClose}
              label={t(`form.general.cancelButton`)}
            />
            <SubmitButton>{t('form.general.saveButton')}</SubmitButton>
          </Modal.Footer>
        </form>
      </FormContext>
    </Modal>
  );
};

const NumericInput = ({ name, value }: { name: string; value: number }) => {
  const [content, setContent] = React.useState(`${value}`);
  const [width, setWidth] = React.useState<number>(0);
  const span = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    setWidth(span.current?.offsetWidth ?? 0);
  }, [content]);

  return (
    <div>
      <span
        ref={span}
        className="invisible form-control position-absolute"
        style={{ width: 'auto' }}>
        {content}
      </span>
      <ControlledInput
        name={name}
        type="number"
        onChange={(e) => setContent(e)}
        style={{
          width: width + 20,
        }}
      />
    </div>
  );
};

const Optional = styled('span')({
  color: Theme.color.gray3,
});

const HorizontalFlexContainer = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={classNames('d-flex', 'align-items-center', 'gap-2', className)}
  />
);

const CustomizedToggleButton = styled(ToggleButton)((btn) => ({
  border: btn.checked ? `1px solid ${Theme.color.black} !important` : 'none',
  backgroundColor: 'unset !important',
  color: `${Theme.color.black} !important`,
  borderRadius: `${Theme.border.radius} !important`,
  fontWeight: 'bold',
  marginRight: '10px',
  boxShadow: 'none !important',
  ':focus': {
    boxShadow: 'none !important',
  },
}));
