import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import i18next from 'i18next';
import { GoalKeyResultType } from '../../types';
import useYupValidationResolver from '../../../../../../../components/form/useYupValidationResolver';

export type CreateTimeEntryResultData = {
  title: string;
  metricValue: GoalKeyResultType;
  startValue: number;
  targetValue: number | null;
};

const i18path = 'performanceReview.goals2.keyResults';

const validationSchema = yup.object().shape<CreateTimeEntryResultData>({
  title: yup.string().required(),
  targetValue: yup
    .number()
    .typeError(i18next.t(`${i18path}.mustBeNumber`))
    .nullable()
    .when(
      'metricValue',
      (
        metricValue: CreateTimeEntryResultData['metricValue'],
        schema: yup.NumberSchema,
      ) => {
        if (metricValue !== 'BOOLEAN') {
          return schema.required();
        }
        return schema;
      },
    ),
  metricValue: yup
    .mixed<GoalKeyResultType>()
    .required()
    .oneOf(['NUMERIC', 'BOOLEAN', 'PERCENTAGE']),
  startValue: yup
    .number()
    .typeError(i18next.t(`${i18path}.mustBeNumber`))
    .when(
      'metricValue',
      (
        metricValue: CreateTimeEntryResultData['metricValue'],
        schema: yup.NumberSchema,
      ) => {
        if (metricValue !== 'BOOLEAN') {
          return schema.required();
        }
        return schema;
      },
    ),
});

const titleField = 'title';
const startValueField = 'startValue';
const targetValueField = 'targetValue';
const metricValueField = 'metricValue';

const defaultValues: CreateTimeEntryResultData = {
  title: '',
  metricValue: 'NUMERIC',
  startValue: 0,
  targetValue: 100,
};

export const useCreateKeyResultForm = () => {
  const form = useForm<CreateTimeEntryResultData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return useMemo(
    () => ({
      form,
      titleField,
      startValueField,
      targetValueField,
      metricValueField,
    }),
    [form],
  );
};
