import { useWindowWidth } from '@react-hook/window-size';

const screenSizesMapping = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useIsScreenSizeLargerThanOrEqual = (size: ScreenSize) => {
  const screenWidth = useWindowWidth();
  return screenWidth >= screenSizesMapping[size];
};

export const useIsXsScreen = () => {
  return useIsScreenSizeLargerThanOrEqual('xs');
};

export const useIsSmScreen = () => {
  return useIsScreenSizeLargerThanOrEqual('sm');
};

export const useIsMdScreen = () => {
  return useIsScreenSizeLargerThanOrEqual('md');
};

export const useIsLgScreen = () => {
  return useIsScreenSizeLargerThanOrEqual('lg');
};

export const useIsXlScreen = () => {
  return useIsScreenSizeLargerThanOrEqual('xl');
};
