import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  URL_PARAM_SHOW_COMMENT,
  commentPointerToQueryParam,
  getCommentPointerFromQueryParam,
} from '../logic';

export const useCommentIdFromUrl = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const commentPointer = getCommentPointerFromQueryParam(
    queryParams.get(URL_PARAM_SHOW_COMMENT),
  );
  return { location, queryParams, commentPointer };
};

export const useCommentsPopoverVisible = (recordId: string) => {
  const history = useHistory();
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);

  const { location, queryParams, commentPointer } = useCommentIdFromUrl();

  const showCommentByDefault =
    commentPointer != null && commentPointer.recordId === recordId;

  // It doesn't work without state... I spent some time to figure out why, but didn't find the solution
  const [visible, setVisible] = useState<boolean>(showCommentByDefault);
  const [scrollCalled, setScrollCalled] = useState<boolean>(false);

  // scroll to the comment when it is shown by default
  useEffect(() => {
    if (showCommentByDefault && containerRef.current && !scrollCalled) {
      setScrollCalled(true);
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showCommentByDefault, containerRef, scrollCalled, setScrollCalled]);

  const updateQueryParam = (visibleParam: boolean) => {
    if (!visibleParam && commentPointer?.recordId === recordId) {
      queryParams.delete(URL_PARAM_SHOW_COMMENT);
    } else {
      queryParams.set(
        URL_PARAM_SHOW_COMMENT,
        commentPointerToQueryParam({
          recordId,
        }),
      );
    }
    const newPath = `${location.pathname}?${queryParams.toString()}`;
    history.push(newPath);
  };

  const handleVisibleChanged = (isVisible: boolean) => {
    updateQueryParam(isVisible);
    setVisible(isVisible);
  };

  return {
    visible,
    setVisible: handleVisibleChanged,
    containerRef,
  };
};
