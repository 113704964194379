import React from 'react';
import './ProjectsTimeSheetsTable.css';
import { ProjectsTimeSheetsTableEmpty } from './ProjectsTimeSheetsTableEmpty';
import { TableRow, TableRowTimeSheet, ViewBy } from './types';
import { ProjectTimeEntriesFilterInput } from '../../../__generated__/graphql';
import { useProjectTimeSheetsTableColumns } from './useProjectTimeSheetsTableColumns';
import { TimeSheetsTableRowConnected } from './Tables/TimeSheetsTableRowConnected';
import { Table } from 'react-bootstrap';

type Props = {
  tableRows: TableRow[];
  expandedRowIdSet: Set<string>;
  headerCheckboxSelected?: boolean;
  onApprove: (timeSheetRow: TableRowTimeSheet) => void;
  onExpandedChanged: (rowId: string, expanded: boolean) => void;
  onSelectRow: (
    allTableRows: TableRow[],
    tableRow: TableRow,
    selected: boolean,
  ) => void;
  onSelectAll: (allTableRows: TableRow[], selected: boolean) => void;
  viewBy: ViewBy;
  highlightIfHasComments: boolean;
  projectsTimeEntriesQueryFilter: ProjectTimeEntriesFilterInput;
};

export const ProjectsTimeSheetsTable: React.FC<Props> = ({
  tableRows,
  expandedRowIdSet,
  ...columnProperties
}) => {
  const columns = useProjectTimeSheetsTableColumns(columnProperties);

  if (!tableRows.length) {
    return <ProjectsTimeSheetsTableEmpty />;
  }

  return (
    <Table
      className="card-table projects-timesheets-table"
      size="sm"
      hover
      responsive>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.accessor}>
              {column.Header({ allRows: tableRows })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row) => (
          <TimeSheetsTableRowConnected
            key={row.id}
            columns={columns}
            tableRows={tableRows}
            expandedRowIdSet={expandedRowIdSet}
            projectsTimeEntriesQueryFilter={
              columnProperties.projectsTimeEntriesQueryFilter
            }
            viewBy={columnProperties.viewBy}
            highlightIfHasComments={columnProperties.highlightIfHasComments}
            row={row}
          />
        ))}
      </tbody>
    </Table>
  );
};
