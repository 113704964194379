import { useMemo } from 'react';
import { ValidationResult } from './validation';
import { parseISO } from 'date-fns';
import { DateAndTime, TimeEntryItem } from '.';
import {
  AdjustTimeEntryRequest,
  autoBreaksCalculationService,
  AutoBreaksRules,
  TimeEntryStats,
  calculateTimeEntryStats,
} from '../../../../components/AutoBreaks/shared';
import { mergeBreakValidationError } from '../../../../components/AutoBreaks/suggestBreaks/validationHelpers';

type Props = {
  timeEntry: TimeEntryItem;
  autoBreakRules: AutoBreaksRules | null;
  validationErrors: ValidationResult;
};

export const useAutoBreaksLogic = ({
  timeEntry,
  autoBreakRules,
  validationErrors,
}: Props) => {
  const adjustTimeEntryRequest = mapToAdjustTimeEntryRequest(timeEntry);

  const validationPlusAutoBreaksErrors = useMemo(() => {
    if (!autoBreakRules) {
      // not autoBreak rules for that timeEntry
      return validationErrors;
    }
    if (!timeEntry.end) {
      // time entry still in progress
      return validationErrors;
    }

    const valError = autoBreaksCalculationService.getStats(
      autoBreakRules,
      adjustTimeEntryRequest,
    );
    return mergeBreakValidationError(validationErrors, valError);
  }, [autoBreakRules, validationErrors, timeEntry, adjustTimeEntryRequest]);

  const timeEntryStats: TimeEntryStats = calculateTimeEntryStats(
    autoBreakRules,
    adjustTimeEntryRequest,
  );

  return {
    validationPlusAutoBreaksErrors,
    timeEntryStats,
  };
};

export const mapToAdjustTimeEntryRequest = (
  src: TimeEntryItem,
): AdjustTimeEntryRequest => ({
  start: fromDateTime(src.start),
  end: src.end ? fromDateTime(src.end) : new Date(),
  breaks: src.breaks.map((x) => ({
    start: fromDateTime(x.start),
    end: x.end ? fromDateTime(x.end) : new Date(),
    breakId: x.uniqueId,
  })),
});

const fromDateTime = (dateAndTime: DateAndTime): Date => {
  return parseISO(`${dateAndTime.date}T${dateAndTime.time}`);
};
