import styled from '@emotion/styled';
import React from 'react';
import { Row } from 'react-bootstrap';
import { Theme } from '../../theme';
import { Nullable } from '../../utils/nullable';
import { RangeDashedHint } from '../RangeDashedHint';

type Props = {
  label?: string;
  numbers: number[];
  onChange?: (num: Nullable<number>) => void;
  startLabel?: string;
  endLabel?: string;
};

const NumberItem = styled.div({
  width: '2.25rem',
  height: '2.25rem',
  color: Theme.color.blue3,
  borderColor: `${Theme.color.blue3}!important`,
  ':hover': {
    color: Theme.color.white,
    background: Theme.color.blue3,
  },
});

export const NumberSelectInput: React.FC<Props> = ({
  label,
  numbers,
  onChange,
  startLabel = '',
  endLabel = '',
}) => {
  const handleOptionPressed = (num: number) => {
    !!onChange && onChange(num);
  };

  return (
    <Row>
      <div className="mb-3 fw-bold text-center">{label}</div>
      <div className="d-flex justify-content-between mb-3">
        {numbers.map((score) => (
          <NumberItem
            key={score}
            role="button"
            className={`d-flex flex-row justify-content-center align-items-center border rounded mx-1`}
            onClick={() => handleOptionPressed(score)}>
            {score}
          </NumberItem>
        ))}
      </div>
      <RangeDashedHint startLabel={startLabel} endLabel={endLabel} />
    </Row>
  );
};
