import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PurchaseDateFormField } from './FormFields/PurchaseDateFormField';
import { PurchasePriceFormField } from './FormFields/PurchasePriceFormField';
import { InventoryModalContext } from '../InventoryModalContext';

export const PurchaseFields: React.FC = () => {
  const { isExpanded = false } = useContext(InventoryModalContext);

  return (
    <Row>
      <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
        <PurchaseDateFormField />
      </Col>
      <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
        <PurchasePriceFormField />
      </Col>
    </Row>
  );
};
