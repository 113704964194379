import React from 'react';
import { routes as r } from '../../../routes';
import ShiftsWeekly from '../../../components/Shifts/ShiftsWeekly';
import AllShifts from '../../AllShifts';

export const routes = () => ({
  personalShifts: {
    route: r.personalShifts.route,
    render: () => <ShiftsWeekly />,
  },
  allShifts: {
    route: r.allShifts.route,
    render: () => <AllShifts />,
  },
});
