import React from 'react';
import {
  Button as BootstrapButton,
  ButtonProps as BootstrapButtonProps,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import FlairIcon, {
  FlairIconName,
  SizeProp,
} from '../../atomic/atoms/FlairIcon';
import { Placement } from 'react-bootstrap/types';

export type ButtonProps = BootstrapButtonProps & {
  label?: string;
  isProcessing?: boolean;
  icon?: FlairIconName;
  faIcon?: FlairIconName;
  className?: string;
  tooltip?: string;
  tooltipPlacement?: Placement;
  iconSize?: SizeProp;
};

const Icon: React.FC<{
  isProcessing?: boolean;
  faIcon?: FlairIconName;
  icon?: FlairIconName;
  iconSize?: SizeProp;
}> = ({ isProcessing, icon, faIcon, iconSize }) =>
  isProcessing ? (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"></span>
  ) : icon ? (
    <FlairIcon icon={icon} className="align-middle" size={iconSize} />
  ) : faIcon ? (
    <FlairIcon icon={faIcon} size={iconSize} />
  ) : (
    <></>
  );

const Label: React.FC<{
  label?: string;
  hasIcon: boolean;
  isProcessing?: boolean;
}> = ({ label, hasIcon, isProcessing }) =>
  label ? (
    <span className={hasIcon || isProcessing ? 'ms-2' : ''}>{label}</span>
  ) : (
    <></>
  );

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      isProcessing,
      icon,
      faIcon,
      disabled,
      tooltip,
      iconSize,
      tooltipPlacement,
      ...rest
    },
    ref,
  ) => {
    const button = (
      <BootstrapButton ref={ref} disabled={isProcessing || disabled} {...rest}>
        <Icon
          isProcessing={isProcessing}
          icon={icon}
          faIcon={faIcon}
          iconSize={iconSize}
        />
        <Label
          label={label}
          hasIcon={!!(icon || faIcon)}
          isProcessing={isProcessing}
        />
      </BootstrapButton>
    );

    return tooltip ? (
      <OverlayTrigger
        overlay={<Tooltip id={`button-tooltip-${tooltip}`}>{tooltip}</Tooltip>}
        placement={tooltipPlacement}>
        <span>{button}</span>
      </OverlayTrigger>
    ) : (
      button
    );
  },
);

export default Button;
