import React, { useCallback, useMemo } from 'react';
import {
  SelectComponentsConfig,
  components as selectComponents,
  GroupBase,
  SingleValue,
} from 'react-select';
import { FilterSelectOption } from '../../atomic/molecules/FlairDropdown/types';
import {
  SelectSingleStyled,
  SelectSingleStyledProps,
} from '../Select/SelectSingleStyled';

export type Props = Omit<
  SelectSingleStyledProps<FilterSelectOption>,
  'options' | 'selectedOption' | 'onChange' | 'value'
> & {
  options: FilterSelectOption[];
  value: string | null;
  //todo: remove boolean?!
  onChange: (value: string | null) => void;
  isSearchable?: boolean;
  components?: SelectComponentsConfig<
    FilterSelectOption,
    false,
    GroupBase<FilterSelectOption>
  >;
};

const defaultComponents: SelectComponentsConfig<
  FilterSelectOption,
  false,
  GroupBase<FilterSelectOption>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <Option data={data} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <Option data={data} />
    </selectComponents.Option>
  ),
};

const Option: React.FC<{ data: FilterSelectOption }> = ({
  data: { label },
}) => {
  return <span>{label}</span>;
};

const SingleFilter: React.FC<Props> = ({
  options,
  value,
  onChange,
  components = {},
  styles = {},
  isClearable = true,
  isSearchable = false,
  ...rest
}) => {
  const filteredOptions = useMemo(
    () => options.filter((o) => o.showCondition ?? true),
    [options],
  );

  const handleOnChange = useCallback(
    (singleValue: SingleValue<FilterSelectOption>) => {
      onChange(getOptionValue(singleValue));
    },
    [onChange],
  );

  return (
    <SelectSingleStyled
      value={filteredOptions.find((o) => o.value === value) ?? null}
      onChange={handleOnChange}
      options={filteredOptions}
      components={{ ...defaultComponents, ...components }}
      menuPlacement={'auto'}
      isClearable={isClearable}
      isSearchable={isSearchable}
      styles={styles}
      {...rest}
    />
  );
};

const getOptionValue = (
  option: SingleValue<FilterSelectOption>,
): string | null => {
  if (!option) {
    return null;
  }
  if ('value' in option) {
    return option.value;
  }
  return null;
};

export default SingleFilter;
