import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export const useLogout = () => {
  const { logout } = useAuth();
  const history = useHistory();

  return useCallback(() => {
    logout();
    history.push('/');
  }, [logout, history]);
};
