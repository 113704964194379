import React from 'react';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { MultiValue } from 'react-select';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import { EvaluationsFilterParams, EvaluationsFiltersKeys } from '../types';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

type Props = Omit<ReactSelectProps<FilterSelectOption, true>, 'options'> & {
  options: FilterSelectOption[];
  filter: EvaluationsFilterParams;
  filterKey: EvaluationsFiltersKeys;
  setFilterData: (filter: EvaluationsFilterParams) => void;
};

const EvaluationsMultipleFilter: React.FC<Props & ReactSelectProps> = ({
  filter,
  filterKey,
  options,
  setFilterData,
  ...rest
}) => {
  const selectedOptionsString = filter[filterKey];

  const handleChange = (selectedOptions: MultiValue<FilterSelectOption>) => {
    setFilterData({
      ...filter,
      [filterKey]: selectedOptions.map((o) => o.value).join(','),
    });
  };

  return (
    <FlairMultiSelectDropdown
      options={options}
      selectedOptions={selectedOptionsString.split(',')}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default EvaluationsMultipleFilter;
