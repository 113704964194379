import React from 'react';
import Select, {
  StylesConfig,
  SelectComponentsConfig,
  components as selectComponents,
  GroupBase,
} from 'react-select';
import { Theme } from '../../../../theme';

type AbsenceCategoryOption = {
  label: string;
  value: string;
  icon: React.ReactNode;
};

type Props = {
  options: AbsenceCategoryOption[];
  value: string | null;
  onSelect: (option: AbsenceCategoryOption | null) => void;
};

const styles: StylesConfig<AbsenceCategoryOption, false> = {
  control: (_, { isFocused }) => ({
    display: 'flex',
    width: '100%',
    height: 'calc(1.5em + 1rem + 2px)',
    border: `${Theme.border.width} solid ${Theme.input.border.color}`,
    borderRadius: Theme.border.radius,
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    borderColor: isFocused ? Theme.input.border.focusColor : undefined,
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.5rem 0.75rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: Theme.input.value.color,
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    border: `${Theme.border.width} solid ${Theme.input.border.focusColor}`,
    borderRadius: Theme.border.radius,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    color: Theme.color.black,
    backgroundColor: isFocused ? Theme.color.gray5 : Theme.color.white,
    ':hover': {
      backgroundColor: Theme.color.gray5,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingRight: Theme.spacer[3],
    color: Theme.input.border.color,
    ':hover': {
      color: Theme.input.border.focusColor,
    },
  }),
};

const Option: React.FC<{ data: AbsenceCategoryOption }> = ({
  data: { icon, label },
}) => {
  return (
    <div>
      {icon}
      <span className="ms-2">{label}</span>
    </div>
  );
};

const components: SelectComponentsConfig<
  AbsenceCategoryOption,
  false,
  GroupBase<AbsenceCategoryOption>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <Option data={data} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <Option data={data} />
    </selectComponents.Option>
  ),
};

const AbsenceCategorySelect: React.FC<Props> = ({
  options,
  value,
  onSelect,
}) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Select
      autoFocus
      styles={styles}
      value={selectedOption}
      options={options}
      isSearchable={false}
      onChange={(optionValue) => {
        if (!optionValue) {
          onSelect(null);
          return;
        }

        if ('value' in optionValue) {
          onSelect(optionValue);
          return;
        }
      }}
      components={components}
    />
  );
};

export default AbsenceCategorySelect;
