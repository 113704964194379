import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';

import { NextPeriodSummary } from '../types';
import { AllowanceListItemAccrued } from './AllowanceListItemAccrued';
import { AbsencesHint } from './AbsencesHint';
import { AllowanceListItem } from './AllowanceListItem';

type NextYearPeriodListItemsProps = {
  year: number;
  isUnlimited: boolean;
  summary: NextPeriodSummary;
};

const i18nKey = 'absences.cards.absenceCategories.listItem.nextYear';

const NextYearPeriodListItems: React.FC<NextYearPeriodListItemsProps> = ({
  summary: { scheduled, willAccrue: willAccur, scheduledAbsences, allowances },
  isUnlimited,
  year,
}) => {
  const namespacedTranslation = useNamespacedTranslation(i18nKey);

  return (
    <>
      <AllowanceListItemAccrued
        isUnlimited={isUnlimited}
        periodType="next"
        amount={willAccur}
        allowances={allowances}
      />
      <AllowanceListItem>
        <small className="d-flex">
          {namespacedTranslation('requested')}
          <AbsencesHint absences={scheduledAbsences} year={year + 1} />
        </small>
        <small>{scheduled}</small>
      </AllowanceListItem>
    </>
  );
};

export default NextYearPeriodListItems;
