import React, { useState } from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../theme';
import { NotificationItem, NotificationItemData } from '../types';
import styled from '@emotion/styled';
import { formatDistanceToNow } from 'date-fns';
import { NotificationsContentType } from '../types';
import NotificationActions from './NotificationActions';
import NotificationIcon from './NotificationIcon';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import StringToHtml from '../../../../../components/StringToHtml';

const Notification = styled.div<{
  showAsUnread: boolean;
  showAsDueSoon: boolean;
}>(({ showAsUnread, showAsDueSoon }) => ({
  borderLeft: showAsDueSoon
    ? `4px ${Theme.color.red2} solid`
    : showAsUnread
    ? `4px ${Theme.color.blue3} solid`
    : '4px transparent solid',
  ':hover': {
    '.notification-actions': {
      display: 'flex',
    },
    '.notification-date': {
      // Using visibility instead of display to avoid layout shift
      visibility: 'hidden',
      height: 0,
    },
  },
  minHeight: '3.313rem',
}));

const NotificationCommentSpacer = styled.div({
  borderColor: `${Theme.color.gray6}!important`,
});

type NotificationTextProps = Pick<
  NotificationItemData,
  'employeeName' | 'description'
>;
const NotificationText: React.FC<NotificationTextProps> = ({
  employeeName,
  description,
}) => {
  return (
    <>
      {employeeName && (
        <>
          <strong>{employeeName}</strong>
          &nbsp;
        </>
      )}
      <span>{description}&nbsp;</span>
    </>
  );
};

type NotificationLinkProps = Pick<NotificationItemData, 'url' | 'urlLabel'>;
const NotificationLink: React.FC<NotificationLinkProps> = ({
  url,
  urlLabel,
}) => {
  return (
    <>
      <a href={url}>{urlLabel}</a>
    </>
  );
};

const NotificationComment: React.FC<Pick<NotificationItemData, 'comment'>> = ({
  comment,
}) => {
  return (
    <div className="d-flex flex-row gap-1">
      <NotificationCommentSpacer className="w-1 border border-top-0 border-bottom-0 rounded" />
      {comment && (
        <div className="small" style={{ maxWidth: '28rem' }}>
          <StringToHtml text={comment} />
        </div>
      )}
    </div>
  );
};

const NotificationDate: React.FC<{
  createdDate: Date;
}> = ({ createdDate }) => {
  const formatedDateTime = new Intl.DateTimeFormat('default', {
    dateStyle: 'full',
    timeStyle: 'long',
  }).format(createdDate);
  return (
    <div
      style={{ color: Theme.color.paper1, fontSize: 'small' }}
      title={formatedDateTime}>
      {formatDistanceToNow(createdDate, { addSuffix: true })}
    </div>
  );
};

const TaskNotificationDueDate: React.FC<{
  dueDate: Date;
  dueSoon?: boolean;
  dueFromNow?: string;
}> = ({ dueDate, dueSoon, dueFromNow }) => {
  const t = useNamespacedTranslation('notifications');
  const formatedDueDate = new Intl.DateTimeFormat('default', {
    dateStyle: 'short',
  }).format(dueDate);
  const formatedDueDatetime = new Intl.DateTimeFormat('default', {
    dateStyle: 'long',
    timeStyle: 'long',
  }).format(dueDate);

  const DueSoonBadge = (
    <span className="badge bg-danger-soft">
      {t('due')} {dueFromNow}
    </span>
  );

  const DueDate = (
    <>
      <FlairIcon
        icon="calendar-clear-outline"
        style={{ marginTop: '0.12rem' }}
        height={14}
        size="1x"
        color={Theme.color.red1}
      />
      <div
        style={{
          color: Theme.color.red1,
          fontSize: 'small',
          paddingLeft: '0.25rem',
        }}
        title={formatedDueDatetime}>
        {formatedDueDate}
      </div>
    </>
  );
  return (
    <>
      {dueSoon ? DueSoonBadge : DueDate}
      <span
        style={{
          color: Theme.color.paper1,
          fontSize: 'small',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        }}>
        &bull;
      </span>
    </>
  );
};

const NotificationContentItem: React.FC<{
  type: NotificationsContentType;
  notification: NotificationItem;
}> = ({ type, notification }) => {
  // State lifted up to keep actions visible when snooze is open
  const [keepActionsVisible, setKeepActionsVisible] = useState(false);

  return (
    <Notification
      showAsUnread={
        (type === 'inbox' || type === 'task') && !notification.isRead
      }
      showAsDueSoon={type === 'task' && !!notification.data.dueSoon}
      className="d-flex flex-row align-items-center p-2">
      <NotificationIcon notification={notification} />
      <div className="d-flex flex-column flex-grow-1 ">
        {notification.data.title && <strong>{notification.data.title}</strong>}
        <div className="d-flex flex-row flex-wrap">
          <NotificationText
            employeeName={notification.data.employeeName}
            description={notification.data.description}
          />
          <NotificationLink
            url={notification.data.url}
            urlLabel={notification.data.urlLabel}
          />
        </div>
        {notification.data.comment && (
          <div className="d-flex flex-row mb-1">
            <NotificationComment comment={notification.data.comment} />
          </div>
        )}
      </div>
      <div className="d-flex flex-column justify-content-end flex-shrink-0">
        {!keepActionsVisible && (
          <div className="d-flex flex-row notification-date flex-shrink-0">
            {notification.data.dueDate && (
              <TaskNotificationDueDate
                dueDate={notification.data.dueDate}
                dueSoon={notification.data.dueSoon}
                dueFromNow={notification.data.dueFromNow}
              />
            )}
            <NotificationDate createdDate={notification.createdDate} />
          </div>
        )}
        <NotificationActions
          type={type}
          notification={notification}
          keepActionsVisible={keepActionsVisible}
          setKeepActionsVisible={setKeepActionsVisible}
        />
      </div>
    </Notification>
  );
};

export default NotificationContentItem;
