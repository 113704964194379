import { Moment } from 'moment';

import {
  AbsenceProps,
  CalendarPeriodProps,
  HolidayProps,
  TimeSheetDayProps,
} from '../../components/TimeSheet/DayCard/types';
import {
  CreateLoomVideoInput,
  ProjectsQuery,
  WorkloadType,
} from '../../__generated__/graphql';
import { Maybe } from '../../../../utils/maybe';

export type Project = {
  id: string;
  name: string;
  billable: boolean;
  description: string;
  estimation: number | null;
  startDate: Date | null;
  endDate: Date | null;
  timeTrackedInMinutes: number;
  overtime: number | null;
  activities: ProjectActivity[];
};

export type ProjectWithStatus = Project & {
  status: ProjectStatus;
};

export type ProjectTimeTrackingMap = {
  [key: string]: ProjectDay[];
};

export type ProjectDay = {
  date: Date;
  dateId: string;
  status: ProjectStatus;
  timesTracked: ProjectDayTimeTrackedToSave[];
};

export type SFProject = ProjectsQuery['projects'][0];
export type SFProjectHistoryItem =
  ProjectsQuery['projects'][0]['historyItems'][0];
export type SFColleague =
  ProjectsQuery['projects'][0]['historyItems'][0]['changedBy'];
export type SFTimesheet = ProjectsQuery['myTimeSheets'][0];
export type SFProjectTimeTracked = SFTimesheet['projectTimeEntries'][0];

export type Timesheet = {
  id: string;
  startDate: Date;
  endDate: Date;
  approvalStatus: string;
  timesheetType: WorkloadType;
  projectTimeTracked: ProjectDayTimeTracked[];
  holidays: ReadonlyArray<HolidayProps>;
  calendarPeriods: ReadonlyArray<CalendarPeriodProps>;
  paidAbsences: ReadonlyArray<AbsenceProps>;
  timeSheetDays: ReadonlyArray<TimeSheetDayProps>;
};

export type WeekDay = {
  date: Date;
  dateId: string;
};

export type ProjectDayTimeTracked = {
  id: string;
  minutes?: number;
  projectId: string;
  costCenterId: string | null;
  timesheetId: string;
  date: Date;
  dateId: string;
  notes: string | null;
};

export type ProjectTimeEntry = {
  id: string;
  projectId: string | null;
  costCenterId: string | null;
  minutes?: number;
  notes: string | null;
};

export type ProjectDayTimeTrackedToSave = Omit<
  ProjectDayTimeTracked,
  'id' | 'timesheetId' | 'dateId'
> & {
  id: string | null;
};

export type ActivityEmployee = {
  id: string;
  name: string;
  initials: string;
};

export type ProjectActivity =
  | ProjectActivityCreate
  | ProjectActivitySetValue
  | ProjectMarkAsBillable
  | ProjectMarkAsNonBillable;

type ProjectActivityCreate = ProjectActivityBase & {
  type: 'CREATE';
};

type ProjectActivitySetValue = ProjectActivityBase & {
  type:
    | 'SET_DESCRIPTION'
    | 'SET_NAME'
    | 'SET_ESTIMATION'
    | 'SET_START_DATE'
    | 'SET_END_DATE';
  value: string;
};

type ProjectMarkAsBillable = ProjectActivityBase & {
  type: 'MARK_AS_BILLABLE';
};

type ProjectMarkAsNonBillable = ProjectActivityBase & {
  type: 'MARK_AS_NON_BILLABLE';
};

type ProjectActivityBase = {
  id: string;
  employee?: ActivityEmployee;
  updatedAt: Date;
  comment?: string;
};

export type ActivityType =
  | 'CREATE'
  | 'SET_DESCRIPTION'
  | 'SET_NAME'
  | 'SET_ESTIMATION'
  | 'SET_START_DATE'
  | 'SET_END_DATE'
  | 'MARK_AS_BILLABLE'
  | 'MARK_AS_NON_BILLABLE';

export type ProjectViewMode = 'USER_VIEW' | 'MANAGER_VIEW';
export type PageType = 'Projects' | 'Timesheet';

export type Day = {
  day: Moment;
  holidays: HolidayProps[];
  absences: AbsenceProps[];
  calendarPeriods: CalendarPeriodProps[];
  timesTracked: ProjectDayTimeTracked[];
  projects: ProjectWithStatus[];
  timeSheetDayId: string;
  commentsCount: number;
  loomVideo: Maybe<CreateLoomVideoInput>;
};

export type ProjectViewType = 'BY_DAYS' | 'BY_PROJECTS';

export const allProjectStatuses = [
  'ACTIVE',
  'NOT_STARTED',
  'FINISHED',
] as const;
export type ProjectStatus = (typeof allProjectStatuses)[number];
export const isProjectStatus = (status: unknown): status is ProjectStatus =>
  allProjectStatuses.some((x) => x === status);

export type ProjectsFilter = {
  billable: string | null;
  status: ProjectStatus | null;
  viewType: ProjectViewType;
};
