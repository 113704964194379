import { useEffect, useState } from 'react';
import { AbsenceRequestFiltersType } from '../types';
import { split } from '../../../../../../utils/string';
import { AbsenceRequest } from '../../types';

export const useAbsenceRequestFilters = (
  filterData: AbsenceRequestFiltersType,
  requests: AbsenceRequest[],
): AbsenceRequest[] => {
  const [filteredAbsenceRequests, setFilteredAbsenceRequests] =
    useState<AbsenceRequest[]>(requests);

  useEffect(() => {
    const filterByType = (request: AbsenceRequest) => {
      const typesIds = split(filterData.type);
      return !!typesIds.length
        ? typesIds.includes(request.categoryName || '')
        : true;
    };

    const filterByEmployee = (request: AbsenceRequest) => {
      const employeesIds = split(filterData.colleaguesIds);
      return !!employeesIds.length
        ? employeesIds.includes(request.requester.id || '')
        : true;
    };

    const filteredRequests = requests
      .filter(filterByType)
      .filter(filterByEmployee);

    setFilteredAbsenceRequests(filteredRequests);
  }, [
    requests,
    setFilteredAbsenceRequests,
    filterData.type,
    filterData.colleaguesIds,
  ]);

  return filteredAbsenceRequests;
};
