import { reduce } from 'lodash';
import { useCallback } from 'react';
import { formatDate, parseDate } from '../../../../../utils/dateUtils';
import { FieldType } from '../../../__generated__/graphql';
import { EmployeeField } from '../types';
import { FieldForUpdate } from './types';

const isDateType = (value: unknown): value is string | number | Date => {
  const valueType = typeof value;
  return (
    valueType === 'string' || valueType === 'number' || value instanceof Date
  );
};

const valueAsString = (type: FieldType, value: unknown): string | null => {
  if (value === null || typeof value === 'undefined') {
    return null;
  }
  switch (type) {
    case FieldType.Time:
      return `${value}Z`;
    case FieldType.Date:
      return isDateType(value)
        ? formatDate(parseDate(value), 'yyyy-MM-dd')
        : String(value);
    case FieldType.Datetime:
      return isDateType(value) ? parseDate(value).toISOString() : String(value);
    case FieldType.Multipicklist:
      return Array.isArray(value) ? value.join(';') : String(value);
    default:
      return String(value);
  }
};

export const useFormDataToUpdateFields = () =>
  useCallback(
    (
      fields: EmployeeField[],
      form: { [key: string]: any },
      dirtyFields: Set<string>,
    ): FieldForUpdate[] => {
      const dataFields = fields.reduce((result, field) => {
        result[field.fieldInfo.name] = field;
        return result;
      }, {} as { [key: string]: EmployeeField });

      return reduce(
        form,
        (result, inputValue, name) => {
          if (!dirtyFields.has(name)) {
            return result;
          }

          const dataField = dataFields[name];

          if (dataField) {
            const value = valueAsString(dataField.fieldInfo.type, inputValue);

            if (value !== dataField.value) {
              result.push({
                name,
                value,
                type: dataField.fieldInfo.type,
              });
            }
          }

          return result;
        },
        [] as FieldForUpdate[],
      );
    },
    [],
  );
