import React, { useCallback, useMemo, useState } from 'react';
import { Col, Container as BootstrapContainer, Row } from 'react-bootstrap';
import PageHeader from '../../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  EvaluationAnswer,
  Maybe,
  CandidateEvaluationQuery,
  useCandidateEvaluationQuery,
  CandidateDetailsDocument,
  CandidateEvaluationDocument,
  useSaveCandidateEvaluationMutation,
  EvaluationTemplateQuestion,
} from '../../../__generated__/graphql';
import Section from './Section';
import EvaluationTemplateContent, {
  getRequiredInvalidFields,
} from '../../../components/Evaluation/EvaluationTemplate';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import Loading from './Loading';
import CandidateInfo from '../CandidateDetails/CandidateInfo';
import Resume from '../CandidateDetails/Resume';
import { debounce } from 'lodash';
import Button from '../../../../../components/button';
import { useUserInfo } from '../../../context/UserInfo';
import FinalScore from '../../../components/Evaluation/FinalScore';
import FinalComment from '../../../components/Evaluation/FinalComment';
import { IRequiredInvalidFields } from '../../../components/Evaluation/helper';
import { Score } from '../../../components/Evaluation/EvaluationTemplate/Question/ScoreCard';
import ServerError from '../../../../../components/ServerError';

type CandidateEvaluation = CandidateEvaluationQuery['candidateEvaluationById'];

type RouteParams = {
  candidateEvaluationId: string;
};

type Params = {
  candidateEvaluation: CandidateEvaluation;
  goBack: () => void;
};

const isInValidForm = (
  fields: IRequiredInvalidFields,
  finalComment: string,
  finalScore?: Score,
) => isInValidFields(fields) || finalScore == null || !finalComment;

const isInValidFields = (fields: IRequiredInvalidFields) =>
  Object.values(fields).some((field) => field);

const Container: React.FC<Pick<Params, 'goBack'>> = ({ goBack, children }) => {
  const { t } = useTranslation();

  return (
    <BootstrapContainer>
      <PageHeader
        title={t('evaluations.candidate.title')}
        goBackButtonAction={goBack}></PageHeader>
      {children}
    </BootstrapContainer>
  );
};

export const EvaluationFeedback: React.FC<Params> = ({
  candidateEvaluation,
  goBack,
}) => {
  const { t } = useTranslation();
  const { isHiringManager } = useUserInfo();
  const { addSuccess } = useToasts();
  const [saveFinalScore, { loading: scoreSaving }] =
    useSaveCandidateEvaluationMutation();
  const [saveFinalComment, { loading: commentSaving }] =
    useSaveCandidateEvaluationMutation();
  const [saveCandidateEvaluation, { loading: saveInProccess }] =
    useSaveCandidateEvaluationMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [
        ...(isHiringManager
          ? [
              {
                query: CandidateDetailsDocument,
                variables: {
                  id: candidateEvaluation.candidate.Id,
                },
              },
            ]
          : []),
        {
          query: CandidateEvaluationDocument,
          variables: {
            id: candidateEvaluation.Id,
          },
        },
      ],
    });
  const errorHandler = useMutationErrorHandler();

  const {
    Id: candidateEvaluationId,
    flair__Final_Score__c,
    flair__Overall_Comment__c,
    flair__Evaluated__c: isEvaluated,
    evaluationTemplate,
    answers,
  } = candidateEvaluation;

  const initialRequiredFields: IRequiredInvalidFields =
    evaluationTemplate && evaluationTemplate.questions
      ? getRequiredInvalidFields(
          evaluationTemplate.questions as EvaluationTemplateQuestion[],
          answers as EvaluationAnswer[],
        )
      : {};

  const [requiredInvalidFields, setRequiredInvalidFields] =
    useState<IRequiredInvalidFields>(initialRequiredFields);

  const [finalScore, setFinalScore] = useState<Score>(flair__Final_Score__c);
  const [finalComment, setFinalComment] = useState<Maybe<string>>(
    flair__Overall_Comment__c,
  );

  const handleSubmit = useCallback(() => {
    saveCandidateEvaluation({
      variables: {
        input: {
          candidateEvaluationId,
          evaluated: true,
          finalComment: null,
          finalScore: null,
        },
      },
    })
      .then(() => {
        addSuccess(t('evaluations.candidate.createAnswerSuccessMessage'));
        goBack();
      })
      .catch(errorHandler);
  }, [
    addSuccess,
    candidateEvaluationId,
    errorHandler,
    saveCandidateEvaluation,
    goBack,
    t,
  ]);

  const saveFinalScoreDebounce = useMemo(
    () =>
      debounce(({ score }: { score: Score }) => {
        saveFinalScore({
          variables: {
            input: {
              candidateEvaluationId,
              finalScore: score != null ? score : null,
              finalComment: null,
              evaluated: false,
            },
          },
        })
          .then(() => {
            setFinalScore(score);
          })
          .catch(errorHandler);
      }, 100),
    [saveFinalScore, candidateEvaluationId, errorHandler],
  );

  const saveFinalCommentDebounce = useMemo(
    () =>
      debounce(({ comment }: { comment: string }) => {
        saveFinalComment({
          variables: {
            input: {
              candidateEvaluationId,
              finalScore: null,
              finalComment: comment,
              evaluated: false,
            },
          },
        })
          .then(() => {
            setFinalComment(comment);
          })
          .catch(errorHandler);
      }, 500),
    [saveFinalComment, candidateEvaluationId, errorHandler],
  );

  const handleFinalScoreUpdate = useCallback(
    (score: Score) => {
      saveFinalScoreDebounce({ score });
    },
    [saveFinalScoreDebounce],
  );

  const handleFinalCommentUpdate = useCallback(
    (comment: string) => {
      saveFinalCommentDebounce({ comment });
    },
    [saveFinalCommentDebounce],
  );

  const handleFieldsUpdate = useCallback(
    (fields: IRequiredInvalidFields) => {
      setRequiredInvalidFields({
        ...requiredInvalidFields,
        ...fields,
      });
    },
    [requiredInvalidFields],
  );

  const isInValid = isInValidForm(
    requiredInvalidFields,
    finalComment || '',
    finalScore,
  );

  return useMemo(
    () => (
      <>
        {evaluationTemplate ? (
          <Section>
            <Row>
              <Col xl={12}>
                <h3>{t('evaluations.yourFeedback')}</h3>
                <EvaluationTemplateContent
                  employeeEvaluationId={null}
                  candidateEvaluationId={candidateEvaluationId}
                  evaluationTemplate={evaluationTemplate}
                  disabled={isEvaluated}
                  answers={answers as EvaluationAnswer[]}
                  onFieldsUpdate={handleFieldsUpdate}
                />
              </Col>
            </Row>
          </Section>
        ) : (
          <></>
        )}

        <Section>
          <FinalScore
            required={true}
            disabled={isEvaluated}
            defaultValue={finalScore}
            onChange={handleFinalScoreUpdate}
            loading={scoreSaving}
          />
          <FinalComment
            required={true}
            disabled={isEvaluated}
            defaultValue={finalComment || ''}
            onChange={handleFinalCommentUpdate}
            loading={commentSaving}
          />
          <div className="float-end d-inline-block">
            <Button
              disabled={isEvaluated || isInValid}
              label={t('evaluations.submitFeedback')}
              isProcessing={saveInProccess}
              onClick={handleSubmit}
              size="sm"
            />
          </div>
        </Section>
      </>
    ),
    [
      answers,
      candidateEvaluationId,
      commentSaving,
      evaluationTemplate,
      finalComment,
      finalScore,
      handleFieldsUpdate,
      handleFinalCommentUpdate,
      handleFinalScoreUpdate,
      handleSubmit,
      isEvaluated,
      isInValid,
      saveInProccess,
      scoreSaving,
      t,
    ],
  );
};

const Evaluation: React.FC = () => {
  const { candidateEvaluationId } = useParams<RouteParams>();
  const history = useHistory();
  const { data, error, loading } = useCandidateEvaluationQuery({
    variables: {
      id: candidateEvaluationId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ServerError />;
  }

  if (!candidateEvaluationId || !data || !data.candidateEvaluationById)
    return <></>;

  return (
    <Container goBack={() => history.goBack()}>
      <Row>
        <Col xl={8}>
          <CandidateInfo candidate={data.candidateEvaluationById.candidate} />
          <EvaluationFeedback
            candidateEvaluation={data.candidateEvaluationById}
            goBack={() => history.goBack()}
          />
        </Col>
        <Col xl={4}>
          <Resume candidate={data.candidateEvaluationById.candidate} />
        </Col>
      </Row>
    </Container>
  );
};

export default Evaluation;
