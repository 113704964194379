import { Theme } from '../../../../theme';
import { OpenShiftState } from './Common/types';

// Open shifts
export const getContainerColors = (state: OpenShiftState) => {
  switch (state) {
    case 'not-available':
      return {
        backgroundColor: Theme.shift.open.unavailable.bg,
        color: Theme.color.black,
        border: `1px solid ${Theme.shift.open.unavailable.bg}`,
      };
    case 'available':
      return {
        backgroundColor: Theme.shift.open.available.hoverBg,
        color: Theme.color.black,
        border: `1px solid ${Theme.shift.open.available.hoverBg}`,
      };
    case 'requested':
    case 'confirmed':
      return {
        backgroundColor: Theme.shift.open.requested.hoverBg,
        color: Theme.color.black,
        border: `1px solid ${Theme.shift.open.requested.hoverBg}`,
      };
  }
};
