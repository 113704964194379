import i18next from 'i18next';
import {
  AdjustTimeEntryRequest,
  autoBreaksCalculationService,
  AutoBreaksRules,
  TimeEntryStatsByLaw,
  getBreakingTime,
} from '../shared';
import { isLegal } from '../shared/autoBreaksCalculation/helpers';
import {
  TimeTrackingBreak,
  TimeTrackingStateWithEndedBreaks,
} from './clockoutBreaksReducer';

const i18nprefix = 'autoBreaks.validation.';

export const addBreakValidationErros = (
  errorsMap: Map<string, string[]>,
  breakRules: AutoBreaksRules,
  request: AdjustTimeEntryRequest,
  initialBreaks: TimeTrackingBreak[],
  timeTrackingState: TimeTrackingStateWithEndedBreaks,
): Map<string, string[]> => {
  if (isBreaksReduced(initialBreaks, timeTrackingState.breaks)) {
    // this is not actually error
    addError(errorsMap, '.', i18next.t(i18nprefix + 'reduceBreaks'));
  }
  const valError = autoBreaksCalculationService.getStats(breakRules, request);
  mergeBreakValidationError(errorsMap, valError);
  return errorsMap;
};

export const getBreakValidationErrorText = (
  valError: TimeEntryStatsByLaw,
): string => {
  if (valError.breakingMinutesCountedByLaw > 0) {
    return i18next.t(i18nprefix + 'legislationRule', {
      required: valError.breakingMinutesRequiredByLaw,
      total: valError.breakingMinutesUnusedByLaw,
    });
  } else {
    return i18next.t(i18nprefix + 'minBreakDurationNoBreaks', {
      minutes: valError.breakingMinutesRequiredByLaw,
    });
  }
};

export const isBreaksReduced = (
  initialBreaks: TimeTrackingBreak[],
  breaks: TimeTrackingBreak[],
): boolean => getBreakingTime(initialBreaks) > getBreakingTime(breaks);

export const mergeBreakValidationError = (
  target: Map<string, string[]>,
  timeEntryStatsByLaw: TimeEntryStatsByLaw,
): Map<string, string[]> => {
  if (!isLegal(timeEntryStatsByLaw)) {
    addError(target, '.', getBreakValidationErrorText(timeEntryStatsByLaw));
  }
  return target;
};

const addError = (
  target: Map<string, string[]>,
  key: string,
  value: string,
) => {
  if (target.has(key)) {
    target.get(key)?.push(value);
  } else {
    target.set(key, [value]);
  }
};
