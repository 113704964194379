import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { employeePageRoutes } from '../../manager/EmployeePage/PageHeader/routes';
import TimeSheetContent from './TimeSheetContent';
import { TimeSheet as TimeSheetType } from './types';

type RouteParams = {
  timeSheetId: string;
};

const TimeSheet: React.FC = () => {
  const { t } = useTranslation();
  const { timeSheetId } = useParams<RouteParams>();
  const history = useHistory();

  const goToAllTimesheetsAction = (timeSheet?: TimeSheetType) => {
    if (timeSheet) {
      history.push(
        employeePageRoutes().timeSheetList.route.create({
          employeeId: timeSheet.flair__Employee__c,
        }),
      );
    }
  };

  return (
    <>
      <PageHeader
        title={t('timeTracking.subMenuItems.controlling.title')}
        subtitle={t('timeTracking.title')}
      />

      <TimeSheetContent
        goToAllTimesheetsAction={goToAllTimesheetsAction}
        timeSheetId={timeSheetId}
      />
    </>
  );
};

export default TimeSheet;
