import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SelectSingleEmployee,
  Props as SelectSingleEmployeeProps,
} from '../../../apps/dashboard/components/Selects/SelectSingleEmployee';
import InputError from '../InputError';
import { getError } from '../utils';

type Props = Omit<SelectSingleEmployeeProps, 'value' | 'onChange'> & {
  name: string;
};

export const SelectSingleEmployeeControlled: React.FC<Props> = ({
  name,
  ...props
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  return (
    <>
      <Controller
        as={({ value, onChange }) => {
          return (
            <SelectSingleEmployee
              {...props}
              value={value}
              onChange={onChange}
              error={error !== undefined}
              className={error ? 'is-invalid' : ''}
            />
          );
        }}
        name={name}
        control={control}
      />
      <InputError error={error} />
    </>
  );
};
