import * as React from 'react';
import { useStartTimeTracking } from '../../../../hooks/timeTracking';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../../components/button';
import './StartTimeTrackingButton.css';
import { WarningModal } from '../../../TimeTracking/components/WarningModal/WarningModal';
import { useCallback } from 'react';
import { useTimeTrackingRestriction } from '../../../../hooks/timeTrackingSettings';

const i18Path = 'dashboard.timeTracking';

const StartTimeTrackingButton: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const {
    hasOngoingRestrictedAbsence,
    showRestrictedWarningModal,
    setShowRestrictedWarningModal,
  } = useTimeTrackingRestriction();
  const { handleStartTracking, loading } = useStartTimeTracking();

  const handleClick = useCallback(() => {
    if (hasOngoingRestrictedAbsence(new Date())) {
      setShowRestrictedWarningModal(true);
    } else {
      handleStartTracking();
    }
  }, [
    handleStartTracking,
    hasOngoingRestrictedAbsence,
    setShowRestrictedWarningModal,
  ]);

  return (
    <>
      <WarningModal
        visible={showRestrictedWarningModal}
        onClose={() => setShowRestrictedWarningModal(false)}
      />
      <Button
        size="sm"
        className="rounded-2 start-tracking-btn h4-regular text-nowrap"
        onClick={handleClick}
        isProcessing={loading}
        label={loading ? '' : t('startTracking')}
        data-testid="start-tracking-btn"
      />
    </>
  );
};

export default StartTimeTrackingButton;
