import classNames from 'classnames';
import React from 'react';
import { TimeSheet } from '../types';
import TimeSheetApproveStatus from './TimeSheetApproveStatus';
import TimesheetDatesInfo from './TimesheetDatesInfo';
import TimeSheetNavigation from './TimeSheetNavigation';

const Container = ({
  className,
  ...rest
}: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('p-4', 'mb-4', 'rounded', 'bg-light')} {...rest} />
);

type Props = {
  timeSheet: TimeSheet;
  goToAllTimesheetsAction?: () => void;
};

const TimeSheetActionsPanel: React.FC<Props> = ({
  timeSheet,
  goToAllTimesheetsAction,
}) => {
  return (
    <Container>
      <div className="d-flex align-items-center mb-3 mb-lg-0 justify-content-around justify-content-md-between flex-wrap">
        <TimeSheetNavigation
          goToAllTimesheetsAction={goToAllTimesheetsAction}
          timeSheet={timeSheet}
        />
        <TimeSheetApproveStatus timeSheet={timeSheet} />
      </div>
      <TimesheetDatesInfo
        classes={'d-flex justify-content-center d-lg-none'}
        timeSheet={timeSheet}
      />
    </Container>
  );
};

export default TimeSheetActionsPanel;
