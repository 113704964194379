import React from 'react';

import NotificationSkeleton from './NotificationSkeleton';

const NotificationsContentLoadingSkeleton: React.FC = () => {
  return (
    <>
      <NotificationSkeleton notificationTextWidth="large" />
      <NotificationSkeleton notificationTextWidth="medium" />
      <NotificationSkeleton notificationTextWidth="large" />
      <NotificationSkeleton notificationTextWidth="small" />
    </>
  );
};

export default NotificationsContentLoadingSkeleton;
