import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import {
  Breadcrumb,
  createRootFolderId,
  PersonalDocumentFilter,
} from '../types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import {
  PersonalDocumentsTable,
  TableItem as PersonalDocumentRowType,
} from '../../../pages/Documents2/Tables/PersonalDocumentsTable';
import {
  usePersonalDocumentsFilterQuery,
  usePersonalDocumentsQuery,
} from '../../../__generated__/graphql';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import ServerError from '../../../../../components/ServerError';
import { mapPersonalDocumentPreview, mapPersonalDocuments } from '../mappings';
import { DocumentPreviewModal } from '../DocumentInfo/DocumentPreviewModal';
import { RowFileTypeEnums } from '../Enums/RowFileTypeEnums';
import { DocumentInfoSidebar } from '../DocumentInfo/DocumentInfoSidebar';
import {
  applyPersonalDocumentsFilter,
  hasPersonalDocumentFilter,
} from '../Fliters/filters';
import { PersonalDocumentsFiltersConnected } from '../Fliters/PersonalDocumentsFiltersConnected';
import { HREmployee } from '../Fliters/mappings';
import { OptionBase } from '../../../../../components/Select/types';
import {
  EmployeeOption,
  mapEmployeeOption,
} from '../../../components/Selects/EmployeeOptionLabel';

import { DocumentMoveModal } from '../DocumentMoveModal/DocumentMoveModal';
import { SelectionBottomPanel } from '../../../components/SelectionBottomPanel/SelectionBottomPanel';
import { TableBottomBanner } from './TableBottomBanner';
import { DocumentUploadModalConnected } from '../DocumentUploadModal/DocumentUploadModalConnected';
import { FolderBreadcrumbs } from '../FolderBreadcrumbs';
import {
  useBreadcrumbs,
  useCreateFolder,
  useDocumentDelete,
  useDocumentView,
} from '../hooks';
import { useDocumentMoveTo } from '../hooks/useDocumentMoveTo';
import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import { DocumentDeleteConfirmation } from '../DeleteConfirmations';
import { DocumentFolderModal } from '../DocumentFolderModal/DocumentFolderModal';
import { useFolder } from '../hooks/useFolder';
import { useDocumentRename } from '../hooks/useDocumentRename';
import { DocumentRenameModal } from '../DocumentRenameModal/DocumentRenameModal';
import { useUserInfo } from '../../../context/UserInfo';
import GlobalTableFilter from '../../../components/GlobalTableFilter';

type Props = {
  isManager?: boolean;
  employeeId: string;
  filter: PersonalDocumentFilter;
  setFilter: (filter: PersonalDocumentFilter) => void;
};

const i18Path = 'documents2.personal';

export const DocumentPageConnected: React.FC<Props> = (props) => {
  const { isManager = false, employeeId, filter, setFilter } = props;
  const t = useNamespacedTranslation(i18Path);
  const { id: meId } = useUserInfo();
  const rootFolderId = createRootFolderId(employeeId);

  const { currentFolderId, setCurrentFolderId } = useFolder({
    employeeId,
  });

  const {
    data: filterData,
    loading: filterLoading,
    error: filterError,
  } = usePersonalDocumentsFilterQuery();

  const { data, loading, error } = usePersonalDocumentsQuery({
    variables: {
      employeeId,
      folderId: currentFolderId,
    },
  });

  const parentFolderId = data?.navigatable?.parentFolderId
    ? data?.navigatable?.parentFolderId
    : createRootFolderId(employeeId);

  const {
    rowsToMove,
    handleMoveToFolder,
    handleMoveToModalClose,
    setSelectedFolderId,
    selectedFolderId,
    move,
    moveInProgress,
    handleMassMove,
    isMoveModalVisible,
  } = useDocumentMoveTo({ employeeId });

  const {
    setFolderName,
    handleCreateFolderClose,
    createFolderVisible,
    setCreateFolderVisible,
    createFolder,
    createFolderInProgress,
    addDocumentPath,
    folderName,
  } = useCreateFolder({
    currentFolderId:
      isMoveModalVisible && selectedFolderId
        ? selectedFolderId
        : currentFolderId,
    rootFolderId,
    employeeId,
    isManager,
  });

  const [uploadVisible, setUploadVisible] = useState<boolean>(false);

  const {
    sidebarVisible,
    previewVisible,
    setDocumentSidebar,
    setDocumentPreview,
    handlePreviewClose,
    handleSideBarClose,
    handleDownload,
  } = useDocumentView();

  const {
    handleDeleteClose,
    handleDelete,
    handleMassDelete,
    documentDelete,
    deleteItems,
    deleteInProgress,
  } = useDocumentDelete({ employeeId });

  const {
    rowToRename,
    renameInProgress,
    name,
    handleSetNewName,
    handleOpenRenameModal,
    handleCloseRenameModal,
    rename,
  } = useDocumentRename();

  const { breadcrumbs, getNextFolderId } = useBreadcrumbs({
    data,
    currentFolderId,
    rootFolderId,
    employeeId,
  });

  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [resetSelectedRows, setResetSelectedRows] = useState<boolean>(false);
  const [selectAllRows, setSelectAllRows] = useState<boolean>(false);

  const addSelectedRow = useCallback((rows?: PersonalDocumentRowType[]) => {
    setSelectedRows(rows);
    setResetSelectedRows(false);
    setSelectAllRows(false);
  }, []);

  const getUniqueSelectedRows = () => {
    return selectedRows.filter(
      (obj: PersonalDocumentRowType, index: number) =>
        selectedRows.findIndex(
          (item: PersonalDocumentRowType) => item.fullPath === obj.fullPath,
        ) === index,
    );
  };

  const handleResetState = () => {
    setResetSelectedRows(true);
  };

  const [categoryOptionsWithId, categoryOptionsWithName] = useMemo<
    [OptionBase[], OptionBase[]]
  >(() => {
    if (!filterData) {
      return [[], []];
    }

    return filterData.documentCategories.reduce<[OptionBase[], OptionBase[]]>(
      (options: any, category: any) => {
        options[0].push({ value: category.Id, label: category.Name });
        options[1].push({ value: category.Name, label: category.Name });
        return options;
      },
      [[], []],
    );
  }, [filterData]);

  const employeeOptions: EmployeeOption[] = useMemo(() => {
    if (!filterData) {
      return [];
    }

    const activeEmployees = filterData.activeEmployees.map(mapEmployeeOption);
    return [...activeEmployees, HREmployee];
  }, [filterData]);
  const allDocuments = useMemo(
    () => (data ? mapPersonalDocuments(data, meId) : []),
    [data, meId],
  );

  const documents = useMemo(
    () => applyPersonalDocumentsFilter(filter, allDocuments),
    [filter, allDocuments],
  );

  const getSelectedDocumentRow = () => {
    const documentRow = selectedDocumentId
      ? allDocuments.find((x) => x.id === selectedDocumentId)
      : null;

    return documentRow
      ? mapPersonalDocumentPreview({
          ...documentRow,
          file: documentRow.files?.find((file) => file.id === selectedFileId),
        })
      : null;
  };

  const onClick = useCallback(
    (row: PersonalDocumentRowType) => {
      const { file, ...document } = row;
      if (file && !document.subRows) {
        switch (file.type) {
          case RowFileTypeEnums.Pdf:
          case RowFileTypeEnums.Png:
          case RowFileTypeEnums.Jpg:
          case RowFileTypeEnums.Jpeg:
          case RowFileTypeEnums.Gif:
            setSelectedDocumentId(file.documentId);
            setSelectedFileId(file.id);
            setDocumentPreview(true);
            break;
          default:
            setSelectedDocumentId(file.documentId);
            setSelectedFileId(file.id);
            setDocumentSidebar(true);
        }
      } else if (!file && !document.subRows) {
        setCurrentFolderId(document.id);
        addDocumentPath(document.id);
      }
    },
    [
      addDocumentPath,
      setCurrentFolderId,
      setDocumentPreview,
      setDocumentSidebar,
    ],
  );

  const navigateBack = useCallback(() => {
    setCurrentFolderId(parentFolderId);
  }, [parentFolderId, setCurrentFolderId]);

  const navigateByFolder = useCallback(
    (breadcrumb: Breadcrumb) => {
      setCurrentFolderId(getNextFolderId(breadcrumb));
    },
    [getNextFolderId, setCurrentFolderId],
  );

  const handleSubmitDocumentDelete = () => {
    if (sidebarVisible) setDocumentSidebar(false);
    if (previewVisible) setDocumentPreview(false);
    documentDelete(handleResetState);
  };

  const renderContent = useCallback(() => {
    if (loading || !data) {
      return <Loading />;
    }

    return (
      <PersonalDocumentsTable
        isManager={isManager}
        resetSelectedRows={resetSelectedRows}
        selectAllRows={selectAllRows}
        documents={documents}
        hasPersonalDocumentFilter={hasPersonalDocumentFilter(filter)}
        onDownload={handleDownload}
        setSelectedRows={addSelectedRow}
        onClick={onClick}
        onDelete={handleDelete}
        onRename={handleOpenRenameModal}
        onMove={handleMoveToFolder}></PersonalDocumentsTable>
    );
  }, [
    loading,
    data,
    isManager,
    resetSelectedRows,
    selectAllRows,
    documents,
    filter,
    handleDownload,
    addSelectedRow,
    onClick,
    handleDelete,
    handleOpenRenameModal,
    handleMoveToFolder,
  ]);

  const onSearch = (val: string) => setFilter({ ...filter, documentName: val });

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <>
        <Card>
          <Card.Header className="h-auto">
            <GlobalTableFilter
              value={filter.documentName || ''}
              placeholder={t('search')}
              onFilterChange={onSearch}
            />
            <PersonalDocumentsFiltersConnected
              categories={categoryOptionsWithName}
              employees={employeeOptions}
              loading={filterLoading}
              error={filterError}
              filter={filter}
              onChange={setFilter}></PersonalDocumentsFiltersConnected>
          </Card.Header>
          <Card.Header>
            <div className="d-flex gap-3 justify-content-between align-items-center small">
              <div className="d-flex align-items-center">
                <FolderBreadcrumbs
                  breadcrumbs={breadcrumbs}
                  navigateByFolder={navigateByFolder}
                  navigateBack={navigateBack}
                  isLoading={loading}
                />
              </div>
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <Button
                  onClick={() => setUploadVisible(true)}
                  variant="primary"
                  className="d-flex align-items-center">
                  <FlairIcon icon="file-arrow-up" />
                  <span className="ms-2 small">
                    {t('table.buttons.upload')}
                  </span>
                </Button>
                {!isManager && (
                  <StyleIcon
                    variant="outline-primary"
                    className="d-flex align-items-center"
                    onClick={() => setCreateFolderVisible(true)}>
                    <FlairIcon icon="folder-simple-plus" />
                    <span className="ms-2 small">
                      {t('table.buttons.folder')}
                    </span>
                  </StyleIcon>
                )}
              </div>
            </div>
          </Card.Header>
          {renderContent()}
        </Card>
        <SelectionBottomPanel
          selectedCount={getUniqueSelectedRows().length}
          onSelectAll={() => setSelectAllRows(true)}
          onCancel={() => {
            setResetSelectedRows(true);
          }}>
          <TableBottomBanner
            isManager={isManager}
            onDelete={() => handleMassDelete(selectedRows)}
            onMove={() => {
              handleMassMove(selectedRows);
            }}></TableBottomBanner>
        </SelectionBottomPanel>
      </>
      <DocumentDeleteConfirmation
        selectedItems={deleteItems}
        visible={!!deleteItems.length}
        submitInProgress={deleteInProgress}
        onClose={handleDeleteClose}
        onSubmit={handleSubmitDocumentDelete}></DocumentDeleteConfirmation>
      <DocumentPreviewModal
        visible={previewVisible && !!getSelectedDocumentRow()}
        isPersonal={true}
        isManager={isManager}
        onDownload={handleDownload}
        onDelete={() => {
          const row = getSelectedDocumentRow();
          if (!row) {
            return;
          }
          handleDelete(
            {
              id: row.id,
              type: row.typeName,
            },
            row.type,
          );
        }}
        isDeleteModalVisible={!!deleteItems.length}
        document={getSelectedDocumentRow()}
        documentCategories={categoryOptionsWithId}
        onClose={handlePreviewClose}></DocumentPreviewModal>
      <DocumentInfoSidebar
        documentCategories={categoryOptionsWithId}
        visible={sidebarVisible && !!getSelectedDocumentRow()}
        isPersonal={true}
        isManager={isManager}
        onDownload={handleDownload}
        document={getSelectedDocumentRow()}
        onDelete={() => {
          const row = getSelectedDocumentRow();
          if (!row) {
            return;
          }
          handleDelete(
            {
              id: row.id,
              type: row.typeName,
            },
            row.type,
          );
        }}
        onClose={() => {
          handleSideBarClose();
        }}></DocumentInfoSidebar>
      <DocumentFolderModal
        folderName={folderName}
        visible={createFolderVisible}
        createFolder={createFolder}
        createInProgress={createFolderInProgress}
        setFolderName={setFolderName}
        onClose={handleCreateFolderClose}
      />
      <DocumentUploadModalConnected
        employeeId={employeeId}
        onClose={() => setUploadVisible(false)}
        visible={uploadVisible}
        categories={categoryOptionsWithId}
        currentFolderId={currentFolderId}
      />
      <DocumentMoveModal
        isCreateFolderModalVisible={createFolderVisible}
        openCreateFolderModal={() => setCreateFolderVisible(true)}
        employeeId={employeeId}
        visible={!!rowsToMove.length}
        currentFolderId={currentFolderId}
        setSelectedFolderId={setSelectedFolderId}
        selectedFolderId={selectedFolderId}
        rowsToMove={rowsToMove}
        onClose={handleMoveToModalClose}
        onSubmit={() => {
          move(handleResetState);
        }}
        moveInProgress={moveInProgress}
      />
      <DocumentRenameModal
        visible={!!rowToRename}
        onClose={handleCloseRenameModal}
        renameInProgress={renameInProgress}
        setName={handleSetNewName}
        rename={rename}
        name={name}
        initialName={rowToRename?.name}
      />
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5} />;
};

const StyleIcon = styled(Button)`
  &:hover,
  &:focus svg {
    path {
      stroke: ${Theme.color.white};
    }
  }
`;
