import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHourlyAbsenceAmountFormatter } from '../../../hooks/useHourlyAbsenceAmountFormatter';
import { AbsenceType } from '../../../__generated__/graphql';

type Variant = 'normal' | 'work' | 'calendar';

type Props = {
  type: AbsenceType;
  amount: number;
  variant: Variant;
};

const DailyAbsenceAmount: React.FC<Pick<Props, 'amount' | 'variant'>> = ({
  amount,
  variant,
}) => {
  const { t } = useTranslation();

  switch (variant) {
    case 'normal': {
      return <>{t('absences.days', { count: amount })}</>;
    }
    case 'work': {
      return <>{t('absences.workingDays', { count: amount })}</>;
    }
    case 'calendar': {
      return <>{t('absences.calendarDays', { count: amount })}</>;
    }
  }
};

const HourlyAbsenceAmount: React.FC<Pick<Props, 'amount'>> = ({ amount }) => {
  const { t } = useTranslation();
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  return <>{t('absences.hours', { amount: hourlyFormatter(amount) })}</>;
};

const AbsenceAmount: React.FC<Props> = ({ type, amount, variant }) => {
  switch (type) {
    case AbsenceType.Daily:
      return <DailyAbsenceAmount amount={amount} variant={variant} />;
    case AbsenceType.Hourly: {
      return <HourlyAbsenceAmount amount={amount} />;
    }
    case AbsenceType.Unknown:
      return <>{amount}</>;
  }
};

export default AbsenceAmount;
