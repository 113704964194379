import { orderBy } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Employee } from '..';
import ServerError from '../../../../../components/ServerError';
import { useYearFromQueryString } from '../../../../../hooks/useYearFromQueryString';
import { Link } from '../../../../../Router';
import CardHeader from '../../../components/CardHeader';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import CompactColumn from '../../../components/Table/CompactColumn';
import TimeSheetBalanceIndicator from '../../../components/TimeSheet/TimeSheetBalanceIndicator';
import TimeSheetStatusIndicator from '../../../components/TimeSheet/TimeSheetStatusIndicator';
import YearChart from '../../../components/TimeSheet/YearChart';
import YearNav from '../../../components/YearNav';
import {
  EmployeePageControllingQuery,
  TimeSheetStatus,
  useEmployeePageControllingQuery,
} from '../../../__generated__/graphql';
import { employeePageRoutes } from '../PageHeader/routes';
import Loading from './Loading';
import {
  firstDateOfIsoYear,
  isoWeekFromDateString,
  lastDateOfIsoYear,
} from '../../../../../utils/date';
import TimeSheetEntryDaysWorked from '../../TimeSheetsControlling/CurrentTimeSheetsTab/TimeSheetEntryDaysWorked';
import TimeSheetEntryWarningBadge from '../../TimeSheetsControlling/CurrentTimeSheetsTab/TimeSheetEntryWarningBadge';

type Props = {
  employee: Employee;
};

const YEAR_PARAM_NAME = 'year';

const buildPath = (employeeId: string, year: number) =>
  employeePageRoutes()
    .timeSheetList.route.withQueryParams({ [YEAR_PARAM_NAME]: String(year) })
    .create({ employeeId });

const shouldDisplayTimeSheet = (timeSheet: TimeSheet) =>
  [TimeSheetStatus.Pending, TimeSheetStatus.Approved].includes(
    timeSheet.flair__Approval_Status__c,
  ) && moment(timeSheet.flair__Start_Date__c).isSameOrBefore(new Date(), 'day');

type TimeSheet = NonNullable<
  EmployeePageControllingQuery['manager']['employee']
>['timeSheets'][0];

const Content: React.FC<{ year: number; employeeId: string }> = ({
  employeeId,
  year,
}) => {
  const { t } = useTranslation();
  const { data, loading, error } = useEmployeePageControllingQuery({
    variables: {
      id: employeeId,
      from: firstDateOfIsoYear(year).format('YYYY-MM-DD'),
      to: lastDateOfIsoYear(year).format('YYYY-MM-DD'),
    },
  });

  const history = useHistory();

  const handleDatasetClick = (timeSheetId: string) => {
    history.push(
      employeePageRoutes().timeSheet.route.create({ employeeId, timeSheetId }),
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (error || data?.manager.employee === undefined) {
    return <ServerError />;
  }

  const timeSheetsTracked = orderBy(
    data?.manager.employee?.timeSheets?.filter((timesheet) =>
      shouldDisplayTimeSheet(timesheet),
    ),
    ['flair__Start_Date__c'],
    ['desc'],
  );

  if (!timeSheetsTracked || !timeSheetsTracked.length) {
    return (
      <EmptyStateCardBody
        title={t('employeePage.timeSheetList.emptyState.title')}
      />
    );
  }

  return (
    <>
      <Card.Body className="border-bottom border-light">
        <Row>
          <Col>
            <h4 className="text-center mb-4">
              {t('employeePage.timeSheetList.weeklyProgressChart.title')}
            </h4>
            <YearChart
              year={year}
              timeSheets={timeSheetsTracked}
              onDatasetClick={handleDatasetClick}
            />
          </Col>
        </Row>
      </Card.Body>
      <Table className="card-table" size="sm" hover responsive>
        <thead>
          <tr>
            <th>{t('employeePage.timeSheetList.table.period')}</th>
            <th>{t('employeePage.timeSheetList.table.status')}</th>
            <th>{t('employeePage.timeSheetList.table.balance')}</th>
            <th>{t('employeePage.timeSheetList.table.daysWorked')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {timeSheetsTracked.map((timeSheet) => (
            <tr key={timeSheet.Id}>
              <td>
                <Link
                  to={employeePageRoutes().timeSheet.route}
                  employeeId={employeeId}
                  timeSheetId={timeSheet.Id}>
                  {t('calendarWeek', {
                    week: isoWeekFromDateString(timeSheet.flair__Start_Date__c),
                  })}
                </Link>
              </td>
              <td>
                <TimeSheetStatusIndicator
                  status={timeSheet.flair__Approval_Status__c}
                />
              </td>
              <td>
                <TimeSheetBalanceIndicator timeSheet={timeSheet} />
              </td>
              <td>
                <TimeSheetEntryDaysWorked timeSheet={timeSheet} />
              </td>
              <CompactColumn>
                <TimeSheetEntryWarningBadge
                  trackedTime={timeSheet.trackedTime}
                />
              </CompactColumn>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const EmployeeTimeSheetList: React.FC<Props> = ({ employee }) => {
  const year = useYearFromQueryString(YEAR_PARAM_NAME);
  const history = useHistory();

  const goToPrevYear = useCallback(() => {
    history.push(buildPath(employee.Id, year - 1));
  }, [year, history, employee.Id]);

  const goToNextYear = useCallback(() => {
    history.push(buildPath(employee.Id, year + 1));
  }, [year, history, employee.Id]);

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col className="text-end">
            <YearNav
              year={year}
              goToNextYear={goToNextYear}
              goToPrevYear={goToPrevYear}
            />
          </Col>
        </Row>
      </CardHeader>
      <Content employeeId={employee.Id} year={year} />
    </Card>
  );
};

export default EmployeeTimeSheetList;
