import * as React from 'react';
import { Modal } from 'react-bootstrap';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import Button from '../../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import './AssignPeerReviewersWarning.css';

type Props = {
  handleOnProceed: () => void;
  onClose: () => void;
  show: boolean;
};

const i18Path = 'managerEmployeeEvaluation';

const AssignPeerReviewersWarning: React.FC<Props> = ({
  show,
  handleOnProceed,
  onClose,
}) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="d-flex flex-column shadow-lg align-items-center p-3">
        <div className="d-flex flex-column align-items-center px-3">
          <div className="exclamation-mark-container">!</div>
          <div className="mt-4 text-center">{t('assignPeersWarning')}</div>
        </div>
        <FlairIcon
          icon="close-outline"
          className="close-icon"
          onClick={onClose}
          size="lg"
        />

        <div className="mt-4">
          <Button
            onClick={onClose}
            label={t('goBack')}
            variant="outline-secondary"
            className="me-3"
          />
          <Button onClick={handleOnProceed} label={t('submit')} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignPeerReviewersWarning;
