import React from 'react';
import PageHeader from '../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import AnalyticsCard from './AnalyticsCard/AnalyticsCard';
import './AnalyticsOverview.css'; // Import the CSS file
import { routes } from '../../routes';
import { AnalyticsType } from './AnalyticsCard/types';
import FeedbackCard from '../../components/FeedbackCard/FeedbackCard';

const i18Path = 'analytics';
const Content: React.FC = () => {
  const { absenceRate, employeeTurnoverRate, employeeOvertime } = routes;
  const t = useNamespacedTranslation(`${i18Path}.analyticsCard`);
  return (
    <div className="analytics-overview-wrapper">
      <AnalyticsCard
        title={t('absenceRate.title')}
        description={t('absenceRate.description')}
        to={absenceRate.route.create({})}
        type={AnalyticsType.LINE}
      />
      <AnalyticsCard
        title={t('employeeTurnoverRate.title')}
        description={t('employeeTurnoverRate.description')}
        to={employeeTurnoverRate.route.create({})}
        type={AnalyticsType.BAR}
      />
      <AnalyticsCard
        title={t('employeeOvertimeRate.title')}
        description={t('employeeOvertimeRate.description')}
        to={employeeOvertime.route.create({})}
        type={AnalyticsType.BAR}
      />
      <FeedbackCard
        title={t('suggest.title')}
        description={t('suggest.description')}
      />
    </div>
  );
};

export const AnalyticsOverview: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  useFlairBreadcrumbHook([{ label: t('title') }]);

  return (
    <>
      <PageHeader title={t('title')} />
      <Content />
    </>
  );
};
