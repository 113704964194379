import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader';
import { GoalsPage } from './GoalsPage';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

const Goals: React.FC = () => {
  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.personalDevelopment.title') },
    { label: t('performanceReview.subMenuItems.goals.title') },
  ]);

  return (
    <>
      <PageHeader title={t('performanceReview.subMenuItems.goals.title')} />
      <GoalsPage isManagerView={false} />
    </>
  );
};

export default Goals;
