import React from 'react';
import AbsenceCategoryIcon from '../../../components/AbsenceCategoryIcon';
import AbsenceDateTimeRange from '../../../components/AbsenceDateTimeRange';
import { Absence } from '../../../__generated__/graphql';

type AbsenceProps = Pick<
  Absence,
  | 'Id'
  | 'flair__Type__c'
  | 'flair__Approval_Status__c'
  | 'flair__Category_Name__c'
  | 'flair__Category_Icon__c'
  | 'flair__Start_Date__c'
  | 'flair__Start_Date_Half_Day__c'
  | 'flair__Start_Time__c'
  | 'flair__End_Date__c'
  | 'flair__End_Date_Half_Day__c'
  | 'flair__End_Time__c'
>;

type Props = {
  absence: AbsenceProps;
};

const AbsenceName: React.FC<Props> = ({ absence }) => {
  return (
    <>
      <AbsenceCategoryIcon
        icon={absence.flair__Category_Icon__c}
        className="me-3"
        tooltip={absence.flair__Category_Name__c}
      />
      <AbsenceDateTimeRange absence={absence} />
    </>
  );
};

export default AbsenceName;
