import styled from '@emotion/styled';
import moment from 'moment';
import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { formattedDateTime } from '../../../../../components/datetime/FormattedDateTime';
import { Theme } from '../../../../../theme';
import { formattedDuration } from '../../../components/DurationFormat';
import SoftBadge from '../../../components/SoftBadge';

const Spinner = styled(BootstrapSpinner)({
  width: '0.75rem',
  height: '0.75rem',
});

const BadgeInfo = styled.span({
  display: 'inline-block',
  position: 'relative',
  zIndex: 2,
});

const BadgeProgress = styled.span<{ percentage: number }>(({ percentage }) => ({
  backgroundColor: Theme.color.blue5,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  width: `${percentage}%`,
  zIndex: 0,
}));

const Badge: React.FC<{
  loading?: boolean;
  variant: 'primary' | 'success' | 'danger';
  percentage?: number;
}> = ({ loading, percentage, variant, children }) => {
  return (
    <SoftBadge className="position-relative overflow-hidden" variant={variant}>
      {percentage && <BadgeProgress percentage={percentage} />}
      {loading && (
        <Spinner className="me-2" animation="grow" variant={variant} />
      )}
      <BadgeInfo>{children}</BadgeInfo>
    </SoftBadge>
  );
};

export const TrackingBadge: React.FC<{
  duration: number;
  clockedInAt: moment.Moment;
  percentage: number;
}> = ({ duration, percentage, clockedInAt }) => {
  const { t } = useTranslation();
  const formatDuration = formattedDuration(t);
  const formatDateTime = formattedDateTime(t);

  return (
    <Badge variant="primary" percentage={percentage} loading>
      <Trans
        t={t}
        i18nKey="timeTracking.controlling.trackingBadge"
        values={{
          duration: formatDuration(duration),
          dateTime: formatDateTime({ dateTime: clockedInAt, format: 'time' }),
        }}
        components={[<strong />, <strong />]}
      />
    </Badge>
  );
};

export const ClockedOutBadge: React.FC<{ overtime: number }> = ({
  overtime,
}) => {
  const { t } = useTranslation();
  const formatDuration = formattedDuration(t);
  const hasOvetime = overtime > 0;

  return (
    <Badge variant="success">
      <span>
        {hasOvetime ? (
          <Trans
            t={t}
            i18nKey="timeTracking.controlling.clockedOutWithOvertimeBadge"
            values={{ overtime: formatDuration(overtime) }}
            components={[<strong />]}
          />
        ) : (
          t('timeTracking.controlling.clockedOutBadge')
        )}
      </span>
    </Badge>
  );
};

export const OvertimeBadge: React.FC<{ overtime: number }> = ({ overtime }) => {
  const { t } = useTranslation();
  const formatDuration = formattedDuration(t);

  return (
    <Badge variant="danger" loading>
      <span>
        <Trans
          t={t}
          i18nKey="timeTracking.controlling.overtimeBadge"
          values={{ overtime: formatDuration(overtime) }}
          components={[<strong />]}
        />
      </span>
    </Badge>
  );
};
