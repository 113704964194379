import * as React from 'react';

const ImageSolidIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.25 2H1.75C1.55109 2 1.36032 2.07902 1.21967 2.21967C1.07902 2.36032 1 2.55109 1 2.75V13.25C1 13.4489 1.07902 13.6397 1.21967 13.7803C1.36032 13.921 1.55109 14 1.75 14H14.25C14.4489 14 14.6397 13.921 14.7803 13.7803C14.921 13.6397 15 13.4489 15 13.25V2.75C15 2.55109 14.921 2.36032 14.7803 2.21967C14.6397 2.07902 14.4489 2 14.25 2ZM10.3631 4.00625C10.6702 3.97811 10.9785 4.0453 11.246 4.19865C11.5135 4.35201 11.7273 4.58408 11.8582 4.86328C11.989 5.14247 12.0307 5.45522 11.9775 5.75895C11.9242 6.06267 11.7787 6.34261 11.5607 6.56065C11.3426 6.77869 11.0627 6.92424 10.7589 6.97747C10.4552 7.0307 10.1425 6.98904 9.86328 6.85815C9.58408 6.72726 9.35201 6.51351 9.19865 6.24599C9.0453 5.97847 8.97811 5.67019 9.00625 5.36312C9.03824 5.01405 9.19147 4.6872 9.43933 4.43933C9.6872 4.19146 10.014 4.03823 10.3631 4.00625V4.00625ZM2.375 13C2.27554 13 2.18016 12.9605 2.10983 12.8902C2.03951 12.8198 2 12.7245 2 12.625V9.88656L6.02 6.3125L9.04969 9.33594L5.38656 13H2.375ZM14 12.625C14 12.7245 13.9605 12.8198 13.8902 12.8902C13.8198 12.9605 13.7245 13 13.625 13H6.80094L11.4738 8.32719L14 10.4325V12.625Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ImageSolidIcon;
