import { Dictionary, groupBy } from 'lodash';
import React from 'react';
import { GoalStatusCard } from '../GoalStatusCard/GoalStatusCard';
import { GoalBase, GoalId, GoalStatus } from '../types';

type Props = {
  goals: GoalBase[];
  onGoalClick: (goalId: GoalId) => void;
};

export const GoalsList: React.FC<Props> = ({ goals, onGoalClick }) => {
  const renderGroup = (
    groupedGoals: Dictionary<GoalBase[]>,
    goalStatus: GoalStatus,
  ) => {
    if (!groupedGoals[goalStatus]) {
      return null;
    }
    return (
      <GoalStatusCard
        status={goalStatus}
        goals={groupedGoals[goalStatus]}
        onGoalClick={onGoalClick}
      />
    );
  };

  const groupedGoals = groupBy(goals, (x) => x.status);

  return (
    <div>
      {renderGroup(groupedGoals, 'DRAFT')}
      {renderGroup(groupedGoals, 'BEHIND')}
      {renderGroup(groupedGoals, 'ON_TRACK')}
      {renderGroup(groupedGoals, 'DONE')}
      {renderGroup(groupedGoals, 'CANCELED')}
    </div>
  );
};
