import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ServerError from '../../../../components/ServerError';
import { Redirect } from '../../../../Router';
import { routes } from '../../../auth/routes';
import PageHeader from '../../components/PageHeader';
import {
  EmployeeTokenResponseErrorCode,
  useCustomerSupportEmployeeTokenQuery,
} from '../../__generated__/graphql';
import { EnterEmailForm } from './enterEmailForm';

export const LoginForCustomerSupport: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null);

  return (
    <>
      <PageHeader title={'Customer Support'} />
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md="6">
            {!email ? (
              <EnterEmailForm onSubmit={setEmail} />
            ) : (
              <LoginForCustomerSupportContent email={email} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

const LoginForCustomerSupportContent: React.FC<{ email: string }> = ({
  email,
}) => {
  const { data, loading, error } = useCustomerSupportEmployeeTokenQuery({
    variables: {
      email,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  const apiToken = data.employeeToken?.apiToken;

  if (apiToken) {
    return (
      <Redirect
        to={routes.login.route.withQueryParams({
          apiToken,
        })}
      />
    );
  }
  return (
    <div className="text-center">
      <div>{getErrorText(data.employeeToken?.error)}</div>
    </div>
  );
};

const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border" role="status"></div>
  </div>
);

const getErrorText = (
  error?: EmployeeTokenResponseErrorCode | null,
): string => {
  if (!error) {
    return 'Unknown error';
  }
  switch (error) {
    case EmployeeTokenResponseErrorCode.AccessDenied:
      return "You don't have an access";
    case EmployeeTokenResponseErrorCode.EmployeeNotFound:
      return 'Employee with provided email is not found';
    case EmployeeTokenResponseErrorCode.EmployeeDoNotAllowSupportAccess:
      return 'Employee didn\' allow customer support access. It is possible to do it at "Settings -> Customer Support" page';
  }
};
