import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import InputLabel from '../../../../../../../../../components/form/InputLabel';
import { SelectDropdownControlled } from '../../../../../../../../../components/form/Selects';
import {
  DictionaryItemsByTypeQuery,
  DictionaryItemType,
  useDictionaryItemsByTypeQuery,
} from '../../../../../../../__generated__/graphql';
import ServerError from '../../../../../../../../../components/ServerError';
import { getCurrentLanguage } from '../../../../../../../../../locales/locale';
import { OptionBase } from '../../../../../../../../../components/Select/types';
import { i18Path } from '../../constants';

type Props = {
  isExistingInventory: boolean;
};
export const ManufacturerFormField: React.FC<Props> = ({
  isExistingInventory,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const locale = getCurrentLanguage();

  const { data, loading, error } = useDictionaryItemsByTypeQuery({
    variables: {
      type: DictionaryItemType.Manufacturer,
      lang: locale,
    },
  });
  if (error) return <ServerError />;
  return (
    <>
      <InputLabel label={t('form.fields.manufacturer')} required />
      <SelectDropdownControlled
        isLoading={loading}
        name="manufacturer"
        options={mapManufacturerOptions(data)}
        placeholder={t('form.fields.manufacturerPlaceholder')}
        heightMode="large"
        isDisabled={isExistingInventory}
      />
    </>
  );
};

const mapManufacturerOptions = (
  data: DictionaryItemsByTypeQuery | undefined,
): OptionBase[] => {
  if (!data || !data.dictionaryItemsByType?.length) {
    return [];
  }
  return data.dictionaryItemsByType?.map(({ Id, Name }) => ({
    value: Id,
    label: Name,
  }));
};
