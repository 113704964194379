import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Button from '../../../../../../components/button';
import { GoalHint } from '../../Goals2/GoalHint';

export type Props = {
  employeeName: string;
  activeGoalsCount: number;
  onClick: () => void;
};

const i18prefix = 'performanceReview.goals2Section';

export const GoalsSectionContent: React.FC<Props> = ({
  employeeName,
  activeGoalsCount,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-start">
      <div>
        <div className="fw-bold mb-2">
          {t(`${i18prefix}.header`, { name: employeeName })}
        </div>
        <div>
          <span>
            {activeGoalsCount > 0
              ? t(`${i18prefix}.activeGoals`, { count: activeGoalsCount })
              : t(`${i18prefix}.noActiveGoals`)}
          </span>
          <GoalHint className="ms-2" text={t(`${i18prefix}.hint`)} />
        </div>
      </div>
      <Button
        className="mt-2"
        label={t(`${i18prefix}.manage`)}
        size="sm"
        variant="outline-primary"
        onClick={onClick}
      />
    </div>
  );
};

export const GoalsSectionContentLoading = () => {
  return (
    <div className="d-flex justify-content-between align-items-start">
      <div>
        <div className="fw-bold mb-2">
          <Skeleton width={200} />
        </div>
        <Skeleton width={92} />
      </div>
      <Skeleton width={66} height={30} />
    </div>
  );
};
