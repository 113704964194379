import React from 'react';
import { BaseNewTicketModal } from './BaseNewTicketModal';
import { FieldValues } from 'react-hook-form';
import {
  categorySchema,
  ticketDetailsSchemaEmployee,
  ticketDetailsSchemaManager,
} from './schemas';
import { TicketCategoryStep } from './Steps/TicketCategoryStep';
import { TicketDetailsStep } from './Steps/TicketDetailsStep';
import { TicketPreviewStep } from './Steps/TicketPreviewStep';
import { FormItem } from './types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { TicketPolicy } from '../types';

type Props = {
  visible: boolean;
  onClose: () => void;
  modalIndex: number;
  isLoading: boolean;
  isManager: boolean;
  addTicket: (data: FieldValues) => void;
  ticketPolicy: TicketPolicy | null;
};
const i18Path = 'support.tickets.newTicket';
export const NewTicketModal: React.FC<Props> = ({
  visible,
  onClose,
  modalIndex,
  isLoading,
  isManager,
  addTicket,
  ticketPolicy,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const formList: FormItem[] = [
    {
      form: <TicketCategoryStep></TicketCategoryStep>,
      validationScheme: categorySchema,
      isVisibleExpanded: false,
      stepName: 'CATEGORY',
    },
    {
      form: <TicketDetailsStep isManager={isManager}></TicketDetailsStep>,
      validationScheme: isManager
        ? ticketDetailsSchemaManager
        : ticketDetailsSchemaEmployee,
      isVisibleExpanded: false,
      stepName: 'DETAILS',
    },
    {
      form: <TicketPreviewStep t={t} isManager={isManager}></TicketPreviewStep>,
      isVisibleExpanded: false,
      stepName: 'PREVIEW',
    },
  ];

  return (
    <BaseNewTicketModal
      visible={visible}
      key={modalIndex}
      ticketPolicy={ticketPolicy}
      steps={formList.map((item, index) => ({
        title: `title-${index}`,
        stepName: item.stepName,
      }))}
      onClose={onClose}
      isSubmitting={isLoading}
      onSubmit={(data) => {
        addTicket(data);
      }}
      formList={formList}></BaseNewTicketModal>
  );
};
