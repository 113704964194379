import { compact, uniq, without } from 'lodash';
import React, { ChangeEvent, useCallback } from 'react';
import { QuestionProps } from '../..';
import { QuestionnaireQuestionNotes } from '../../../QuestionnaireQuestionNotes';
import Checkbox from '../../../../../components/Checkbox';

export type MultipleChoiceInputProps = Omit<
  QuestionProps,
  'type' | 'requiredChoices'
>;

const MultipleChoice: React.FC<MultipleChoiceInputProps> = ({
  id,
  value,
  onChange,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
}) => {
  const values = compact(value.split(';'));
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, checked },
      } = event;
      const newValues = checked
        ? uniq([...values, value])
        : without(values, value);

      onChange(newValues.join(';'));
    },
    [onChange, values],
  );

  return (
    <>
      {choices.map((choice) => (
        <Checkbox
          key={choice.value}
          id={`${id}-${choice.value}`}
          value={choice.value}
          label={choice.label}
          checked={values.includes(choice.value)}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly}
        />
      ))}
      {showOptionalComment && (
        <QuestionnaireQuestionNotes
          value={notes}
          onChange={onChangeNotes}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default MultipleChoice;
