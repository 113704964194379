import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../../theme';
import { LoomVideo, LoomVideoPreview } from '../../../components/LoomVideo';
import { IMAGE_HEIGHT_REM, IMAGE_WIDTH_REM } from '../images';
import { Celebration } from '../types';

type CelebrationLoomVideoProps = Pick<Celebration, 'imageUrl' | 'type'> & {
  loomVideo: LoomVideo;
};

const IMAGE_BADGE_DIAMETER_REM = '5rem';

export const CelebrationLoomVideo: React.FC<CelebrationLoomVideoProps> = ({
  imageUrl,
  type,
  loomVideo,
}) => {
  return (
    <Container>
      <LoomVideoPreview
        className="card-img-top"
        loomVideo={loomVideo}
        previewWidth={IMAGE_WIDTH_REM * 16}
        previewHeight={IMAGE_HEIGHT_REM * 16}
      />
      <BadgeContainer className="loom-video-container__badge-container">
        <ImgStyled width="100%" height="100%" src={imageUrl} alt={type} />
      </BadgeContainer>
    </Container>
  );
};

const BadgeContainer = styled.div({
  position: 'absolute',
  borderRadius: '50%',
  border: 'solid',
  borderColor: Theme.color.white,
  borderWidth: '0.125rem',
  overflow: 'hidden',
  width: IMAGE_BADGE_DIAMETER_REM,
  height: IMAGE_BADGE_DIAMETER_REM,
  right: '1rem',
  bottom: `calc(-${IMAGE_BADGE_DIAMETER_REM} / 2)`,
});

const Container = styled.div(
  {
    position: 'relative',
  },
  `
  &:hover .loom-video-container__badge-container {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  },
  & .loom-video-container__badge-container {
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
`,
);

const ImgStyled = styled.img({
  objectFit: 'cover',
});
