import React, { memo } from 'react';
import { EmployeeCard } from './EmployeeCard';
import { Handle, Position } from 'react-flow-renderer';

export default memo(({ data }: any) => {
  const stylePositionTarget = { top: 0 };
  const stylePositionSource = { bottom: 0 };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: 'transparent', border: 0, ...stylePositionTarget }}
      />
      <EmployeeCard data={data} />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: 'transparent', border: 0, ...stylePositionSource }}
      />
      <Handle
        id="parent"
        type="source"
        position={Position.Bottom}
        style={{ background: 'transparent', border: 0, bottom: 0 }}
      />
    </>
  );
});
