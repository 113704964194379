import { useTimeTrackingSettings } from '../../hooks/timeTrackingSettings';

export type AutoBreaksTimeTrackingFeatures = {
  reminders: boolean;
  suggestBreaks: boolean;
  deductBreaks: boolean;
  denyEndBreakEarlier: boolean;
};

export const useAutoBreaksTimeTrackingFeatures =
  (): AutoBreaksTimeTrackingFeatures => {
    const { timeFramework } = useTimeTrackingSettings();

    return {
      reminders:
        timeFramework?.flair__Notify_Employee_For_Break_Time__c ?? false,
      suggestBreaks: timeFramework?.flair__Suggest_Adding_Break__c ?? false,
      deductBreaks:
        timeFramework?.flair__Automatically_Deduct_Unused_Break__c ?? false,
      denyEndBreakEarlier:
        timeFramework?.flair__Block_Ending_Breaks__c ?? false,
    };
  };
