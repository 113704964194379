import React from 'react';
import { Col } from 'react-bootstrap';
import Header, {
  PageHeaderRow as HeaderRow,
} from '../../../components/PageHeader';
import EmployeeNav from './EmployeeNav';
import { Employee } from '..';

type Props = {
  employee: Employee;
};

const PageHeader: React.FC<Props> = ({ employee }) => (
  <Header
    title={employee.Name}
    subtitle={employee.flair__Position__c ?? undefined}>
    <HeaderRow>
      <Col>
        <EmployeeNav
          employeeId={employee.Id}
          managerRolesForEmployee={employee.managerRolesForEmployee}
        />
      </Col>
    </HeaderRow>
  </Header>
);

export default PageHeader;
