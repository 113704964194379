import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  WorkflowContext,
  useWorkflowItemsFilterForMeQuery,
} from '../../../__generated__/graphql';
import { WorkflowItemFilterForMe, WorkflowItemFilterAllOptions } from './types';
import {
  WorkflowItemsFilter,
  Props as WorkflowsItemsFilterProps,
} from './WorkflowItemsFilter';
import { WorkflowItemsFilterLoading } from './WorkflowItemsFilterLoading';
import { mapCommonOption } from './mappings';

type Props = Omit<
  WorkflowsItemsFilterProps,
  | 'workflowTemplateOptions'
  | 'workflowItemTemplateOptions'
  | 'filter'
  | 'onChange'
  | 'responsibleOptions'
> & {
  filter: WorkflowItemFilterForMe;
  onChange: (filter: WorkflowItemFilterForMe) => void;
};

export const WorkflowItemsFilterForMeConnected: React.FC<Props> = ({
  filter,
  onChange,
  ...props
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  const { data, loading, error } = useWorkflowItemsFilterForMeQuery();

  if (error) {
    return <div>{t('errorLoadingOptions')}</div>;
  }

  if (loading || !data) {
    return <WorkflowItemsFilterLoading workflowContext={WorkflowContext.My} />;
  }

  const {
    workflowTemplates,
    workflowItemTemplates,
    responsibleEmployees,
    responsibleTeams,
  } = data.workflowItemsFilterOptions;

  const workflowTemplateOptions = workflowTemplates.map(mapCommonOption);
  const workflowItemTemplateOptions =
    workflowItemTemplates.map(mapCommonOption);
  const responsibleOptions = [...responsibleEmployees, ...responsibleTeams].map(
    mapCommonOption,
  );

  const handleOnChange = (value: WorkflowItemFilterAllOptions) => {
    onChange({
      workflowTemplateId: value.workflowTemplateId,
      workflowItemTemplateId: value.workflowItemTemplateId,
      year: value.year,
      responsibleId: value.responsibleId,
      status: value.status,
      startDateFrom: null,
      startDateTo: null,
      employeeId: null,
      onlyMyTasks: 'true',
    });
  };

  return (
    <WorkflowItemsFilter
      {...props}
      filter={{ ...filter, employeeId: null, onlyMyTasks: 'false' }}
      onChange={handleOnChange}
      responsibleOptions={responsibleOptions}
      workflowItemTemplateOptions={workflowItemTemplateOptions}
      yearVisible={true}
      workflowTemplateOptions={workflowTemplateOptions}></WorkflowItemsFilter>
  );
};
