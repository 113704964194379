import React from 'react';
import Doughnut from '../../../../../../components/charts/Doughnut';
import { flairAIMatchingScoreColor } from './types';
import styled from '@emotion/styled';

type FlairAILogoProps = {
  score: number;
};
const FlairAIMatchScoreChart: React.FC<FlairAILogoProps> = ({ score }) => {
  const color = flairAIMatchingScoreColor(score);
  return (
    <Doughnut
      width="3rem"
      height="3rem"
      options={{
        events: [], // disable hover
        cutout: 17,
      }}
      data={{
        datasets: [
          {
            data: [score, 100 - score],
            backgroundColor: [color, '#F4F4F4'],
          },
        ],
      }}>
      <ScoreSpan color={color}>{score}</ScoreSpan>
    </Doughnut>
  );
};

const ScoreSpan = styled.span<{ color: string }>(({ color }) => ({
  fontSize: '1rem',
  color,
}));

export default FlairAIMatchScoreChart;
