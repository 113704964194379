import React from 'react';
import { Col } from 'react-bootstrap';
import PageHeader, { PageHeaderRow } from '../../../components/PageHeader';
import ShiftsNav from '../ShiftsNav';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useUserInfo } from '../../../context/UserInfo';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

const i18Path = 'navigation.menuItems.time';

const ShiftsHeader: React.FC = () => {
  const { locationName } = useUserInfo();
  const t = useNamespacedTranslation(i18Path);
  const title = t('subMenuItems.shifts.title');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  return (
    <PageHeader title={`${title} (${locationName})`}>
      <PageHeaderRow>
        <Col>
          <ShiftsNav />
        </Col>
      </PageHeaderRow>
    </PageHeader>
  );
};

export default ShiftsHeader;
