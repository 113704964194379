import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
  color: string;
  size: number;
  className?: string;
};

export const StatusCircle: React.FC<Props> = ({ className, color, size }) => {
  return <Container className={className} color={color} size={size} />;
};

const Container = styled.div<{
  color: string;
  size: number;
}>`
  border-radius: 50%;
  background-color: ${(item) => item.color};
  width: ${(item) => `${item.size}px`};
  height: ${(item) => `${item.size}px`};
`;
