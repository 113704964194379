import styled from '@emotion/styled';
import moment, { Moment } from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { FilterSelectOption } from '../../../../../atomic/molecules/FlairDropdown/types';
import WeekSelector from '../../../../../components/WeekSelector';
import { ShiftEmployeeInfo } from '../Common/types';
import { WeeklyFilter } from './weeklyShiftsLogic';
import { FlairMultiSelectDropdown } from '../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

const StyledMultipleFilter = styled(FlairMultiSelectDropdown)({
  minWidth: 180,
});

type Props = {
  filter: WeeklyFilter;
  allEmployees: ShiftEmployeeInfo[];
  onFilterChanged: (filter: WeeklyFilter) => void;
};

const ShiftsWeeklyFilter: React.FC<Props> = ({
  allEmployees,
  filter,
  onFilterChanged,
}) => {
  const { weekStart } = filter;
  const { t } = useTranslation();

  const handleWeekStartChanged = (newValue: Moment) => {
    onFilterChanged({ ...filter, weekStart: newValue });
  };

  const handleEmployeeChange = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onFilterChanged({
      ...filter,
      additionalEmployeeIds: Array.isArray(selectedOptions)
        ? selectedOptions.map((x) => x.value)
        : [],
    });
  };

  const setWeekStartToCurrent = () => {
    onFilterChanged({ ...filter, weekStart: moment().startOf('isoWeek') });
  };

  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <StyledMultipleFilter
        options={allEmployees.map((x) => ({ value: x.id, label: x.name }))}
        selectedOptions={filter.additionalEmployeeIds}
        onChange={handleEmployeeChange}
        placeholder={t('shifts.personalShifts.employees')}
      />
      <div className="d-flex flex-wrap gap-3 justify-content-end">
        <WeekSelector
          weekStart={weekStart}
          onWeekStartChanged={handleWeekStartChanged}
        />
        <Button
          className="d-none d-md-block"
          variant="outline-primary"
          size="sm"
          onClick={setWeekStartToCurrent}>
          {t('shifts.personalShifts.currentWeek')}
        </Button>
      </div>
    </div>
  );
};

export default ShiftsWeeklyFilter;
