import {
  URL_PARAM_SHOW_COMMENT,
  getCommentPointerFromQueryParam,
} from '../logic';
import { useQueryParameter } from '../../../../../hooks/useQueryParameter';

export type Item = {
  id: string;
  showComments?: boolean;
};

export const useSelectedItemWithComments = () => {
  const [showCommentQueryParam] = useQueryParameter(URL_PARAM_SHOW_COMMENT);
  const commentPointer = getCommentPointerFromQueryParam(showCommentQueryParam);

  return {
    defaultSelectedItem: commentPointer
      ? { id: commentPointer.recordId, showComments: true }
      : null,
  };
};
