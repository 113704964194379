import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FieldLabel } from './FieldLabel';
import { FieldValue } from './FieldValue';
import { EmployeeFieldFull } from './types';

type Props = {
  field: EmployeeFieldFull;
};

const Value: React.FC<Props> = ({ field }) => {
  return field.pendingApproval && field.oldValue ? (
    <>
      <span style={{ textDecoration: 'line-through' }}>
        <FieldValue
          type={field.fieldInfo.type}
          options={field.fieldInfo.options}
          value={field.oldValue}
        />
      </span>

      {field.value && (
        <>
          <span className="me-1 ms-1">→</span>
          <span>
            <FieldValue
              type={field.fieldInfo.type}
              options={field.fieldInfo.options}
              value={field.value}
            />
          </span>
        </>
      )}
    </>
  ) : (
    <FieldValue
      type={field.fieldInfo.type}
      options={field.fieldInfo.options}
      value={field.value}
    />
  );
};

export const FieldItem: React.FC<Props> = ({ field }) => {
  return (
    <ListGroup.Item className="d-flex align-items-center justify-content-between px-0 text-truncate">
      <small className="me-2">
        <FieldLabel
          name={field.fieldInfo.name}
          label={field.fieldInfo.label}
          pendingApproval={field.pendingApproval}
        />
      </small>
      <small>
        <Value field={field} />
      </small>
    </ListGroup.Item>
  );
};
