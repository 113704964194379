import classNames from 'classnames';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { ShiftLabelInfo } from '../types';

type Props = {
  shiftLabels: ShiftLabelInfo[];
};

const ShiftLabelsTags: React.FC<Props> = ({ shiftLabels }) => (
  <>
    {shiftLabels.map((sl) => (
      <Badge
        key={sl.id}
        bg="light"
        className={classNames('rounded-sm', 'fw-bold', 'me-1')}>
        {sl.tagName}
      </Badge>
    ))}
  </>
);

export default ShiftLabelsTags;
