import React from 'react';
import { InventoryItemStatus } from '../../Table/StatusCell/InventoryStatus';
import { InventoryStatus } from '../../types';
import { AutoSaveIndicator } from '../../../../../../../components/form/AutoSaveIndicator';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import {
  INVENTORY_STATUS_FEATURE,
  useFeature,
} from '../../../../../../../context/FeaturesContext';

type Props = {
  label: string;
  status: InventoryStatus;
  isLoading: boolean;
  handleStatusUpdate: (status: InventoryStatus) => void;
  hasUpdateAccess: boolean;
};

const i18Path = 'myData.inventory.statusUpdate';
export const InventoryStatusSidebar: React.FC<Props> = ({
  label,
  status,
  isLoading,
  handleStatusUpdate,
  hasUpdateAccess,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const isInventoryStatusFeatureEnabled = useFeature(INVENTORY_STATUS_FEATURE);
  const isStatusPending = status === 'PENDING';

  const hasStatusUpdateAccess =
    hasUpdateAccess && isStatusPending && !isLoading;

  if (isInventoryStatusFeatureEnabled) {
    return (
      <div className="d-flex flex-column gap-2">
        <h4 className="card-header-title">{label}</h4>
        <div className="d-flex align-items-center gap-2">
          <InventoryItemStatus status={status} />
          <AutoSaveIndicator loading={isLoading} completed={isLoading} />
          {hasStatusUpdateAccess && (
            <>
              <StyledButton
                className="text-sm-center p-0 h7-medium"
                onClick={() => handleStatusUpdate('APPROVED')}
                variant="primary">
                {t('buttons.approve')}
              </StyledButton>
              <StyledButton
                className="text-sm-center p-0 h7-medium"
                onClick={() => handleStatusUpdate('REJECTED')}
                variant="outline-primary">
                {t('buttons.reject')}
              </StyledButton>
            </>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

const StyledButton = styled(Button)({
  width: '3.75rem',
  height: '1.5rem',
});
