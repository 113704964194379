import React, { ChangeEvent, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { Props } from '..';
import { EmployeeFeedbackNotes } from '../../EmployeeFeedbackNotes';

type SingleChoiceInputProps = Omit<Props, 'type' | 'requiredChoices'>;

const SingleChoice: React.FC<SingleChoiceInputProps> = ({
  value,
  onChange,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <>
      {choices.map((choice) => (
        <Form.Check
          key={choice}
          type="radio"
          label={choice}
          value={choice}
          checked={value === choice}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly}
        />
      ))}
      {showOptionalComment && (
        <EmployeeFeedbackNotes
          value={notes}
          onChange={onChangeNotes}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default SingleChoice;
