import React from 'react';
import { DEPARTMENT_FILTER, FilterProps } from './types';
import { useTranslation } from 'react-i18next';
import TeamCalendarMultipleFilter from './TeamCalendarMultipleFilter';

const DepartmentFilter: React.FC<FilterProps> = ({ options, filterData }) => {
  const { t } = useTranslation();

  return (
    <TeamCalendarMultipleFilter
      options={options}
      filterData={filterData}
      filterKey={DEPARTMENT_FILTER}
      placeholder={t(
        'employeePage.teamCalendar.filters.department.placeholder',
      )}
    />
  );
};

export default DepartmentFilter;
