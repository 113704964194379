import React from 'react';
import { useTranslation } from 'react-i18next';
import SingleFilter from '../../../../components/SelectFilters/SingleFilter';
import { FilterSelectOption } from '../../../../atomic/molecules/FlairDropdown/types';
import { ViewByType } from './shiftsLogic';
import { getViewByFromQuery, viewByToQueryParams } from './shiftsQueryUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import classNames from 'classnames';

type Props = {};

const isViewBy = (value: FilterSelectOption['value']): value is ViewByType =>
  value === 'day' || value === 'week';

const ShiftsViewBy: React.FC<Props> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const viewBy = getViewByFromQuery(query);

  const handleViewByChanged = (viewBy: ViewByType) => {
    history.push(
      routes.allShifts.route
        .withQueryParams(viewByToQueryParams(viewBy))
        .create({}),
    );
  };

  const handleChange = (newValue: string | null) => {
    const value = newValue ?? viewBy;
    if (isViewBy(value) && viewBy !== value) {
      handleViewByChanged(value);
    }
  };

  const options = [
    { value: 'day', label: t('shifts.personalShifts.viewByType.day') },
    { value: 'week', label: t('shifts.personalShifts.viewByType.week') },
  ];

  return (
    <div className={classNames('d-flex', 'align-items-center', 'gap-3')}>
      <span className="text-nowrap d-none d-lg-block">
        {t('shifts.personalShifts.viewByType.viewBy')}
      </span>
      <SingleFilter
        options={options}
        value={viewBy}
        onChange={handleChange}
        isClearable={false}
      />
    </div>
  );
};

export default ShiftsViewBy;
