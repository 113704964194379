import _ from 'lodash';

const DEFAULT_BATCH_SIZE = 5;

export type SerialProcessingReducerState = {
  pendingIds: string[];
  processingIds: string[];
  toRequestProcessingIds: string[]; // ephemeral just to communicate with hook
};

type SerialProcessingAction =
  | {
      type: 'addItem';
      id: string;
    }
  | { type: 'processingStarted' }
  | { type: 'processingCompleted' };

export const actions = {
  addItem: (id: string): SerialProcessingAction => ({
    type: 'addItem',
    id,
  }),
  processingStarted: (): SerialProcessingAction => ({
    type: 'processingStarted',
  }),
  processingCompleted: (): SerialProcessingAction => ({
    type: 'processingCompleted',
  }),
};

export const initialState: SerialProcessingReducerState = {
  pendingIds: [],
  processingIds: [],
  toRequestProcessingIds: [],
};

export type SerialProcessingOptions = {
  batchSize?: number;
};

export const serialProcessingReducer =
  ({ batchSize = DEFAULT_BATCH_SIZE }: SerialProcessingOptions) =>
  (
    state: SerialProcessingReducerState,
    action: SerialProcessingAction,
  ): SerialProcessingReducerState => {
    switch (action.type) {
      case 'addItem': {
        if (state.processingIds.length === 0) {
          // we can start processing immediatly
          return {
            ...state,
            processingIds: [],
            toRequestProcessingIds: [action.id],
          };
        } else {
          // we are processing
          return {
            ...state,
            pendingIds: _.uniq([...state.pendingIds, action.id]),
          };
        }
      }
      case 'processingStarted': {
        return {
          ...state,
          processingIds: state.toRequestProcessingIds,
          toRequestProcessingIds: [],
        };
      }
      case 'processingCompleted': {
        return {
          pendingIds: state.pendingIds.slice(batchSize),
          processingIds: [],
          toRequestProcessingIds: state.pendingIds.slice(0, batchSize),
        };
      }
      default:
        return state;
    }
  };
