import styled from '@emotion/styled';
import React from 'react';
import { WorkflowItemStatusComponent } from '../Common/WorkflowItemStatus';
import { WorkflowItemStatusInput } from '../Common/WorkflowItemStatusInput';
import {
  supportedWorkflowItemStatuses,
  WorkflowItemStatus,
  WorkflowItemStatusInfo,
} from './types';

type Props = {
  value: WorkflowItemStatusInfo;
  onChange: (value: WorkflowItemStatusInfo) => void;
  readonly: boolean;
  updatingToStatus: WorkflowItemStatus | null;
  isStepActive: boolean;
};

export const WorkflowItemStatusCell: React.FC<Props> = ({
  value,
  onChange,
  readonly,
  updatingToStatus,
  isStepActive,
}) => {
  const isReadonly =
    readonly ||
    !isStepActive ||
    !supportedWorkflowItemStatuses.includes(value.status);
  const getContent = () => {
    if (updatingToStatus !== null) {
      return (
        <WorkflowItemStatusInput
          value={updatingToStatus}
          isLoading={true}
          onChange={() => {}}
        />
      );
    }
    if (isReadonly) {
      return (
        <WorkflowItemStatusReadonlyContainer>
          <WorkflowItemStatusComponent status={value.status} />
        </WorkflowItemStatusReadonlyContainer>
      );
    } else {
      return (
        <WorkflowItemStatusInput
          value={value.status}
          onChange={(newStatus) => {
            if (newStatus) {
              onChange({ ...value, status: newStatus });
            }
          }}
        />
      );
    }
  };
  return <Container>{getContent()}</Container>;
};

const WorkflowItemStatusReadonlyContainer = styled.div({
  padding: '1px 9px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '36px',
});

const Container = styled.div({
  minWidth: '140px',
});
