import React from 'react';
import { useFlairPersistentFilter } from '../../../../../hooks/useFlairPersistentFilter';
import { emptyPersonalDocumentFilter } from '../../../pages/Documents2/types';
import { useParams } from 'react-router-dom';
import { DocumentPageConnected } from '../../../pages/Documents2/Components/DocumentsPageConnected';
import { managerRoutes } from '../../routes';

type RouteParams = {
  employeeId: string;
};

export const EmployeeDocuments: React.FC = () => {
  const { employeeId } = useParams<RouteParams>();
  const [filter, setFilter] = useFlairPersistentFilter({
    defaultFilter: emptyPersonalDocumentFilter,
    storageKey: 'employee_document_filter',
    createRouteUrl: (queryParams) =>
      managerRoutes.documents.route
        .withQueryParams(queryParams)
        .create({ employeeId }),
  });
  return (
    <DocumentPageConnected
      isManager={true}
      filter={filter}
      setFilter={setFilter}
      employeeId={employeeId}
    />
  );
};
