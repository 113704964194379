import { ExpenseCategory } from '../../../__generated__/graphql';
import { Colleague } from '../types';

export const employeeSort = (employeeA: Colleague, employeeB: Colleague) => {
  const a = employeeA.Name;
  const b = employeeB.Name;
  return a.localeCompare(b);
};

export const categorySort = (
  categoryA: ExpenseCategory | null,
  categoryB: ExpenseCategory | null,
) => {
  if (categoryA === null && categoryB === null) {
    return 0;
  }
  if (categoryA === null) {
    return 1;
  }
  if (categoryB === null) {
    return -1;
  }
  const a = categoryA.Name;
  const b = categoryB.Name;
  return a.localeCompare(b);
};
