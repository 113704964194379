import moment from 'moment';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Employee } from '..';
import ServerError from '../../../../../components/ServerError';
import EmployeeAbsenceCalendarCard from '../../../components/EmployeeAbsenceCalendarCard';
import {
  AbsenceApprovalStatus,
  useEmployeePageAbsenceCalendarQuery,
} from '../../../__generated__/graphql';
import { useYearFromQueryString } from '../../../../../hooks/useYearFromQueryString';
import { employeePageRoutes } from '../PageHeader/routes';

type Props = {
  employee: Employee;
};

const YEAR_PARAM_NAME = 'year';

const buildPath = (employeeId: string, year: number) =>
  employeePageRoutes()
    .absenceCalendar.route.withQueryParams({ [YEAR_PARAM_NAME]: String(year) })
    .create({ employeeId });

const EmployeeAbsenceCalendar: React.FC<Props> = ({ employee }) => {
  const year = useYearFromQueryString(YEAR_PARAM_NAME);
  const history = useHistory();

  const goToPrevYear = useCallback(() => {
    history.push(buildPath(employee.Id, year - 1));
  }, [year, history, employee.Id]);

  const goToNextYear = useCallback(() => {
    history.push(buildPath(employee.Id, year + 1));
  }, [year, history, employee.Id]);

  const { data, loading, error } = useEmployeePageAbsenceCalendarQuery({
    variables: {
      id: employee.Id,
      from: moment().year(year).startOf('year').toISOString(),
      to: moment().year(year).endOf('year').toISOString(),
    },
  });

  if (error) {
    return <ServerError />;
  }

  const absences = data?.manager.employee?.absences.filter(
    (a) => a.flair__Approval_Status__c !== AbsenceApprovalStatus.Rejected,
  );

  return (
    <>
      <EmployeeAbsenceCalendarCard
        absenceCategories={data?.manager.employee?.absenceCategories}
        absences={absences}
        workloads={data?.manager.employee?.workloads}
        holidays={data?.manager.employee?.holidays}
        loading={loading}
        year={year}
        goToPrevYear={goToPrevYear}
        goToNextYear={goToNextYear}
        readOnly
      />
    </>
  );
};

export default EmployeeAbsenceCalendar;
