import i18next from 'i18next';
import { OptionBase } from '../../../../../../components/Select/types';
import { allInventoryStatuses } from '../types';
import { InventoryFilter, InventoryListFilterInput } from './types';
import { parseDate, toISODateOnly } from '../../../../../../utils/dateUtils';
import { setMonth, startOfYear } from 'date-fns';

export const createInventoryStatusOptions = (): OptionBase[] => {
  return allInventoryStatuses.map((x) => ({
    value: x,
    label: i18next.t(`myData.inventory.table.inventoryStatus.${x}`),
  }));
};

export const createYearOptions = (now: Date): OptionBase[] => {
  const currentYear = now.getFullYear();
  return [currentYear - 1, currentYear, currentYear + 1].map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
};

export const createMonthOptions = (dates: OptionBase[]): OptionBase[] => {
  return dates.map((date) => ({ value: date.value, label: date.label }));
};

export const mapInventoryListFilterInput = (
  filter: InventoryFilter,
): InventoryListFilterInput => {
  return {
    searchTerm: filter.searchTerm,
    categoryName: filter.category,
    locationId: filter.location,
    status: filter.status,
    month: filter.month
      ? toISODateOnly(setMonth(new Date(), Number(filter.month) - 1))
      : null,
    year: filter.year
      ? toISODateOnly(startOfYear(parseDate(filter.year)))
      : null,
  };
};
