import React from 'react';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { MOBILE_BREAKPOINT } from './common';
import { Col } from 'react-bootstrap';
import Button from '../../../../components/button';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

const i18Path = 'absences.cards.pendingAbsenceRequests.actions';

type Props = {
  id: string;
  isAccepting: (id: string) => boolean;
  isRejecting: (id: string) => boolean;
  isInProgress: (id: string) => boolean;
  handleRejectButtonClick: (id: string) => void;
  handleAccept: (id: string) => void;
};
export const ReviewActionsCell: React.FC<Props> = ({
  id,
  isAccepting,
  isRejecting,
  isInProgress,
  handleRejectButtonClick,
  handleAccept,
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;
  const t = useNamespacedTranslation(i18Path);

  return (
    <>
      {isMobile ? (
        <Col className="col-auto">
          <Button
            isProcessing={isAccepting(id)}
            disabled={isInProgress(id)}
            variant="outline-primary"
            size="sm"
            icon="checkmark-outline"
            onClick={(e) => {
              e.preventDefault();
              handleAccept(id);
            }}>
            <FlairIcon icon="checkmark-outline" />
          </Button>
          <Button
            icon="close-outline"
            variant="outline-danger"
            className="ms-2"
            size="sm"
            isProcessing={isRejecting(id)}
            disabled={isInProgress(id)}
            onClick={(e) => {
              e.preventDefault();
              handleRejectButtonClick(id);
            }}>
            <FlairIcon icon="close-outline" />
          </Button>
        </Col>
      ) : (
        <Col className="col-auto">
          <Button
            label={t('accept')}
            isProcessing={isAccepting(id)}
            disabled={isInProgress(id)}
            variant="outline-primary"
            size="sm"
            icon="checkmark-outline"
            onClick={(e) => {
              e.preventDefault();
              handleAccept(id);
            }}></Button>
          <Button
            label={t('reject')}
            isProcessing={isRejecting(id)}
            disabled={isInProgress(id)}
            icon="close-outline"
            variant="outline-danger"
            className="ms-2"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              handleRejectButtonClick(id);
            }}></Button>
        </Col>
      )}
    </>
  );
};
