import { useState, useEffect } from 'react';

function getWidth() {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
}

export function useWindowWidth() {
  const [width, setWidth] = useState(() => getWidth());
  useEffect(() => {
    function handler() {
      setWidth(getWidth());
    }
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [setWidth]);
  return width;
}
