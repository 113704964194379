import {
  GoalEmployee,
  GoalKeyResultType,
  GoalRate,
  GoalRateInfo,
  GoalStatus,
  GoalType,
  GoalVisibility,
} from './types';
import i18next from 'i18next';
import {
  formatDateLong,
  formatDateShort,
  parseDate,
} from '../../../../../utils/dateUtils';

const i18prefix = 'performanceReview.goals2';

export const getStatusStr = (goalStatus: GoalStatus): string =>
  i18next.t(`${i18prefix}.goalStatus.${goalStatus}`);

export const getGoalTypeStr = (goalType: GoalType): string =>
  i18next.t(`${i18prefix}.goalType.${goalType}`);

export const getVisibilityStr = (goalVisibility: GoalVisibility): string =>
  i18next.t(`${i18prefix}.goalVisbility.${goalVisibility}`);

export const getVisibilityOptionStr = (
  goalVisibility: GoalVisibility,
): string => i18next.t(`${i18prefix}.goalVisbilityOption.${goalVisibility}`);

export const getGoalRateStr = (goalRate: GoalRate): string =>
  i18next.t(`${i18prefix}.goalRate.${goalRate}`);

export const getRateInfoStr = (rateInfo: GoalRateInfo): string => {
  const rate = rateInfo.rate;
  if (rate === 'OPEN') {
    return i18next.t(`${i18prefix}.goalRateInfo.pending`);
  } else {
    return i18next.t(`${i18prefix}.goalRateInfo.ratedBy`, {
      name: rateInfo.rateEmployee?.name,
      ratedAt: rateInfo.ratedAt ? formatDateLong(rateInfo.ratedAt) : '-',
    });
  }
};

export const getCancelInfoStr = (
  cancelReason: string,
  statusChangedBy: GoalEmployee,
): string => {
  return i18next.t(`${i18prefix}.cancelReason`, {
    employee: statusChangedBy.name,
    cancelReason,
  });
};

export const getKeyResultCurrentValueStr = (
  type: GoalKeyResultType,
  current: number,
  target: number,
): string => {
  switch (type) {
    case 'NUMERIC':
      return `${current}/${target}`;
    case 'PERCENTAGE':
      return `${current}%/${target}%`;
    case 'BOOLEAN':
      return getKeyResultBooleanValue(current);
  }
};

export const getKeyResultBooleanValue = (value: number): string => {
  if (value === 0) {
    return i18next.t(`${i18prefix}.keyResultBooleanValue.negative`);
  } else {
    return i18next.t(`${i18prefix}.keyResultBooleanValue.positive`);
  }
};

export const getGoalPeriodStr = (
  startDate: string,
  endDate: string,
): string => {
  return i18next.t(`${i18prefix}.goalPeriodStr`, {
    start: formatDateShort(parseDate(startDate)),
    end: formatDateShort(parseDate(endDate)),
    interpolation: { escapeValue: false },
  });
};
