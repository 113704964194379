import React from 'react';
import PageHeader from '../../components/PageHeader';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { recruitmentRoutes } from '../routes';
import Evaluations from '../Evaluations';
import { useUserInfo } from '../../context/UserInfo';
import { useEvaluationsQuery } from '../../__generated__/graphql';
import { Container } from 'react-bootstrap';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

const i18Path = 'navigation.menuItems.recruitment';

const EmployeeEvaluations: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const title = t('subMenuItems.myEvaluations.title');
  const { id } = useUserInfo();
  const { data, loading, error } = useEvaluationsQuery({
    variables: {
      filter: {
        evaluatorId: id,
        status: [],
        jobId: null,
        managerId: null,
      },
    },
  });

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  return (
    <Container>
      <PageHeader title={title} />
      <Evaluations
        data={data?.evaluations}
        route={recruitmentRoutes.employeeEvaluations.route}
        storageKey="EMPLOYEE_EVALUATIONS_LIST_FILTERS"
        error={error}
        loading={loading}
      />
    </Container>
  );
};

export default EmployeeEvaluations;
