import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InventoryModalContext } from '../InventoryModalContext';
import { WarrantyInfoFormField } from './FormFields/WarrantyInfoFormField';
import { WarrantyDateFormField } from './FormFields/WarrantyDateFormField';

export const WarrantyForm = () => {
  const { isExpanded = false } = useContext(InventoryModalContext);

  return (
    <Row>
      <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
        <WarrantyInfoFormField />
      </Col>
      <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
        <WarrantyDateFormField />
      </Col>
    </Row>
  );
};
