import { parseISO } from 'date-fns';
import { toISODateOnly } from '../../../../../utils/dateUtils';
import { invertMap } from '../../../../../utils/map';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';
import { getEmployeeInitials } from '../../../utils/employeeInitials';
import {
  CompanyObjectiveFragment,
  EmployeeObjectiveColleagueFragment,
  EmployeeObjectiveFragment,
  KeyResultFragment,
  ObjectiveIdInput,
  ObjectiveKeyResultType,
  ObjectiveTimePeriodFragment,
  ObjectiveVisibility,
  ObjectiveType,
  TagFragment,
  TeamObjectiveFragment,
  EmployeeObjectiveRateType,
  ObjectiveCurrentStatusType,
  EmployeeObjectiveEmployeeFragment,
} from '../../../__generated__/graphql';
import { GoalStatusEx } from './GoalSidebar/types';
import {
  GoalBase,
  GoalEmployee,
  GoalId,
  GoalKeyResult,
  GoalKeyResultType,
  GoalPeriod,
  GoalRate,
  GoalRateInfo,
  GoalStatus,
  GoalTag,
  GoalType,
  GoalVisibility,
} from './types';

const visibilityMap: Map<ObjectiveVisibility, GoalVisibility> = new Map([
  [ObjectiveVisibility.AllTheCompany, 'public'],
  [ObjectiveVisibility.ManagementAndHr, 'manager'],
  [ObjectiveVisibility.ProtectedItem, 'protected'],
]);

const visibilityInvertedMap: Map<GoalVisibility, ObjectiveVisibility> =
  invertMap(visibilityMap);

export const mapGoals = (
  objectives:
    | Readonly<
        Array<
          | TeamObjectiveFragment
          | EmployeeObjectiveFragment
          | CompanyObjectiveFragment
        >
      >
    | undefined,
): GoalBase[] | undefined => {
  if (objectives === undefined) {
    return undefined;
  }
  return objectives.map(mapGoal);
};

export const mapGoal = (
  src:
    | TeamObjectiveFragment
    | EmployeeObjectiveFragment
    | CompanyObjectiveFragment,
): GoalBase => {
  const type = mapGoalType(src.__typename!);
  return {
    id: src.Id,
    type,
    name: src.Name,
    dueDate: src.flair__Due_Date__c
      ? toISODateOnly(parseISO(src.flair__Due_Date__c))
      : null,
    status: mapStatus(src.flair__Current_Status__c),
    visiblity: mapVisibility(src.flair__Visibility__c),
    sharedWith:
      src.flair__Visibility__c === ObjectiveVisibility.ProtectedItem
        ? src.sharedWith.map(mapEmployee)
        : undefined,
    keyResults:
      src.__typename !== 'CompanyObjective'
        ? src.keyResults?.map(mapKeyResult)
        : [],
    progress: src.flair__Progress__c,
    expectedProgress: src.flair__Expected_Progress__c,
    employee:
      src.__typename === 'EmployeeObjective' && src.responsible
        ? mapEmployee(src.responsible)
        : undefined,
    period: src.period ? mapGoalPeriod(src.period) : undefined,
    tags: src.tags.map(mapTag),
    cancelReason: src.flair__Status_Reason__c ?? undefined,
    statusChangedBy: src.statusChangedBy
      ? mapEmployee(src.statusChangedBy)
      : undefined,
    ...mapRateInfo(src),
  };
};

export const mapKeyResult = (src: KeyResultFragment): GoalKeyResult => ({
  id: src.Id,
  name: src.Name,
  type: mapKeyResultType(src.flair__Type__c),
  initialValue: src.flair__Initial_Value__c,
  targetValue: src.flair__Target_Value__c,
  currentValue: src.flair__Current_Value__c,
  progress: src.flair__Progress__c,
});

export const mapGoalType = (srcType: string): GoalType => {
  switch (srcType) {
    case 'CompanyObjective':
      return 'company';
    case 'TeamObjective':
      return 'team';
    case 'EmployeeObjective':
      return 'employee';
    default:
      throw new Error(`Unknown type ${srcType}`);
  }
};

const mapTag = (src: TagFragment): GoalTag => ({
  id: src.Id,
  name: src.Name,
});

export const mapKeyResultType = (
  srcType: ObjectiveKeyResultType,
): GoalKeyResultType => {
  if (srcType === ObjectiveKeyResultType.Unknown) {
    throw new Error('Invalid key result type');
  }
  return srcType as GoalKeyResultType;
};

export const fromKeyResultType = (
  src: GoalKeyResultType,
): ObjectiveKeyResultType => src as ObjectiveKeyResultType;

export const mapStatus = (
  srcStatus: ObjectiveCurrentStatusType,
): GoalStatus => {
  if (srcStatus === ObjectiveCurrentStatusType.Unknown) {
    return 'ON_TRACK';
  }
  return srcStatus as GoalStatus;
};

export const fromStatus = (src: GoalStatusEx): ObjectiveCurrentStatusType => {
  if (src === 'BEHIND_OR_ON_TRACK') {
    // BEHIND_OR_ON_TRACK means we can not control that state, it is controlled by SF.
    // So we just pass either ON_TRACK either BEHIND;
    // It could be ON_TRACK as weel
    return ObjectiveCurrentStatusType.Behind;
  }
  return src as ObjectiveCurrentStatusType;
};

export const mapRate = (srcRate: EmployeeObjectiveRateType): GoalRate => {
  if (srcRate === EmployeeObjectiveRateType.Unknown) {
    throw new Error('Invalid goal rate');
  }
  return srcRate as GoalRate;
};

export const fromRate = (src: GoalRate): EmployeeObjectiveRateType =>
  src as EmployeeObjectiveRateType;

export const mapVisibility = (src: ObjectiveVisibility): GoalVisibility => {
  return visibilityMap.get(src) ?? 'public';
};

export const fromVisibility = (src: GoalVisibility): ObjectiveVisibility => {
  const result = visibilityInvertedMap.get(src);
  if (!result) {
    throw new Error('Invalid mapping');
  }
  return result;
};

const mapGoalPeriod = (src: ObjectiveTimePeriodFragment): GoalPeriod => ({
  id: src.Id,
  name: src.Name,
  startDate: src.flair__Start_Date__c,
  endDate: src.flair__End_Date__c,
});

export const mapEmployee = (
  src: EmployeeObjectiveColleagueFragment,
): GoalEmployee => ({
  id: src.Id,
  name: src.Name,
  initials: getEmployeeInitials(src),
  avatarUrl: src.avatar?.url ?? undefined,
});

export const toObjectiveIdInput = (src: GoalId): ObjectiveIdInput => ({
  id: src.id,
  type: toObjectiveType(src.type),
});

const toObjectiveType = (goalType: GoalType): ObjectiveType => {
  switch (goalType) {
    case 'company':
      return ObjectiveType.Company;
    case 'team':
      return ObjectiveType.Team;
    case 'employee':
      return ObjectiveType.Employee;
  }
};

const mapRateInfo = (
  src:
    | TeamObjectiveFragment
    | EmployeeObjectiveFragment
    | CompanyObjectiveFragment,
): GoalRateInfo => {
  if (
    src.__typename === 'EmployeeObjective' ||
    src.__typename === 'TeamObjective'
  ) {
    return {
      rate: src.flair__Rate__c ? mapRate(src.flair__Rate__c) : 'OPEN',
      rateEmployee: src.rateBy ? mapEmployee(src.rateBy) : undefined,
      ratedAt: src.flair__RateAt__c
        ? parseISO(src.flair__RateAt__c)
        : undefined,
    };
  }
  return {
    rate: 'OPEN',
  };
};

export const mapEmployeeOption = (
  src: EmployeeObjectiveColleagueFragment | EmployeeObjectiveEmployeeFragment,
): EmployeeOption => ({
  label: src.Name,
  value: src.Id,
  initials: getEmployeeInitials(src),
});
