import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Theme } from '../../../../../theme';

type BarChartProps = {
  seriesName: string;
  seriesData: number[];
  categories: string[];
  height?: number;
  chartTitle?: string;
  dataFormatter?: (value: number) => string;
};

const DOWNLOAD_FILE_NAME = 'flair-report';

const BarChart: React.FC<BarChartProps> = (props) => {
  const {
    seriesData,
    seriesName,
    categories,
    dataFormatter,
    chartTitle,
    height,
  } = props;

  const options: ApexOptions = {
    series: [
      {
        name: seriesName,
        data: seriesData,
      },
    ],
    chart: {
      height: height ?? 'auto',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 5,
        offsetY: -5,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: DOWNLOAD_FILE_NAME,
          },
          svg: {
            filename: DOWNLOAD_FILE_NAME,
          },
          png: {
            filename: DOWNLOAD_FILE_NAME,
          },
        },
      },
      animations: {
        speed: 400,
        easing: 'linear',
        dynamicAnimation: {
          speed: 400,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return dataFormatter ? dataFormatter(value) : value.toString();
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return dataFormatter ? dataFormatter(value) : value.toString();
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return dataFormatter
          ? dataFormatter(value as number)
          : value.toString();
      },
      offsetX: 0,
    },
    title: {
      text: chartTitle,
      align: 'left',
    },
    colors: [Theme.color.teal2],
    xaxis: {
      categories: categories,
    },
  };

  return (
    <div className="m-4">
      <ReactApexChart
        options={options}
        series={options.series}
        type={'bar'}
        height={height}
      />
    </div>
  );
};

export default BarChart;
