import React, { useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import CardHeader from '../../components/CardHeader';
import { DropdownActionItem } from '../../../../atomic/molecules/DropdownActionItem';
import {
  AbsenceApprovalStatus,
  MyAbsencesQuery,
  useDeleteUpcomingAbsenceMutation,
} from '../../__generated__/graphql';
import AbsenceAmount from './components/AbsenceAmount';
import AbsenceDateTime from './components/AbsenceDateTime';
import AbsenceStatus from './components/AbsenceStatus';
import { useMyAbsencesRefetchOption } from './useMyAbsencesRefetchOption';
import AbsenceIcon from './components/AbsenceIcon';
import CommentsPopover from '../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../components/Comment/types';
import { DropdownActions } from '../../../../atomic/templates/DropdownActions';

type Props = {
  absences: MyAbsencesQuery['me']['upcomingAbsences'] | undefined;
};

const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border spinner-border-sm" role="status"></div>
  </div>
);

const canDeleteAbsence = (
  absence: MyAbsencesQuery['me']['upcomingAbsences'][0],
) =>
  (absence.flair__Category_Restrict_Absence_Deletion__c &&
    absence.flair__Approval_Status__c !== AbsenceApprovalStatus.Approved) ||
  !absence.flair__Category_Restrict_Absence_Deletion__c;

export const UpcomingAbsencesCard: React.FC<Props> = ({ absences = [] }) => {
  const [deletingAbsence, setDeletingAbsence] = useState<string>('');
  const { t } = useTranslation();
  const { addSuccess } = useToasts();
  const myAbsencesRefetchQuery = useMyAbsencesRefetchOption();
  const [deleteUpcomingAbsence, { loading }] = useDeleteUpcomingAbsenceMutation(
    {
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [myAbsencesRefetchQuery],
    },
  );
  const errorHandler = useMutationErrorHandler(() =>
    t('absences.cards.upcomingAbsences.delete.failure'),
  );

  const isDeleting = (id: string): boolean => loading && deletingAbsence === id;

  const hasAbsences = absences && absences.length > 0;

  const handleDelete = (id: string) => {
    setDeletingAbsence(id);
    deleteUpcomingAbsence({
      variables: { id },
    })
      .then(() =>
        addSuccess(t('absences.cards.upcomingAbsences.delete.success')),
      )
      .catch(errorHandler)
      .finally(() => setDeletingAbsence(''));
  };

  return (
    <Card>
      <CardHeader>{t('absences.cards.upcomingAbsences.title')}</CardHeader>
      <Card.Body>
        <ListGroup variant="flush" className="my-n4">
          {hasAbsences ? (
            absences.map((absence) => {
              const rejectedRequest = absence.approvalRequests?.find(
                (req) => req.flair__Approval_Status__c === 'REJECTED',
              );
              const rejectionComment =
                rejectedRequest?.flair__Approver_Comment__c ?? null;
              return (
                <ListGroup.Item key={absence.Id}>
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <AbsenceIcon icon={absence.flair__Category_Icon__c} />
                    </Col>
                    <Col>
                      <h4>
                        <AbsenceDateTime
                          date={absence.flair__Start_Date__c}
                          time={absence.flair__Start_Time__c}
                          type={absence.flair__Type__c}
                          halfDay={absence.flair__Start_Date_Half_Day__c}
                        />
                        &nbsp;-&nbsp;
                        <AbsenceDateTime
                          date={absence.flair__End_Date__c}
                          time={absence.flair__End_Time__c}
                          type={absence.flair__Type__c}
                          halfDay={absence.flair__End_Date_Half_Day__c}
                        />
                      </h4>

                      <div className="card-text small text-dark mb-1">
                        <AbsenceStatus
                          status={absence.flair__Approval_Status__c}
                        />
                        {rejectionComment && (
                          <div className="bg-secondary-soft border my-1 py-1 px-3 rounded-1">
                            {rejectionComment}
                          </div>
                        )}
                        &nbsp;&#xB7;&nbsp;
                        {absence.flair__Category_Name__c}&nbsp;&#xB7;&nbsp;
                        <AbsenceAmount
                          type={absence.flair__Type__c}
                          amount={absence.flair__Working_Amount__c}
                          variant="work"
                        />
                        &nbsp;&#xB7;&nbsp;
                        <AbsenceAmount
                          type={absence.flair__Type__c}
                          amount={absence.flair__Amount__c}
                          variant="calendar"
                        />
                      </div>
                    </Col>
                    <Col className="col-auto">
                      <CommentsPopover
                        recordId={absence.Id}
                        relatedObjectName={RelatedObjectNames.Absence}
                        commentsCount={absence.commentsCount}
                        mode="icon"
                      />
                    </Col>
                    {canDeleteAbsence(absence) && (
                      <Col className="col-auto">
                        {isDeleting(absence.Id) ? (
                          <Loading />
                        ) : (
                          <DropdownActions id={`${absence.Id}-actions`}>
                            <DropdownActionItem
                              onClick={() => {
                                handleDelete(absence.Id);
                              }}
                              disabled={loading}>
                              {t(
                                'absences.cards.upcomingAbsences.actions.delete',
                              )}
                            </DropdownActionItem>
                          </DropdownActions>
                        )}
                      </Col>
                    )}
                  </Row>
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item>
              <Row className="align-items-center justify-content-center ">
                <Col className="col-auto">
                  <p className="text-justify mb-0">
                    {t('absences.cards.upcomingAbsences.emptyState')}
                  </p>
                </Col>
              </Row>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
