import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ServerError from '../../../../../components/ServerError';
import { useState } from 'react';
import WidgetFilter from '../CommonComponents/WidgetFilter';
import BirthdaysModal from './BirthdaysModal';
import { getFilterOption } from '../CommonComponents/WidgetFilter/logic';
import {
  SectionCategory,
  SectionType,
} from '../CommonComponents/WidgetSections/types';
import Sections from '../CommonComponents/WidgetSections/Sections';
import useBirthdaysData from './logic';
import _ from 'lodash';
import { mapBirthday } from './mappings';
import Button from '../../../../../components/button';

const i18Path = 'dashboard.birthdaysWidget';

const BirthdaysWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [modalVisible, setIsModalVisible] = useState<boolean>(false);
  const today = new Date().toISOString().split('T')[0];

  const { data, loading, error, filterBy, handleChangeFilter } =
    useBirthdaysData();

  if (error) {
    return <ServerError />;
  }

  const colleaguesBirthdays =
    data?.me.birthdaysColleagues
      .filter((x) => x.flair__Birthday_this_year__c !== null)
      .map(mapBirthday) ?? [];

  const [todayBirthdays, upcomingBirthdays] = _.partition(
    colleaguesBirthdays,
    (i) => i.date.toISOString().split('T')[0] === today,
  );

  const sections = [
    {
      key: SectionCategory.TODAY,
      label: t('today'),
      items: todayBirthdays,
    },
    {
      key: SectionCategory.UPCOMING,
      label: t('upcoming'),
      items: upcomingBirthdays,
    },
  ].filter((i) => i.items.length > 0);

  const headerActions = (
    <WidgetFilter
      selectedFilter={getFilterOption(filterBy)}
      onChange={handleChangeFilter}
    />
  );

  const footerActions = (
    <Button
      className="p-0"
      label={t('seeMore')}
      variant="link"
      onClick={() => setIsModalVisible(true)}
    />
  );

  return (
    <>
      <FlairCard
        cardTitle={t('title')}
        cardContainerClassName="birthdays-widget"
        footerActions={footerActions}
        headerActions={headerActions}>
        <Sections
          sections={sections}
          type={SectionType.BIRTHDAYS}
          emptyStateTitle={t('title')}
          loading={loading}
        />
      </FlairCard>
      {modalVisible && (
        <BirthdaysModal
          onClose={() => setIsModalVisible(false)}
          filterBy={filterBy}
        />
      )}
    </>
  );
};

export default BirthdaysWidget;
