import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BreakingRulesInfoPanel } from '../../components/AutoBreaks/breakingRulesInfo/BreakingRulesInfoPanel';
import CurrentTimeTracking from './CurrentTimeTracking';
import MyTimeSheet from './MyTimeSheet';

export const TimeTrackingTimeSheetPage: React.FC = () => (
  <Row>
    <Col lg={4}>
      <CurrentTimeTracking />
      <BreakingRulesInfoPanel />
    </Col>
    <Col>
      <MyTimeSheet />
    </Col>
  </Row>
);
