import React from 'react';
import styled from '@emotion/styled';
import { InventoryItem, InventoryStatus } from '../../types';
import { InventoryElectronicDeviceFields } from './InventoryElectronicDeviceFields';
import {
  mapInventoryElectronicDevice,
  mapInventoryOfficeEquipment,
  mapInventoryOther,
} from '../mappings';
import { InventoryOtherFields } from './InventoryOtherFields';
import { InventoryOfficeEquipmentFields } from './InventoryOfficeEquipmentFields';
import { useUpdateInventoryItemStatus } from '../../hooks/useUpdateInventoryItemStatus';

type Props = {
  inventoryItem: InventoryItem;
  isManager: boolean;
};
export const InventoryInformationTab: React.FC<Props> = ({
  inventoryItem,
  isManager,
}) => {
  const { updateStatus, loading } = useUpdateInventoryItemStatus();

  const handleUpdateInventoryItemStatus = (status: InventoryStatus) => {
    updateStatus(inventoryItem.id, status);
  };
  const renderContent = () => {
    switch (inventoryItem.category.name) {
      case 'Electronic Device':
        return (
          <InventoryElectronicDeviceFields
            isManager={isManager}
            isStatusUpdateLoading={loading}
            handleStatusUpdate={handleUpdateInventoryItemStatus}
            inventoryItem={mapInventoryElectronicDevice(inventoryItem)}
          />
        );
      case 'Office Equipment':
        return (
          <InventoryOfficeEquipmentFields
            isManager={isManager}
            isStatusUpdateLoading={loading}
            handleStatusUpdate={handleUpdateInventoryItemStatus}
            inventoryItem={mapInventoryOfficeEquipment(inventoryItem)}
          />
        );
      case 'Other':
        return (
          <InventoryOtherFields
            isManager={isManager}
            isStatusUpdateLoading={false}
            handleStatusUpdate={handleUpdateInventoryItemStatus}
            inventoryItem={mapInventoryOther(inventoryItem)}
          />
        );
      default:
        return (
          <InventoryOtherFields
            isManager={isManager}
            isStatusUpdateLoading={false}
            handleStatusUpdate={handleUpdateInventoryItemStatus}
            inventoryItem={mapInventoryOther(inventoryItem)}
          />
        );
    }
  };
  return <StyledInfoWrapper>{renderContent()}</StyledInfoWrapper>;
};

const StyledInfoWrapper = styled.div`
  overflow-y: auto;
  max-height: 50rem;
`;
