import { TimeFrameworkFragment } from '../../../__generated__/graphql';
import { mapBreakLegislationRule } from '../mappings';
import { BreakingRulesInfo } from '../shared';
import { orderBy } from 'lodash';

export const mapBreakingRulesInfo = (
  timeFramework: TimeFrameworkFragment,
): BreakingRulesInfo => ({
  minBreakDuration: timeFramework.flair__Minimal_Break_Length__c,
  legislationRules: orderBy(
    timeFramework.breakLegislationRules.map(mapBreakLegislationRule),
    (x) => x.workingTime,
  ),
  deductBreaks: timeFramework.flair__Automatically_Deduct_Unused_Break__c,
});
