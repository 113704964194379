import styled from '@emotion/styled';
import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  title: string;
  onClick: () => void;
  disabled: boolean;
  isProcessing?: boolean;
};

const StyledButton = styled(Button)({
  border: 'none',
  backgroundColor: 'transparent',
});

const CancelButton: React.FC<Props> = ({
  title,
  disabled,
  isProcessing,
  onClick,
}) => {
  return (
    <StyledButton
      type="button"
      size="sm"
      variant="light"
      className="ms-2 d-flex align-items-center"
      disabled={disabled}
      onClick={onClick}>
      {isProcessing && (
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"></span>
      )}
      <span>{title}</span>
    </StyledButton>
  );
};

export default CancelButton;
