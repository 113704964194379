import React, { ReactNode } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import InputError from '../InputError';
import { getError } from '../utils';
import { InputWithSaveBase } from './InputWithSaveBase';

type Props = InputProps | TextAreaProps | NumberInputProps;

type InputProps = Omit<FormControlProps, 'style'> & {
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  labelComponent?: ReactNode;
  value: string;
  isInEditModeInitial?: boolean;
  readOnly?: boolean;
  onChange: (value: string | number | undefined) => void;
};

type NumberInputProps = InputProps & {
  type: 'number';
  value: number | undefined;
};

type TextAreaProps = InputProps &
  TextareaAutosizeProps & {
    type: 'textarea';
  };

export const InputWithSave: React.FC<Props> = ({
  type = 'text',
  isInEditModeInitial,
  className,
  containerClassName,
  labelComponent,
  value,
  onChange,
  ...restProps
}) => {
  return (
    <InputWithSaveBase
      containerClassName={containerClassName}
      value={value}
      labelComponent={labelComponent}
      onChange={onChange}
      readOnly={restProps.readOnly}
      disabled={restProps.disabled}
      type={type}
      renderInput={(inputProps) => {
        return type === 'textarea' ? (
          <TextareaAutosize
            {...restProps}
            disabled={inputProps.disabled}
            readOnly={inputProps.readOnly}
            value={inputProps.value}
            onChange={(e) => inputProps.onChange(e.target.value)}
            className={['form-control', inputProps.className].join(' ')}
          />
        ) : (
          <Form.Control
            {...restProps}
            type={type}
            disabled={inputProps.disabled}
            readOnly={inputProps.readOnly}
            value={inputProps.value}
            onChange={(e) => inputProps.onChange(e.target.value)}
          />
        );
      }}
    />
  );
};

type PropsControlled = { name: string } & Omit<Props, 'value' | 'onChange'>;

export const InputWithSaveControlled: React.FC<PropsControlled> = ({
  name,
  isInEditModeInitial,
  ...props
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);
  return (
    <>
      <Controller
        as={({ value, onChange }) => {
          return (
            <InputWithSave
              {...props}
              value={value}
              onChange={onChange}
              isInEditModeInitial={isInEditModeInitial || !!error}
              containerClassName={error ? 'is-invalid' : ''}
              className={error ? 'is-invalid' : ''}
            />
          );
        }}
        name={name}
        control={control}
      />
      <InputError error={error} />
    </>
  );
};
