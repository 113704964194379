import {
  EmployeePerformanceReviewMeetingComparedFeedbackPageQuery,
  MyPastPerformanceReviewDetailsQuery,
  PastEmployeeFeedbackDetailsQuery,
  PerformanceEmployeeEvaluationQuery,
} from '../../../../__generated__/graphql';
import { Maybe } from '../../../../../../utils/maybe';
import { mapPeerFeedbacks } from '../EmployeeEvaluation/mapping';

export const usePeerReviewsHook = (
  feedback: Maybe<
    | PerformanceEmployeeEvaluationQuery['employeeFeedback']
    | EmployeePerformanceReviewMeetingComparedFeedbackPageQuery['employeeFeedback']
    | PastEmployeeFeedbackDetailsQuery['employeeFeedback']
    | MyPastPerformanceReviewDetailsQuery['me']['feedback']
  >,
) => {
  if (!feedback) {
    return {
      isPeerReviewsFeatureEnabled: false,
      isPeerReviewsEnabled: false,
      isPeerReviewer: false,
      peerFeedbacks: [],
    };
  }

  const isPeerReviewer = feedback.flair__Is_Peer_Reviewer__c;

  const isPeerReviewsEnabled =
    feedback.feedbackCycle.flair__With_Peers__c &&
    !isPeerReviewer &&
    !feedback.flair__Self_Evaluation__c;

  const hasAnyNonFinishedPeerFeedback = feedback.peerFeedbacks.some(
    (p) => !p.flair__Submitted_At__c,
  );

  const peerFeedbacks = mapPeerFeedbacks(feedback.peerFeedbacks);

  return {
    isPeerReviewsEnabled,
    isPeerReviewer,
    hasAnyNonFinishedPeerFeedback,
    peerFeedbacks,
  };
};
