import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import Button from '../../../../components/button';
import styled from '@emotion/styled';
import { ApproveTimeSheetAction } from '../../__generated__/graphql';

type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: (action: ApproveTimeSheetAction) => void;
};

const i18Path = 'components.timeSheetApproveModal';

export const TimeSheetApproveModal = ({
  visible,
  onClose,
  onSubmit,
}: Props) => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      className="confirm-modal"
      contentClassName="border"
      backdropClassName="confirm-modal-backdrop">
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
        <CloseBtn role="button" icon="close-outline" onClick={onClose} />
        <Modal.Body className="pb-0">
          <div className="d-flex justify-content-center flex-column align-items-center gap-3">
            <div className="d-flex icon-container justify-content-center p-3 align-items-center">
              <FlairIcon icon="alert-outline" />
            </div>
            <h2 className="card-header-title">{t(`title`)}</h2>
            <div>{t(`text`)}</div>
          </div>
        </Modal.Body>
        <ModalFooter className="border-top-0 pt-0 d-flex flex-row flex-nowrap gap-3">
          <Button
            variant="outline-primary"
            onClick={() =>
              onSubmit(ApproveTimeSheetAction.RejectAllChangeRequests)
            }
            label={t('btnReject')}
          />
          <Button
            onClick={() =>
              onSubmit(ApproveTimeSheetAction.ApproveAllChangeRequests)
            }
            variant="primary"
            label={t('btnApprove')}
          />
        </ModalFooter>
      </div>
    </Modal>
  );
};

const CloseBtn = styled(FlairIcon)({
  position: 'absolute',
  right: '0.5rem',
  top: '0.5rem',
});
