import { useCallback } from 'react';
import { Schema, ValidationError } from 'yup';
import { ValidationResolver } from 'react-hook-form';

const useYupValidationResolver = (
  validationSchema: Schema<any>,
  context?: object,
): ValidationResolver<any, any> =>
  useCallback(
    (data) =>
      validationSchema
        .validate(data, {
          abortEarly: false,
          context,
        })
        .then((values) => ({ values, errors: {} }))
        .catch((errors: ValidationError) => ({
          values: {},
          errors:
            errors.inner?.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {},
            ) ?? {},
        })),
    [validationSchema, context],
  );
export default useYupValidationResolver;
