import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Checkbox from '../../../components/Checkbox';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import { createWorkflowItemStatusOptions, createYearOptions } from './mappings';
import {
  emptyWorkflowItemFilterForOthers,
  Option,
  WorkflowItemFilterAllOptions,
  WorkflowItemFilterForOthers,
} from './types';
import WorkflowTableMandatoryHintText from './WorkflowTableMandatoryHintText';

export type Props = {
  workflowTemplateOptions?: Option[];
  workflowItemTemplateOptions?: Option[];
  responsibleOptions: Option[];
  employeeOptions?: Option[];
  filter: WorkflowItemFilterAllOptions;
  onChange: (filter: WorkflowItemFilterAllOptions) => void;
  onlyMyTasksVisible?: boolean;
  yearVisible?: boolean;
};

export const WorkflowItemsFilter: React.FC<Props> = ({
  filter,
  yearVisible,
  onChange,
  workflowItemTemplateOptions,
  workflowTemplateOptions,
  responsibleOptions,
  employeeOptions,
  onlyMyTasksVisible,
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  const yearOptions = createYearOptions(new Date());

  const handleWorkflowItemTemplateIdChanged = useCallback(
    (value: string | null) => {
      return handleFilterChange(
        value,
        filter,
        onChange,
        'workflowItemTemplateId',
      );
    },
    [filter, onChange],
  );

  const handleWorkflowTemplateIdChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'workflowTemplateId'),
    [filter, onChange],
  );

  const handleEmployeeChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'employeeId'),
    [filter, onChange],
  );

  const handleResponsibleChanged = useCallback(
    (responsibleId: string | null) => {
      onChange({
        ...filter,
        // drop onlyMyTasks if we set responsibleId
        responsibleId,
        onlyMyTasks: responsibleId !== null ? 'false' : filter.onlyMyTasks,
      });
    },
    [filter, onChange],
  );

  const handleYearChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  const handleStatusChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );

  const handleClearFilter = useCallback(() => {
    onChange(emptyWorkflowItemFilterForOthers);
  }, [onChange]);

  const handleOnlyMyTasksChanged = useCallback(
    (e) => {
      onChange({
        ...filter,
        // drop responsibleId in case we set "onlyMyTasks"
        responsibleId: e.target.checked ? null : filter.responsibleId,
        onlyMyTasks: e.target.checked ? 'true' : 'false',
      });
    },
    [filter, onChange],
  );

  return (
    <>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        <span className="d-none d-md-block">{t('filterBy')}</span>
        {workflowItemTemplateOptions && (
          <SingleFilterStyled
            placeholder={t('task')}
            isSearchable={true}
            options={workflowItemTemplateOptions}
            value={filter.workflowItemTemplateId}
            onChange={handleWorkflowItemTemplateIdChanged}
          />
        )}
        {workflowTemplateOptions && (
          <SingleFilterStyled
            placeholder={t('workflowTemplate')}
            isSearchable={true}
            options={workflowTemplateOptions}
            value={filter.workflowTemplateId}
            onChange={handleWorkflowTemplateIdChanged}
          />
        )}
        {employeeOptions && (
          <SingleFilterStyled
            placeholder={t('employee')}
            isSearchable={true}
            options={employeeOptions}
            value={filter.employeeId}
            onChange={handleEmployeeChanged}
          />
        )}

        <SingleFilterStyled
          placeholder={t('responsible')}
          isSearchable={true}
          options={responsibleOptions}
          value={filter.responsibleId}
          onChange={handleResponsibleChanged}
        />
        {yearVisible && (
          <SingleFilterStyled
            placeholder={t('year')}
            options={yearOptions}
            value={filter.year}
            onChange={handleYearChanged}
          />
        )}
        <SingleFilterStyled
          placeholder={t('status')}
          options={createWorkflowItemStatusOptions()}
          value={filter.status}
          onChange={handleStatusChanged}
        />

        {onlyMyTasksVisible && (
          <Checkbox
            id="onlyMyTasksVisible"
            labelClassName="align-middle"
            label={t('onlyMyTasks')}
            checked={filter.onlyMyTasks === 'true'}
            onChange={handleOnlyMyTasksChanged}
          />
        )}
        <ClearFiltersLink onClick={handleClearFilter} />
      </div>
      <WorkflowTableMandatoryHintText></WorkflowTableMandatoryHintText>
    </>
  );
};

const SingleFilterStyled = styled(SingleFilter)({
  maxWidth: '200px',
});

const handleFilterChange = (
  value: string | null,
  filter: WorkflowItemFilterForOthers,
  onChange: (filter: WorkflowItemFilterForOthers) => void,
  filterKey: keyof WorkflowItemFilterForOthers,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
