import React, { useCallback, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/button';
import ConfirmButton from '../../../../../../../components/button/ConfirmButton';
import { useToasts } from '../../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../../hooks/useMutationErrorHandler';
import {
  ManagerEmployeeReviewsDocument,
  useChangeReviewerMutation,
} from '../../../../../__generated__/graphql';
import EmployeeInput from '../../../../../components/EmployeeInput';
import styled from '@emotion/styled';

type Props = {
  participantId: string;
  employeeId: string;
  reviewerId: string;
};

type PopoverLayoutProps = {
  title: string;
  isProcessing: boolean;
  show: boolean;
  onToggle: () => void;
};

const StyledDiv = styled('div')({
  width: '300px',
});

const PopoverLayout: React.FC<PopoverLayoutProps> = ({
  title,
  isProcessing,
  children,
  show,
  onToggle,
}) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      show={show}
      rootClose={true}
      trigger="click"
      placement="bottom"
      overlay={
        <Popover id="popover-positioned-bottom">
          <Popover.Header as="h3">{title}</Popover.Header>
          <Popover.Body className="mt-3">
            <StyledDiv className="mt-2">{children}</StyledDiv>
          </Popover.Body>
        </Popover>
      }>
      <Button
        onClick={onToggle}
        size="sm"
        variant="outline-primary"
        isProcessing={isProcessing}
        label={t(
          `employeePage.performanceReview.nextPerformanceReview.changeReviewer.${
            !show ? 'title' : 'cancelUpdate'
          }`,
        )}
      />
    </OverlayTrigger>
  );
};

const ChangeReviewer: React.FC<Props> = ({
  participantId,
  employeeId,
  reviewerId,
}) => {
  const [show, setShow] = useState(false);
  const [changeReviewer, { loading: isProcessing }] = useChangeReviewerMutation(
    {
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [
        {
          query: ManagerEmployeeReviewsDocument,
        },
      ],
    },
  );
  const errorHandler = useMutationErrorHandler();

  const { t } = useTranslation();
  const [reviewer, setReviewer] = useState<string | null>(reviewerId);
  const { addSuccess } = useToasts();

  const handleFinish = () => {
    if (!reviewer) {
      return;
    }

    changeReviewer({
      variables: {
        input: {
          employeeId,
          participantId,
          reviewerId: reviewer,
        },
      },
    })
      .then(() => {
        setShow(false);
        addSuccess(
          t(
            'employeePage.performanceReview.nextPerformanceReview.changeReviewer.changeSuccessMessage',
          ),
        );
      })
      .catch((error) => errorHandler(error));
  };

  const handleReviewerChange = useCallback(
    (employeeId: string | null) => {
      setReviewer(employeeId);
    },
    [setReviewer],
  );

  const handleToggle = () => {
    if (show && (!reviewer || reviewerId !== reviewer)) setReviewer(reviewerId);
    setShow(!show);
  };

  return (
    <PopoverLayout
      onToggle={handleToggle}
      show={show}
      isProcessing={isProcessing}
      title={t(
        'employeePage.performanceReview.nextPerformanceReview.changeReviewer.title',
      )}>
      <EmployeeInput
        selectedEmployee={reviewer}
        onChange={handleReviewerChange}
      />

      <div className="text-end">
        <ConfirmButton
          size="sm"
          variant="primary"
          onConfirm={handleFinish}
          disabled={!reviewer}
          isProcessing={false}
          label={t(
            'employeePage.performanceReview.nextPerformanceReview.changeReviewer.confirmButtonTitle',
          )}
          confirmTitle={t(
            'employeePage.performanceReview.nextPerformanceReview.changeReviewer.confirmModalTitle',
          )}
          confirmText={t(
            'employeePage.performanceReview.nextPerformanceReview.changeReviewer.confirmText',
          )}
        />
      </div>
    </PopoverLayout>
  );
};

export default ChangeReviewer;
