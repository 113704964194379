import i18next from 'i18next';
import { formatDate } from '../../../../../utils/dateUtils';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';

export const formatWidgetDate = (
  date: Date | string,
  pattern: FlairDateFormat = FlairDateFormat.DayNumberWithMonth,
): string => {
  return formatDate(date, i18next.t(`date.${pattern}`));
};
