import React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../../components/button';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

import { Card } from 'react-bootstrap';
import './styles.css';
import { PeersReviewList } from './PeersReviewList/PeersReviewList';
import { PeerFeedback } from '../EmployeeEvaluation/types';
import { calcRatingScoreAvg, hasAnySubmitted } from './logic';
import { RatingChart } from '../FeedbackRatingSection/RatingChart';
import classNames from 'classnames';
import WidgetSpinner from '../../../Dashboard/CommonComponents/WidgetSpinner';

const i18Path =
  'employeePage.performanceReview.nextPerformanceReview.peerReviews';

type Props = {
  maxPeers: number;
  openAssignPeersModal: () => void;
  peerFeedbacks: PeerFeedback[];
  canEdit: boolean;
  onPeerClick: (peerFeedbackId: string) => void;
  onTitleClick: () => void;
  onRemovePeer?: (peerFeedbackId: string) => void;
  isLoading: boolean;
};

export const PeerReviewsSection: React.FC<Props> = ({
  maxPeers,
  openAssignPeersModal,
  peerFeedbacks,
  canEdit,
  onPeerClick,
  onTitleClick,
  onRemovePeer,
  isLoading,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const hasAnyPeerSubmitted = hasAnySubmitted(peerFeedbacks);

  const renderContent = () => {
    if (isLoading) {
      return <WidgetSpinner />;
    }

    if (peerFeedbacks.length === 0) {
      return (
        <div className="w-full">
          <div className="d-flex gap-2 flex-column align-items-center justify-content-center mb-3">
            <FlairIcon icon="user-circle-plus" size="xxl" />
            <span className="h4-medium text-gray-900">
              {t('noPeersAssigned.title')}
            </span>
            <span className="h4-regular no-assigned-peer-description">
              {t('noPeersAssigned.description', { maxPeers })}
            </span>
          </div>
          <Button
            onClick={openAssignPeersModal}
            className="w-100"
            variant="outline-primary"
            label={t('buttons.assignPeers')}
            disabled={!canEdit}
          />
        </div>
      );
    }

    return (
      <>
        <PeersReviewList
          isViewMode={true}
          peerFeedbacks={peerFeedbacks}
          onRowClick={onPeerClick}
          onDelete={onRemovePeer}
        />

        {canEdit && (
          <div>
            <Button
              className="w-100 mt-3"
              variant="outline-primary"
              label={t('buttons.edit')}
              onClick={openAssignPeersModal}
            />
          </div>
        )}
      </>
    );
  };

  const ratingScoreAvg = calcRatingScoreAvg(
    peerFeedbacks
      .filter((p) => p.submittedAt)
      .map((p) => p.answers)
      .flat(),
  );

  return (
    <Card>
      <div className="peer-review-wrapper">
        <div
          className={classNames(
            'mb-4 pb-3 d-flex align-items-center justify-content-between',
            ratingScoreAvg != null ? 'border-bottom' : '',
          )}>
          <div>
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                className={classNames(
                  'h4-medium',
                  hasAnyPeerSubmitted ? 'cu-pointer' : '',
                )}
                onClick={() => hasAnyPeerSubmitted && onTitleClick()}>
                {t('title')}
              </div>
              <FlairIcon
                icon="alert-circle-outline"
                tooltip={t('titleTooltip')}
                tooltipPlacement="top"
                size="sm"
              />
            </div>
            {ratingScoreAvg != null && (
              <div className="h5-regular sub-title mt-1">
                {t('basedOnRating')}
                <FlairIcon
                  icon="alert-circle-outline"
                  tooltip={t('avgRatingTooltip')}
                  tooltipPlacement="top"
                  size="xs"
                />
              </div>
            )}
          </div>
          {ratingScoreAvg != null && (
            <RatingChart
              rating={ratingScoreAvg}
              fontSize="1rem"
              fontWeight="700"
              startAngle={-120}
              endAngle={120}
              height={120}
              width={60}
            />
          )}
        </div>
        <div>{renderContent()}</div>
      </div>
    </Card>
  );
};
