import React from 'react';
import { TeamPopupContent } from './TeamPopupContent';
import {
  TeamWithPopupBase,
  Props as TeamWithPopupBaseProps,
} from './TeamWithPopupBase';
import { EmployeeInfo } from './types';

export type Props = Omit<TeamWithPopupBaseProps, 'popup'> & {
  employees: EmployeeInfo[] | 'loading' | 'error';
};

export const TeamWithPopup: React.FC<Props> = ({ employees, ...props }) => {
  return (
    <TeamWithPopupBase
      {...props}
      popup={
        <TeamPopupContent
          employees={employees}
          header={!props.isTeamNameVisible ? props.name : undefined}
        />
      }
    />
  );
};
