import React, { ReactNode } from 'react';
import { Placement } from 'react-toast-notifications';

const placements = {
  'top-left': { top: 0, left: 0 },
  'top-center': { top: 0, left: '50%', transform: 'translateX(-50%)' },
  'top-right': { top: 0, right: 0 },
  'bottom-left': { bottom: 0, left: 0 },
  'bottom-center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
  'bottom-right': { bottom: 0, right: 0 },
};

type Props = {
  children?: ReactNode;
  hasToasts: boolean;
  placement: Placement;
};

export const ToastContainer: React.FC<Props> = ({
  hasToasts,
  placement,
  ...props
}) => (
  <div
    className="react-toast-notifications__container"
    style={{
      boxSizing: 'border-box',
      maxHeight: '100%',
      overflow: 'hidden',
      padding: 8,
      pointerEvents: hasToasts ? 'unset' : 'none',
      position: 'fixed',
      zIndex: 2000,
      ...placements[placement],
    }}
    {...props}
  />
);
