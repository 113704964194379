import React from 'react';

import Button from '../../../../../../../components/button';
import { NotificationItem } from '../../../types';

const OpenAction: React.FC<{
  notification: NotificationItem;
}> = ({ notification }) => {
  return (
    <Button
      variant="outline-primary"
      className="ms-3"
      label="Open"
      href={notification.data.url}
    />
  );
};

export default OpenAction;
