import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/button';
import AbsenceDateTimeRange from '../../../components/AbsenceDateTimeRange';
import LightList from '../../../components/LightList';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import { routes } from '../../../routes';
import {
  AbsenceApprovalRequestToMe,
  Colleague,
  Absence,
} from '../../../__generated__/graphql';
import { SectionTitle } from '../Heading';

type RequesterProps = Pick<Colleague, 'Name'>;
type AbsenceProps = Pick<
  Absence,
  | 'flair__Type__c'
  | 'flair__Start_Date__c'
  | 'flair__Start_Date_Half_Day__c'
  | 'flair__Start_Time__c'
  | 'flair__End_Date__c'
  | 'flair__End_Date_Half_Day__c'
  | 'flair__End_Time__c'
> & {
  employee: RequesterProps | null;
};
type AbsenceRequestProps = Pick<AbsenceApprovalRequestToMe, 'Id'> & {
  absence: AbsenceProps;
};

type Props = {
  absenceRequests: ReadonlyArray<AbsenceRequestProps>;
};

const PendingAbsenceRequests: React.FC<Props> = ({ absenceRequests }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [myTeamReportMode] = useManagerReportsMode('myteam');
  const [targetReportMode, setTargetReportsMode] =
    useManagerReportsMode('absences');
  const items = absenceRequests.map((ar) => ({
    key: ar.Id,
    name: ar.absence.employee?.Name ?? '',
    value: <AbsenceDateTimeRange absence={ar.absence} />,
  }));

  const count = absenceRequests.length;

  return (
    <Row>
      <Col xs={12} className="mb-1">
        <SectionTitle>
          {t('home.pendingApprovalCard.pendingAbsenceRequests.title', {
            count: count,
          })}
        </SectionTitle>
      </Col>
      <Col>
        <LightList
          items={items}
          actionLink={
            <Button
              variant="link"
              size="sm"
              className="p-0 m-0"
              label={t(
                'home.pendingApprovalCard.pendingAbsenceRequests.actionLink',
              )}
              onClick={() => {
                // set manager mode to the same as in myTeam page
                if (myTeamReportMode !== targetReportMode) {
                  setTargetReportsMode(myTeamReportMode);
                }
                history.push(routes.pendingAbsenceRequests.route.create({}));
              }}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default PendingAbsenceRequests;
