import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useYupValidationResolver from '../../../components/form/useYupValidationResolver';

export type TimeEntryNotesFormData = {
  notes: string;
};

const validationSchema = yup.object().shape<TimeEntryNotesFormData>({
  notes: yup.string().max(32768).ensure().trim(),
});

type Props = {
  notes: string | null;
};

const fieldName = 'notes';

export const useTimeEntryNotesForm = ({ notes }: Props) => {
  const defaultValues = {
    notes: notes ?? '',
  };
  const form = useForm<TimeEntryNotesFormData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return useMemo(
    () => ({
      fieldName,
      form,
    }),
    [form],
  );
};
