import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import {
  AbsenceRequestPolicy,
  AbsenceRequestPolicyNoticeRequiredUnit,
  AbsenceRequestPolicyUsedAmountPeriod,
  AbsenceType,
} from '../../__generated__/graphql';
import { RulesCard } from './RulesCard';
import { useAbsenceAmountFormatter } from './useAbsenceAmountFormatter';

type AbsenceRequestPolicyProps = Pick<
  AbsenceRequestPolicy,
  | 'flair__Max_Absence_Amount__c'
  | 'flair__Min_Absence_Amount__c'
  | 'flair__Max_Used_Amount__c'
  | 'flair__Max_Used_Amount_Period__c'
  | 'flair__Min_Notice_Required__c'
  | 'flair__Min_Notice_Period_Unit__c'
>;

type Props = {
  absenceRequestPolicy: AbsenceRequestPolicyProps;
  absenceType: AbsenceType;
};

const i18nKey = 'requestAbsence.form.requestPolicyRules';

export const AbsenceRequestPolicyRulesCard: React.FC<Props> = ({
  absenceRequestPolicy,
  absenceType,
}) => {
  if (isEmpty(absenceRequestPolicy)) {
    return null;
  }

  return (
    <RulesCard>
      <ListRules>
        <NoticePeriodListItem
          absenceRequestPolicy={absenceRequestPolicy}
          absenceType={absenceType}
        />
        <MinAmountListItem
          absenceRequestPolicy={absenceRequestPolicy}
          absenceType={absenceType}
        />
        <MaxAmountListItem
          absenceRequestPolicy={absenceRequestPolicy}
          absenceType={absenceType}
        />
        <MaxUsedAmountListItem
          absenceRequestPolicy={absenceRequestPolicy}
          absenceType={absenceType}
        />
      </ListRules>
    </RulesCard>
  );
};

const ListRules: React.FC = ({ children }) => (
  <ul className="m-0 px-4">{children}</ul>
);

const MinAmountListItem: React.FC<Props> = ({
  absenceRequestPolicy: { flair__Min_Absence_Amount__c },
  absenceType,
}) => {
  const t = useNamespacedTranslation(i18nKey);
  const amountFormatter = useAbsenceAmountFormatter();

  if (flair__Min_Absence_Amount__c === null) {
    return null;
  }

  return (
    <li>
      {t('minAmount', {
        amount: amountFormatter({
          amount: toAbsenceAmount(flair__Min_Absence_Amount__c, absenceType),
          type: absenceType,
        }),
      })}
    </li>
  );
};

const MaxAmountListItem: React.FC<Props> = ({
  absenceRequestPolicy: { flair__Max_Absence_Amount__c },
  absenceType,
}) => {
  const t = useNamespacedTranslation(i18nKey);
  const amountFormatter = useAbsenceAmountFormatter();

  if (flair__Max_Absence_Amount__c === null) {
    return null;
  }

  return (
    <li>
      {t('maxAmount', {
        amount: amountFormatter({
          amount: toAbsenceAmount(flair__Max_Absence_Amount__c, absenceType),
          type: absenceType,
        }),
      })}
    </li>
  );
};

const MaxUsedAmountListItem: React.FC<Props> = ({
  absenceRequestPolicy: {
    flair__Max_Used_Amount__c,
    flair__Max_Used_Amount_Period__c,
  },
  absenceType,
}) => {
  const t = useNamespacedTranslation(i18nKey);
  const amountFormatter = useAbsenceAmountFormatter();

  if (
    flair__Max_Used_Amount__c === null ||
    flair__Max_Used_Amount_Period__c == null
  ) {
    return null;
  }

  switch (flair__Max_Used_Amount_Period__c) {
    case AbsenceRequestPolicyUsedAmountPeriod.Yearly: {
      return (
        <li>
          {t('maxUsedAmount_yearly', {
            amount: amountFormatter({
              amount: toAbsenceAmount(flair__Max_Used_Amount__c, absenceType),
              type: absenceType,
            }),
          })}
        </li>
      );
    }
    case AbsenceRequestPolicyUsedAmountPeriod.Monthly: {
      return (
        <li>
          {t('maxUsedAmount_monthly', {
            amount: amountFormatter({
              amount: toAbsenceAmount(flair__Max_Used_Amount__c, absenceType),
              type: absenceType,
            }),
          })}
        </li>
      );
    }
    case AbsenceRequestPolicyUsedAmountPeriod.Weekly: {
      return (
        <li>
          {t('maxUsedAmount_weekly', {
            amount: amountFormatter({
              amount: toAbsenceAmount(flair__Max_Used_Amount__c, absenceType),
              type: absenceType,
            }),
          })}
        </li>
      );
    }
    case AbsenceRequestPolicyUsedAmountPeriod.Unknown: {
      return null;
    }
  }
};

const NoticePeriodListItem: React.FC<Props> = ({
  absenceRequestPolicy: {
    flair__Min_Notice_Required__c,
    flair__Min_Notice_Period_Unit__c,
  },
}) => {
  const t = useNamespacedTranslation(i18nKey);

  if (
    flair__Min_Notice_Required__c === null ||
    flair__Min_Notice_Period_Unit__c === null ||
    flair__Min_Notice_Period_Unit__c ===
      AbsenceRequestPolicyNoticeRequiredUnit.Unknown
  ) {
    return null;
  }

  const unit = t(`noticePeriodUnits.${flair__Min_Notice_Period_Unit__c}`, {
    count: flair__Min_Notice_Required__c,
  });

  return (
    <li>
      {t('noticePeriod', {
        noticePeriodAmount: flair__Min_Notice_Required__c,
        unit,
      })}
    </li>
  );
};

const toAbsenceAmount = (amount: number, absenceType: AbsenceType) =>
  absenceType === AbsenceType.Hourly ? amount / 60 : amount;

const isEmpty = (absenceRequestPolicy: AbsenceRequestPolicyProps): boolean =>
  absenceRequestPolicy.flair__Min_Absence_Amount__c === null &&
  absenceRequestPolicy.flair__Max_Absence_Amount__c === null &&
  absenceRequestPolicy.flair__Min_Notice_Required__c === null &&
  absenceRequestPolicy.flair__Max_Used_Amount__c === null;
