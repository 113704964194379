import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCommentDeepLinkInfoQuery } from '../../../__generated__/graphql';
import { routes } from '../../../routes';
import { useUserInfo } from '../../../context/UserInfo';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { PageLoading } from '../../../components/PageLoading';
import { useToasts } from '../../../../../context/Toast';
import { createCommentDeepLinkRoute } from './commentRouteLogic';

type RouteParams = {
  commentId: string;
};

const i18prefix = 'components.deepLinkComment';

export const DeepLinkComment: React.FC = () => {
  const t = useNamespacedTranslation(i18prefix);

  const { commentId } = useParams<RouteParams>();
  const history = useHistory();
  const { id: currentUserId } = useUserInfo();
  const { addWarning } = useToasts();

  const { data, error, loading } = useCommentDeepLinkInfoQuery({
    variables: {
      commentId,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!data.comment) {
      addWarning(t('warningCommentWasDeleted'));
      history.push(routes.home.route.create({}));
      return;
    }

    const redirectRoute = createCommentDeepLinkRoute(
      currentUserId,
      data.comment,
    );
    if (!redirectRoute) {
      addWarning(t('errorRouteNotFound'));
      history.push(routes.home.route.create({}));
      return;
    }

    history.push(redirectRoute);
  }, [data, history, currentUserId, addWarning, t]);

  const text = t(error ? 'errorGetCommentInfo' : 'loading');

  return (
    <PageLoading header={t('pageHeader')} text={text} isLoading={loading} />
  );
};
