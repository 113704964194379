import * as React from 'react';

const PlayOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M3.5 3.46884V12.5313C3.5 13.0763 4.03125 13.4226 4.46875 13.1613L12.2156 8.52478C12.5944 8.29822 12.5944 7.70197 12.2156 7.47541L4.46875 2.83884C4.03125 2.57759 3.5 2.92384 3.5 3.46884Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default PlayOutlineIcon;
