import { ContentDocumentLinkDataFragment } from '../../__generated__/graphql';
import { DocumentFile } from './types';

export const mapDocumentFile = (
  link: ContentDocumentLinkDataFragment,
): DocumentFile => ({
  id: link.Id,
  fileName: link.ContentDocument.Title
    ? `${link.ContentDocument.Title}.${link.ContentDocument.FileExtension}`
    : link.ContentDocument.Title,
  downloadPublicUrl: link.downloadUrl,
});
