import { EmployeeScopeFilter } from '../../../../__generated__/graphql';
import { FilterOption } from './types';

export const getFilterOption = (filterBy: EmployeeScopeFilter) => {
  if (filterBy === FilterOption.MY_TEAM.toString()) {
    return FilterOption.MY_TEAM;
  }

  return FilterOption.ALL_COMPANY;
};
