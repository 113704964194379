import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Filter: React.FC<{ width?: string }> = ({ width = 125 }) => (
  <div className="me-3 ">
    <Skeleton width={width} height={'1.6rem'} />
  </div>
);

const FiltersLoading: React.FC = () => (
  <div className="d-none d-md-flex justify-content-between">
    <div className="d-flex align-items-center">
      <Skeleton className="me-3 mt-2" width={'3.12rem'} height={'1rem'} />
      <Filter />
      <Filter />
      <Filter />
      <Skeleton className="me-3" width={'4rem'} height={'1rem'} />
    </div>
    <div className="d-flex align-items-center">
      <Skeleton className="me-3 mt-2" width={'3.12rem'} height={'1rem'} />
      <Filter width={'5.3rem'} />
    </div>
  </div>
);

export default FiltersLoading;
