import { Option } from './types';
import i18next from 'i18next';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';
import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import {
  supportedWorkflowItemStatuses,
  WorkflowItem,
  WorkflowResponsibleEmployeeItem,
} from '../WorkflowItems/types';
import _ from 'lodash';
import {
  WorkflowFilter_EmployeeFragment,
  WorkflowStatus,
  WorkflowType,
} from '../../../__generated__/graphql';

export const mapCommonOption = (src: { Id: string; Name: string }): Option => ({
  value: src.Id,
  label: src.Name,
});

export const mapEmployeeOption = (
  src: WorkflowFilter_EmployeeFragment,
): EmployeeOption => ({
  value: src.Id,
  label: src.Name,
  initials: getEmployeeInitialsFromName(src.Name),
});

export const createYearOptions = (now: Date): Option[] => {
  const currentYear = now.getFullYear();
  return [currentYear - 1, currentYear, currentYear + 1].map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
};

export const createWorkflowTypeOptions = (): Option[] => {
  const types: WorkflowType[] = [
    WorkflowType.Onboarding,
    WorkflowType.Offboarding,
    WorkflowType.Other,
  ];
  return types.map((x) => ({
    value: x,
    label: i18next.t(`workflows.workflowTypes.${x}`),
  }));
};

export const createWorkflowStatusOptions = (): Option[] => {
  const statuses: WorkflowStatus[] = [
    WorkflowStatus.NotStarted,
    WorkflowStatus.InProgress,
    WorkflowStatus.Completed,
  ];
  return statuses.map((status) => ({
    value: status,
    label: i18next.t(`workflows.workflowStatus.${status}`),
  }));
};

export const createWorkflowItemStatusOptions = (): Option[] => {
  return supportedWorkflowItemStatuses.map((x) => ({
    value: x,
    label: i18next.t(`workflows.statuses.${x}`),
  }));
};

export const createResponsibleOptions = (items: WorkflowItem[]): Option[] => {
  return _.orderBy(
    _.uniqBy(
      items
        .filter((x) => x.responsible !== null && x.responsible.type !== 'team') // TODO add support also to teams after checking design
        .map((x) => ({
          value: x.responsible!.id,
          label: x.responsible!.name,
        })),
      'value',
    ),
    'label',
  );
};

export const createWorkflowResponsibleOptions = (
  items: WorkflowResponsibleEmployeeItem[],
): Option[] => {
  return _.orderBy(
    _.uniqBy(
      items.map((x) => ({ value: x!.id, label: x!.name })),
      'value',
    ),
    'label',
  );
};
