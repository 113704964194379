import React, { useCallback } from 'react';
import { ManagerReportsMode } from '../../hooks/useManagerReportsMode';
import Dropdown from 'react-bootstrap/Dropdown';
import { Theme } from '../../../../theme';
import i18next from 'i18next';

const i18prefix = 'components.managerReportsModeSwitch';

export type Props = {
  value: ManagerReportsMode;
  onChange: (value: ManagerReportsMode) => void;
};

const allModes: ManagerReportsMode[] = ['all', 'direct'];

export const ManagerReportsModeSwitch: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const handleToggleChange = useCallback(
    (value) => onChange(value ?? 'all'),
    [onChange],
  );

  const valueRenderInfo = getRenderInfo(value);

  return (
    <Dropdown onSelect={handleToggleChange}>
      <Dropdown.Toggle
        id="manager-reports-mode-switch"
        variant="link"
        style={{ color: valueRenderInfo.color }}>
        {valueRenderInfo.label}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {allModes.map((mode) => {
          const renderInfo = getRenderInfo(mode);
          return (
            <Dropdown.Item
              style={{ color: renderInfo.color }}
              active={value === mode}
              eventKey={mode}
              key={mode}>
              {renderInfo.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

function getRenderInfo(src: ManagerReportsMode) {
  switch (src) {
    case 'all':
      return {
        label: i18next.t(`${i18prefix}.managerReportsMode.all`),
        color: Theme.color.black,
      };
    case 'direct':
      return {
        label: i18next.t(`${i18prefix}.managerReportsMode.direct`),
        color: Theme.color.blue3,
      };
  }
}
