import {
  EmployeeFeedbackState,
  FeedbackCycleTimelineTypes,
} from '../../../__generated__/graphql';
import { useState } from 'react';

export const useFeedbackRestriction = ({
  isReviewer,
}: {
  isReviewer: boolean;
}) => {
  const [showRestrictionModal, setShowRestrictionModal] =
    useState<boolean>(false);
  const isFeedbackCycleIsInactive = (
    timelineType: FeedbackCycleTimelineTypes,
    employeeFeedbackState: EmployeeFeedbackState | undefined,
  ) => {
    const isTimelineTypeSequential =
      timelineType === FeedbackCycleTimelineTypes.Sequential;
    const isEmployeeFeedbackStateNotSubmitted =
      employeeFeedbackState &&
      employeeFeedbackState !== EmployeeFeedbackState.Submitted;

    return (
      isReviewer &&
      isTimelineTypeSequential &&
      isEmployeeFeedbackStateNotSubmitted
    );
  };

  return {
    showRestrictionModal,
    setShowRestrictionModal,
    isFeedbackCycleIsInactive,
  };
};
