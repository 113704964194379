import { getISODay } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { BreakEarlierModal } from '../../../components/AutoBreaks/denyEndBreakEarlier/BreakEarlierModal';
import { useDenyEndBreakRules } from '../../../components/AutoBreaks/denyEndBreakEarlier/useDenyEndBreakRules';
import { IsoWeekDay } from '../../../components/AutoBreaks/mappings';
import {
  allowToEndBreak,
  AllowToEndBreakResultFail,
} from '../../../components/AutoBreaks/shared';
import { useAutoBreaksTimeTrackingFeatures } from '../../../components/AutoBreaks/useAutoBreaksTimeTrackingFeatures';
import { useTimeTrackingState } from '../../../components/AutoBreaks/useTimeTrackingState';
import { useResumeTimeTracking } from '../../../hooks/timeTracking';

type Props = {
  timeEntryId: string;
  className: string;
};

const ResumeTimeTrackingButton: React.FC<Props> = ({
  timeEntryId,
  className,
}) => {
  const [resumeTimeEntry, { loading }] = useResumeTimeTracking(timeEntryId);
  const { t } = useTranslation();

  const timeTrackingFeatures = useAutoBreaksTimeTrackingFeatures();

  const { timeTrackingState } = useTimeTrackingState();

  const breakRules = useDenyEndBreakRules(
    timeTrackingState
      ? (getISODay(timeTrackingState.start) as IsoWeekDay)
      : undefined,
  );
  const [modalVisible, setModalVisible] = useState<{
    allowResult: AllowToEndBreakResultFail;
    now: Date;
  } | null>(null);

  const handleClick = () => {
    if (!timeTrackingState) {
      return;
    }
    const now = new Date();
    const allowResult = allowToEndBreak(breakRules, timeTrackingState, now);
    const allowedToEndBreak =
      !timeTrackingFeatures.denyEndBreakEarlier ||
      allowResult.result === 'allow';

    if (allowedToEndBreak) {
      resumeTimeEntry();
    } else {
      setModalVisible({
        allowResult,
        now,
      });
    }
  };

  const handleModalClose = () => {
    setModalVisible(null);
  };

  const handleEndBreak = () => {
    setModalVisible(null);
    resumeTimeEntry();
  };

  return (
    <>
      <Button
        size="sm"
        variant="outline-primary"
        className={className}
        onClick={handleClick}
        isProcessing={loading}
        icon="play-outline"
        tooltip={t('timeTracking.current.steps.duringBreak.endBreak.tooltip')}
      />
      {modalVisible && (
        <BreakEarlierModal
          visible={true}
          now={modalVisible.now}
          allowEndBreakResult={modalVisible.allowResult}
          onEndBreak={handleEndBreak}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default ResumeTimeTrackingButton;
