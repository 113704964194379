import { ApolloError } from '@apollo/client';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../context/Toast';
import { getRequestInfoFromError } from './errorHandlingRequestInfo';
import { GraphQLError } from 'graphql';

const ERROR_AUTO_DISMISS_TIMEOUT_SECONDS = 30;

type ErrorMessageHandler = (err: ApolloError) => string;

// copy-pasted from packages/server/src/apps/internal-shared/apollo/customErrorHandling.ts
// should be synced

/* eslint-disable no-unused-vars */
export enum CustomErrorCodes {
  // WARNINGS: These errors will be reported to rollbar as warnings
  // USER_FRIENDLY_BACKEND_ERROR: caused by throw new UserFriendlyError('Absence is not found') or Promise.reject(new UserFriendlyError('...'))
  USER_FRIENDLY_BACKEND_ERROR = 'USER_FRIENDLY_BACKEND_ERROR',
  // SALESFORCE_FIELD_CUSTOM_VALIDATION: caused by Salesforce FIELD_CUSTOM_VALIDATION_EXCEPTION
  SALESFORCE_FIELD_CUSTOM_VALIDATION_EXCEPTION = 'SALESFORCE_FIELD_CUSTOM_VALIDATION_EXCEPTION',

  // ERRORS: These errors will be reported to rollbar as errors
  // SALESFORCE_EXCEPTION: caused by throw new IllegalArgumentException in Salesforce code
  SALESFORCE_EXCEPTION = 'SALESFORCE_EXCEPTION',
  // BACKEND_ERROR: caused by throw new Error('Absence is not found') or Promise.reject(new Error('...')) or Promise.reject('...')
  BACKEND_ERROR = 'BACKEND_ERROR',
}

export const useSystemErrorMessageHandler = (): ErrorMessageHandler => {
  const { t } = useTranslation();

  return useCallback(
    (err: ApolloError) => {
      const errorMessagesToShow = err.graphQLErrors
        .filter(isErrorMessageVisibleToUser)
        .map((x) => x.message);

      return errorMessagesToShow.length > 0
        ? errorMessagesToShow.join('\n')
        : t('errors.toast.genericErrorContactSupport');
    },
    [t],
  );
};

const isErrorMessageVisibleToUser = (err: GraphQLError): boolean => {
  const errorCode = err.extensions?.code;
  switch (errorCode) {
    case CustomErrorCodes.USER_FRIENDLY_BACKEND_ERROR:
    case CustomErrorCodes.SALESFORCE_FIELD_CUSTOM_VALIDATION_EXCEPTION:
    case CustomErrorCodes.SALESFORCE_EXCEPTION:
      return true;
    default:
      return false;
  }
};

export const useMutationErrorHandler = (
  errorMessageHandler?: ErrorMessageHandler,
) => {
  const { addError } = useToasts();
  const defaultMessageHandler = useSystemErrorMessageHandler();
  const messageHandler = errorMessageHandler ?? defaultMessageHandler;

  return useCallback(
    (err: ApolloError) => {
      const errorMessage = messageHandler(err);
      addError(addErrorMetadataToMessage(errorMessage, err), {
        autoDismissTimeout: ERROR_AUTO_DISMISS_TIMEOUT_SECONDS * 1000,
      });
    },
    [addError, messageHandler],
  );
};

export const addErrorMetadataToMessage = (
  message: string,
  err: ApolloError,
): React.ReactNode => {
  const requestInfo = getRequestInfoFromError(err);
  if (requestInfo !== null) {
    const requestStr = `RequestId: ${requestInfo.requestId} (${requestInfo.timestamp}) ${requestInfo.operationName}`;
    return (
      <div>
        <div>{message}</div>
        <br />
        <div>{requestStr}</div>
      </div>
    );
  }
  return <span>{message}</span>;
};
