import React from 'react';
import { Employee } from '..';
import { Section, mapEmployeeSections } from '../../../components/EmployeeData';

type Props = {
  employee: Employee;
};

const PersonalData: React.FC<Props> = ({ employee }) => {
  const sections = mapEmployeeSections(employee.data);
  return (
    <>
      {sections.map((section) => (
        <Section key={section.label} section={section} />
      ))}
    </>
  );
};

export default PersonalData;
