import React from 'react';
import { Badge } from 'react-bootstrap';

type Props = {
  label: string;
};

export const SkillOptionLabel: React.FC<Props> = ({ label }) => (
  <Badge bg="light" className="fs-base">
    {label}
  </Badge>
);
