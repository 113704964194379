import styled from '@emotion/styled';
import React, { useCallback, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import { Nullable } from '../../../../../../utils/nullable';
import AbsenceDisplay from '../../../../components/AbsenceCalendar/AbsenceDisplay';
import AbsenceOverlay from '../../../../components/AbsenceCalendar/AbsenceOverlay';
import { CalendarDay } from '../../../../components/AbsenceCalendar/types';
import {
  calcVariant,
  isOverlapped,
} from '../../../../components/AbsenceCalendar/Utils';
import HolidayPopover from '../../../../components/HolidayPopover';
import { AbsenceColleague } from '../Content';
import Cell from './TeamCalendarCell';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = {
  day: CalendarDay;
  onHover: (
    day: Nullable<CalendarDay>,
    colleague: Nullable<AbsenceColleague>,
  ) => void;
  hoveredDay?: Nullable<CalendarDay>;
  hoveredColleague?: Nullable<AbsenceColleague>;
  colleague: AbsenceColleague;
};

const Content = styled.div<{ visible: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  ({ visible }) => ({
    visibility: visible ? 'visible' : 'hidden',
  }),
);

const TeamCalendarDayCell: React.FC<Props> = ({
  day,
  onHover,
  hoveredDay,
  hoveredColleague,
  colleague,
}) => {
  const target = useRef<HTMLTableDataCellElement>(null);

  const absences = day.absences;
  const absence = absences[0];
  const holiday = day.holiday;

  const isHovered =
    !!hoveredDay?.day.isSame(day.day, 'date') &&
    hoveredColleague?.id === colleague.id;

  const handleMouseOver = useCallback(() => {
    onHover(day, colleague);
  }, [day, onHover, colleague]);

  const overlappedAbsences = isOverlapped(absences);
  const variant = calcVariant(
    day.workday,
    overlappedAbsences,
    absences,
    holiday,
  );

  return (
    <>
      <Cell
        ref={target}
        onMouseEnter={handleMouseOver}
        cellHovered={isHovered}
        variant={variant}>
        {absences && (
          <AbsenceDisplay day={day} absences={absences} isHovered={isHovered} />
        )}
        <Content visible={isHovered}>
          {overlappedAbsences ? (
            <FlairIcon icon="alert-circle-outline" />
          ) : (
            <strong>{day.day.date()}</strong>
          )}
          <span>{day.day.format('ddd')}</span>
        </Content>
      </Cell>

      {absence && (
        <AbsenceOverlay
          target={target}
          showPopover={isHovered}
          absence={absence}
          absences={absences}
          holiday={holiday}
        />
      )}

      {holiday && (
        <Overlay
          target={target.current}
          show={!absence && isHovered}
          placement="top">
          {(props) => <HolidayPopover holiday={holiday} {...props} />}
        </Overlay>
      )}
    </>
  );
};
export default TeamCalendarDayCell;
