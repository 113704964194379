import classNames from 'classnames';
import React from 'react';
import { ButtonGroup, Spinner } from 'react-bootstrap';
import SoftBadge from '../../../components/SoftBadge';
import { NavLink } from '../../../../../Router';
import { routes } from '../../../routes';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  pendingApprovalCount: number;
  loading: boolean;
  selectedMode: TimeSheetNavigationMode;
};

export type TimeSheetNavigationMode = 'pending' | 'week';

export const TimeSheetNavigation: React.FC<Props> = ({
  pendingApprovalCount,
  loading,
  selectedMode,
}) => {
  const t = useNamespacedTranslation('timeSheetsControlling.navigation');

  const showPending = selectedMode === 'pending';
  return (
    <ButtonGroup size="sm">
      <NavLink
        to={routes.timeSheetsControllingPending.route}
        className={classNames('btn', 'btn-sm', {
          'btn-light': !showPending,
          'btn-secondary': showPending,
        })}
        isActive={() => showPending}>
        {t('pending')}
        <PendingApprovalCount
          selectedMode={selectedMode}
          pendingApprovalCount={pendingApprovalCount}
          loading={loading}
        />
      </NavLink>
      <NavLink
        to={routes.timeSheetsControllingWeek.route}
        className={classNames('btn', 'btn-sm', {
          'btn-light': showPending,
          'btn-secondary': !showPending,
        })}
        isActive={() => !showPending}>
        {t('week')}
      </NavLink>
    </ButtonGroup>
  );
};

const PendingApprovalCount: React.FC<Props> = ({
  loading,
  pendingApprovalCount,
  selectedMode,
}) => {
  if (loading) {
    return <Spinner animation="border" size="sm" className="ms-2" />;
  }

  return (
    <SoftBadge
      variant={selectedMode === 'pending' ? 'secondary' : 'dark'}
      className="ms-2">
      {pendingApprovalCount}
    </SoftBadge>
  );
};
