import React from 'react';

import { useUnarchiveNotification } from './useUnarchiveNotification';
import Button from '../../../../../../../components/button';
import { NotificationItem } from '../../../types';

const UnarchiveAction: React.FC<{
  notification: NotificationItem;
}> = ({ notification }) => {
  const [unarchiveNotification] = useUnarchiveNotification();
  return (
    <Button
      variant="link"
      label="Unarchive"
      className="py-0 pe-0"
      onClick={() => {
        unarchiveNotification({
          variables: {
            id: notification.id,
          },
        });
      }}
    />
  );
};

export default UnarchiveAction;
