import React from 'react';
import {
  StatusApprovedIcon,
  StatusPendingIcon,
  StatusRejectedIcon,
} from '../FlairIcon/icons';

export type Status = 'PENDING' | 'APPROVED' | 'REJECTED';

export type StatusIconProps = {
  status: Status;
};

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case 'PENDING':
      return <StatusPendingIcon data-testid={'status-pending-icon'} />;
    case 'APPROVED':
      return <StatusApprovedIcon data-testid={'status-approved-icon'} />;
    case 'REJECTED':
      return <StatusRejectedIcon data-testid={'status-rejected-icon'} />;
    default:
      return null;
  }
};

export default StatusIcon;
