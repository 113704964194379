import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringToHtml from '../../../../components/StringToHtml';
import { RulesCard } from './RulesCard';

type Props = {
  richText: string;
};

const TruncatedText = styled('div')({
  overflow: 'hidden',
  lineHeight: '1.5em',
  maxHeight: '1.5em',
});

type ContentProps = {
  richText: string;
  truncate: boolean;
};

const Content: React.FC<ContentProps> = ({ richText, truncate }) => {
  const content = <StringToHtml text={richText} />;

  return truncate ? (
    <TruncatedText>{content}</TruncatedText>
  ) : (
    <div>{content}</div>
  );
};

const SpecialRulesCard: React.FC<Props> = ({ richText }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <RulesCard>
      <Content richText={richText} truncate={!open} />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          setOpen((o) => !o);
        }}
        tabIndex={-1}>
        {open
          ? t('requestAbsence.form.specialRules.showLess')
          : t('requestAbsence.form.specialRules.showMore')}
      </a>
    </RulesCard>
  );
};

export default SpecialRulesCard;
