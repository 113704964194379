import React, { useMemo, useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RequestAbsenceButtonFragment } from '../../__generated__/graphql';
import RequestAbsenceSidebar from '../RequestAbsenceSidebar';
import { PureQueryOptions } from '@apollo/client';

type EmployeeAbsenceCategoryProps = RequestAbsenceButtonFragment;

type Props = Pick<ButtonProps, 'variant' | 'size'> & {
  categoryId: string | null;
  categories: ReadonlyArray<EmployeeAbsenceCategoryProps>;
  buttonLabel?: string;
  block?: boolean;
  button?: React.ReactNode;
  isProcessing?: boolean;
  onSuccess?: () => void;
  refetchQueries?: PureQueryOptions[];
};

const RequestAbsenceButton: React.FC<Props> = ({
  categoryId,
  categories,
  buttonLabel,
  block,
  button,
  isProcessing = false,
  onSuccess,
  refetchQueries,
  ...rest
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const absenceCategories = useMemo(
    () =>
      categories.map((c) => ({
        id: c.Id,
        Name: c.category.Name,
        icon: c.category.flair__Icon__c,
        type: c.category.flair__Type__c,
        order: c.category.flair__Order__c,
        unlimited: c.flair__Unlimited__c,
        availableAmount: c.allowancesSummary.availableAmount,
        group: c.category?.group || null,
        policies: c.absenceRequestPolicies,
      })),
    [categories],
  );

  let requestBtn = (
    <Button onClick={handleShow} {...rest} disabled={isProcessing}>
      {buttonLabel ?? t('absences.cards.absenceCategories.requestAbsence')}
    </Button>
  );

  if (button) {
    requestBtn = React.cloneElement(button as React.ReactElement, {
      onClick: handleShow,
      isProcessing,
    });
  }

  return (
    <div className={block ? 'd-grid' : undefined}>
      {requestBtn}
      <RequestAbsenceSidebar
        absenceCategoryId={categoryId ?? absenceCategories[0]?.id ?? ''}
        show={show}
        onClose={handleClose}
        absenceCategories={absenceCategories}
        onSuccess={onSuccess}
        refetchQueries={refetchQueries}
      />
    </div>
  );
};

export default RequestAbsenceButton;
