import React from 'react';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../../hooks/useManagerReportsMode';
import { useCurrentTimeSheetsQuery } from '../../../__generated__/graphql';
import Loading from './Loading';
import ServerError from '../../../../../components/ServerError';
import CurrentTimeSheets from './CurrentTimeSheets';

type Props = {
  employeeNameFilter: string;
};

export const CurrentTimeSheetsTab: React.FC<Props> = ({
  employeeNameFilter,
}) => {
  const [reportsMode] = useManagerReportsMode('timeTrackingControlling');

  const { data, loading, error } = useCurrentTimeSheetsQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || data === undefined) {
    return <ServerError />;
  }

  return (
    <CurrentTimeSheets
      employeeNameFilter={employeeNameFilter}
      timeSheets={data.manager.currentTimeSheets}
    />
  );
};
