import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';

type Props = {
  visible: boolean;
  onClose: () => void;
  renameInProgress: boolean;
  setName: (name: string) => void;
  rename: () => void;
  name: string | null;
  initialName: string | undefined;
};

const i18Path = 'documents2.personal.rename';

export const DocumentRenameModal: React.FC<Props> = ({
  visible,
  onClose,
  renameInProgress,
  rename,
  setName,
  name,
  initialName,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const saveFolder = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    rename();
  };

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      contentClassName="border"
      dialogClassName="break-modal-440">
      <Modal.Header closeButton className="card-header border-0">
        <h2 className="card-header-title text-center">{t('title')}</h2>
      </Modal.Header>
      <Modal.Body className={'pt-0'}>
        <form onSubmit={saveFolder}>
          <div className={'form-group'}>
            <input
              defaultValue={name || ''}
              autoFocus={true}
              className={'form-control'}
              placeholder={t('input')}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={'d-flex justify-content-center gap-3'}>
            <Button
              variant="outline-primary"
              label={t('buttons.cancel')}
              onClick={() => onClose()}
            />
            <Button
              isProcessing={renameInProgress}
              disabled={!name || name === initialName}
              type="submit"
              label={t('buttons.rename')}
              variant="primary"
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
