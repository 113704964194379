import classNames from 'classnames';
import React from 'react';
import StatusDot, { StatusDotVariant } from '../StatusDot';

type Props = {
  text?: React.ReactNode;
  variant?: StatusDotVariant;
  textFirst?: boolean;
  textStyle?: React.CSSProperties;
  containerClassName?: string;
};

const StatusIndicator: React.FC<Props> = ({
  variant = 'info',
  text,
  textFirst,
  textStyle,
  containerClassName,
}) => {
  const textElm = <span key="text">{text}</span>;
  const iconElm = <StatusDot key="icon" variant={variant} />;
  let toRender = [iconElm];

  if (text) {
    if (textFirst) {
      toRender.unshift(textElm);
    } else {
      toRender.push(textElm);
    }
  }

  return (
    <Container className={containerClassName} style={{ ...textStyle }}>
      {toRender}
    </Container>
  );
};

export default StatusIndicator;

const Container = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={classNames('d-flex', 'align-items-center', 'gap-1', className)}
    {...rest}
  />
);
