import type { Locale as DateFnsLocale } from 'date-fns';
import * as dateFns from 'date-fns';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  eachMonthOfInterval,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  format,
  formatDistanceStrict,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  isValid,
  isWithinInterval,
  isYesterday,
  parse,
  parseISO,
  setYear,
  startOfDay,
  startOfHour,
  startOfISOWeek,
  startOfMinute,
  startOfMonth,
  startOfWeek,
  startOfYear,
  toDate,
} from 'date-fns';
import { de, enGB, enUS, es, fr, it, ru } from 'date-fns/locale';
import { getCurrentLocale, Locale } from '../context/I18n';
import i18next from 'i18next';
import { Moment, unitOfTime } from 'moment';
import { range } from 'lodash';
import { OptionBase } from '../components/Select/types';

//todo: this is duplication of packages/mobile/src/utils/dateUtils.ts

type SupportedLocalesType = Record<Locale, DateFnsLocale>;

export const WEEK_DAY_FORMAT = 'EEEE';
export const WEEK_DAY_FORMAT_SHORT = 'EEE';
// todo: add tests
export type UnitType =
  | 'customYear'
  | 'year'
  | 'month'
  | 'day'
  | 'hour'
  | 'minute'
  | 'week';

const supportedDateFnsLocales: SupportedLocalesType = {
  en: enGB,
  'en-us': enUS,
  'de-ch': de,
  de,
  fr,
  it,
  es,
  ru,
};

const getDateFnsLocale = () => {
  const lang: Locale = getCurrentLocale();
  return supportedDateFnsLocales[lang];
};

type DateInput = Date | number | string;

type Pattern =
  | 'LLLL'
  | 'LLL'
  | 'P'
  | 'yyyy-MM-dd'
  | 'MMMM'
  | 'do MMMM yyyy'
  | 'dd MMMM yyyy'
  | 'dd.MM.yyyy'
  | 'MMMM do' //May 24th -> adding th
  | 'EEE MMM do'
  | 'yyyy'
  | 'MMMM yyyy'
  | 'EEE'
  | 'EEEE'
  | 'HH:mm'
  | 'dd/MM/yyyy HH:mm'
  | 'EEEE - PPP' // Wednesday - April 12th, 2023
  | 'EEE. MMM d, yyyy'; // Wed. Sep 4, 2023

export const dateFnsLocale = getDateFnsLocale();

export const formatDateShort = (date: DateInput): string =>
  formatDate(
    parseDate(date),
    i18next.t('dateFnsFormat.date.short', {
      interpolation: { escapeValue: false },
    }),
  );

export const formatDateLong = (date: DateInput): string =>
  formatDate(
    parseDate(date),
    i18next.t('dateFnsFormat.date.long', {
      interpolation: { escapeValue: false },
    }),
  );

export const formatDate = (
  date: DateInput,
  formatStr: Pattern | string,
): string => {
  try {
    return format(parseDate(date), formatStr, { locale: dateFnsLocale });
  } catch (e: unknown) {
    const errorMessage = e instanceof Error ? e.message : 'unknown';
    throw new Error(
      `formatDate error: date: ${date}; formatStr: ${formatStr}; locale: ${dateFnsLocale.code}, error: ${errorMessage};`,
    );
  }
};

export const formatTime24h = (date: DateInput): string => {
  return format(parseDate(date), 'HH:mm');
};

export const toISODateOnly = (date: DateInput): string =>
  format(parseDate(date), 'yyyy-MM-dd');

export const toISOTimeOnly = (date: DateInput): string =>
  format(parseDate(date), 'HH:mm:ss');

export const parseDate = (date: DateInput): Date => {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return toDate(date);
};

export const formatRelative = (date: Date): string => {
  if (isYesterday(date)) {
    return `${i18next.t('dateFnsFormat.date.yesterday', {
      time: formatDate(date, 'HH:mm'),
    })}`;
  }

  if (isToday(date)) {
    return `${i18next.t('dateFnsFormat.date.today', {
      time: formatDate(date, 'HH:mm'),
    })}`;
  }

  return `${formatDate(date, 'dd/MM/yyyy HH:mm')}`;
};

//todo: renamed from changeTime
export const setTime = (date: DateInput, newTime: string) =>
  parseDate(`${toISODateOnly(date)}T${newTime}`);

export const minDate = (a: Date, b: Date): Date =>
  a.getTime() < b.getTime() ? a : b;

export const getRange = (
  startDate: Moment,
  endDate: Moment,
  type: unitOfTime.Diff,
) =>
  range(0, endDate.diff(startDate, type)).map((i) =>
    startDate.clone().add(i, type),
  );

export type UnitTypeArgs =
  | {
      unit: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'week';
      customYearStartDay?: undefined;
    }
  | {
      unit: 'customYear';
      customYearStartDay: Date;
    };

export function isSame(date1: Date, date2: Date, unitTypeArgs?: UnitTypeArgs) {
  if (unitTypeArgs) {
    date1 = changeDateByUnit(date1, unitTypeArgs);
    date2 = changeDateByUnit(date2, unitTypeArgs);
  }
  return isEqual(date1, date2);
}

export function isSameOrBefore(
  date1: Date,
  date2: Date,
  unitTypeArgs?: UnitTypeArgs,
) {
  if (unitTypeArgs) {
    date1 = changeDateByUnit(date1, unitTypeArgs);
    date2 = changeDateByUnit(date2, unitTypeArgs);
  }
  return isBefore(date1, date2) || isEqual(date1, date2);
}

export function isSameOrAfter(
  date1: Date,
  date2: Date,
  unitTypeArgs?: UnitTypeArgs,
) {
  if (unitTypeArgs) {
    date1 = changeDateByUnit(date1, unitTypeArgs);
    date2 = changeDateByUnit(date2, unitTypeArgs);
  }
  return isAfter(date1, date2) || isEqual(date1, date2);
}

export const isDayInFuture = (today: Date, date: Date): boolean => {
  const todayStart = startOfDay(today);
  const dateStart = startOfDay(date);

  return isAfter(dateStart, todayStart);
};

export function changeDateByUnit(
  date: Date,
  { unit, customYearStartDay }: UnitTypeArgs,
) {
  switch (unit) {
    case 'year':
      return startOfYear(date);
    case 'month':
      return startOfMonth(date);
    case 'week':
      return startOfWeek(date);
    case 'day':
      return startOfDay(date);
    case 'hour':
      return startOfHour(date);
    case 'minute':
      return startOfMinute(date);
    case 'customYear':
      return startOfCustomYear(date, customYearStartDay);
    default:
      return date;
  }
}

export function changePeriodByUnit(
  date: Date,
  { unit, customYearStartDay }: UnitTypeArgs,
) {
  switch (unit) {
    case 'year':
      return {
        start: startOfYear(date),
        end: endOfYear(date),
      };
    case 'month':
      return {
        start: startOfMonth(date),
        end: endOfMonth(date),
      };
    case 'week':
      return {
        start: startOfISOWeek(date),
        end: endOfISOWeek(date),
      };
    case 'day':
      return {
        start: startOfDay(date),
        end: endOfDay(date),
      };
    case 'customYear':
      const start = startOfCustomYear(date, customYearStartDay);
      const end = addDays(addYears(start, 1), -1);
      return {
        start,
        end,
      };
    default:
      return {
        start: date,
        end: date,
      };
  }
}

export function cloneDate(date: Date) {
  return new Date(date.getTime());
}

export function addDateByUnit(date: Date, amount: number = 0, unit?: UnitType) {
  const clonedDate = cloneDate(date);
  switch (unit) {
    case 'year':
    case 'customYear':
      return addYears(clonedDate, amount);
    case 'month':
      return addMonths(clonedDate, amount);
    case 'week':
      return addWeeks(clonedDate, amount);
    case 'day':
      return addDays(clonedDate, amount);

    default:
      return clonedDate;
  }
}

export function startOfCustomYear(date: Date, customYearStartDay: Date) {
  const dateYear = getYear(date);
  const customYearStartDayThisYear = setYear(customYearStartDay, dateYear);
  if (date >= customYearStartDayThisYear) {
    return customYearStartDayThisYear;
  } else {
    return addYears(customYearStartDayThisYear, -1);
  }
}

export const getMonthName = (date: DateInput): string =>
  format(parseDate(date), 'LLLL', { locale: dateFnsLocale });

export const getMonthsOptions = (
  monthFormat: Pattern = 'LLLL',
): OptionBase[] => {
  const currentDate = new Date();
  const yearInterval = {
    start: startOfYear(currentDate),
    end: endOfYear(currentDate),
  };
  const dates = eachMonthOfInterval(yearInterval);
  return dates.map((month) => ({
    value: String(getMonth(month) + 1),
    label: format(month, monthFormat, { locale: dateFnsLocale }),
  }));
};

export const getDateYear = (date?: Date): number => {
  if (!date) {
    return getYear(new Date());
  }
  return getYear(date);
};

export const diff = (from: Date, to: Date, unit?: UnitType) => {
  switch (unit) {
    case 'year':
      return dateFns.differenceInCalendarYears(from, to);
    case 'month':
      return dateFns.differenceInCalendarMonths(from, to);
    case 'week':
      return dateFns.differenceInCalendarISOWeeks(from, to);
    case 'day':
      return dateFns.differenceInCalendarDays(from, to);

    default:
      return dateFns.differenceInHours(from, to);
  }
};

export const formattedDistance = (
  date: number | Date,
  baseDate: number | Date,
  addSuffix?: boolean,
  locale: globalThis.Locale = dateFnsLocale,
) => {
  return formatDistanceStrict(date, baseDate, {
    addSuffix,
    locale,
  });
};

export function formatDateWithLocaleDay(
  dateValue: string,
  weekday: 'short' | 'long' = 'short',
) {
  return (
    formatDate(dateValue, weekday === 'short' ? 'EEE' : 'EEEE') +
    ' ' +
    formatDateShort(dateValue)
  );
}

export const isCurrentDateInRange = (
  currentDate: Date,
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
): boolean => {
  const startDateTime = parse(
    `${startDate}T${startTime}`,
    "yyyy-MM-dd'T'HH:mm:ss.SSS",
    currentDate,
  );
  const endDateTime = parse(
    `${endDate}T${endTime}`,
    "yyyy-MM-dd'T'HH:mm:ss.SSS",
    currentDate,
  );

  if (!isValid(startDateTime) || !isValid(endDateTime)) {
    throw new Error('Invalid date or time format');
  }
  return isWithinInterval(currentDate, {
    start: startDateTime,
    end: endDateTime,
  });
};
