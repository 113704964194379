export const getTextWithoutHtmlTags = (html: string) => {
  if (html.length === 0) {
    return '';
  }

  // Create a temporary element and set the HTML content
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Extract the text content and remove HTML tags using a regular expression
  const textContent = tempElement.textContent || tempElement.innerText;
  return textContent.replace(/(<([^>]+)>)/gi, '');
};
