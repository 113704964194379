import React, { useEffect } from 'react';
import { GoalBase, GoalsFilterType } from '../types';
import { GoalFilter, GoalsFilter } from './GoalsFilter';
import i18next from 'i18next';
import SkeletonCard from '../../../../components/Skeleton/Card';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGoalFilterOptions } from '../useGoalSelectOptions';
import { GoalType } from '../../../../__generated__/graphql';

const i18prefix = 'performanceReview.goals2';

type Props = {
  filterTypes: GoalsFilterType[];
  filter: GoalFilter;
  onChange: (filter: GoalFilter) => void;
  onCreateGoal: () => void;
  goals: GoalBase[] | undefined;
  isReviewGoals?: boolean;
};

export const GoalsFilterConnected: React.FC<Props> = ({
  filterTypes,
  goals,
  filter,
  onChange,
  onCreateGoal,
  isReviewGoals,
}) => {
  const { t } = useTranslation();

  const { options, loading, error } = useGoalFilterOptions(goals);

  // Drop filter by employeeId if we do not have that employee in reports
  useEffect(() => {
    if (
      options &&
      filter.employeeId &&
      filter.filterType === 'myReportsGoals' &&
      options.managerReports.every((x) => x.value !== filter.employeeId)
    ) {
      onChange({ ...filter, employeeId: null });
    }
  }, [options, filter, onChange]);

  // Drop filter by employeeId if we do not have that employee in collegues
  useEffect(() => {
    if (
      options &&
      filter.employeeId &&
      filter.filterType === 'colleaguesGoals' &&
      options.colleagues.every((x) => x.value !== filter.employeeId)
    ) {
      onChange({ ...filter, employeeId: null });
    }
  }, [options, filter, onChange]);

  if (error) {
    return (
      <Card id="filter-goals-card">
        <Card.Body>{t(`${i18prefix}.goalsFilter.errorLoading`)}</Card.Body>
      </Card>
    );
  }

  if (!goals || loading || !options) {
    return <SkeletonCard height={210} />;
  }

  const getEmployeeOptions = () => {
    switch (filter.filterType) {
      case 'myReportsGoals':
        return options.managerReports;
      case 'colleaguesGoals':
        return options.colleagues;
      default:
        return options.employees;
    }
  };

  const getGoalTypeOptions = () => {
    switch (filter.filterType) {
      case 'myReportsGoals':
      case 'myGoals':
      case 'colleaguesGoals':
        return options.goalTypes.filter(
          (goalType) => goalType.value !== GoalType.Company,
        );
      default:
        return options.goalTypes;
    }
  };

  return (
    <GoalsFilter
      filterOptions={filterTypes.map(createFilterOption)}
      tagsOptions={options.tags}
      periodsOptions={options.periods}
      employeeOptions={getEmployeeOptions()}
      goalTypeOptions={getGoalTypeOptions()}
      goals={goals}
      isCreateButtonVisible={true}
      onCreateGoal={onCreateGoal}
      onClearFilter={() =>
        onChange({
          ...filter,
          tagId: null,
          periodId: null,
          employeeId: null,
        })
      }
      onChange={onChange}
      filter={filter}
      showEmployeeFilter={filter.filterType !== 'myGoals' && !isReviewGoals}
    />
  );
};

const createFilterOption = (filterType: GoalsFilterType) => ({
  label: i18next.t(`${i18prefix}.filterType.${filterType}`),
  value: filterType,
});
