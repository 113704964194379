import styled from '@emotion/styled';
import React from 'react';
import FlairIcon, {
  FlairIconName,
} from '../../../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../../../theme';
import { InventoryCategory } from '../../types';

type Props = {
  hasSubRow?: boolean;
  onClick: () => void;
  name: string;
  type: string;
  isExpanded: boolean;
};

export const InventoryNameCell: React.FC<Props> = ({
  hasSubRow = false,
  onClick,
  type,
  isExpanded,
  name,
}) => {
  const getIcon = (): FlairIconName => {
    switch (type as InventoryCategory) {
      case 'App & Software':
        return 'app-software-icon';
      case 'Electronic Device':
        return 'electronic-device-icon';
      case 'Office Equipment':
        return 'office-equipment-icon';
      case 'Other':
        return 'inventory-other-icon';
      case 'Vehicle':
        return 'vehicle-icon';
      case 'Uniforms & Workwear':
        return 'uniforms-icon';
      default:
        return 'inventory-other-icon';
    }
  };

  return (
    <div className="d-flex flex-row align-items-center" onClick={onClick}>
      <div className="d-flex flex-row me-2 align-items-center">
        <FlairIcon size={'xl'} icon={getIcon()} color={Theme.color.blue3} />
      </div>
      {hasSubRow && (
        <FlairIcon
          className="me-1"
          icon={
            isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'
          }></FlairIcon>
      )}
      <NameStyled
        title={name}
        className="d-inline-block cu-pointer h7-medium text-truncate">
        {name}
      </NameStyled>
    </div>
  );
};

const NameStyled = styled.span`
  color: ${Theme.color.blue3};
  max-width: 14.5rem;
`;
