import React, { useCallback } from 'react';
import { OptionLabel } from './OptionLabel';

import {
  SelectSingleBase,
  RenderOptionParams,
  Props as SelectSingleBaseProps,
  RenderOptionFunc,
} from './SelectSingleBase';
import { OptionBase } from '../../../../components/Select/types';

export type Props = Omit<SelectSingleBaseProps<OptionBase>, 'renderOption'> & {
  value: string | null;
  options: OptionBase[];
  onChange: (value: string | null) => void;
  renderOption?: RenderOptionFunc<OptionBase>;
};

export const SelectSingle: React.FC<Props> = ({
  renderOption,
  ...restProps
}) => {
  const renderOptionDefault = useCallback(
    (renderProps: RenderOptionParams<OptionBase>) => (
      <OptionLabel {...renderProps} />
    ),
    [],
  );
  return (
    <SelectSingleBase
      {...restProps}
      renderOption={renderOption ?? renderOptionDefault}
    />
  );
};
