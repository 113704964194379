import React from 'react';
import Arrow from './arrow.svg';
import { TimeBalanceWithdrawRowInfo } from '../types';
import { useTranslation, Trans } from 'react-i18next';
import { formattedDuration } from '../../DurationFormat';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { TimeBalanceWithdrawalType } from '../../../__generated__/graphql';
import { formatDate, parseDate } from '../../../../../utils/dateUtils';
import { InfoHint } from '../../../../../components/hint/InfoHint';

type Props = {
  day: string;
  withdraw: TimeBalanceWithdrawRowInfo;
};

const i18Prefix = 'components.timeSheetsTable.timeBalanceWithdrawal';

export const TimeBalanceWithdrawal: React.FC<Props> = ({ day, withdraw }) => {
  const formatDuration = formattedDuration(useTranslation().t);
  const t = useNamespacedTranslation(i18Prefix);
  const timeBalance = formatDuration(withdraw.amount);

  const getTextContent = () => {
    switch (withdraw.withdrawalType) {
      case TimeBalanceWithdrawalType.AdjustTimeBalanceTo:
      case TimeBalanceWithdrawalType.ToNull:
      case TimeBalanceWithdrawalType.ToOneTimeCompensation:
        return (
          <Trans
            i18nKey={`${i18Prefix}.text.${withdraw.withdrawalType}`}
            values={{ timeBalance }}
            components={[<span className="text-success" />]}></Trans>
        );
      case TimeBalanceWithdrawalType.ToAbsenceAllowance:
        return (
          <Trans
            i18nKey={`${i18Prefix}.text.${withdraw.withdrawalType}`}
            values={{
              timeBalance,
              absenceCategoryName: withdraw.absenceCategoryName,
            }}
            components={[<span className="text-success" />]}></Trans>
        );
    }
  };

  const dayStr = formatDate(parseDate(day), 'EEEE - PPP');

  return (
    <Container className="d-flex gap-2 align-items-center h7-regular">
      <Img src={Arrow} alt="background" />
      <div className="color-paper-1">{dayStr}</div>
      <div>{getTextContent()}</div>
      <InfoHint
        className="d-flex"
        text={t(`hint.${withdraw.withdrawalType}`)}
      />
    </Container>
  );
};

const MAX_HEIGHT_REMS = '3rem';

const Container = styled.div({
  maxHeight: MAX_HEIGHT_REMS,
});

const Img = styled.img({
  maxHeight: MAX_HEIGHT_REMS,
});
