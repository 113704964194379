import React from 'react';
import classNames from 'classnames';
import FlairFormattedDate from '../../../../../../atomic/atoms/FlairFormattedDate';
import { FlairDateFormat } from '../../../../../../atomic/atoms/FlairFormattedDate/types';

type Props = {
  day: string;
  className?: string;
};

export const DayRowCell: React.FC<Props> = ({ className, day }) => {
  return (
    <div className={classNames(className, 'd-flex align-items-center gap-2')}>
      <FlairFormattedDate
        day={day}
        format={FlairDateFormat.WeekdayLongDateSuffix}></FlairFormattedDate>
    </div>
  );
};
