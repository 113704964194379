export class ReportingError<TReportData extends {}> extends Error {
  cause: unknown;
  reportData: TReportData;

  constructor(reportData: TReportData, cause: unknown) {
    super();
    this.reportData = reportData;
    this.cause = cause;
    this.message = getErrorMessage(cause);
  }
}

const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return 'Unknown error';
  }
};
