import {
  DateInterval,
  isSameIntervalArrays,
  isSameIntervals,
} from '../../../../utils/dateInterval';
import {
  CalendarQueryQuery,
  Employee,
  EmployeeShift,
  OpenShift,
  OpenShiftAssignmentRequest,
  ShiftBreak,
} from '../../__generated__/graphql';
import {
  EmployeeShiftInfo,
  ShiftEmployeeInfo,
  OpenShiftInfo,
  BaseShiftInfo,
  ShiftInfoBlock,
  OpenShiftStateAndReason,
  ShiftLabelInfo,
  ShiftLocationInfo,
} from './Common/types';
import moment from 'moment';
import {
  DailyEmployeeShiftInfo,
  DailyOpenShiftInfo,
  DailyShiftInfo,
} from './ShiftsDaily/dailyShiftsTypes';
import { canRequestOpenShift, isPastShift } from './shiftsLogic';

export const mapWaitingApprovalOpenShifts = (
  allShifts: {
    allOpenShifts: DailyOpenShiftInfo[];
    allEmployeeShifts: DailyEmployeeShiftInfo[];
  },
  employeeId: string,
): {
  allAvailableOpenShifts: DailyOpenShiftInfo[];
  allEmployeeShiftsWithRequested: DailyEmployeeShiftInfo[];
} => {
  let allAvailableOpenShifts = allShifts.allOpenShifts.filter(
    (x) => x.state !== 'requested',
  );

  let requestedShifts = allShifts.allOpenShifts.filter(
    (x) => x.state === 'requested',
  );

  const currentEmployee = allShifts.allEmployeeShifts.find(
    (s) => s.employee.id === employeeId,
  );
  if (currentEmployee) {
    currentEmployee.shifts = [...currentEmployee.shifts, ...requestedShifts];
  }

  return {
    allAvailableOpenShifts,
    allEmployeeShiftsWithRequested: allShifts.allEmployeeShifts,
  };
};

export const getShifts = (
  queryData: CalendarQueryQuery,
  additionalEmployeeIds: string[],
  view: String,
): {
  allOpenShifts: (OpenShiftInfo | DailyShiftInfo)[];
  allEmployeeShifts: EmployeeShiftInfo[] | DailyEmployeeShiftInfo[];
} => {
  const meId = queryData.me.Id;
  const myEmployeeShifts = getEmployeeShifts(meId, queryData);

  const allOpenShifts = queryData.me.openShifts.map((openShift) => {
    if (view === 'weekly')
      return mapOpenShiftWeekly(openShift, meId, myEmployeeShifts);
    else return mapOpenShiftsDaily(openShift, meId, myEmployeeShifts);
  });

  let allEmployeeShifts: EmployeeShiftInfo[] | DailyEmployeeShiftInfo[] = [];

  if (view === 'weekly') {
    allEmployeeShifts = getAllEmployeeShifts(
      queryData,
      new Set<string>([meId, ...additionalEmployeeIds]),
    );
  } else {
    allEmployeeShifts = setCurrentEmployeeFirst(
      mapDailyEmployeeShifts(queryData),
    );
  }

  return { allOpenShifts, allEmployeeShifts };
};

export const mapMultiDayWeeklyShifts = (
  shifts: (OpenShiftInfo | EmployeeShiftInfo)[],
): ShiftInfoBlock[] => {
  let modifiedShifts: ShiftInfoBlock[] = [];

  shifts.forEach((shift) => {
    if (!shift.end.isSame(shift.start, 'day')) {
      let firstDayBlockInfo = {
        start: shift.start,
        end: shift.start.clone().endOf('day'),
      };

      let secondDayBlockInfo = {
        start: shift.end.clone().startOf('day'),
        end: shift.end,
      };

      modifiedShifts.push({ ...shift, blockInfo: firstDayBlockInfo });
      modifiedShifts.push({ ...shift, blockInfo: secondDayBlockInfo });
    } else {
      modifiedShifts.push({
        ...shift,
        blockInfo: { start: shift.start, end: shift.end },
      });
    }
  });

  return modifiedShifts;
};

export const mergeShiftsForWeeklyView = (
  allOpenShifts: OpenShiftInfo[],
  allEmployeeShifts: EmployeeShiftInfo[],
) => {
  // this can be a little bit optimized, but i am not sure we need it right now
  const openShifts = allOpenShifts.filter((x) => x.state === 'requested');
  const employeePureShifts = allEmployeeShifts.filter((x) => !x.openShiftId);
  const employeeOpenShifts: EmployeeShiftInfo[] = [];
  allEmployeeShifts
    .filter((x) => !!x.openShiftId)
    .forEach((employeeShift) => {
      const relatedOpenShift = openShifts.find(
        (x) => x.id === employeeShift.openShiftId,
      );
      if (!relatedOpenShift) {
        // we do not found related open shift (but we still want to display employee shift)
        employeeOpenShifts.push(employeeShift);
      } else if (
        relatedOpenShift &&
        !areShiftsMatchByTime(relatedOpenShift, employeeShift)
      ) {
        // we found open shift, but intervals are different
        employeeOpenShifts.push(employeeShift);
        // if it is my do not display open shift
        if (employeeShift.isMy) {
          openShifts.splice(openShifts.indexOf(relatedOpenShift), 1);
        }
      } else {
        // we found open shift and it match exactly the employee shift so we do not want to show employee shift in that case
        // just do nothning
      }
    });
  return [...employeePureShifts, ...openShifts, ...employeeOpenShifts];
};

const setCurrentEmployeeFirst = (
  dailyEmployeeShiftRows: DailyEmployeeShiftInfo[],
): DailyEmployeeShiftInfo[] => {
  let currentEmployee = dailyEmployeeShiftRows.splice(
    dailyEmployeeShiftRows.findIndex(({ isMy }) => isMy),
    1,
  );
  return [...currentEmployee, ...dailyEmployeeShiftRows];
};

export const mapDailyEmployeeShifts = (
  data: CalendarQueryQuery,
): DailyEmployeeShiftInfo[] => {
  if (!data) return [];

  return [...data.me.activeColleagues, ...data.me.invitedColleagues].map(
    (colleague) => {
      let employee = {
        id: colleague.Id,
        name: colleague.Name,
        position: colleague.flair__Position__c,
        isInvited: colleague.__typename === 'InvitedColleague',
        location: 'location' in colleague ? colleague.location?.Name : null,
      };

      return {
        shifts: [...colleague.shifts].map(
          ({
            Id,
            flair__Start_Datetime__c,
            flair__End_Datetime__c,
            flair__Employee_Notes__c,
            breaks,
            shiftLabels,
            location,
          }) => ({
            id: Id,
            type: 'employee-shift',
            rowIndex: 0,
            employee,
            isMy: data.me.Id === colleague.Id,
            breaks: breaks.map(mapInterval),
            start: moment(flair__Start_Datetime__c),
            end: moment(flair__End_Datetime__c),
            employeeNotes: flair__Employee_Notes__c,
            totalBreakMinutes: 0,
            shiftLabels: shiftLabels.map(mapShiftLabel),
            location: location ? mapLocation(location) : null,
          }),
        ),
        employee,
        isMy: data.me.Id === colleague.Id,
      };
    },
  );
};

export const gelAllEmployeeExceptMe = (
  data: CalendarQueryQuery,
): ShiftEmployeeInfo[] =>
  [...data.me.activeColleagues, ...data.me.invitedColleagues]
    .map((x) => ({
      id: x.Id,
      name: x.Name,
      isInvited: x.__typename === 'InvitedColleague',
    }))
    .filter((x) => x.id !== data.me.Id);

const getAllEmployeeShifts = (
  data: CalendarQueryQuery,
  employeeIds: Set<string>,
): EmployeeShiftInfo[] => {
  if (!employeeIds.size) {
    return [];
  }
  const me = data.me;
  const employees = [...me.activeColleagues, ...me.invitedColleagues].filter(
    (employee) => employeeIds.has(employee.Id),
  );
  return employees.flatMap((curEmployee) =>
    curEmployee.shifts.map((shift) =>
      mapEmployeeShift(
        data.me.Id,
        curEmployee,
        shift,
        curEmployee.__typename === 'InvitedColleague',
        'location' in curEmployee ? curEmployee.location?.Name : null,
      ),
    ),
  );
};

const mapOpenShiftsDaily = (
  shift: OpenShiftOnlyRequiredFields,
  meId: string,
  myEmployeeShiftsIntervals: DateInterval[],
): DailyOpenShiftInfo => {
  let { state, notAvailableReason } = calculateShiftState(
    shift,
    meId,
    myEmployeeShiftsIntervals,
  );

  return {
    id: shift.Id,
    type: 'open-shift',
    state,
    rowIndex: 0,
    start: moment(shift.flair__Start_Datetime__c),
    end: moment(shift.flair__End_Datetime__c),
    totalBreakMinutes: 0,
    breaks: shift.Breaks.map(mapInterval),
    notAvailableReason,
    assignmentRequestId:
      state === 'requested'
        ? shift.AssignmentRequests.find(
            (x) =>
              x.flair__Employee__c === meId &&
              x.flair__Confirmed_At__c === null,
          )!.Id
        : null,
    openShiftEmployees: shift.AssignmentRequests.map((x) => ({
      id: x.employee.Id,
      name: x.employee.Name,
    })),
    shiftLabels: [],
    location: null,
  };
};

const mapOpenShiftWeekly = (
  src: OpenShiftOnlyRequiredFields,
  meId: string,
  employeeShifts: DateInterval[],
): OpenShiftInfo => {
  const openShiftInterval = mapInterval(src);
  let { state, notAvailableReason } = calculateShiftState(
    src,
    meId,
    employeeShifts,
  );
  return {
    ...openShiftInterval,
    breaks: src.Breaks.map(mapInterval),
    type: 'open-shift',
    id: src.Id,
    state,
    notAvailableReason,
    assignmentRequestId:
      state === 'requested'
        ? src.AssignmentRequests.find(
            (x) =>
              x.flair__Employee__c === meId &&
              x.flair__Confirmed_At__c === null,
          )!.Id
        : null,
    totalBreakMinutes: src.flair__Total_Break_Period_in_Minutes__c,
    openShiftEmployees: src.EmployeeShifts.map((employeeShift) => ({
      id: employeeShift.employee.Id,
      name: employeeShift.employee.Name,
    })),
    shiftLabels: [],
    location: null,
  };
};

const mapEmployeeShift = (
  meId: string,
  employee: { Id: string; Name: string },
  src: EmployeeShiftRequiredFields,
  isInvited: boolean,
  location?: string | null,
): EmployeeShiftInfo => ({
  ...mapInterval(src),
  breaks: src.breaks.map(mapInterval),
  type: 'employee-shift',
  id: src.Id,
  totalBreakMinutes: src.flair__Total_Break_Period_in_Minutes__c,
  employee: {
    id: employee.Id,
    name: employee.Name,
    location,
    isInvited,
  },
  employeeNotes: src.flair__Employee_Notes__c,
  isMy: meId === employee.Id,
  openShiftId: src.flair__Open_Shift__c,
  shiftLabels: src.shiftLabels.map(mapShiftLabel),
  location: src.location ? mapLocation(src.location) : null,
});

export const mapShiftLabel = (shiftLabel: {
  Id: string;
  Name: string;
  flair__Tag_Name__c: string;
}): ShiftLabelInfo => {
  return {
    id: shiftLabel.Id,
    name: shiftLabel.Name,
    tagName: shiftLabel.flair__Tag_Name__c,
  };
};

const mapLocation = (location: {
  Id: string;
  Name: string;
}): ShiftLocationInfo => {
  return {
    id: location.Id,
    name: location.Name,
  };
};

export const calculateShiftState = (
  shift: OpenShiftOnlyRequiredFields,
  meId: string,
  myEmployeeShiftsIntervals: DateInterval[],
): OpenShiftStateAndReason => {
  if (isPastShift(shift))
    return {
      state: 'not-available',
      notAvailableReason: 'past-shift',
    };

  if (!canRequestOpenShift(myEmployeeShiftsIntervals, shift))
    return {
      state: 'not-available',
      notAvailableReason: 'overlapped',
    };

  if (shift.EmployeeShifts.some((x) => x.employee.Id === meId)) {
    return { state: 'confirmed' };
  } else if (
    shift.AssignmentRequests.some(
      (x) => x.flair__Employee__c === meId && x.flair__Confirmed_At__c === null,
    )
  ) {
    return { state: 'requested' };
  } else {
    const assignedShiftsCount = shift.EmployeeShifts.length;
    const hasFreeSlots = shift.flair__Employees_Count__c > assignedShiftsCount;
    return {
      state: hasFreeSlots ? 'available' : 'not-available',
      notAvailableReason: hasFreeSlots ? undefined : 'no-free-slots',
    };
  }
};

export const areShiftsMatchByTime = (
  shift1: BaseShiftInfo,
  shift2: BaseShiftInfo,
): boolean => {
  if (!isSameIntervals(shift1, shift2)) {
    return false;
  }
  if (!isSameIntervalArrays(shift1.breaks, shift2.breaks)) {
    return false;
  }
  return true;
};

export const getEmployeeShifts = (
  employeeId: string,
  data: CalendarQueryQuery,
): DateInterval[] => {
  const employee = data.me.activeColleagues.find((x) => x.Id === employeeId);
  if (!employee) {
    return [];
  }
  return employee.shifts.map(mapInterval);
};

export const mapInterval = (src: {
  flair__Start_Datetime__c: string;
  flair__End_Datetime__c: string;
}): DateInterval => ({
  start: moment(src.flair__Start_Datetime__c),
  end: moment(src.flair__End_Datetime__c),
});

export type OpenShiftOnlyRequiredFields = Pick<
  OpenShift,
  | 'Id'
  | 'flair__Start_Datetime__c'
  | 'flair__End_Datetime__c'
  | 'flair__Total_Break_Period_in_Minutes__c'
  | 'flair__Employees_Count__c'
> & {
  AssignmentRequests: ReadonlyArray<
    Pick<
      OpenShiftAssignmentRequest,
      'Id' | 'flair__Employee__c' | 'flair__Confirmed_At__c'
    > & { employee: Pick<Employee, 'Id' | 'Name'> }
  >;
  EmployeeShifts: ReadonlyArray<{ employee: Pick<Employee, 'Id' | 'Name'> }>;
  Breaks: ReadonlyArray<
    Pick<ShiftBreak, 'flair__Start_Datetime__c' | 'flair__End_Datetime__c'>
  >;
};

type EmployeeShiftRequiredFields = Pick<
  EmployeeShift,
  | 'Id'
  | 'flair__Start_Datetime__c'
  | 'flair__End_Datetime__c'
  | 'flair__Total_Break_Period_in_Minutes__c'
  | 'flair__Open_Shift__c'
  | 'flair__Employee_Notes__c'
  | 'shiftLabels'
  | 'location'
> & {
  breaks: ReadonlyArray<
    Pick<ShiftBreak, 'flair__Start_Datetime__c' | 'flair__End_Datetime__c'>
  >;
};
