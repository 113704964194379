import { omitBy, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { routes } from '../../../routes';
import { GoalFilter } from './GoalsFilter/GoalsFilter';
import { GoalsFilterType } from './types';

const FILTER_TYPE = 'filterType';
const TAG_ID = 'tag';
const PERIOD_ID = 'period';
const EMPLOYEE_ID = 'employee';
const LOCATION_ID = 'location';
const GOAL_TYPE = 'goalType';

export const useGoalFilter = (
  isManagerView: boolean,
  defaultFilterType: GoalsFilterType,
  employeeId?: string,
): [GoalFilter, (filter: GoalFilter) => void] => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const filter: GoalFilter = {
    filterType: queryParams[FILTER_TYPE] ?? defaultFilterType,
    tagId: queryParams[TAG_ID] ?? null,
    periodId: queryParams[PERIOD_ID] ?? null,
    employeeId: queryParams[EMPLOYEE_ID] ?? employeeId ?? null,
    locationId: queryParams[LOCATION_ID] ?? null,
    goalType: queryParams[GOAL_TYPE] ?? null,
  };

  const setFilter = useCallback(
    (filter: GoalFilter) => {
      const newQueryParams = omitBy(
        {
          ...queryParams,
          [FILTER_TYPE]:
            filter.filterType !== defaultFilterType
              ? filter.filterType
              : undefined,
          [TAG_ID]: filter.tagId,
          [PERIOD_ID]: filter.periodId,
          [EMPLOYEE_ID]: filter.employeeId,
          [LOCATION_ID]: filter.locationId,
          [GOAL_TYPE]: filter.goalType,
        },
        isEmpty,
      ) as Record<string, any>;
      const route = !isManagerView
        ? routes.performanceReviewGoals.route
        : routes.goals.route;
      history.push(route.withQueryParams(newQueryParams).create({}));
    },
    [queryParams, history, defaultFilterType, isManagerView],
  );

  return [filter, setFilter];
};
