import React from 'react';

import { mapAvatarSizeClassName } from './mappings';
import { AvatarSize } from './types';

export type AvatarContainerProps = {
  size?: AvatarSize;
  className?: string;
  title?: string;
  children: React.ReactNode;
};

export const AvatarContainer = React.forwardRef<
  HTMLDivElement,
  AvatarContainerProps
>(({ size, className, title, children }, ref) => {
  return (
    <div
      ref={ref}
      title={title}
      className={[
        'align-middle',
        'avatar',
        mapAvatarSizeClassName(size),
        className,
      ].join(' ')}>
      {children}
    </div>
  );
});
