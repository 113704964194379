import React from 'react';
import { Provider } from '@rollbar/react';
import { Configuration } from 'rollbar';

export const RollbarProvider: React.FC = ({ children }) => {
  const rollbarConfig: Configuration = {
    accessToken: import.meta.env.VITE_APP_ROLLBAR_TOKEN_WEB,
    enabled: import.meta.env.MODE === 'production',
    captureIp: 'anonymize',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: import.meta.env.MODE,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: import.meta.env.VITE_APP_CODE_VERSION,
          guess_uncaught_frames: true,
        },
      },
    },
  };

  return <Provider config={rollbarConfig} children={children} />;
};
