import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import '../index.css';
import { useUserInfo } from '../../../../context/UserInfo';
import { EmployeeNotificationSettingsQuery } from '../../../../__generated__/graphql';
import UpdateNotificationSettingsByChannelButton from './UpdateNotificationSettingsByChannelButton';
import { NOTIFICATION_ALL_CHANNELS } from '../types';

type NotificationSettingsHeaderProps = {
  data: EmployeeNotificationSettingsQuery;
};
const NotificationSettingsHeader: React.FC<NotificationSettingsHeaderProps> = ({
  data,
}) => {
  const { hasSlackEnabled } = useUserInfo();

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h2 className="mb-0">All notifcations</h2>
          </Col>
          {NOTIFICATION_ALL_CHANNELS.map((channel) => {
            if (channel === 'slack' && !hasSlackEnabled) {
              return null;
            }
            return (
              <Col
                className="notification-channel-col p-0 d-flex justify-content-center"
                key={channel}
                xs={1}>
                <UpdateNotificationSettingsByChannelButton
                  channel={channel}
                  data={data}
                />
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NotificationSettingsHeader;
