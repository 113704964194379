import React from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { useTranslation } from 'react-i18next';
import { ClockIn } from '../../../hooks/useCurrentTimeTrackingTimeline';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  step: ClockIn;
};

const ClockInStep: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <ListGroup.Item>
      <Row>
        <Col className="col-auto">
          <div className="avatar avatar-sm">
            <div className="avatar-title fs-3 bg-primary-soft rounded-circle text-primary">
              <FlairIcon icon="play-outline" />
            </div>
          </div>
        </Col>
        <Col className="ms-n2">
          <h5 className="mb-1">
            {t('timeTracking.current.steps.clockIn.title')}
          </h5>

          {step.location && (
            <p className="small text-dark mb-0">{step.location}</p>
          )}

          <small className="text-dark">
            <FormattedDateTime dateTime={step.time} format="time" />
          </small>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ClockInStep;
