export const isLoomUrl = (url: string): boolean => {
  try {
    const urlObject = new URL(url);
    return (
      urlObject.hostname === 'www.loom.com' &&
      urlObject.pathname.startsWith('/share/')
    );
  } catch (error) {
    return false;
  }
};
