import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Employee } from '../../pages/People/types';
import { getEmployeeInitialsFromName } from '../../utils/employeeInitials';
import Avatar from '../../../../atomic/molecules/avatar/Avatar';
import ModalSidebar from '../ModalSidebar';
import FlairIcon, { FlairIconName } from '../../../../atomic/atoms/FlairIcon';

type Props = {
  show: boolean;
  onClose: () => void;
  employee: Employee | null;
};

const i18Path = 'people';

export const EmployeeInfoSidebar: React.FC<Props> = ({
  show,
  onClose,
  employee,
}) => {
  return (
    <ModalSidebar
      show={show}
      header=""
      onClose={onClose}
      content={<SidebarContent employee={employee} />}
    />
  );
};

type SidebarContentProps = {
  employee: Employee | null;
};

type EmployeeInfo = {
  label: string;
  icon: FlairIconName;
  value: any;
};

const SidebarContent: React.FC<SidebarContentProps> = ({ employee }) => {
  const t = useNamespacedTranslation(i18Path);

  if (!employee) {
    return <div />;
  }

  const employeeInfo: EmployeeInfo[] = [
    {
      label: 'department',
      icon: 'business-outline',
      value: employee.department,
    },
    {
      label: 'location',
      icon: 'navigate-circle-outline',
      value: employee.location,
    },
    {
      label: 'manager',
      icon: 'business-outline',
      value: employee.manager?.name,
    },
    {
      label: 'email',
      icon: 'mail-outline',
      value: employee.companyEmail,
    },
    {
      label: 'companyPhone',
      icon: 'call-outline',
      value: employee.phone,
    },
  ];

  return (
    <div className="d-flex flex-column p-4">
      <HeaderContainer>
        <Avatar
          avatarUrl={employee.avatarUrl ?? ''}
          initials={getEmployeeInitialsFromName(employee.name)}
          size="xl"
        />
        <NameContainer>
          <NameLabel>{employee.name}</NameLabel>
          <PositionLabel>{employee.position}</PositionLabel>
        </NameContainer>
      </HeaderContainer>

      {employeeInfo.map((e) => (
        <RowContainer className="fs-5">
          <LabelContainer>
            <FlairIcon icon={e.icon} size="sm" />
            <Label>{t(e.label)}</Label>
          </LabelContainer>
          <Value>{e.value}</Value>
        </RowContainer>
      ))}
    </div>
  );
};

const HeaderContainer = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    {...rest}
    className={classNames(
      'd-flex',
      'flex-column',
      'align-items-center',
      'justify-content-center',
      'gap-3',
      'mb-6',
      className,
    )}
    ref={ref}
  />
));

const NameContainer = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    {...rest}
    className={classNames(
      'd-flex',
      'flex-column',
      'align-items-center',
      'justify-content-center',
      'gap-2',
      className,
    )}
    ref={ref}
  />
));

const RowContainer = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    {...rest}
    className={classNames(
      'd-flex',
      'justify-content-between',
      'align-items-center',
      'p-3',
      'gap-3',
      'border-bottom',
      className,
    )}
    ref={ref}
  />
));

const LabelContainer = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    {...rest}
    className={classNames(
      'd-flex',
      'flex-nowrap',
      'align-items-center',
      'gap-2',
      'text-dark',
      className,
    )}
    ref={ref}
  />
));

const Label = React.forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...rest }, ref) => (
  <span {...rest} className={classNames('fw-bold', className)} ref={ref} />
));

const Value = React.forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...rest }, ref) => (
  <span {...rest} className={classNames('fw-bold', className)} ref={ref} />
));

const NameLabel = React.forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...rest }, ref) => (
  <span
    {...rest}
    className={classNames('fw-bold', 'fs-2', className)}
    ref={ref}
  />
));

const PositionLabel = React.forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...rest }, ref) => (
  <span
    {...rest}
    className={classNames('fw-bold', 'text-dark', 'fs-5', className)}
    ref={ref}
  />
));
