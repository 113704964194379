import {
  CreateLoomVideoInput,
  LoomVideoFragment,
} from '../../__generated__/graphql';
import { LoomVideo } from './types';
import { Maybe } from '../../../../utils/maybe';

export const mapLoomVideo = (
  src: Maybe<LoomVideoFragment>,
): LoomVideo | null => {
  if (!src) {
    return null;
  }

  return {
    embedUrl: src.flair__Embed_Url__c,
    sharedUrl: src.flair__Shared_Url__c,
    width: src.flair__Width__c,
    height: src.flair__Height__c,
  };
};

export const mapLoomRecordVideo = (
  src: Maybe<LoomVideoFragment>,
): CreateLoomVideoInput | null => {
  if (!src) {
    return null;
  }

  return {
    embedUrl: src.flair__Embed_Url__c,
    sharedUrl: src.flair__Shared_Url__c,
    width: src.flair__Width__c,
    height: src.flair__Height__c,
    loomId: src.flair__Loom_Id__c,
    loomPublicAppId: src.flair__Loom_Public_App_Id__c,
  };
};

export const mapLoomVideoFromUrl = (shareUrl: string): LoomVideo => {
  return {
    embedUrl: shareUrl.replace('/share/', '/embed/'),
    sharedUrl: shareUrl,
    width: 1920,
    height: 1080,
  };
};
