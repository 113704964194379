import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import FlairIcon from '../../atomic/atoms/FlairIcon';
import type { FlairIconName } from '../../atomic/atoms/FlairIcon';
import classNames from 'classnames';

export type CardItem = {
  iconName: FlairIconName;
  label: string;
  buttonKey: string;
  hasError?: boolean;
  onButtonClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
};

export type SizeProps = 'xl' | 'lg' | 'sm';

export type SelectableCardProps = Omit<CardItem, 'onButtonClick'> & {
  onClick: () => void;
  size?: SizeProps;
};

const getSize = (size?: SizeProps) => {
  switch (size) {
    case 'lg':
      return {
        height: '6.25rem',
        width: '8.75rem',
      };
    case 'sm':
      return {
        height: '6.25rem',
        width: '6.25rem',
      };

    default:
      return {
        height: '6.25rem',
        width: '12.5rem',
      };
  }
};

export const SelectableCard: React.FC<SelectableCardProps> = ({
  iconName,
  label,
  isActive,
  hasError,
  onClick,
  size,
  disabled,
}) => (
  // pe-none
  <StyledContainer
    disabled={disabled}
    {...getSize(size)}
    isActive={isActive}
    hasError={hasError}
    onClick={onClick}
    className={classNames(
      'd-flex align-items-center text-center width-auto rounded-1 border  cu-pointer user-select-none',
      {
        'pe-none': disabled,
      },
    )}>
    <Row className="ms-1 me-1 w-100">
      <Col className="pr-1 pl-1" sm={12}>
        <FlairIcon
          color={
            disabled
              ? Theme.color.gray5
              : isActive
              ? Theme.color.blue3
              : Theme.color.paper1
          }
          size="2x"
          icon={iconName}
        />
      </Col>
      <Col className="pr-1 pl-1 d-flex justify-content-center" sm={12}>
        <div className="h5-medium">{label}</div>
      </Col>
    </Row>
  </StyledContainer>
);

const StyledContainer = styled.div<{
  isActive?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  width: string;
  height: string;
}>`
  ${({ height, width }) => ({ height, width })};
  ${({ isActive, hasError }) => `
    background: ${isActive ? Theme.color.blue6 : Theme.color.paper5};
    color: ${isActive ? Theme.color.blue3 : Theme.color.paper1};
    border-color: ${
      hasError
        ? Theme.color.red2
        : isActive
        ? Theme.color.blue3
        : Theme.color.paper3
    } !important;
  `}
  ${({ disabled }) =>
    disabled
      ? `
    background: ${Theme.color.gray3};
    color: ${Theme.color.gray5};
    border-color: ${Theme.color.gray5} !important;
  `
      : ''}
  max-height: 6.25rem;
  max-width: 12.5rem;
`;

export type SelectableCardsGroupProps = {
  activeButton?: string;
  list: CardItem[];
  isValid: boolean;
  onClick?: (cardInfo: CardItem) => void;
  size?: SizeProps;
};

const SelectableCardsGroup: React.FC<SelectableCardsGroupProps> = ({
  activeButton,
  list,
  onClick = () => {},
  size,
  isValid,
}) => {
  return (
    <Row className="justify-content-center">
      {list.map((cardItem: CardItem) => (
        <Col
          key={cardItem.buttonKey}
          className="m-2 p-0 d-flex justify-content-center"
          sm="auto">
          <SelectableCard
            size={size}
            isActive={activeButton === cardItem.buttonKey}
            hasError={!isValid}
            onClick={() => onClick(cardItem)}
            {...cardItem}
          />
        </Col>
      ))}
    </Row>
  );
};

export default SelectableCardsGroup;
