import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import PageHeader from '../../../components/PageHeader';
import { useFlairLocalStorage } from '../../../hooks/useFlairLocalStorage';
import { routes } from '../../../routes';
import ICalendar from '../ICalendar';
import Content from './Content';
import Nav from './Nav';
import TeamCalendarFilters from './Table/TeamCalendarFilters';
import {
  ABSENCE_CATEGORIES_FILTER,
  ABSENT_STATUS_FILTER,
  COLLEAGUES_IDS_FILTER,
  DEPARTMENT_FILTER,
  filterDefaultData,
  LOCATIONS_FILTER,
  TeamCalendarFiltersType,
  TEAMS_FILTER,
} from './Table/TeamCalendarFilters/types';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

const DATE_PARAM = 'startDate';

const dateFromParam = (query: string | null): moment.Moment =>
  moment(query ? query : undefined);

const getQueryFiltersData = (
  query: URLSearchParams,
): TeamCalendarFiltersType => {
  return {
    teams: query.get(TEAMS_FILTER) || '',
    departments: query.get(DEPARTMENT_FILTER) || '',
    locations: query.get(LOCATIONS_FILTER) || '',
    absenceCategories: query.get(ABSENCE_CATEGORIES_FILTER) || '',
    colleaguesIds: query.get(COLLEAGUES_IDS_FILTER) || '',
    absentStatus: query.get(ABSENT_STATUS_FILTER) || '',
  };
};

const useStoreFiltersToLocalStorage = (query: URLSearchParams) => {
  const queryParams = useQueryParams();
  let filterDataFromUrl = getQueryFiltersData(query);

  const [localStorageFilters, setLocalStorageFilters] = useFlairLocalStorage(
    'TEAM_CALENDAR_FILTERS',
    filterDefaultData,
  );
  const history = useHistory();

  const setUrlWithQueryParams = useCallback(
    (params: TeamCalendarFiltersType) => {
      history.push(
        routes.teamCalendar.route
          .withQueryParams({
            ...params,
          })
          .create({}),
      );
    },
    [history],
  );
  useEffect(() => {
    if (isEmpty(queryParams)) {
      setUrlWithQueryParams(localStorageFilters);
    } else if (!isEqual(localStorageFilters, filterDataFromUrl)) {
      setLocalStorageFilters(filterDataFromUrl);
      setUrlWithQueryParams(filterDataFromUrl);
    }
  }, [
    query,
    history,
    localStorageFilters,
    filterDataFromUrl,
    setLocalStorageFilters,
    queryParams,
    setUrlWithQueryParams,
  ]);

  return [{ ...filterDefaultData, ...localStorageFilters }];
};

const i18Path = 'absences';

const TeamCalendar: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  useFlairBreadcrumbHook([
    { label: t('title') },
    { label: t('subMenuItems.colleagues.title') },
  ]);

  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const fromDate = dateFromParam(query.get(DATE_PARAM)).startOf('month');
  const [filterData] = useStoreFiltersToLocalStorage(query);

  return (
    <>
      <PageHeader title={t('subMenuItems.colleagues.title')} />
      <Card>
        <Card.Header className="h-auto">
          <div className="d-flex gap-3 align-items-center small">
            <div className="me-auto">
              <TeamCalendarFilters filterData={filterData} />
            </div>
            <div className="d-flex align-items-center gap-3">
              <ICalendar filterData={filterData} />
              <Nav
                fromDate={fromDate}
                dateFromName={DATE_PARAM}
                filterData={filterData}
              />
            </div>
          </div>
        </Card.Header>
        <Content fromDate={fromDate} filterData={filterData} />
      </Card>
    </>
  );
};

export default TeamCalendar;
