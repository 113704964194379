import { ReportingError } from '../../../../../../components/ErrorHandling';
import {
  AdjustBreakResult,
  autoBreaksCalculationService,
} from '../../../../components/AutoBreaks/shared';
import {
  createBreak,
  createNewBreak,
  TimeEntryBreakItem,
  TimeEntryItem,
  toDateTime,
} from '../logic';
import { mapToAdjustTimeEntryRequest } from '../logic/useAutoBreaksLogic';

import {
  emptyValidationResult,
  validateTimeEntry,
  ValidationResult,
} from '../logic/validation';
import { Actions, TimeEntryActions } from './actions';

export type TimeEntryState = {
  current: TimeEntryItem;
  validationErrors: ValidationResult;
  notesVisible: boolean;
  costCenterVisible: boolean;
};

export const getInitialState = (
  initialTimeEntry: TimeEntryItem,
): TimeEntryState => ({
  current: initialTimeEntry,
  validationErrors: emptyValidationResult(),
  notesVisible: initialTimeEntry.notes.length > 0,
  costCenterVisible: initialTimeEntry.costCenterId !== null,
});

export const timeEntryStateReducer = (
  state: TimeEntryState,
  action: Actions,
): TimeEntryState => {
  try {
    switch (action.type) {
      case 'reset':
        return getInitialState(action.initialTimeEntry);
      case 'setNotes':
      case 'setStart':
      case 'setEnd':
      case 'updateBreak':
      case 'deleteBreak':
      case 'setCostCenter':
      case 'newBreak': {
        const current = timeEntryReducer(state.current, action);
        return {
          ...state,
          validationErrors: validateTimeEntry(current),
          current,
        };
      }
      case 'autoAdjust': {
        if (validateTimeEntry(state.current).size > 0) {
          return state;
        }
        const current = timeEntryReducer(state.current, action);
        return {
          ...state,
          validationErrors: validateTimeEntry(current),
          current,
        };
      }
      case 'addNotes':
        return {
          ...state,
          notesVisible: true,
        };
      case 'assignCostCenter':
        return {
          ...state,
          costCenterVisible: true,
        };
      default:
        return state;
    }
  } catch (e) {
    throw new ReportingError({ state, action }, e);
  }
};

const timeEntryReducer = (
  state: TimeEntryItem,
  action: TimeEntryActions,
): TimeEntryItem => {
  switch (action.type) {
    case 'setNotes':
      return { ...state, notes: action.notes };
    case 'setStart':
      return { ...state, start: action.start };
    case 'setEnd':
      return { ...state, end: action.end };
    case 'setCostCenter':
      return { ...state, costCenterId: action.costCenterId };
    case 'updateBreak': {
      const updatedBreaks = state.breaks.map((x) =>
        x.uniqueId === action.breakItem.uniqueId
          ? { ...action.breakItem, isBreakAutoFixed: false }
          : x,
      );
      return { ...state, breaks: updatedBreaks };
    }
    case 'newBreak':
      const newBreak = createNewBreak(state);
      if (newBreak === null) {
        return state;
      }
      return { ...state, breaks: [...state.breaks, newBreak] };
    case 'deleteBreak':
      return {
        ...state,
        breaks: state.breaks.filter((x) => x.uniqueId !== action.uniqueId),
      };
    case 'autoAdjust':
      const adjustTimeEntryRequest = mapToAdjustTimeEntryRequest(state);

      const fixBreaksResult = autoBreaksCalculationService.adjustBreaks(
        action.breakRules,
        adjustTimeEntryRequest,
      );
      if (fixBreaksResult.nonComplientReason !== null) {
        // TODO: add adjust error
        return state;
      }
      return {
        ...state,
        breaks: applyBreakFixes(state.breaks, fixBreaksResult.breaks),
      };
    default:
      return state;
  }
};

const applyBreakFixes = (
  srcBreaks: TimeEntryBreakItem[],
  fixedBreaks: AdjustBreakResult[],
): TimeEntryBreakItem[] => {
  // Breaks can only be added or increased, no deduction
  // 1 Adjusting breaks
  let adjustedBreaks: TimeEntryBreakItem[] = srcBreaks.map((srcBreak) => {
    const fixedBreak = fixedBreaks.find(
      (x) => x.breakId === srcBreak.uniqueId && x.isAutoAdjusted,
    );
    if (!fixedBreak) {
      return srcBreak;
    }
    return {
      ...srcBreak,
      start: toDateTime(fixedBreak.start),
      end: toDateTime(fixedBreak.end),
      isBreakAutoFixed: true,
    };
  });

  // 2 Adding new break if required

  const newFixedBreak = fixedBreaks.find((fixedBreak) =>
    srcBreaks.every((src) => src.uniqueId !== fixedBreak.breakId),
  );

  if (newFixedBreak) {
    adjustedBreaks = [
      ...adjustedBreaks,
      {
        ...createBreak(newFixedBreak.start, newFixedBreak.end),
        isBreakAutoFixed: true,
      },
    ];
  }

  return adjustedBreaks;
};
