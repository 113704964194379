import * as React from 'react';

const ClipBoardOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9617 1.5C10.82 0.925786 10.3014 0.5 9.68344 0.5H6.31656C5.69858 0.5 5.18004 0.925786 5.03831 1.5H4.5C3.96957 1.5 3.46086 1.71071 3.08579 2.08579C2.71071 2.46086 2.5 2.96957 2.5 3.5V13.5C2.5 14.0304 2.71071 14.5391 3.08579 14.9142C3.46086 15.2893 3.96957 15.5 4.5 15.5H11.5C12.0304 15.5 12.5391 15.2893 12.9142 14.9142C13.2893 14.5391 13.5 14.0304 13.5 13.5V3.5C13.5 2.96957 13.2893 2.46086 12.9142 2.08579C12.5391 1.71071 12.0304 1.5 11.5 1.5H10.9617ZM6 1.81656C6 1.64173 6.14173 1.5 6.31656 1.5H9.68344C9.85827 1.5 10 1.64173 10 1.81656V2.18344C10 2.35827 9.85827 2.5 9.68344 2.5H6.31656C6.14173 2.5 6 2.35827 6 2.18344V1.81656ZM5.03831 2.5H4.5C4.23478 2.5 3.98043 2.60536 3.79289 2.79289C3.60536 2.98043 3.5 3.23478 3.5 3.5V13.5C3.5 13.7652 3.60536 14.0196 3.79289 14.2071C3.98043 14.3946 4.23478 14.5 4.5 14.5H11.5C11.7652 14.5 12.0196 14.3946 12.2071 14.2071C12.3946 14.0196 12.5 13.7652 12.5 13.5V3.5C12.5 3.23478 12.3946 2.98043 12.2071 2.79289C12.0196 2.60536 11.7652 2.5 11.5 2.5H10.9617C10.82 3.07421 10.3014 3.5 9.68344 3.5H6.31656C5.69858 3.5 5.18004 3.07421 5.03831 2.5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ClipBoardOutlineIcon;
