import { omit } from 'lodash';
import {
  EmployeeNotificationSettingsQuery,
  useUpdateEmployeeNotificationSettingsMutation,
} from '../../../../../__generated__/graphql';
import { useUserInfo } from '../../../../../context/UserInfo';
import { NotificationChannel } from '../../types';

type UpdateNotificationSettingsByChannelParams = {
  channel: NotificationChannel;
  valueToSet: boolean;
  data: EmployeeNotificationSettingsQuery;
};

export const useUpdateNotificationSettingsByChannel = ({
  channel,
  valueToSet,
  data,
}: UpdateNotificationSettingsByChannelParams) => {
  const { id: meId } = useUserInfo();

  const updatedSettings = () => {
    const currentValues = omit(data.me.notificationSettings, [
      'Name',
      'flair__Employee__c',
      '__typename',
    ]);
    const updatedSettingsFields = {};
    for (const key of Object.keys(currentValues)) {
      if (key.toLowerCase().endsWith(`_${channel}__c`.toLowerCase())) {
        Object.assign(updatedSettingsFields, { [key]: valueToSet });
      }
    }

    return { ...currentValues, ...updatedSettingsFields };
  };

  return useUpdateEmployeeNotificationSettingsMutation({
    variables: {
      input: updatedSettings(),
    },
    update: (cache, response) => {
      const recordId = response.data?.updateEmployeeNotificationSettings;
      if (!recordId) {
        return;
      }
      const meCacheId: string | undefined = cache.identify({
        __typename: 'Me',
        Id: meId,
      });

      // Toggle current value
      cache.modify({
        id: meCacheId,
        fields: {
          notificationSettings: (existing) => {
            return { ...existing, ...updatedSettings() };
          },
        },
      });
    },
    optimisticResponse: ({ input }) => {
      return {
        updateEmployeeNotificationSettings: input.Id,
      };
    },
  });
};
