import React from 'react';
import { OptionBase } from '../../../../components/Select/types';
import { TeamFragment } from '../../__generated__/graphql';
import { TeamAvatar } from '../Team';
import { TeamWithPopupConnected } from '../Team/TeamWithPopupConnected';
import { OptionLabel } from './OptionLabel';

type Props = {
  option: OptionBase;
  isInsideSelect: boolean;
};

export const TeamOptionLabel: React.FC<Props> = (props) => {
  return (
    <div className="d-flex align-items-center">
      {props.isInsideSelect && <TeamAvatar />}
      {!props.isInsideSelect && (
        <TeamWithPopupConnected
          isTeamNameVisible={false}
          name={props.option.label}
          id={props.option.value}
        />
      )}
      <div className="ms-2">
        <OptionLabel {...props} />
      </div>
    </div>
  );
};

export const mapTeamOption = (src: TeamFragment): OptionBase => ({
  label: src.Name,
  value: src.Id,
});
