import { TableDaySubRow } from '../../../manager/TimeSheetsControlling/Tables/types';

export const defaultColumns = {
  targetMinutes: true,
  startTime: true,
  endTime: true,
  breakMinutes: true,
  workedMinutes: true,
  workedDifferenceMinutes: true,
  commentsCount: true,
  timeBalanceAccumulatedMinutes: true,
} as const;

type ColumnId = keyof typeof defaultColumns;

export const timeSheetColumnIds: ColumnId[] = Array.from(
  Object.keys(defaultColumns),
) as ColumnId[]; // If you know how to remove 'as' here please write me.

export type TimeSheetColumnId = Extract<
  keyof TableDaySubRow, // to be on the more safe side
  ColumnId
>;

export type TimeSheetColumnSetting = {
  id: TimeSheetColumnId;
  checked: boolean;
};
