import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import PageHeader from '../../components/PageHeader';
import Section from '../../components/Section';
import EvaluationTemplateContent, {
  getRequiredInvalidFields,
} from '../../components/Evaluation/EvaluationTemplate';
import FinalComment from '../../components/Evaluation/FinalComment';
import FinalScore from '../../components/Evaluation/FinalScore';
import { IRequiredInvalidFields } from '../../components/Evaluation/helper';
import { useUserInfo } from '../../context/UserInfo';
import { routes } from '../../routes';
import {
  EmployeeEvaluationQuery,
  EvaluationAnswer,
  EvaluationTemplateQuestion,
  Maybe,
  useEmployeeEvaluationQuery,
  useSaveEmployeeEvaluationMutation,
} from '../../__generated__/graphql';
import EmployeeInfo from './EmployeeInfo';
import Loading from './Loading';
import { Score } from '../../components/Evaluation/EvaluationTemplate/Question/ScoreCard';
import { DEFAULT_DEBOUNCE_TIME } from '../../../../utils/time';

type EmployeeEvaluation = EmployeeEvaluationQuery['employeeEvaluationById'];

type RouteParams = {
  employeeEvaluationId: string;
};

type Params = {
  employeeEvaluation: EmployeeEvaluation;
};

const isInValidForm = (fields: IRequiredInvalidFields) =>
  isInValidFields(fields);

const isInValidFields = (fields: IRequiredInvalidFields) =>
  Object.values(fields).some((field) => field);

const Container: React.FC<{ workflowId: string | null }> = ({
  children,
  workflowId,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const goBackButtonAction = workflowId
    ? () => {
        history.push(
          routes.workflowsAllWorkflowItems.route.create({ workflowId }),
        );
      }
    : undefined;

  return (
    <div className={'ps-6'}>
      <PageHeader
        title={t('evaluations.employee.title')}
        goBackButtonAction={goBackButtonAction}
      />
      {children}
    </div>
  );
};

export const EvaluationFeedback: React.FC<Params> = ({
  employeeEvaluation,
}) => {
  const { t } = useTranslation();
  const { id: myId } = useUserInfo();
  const { addSuccess } = useToasts();
  const [saveFinalScore, { loading: scoreSaving }] =
    useSaveEmployeeEvaluationMutation();
  const [saveFinalComment, { loading: commentSaving }] =
    useSaveEmployeeEvaluationMutation();
  const [saveEmployeeEvaluation, { loading: saveInProccess }] =
    useSaveEmployeeEvaluationMutation();
  const errorHandler = useMutationErrorHandler();

  const {
    Id: employeeEvaluationId,
    flair__Final_Score__c,
    flair__Overall_Comment__c,
    flair__Evaluated__c: isEvaluated,
    flair__Evaluator__c: employeeId,
    evaluationTemplate,
    answers,
  } = employeeEvaluation;

  const initialRequiredFields: IRequiredInvalidFields =
    evaluationTemplate && evaluationTemplate.questions
      ? getRequiredInvalidFields(
          evaluationTemplate.questions as EvaluationTemplateQuestion[],
          answers as EvaluationAnswer[],
        )
      : {};

  const [requiredInvalidFields, setRequiredInvalidFields] =
    useState<IRequiredInvalidFields>(initialRequiredFields);

  const [finalScore, setFinalScore] = useState<Score>(flair__Final_Score__c);
  const [finalComment, setFinalComment] = useState<Maybe<string>>(
    flair__Overall_Comment__c,
  );

  const handleSubmit = useCallback(() => {
    saveEmployeeEvaluation({
      variables: {
        input: {
          employeeEvaluationId,
          evaluated: true,
          finalComment: null,
          finalScore: null,
        },
      },
    })
      .then(() => {
        addSuccess(t('evaluations.employee.createAnswerSuccessMessage'));
      })
      .catch(errorHandler);
  }, [
    addSuccess,
    employeeEvaluationId,
    errorHandler,
    saveEmployeeEvaluation,
    t,
  ]);

  const saveFinalScoreDebounce = useMemo(
    () =>
      debounce(({ score }: { score: Score }) => {
        saveFinalScore({
          variables: {
            input: {
              employeeEvaluationId,
              finalScore: score ?? null,
              finalComment: null,
              evaluated: false,
            },
          },
        })
          .then(() => {
            setFinalScore(score);
          })
          .catch(errorHandler);
      }, 100),
    [saveFinalScore, employeeEvaluationId, errorHandler],
  );

  const saveFinalCommentDebounce = useMemo(
    () =>
      debounce(({ comment }: { comment: string }) => {
        saveFinalComment({
          variables: {
            input: {
              employeeEvaluationId,
              finalScore: null,
              finalComment: comment,
              evaluated: false,
            },
          },
        })
          .then(() => {
            setFinalComment(comment);
          })
          .catch(errorHandler);
      }, DEFAULT_DEBOUNCE_TIME),
    [saveFinalComment, employeeEvaluationId, errorHandler],
  );

  const handleFinalScoreUpdate = useCallback(
    (score: Score) => {
      saveFinalScoreDebounce({ score });
    },
    [saveFinalScoreDebounce],
  );

  const handleFinalCommentUpdate = useCallback(
    (comment: string) => {
      saveFinalCommentDebounce({ comment });
    },
    [saveFinalCommentDebounce],
  );

  const handleFieldsUpdate = useCallback(
    (fields: IRequiredInvalidFields) => {
      setRequiredInvalidFields({
        ...requiredInvalidFields,
        ...fields,
      });
    },
    [requiredInvalidFields],
  );

  const isInValid = isInValidForm(requiredInvalidFields);
  const readOnly = isEvaluated || employeeId !== myId;

  return useMemo(
    () => (
      <>
        {evaluationTemplate ? (
          <Section>
            <Row>
              <Col xl={12}>
                <EvaluationTemplateContent
                  employeeEvaluationId={employeeEvaluationId}
                  candidateEvaluationId={null}
                  evaluationTemplate={evaluationTemplate}
                  disabled={readOnly}
                  answers={answers as EvaluationAnswer[]}
                  onFieldsUpdate={handleFieldsUpdate}
                />
              </Col>
            </Row>
          </Section>
        ) : (
          <></>
        )}

        <Section>
          <FinalScore
            disabled={readOnly}
            defaultValue={finalScore}
            onChange={handleFinalScoreUpdate}
            loading={scoreSaving}
          />
          <FinalComment
            required={false}
            disabled={readOnly}
            defaultValue={finalComment || ''}
            onChange={handleFinalCommentUpdate}
            loading={commentSaving}
          />
          <div className="float-end d-inline-block">
            {!readOnly && (
              <Button
                disabled={isInValid}
                label={t('evaluations.submitFeedback')}
                isProcessing={saveInProccess}
                onClick={handleSubmit}
                size="sm"
              />
            )}
          </div>
        </Section>
      </>
    ),
    [
      answers,
      employeeEvaluationId,
      commentSaving,
      evaluationTemplate,
      finalComment,
      finalScore,
      handleFieldsUpdate,
      handleFinalCommentUpdate,
      handleFinalScoreUpdate,
      handleSubmit,
      isInValid,
      readOnly,
      saveInProccess,
      scoreSaving,
      t,
    ],
  );
};

const Evaluation: React.FC = () => {
  const { employeeEvaluationId } = useParams<RouteParams>();

  const { data, loading } = useEmployeeEvaluationQuery({
    variables: {
      id: employeeEvaluationId,
    },
  });

  const workflowId =
    data && data.employeeEvaluationById.workflowItem
      ? data.employeeEvaluationById.workflowItem.flair__Workflow__c
      : null;

  if (loading) {
    return <Loading />;
  }

  if (!employeeEvaluationId || !data || !data.employeeEvaluationById)
    return <></>;

  if (loading) {
    return <Loading />;
  }
  return (
    <Container workflowId={workflowId}>
      <Row>
        <Col xl={12}>
          <EmployeeInfo
            employee={data.employeeEvaluationById.employee}
            workflowItem={data.employeeEvaluationById.workflowItem}
          />
          <EvaluationFeedback
            employeeEvaluation={data.employeeEvaluationById}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Evaluation;
