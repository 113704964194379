import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ServerError from '../../../../../../components/ServerError';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { PastPerformanceReviewDetails } from '../../../../components/PastPerformanceReviewDetails';
import {
  MyPerformanceReviewsDocument,
  useAcceptEmployeeFeedbackOutcomeObjectivesMutation,
  useMyPastPerformanceReviewDetailsQuery,
} from '../../../../__generated__/graphql';
import { Loading } from './Loading';

type Params = {
  feedbackId: string;
};

const Content: React.FC<Params> = ({ feedbackId }) => {
  const { data, loading, error } = useMyPastPerformanceReviewDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      feedbackId,
    },
  });

  const [acceptGoals, { loading: acceptingGoals }] =
    useAcceptEmployeeFeedbackOutcomeObjectivesMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [{ query: MyPerformanceReviewsDocument }],
    });
  const errorHandler = useMutationErrorHandler();

  const outcomeId = data?.me.feedback.outcome.Id;
  const handleAcceptGoals = useCallback(async () => {
    if (outcomeId) {
      await acceptGoals({
        variables: {
          input: {
            employeeFeedbackOutcomeId: outcomeId,
          },
        },
      })
        .then()
        .catch(errorHandler);
    }
  }, [acceptGoals, outcomeId, errorHandler]);

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const {
    me: { Name: employeeName, Id: employeeId, feedback },
  } = data;
  const {
    feedbackCycle,
    managerFeedback,
    outcome,
    flair__Is_Peer_Reviewer__c,
  } = feedback;

  const withoutReviewer =
    feedbackCycle.flair__Without_Reviewer__c || !managerFeedback;

  const props = {
    outcomeId: outcome.Id,
    employeeName: employeeName,
    employeeId: employeeId,
    managerId: withoutReviewer ? null : managerFeedback.reviewer.Id,
    managerName: withoutReviewer ? null : managerFeedback.reviewer.Name,
    feedbackCycle,
    questions: feedbackCycle.questions,
    employeeAnswers: feedback.answers,
    managerAnswers: withoutReviewer
      ? feedback.answers
      : managerFeedback.answers,
    resolutions: outcome.resolutions,
    manageGoals: feedbackCycle.flair__Manage_Goals__c,
    canSign:
      feedbackCycle.flair__Signature_required__c &&
      !outcome.flair__Accepted_At__c && // outcome.flair__Accepted_At__c means the employee has not signed yet
      !flair__Is_Peer_Reviewer__c,
    isFinishing: acceptingGoals,
    onFinish: handleAcceptGoals,
    employeeFeedback: feedback,
  };

  // rendering for the employee
  return <PastPerformanceReviewDetails {...props} />;
};

export const PastPerformanceReviewPage: React.FC = () => {
  const { feedbackId } = useParams<Params>();

  return (
    <>
      <Content feedbackId={feedbackId} />
    </>
  );
};
