import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useUpdatePersonalDocumentMutation } from '../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { debounce } from 'lodash';
import { useUserInfo } from '../../../context/UserInfo';

type Props = {
  defaultCategory: string | null | undefined;
  defaultCategoryNotes: string | null;
  documentId: string | undefined;
};

type LoaderType = 'notes' | 'category';

export const useDocumentUpdate = ({
  defaultCategory,
  defaultCategoryNotes,
  documentId,
}: Props) => {
  const [newCategory, setNewCategory] = useState<string | null>(
    defaultCategory ?? '',
  );
  const [newNotes, setNewNotes] = useState<string>(defaultCategoryNotes ?? '');
  const [currentLoaderType, setCurrentLoaderType] = useState<LoaderType | null>(
    null,
  );
  const [updatePersonalDocument, { loading }] =
    useUpdatePersonalDocumentMutation();
  const errorHandler = useMutationErrorHandler();
  const { id: meId } = useUserInfo();

  const saveChanges = useMemo(() => {
    if (documentId) {
      return debounce(
        ({ category, note }) =>
          updatePersonalDocument({
            variables: {
              employeeId: meId,
              input: {
                id: documentId,
                category,
                note,
              },
            },
          })
            .catch(errorHandler)
            .finally(() => setCurrentLoaderType(null)),
        500,
      );
    }
  }, [documentId, errorHandler, meId, updatePersonalDocument]);
  const onChangeCategory = useCallback(
    (value: string | null) => {
      setNewCategory(value);
      if (saveChanges) {
        setCurrentLoaderType('category');
        saveChanges({ category: value, note: newNotes });
      }
    },
    [newNotes, saveChanges],
  );
  const onChangeNotes = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNewNotes(event.target.value);
      if (saveChanges && newCategory) {
        setCurrentLoaderType('notes');
        saveChanges({ category: newCategory, note: event.target.value });
      }
    },
    [newCategory, saveChanges],
  );

  return {
    onChangeNotes,
    onChangeCategory,
    newCategory,
    newNotes,
    loading,
    currentLoaderType,
  };
};
