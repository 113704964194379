import { UpdateCardColleague } from './types';
import { DashboardLastUpdate_ColleagueFragment } from '../../../__generated__/graphql';
import { mapCommonColleagueFields } from '../common/mappings';

export const mapColleague = (
  colleague: DashboardLastUpdate_ColleagueFragment,
): UpdateCardColleague => ({
  ...mapCommonColleagueFields(colleague),
  startDate: colleague.flair__Start_Date__c ?? '',
});
