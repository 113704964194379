import { toISODateOnly } from '../../../../utils/dateUtils';
import { useTimeBalanceForDayQuery } from '../../__generated__/graphql';
import { useUserInfo } from '../../context/UserInfo';

type Props = {
  day: string;
  employeeId: string;
  onlyFromCache?: boolean;
};

export const useTimeBalanceForDay = ({
  employeeId,
  day,
  onlyFromCache,
}: Props): number | null => {
  const { data } = useTimeBalanceForDayQuery({
    fetchPolicy: onlyFromCache ? 'cache-only' : 'cache-first',
    variables: {
      employeeId,
      day,
    },
  });
  const timeBalance = data?.timeSheetDay?.timeBalanceAccumulated;
  return timeBalance !== undefined ? timeBalance : null;
};

export const useTimeBalanceForToday = ({
  onlyFromCache,
}: Pick<Props, 'onlyFromCache'> = {}) => {
  const { id: meId } = useUserInfo();
  const today = toISODateOnly(new Date());
  return useTimeBalanceForDay({
    employeeId: meId,
    day: today,
    onlyFromCache,
  });
};
