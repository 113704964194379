import * as React from 'react';

const StarFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54911)">
        <path
          d="M12.3104 14.9972C12.2051 14.9976 12.1025 14.9648 12.017 14.9034L7.99887 11.9904L3.98076 14.9034C3.89491 14.9657 3.79149 14.9991 3.68544 14.9987C3.5794 14.9983 3.47622 14.9642 3.39083 14.9013C3.30544 14.8384 3.24226 14.75 3.21043 14.6488C3.1786 14.5477 3.17976 14.439 3.21375 14.3386L4.78088 9.69686L0.719338 6.91158C0.631362 6.85132 0.564963 6.7645 0.529842 6.66381C0.494722 6.56313 0.49272 6.45385 0.524129 6.35194C0.555539 6.25003 0.618713 6.16084 0.704423 6.0974C0.790133 6.03395 0.893885 5.99958 1.00052 5.9993H6.01121L7.52336 1.34571C7.55593 1.24524 7.61949 1.15767 7.70492 1.09556C7.79035 1.03345 7.89325 1 7.99887 1C8.10449 1 8.20739 1.03345 8.29282 1.09556C8.37825 1.15767 8.44181 1.24524 8.47438 1.34571L9.98653 6.00086H14.9972C15.104 6.00081 15.208 6.03494 15.2939 6.09827C15.3799 6.16159 15.4433 6.25077 15.4749 6.35276C15.5065 6.45474 15.5047 6.56417 15.4696 6.66501C15.4345 6.76585 15.368 6.85281 15.28 6.91314L11.2169 9.69686L12.7831 14.3373C12.8084 14.4125 12.8156 14.4925 12.8039 14.571C12.7922 14.6494 12.762 14.7239 12.7158 14.7884C12.6696 14.8529 12.6088 14.9054 12.5383 14.9417C12.4678 14.978 12.3897 14.997 12.3104 14.9972Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54911">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000732422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default StarFilledIcon;
