import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import ErrorTooltip from './ErrorTooltip';

type Props = {
  name: string;
  value?: string;
  readonly: boolean;
  error: string | null;
  onChange: (value: string) => void;
};

const Notes: React.FC<Props> = ({ name, value, readonly, error, onChange }) => {
  const target = useRef(null);

  return (
    <>
      <ErrorTooltip target={target.current} error={error} />
      <Form.Control
        name={name}
        as="textarea"
        className="fs-5"
        rows={3}
        ref={target}
        value={value}
        isInvalid={!!error}
        readOnly={readonly}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </>
  );
};

export default Notes;
