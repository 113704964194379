import React from 'react';
import {
  DictionaryItemsByTypeQuery,
  DictionaryItemType,
  useDictionaryItemsByTypeQuery,
} from '../../../../../../../__generated__/graphql';
import InputLabel from '../../../../../../../../../components/form/InputLabel';
import { i18Path } from '../../constants';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { OptionBase } from '../../../../../../../../../components/Select/types';
import ServerError from '../../../../../../../../../components/ServerError';
import { SelectDropdownControlled } from '../../../../../../../../../components/form/Selects';
import { getCurrentLanguage } from '../../../../../../../../../locales/locale';

export const OperatingSystemFormField: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const locale = getCurrentLanguage();

  const { data, loading, error } = useDictionaryItemsByTypeQuery({
    variables: {
      type: DictionaryItemType.OperatingSystem,
      lang: locale,
    },
  });

  if (error) return <ServerError />;

  return (
    <>
      <InputLabel label={t('form.fields.operatingSystem')} />
      <SelectDropdownControlled
        isLoading={loading}
        name="operatingSystem"
        options={mapOperatingSystemOptions(data)}
        heightMode="large"
        placeholder={t('form.fields.operatingSystemPlaceholder')}
      />
    </>
  );
};

const mapOperatingSystemOptions = (
  data: DictionaryItemsByTypeQuery | undefined,
): OptionBase[] => {
  if (!data || !data.dictionaryItemsByType?.length) {
    return [];
  }
  return data.dictionaryItemsByType?.map(({ Id, Name }) => ({
    value: Id,
    label: Name,
  }));
};
