import React from 'react';

type TimeUnits = {
  hours: number;
  minutes: number;
  seconds: number;
};

const timeFromHours = (hours: number) => timeFromMinutes(hours * 60);
const timeFromMinutes = (minutes: number) => timeFromSeconds(minutes * 60);
const timeFromSeconds = (totalSeconds: number): TimeUnits => {
  const hours = Math.trunc(totalSeconds / 60 / 60);
  const minutes = Math.trunc(totalSeconds / 60) % 60;
  const seconds = Math.trunc(totalSeconds % 60);

  return {
    hours,
    minutes,
    seconds,
  };
};

const formatTime = (
  { hours, minutes, seconds }: TimeUnits,
  format: Formats,
) => {
  const formatUnit = (unit: number) => unit.toString().padStart(2, '0');

  switch (format) {
    case 'hh:mm:ss': {
      return `${formatUnit(hours)}:${formatUnit(minutes)}:${formatUnit(
        seconds,
      )}`;
    }
    case 'hh:mm': {
      return `${formatUnit(hours)}:${formatUnit(minutes)}`;
    }
  }
};

type Formats = 'hh:mm:ss' | 'hh:mm';
type Duration =
  | {
      hours: number;
    }
  | { minutes: number };
type Props = Duration & {
  format: Formats;
};

const Time: React.FC<Props> = ({ format, ...props }) => {
  const time =
    'hours' in props
      ? timeFromHours(props.hours)
      : timeFromMinutes(props.minutes);

  return <time>{formatTime(time, format)}</time>;
};

export default Time;
