import * as React from 'react';

const StatusApprovedIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="32" height="32" rx="8" fill="#E7FBE2" />
      <path
        d="M24 10L13 21L8 16"
        stroke="#66B854"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default StatusApprovedIcon;
