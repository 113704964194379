import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoalHint } from '../../GoalHint';

const i18prefix = 'performanceReview.goals2.goalSidebar';

export const GoalStatusHint = () => {
  const { t } = useTranslation();

  return <GoalHint text={t(`${i18prefix}.goalStatusHint`)} />;
};
