import {
  isCurrentDateInRange,
  parseDate,
  toISODateOnly,
} from '../../../../utils/dateUtils';
import {
  AbsenceApprovalStatus,
  useTimeTrackingSettingsAllDaysQuery,
} from '../../__generated__/graphql';
import { useUserInfo } from '../../context/UserInfo';
import { getWorkloadForDate } from '../../utils/timesheetHelper';
import {
  TimeTrackingSettings,
  mapTimeTrackingSettings,
} from './useTimeTrackingSettings';

export const useTimeTrackingSettingsAllDays = () => {
  const { id: meId } = useUserInfo();
  const { data, loading } = useTimeTrackingSettingsAllDaysQuery({
    variables: { meId },
  });

  return {
    loading,
    getAllTimeTrackingSettings: (): TimeTrackingSettings[] | undefined => {
      if (!data) {
        return undefined;
      }
      return data.me.workloads.map(mapTimeTrackingSettings);
    },
    getTimeTrackingSettingForDay: (
      day: Date,
    ): TimeTrackingSettings | undefined => {
      if (!data) {
        return undefined;
      }
      const workloads = data.me.workloads;
      const workloadForDate = getWorkloadForDate(workloads, day);
      return mapTimeTrackingSettings(workloadForDate);
    },
    hasOngoingRestrictedAbsenceForDay: (day: string, now?: Date): boolean => {
      if (!data) {
        return false;
      }
      if (!now) {
        now = new Date();
      }
      const dateToCheck = parseDate(day);
      // if date is today then checking also time otherwise check only by day start
      const isCheckingForToday = toISODateOnly(now) === day;
      if (isCheckingForToday) {
        dateToCheck.setHours(now.getHours());
        dateToCheck.setMinutes(now.getMinutes());
        dateToCheck.setSeconds(now.getSeconds());
        return data.employeeAbsences.some(
          (item) =>
            item.flair__Approval_Status__c !== AbsenceApprovalStatus.Rejected &&
            item.flair__Category_Time_Tracking_Restricted__c &&
            isCurrentDateInRange(
              dateToCheck,
              item.flair__Start_Date__c,
              item.flair__End_Date__c,
              item.flair__Start_Time__c,
              item.flair__End_Time__c,
            ),
        );
      } else {
        return data.employeeAbsences.some(
          (item) =>
            item.flair__Approval_Status__c !== AbsenceApprovalStatus.Rejected &&
            item.flair__Category_Time_Tracking_Restricted__c &&
            isCurrentDateInRange(
              dateToCheck,
              item.flair__Start_Date__c,
              item.flair__End_Date__c,
              '00:00:00.000',
              '23:59:59.000',
            ),
        );
      }
    },
  };
};
