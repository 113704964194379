import * as React from 'react';

const StatusRejectedIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="32" height="32" rx="8" fill="#FED5CF" />
      <path
        d="M23.2806 22.2194C23.3503 22.2891 23.4056 22.3718 23.4433 22.4629C23.481 22.5539 23.5004 22.6515 23.5004 22.7501C23.5004 22.8486 23.481 22.9462 23.4433 23.0372C23.4056 23.1283 23.3503 23.211 23.2806 23.2807C23.2109 23.3504 23.1282 23.4056 23.0372 23.4433C22.9461 23.4811 22.8485 23.5005 22.75 23.5005C22.6515 23.5005 22.5539 23.4811 22.4628 23.4433C22.3718 23.4056 22.2891 23.3504 22.2194 23.2807L16 17.0604L9.78062 23.2807C9.63989 23.4214 9.44902 23.5005 9.25 23.5005C9.05097 23.5005 8.8601 23.4214 8.71937 23.2807C8.57864 23.1399 8.49958 22.9491 8.49958 22.7501C8.49958 22.551 8.57864 22.3602 8.71937 22.2194L14.9397 16.0001L8.71937 9.78068C8.57864 9.63995 8.49958 9.44907 8.49958 9.25005C8.49958 9.05103 8.57864 8.86016 8.71937 8.71943C8.8601 8.5787 9.05097 8.49963 9.25 8.49963C9.44902 8.49963 9.63989 8.5787 9.78062 8.71943L16 14.9397L22.2194 8.71943C22.3601 8.5787 22.551 8.49963 22.75 8.49963C22.949 8.49963 23.1399 8.5787 23.2806 8.71943C23.4214 8.86016 23.5004 9.05103 23.5004 9.25005C23.5004 9.44907 23.4214 9.63995 23.2806 9.78068L17.0603 16.0001L23.2806 22.2194Z"
        fill="#FD553E"
      />
    </svg>
  );
});

export default StatusRejectedIcon;
