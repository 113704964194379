import React from 'react';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import styled from '@emotion/styled';
import { Theme } from '../../../../../../theme';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

const i18Path = 'support.tickets.newTicket.whistleblowerProtection';
export const WhistleBlowerProtectionSection: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <WhistleBlowerWrapper className="d-flex flex-row rounded gap-3">
      <FlairIcon
        icon="information-circle-outline"
        className="flex-shrink-0"
        size="xl"
      />
      <div>
        <h5 className="h5-regular m-0">
          {t('mainText')}
          <LearnMoreWrapper className="h5-medium ms-2 cu-pointer">
            {t('learnMore')}
          </LearnMoreWrapper>
        </h5>
      </div>
    </WhistleBlowerWrapper>
  );
};

const WhistleBlowerWrapper = styled.div({
  background: Theme.color.paper7,
  padding: '0.75rem',
});

const LearnMoreWrapper = styled.span({
  color: Theme.color.blue3,
});
