import * as React from 'react';
import { ProjectActivity } from '../types';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';
import { i18prefix } from '../helpers';
import i18next from 'i18next';

import Activity from '../../../components/Activity';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  activity: ProjectActivity;
};

export const ProjectActivityItem: React.FC<Props> = ({ activity }) => {
  return (
    <Activity
      iconComponent={getActivityIcon(activity)}
      text={getText(activity)}
      date={activity.updatedAt}
    />
  );
};

const getText = (activity: ProjectActivity): string => {
  const activityI18Prefix = `${i18prefix}.activity`;

  switch (activity.type) {
    case 'CREATE':
      return activity.employee?.name
        ? i18next.t(`${activityI18Prefix}.CREATE`, {
            employee: activity.employee?.name,
          })
        : i18next.t(`${activityI18Prefix}.CREATE_NO_EMPLOYEE`);
    case 'SET_DESCRIPTION':
      return i18next.t(`${activityI18Prefix}.${activity.type}`, {
        employee: activity.employee?.name,
      });
    default:
      return i18next.t(`${activityI18Prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        value: activity.value,
      });

    case 'MARK_AS_BILLABLE':
    case 'MARK_AS_NON_BILLABLE':
      return i18next.t(`${activityI18Prefix}.${activity.type}`, {
        employee: activity.employee?.name,
      });
  }
};

const getActivityIcon = (activity: ProjectActivity) => {
  switch (activity.type) {
    case 'CREATE':
      return <Avatar initials={activity.employee?.initials ?? ''} size="xxs" />;
    default:
      return <FlairIcon size="xs" icon="pencil-outline" />;
  }
};
