import React from 'react';
import { t } from 'i18next';
import { NotificationTypes } from 'server/src/shared/NotificationTypes';
import AbsenceDateTimeRange from '../../AbsenceDateTimeRange';

import {
  NotificationIconType,
  NotificationItemData,
  NotificationQueryItem,
} from '../types';
import { Absence, AbsenceType } from '../../../__generated__/graphql';
import { routes } from '../../../routes';
import {
  AbsenceNotificationJsonBody,
  safeJsonParse,
} from './NotificationJSONBodyParser';

const i18prefix = 'notifications.absences';

const iconsByType = new Map<string, NotificationIconType>([
  [NotificationTypes.ABSENCE_APPROVED, 'checkmark-outline'],
  [NotificationTypes.ABSENCE_CREATED, 'umbrella-outline'],
  [NotificationTypes.ABSENCE_REJECTED, 'close-outline'],
  [NotificationTypes.ABSENCE_DELETED, 'umbrella-outline'],
]);

const descriptionsByType = new Map([
  [NotificationTypes.ABSENCE_APPROVED, t(`${i18prefix}.approved`)],
  [NotificationTypes.ABSENCE_CREATED, t(`${i18prefix}.created`)],
  [NotificationTypes.ABSENCE_REJECTED, t(`${i18prefix}.rejected`)],
  [NotificationTypes.ABSENCE_DELETED, t(`${i18prefix}.deleted`)],
]);

export const mapAbsenceNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };
  data.icon = iconsByType.get(src.flair__Type__c);
  data.description = descriptionsByType.get(src.flair__Type__c) || '';

  if (src.renderInfo.__typename === 'AbsenceNotificationRenderInfo') {
    // Check required fields. If not present, the notification won't be rendered.
    if (
      !src.renderInfo.absence?.employee &&
      src.flair__Type__c !== NotificationTypes.ABSENCE_DELETED
    ) {
      return null;
    }
    if (src.renderInfo.absence) {
      if (src.flair__Type__c === NotificationTypes.ABSENCE_CREATED) {
        const employee = src.renderInfo.absence?.employee;
        if (employee) {
          data.url = routes.teamCalendar.route
            .withQueryParams({ colleaguesIds: employee.Id })
            .create({});
          data.title = descriptionsByType.get(src.flair__Type__c) || '';
          data.description = employee.Name;
        }
      } else {
        data.url = routes.myAbsences.route.create({});
      }

      type AbsenceWithType = Pick<
        Absence,
        | 'flair__Type__c'
        | 'flair__Start_Date__c'
        | 'flair__Start_Date_Half_Day__c'
        | 'flair__Start_Time__c'
        | 'flair__End_Date__c'
        | 'flair__End_Date_Half_Day__c'
        | 'flair__End_Time__c'
      >;
      let absenceWithType: AbsenceWithType = {
        flair__Type__c: AbsenceType.Daily,
        ...src.renderInfo.absence,
      };
      data.urlLabel = (
        <AbsenceDateTimeRange absence={absenceWithType} dateFormat="short" />
      );
    }

    if (src.flair__Type__c === NotificationTypes.ABSENCE_DELETED) {
      data.title = descriptionsByType.get(src.flair__Type__c) || '';
      if (src.flair__Body__c) {
        const body = safeJsonParse<AbsenceNotificationJsonBody>(
          src.flair__Body__c,
        );
        if (body && body.employeeName && body.absenceName) {
          data.description = `${body.employeeName} ${body.absenceName}`;
        }
      }
    }
  }

  return data;
};
