import React from 'react';

import {
  Props as WorkflowsItemsFilterProps,
  WorkflowItemsFilter,
} from './WorkflowItemsFilter';

import {
  WorkflowItemFilterAllOptions,
  WorkflowItemFilterForWorkflow,
} from './types';
import { WorkflowItemsFilterLoading } from './WorkflowItemsFilterLoading';
import {
  useWorkflowItemsFilterForWorkflowQuery,
  WorkflowContext,
} from '../../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { mapCommonOption } from './mappings';

type Props = Omit<
  WorkflowsItemsFilterProps,
  | 'workflowTemplateOptions'
  | 'workflowItemTemplateOptions'
  | 'filter'
  | 'onChange'
  | 'responsibleOptions'
> & {
  filter: WorkflowItemFilterForWorkflow;
  onChange: (filter: WorkflowItemFilterForWorkflow) => void;
  employeeColumnVisible: boolean;
  workflowId: string;
};

export const WorkflowItemsFilterForWorkflowConnected: React.FC<Props> = ({
  filter,
  onChange,
  employeeColumnVisible,
  workflowId,
  ...props
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  const { data, loading, error } = useWorkflowItemsFilterForWorkflowQuery({
    variables: {
      workflowId,
    },
  });

  const handleOnChange = (value: WorkflowItemFilterAllOptions) => {
    onChange({
      responsibleId: value.responsibleId,
      status: value.status,
      employeeId: employeeColumnVisible ? value.employeeId : null,
      onlyMyTasks: value.onlyMyTasks,
    });
  };

  if (error) {
    return <div>{t('errorLoadingOptions')}</div>;
  }

  if (loading || !data) {
    return <WorkflowItemsFilterLoading workflowContext={WorkflowContext.My} />;
  }

  const { responsibleEmployees, responsibleTeams } =
    data.workflowItemsFilterOptions;

  const responsibleOptions = [...responsibleEmployees, ...responsibleTeams].map(
    mapCommonOption,
  );

  return (
    <WorkflowItemsFilter
      {...props}
      filter={{
        ...filter,
        workflowItemTemplateId: null,
        workflowTemplateId: null,
        year: null,
        startDateTo: null,
        startDateFrom: null,
        employeeId: employeeColumnVisible ? filter.employeeId : null,
      }}
      onChange={handleOnChange}
      onlyMyTasksVisible={false}
      responsibleOptions={responsibleOptions}></WorkflowItemsFilter>
  );
};
