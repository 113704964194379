import React from 'react';
import {
  DEPARTMENT_FILTER,
  Job,
  JobsFiltersType,
  LOCATIONS_FILTER,
  NAME_FILTER,
} from '../types';
import { useTranslation } from 'react-i18next';
import { FilterSelectOption } from '../../../../../atomic/molecules/FlairDropdown/types';
import JobsMultipleFilter from './JobsMultipleFilter';
import JobsTextFilter from './JobsTextFilter';
import { uniqBy } from 'lodash';

type Props = {
  filterData: JobsFiltersType;
  jobs: Job[];
};

const FilterItem: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => {
  return <div className={`me-3 ${className}`}>{children}</div>;
};

const JobsFilters: React.FC<Props> = ({ jobs, filterData }) => {
  const { t } = useTranslation();

  const locationOptions: FilterSelectOption[] = uniqBy(
    jobs
      .filter((job) => job.location != null)
      .map((job) => ({
        label: job.location?.Name || '-',
        value: job.location?.Id || '-',
      })),
    'value',
  );

  const departmentOptions: FilterSelectOption[] = uniqBy(
    jobs
      .filter((job) => job.department != null)
      .map((job) => ({
        label: job.department?.Name || '-',
        value: job.department?.Id || '-',
      })),
    'value',
  );

  return (
    <div className="d-flex align-items-center small">
      <FilterItem>
        <JobsTextFilter
          placeholder={t('recruitment.jobsPage.filters.name')}
          filterData={filterData}
          filterKey={NAME_FILTER}
        />
      </FilterItem>
      <FilterItem>
        <JobsMultipleFilter
          options={departmentOptions}
          filterData={filterData}
          filterKey={DEPARTMENT_FILTER}
          placeholder={t('recruitment.jobsPage.filters.department')}
        />
      </FilterItem>
      <FilterItem>
        <JobsMultipleFilter
          options={locationOptions}
          filterData={filterData}
          filterKey={LOCATIONS_FILTER}
          placeholder={t('recruitment.jobsPage.filters.location')}
        />
      </FilterItem>
    </div>
  );
};

export default JobsFilters;
