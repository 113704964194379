import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InventoryModalContext } from '../../InventoryModalContext';
import { InventoryForm } from '../InventoryForm';
import { PurchaseFields } from '../PurchaseFields';
import { SerialNumberFormField } from '../FormFields/SerialNumberFormField';

export const OfficeEquipmentForm: React.FC = () => {
  const { isExpanded = false } = useContext(InventoryModalContext);

  return (
    <React.Fragment>
      <Row>
        <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
          <InventoryForm />
        </Col>
        <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
          <SerialNumberFormField />
        </Col>
        <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
          <PurchaseFields />
        </Col>
      </Row>
    </React.Fragment>
  );
};
