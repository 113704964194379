import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';

type Props = Pick<BadgeProps, 'pill'> & {
  className?: string;
  variant?: BadgeProps['bg'];
  children?: React.ReactNode;
};

const SoftBadge = React.forwardRef<HTMLSpanElement, Props>(
  ({ className, variant = 'primary', children, ...rest }, ref) => (
    <Badge
      ref={ref}
      className={[`bg-${variant}-soft`, className].join(' ')}
      {...rest}>
      {children}
    </Badge>
  ),
);

export default SoftBadge;
