import React from 'react';
import { Tickets } from './Tickets';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { routes as mainRoutes } from '../../../routes';
import { emptyTicketsFilter } from '../common';
import { mapTicketsListFilterInput } from './Filters/mappings';
import { getCurrentLanguage } from '../../../../../locales/locale';
import { useTicketListQuery } from '../../../__generated__/graphql';
import { mapSortBy, mapTicketList } from './mappings';
import { useUserInfo } from '../../../context/UserInfo';
import { TicketsListItem } from './types';
import { TicketsFilter } from './Filters/types';

const PAGE_SIZE = 50;
export const TicketsConnected: React.FC = () => {
  const isManagerView = false;
  const locale = getCurrentLanguage();
  const { id: meId } = useUserInfo();

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter<TicketsListItem, TicketsFilter>({
    defaultFilter: { ...emptyTicketsFilter, status: 'OPEN_IN_PROGRESS' },
    storageKey: 'tickets_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.tickets.route.withQueryParams(queryParams).create({}),
  });

  const { data, loading, error } = useTicketListQuery({
    variables: {
      employeeId: meId,
      lang: locale,
      filter: mapTicketsListFilterInput(tableState.filter),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });
  const tableItemsTotalCount =
    data?.ticketPagination.paginationInfo?.totalCount;

  const allTickets = data ? mapTicketList(data) : [];

  return (
    <Tickets
      isManager={isManagerView}
      pageSize={PAGE_SIZE}
      data={allTickets}
      tableItemsTotalCount={tableItemsTotalCount}
      tableStateFilter={tableState.filter}
      tableStateSortBy={tableState.sortBy}
      tableStatePageIndex={tableState.pageIndex}
      handleFilterChange={handleFilterChange}
      handlePageIndexChanged={handlePageIndexChanged}
      handleSortByChanged={handleSortByChanged}
      loading={loading}
      error={error}
    />
  );
};
