import React, { createContext, useEffect, useRef } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useAuth } from '../../../../context/AuthContext';
import { WebSocketMessage } from './';

const getWebsocketBaseUrl = () => {
  if (import.meta.env.VITE_APP_WEBSOCKET_URL) {
    return import.meta.env.VITE_APP_WEBSOCKET_URL;
  }
  return `wss://${window.location.host}/ws`;
};

// Send frame to websocket to keep AWS connection alive.
// By default AWS drop connection after 10 minutes of silence
const HEARTBEAT_INTERVAL = 9 * 60 * 1000;

export const WebSocketContext = createContext<{}>({});

type Props = {
  socketStateChanged: (opened: boolean, openTryNumber: number) => void;
  messageReceived: (message: WebSocketMessage) => void;
};

export const WebSocketProvider: React.FC<Props> = ({
  messageReceived,
  socketStateChanged,
  ...rest
}) => {
  const ws = useRef<ReconnectingWebSocket | null>(null);
  const wsOpenAttempts = useRef<number>(0);
  const heartBeatIntervalId = useRef<NodeJS.Timeout | null>(null);

  const { apiToken } = useAuth();

  useEffect(() => {
    if (!apiToken) {
      return;
    }

    const heartBeat = () => {
      const webSocket = ws.current;
      if (!webSocket) {
        return;
      }
      if (webSocket.readyState !== webSocket.OPEN) {
        return;
      }
      webSocket.send(JSON.stringify({ message: 'ping' }));
    };

    const baseUrl = getWebsocketBaseUrl();
    const url = `${baseUrl}?authorization=${encodeURIComponent(apiToken)}`;
    ws.current = new ReconnectingWebSocket(url);
    ws.current.onopen = () => {
      wsOpenAttempts.current = wsOpenAttempts.current + 1;
      socketStateChanged(true, wsOpenAttempts.current);
      heartBeatIntervalId.current = setInterval(heartBeat, HEARTBEAT_INTERVAL);
    };
    ws.current.onclose = () => {
      if (heartBeatIntervalId.current) {
        clearInterval(heartBeatIntervalId.current);
      }
      socketStateChanged(false, wsOpenAttempts.current);
    };
    ws.current.onmessage = (m) => {
      const message = JSON.parse(m.data) as WebSocketMessage;
      messageReceived(message);
    };

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  }, [ws, apiToken, messageReceived, socketStateChanged]);

  return <WebSocketContext.Provider value={{}} {...rest} />;
};
