import * as React from 'react';
import TopNavAvatarWithActions from './TopNavAvatarWithActions';
import Notifications from './Notifications';
import './TopNav.css';
import TopNavBreadcrumb from './TopNavBreadcrumb';
import FlairButtonIcon from '../../../../atomic/molecules/FlairButtonIcon';
import { useIsScreenSizeLargerThanOrEqual } from '../../../../hooks/useIsScreenSizeLargerThan';
import { MenuBarContext } from '../../context/MenuBar';
import { useContext } from 'react';

const TopNav: React.FC = () => {
  const isLargeScreen = useIsScreenSizeLargerThanOrEqual('lg');
  const { isExpanded, setIsExpanded } = useContext(MenuBarContext);

  const onToggleMenuBar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="top-nav-container d-flex align-items-center position-sticky top-0"
      data-testid="TopNav">
      {isLargeScreen && <TopNavBreadcrumb />}
      {!isLargeScreen && (
        <FlairButtonIcon
          icon="hamburger-menu"
          onClick={onToggleMenuBar}
          size="xl"
          variant="text"
        />
      )}
      <div className="top-nav-actions d-flex align-items-center gap-4">
        <Notifications />
        <TopNavAvatarWithActions />
      </div>
    </div>
  );
};

export default TopNav;
