import * as React from 'react';

const DocumentOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M12.6893 6.78819V13.596C12.6893 14.041 12.5095 14.4678 12.1893 14.7825C11.8692 15.0972 11.435 15.274 10.9822 15.274H3.0157C2.56295 15.274 2.12874 15.0972 1.80859 14.7825C1.48845 14.4678 1.30859 14.041 1.30859 13.596V2.40986C1.30859 1.96485 1.48845 1.53806 1.80859 1.22339C2.12874 0.908715 2.56295 0.731934 3.0157 0.731934H6.52772C6.82945 0.731978 7.11881 0.849794 7.33219 1.05948L12.3561 5.99747C12.5694 6.20721 12.6893 6.49162 12.6893 6.78819Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinejoin="round"
      />
      <path
        d="M7 1.01318V5.208C7 5.50467 7.1199 5.7892 7.33333 5.99898C7.54676 6.20876 7.83624 6.32661 8.13807 6.32661H12.4058"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default DocumentOutlineIcon;
