import React from 'react';
import { GoalId } from '../types';
import { GoalSidebarCreate } from './GoalSidebarCreate';
import { GoalSidebarUpdate } from './GoalSidebarUpdate';

type Props = {
  visible: boolean;
  goalId: GoalId | null;
  onClose: () => void;
};

export const GoalSidebar: React.FC<Props> = ({ goalId, ...rest }) => {
  if (goalId !== null) {
    return <GoalSidebarUpdate {...rest} goalId={goalId} />;
  }
  return <GoalSidebarCreate {...rest} />;
};
