import React from 'react';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import FiltersLoading from './CandidatesListFilter/FiltersLoading';

const Loading: React.FC = () => {
  return (
    <>
      <div className="mb-3">
        <FiltersLoading />
      </div>
      <SkeletonTable className="card-table" columns={4} rows={6} />
    </>
  );
};

export default Loading;
