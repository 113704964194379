import { ManagerRole } from '../../__generated__/graphql';

export const managerRolesWhoHasAccessToDocuments = [
  ManagerRole.Manager,
  ManagerRole.SeeEmployeeData,
];

export const DOCUMENT_OWNERS = {
  HR: 'HR',
  EMPLOYEE: 'EMPLOYEE',
};
