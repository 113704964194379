import React from 'react';
import { EmployeeNotificationSettingsQuery } from '../../../../__generated__/graphql';
import { Card, Col, Row } from 'react-bootstrap';
import { NOTIFICATION_ALL_CHANNELS, NotificationCategoryItem } from '../types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import '../index.css';
import { Theme } from '../../../../../../theme';
import NotificationSettingToggle from './NotificationSettingToggle';
import { useUserInfo } from '../../../../context/UserInfo';

type NotificationSettingsCategoryCardProps = {
  data: EmployeeNotificationSettingsQuery;
  categoryItem: NotificationCategoryItem;
};
const NotificationSettingsCategoryCard: React.FC<
  NotificationSettingsCategoryCardProps
> = ({ data, categoryItem }) => {
  const { hasSlackEnabled } = useUserInfo();

  const t = useNamespacedTranslation('personalData.notificationSettings2');

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row className="align-items-center pb-3">
            <Col>
              <h2>{t(`categories.${categoryItem.category}.name`)}</h2>
            </Col>
            {NOTIFICATION_ALL_CHANNELS.map((channel) => {
              if (channel === 'slack' && !hasSlackEnabled) {
                return null;
              }
              return (
                <Col
                  className="notification-channel-col p-0 d-flex justify-content-center"
                  style={{ color: Theme.color.gray2 }}
                  xs={1}
                  key={channel}>
                  {t(`channelHeaderTitles.${channel}`)}
                </Col>
              );
            })}
          </Row>
        </Card.Title>
        {categoryItem.notifications.map((notification, index) => {
          if (notification.showCondition === false) {
            return null;
          }
          const i18PathNotification = `categories.${categoryItem.category}.notifications.${notification.name}`;
          return (
            <React.Fragment key={index}>
              {index !== 0 && <hr className="my-3" />}
              <Row className="align-items-center py-1">
                <Col>
                  <h5>{t(`${i18PathNotification}.name`)}</h5>
                  <div>{t(`${i18PathNotification}.description`)}</div>
                </Col>
                {NOTIFICATION_ALL_CHANNELS.map((channel) => {
                  if (channel === 'slack' && !hasSlackEnabled) {
                    return null;
                  }
                  return (
                    <Col
                      className="notification-channel-col p-0 d-flex justify-content-center"
                      key={channel}
                      xs={1}>
                      <NotificationSettingToggle
                        data={data}
                        name={notification.name}
                        channel={channel}
                      />
                    </Col>
                  );
                })}
              </Row>
            </React.Fragment>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default NotificationSettingsCategoryCard;
