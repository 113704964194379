import { useState, useCallback } from 'react';
import { isTableRowTimeSheet, TableRow } from './types';
import { uniq } from 'lodash';
import { TimeSheetStatus } from '../../__generated__/graphql';

export const isSelectDisabled = (
  row: TableRow,
  approvingIdsSet: Set<string>,
): boolean => {
  const approving = isTableRowTimeSheet(row)
    ? approvingIdsSet.has(row.timeSheetId)
    : undefined;
  const isTableRowAndNotInPendingState =
    isTableRowTimeSheet(row) && row.approveStatus !== TimeSheetStatus.Pending;
  return approving === true || isTableRowAndNotInPendingState;
};

export const useTimeSheetsTableSelection = (
  allTableRows: TableRow[],
  approvingIds: string[],
) => {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const onSelectAll = useCallback(
    (allTableRows: TableRow[], selected: boolean) => {
      const approvingIdsSet = new Set(approvingIds);
      if (selected) {
        setSelectedRowIds(
          allTableRows
            .filter((x) => !isSelectDisabled(x, approvingIdsSet))
            .map((x) => x.id),
        );
      } else {
        setSelectedRowIds([]);
      }
    },
    [setSelectedRowIds, approvingIds],
  );

  const onSelectRow = useCallback(
    (allTableRows: TableRow[], tableRow: TableRow, selected: boolean) => {
      const selectedId = tableRow.id;
      const approvingIdsSet = new Set(approvingIds);
      const affectedRowIds = allTableRows.reduce((acc, curRow) => {
        if (
          (curRow.id === selectedId ||
            (isTableRowTimeSheet(curRow) && curRow.headerId === selectedId)) &&
          !isSelectDisabled(curRow, approvingIdsSet)
        ) {
          return [...acc, curRow.id];
        }
        return acc;
      }, new Array<string>());
      if (selected) {
        setSelectedRowIds((prev) => uniq([...prev, ...affectedRowIds]));
      } else {
        setSelectedRowIds((prev) =>
          prev.filter((curId) => !affectedRowIds.includes(curId)),
        );
      }
    },
    [setSelectedRowIds, approvingIds],
  );

  const selectedTimeSheetIds: string[] = allTableRows
    .filter((x) => isTableRowTimeSheet(x) && selectedRowIds.includes(x.id))
    .map((x) => x.timeSheetId!);

  return { selectedRowIds, selectedTimeSheetIds, onSelectAll, onSelectRow };
};
