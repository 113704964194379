import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { SelectionBottomActionButton } from '../../../components/SelectionBottomPanel/SelectionBottomActionButton';
import { Theme } from '../../../../../theme';

type TableBottomBannerProps = {
  onAccept: () => void;
  onReject: () => void;
};

const i18Prefix = 'absences.myAbsences.massActions';

export const TableBottomBanner: React.FC<TableBottomBannerProps> = ({
  onAccept,
  onReject,
}) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <>
      <SelectionBottomActionButton
        label={t(`buttons.accept`)}
        onClick={onAccept}
      />
      <SelectionBottomActionButton
        label={t(`buttons.reject`)}
        onClick={onReject}
        bgColor={Theme.color.red1}
      />
    </>
  );
};
