import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

const ProjectsHeader: React.FC = () => {
  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.time.title') },
    { label: t('timeTracking.subMenuItems.projects.title') },
  ]);

  return (
    <PageHeader title={t('timeTracking.subMenuItems.projects.title')}>
      {/*  TODO check with Pierre if we need the overview*/}
      {/*<PageHeaderRow>*/}
      {/*  <Col>*/}
      {/*    <ProjectNav />*/}
      {/*  </Col>*/}
      {/*</PageHeaderRow>*/}
    </PageHeader>
  );
};

export default ProjectsHeader;
