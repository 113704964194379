export type TimeEntryChangeRequestNotificationJsonBody = {
  startDatetime: string;
  endDatetime: string;
};

export type AbsenceNotificationJsonBody = {
  employeeName: string;
  absenceName: string;
};

/**
 * Parses JSON string to an object.
 * When JSON string is invalid it returns undefined
 * NOTE: this does not guarantee that parsed object matches generic type `T`
 * @param str JSON string
 * @returns parsed object
 */
export const safeJsonParse = <T>(str: string) => {
  try {
    const jsonValue: T = JSON.parse(str);

    return jsonValue;
  } catch {
    return undefined;
  }
};
