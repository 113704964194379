import * as React from 'react';

const SmileSolidIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_1465_43023)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6769 1.51872C11.5274 1.87467 12.2991 2.39515 12.9478 3.05026C13.6029 3.69895 14.1234 4.47069 14.4793 5.32114C14.8353 6.17159 15.0197 7.08398 15.022 8.00592C15.0242 8.92785 14.8443 9.84115 14.4926 10.6933C14.1408 11.5455 13.6241 12.3198 12.9722 12.9717C12.3203 13.6236 11.546 14.1403 10.6938 14.4921C9.84163 14.8438 8.92834 15.0237 8.00641 15.0215C7.08447 15.0192 6.17207 14.8348 5.32163 14.4788C4.47118 14.1229 3.69944 13.6024 3.05075 12.9473C2.39564 12.2986 1.87516 11.5269 1.51921 10.6764C1.16326 9.82598 0.978853 8.91358 0.976584 7.99165C0.974315 7.06972 1.15423 6.15642 1.50599 5.30423C1.85775 4.45204 2.37442 3.67775 3.02633 3.02584C3.67824 2.37394 4.45253 1.85726 5.30472 1.5055C6.15691 1.15374 7.0702 0.973826 7.99214 0.976095C8.91407 0.978365 9.82647 1.16277 10.6769 1.51872ZM6.5026 7.25064C6.5026 7.66571 6.16612 8.00219 5.75105 8.00219C5.33599 8.00219 4.99951 7.66571 4.99951 7.25064C4.99951 6.83557 5.33599 6.49909 5.75105 6.49909C6.16612 6.49909 6.5026 6.83557 6.5026 7.25064ZM10.2504 8.00219C10.6655 8.00219 11.0019 7.66571 11.0019 7.25064C11.0019 6.83557 10.6655 6.49909 10.2504 6.49909C9.83533 6.49909 9.49885 6.83557 9.49885 7.25064C9.49885 7.66571 9.83533 8.00219 10.2504 8.00219ZM6.16023 9.83839C6.07192 9.57681 5.78829 9.43635 5.52671 9.52466C5.26514 9.61297 5.12468 9.8966 5.21299 10.1582C5.58389 11.2568 6.72096 11.9978 7.99965 11.9978C9.27792 11.9978 10.4162 11.257 10.7845 10.157C10.8722 9.89519 10.731 9.61191 10.4692 9.52426C10.2074 9.43661 9.92415 9.57778 9.8365 9.83957C9.62527 10.4705 8.91673 10.998 7.99965 10.998C7.08298 10.998 6.37369 10.4707 6.16023 9.83839Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1465_43023">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default SmileSolidIcon;
