import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  title: string;
  onClick: () => void;
  disabled: boolean;
};

export const CancelButton: React.FC<Props> = ({ title, disabled, onClick }) => {
  return (
    <Button
      type="button"
      size="sm"
      variant="outline-primary"
      className="ms-2 d-flex align-items-center"
      disabled={disabled}
      onClick={onClick}>
      <span>{title}</span>
    </Button>
  );
};

export default CancelButton;
