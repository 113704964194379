import React from 'react';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

export type Period = 'last6Months' | 'lastYear';

export type Props = {
  value: Period;
  onChange: (value: Period) => void;
};

export const AbsenceRatePeriodFilter: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const t = useNamespacedTranslation(
    'analytics.absenceRatePage.periodDropdown',
  );

  const handleChange = (newValue: string | null) => {
    if (newValue) {
      onChange(newValue as Period);
    }
  };

  const allPeriods: FilterSelectOption[] = [
    { value: 'last6Months', label: t('last6Months') },
    { value: 'lastYear', label: t('lastYear') },
  ];

  return (
    <div style={{ maxWidth: '12rem' }}>
      <SingleFilter
        options={allPeriods}
        value={value}
        onChange={handleChange}
        isClearable={false}
        className="h6-regular"
      />
    </div>
  );
};
