import { Emojis } from '../../../pages/People/Announcements/types';
import { groupBy } from 'lodash';
import { Emoji } from '../../Announcement';
import {
  Reaction,
  ReactionsRelatedObjectName,
  ReactionsRelatedObjectNames,
  RelatedObjectTypeName,
  RelatedObjectTypeNames,
} from './types';
import { ReactionFragment } from '../../../__generated__/graphql';

export const groupAndMapReactionsToEmojis = (reactions: Reaction[]): Emojis => {
  return groupBy(reactions.map(mapEmoji), 'name');
};

const mapEmoji = (r: Reaction): Emoji => ({
  id: r.id,
  name: r.text,
  recordId: r.relatedId,
  employee: {
    id: r.employee.id,
    name: r.employee.name,
  },
});

export const mapReaction = (r: ReactionFragment): Reaction => ({
  id: r.Id,
  text: r.flair__Text__c,
  relatedObjectName: r.flair__Related_Object_Name__c,
  relatedId: r.flair__Related_Id__c,
  employee: {
    id: r.employee.Id,
    name: r.employee.Name,
  },
});

export const getTypeName = (
  relatedObjectName: ReactionsRelatedObjectName,
): RelatedObjectTypeName => {
  if (relatedObjectName === ReactionsRelatedObjectNames.Celebration) {
    return RelatedObjectTypeNames.Celebration;
  } else if (relatedObjectName === ReactionsRelatedObjectNames.Comment) {
    return RelatedObjectTypeNames.Comment;
  } else {
    throw new Error(
      `getTypeName for ${relatedObjectName} is not implemented yet`,
    );
  }
};
