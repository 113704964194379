import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Checkbox from '../../../components/Checkbox';
import { getEmployeeDetailsLabel } from '../helper';
import { EmployeeDetail } from '../types';
import { OrgChartIconButton } from './OrgChartIconButton';

type Props = {
  detailsSettings: EmployeeDetail[];
  onSelectedIdsChange: (newIds: string[]) => void;
  selectedIds: string[];
};

const i18Path = 'people.orgChart';

export const DetailsFilter: React.FC<Props> = ({
  detailsSettings,
  onSelectedIdsChange,
  selectedIds,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    let updatedSelectedIds = selectedIds;

    //Checking the selectedIds length to restrict deselecting all options
    if (!isChecked && selectedIds.length > 1) {
      updatedSelectedIds = selectedIds.filter((i) => i !== e.target.id);
    } else if (isChecked) {
      updatedSelectedIds = selectedIds.concat(e.target.id);
    }
    onSelectedIdsChange(updatedSelectedIds);
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement={'bottom'}
      rootClose
      overlay={
        <Popover id="show-details-filter">
          <Popover.Header className="fw-bold">
            {t('showInOrgChart')}
          </Popover.Header>
          <Popover.Body className="text-dark">
            {detailsSettings.map((s) => (
              <Checkbox
                id={s}
                key={s}
                label={getEmployeeDetailsLabel(s)}
                onChange={handleChange}
                checked={selectedIds.includes(s)}
              />
            ))}
          </Popover.Body>
        </Popover>
      }>
      <span>
        <OrgChartIconButton icon="settings-outline" />
      </span>
    </OverlayTrigger>
  );
};
