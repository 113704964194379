import {
  changePeriodByUnit,
  toISODateOnly,
} from '../../../../../utils/dateUtils';
import {
  MyEmployeeOvertimeReportQueryResult,
  OvertimeReportPayload,
  useMyEmployeeOvertimeReportQuery,
} from '../../../__generated__/graphql';

export const useMyEmployeeOvertimeReportData = (
  period: string,
): MyEmployeeOvertimeReportQueryResult => {
  const periodDate = new Date(period);
  const { start, end } = changePeriodByUnit(periodDate, { unit: 'month' });

  return useMyEmployeeOvertimeReportQuery({
    variables: {
      filter: {
        startDate: toISODateOnly(start),
        endDate: toISODateOnly(end),
      },
    },
  });
};

export const prepareChartData = (data: OvertimeReportPayload) => {
  const categories = data.employeeResults.map((item) => item.employeeName);
  const seriesData = data.employeeResults.map((item) => item.totalOvertime);
  const overallOvertime = data.overallOvertime;

  return {
    categories,
    seriesData,
    overallOvertime,
  };
};
