import React, { useState } from 'react';
import { Card, CloseButton, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NumberSelectInput } from '../../../../../../components/NumberSelectInput';
import { Nullable } from '../../../../../../utils/nullable';
import { SurveySubmitModal } from '../../../../components/SurveySubmitModal/SurveySubmitModal';
import { CardTitle } from '../../Heading';

export type EnpsData = {
  score: number;
  comment: Nullable<string>;
};

type Props = {
  headerTitle: string;
  questionText: string;
  commentPromptText: string;
  allowComments: boolean;
  allowDismiss: boolean;
  handleSubmit: (data: EnpsData) => void;
  handleDismiss: (callback?: () => void) => void;
  submitLoading: boolean;
};

export const EnpsSurveyUI: React.FC<Props> = ({
  handleSubmit,
  handleDismiss,
  allowDismiss,
  headerTitle,
  questionText,
  commentPromptText,
  allowComments,
  submitLoading,
}) => {
  const { t } = useTranslation();
  const scoreOptions = Array.from(Array(11).keys());
  const [selectedScore, setSelectedScore] = useState<Nullable<number>>(null);
  const [comment, setComment] = useState<Nullable<string>>(null);
  const showModal = selectedScore !== null ? true : false;

  const submitData = () => {
    if (selectedScore !== null) {
      const dataToSubmit: EnpsData = {
        score: selectedScore,
        comment: comment,
      };
      handleSubmit(dataToSubmit);
    }
  };
  return (
    <Card className="rounded-4">
      <Card.Header>
        <Row className="justify-content-center align-items-center">
          <Col>
            <CardTitle>{headerTitle}</CardTitle>
          </Col>
          {allowDismiss && (
            <Col>
              <CloseButton onClick={() => handleDismiss()} />
            </Col>
          )}
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <div className="w-75 mx-auto">
            <NumberSelectInput
              label={questionText}
              numbers={scoreOptions}
              onChange={(emojiItem) => setSelectedScore(emojiItem)}
              startLabel={t('home.enpsCard.notLikely')}
              endLabel={t('home.enpsCard.veryLikely')}
            />
            {selectedScore !== null && (
              <SurveySubmitModal
                onSubmit={submitData}
                onClose={() => setSelectedScore(null)}
                setComment={setComment}
                visible={showModal}
                submitInProgress={submitLoading}
                commentPromptText={commentPromptText.replace(
                  '{{score}}',
                  selectedScore.toString(),
                )}
                allowComment={allowComments}
              />
            )}
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};
