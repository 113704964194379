import moment from 'moment';
import React from 'react';
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import DateInput from '../../../../../components/DateInput/DateInput';
import { DailyFilter } from './dailyShiftsLogic';
import ShiftsFilters from '../../../pages/AllShifts/ShiftsFilters';
import { routes } from '../../../routes';
import ShiftsViewBy from '../ViewBy';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  filter: DailyFilter;
  onFilterChanged: (filter: DailyFilter) => void;
};

const ShiftsDailyFilter: React.FC<Props> = ({ filter, onFilterChanged }) => {
  const { day } = filter;

  const handleDateChange = (value: string) =>
    onFilterChanged({ ...filter, day: moment(value) });

  const route = routes.allShifts.route;

  return (
    <Row className="small">
      <Col md={8}>
        <Row>
          <Col md={4}>
            <EmployeeFilter />
          </Col>
          <Col md={2}>
            <ShiftsFilters route={route} />
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <Row>
          <Col>
            <ShiftsViewBy />
          </Col>
          <Col>
            <DateInput
              name="date"
              readonly={false}
              value={day.toString()}
              onChange={handleDateChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const i18Path = 'shiftPlanner.nav';

const EmployeeFilter: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <InputGroup className="input-group-flush  ">
      <InputGroup.Text>
        <FlairIcon icon="search-filled" />
      </InputGroup.Text>
      <FormControl
        className="form-control-auto"
        placeholder={t('findByEmployeeName')}
      />
    </InputGroup>
  );
};

export default ShiftsDailyFilter;
