import React from 'react';

import { TeamPopupContentConnected } from './TeamPopupContentConnected';
import {
  TeamWithPopupBase,
  Props as TeamWithPopupBaseProps,
} from './TeamWithPopupBase';

export type Props = Omit<TeamWithPopupBaseProps, 'popup'> & {
  id: string;
};

export const TeamWithPopupConnected: React.FC<Props> = ({ id, ...props }) => {
  return (
    <TeamWithPopupBase
      {...props}
      popup={
        <TeamPopupContentConnected
          id={id}
          header={!props.isTeamNameVisible ? props.name : undefined}
        />
      }
    />
  );
};
