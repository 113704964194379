import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../../../../../../theme';

type Props = {
  name: string;
  onClick: () => void;
};
export const TicketSubjectCell: React.FC<Props> = ({ name, onClick }) => {
  return (
    <div className="d-flex flex-row align-items-center" onClick={onClick}>
      <NameStyled
        title={name}
        className="d-inline-block cu-pointer h7-medium text-truncate">
        {name}
      </NameStyled>
    </div>
  );
};
const NameStyled = styled.span`
  color: ${Theme.color.blue3};
  max-width: 14.5rem;
`;
