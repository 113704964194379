import React, { useCallback, useState } from 'react';
import { Nullable } from '../../../../../utils/nullable';
import { CalendarDay } from '../../AbsenceCalendar/types';
import DaysRow from './DaysRow';
import MonthRow from './MonthRow';
import { CalendarMonth } from './types';

const Content: React.FC<{ months: CalendarMonth[]; loading: boolean }> = ({
  months,
  loading,
}) => {
  const [hoveredDay, setHoveredDay] = useState<Nullable<CalendarDay>>();
  const handleHover = useCallback(
    (day: Nullable<CalendarDay>) => {
      setHoveredDay(day);
    },
    [setHoveredDay],
  );

  return (
    <tbody
      onMouseLeave={() => {
        handleHover(null);
      }}>
      <DaysRow hoveredDay={hoveredDay} />
      {months.map((month) => (
        <MonthRow
          key={month.month.format('YYYYMM')}
          month={month}
          hoveredDay={hoveredDay}
          loading={loading}
          onHover={handleHover}
        />
      ))}
    </tbody>
  );
};
export default Content;
