import { MenuItemType } from '../../../../atomic/molecules/navigation/types';
import { Nullable } from '../../../../utils/nullable';

export function getMenuItems(
  nullableMenuItems: Nullable<MenuItemType>[],
): MenuItemType[] {
  return nullableMenuItems
    .filter((x): x is MenuItemType => x !== null)
    .filter(hasActiveMenuItem);
}

function hasActiveMenuItem(menuItem: MenuItemType) {
  if (menuItem.subItems) {
    return menuItem.subItems.some((item) => item.shouldRender);
  }

  return true;
}
