import React, { useMemo, useState } from 'react';
import {
  TableRow,
  TableRowTimeSheet,
  isTableDaySubRow,
  isTableRowTimeSheet,
} from './types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { MainCell } from './MainCell';
import { WarningCell } from './WarningCell';
import { ApproveStatusCell } from './ApproveStatusCell';
import {
  TimeSheetColumnId,
  TimeSheetColumnSetting,
} from '../../../components/TimeSheetsTable/CustomizeTableColumns/types';
import DurationFormat from '../../../components/DurationFormat';
import { ManagerStartEndTimeCell } from './ManagerStartEndTimeCell';
import {
  BadgesCell,
  CheckboxCell,
  RowCommentsCell,
  TimeBalanceCell,
  TimeSheetColumn,
} from '../../../components/TimeSheetsTable';
import { getDaySubRowTimezoneWarning } from '../../../components/TimeSheetsTable/mappings';
import { WorkedMinutesCell } from '../../../components/TimeSheetsTable/Cells/WorkedMinutesCell';
import { WorkedMinutesDifferenceCell } from '../../../components/TimeSheetsTable/Cells/WorkedMinutesDifferenceCell';
import { ManagerDurationCell } from './ManagerDurationCell';

type Props = {
  columnSettings: TimeSheetColumnSetting[];
  isTimeBalanceEnabled: boolean;
  onApprove: (timeSheetRow: TableRowTimeSheet) => void;
  onExpandedChanged: (
    timeSheetRow: TableRowTimeSheet,
    expanded: boolean,
  ) => void;
  onSelectRow: (
    allTableRows: TableRow[],
    tableRow: TableRow,
    selected: boolean,
  ) => void;
  onSelectAll: (allTableRows: TableRow[], selected: boolean) => void;
};

export const useTimeSheetsTableColumns = ({
  columnSettings,
  isTimeBalanceEnabled,
  onSelectRow,
  onSelectAll,
  onExpandedChanged,
  onApprove,
}: Props): TimeSheetColumn[] => {
  const t = useNamespacedTranslation('timeSheetsControlling.table');

  const [headerCheckboxSelected, setHeaderCheckboxSelected] =
    useState<boolean>(false);

  const columns: TimeSheetColumn[] = useMemo(() => {
    // The most important thing to ux and performance is to do not rerender columns on any data changes
    // It can be easy check by uncommenting line below
    // It should happen ideally only once
    // console.log('columns rerener!');

    const checkboxColumn: TimeSheetColumn = {
      Header: ({ allRows }) => (
        <input
          id="isAllSelected"
          type="checkbox"
          checked={headerCheckboxSelected}
          onChange={(e) => {
            setHeaderCheckboxSelected(e.currentTarget.checked);
            onSelectAll(allRows, e.currentTarget.checked);
          }}
          className="form-check-input"
        />
      ),
      accessor: 'id',
      Cell: ({ value, row, allRows }) => {
        return (
          <CheckboxCell
            id={value}
            row={row}
            onChange={(newChecked) => {
              onSelectRow(allRows, row, newChecked);
            }}
          />
        );
      },
    };

    const secondColumn: TimeSheetColumn = {
      Header: () => null,
      accessor: 'rowType',
      Cell: ({ row, isExpanded }) => {
        return (
          <MainCell
            row={row}
            isExpanded={isExpanded}
            onExpandedChanged={onExpandedChanged}
          />
        );
      },
    };

    const warningsColumn: TimeSheetColumn = {
      Header: () => null,
      accessor: 'warnings',
      Cell: ({ row }) => {
        if (isTableRowTimeSheet(row)) {
          return <WarningCell warnings={row.warnings ?? []} />;
        } else if (row.rowType === 'subrow-day') {
          return <BadgesCell badges={row.badges} />;
        }
        return null;
      },
    };

    const actionsColumn: TimeSheetColumn = {
      Header: () => null,
      accessor: 'actions',
      Cell: ({ row }) => {
        const { rowType } = row;
        switch (rowType) {
          case 'row-employee':
          case 'row-period':
            return (
              <ApproveStatusCell timeSheetRow={row} onApprove={onApprove} />
            );
          default:
            return null;
        }
      },
    };

    const customizableColumns: Record<TimeSheetColumnId, TimeSheetColumn> = {
      targetMinutes: {
        Header: () => t('header.targetMinutes'),
        accessor: 'targetMinutes',
        Cell: ({ value }) =>
          value !== undefined ? <DurationFormat minutes={value} /> : null,
      },
      startTime: {
        Header: () => t('header.startTime'),
        accessor: 'startTime',
        Cell: ({ row }) => {
          if (!isTableDaySubRow(row)) {
            return null;
          }
          const timeZoneInfo = getDaySubRowTimezoneWarning(row);
          return (
            <ManagerStartEndTimeCell
              fieldKey="startTime"
              value={row.startTime}
              day={row.day}
              timeSheetId={row.timeSheetId}
              employeeId={row.employeeId}
              dayChanges={row}
              infoText={timeZoneInfo}
            />
          );
        },
      },
      endTime: {
        Header: () => t('header.endTime'),
        accessor: 'endTime',
        Cell: ({ row }) => {
          if (!isTableDaySubRow(row)) {
            return null;
          }
          return (
            <ManagerStartEndTimeCell
              fieldKey="endTime"
              value={row.endTime}
              day={row.day}
              timeSheetId={row.timeSheetId}
              employeeId={row.employeeId}
              dayChanges={row}
            />
          );
        },
      },
      breakMinutes: {
        Header: () => t('header.breakMinutes'),
        accessor: 'breakMinutes',
        Cell: ({ row }) => {
          if (!isTableDaySubRow(row)) {
            return null;
          }
          return (
            <ManagerDurationCell
              fieldKey="breakMinutes"
              value={row.breakMinutes}
              day={row.day}
              timeSheetId={row.timeSheetId}
              employeeId={row.employeeId}
              dayChanges={row}
            />
          );
        },
      },
      workedMinutes: {
        Header: () => t('header.workedMinutes'),
        accessor: 'workedMinutes',
        Cell: ({ row }) => <WorkedMinutesCell row={row} />,
      },
      workedDifferenceMinutes: {
        Header: () => t('header.workedDifferenceMinutes'),
        accessor: 'workedDifferenceMinutes',
        Cell: ({ row }) => <WorkedMinutesDifferenceCell row={row} />,
      },
      commentsCount: {
        Header: () => t('header.commentsCount'),
        accessor: 'commentsCount',
        Cell: ({ row }) => <RowCommentsCell row={row} />,
      },
      timeBalanceAccumulatedMinutes: {
        Header: () => t('header.timeBalanceAccumulatedMinutes'),
        accessor: 'timeBalanceAccumulatedMinutes',
        Cell: ({ row }) => <TimeBalanceCell row={row} />,
      },
    };

    return [
      checkboxColumn,
      secondColumn,
      ...columnSettings
        .filter((columnSetting) => {
          if (
            columnSetting.id === 'timeBalanceAccumulatedMinutes' &&
            !isTimeBalanceEnabled
          ) {
            return false;
          }
          return columnSetting.checked;
        })
        .map((columnSetting) => customizableColumns[columnSetting.id]),
      warningsColumn,
      actionsColumn,
    ];
  }, [
    t,
    columnSettings,
    isTimeBalanceEnabled,
    onApprove,
    onSelectAll,
    onSelectRow,
    headerCheckboxSelected,
    setHeaderCheckboxSelected,
    onExpandedChanged,
  ]);

  return columns;
};
