import { Locale } from '../../context/I18n';
import { DateTimePickerProps } from 'react-flatpickr';

type FlatpickrLocale = NonNullable<
  NonNullable<DateTimePickerProps['options']>['locale']
>;

export const toFlatpickrLocale = (locale: Locale): FlatpickrLocale => {
  if (locale === 'de-ch') {
    return 'de';
  }
  if (locale === 'en-us') {
    return 'en';
  }

  return locale;
};
