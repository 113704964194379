import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Row, Col } from 'react-bootstrap';
import { useAbsenceRequestTableColumns } from './useAbsenceRequestsTableColumns';
import { ApproverCommentModal } from '../../../pages/Absences/components/ApproverCommentModal';
import { AbsenceRequestFilterType, defaultFilter } from './types';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import { useAbsencesRequestsPaginationQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import {
  mapAbsenceRequestFilterInput,
  mapAbsenceRequests,
  mapSortBy,
} from './mappings';
import { routes as mainRoutes } from '../../../routes';
import { AbsenceRequestFiltersConnected } from './Filters/AbsenceRequestFiltersConnected';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { TableContentManual } from '../../components/TableContentManual';
import { Maybe } from '../../../../../utils/maybe';
import { LoomVideo } from '../../../components/LoomVideo';
import LoomVideoPreviewInModal from '../../../components/LoomVideo/LoomVideoPreviewInModal';

import { AbsenceRequest } from '../types';
import { EmptyAbsenceRequestsCard } from '../EmptyAbsenceRequestsCard';

const PAGE_SIZE = 50;

export const AbsenceRequestsTable: React.FC = () => {
  const [selectedLoomVideo, setSelectedLoomVideo] =
    useState<Maybe<LoomVideo>>(null);

  const { columns, rejectingModalId, onModalClose, handleReject } =
    useAbsenceRequestTableColumns(setSelectedLoomVideo);

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  } = useFlairTableWithPaginationAndFilter<
    AbsenceRequest,
    AbsenceRequestFilterType
  >({
    defaultFilter: defaultFilter,
    storageKey: 'absence_requests_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.allAbsenceRequests.route
        .withQueryParams(queryParams)
        .create({}),
  });

  const [reportsMode] = useManagerReportsMode('absences');

  const { data, error, loading } = useAbsencesRequestsPaginationQuery({
    variables: {
      filter: mapAbsenceRequestFilterInput(tableState.filter, reportsMode),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });

  useEffect(() => {
    const totalCount =
      data?.manager.absenceRequestsPagination.paginationInfo?.totalCount;
    setTotalItemsCount(totalCount);
  }, [setTotalItemsCount, data]);

  if (error) return <ServerError />;

  const requests = data
    ? mapAbsenceRequests(data?.manager.absenceRequestsPagination.items)
    : undefined;

  const renderTable = (requests: AbsenceRequest[]) => {
    return requests.length > 0 ? (
      <TableContentManual
        columns={columns}
        data={requests}
        onRowClick={() => {}}
        pageSize={PAGE_SIZE}
        itemsCount={tableState.totalItemsCount}
        initialSortBy={tableState.sortBy}
        onSortByChanged={handleSortByChanged}
        initialPageIndex={tableState.pageIndex}
        onPageIndexChanged={handlePageIndexChanged}
      />
    ) : (
      <EmptyAbsenceRequestsCard />
    );
  };

  return (
    <Card>
      <Header className="h-auto">
        <Row className="align-items-center">
          <Col className="small">
            <AbsenceRequestFiltersConnected
              filter={tableState.filter}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Header>

      {requests !== undefined && !loading ? (
        renderTable(requests)
      ) : (
        <SkeletonTable className="card-table" columns={5} rows={10} />
      )}

      {selectedLoomVideo && (
        <LoomVideoPreviewInModal
          loomVideo={selectedLoomVideo}
          onHide={() => setSelectedLoomVideo(null)}
        />
      )}

      <ApproverCommentModal
        requestId={rejectingModalId}
        onClose={onModalClose}
        onRejected={handleReject}
      />
    </Card>
  );
};

const Header = styled(Card.Header)({
  height: 'auto',
  minHeight: 60,
});
