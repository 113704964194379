import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Redirect } from '../../../../Router';
import {
  ResetPasswordEmployeeError,
  useFetchResetPasswordEmployeeQuery,
} from '../../__generated__/graphql';
import Form from './Form';
import { routes } from '../../routes';
import ServerError from '../../../../components/ServerError';
import OrganizationNotEnabled from '../OrganizationNotEnabled';

type RouteParams = {
  organizationId: string;
};

const OperationError: React.FC<{ error: ResetPasswordEmployeeError }> = ({
  error,
}) => {
  switch (error) {
    case ResetPasswordEmployeeError.EmployeeNotFound:
      return <Redirect to={routes.login.route} />;
    case ResetPasswordEmployeeError.OrganizationNotEnabled:
      return <OrganizationNotEnabled />;
  }
};

const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border" role="status"></div>
  </div>
);

const ResetPassword: React.FC = () => {
  const { organizationId } = useParams<RouteParams>();
  const queryString = new URLSearchParams(useLocation().search);
  const token = queryString.get('token') || 'invalidToken';
  const { loading, data, error } = useFetchResetPasswordEmployeeQuery({
    variables: { organizationId, token },
  });

  if (loading) return <Loading />;
  if (error) return <ServerError />;

  if (data) {
    if (data.getResetPasswordEmployee.error) {
      return (
        <OperationError
          error={data.getResetPasswordEmployee.error}></OperationError>
      );
    }

    if (data.getResetPasswordEmployee.employee) {
      return (
        <Form
          employee={data.getResetPasswordEmployee.employee}
          organizationId={organizationId}
          token={token}
        />
      );
    }
  }

  return <Redirect to={routes.login.route} />;
};

export default ResetPassword;
