import React, { useState } from 'react';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { ModalSidebarContent } from '../../../components/ModalSidebar';
import { routes } from '../../../routes';
import {
  useUpdateWorkflowItemRegularMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';
import {
  WorkflowItemCycleAssignment,
  WorkflowItemStatus,
} from '../WorkflowItems/types';
import { WorkflowItemCommonFields } from './WorkflowItemCommonFields';
import { WorkflowItemStatusAutoSave } from './WorkflowItemStatusAutoSave';
import LightSectionContainer from './LightSectionContainer';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';
import { useHistory } from 'react-router-dom';

type Props = {
  workflowItem: WorkflowItemCycleAssignment;
};

export const WorkflowItemPRRequestForm: React.FC<Props> = ({
  workflowItem,
}) => {
  const history = useHistory();
  const t = useNamespacedTranslation(
    'performanceReview.workflowsItems.sidebar',
  );

  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const [updateItem] = useUpdateWorkflowItemRegularMutation();

  const errorHandler = useMutationErrorHandler();

  const handleStatusChange = (status: WorkflowItemStatus) => {
    setIsStatusLoading(true);
    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
        },
      },
    })
      .then(() => {})
      .catch(errorHandler)
      .finally(() => {
        setIsStatusLoading(false);
      });
  };

  const handleOpenPR = () => {
    history.push(routes.performanceReview.route.create({}));

    let status = workflowItem.status;
    if (
      !workflowItem.readonly &&
      status === WorkflowItemStatusGenerated.NotStarted
    ) {
      status = WorkflowItemStatusGenerated.InProgress;
      handleStatusChange(status);
    }
  };

  const onStatusLoading = (isStatusLoading: boolean) => {
    setIsStatusLoading(isStatusLoading);
  };

  return (
    <ModalSidebarContent
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={onStatusLoading}
          />
          <FormGroup>
            <LightSectionContainer>
              {/*//TODO:: link to the exact performance review, will be done as part of FEA-502*/}
              <Button
                label={t('openPerformanceReview')}
                className="w-100"
                variant="outline-primary"
                onClick={handleOpenPR}
              />
            </LightSectionContainer>
          </FormGroup>
          <WorkflowItemStatusAutoSave
            value={workflowItem.status}
            onChange={handleStatusChange}
            readonly={workflowItem.readonly}
            saving={isStatusLoading}
            saved={!isStatusLoading}
          />
        </>
      }
    />
  );
};
