import { compact } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  IScorecard,
  onUpdateAnswerRet,
  onUpdateNotesRet,
  QuestionnaireType,
} from './types';
import { convertToScorecards } from './utils';
import {
  QuestionnaireSection,
  QuestionnaireSectionData,
} from './QuestionnaireSection';
import { IQuestionAnswer } from './QuestionnaireField';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../components/button';
import { useTranslation } from 'react-i18next';
import { ConfirmModalSimple } from '../../../../components/ConfirmModalSimple';

const isScorecardComplete = (
  value: string | null,
  requiredChoices: ReadonlyArray<string>,
): boolean => {
  if (!requiredChoices || requiredChoices.length === 0) {
    return true;
  }

  const scorecards: IScorecard[] = compact(convertToScorecards(value || ''));

  for (const requiredChoice of requiredChoices) {
    const scorecard = scorecards.find((item) => item.name === requiredChoice);
    if (!scorecard || scorecard.score === undefined) {
      return false;
    }
  }

  return true;
};

const isFieldOptional = (
  optional: boolean,
  type: QuestionnaireType,
  requiredChoices: ReadonlyArray<string>,
) => {
  return (
    (type === QuestionnaireType.Scorecard && requiredChoices.length === 0) ||
    optional
  );
};

const isFieldComplete = (
  value: string | null,
  optional: boolean,
  type: QuestionnaireType,
  requiredChoices: ReadonlyArray<string>,
): boolean => {
  switch (type) {
    case QuestionnaireType.Scorecard: {
      return isScorecardComplete(value, requiredChoices);
    }

    case QuestionnaireType.FreeSlider:
      return optional || (value !== undefined && value !== null);
    case QuestionnaireType.FreeText:
    case QuestionnaireType.Dropdown:
    case QuestionnaireType.SingleChoice:
    case QuestionnaireType.MultipleChoice:
    case QuestionnaireType.Unknown:
    default:
      return optional || !!value;
  }
};

export type QuestionnaireProps = {
  sections: QuestionnaireSectionData[];
  showSubmitButton?: boolean;
  showDismissButton?: boolean;
  readOnly?: boolean;
  seeAllQuestions?: boolean;
  visibleForManager?: boolean;
  onUpdateQuestionAnswer: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateAnswerRet;
  onUpdateQuestionNotes: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateNotesRet;
  onSubmit: (questions: IQuestionAnswer[]) => Promise<void>;
  onDismiss?: (callback?: () => void) => void;
  onChange: (sections: Array<QuestionnaireSectionData>) => void;
};

export const Questionnaire: React.FC<QuestionnaireProps> = ({
  sections,
  showSubmitButton = true,
  showDismissButton = false,
  readOnly = false,
  visibleForManager = false,
  seeAllQuestions,
  onUpdateQuestionAnswer,
  onUpdateQuestionNotes,
  onSubmit,
  onDismiss,
  onChange,
}) => {
  const { t } = useTranslation();

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [dismissConfirmation, setDismissConfirmation] =
    useState<boolean>(false);
  const [dismissLoading, setDismissLoading] = useState<boolean>(false);

  const showOnlyQuestionName = useMemo(
    () => seeAllQuestions && visibleForManager,
    [seeAllQuestions, visibleForManager],
  );
  const sectionsMapped = useMemo(
    () =>
      sections.map((s) => ({
        ...s,
        questions: s.questions.map((question) => {
          return {
            ...question,
            isOptional:
              showOnlyQuestionName ||
              isFieldOptional(
                question.isOptional,
                question.type,
                question.requiredChoices,
              ),
            isComplete:
              showOnlyQuestionName ||
              isFieldComplete(
                question.value,
                question.isOptional,
                question.type,
                question.requiredChoices,
              ),
          };
        }),
      })),
    [sections, showOnlyQuestionName],
  );

  const questions = useMemo(() => {
    return sectionsMapped.reduce<IQuestionAnswer[]>(
      (questions, section) => [...questions, ...section.questions],
      [],
    );
  }, [sectionsMapped]);

  const isCompletedForm = useMemo(
    () => questions.every((q) => q.isComplete),
    [questions],
  );

  const handleSubmitInternal = () => {
    setSubmitLoading(true);
    onSubmit(questions).then(() => {
      setSubmitLoading(false);
    });
  };

  const handleShowDismissConfirmation = () => {
    setDismissConfirmation(true);
  };
  //
  // const handleDismissResponse = () => {
  //   setDismissLoading(true);
  //   onDismiss(questions).then(() => {
  //     setSubmitLoading(false);
  //   });
  // };

  const onUpdateQuestionAnswerInternal = (
    questionInfo: IQuestionAnswer,
    value: string,
    field: 'value' | 'notes',
  ) => {
    const newSections: QuestionnaireSectionData[] = sections.map(
      (section: QuestionnaireSectionData) =>
        section.sectionId === questionInfo.sectionId
          ? {
              ...section,
              questions: section.questions.map((q) =>
                q.id === questionInfo.id ? { ...q, [field]: value } : q,
              ),
            }
          : section,
    );
    onChange(newSections);

    if (field === 'value') {
      return onUpdateQuestionAnswer(questionInfo, value);
    } else {
      return onUpdateQuestionNotes(questionInfo, value);
    }
  };

  return (
    <div>
      <Row>
        {sectionsMapped.map((section) => (
          <QuestionnaireSection
            key={section.sectionId ?? 'unknow-section'}
            {...section}
            onUpdateQuestionAnswer={(
              questionInfo: IQuestionAnswer,
              value: string,
            ) => onUpdateQuestionAnswerInternal(questionInfo, value, 'value')}
            onUpdateQuestionNotes={(
              questionInfo: IQuestionAnswer,
              value: string,
            ) => onUpdateQuestionAnswerInternal(questionInfo, value, 'notes')}
            readOnly={readOnly || submitLoading}
          />
        ))}
      </Row>
      {showSubmitButton && (
        <Row>
          <Col className="text-end mb-3">
            <Button
              onClick={handleSubmitInternal}
              isProcessing={submitLoading}
              disabled={!isCompletedForm}
              label={t('questionnaire.submit')}
            />
            {showDismissButton && (
              <Button
                className="ms-2"
                variant="outline-primary"
                onClick={handleShowDismissConfirmation}
                isProcessing={submitLoading}
                label={t('questionnaire.dismissAnswerButton')}
              />
            )}
          </Col>
        </Row>
      )}
      <ConfirmModalSimple
        visible={dismissConfirmation}
        submitInProgress={dismissLoading}
        onClose={() => setDismissConfirmation(false)}
        message={t('questionnaire.dismissConfirmationMessage')}
        onSubmit={() => {
          setDismissLoading(true);
          onDismiss &&
            onDismiss(() => {
              setDismissConfirmation(false);
              setDismissLoading(false);
            });
        }}
      />
    </div>
  );
};
