import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MultiValue } from 'react-select';
import { FilterSelectOption } from '../../../../../atomic/molecules/FlairDropdown/types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';
import { defaultFilter, isWarningsType, TimeSheetsFilter } from './types';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { FlairMultiSelectDropdown } from '../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';
import { getMonthsOptions } from '../../../../../utils/dateUtils';
import { TimeSheetStatus } from '../../../__generated__/graphql';
import { getFilterWarningOptions } from './mappings';
import { isViewByType } from '../../../components/TimeSheetsTable';
import {
  createMonthOptions,
  createYearOptions,
  getTimeSheetStatusOptions,
  getViewByOptions,
} from '../../../components/TimeSheetsTable/filterOptions';
import Checkbox from '../../../components/Checkbox';

export type Props = {
  employeeOptions: EmployeeOption[];
  filter: TimeSheetsFilter;
  onChange: (filter: TimeSheetsFilter) => void;
};

const i18Prefix = 'timeSheetsControlling.filter';

export const TimeSheetFilters: React.FC<Props> = ({
  filter,
  employeeOptions,
  onChange,
}) => {
  const t = useNamespacedTranslation(i18Prefix);

  const handleEmployeeChanged = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onChange({
      ...filter,
      employeeIds: selectedOptions.map((x) => x.value),
    });
  };

  const handleStatusChanged = (value: string | null) => {
    onChange({
      ...filter,
      warnings: isWarningsType(value) ? value : null,
    });
  };

  const handleViewByChanged = (value: string | null) => {
    onChange({
      ...filter,
      viewBy: isViewByType(value) ? value : null,
    });
  };

  const handleTimeSheetStatusChanged = (value: string | null) => {
    onChange({
      ...filter,
      status: (value as TimeSheetStatus) ?? null,
    });
  };

  const handleYearChanged = (value: string | null) => {
    onChange({
      ...filter,
      year: value ?? null,
    });
  };

  const handleMonthChanged = (value: string | null) => {
    onChange({
      ...filter,
      month: value ?? null,
    });
  };

  const handleClearFilter = () => {
    // do not clear viewBy
    return onChange({ ...defaultFilter, viewBy: filter.viewBy });
  };

  const handleOnlyWithCommentsChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...filter,
      onlyWithComments: e.target.checked ? 'true' : 'false',
    });
  };

  return (
    <Row className="justify-content-between g-2">
      <Col className="col-auto">
        <Row className="align-items-center row-cols-auto g-2">
          <Col>
            <span className="text-nowrap">{t('filterBy')}</span>
          </Col>
          <Col>
            <FlairMultiSelectDropdown
              placeholder={t('employee')}
              options={employeeOptions}
              isSearchable={true}
              selectedOptions={filter.employeeIds}
              onChange={handleEmployeeChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('status')}
              options={getFilterWarningOptions()}
              value={filter.warnings}
              onChange={handleStatusChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('timeSheetStatus')}
              options={getTimeSheetStatusOptions()}
              value={filter.status}
              onChange={handleTimeSheetStatusChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('year')}
              options={createYearOptions(new Date())}
              value={filter.year}
              onChange={handleYearChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('month')}
              options={createMonthOptions(getMonthsOptions())}
              value={filter.month}
              onChange={handleMonthChanged}
            />
          </Col>
          <Col>
            <Checkbox
              id="onlyWithComments"
              className="mt-2"
              labelClassName="align-middle"
              label={t('onlyWithComments')}
              checked={filter.onlyWithComments === 'true'}
              onChange={handleOnlyWithCommentsChanged}
            />
          </Col>
          <Col>
            <ClearFiltersLink onClick={handleClearFilter} />
          </Col>
        </Row>
      </Col>
      <Col className="col-auto">
        <Row className="align-items-center justify-content-end row-cols-auto g-2">
          <Col>
            <span className="text-nowrap">{t('viewBy')}</span>
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('viewBy')}
              options={getViewByOptions()}
              value={filter.viewBy ?? 'week'}
              onChange={handleViewByChanged}
              isClearable={false}
              heightMode="normal"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
