import { Evaluation, EvaluationsFilterParams } from '../types';
import { split } from '../../../../../../utils/string';

export const filter = (
  searchText: string,
  filterData: EvaluationsFilterParams,
  evaluations: Evaluation[],
): Evaluation[] => {
  const filterByStage = (evaluation: Evaluation) => {
    const stages = split(filterData.stages);
    if (stages.length === 0) {
      return true;
    }

    return stages.includes(evaluation.stage);
  };

  const filterBySearchText = (evaluation: Evaluation) => {
    if (searchText === '') {
      return true;
    }

    return evaluation.candidate.name
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };

  const filterByScores = (evaluation: Evaluation) => {
    const scores = split(filterData.scores);
    if (scores.length === 0) {
      return true;
    }
    return scores.some((s) => {
      return parseInt(s) === evaluation.scoreRange;
    });
  };

  const filterByStatuses = (evaluation: Evaluation) => {
    const statuses = split(filterData.statuses);
    if (statuses.length === 0) {
      return true;
    }
    return statuses.includes(evaluation.status.value);
  };

  return evaluations
    .filter(filterByStage)
    .filter(filterBySearchText)
    .filter(filterByScores)
    .filter(filterByStatuses);
};
