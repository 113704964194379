import styled from '@emotion/styled';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useIsMdScreen } from '../../../../../hooks/useIsScreenSizeLargerThan';
import { Theme } from '../../../../../theme';
import { Nullable } from '../../../../../utils/nullable';
import { CalendarDay } from '../../AbsenceCalendar/types';
import DaysOfMonth from './DaysOfMonth';
import LoadingDaysOfMonth from './LoadingDaysOfMonth';
import { CalendarMonth, MonthRowProps } from './types';

type MonthNameTableHeaderProps = {
  isHovered?: boolean;
  isCurrent?: boolean;
};

type MonthNameProps = {
  hoveredDay?: Nullable<CalendarDay>;
  month: CalendarMonth;
};

const MonthNameTableHeader = styled.th<MonthNameTableHeaderProps>(
  {
    position: 'sticky',
    left: 0,
    zIndex: 999,
    backgroundColor: `${Theme.color.white} !important`,
    fontSize: `${Theme.font.size.base} !important`,
    fontWeight: 'normal',
  },
  ({ isHovered, isCurrent }) => {
    return {
      color: isHovered ? Theme.color.primary : undefined,
      '::after': isCurrent
        ? {
            content: "''",
            position: 'absolute',
            borderBottom: `3px solid ${Theme.color.primary}`,
            bottom: 0,
            left: 0,
            right: 0,
          }
        : undefined,
    };
  },
);

const MonthName: React.FC<MonthNameProps> = ({ month, hoveredDay }) => {
  const isCurrentMonth = moment().isSame(month.month, 'month');
  const isHoveredMonth = !!(
    hoveredDay && hoveredDay.day.isSame(month.month, 'month')
  );
  const isMdScreen = useIsMdScreen();
  const monthName = month.month.format(isMdScreen ? 'MMMM' : 'MMM');

  return useMemo(
    () => (
      <MonthNameTableHeader
        isHovered={isHoveredMonth}
        isCurrent={isCurrentMonth}>
        {monthName}
      </MonthNameTableHeader>
    ),
    [isCurrentMonth, isHoveredMonth, monthName],
  );
};

const MonthRow: React.FC<MonthRowProps> = ({
  month,
  loading,
  onHover,
  hoveredDay,
}) => {
  return (
    <tr>
      <MonthName month={month} hoveredDay={hoveredDay} />
      {loading ? (
        <LoadingDaysOfMonth month={month.month} />
      ) : (
        <DaysOfMonth
          days={month.days}
          daysInMonth={month.daysInMonth}
          onHover={onHover}
          hoveredDay={hoveredDay}
        />
      )}
    </tr>
  );
};

export default MonthRow;
