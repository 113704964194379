import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';

import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { emptyExpenseFilter, Expense, ExpenseFilter } from '../types';
import { createExpenseStatusOptions } from './mappings';
import { Option } from './types';
import _ from 'lodash';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';

type Props = {
  filter: ExpenseFilter;
  yearOptions: Option[];
  categoryOptions: Option[];
  onChange: (filter: ExpenseFilter) => void;
  onCreateBtnClick?: () => void;
};

export const ExpensesFilter: React.FC<Props> = ({
  filter,
  yearOptions,
  categoryOptions,
  onChange,
  onCreateBtnClick,
}) => {
  const { t } = useTranslation();

  const SingleFilterStyled = styled(SingleFilter)({
    maxWidth: '200px',
  });

  const handleClearFilter = useCallback(() => {
    onChange(emptyExpenseFilter);
  }, [onChange]);

  const handleYearChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  const handleCategoryChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'category'),
    [filter, onChange],
  );

  const handleStatusChanged = useCallback(
    (value: string | null) =>
      handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );

  return (
    <Row className="h-auto small d-flex gap-2 align-items-center justify-content-between card-header">
      <Col sm={8}>
        <div className="d-flex gap-2 flex-wrap align-items-center">
          <span className="d-none d-md-block">
            {t('expenses.table.filter.filterBy')}
          </span>

          <SingleFilterStyled
            placeholder={t('expenses.table.filter.year')}
            isSearchable={true}
            options={yearOptions}
            value={filter.year}
            onChange={handleYearChanged}
          />
          <SingleFilterStyled
            placeholder={t('expenses.table.filter.category')}
            isSearchable={true}
            options={categoryOptions}
            value={filter.category}
            onChange={handleCategoryChanged}
          />

          <SingleFilter
            placeholder={t('expenses.table.filter.status')}
            options={createExpenseStatusOptions()}
            value={filter.status}
            onChange={handleStatusChanged}
          />
          <ClearFiltersLink onClick={handleClearFilter} />
        </div>
      </Col>
      {onCreateBtnClick && (
        <Col sm={4} className="text-end">
          <Button
            size="sm"
            variant="primary"
            onClick={onCreateBtnClick}
            label={t('expenses.newExpense')}
          />
        </Col>
      )}
    </Row>
  );
};

export const collectYearFilterOptions = (expenses: Expense[]): Option[] => {
  return _.sortBy(
    _.uniqBy(
      expenses.map((expense) => {
        const year = expense.expenseDate.getFullYear().toString();
        return { label: year, value: year };
      }),
      'value',
    ),
    'label',
  );
};

export const collectCategoryFilterOptions = (expenses: Expense[]): Option[] => {
  return _.sortBy(
    _.uniqBy(
      expenses
        .map((expense) => {
          return expense.category
            ? {
                label: expense.category.Name,
                value: expense.category.Id,
              }
            : null;
        })
        .filter((x): x is Option => x !== null),
      'value',
    ),
    'label',
  );
};

const handleFilterChange = (
  value: string | null,
  filter: ExpenseFilter,
  onChange: (filter: ExpenseFilter) => void,
  filterKey: keyof ExpenseFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
