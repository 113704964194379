import classNames from 'classnames';
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import {
  MenuHeaderProps,
  MenuItemsSectionType,
} from '../../../molecules/navigation/types';
import { NavItem } from '../../../molecules/navigation/NavItem/NavItem';
import './MenuBar.css';
import { CompanyAvatar } from '../../../molecules/CompanyAvatar';

type Props = {
  sections: MenuItemsSectionType[];
  header?: MenuHeaderProps;
  isExpanded?: boolean;
  hideSmallScreenBurgerMenu?: boolean;
  className?: string;

  //Navigational Item is each item that will navigate to a new route
  onNavigationalItemClick?: () => void;
};

export const MenuBar: React.FC<Props> = ({
  sections,
  header,
  isExpanded,
  hideSmallScreenBurgerMenu = false,
  className,
  onNavigationalItemClick,
}) => {
  return (
    <Navbar
      variant="light"
      expand="lg"
      expanded={isExpanded}
      className={classNames('navbar-vertical', 'fixed-start', className)}>
      <Container fluid className="bg-inherited flex-column px-0">
        {!hideSmallScreenBurgerMenu && (
          <div className="navbar-mobile d-lg-none d-flex">
            <Navbar.Toggle aria-controls="hub-navbar-nav" />
          </div>
        )}
        <Navbar.Collapse id="hub-navbar-nav">
          <Nav className="menubar-nav">
            {header && (
              <Navbar.Brand
                className="pt-4 pb-1 ps-2"
                children={
                  <CompanyAvatar
                    avatarSrc={header.logoSrc}
                    title={header.title}
                    subtitle={header.subtitle}
                    className="ms-3"
                    logoClass="me-2"
                  />
                }
              />
            )}
            {sections.map(({ section, items }, i) => (
              <React.Fragment key={`menuBarSection-${i}`}>
                {section.visible && (
                  <div className="nav-item nav-link mt-2 ms-3 h3-bold section-title">
                    {section.sectionName}
                  </div>
                )}
                {items.map((item, i) => (
                  <NavItem
                    key={`${item.label}-${i}`}
                    menuItem={item}
                    onNavigationalItemClick={onNavigationalItemClick}
                  />
                ))}
              </React.Fragment>
            ))}
          </Nav>
          <div className="d-lg-none d-flex flex-column">
            <div className="nav-border"></div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MenuBar;
