import * as React from 'react';

const StopwatchFilled = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 11.25C10.3452 11.25 10.625 10.9702 10.625 10.625C10.625 10.2798 10.3452 10 10 10C9.65482 10 9.375 10.2798 9.375 10.625C9.375 10.9702 9.65482 11.25 10 11.25Z"
        fill="currentColor"
      />
      <path
        d="M10.9375 3.18359V2.8125C10.9375 2.56386 10.8387 2.3254 10.6629 2.14959C10.4871 1.97377 10.2486 1.875 10 1.875C9.75136 1.875 9.51291 1.97377 9.33709 2.14959C9.16127 2.3254 9.0625 2.56386 9.0625 2.8125V3.18359C7.87861 3.33222 6.74763 3.76261 5.76446 4.43867L5.35157 4.02344C5.17545 3.84732 4.93657 3.74837 4.6875 3.74837C4.43843 3.74837 4.19956 3.84732 4.02344 4.02344C3.84732 4.19956 3.74838 4.43843 3.74838 4.6875C3.74838 4.93657 3.84732 5.17544 4.02344 5.35156L4.35938 5.6875C3.15946 7.05227 2.49837 8.80775 2.5 10.625C2.5 14.7605 5.86446 18.125 10 18.125C14.1355 18.125 17.5 14.7605 17.5 10.625C17.5 6.80703 14.632 3.64609 10.9375 3.18359ZM10 12.5C9.55779 12.4999 9.12986 12.3435 8.7918 12.0584C8.45374 11.7733 8.22732 11.3779 8.15254 10.9421C8.07776 10.5062 8.15943 10.058 8.38312 9.67653C8.60681 9.29507 8.95813 9.00495 9.375 8.85742V5.9375C9.375 5.77174 9.44085 5.61277 9.55806 5.49556C9.67527 5.37835 9.83424 5.3125 10 5.3125C10.1658 5.3125 10.3247 5.37835 10.4419 5.49556C10.5592 5.61277 10.625 5.77174 10.625 5.9375V8.85742C11.0419 9.00495 11.3932 9.29507 11.6169 9.67653C11.8406 10.058 11.9222 10.5062 11.8475 10.9421C11.7727 11.3779 11.5463 11.7733 11.2082 12.0584C10.8701 12.3435 10.4422 12.4999 10 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default StopwatchFilled;
