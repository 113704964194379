import {
  CelebrationFragment,
  useCreateCelebrationMutation,
} from '../../__generated__/graphql';

export const useCreateCelebration = () => {
  return useCreateCelebrationMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const createdCelebration: CelebrationFragment | null =
        response.data.celebrations.createCelebration.record;
      if (!createdCelebration) {
        return;
      }
      cache.evict({
        fieldName: 'celebrationsPagination',
      });
      cache.gc();
    },
  });
};
