import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Employee } from '..';
import { useEmployeePageEmployeeNotesQuery } from '../../../__generated__/graphql';
import { EmployeeNoteCard } from './EmployeeNoteCard';
import { EmployeeNoteForm } from './EmployeeNoteForm';
import styled from '@emotion/styled';
import { Loading } from './Loading';

type Props = {
  employee: Employee;
};

const Notes = styled.div({
  display: 'inline-flex',
  flexWrap: 'wrap',
  margin: '-1.5rem 0 0 -1.5rem',
  '.card': {
    flex: '1 1 auto',
    margin: '1.5rem 0 0 1.5rem',
  },
});

export const EmployeeNotes: React.FC<Props> = ({ employee }) => {
  const { Id: employeeId } = employee;
  const { data, loading } = useEmployeePageEmployeeNotesQuery({
    variables: { employeeId: employeeId },
  });

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Row>
      <Col lg={5} md={6}>
        <EmployeeNoteForm employee={employee} />
      </Col>
      <Col lg={7} md={6}>
        <Notes>
          {data.manager.employeeNotes.map((note) => (
            <EmployeeNoteCard
              key={note.Id}
              employeeId={employeeId}
              employeeNote={note}
            />
          ))}
        </Notes>
      </Col>
    </Row>
  );
};
