import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const TicketsSidebarLoading: React.FC = () => {
  return (
    <div className="mx-4 d-flex flex-column gap-4">
      <Skeleton height={30} />
      <div className="d-flex flex-column gap-2 mt-3">
        <Skeleton width={100} />
        <Skeleton height={40} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton width={100} />
        <Skeleton height={80} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton height={40} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton width={100} />
        <Skeleton height={40} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton width={100} />
        <Skeleton height={40} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton width={100} />
        <Skeleton height={40} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton width={100} />
        <Skeleton height={40} />
      </div>
      <div className="d-flex flex-column gap-2">
        <Skeleton width={100} />
        <Skeleton height={40} />
      </div>
    </div>
  );
};
