import { useTimeTrackingSettingsAllDays } from '../useTimeTrackingSettingsAllDays';
import { getMaxDailyLimit, MaxDailyLimit } from './timeLimitUtils';

export const useMaxDailyTimeLimitTracking = () => {
  const { loading, getTimeTrackingSettingForDay } =
    useTimeTrackingSettingsAllDays();
  return {
    loading,
    getMaxDailyMinutesLimitForADay: (inputDate: Date): MaxDailyLimit => {
      const timeTrackingSettings = getTimeTrackingSettingForDay(inputDate);

      return getMaxDailyLimit(
        timeTrackingSettings
          ? {
              timeLimitSettings: timeTrackingSettings.timeLimitSettings,
              workloadWeek: timeTrackingSettings.workloadWeek,
              workload: timeTrackingSettings.workload,
            }
          : null,
        inputDate,
      );
    },
  };
};
