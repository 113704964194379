import React from 'react';
import { Employee } from '..';
import CategoriesAccordion from '../../../pages/Absences/CategoriesAccordion';
import { AbsencesContext } from '../../../pages/Absences/AbsencesContext';

type Props = {
  employee: Employee;
};

const AbsenceCategories: React.FC<Props> = ({ employee }) => {
  const categories = employee.absenceCategories.filter(
    (c) => !c.flair__Unlimited__c,
  );

  return (
    <AbsencesContext.Provider
      value={{
        employeeId: employee.Id,
        timeSheets: employee.timeSheets,
      }}>
      <CategoriesAccordion
        categories={categories}
        absencesCurrentYear={employee.absences}
        hideRequestButton={true}
      />
    </AbsencesContext.Provider>
  );
};

export default AbsenceCategories;
