import * as React from 'react';

const UniformsIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M23.2116 5.73938L18.3591 3.09375C18.2491 3.03292 18.1257 3.00068 18 3H15C14.8011 3 14.6103 3.07902 14.4697 3.21967C14.329 3.36032 14.25 3.55109 14.25 3.75C14.25 4.34674 14.0129 4.91903 13.591 5.34099C13.169 5.76295 12.5967 6 12 6C11.4033 6 10.831 5.76295 10.409 5.34099C9.98705 4.91903 9.75 4.34674 9.75 3.75C9.75 3.55109 9.67098 3.36032 9.53033 3.21967C9.38968 3.07902 9.19891 3 9 3H6C5.87403 3.00052 5.75022 3.03276 5.64 3.09375L0.788437 5.73938C0.442594 5.92214 0.183342 6.2346 0.0675276 6.60823C-0.0482869 6.98186 -0.0112115 7.38617 0.170624 7.7325L1.97719 11.1834C2.10854 11.4315 2.30544 11.6389 2.54645 11.7828C2.78745 11.9268 3.06333 12.0019 3.34406 12H5.25V19.5C5.25 19.8978 5.40803 20.2794 5.68934 20.5607C5.97064 20.842 6.35217 21 6.75 21H17.25C17.6478 21 18.0294 20.842 18.3107 20.5607C18.592 20.2794 18.75 19.8978 18.75 19.5V12H20.6569C20.9376 12.0019 21.2135 11.9268 21.4545 11.7828C21.6955 11.6389 21.8924 11.4315 22.0237 11.1834L23.8303 7.7325C24.0121 7.38605 24.049 6.98165 23.933 6.60801C23.817 6.23437 23.5576 5.92198 23.2116 5.73938ZM3.34406 10.5C3.33021 10.5007 3.31657 10.4963 3.30562 10.4878L1.50844 7.05562L5.25 5.01375V10.5H3.34406ZM17.25 19.5H6.75V4.5H8.325C8.49804 5.34671 8.95821 6.10767 9.62769 6.65417C10.2972 7.20066 11.1349 7.49916 11.9991 7.49916C12.8633 7.49916 13.701 7.20066 14.3704 6.65417C15.0399 6.10767 15.5001 5.34671 15.6731 4.5H17.25V19.5ZM20.6953 10.4869C20.6901 10.4916 20.684 10.4951 20.6774 10.4974C20.6708 10.4996 20.6638 10.5005 20.6569 10.5H18.75V5.01375L22.4925 7.05562L20.6953 10.4869Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default UniformsIcon;
