import { memo } from 'react';
import React from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import Hint from '../../../../../components/hint';

type Props = {
  warnings: string[];
};

export const WarningCell: React.FC<Props> = memo(({ warnings }) => {
  if (!warnings.length) {
    return null;
  }

  return (
    <div className="d-inline-block">
      <Hint id={`warnings-tooltip`} mode="popover" text={warnings.join('\n')}>
        <div className="d-flex align-items-center p-2 justify-content-start icon-warning rounded-1">
          <FlairIcon icon="alert-circle-outline" className="text-warning" />
          <span className="ms-1 h9-regular">{warnings.length}</span>
        </div>
      </Hint>
    </div>
  );
});
