import React, { useContext } from 'react';
import { Input } from '../../../../../../../../../components/form/Input';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { InventoryModalContext } from '../../InventoryModalContext';
import { i18Path } from '../../constants';

export const SerialNumberFormField = () => {
  const t = useNamespacedTranslation(i18Path);
  const { onChange: onFieldChange } = useContext(InventoryModalContext);

  return (
    <Input
      name="serialNumber"
      size="md"
      onChange={(value) => onFieldChange({ serialNumber: value })}
      label={t('form.fields.serialNumber')}
      placeholder={t('form.fields.serialNumberPlaceHolder')}
      required
    />
  );
};
