import { useCallback } from 'react';
import { useReloadCurrentTimeTracking } from './timeTracking/useReloadCurrentTimeTracking';
import { WebSocketMessage } from './webSocket';

export const useProcessWebSocketMessages = () => {
  const { reloadCurrentTimeTracking } = useReloadCurrentTimeTracking();

  return useCallback(
    (message: WebSocketMessage) => {
      switch (message.type) {
        case 'TimeTrackingUpdated':
          reloadCurrentTimeTracking({ timeEntry: message.timeEntry });
          break;
      }
    },
    [reloadCurrentTimeTracking],
  );
};

export const useProcessSocketStateChanged = () => {
  const { reloadCurrentTimeTracking } = useReloadCurrentTimeTracking();

  return useCallback(
    (opened: boolean, openTryNumber) => {
      const isReloaded = opened && openTryNumber > 1;
      if (isReloaded) {
        reloadCurrentTimeTracking({ force: true });
      }
    },
    [reloadCurrentTimeTracking],
  );
};
