import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { JobCandidateRow } from '../JobCandidatesTable';
import { useCallback, useState } from 'react';
import {
  useEvaluationTemplatesQuery,
  useCreateCandidateEvaluationMutation,
} from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import { SelectDropdown } from '../../../../../../components/Select/SelectDropdown';
import { useUserInfo } from '../../../../context/UserInfo';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { recruitmentRoutes } from '../../../routes';
import { useHistory } from 'react-router-dom';

type Props = {
  selectedCandidate: JobCandidateRow;
  onCancel: () => void;
};

const i18Path = 'recruitment.jobCandidatesPage';

const ChooseTemplatePopup: React.FC<Props> = ({
  selectedCandidate,
  onCancel,
}) => {
  const { id } = useUserInfo();
  const t = useNamespacedTranslation(i18Path);
  const show = selectedCandidate != null;
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const history = useHistory();

  const [createCandidateEvaluation, { loading: isSubmitting }] =
    useCreateCandidateEvaluationMutation();
  const errorHandler = useMutationErrorHandler();
  const { data, loading, error } = useEvaluationTemplatesQuery({
    variables: {
      input: {
        candidateId: selectedCandidate.id,
        employeeId: id,
      },
    },
  });

  const handleTemplateChange = useCallback((value: string | null) => {
    setSelectedTemplate(value);
  }, []);

  const onSelectTemplate = () => {
    if (!selectedTemplate) {
      return;
    }
    createCandidateEvaluation({
      variables: {
        input: {
          employeeId: id,
          candidateId: selectedCandidate!.id,
          templateId: selectedTemplate,
        },
      },
    })
      .then(() => {
        history.push(
          recruitmentRoutes.candidateDetails.route.create({
            candidateId: selectedCandidate!.id,
          }),
        );
      })
      .catch(errorHandler);
  };

  const renderContent = () => {
    if (loading || isSubmitting) {
      return (
        <div className="text-center">
          <Spinner animation="border" className="me-2" />
        </div>
      );
    }

    if (error || !data) {
      return <ServerError />;
    }

    const templates = data.availableEvaluationTemplates.map((e) => ({
      value: e.Id,
      label: e.Name,
    }));

    return (
      <SelectDropdown
        options={templates}
        value={selectedTemplate}
        onChange={handleTemplateChange}
        fullWidthMenu
      />
    );
  };

  return (
    <Modal show={show} centered onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title className="mt-2">
          {t('chooseTemplatePopupTitle', { name: selectedCandidate?.name })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          disabled={isSubmitting}
          variant="primary"
          onClick={onSelectTemplate}>
          {t('select')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChooseTemplatePopup;
