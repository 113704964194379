import React from 'react';
import { Card } from 'react-bootstrap';

export const CardHeaderWithFilter: React.FC = ({ children }) => {
  return (
    <Card.Header className="h-auto small d-flex gap-2 align-items-center justify-content-between">
      {children}
    </Card.Header>
  );
};
