import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { AvatarSize } from '../../../../atomic/atoms/avatar/AvatarContainer/types';
import { AvatarSkeleton } from '../../../../atomic/molecules/avatar/AvatarSkeleton';

type Props = {
  size?: AvatarSize;
};

export const EmployeeWithAvatarSkeleton: React.FC<Props> = ({ size }) => {
  return (
    <div className="d-flex align-items-center flex-nowrap">
      <div>
        <AvatarSkeleton size={size} />
      </div>
      <div className="ms-2">
        <Skeleton width="6rem" height="1rem" />
      </div>
    </div>
  );
};
