import { EvaluationAnswer } from '../../../../__generated__/graphql';

export const findAnswerIds = (
  optionIds: string[],
  answers: EvaluationAnswer[],
) =>
  answers
    .filter(
      (answer) =>
        answer.flair__Evaluation_Template_Option__c &&
        optionIds.indexOf(answer.flair__Evaluation_Template_Option__c) !== -1,
    )
    .map((answer) => answer.Id);

export const isAnswerDelete = (options: string[], oldOptions: string[]) =>
  oldOptions && options.length < oldOptions.length;

export const notEmpty = (value: string | undefined | null): value is string =>
  !!value;

export const findByQuestionId = (
  answers: EvaluationAnswer[],
  questionId: string,
) =>
  answers.find(
    (answer) => answer.flair__Evaluation_Template_Question__c === questionId,
  );

export const findKeyByQuestionId = (
  answers: EvaluationAnswer[],
  questionId: string,
) =>
  answers.findIndex(
    (answer) => answer.flair__Evaluation_Template_Question__c === questionId,
  );

export const findByQuestionAndOptionId = (
  answers: EvaluationAnswer[],
  questionId: string,
  optionId: string,
) =>
  answers.find(
    (answer) =>
      answer.flair__Evaluation_Template_Question__c === questionId &&
      answer.flair__Evaluation_Template_Option__c === optionId,
  );

export const findKeyByQuestionAndOptionId = (
  answers: EvaluationAnswer[],
  questionId: string,
  optionId: string | null,
) =>
  answers.findIndex(
    (answer) =>
      answer.flair__Evaluation_Template_Question__c === questionId &&
      answer.flair__Evaluation_Template_Option__c === optionId,
  );

export const getDiffValues = (array1: string[], array2: string[]) => {
  return array1
    .filter((x) => !array2.includes(x))
    .concat(array2.filter((x) => !array1.includes(x)));
};
