import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import InputError from '../../../../../components/form/InputError';
import InputLabel from '../../../../../components/form/InputLabel';
import { MessageInput } from '../../../../../components/MessageInput/MessageInput';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../../theme';
import {
  LoomVideoPreview,
  LoomVideoRecord,
  RecordedLoomVideo,
} from '../../../components/LoomVideo';
import { MessageLengthCounter } from './MessageLengthCounter';

export type CelebrationMessage = {
  message: string;
  loomVideo: RecordedLoomVideo | null;
};

export type Props = {
  value: CelebrationMessage;
  onChange: (value: CelebrationMessage) => void;
  error: string | undefined;
};

export const MAX_MESSAGE_LENGTH = 160;
const WARNING_MESSAGE_LENGTH = 140;

const ATTACHMENT_WIDTH = 150;
const ATTACHMENT_HEIGHT = 97;

const i18nPrefix = 'cheers.form';

export const CelebrationMessageInput: React.FC<Props> = ({
  value,
  onChange,
  error,
}) => {
  const t = useNamespacedTranslation(i18nPrefix);

  const handleVideoRecorder = (loomVideo: RecordedLoomVideo) => {
    onChange({
      ...value,
      loomVideo,
    });
  };

  const handleMessageChange = (message: string) => {
    onChange({
      ...value,
      message,
    });
  };

  const handleDeleteAttachment = () => {
    onChange({
      ...value,
      loomVideo: null,
    });
  };

  const attachment = useMemo(() => {
    if (!value.loomVideo) {
      return null;
    }
    return (
      <LoomVideoPreviewStyled
        loomVideo={value.loomVideo}
        previewWidth={ATTACHMENT_WIDTH}
        previewHeight={ATTACHMENT_HEIGHT}
      />
    );
  }, [value.loomVideo]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <InputLabel label={t('messageLabel')} />
        <MessageLengthCounter
          max={MAX_MESSAGE_LENGTH}
          errorThreshold={MAX_MESSAGE_LENGTH}
          warningThreshold={WARNING_MESSAGE_LENGTH}
          cur={value.message.length}
        />
      </div>
      <MessageInput
        value={value.message}
        onChange={handleMessageChange}
        maxLength={MAX_MESSAGE_LENGTH + 1}
        placeholder={t('messagePlaceholder')}
        minRows={4}
        maxRows={6}
        attachment={attachment}
        attachmentWidth={`${ATTACHMENT_WIDTH}px`}
        attachmentHeight={`${ATTACHMENT_HEIGHT}px`}
        hasError={error !== undefined}
        onDeleteAttachment={handleDeleteAttachment}
      />
      <InputError error={error} />
      {value.loomVideo === null && (
        <LoomVideoRecord
          text={t('sendVideoText')}
          className="mt-3"
          onVideoRecorded={handleVideoRecorder}
        />
      )}
    </>
  );
};

const LoomVideoPreviewStyled = styled(LoomVideoPreview)({
  borderRadius: Theme.border.radius,
});
