import * as React from 'react';

const DashboardOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M8.12524 3.125H4.37524C4.04372 3.125 3.72578 3.2567 3.49136 3.49112C3.25694 3.72554 3.12524 4.04348 3.12524 4.375V8.125C3.12524 8.45652 3.25694 8.77446 3.49136 9.00888C3.72578 9.2433 4.04372 9.375 4.37524 9.375H8.12524C8.45676 9.375 8.77471 9.2433 9.00913 9.00888C9.24355 8.77446 9.37524 8.45652 9.37524 8.125V4.375C9.37524 4.04348 9.24355 3.72554 9.00913 3.49112C8.77471 3.2567 8.45676 3.125 8.12524 3.125ZM8.12524 8.125H4.37524V4.375H8.12524V8.125ZM15.6252 3.125H11.8752C11.5437 3.125 11.2258 3.2567 10.9914 3.49112C10.7569 3.72554 10.6252 4.04348 10.6252 4.375V8.125C10.6252 8.45652 10.7569 8.77446 10.9914 9.00888C11.2258 9.2433 11.5437 9.375 11.8752 9.375H15.6252C15.9568 9.375 16.2747 9.2433 16.5091 9.00888C16.7435 8.77446 16.8752 8.45652 16.8752 8.125V4.375C16.8752 4.04348 16.7435 3.72554 16.5091 3.49112C16.2747 3.2567 15.9568 3.125 15.6252 3.125ZM15.6252 8.125H11.8752V4.375H15.6252V8.125ZM8.12524 10.625H4.37524C4.04372 10.625 3.72578 10.7567 3.49136 10.9911C3.25694 11.2255 3.12524 11.5435 3.12524 11.875V15.625C3.12524 15.9565 3.25694 16.2745 3.49136 16.5089C3.72578 16.7433 4.04372 16.875 4.37524 16.875H8.12524C8.45676 16.875 8.77471 16.7433 9.00913 16.5089C9.24355 16.2745 9.37524 15.9565 9.37524 15.625V11.875C9.37524 11.5435 9.24355 11.2255 9.00913 10.9911C8.77471 10.7567 8.45676 10.625 8.12524 10.625ZM8.12524 15.625H4.37524V11.875H8.12524V15.625ZM15.6252 10.625H11.8752C11.5437 10.625 11.2258 10.7567 10.9914 10.9911C10.7569 11.2255 10.6252 11.5435 10.6252 11.875V15.625C10.6252 15.9565 10.7569 16.2745 10.9914 16.5089C11.2258 16.7433 11.5437 16.875 11.8752 16.875H15.6252C15.9568 16.875 16.2747 16.7433 16.5091 16.5089C16.7435 16.2745 16.8752 15.9565 16.8752 15.625V11.875C16.8752 11.5435 16.7435 11.2255 16.5091 10.9911C16.2747 10.7567 15.9568 10.625 15.6252 10.625ZM15.6252 15.625H11.8752V11.875H15.6252V15.625Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default DashboardOutlineIcon;
