import React, { useContext } from 'react';
import { Input } from '../../../../../../../../../components/form/Input';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { InventoryModalContext } from '../../InventoryModalContext';
import { i18Path } from '../../constants';

type Props = {
  isExistingInventory: boolean;
};
export const ModelFormField: React.FC<Props> = ({ isExistingInventory }) => {
  const t = useNamespacedTranslation(i18Path);
  const { onChange: onFieldChange } = useContext(InventoryModalContext);
  return (
    <Input
      onChange={(value) => onFieldChange({ model: value })}
      label={t('form.fields.model')}
      placeholder={t('form.fields.modelPlaceholder')}
      name="model"
      size="md"
      required
      disabled={isExistingInventory}
    />
  );
};
