import * as React from 'react';

const HashTagOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4328 0.586628C11.7046 0.634568 11.8862 0.893836 11.8382 1.16572L9.72241 13.1652C9.67447 13.4371 9.4152 13.6187 9.14332 13.5707C8.87143 13.5228 8.68989 13.2635 8.73783 12.9916L10.8537 0.992112C10.9016 0.720229 11.1609 0.538687 11.4328 0.586628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.71572 0.586628C6.9876 0.634568 7.16914 0.893836 7.1212 1.16572L5.00537 13.1652C4.95743 13.4371 4.69816 13.6187 4.42627 13.5707C4.15439 13.5228 3.97285 13.2635 4.02079 12.9916L6.13663 0.992112C6.18457 0.720229 6.44384 0.538687 6.71572 0.586628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0649 4.28013C15.0649 4.00406 14.8411 3.78025 14.5651 3.78025H2.38043C2.10436 3.78025 1.88055 4.00406 1.88055 4.28013C1.88055 4.55621 2.10436 4.78001 2.38043 4.78001H14.5651C14.8411 4.78001 15.0649 4.55621 15.0649 4.28013Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1094 9.6952C14.1094 9.41912 13.8856 9.19532 13.6095 9.19532H1.42487C1.14879 9.19532 0.924986 9.41912 0.924986 9.6952C0.924986 9.97128 1.14879 10.1951 1.42487 10.1951H13.6095C13.8856 10.1951 14.1094 9.97128 14.1094 9.6952Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default HashTagOutlineIcon;
