import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProjectDayTimeTracked,
  ProjectTimeEntry,
  ProjectWithStatus,
} from '../../../types';
import ButtonLink from '../../../../TimeTracking/TimeTrackingEntries/components/ButtonLink';
import { i18prefix } from '../../../helpers';
import { ButtonsContainer } from '../../../../TimeTracking/TimeTrackingEntries/components/ButtonsContainer';
import SubmitButton from '../../../../TimeTracking/TimeTrackingEntries/components/SubmitButton';
import CancelButton from '../../../../TimeTracking/TimeTrackingEntries/components/CancelButton';
import DayTimeEntry from './DayTimeEntry';
import DayTimeInputLabels from './DayTimeInputLabels';
import { notEmpty } from '../../../../../../../utils/array';
import { uniqueId } from 'lodash';
import { minToHoursInput, UNIQUE_ID_PREFFIX } from '../../helpers';
import { TimeEntryCardLayout } from '../../../../TimeTracking/TimeTrackingEntries/components/TimeEntryCardLayout';
import { CostCenter } from '../../../../../__generated__/graphql';
import { WarningModal } from '../../../../TimeTracking/components/WarningModal/WarningModal';
import { useTimeTrackingRestriction } from '../../../../../hooks/timeTrackingSettings';
import { toISODateOnly } from '../../../../../../../utils/dateUtils';

export type TimeEntryData = {
  id: string;
  projectId: string | null;
  costCenterId: string | null;
  hours: number | null;
  notes: string;
};

type Props = {
  disabled: boolean;
  disableSubmit: boolean;
  restrictedFutureEntries: boolean;
  projects: ProjectWithStatus[];
  costCenters: ReadonlyArray<CostCenter>;
  timesTracked: ProjectDayTimeTracked[];
  onSave: (timesTracked: ProjectTimeEntry[]) => Promise<void>;
  day: Date;
  setDayTotalTracked: (dayTotalTracked: number) => void;
};

const DayTimeEntriesForm: React.FC<Props> = ({
  disabled,
  disableSubmit,
  restrictedFutureEntries,
  projects,
  costCenters,
  timesTracked,
  onSave,
  day,
  setDayTotalTracked,
}) => {
  const { t } = useTranslation();
  const {
    hasOngoingRestrictedAbsenceForDay,
    setShowRestrictedWarningModal,
    showRestrictedWarningModal,
  } = useTimeTrackingRestriction();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [timeEntries, setTimeEntries] = useState<TimeEntryData[]>([]);
  const hasRestrictedAbsence = hasOngoingRestrictedAbsenceForDay(
    toISODateOnly(day),
  );

  const initData = useCallback(() => {
    setTimeEntries(timesTracked.map(mapToFormData));
  }, [timesTracked]);

  useEffect(() => {
    const totalHours = timeEntries
      .map((timeEntry) => timeEntry.hours || 0)
      .reduce((s, c) => s + c, 0);
    setDayTotalTracked(totalHours);
  }, [timeEntries, setDayTotalTracked]);

  useEffect(() => {
    initData();
  }, [initData, timesTracked]);

  const handleSave = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (hasRestrictedAbsence) {
        setShowRestrictedWarningModal(true);
      } else {
        const filteredTimeEntries = timeEntries
          .map(mapToProjectTimeEntry)
          .filter(notEmpty);
        onSave(filteredTimeEntries).then(() => {
          setIsEditMode(false);
        });
      }
    },
    [onSave, timeEntries, hasRestrictedAbsence, setShowRestrictedWarningModal],
  );

  const handleCancel = useCallback(() => {
    setIsEditMode(false);
    initData();
  }, [initData]);

  const handleChange = useCallback((timeEntryToChange: TimeEntryData) => {
    setTimeEntries((timeEntries) =>
      timeEntries.map((timeEntry) =>
        timeEntry.id !== timeEntryToChange.id ? timeEntry : timeEntryToChange,
      ),
    );
    setIsEditMode(true);
  }, []);

  const handleAddTimeEntry = useCallback(() => {
    if (hasRestrictedAbsence) {
      setShowRestrictedWarningModal(true);
    } else {
      setTimeEntries((timeEntries) => [
        ...timeEntries,
        createEmptyItem(getActiveProjectId(projects)),
      ]);
      setIsEditMode(true);
    }
  }, [projects, hasRestrictedAbsence, setShowRestrictedWarningModal]);

  const handleDeleteTimeEntry = useCallback((id: string) => {
    setTimeEntries((timeEntries) =>
      timeEntries.filter((timeEntry) => timeEntry.id !== id),
    );
    setIsEditMode(true);
  }, []);

  return (
    <>
      <WarningModal
        visible={showRestrictedWarningModal}
        onClose={() => setShowRestrictedWarningModal(false)}
      />
      <form onSubmit={handleSave}>
        <TimeEntryCardLayout>
          {timeEntries.length > 0 && (
            <DayTimeInputLabels
              className="mb-2"
              showCostCenterLabel={costCenters.length > 0}
            />
          )}

          {timeEntries.map((timeTracked) => (
            <div key={timeTracked.id}>
              <DayTimeEntry
                timeTracked={timeTracked}
                projects={projects}
                costCenters={costCenters}
                disabled={disabled}
                onDataChange={handleChange}
                onDelete={handleDeleteTimeEntry}
              />
            </div>
          ))}
          {restrictedFutureEntries && (
            <div>{t(`${i18prefix}.restrictedFutureEntries`)}</div>
          )}
          {!disabled && (
            <ButtonLink
              disabled={disabled}
              title={t(`${i18prefix}.addEntry`)}
              onClick={handleAddTimeEntry}
            />
          )}
          {isEditMode && (
            <ButtonsContainer>
              <CancelButton
                disabled={disabled}
                onClick={handleCancel}
                title={t('timeTrackingNew.dayCard.cancel')}
              />
              <SubmitButton
                disabled={disabled || disableSubmit}
                isProcessing={disabled}
                title={t('timeTrackingNew.dayCard.saveChanges')}
              />
            </ButtonsContainer>
          )}
        </TimeEntryCardLayout>
      </form>
    </>
  );
};

export default DayTimeEntriesForm;

function mapToFormData({
  id,
  projectId,
  costCenterId,
  notes,
  minutes,
}: ProjectTimeEntry): TimeEntryData {
  return {
    id: id,
    projectId,
    costCenterId,
    notes: notes || '',
    hours: minutes ? minToHoursInput(minutes) : null,
  };
}

function mapToProjectTimeEntry({
  id,
  projectId,
  costCenterId,
  notes,
  hours,
}: TimeEntryData): ProjectTimeEntry | null {
  if (!hours || !projectId) {
    return null;
  }

  return {
    id,
    notes,
    projectId,
    costCenterId,
    minutes: Math.round(hours * 60),
  };
}

function getActiveProjectId(projects: ProjectWithStatus[]) {
  const project = projects.find((project) => project.status === 'ACTIVE');
  return project ? project.id : null;
}

function createEmptyItem(projectId: string | null): TimeEntryData {
  return {
    id: uniqueId(`${UNIQUE_ID_PREFFIX}_${Date.now()}`),
    notes: '',
    hours: 1,
    projectId,
    costCenterId: null,
  };
}
