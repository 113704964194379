import React from 'react';
import { Spinner } from 'react-bootstrap';
import AttentionBadge from '../AttentionBadge';
import SoftBadge from '../SoftBadge';

type Props = {
  loading?: boolean;
  type?: 'soft' | 'attention' | 'attention-gray';
};

export const SidebarBadge: React.FC<Props> = ({
  children,
  loading = false,
  type = 'soft',
}) => {
  if (loading) {
    return <Spinner animation="border" size="sm" />;
  } else {
    switch (type) {
      case 'soft':
        return <SoftBadge variant="primary">{children}</SoftBadge>;
      case 'attention':
        return (
          <AttentionBadge className="badge-red" bg="">
            {children}
          </AttentionBadge>
        );
      case 'attention-gray':
        return (
          <AttentionBadge className="badge-gray" bg="">
            {children}
          </AttentionBadge>
        );
      default:
        return <></>;
    }
  }
};
