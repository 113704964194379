import { ActiveElement, ChartData, ChartEvent, ChartOptions } from 'chart.js';
import { times } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Bar from '../../../../components/charts/Bar';
import { Theme } from '../../../../theme';
import { isoWeekFromDateString } from '../../../../utils/date';
import { hoursToMinutes } from '../../../../utils/time';
import { TimeSheetYearlyChartFragment } from '../../__generated__/graphql';
import { formattedDuration } from '../DurationFormat';
import { isHourlyBasedWeek } from '../../utils/timesheetHelper';

type Props = {
  year: number;
  timeSheets: TimeSheetYearlyChartFragment[];
  onDatasetClick?: (timeSheetId: string) => void;
};

const theme = Theme.chart.timeTracking;

const chartOptions = (
  durationFormatter: (n: number) => string,
  labelFormatter: (n: number) => string,
  onDatasetClick: (week: number) => void,
): ChartOptions<'bar'> => ({
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.dataset.label;
          const hours = context.dataset.data[context.dataIndex];
          return `<div class="d-flex align-items-center justify-content-between gap-2">
          <div>${label}</div>
          <div>
          ${durationFormatter(hoursToMinutes(hours))}
          </div>
          </div>
          `;
        },
        title: (tooltipItems) => {
          const item = tooltipItems[0];

          return item.label ? labelFormatter(parseFloat(item.label)) : '';
        },
      },
    },
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        color: theme.ticks.color,
      },
      grid: {
        color: theme.gridLines.color,
      },
    },
    x: {
      stacked: true,
      ticks: {
        color: theme.ticks.color,
      },
      grid: {
        color: theme.gridLines.color,
      },
    },
  },
  onClick: (_event?: ChartEvent, activeElements?: ActiveElement[]) => {
    const weekIndex = !!activeElements?.length
      ? activeElements[0].index
      : undefined;

    if (weekIndex !== undefined) {
      onDatasetClick(weekIndex + 1);
    }
  },
});

type BarData = {
  worked: number[];
  overtime: number[];
  absence: number[];
  holiday: number[];
};

type TimeSheetsByWeek = {
  [key: number]: TimeSheetYearlyChartFragment;
};

const buidBarData = (timeSheetByWeek: TimeSheetsByWeek, weeks: number[]) =>
  weeks.reduce<BarData>(
    (result, week) => {
      const timeSheet = timeSheetByWeek[week];

      if (!timeSheet) {
        return {
          worked: [...result.worked, 0],
          overtime: [...result.overtime, 0],
          absence: [...result.absence, 0],
          holiday: [...result.holiday, 0],
        };
      }

      const trackedHours = timeSheet.flair__Time_Entry_Hours__c;
      const overtime = getTimesheetOvertime(timeSheet);
      const worked = trackedHours - overtime;

      return {
        worked: [...result.worked, worked],
        overtime: [...result.overtime, overtime],
        absence: [...result.absence, timeSheet.flair__Absence_Hours__c],
        holiday: [...result.holiday, timeSheet.flair__Holiday_Hours__c],
      };
    },
    { worked: [], overtime: [], absence: [], holiday: [] },
  );

const YearChart: React.FC<Props> = ({ year, timeSheets, onDatasetClick }) => {
  const { t } = useTranslation();
  const durationFormatter = formattedDuration(t);
  const labelFormatter = (week: number) =>
    t('calendarWeek', {
      week: week,
    });

  const weeks = times(moment().year(year).weeksInYear(), (i) => i + 1);
  const timeSheetByWeek = timeSheets.reduce<TimeSheetsByWeek>(
    (result, timeSheet) => ({
      ...result,
      [isoWeekFromDateString(timeSheet.flair__Start_Date__c)]: timeSheet,
    }),
    {},
  );

  const barData = buidBarData(timeSheetByWeek, weeks);

  const chartData: ChartData<'bar'> = {
    labels: weeks,
    datasets: [
      {
        backgroundColor: theme.tracked.color,
        label: t('timeTracking.graphs.week.worked'),
        data: barData.worked,
      },
      {
        backgroundColor: theme.absence.color,
        label: t('timeTracking.graphs.week.absences'),
        data: barData.absence,
      },
      {
        backgroundColor: theme.holiday.color,
        label: t('timeTracking.graphs.week.holidays'),
        data: barData.holiday,
      },
      {
        backgroundColor: theme.overtime.color,
        label: t('timeTracking.graphs.week.overtime'),
        data: barData.overtime,
      },
    ],
  };

  const handleOnClick = (week: number) => {
    const timeSheet = timeSheetByWeek[week];

    if (timeSheet && onDatasetClick) {
      onDatasetClick(timeSheet.Id);
    }
  };

  const options = chartOptions(
    durationFormatter,
    labelFormatter,
    handleOnClick,
  );

  return <Bar data={chartData} options={options} />;
};

function getTimesheetOvertime(timeSheet: TimeSheetYearlyChartFragment): number {
  const trackedHours = timeSheet.flair__Time_Entry_Hours__c;
  const targetHours = timeSheet.flair__Target_Hours__c;

  return isHourlyBasedWeek(timeSheet)
    ? 0
    : Math.max(0, trackedHours - targetHours);
}

export default YearChart;
