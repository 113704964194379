import React from 'react';
import { useTranslation } from 'react-i18next';
import { formattedDuration } from '../DurationFormat';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import FulfillmentPopover from './FulfillmentPopover';
import { TimesheetTrackedTimeFieldsFragment } from '../../__generated__/graphql';
import { hoursToMinutes } from '../../../../utils/time';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import {
  TimeSheetOvertimeInfo,
  getTimesheetOvertimeMinutes,
  getTargetMinutes,
} from '../../utils/timesheetHelper';

type TimeSheet = TimeSheetOvertimeInfo & {
  Id: string;
  trackedTime: TimesheetTrackedTimeFieldsFragment;
  flair__Target_Hours__c: number;
  flair__Time_Entry_Hours__c: number;
  flair__Current_Compensatory_Time_Level_1__c: number | null;
  flair__Current_Compensatory_Time_Level_2__c: number | null;
  flair__Given_Compensatory_Time_Level_1__c: number | null;
  flair__Given_Compensatory_Time_Level_2__c: number | null;
  flair__Non_Deductible_Absence_Hours__c: number | null;
};

type Props = {
  timeSheet: TimeSheet;
};

const Content = React.forwardRef<HTMLSpanElement, Props>(
  ({ timeSheet, ...props }, ref) => {
    const { t } = useTranslation();
    const durationFormatter = formattedDuration(t);
    const balance = getTimesheetOvertimeMinutes(timeSheet);
    const formattedHours = durationFormatter(Math.abs(balance));

    if (balance >= 0) {
      return (
        <span className="text-success" {...props} ref={ref}>
          <FlairIcon icon="caret-up-outline" /> +{formattedHours}
        </span>
      );
    } else {
      return (
        <span className="text-danger" {...props} ref={ref}>
          <FlairIcon icon="caret-down-outline" /> -{formattedHours}
        </span>
      );
    }
  },
);

const TimeSheetBalanceIndicator: React.FC<Props> = ({ timeSheet }) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={
      <Popover id={timeSheet.Id}>
        <FulfillmentPopover
          target={getTargetMinutes(timeSheet)}
          tracked={hoursToMinutes(
            timeSheet.flair__Time_Entry_Hours__c -
              (timeSheet.flair__Non_Deductible_Absence_Hours__c ?? 0),
          )}
        />
      </Popover>
    }>
    <Content timeSheet={timeSheet} />
  </OverlayTrigger>
);

export default TimeSheetBalanceIndicator;
