import { useMemo } from 'react';

import i18next from '../initializers/i18next';

export type Locale =
  | 'en'
  | 'en-us'
  | 'de'
  | 'de-ch'
  | 'fr'
  | 'it'
  | 'es'
  | 'ru';

export const useCurrentLocale = (): Locale => {
  const lang = i18next.languages[0].toLowerCase();
  return useMemo(() => getSupportedLocale(lang), [lang]);
};

export const getCurrentLocale = (): Locale => {
  const lang = i18next.languages[0].toLowerCase();
  return getSupportedLocale(lang);
};

const getSupportedLocale = (lang: string): Locale => {
  if (
    lang === 'en' ||
    lang === 'en-us' ||
    lang === 'de' ||
    lang === 'de-ch' ||
    lang === 'fr' ||
    lang === 'it' ||
    lang === 'es' ||
    lang === 'ru'
  ) {
    return lang;
  }

  return 'en';
};
