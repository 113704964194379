import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { AllowanceInfo, PeriodType } from '../types';
import { AllowanceListItem } from './AllowanceListItem';
import { AccruedHint } from './AccruedHint';

type Props = {
  isUnlimited: boolean;
  amount: number | null;
  allowances: AllowanceInfo[] | null;
  periodType: PeriodType;
};

export const AllowanceListItemAccrued: React.FC<Props> = ({
  isUnlimited,
  periodType,
  amount,
  allowances,
}) => {
  const t = useNamespacedTranslation('absences.cards.absenceCategories');

  return (
    <AllowanceListItem>
      <small className="d-flex">
        {t(periodType === 'next' ? 'willAccrue' : 'accrued')}
        {allowances && amount ? (
          <AccruedHint allowances={allowances} periodType={periodType} />
        ) : null}
      </small>
      <small>{!isUnlimited ? amount : t('unlimited')}</small>
    </AllowanceListItem>
  );
};
