import { useState, useCallback } from 'react';

import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { getErrorText } from './useApproveTimeSheetsMutation';
import { uniq } from 'lodash';
import { TimeSheet } from '../../components/TimeSheetsTable';
import {
  ApproveTimeSheetAction,
  useApproveTimeSheetsMutation,
} from '../../__generated__/graphql';
import { mapPeriod } from '../../components/TimeSheetsTable/mappings';
import { formatPeriodName } from '../../components/TimeSheetsTable/periodFormatter';

export type TimeSheetInfo = { timeSheetId: string } & Pick<
  TimeSheet,
  'period' | 'employee'
>;

export type ApproveTimeSheetValues = {
  approvingIds: string[];
  approveTimeSheets: (
    timeSheetIds: string[],
    timeEntryChangeRequestApproveAction: ApproveTimeSheetAction,
  ) => Promise<void>;
};

export const useApproveTimeSheets = (): ApproveTimeSheetValues => {
  const t = useNamespacedTranslation('timeSheetsControlling');

  const { addSuccess, addError } = useToasts();

  const errorHandler = useMutationErrorHandler();

  const [approvingIds, setApprovingIds] = useState<string[]>([]);

  const [approve] = useApproveTimeSheetsMutation();

  const approveTimeSheets = useCallback(
    (
      timeSheetIds: string[],
      timeEntryChangeRequestApproveAction: ApproveTimeSheetAction,
    ): Promise<void> => {
      if (!timeSheetIds.length) {
        return Promise.resolve();
      }
      setApprovingIds((prev) => uniq([...prev, ...timeSheetIds]));
      return approve({
        variables: {
          ids: timeSheetIds,
          timeEntryChangeRequestApproveAction,
        },
      })
        .then((result) => {
          const error = result.data?.timeSheet.approveTimeSheets.error;
          if (error) {
            addError(getErrorText(error));
            return;
          }
          if (timeSheetIds.length > 1) {
            addSuccess(t('timeSheetsApproved', { count: timeSheetIds.length }));
          } else if (timeSheetIds.length === 1) {
            const timeSheet =
              result.data?.timeSheet.approveTimeSheets.records[0];
            if (timeSheet) {
              addSuccess(
                t('timeSheetApproved', {
                  period: formatPeriodName(mapPeriod(timeSheet)),
                  employeeName: timeSheet.employee.Name,
                }),
              );
            }
          }
        })
        .catch(errorHandler)
        .finally(() => {
          setApprovingIds((prev) =>
            prev.filter((x) => !timeSheetIds.includes(x)),
          );
        });
    },
    [setApprovingIds, approve, errorHandler, addSuccess, addError, t],
  );

  return { approvingIds, approveTimeSheets };
};
