import React from 'react';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { formatDate, toISODateOnly } from '../../../../../../utils/dateUtils';
import { Maybe } from '../../../../../../utils/maybe';
import { useTranslation } from 'react-i18next';

export type Props = {
  value: string;
  onChange: (value: string) => void;
};

const NUMBER_OF_MONTHS = 6;

export const EmployeeOvertimePeriodFilter: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChange = (newValue: Maybe<string>) => {
    if (newValue) {
      onChange(newValue);
    }
  };

  const getLastSixMonths = (): FilterSelectOption[] => {
    const options: FilterSelectOption[] = [];
    const today = new Date();

    for (let i = 0; i < NUMBER_OF_MONTHS; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      let label = formatDate(date, t('date.month-with-year-short'));
      options.push({ value: toISODateOnly(date), label });
    }
    return options;
  };

  const allPeriods = getLastSixMonths();

  return (
    <div style={{ maxWidth: '12rem' }}>
      <SingleFilter
        options={allPeriods}
        value={value}
        onChange={handleChange}
        isClearable={false}
        className="h6-regular"
      />
    </div>
  );
};
