import { keyBy } from 'lodash';
import {
  AnswerProps,
  EmployeeFeedbackResolutionProps,
  FeedbackQuestionProps,
  mapToComparedFeedbackAnswer,
  mapToComparedFeedbackQuestion,
} from '../../../../../../hooks/useComparedFeedbackSectionsMapper';
import { AnswerIdToPeer, PeerFeedback } from '../../types';
import {
  ComparedFeedbackResolution,
  ComparedFeedbackSection,
  GroupedResolutionsByQuestionId,
} from './types';

type Props = {
  questions: ReadonlyArray<FeedbackQuestionProps>;
  answers: ReadonlyArray<ReadonlyArray<AnswerProps>>;
  resolutions: ReadonlyArray<EmployeeFeedbackResolutionProps>;
};

export const mapSections = (props: Props) => {
  const { questions, answers, resolutions } = props;
  const resolutionByQuestion = keyBy<EmployeeFeedbackResolutionProps>(
    resolutions,
    (r) => r.flair__Employee_Feedback_Question__c,
  );

  const sections = questions.reduce((result, question) => {
    const resolutionsForQuestion = answers.reduce(
      (resolutionsAcc, answerSet) => {
        const resolution = resolutionByQuestion[question.Id];
        const answer = keyBy<AnswerProps>(
          answerSet,
          (a) => a.flair__Employee_Feedback_Question__c,
        )[question.Id];

        if (resolution && answer) {
          resolutionsAcc.push({
            id: resolution.Id,
            value: resolution.flair__Resolution__c,
            answers: mapToComparedFeedbackAnswer(answer, question),
            question: mapToComparedFeedbackQuestion(question),
          });
        }

        return resolutionsAcc;
      },
      [] as ComparedFeedbackResolution[],
    );

    if (resolutionsForQuestion.length > 0) {
      const sectionId = question.flair__Employee_Feedback_Question_Section__c;
      if (sectionId) {
        const existingSection = result.find((s) => s.id === sectionId);

        if (existingSection) {
          existingSection.resolutions.push(...resolutionsForQuestion);
        } else {
          result.push({
            id: sectionId,
            name: question.flair__Section_Name__c ?? '',
            resolutions: resolutionsForQuestion,
          });
        }
      }
    }

    return result;
  }, [] as ComparedFeedbackSection[]);

  return sections.map((s) => {
    const groupedResolutionsByQuestion: GroupedResolutionsByQuestionId = {};
    s.resolutions.forEach((r) => {
      const questionId = r.question.id;

      if (!groupedResolutionsByQuestion[questionId]) {
        groupedResolutionsByQuestion[questionId] = [r];
      } else {
        groupedResolutionsByQuestion[questionId].push(r);
      }
    });

    return { ...s, resolutions: groupedResolutionsByQuestion };
  });
};

export const getAnswerIdToPeer = (peerFeedbacks: PeerFeedback[]) => {
  const answerIdToPeerReviewer: AnswerIdToPeer = {};
  peerFeedbacks.forEach((peerFeedback) => {
    peerFeedback.answers.forEach((answer) => {
      answerIdToPeerReviewer[answer.id] = peerFeedback.reviewer;
    });
  });

  return answerIdToPeerReviewer;
};
