import styled from '@emotion/styled';
import i18next from 'i18next';
import React from 'react';
import { Theme } from '../../../../../theme';
import { getCertificateStatusColor } from '../mappings';
import { CertificateStatus } from '../types';
import FlairIcon, {
  FlairIconName,
} from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  status: CertificateStatus;
};

type RenderInfo = {
  text: string;
  color: string;
  icon: FlairIconName;
};

export const CertificateStatusComponent: React.FC<Props> = ({ status }) => {
  const info = getRenderInfo(status);
  return (
    <Container backgroundColor={info.color}>
      <FlairIcon className="mr-2 me-1" size="sm" icon={info.icon} />
      <span>{info.text}</span>
    </Container>
  );
};

const getRenderInfo = (status: CertificateStatus): RenderInfo => {
  const text = i18next.t(`certificates.certificateStatus.${status}`);
  const color = getCertificateStatusColor(status);
  switch (status) {
    case 'Planned':
      return {
        text,
        color,
        icon: 'circle-notch-outline',
      };
    case 'Expired':
      return {
        text,
        color,
        icon: 'close-outline',
      };
    case 'Valid':
      return {
        text,
        color,
        icon: 'checkmark-outline',
      };
    default:
      return {
        text,
        color,
        icon: 'circle-notch-outline',
      };
  }
};

const Container = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 6px',
    borderRadius: 6,
    lineHeight: '20px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor,
    color: Theme.color.white,
  }),
);
