import React, { useEffect, useMemo } from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { i18prefix } from '../../helpers';
import { Day, Project, ProjectTimeTrackingMap } from '../../types';
import HolidaysAndAbsences from '../../../../components/TimeSheet/DayCard/DayHeader/HolidaysAndAbsences';
import classNames from 'classnames';
import {
  useMaxDailyTimeLimitTracking,
  useTimeTrackingRestriction,
} from '../../../../hooks/timeTrackingSettings';
import { formatHoursAndMinutes } from '../helpers';
import { toISODateOnly } from '../../../../../../utils/dateUtils';
import { TotalHoursCol } from './TotalHoursCol';
import { ProjectTitleCol } from './ProjectTitleCol';

const calculateSumByDay = (
  input: ProjectTimeTrackingMap,
): [Map<string, number>, number] => {
  const dateIdMap = new Map<string, number>();
  let allDaysSum = 0;

  for (const projectId in input) {
    if (input.hasOwnProperty(projectId)) {
      const timesheetRecords = input[projectId];
      for (const projectDay of timesheetRecords) {
        const dateId = projectDay.dateId;
        const minutesSum = projectDay.timesTracked.reduce(
          (sum, tracked) => (sum += tracked.minutes || 0),
          0,
        );

        const currentDateIdSum = dateIdMap.get(dateId);
        if (currentDateIdSum !== undefined) {
          dateIdMap.set(dateId, currentDateIdSum + minutesSum);
        } else {
          dateIdMap.set(dateId, minutesSum);
        }

        allDaysSum += minutesSum;
      }
    }
  }

  return [dateIdMap, allDaysSum];
};

const FormatMinutesAndHoursByMinutes: React.FC<{
  minutesToFormat: number;
}> = ({ minutesToFormat }) => {
  return <>{formatHoursAndMinutes(minutesToFormat)}</>;
};

type DaysTotalsRowProps = {
  projects: Project[];
  projectTimeTrackingsMap: ProjectTimeTrackingMap;
  timeSheetsDays: Day[];
  setExceededTimeLimit: (exceededTimeLimit: boolean) => void;
};

export const DaysTotalsRow: React.FC<DaysTotalsRowProps> = ({
  projects,
  projectTimeTrackingsMap,
  timeSheetsDays,
  setExceededTimeLimit,
}) => {
  const t = useNamespacedTranslation(i18prefix);
  const { hasOngoingRestrictedAbsenceForDay } = useTimeTrackingRestriction();
  const { getMaxDailyMinutesLimitForADay } = useMaxDailyTimeLimitTracking();

  // daysTotals will have a Map of each day with the total tracked min [{key: '20240115', value: 600 }]
  const [daysTotals, allDaysTotal] = useMemo(
    () => calculateSumByDay(projectTimeTrackingsMap),
    [projectTimeTrackingsMap],
  );
  const exceededDailyLimitInAnyDay = useMemo(() => {
    return timeSheetsDays.some((timeSheetDay) => {
      const dateId = timeSheetDay.day.format('YYYYMMDD');
      const dayTotal = daysTotals.get(dateId) || 0;
      const { maxDailyLimitEnabled, maxDailyLimitMinutesInMinutes } =
        getMaxDailyMinutesLimitForADay(timeSheetDay.day.toDate());

      return maxDailyLimitEnabled && dayTotal > maxDailyLimitMinutesInMinutes;
    });
  }, [daysTotals, getMaxDailyMinutesLimitForADay, timeSheetsDays]);

  useEffect(() => {
    setExceededTimeLimit(exceededDailyLimitInAnyDay);
  }, [exceededDailyLimitInAnyDay, setExceededTimeLimit]);

  return (
    <>
      {projects.length > 0 && (
        <tr className="py-2 align-items-center">
          <ProjectTitleCol></ProjectTitleCol>
          {timeSheetsDays.map((timeSheetDay) => {
            const hasRestrictedAbsences = hasOngoingRestrictedAbsenceForDay(
              toISODateOnly(timeSheetDay.day.toDate()),
            );
            const dateId = timeSheetDay.day.format('YYYYMMDD');
            const dayTotal = daysTotals.get(dateId) || 0;
            const { maxDailyLimitEnabled, maxDailyLimitMinutesInMinutes } =
              getMaxDailyMinutesLimitForADay(timeSheetDay.day.toDate());

            return (
              <td
                key={dateId}
                className={classNames({
                  'cell-bl': true,
                  highlighted: hasRestrictedAbsences,
                })}>
                <div className="fw-bold ms-2 mb-0 d-flex  align-items-center">
                  <p className="mb-0 me-3">
                    <FormatMinutesAndHoursByMinutes
                      minutesToFormat={
                        dayTotal
                      }></FormatMinutesAndHoursByMinutes>
                  </p>
                  <HolidaysAndAbsences
                    holidays={timeSheetDay.holidays}
                    absences={timeSheetDay.absences}
                    calendarPeriods={timeSheetDay.calendarPeriods}
                    mode="icons"
                  />
                </div>
                <div>
                  {maxDailyLimitEnabled &&
                    dayTotal > maxDailyLimitMinutesInMinutes && (
                      <p className="text-danger fw-bold">
                        {t('projectTable.exceededMaxDailyLimitShort', {
                          timeFormatted: formatHoursAndMinutes(
                            maxDailyLimitMinutesInMinutes,
                          ),
                        })}
                      </p>
                    )}
                </div>
              </td>
            );
          })}
          <TotalHoursCol className="cell-bl">
            <p className="fw-bold text-end ms-2 mb-0">
              <FormatMinutesAndHoursByMinutes
                minutesToFormat={allDaysTotal}></FormatMinutesAndHoursByMinutes>
            </p>
          </TotalHoursCol>
        </tr>
      )}
    </>
  );
};
