import React, { useCallback } from 'react';

import Select, { StylesConfig } from 'react-select';

import { QuestionProps } from '../../index';
import { Theme } from '../../../../../../../theme';
import { QuestionnaireQuestionNotes } from '../../../QuestionnaireQuestionNotes';

type Option = {
  label: string;
  value: string;
};

const selectStyles: StylesConfig<Option, false> = {
  control: (provided, { isFocused }) => ({
    ...provided,
    color: Theme.input.value.color,
    borderRadius: Theme.border.radius,
    borderColor: isFocused
      ? Theme.input.border.focusColor
      : Theme.input.border.color,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: Theme.input.border.focusColor,
    },
  }),
  menuList: (provided) => ({
    ...provided,
    border: `${Theme.border.width} solid ${Theme.input.border.focusColor}`,
    borderRadius: Theme.border.radius,
  }),
  dropdownIndicator: (provided, { isFocused }) => ({
    ...provided,
    color: isFocused ? Theme.input.border.focusColor : Theme.input.border.color,
    ':hover': {
      color: Theme.input.border.focusColor,
    },
  }),
  indicatorSeparator: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused
      ? Theme.input.border.focusColor
      : Theme.input.border.color,
    ':hover': {
      backgroundColor: Theme.input.border.focusColor,
    },
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected
      ? Theme.input.select.option.focusedBg
      : Theme.input.select.option.bg,
    color: Theme.input.select.option.color,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Theme.input.select.option.hoverBg,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: Theme.input.placeholder.color,
  }),
};

export type DropdownInputProps = Omit<
  QuestionProps,
  'type' | 'requiredChoices'
>;

const Dropdown: React.FC<DropdownInputProps> = ({
  value,
  onChange,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
}) => {
  const handleChange = useCallback(
    (option) => {
      onChange(option.value);
    },
    [onChange],
  );

  return (
    <>
      <Select
        value={choices.find((choice) => choice.value === value)}
        options={choices}
        onChange={handleChange}
        isDisabled={readOnly}
        styles={selectStyles}
      />
      {showOptionalComment && (
        <QuestionnaireQuestionNotes
          value={notes}
          onChange={onChangeNotes}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default Dropdown;
