import React from 'react';
import Hint from '../../../../components/hint';
import { OptionBase } from '../../../../components/Select/types';
import { getEmployeeInitials } from '../../utils/employeeInitials';
import {
  EmployeeSelectInfoColleagueFragment,
  EmployeeSelectInfoFragment,
} from '../../__generated__/graphql';
import Avatar from '../../../../atomic/molecules/avatar/Avatar';

export type EmployeeOption = OptionBase & {
  initials: string;
  avatarUrl?: string | null;
};

type Props = {
  employee: EmployeeOption;
  isNameVisible: boolean;
  className?: string;
};

export const EmployeeOptionLabel: React.FC<Props> = ({
  employee,
  isNameVisible,
  className,
}) => {
  return (
    <div className={`d-flex align-items-center text-black ${className ?? ''}`}>
      {isNameVisible ? (
        <>
          <Avatar
            initials={employee.initials}
            avatarUrl={employee.avatarUrl ?? undefined}
            size="xs"
            title={employee.label}
          />
          <div className="ms-2">{employee.label}</div>
        </>
      ) : (
        <Hint id={employee.value} text={employee.label}>
          <div>
            <Avatar
              initials={employee.initials}
              size="xs"
              title={employee.label}
            />
          </div>
        </Hint>
      )}
    </div>
  );
};

export const mapEmployeeOption = (
  src: EmployeeSelectInfoFragment | EmployeeSelectInfoColleagueFragment,
): EmployeeOption => ({
  label: src.Name,
  value: src.Id,
  initials: getEmployeeInitials(src),
  avatarUrl: src.avatar?.url,
});
