import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNow } from '../../../../../hooks/useNow';
import { TimeEntryBreakItem } from '../../../pages/TimeTracking/TimeTrackingEntries/logic/types';
import { validateTimeEntry } from '../../../pages/TimeTracking/TimeTrackingEntries/logic/validation';
import { TimeEntryClockoutDumb } from '../../../pages/TimeTracking/TimeTrackingEntries/TimeEntry/TimeEntryClockoutDumb';
import {
  AutoBreaksRules,
  calculateTimeEntryStats,
  TimeEntryStats,
} from '../shared';
import { AutoBreaksTimeTrackingFeatures } from '../useAutoBreaksTimeTrackingFeatures';
import {
  clockoutBreaksActions,
  createClockoutBreaksReducer,
  createInitialState,
  isDirty,
  TimeTrackingBreak,
  TimeTrackingStateWithEndedBreaks,
} from './clockoutBreaksReducer';
import {
  fromTimeEntryBreakItem,
  mapToAdjustTimeEntryRequest,
  toTimeEntry,
} from './mappings';
import { UnusedBreakTimeModalDumb } from './UnusedBreakTimeModalDumb';
import { addBreakValidationErros, isBreaksReduced } from './validationHelpers';

type Props = {
  breakRules: AutoBreaksRules;
  timeTrackingState: TimeTrackingStateWithEndedBreaks;
  timeTrackingFeatures: AutoBreaksTimeTrackingFeatures;
  notes: string | null;
  onClose: () => void;
  onProceed: (
    notes: string | null,
    updatedBreaks: TimeTrackingBreak[] | null,
  ) => Promise<void>;
};

const i18nprefix = 'autoBreaks.unusedBreakTimeModal.';

export const SuggestBreaksModal: React.FC<Props> = ({
  notes,
  breakRules,
  timeTrackingFeatures,
  timeTrackingState: initialTimeTrackingState,
  onClose,
  onProceed,
}) => {
  const { t } = useTranslation();

  const { now } = useNow(10 * 1000);

  const initialBreaks = useMemo(
    () => [...initialTimeTrackingState.breaks],
    [initialTimeTrackingState.breaks],
  );

  const clockoutBreaksReducer = useMemo(
    () =>
      createClockoutBreaksReducer({
        breakRules,
        initialBreaks,
      }),
    [breakRules, initialBreaks],
  );

  const [state, dispatch] = useReducer(
    clockoutBreaksReducer,
    createInitialState(
      initialTimeTrackingState.start,
      initialTimeTrackingState.breaks,
      new Date(),
    ),
  );

  const adjustTimeEntryRequest = mapToAdjustTimeEntryRequest(state);

  const timeStats: TimeEntryStats = calculateTimeEntryStats(
    breakRules,
    adjustTimeEntryRequest,
  );

  useEffect(() => {
    dispatch(clockoutBreaksActions.updateClockout(now));
  }, [now, dispatch]);

  const handleBreakModalProcees = useCallback(
    (notes: string | null) => {
      const updatedBreaks = !isEqual(initialBreaks, state.breaks)
        ? state.breaks
        : null;
      return onProceed(notes, updatedBreaks);
    },
    [initialBreaks, state.breaks, onProceed],
  );

  const dirty = useMemo(
    () => isDirty(initialBreaks, state.breaks),
    [initialBreaks, state.breaks],
  );

  const handleAddBreak = () => {
    dispatch(clockoutBreaksActions.newBreak());
  };

  const handleAutoAdjust = () => {
    dispatch(clockoutBreaksActions.autoAdjust());
  };

  const handleUndo = () => {
    dispatch(clockoutBreaksActions.undo());
  };

  const handleBreakChange = (breakItem: TimeEntryBreakItem) => {
    dispatch(
      clockoutBreaksActions.updateBreak(fromTimeEntryBreakItem(breakItem)),
    );
  };

  const handleBreakDelete = (breakItem: TimeEntryBreakItem) => {
    dispatch(clockoutBreaksActions.deleteBreak(breakItem.uniqueId));
  };

  const timeEntry = useMemo(() => {
    return toTimeEntry(state.start, state.clockout, state.breaks);
  }, [state.start, state.clockout, state.breaks]);

  const validationErrors = useMemo(() => {
    const errorsMap = validateTimeEntry(timeEntry);
    return addBreakValidationErros(
      errorsMap,
      breakRules,
      adjustTimeEntryRequest,
      initialBreaks,
      state,
    );
  }, [breakRules, timeEntry, initialBreaks, state, adjustTimeEntryRequest]);

  const okDisabled = isBreaksReduced(initialBreaks, state.breaks);

  const buttonLabel = t(i18nprefix + 'confirm');

  return (
    <UnusedBreakTimeModalDumb
      visible={true}
      mode={timeTrackingFeatures.deductBreaks ? 'deduction' : 'suggestions'}
      okButtonLabel={buttonLabel}
      okDisabled={okDisabled}
      onClose={onClose}
      initialNotes={notes}
      onSubmit={handleBreakModalProcees}>
      <div>
        <TimeEntryClockoutDumb
          timeStats={timeStats}
          autoBreakRules={breakRules}
          breaks={timeEntry.breaks}
          start={timeEntry.start}
          validationErrors={validationErrors}
          clockOutDate={state.clockout}
          isAutoAdjusted={state.isAutoAdjusted}
          isDirty={dirty}
          onAddBreak={handleAddBreak}
          onBreakDelete={handleBreakDelete}
          onAutoAdjustBreaks={handleAutoAdjust}
          onUndo={handleUndo}
          onBreakChange={handleBreakChange}
        />
      </div>
    </UnusedBreakTimeModalDumb>
  );
};
