import React, { useCallback } from 'react';
import AutoSaveRadioGroup, {
  RadioGroupParams,
} from '../../../../../../components/form/AutoSaveRadioGroup';
import {
  EvaluationTemplateQuestion,
  EvaluationTemplateQuestionType,
} from '../../../../__generated__/graphql';
import { EvaluationQuestionParams } from './types';

const mapToRadioGroup = (
  question: EvaluationTemplateQuestion,
  defaultValue: string | null,
): RadioGroupParams => ({
  groupLabel: question.flair__Name__c,
  groupName: question.Id,
  hint: question.flair__Hint__c,
  defaultValue: defaultValue,
  items: question.options.map((option) => ({
    name: option.Id,
    label: option.Name,
    hint: option.flair__Hint__c,
  })),
});

type SingleChoiceParams = EvaluationQuestionParams & {
  defaultValue: string | null;
};

const SingleChoice: React.FC<SingleChoiceParams> = ({
  question,
  defaultValue,
  disabled,
  loading,
  onUpdateAnswer,
}) => {
  const handleChange = useCallback(
    (selectedItem: string) => {
      onUpdateAnswer({
        questionType: EvaluationTemplateQuestionType.SingleChoice,
        value: selectedItem,
      });
    },
    [onUpdateAnswer],
  );

  return (
    <AutoSaveRadioGroup
      disabled={disabled || loading}
      required={question.flair__Required__c}
      itemClassName="ms-3 my-1"
      isComplete={!!defaultValue}
      loading={loading}
      onChange={(selectedItem) =>
        handleChange && handleChange(String(selectedItem))
      }
      {...mapToRadioGroup(question, defaultValue)}
    />
  );
};

export default SingleChoice;
