import React from 'react';
import { useTranslation } from 'react-i18next';

const OrganizationWrongPermissionSet: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h1 className="display-4 mb-3">
        {t('organizationWrongPermissionSet.title')}
      </h1>
      <p className="text-muted mb-4">
        {t('organizationWrongPermissionSet.description')}
      </p>
    </div>
  );
};

export default OrganizationWrongPermissionSet;
