import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeaderNav, { TabItem } from '../../components/PageHeaderNav';
import { useUserInfo } from '../../context/UserInfo';
import { routes } from '../../routes';

const PerformanceReviewNav: React.FC = () => {
  const { t } = useTranslation();
  const userInfo = useUserInfo();

  const activeTabs: (TabItem | null)[] = userInfo.isActive
    ? [
        {
          label: t('performanceReview.subMenuItems.reviews.tabs.forMe'),
          route: routes.performanceReview.route,
        },
        {
          label: t('performanceReview.subMenuItems.reviews.tabs.forOthers'),
          route: routes.performanceReviewsForReviewer.route,
        },
      ]
    : [];

  return (
    <PageHeaderNav
      tabItems={activeTabs.filter((x): x is TabItem => x !== null)}
    />
  );
};

export default PerformanceReviewNav;
