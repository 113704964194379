import classNames from 'classnames';
import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { MAX_TOP_SKILLS_NUMBER } from './types';

type Props = {
  totalSkills: number;
  choosenSkills: number;
  className?: string;
};

export const SkillsChoosenStatus: React.FC<Props> = ({
  totalSkills,
  choosenSkills,
  className,
}) => {
  const t = useNamespacedTranslation('skills.employeeSkills');

  return (
    <Container className={className}>
      {t('skillsChooseStatus', {
        total: totalSkills,
        choosen: choosenSkills,
        limit: MAX_TOP_SKILLS_NUMBER,
      })}
    </Container>
  );
};

const Container = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('fs-5', 'text-dark', className)} />
);
