import React from 'react';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import { DashboardBirthdayColleagueFieldsFragment } from '../../../../__generated__/graphql';
import { mapBirthday } from '../mappings';
import { formatDate } from '../../../../../../utils/dateUtils';
import { FlairDateFormat } from '../../../../../../atomic/atoms/FlairFormattedDate/types';
import i18next from 'i18next';

export const mapBirthdayForModal = (
  birthday: DashboardBirthdayColleagueFieldsFragment,
) => {
  const dateDisplayFormat = i18next.t(
    `date.${FlairDateFormat.DayNumberWithMonth}`,
  );

  const employee = {
    name: birthday.Name,
    avatarUrl: birthday.avatar?.url,
  };

  return {
    ...mapBirthday(birthday),
    birthdayDate: formatDate(
      birthday.flair__Birthday_this_year__c || '',
      dateDisplayFormat,
    ),
    employee: <EmployeeWithAvatar employee={employee} />,
  };
};
