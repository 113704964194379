import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlappingAbsence } from './useFetchOverlappingAbsences';
import { Row, Col } from 'react-bootstrap';
import AbsenceStatus from '../../pages/Absences/components/AbsenceStatus';
import AbsenceName from '../../pages/Absences/components/AbsenceName';
import LightList from '../LightList';

type Props = {
  absences: readonly OverlappingAbsence[];
  loading: boolean;
};

const OverlappingAbsences: React.FC<Props> = ({ absences, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return <></>;
  }

  const listItems = absences.map((a) => ({
    key: a.Id,
    name: <AbsenceName absence={a} />,
    value: <AbsenceStatus status={a.flair__Approval_Status__c} textFirst />,
  }));

  return (
    <Row>
      <Col>
        <span className="h5">
          {t('requestAbsence.overlappingAbsences_interval', {
            postProcess: 'interval',
            count: absences.length,
          })}
        </span>

        <LightList items={listItems} itemsToShow={10} />
      </Col>
    </Row>
  );
};

export default OverlappingAbsences;
