import React from 'react';
import { minutesToHoursAndMinutes } from '../../../../utils/time';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { i18prefix } from './helpers';

export const ProjectTrackedTimeFormattedMessage: React.FC<{
  trackedTime: number;
  projectEstimation: number | null;
}> = ({ trackedTime, projectEstimation }) => {
  const t = useNamespacedTranslation(i18prefix);
  const [trackedHours, trackedMinutes] = minutesToHoursAndMinutes(trackedTime);
  if (projectEstimation !== null) {
    const [projectEstimationHours, projectEstimationMinutes] =
      minutesToHoursAndMinutes(projectEstimation);

    return (
      <>
        {t(`projectTable.trackedTimeFormat`, {
          hours: trackedHours,
          minutes: trackedMinutes,
          estimationHours: projectEstimationHours,
          estimationMinutes: projectEstimationMinutes,
        })}
      </>
    );
  } else {
    return (
      <>
        {t(`projectTable.timeTrackedWithoutEstimationFormat`, {
          hours: trackedHours,
          minutes: trackedMinutes,
        })}
      </>
    );
  }
};
