import * as React from 'react';

const FileVideoIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.25 3V8.25H19.5"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 21H18.75C18.9489 21 19.1397 20.921 19.2803 20.7803C19.421 20.6397 19.5 20.4489 19.5 20.25V8.25L14.25 3H5.25C5.05109 3 4.86032 3.07902 4.71967 3.21967C4.57902 3.36032 4.5 3.55109 4.5 3.75V12"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.75 15H4.5C4.08579 15 3.75 15.3358 3.75 15.75V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5V15.75C10.5 15.3358 10.1642 15 9.75 15Z"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 16.875L13.5 15.375V19.9875L10.5 18.375"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default FileVideoIcon;
