import { toISODateOnly } from '../../../../utils/dateUtils';
import { UpdateProjectInput } from '../../__generated__/graphql';
import { ProjectFormData } from './ProjectSidebar/types';
import {
  Project,
  ProjectDayTimeTracked,
  SFProject,
  SFProjectTimeTracked,
  SFTimesheet,
  Timesheet,
} from './types';
import { mapActivities } from './ProjectActivities/mappings';
import { calculateOvertime, toDateId } from './helpers';
import { parseISO } from 'date-fns';
import { mapTypeSafe } from '../../components/TimeSheetsTable/mappings';
import { EmptyProjectId } from './ProjectsControllingTimeSheets/types';

export const mapProject = (project: SFProject): Project => ({
  id: project.Id,
  billable: project.flair__Billable__c,
  name: project.flair__Name__c,
  description: project.flair__Description__c || '',
  startDate: project.flair__Start_Date__c
    ? parseISO(project.flair__Start_Date__c)
    : null,
  endDate: project.flair__End_Date__c
    ? parseISO(project.flair__End_Date__c)
    : null,
  estimation: project.flair__Estimation__c || null,
  timeTrackedInMinutes: project.timeTracked,
  overtime: project.flair__Estimation__c
    ? calculateOvertime(
        project.timeTracked || 0,
        project.flair__Estimation__c * 60,
      )
    : null,
  activities: mapActivities(project),
});

export const mapTimesheet = (timesheet: SFTimesheet): Timesheet => ({
  id: timesheet.Id,
  startDate: parseISO(timesheet.flair__Start_Date__c),
  endDate: parseISO(timesheet.flair__End_Date__c),
  timesheetType: mapTypeSafe(timesheet.flair__Type__c),
  approvalStatus: timesheet.flair__Approval_Status__c,
  projectTimeTracked: timesheet.projectTimeEntries.map(
    mapProjectDayTimeTracked,
  ),
  holidays: timesheet.holidays,
  calendarPeriods: timesheet.calendarPeriods,
  paidAbsences: timesheet.paidAbsences,
  timeSheetDays: timesheet.timeSheetDays,
});

export const mapToUpdateProjectFieldsInput = (
  id: string,
  formData: ProjectFormData,
): UpdateProjectInput => ({
  id,
  name: formData.name,
  description: formData.description,
  billable: formData.billable,
  estimation: formData.estimation || null,
  startDate: dateToStringInput(formData.startDate),
  endDate: dateToStringInput(formData.endDate),
});

export const mapProjectDayTimeTracked = (
  projectTimeEntry: SFProjectTimeTracked,
): ProjectDayTimeTracked => ({
  id: projectTimeEntry.Id,
  timesheetId: projectTimeEntry.flair__Employee_Timesheet__c,
  projectId: projectTimeEntry.flair__Project__c ?? EmptyProjectId,
  costCenterId: projectTimeEntry.flair__Cost_Center__c,
  date: new Date(projectTimeEntry.flair__Date__c),
  dateId: toDateId(projectTimeEntry.flair__Date__c),
  minutes: projectTimeEntry.flair__Minutes__c || undefined,
  notes: projectTimeEntry.flair__Notes__c,
});

const dateToStringInput = (dateValue: Date | null | undefined): string | null =>
  dateValue ? toISODateOnly(dateValue) : null;
