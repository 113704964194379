import { Card } from 'react-bootstrap';
import styled from '@emotion/styled';

const CardBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = styled(
  Card.Body,
)`
  > :last-child {
    margin-bottom: 0;
  }
`;

export default CardBody;
