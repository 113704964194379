import React, { useCallback } from 'react';
import {
  addDateByUnit,
  changePeriodByUnit,
  formatDate,
  isSameOrAfter,
  isSameOrBefore,
  UnitTypeArgs,
} from '../../utils/dateUtils';
import { Col, Row } from 'react-bootstrap';
import Button from '../button';
import { useNamespacedTranslation } from '../../hooks/useNamespacedTranslation';
import classNames from 'classnames';
import styled from '@emotion/styled';

type PeriodItem = { periodStart: Date; periodEnd: Date; currentPeriod: Date };

export type Props = {
  minPeriod?: Date;
  maxPeriod?: Date;
  currentPeriod: Date;
  periodInfo: UnitTypeArgs;
  customClassNames?: { wrapperClassName?: string; messageClassName?: string };
  onPeriodChanged: (val: PeriodItem) => void;
};
const PeriodNav: React.FC<Props> = ({
  currentPeriod,
  minPeriod,
  maxPeriod,
  customClassNames = {
    wrapperClassName: '',
    messageClassName: '',
  },
  periodInfo,
  onPeriodChanged,
}) => {
  const t = useNamespacedTranslation('periodNav');

  const handlePeriodChange = (isAdd: boolean) => {
    const newPeriod = addDateByUnit(
      currentPeriod,
      isAdd ? 1 : -1,
      periodInfo.unit,
    );
    const { start, end } = changePeriodByUnit(newPeriod, periodInfo);
    onPeriodChanged({
      periodStart: start,
      periodEnd: end,
      currentPeriod: newPeriod,
    });
  };

  const getMessage = useCallback(() => {
    const { start, end } = changePeriodByUnit(currentPeriod, periodInfo);
    const res = { start: '', end: '' };
    switch (periodInfo.unit) {
      case 'week': {
        res.start = formatDate(start, 'EEE MMM do');
        res.end = formatDate(end, 'EEE MMM do');
        break;
      }
      case 'month': {
        res.start = formatDate(start, 'MMMM do');
        res.end = formatDate(end, 'MMMM do');
        break;
      }
      case 'year': {
        res.start = formatDate(start, 'MMMM');
        res.end = formatDate(end, 'MMMM yyyy');
        break;
      }
      case 'customYear': {
        res.start = formatDate(start, 'MMMM yyyy');
        res.end = formatDate(end, 'MMMM yyyy');
        break;
      }
      default:
        return formatDate(currentPeriod, 'EEEE - PPP');
    }
    return t('message', res);
  }, [currentPeriod, periodInfo, t]);

  return (
    <Row
      className={classNames('justify-content-between align-items-center', {
        [customClassNames.wrapperClassName || '']:
          !customClassNames.wrapperClassName,
      })}>
      <StyledCol className="col-auto">
        {(!minPeriod ||
          !isSameOrBefore(currentPeriod, minPeriod, periodInfo)) && (
          <Button
            className="h7-medium"
            label={t('previousPeriod')}
            variant="link"
            onClick={() => handlePeriodChange(false)}
          />
        )}
      </StyledCol>
      <Col className="col-auto">
        <div
          className={classNames('text-center', 'h7-medium', {
            [customClassNames.messageClassName || '']:
              !customClassNames.messageClassName,
          })}>
          {getMessage()}
        </div>
      </Col>
      <StyledCol className="col-auto">
        {(!maxPeriod ||
          !isSameOrAfter(currentPeriod, maxPeriod, periodInfo)) && (
          <Button
            className="h7-medium"
            label={t('nextPeriod')}
            variant="link"
            onClick={() => handlePeriodChange(true)}></Button>
        )}
      </StyledCol>
    </Row>
  );
};

const StyledCol = styled(Col)`
  min-width: 9rem;
`;

export default PeriodNav;
