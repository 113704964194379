import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SelectableRowGroupForm } from '../../../../../../../components/form/Selects/SelectableRowGroupForm';
import { SelectableRowType } from '../../../../../../../components/SelectableRowGroup/types';

type Props = {
  list: SelectableRowType[];
  handleChange: (value: { [key: string]: SelectableRowType }) => void;
  isLoading?: boolean;
  defaultActiveButton?: string;
};
export const CategoryFormField: React.FC<Props> = ({
  list,
  handleChange,
  isLoading,
  defaultActiveButton,
}) => {
  if (isLoading) {
    return (
      <div className="d-flex flex-column gap-3">
        <Skeleton height={45} />
        <Skeleton height={45} />
        <Skeleton height={45} />
        <Skeleton height={45} />
      </div>
    );
  }
  return (
    <SelectableRowGroupForm
      fieldName="category"
      rows={list}
      onChange={handleChange}
      defaultActiveButton={defaultActiveButton}></SelectableRowGroupForm>
  );
};
