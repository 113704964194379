import styled from '@emotion/styled';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ModalSidebarContent } from '../ModalSidebar';
import FormBody from './FormBody';

const FooterContainer = styled('div')({
  flex: '1 1 auto',
});

type Props = {
  absenceCategoriesGroupSelect: React.ReactNode;
  absenceCategorySelect: React.ReactNode;
};

const LoadingInput: React.FC<{ lines?: number }> = ({ lines = 1 }) => (
  <Skeleton height={lines * 40} />
);

const LoadingButton: React.FC = () => (
  <div className="btn-block">
    <Skeleton height={40} />
  </div>
);

const Loading: React.FC<Props> = ({
  absenceCategorySelect,
  absenceCategoriesGroupSelect,
}) => {
  return (
    <ModalSidebarContent
      body={
        <FormBody
          absenceCategoriesGroupSelect={absenceCategoriesGroupSelect}
          absenceCategorySelect={absenceCategorySelect}
          startDateInput={<LoadingInput />}
          startDateTimeInput={<LoadingInput />}
          endDateInput={<LoadingInput />}
          endDateTimeInput={<LoadingInput />}
          requireNote={false}
          noteInput={<LoadingInput lines={2} />}
        />
      }
      footer={
        <FooterContainer>
          <LoadingButton />
          <LoadingButton />
        </FooterContainer>
      }
    />
  );
};

export default Loading;
