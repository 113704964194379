import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatDateLong, parseDate } from '../../../../../../utils/dateUtils';
import { getGoalDueDate } from '../logic';
import { GoalBase, GoalId, GoalKeyResult } from '../types';
import { GoalCardLabels } from './GoalLabels/GoalCardLabels';
import { GoalProgress } from './GoalProgress';
import { GoalRateIcon } from './GoalRateIcon';
import { KeyResultValue } from './KeyResultValue';
import {
  UpdateKeyResultData,
  UpdateKeyResultsModal,
} from '../UpdateKeyResultsModal';
import { useUpdateKeyResultMutation } from '../../../../__generated__/graphql';
import { toObjectiveIdInput } from '../mappings';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { GoalAvatar } from '../GoalAvatar';
import classNames from 'classnames';
import { Theme } from '../../../../../../theme';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = {
  className?: string;
  goal: GoalBase;
  onClick: (goalId: GoalId) => void;
};

const i18prefix = 'performanceReview.goals2.goalStatusCard.';

export const GoalCardItem: React.FC<Props> = ({ className, goal, onClick }) => {
  const {
    name,
    employee,
    progress,
    expectedProgress,
    status,
    keyResults,
    type,
    period,
    visiblity,
    tags,
    sharedWith,
  } = goal;
  const dueDate = getGoalDueDate(goal);
  const dueDateStr = dueDate ? formatDateLong(parseDate(dueDate)) : '';

  const { t } = useTranslation();
  const [updateKeyResultValue] = useUpdateKeyResultMutation();
  const errorHandler = useMutationErrorHandler();

  const [keyResultsExpanded, setKeyResultsExpanded] = useState<boolean>(false);
  const [selectedKeyResult, setSelectedKeyResult] =
    useState<GoalKeyResult | null>(null);

  const handleExpandClick = useCallback(() => {
    setKeyResultsExpanded((prevValue) => !prevValue);
  }, [setKeyResultsExpanded]);

  const handleClick = useCallback(() => {
    onClick(goal);
  }, [onClick, goal]);

  const handleGoalKeyResultUpdate = (
    goalId: GoalId,
    data: UpdateKeyResultData,
  ) => {
    return updateKeyResultValue({
      variables: {
        objective: toObjectiveIdInput(goalId),
        input: {
          id: data.keyResultId,
          value: data.currentValue,
          comment: data.comments,
        },
      },
    })
      .then(() => setSelectedKeyResult(null))
      .catch(errorHandler);
  };

  return (
    <div className={className}>
      <div className="d-sm-flex align-items-center">
        <MainCol className="d-flex align-items-center">
          <GoalAvatar
            initials={employee?.initials ?? ''}
            hintText={employee?.name ?? ''}
            avatarUrl={employee?.avatarUrl}
          />
          <div className="ms-3">
            <Name onClick={handleClick}>{name}</Name>
            <div className="d-flex">
              {keyResults.length > 0 && (
                <ExpandKeyResultsButton
                  className="me-2 d-none d-sm-block text-nowrap"
                  onClick={handleExpandClick}>
                  <FlairIcon
                    size="xs"
                    icon={
                      keyResultsExpanded
                        ? 'chevron-down-outline'
                        : 'chevron-forward-outline'
                    }
                  />
                  <span className="ms-2">{keyResults.length}</span>
                </ExpandKeyResultsButton>
              )}
              <GoalCardLabels
                goalId={goal.id}
                status={status}
                goalType={type}
                periodName={period?.name}
                visiblity={visiblity}
                sharedWith={sharedWith}
                tags={tags}
                rateInfo={goal}
                cancelReason={goal.cancelReason}
                statusChangedBy={goal.statusChangedBy}
              />
            </div>
          </div>
        </MainCol>
        <SecondCol className="me-sm-4 mt-2 mt-sm-0">
          <span className="text-nowrap me-2 me-sm-4">{dueDateStr}</span>
          <GoalRateIcon rate={goal.rate} />
        </SecondCol>
        <ProgressCol className="d-flex align-items-center justify-content-between">
          {keyResults.length > 0 ? (
            <ExpandKeyResultsButton
              className="d-flex d-sm-none text-nowrap align-items-center me-2"
              onClick={handleExpandClick}>
              <span className="me-2">
                {t(i18prefix + 'keyResult', { count: keyResults.length })}
              </span>
              <FlairIcon
                size="sm"
                icon={
                  keyResultsExpanded
                    ? 'chevron-down-outline'
                    : 'chevron-forward-outline'
                }
              />
            </ExpandKeyResultsButton>
          ) : (
            <div>&nbsp;</div>
          )}
          <ProgressStyled
            goalId={goal.id}
            progress={progress}
            goalExpectedProgress={expectedProgress}
            status={status}
          />
        </ProgressCol>
      </div>
      {selectedKeyResult && (
        <UpdateKeyResultsModal
          keyResult={selectedKeyResult}
          onClose={() => setSelectedKeyResult(null)}
          onGoalKeyResultUpdate={(value) =>
            handleGoalKeyResultUpdate(goal, value)
          }
        />
      )}
      <Collapse in={keyResultsExpanded}>
        <div className="ms-0 ms-sm-5">
          {keyResults.map((kr) => (
            <KeyResult
              key={kr.id}
              className="d-flex align-items-center pb-3 pt-3"
              onClick={() => setSelectedKeyResult(kr)}>
              <MainCol>
                <Name>{kr.name}</Name>
              </MainCol>
              <SecondCol className="me-4 w-sm-100">
                <KeyResultValue
                  type={kr.type}
                  target={kr.targetValue}
                  current={kr.currentValue}></KeyResultValue>
              </SecondCol>
              <ProgressStyled
                goalId={goal.id}
                progress={kr.progress}
                status={status}
              />
            </KeyResult>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const MainCol = styled('div')({
  flexGrow: 1,
});

const SecondCol = styled('div')({
  fontSize: Theme.font.size.sm,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '@media (min-width: 576px)': {
    justifyContent: 'space-between',
  },
});

const ProgressStyled = styled(GoalProgress)({
  maxWidth: 85,
});

const ProgressCol = styled('div')({
  minHeight: 18,
});

const Name = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('text-primary')} role="button" />
);

const KeyResult = styled.div`
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 1px dashed ${Theme.color.paper3};
  }
`;

const ExpandKeyResultsButton = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={classNames('text-primary', 'fs-5', className)}
    role="button"
  />
);
