import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { TeamAvatar, TeamAvatarSize } from './TeamAvatar';

export type Props = {
  name: string;
  popup: React.ReactNode;
  isTeamNameVisible?: boolean;
  teamAvatarSize?: TeamAvatarSize;
};

export const TeamWithPopupBase: React.FC<Props> = ({
  name,
  popup,
  teamAvatarSize,
  isTeamNameVisible = true,
}) => {
  const getCssClasses = () =>
    isTeamNameVisible ? 'd-flex align-items-center' : 'd-inline-block';

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id="team-popup">
          <Popover.Body>{popup}</Popover.Body>
        </Popover>
      }>
      <div className={getCssClasses()}>
        <TeamAvatar size={teamAvatarSize} />
        {isTeamNameVisible && <div className="ms-2">{name}</div>}
      </div>
    </OverlayTrigger>
  );
};
