import React from 'react';
import { AbsenceCalendarContainer } from '../../../../components/AbsenceCalendar/AbsenceCalendarContainer';
import { Table as BootstrapTable } from 'react-bootstrap';
import { Moment } from 'moment';
import { AbsenceColleague } from '../Content';
import Content from './Content';
import { TeamCalendarFiltersType } from './TeamCalendarFilters/types';
import { times } from 'lodash';
import moment from 'moment';

type Props = {
  fromDate: Moment;
  toDate: Moment;
  colleagues: AbsenceColleague[];
  filterData: TeamCalendarFiltersType;
};

const Table: React.FC<Props> = ({
  fromDate,
  toDate,
  colleagues,
  filterData,
}) => {
  const numOfDays = toDate.diff(fromDate, 'days');
  const days = times(numOfDays + 1, (i) => moment(fromDate).add(i, 'days'));

  return (
    <AbsenceCalendarContainer>
      <BootstrapTable size="sm" responsive>
        <Content days={days} colleagues={colleagues} filterData={filterData} />
      </BootstrapTable>
    </AbsenceCalendarContainer>
  );
};
export default Table;
