import React, { useMemo, useState } from 'react';
import ServerError from '../../../../../components/ServerError';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { useCelebrationsQuery } from '../../../__generated__/graphql';
import {
  CelebrationsGroup,
  CelebrationsGroupSkeleton,
} from '../CelebrationsGroup';
import {
  groupCelebrations,
  mapCelebration,
  mapCelebrationFeed,
} from './mappings';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { CelebrationCreateModal } from '../createOrEditCelebration/CelebrationCreateModal';
import { CelebrationUpdateModal } from '../createOrEditCelebration/CelebrationUpdateModal';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../context/Toast';
import { CelebrationDeleteConfirmation } from './CelebrationDeleteConfirmation';
import { Celebration, CelebrationsView } from '../types';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import { useDeleteCelebration } from '../useDeleteCelebration';
import { CelebrationMakePublicConfirmation } from './CelebrationMakePublicConfirmation';
import { useMakeCelebrationPublic } from '../useMakePublicCelebration';

type Props = {
  view: CelebrationsView;
};

const i18nPrefix = 'cheers.employeePage';

const PAGE_SIZE = 50;

export const Celebrations: React.FC<Props> = ({ view }) => {
  const t = useNamespacedTranslation(i18nPrefix);

  const { addSuccess, addError } = useToasts();

  const { data, loading, error, fetchMore } = useCelebrationsQuery({
    variables: {
      filter: {
        feed: mapCelebrationFeed(view),
      },
      offset: 0,
      limit: PAGE_SIZE,
      skipPaginationInfo: false,
    },
  });

  const celebrationPaginationInfo = useMemo(() => {
    return data?.celebrationsPagination;
  }, [data]);

  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: celebrationPaginationInfo,
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo:
            celebrationPaginationInfo?.paginationInfo?.totalCount !== undefined,
        },
      }).then(() => {});
    },
  });

  const [makePublicItemId, setMakePublicItemId] = useState<string | null>(null);
  const [celebrationToUpdate, setCelebrationToUpdate] =
    useState<Celebration | null>(null);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [makeCelebrationPublic] = useMakeCelebrationPublic();
  const [deleteCelebration] = useDeleteCelebration();
  const errorHandler = useMutationErrorHandler();

  const [createModalVisible, setCreateModalVisible] = useState(false);

  const handleCreate = () => {
    setCreateModalVisible(true);
  };

  const handleMakePublic = (id: string) => {
    setMakePublicItemId(id);
  };

  const handleMakePublicClose = () => {
    setMakePublicItemId(null);
  };

  const doMakeCelebrationPublic = () => {
    if (!makePublicItemId) {
      return;
    }
    makeCelebrationPublic({
      variables: {
        input: {
          id: makePublicItemId,
        },
      },
    })
      .then((r) => {
        if (r.data?.celebrations.makeCelebrationPublic.error) {
          addError(r.data?.celebrations.makeCelebrationPublic.error);
          return;
        }
        addSuccess(t('makePublic.success'));
      })
      .catch(errorHandler)
      .finally(() => {
        setMakePublicItemId(null);
      });

    setMakePublicItemId(null);
  };

  const handleUpdate = (celebration: Celebration) => {
    setCelebrationToUpdate(celebration);
  };

  const handleUpdateClose = () => {
    setCelebrationToUpdate(null);
  };

  const handleDelete = (id: string) => {
    setDeleteItemId(id);
  };

  const handleDeleteClose = () => {
    setDeleteItemId(null);
  };

  const doDeleteCelebration = () => {
    if (!deleteItemId) {
      return;
    }
    deleteCelebration({
      variables: {
        input: {
          id: deleteItemId,
        },
      },
    })
      .then((r) => {
        if (r.data?.celebrations.deleteCelebration.error) {
          addError(r.data?.celebrations.deleteCelebration.error);
          return;
        }
        addSuccess(t('deleteSuccess'));
      })
      .catch(errorHandler)
      .finally(() => {
        setDeleteItemId(null);
      });

    setDeleteItemId(null);
  };

  const renderContent = () => {
    if (error) {
      return <ServerError />;
    }

    if (loading || !data) {
      return <Loading />;
    }

    const now = new Date();

    const celebrations = data.celebrationsPagination.items;

    if (celebrations.length === 0) {
      return <Empty view={view} onCreate={handleCreate} />;
    }
    const mappedCelebrations = celebrations.map(mapCelebration);
    const groupedCelebrations = groupCelebrations(mappedCelebrations, now);

    return (
      <div>
        {groupedCelebrations.map((group, i) => {
          return (
            <CelebrationsGroup
              key={group.firstGroupDay}
              group={group}
              celebrations={group.celebrations}
              now={now.toISOString()}
              onMakePublic={handleMakePublic}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              className="mb-4"
              view={view}
              topRightAction={
                i === 0 ? (
                  <Button
                    variant="primary"
                    onClick={handleCreate}
                    label={t('cheerBtn')}
                  />
                ) : undefined
              }
            />
          );
        })}
        {LoadingMore}
      </div>
    );
  };

  return (
    <div>
      {renderContent()}
      <CelebrationCreateModal
        show={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
      />
      <CelebrationUpdateModal
        show={!!celebrationToUpdate}
        celebration={celebrationToUpdate}
        onClose={handleUpdateClose}
      />
      <CelebrationMakePublicConfirmation
        visible={!!makePublicItemId}
        onClose={handleMakePublicClose}
        onSubmit={doMakeCelebrationPublic}
      />
      <CelebrationDeleteConfirmation
        visible={!!deleteItemId}
        onClose={handleDeleteClose}
        onSubmit={doDeleteCelebration}
      />
    </div>
  );
};

const Loading: React.FC = () => {
  return <CelebrationsGroupSkeleton />;
};

const Empty: React.FC<{ view: CelebrationsView; onCreate: () => void }> = ({
  view,
  onCreate,
}) => {
  const t = useNamespacedTranslation(i18nPrefix);

  return (
    <EmptyStateCardBody
      title={
        view === 'received'
          ? t('emptyStateMessageReceived')
          : t('emptyStateMessage')
      }
      action={
        <Button variant="primary" onClick={onCreate} label={t('cheerBtn')} />
      }
    />
  );
};
