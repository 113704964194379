import moment from 'moment';
import React from 'react';
import FormattedDateTime, {
  DateTimeFormat,
} from '../../../../../components/datetime/FormattedDateTime';
import { AbsenceType } from '../../../__generated__/graphql';
import FormattedAbsenceDate from './FormattedAbsenceDate';
import { DateFormat } from '../../../../../components/date/FormattedDate';

type Props = {
  date: string;
  time: string;
  halfDay: boolean;
  type: AbsenceType;
  dateFormat?: DateFormat;
  dateTimeFormat?: DateTimeFormat;
};

const toDate = (date: string, time: string) =>
  moment(`${date}T${time}`).toDate();

const AbsenceDateTime: React.FC<Props> = ({
  date,
  time,
  halfDay,
  type,
  dateFormat,
  dateTimeFormat = 'long',
}) => {
  switch (type) {
    case AbsenceType.Daily:
      return (
        <FormattedAbsenceDate
          day={date}
          halfDay={halfDay}
          format={dateFormat}
        />
      );
    case AbsenceType.Hourly:
    case AbsenceType.Unknown:
      return (
        <FormattedDateTime
          dateTime={toDate(date, time)}
          format={dateTimeFormat}
        />
      );
  }
};

export default AbsenceDateTime;
