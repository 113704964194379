import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { EmployeeSkillsRadar } from '../radarChart/EmployeeSkillsRadar';
import { EmployeeSkillsSidebar } from './EmployeeSkillsSidebar';

type Props = {
  employeeId: string;
  employeeName: string;
};

export const EmployeeSkillsCard: React.FC<Props> = ({
  employeeId,
  employeeName,
}) => {
  const t = useNamespacedTranslation('skills.employeeSkillCard');
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

  const handleViewAllClick = () => {
    setSidebarVisible(true);
  };

  const handleSidebarClose = () => {
    setSidebarVisible(false);
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="fw-bold">{t('header')}</div>
          <Button
            label={t(`viewAll`)}
            size="sm"
            variant="outline-primary"
            onClick={handleViewAllClick}
          />
        </Card.Header>
        <Card.Body>
          <EmployeeSkillsRadar employeeId={employeeId} />
        </Card.Body>
      </Card>
      <EmployeeSkillsSidebar
        visible={sidebarVisible}
        employeeId={employeeId}
        employeeName={employeeName}
        onClose={handleSidebarClose}
      />
    </>
  );
};
