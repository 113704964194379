import React from 'react';
import { Button, ButtonProps, Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type DropdownActionItemProps = DropdownItemProps & {
  size?: 'sm';
};

const DropdownToggle = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, ...rest }, ref) => (
    <Button
      {...rest}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
      variant="link"
      className="p-0 d-flex">
      <FlairIcon icon="calendar-clear-outline" />
      <FlairIcon icon="chevron-down-outline" className="ms-sm-1" />
    </Button>
  ),
);

export const DropdownActions: React.FC = ({ children }) => (
  <Dropdown>
    <Dropdown.Toggle as={DropdownToggle} />
    <Dropdown.Menu className="dropdown-menu-right">{children}</Dropdown.Menu>
  </Dropdown>
);

export const DropdownActionItem: React.FC<DropdownActionItemProps> = ({
  children,
  size,
  className,
  onClick,
  ...props
}) => (
  <Dropdown.Item
    {...props}
    as="button"
    className={[
      'd-flex align-items-center',
      className,
      size === 'sm' ? 'small px-3 py-1' : undefined,
    ].join(' ')}
    onClick={(e) => {
      e.stopPropagation();
      onClick && onClick(e);
    }}>
    {children}
  </Dropdown.Item>
);
