import { Reference, isReference } from '@apollo/client/utilities';
import { deleteItemFromPagination } from '../../../../utils/pagination';
import {
  CelebrationPagination,
  useDeleteCelebrationMutation,
} from '../../__generated__/graphql';

export const useDeleteCelebration = () => {
  return useDeleteCelebrationMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const idToRemove = response.data.celebrations.deleteCelebration.recordId;
      if (!idToRemove) {
        return;
      }

      cache.modify({
        fields: {
          celebrationsPagination(
            existent: Reference | CelebrationPagination,
            { readField },
          ) {
            if (isReference(existent)) {
              return existent;
            }
            return deleteItemFromPagination(existent, readField, idToRemove);
          },
        },
      });
    },
    optimisticResponse: ({ input }) => {
      return {
        celebrations: {
          deleteCelebration: {
            __typename: 'CelebrationPayload',
            error: null,
            recordId: input.id,
          },
        },
      };
    },
  });
};
