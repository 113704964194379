import * as yup from 'yup';
import { TicketCategorySchemaType, TicketDetailsSchemaType } from './types';

export const categorySchema = yup.object().shape<TicketCategorySchemaType>({
  category: yup.string().required(),
});

export const ticketDetailsSchemaEmployee = yup
  .object()
  .shape<TicketDetailsSchemaType>({
    issue: yup.string().required(),
    details: yup.string(),
    shareWithManager: yup.boolean().default(false),
    stayAnonymous: yup.boolean().default(false),
  });

export const ticketDetailsSchemaManager = yup
  .object()
  .shape<TicketDetailsSchemaType>({
    employee: yup.string().required(),
    issue: yup.string().required(),
    details: yup.string(),
  });
