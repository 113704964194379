import { ChartData, ChartOptions } from 'chart.js';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Bar from '../../../../components/charts/Bar';
import { Theme } from '../../../../theme';
import { hoursToMinutes, minutesToHours } from '../../../../utils/time';
import { TimeSheetDistChartFragment } from '../../__generated__/graphql';
import { formattedDuration } from '../DurationFormat';
import {
  getTimesheetOvertimeMinutes,
  isHourlyBasedWeek,
} from '../../utils/timesheetHelper';

type TimeSheet = TimeSheetDistChartFragment;

type Props = {
  timeSheet: TimeSheet;
};

const theme = Theme.chart.timeTracking;

const chartOptions = (t: TFunction): ChartOptions<'bar'> => {
  const durationFormatter = formattedDuration(t);

  return {
    indexAxis: 'y', // horizontalBar
    plugins: {
      tooltip: {
        filter: (context) => {
          const hours = context.dataset.data[context.dataIndex];
          return hours > 0;
        },
        callbacks: {
          label: (context) => {
            const label = context.dataset.label;
            const hours = context.dataset.data[context.dataIndex];
            return `<div class="d-flex align-items-center justify-content-between gap-2">
            <div>${label}</div>
            <div>
            ${durationFormatter(hoursToMinutes(hours))}
            </div>
            </div>
            `;
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          color: theme.ticks.color,
        },
        grid: {
          color: theme.gridLines.color,
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: theme.ticks.color,
        },
        grid: {
          color: theme.gridLines.color,
        },
      },
    },
  };
};

const TimeDistChart: React.FC<Props> = ({ timeSheet }) => {
  const { t } = useTranslation();

  const tracked = timeSheet.trackedTime;
  const workedMinutes = Math.round(tracked.totalWorkedMinutes);
  const trackedMinutes = Math.round(tracked.totalTrackedMinutes);
  const isTrackedVisible = trackedMinutes !== workedMinutes;

  const isHourlyBased = isHourlyBasedWeek(timeSheet);

  const overtimeMinutes = Math.max(0, getTimesheetOvertimeMinutes(timeSheet));

  const barData: ChartData<'bar'> = {
    labels: [
      t('timeTracking.graphs.timeDist.bar.workload'),
      t('timeTracking.graphs.timeDist.bar.worked'),
      isTrackedVisible ? t('timeTracking.graphs.timeDist.bar.tracked') : null,
    ].filter((x): x is string => x !== null),
    datasets: [
      ...(!isHourlyBased
        ? [
            {
              data: [timeSheet.flair__Target_Hours__c],
              backgroundColor: theme.tracked.color,
              label: t('timeTracking.graphs.timeDist.tooltip.target'),
            },
          ]
        : []),
      {
        data: [
          timeSheet.flair__Absence_Hours__c -
            (timeSheet.flair__Non_Deductible_Absence_Hours__c ?? 0),
        ],
        backgroundColor: theme.absence.color,
        label: t('timeTracking.graphs.timeDist.tooltip.absences'),
      },
      {
        data: [timeSheet.flair__Holiday_Hours__c],
        backgroundColor: theme.holiday.color,
        label: t('timeTracking.graphs.timeDist.tooltip.holidays'),
      },
      {
        data: [0, minutesToHours(workedMinutes)],
        backgroundColor: theme.tracked.color,
        label: t('timeTracking.graphs.timeDist.tooltip.worked'),
      },
      {
        data: [0, minutesToHours(overtimeMinutes)],
        backgroundColor: theme.overtime.color,
        label: t('timeTracking.graphs.timeDist.tooltip.overtime'),
      },
      ...(isTrackedVisible
        ? [
            {
              data: [0, 0, minutesToHours(trackedMinutes)],
              backgroundColor: theme.tracked.color,
              label: t('timeTracking.graphs.timeDist.tooltip.tracked'),
            },
          ]
        : []),
    ],
  };

  return <Bar data={barData} options={chartOptions(t)} />;
};

export default TimeDistChart;
