/**
 * Formats a datetime range string for a time entry change request
 *
 * @param startDatetime start datetime
 * @param endDatetime end datetime
 * @returns formatted datetime rage string. If any of the dates is invalid, returns undefined.
 */
export const formatTimeEntryChangeRequestDateRange = (
  startDatetime: Date,
  endDatetime: Date,
) => {
  const isValidDateObject = (d: Date) => {
    return d instanceof Date && !isNaN(d.getTime());
  };
  if (
    !startDatetime ||
    !isValidDateObject(startDatetime) ||
    !endDatetime ||
    !isValidDateObject(endDatetime)
  ) {
    return undefined;
  }
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  });
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  });
  const startDate = dateFormatter.format(startDatetime);
  const startTime = timeFormatter.format(startDatetime);
  const endDate = dateFormatter.format(endDatetime);
  const endTime = timeFormatter.format(endDatetime);
  if (startDate === endDate) {
    return `${startDate}, ${startTime} - ${endTime}`;
  } else {
    return `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
  }
};
