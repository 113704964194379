import React from 'react';
import classNames from 'classnames';
import DurationFormat from '../../../../components/DurationFormat';

type Props = {
  value: number;
};

export const TrackedTimeCell: React.FC<Props> = ({ value }) => {
  return (
    <div className={classNames('d-flex gap-1 align-items-center')}>
      <DurationFormat minutes={value} />
    </div>
  );
};
