import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Redirect } from '../../../../Router';
import {
  useFetchRegisterEmployeeQuery,
  RegistrationEmployeeError,
} from '../../__generated__/graphql';
import Form from './Form';
import { routes } from '../../routes';
import ServerError from '../../../../components/ServerError';
import OrganizationNotEnabled from '../OrganizationNotEnabled';
import UserNotFound from '../UserNotFound';
import EmailAlreadyRegistered from '../EmailAlreadyRegistered';
import OrganizationWrongPermissionSet from '../OrganizationWrongPermissionSet';

type RouteParams = {
  organizationId: string;
};

const OperationError: React.FC<{
  error: RegistrationEmployeeError;
  email?: string;
}> = ({ error, email }) => {
  switch (error) {
    case RegistrationEmployeeError.EmployeeNotFound:
      return <UserNotFound />;
    case RegistrationEmployeeError.OrganizationNotEnabled:
      return <OrganizationNotEnabled />;
    case RegistrationEmployeeError.OrganizationWrongPermissionSet:
      return <OrganizationWrongPermissionSet />;
    case RegistrationEmployeeError.EmployeeAlreadyRegistered:
      return <Redirect to={routes.login.route} />;
    case RegistrationEmployeeError.EmailAlreadyRegistered:
      return <EmailAlreadyRegistered email={email ?? ''} />;
  }
};

const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border" role="status"></div>
  </div>
);

const Register: React.FC = () => {
  const { organizationId } = useParams<RouteParams>();
  const queryString = new URLSearchParams(useLocation().search);
  const token = queryString.get('token') || 'invalidToken';
  const { loading, data, error } = useFetchRegisterEmployeeQuery({
    variables: { organizationId, token },
  });

  if (loading) return <Loading />;
  if (error) return <ServerError />;

  if (data) {
    if (data.getRegistationEmployee.error) {
      return (
        <OperationError
          error={data.getRegistationEmployee.error}
          email={
            data.getRegistationEmployee.employee?.flair__Company_Email__c
          }></OperationError>
      );
    }

    if (data.getRegistationEmployee.employee) {
      return (
        <Form
          employee={data.getRegistationEmployee.employee}
          organizationId={organizationId}
          token={token}
        />
      );
    }
  }

  return <Redirect to={routes.login.route} />;
};

export default Register;
