import React from 'react';
import SkeletonCard from '../../../../components/Skeleton/Card';
import SkeletonPageHeader from '../../../../components/Skeleton/PageHeader';

export const Loading: React.FC = () => {
  return (
    <>
      <SkeletonPageHeader />
      <SkeletonCard height={250} />
      <SkeletonCard height={250} />
      <SkeletonCard height={250} />
    </>
  );
};
