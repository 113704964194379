import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18prefix } from '../helpers';
import { SelectDropdown } from '../../../../../components/Select/SelectDropdown';
import { ProjectViewType, Timesheet } from '../types';
import WeekNav from '../../../../../components/WeekNav';
import { startOfISOWeek, startOfMonth } from 'date-fns';
import { isSame } from '../../../../../utils/dateUtils';
import MonthNav from '../../../../../components/MonthNav';
import { WorkloadType } from '../../../__generated__/graphql';

type Props = {
  timesheets: Timesheet[];
  selectedViewType: ProjectViewType;
  selectedTimesheet: Timesheet;
  onTimesheetChange: (id: string) => void;
  onViewTypeChange: (viewType: ProjectViewType) => void;
};

const SelectionPanel: React.FC<Props> = ({
  timesheets,
  selectedTimesheet,
  selectedViewType,
  onViewTypeChange,
  onTimesheetChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="ms-auto me-2 text-muted">
        {t(`${i18prefix}.topPanel.viewBy`)}
      </div>
      <SelectDropdown
        className="pe-2"
        options={[
          {
            value: 'BY_PROJECTS',
            label: t(`${i18prefix}.topPanel.projects`),
          },
          { value: 'BY_DAYS', label: t(`${i18prefix}.topPanel.days`) },
        ]}
        value={selectedViewType}
        onChange={(val: string | null) => {
          isViewType(val) && onViewTypeChange(val);
        }}
      />
      <Nav
        timesheets={timesheets}
        selectedTimesheet={selectedTimesheet}
        onTimesheetChange={onTimesheetChange}></Nav>
    </>
  );
};

const Nav: React.FC<
  Pick<Props, 'timesheets' | 'onTimesheetChange' | 'selectedTimesheet'>
> = ({ timesheets, selectedTimesheet, onTimesheetChange }) => {
  const minDate = timesheets[0]?.startDate;
  const maxDate = timesheets[timesheets.length - 1]?.startDate;

  const handleNavChange = (newValue: Date) => {
    const newTimesheet = timesheets?.find((timesheet) =>
      isSame(newValue, timesheet.startDate, { unit: 'day' }),
    );

    if (newTimesheet && onTimesheetChange) {
      onTimesheetChange(newTimesheet.id);
    }
  };

  switch (selectedTimesheet.timesheetType) {
    case WorkloadType.Monthly:
      return (
        <MonthNav
          minMonth={startOfMonth(minDate)}
          maxMonth={startOfMonth(maxDate)}
          currentMonth={startOfMonth(selectedTimesheet.startDate)}
          onMonthChanged={handleNavChange}
        />
      );

    default:
      return (
        <WeekNav
          minWeek={startOfISOWeek(minDate)}
          maxWeek={startOfISOWeek(maxDate)}
          currentWeek={startOfISOWeek(selectedTimesheet.startDate)}
          onWeekChanged={handleNavChange}
        />
      );
  }
};

export default SelectionPanel;

function isViewType(str: string | null): str is ProjectViewType {
  return str === 'BY_DAYS' || str === 'BY_PROJECTS';
}
