import React, { useCallback } from 'react';
import {
  SelectSingle,
  Props as SelectSingleProps,
} from '../../../components/Selects/SelectSingle';
import { Skill } from './types';
import { OptionBase } from '../../../../../components/Select/types';
import { RenderOptionParams } from '../../../components/Selects/SelectSingleBase';
import { SkillOptionLabel } from './SkillOptionLabel';
import { OptionLabel } from '../../../components/Selects/OptionLabel';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = Omit<
  SelectSingleProps,
  'value' | 'options' | 'onChange' | 'renderOption'
> & {
  value: string | null;
  allSkills: Skill[];
  onChange: (value: string | null) => void;
};

export const SkillSingleSelect: React.FC<Props> = ({
  allSkills,
  placeholder,
  ...props
}) => {
  const t = useNamespacedTranslation('skills.addEmployeeSkill');

  const renderOption = useCallback(
    ({ option, isInsideSelect }: RenderOptionParams<OptionBase>) => {
      if (isInsideSelect) {
        return <OptionLabel option={option} isInsideSelect={isInsideSelect} />;
      }
      return <SkillOptionLabel label={option.label} />;
    },
    [],
  );

  const options = allSkills.map(mapOption);
  return (
    <Container>
      <StyledSelectSingle
        {...props}
        options={options}
        renderOption={renderOption}
        heightMode="normal"
        borderless={false}
        placeholder={placeholder ?? t('skillsPlaceholder')}
      />
    </Container>
  );
};

const mapOption = (skill: Skill): OptionBase => ({
  value: skill.id,
  label: skill.name,
});

const StyledSelectSingle = styled(SelectSingle)({
  width: '100%',
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  minHeight: '2.75rem',
});
