import { Certificate, CertificateFilter } from './types';

export const applyCertificateFilter = (
  filter: CertificateFilter,
  certificates: Certificate[],
): Certificate[] =>
  certificates.filter((x) => isMatchCertificateFilter(filter, x));

const isMatchCertificateFilter = (
  filter: CertificateFilter,
  certificate: Certificate,
): boolean => {
  if (filter.issuedBy !== null && certificate.issuedBy !== filter.issuedBy) {
    return false;
  }

  if (filter.status !== null && certificate.status !== filter.status) {
    return false;
  }

  if (filter.issueDate !== null && certificate.issueDate !== null) {
    if (certificate.issueDate.getFullYear() !== parseInt(filter.issueDate)) {
      return false;
    }
  }

  if (filter.expirationDate !== null && certificate.expirationDate !== null) {
    if (
      certificate.expirationDate.getFullYear() !==
      parseInt(filter.expirationDate)
    ) {
      return false;
    }
  }

  return true;
};
