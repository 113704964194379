import { ApolloError, Operation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { v4 as uuidv4 } from 'uuid';

export const CONTEXT_REQUEST_ID = 'requestId';
export const CONTEXT_TIMESTAMP = 'timestamp';
export const CONTEXT_OPERATION_NAME = 'operationName';

export type RequestInfo = {
  requestId: string;
  timestamp: string;
  operationName: string;
};

export const createRequestInfoInApolloOperation = (operation: Operation) => {
  const requestId = uuid();
  operation.setContext({ [CONTEXT_REQUEST_ID]: requestId });
  operation.setContext({ [CONTEXT_OPERATION_NAME]: operation.operationName });
  operation.setContext({ [CONTEXT_TIMESTAMP]: new Date().toUTCString() });
  operation.setContext((prevContext: Record<string, any>) => ({
    ...prevContext,
    headers: {
      'flair-request-id': requestId,
      ...prevContext.headers,
    },
  }));
};

export const addRequestInfoToErrorResponse = ({
  graphQLErrors,
  operation,
  networkError,
}: ErrorResponse) => {
  const requestInfo: RequestInfo = {
    requestId: operation.getContext()[CONTEXT_REQUEST_ID],
    timestamp: operation.getContext()[CONTEXT_TIMESTAMP],
    operationName: operation.getContext()[CONTEXT_OPERATION_NAME],
  };
  // Here we can receive networkError and/or graphQLErrors
  // And we need to add requestInfo to these objects
  // GraphQL Error object has a documented way to extend it
  // But NetworkError doesn't have, so I will just add new property to the object
  if (networkError) {
    try {
      // I know this is bullshit, but i didn't find a way how to get access to ApolloOperation in useMutationErrorHandler
      const modifedNetworkError: any = networkError;
      modifedNetworkError.extra = requestInfo;
    } catch (e) {
      console.error('failed to add extra to networkError object');
    }
  }
  if (graphQLErrors) {
    const requestId = operation.getContext()[CONTEXT_REQUEST_ID];
    const timestamp = operation.getContext()[CONTEXT_TIMESTAMP];
    const operationName = operation.getContext()[CONTEXT_OPERATION_NAME];
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions) {
        extensions[CONTEXT_REQUEST_ID] = requestId;
        extensions[CONTEXT_TIMESTAMP] = timestamp;
        extensions[CONTEXT_OPERATION_NAME] = operationName;
      }
    });
  }
};

export const getRequestInfoFromError = (
  error: ApolloError,
): RequestInfo | null => {
  if (error.networkError) {
    const extra: unknown = (error.networkError as any).extra;
    if (typeof extra === 'object') {
      return extra as RequestInfo;
    }
  }
  if (error.graphQLErrors?.length > 0) {
    const extensions = error.graphQLErrors[0].extensions;
    if (extensions) {
      return {
        requestId: extensions[CONTEXT_REQUEST_ID] ?? '',
        timestamp: extensions[CONTEXT_TIMESTAMP] ?? '',
        operationName: extensions[CONTEXT_OPERATION_NAME] ?? '',
      };
    }
  }
  return null;
};

const uuid = (): string => uuidv4();
