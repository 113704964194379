import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Nullable } from '../../../../../../utils/nullable';
import { CalendarDay } from '../../../../components/AbsenceCalendar/types';
import DaysOfMonth from './DaysOfMonth';
import ColleagueRow from './ColleagueRow';
import { AbsenceColleague } from '../Content';
import moment, { Moment, unitOfTime } from 'moment';
import { TeamCalendarFiltersType } from './TeamCalendarFilters/types';
import { split } from '../../../../../../utils/string';

type ContentParams = {
  days: Moment[];
  colleagues: AbsenceColleague[];
  filterData: TeamCalendarFiltersType;
};

const useColleaguesFiltration = (
  filterData: TeamCalendarFiltersType,
  colleagues: AbsenceColleague[],
) => {
  const [filteredColleagues, setFilteredColleagues] =
    useState<AbsenceColleague[]>(colleagues);

  const colleaguesIdsArray = useMemo(
    () =>
      split(filterData.colleaguesIds).filter((filterColleagueId) =>
        colleagues.some((c) => c.id === filterColleagueId),
      ),
    [filterData.colleaguesIds, colleagues],
  );

  useEffect(() => {
    const filterAbsenceCategory = (colleagues: AbsenceColleague[]) => {
      const categoryOrGroupIds = split(filterData.absenceCategories);
      if (!categoryOrGroupIds.length) {
        return colleagues;
      }
      return colleagues.map((c) => {
        return {
          ...c,
          days: c.days.map((d) => {
            return {
              ...d,
              absences: d.absences.filter((a) => {
                return (
                  categoryOrGroupIds.includes(a.flair__Category_Id__c) ||
                  categoryOrGroupIds.includes(a.category.group?.Id || '')
                );
              }),
            };
          }),
        };
      });
    };

    const filterByColleagueId = (colleague: AbsenceColleague) => {
      return !!colleaguesIdsArray.length
        ? colleaguesIdsArray.includes(colleague.id)
        : true;
    };

    const filterByAbsentStatus = (colleague: AbsenceColleague) => {
      const mapAbsentStatusFilter = (status: string): unitOfTime.StartOf => {
        switch (status) {
          case 'absent_today':
            return 'day';
          case 'absent_this_month':
            return 'month';
        }
        return null;
      };

      let absentStatus = filterData.absentStatus;
      if (!!mapAbsentStatusFilter(absentStatus)) {
        return colleague.days.some(
          (d) =>
            d.absences.length > 0 &&
            d.day.isSame(moment(), mapAbsentStatusFilter(absentStatus)),
        );
      }
      return true;
    };

    const filteredColleagues = filterAbsenceCategory(
      colleagues.filter(filterByColleagueId).filter(filterByAbsentStatus),
    );

    setFilteredColleagues(filteredColleagues);
  }, [
    colleagues,
    setFilteredColleagues,
    filterData.absentStatus,
    colleaguesIdsArray,
    filterData.absenceCategories,
  ]);

  return filteredColleagues;
};
const Content: React.FC<ContentParams> = ({ days, colleagues, filterData }) => {
  const [hoveredDay, setHoveredDay] = useState<Nullable<CalendarDay>>();
  const [hoveredColleague, setHoveredColleague] =
    useState<Nullable<AbsenceColleague>>();
  const filteredColleagues = useColleaguesFiltration(filterData, colleagues);

  const handleHover = useCallback(
    (day: Nullable<CalendarDay>, colleague: Nullable<AbsenceColleague>) => {
      setHoveredDay(day);
      setHoveredColleague(colleague);
    },
    [setHoveredDay, setHoveredColleague],
  );

  return (
    <tbody onMouseLeave={() => handleHover(null, null)}>
      <DaysOfMonth
        colleagues={colleagues}
        days={days}
        filterData={filterData}
      />
      {filteredColleagues.map((colleague) => (
        <ColleagueRow
          key={colleague.name}
          colleague={colleague}
          onHover={handleHover}
          hoveredDay={hoveredDay}
          hoveredColleague={hoveredColleague}
        />
      ))}
    </tbody>
  );
};

export default Content;
