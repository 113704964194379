import React from 'react';
import { CheckboxInput } from '../../../../../components/form/CheckboxInput';
import { Datepicker } from '../../../../../components/form/Datepicker';
import { Input } from '../../../../../components/form/Input';
import { MultiSelect } from '../../../../../components/form/MultiSelect';
import { FieldType } from '../../../__generated__/graphql';
import { FieldInfo } from '../types';

type Props = {
  fieldInfo: FieldInfo;
};

export const FieldInput: React.FC<Props> = ({ fieldInfo }) => {
  const inputProps = {
    name: fieldInfo.name,
    label: fieldInfo.label,
    disabled: fieldInfo.disabled,
    required: fieldInfo.required,
    onChange: (value: any) =>
      fieldInfo.onChange && fieldInfo.onChange(value, fieldInfo),
  };

  switch (fieldInfo.type) {
    case FieldType.Boolean: {
      return <CheckboxInput {...inputProps} />;
    }
    case FieldType.Currency:
    case FieldType.Percent:
    case FieldType.Integer:
    case FieldType.Long:
    case FieldType.Double: {
      return <Input {...inputProps} type="number" />;
    }
    case FieldType.Time: {
      return <Input {...inputProps} type="time" />;
    }
    case FieldType.Date: {
      return <Datepicker {...inputProps} type="date" />;
    }
    case FieldType.Datetime: {
      return <Datepicker {...inputProps} type="datetime" />;
    }
    case FieldType.Picklist: {
      return (
        <Input {...inputProps} type="select">
          <option></option>
          {fieldInfo.options.map((entry, i) => (
            <option key={i} value={entry.value}>
              {entry.label}
            </option>
          ))}
        </Input>
      );
    }
    case FieldType.Multipicklist: {
      return (
        <MultiSelect {...inputProps}>
          <option></option>
          {fieldInfo.options.map((entry, i) => (
            <option key={i} value={entry.value}>
              {entry.label}
            </option>
          ))}
        </MultiSelect>
      );
    }
    case FieldType.Email: {
      return <Input {...inputProps} type="email" />;
    }
    case FieldType.Url: {
      return <Input {...inputProps} type="url" />;
    }
    case FieldType.Textarea: {
      return <Input {...inputProps} type="textarea" />;
    }
    case FieldType.Phone: {
      return <Input {...inputProps} type="tel" />;
    }
    default: {
      return <Input {...inputProps} />;
    }
  }
};
