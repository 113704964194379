import React from 'react';
import PageHeader from '../../components/PageHeader';
import EmployeesList from './EmployeesList';
import { Col, Row } from 'react-bootstrap';
import PendingApprovalCard from '../../pages/Home/PendingApprovalCard';
import { useManagerReportsMode } from '../../hooks/useManagerReportsMode';
import { ManagerReportsModeSwitch } from '../components/ManagerReportsModeSwitch';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

const MyTeam: React.FC = () => {
  const [reportsMode, setReportsMode] = useManagerReportsMode('myteam');
  const t = useNamespacedTranslation('navigation.menuItems.people');

  useFlairBreadcrumbHook([{ label: t('title') }]);

  return (
    <>
      <PageHeader
        title={t('title')}
        actions={
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        }
      />
      <Row>
        <Col md={8}>
          <EmployeesList />
        </Col>
        <Col md={4}>
          <PendingApprovalCard />
        </Col>
      </Row>
    </>
  );
};

export default MyTeam;
