import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { OptionBase } from '../../../../components/Select/types';
import { DeleteOptionIcon } from './DeleteOptionIcon';
import {
  Props as SelectSingleBaseProps,
  RenderOptionFunc,
  SelectBase,
} from './SelectSingleBase';

type RenderMode = 'popup' | 'inline' | 'block';

export type Props<TOption extends OptionBase> = Omit<
  SelectSingleBaseProps<TOption>,
  'value' | 'options' | 'onChange'
> & {
  values: string[];
  options: TOption[];
  renderOption: RenderOptionFunc<TOption>;
  onChange: (values: string[]) => void;
  selectBtnTitle?: string;
  renderMode?: RenderMode;
  hideSelectedOptions?: boolean;
};

export function SelectMultipleBase<TOption extends OptionBase>({
  className,
  values,
  options,
  onChange,
  renderOption,
  selectBtnTitle,
  placeholder,
  noOptionsMessage,
  isDisabled,
  renderMode = 'popup',
  hideSelectedOptions = false,
  ...restProps
}: Props<TOption>) {
  const { t } = useTranslation();

  const [popupVisible, setPopupVisible] = useState(false);

  const handleDeleteClick = useCallback(
    (option: TOption) => {
      if (isDisabled) {
        return;
      }
      onChange(values.filter((x) => x !== option.value));
    },
    [onChange, values, isDisabled],
  );

  const handleSelect = useCallback(
    (value: string | null) => {
      if (value) {
        // values suppose to be always [] here
        onChange([...values, value]);
      }
      setPopupVisible(false);
    },
    [onChange, values, setPopupVisible],
  );

  const handleModalShow = useCallback(() => {
    setPopupVisible(true);
  }, [setPopupVisible]);

  const selectedOptions = values
    .map((value) => options.find((o) => o.value === value))
    .filter((x): x is TOption => x !== undefined);

  const availableOptions = options.filter(
    (o) => !values.some((value) => value === o.value),
  );

  const renderSelect = (autoFocus: boolean) => {
    return (
      <SelectBase
        {...restProps}
        key="select"
        value={values[values.length - 1] ?? null}
        options={availableOptions}
        onChange={handleSelect}
        renderOption={renderOption}
        placeholder={placeholder}
        hideSelectedOptions={hideSelectedOptions}
        noOptionsMessage={noOptionsMessage}
        isDisabled={isDisabled}
        autoFocus={autoFocus}
        css={minWidthCss}
      />
    );
  };

  const renderPopupButton = () => {
    return (
      <OverlayTrigger
        rootClose={true}
        trigger="click"
        //placement="auto-end"
        show={popupVisible}
        onToggle={(visible) => setPopupVisible(visible)}
        overlay={
          <Popover id="select-employee-popup">
            <Popover.Body>{renderSelect(true)}</Popover.Body>
          </Popover>
        }>
        <StyledButton
          label={selectBtnTitle ?? t('select.selectMultipleBase.selectButton')}
          size="sm"
          disabled={isDisabled}
          variant="outline-primary"
          onClick={handleModalShow}
        />
      </OverlayTrigger>
    );
  };

  const renderOptions = () => {
    return selectedOptions.map((option) => (
      <div
        key={option.value}
        className="d-flex align-items-center"
        style={renderMode === 'popup' ? { height: 40 } : undefined}>
        {renderOption({ option, isInsideSelect: false })}
        <DeleteOptionIcon
          onClick={() => handleDeleteClick(option)}
          className="ms-2 me-2"
        />
      </div>
    ));
  };

  const renderBlock = () => {
    return (
      <>
        <div className="d-flex w-100 flex-column">{renderSelect(false)}</div>
        {!hideSelectedOptions && (
          <div className="d-flex flex-wrap gap-2 mt-2">{renderOptions()}</div>
        )}
      </>
    );
  };
  const renderInline = () => {
    return (
      <>
        {renderOptions()}
        {renderSelect(false)}
      </>
    );
  };
  const renderPopup = () => {
    return (
      <>
        {renderOptions()}
        {renderPopupButton()}
      </>
    );
  };

  return (
    <div className={`d-flex flex-wrap align-items-center ${className}`}>
      {renderMode === 'block' && renderBlock()}
      {renderMode === 'inline' && renderInline()}
      {renderMode === 'popup' && renderPopup()}
    </div>
  );
}

const minWidthCss = css({
  minWidth: 160,
});

const StyledButton = styled(Button)({
  maxHeight: 28,
});
