import { InventoryFilter } from './types';
import { InventoryListItem } from '../types';
import { parseDate } from '../../../../../../utils/dateUtils';
import { getMonth } from 'date-fns';

export const hasInventoryFilter = (filter: InventoryFilter): boolean => {
  if (filter.category !== null) {
    return true;
  }
  if (filter.location !== null) {
    return true;
  }
  if (filter.status !== null) {
    return true;
  }
  if (filter.month !== null) {
    return true;
  }
  if (filter.year !== null) {
    return true;
  }
  if (filter.searchTerm !== null) {
    return true;
  }

  return false;
};

export const applyInventoryFilter = (
  filter: InventoryFilter,
  inventoryItems: InventoryListItem[],
): InventoryListItem[] =>
  inventoryItems.filter((x) => isMatchInventoryFilter(filter, x));

const isMatchInventoryFilter = (
  filter: InventoryFilter,
  inventoryItem: InventoryListItem,
): boolean => {
  if (
    filter.category !== null &&
    inventoryItem.category.name !== filter.category
  ) {
    return false;
  }
  if (
    filter.location !== null &&
    inventoryItem.location?.id !== filter.location
  ) {
    return false;
  }
  if (filter.month !== null && inventoryItem.createdOn !== null) {
    if (
      String(getMonth(parseDate(inventoryItem.createdOn)) + 1) !== filter.month
    ) {
      return false;
    }
  }
  if (filter.year !== null && inventoryItem.createdOn !== null) {
    if (
      parseDate(inventoryItem.createdOn).getFullYear() !== parseInt(filter.year)
    ) {
      return false;
    }
  }

  if (filter.status !== null && inventoryItem.status !== filter.status) {
    return false;
  }
  return true;
};
