import * as React from 'react';

const FrownSolidIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_1465_42991)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6769 1.51775C11.5274 1.8737 12.2991 2.39418 12.9478 3.04929C13.6029 3.69798 14.1234 4.46971 14.4793 5.32016C14.8353 6.17061 15.0197 7.08301 15.022 8.00494C15.0242 8.92687 14.8443 9.84017 14.4926 10.6924C14.1408 11.5445 13.6241 12.3188 12.9722 12.9707C12.3203 13.6227 11.546 14.1393 10.6938 14.4911C9.84163 14.8428 8.92834 15.0228 8.00641 15.0205C7.08447 15.0182 6.17207 14.8338 5.32163 14.4779C4.47118 14.1219 3.69944 13.6014 3.05075 12.9463C2.39564 12.2976 1.87516 11.5259 1.51921 10.6755C1.16326 9.825 0.978853 8.9126 0.976584 7.99067C0.974315 7.06874 1.15423 6.15544 1.50599 5.30325C1.85775 4.45106 2.37442 3.67677 3.02633 3.02487C3.67824 2.37296 4.45253 1.85629 5.30472 1.50452C6.15691 1.15276 7.0702 0.97285 7.99214 0.975119C8.91407 0.977388 9.82647 1.1618 10.6769 1.51775ZM6.5026 7.25014C6.5026 7.6652 6.16612 8.00168 5.75105 8.00168C5.33599 8.00168 4.99951 7.6652 4.99951 7.25014C4.99951 6.83507 5.33599 6.49859 5.75105 6.49859C6.16612 6.49859 6.5026 6.83507 6.5026 7.25014ZM10.2504 8.00168C10.6655 8.00168 11.0019 7.6652 11.0019 7.25014C11.0019 6.83507 10.6655 6.49859 10.2504 6.49859C9.83533 6.49859 9.49885 6.83507 9.49885 7.25014C9.49885 7.6652 9.83533 8.00168 10.2504 8.00168ZM6.16023 11.6573C6.07192 11.9189 5.78829 12.0593 5.52671 11.971C5.26514 11.8827 5.12468 11.5991 5.21299 11.3375C5.58389 10.2389 6.72096 9.49787 7.99965 9.49787C9.27792 9.49787 10.4162 10.2387 10.7845 11.3387C10.8722 11.6005 10.731 11.8838 10.4692 11.9714C10.2074 12.0591 9.92415 11.9179 9.8365 11.6561C9.62527 11.0252 8.91673 10.4976 7.99965 10.4976C7.08298 10.4976 6.37369 11.025 6.16023 11.6573Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1465_42991">
          <rect width="15.9962" height="15.9962" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default FrownSolidIcon;
