import React from 'react';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = {
  isExpanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;
  children: React.ReactNode;
};

export const TimeSheetRowCell: React.FC<Props> = ({
  isExpanded,
  onExpandedChanged,
  children,
}) => {
  const handleExpandClick = () => {
    onExpandedChanged(!isExpanded);
  };

  return (
    <div className="d-flex align-items-center gap-2">
      <FlairIcon
        onClick={handleExpandClick}
        className="me-1"
        icon={
          isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'
        }></FlairIcon>
      {children}
    </div>
  );
};
