import classNames from 'classnames';
import { parseISO, setISODay } from 'date-fns';
import i18next from 'i18next';
import React, { useCallback, useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  WEEK_DAY_FORMAT_SHORT,
} from '../../../../../utils/dateUtils';
import { BreakingRulesInfo, BreakLegislationRule } from '../shared';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { formattedDuration } from '../../DurationFormat';
import StringToHtml from '../../../../../components/StringToHtml';

type Props = BreakingRulesInfo;

const i18prefix = 'autoBreaks.breakRulesInfo.';

export const BreakingRulesInfoPanelDumb: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const handleClick = useCallback(() => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  }, [setCollapsed]);

  if (isEmpty(props)) {
    return null;
  }

  return (
    <Card className="bg-light">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>{t(i18prefix + 'title')}</div>
        <div onClick={handleClick} role="button">
          {!collapsed ? (
            <FlairIcon icon="chevron-up-outline" />
          ) : (
            <FlairIcon icon="chevron-down-outline" />
          )}
        </div>
      </Card.Header>
      <Collapse in={!collapsed}>
        <Card.Body className="py-0">
          <BreakingRulesContent {...props} />
        </Card.Body>
      </Collapse>
    </Card>
  );
};

const BreakingRulesContent: React.FC<Props> = (props) => {
  const lines = getLines(props);
  return (
    <div>
      {lines.length > 0 && (
        <ul className={classNames('fs-5', 'mb-0', 'ps-4')}>
          {lines.map((line, i) => (
            <li className="mb-3" key={i}>
              <span>
                <StringToHtml text={line} />
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const getLines = ({
  minBreakDuration,
  legislationRules,
  deductBreaks,
}: BreakingRulesInfo): string[] => {
  const lines = [];
  if (minBreakDuration > 0) {
    lines.push(
      i18next.t(i18prefix + 'minimalBreakLength', {
        minutes: minBreakDuration,
      }),
    );
  }
  if (legislationRules.length > 0) {
    lines.push(getLegislationRulesStr(legislationRules));
  }

  if (deductBreaks) {
    lines.push(i18next.t(i18prefix + 'deductBreaks'));
  }
  return lines;
};

const getLegislationRulesStr = (
  legislationRules: BreakLegislationRule[],
): string => {
  const durationFormatter = formattedDuration(i18next.t);
  const parts = [];
  for (let i = 0; i < legislationRules.length; i++) {
    const str =
      i === 0
        ? i18next.t(i18prefix + 'legislationRuleFirstLine2', {
            workingTimeDuration: durationFormatter(
              legislationRules[i].workingTime,
            ),
            breakMinutes: legislationRules[i].breakDuration,
          })
        : i18next.t(i18prefix + 'legislationRuleLine2', {
            workingTimeDuration: durationFormatter(
              legislationRules[i].workingTime,
            ),
            breakMinutes: legislationRules[i].breakDuration,
            nbsp: '&nbsp;',
          });
    parts.push(str);
  }
  const zeroLine = i18next.t(i18prefix + 'legislationRuleZeroLine');
  return zeroLine + ' ' + parts.join(', ') + '.';
};

export const getWeekDaysBreakDurationStr = (
  weekDaysBreakDuration: number[],
): string => {
  const groupedPerBreakTime = new Map<number, number[]>();
  for (let i = 0; i < weekDaysBreakDuration.length; i++) {
    const curDuration = weekDaysBreakDuration[i];
    if (weekDaysBreakDuration[i] > 0) {
      if (groupedPerBreakTime.has(curDuration)) {
        groupedPerBreakTime.get(curDuration)!.push(i);
      } else {
        groupedPerBreakTime.set(curDuration, [i]);
      }
    }
  }
  const groupStrings = new Array<string>();
  groupedPerBreakTime.forEach((dayIndices, breakTime) => {
    const nMinutesOn = i18next.t(i18prefix + 'nMinutesOn', {
      minutes: breakTime,
    });
    const dayNames = dayIndices
      .map((dayIndex) => toWeekDayName(dayIndex + 1))
      .join(', ');
    groupStrings.push(`${nMinutesOn} ${dayNames}`);
  });

  return groupStrings.join(', ');
};

const isEmpty = (src: Props) => {
  return (
    src.minBreakDuration === 0 &&
    !src.deductBreaks &&
    src.legislationRules.length === 0
  );
};

const anyMonday = parseISO('2021-10-11');
// 1 Monday
const toWeekDayName = (isoWeekDay: number): string =>
  formatDate(setISODay(anyMonday, isoWeekDay), WEEK_DAY_FORMAT_SHORT);
