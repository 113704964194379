import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../theme';
import FlairIcon from '../../atomic/atoms/FlairIcon';

type Props = {
  onClick: () => void;
  className?: string;
};

export const DeleteAttachmentIcon: React.FC<Props> = ({
  onClick,
  className,
}) => {
  return (
    <Container className={className}>
      <FlairIcon
        icon="close-outline"
        onClick={onClick}
        size="xs"
        color={Theme.color.white}
        role="button"
      />
    </Container>
  );
};

const Container = styled.div({
  backgroundColor: Theme.color.black,
  color: Theme.color.white,
  border: `1px solid ${Theme.color.white}`,
  width: '1.2rem',
  height: '1.2rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
