import * as React from 'react';

const StickyNoteOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 14.1523C1.96957 14.1523 1.46086 13.9416 1.08579 13.5666C0.710714 13.1915 0.5 12.6828 0.5 12.1523V2.15234C0.5 1.62191 0.710714 1.1132 1.08579 0.73813C1.46086 0.363057 1.96957 0.152344 2.5 0.152344H9.5C10.0304 0.152344 10.5391 0.363057 10.9142 0.73813C11.2893 1.1132 11.5 1.62191 11.5 2.15234V8.23836C11.4999 8.63604 11.342 9.01743 11.0608 9.29867L6.64637 13.7131C6.36513 13.9943 5.9837 14.1523 5.58601 14.1523H2.5ZM1.79289 12.8595C1.98043 13.047 2.23478 13.1523 2.5 13.1523H5.5V9.65234C5.5 9.25452 5.65804 8.87299 5.93934 8.59168C6.22064 8.31038 6.60217 8.15234 7 8.15234H10.5V2.15234C10.5 1.88713 10.3946 1.63277 10.2071 1.44524C10.0196 1.2577 9.76522 1.15234 9.5 1.15234H2.5C2.23478 1.15234 1.98043 1.2577 1.79289 1.44524C1.60536 1.63277 1.5 1.88713 1.5 2.15234V12.1523C1.5 12.4176 1.60536 12.6719 1.79289 12.8595ZM9.79289 9.15234L6.5 12.4452V9.65234C6.5 9.51974 6.55268 9.39256 6.64645 9.29879C6.74022 9.20502 6.86739 9.15234 7 9.15234H9.79289Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default StickyNoteOutlineIcon;
