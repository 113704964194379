import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import './AttentionBadge.css';

type Props = Pick<BadgeProps, 'pill'> & {
  className?: string;
  bg?: BadgeProps['bg'];
  children?: React.ReactNode;
};

const AttentionBadge = React.forwardRef<HTMLSpanElement, Props>(
  ({ className, children, ...rest }, ref) => (
    <Badge
      ref={ref}
      className={[`d-flex align-items-center`, className].join(' ')}
      {...rest}>
      {children}
    </Badge>
  ),
);

export default AttentionBadge;
