import React from 'react';
import { AllowanceInfo, PeriodType } from '../types';
import { Trans, useTranslation } from 'react-i18next';
import AbsenceHint from './AbsenceHint';
import styled from '@emotion/styled';

type Props = {
  periodType: PeriodType;
  allowances: AllowanceInfo[];
};

const i18nKey = 'absences.cards.accruedHint';

export const AccruedHint: React.FC<Props> = (props) => {
  return <AbsenceHint text={<AllowancesList {...props} />} />;
};

const AllowancesList: React.FC<Props> = ({ allowances, periodType }) => {
  const { t } = useTranslation();

  return (
    <StyledList className="ps-3">
      {allowances.map((item) => (
        <li key={item.absenceAllowanceId}>
          <Trans
            t={t}
            i18nKey={
              periodType === 'next'
                ? `${i18nKey}.future_info`
                : `${i18nKey}.info`
            }
            values={{
              title: item.title,
              count: item.amount,
            }}
            components={[<strong />]}
          />
        </li>
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul`
  list-style-type: none;
`;
