import styled from '@emotion/styled';

export const AbsenceCalendarContainer = styled.div`
  table {
    margin-bottom: 0;
  }

  table th {
    white-space: nowrap;
    font-size: 0.7125rem;
    padding: 0.9rem 1rem;
  }

  table td {
    width: 3%;
    text-align: center;
    font-size: 0.7125rem;
    padding: 0 0.5em;
  }

  table td:last-child {
    border-right: 0;
  }

  table tr:last-child td {
    border-bottom: 0;
  }
`;
