import * as React from 'react';
import { CelebrationRecipient, Employee } from '../../../../Celebrations/types';
import Avatar, {
  AvatarProps,
} from '../../../../../../../atomic/molecules/avatar/Avatar';
import { extractFullNameAndInitials } from '../../../../../components/Employee/EmployeeWithAvatar';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import { TeamWithPopupConnected } from '../../../../../components/Team/TeamWithPopupConnected';
import { useIsScreenSizeLargerThanOrEqual } from '../../../../../../../hooks/useIsScreenSizeLargerThan';
import Hint from '../../../../../../../components/hint';
import { EmployeesAvatarGroup } from '../../../../../../../atomic/molecules/EmployeesAvatarGroup/EmployeeAvatarGroup';

type Props = {
  sender: Employee;
  recipients: CelebrationRecipient[];
};

const SenderAndRecipientsSection: React.FC<Props> = ({
  recipients,
  sender,
}) => {
  const { initials } = extractFullNameAndInitials(sender);
  const isLargeScreen = useIsScreenSizeLargerThanOrEqual('lg');
  const numberOfAvatarsToShow = isLargeScreen ? 4 : 0;

  //TODO:: add tooltip for one avatar
  const recipientsElement =
    recipients.length === 1 ? (
      <SingleRecipient recipient={recipients[0]} />
    ) : (
      <EmployeesAvatarGroup
        recipients={recipients}
        maxAvatarsToShow={numberOfAvatarsToShow}
      />
    );

  return (
    <div className="d-flex align-items-center gap-2">
      <AvatarWithHint
        initials={initials}
        size="md"
        className="align-middle"
        avatarUrl={sender.avatarUrl ?? undefined}
        id={sender.id}
        hintText={sender.name}
      />

      <FlairIcon icon="arrow-forward-outline" size="xl" />

      {recipientsElement}
    </div>
  );
};

const SingleRecipient: React.FC<{ recipient: CelebrationRecipient }> = ({
  recipient,
}) => {
  if (recipient.type === 'employee') {
    return (
      <AvatarWithHint
        initials={recipient.employee.initials}
        size="md"
        className="align-middle"
        avatarUrl={recipient.employee.avatarUrl ?? undefined}
        id={recipient.id}
        hintText={recipient.employee.name}
      />
    );
  } else if (recipient.type === 'team') {
    return (
      <div className="d-flex align-items-center">
        <TeamWithPopupConnected
          teamAvatarSize="md"
          isTeamNameVisible={false}
          name={recipient.team.name}
          id={recipient.team.id}
        />
      </div>
    );
  }
  return null;
};

type AvatarWithHintProps = AvatarProps & {
  id: string;
  hintText: string;
};

const AvatarWithHint: React.FC<AvatarWithHintProps> = ({
  id,
  hintText,
  initials,
  avatarUrl,
}) => {
  return (
    <Hint id={`${id}-tooltip`} text={hintText}>
      <Avatar
        initials={initials}
        size="md"
        className="align-middle"
        avatarUrl={avatarUrl}
      />
    </Hint>
  );
};

export default SenderAndRecipientsSection;
