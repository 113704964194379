import React from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { WorkflowStep } from '../types';
import { formatDateShort } from '../../../../../utils/dateUtils';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { WorkflowStepActivatingTrigger } from '../../../__generated__/graphql';

type Props = {
  name: string;
  rowId: string;
  onItemClick: (item: { id: string }) => void;
  isMandatory: boolean;
  workflowStep: Pick<
    WorkflowStep,
    'activatingTrigger' | 'activationDate' | 'isActive'
  >;
};

export const WorkflowItemNameCell: React.FC<Props> = ({
  name,
  rowId,
  onItemClick,
  isMandatory,
  workflowStep,
}) => {
  const t = useNamespacedTranslation('workflows.workflowItems.workflowStep');
  const getTooltip = () => {
    if (
      workflowStep.activatingTrigger ===
      WorkflowStepActivatingTrigger.AfterFinishingMandatoryTasks
    ) {
      return t('inactive.tooltip.afterFinishingMandatoryTasks');
    } else if (
      workflowStep.activatingTrigger ===
        WorkflowStepActivatingTrigger.SpecificDate &&
      workflowStep.activationDate
    ) {
      return t('inactive.tooltip.onSpecificData', {
        date: formatDateShort(workflowStep.activationDate),
      });
    } else {
      return '';
    }
  };

  if (!workflowStep.isActive) {
    return (
      <div className="d-flex">
        <div>
          <FlairIcon
            className="me-2"
            icon={'lock-closed-outline'}
            tooltip={getTooltip()}></FlairIcon>
        </div>
        <NameWithMandatory isMandatory={isMandatory} name={name} />
      </div>
    );
  }

  return (
    <a
      href="stub"
      onClick={(e) => {
        e.preventDefault();
        onItemClick({ id: rowId });
      }}>
      <NameWithMandatory isMandatory={isMandatory} name={name} />
    </a>
  );
};

const NameWithMandatory = ({
  name,
  isMandatory,
}: {
  name: string;
  isMandatory: boolean;
}) => {
  return (
    <div className="d-flex">
      <div>{name}</div>
      {isMandatory && <span className="text-danger ms-2">*</span>}
    </div>
  );
};
