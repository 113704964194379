import { addDays, getISOWeek } from 'date-fns';
import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  cloneDate,
  isSameOrAfter,
  isSameOrBefore,
} from '../../utils/dateUtils';
import Button from '../button';

type Props = {
  minWeek?: Date;
  maxWeek?: Date;
  currentWeek: Date;
  onWeekChanged: (newValue: Date) => void;
};
const WeekNav: React.FC<Props> = ({
  currentWeek,
  minWeek,
  maxWeek,
  onWeekChanged,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ButtonGroup size="sm" className="bg-gray rounded p-1">
        <Button
          disabled={
            minWeek && isSameOrBefore(currentWeek, minWeek, { unit: 'day' })
          }
          label="←"
          variant="gray"
          onClick={() =>
            onWeekChanged && onWeekChanged(addDays(cloneDate(currentWeek), -7))
          }
        />
        <Button
          variant="white"
          size="sm"
          className="rounded"
          label={t('calendarWeek', { week: getISOWeek(currentWeek) })}
        />
        <Button
          disabled={
            maxWeek && isSameOrAfter(currentWeek, maxWeek, { unit: 'day' })
          }
          label="→"
          variant="gray"
          onClick={() =>
            onWeekChanged && onWeekChanged(addDays(cloneDate(currentWeek), 7))
          }></Button>
      </ButtonGroup>
    </div>
  );
};

export default WeekNav;
