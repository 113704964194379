import * as React from 'react';

const MicOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4.27614 6 4.5 6.22386 4.5 6.5V7.5C4.5 9.42386 6.07614 11 8 11C9.92386 11 11.5 9.42386 11.5 7.5V6.5C11.5 6.22386 11.7239 6 12 6C12.2761 6 12.5 6.22386 12.5 6.5V7.5C12.5 9.8072 10.7429 11.7217 8.5 11.9722V13.5H10C10.2761 13.5 10.5 13.7239 10.5 14C10.5 14.2761 10.2761 14.5 10 14.5H6C5.72386 14.5 5.5 14.2761 5.5 14C5.5 13.7239 5.72386 13.5 6 13.5H7.5V11.9722C5.25712 11.7217 3.5 9.8072 3.5 7.5V6.5C3.5 6.22386 3.72386 6 4 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00128 1.50003C9.40182 1.50068 10.5 2.5993 10.5 4.00003V7.46878C10.5 8.86486 9.37495 10 8.00003 10C6.62114 10 5.50003 8.83584 5.50003 7.46878V4.00121C5.49855 3.67257 5.56214 3.34687 5.68715 3.04292C5.81232 2.73855 5.99659 2.46201 6.2293 2.2293C6.46201 1.99659 6.73855 1.81232 7.04292 1.68715C7.34689 1.56213 7.67261 1.49854 8.00128 1.50003ZM7.99751 2.50002C7.80058 2.49903 7.6054 2.53708 7.42327 2.61199C7.24114 2.68689 7.07566 2.79716 6.93641 2.93641C6.79716 3.07566 6.68689 3.24114 6.61199 3.42327C6.53708 3.6054 6.49903 3.80058 6.50002 3.99751L6.50003 4.00003V7.46878C6.50003 8.30171 7.19141 9.00003 8.00003 9.00003C8.8126 9.00003 9.50003 8.32269 9.50003 7.46878V4.00003C9.50003 3.15117 8.84888 2.50003 8.00003 2.50003L7.99751 2.50002Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default MicOutlineIcon;
