import i18next from 'i18next';
import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { PageLoading } from '../../components/PageLoading';

type SlackConnectResult =
  | 'connecting'
  | 'success'
  | 'error'
  | 'slack-not-enabled-for-organization';

export type Props = {
  result: SlackConnectResult;
  errorMessage?: string;
};

const i18prefix = 'components.slackConnectEmployee';

export const SlackConnectEmployeeContent: React.FC<Props> = (props) => {
  const t = useNamespacedTranslation(i18prefix);

  return (
    <PageLoading
      header={t('pageHeader')}
      text={getText(props)}
      isLoading={props.result === 'connecting'}
    />
  );
};

function getText(props: Props): string {
  switch (props.result) {
    case 'connecting':
    case 'success':
    case 'slack-not-enabled-for-organization':
      return i18next.t(`${i18prefix}.${props.result}`);
    case 'error':
      return i18next.t(`${i18prefix}.${props.result}`, {
        message: props.errorMessage,
      });
  }
}
