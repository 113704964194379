import * as React from 'react';

const HappyFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4375 7.5C7.91833 7.5 7.41081 7.34605 6.97913 7.05761C6.54745 6.76917 6.211 6.3592 6.01232 5.87955C5.81364 5.39989 5.76165 4.87209 5.86294 4.36289C5.96423 3.85369 6.21423 3.38596 6.58135 3.01885C6.94846 2.65173 7.41619 2.40173 7.92539 2.30044C8.43459 2.19915 8.96239 2.25114 9.44205 2.44982C9.9217 2.6485 10.3317 2.98495 10.6201 3.41663C10.9085 3.84831 11.0625 4.35583 11.0625 4.875C11.0618 5.57097 10.785 6.23821 10.2928 6.73033C9.80071 7.22246 9.13347 7.49926 8.4375 7.5Z"
        fill="currentColor"
      />
      <path
        d="M15.5625 7.5C15.0433 7.5 14.5358 7.34605 14.1041 7.05761C13.6725 6.76917 13.336 6.3592 13.1373 5.87955C12.9386 5.39989 12.8867 4.87209 12.9879 4.36289C13.0892 3.85369 13.3392 3.38596 13.7063 3.01885C14.0735 2.65173 14.5412 2.40173 15.0504 2.30044C15.5596 2.19915 16.0874 2.25114 16.567 2.44982C17.0467 2.6485 17.4567 2.98495 17.7451 3.41663C18.0335 3.84831 18.1875 4.35583 18.1875 4.875C18.1868 5.57097 17.91 6.23821 17.4178 6.73033C16.9257 7.22246 16.2585 7.49926 15.5625 7.5Z"
        fill="currentColor"
      />
      <path
        d="M8.4375 14.625C7.91833 14.625 7.41081 14.471 6.97913 14.1826C6.54745 13.8942 6.211 13.4842 6.01232 13.0045C5.81364 12.5249 5.76165 11.9971 5.86294 11.4879C5.96423 10.9787 6.21423 10.511 6.58135 10.1438C6.94846 9.77673 7.41619 9.52673 7.92539 9.42544C8.43459 9.32415 8.96239 9.37614 9.44205 9.57482C9.9217 9.7735 10.3317 10.11 10.6201 10.5416C10.9085 10.9733 11.0625 11.4808 11.0625 12C11.0618 12.696 10.785 13.3632 10.2928 13.8553C9.80071 14.3475 9.13347 14.6243 8.4375 14.625Z"
        fill="currentColor"
      />
      <path
        d="M15.5625 14.625C15.0433 14.625 14.5358 14.471 14.1041 14.1826C13.6725 13.8942 13.336 13.4842 13.1373 13.0045C12.9386 12.5249 12.8867 11.9971 12.9879 11.4879C13.0892 10.9787 13.3392 10.511 13.7063 10.1438C14.0735 9.77673 14.5412 9.52673 15.0504 9.42544C15.5596 9.32415 16.0874 9.37614 16.567 9.57482C17.0467 9.7735 17.4567 10.11 17.7451 10.5416C18.0335 10.9733 18.1875 11.4808 18.1875 12C18.1868 12.696 17.91 13.3632 17.4178 13.8553C16.9257 14.3475 16.2585 14.6243 15.5625 14.625Z"
        fill="currentColor"
      />
      <path
        d="M8.4375 21.75C7.91833 21.75 7.41081 21.596 6.97913 21.3076C6.54745 21.0192 6.211 20.6092 6.01232 20.1295C5.81364 19.6499 5.76165 19.1221 5.86294 18.6129C5.96423 18.1037 6.21423 17.636 6.58135 17.2688C6.94846 16.9017 7.41619 16.6517 7.92539 16.5504C8.43459 16.4492 8.96239 16.5011 9.44205 16.6998C9.9217 16.8985 10.3317 17.235 10.6201 17.6666C10.9085 18.0983 11.0625 18.6058 11.0625 19.125C11.0618 19.821 10.785 20.4882 10.2928 20.9803C9.80071 21.4725 9.13347 21.7493 8.4375 21.75Z"
        fill="currentColor"
      />
      <path
        d="M15.5625 21.75C15.0433 21.75 14.5358 21.596 14.1041 21.3076C13.6725 21.0192 13.336 20.6092 13.1373 20.1295C12.9386 19.6499 12.8867 19.1221 12.9879 18.6129C13.0892 18.1037 13.3392 17.636 13.7063 17.2688C14.0735 16.9017 14.5412 16.6517 15.0504 16.5504C15.5596 16.4492 16.0874 16.5011 16.567 16.6998C17.0467 16.8985 17.4567 17.235 17.7451 17.6666C18.0335 18.0983 18.1875 18.6058 18.1875 19.125C18.1868 19.821 17.91 20.4882 17.4178 20.9803C16.9257 21.4725 16.2585 21.7493 15.5625 21.75Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default HappyFilledIcon;
