import React from 'react';
import HolidayIcon from '../../../../components/HolidayIcon';
import HolidayPopover from '../../../../components/HolidayPopover';
import AbsencePopover from '../../../../components/AbsencePopover';
import classNames from 'classnames';
import Badge from '../../Badge';
import AbsenceIcon from '../../../../pages/Absences/components/AbsenceIcon';
import {
  AbsencePopoverFragment,
  HolidayPopoverFragment,
} from '../../../../__generated__/graphql';
import { CalendarPeriodProps } from '../types';
import CalendarPeriodPopover from '../../../CalendarPeriodPopover';

type Props = {
  absences: ReadonlyArray<AbsencePopoverFragment>;
  holidays: ReadonlyArray<HolidayPopoverFragment>;
  calendarPeriods: ReadonlyArray<CalendarPeriodProps>;
  mode?: 'icons' | 'iconsWithLabels';
};

const HolidaysAndAbsences: React.FC<Props> = ({
  holidays,
  absences,
  calendarPeriods,
  mode = 'iconsWithLabels',
}) => (
  <div
    className={classNames(
      'd-flex',
      'flex-wrap',
      'gap-2',
      'align-items-center',
    )}>
    {holidays.map((holiday) => (
      <Badge
        key={holiday.Id}
        icon={<HolidayIcon size="xs" />}
        label={mode === 'iconsWithLabels' ? holiday.Name : undefined}
        popover={<HolidayPopover holiday={holiday} />}
      />
    ))}
    {calendarPeriods.map((calendarPeriod) => (
      <Badge
        key={calendarPeriod.Id}
        icon={<HolidayIcon size="xs" />}
        label={mode === 'iconsWithLabels' ? calendarPeriod.Name : undefined}
        popover={<CalendarPeriodPopover calendarPeriod={calendarPeriod} />}
      />
    ))}
    {absences.map((absence) => (
      <Badge
        key={absence.Id}
        icon={
          <AbsenceIcon icon={absence.flair__Category_Icon__c} variant="plain" />
        }
        label={
          mode === 'iconsWithLabels'
            ? absence.flair__Category_Name__c
            : undefined
        }
        popover={<AbsencePopover absence={absence} />}
      />
    ))}
  </div>
);

export default HolidaysAndAbsences;
