import { addMinutes, differenceInMinutes, isEqual } from 'date-fns';
import i18next from 'i18next';
import { formatTime24h, parseDate } from '../../../../utils/dateUtils';
import {
  AscDescEnum,
  DateTimeWithOrigin,
  DurationWithOrigin,
  TimeEntryChangeRequestFieldsFragment,
  TimeSheetDaySubRowTimeSheetDayWithTimeBalanceFragment,
  TimeSheetDaySubRowTrackedTimeDayFragment,
  TimeSheetDaySubRowsFragment,
  TimeSheetSortFieldEnum,
  TimeSheetSortInput,
  TimeSheetStatus,
  TimeSheetTableFragment,
  TimeSheetsTable_TimeBalanceWithdrawalFragment,
  WorkloadType,
} from '../../__generated__/graphql';
import {
  ChangeRequestValue,
  ChangeRequestsValue,
  DateTimeValueWithChangeRequest,
  MinutesWithChangeRequest,
  TableDayAnySubRow,
  TableDayBadges,
  TableDaySubRow,
  TableDaySubRowLoading,
  TableDaySubRowLoadingError,
  TableDaySubRowWithdraw,
  TableRow,
  TableRowState,
  TimeBalanceWithdrawRowInfo,
  TimeSheet,
  TimeSheetEmployee,
  TimeSheetFields,
  TimeSheetPeriodInfo,
  TimesheetWarning,
  ViewBy,
  isTableRowTimeSheet,
  timeSheetTrackFieldsUndefined,
} from './types';
import { isSelectDisabled } from './useTimeSheetsTableSelection';
import { formattedDuration } from '../DurationFormat';

export const mapToEmployeeTableRows = (inputItems: TimeSheet[]): TableRow[] => {
  // we skip the first single header-employee row
  return mapToTableRows(inputItems, 'employee').filter(
    (x) => x.rowType !== 'header-employee',
  );
};

export const mapToTableRows = (
  inputItems: TimeSheet[],
  viewBy: ViewBy,
): TableRow[] => {
  const items =
    viewBy === 'week'
      ? inputItems.filter((x) => x.type === WorkloadType.Weekly)
      : inputItems;
  const getKey = createGetKey(viewBy);
  const groupedItems: Map<string, TimeSheet[]> = items.reduce((acc, item) => {
    const key = getKey(item);
    if (!acc.has(key)) {
      acc.set(key, []);
    }
    acc.get(key)!.push(item);
    return acc;
  }, new Map<string, TimeSheet[]>());
  return Array.from(groupedItems.entries()).reduce((acc, [headerId, items]) => {
    return [
      ...acc,
      createHeader(viewBy, items[0]),
      ...items.map((x) => createRow(headerId, viewBy, x)),
    ];
  }, new Array<TableRow>());
};

export const mapToDaySubRows = (
  timeSheet: TimeSheetDaySubRowsFragment,
): TableDayAnySubRow[] => {
  const timeSheetDaysMap = timeSheet.timeSheetDays.reduce(
    (map, timeSheetDay) => map.set(timeSheetDay.flair__Day__c, timeSheetDay),
    new Map<string, TimeSheetDaySubRowTimeSheetDayWithTimeBalanceFragment>(),
  );
  const allChangeRequests = timeSheet.timeEntryChangeRequests;

  const breakIdToChangeRequestMap =
    createBreakIdToChangeRequestMap(allChangeRequests);

  const tableDaySubRows = timeSheet.trackedTime.days.map((timeSheetDay) =>
    mapDaySubRow(
      timeSheet.Id,
      timeSheet.flair__Employee__c,
      timeSheetDay,
      timeSheetDaysMap.get(timeSheetDay.day),
      breakIdToChangeRequestMap,
    ),
  );
  return addWithdrawalSubRows(tableDaySubRows, timeSheetDaysMap);
};

export const addWithdrawalSubRows = (
  dayRows: TableDaySubRow[],
  timeSheetDaysMap: Map<
    string,
    Pick<
      TimeSheetDaySubRowTimeSheetDayWithTimeBalanceFragment,
      'timeBalanceWithdrawals'
    >
  >,
): TableDayAnySubRow[] => {
  return dayRows.reduce((acc, cur) => {
    acc.push(cur);
    // try to add withdraw
    const timeSheetDay = timeSheetDaysMap.get(cur.day);
    const timeBalanceWithdrawals = timeSheetDay?.timeBalanceWithdrawals ?? [];
    if (timeBalanceWithdrawals.length > 0) {
      const withdrawalsRows: TableDaySubRowWithdraw[] =
        timeBalanceWithdrawals.map((timeBalanceWithdrawal) => ({
          ...timeSheetTrackFieldsUndefined,
          id: timeBalanceWithdrawal.Id,
          rowType: 'subrow-day-withdraw',
          day: cur.day,
          withdraw: mapWithdrawal(timeBalanceWithdrawal),
          commentsCount: 0,
        }));
      const dayWithdrawalSum = withdrawalsRows.reduce(
        (acc, cur) => acc + cur.withdraw.amount,
        0,
      );
      // we should increase current day timebalance raw on the amout of withdrawal overwise it looks weird
      cur.timeBalanceAccumulatedMinutes += dayWithdrawalSum;
      return [...acc, ...withdrawalsRows];
    }
    return acc;
  }, new Array<TableDayAnySubRow>());
};

const mapWithdrawal = (
  src: TimeSheetsTable_TimeBalanceWithdrawalFragment,
): TimeBalanceWithdrawRowInfo => ({
  amount: src.flair__Amount__c,
  withdrawalType: src.flair__Withdrawal_Type__c,
  absenceCategoryName: mapAbsenceCategoryName(src),
});

const mapAbsenceCategoryName = (
  src: TimeSheetsTable_TimeBalanceWithdrawalFragment,
): string | undefined => {
  if (!src.absenceAllowances || !src.absenceAllowances.length) {
    return undefined;
  }
  return src.absenceAllowances[0].employeeAbsenceCategory.category.Name;
};

export const updateTableRows = (
  rows: TableRow[],
  approvingIds: string[],
  selectedRowIds: string[],
  expandedRowIds: string[],
): TableRow[] => {
  const approvingIdsSet = new Set(approvingIds);
  const selectingIdsSet = new Set(selectedRowIds);
  const expandedIdsSet = new Set(expandedRowIds);

  return rows.map((row) => ({
    ...row,
    ...calculateRowState(row, approvingIdsSet, selectingIdsSet, expandedIdsSet),
  }));
};

export const createDefaultSortInput = (
  viewBy: ViewBy,
): TimeSheetSortInput[] => {
  if (viewBy === 'employee') {
    return [sortInputs.employeeNameAsc, sortInputs.startDateDesc];
  }
  return [sortInputs.startDateDesc, sortInputs.employeeNameAsc];
};

const calculateRowState = (
  row: TableRow,
  approvingIdsSet: Set<string>,
  selectingIdsSet: Set<string>,
  expandedIdsSet: Set<string>,
): TableRowState => {
  const approving = isTableRowTimeSheet(row)
    ? approvingIdsSet.has(row.timeSheetId)
    : undefined;
  return {
    approving,
    selected: selectingIdsSet.has(row.id),
    expanded: expandedIdsSet.has(row.id),
    selectDisabled: isSelectDisabled(row, approvingIdsSet),
  };
};

const createHeader = (viewBy: ViewBy, anyItemInGroup: TimeSheet): TableRow => {
  switch (viewBy) {
    case 'week': {
      if (anyItemInGroup.period.type !== WorkloadType.Weekly) {
        throw new Error(
          "We don't support not weekly timesheets in weekly mode",
        );
      }
      const headerId = anyItemInGroup.period.id;
      return {
        ...timeSheetTrackFieldsUndefined,
        ...defaultTableRowState,
        id: headerId,
        rowType: 'header-week',
        headerWeek: anyItemInGroup.period,
        commentsCount: 0,
      };
    }
    case 'employee': {
      const headerId = anyItemInGroup.employee.id;
      return {
        ...timeSheetTrackFieldsUndefined,
        ...defaultTableRowState,
        headerEmployee: anyItemInGroup.employee,
        rowType: 'header-employee',
        id: headerId,
        commentsCount: 0,
      };
    }
  }
};

const createRow = (
  headerId: string,
  viewBy: ViewBy,
  item: TimeSheet,
): TableRow => {
  switch (viewBy) {
    case 'employee':
      return {
        id: item.id,
        rowType: 'row-period',
        commentsCount: item.commentsCount,
        headerId,
        ...defaultTableRowState,
        ...mapBaseRowProps(item),
      };
    case 'week':
      return {
        id: item.id,
        rowType: 'row-employee',
        commentsCount: item.commentsCount,
        headerId,
        ...defaultTableRowState,
        ...mapBaseRowProps(item),
      };
  }
};

const createGetKey = (viewBy: ViewBy) => {
  switch (viewBy) {
    case 'week':
      return (src: TimeSheet) => src.period.id;
    case 'employee':
      return (src: TimeSheet) => src.employee.id;
  }
};

const mapBaseRowProps = (src: TimeSheet): TimeSheetFields => ({
  timeSheetId: src.id,
  approveStatus: src.approveStatus,
  employee: src.employee,
  period: src.period,
  targetMinutes: src.targetMinutes,
  trackedMinutes: src.trackedMinutes,
  workedMinutes: src.workedMinutes,
  differenceMinutes: src.differenceMinutes,
  workedDifferenceMinutes: src.workedDifferenceMinutes,
  overtimeMinutes: src.compensatoryOvertimeMinutes,
  startTime: undefined,
  endTime: undefined,
  balance: 0,
  actions: undefined,
  breakMinutes: undefined,
  timeBalanceAccumulatedMinutes: undefined,
  warnings: src.warnings?.map((x) => x.message) ?? [],
});

const mapDaySubRow = (
  timeSheetId: string,
  employeeId: string,
  trackedDay: TimeSheetDaySubRowTrackedTimeDayFragment,
  timeSheetDay:
    | TimeSheetDaySubRowTimeSheetDayWithTimeBalanceFragment
    | undefined,
  breakIdToChangeRequestMap: Map<string, string>,
): TableDaySubRow => {
  const trackedDuration = mapDuration(
    trackedDay.trackedDuration,
    trackedDay.dayChanges?.trackedDuration,
  );

  const workedMinutesWithChanges =
    trackedDay.dayChanges &&
    trackedDay.dayChanges.workedMinutes !== null &&
    trackedDay.dayChanges.workedMinutes !== trackedDay.trackedDuration.minutes
      ? trackedDay.dayChanges.workedMinutes
      : null;

  return {
    ...timeSheetTrackFieldsUndefined,
    id: `${timeSheetId}-day-${trackedDay.day}`,
    rowType: 'subrow-day',
    day: trackedDay.day,
    originalDayStartDateTime: parseDate(trackedDay.dayStartDateTime),
    timeSheetId,
    employeeId,
    timeSheetDayId: timeSheetDay?.Id ?? null,
    commentsCount: timeSheetDay?.commentsCount ?? 0,
    timeBalanceAccumulatedMinutes: timeSheetDay?.timeBalanceAccumulated ?? 0,
    badges: mapDayBadges(trackedDay),
    dayTimeEntryIds: trackedDay.timeEntriesIds,
    hasEntriesWithNotes: hasEntriesWithNotes(trackedDay),
    dayChangeRequestIds: trackedDay.dayChanges?.changeRequestIds ?? [],
    startTime: mapDateTime(
      trackedDay.trackedTimeStart,
      trackedDay.dayChanges?.trackedTimeStart,
    ),
    endTime: mapDateTime(
      trackedDay.trackedTimeEnd,
      trackedDay.dayChanges?.trackedTimeEnd,
    ),
    breakMinutes: mapBreakMinutes(
      trackedDay.breaksDuration,
      trackedDay.dayChanges?.breaksDuration,
      breakIdToChangeRequestMap,
    ),
    targetMinutes: trackedDay.targetMinutes,
    trackedMinutes: trackedDuration.value,
    trackedMinutesWithChanges:
      trackedDuration.changeTo !== undefined ? trackedDuration.changeTo : null,
    workedMinutes: trackedDay.workedMinutes,
    workedMinutesWithChanges,
    trackedDifferenceMinutes:
      (trackedDay.trackedDuration.minutes ?? 0) - trackedDay.targetMinutes,
    trackedDifferenceMinutesWithChanges:
      trackedDuration.changeTo !== undefined
        ? trackedDuration.changeTo - trackedDay.targetMinutes
        : null,
    workedDifferenceMinutes:
      trackedDay.workedMinutes - trackedDay.targetMinutes,
    workedDifferenceMinutesWithChanges:
      workedMinutesWithChanges !== null
        ? workedMinutesWithChanges - trackedDay.targetMinutes
        : null,
  };
};

const createBreakIdToChangeRequestMap = (
  changeRequests: ReadonlyArray<TimeEntryChangeRequestFieldsFragment>,
): Map<string, string> => {
  return changeRequests.reduce((acc, changeReq) => {
    changeReq.breaks.forEach((curBreak) => acc.set(curBreak.Id, changeReq.Id));
    return acc;
  }, new Map<string, string>());
};

const mapBreakMinutes = (
  src: DurationWithOrigin,
  valueWithChange: DurationWithOrigin | undefined,
  breakIdToChangeRequestMap: Map<string, string>,
): MinutesWithChangeRequest => {
  const duration = mapDuration(src, valueWithChange);
  const changeRequestIds: string[] = duration.changeRequestIds.map(
    (breakChangeRequestId) => {
      return breakIdToChangeRequestMap.get(breakChangeRequestId)!;
    },
  );
  return {
    ...duration,
    changeRequestIds,
  };
};

const mapDuration = (
  { minutes }: DurationWithOrigin,
  valueWithChange: DurationWithOrigin | undefined,
): MinutesWithChangeRequest => {
  return {
    value: minutes ?? 0,
    ...mapDurationToChangeRequestValues(valueWithChange, minutes ?? 0),
  };
};

const mapDateTime = (
  { value, timeEntryId }: DateTimeWithOrigin,
  valueWithChange: DateTimeWithOrigin | undefined,
): DateTimeValueWithChangeRequest => {
  const changes = mapToChangeRequestDateValue(valueWithChange, value);

  if (!timeEntryId || !value) {
    return {
      timeEntryId: null,
      value: null,
      ...changes,
    };
  }
  return {
    timeEntryId,
    value: parseDate(value),
    ...changes,
  };
};

const mapToChangeRequestDateValue = (
  src: DateTimeWithOrigin | undefined,
  originalValue: string | null,
): ChangeRequestValue<Date> => {
  if (!src) {
    return { changeRequestId: null, changeTo: undefined };
  }
  const { value, changeRequestId } = src;
  if (changeRequestId === null || value === null) {
    return { changeRequestId: null, changeTo: undefined };
  }
  const changeTo = parseDate(value);
  if (originalValue && isEqual(changeTo, parseDate(originalValue))) {
    return { changeRequestId: null, changeTo: undefined };
  }
  return { changeRequestId, changeTo };
};

const mapDurationToChangeRequestValues = (
  src: DurationWithOrigin | undefined,
  originalValue: number,
): ChangeRequestsValue<number> => {
  if (!src) {
    return { changeRequestIds: [], changeTo: undefined };
  }
  const { minutes, changeRequestIds } = src;
  if (changeRequestIds === null || minutes === null) {
    return { changeRequestIds: [], changeTo: undefined };
  }
  if (minutes === originalValue) {
    return { changeRequestIds: [], changeTo: undefined };
  }
  return { changeRequestIds, changeTo: minutes };
};

export const createTableDaySubRowLoading = (
  timeSheetId: string,
): TableDaySubRowLoading => ({
  id: `${timeSheetId}-subrow-day-loading`,
  rowType: 'subrow-day-loading',
  commentsCount: 0,
  ...timeSheetTrackFieldsUndefined,
});

export const createTableDaySubRowLoadingError = (
  timeSheetId: string,
  error: string,
): TableDaySubRowLoadingError => ({
  id: `${timeSheetId}-subrow-day-loading-error`,
  rowType: 'subrow-day-loading-error',
  commentsCount: 0,
  error,
  ...timeSheetTrackFieldsUndefined,
});

const defaultTableRowState: TableRowState = {
  selected: false,
  expanded: false,
  selectDisabled: false,
  approving: undefined,
};

const sortInputs = {
  employeeNameAsc: {
    field: TimeSheetSortFieldEnum.EmployeeName,
    order: AscDescEnum.Asc,
  },
  startDateDesc: {
    field: TimeSheetSortFieldEnum.StartDate,
    order: AscDescEnum.Desc,
  },
};

const mapDayBadges = (
  src: TimeSheetDaySubRowTrackedTimeDayFragment,
): TableDayBadges => ({
  absences: src.allAbsences,
  holidays: src.holidays,
  absenceMinutes: src.absenceMinutes,
  holidayMinutes: src.holidayMinutes,
});

// Day splitting happens on server side in server time zone (SF organization timeZone right now)
// So we need to show some warning if current timeZone differs
export const getDaySubRowTimezoneWarning = (
  src: TableDaySubRow,
): string | null => {
  const browserStartOfTheDay = parseDate(src.day);
  const serverStartOfTheDay = src.originalDayStartDateTime;
  const difference = differenceInMinutes(
    serverStartOfTheDay,
    browserStartOfTheDay,
  );
  if (difference !== 0) {
    const durationStr = formattedDuration(i18next.t)(difference);
    const differenceStr: string =
      difference > 0 ? '+' + durationStr : durationStr;
    const serverStartTime = src.startTime.value
      ? formatTime24h(addMinutes(src.startTime.value, -difference))
      : '';
    const serverEndTime = src.endTime.value
      ? formatTime24h(addMinutes(src.endTime.value, -difference))
      : '';
    return i18next.t('timeSheetsControlling.table.timeZoneDifferenceWarning', {
      difference: differenceStr,
      serverStartTime,
      serverEndTime,
    });
  }
  return null;
};

export const mapTimeSheet = (src: TimeSheetTableFragment): TimeSheet => {
  const trackedMinutes = src.flair__Time_Entry_Hours__c * 60;
  const workedMinutes = src.workedHours * 60;
  const targetMinutes = src.flair__Target_Hours__c * 60;
  return {
    id: src.Id,
    type: mapTypeSafe(src.flair__Type__c),
    period: mapPeriod(src),
    employee: mapEmployee(src.employee),
    approveStatus: src.flair__Approval_Status__c,
    targetMinutes,
    workedMinutes,
    trackedMinutes,
    differenceMinutes: trackedMinutes - targetMinutes,
    workedDifferenceMinutes: workedMinutes - targetMinutes,
    compensatoryOvertimeMinutes: getCompensatoryOvertimeHours(src) * 60,
    commentsCount: src.timeSheetDays.reduce(
      (sum, curTimeSheetDay) => sum + curTimeSheetDay.commentsCount,
      0,
    ),
    warnings: src.warnings.map(mapWarning),
  };
};

export const mapPeriod = (
  src: Pick<
    TimeSheetTableFragment,
    'flair__Start_Date__c' | 'flair__Type__c' | 'flair__End_Date__c'
  >,
): TimeSheetPeriodInfo => ({
  id: src.flair__Start_Date__c,
  type: mapTypeSafe(src.flair__Type__c),
  startDay: src.flair__Start_Date__c,
  endDay: src.flair__End_Date__c,
});

// We are migrating from TimeSheetTypes -> WorkloadType,
// todo: Please remove  that function in one month after merge https://github.com/flair-hr/employee-hub-monorepo/pull/2194
export const mapTypeSafe = (flair__Type__c: unknown): WorkloadType => {
  if (typeof flair__Type__c !== 'string') {
    return WorkloadType.Weekly;
  }
  switch (flair__Type__c?.toLocaleLowerCase()) {
    case 'monthly':
      return WorkloadType.Monthly;
    case 'weekly':
      return WorkloadType.Weekly;
  }
  return WorkloadType.Weekly;
};

const mapEmployee = (
  src: TimeSheetTableFragment['employee'],
): TimeSheetEmployee => ({
  id: src.Id,
  name: src.Name,
  avatarUrl: src.avatar?.url ?? undefined,
});

const mapWarning = (
  src: TimeSheetTableFragment['warnings'][0],
): TimesheetWarning => ({
  id: src.id,
  message: src.message,
});

const getCompensatoryOvertimeHours = (src: TimeSheetTableFragment): number => {
  if (src.flair__Approval_Status__c === TimeSheetStatus.Approved) {
    return (
      (src.flair__Given_Compensatory_Time_Level_1__c ?? 0) +
      (src.flair__Given_Compensatory_Time_Level_2__c ?? 0)
    );
  }
  return (
    (src.flair__Current_Compensatory_Time_Level_1__c ?? 0) +
    (src.flair__Current_Compensatory_Time_Level_2__c ?? 0)
  );
};

const hasEntriesWithNotes = (
  trackedDay: TimeSheetDaySubRowTrackedTimeDayFragment,
): boolean => {
  const hasTimeEntryWithNotes: boolean =
    trackedDay.timeEntries.some((x) => x.flair__Notes__c?.length) ?? false;
  const hastTimeEntryChangeRequestsWithNotes: boolean =
    trackedDay.dayChanges?.changeRequests.some(
      (x) => x.flair__Creation_Time_Entry_Notes__c?.length,
    ) ?? false;
  return hasTimeEntryWithNotes || hastTimeEntryChangeRequestsWithNotes;
};
