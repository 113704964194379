import React from 'react';
import { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AutoSaveIndicator } from '../../../../../../components/form/AutoSaveIndicator';
import { useToasts } from '../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { useUserInfo } from '../../../../context/UserInfo';
import { useIsManagerMode } from '../../../../hooks/useIsManagerMode';
import {
  ManagerRole,
  useGoalsEmployeesQuery,
  useObjectiveQuery,
} from '../../../../__generated__/graphql';
import { canCloneGoal, canDeleteGoal } from '../logic';
import { toObjectiveIdInput } from '../mappings';
import { GoalId } from '../types';
import { GoalsDropdownActions } from './GoalDropdownActions';
import { useDeleteObjective } from './useObjectiveMutations';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';

type Props = {
  goalId: GoalId;
  onComplete: () => void;
};

export const GoalsDropdownActionsConnected: React.FC<Props> = ({
  goalId,
  onComplete,
}) => {
  const { t } = useTranslation();
  const {
    data: dataEmployees,
    loading: loadingEmployees,
    error: errorEmployees,
  } = useGoalsEmployeesQuery();

  const { data, error, loading } = useObjectiveQuery({
    variables: {
      objectiveId: toObjectiveIdInput(goalId),
    },
  });

  const goalName = data?.objective?.Name ?? '';
  const ownerId = data?.objective?.owner?.Id;

  const userInfo = useUserInfo();
  const { hasManagerRole } = useIsManagerMode();
  const isDirectManager = hasManagerRole(ManagerRole.DirectManager);

  const { id: currentUserId } = userInfo;

  const canClone = ownerId
    ? canCloneGoal({ id: currentUserId, isDirectManager }, ownerId, goalId.type)
    : false;
  const canDelete = ownerId
    ? canDeleteGoal(
        { id: currentUserId, isDirectManager },
        ownerId,
        goalId.type,
      )
    : false;

  const errorHandler = useMutationErrorHandler();
  const { addSuccess, addError } = useToasts();

  const [deleteObjective, { loading: isDeleting }] =
    useDeleteObjective(currentUserId);

  const handleDelete = useCallback(() => {
    deleteObjective({
      variables: {
        objectiveId: toObjectiveIdInput(goalId),
      },
    })
      .then((data) => {
        if (data.data?.objectives.deleteObjective.error) {
          addError(data.data?.objectives.deleteObjective.error);
          return;
        }
        addSuccess(t(`performanceReview.goals2.toastDeleteSuccess`));
        onComplete();
      })
      .catch(errorHandler);
  }, [
    deleteObjective,
    goalId,
    errorHandler,
    addSuccess,
    onComplete,
    t,
    addError,
  ]);

  if (errorEmployees || error) {
    return null;
  }

  if (loading || loadingEmployees || !data || !dataEmployees) {
    // Empty dots stub
    return (
      <DropdownActions id="goal-actions-stub">
        <Dropdown.Item>{t('performanceReview.goals2.loading')}</Dropdown.Item>
      </DropdownActions>
    );
  }

  if (isDeleting) {
    return <AutoSaveIndicator loading={true} />;
  }

  return (
    <GoalsDropdownActions
      onDelete={handleDelete}
      goalId={goalId}
      goalName={goalName}
      canClone={canClone}
      canDelete={canDelete}
    />
  );
};
