import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkeletonCard from '../../../../components/Skeleton/Card';
import SkeletonPageHeader from '../../../../components/Skeleton/PageHeader';

export const LoadingEmployeeEvaluation: React.FC = () => {
  return (
    <>
      <SkeletonPageHeader />
      <Row>
        <Col lg={8}>
          <SkeletonCard height={250} />
          <SkeletonCard height={250} />
          <SkeletonCard height={250} />
        </Col>
        <Col lg={4}>
          <SkeletonCard height={100} />
          <SkeletonCard height={100} />
        </Col>
      </Row>
    </>
  );
};
