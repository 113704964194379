export function isDocumentUploadVisible(
  requireDocumentUpload: boolean = false,
  minAmountForDocumentUpload: number | null,
  requestedAmount?: number | null,
) {
  if (!requireDocumentUpload) {
    return false;
  }

  return (
    !minAmountForDocumentUpload ||
    (requestedAmount && requestedAmount >= minAmountForDocumentUpload)
  );
}
