import styled from '@emotion/styled';
import React, { MutableRefObject, useCallback, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AutoSaveInputLabel from '../../../../../../components/form/AutoSaveInputLabel';
import { CheckboxInput } from '../../../../../../components/form/CheckboxInput';
import { ControlledDateInput } from '../../../../../../components/form/Datepicker';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import { ControlledInput } from '../../../../../../components/form/Input';
import { InputWithSaveControlled } from '../../../../../../components/form/InputsWithSave/InputWithSave';
import { RichtextInputWithSaveControlled } from '../../../../../../components/form/InputsWithSave/RichtextInputWithSave';
import { RichtextInput } from '../../../../../../components/form/RichtextInput';
import ScrollToError from '../../../../../../components/form/ScrollToError';
import {
  SelectDropdownControlled,
  SelectMultipleControlled,
  SelectMultipleEmployeesControlled,
  SelectSingleEmployeeControlled,
} from '../../../../../../components/form/Selects';
import { SelectSingleControlled } from '../../../../../../components/form/Selects/SelectSingleControlled';
import { OptionBase } from '../../../../../../components/Select/types';
import { Theme } from '../../../../../../theme';
import {
  EmployeeOption,
  EmployeeOptionLabel,
} from '../../../../components/Selects/EmployeeOptionLabel';
import { RenderOptionParams } from '../../../../components/Selects/SelectSingleBase';
import { TeamOptionLabel } from '../../../../components/Selects/TeamOptionLabel';
import { GoalHint } from '../GoalHint';
import { getVisibilityOptionStr } from '../translations';
import { allVisibilityOptions, GoalType } from '../types';
import { GoalOption, GoalOptions } from '../useGoalSelectOptions';
import { GoalKeyResultsInputControlled } from './GoalKeyResultsInputControlled';
import {
  GoalStatusHint,
  GoalStatusInputWithRateAndSaveControlled,
  GoalStatusInputWithRateControlled,
} from './GoalStatusInput';
import { SelectSingleGoalControlled } from './SelectSingleGoalControlled';
import {
  GoalSidebarMode,
  ObjectiveFormData,
  ObjectiveFormSaveInfo,
} from './types';
import { useCommonFieldsLogic } from './useCommonFieldsLogic';

type Props = {
  mode: GoalSidebarMode;
  owner?: EmployeeOption;
  options: GoalOptions;
  saveInfo?: ObjectiveFormSaveInfo;
};

const i18prefix = 'performanceReview.goals2.goalSidebar';

export const GoalCommonFields: React.FC<Props> = ({
  owner,
  options,
  mode,
  saveInfo = {},
}) => {
  const { periods, tags, employees, teams, allGoals } = options;
  const refs: Partial<Record<keyof ObjectiveFormData, MutableRefObject<null>>> =
    {
      employeeId: useRef(null),
      teamId: useRef(null),
      timePeriodId: useRef(null),
      name: useRef(null),
      statusWithRate: useRef(null),
      visibility: useRef(null),
    };
  const { t } = useTranslation();

  const {
    readonly,
    availableStatuses,
    isManualProgressVisible,
    isGoalAlignmentVisible,
    visibility,
    goalType,
    rateEnabled,
    isManualProgressEnabled,
    autoGoalInfo,
    timePeriodId,
  } = useCommonFieldsLogic(mode, owner?.value ?? '');

  const timePeriodStr: string | undefined = timePeriodId
    ? options?.periods.find((x) => x.value === timePeriodId)?.periodStr
    : undefined;

  const allowedParentGoals = allGoals.filter((x) =>
    canBeUsedAsParentGoal(goalType, x),
  );

  const isSaving = (name: keyof ObjectiveFormData) =>
    saveInfo[name]?.loading ?? false;

  const renderField = (
    name: keyof ObjectiveFormData,
    renderInput: (name: string, isReadonly: boolean) => React.ReactNode,
    options: {
      optional?: boolean;
      hintText?: string;
      wrap?: boolean;
    } = { wrap: true },
  ) => {
    const refProp = refs[name];
    const label = t(`${i18prefix}.formFields.${name}`);
    return (
      <FormGroup ref={refProp}>
        <AutoSaveInputLabel
          label={label}
          optional={options?.optional}
          hint={
            options?.hintText ? <GoalHint text={options.hintText} /> : undefined
          }
          {...getSaveInfo(name)}
        />
        {options?.wrap ? (
          <StandardHeight>
            {renderInput(name, readonly || isSaving(name))}
          </StandardHeight>
        ) : (
          renderInput(name, readonly || isSaving(name))
        )}
        {refProp && <ScrollToError name={name} inputContainerRef={refProp} />}
      </FormGroup>
    );
  };

  const renderTeamOption = useCallback(
    ({ option, isInsideSelect }: RenderOptionParams<OptionBase>) => {
      return (
        <TeamOptionLabel option={option} isInsideSelect={isInsideSelect} />
      );
    },
    [],
  );

  const getSaveInfo = (name: keyof ObjectiveFormData) => saveInfo[name] ?? {};

  return (
    <>
      <Row>
        {goalType !== 'company' && (
          <Col>
            {goalType === 'employee' &&
              renderField('employeeId', (name, isReadonly) => (
                <SelectSingleEmployeeControlled
                  name={name}
                  isDisabled={isReadonly}
                  options={employees}
                />
              ))}
            {goalType === 'team' &&
              renderField('teamId', (name, isReadonly) => (
                <SelectSingleControlled
                  name={name}
                  isDisabled={isReadonly}
                  renderOption={renderTeamOption}
                  options={teams}
                />
              ))}
          </Col>
        )}
        <Col>
          {owner && (
            <>
              <AutoSaveInputLabel label={t(`${i18prefix}.formFields.owner`)} />
              <StandardHeight>
                <EmployeeOptionLabel employee={owner} isNameVisible={true} />
              </StandardHeight>
            </>
          )}
        </Col>
      </Row>
      {mode === 'update' ? (
        <>
          <FormGroup ref={refs.name}>
            <InputWithSaveControlled
              name="name"
              labelComponent={
                <AutoSaveInputLabel
                  label={t(`${i18prefix}.formFields.name`)}
                  {...getSaveInfo('name')}
                />
              }
              placeholder={t(`${i18prefix}.placeholders.name`)}
              disabled={readonly}
            />
            <ScrollToError name="name" inputContainerRef={refs.name!} />
          </FormGroup>
          <div className="mb-3">
            <RichtextInputWithSaveControlled
              name="description"
              labelComponent={
                <AutoSaveInputLabel
                  label={t(`${i18prefix}.formFields.description`)}
                  optional
                  {...getSaveInfo('description')}
                />
              }
              readOnly={readonly}
            />
          </div>
        </>
      ) : (
        <>
          <FormGroup ref={refs.name}>
            <AutoSaveInputLabel label={t(`${i18prefix}.formFields.name`)} />
            <ControlledInput
              name="name"
              placeholder={t(`${i18prefix}.placeholders.name`)}
              disabled={readonly}
            />
            <ScrollToError name="name" inputContainerRef={refs.name!} />
          </FormGroup>
          <div>
            <AutoSaveInputLabel
              label={t(`${i18prefix}.formFields.description`)}
              optional
            />
            <RichtextInput name="description" disabled={readonly} />
          </div>
        </>
      )}

      {renderField(
        'tagIds',
        (name, isReadonly) => (
          <SelectMultipleControlled
            name={name}
            isDisabled={options.tags.length === 0 || isReadonly}
            options={tags}
          />
        ),
        { optional: true, wrap: false },
      )}
      {(goalType === 'team' || goalType === 'employee') &&
        renderField(
          'keyResults',
          (name, isReadonly) => (
            <GoalKeyResultsInputControlled
              name={name}
              isDisabled={isReadonly}
              confirmDeletion={mode === 'update'}
            />
          ),
          { optional: true, wrap: false },
        )}

      {isGoalAlignmentVisible && (
        <Row>
          <Col>
            {renderField(
              'parentObjectiveId',
              (name, isReadonly) => (
                <SelectSingleGoalControlled
                  name={name}
                  isDisabled={isReadonly}
                  options={allowedParentGoals}
                  placeholder=""
                />
              ),
              { optional: true, wrap: true },
            )}
          </Col>
          <Col></Col>
        </Row>
      )}

      <Row>
        <Col>
          {renderField('visibility', (name, isReadonly) => (
            <SelectDropdownControlled
              name={name}
              isDisabled={isReadonly}
              options={allVisibilityOptions.map((value) => ({
                value,
                label: getVisibilityOptionStr(value),
              }))}
              heightMode="large"
            />
          ))}
        </Col>
        <Col>
          {visibility === 'protected' &&
            renderField('sharedWithEmployeeIds', (name, isReadonly) => (
              <SelectMultipleEmployeesControlled
                name={name}
                isDisabled={isReadonly}
                options={employees}
              />
            ))}
        </Col>
      </Row>
      <Row>
        <Col>
          {renderField('timePeriodId', (name, isReadonly) => (
            <>
              <SelectDropdownControlled
                name={name}
                isDisabled={isReadonly}
                options={periods}
                heightMode="large"
              />
              {timePeriodStr && <Hint className="mt-1">{timePeriodStr}</Hint>}
            </>
          ))}
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          {renderField(
            'startDate',
            (name, isReadonly) => (
              <ControlledDateInput name={name} disabled={isReadonly} />
            ),
            { optional: true },
          )}
        </Col>
        <Col>
          {renderField(
            'dueDate',
            (name, isReadonly) => (
              <ControlledDateInput name={name} disabled={isReadonly} />
            ),
            { optional: true },
          )}
        </Col>
      </Row>

      <div ref={refs.statusWithRate}>
        {mode === 'create' && (
          <GoalStatusInputWithRateControlled
            name="statusWithRate"
            className="mb-4"
            isRateVisible={true}
            saveInfo={getSaveInfo('statusWithRate')}
            availableStatuses={availableStatuses}
            autoGoalInfo={autoGoalInfo}
          />
        )}
        {(mode === 'update' || mode === 'view') && (
          <GoalStatusInputWithRateAndSaveControlled
            name="statusWithRate"
            className="mb-4"
            saveInfo={getSaveInfo('statusWithRate')}
            isDisabled={readonly}
            isRateVisible={rateEnabled}
            availableStatuses={availableStatuses}
            autoGoalInfo={autoGoalInfo}
          />
        )}
      </div>
      {isManualProgressVisible && (
        <div className="d-flex ms-2">
          <CheckboxInput
            disabled={readonly || !isManualProgressEnabled}
            name="manualProgress"
          />
          <div className="ps-2">
            <AutoSaveInputLabel
              label={t(`${i18prefix}.formFields.manualProgress`)}
              hint={<GoalStatusHint />}
              {...getSaveInfo('manualProgress')}
            />
          </div>
        </div>
      )}
    </>
  );
};

const canBeUsedAsParentGoal = (
  targetGoalType: GoalType,
  goal: GoalOption,
): boolean => {
  if (targetGoalType === 'company') {
    return false;
  }
  if (targetGoalType === 'team' && goal.goalType === 'employee') {
    return false;
  }
  return true;
};

const StandardHeight = styled('div')({
  minHeight: 'calc(1.5em + 1rem + 2px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const Hint = styled('div')({
  color: Theme.color.dark,
  whiteSpace: 'nowrap',
});
