import React from 'react';
import { TimeBalancePanel } from './TimeBalancePanel';
import { useTimeBalanceForToday } from '../TimeBalanceForDay/useTimeBalanceForDay';
import { TimeBalanceCalculationMode } from '../../__generated__/graphql';
import { useTimeTrackingSettings } from '../../hooks/timeTrackingSettings';

type Props = {};

export const TimeBalancePanelConnected: React.FC<Props> = () => {
  const { timeBalanceSettings } = useTimeTrackingSettings();
  const timeBalance = useTimeBalanceForToday();

  if (
    !timeBalanceSettings ||
    timeBalanceSettings.flair__Calculation_Mode__c ===
      TimeBalanceCalculationMode.NotTrack
  ) {
    return null;
  }
  return <TimeBalancePanel timeBalance={timeBalance} />;
};
