import * as React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import './DeleteConfirmationModal.css';

type Props = {
  loading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const i18Path = 'comments';

const DeleteConfirmationModal: React.FC<Props> = ({
  loading,
  onClose,
  onSubmit,
}) => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <Modal show={true} onHide={onClose} centered id="delete-comments-modal">
      <Modal.Body className="d-flex flex-column align-items-center shadow-lg">
        <div className="exclamation-mark-container">!</div>
        <div className="mt-3">{t('deleteConfirmationMsg')}</div>

        <div className="mt-4">
          <Button
            className="me-3"
            onClick={onClose}
            variant="outline-secondary"
            label={t('cancel')}
            disabled={loading}
          />

          <Button
            isProcessing={loading}
            onClick={onSubmit}
            label={t('delete')}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmationModal;
