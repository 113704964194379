import React from 'react';
import { useInventoryListQuery } from '../../../__generated__/graphql';
import { mapSortBy } from './mappings';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { mapInventoryListFilterInput } from './Filters/mappings';
import { emptyInventoryFilter } from '../common';
import { routes as mainRoutes } from '../../../routes';
import { Inventory } from './Inventory';
import { useUserInfo } from '../../../context/UserInfo';
import { getCurrentLanguage } from '../../../../../locales/locale';

const PAGE_SIZE = 50;
export const InventoryConnected: React.FC = () => {
  const { id: meId } = useUserInfo();
  const locale = getCurrentLanguage();
  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: emptyInventoryFilter,
    storageKey: 'inventory_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.inventory.route.withQueryParams(queryParams).create({}),
  });

  const { data, loading, error } = useInventoryListQuery({
    variables: {
      employeeId: meId,
      lang: locale,
      filter: mapInventoryListFilterInput(tableState.filter),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });

  return (
    <Inventory
      pageSize={PAGE_SIZE}
      data={data}
      loading={loading}
      error={error}
      tableStateFilter={tableState.filter}
      isManager={false}
      tableStateSortBy={tableState.sortBy}
      tableStatePageIndex={tableState.pageIndex}
      handleFilterChange={handleFilterChange}
      handlePageIndexChanged={handlePageIndexChanged}
      handleSortByChanged={handleSortByChanged}
    />
  );
};
