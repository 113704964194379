import React from 'react';
import { routes } from '../../../routes';
import { DayRowCell } from '../../../components/TimeSheetsTable';
import {
  PeriodCell,
  TableRow,
  TableRowTimeSheet,
  TimeSheetRowCell,
} from '../../../components/TimeSheetsTable';
import { SubRowDaysLoading } from '../../../components/TimeSheetsTable/Cells/SubRowDaysLoading';
import { Link } from '../../../../../Router';
import { createTimeSheetPageQueryParams } from '../MyTimeSheet';

type Props = {
  row: TableRow;
  isExpanded: boolean;
  onExpandedChanged: (
    timeSheetRow: TableRowTimeSheet,
    expanded: boolean,
  ) => void;
};

export const MainCell: React.FC<Props> = ({
  row,
  isExpanded,
  onExpandedChanged,
}) => {
  const renderCell = () => {
    const { rowType } = row;
    switch (rowType) {
      case 'header-week':
        return <PeriodCell period={row.headerWeek} />;
      case 'row-period':
        return (
          <TimeSheetRowCell
            isExpanded={isExpanded}
            onExpandedChanged={(newExpanded) =>
              onExpandedChanged(row, newExpanded)
            }
            period={row.period}
            employeeName={row.employee.name}
            employeeId={row.employee.id}
            timeSheetId={row.timeSheetId}>
            <Link
              to={routes.timeTracking.route.withQueryParams(
                createTimeSheetPageQueryParams({
                  startDate: row.period.startDay,
                  workloadType: row.period.type,
                }),
              )}>
              <PeriodCell period={row.period} />
            </Link>
          </TimeSheetRowCell>
        );
      case 'subrow-day-loading':
        return <SubRowDaysLoading />;
      case 'subrow-day-loading-error':
        return <span className="ms-4 text-danger">{row.error}</span>;
      case 'subrow-day':
        return <DayRowCell className="ms-4" day={row.day} />;
      default:
        return null;
    }
  };
  return <div>{renderCell()}</div>;
};
