import * as React from 'react';

const AlertOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="4"
      height="19"
      viewBox="0 0 4 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M2 0.125C1.21875 0.125 0.4375 0.571396 0.4375 2.34172C0.4375 4.11204 0.697917 9.57055 0.828125 12.0785C0.855097 12.598 1.0625 13.4062 2 13.4062C2.9375 13.4062 3.1449 12.598 3.17188 12.0785C3.30208 9.57055 3.5625 4.11204 3.5625 2.34172C3.5625 0.571396 2.78125 0.125 2 0.125Z"
        fill="#50565E"
      />
      <path
        d="M2 15.75C1.13706 15.75 0.4375 16.4496 0.4375 17.3125C0.4375 18.1754 1.13706 18.875 2 18.875C2.86294 18.875 3.5625 18.1754 3.5625 17.3125C3.5625 16.4496 2.86294 15.75 2 15.75Z"
        fill="#50565E"
      />
    </svg>
  );
});

export default AlertOutlineIcon;
