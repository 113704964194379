import React, { useEffect, useRef } from 'react';
import { Interpolation } from '@emotion/serialize';
import { Theme as EmotionTheme } from '@emotion/react';
import Select, {
  GroupBase,
  Props as ReactSelectProps,
  StylesConfig,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

import { Theme } from '../../theme';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';

export type SelectSingleStyledProps<Option> = {
  styles?: StylesConfig<Option, false>;
  heightMode?: HeightMode;
  error?: boolean;
  autoFocus?: boolean;
  borderless?: boolean;
  isCreatable?: boolean;
  isAsync?: boolean;
  fullWidthMenu?: boolean;
  css?: Interpolation<EmotionTheme>;
  minWidth?: string;
} & ReactSelectProps<Option, false> &
  AsyncAdditionalProps<Option, GroupBase<Option>>;

export type HeightMode = 'normal' | 'large' | 'small';

export const SMALL_HEIGHT_CSS = 'calc(1.5em + 2px)';
export const NORMAL_HEIGHT_CSS = 'calc(1.75em + 0.25rem + 2px)';
export const LARGE_HEIGHT_CSS = 'calc(1.5em + 1rem + 2px)';

export function SelectSingleStyled<Option>({
  styles,
  error,
  heightMode = 'normal',
  autoFocus,
  borderless,
  isCreatable,
  isAsync,
  fullWidthMenu,
  minWidth,
  ...props
}: SelectSingleStyledProps<Option>) {
  const defaultStyles: StylesConfig<Option, false> = {
    control: (_, { isFocused }) => ({
      display: 'flex',
      alignItems: 'center',
      minWidth: minWidth ?? '5rem',
      border: !borderless
        ? `${Theme.border.width} solid ${Theme.input.border.color}`
        : 'none',
      borderRadius: Theme.border.radius,
      borderColor: getBorderColor(isFocused, error === true),
      height:
        heightMode === 'normal'
          ? NORMAL_HEIGHT_CSS
          : heightMode === 'small'
          ? SMALL_HEIGHT_CSS
          : LARGE_HEIGHT_CSS,
      tableLayout: 'fixed',
      cursor: 'pointer',
    }),
    input: (base) => ({
      ...base,
      overflow: 'hidden',
      maxWidth: '100%',
    }),
    valueContainer: (provided) => ({
      ...provided,
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      padding: !borderless ? '0.125rem 0.5rem' : '0.5rem 0.125rem 0.5rem 0',
    }),
    menu: (provided) => ({
      ...provided,
      whiteSpace: 'pre-wrap',
      boxShadow: 'none',
      width: fullWidthMenu ? '100%' : '12rem',
    }),
    menuList: (provided) => ({
      ...provided,
      border: `${Theme.border.width} solid ${Theme.input.border.focusColor}`,
      borderRadius: Theme.border.radius,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      cursor: 'pointer',
      color: Theme.input.select.option.color,
      backgroundColor: isFocused
        ? Theme.input.select.option.focusedBg
        : Theme.input.select.option.bg,
      fontSize:
        heightMode === 'normal' || heightMode === 'small'
          ? Theme.font.size.sm
          : Theme.font.size.base,
      ':hover': {
        backgroundColor: Theme.input.select.option.hoverBg,
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: '5px',
      color: Theme.input.border.color,
      ':hover': {
        color: Theme.input.border.focusColor,
      },
    }),
    placeholder: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? 'hsl(0,0%,60%)' : Theme.input.placeholder.color,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize:
        heightMode === 'normal' || heightMode === 'small'
          ? Theme.font.size.sm
          : Theme.font.size.base,
    }),
  };

  // If you know hot to find right type, please do it :)
  // I think it should be kind of useRef<ReactSelectProps<Option, false, GroupBase<Option>>>(null);
  const ref = useRef<any>(null);
  useEffect(() => {
    if (autoFocus) {
      const current = ref.current;
      if (current !== null && 'focus' in current) {
        current.focus();
      }
    }
  }, [ref, autoFocus]);

  const SelectComponent = isCreatable
    ? CreatableSelect
    : isAsync
    ? AsyncSelect
    : Select;

  return (
    <SelectComponent
      {...props}
      ref={ref}
      styles={{ ...defaultStyles, ...styles }}
      autoFocus={false}
      menuPortalTarget={document.body}
    />
  );
}

const getBorderColor = (isFocused: boolean, isError: boolean) => {
  if (isError) {
    return Theme.input.border.errorColor;
  }
  return isFocused ? Theme.input.border.focusColor : undefined;
};
