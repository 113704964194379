import React, { useMemo } from 'react';
import { TableContent } from '../../../manager/components/TableContent';
import { Column } from 'react-table';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { recruitmentRoutes } from '../../routes';
import { Evaluation, EvaluationsFilterParams } from './types';
import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import StatusIndicator from '../../../components/StatusIndicator';
import { useHistory } from 'react-router-dom';
import ScoreContent from '../CandidateDetails/TeamFeedback/ScoreContent';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { filter } from './EvaluationsFilters/logic';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';

type Props = {
  data: Evaluation[];
  searchText: string;
  filterData: EvaluationsFilterParams;
  isManagerMode: boolean;
};

const i18Path = 'recruitment.jobPage.evaluations';

const EvaluationsTable: React.FC<Props> = ({
  data,
  searchText,
  filterData,
  isManagerMode,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const history = useHistory();

  const filteredEvaluations = filter(searchText, filterData, data);

  const columns: Column<Evaluation>[] = useMemo(
    () =>
      [
        {
          Header: t('candidate'),
          accessor: 'candidate',
          Cell: (props: any) => (
            <span role="button" className="btn-link">
              {props.value.name}
            </span>
          ),
        },
        {
          Header: t('stage'),
          accessor: 'stage',
        },
        isManagerMode
          ? {
              Header: t('evaluator'),
              accessor: 'evaluator',
              Cell: ({ value: { name, avatar } }: any) => (
                <div className="d-flex align-items-center flex-nowrap">
                  <Avatar
                    avatarUrl={avatar ?? ''}
                    initials={getEmployeeInitialsFromName(name)}
                    size="xs"
                  />
                  <div className="ms-2">{name}</div>
                </div>
              ),
            }
          : null,
        !isManagerMode
          ? {
              Header: t('job'),
              accessor: 'candidate.job.name',
            }
          : null,
        {
          Header: t('dueDate'),
          accessor: 'dueDate',
        },
        {
          Header: t('score'),
          accessor: 'score',
          Cell: ({ value }: any) =>
            value === null ? (
              '--'
            ) : (
              <ScoreContent
                score={value}
                labelPosition="right"
                labelClassName="pr-3"
                showScore={true}
                useSplitter={true}
              />
            ),
        },
        {
          Header: t('overallComment'),
          accessor: 'overallComment',
          Cell: ({ value }: any) =>
            value != null
              ? value.length > 20
                ? `${value.substr(0, 20)}...`
                : value
              : '--',
        },
        {
          Header: t('status'),
          accessor: 'status',
          Cell: ({ value: { label, variant } }: any) => (
            <StatusIndicator text={label} variant={variant} />
          ),
        },
        {
          Header: '',
          accessor: 'id',
          Cell: () => (
            <FlairIcon
              icon="chevron-forward-outline"
              aria-label="Edit"
              role="button"
            />
          ),
        },
      ].filter((x) => x !== null) as Column<Evaluation>[],
    [isManagerMode, t],
  );

  const handleOnRowClick = ({ candidate }: Evaluation) => {
    history.push(
      recruitmentRoutes.candidateDetails.route.create({
        candidateId: candidate.id,
      }),
    );
  };

  return filteredEvaluations.length === 0 ? (
    <EmptyStateCardBody title={t('noEvaluationsToDisplay')} />
  ) : (
    <TableContent
      columns={columns}
      data={filteredEvaluations}
      onRowClick={handleOnRowClick}
      pageSize={10}
    />
  );
};

export default EvaluationsTable;
