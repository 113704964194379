import { TimeSheetWorkloadWithTimeFrameworkFragment } from '../../__generated__/graphql';
import { getWorkloadForDate } from '../../utils/timesheetHelper';
import { AutoBreaksRules } from './shared';
import {
  mapAutoBreakRules,
  isValidateAutoBreaksInsideTimeEntry,
} from './suggestBreaks/mappings';

export const getAutoBreakRules = (
  timeSheetWorkloads: ReadonlyArray<TimeSheetWorkloadWithTimeFrameworkFragment>,
  currDate: Date,
): AutoBreaksRules | null => {
  const workload = getWorkloadForDate(timeSheetWorkloads, currDate);
  const timeFramework = workload?.timeFramework;
  if (!timeFramework) {
    return null;
  }
  if (!isValidateAutoBreaksInsideTimeEntry(timeFramework)) {
    return null;
  }
  return mapAutoBreakRules(timeFramework);
};
