import * as React from 'react';
import { Maybe } from '../../../../../utils/maybe';
import { MentionEmployee } from '../types';

type Props = {
  recordId: string;
  selectedEmployee: Maybe<MentionEmployee>;
  setSelectedEmployee: React.Dispatch<
    React.SetStateAction<Maybe<MentionEmployee>>
  >;
};

export const CommentContext = React.createContext<Props>({
  recordId: '',
  selectedEmployee: null,
  setSelectedEmployee: () => null,
});
