import React from 'react';

type Props = {
  className?: string;
};

export const ButtonsContainer: React.FC<Props> = ({
  children,
  className = '',
}) => (
  <div
    className={`position-absolute top-100 end-0 me-4 translate-middle-y bg-white py-2 px-3 rounded-3 border border-color-paper-2 ${className}`}>
    {children}
  </div>
);
