import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../../theme';
import {
  IMAGE_WIDTH_REM,
  IMAGE_HEIGHT_REM,
  getPredefinedImageUrl,
  allImageIds,
  ImageId,
} from '../images';

export type Props = {
  value: ImageId | null;
  onChange: (value: ImageId) => void;
};

export const PredefinedImageInput: React.FC<Props> = ({ value, onChange }) => {
  return (
    <div className="d-flex flex-wrap gap-3 align-items-center">
      {allImageIds.map((imageId) => (
        <PredefinedImage
          key={imageId}
          imageId={imageId}
          isSelected={imageId === value}
          onSelect={onChange}
        />
      ))}
    </div>
  );
};

type PredefinedImageProps = {
  imageId: ImageId;
  isSelected: boolean;
  onSelect: (imageId: ImageId) => void;
};

const PredefinedImage: React.FC<PredefinedImageProps> = ({
  imageId,
  isSelected,
  onSelect,
}) => {
  const imageUrl = getPredefinedImageUrl(imageId);
  return (
    <ImgContainer
      isSelected={isSelected}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(imageId);
      }}>
      <StyledImg src={imageUrl} alt={imageId}></StyledImg>
    </ImgContainer>
  );
};

const COEFF = 2.75;

const ImgContainer = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  borderRadius: '0.625rem',
  border: isSelected ? `3px solid ${Theme.color.blue5}` : undefined,
  opacity: isSelected ? 1 : 0.33,
  width: `${IMAGE_WIDTH_REM / COEFF}rem`,
  height: `${IMAGE_HEIGHT_REM / COEFF}rem`,
  cursor: 'pointer',
  overflow: 'hidden',
  boxSizing: 'border-box',
}));

const StyledImg = styled.img({
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
});
