import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectMultipleEmployeeOrTeamAsyncConnected } from '../../../apps/dashboard/components/Selects/SelectMultipleEmployeeOrTeamAsyncConnected';
import InputError from '../InputError';
import { getError } from '../utils';

export type Props = {
  name: string;
};

export const SelectMultipleEmployeeOrTeamConnectedControlled: React.FC<
  Props
> = ({ name }) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  const renderAs = useCallback(
    ({ value, onChange }) => {
      return (
        <SelectMultipleEmployeeOrTeamAsyncConnected
          values={value}
          onChange={onChange}
          className={error ? 'is-invalid' : ''}
          error={!!error}
        />
      );
    },
    [error],
  );

  return (
    <>
      <Controller as={renderAs} name={name} control={control} />
      <InputError error={error} />
    </>
  );
};
