import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import PageHeader from '../../components/PageHeader';
import { useManagerReportsMode } from '../../hooks/useManagerReportsMode';
import { routes } from '../../routes';
import { ManagerReportsModeSwitch } from '../components/ManagerReportsModeSwitch';
import {
  defaultFilter,
  FilterWarningsType,
  TimeSheetsFilter,
} from './Filters/types';
import { TimeSheetFiltersConnected } from './Filters/TimeSheetFiltersConnected';
import { SingleVisiblePopoverProvider } from '../../../../context/SingleVisiblePopoverContext';
import { TimeSheetTopPanelConnected } from './TopPanel/TimeSheetTopPanelConnected';
import { TimeSheetNavigationConnected } from './Navigation/TimeSheetNavigationConnected';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { TimeSheets } from './Tables/TimeSheets';
import { TimeSheetStatus } from '../../__generated__/graphql';
import { TimeBalancesProvider } from '../../components/TimeSheetsTable/TimeBalanceProvider/TimeBalancesProvider';
import { ManagerCustomizeTableColumnsIcon } from './Tables/ManagerCustomizeTableColumns';
import { useIsTimeBalanceEnabledGlobal } from '../../hooks/timeTrackingSettings/useIsTimeBalanceEnabledGlobal';
import {
  TimeSheetApproveModalConnected,
  TimeSheetApproveContextProvider,
} from '../../components/TimeSheetApprove';

export const TimeSheetsControllingPage: React.FC = () => {
  const isTimeBalanceEnabled = useIsTimeBalanceEnabledGlobal();
  const t = useNamespacedTranslation('navigation.menuItems.timeManagement');

  useFlairBreadcrumbHook([
    { label: t('title') },
    { label: t('subMenuItems.controlling.title') },
  ]);

  const [reportsMode, setReportsMode] = useManagerReportsMode(
    'timeTrackingControlling',
  );

  const [filter, setFilter] = useFlairPersistentFilter<TimeSheetsFilter>({
    defaultFilter: defaultFilter,
    storageKey: 'timeSheets_controlling_pending',
    createRouteUrl: (queryParams) =>
      routes.timeSheetsControllingPending.route
        .withQueryParams(queryParams)
        .create({}),
  });

  const handleSetWarningFilter = (fitlerType: FilterWarningsType) => {
    setFilter({
      ...defaultFilter,
      viewBy: filter.viewBy,
      warnings: fitlerType,
      status: TimeSheetStatus.Pending,
    });
  };

  return (
    <SingleVisiblePopoverProvider>
      <TimeSheetApproveContextProvider>
        <PageHeader
          title={t('subMenuItems.controlling.title')}
          actions={
            <ManagerReportsModeSwitch
              value={reportsMode}
              onChange={setReportsMode}
            />
          }
        />
        <TimeSheetTopPanelConnected
          filter={filter}
          reportsMode={reportsMode}
          onSetWarningFilter={handleSetWarningFilter}
        />
        <Card className="h7-regular">
          <Card.Header className="flexible-height">
            <Row>
              <Col>
                <TimeSheetFiltersConnected
                  filter={filter}
                  onChange={setFilter}
                  reportsMode={reportsMode}
                />
              </Col>
              <Col className="col-auto d-flex align-items-center p-2">
                <ManagerCustomizeTableColumnsIcon
                  isTimeBalanceEnabled={isTimeBalanceEnabled}
                />
              </Col>
              <Col className="col-auto d-flex align-items-center">
                <TimeSheetNavigationConnected
                  reportsMode={reportsMode}
                  selectedMode="pending"
                />
              </Col>
            </Row>
          </Card.Header>
          <TimeBalancesProvider>
            <TimeSheets filter={filter} reportsMode={reportsMode} />
          </TimeBalancesProvider>
          <TimeSheetApproveModalConnected />
        </Card>
      </TimeSheetApproveContextProvider>
    </SingleVisiblePopoverProvider>
  );
};
