import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useUserInfo } from '../../../context/UserInfo';
import {
  useCreateSkillMutation,
  useSkillNamesQuery,
} from '../../../__generated__/graphql';
import { CreateSkillDialog } from './CreateSkillDialog';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const CreateSkillDialogConnected: React.FC<Props> = ({
  show,
  onClose,
}) => {
  const { t } = useTranslation();
  const [createSkill] = useCreateSkillMutation();
  const errorHandler = useMutationErrorHandler();
  const { id: userId } = useUserInfo();
  const { addSuccess, addError } = useToasts();
  const { data } = useSkillNamesQuery();

  const onCreateSkill = useCallback(
    async (name: string, rateable: boolean, onClose: () => void) => {
      await createSkill({
        variables: {
          input: {
            name: name,
            rateable: rateable,
            createdBy: userId,
          },
        },
      })
        .then((r) => {
          if (r.data?.skills.createSkill.error) {
            addError(r.data?.skills.createSkill.error);
            return;
          }
          addSuccess(t('skills.createSkill.success'));
          onClose();
        })
        .catch(errorHandler);
    },
    [createSkill, errorHandler, userId, addSuccess, addError, t],
  );
  return (
    <CreateSkillDialog
      show={show}
      onClose={onClose}
      onSubmit={(name: string, rateable: boolean) =>
        onCreateSkill(name, rateable, onClose)
      }
      allSkills={data?.skills.map((x) => ({ id: x.Id, name: x.Name })) ?? []}
    />
  );
};
