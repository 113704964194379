import React from 'react';
import { CalendarAbsence, CalendarHoliday } from './types';
import { Overlay } from 'react-bootstrap';
import AbsencePopover from '../AbsencePopover';

type AbsenceOverlayParams = {
  target: React.RefObject<HTMLTableDataCellElement>;
  showPopover: boolean;
  absence: CalendarAbsence;
  absences: CalendarAbsence[];
  holiday?: CalendarHoliday;
};

const AbsenceOverlay: React.FC<AbsenceOverlayParams> = ({
  target,
  showPopover,
  absence,
  absences,
  holiday,
}) => (
  <Overlay target={target.current} show={showPopover} placement="top">
    {(props) => (
      <AbsencePopover
        absence={absence}
        holiday={holiday}
        absences={absences}
        {...props}
      />
    )}
  </Overlay>
);

export default AbsenceOverlay;
