import {
  AbsencePopoverFragment,
  HolidayPopoverFragment,
  TimeSheetStatus,
  WorkloadType,
} from '../../../__generated__/graphql';
import { Nullable } from '../../../../../utils/nullable';

export const viewByValues = ['project', 'employee'] as const;
export type ViewBy = (typeof viewByValues)[number]; // 'project' | 'employee'

export const isViewByType = (src: string | null): src is ViewBy => {
  return viewByValues.some((x) => x === src);
};

export type ProjectsTimeSheetEmployee = {
  id: string;
  name: string;
  avatarUrl?: string;
};

export type ProjectsTimeSheetPeriodInfo = {
  type: WorkloadType;
  id: string;
  startDay: string;
  endDay: string;
};

export const EmptyProjectId = 'empty-project-id';

export type ProjectsTimeSheetProjectInfo = {
  id: string;
  name: string;
  startDay: Nullable<string>;
  endDay: Nullable<string>;
  estimatedInMin: Nullable<number>;
  billable: boolean;
  timeTrackedInMin: number;
  timeEntries: TimeSheetProjectTimeEntryInfo[];
  isEmpty: boolean;
};

export type TimeSheetProjectTimeEntryInfo = {
  id: string;
  trackedInMin: number;
  projectId: string;
};

export type TableRow =
  | TableRowHeaderWeek
  | TableRowHeaderEmployee
  | TableRowHeaderProject
  | TableRowTimeSheet
  | TableDayAnySubRow
  | TableProjectSubRow;

export type RowType = TableRow['rowType'];

type TableRowBase = {
  id: string;
  commentsCount: number;
} & TableRowState;

// the row where we show "Week 36", or "Month 9"
export type TableRowHeaderWeek = TableRowBase &
  ProjectsTimeSheetFieldsUndefined & {
    rowType: 'header-week';
    headerWeek: ProjectsTimeSheetPeriodInfo;
  };

export type TableRowHeaderProject = TableRowBase &
  ProjectsTimeSheetFieldsUndefined & {
    rowType: 'header-project';
    headerProject: ProjectsTimeSheetProjectInfo;
  };

export type TableRowHeaderEmployee = TableRowBase &
  ProjectsTimeSheetFieldsUndefined & {
    rowType: 'header-employee';
    headerEmployee: ProjectsTimeSheetEmployee;
  };

// The row that contains the timesheets days (it can be a row for an employee or a row for a period)
export type TableRowTimeSheet = TableRowBase &
  ProjectsTimeSheetFields & {
    rowType: 'row-employee' | 'row-period';
    headerId: string;
    projectId: string | undefined;
  };

export type TableDayAnySubRow =
  | TableDaySubRow
  | TableDaySubRowLoading
  | TableDaySubRowLoadingError;

export const isTableDayAnySubRow = (src: TableRow): src is TableDayAnySubRow =>
  src.rowType === 'subrow-day' ||
  src.rowType === 'subrow-day-loading' ||
  src.rowType === 'subrow-day-loading-error';

export const isTableProjectSubRow = (
  src: TableRow,
): src is TableProjectSubRow => src.rowType === 'subrow-project';

export const isTableDaySubRow = (src: TableRow): src is TableDaySubRow =>
  src.rowType === 'subrow-day';

export type ExpandableRow = TableRowTimeSheet | TableDaySubRow;

export type TableDaySubRow = Omit<
  ProjectsTimeSheetFieldsUndefined,
  'targetMinutes' | 'trackedMinutes' | 'timeSheetId' | 'note' | 'badges'
> & {
  rowType: 'subrow-day';
  id: string;
  day: string;
  timeSheetId: string;
  projectId: string | undefined;
  timeSheetDayId: Nullable<string>;
  commentsCount: number;
  note: Nullable<string>;
  targetMinutes: number;
  trackedMinutes: number;
  badges: TableDayBadges;
  subRows?: TableProjectSubRow[];
};

export type TableProjectSubRow = Omit<
  ProjectsTimeSheetFieldsUndefined,
  'trackedMinutes' | 'note'
> &
  Pick<ProjectsTimeSheetFields, 'trackedMinutes' | 'note'> & {
    rowType: 'subrow-project';
    id: string;
    projectInfo: Pick<ProjectsTimeSheetProjectInfo, 'name' | 'billable'>;
    commentsCount: undefined;
  };

export type TableDaySubRowLoading = ProjectsTimeSheetFieldsUndefined & {
  rowType: 'subrow-day-loading';
  id: string;
  commentsCount: number;
};

export type TableDaySubRowLoadingError = ProjectsTimeSheetFieldsUndefined & {
  rowType: 'subrow-day-loading-error';
  id: string;
  commentsCount: number;
  error: string;
};

export type TableDayBadges = {
  absences: ReadonlyArray<AbsencePopoverFragment>;
  holidays: ReadonlyArray<HolidayPopoverFragment>;
  absenceMinutes: number;
  holidayMinutes: number;
};

export const isTableRowTimeSheet = (src: TableRow): src is TableRowTimeSheet =>
  src.rowType === 'row-employee' || src.rowType === 'row-period';

export type TableRowHeader = TableRowHeaderEmployee | TableRowHeaderWeek;

export const isTableRowHeader = (src: TableRow): src is TableRowHeader =>
  src.rowType === 'header-employee' ||
  src.rowType === 'header-week' ||
  src.rowType === 'header-project';

export const isTableRowHeaderProject = (
  src: TableRow,
): src is TableRowHeaderProject => src.rowType === 'header-project';

export const isTableRowHeaderEmployee = (
  src: TableRow,
): src is TableRowHeaderEmployee => src.rowType === 'header-employee';

export const isTableRowHeaderWeek = (
  src: TableRow,
): src is TableRowHeaderWeek => src.rowType === 'header-week';

export type TableRowState = {
  approving?: boolean;
  selected: boolean;
  selectDisabled: boolean;
};

/**
 * ProjectsTimeSheetFields is the fields used in the table columns
 */
export type ProjectsTimeSheetFields = {
  timeSheetId: string;
  period: ProjectsTimeSheetPeriodInfo;
  employee: ProjectsTimeSheetEmployee;
  approveStatus: TimeSheetStatus;
  targetMinutes: number;
  trackedMinutes: number;
  note: Nullable<string>;
  actions: undefined;
  badges: TableDayBadges | undefined;
};

// make every field optional
type ProjectsTimeSheetFieldsUndefined = Record<
  keyof ProjectsTimeSheetFields,
  undefined
>;

export const projectTimeSheetTrackFieldsUndefined: ProjectsTimeSheetFieldsUndefined =
  {
    timeSheetId: undefined,
    period: undefined,
    employee: undefined,
    approveStatus: undefined,
    targetMinutes: undefined,
    trackedMinutes: undefined,
    note: undefined,
    badges: undefined,
    actions: undefined,
  };

export type ProjectsTimeSheetTableDayItem = {
  id: string;
  day: string;
  trackedMinutes: number;
};

export type ProjectsTimeSheet = {
  id: string;
  type: WorkloadType;
  period: ProjectsTimeSheetPeriodInfo;
  employee: ProjectsTimeSheetEmployee;
  projects: ProjectsTimeSheetProjectInfo[];
  targetMinutes: number;
  trackedMinutes: number;
  approveStatus: TimeSheetStatus;
  commentsCount: number;
  timesheetDays: ProjectsTimeSheetTableDayItem[];
};
