import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import Button from '../../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import './deleteModalstyles.css';

type Props = {
  visible: boolean;
  onClose: () => void;
  submitInProgress: boolean;
  onSubmit: () => void;
};

const i18Path =
  'employeePage.performanceReview.nextPerformanceReview.peerDeleteConfirmationModal';

export const PeerDeleteConfirmationModal = ({
  visible,
  submitInProgress,
  onClose,
  onSubmit,
}: Props) => {
  const t = useNamespacedTranslation(i18Path);
  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      className="confirm-modal"
      contentClassName="border"
      backdropClassName="confirm-modal-backdrop">
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
        <Modal.Body className="pb-0">
          <div className="d-flex justify-content-center flex-column align-items-center gap-3">
            <div className="d-flex icon-container justify-content-center p-3 align-items-center">
              <FlairIcon icon="alert-outline" />
            </div>
            <h2 className="card-header-title">{t(`title`)}</h2>
          </div>
        </Modal.Body>
        <ModalFooter className="border-top-0 pt-0 d-flex flex-row flex-nowrap gap-3">
          <Button
            variant="outline-primary"
            onClick={onClose}
            label={t('buttons.cancel')}
          />
          <Button
            isProcessing={submitInProgress}
            onClick={onSubmit}
            variant="primary"
            label={t('buttons.remove')}
          />
        </ModalFooter>
      </div>
    </Modal>
  );
};
