import { DropdownOption, ExpenseCategory, Merchant, Project } from './types';

export const mapExpenseCategory = (
  category: ExpenseCategory,
): DropdownOption => ({
  label: category.Name,
  value: category.Id,
});

export const mapMerchant = (merchant: Merchant): DropdownOption => ({
  label: merchant.Name,
  value: merchant.Id,
});

export const mapProject = (project: Project): DropdownOption => ({
  label: project.flair__Name__c,
  value: project.Id,
});
