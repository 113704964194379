import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

type Props = {
  className?: string;
  children: ReactNode;
};

const CardHeader: React.FC<Props> = ({ className, children }) => {
  return (
    <Card.Header className={className}>
      <h4 className="card-header-title">{children}</h4>
    </Card.Header>
  );
};

export default CardHeader;
