import { StoreObject } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { PaginationInfo } from '../apps/dashboard/__generated__/graphql';
export type ObjectWithPagination<T> = {
  items?: ReadonlyArray<T>;
  paginationInfo?: PaginationInfo;
};

export function mergeObjectWithPagination<T>(
  existing: ObjectWithPagination<T> | undefined,
  incoming: ObjectWithPagination<T>,
  pagination: Record<string, any> | undefined,
) {
  if (pagination !== undefined && typeof pagination !== 'object') {
    throw new Error('Invalid pagination argument');
  }

  const merged = { ...existing, ...incoming, items: existing?.items };
  if (
    !pagination &&
    incoming.items !== undefined &&
    incoming.paginationInfo !== undefined
  ) {
    return incoming;
  }
  if (pagination?.offset === undefined) {
    return merged;
  }

  if (incoming.items) {
    const existentItems = existing?.items;
    return {
      ...merged,
      items: mergeArrays(
        existentItems !== undefined ? existentItems : undefined,
        incoming.items,
        pagination?.offset,
      ),
    };
  }
  return merged;
}

export function mergeArrays<T>(
  existing: ReadonlyArray<T> | undefined,
  incoming: ReadonlyArray<T>,
  incomingOffset: number,
) {
  if (existing === undefined) {
    return incoming;
  }
  const merged = [...existing];
  const offsetToMerge = Math.min(incomingOffset, existing.length);
  for (let i = 0; i < incoming.length; ++i) {
    merged[offsetToMerge + i] = incoming[i];
  }

  return merged;
}

export const deleteItemFromPagination = <T extends StoreObject>(
  existent: ObjectWithPagination<T>,
  readField: ReadFieldFunction,
  idToRemove: string,
): {
  items: ReadonlyArray<T>;
  paginationInfo: PaginationInfo;
} => {
  if (!existent.items) {
    return {
      items: [],
      paginationInfo: {
        totalCount: 0,
      },
    };
  }
  const updatedItems = existent.items.filter(
    (cacheItemRef: T) => idToRemove !== readField('Id', cacheItemRef),
  );
  const deletedCount = existent.items.length - updatedItems.length;

  return {
    items: updatedItems,
    paginationInfo:
      existent.paginationInfo?.totalCount !== undefined
        ? {
            totalCount: existent.paginationInfo.totalCount - deletedCount,
          }
        : {
            totalCount: 0,
          },
  };
};
