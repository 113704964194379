import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { StatusCircle } from '../../../../../components/StatusCircle';
import { TicketStatus } from '../../types';
import { getTicketItemStatusColor } from '../../mappings';

type Props = {
  status: TicketStatus;
};
const i18Prefix = 'support.tickets.table.ticketStatus';
export const TicketItemStatus: React.FC<Props> = ({ status }) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <div className="d-flex flex-row align-items-center gap-sm-2">
      <StatusCircle color={getTicketItemStatusColor(status)} size={5} />
      <span>{t(`${status}`)}</span>
    </div>
  );
};
