import { useCallback, useEffect } from 'react';
import { hasAnyRoles, useUserInfo } from '../context/UserInfo';
import { ManagerRole } from '../__generated__/graphql';
import { useFlairLocalStorage } from './useFlairLocalStorage';

export const MANAGER_MODE_LS_KEY = 'MANAGER_MODE';

export const useIsManagerMode = () => {
  const { managerRoles, isManagerModeAvailable } = useUserInfo();

  const [isManagerModeOn, setIsManagerModeOn] = useFlairLocalStorage(
    MANAGER_MODE_LS_KEY,
    false,
  );

  const hasAnyOfManagerRole = useCallback(
    (roles: ManagerRole[]) => hasAnyRoles(roles, managerRoles),
    [managerRoles],
  );

  const hasManagerRole = useCallback(
    (role: ManagerRole) => {
      return managerRoles.includes(role);
    },
    [managerRoles],
  );

  useEffect(() => {
    if (isManagerModeOn && !isManagerModeAvailable) {
      setIsManagerModeOn(false);
    }
  }, [setIsManagerModeOn, isManagerModeOn, isManagerModeAvailable]);

  return {
    isManagerModeAvailable,
    isManagerModeOn,
    setIsManagerModeOn,
    hasAnyOfManagerRole,
    hasManagerRole,
  };
};
