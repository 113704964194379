import moment from 'moment';
import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { useMemo } from 'react';
import { EmployeeFieldWithState } from '../types';
import {
  EmployeeDataFieldState,
  FieldType,
} from '../../../__generated__/graphql';

const buildFieldSchema = (field: EmployeeFieldWithState) => {
  const { state } = field;
  const { options } = field.fieldInfo;

  const emptyToNull = (value: any) => {
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'number') {
      return isNaN(value) ? null : value;
    }
    if (value instanceof Date) {
      return moment(value).isValid() ? value : null;
    }

    return isEmpty(value) ? null : value;
  };

  const schema = ((type) => {
    switch (type) {
      case FieldType.Boolean: {
        return yup.boolean();
      }
      case FieldType.Date:
      case FieldType.Datetime: {
        return yup.date();
      }
      case FieldType.Integer:
      case FieldType.Long: {
        return yup.number().integer();
      }
      case FieldType.Currency:
      case FieldType.Percent:
      case FieldType.Double: {
        return yup.number();
      }
      case FieldType.Picklist: {
        return yup
          .string()
          .oneOf(
            options.reduce((r, e) => r.concat(e.value), [null] as (
              | string
              | null
            )[]),
          );
      }
      case FieldType.Multipicklist: {
        return yup
          .array()
          .of(
            yup
              .string()
              .oneOf(
                options.reduce((r, e) => r.concat(e.value), [null] as (
                  | string
                  | null
                )[]),
              )
              .transform(emptyToNull)
              .nullable(),
          )
          .transform((values) =>
            values ? values.filter((v: any) => !!v) : [],
          );
      }
      case FieldType.Email: {
        return yup.string().email();
      }
      case FieldType.Url: {
        return yup.string().url();
      }
      default: {
        return yup.string();
      }
    }
  })(field.fieldInfo.type)
    .transform(emptyToNull)
    .nullable();

  return state === EmployeeDataFieldState.Required
    ? schema.required()
    : schema.notRequired();
};

export const useValidationSchema = (fields: EmployeeFieldWithState[]) =>
  useMemo(
    () =>
      fields.reduce(
        (result, field) =>
          result.shape({ [field.fieldInfo.name]: buildFieldSchema(field) }),
        yup.object(),
      ),
    [fields],
  );
