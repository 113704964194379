import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import './LoadingWidget.css';

type Props = {
  height?: number;
};

const LoadingWidget: React.FC<Props> = ({ height = 200 }) => {
  return <Skeleton className="loading-widget-skeleton" height={height} />;
};

export default LoadingWidget;
