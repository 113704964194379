import * as React from 'react';
import { useState } from 'react';
import './UploadFile.css';
import { useNamespacedTranslation } from '../../hooks/useNamespacedTranslation';
import { FILE_SIZE_LIMIT, fileSizeFormatter } from '../../utils/file';
import classNames from 'classnames';

type Props = {
  onChange: (files: FileList) => void;
  acceptExtensions?: string;
  content?: React.ReactNode;
};

const i18Path = 'comments';

const UploadFile: React.FC<Props> = ({
  onChange,
  acceptExtensions = '',
  content,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputId = `input-file-${Math.random()}`;

  const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.files) {
      onChange(target.files);
    }
  };

  return (
    <div className="pt-2">
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}>
        <input
          className="file-input"
          type="file"
          id={inputId}
          onChange={handleChange}
          accept={acceptExtensions}
        />
        {content ? (
          <label
            htmlFor={inputId}
            className={classNames({
              'drag-active-custom': dragActive,
            })}>
            {content}
          </label>
        ) : (
          <label
            htmlFor={inputId}
            className={classNames(
              'd-flex',
              'p-3',
              'align-items-center',
              'justify-content-center',
              'file-input-container rounded',
              {
                'drag-active': dragActive,
              },
            )}>
            <div className="file-input-label w-100 text-center">
              {t('selectFileWithSize', {
                size: fileSizeFormatter(FILE_SIZE_LIMIT),
              })}
            </div>
          </label>
        )}
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}></div>
        )}
      </form>
    </div>
  );
};

export default UploadFile;
