import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import ServerError from '../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { SkeletonTable } from '../../components/Skeleton/Table';
import { EmptyStateCardBody } from '../../components/EmptyStateCard';
import {
  EvaluationsFilterParams,
  filterDefaultData,
} from '../JobPage/JobEvaluations/types';
import { EvaluationsFilters } from '../JobPage/JobEvaluations/EvaluationsFilters';
import EvaluationsTable from '../JobPage/JobEvaluations/EvaluationsTable';
import { Route } from '../../../../utils/router';
import { mapEvaluations } from '../JobPage/JobEvaluations/logic';
import { EvaluationsQuery } from '../../__generated__/graphql';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';

type Props = {
  data: EvaluationsQuery['evaluations'] | undefined;
  route: Route<['jobEvaluations', 'employeeEvaluations']>;
  storageKey: 'EMPLOYEE_EVALUATIONS_LIST_FILTERS' | 'EVALUATIONS_LIST_FILTERS';
  jobId?: string;
  loading: boolean;
  error: any;
  isManagerMode?: boolean;
};

const i18Path = 'recruitment.jobPage.evaluations';

const Evaluations: React.FC<Props> = ({
  data,
  loading,
  error,
  route,
  storageKey,
  jobId,
  isManagerMode = false,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const [filterData, setFilterData] =
    useFlairPersistentFilter<EvaluationsFilterParams>({
      defaultFilter: filterDefaultData,
      storageKey,
      createRouteUrl: (queryParams) =>
        route
          .withQueryParams({
            ...queryParams,
          })
          .create({ jobId }),
    });

  const [searchText, setSearchText] = useState<string>('');

  if (error) {
    return <ServerError />;
  }

  const evaluations = mapEvaluations(data ?? []);

  const renderContent = () => {
    if (loading) {
      return <SkeletonTable className="card-table" columns={4} rows={6} />;
    }

    if (evaluations.length === 0) {
      return <EmptyStateCardBody title={t('noEvaluations')} />;
    }

    return (
      <EvaluationsTable
        data={evaluations}
        searchText={searchText}
        filterData={filterData}
        isManagerMode={isManagerMode}
      />
    );
  };

  return (
    <Card>
      <Card.Header>
        <EvaluationsFilters
          evaluations={evaluations}
          setSearchText={setSearchText}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      </Card.Header>
      {renderContent()}
    </Card>
  );
};

export default Evaluations;
