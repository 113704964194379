import React from 'react';
import { Button } from 'react-bootstrap';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import { SidePanelHeaderActionsProps } from '../types';

const SidePanelHeaderActions: React.FC<SidePanelHeaderActionsProps> = ({
  onSwitch,
  canSwitch,
}) => (
  <div style={{ marginTop: '0.3rem' }}>
    <Button
      variant="link"
      disabled={!canSwitch.previous}
      onClick={() => onSwitch('previous')}>
      <FlairIcon icon="chevron-back-outline" size="lg" />
    </Button>
    <Button
      variant="link"
      disabled={!canSwitch.next}
      onClick={() => onSwitch('next')}>
      <FlairIcon icon="chevron-forward-outline" size="lg" />
    </Button>
  </div>
);

export default SidePanelHeaderActions;
