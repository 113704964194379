import {
  CorporateDocument,
  CorporateDocumentFilter,
  Navigatable,
  PersonalDocumentFilter,
} from '../types';
import { getMonthName, parseDate } from '../../../../../utils/dateUtils';

export const applyPersonalDocumentsFilter = (
  filter: PersonalDocumentFilter,
  documents: Navigatable[],
): Navigatable[] =>
  documents.filter((x) => isMatchPersonalDocumentFilter(filter, x));

export const applyCorporateDocumentsFilter = (
  filter: CorporateDocumentFilter,
  documents: CorporateDocument[],
): CorporateDocument[] =>
  documents.filter((x) => isMatchCorporateDocumentFilter(filter, x));
const isMatchPersonalDocumentFilter = (
  filter: PersonalDocumentFilter,
  document: Navigatable,
): boolean => {
  if (filter.status !== null && document.status !== filter.status) {
    return false;
  }

  if (filter.category !== null && document.category !== filter.category) {
    return false;
  }

  if (filter.documentName !== null) {
    const documentName = filter.documentName.toLowerCase();
    if (
      !document.name.toLowerCase().includes(documentName) &&
      !(document.files || []).filter((item) =>
        item.title.toLowerCase().includes(documentName),
      ).length
    )
      return false;
  }

  if (filter.year !== null && document.addedOn !== null) {
    if (parseDate(document.addedOn).getFullYear() !== parseInt(filter.year)) {
      return false;
    }
  }
  if (filter.month !== null && document.addedOn !== null) {
    if (getMonthName(document.addedOn) !== filter.month) {
      return false;
    }
  }
  if (filter.addedBy !== null && document.employee.id !== filter.addedBy) {
    return false;
  }

  return true;
};
export const hasPersonalDocumentFilter = (
  filter: PersonalDocumentFilter,
): boolean => {
  if (filter.status !== null) {
    return true;
  }

  if (filter.documentName !== null) {
    return true;
  }

  if (filter.category !== null) {
    return true;
  }

  if (filter.year !== null) {
    return true;
  }
  if (filter.month !== null) {
    return true;
  }
  if (filter.addedBy !== null) {
    return true;
  }

  return false;
};

export const hasCorporateDocumentFilter = (
  filter: CorporateDocumentFilter,
): boolean => {
  if (filter.documentName !== null) {
    return true;
  }
  if (filter.year !== null) {
    return true;
  }
  if (filter.month !== null) {
    return true;
  }
  if (filter.addedBy !== null) {
    return true;
  }

  return false;
};
const isMatchCorporateDocumentFilter = (
  filter: CorporateDocumentFilter,
  document: CorporateDocument,
): boolean => {
  if (filter.documentName !== null) {
    const documentName = filter.documentName.toLowerCase();
    if (
      !document.name.toLowerCase().includes(documentName) &&
      !(document.files || []).filter((item) =>
        item.title.toLowerCase().includes(documentName),
      ).length
    )
      return false;
  }
  if (filter.year !== null && document.addedOn !== null) {
    if (parseDate(document.addedOn).getFullYear() !== parseInt(filter.year)) {
      return false;
    }
  }
  if (filter.month !== null && document.addedOn !== null) {
    if (getMonthName(document.addedOn) !== filter.month) {
      return false;
    }
  }
  if (filter.addedBy !== null && document.employee.id !== filter.addedBy) {
    return false;
  }

  return true;
};
