import React, { ReactNode, useMemo } from 'react';
import { Celebration, CelebrationsView, Employee } from '../types';
import styled from '@emotion/styled';
import { EmployeeWithAvatar } from '../../../components/Employee';
import { formatDateShort } from '../../../../../utils/dateUtils';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Skeleton from 'react-loading-skeleton';
import { IMAGE_HEIGHT_REM, IMAGE_WIDTH_REM } from '../images';
import { Card } from 'react-bootstrap';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { CelebrationLoomVideo } from './CelebrationLoomVideo';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../components/Comment/types';
import ConnectedReactions from '../../../components/Reactions/ConnectedReactions';
import { ReactionsRelatedObjectNames } from '../../../components/Reactions/ConnectedReactions/types';
import { ReactionFragment } from '../../../__generated__/graphql';
import './CelebrationCard.css';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';
import { EmployeesAvatarGroup } from '../../../../../atomic/molecules/EmployeesAvatarGroup/EmployeeAvatarGroup';

type Props = {
  celebration: Celebration;
  variant?: CelebrationsView;
  canManage: boolean;
  onMakePublic: (id: string) => void;
  onUpdate: (celebration: Celebration) => void;
  onDelete: (id: string) => void;
};

const i18nPrefix = 'cheers.cheersCard';

export const CelebrationCard: React.FC<Props> = ({
  celebration,
  variant = 'all',
  canManage,
  onMakePublic,
  onUpdate,
  onDelete,
}) => {
  const {
    message,
    from,
    recipients,
    date,
    isPublic,
    id,
    imageUrl,
    type,
    loomVideo,
    commentsCount,
    reactions,
  } = celebration;
  const t = useNamespacedTranslation(i18nPrefix);

  const imageComponent = useMemo(
    () =>
      loomVideo === null ? (
        <Card.Img variant="top" src={imageUrl} alt={type} />
      ) : (
        <CelebrationLoomVideo
          imageUrl={imageUrl}
          type={type}
          loomVideo={loomVideo}
        />
      ),
    [imageUrl, type, loomVideo],
  );

  const isRecipientsHidden =
    variant === 'received' &&
    recipients.length === 1 &&
    recipients[0].type === 'employee';
  // todo: In next PR fix it

  return (
    <CelebrationCardLayout
      id={id}
      image={imageComponent}
      message={message}
      recipient={
        !isRecipientsHidden && (
          <EmployeesAvatarGroup className="mb-3" recipients={recipients} />
        )
      }
      sender={variant !== 'sent' && from !== null && <Sender employee={from} />}
      createdDate={formatDateShort(date)}
      visibility={variant !== 'feed' && <PublicPrivate isPublic={isPublic} />}
      commentsCount={commentsCount}
      reactions={reactions}>
      {canManage && (
        <DropDownActionsContainer>
          <DropdownActions id="celebration-card-actions" drop="end">
            {!isPublic && (
              <DropdownActionItem onClick={() => onMakePublic(id)}>
                {t('makePublic')}
              </DropdownActionItem>
            )}
            <DropdownActionItem onClick={() => onUpdate(celebration)}>
              {t('edit')}
            </DropdownActionItem>
            <DropdownActionItem onClick={() => onDelete(id)}>
              {t('delete')}
            </DropdownActionItem>
          </DropdownActions>
        </DropDownActionsContainer>
      )}
    </CelebrationCardLayout>
  );
};

export const CelebrationCardSkeleton: React.FC = () => {
  const LINE_HEIGHT = '1.5rem';
  const MESSAGE_HEIGHT = '4rem';

  return (
    <CelebrationCardLayout
      image={
        <Skeleton
          style={{ lineHeight: 'unset' }}
          width={`${IMAGE_WIDTH_REM}rem`}
          height={`${IMAGE_HEIGHT_REM}rem`}
        />
      }
      reactions={[]}
      commentsCount={0}
      recipient={<Skeleton width="100%" height={LINE_HEIGHT} />}
      message={<Skeleton width="100%" height={MESSAGE_HEIGHT} />}
      sender={<Skeleton width="100%" height={LINE_HEIGHT} />}
      createdDate={<Skeleton width="5rem" height={LINE_HEIGHT} />}
      visibility={
        <Skeleton width="5rem" height={LINE_HEIGHT} />
      }></CelebrationCardLayout>
  );
};

type CelebrationCardLayoutProps = {
  id?: string;
  image: ReactNode;
  sender: ReactNode;
  message: ReactNode;
  recipient: ReactNode;
  createdDate: ReactNode;
  visibility: ReactNode;
  commentsCount: number;
  reactions: ReadonlyArray<ReactionFragment>;
};

export const CelebrationCardLayout: React.FC<CelebrationCardLayoutProps> = ({
  id,
  image,
  sender,
  message,
  recipient,
  createdDate,
  visibility,
  children,
  commentsCount,
  reactions,
}) => (
  <CardContainer className="celebration-card-container">
    {image}
    <Card.Body>
      {recipient}
      <MessageContainer>{message}</MessageContainer>
      <div className="d-flex justify-content-between align-items-center small text-dark">
        {sender}
        <div>{visibility}</div>
        <div>{createdDate}</div>
      </div>
    </Card.Body>
    <Card.Footer className="d-flex justify-content-between align-items-center small text-dark">
      {id && (
        <ConnectedReactions
          reactions={reactions}
          recordId={id}
          relatedObjectName={ReactionsRelatedObjectNames.Celebration}
        />
      )}
      {id && (
        <CommentsPopover
          recordId={id}
          relatedObjectName={RelatedObjectNames.Celebration}
          commentsCount={commentsCount}
          mode="text"
        />
      )}
    </Card.Footer>
    {children}
  </CardContainer>
);

const Sender: React.FC<{ employee: Employee }> = ({ employee }) => {
  const t = useNamespacedTranslation(i18nPrefix);

  return (
    <div className="d-flex align-items-center small text-dark">
      <div className="me-2">{t('from')}</div>
      <EmployeeWithAvatar employee={employee} size="xs" />
    </div>
  );
};

const PublicPrivate: React.FC<{ isPublic: boolean }> = ({ isPublic }) => {
  const icon = isPublic ? 'eye-outline' : 'eye-off-outline';
  const t = useNamespacedTranslation(i18nPrefix);
  const text = isPublic ? t('public') : t('private');
  return (
    <div className="d-flex align-items-center">
      <FlairIcon icon={icon} className="me-1" />
      <span>{text}</span>
    </div>
  );
};

const CardContainer = styled(Card)({
  maxWidth: `${IMAGE_WIDTH_REM}rem`,
  margin: 0,
});

const DropDownActionsContainer = styled.div({
  position: 'absolute',
  top: '0.625rem',
  right: '0.625rem',
});

const MessageContainer = styled.div({
  height: '9.5rem',
});
