import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Hint from '../../../../../../components/hint';
import { Theme } from '../../../../../../theme';
import { GoalStatus } from '../types';

type Props = {
  goalId: string;
  status: GoalStatus;
  progress: number;
  goalExpectedProgress?: number; // only for goals
  className?: string;
};

const i18prefix = 'performanceReview.goals2';

export const GoalProgress: React.FC<Props> = ({
  goalId,
  status,
  progress,
  goalExpectedProgress,
  className,
}) => {
  const { t } = useTranslation();
  const hintText = goalExpectedProgress
    ? t(`${i18prefix}.progressHintExpected`, {
        current: progress.toFixed(),
        expected: goalExpectedProgress.toFixed(),
      })
    : null;

  const getContent = () => {
    return <GoalProgressBar status={status} progress={progress} />;
  };

  return (
    <GoalProgressContainer className={className}>
      {hintText ? (
        <Hint id={`goal-progress-hint-${goalId}`} text={hintText}>
          {getContent()}
        </Hint>
      ) : (
        getContent()
      )}
    </GoalProgressContainer>
  );
};

const GoalProgressContainer = ({
  style = {},
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    style={{
      minWidth: '85px',
      ...style,
    }}
  />
);

type GoalProgressBarProps = {
  progress: number;
  status: GoalStatus;
};

const GoalProgressBar = ({ progress, status }: GoalProgressBarProps) => (
  <ProgressBar
    className="progress-sm"
    max={100}
    now={progress}
    style={{
      backgroundColor: Theme.goals.statusBar[status].color,
    }}
  />
);
