import React, { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { IdName } from '../common/types';
import { CreateSkillDialogConnected } from '../dialog/CreateSkillDialogConnected';
import { EmployeeSkillsSidebar } from '../managerForEmployee/EmployeeSkillsSidebar';
import { SkillsMatrixConnected } from '../skillsMatrix/SkillsMatrixConnected';
import { ManageSkillsSidebar } from './ManageSkillsSidebar';
import { EngagementContainer } from '../../../manager/EngagementContainer';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

export const SkillsManagerPage: React.FC = () => {
  const { t } = useTranslation();

  const tNavigation = useNamespacedTranslation(
    'navigation.menuItems.teamDevelopment',
  );

  useFlairBreadcrumbHook([
    { label: tNavigation('title') },
    { label: tNavigation('subMenuItems.skills.title') },
  ]);

  const [manageSkillsVisible, setManageSkillsVisible] =
    useState<boolean>(false);
  const [showCreateSkillDialog, setShowCreateSkillDialog] =
    useState<boolean>(false);

  const [selectedEmployee, setSelectedEmployee] = useState<IdName | null>(null);

  const handleManageSkillsClick = useCallback(() => {
    setManageSkillsVisible(true);
  }, [setManageSkillsVisible]);

  const handleCreateSkillsClick = useCallback(() => {
    setShowCreateSkillDialog(true);
  }, [setShowCreateSkillDialog]);

  return (
    <EngagementContainer
      title={t('performanceReview.subMenuItems.skills.title')}>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="fw-bold">{t('skills.title')}</div>
          <Button
            className="me-3"
            size="sm"
            variant="outline-primary"
            label={t('skills.manager.title')}
            onClick={handleManageSkillsClick}
          />
          <Button
            variant="primary"
            size="sm"
            label={t('skills.createSkill.title')}
            onClick={handleCreateSkillsClick}
          />
        </Card.Header>
        <Card.Body className="px-0 pb-0">
          <SkillsMatrixConnected onEmployeeClick={setSelectedEmployee} />
        </Card.Body>
      </Card>
      <ManageSkillsSidebar
        visible={manageSkillsVisible}
        onClose={() => setManageSkillsVisible(false)}
        headerActions={() => setShowCreateSkillDialog(true)}
      />
      <EmployeeSkillsSidebar
        visible={selectedEmployee !== null}
        employeeId={selectedEmployee?.id ?? ''}
        employeeName={selectedEmployee?.name ?? ''}
        onClose={() => setSelectedEmployee(null)}
      />
      <CreateSkillDialogConnected
        show={showCreateSkillDialog}
        onClose={() => setShowCreateSkillDialog(false)}
      />
    </EngagementContainer>
  );
};
