import React from 'react';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { TicketsFilter } from './Filters/types';
import { SortingRule } from 'react-table';
import { ApolloError } from '@apollo/client';
import ServerError from '../../../../../components/ServerError';
import { Button, Card } from 'react-bootstrap';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { TicketsFiltersConnected } from './Filters/TicketsFiltersConnected';
import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  TableItem as TicketsTableRowType,
  TicketsTable,
} from './Table/TicketsTable';
import { applyTicketFilter, hasTicketFilter } from './Filters/filters';
import { TicketsListItem } from './types';
import { TicketsSidebar } from './TicketSidbar/TicketsSidebar';
import { mapTicketSidebarData } from './TicketSidbar/mappings';
import { useTicketSidebar } from './hooks/useTicketSidebar';
import { CloseTicketModal } from './CloseTicketModal/CloseTicketModal';
import { useTicketClose } from './hooks/useTicketClose';
import { NewTicketModal } from './NewTicketModal/NewTicketModal';
import { useAddTicket } from './hooks/useAddTicket';
import { OpenTicketSuccessModal } from './NewTicketModal/OpenTicketSuccessModal';

const i18Path = 'support.tickets';

type Props = {
  isManager: boolean;
  pageSize: number;
  tableStatePageIndex: number;
  tableStateFilter: TicketsFilter;
  handlePageIndexChanged: (pageIndex: number) => void;
  error: ApolloError | undefined;
  data: TicketsListItem[];
  loading: boolean;
  handleSortByChanged: (sortBy: SortingRule<TicketsListItem>[]) => void;
  tableStateSortBy: Array<SortingRule<TicketsListItem>>;
  handleFilterChange: (filter: TicketsFilter) => void;
  tableItemsTotalCount: number | undefined;
};

export const Tickets: React.FC<Props> = ({
  isManager,
  pageSize,
  data,
  tableStatePageIndex,
  tableStateFilter,
  handlePageIndexChanged,
  handleSortByChanged,
  tableStateSortBy,
  handleFilterChange,
  error,
  loading,
  tableItemsTotalCount,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const {
    isSidebarVisible,
    handleSidebarClose,
    selectedRecordId,
    handleSidebarOpen,
    sidebarResponse,
    isSidebarLoading,
    sidebarError,
    sidebarTab,
    setSidebarTab,
  } = useTicketSidebar(isManager);

  const {
    isCloseTicketModal,
    handleCloseTicketModalClose,
    handleCloseTicketModalOpen,
    submitTicketClose,
    closeTicketLoading,
  } = useTicketClose(isManager);

  const {
    setOpenTicketModalVisible,
    handleAddTicketModalClose,
    openTicketModalVisible,
    setOpenTicketSuccessModalVisible,
    openTicketSuccessModalVisible,
    modalIndex,
    openTicket,
    openTicketLoading,
    ticketPolicy,
  } = useAddTicket(isManager);

  const handleRowClick = async (row: TicketsTableRowType) => {
    handleSidebarOpen(row.id);
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    const tickets = applyTicketFilter(tableStateFilter, data);

    return (
      <TicketsTable
        pageSize={pageSize}
        tickets={tickets}
        itemsCount={tableItemsTotalCount}
        isManager={isManager}
        initialSortBy={tableStateSortBy}
        initialPageIndex={tableStatePageIndex}
        onPageIndexChanged={handlePageIndexChanged}
        onSortByChanged={handleSortByChanged}
        onClick={handleRowClick}
        hasTicketFilter={hasTicketFilter(tableStateFilter)}
      />
    );
  };

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <Card>
        <Card.Header className="h-auto">
          <TicketsFiltersConnected
            filter={tableStateFilter}
            onChange={handleFilterChange}></TicketsFiltersConnected>
        </Card.Header>
        <Card.Header>
          <div className="d-flex gap-3 justify-content-between align-items-center small">
            <div className="d-flex align-items-center">
              <SubheaderStyled className="me-2 h7-regular">
                {t('table.subHeader.title', {
                  count: tableItemsTotalCount,
                })}
              </SubheaderStyled>
            </div>
            <div className="d-flex gap-3 align-items-center justify-content-end">
              <Button
                onClick={() => setOpenTicketModalVisible(true)}
                variant="primary"
                className="d-flex align-items-center">
                <FlairIcon icon="add-outline" />
                <span className="ms-2 small">
                  {t('table.buttons.newTicket')}
                </span>
              </Button>
            </div>
          </div>
        </Card.Header>
        {renderContent()}
      </Card>
      {isSidebarVisible && (
        <TicketsSidebar
          visible={isSidebarVisible}
          onClose={handleSidebarClose}
          isManager={isManager}
          isLoading={isSidebarLoading}
          ticket={mapTicketSidebarData(sidebarResponse?.ticketById)}
          error={sidebarError}
          onTicketClose={handleCloseTicketModalOpen}
          recordId={selectedRecordId!}
          selectedTab={sidebarTab}
          setSelectedTab={setSidebarTab}
        />
      )}
      <CloseTicketModal
        visible={isCloseTicketModal}
        onClose={handleCloseTicketModalClose}
        inProgress={closeTicketLoading}
        onSubmit={submitTicketClose}
      />
      <NewTicketModal
        visible={openTicketModalVisible}
        modalIndex={modalIndex}
        isLoading={openTicketLoading}
        ticketPolicy={ticketPolicy}
        isManager={isManager}
        addTicket={openTicket}
        onClose={handleAddTicketModalClose}></NewTicketModal>
      <OpenTicketSuccessModal
        onClose={() => setOpenTicketSuccessModalVisible(false)}
        visible={openTicketSuccessModalVisible}></OpenTicketSuccessModal>
    </>
  );
};

const SubheaderStyled = styled.span`
  color: ${Theme.color.gray2};
`;

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5} />;
};
