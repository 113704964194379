import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useChangeEmployeeEmailMutation } from '../../__generated__/graphql';
import { routes } from '../../routes';
import ServerError from '../../../../components/ServerError';
import { useIsLoggedIn } from '../../../../context/AuthContext';
import { root as dashboardRoot } from '../../../dashboard/routes';
import { useEffect } from 'react';

type RouteParams = {
  organizationId: string;
};

const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border" role="status" />
  </div>
);

const ChangeEmail: React.FC = () => {
  const history = useHistory();
  const { organizationId } = useParams<RouteParams>();
  const queryString = new URLSearchParams(useLocation().search);
  const token = queryString.get('token') || 'invalidToken';
  const [changeEmployeeEmail, { data, loading, error }] =
    useChangeEmployeeEmailMutation({});

  useEffect(() => {
    changeEmployeeEmail({
      variables: { organizationId, token },
    });
  }, [changeEmployeeEmail, organizationId, token]);
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (data) {
      const redirectToRoute = isLoggedIn ? dashboardRoot : routes.login;
      if (data.changeEmployeeEmail.hasEmailChanged) {
        history.push(redirectToRoute.route.create({}), {
          alertMessage: 'change-email',
        });
      } else {
        history.push(redirectToRoute.route.create({}));
      }
    }
  }, [data, history, isLoggedIn]);
  if (loading) return <Loading />;
  if (error) return <ServerError />;
  return <></>;
};

export default ChangeEmail;
