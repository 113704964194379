import { useArchiveAllInboxNotificationsMutation } from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';

export const useArchiveAllInboxNotifications = () => {
  const { id: meId } = useUserInfo();

  return useArchiveAllInboxNotificationsMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const recordIdsModified =
        response.data.notifications.archiveAllInboxNotifications.recordIds;
      if (!recordIdsModified || recordIdsModified.length === 0) {
        return;
      }

      const meCacheId: string | undefined = cache.identify({
        __typename: 'Me',
        Id: meId,
      });

      // uncache archive notifications
      cache.evict({
        id: meCacheId,
        fieldName: 'archivedNotifications',
      });
      cache.gc();

      // remove from inbox notifications
      cache.modify({
        id: meCacheId,
        fields: {
          inboxNotifications: (existing) => {
            return {
              ...existing,
              items: [],
              unreadCount: 0,
              paginationInfo: {
                ...existing.paginationInfo,
                totalCount: 0,
              },
            };
          },
        },
      });
    },
    optimisticResponse: () => {
      return {
        notifications: {
          archiveAllInboxNotifications: {
            recordIds: [],
            __typename: 'AllNotificationsActionPayload',
          },
        },
      };
    },
  });
};
