import React from 'react';
import { useTranslation } from 'react-i18next';
import FormattedDate, {
  DateFormat,
} from '../../../../../components/date/FormattedDate';

type Props = {
  day: string;
  halfDay: boolean;
  format?: DateFormat;
};

const HalfDay: React.FC<{ show: boolean }> = ({ show }) => {
  const { t } = useTranslation();

  return show ? <span> ({t('absences.table.halfDay')})</span> : <></>;
};

const FormattedAbsenceDate: React.FC<Props> = ({
  day,
  halfDay,
  format = 'long',
}) => (
  <>
    <FormattedDate format={format} day={day}></FormattedDate>
    <HalfDay show={halfDay} />
  </>
);

export default FormattedAbsenceDate;
