import * as React from 'react';

const HourGlassOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M8 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V3.25C0.000337806 3.40519 0.0366312 3.55818 0.106032 3.69698C0.175434 3.83579 0.276055 3.95662 0.4 4.05L3.66687 6.5L0.4 8.95C0.276055 9.04338 0.175434 9.16421 0.106032 9.30302C0.0366312 9.44182 0.000337806 9.59481 0 9.75V12C0 12.2652 0.105357 12.5196 0.292893 12.7071C0.48043 12.8946 0.734784 13 1 13H8C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12V9.7725C8.99964 9.61791 8.96365 9.46548 8.89482 9.32706C8.82599 9.18864 8.72617 9.06795 8.60312 8.97437L5.32938 6.5L8.60312 4.025C8.72623 3.93162 8.8261 3.81105 8.89494 3.67272C8.96378 3.53439 8.99974 3.38202 9 3.2275V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0ZM8 1V2H1V1H8ZM8 12H1V9.75L4.5 7.125L8 9.77188V12ZM4.5 5.875L1 3.25V3H8V3.2275L4.5 5.875Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default HourGlassOutlineIcon;
