import i18next from 'i18next';
import { useToasts } from '../../../../context/Toast';
import {
  TimeTrackingErrorFragment,
  TimeTrackingWarningFragment,
} from '../../__generated__/graphql';
import { formattedDuration } from '../../components/DurationFormat';
import { useCallback } from 'react';

type Params = {
  errors?: ReadonlyArray<TimeTrackingErrorFragment>;
  warnings?: ReadonlyArray<TimeTrackingWarningFragment>;
};

const i18ErrorsPrefix = 'timeTracking.timeTrackingErrors';
const i18WarningsPrefix = 'timeTracking.timeTrackingWarnings';

export const useTimeTrackingErrorsAndWarnings = () => {
  const { addError, addWarning } = useToasts();
  const formatDuration = formattedDuration(i18next.t);

  const formatErrorText = useCallback(
    (error: TimeTrackingErrorFragment): string => {
      switch (error.__typename) {
        case 'TimeLimitErrorMaxDailyLimitExceeded':
          if (error.allowedTimeInMin > 0) {
            return i18next.t(`${i18ErrorsPrefix}.maxDailyLimit`, {
              allowedDailyTime: formatDuration(error.allowedTimeInMin),
              interpolation: { escapeValue: false },
            });
          } else {
            return i18next.t(`${i18ErrorsPrefix}.timeLimitTrackingNotAllowed`);
          }
        case 'TimeTrackingError':
        default:
          return error.message;
      }
    },
    [formatDuration],
  );

  const formatWarningText = useCallback(
    (warning: TimeTrackingWarningFragment): string => {
      if (warning.__typename === undefined) {
        return '';
      }
      switch (warning.__typename) {
        case 'TimeLimitWarningMaxDailyLimit':
          const translationKey = `${i18WarningsPrefix}.maxDailyLimit.${
            warning.autoClockOut ? 'textWithAutoClock' : 'textWithoutAutoClock'
          }`;
          return i18next.t(translationKey, {
            allowedDailyTime: formatDuration(warning.allowedTimeInMin),
          });
      }
    },
    [formatDuration],
  );

  const showErrorsAndWarningsToasts = useCallback(
    ({ errors, warnings }: Params) => {
      if (errors) {
        errors.forEach((error) => addError(formatErrorText(error)));
      }
      if (warnings) {
        warnings.forEach((warning) =>
          addWarning(formatWarningText(warning), {
            autoDismissTimeout: 10 * 1000, // 10 sec
          }),
        );
      }
    },
    [addError, addWarning, formatErrorText, formatWarningText],
  );

  return { showErrorsAndWarningsToasts };
};
