import { addMinutes } from 'date-fns';
import _ from 'lodash';
import {
  getIntervalInMiddle,
  durationMinutes,
  Gap,
  getGapMinutes,
} from '../../../../../../utils/dateIntervalUtils';
import { BreakLegislationRule } from '../types';
import {
  AdjustBreakResult,
  AdjustTimeEntryBreakRequest,
  AdjustTimeEntryRequest,
  AdjustTimeEntryResult,
  AutoBreaksCalculationService,
  AutoBreaksRules,
  TimeEntryStatsByLaw,
  DateInterval,
} from './types';

// Implementation from sfdx-source/flair/main/default/classes/AutoBreaksCalculationService.cls

export class AutoBreaksCalculationServiceSimple
  implements AutoBreaksCalculationService
{
  adjustBreaks(
    rules: AutoBreaksRules,
    request: AdjustTimeEntryRequest,
  ): AdjustTimeEntryResult {
    if (request.breaks.length === 0) {
      return this.buildNewBreaksBasedOnRules(rules, request);
    }
    return this.adjustExistingBreak(rules, request);
  }

  getStats(
    rules: AutoBreaksRules,
    request: AdjustTimeEntryRequest,
  ): TimeEntryStatsByLaw {
    let { deficit, required } = this.getBreakDificit(rules, request);

    const breakingMunites = getTotalBreakingTimeWithoutBreaksLessThenMinimum(
      rules.minumumBreakDuration,
      request.breaks,
    );
    return {
      breakingMinutesUnusedByLaw: deficit,
      breakingMinutesCountedByLaw: breakingMunites,
      breakingMinutesRequiredByLaw: required,
    };
  }

  adjustExistingBreak(
    rules: AutoBreaksRules,
    request: AdjustTimeEntryRequest,
  ): AdjustTimeEntryResult {
    let { deficit: breakDurationToAdd } = this.getBreakDificit(rules, request);
    if (breakDurationToAdd === 0) {
      // we do not have to create a break
      return {
        nonComplientReason: null,
        breaks: [],
      };
    }
    const longestBreak = this.getLongestBreak(request);

    // we should reduce longestBreak period if it less than match minBreakDuration
    if (durationMinutes(longestBreak) < rules.minumumBreakDuration) {
      breakDurationToAdd -= durationMinutes(longestBreak);
    }

    if (breakDurationToAdd <= 0) {
      return {
        nonComplientReason: null,
        breaks: [...request.breaks],
      };
    }

    const adjustedBreak = this.adjustSingleBreak(
      request,
      longestBreak,
      breakDurationToAdd,
    );
    if (adjustedBreak === 'NOT_ENOUGH_GAPS_BETWEEN_BREAKS') {
      return {
        nonComplientReason: 'NOT_ENOUGH_GAPS_BETWEEN_BREAKS',
        breaks: [],
      };
    }
    return {
      nonComplientReason: null,
      breaks: request.breaks.map((curBreak) =>
        curBreak.breakId === adjustedBreak.breakId ? adjustedBreak : curBreak,
      ),
    };
  }

  getBreakGap(
    request: AdjustTimeEntryRequest,
    targetBreak: AdjustTimeEntryBreakRequest,
  ): Gap {
    const index = request.breaks.indexOf(targetBreak);
    const gaps = getGapMinutes(request.start, request.end, request.breaks);
    return gaps[index];
  }

  buildNewBreaksBasedOnRules(
    rules: AutoBreaksRules,
    request: AdjustTimeEntryRequest,
  ): AdjustTimeEntryResult {
    let { deficit } = this.getBreakDificit(rules, request);

    if (deficit === 0) {
      // we do not have to create a break
      return {
        nonComplientReason: null,
        breaks: [],
      };
    }

    const intervalToCreate = getIntervalInMiddle(request, deficit);
    if (intervalToCreate === null) {
      return {
        nonComplientReason: 'NOT_ENOUGH_WORKING_TIME',
        breaks: [],
      };
    }
    return {
      nonComplientReason: null,
      breaks: [
        {
          start: intervalToCreate.start,
          end: intervalToCreate.end,
          breakId: null,
          isAutoAdjusted: true,
        },
      ],
    };
  }

  getBreakDificit(
    rules: AutoBreaksRules,
    request: AdjustTimeEntryRequest,
  ): { deficit: number; required: number } {
    const { legislationRules } = rules;
    const workingTime = getTotalWorkingTimeWithoutBreaks(request);
    const breakingTime = getTotalBreakingTimeWithoutBreaksLessThenMinimum(
      rules.minumumBreakDuration,
      request.breaks,
    );

    if (rules.deductPartially) {
      return this.calculateBreakDificitWithDeductPartial(
        legislationRules,
        workingTime,
        breakingTime,
      );
    } else {
      return this.calculateBreakDificit(
        legislationRules,
        workingTime,
        breakingTime,
      );
    }
  }

  calculateBreakDificit(
    legislationRules: BreakLegislationRule[],
    workingTimeWithoutBreaks: number,
    breakingTime: number,
  ): { deficit: number; required: number } {
    const matchedRule = this.getMatchedRule(
      legislationRules,
      workingTimeWithoutBreaks - breakingTime,
    );
    return {
      deficit: matchedRule ? matchedRule.breakDuration - breakingTime : 0,
      required: matchedRule ? matchedRule.breakDuration : 0,
    };
  }

  calculateBreakDificitWithDeductPartial(
    legislationRules: BreakLegislationRule[],
    workingTimeWithoutBreaks: number,
    breakingTime: number,
  ): { deficit: number; required: number } {
    let deficit = 0;
    const initialPureWorkingTime = workingTimeWithoutBreaks - breakingTime;
    let lastMatchedRule: BreakLegislationRule | null = null;

    while (true) {
      const matchedRule = this.getMatchedRule(
        legislationRules,
        initialPureWorkingTime - deficit,
      );
      if (matchedRule !== null) {
        lastMatchedRule = matchedRule;
      }
      if (matchedRule == null) {
        return {
          deficit,
          required: this.calculateRequiredTime(
            lastMatchedRule,
            deficit,
            breakingTime,
          ),
        };
      }
      const pureWorkingTime = initialPureWorkingTime - deficit;
      let workingTimeToReduceToReachNextThreshold = Math.max(
        0,
        pureWorkingTime - matchedRule.workingTime,
      );
      const breakDificitFromRule = Math.max(
        0,
        matchedRule.breakDuration - breakingTime - deficit,
      );
      const amountToAdd =
        matchedRule.workingTime > 0
          ? Math.min(
              workingTimeToReduceToReachNextThreshold,
              breakDificitFromRule,
            )
          : breakDificitFromRule; // edge case when rule.workingTime == 0
      if (amountToAdd <= 0) {
        // nothing to do, we can exit
        return {
          deficit,
          required: this.calculateRequiredTime(
            lastMatchedRule,
            deficit,
            breakingTime,
          ),
        };
      }
      deficit += amountToAdd;
    }
  }

  private calculateRequiredTime(
    lastMatchedRule: BreakLegislationRule | null,
    deficit: number,
    breakingTime: number,
  ): number {
    if (lastMatchedRule === null) {
      // if we do not much any rule, no time required
      return 0;
    }
    const required = Math.min(
      deficit + breakingTime,
      lastMatchedRule.breakDuration,
    );
    return Math.max(deficit, required);
  }

  getBreakDurationFromRules(
    legislationRules: BreakLegislationRule[],
    workingTime: number,
  ): number {
    const info = this.getMatchedRule(legislationRules, workingTime);
    return info ? info.breakDuration : 0;
  }

  getMatchedRule(
    legislationRules: BreakLegislationRule[],
    workingTime: number,
  ): BreakLegislationRule | null {
    const sortedRules = _.orderBy(legislationRules, (x) => x.workingTime);
    for (let i = sortedRules.length - 1; i >= 0; i--) {
      if (workingTime > sortedRules[i].workingTime) {
        return sortedRules[i];
      }
    }
    return null;
  }

  getLongestBreak(
    request: AdjustTimeEntryRequest,
  ): AdjustTimeEntryBreakRequest {
    if (request.breaks.length === 0) {
      throw new Error('Request must have at least one break');
    }
    if (request.breaks.length === 1) {
      return request.breaks[0];
    }
    return request.breaks.reduce((res, curBreak) => {
      return durationMinutes(curBreak) > durationMinutes(res) ? curBreak : res;
    }, request.breaks[0]);
  }

  adjustSingleBreak(
    request: AdjustTimeEntryRequest,
    targetBreak: AdjustTimeEntryBreakRequest,
    minutesToAdjust: number,
  ): AdjustBreakResult | 'NOT_ENOUGH_GAPS_BETWEEN_BREAKS' {
    let adjustedMinutes = 0;
    let addMinutesRight = 0;
    let addMinutesLeft = 0;
    const breakGap = this.getBreakGap(request, targetBreak);
    if (breakGap.right > 0) {
      addMinutesRight = Math.min(
        breakGap.right,
        minutesToAdjust - adjustedMinutes,
      );
      adjustedMinutes += addMinutesRight;
    }
    if (breakGap.left > 0) {
      addMinutesLeft = Math.min(
        breakGap.left,
        minutesToAdjust - adjustedMinutes,
      );
      adjustedMinutes += addMinutesLeft;
    }
    if (minutesToAdjust - adjustedMinutes > 0) {
      // we can't manage to adjust
      return 'NOT_ENOUGH_GAPS_BETWEEN_BREAKS';
    }
    const adjustedBreak: AdjustBreakResult = {
      start: addMinutes(targetBreak.start, -addMinutesLeft),
      end: addMinutes(targetBreak.end, addMinutesRight),
      breakId: targetBreak.breakId,
      isAutoAdjusted: true,
    };
    return adjustedBreak;
  }
}

function getTotalWorkingTimeWithoutBreaks(request: DateInterval): number {
  return durationMinutes(request);
}

function getTotalBreakingTimeWithoutBreaksLessThenMinimum(
  minumumBreakDuration: number,
  breaks: DateInterval[],
): number {
  return breaks
    .map(durationMinutes)
    .filter((breakDuration) => breakDuration >= minumumBreakDuration)
    .reduce((acc, cur) => acc + cur, 0);
}
