import styled from '@emotion/styled';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import EmptyStateImg from '../../../../vendor/img/empty-state.png';

type Props = {
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  img?: string;
};

const Image = styled.img`
  max-width: 270px;
`;

export const EmptyState: React.FC<Props> = ({
  title,
  subtitle,
  action,
  img,
}) => {
  return (
    <>
      <Image
        src={img ?? EmptyStateImg}
        alt={title}
        className="img-fluid mb-4"
      />

      {title && <p className="mb-2">{title}</p>}

      {subtitle && <p className="text-muted">{subtitle}</p>}

      {action}
    </>
  );
};

export const EmptyStateCardBody: React.FC<Props> = (props) => (
  <Card.Body className="text-center">
    <Row className="justify-content-center">
      <Col>
        <EmptyState {...props} />
      </Col>
    </Row>
  </Card.Body>
);

const EmptyStateCard: React.FC<Props> = (props) => (
  <Card>
    <EmptyStateCardBody {...props} />
  </Card>
);

export default EmptyStateCard;
