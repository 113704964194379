import React from 'react';
import styled from '@emotion/styled';
import { Column, CellProps } from 'react-table';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { AbsenceTypeMap } from './types';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import AbsenceCategoryIcon from '../../../components/AbsenceCategoryIcon';
import AbsenceStatus from '../../../pages/Absences/components/AbsenceStatus';
import Avatar from '../../../pages/Absences/components/Avatar';
import AbsenceDateTimeRange from '../../../pages/Absences/components/AbsenceDateTimeRange';
import { routes } from '../../../routes';
import { EmployeeWithAvatar } from '../../../components/Employee';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { useApproveAbsenceRequestTable } from '../useAbsencesRequestsTable';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Link } from '../../../../../Router';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { LoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';

import { AbsenceRequest } from '../types';
import { MOBILE_BREAKPOINT } from '../common';
import { ReviewActionsCell } from '../ReviewActionsCell';

const i18Path = 'absences.myAbsences.table';

export const useAbsenceRequestTableColumns = (
  setSelectedLoomVideo: (loomVideo: Maybe<LoomVideo>) => void,
) => {
  const t = useNamespacedTranslation(i18Path);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MOBILE_BREAKPOINT;

  const {
    isAccepting,
    isRejecting,
    isInProgress,
    handleAccept,
    handleReject,
    handleRejectButtonClick,
    onModalClose,
    rejectingModalId,
  } = useApproveAbsenceRequestTable();

  const reviewCell = (
    props: React.PropsWithChildren<CellProps<AbsenceRequest, string>>,
  ) => {
    if (props.row.original.approvalStatus === 'PENDING') {
      return (
        <ReviewActionsCell
          id={props.row.original.id}
          isAccepting={isAccepting}
          isRejecting={isRejecting}
          isInProgress={isInProgress}
          handleAccept={handleAccept}
          handleRejectButtonClick={handleRejectButtonClick}
        />
      );
    } else {
      const row = props.row.original;
      const reviewedRequest = row.approvalRequests.find((item) =>
        ['APPROVED', 'REJECTED'].includes(item.approvalStatus),
      );
      const avatar = reviewedRequest?.approver.avatar;
      const firstName = reviewedRequest?.approver.firstName;
      const lastName = reviewedRequest?.approver.lastName;
      const initials = `${firstName?.[0]}${lastName?.[0]}`;
      const fullName = reviewedRequest?.approver.name;
      return (
        <div className="d-flex align-items-center gap-2 ps-3">
          <Avatar
            avatar={avatar}
            initials={initials}
            fullName={fullName || ''}
          />
          <FormattedDate day={row.lastModifiedDate} format="short" />
        </div>
      );
    }
  };

  const desktopColumns: Column<AbsenceRequest>[] = [
    {
      Header: t('employee'),
      accessor: 'requester',
      Cell: (props) => (
        <Link
          to={routes.absenceRequestToMe.route}
          absenceRequestId={props.row.original.id}>
          <EmployeeWithAvatar employee={props.row.original.requester} />
        </Link>
      ),
    },
    {
      Header: t('duration'),
      accessor: 'startDate',
      Cell: (props) => (
        <Link
          to={routes.absenceRequestToMe.route}
          absenceRequestId={props.row.original.id}>
          <AbsenceDateTimeRange absence={props.row.original} />
          {props.row.original.comment && (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip`}>{props.row.original.comment}</Tooltip>
              }>
              <span>
                <FlairIcon
                  role="button"
                  className={'ms-2 cursor-auto'}
                  icon="chatbubble-outline"
                />
              </span>
            </OverlayTrigger>
          )}
          {props.row.original.attachments.length > 0 && (
            <span>
              <FlairIcon
                role="button"
                className={'ms-2'}
                icon="document-outline"
              />
            </span>
          )}
        </Link>
      ),
    },
    {
      Header: t('type'),
      accessor: 'categoryName',
      Cell: (props) => (
        <div className="d-flex gap-2">
          <AbsenceCategoryIcon icon={props.row.original.categoryIcon} />
          <span>{props.row.original.categoryName}</span>
        </div>
      ),
    },
    {
      Header: t('workingTime'),
      accessor: 'workingAmount',
      Cell: (props) =>
        `${props.row.original.workingAmount} ${
          AbsenceTypeMap[props.row.original.type]
        }`,
    },
    {
      Header: t('calendarTime'),
      accessor: 'amount',
      Cell: (props) =>
        `${props.row.original.amount} ${
          AbsenceTypeMap[props.row.original.type]
        }`,
    },
    {
      Header: t('comments'),
      accessor: 'recordId',
      Cell: ({ value, row }) => (
        <CommentsPopover
          recordId={value}
          relatedObjectName={RelatedObjectNames.Absence}
          commentsCount={row.original.commentsCount}
          mode="icon"
        />
      ),
    },
    {
      Header: t('loom'),
      accessor: 'loomVideo',
      Cell: ({ value }) => (
        <div className="pt-2">
          {value ? (
            <FlairIcon
              icon="loom"
              className="text-primary"
              onClick={() => setSelectedLoomVideo(value)}
            />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: t('status'),
      accessor: 'approvalStatus',
      Cell: (props) => {
        const rejectedRequest = props.row.original.approvalRequests.find(
          (req) => req.approvalStatus === 'REJECTED',
        );
        const hasRejectionComment =
          rejectedRequest && rejectedRequest.approverComment;
        return (
          <div className="d-flex gap-1 align-items-center">
            <AbsenceStatus status={props.row.original.approvalStatus} />
            {hasRejectionComment && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip">
                    {rejectedRequest.approverComment}
                  </Tooltip>
                }>
                <span>
                  <FlairIcon
                    role="button"
                    className={'ms-2 cursor-auto text-primary'}
                    icon="chatbubble-outline"
                  />
                </span>
              </OverlayTrigger>
            )}
          </div>
        );
      },
    },
    {
      Header: t('requestedAt'),
      accessor: 'createdDate',
      Cell: (props) => (
        <FormattedDateTime
          dateTime={props.row.original.createdDate}
          format="long"
        />
      ),
    },
    {
      Header: t('review'),
      disableSortBy: true,
      accessor: 'absenceId',
      Cell: reviewCell,
    },
  ];

  const mobileColumns: Column<AbsenceRequest>[] = [
    {
      Header: t('employee'),
      accessor: 'id',
      Cell: (props) => (
        <Col>
          <Link
            to={routes.absenceRequestToMe.route}
            absenceRequestId={props.row.original.id}>
            <EmployeeWithAvatar employee={props.row.original.requester} />
          </Link>
          <Detail>
            <Link
              to={routes.absenceRequestToMe.route}
              absenceRequestId={props.row.original.id}>
              <AbsenceDateTimeRange absence={props.row.original} />
            </Link>
            <div>
              <span className="me-1">
                {t('myAbsences.table.mobile.workingTime')}
              </span>
              {`${props.row.original.workingAmount} ${
                AbsenceTypeMap[props.row.original.type]
              }`}
            </div>
            <div>
              <span className="me-1">{t('mobile.type')}</span>
              {props.row.original.categoryName}
            </div>
            <div>
              <span className="me-1">{t('mobile.requestedAt')}</span>
              <FormattedDateTime
                dateTime={props.row.original.createdDate}
                format="long"
              />
            </div>
          </Detail>
        </Col>
      ),
    },
    {
      Header: t('review'),
      accessor: 'absenceId',
      Cell: reviewCell,
    },
  ];

  const columns = isMobile ? mobileColumns : desktopColumns;
  return { columns, rejectingModalId, onModalClose, handleReject };
};

const Detail = styled.div({
  marginLeft: 32,
});
