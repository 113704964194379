import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { CelebrationCreateModal } from '../../../Celebrations/createOrEditCelebration/CelebrationCreateModal';
import { useState } from 'react';
import FlairButton from '../../../../../../atomic/atoms/FlairButton';
import { routes } from '../../../../routes';
import { useHistory } from 'react-router-dom';

const i18Path = 'dashboard.profileWidget';

const CheerCoworkers: React.FC = () => {
  const history = useHistory();
  const t = useNamespacedTranslation(i18Path);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const onClick = () => {
    setCreateModalVisible(true);
  };

  const handleOnSuccess = () =>
    history.push(routes.celebrationsFeed.route.create({}));

  return (
    <>
      <FlairButton
        label={t('cheerCoworkers')}
        icon="confetti"
        onClick={onClick}
      />
      <CelebrationCreateModal
        show={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
        onSuccess={handleOnSuccess}
      />
    </>
  );
};

export default CheerCoworkers;
