import React from 'react';
import './RatingCard.css';

type RatingCardProps = {
  value: string;
  selected?: boolean;
  disabled?: boolean;
  handleChange: (value: string) => void;
};
export const RatingCard: React.FC<RatingCardProps> = ({
  value,
  selected,
  disabled,
  handleChange,
}) => {
  let classNames = {
    container: 'rating-card-container',
    value: 'rating-card-value',
  };
  if (disabled) {
    classNames.container = 'rating-card-container disabled';
    classNames.value = 'rating-card-value disabled';
  }

  if (selected) {
    classNames.container = 'rating-card-container selected';
    classNames.value = 'rating-card-value selected';
  }

  return (
    <div className={classNames.container} onClick={() => handleChange(value)}>
      <div className={classNames.value}>{value}</div>
    </div>
  );
};
