import { endOfISOWeek, startOfISOWeek, startOfMonth } from 'date-fns';
import { parseDate, changePeriodByUnit } from '../../../../../utils/dateUtils';
import { DateInterval } from '../../../../../utils/dateIntervalUtils';

export const getDefaultInterval = (now: Date): DateInterval => {
  const currentDate = parseDate(now);

  const fromDateMonth = startOfMonth(currentDate);
  const fromDateWeek = startOfISOWeek(currentDate);
  const start = fromDateMonth < fromDateWeek ? fromDateWeek : fromDateMonth;

  return {
    start,
    end: endOfISOWeek(currentDate),
  };
};

export const getIntervalByParams = (
  now: Date,
  dateParam: string | null,
  workloadParam: string | null,
) => {
  if (!dateParam) {
    return getDefaultInterval(now);
  }

  const date = parseDate(dateParam);

  const { start, end } = changePeriodByUnit(date, {
    unit: workloadParam === 'Monthly' ? 'month' : 'week',
  });

  return {
    start,
    end,
  };
};
