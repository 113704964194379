import classnames from 'classnames';
import * as React from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/button/index';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { GoalType } from '../../../../__generated__/graphql';
import { GoalBase, GoalsFilterType } from '../types';
import { StatusStackedBarChart } from './StatusStackedBarChart';

type Option = {
  label: string;
  value: string;
};

export type GoalFilter = {
  filterType: GoalsFilterType;
  tagId: string | null;
  periodId: string | null;
  employeeId: string | null;
  locationId: string | null;
  goalType: GoalType | null;
};

type Props = {
  filterOptions: Option[];
  tagsOptions: Option[];
  periodsOptions: Option[];
  employeeOptions: Option[];
  goalTypeOptions: Option[];
  goals: GoalBase[];
  onCreateGoal: () => void;
  onClearFilter: () => void;
  onChange: (filter: GoalFilter) => void;
  filter: GoalFilter;
  isCreateButtonVisible: boolean;
  showEmployeeFilter: boolean;
};

const i18prefix = 'performanceReview.goals2.goalsFilter';

export const GoalsFilter: React.FC<Props> = ({
  filterOptions,
  goals,
  tagsOptions,
  periodsOptions,
  employeeOptions,
  goalTypeOptions,
  onCreateGoal,
  onClearFilter,
  onChange,
  filter,
  isCreateButtonVisible,
  showEmployeeFilter,
}) => {
  const { t } = useTranslation();

  const buildGoalsDropdown = () => (
    <Dropdown
      onSelect={(value) => {
        if (value && value !== filter.filterType) {
          onChange({ ...filter, filterType: value as GoalsFilterType });
        }
      }}>
      <Dropdown.Toggle
        variant="link"
        className={classnames('p-0', 'fs-2', 'fw-bold', 'text-black')}>
        {filterOptions.find((x) => x.value === filter.filterType)?.label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterOptions.map((option) => (
          <Dropdown.Item
            key={option.value}
            eventKey={option.value}
            active={option.value === filter.filterType}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const buildFilters = () => {
    return (
      <Row className={classnames('align-items-center', 'row-cols-auto', 'g-3')}>
        <Col>
          <span>{t(`${i18prefix}.filterBy`)}</span>
        </Col>
        <Col>
          <SingleFilter
            placeholder={t(`${i18prefix}.tags`)}
            options={tagsOptions}
            value={filter.tagId}
            onChange={(value) => {
              return onChange({
                ...filter,
                tagId: value,
              });
            }}
          />
        </Col>
        <Col>
          <SingleFilter
            placeholder={t(`${i18prefix}.period`)}
            options={periodsOptions}
            value={filter.periodId}
            onChange={(value) =>
              onChange({
                ...filter,
                periodId: value,
              })
            }
          />
        </Col>
        {showEmployeeFilter && (
          <Col>
            <SingleFilter
              placeholder={t(`${i18prefix}.employee`)}
              options={employeeOptions}
              isSearchable={true}
              value={filter.employeeId}
              onChange={(value) =>
                onChange({
                  ...filter,
                  employeeId: value,
                })
              }
            />
          </Col>
        )}
        <Col>
          <SingleFilter
            placeholder={t(`${i18prefix}.goalType`)}
            options={goalTypeOptions}
            isSearchable={true}
            value={filter.goalType}
            onChange={(value) =>
              onChange({
                ...filter,
                goalType: value as GoalType,
              })
            }
          />
        </Col>
        <Col>
          <Button
            variant="link"
            onClick={onClearFilter}
            label={t(`${i18prefix}.clearFilters`)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row
          className={classnames(
            'justify-content-md-between',
            'align-content-between',
          )}>
          <Col sm={8}>
            <Row
              className={classnames(
                'justify-content-between',
                'align-items-center',
                'fw-bold',
              )}>
              <Col>{buildGoalsDropdown()}</Col>
              <Col xs="auto">
                <span>
                  {goals.length +
                    ' ' +
                    t('performanceReview.goals2.goal', { count: goals.length })}
                </span>
              </Col>
            </Row>
            <Row className={classnames('py-4')}>
              <Col>
                <StatusStackedBarChart goals={goals} />
              </Col>
            </Row>
            <Row>
              <Col>{buildFilters()}</Col>
            </Row>
          </Col>
          <Col sm={4}>
            <div
              className={classnames(
                'd-flex',
                'flex-column-reverse',
                'flex-sm-column',
                'align-items-end',
                'justify-content-between',
                'h-100',
              )}>
              {isCreateButtonVisible && (
                <Button
                  variant="primary"
                  onClick={onCreateGoal}
                  label={t(`${i18prefix}.createGoal`)}
                />
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
