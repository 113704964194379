import * as React from 'react';
import HeroImage from './HeroImage';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import ProfileWidget from './ProfileWidget';
import { Col, Row } from 'react-bootstrap';
import './Dashboard.css';
import OnboardingWidget from './OnboardingWidget';
import UpcomingAbsencesWidget from './UpcomingAbsencesWidget';
import PublicHolidaysWidget from './PublicHolidaysWidget';
import CompensatoryTimeWidget from './CompensatoryTimeWidget';
import AnniversariesWidget from './AnniversariesWidget';
import { DashboardContext, DashboardProvider } from './context';
import { useContext } from 'react';
import { EmployeeInfoSidebar } from '../../components/EmployeeInfoSidebar';
import BirthdayWidget from './BirthdayWidget';
import EndOfProbationWidget from './EndOfProbationWidget';
import LastUpdatesWidget from './LastUpdatesWidget';
import AbsentTodayWidget from './AbsentTodayWidget';
import TimeTrackingWidget from './TimeTrackingWidget';
import NextMeetingsWidget from './NextMeetingsWidget';
import EngagementSurveyNotificationCard from '../EngagementSurvey/EngagementSurveyNotificationCard';
import { useUserInfo } from '../../context/UserInfo';
import { useServerFeatures } from '../../../../hooks/useServerFeature';
import EnpsSurveys from '../Home/EnpsSurveys';
import CheckinSurveys from '../Home/CheckinSurveys';

const i18Path = 'navigation.menuItems.dashboard';

const Dashboard: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  useFlairBreadcrumbHook([{ label: t('title') }]);

  return (
    <DashboardProvider>
      <Content />
    </DashboardProvider>
  );
};

const Content: React.FC = () => {
  const { selectedEmployee, setSelectedEmployee } =
    useContext(DashboardContext);
  const { isActive } = useUserInfo();
  const isFeatureVisible = useServerFeatures();

  const shouldRenderSurveys = isActive && isFeatureVisible('Surveys');
  const isBirthdaysVisible =
    isFeatureVisible('Home_Birthdays_Card') && isActive;
  const isWorkflowsVisible = isFeatureVisible('Workflows');
  const isTimeTrackingVisible = isActive && isFeatureVisible('Time_Tracking');
  const isAbsencesVisible = isActive && isFeatureVisible('Absences');
  const isAbsentTodayVisible =
    isActive && isFeatureVisible('Home_Absent_Today');
  const isAssessmentsVisible = isActive && isFeatureVisible('Assessments');
  const isAnniversariesVisible = isActive && isFeatureVisible('Anniversaries');
  const isPublicHolidaysVisible =
    isActive && isFeatureVisible('Public_Holidays');

  return (
    <div className="vh-100" data-testid="DashboardPage">
      <HeroImage />
      <Row className="mt-3 pb-5 justify-content-center">
        <Col className="main-column px-2-5">
          <ProfileWidget />
          {shouldRenderSurveys && (
            <>
              <EngagementSurveyNotificationCard />
              <EnpsSurveys />
              <CheckinSurveys />
            </>
          )}
          {isActive && <LastUpdatesWidget />}
        </Col>
        <Col className="secondary-column px-2-5">
          {isWorkflowsVisible && <OnboardingWidget />}
          {isAbsencesVisible && <UpcomingAbsencesWidget />}
          {isTimeTrackingVisible && <TimeTrackingWidget />}
          {isAssessmentsVisible && <NextMeetingsWidget />}
          {isActive && <CompensatoryTimeWidget />}
          {isPublicHolidaysVisible && <PublicHolidaysWidget />}
          {isAbsentTodayVisible && <AbsentTodayWidget />}
          {isBirthdaysVisible && <BirthdayWidget />}
          {isAnniversariesVisible && <AnniversariesWidget />}
          {isActive && <EndOfProbationWidget />}
        </Col>
      </Row>
      <EmployeeInfoSidebar
        show={!!selectedEmployee}
        onClose={() => setSelectedEmployee(null)}
        employee={selectedEmployee}
      />
    </div>
  );
};

export default Dashboard;
