import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import { Col, FormCheck, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup } from './FormGroup';
import InputError from './InputError';
import InputLabel from './InputLabel';
import ScrollToError from './ScrollToError';
import { getError } from './utils';

type InputType = 'checkbox';

type Props = InputProps;

type InputProps = {
  label?: string;
  name: string;
  placeholder?: string;
  type?: InputType;
  children?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  labelComponent?: ReactNode;
  autoFocus?: boolean;
  size?: 'sm' | 'lg';
  className?: string;
  hint?: ReactNode;
  onChange?: (value: string) => void;
};

type ControlledInputProps = Omit<
  InputProps,
  'label' | 'labelComponent' | 'required'
>;

export const ControlledCheckboxInput: React.FC<ControlledInputProps> = ({
  name,
  type,
  disabled,
  className,
  onChange,
  ...rest
}) => {
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext();

  const handleChange = useCallback(
    ([event]) => {
      onChange && onChange(event.target.checked);
      return event.target.checked;
    },
    [onChange],
  );

  const error = getError(name, errors);

  return (
    <>
      <Controller
        as={
          <FormCheck.Input
            disabled={disabled}
            isInvalid={!!error}
            readOnly={isSubmitting}
            type="checkbox"
          />
        }
        onChange={handleChange}
        name={name}
        control={control}
        {...rest}
      />
    </>
  );
};

export const CheckboxInput: React.FC<Props> = ({
  label,
  name,
  required,
  hint,
  labelComponent,
  ...rest
}) => {
  const ref = useRef(null);
  const { errors } = useFormContext();

  const error = useMemo(() => getError(name, errors), [name, errors]);

  return React.useMemo(
    () => (
      <FormGroup ref={ref}>
        <Row>
          <Col>
            <InputLabel
              label={label}
              required={required}
              hint={hint}
              className="form-check-label"
            />
          </Col>

          <Col className="col-auto">
            <ControlledCheckboxInput name={name} {...rest} />
          </Col>
        </Row>
        {error && (
          <Row>
            <Col>
              <InputError error={error} className="d-block" />
            </Col>
          </Row>
        )}
        <ScrollToError name={name} inputContainerRef={ref} />
      </FormGroup>
    ),
    [label, name, required, rest, ref, hint, error],
  );
};
