import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';

type Props = {
  provider: string;
};

const LoginWithSaml: React.FC<Props> = ({ provider }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="display-4 text-center mb-3">{t('signInSaml.title')}</h1>
      <p className="text-muted text-center mb-5">
        {t('signInSaml.subTitle', { provider })}
      </p>
      <Button
        className="w-100"
        label={t('signInSaml.button', { provider })}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = '/_auth/saml';
        }}></Button>
    </>
  );
};

export default LoginWithSaml;
