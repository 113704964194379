import React, { useMemo, useState } from 'react';
import Checkbox from '../../../components/Checkbox';
import { AutoSaveIndicator } from '../../../../../components/form/AutoSaveIndicator';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { Form } from 'react-bootstrap';
import { debounce } from 'lodash';
import { DEFAULT_DEBOUNCE_TIME } from '../../../../../utils/time';
import {
  useUpdateWorkflowItemSubTasksMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';
import { WorkflowItemBase, WorkflowSubTask } from '../WorkflowItems/types';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  workflowItem: WorkflowItemBase;
  subTasks: WorkflowSubTask[];
  readonly: boolean;
  onStatusLoading?: (isSaving: boolean) => void;
};

export const WorkflowItemSubTasks: React.FC<Props> = ({
  workflowItem,
  subTasks,
  readonly,
  onStatusLoading,
}) => {
  const t = useNamespacedTranslation(
    'performanceReview.workflowsItems.sidebar',
  );
  const [updateWorkflowItemSubTasks] = useUpdateWorkflowItemSubTasksMutation();
  const errorHandler = useMutationErrorHandler();

  const [allSubTasks, setAllSubTasks] = useState(subTasks);
  const [saving, setSaving] = useState(false);

  const debouncedUpdateSubTasks = useMemo(() => {
    return debounce((updatedSubTasks: WorkflowSubTask[]) => {
      let status = workflowItem.status;
      if (status === WorkflowItemStatusGenerated.NotStarted) {
        status = WorkflowItemStatusGenerated.InProgress;
      }

      updateWorkflowItemSubTasks({
        variables: {
          input: {
            id: workflowItem.id,
            subTasks: updatedSubTasks,
            status: status as WorkflowItemStatusGenerated,
          },
        },
      })
        .then()
        .catch(errorHandler)
        .finally(() => {
          setSaving(false);
          onStatusLoading && onStatusLoading(false);
        });
    }, DEFAULT_DEBOUNCE_TIME);
  }, [
    workflowItem.status,
    workflowItem.id,
    updateWorkflowItemSubTasks,
    errorHandler,
    onStatusLoading,
  ]);

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setSaving(true);

    //If current status is Not Started, then it will change to In Progress
    const isStatusGoingToChange =
      workflowItem.status === WorkflowItemStatusGenerated.NotStarted;

    if (isStatusGoingToChange) {
      onStatusLoading && onStatusLoading(true);
    }

    const updatedSubTasks = allSubTasks.map((subTask) =>
      subTask.id === id ? { ...subTask, checked } : subTask,
    );

    setAllSubTasks(updatedSubTasks);
    debouncedUpdateSubTasks(updatedSubTasks);
  };

  return (
    <div>
      <FormGroup>
        <Form.Label>
          {t('subTasks')}
          <div className="d-inline-block ms-2">
            <InfoHint className={'ms-1'} text={t('subTasksTooltip')} />
          </div>
          <div className="d-inline-block ms-2">
            <AutoSaveIndicator loading={saving} completed={false} />
          </div>
        </Form.Label>
        {allSubTasks.map((subTask) => {
          return (
            <Checkbox
              key={subTask.id}
              id={subTask.id}
              label={subTask.text}
              checked={subTask.checked}
              disabled={readonly}
              onChange={(e) =>
                handleCheckboxChange(subTask.id, e.target.checked)
              }
            />
          );
        })}
      </FormGroup>
    </div>
  );
};
