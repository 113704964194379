import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../../../theme';

type Props = {
  slotsPerDay: number;
  backgroundColor: string;
  color?: string;
  border?: string;
  padding?: string;
};

const Container = styled('div')<Props>(
  {
    borderRadius: Theme.border.radius,
    color: Theme.color.black,
    margin: '0.125rem',
    position: 'relative',
  },
  ({ slotsPerDay, backgroundColor, color, border, padding = '0.375rem' }) => ({
    height: 100.0 / slotsPerDay + '%',
    backgroundColor,
    color,
    border,
    padding,
  }),
);

export const AbsenceContainer: React.FC<{
  slotsPerDay: number;
  children: React.ReactNode;
}> = ({ slotsPerDay, children }) => (
  <Container backgroundColor={Theme.shift.other.bg} slotsPerDay={slotsPerDay}>
    {children}
  </Container>
);

export const ShiftContainer: React.FC<{
  slotsPerDay: number;
  children: React.ReactNode;
}> = ({ slotsPerDay, children }) => (
  <Container backgroundColor={Theme.shift.self.bg} slotsPerDay={slotsPerDay}>
    {children}
  </Container>
);

export const OpenShiftContainer: React.FC<{
  slotsPerDay: number;
  children: React.ReactNode;
  backgroundColor: string;
  color: string;
  border?: string;
  className?: string;
}> = ({ slotsPerDay, children, backgroundColor, color, border, className }) => (
  <Container
    className={className}
    backgroundColor={backgroundColor}
    slotsPerDay={slotsPerDay}
    color={color}
    border={border}
    padding="0">
    {children}
  </Container>
);
