import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  inputId?: string;
  label?: React.ReactNode;
  required?: boolean;
  hint?: React.ReactNode;
  className?: string;
};

const InputLabel: React.FC<Props> = ({
  inputId,
  label,
  required,
  hint,
  className,
}) => (
  <Form.Label htmlFor={inputId} className={className}>
    {required && <abbr className="text-danger me-1">*</abbr>}
    {label ?? <>&nbsp;</>}
    {hint && <span> {hint}</span>}
  </Form.Label>
);

export default InputLabel;
