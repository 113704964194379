import i18next from 'i18next';
import { OptionBase } from '../../../../../components/Select/types';
import { AbsenceApprovalStatus } from '../../../__generated__/graphql';

export type AbsenceRequestFilterType = {
  employeeIds: string[];
  categoryIds: string[];
  month: string | null;
  approvalStatuses: string[];
};

export const defaultFilter: AbsenceRequestFilterType = {
  employeeIds: [],
  categoryIds: [],
  month: null,
  approvalStatuses: [],
};

export const AbsenceTypeMap: Record<string, string> = {
  DAILY: 'days',
  HOURLY: 'hours',
  UNKNOWN: '',
};

export const allApprovalStatuses: AbsenceApprovalStatus[] = [
  AbsenceApprovalStatus.Approved,
  AbsenceApprovalStatus.Rejected,
  AbsenceApprovalStatus.Pending,
  AbsenceApprovalStatus.Unknown,
];

export const isAbsenceApprovalStatus = (
  value: string,
): value is AbsenceApprovalStatus => {
  return allApprovalStatuses.some((x) => x === value);
};

export const getApprovalStatusOptions = (): OptionBase[] => {
  return [
    AbsenceApprovalStatus.Approved,
    AbsenceApprovalStatus.Rejected,
    AbsenceApprovalStatus.Pending,
  ].map((x) => ({
    value: x,
    label: i18next.t(`absences.status.${x.toLowerCase()}`),
  }));
};
