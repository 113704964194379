import * as React from 'react';

const ChartBarHorizontalOutine = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M16.875 7.5H13.75V4.375C13.75 4.20924 13.6842 4.05027 13.5669 3.93306C13.4497 3.81585 13.2908 3.75 13.125 3.75H3.75V3.125C3.75 2.95924 3.68415 2.80027 3.56694 2.68306C3.44973 2.56585 3.29076 2.5 3.125 2.5C2.95924 2.5 2.80027 2.56585 2.68306 2.68306C2.56585 2.80027 2.5 2.95924 2.5 3.125V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5C3.29076 17.5 3.44973 17.4342 3.56694 17.3169C3.68415 17.1997 3.75 17.0408 3.75 16.875V16.25H10.625C10.7908 16.25 10.9497 16.1842 11.0669 16.0669C11.1842 15.9497 11.25 15.7908 11.25 15.625V12.5H16.875C17.0408 12.5 17.1997 12.4342 17.3169 12.3169C17.4342 12.1997 17.5 12.0408 17.5 11.875V8.125C17.5 7.95924 17.4342 7.80027 17.3169 7.68306C17.1997 7.56585 17.0408 7.5 16.875 7.5ZM12.5 5V7.5H3.75V5H12.5ZM10 15H3.75V12.5H10V15ZM16.25 11.25H3.75V8.75H16.25V11.25Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ChartBarHorizontalOutine;
