import styled from '@emotion/styled';
import { Theme } from '../../../../../../theme';
import { CellVariant } from '../../../../components/AbsenceCalendar/Utils';

type Props = {
  variant: CellVariant;
  cellHovered: boolean;
};

const TeamCalendarCell = styled.td<Props>(
  { color: Theme.color.white },
  ({ variant, cellHovered }) => {
    let baseStyle: any = {
      cursor: 'pointer',
      position: 'relative',
      zIndex: 1,
    };

    if (cellHovered) {
      return {
        ...baseStyle,
        backgroundColor: `${Theme.absence.hover.bgColor} !important`,
        borderRadius: Theme.border.radius,
      };
    }

    switch (variant) {
      case 'warning': {
        return {
          ...baseStyle,
          backgroundColor: `${Theme.absence.warning.bgColor} !important`,
        };
      }

      case 'absenceWeekend':
      case 'weekend': {
        return {
          ...baseStyle,
          backgroundColor: `${Theme.absence.nonWorkingDay.bgColor} !important`,
        };
      }

      case 'holiday': {
        return {
          ...baseStyle,
          backgroundColor: `${Theme.absence.holiday.bgColor} !important`,
        };
      }

      case 'pending':
      case 'absence':
      case 'workday': {
        return {
          ...baseStyle,
        };
      }
    }
  },
);

export default TeamCalendarCell;
