import { RowFileTypeEnums } from './Enums/RowFileTypeEnums';

export const isDocumentMulti = (type: string) =>
  type === RowFileTypeEnums.Multi;

export const isFolder = (type: string) => type === RowFileTypeEnums.Folder;

export const replaceUnderscores = (str: string, replaceValue: string = ' ') => {
  return str.replace(/_/g, replaceValue);
};
