import React from 'react';
import { ProjectsTimeSheetPeriodInfo } from '../types';
import {
  formatPeriodInterval,
  formatPeriodName,
} from '../../../../components/TimeSheetsTable/periodFormatter';

type Props = {
  period: ProjectsTimeSheetPeriodInfo;
};

export const TimeSheetPeriodCell: React.FC<Props> = ({ period }) => {
  return (
    <div>
      <span className="text-nowrap me-2 color-gray-1 h7-medium">
        {formatPeriodName(period)}
      </span>
      <span className="text-nowrap color-paper-1 h7-regular">
        {formatPeriodInterval(period)}
      </span>
    </div>
  );
};
