import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  email: string;
};

const i18prefix = 'registration.errors.emailAlreadyRegistered';

const EmailAlreadyRegistered: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h1 className="display-4 mb-3">{t(`${i18prefix}.title`)}</h1>
      <p className="text-muted mb-4">
        {t(`${i18prefix}.description`, { email })}
      </p>
    </div>
  );
};

export default EmailAlreadyRegistered;
