import * as React from 'react';
import { LoomVideoPreview } from '../LoomVideoPreview';
import { Modal } from 'react-bootstrap';
import { LoomVideo } from '../types';
import './LoomVideoPreviewInModal.css';

type Props = {
  loomVideo: LoomVideo;
  onHide: () => void;
  previewWidth?: number;
  previewHeight?: number;
};

const DEFAULT_PREVIEW_WIDTH = 800;
const DEFAULT_PREVIEW_HEIGHT = 452;

const LoomVideoPreviewInModal: React.FC<Props> = ({
  loomVideo,
  onHide,
  previewWidth = DEFAULT_PREVIEW_WIDTH,
  previewHeight = DEFAULT_PREVIEW_HEIGHT,
}) => {
  return (
    <Modal
      centered
      show={true}
      onHide={onHide}
      size="lg"
      className="loom-preview-modal">
      <LoomVideoPreview
        loomVideo={loomVideo}
        previewWidth={previewWidth}
        previewHeight={previewHeight}
      />
    </Modal>
  );
};

export default LoomVideoPreviewInModal;
