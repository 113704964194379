import { toISODateOnly } from '../../../../utils/dateUtils';
import { useMyTimeTrackingSettingsQuery } from '../../__generated__/graphql';

export const useMyTimeTrackingSettings = () => {
  const now = new Date();
  const today = toISODateOnly(now);

  return useMyTimeTrackingSettingsQuery({
    variables: {
      today,
    },
  });
};
