import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkeletonCard from '../../components/Skeleton/Card';

const Loading: React.FC = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <SkeletonCard height={76} />
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <SkeletonCard height={300} />
        </Col>
        <Col lg={4}>
          <SkeletonCard height={300} />
        </Col>
        <Col lg={4}>
          <SkeletonCard height={300} />
        </Col>
      </Row>

      <Row>
        <Col xl={8}>
          <SkeletonCard height={130} />
          <SkeletonCard height={130} />
          <SkeletonCard height={130} />
          <SkeletonCard height={130} />
          <SkeletonCard height={130} />
          <SkeletonCard height={130} />
          <SkeletonCard height={130} />
        </Col>
        <Col xl={4}>
          <SkeletonCard height={300} />
        </Col>
      </Row>
    </>
  );
};

export default Loading;
