import React, { useState } from 'react';
import {
  CustomizeTableColumnsIcon,
  CustomizeTableColumnsModal,
  timeSheetColumnIds,
  useTimeSheetColumnSettings,
} from '../../../components/TimeSheetsTable/CustomizeTableColumns';

type Props = {
  isTimeBalanceEnabled: boolean;
};

export const useManagerTimeSheetColumnSettings = ({
  isTimeBalanceEnabled,
}: Props) => {
  const supportedColumns = new Set(timeSheetColumnIds);
  if (!isTimeBalanceEnabled) {
    supportedColumns.delete('timeBalanceAccumulatedMinutes');
  }
  return useTimeSheetColumnSettings('timeSheets_columns', supportedColumns);
};

export const ManagerCustomizeTableColumnsIcon: React.FC<Props> = ({
  isTimeBalanceEnabled,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [timeSheetColumnSettings, setTimeSheetColumnSettings] =
    useManagerTimeSheetColumnSettings({ isTimeBalanceEnabled });

  return (
    <>
      <CustomizeTableColumnsIcon onClick={() => setVisible(true)} />
      <CustomizeTableColumnsModal
        visible={visible}
        timeSheetColumnSettings={timeSheetColumnSettings}
        onSave={setTimeSheetColumnSettings}
        onClose={() => setVisible(false)}
      />
    </>
  );
};
