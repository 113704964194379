import React from 'react';
import { Employee } from '../..';
import ServerError from '../../../../../../components/ServerError';
import { Link } from '../../../../../../Router';
import { QueryString } from '../../../../../../utils/router';
import { PastPerformanceReviewCard } from '../../../../components/PastPerformanceReviewCard';
import { EmptyEmployeeFeedbacks } from '../../../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/EmptyEmployeeFeedbacks';
import { LoadingEmployeeFeedbacks } from '../../../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/LoadingEmployeeFeedbacks';
import NextPerformanceReview from '../../../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/NextPerformanceReview';
import { routes } from '../../../../routes';
import { useEmployeePagePerformanceReviewQuery } from '../../../../__generated__/graphql';
import { employeePageRoutes } from '../../PageHeader/routes';
import { useFeedbacks } from '../../../ReviewsList/useFeedbacks';
import { mapPeerFeedbacks } from '../../../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeEvaluation/mapping';

type Props = {
  employee: Employee;
};

export const EmployeeFeedbacks: React.FC<Props> = ({ employee }) => {
  const { data, loading, error } = useEmployeePagePerformanceReviewQuery({
    variables: { id: employee.Id },
  });

  const { feedbacks } = useFeedbacks(data?.manager.employee?.feedbacks);

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingEmployeeFeedbacks />;
  }

  if (!data.manager.employee) {
    return <ServerError />;
  }

  const employeeFeedbacks = data.manager.employee.feedbacks;

  if (!employeeFeedbacks.length) {
    return <EmptyEmployeeFeedbacks />;
  }

  const mappedPastFeedbacks = feedbacks.pastFeedbacks.map((pastFeedback) => ({
    ...pastFeedback,
    peerFeedbacks: mapPeerFeedbacks(pastFeedback.peerFeedbacks),
  }));

  return (
    <>
      <NextPerformanceReview
        feedbacks={feedbacks.nextFeedbacks}
        canChangeReviewer={true}
      />
      <PastPerformanceReviewCard
        feedbacks={mappedPastFeedbacks}
        detailsLink={(feedbackId, cycleName) => (
          <Link
            to={routes.performanceReviewerPreviousReviews.route}
            feedbackId={feedbackId}>
            {cycleName}
          </Link>
        )}
        filtrationRoute={(params: QueryString) => {
          return employeePageRoutes()
            .performanceReview.route.withQueryParams(params)
            .create({ employeeId: employee.Id });
        }}
        showReviewers={true}
      />
    </>
  );
};
