import styled from '@emotion/styled';
import React from 'react';
import { Row } from 'react-bootstrap';
import { Theme } from '../../../../theme';
import { useUserInfo } from '../../context/UserInfo';
import { ComparedFeedbackAnswer } from '../../hooks/useComparedFeedbackSectionsMapper';
import {
  FeedbackAnswerVisibility,
  FeedbackQuestionVisibility,
} from '../../__generated__/graphql';
import {
  FeedbackAnswer,
  HiddenFeedbackAnswer,
  NotRequiredFeedbackAnswer,
  NotSubmittedAnswer,
} from './FeedbackAnswer';
import { Nullable } from '../../../../utils/nullable';

type VisibilityProps = {
  questionVisibility: FeedbackQuestionVisibility;
  answerVisibility: FeedbackAnswerVisibility;
};

type SideBySideAnswersProps = {
  employeeId: string;
  employeeName: string;
  employeeAnswer: ComparedFeedbackAnswer;
  managerId: Nullable<string>;
  managerName: Nullable<string>;
  managerAnswer: ComparedFeedbackAnswer;
  isManagerSubmitted?: boolean;
  isEmployeeSubmitted?: boolean;
} & VisibilityProps;

const VerticalDivider = styled('div')({
  flex: 0,
  borderLeft: `0.5px solid ${Theme.color.paper3}`,
});

export const FeedbackAnswerSection: React.FC<
  VisibilityProps & {
    isAnswerAuthor: boolean;
    isEmployeeSection: boolean;
    employeeName: string;
    isSubmitted?: boolean;
  }
> = ({
  questionVisibility,
  answerVisibility,
  isAnswerAuthor,
  isEmployeeSection,
  employeeName,
  children,
  isSubmitted = true,
}) => {
  const isManagerSection = !isEmployeeSection;
  const isVisibleForManager = questionVisibility.includes(
    FeedbackQuestionVisibility.Manager,
  );
  const isVisibleForEmployee = questionVisibility.includes(
    FeedbackQuestionVisibility.Employee,
  );

  if (!isSubmitted) {
    return <NotSubmittedAnswer employeeName={employeeName} />;
  }

  if (
    answerVisibility === FeedbackAnswerVisibility.SemiHidden &&
    !isAnswerAuthor
  ) {
    return <HiddenFeedbackAnswer employeeName={employeeName} />;
  }

  if (
    (isManagerSection && !isVisibleForManager) ||
    (isEmployeeSection && !isVisibleForEmployee)
  ) {
    return <NotRequiredFeedbackAnswer employeeName={employeeName} />;
  }

  return <>{children}</>;
};

export const SideBySideAnswers: React.FC<SideBySideAnswersProps> = ({
  employeeId,
  employeeName,
  employeeAnswer,
  managerId,
  managerName,
  managerAnswer,
  questionVisibility,
  answerVisibility,
  isEmployeeSubmitted,
  isManagerSubmitted,
}) => {
  const { id: myId } = useUserInfo();

  const selfAssessment = managerId === null || managerName === null;
  return (
    <Row>
      <FeedbackAnswerSection
        questionVisibility={questionVisibility}
        answerVisibility={answerVisibility}
        isAnswerAuthor={myId === employeeId}
        isEmployeeSection={true}
        employeeName={employeeName}
        isSubmitted={isEmployeeSubmitted}>
        <FeedbackAnswer
          answer={employeeAnswer}
          employeeName={selfAssessment ? null : employeeName}
          side="left"
        />
      </FeedbackAnswerSection>
      {!selfAssessment && (
        <>
          <VerticalDivider />
          <FeedbackAnswerSection
            questionVisibility={questionVisibility}
            answerVisibility={answerVisibility}
            isAnswerAuthor={myId === managerId}
            isEmployeeSection={false}
            employeeName={managerName}
            isSubmitted={isManagerSubmitted}>
            <FeedbackAnswer
              answer={managerAnswer}
              employeeName={managerName}
              side="right"
            />
          </FeedbackAnswerSection>
        </>
      )}
    </Row>
  );
};
