import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Theme } from '../../../../../theme';

type LineChartProps = {
  seriesName: string;
  seriesData: number[];
  categories: string[];
  anotationsYAxis: number;
  height?: number;
  chartTitle?: string;
};

const DOWNLOAD_FILE_NAME = 'flair-report';

const LineChart: React.FC<LineChartProps> = (props) => {
  const { seriesData, seriesName, categories, chartTitle, height } = props;

  const options: ApexOptions = {
    series: [
      {
        name: seriesName,
        data: seriesData,
      },
    ],
    chart: {
      height: height ?? 'auto',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 5,
        offsetY: -5,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: DOWNLOAD_FILE_NAME,
          },
          svg: {
            filename: DOWNLOAD_FILE_NAME,
          },
          png: {
            filename: DOWNLOAD_FILE_NAME,
          },
        },
      },
      animations: {
        speed: 400,
        easing: 'linear',
        dynamicAnimation: {
          speed: 400,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${value}%`;
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value}%`;
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
      width: 5,
    },
    title: {
      text: chartTitle,
      align: 'left',
    },
    colors: [Theme.color.teal2],
    xaxis: {
      categories: categories,
    },
    fill: {
      //type: 'solid', opacity: 0.3 - opacity not working, this is a quick fix:
      type: 'gradient',
      gradient: {
        opacityFrom: 0.3,
        opacityTo: 0.3,
        stops: [100],
      },
    },
  };

  return (
    <div className="m-4">
      <ReactApexChart
        options={options}
        series={options.series}
        type={'area'}
        height={height}
      />
    </div>
  );
};

export default LineChart;
