import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '../../../../context/UserInfo';
import { useGoalSelectOptions } from '../useGoalSelectOptions';
import { GoalCommonFieldsSkeleton } from './GoalCommonFieldsSkeleton';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { GoalCreateForm } from './GoalCreateForm';

type Props = {
  employeeId?: string;
  onClose: () => void;
};

export const GoalSidebarCreateContent: React.FC<Props> = ({
  employeeId,
  onClose,
}) => {
  const { t } = useTranslation();

  const userInfo = useUserInfo();

  const { options, loading, error } = useGoalSelectOptions();

  if (error) {
    return <div>{t(`performanceReview.goals2.errorLoading`)}</div>;
  }

  if (!options || loading) {
    return <GoalCommonFieldsSkeleton />;
  }

  const owner: EmployeeOption = {
    value: userInfo.id,
    label: userInfo.name,
    initials: userInfo.initials,
  };

  return (
    <GoalCreateForm
      employeeId={employeeId}
      owner={owner}
      onClose={onClose}
      options={options}
    />
  );
};
