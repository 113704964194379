import * as React from 'react';
import { GoalActivity } from '../types';
import { GoalActivityItem } from './GoalActivityItem';

type Props = {
  activities: GoalActivity[];
};

export const GoalsActivities: React.FC<Props> = ({ activities }) => {
  return (
    <div className="goals-activity">
      {activities.map((activity: GoalActivity) => (
        <GoalActivityItem key={activity.id} activity={activity} />
      ))}
    </div>
  );
};
