import styled from '@emotion/styled';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { AutoSaveIndicator } from '../../../../../components/form/AutoSaveIndicator';
import Hint from '../../../../../components/hint';
import StringToHtml from '../../../../../components/StringToHtml';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { Theme } from '../../../../../theme';
import { useUpdateEmployeeFeedbackAnswerMutation } from '../../../__generated__/graphql';
import { NotRequiredFeedbackAnswer } from '../../EmployeeFeedback/FeedbackAnswer';
import { EmployeeFeedbackInput } from '../EmployeeFeedbackInput';
import { IEmployeeFeedbackAnswer } from '../types';
import { DEFAULT_DEBOUNCE_TIME } from '../../../../../utils/time';

type Props = {
  answer: IEmployeeFeedbackAnswer;
  onUpdateAnswer: (answer: IEmployeeFeedbackAnswer, value: string) => void;
  readOnly: boolean;
};

const FieldContainer = styled.div({
  marginBottom: '2.25rem',
});

const HintSpan = styled.span({
  color: Theme.color.gray2,
});

export const EmployeeFeedbackField: React.FC<Props> = ({
  answer,
  onUpdateAnswer,
  readOnly,
}) => {
  const [value, setValue] = useState<string>(answer.answer ?? '');
  const [notes, setNotes] = useState<string>(answer.notes ?? '');
  const [updateAnswer, { loading }] = useUpdateEmployeeFeedbackAnswerMutation();
  const errorHandler = useMutationErrorHandler();
  const saveChanges = useMemo(
    () =>
      debounce(
        ({ value, notes }: { value: string; notes: string }) =>
          updateAnswer({
            variables: {
              input: {
                id: answer.id,
                answer: value,
                notes: notes,
              },
            },
          })
            .then(() => onUpdateAnswer(answer, value))
            .catch(errorHandler),
        DEFAULT_DEBOUNCE_TIME,
      ),
    [answer, updateAnswer, onUpdateAnswer, errorHandler],
  );
  const onChange = useCallback(
    (value: string) => {
      setValue(value);
      saveChanges({ value, notes });
    },
    [setValue, saveChanges, notes],
  );
  const onChangeNotes = useCallback(
    (notes: string) => {
      setNotes(notes);
      saveChanges({ value, notes });
    },
    [setNotes, saveChanges, value],
  );

  const {
    isOptional,
    isComplete,
    showOptionalComment,
    description,
    question,
    choices,
    requiredChoices,
    type,
    id,
    hint,
  } = answer;

  return (
    <FieldContainer>
      <Form.Label className="row align-items-center">
        <Col>
          <h3 className="mb-0">
            {!isOptional && <abbr className="text-danger me-1">*</abbr>}
            <span>{question}</span>
            {hint && (
              <HintSpan className="ms-1">
                <Hint id={`hint_${id}`} text={hint} />
              </HintSpan>
            )}
          </h3>
        </Col>
        {!readOnly && (
          <Col className="col-auto align-self-start">
            <AutoSaveIndicator loading={loading} completed={isComplete} />
          </Col>
        )}
      </Form.Label>
      {description && (
        <Form.Text>
          <span>
            <StringToHtml text={description} />
          </span>
        </Form.Text>
      )}

      {!answer.showOnlyQuestionName ? (
        <EmployeeFeedbackInput
          id={answer.id}
          value={value}
          onChange={onChange}
          choices={choices}
          requiredChoices={requiredChoices}
          showOptionalComment={showOptionalComment}
          type={type}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
        />
      ) : (
        <NotRequiredFeedbackAnswer />
      )}
    </FieldContainer>
  );
};
