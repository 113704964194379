import React from 'react';
import { useTimeSheetQuery } from '../../__generated__/graphql';
import Loading from './Loading';
import ServerError from '../../../../components/ServerError';
import { Row, Col } from 'react-bootstrap';
import GoalChartCard from './GoalChartCard';
import WeekChartCard from './WeekChartCard';
import TimeDistChartCard from './TimeDistChartCard';
import { EmployeeInfo } from './EmployeeInfo';
import moment from 'moment';
import TimeSheetActionsPanel from './TimeSheetActionsPanel';
import TimeTrackingEntries from '../TimeTracking/TimeTrackingEntries';
import { TimeSheet } from './types';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { routes } from '../../routes';
import {
  TimeSheetApproveContextProvider,
  TimeSheetApproveModalConnected,
} from '../../components/TimeSheetApprove';

type Props = {
  timeSheetId: string;
  goToAllTimesheetsAction?: (timeSheet?: TimeSheet) => void;
};

const TimeSheetContent: React.FC<Props> = ({
  timeSheetId,
  goToAllTimesheetsAction,
}) => {
  const { data, loading, error } = useTimeSheetQuery({
    variables: { id: timeSheetId },
  });

  const t = useNamespacedTranslation('navigation.menuItems.timeManagement');

  useFlairBreadcrumbHook(
    [
      { label: t('title') },
      {
        label: t('subMenuItems.controlling.title'),
        url: routes.timeSheetsControllingPending.route.create({}),
      },
      { label: data?.timeSheet.employee.Name ?? '' },
    ],
    loading,
  );

  if (loading) return <Loading />;

  if (error) return <ServerError />;

  const timeSheet = data?.timeSheet;

  if (!timeSheet) {
    return <></>;
  }

  return (
    <TimeSheetApproveContextProvider>
      <Row>
        <Col lg={12}>
          <TimeSheetActionsPanel
            goToAllTimesheetsAction={goToAllTimesheetsAction}
            timeSheet={timeSheet}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <GoalChartCard timeSheet={timeSheet} />
        </Col>
        <Col lg={4}>
          <WeekChartCard
            startDate={moment(timeSheet.flair__Start_Date__c).toDate()}
            timeSheet={timeSheet}
          />
        </Col>
        <Col lg={4}>
          <TimeDistChartCard timeSheet={timeSheet} />
        </Col>
      </Row>

      <Row>
        <Col xl={8}>
          <TimeTrackingEntries
            timeSheet={timeSheet}
            costCenters={data.costCenters}
            isManager={true}
          />
        </Col>
        <Col xl={4}>
          <EmployeeInfo employee={timeSheet.employee} />
        </Col>
      </Row>
      <TimeSheetApproveModalConnected />
    </TimeSheetApproveContextProvider>
  );
};

export default TimeSheetContent;
