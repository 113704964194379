import { parseISO } from 'date-fns';
import {
  BreakLegislationRuleFragment,
  MyTimeEntryTimeTrackingFragment,
  WorkloadDayFragment,
  WorkloadWeekFragment,
} from '../../__generated__/graphql';
import { BreakLegislationRule } from './shared';
import { TimeTrackingState } from './shared';

export type IsoWeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const getIsoWeekDays = (): IsoWeekDay[] => [1, 2, 3, 4, 5, 6, 7];

export const mapTimeTrackingState = (
  src: MyTimeEntryTimeTrackingFragment,
): TimeTrackingState => ({
  start: parseISO(src.flair__Start_Datetime__c),
  breaks: src.breaks.map((srcBreak) => ({
    start: parseISO(srcBreak.flair__Start_Datetime__c),
    end: srcBreak.flair__End_Datetime__c
      ? parseISO(srcBreak.flair__End_Datetime__c)
      : undefined,
  })),
});

export const mapBreakLegislationRule = (
  src: BreakLegislationRuleFragment,
): BreakLegislationRule => ({
  workingTime:
    src.flair__Work_More_Than_Hour__c * 60 +
    src.flair__Work_More_Than_Minute__c,
  breakDuration: src.flair__Break__c,
});

export const getWorkloadDay = (
  src: WorkloadWeekFragment,
  isoWeekDay: IsoWeekDay,
): WorkloadDayFragment => {
  switch (isoWeekDay) {
    case 1:
      return src.monday;
    case 2:
      return src.tuesday;
    case 3:
      return src.wednesday;
    case 4:
      return src.thursday;
    case 5:
      return src.friday;
    case 6:
      return src.saturday;
    case 7:
      return src.sunday;
  }
};
