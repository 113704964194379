import React from 'react';
import { InventoryConnected } from './Inventory/InventoryConnected';
import PageHeader from '../../components/PageHeader';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

export const InventoryPage: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems');
  const title = t('inventory.title');

  useFlairBreadcrumbHook([{ label: t('profile.title') }, { label: title }]);

  return (
    <>
      <PageHeader title={title} />
      <InventoryConnected />
    </>
  );
};
