import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../../theme';
import { EmployeeWithAvatar } from '../../../components/Employee';
import {
  SkillLevelLabel,
  SKILL_LEVEL_LABEL_HEIGHT,
} from '../common/SkillLevelLabel';
import {
  getClosestSkillLevel,
  getSkillLevelMedian,
} from '../common/skillLevels';
import { Employee, isSkillLevel, Skill } from '../common/types';
import { EmployeeSkillMatrix } from './types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

export type Props = {
  employees: Employee[];
  skills: Skill[];
  employeeSkills: EmployeeSkillMatrix[] | 'loading';
  onRemoveSkillClick: (skillId: string) => void;
  onRemoveEmployeeClick: (employeeId: string) => void;
  onEmployeeClick: (employee: Employee) => void;
  topLeftCell?: React.ReactNode;
};

export const SkillsMatrix: React.FC<Props> = ({
  employees,
  skills,
  employeeSkills,
  onRemoveSkillClick,
  onRemoveEmployeeClick,
  topLeftCell,
  onEmployeeClick,
}) => {
  const t = useNamespacedTranslation('skills.matrix');

  const renderHeader = () => {
    return (
      <>
        <StickyCell key="empty-header">
          <div style={{ zIndex: 1000 }}>
            {topLeftCell ? topLeftCell : <span>&nbsp;</span>}
          </div>
        </StickyCell>
        {skills.length > 0 &&
          skills.map((skill) => (
            <HeaderSkillCell
              key={skill.id}
              skill={skill}
              onRemoveSkillClick={onRemoveSkillClick}
            />
          ))}
      </>
    );
  };

  const renderEmployeeRow = (
    employee: Employee,
    employeeSkills: EmployeeSkillMatrix[],
  ) => {
    return (
      <React.Fragment key={`employee-${employee.id}`}>
        <EmployeeCell
          employee={employee}
          onRemoveEmployeeClick={onRemoveEmployeeClick}
          onClick={onEmployeeClick}
        />
        {skills.length > 0 &&
          skills.map((skill, i) => {
            const key = `${employee.id}_${skill.id}`;
            const employeeSkill = employeeSkills.find(
              (x) => x.employeeId === employee.id && x.skillId === skill.id,
            );
            return (
              <CellContainer key={key}>
                <SkillLevelLabel
                  value={employeeSkill ? employeeSkill.level : undefined}
                  width="100%"
                />
              </CellContainer>
            );
          })}
      </React.Fragment>
    );
  };

  const renderLoadingRow = (employee: Employee) => {
    return (
      <React.Fragment key={`loading-${employee.id}`}>
        <EmployeeCell
          employee={employee}
          onRemoveEmployeeClick={onRemoveEmployeeClick}
          onClick={() => {}}
        />
        {skills.map((_skill, i) => {
          return <LoadingCell key={i.toString()} />;
        })}
      </React.Fragment>
    );
  };

  const renderNoEmployeesRow = () => {
    return (
      <>
        <NoEmployeesCell>
          <Hint>{t('emptyEmployees')}</Hint>
        </NoEmployeesCell>
        {skills.length > 0 ? (
          skills.map((_skill, i) => {
            return <div key={i} />;
          })
        ) : (
          <div></div>
        )}
      </>
    );
  };

  const renderFooter = (employeeSkills: EmployeeSkillMatrix[]) => {
    return (
      <>
        <SummaryCell key="summary">
          <div>{t('median')}</div>
          <Hint>{t('ofNEmployees', { count: employees.length })}</Hint>
        </SummaryCell>
        {skills.map((skill) => {
          const key = `median_${skill.id}`;
          const allSkillsOfColumn = employeeSkills.filter(
            (x) => x.skillId === skill.id,
          );
          const allLevels = allSkillsOfColumn
            .map((x) => x.level)
            .filter(isSkillLevel);
          const median = getSkillLevelMedian(allLevels);
          return (
            <CellContainer key={key}>
              <SkillLevelLabel
                value={
                  median !== undefined
                    ? getClosestSkillLevel(median)
                    : undefined
                }
                width="100%"
                displayValue={
                  median !== undefined ? median.toString() : undefined
                }
              />
            </CellContainer>
          );
        })}
      </>
    );
  };

  const renderLoadingFooter = () => {
    return (
      <>
        <SummaryCell key="summary">
          <div>{t('median')}</div>
          <Hint>{t('ofNEmployees', { count: employees.length })}</Hint>
        </SummaryCell>
        {skills.map((_skill, i) => {
          return <LoadingCell key={i.toString()} />;
        })}
      </>
    );
  };

  const renderNoSkills = () => {
    return (
      <NoSkillsContainer employeesCount={employees.length}>
        <div>{t('emptySkills')}</div>
        <Hint>{t('emptySkillsSubline')}</Hint>
      </NoSkillsContainer>
    );
  };

  const isLoading = employeeSkills === 'loading';

  return (
    <Grid skillsCount={skills.length} employeesCount={employees.length}>
      {skills.length === 0 && renderNoSkills()}
      {renderHeader()}
      {employees.length > 0 &&
        employees.map((employee) =>
          !isLoading
            ? renderEmployeeRow(employee, employeeSkills)
            : renderLoadingRow(employee),
        )}
      {employees.length === 0 && renderNoEmployeesRow()}
      {!isLoading ? renderFooter(employeeSkills) : renderLoadingFooter()}
      <FooterBorder
        skillsCount={skills.length}
        employeesCount={employees.length}>
        &nbsp;
      </FooterBorder>
    </Grid>
  );
};

type HeaderSkillCellProps = {
  skill: Skill;
  onRemoveSkillClick: (skillId: string) => void;
};

const HeaderSkillCell: React.FC<HeaderSkillCellProps> = ({
  skill,
  onRemoveSkillClick,
}) => {
  return (
    <HeaderCell className="d-flex justify-content-between align-items-center flex-nowrap gap-1">
      <div title={skill.name} className="text-truncate">
        {skill.name}
      </div>
      <FlairIcon
        icon="close-outline"
        role="button"
        onClick={() => onRemoveSkillClick(skill.id)}
        color={Theme.color.paper1}
        cursor="pointer"
      />
    </HeaderCell>
  );
};

type EmployeeCellProps = {
  employee: Employee;
  onRemoveEmployeeClick: (employeeId: string) => void;
  onClick: (employee: Employee) => void;
};

const EmployeeCell: React.FC<EmployeeCellProps> = ({
  employee,
  onRemoveEmployeeClick,
  onClick,
}) => {
  const handleEmployeeClick = useCallback(() => {
    onClick(employee);
  }, [onClick, employee]);

  const handleRemoveeEmployee = useCallback(() => {
    onRemoveEmployeeClick(employee.id);
  }, [onRemoveEmployeeClick, employee]);

  return (
    <StickyCell className="d-flex align-items-center flex-nowrap small">
      <div onClick={handleEmployeeClick} role="button">
        <EmployeeWithAvatar
          employee={{
            name: employee.name,
            avatarUrl: employee.avatarUrl,
          }}
        />
      </div>
      <FlairIcon
        icon="close-outline"
        className="ms-2"
        onClick={handleRemoveeEmployee}
        color={Theme.color.paper1}
        cursor="pointer"
      />
    </StickyCell>
  );
};

const LoadingCell: React.FC = () => (
  <CellContainer>
    <Skeleton width="100%" height={SKILL_LEVEL_LABEL_HEIGHT} />
  </CellContainer>
);

const Grid = styled.div<{ skillsCount: number; employeesCount: number }>(
  {
    display: 'grid',
    gridGap: '1rem',
    alignItems: 'center',
    position: 'relative',
  },
  ({ skillsCount, employeesCount }) => ({
    gridTemplateRows: `repeat(${employeesCount + 2}, auto)`,
    gridTemplateColumns:
      skillsCount > 0
        ? `minmax(auto, 13rem) repeat(${skillsCount}, 5rem)`
        : 'minmax(auto, 13rem) auto',
  }),
);

const NoSkillsContainer = styled.div<{
  employeesCount: number;
}>(
  {
    gridColumn: '2 / 3',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ employeesCount }) => ({
    gridRow: `1 / span ${employeesCount + 1}`,
  }),
);

const HeaderCell = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('fs-5', 'fw-bold', className)} />
);

const StickyCell = styled.div({
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  paddingRight: '0.75rem',
  paddingLeft: '1.5rem',
});

const NoEmployeesCell = styled(StickyCell)({
  minWidth: '13rem',
});

const SummaryCell = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <StickyCell
    {...rest}
    className={classNames('fs-5', 'fw-bold', 'text-end', className)}
  />
);

const Hint = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('fs-5', 'text-dark')} />
);

const CellContainer = styled.div({
  lineHeight: 1,
});

const FooterBorder = styled('div')<{
  skillsCount: number;
  employeesCount: number;
}>(
  {
    position: 'absolute',
    top: 0,
    marginTop: '-0.325rem',
    borderColor: Theme.color.paper2,
    borderTopWidth: '1px',
    borderTopStyle: 'dashed',
    marginLeft: '1.5rem',
    width: '100%',
  },
  ({ skillsCount, employeesCount }) => ({
    gridRow: employeesCount > 0 ? employeesCount + 2 : 3,
    gridColumn: `1 / ${skillsCount > 0 ? skillsCount + 2 : 3}`,
  }),
);
