import { ChartData, ChartOptions } from 'chart.js';
import { TFunction } from 'i18next';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Bar from '../../../../components/charts/Bar';
import { useDurationFormatter } from '../../../../hooks/useDurationFormatter';
import { Theme } from '../../../../theme';
import { hoursToMinutes } from '../../../../utils/time';

type TimeSheet = {
  Id: string;
  flair__Start_Date__c: string;
  flair__Given_Compensatory_Time_Level_1__c: number | null;
  flair__Given_Compensatory_Time_Level_2__c: number | null;
};

type Props = {
  timeSheets: ReadonlyArray<TimeSheet>;
};

const theme = Theme.chart.compensatoryTime;

const useLabels = (timeSheets: ReadonlyArray<TimeSheet>): number[] =>
  useMemo(
    () => timeSheets.map((ts) => moment(ts.flair__Start_Date__c).isoWeek()),
    [timeSheets],
  );

type DataSets = {
  overtime: number[];
  undertime: number[];
};

const useDataSets = (timeSheets: ReadonlyArray<TimeSheet>): DataSets =>
  useMemo(
    () =>
      timeSheets.reduce<DataSets>(
        (result, ts) => {
          const compensatoryTime =
            (ts.flair__Given_Compensatory_Time_Level_1__c ?? 0) +
            (ts.flair__Given_Compensatory_Time_Level_2__c ?? 0);

          return {
            overtime: [...result.overtime, Math.max(compensatoryTime, 0)],
            undertime: [...result.undertime, Math.min(compensatoryTime, 0)],
          };
        },
        { overtime: [], undertime: [] },
      ),
    [timeSheets],
  );

const useChartData = (
  t: TFunction,
  timeSheets: ReadonlyArray<TimeSheet>,
): ChartData<'bar'> => {
  const labels = useLabels(timeSheets);
  const datasets = useDataSets(timeSheets);

  return useMemo(
    () => ({
      labels,
      datasets: [
        {
          backgroundColor: theme.overtime.color,
          label: t('timeSheet.compensatoryTimeChart.labels.overtime'),
          data: datasets.overtime,
        },
        {
          backgroundColor: theme.deficit.color,
          label: t('timeSheet.compensatoryTimeChart.labels.undertime'),
          data: datasets.undertime,
        },
      ],
    }),
    [t, labels, datasets],
  );
};

const useChartOptions = (t: TFunction): ChartOptions<'bar'> => {
  const durationFormatter = useDurationFormatter(t);
  const labelFormatter = useLabelFormatter(t);

  return useMemo(() => {
    const options: ChartOptions<'bar'> = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label;
              const hours = context.dataset.data[context.dataIndex];
              return `<div class="d-flex align-items-center justify-content-between gap-2">
              <div>${label}</div>
              <div>
              ${durationFormatter(hoursToMinutes(hours))}
              </div>
              </div>
              `;
            },
            title: (tooltipItems) => {
              const item = tooltipItems[0];

              return item.label ? labelFormatter(parseFloat(item.label)) : '';
            },
          },
        },
      },

      scales: {
        y: {
          stacked: true,
          ticks: {
            color: theme.ticks.color,
          },
          grid: {
            color: theme.gridLines.color,
          },
        },
        x: {
          stacked: true,
          ticks: {
            color: theme.ticks.color,
          },
          grid: {
            color: theme.gridLines.color,
          },
        },
      },
    };
    return options;
  }, [durationFormatter, labelFormatter]);
};

const useOrderedTimeSheets = (timeSheets: ReadonlyArray<TimeSheet>) =>
  useMemo(() => sortBy(timeSheets, ['flair__Start_Date__c']), [timeSheets]);

const useLabelFormatter = (t: TFunction) =>
  useCallback(
    (value: number) =>
      t('calendarWeek', {
        week: value,
      }),
    [t],
  );

const CompensatoryTimeChart: React.FC<Props> = ({ timeSheets }) => {
  const { t } = useTranslation();
  const orderedTimeSheets = useOrderedTimeSheets(timeSheets);
  const chartData = useChartData(t, orderedTimeSheets);
  const chartOptions = useChartOptions(t);

  return <Bar data={chartData} options={chartOptions} />;
};

export default CompensatoryTimeChart;
