import styled from '@emotion/styled';
import React from 'react';
import { ExpenseStatusComponent } from './ExpenseStatus';

import { ExpenseStatusInput } from './ExpenseStatusInput';
import { allExpenseStatuses, ExpenseStatus, ExpenseStatusInfo } from '../types';
import { ExpenseStatus as ExpenseStatusGenerated } from '../../../__generated__/graphql';

type Props = {
  value: ExpenseStatusInfo;
  onChange?: (value: ExpenseStatusInfo) => void;
  readonly: boolean;
  updatingToStatus: ExpenseStatus | null;
};

export const ExpenseStatusCell: React.FC<Props> = ({
  value,
  onChange,
  readonly,
  updatingToStatus,
}) => {
  const getContent = () => {
    if (updatingToStatus !== null) {
      return (
        <ExpenseStatusInput
          value={updatingToStatus}
          isLoading={true}
          onChange={() => {}}
        />
      );
    }
    if (isReadonly(readonly, value.status)) {
      return (
        <ExpenseStatusReadonlyContainer>
          <ExpenseStatusComponent status={value.status} />
        </ExpenseStatusReadonlyContainer>
      );
    } else {
      return (
        <ExpenseStatusInput
          value={value.status}
          onChange={(newStatus) => {
            if (newStatus && onChange) {
              onChange({ ...value, status: newStatus });
            }
          }}
        />
      );
    }
  };
  return <Container>{getContent()}</Container>;
};

const ExpenseStatusReadonlyContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  minHeight: '36px',
});

const Container = styled.div({
  minWidth: '140px',
});

function isReadonly(readonly: boolean, status: ExpenseStatus) {
  const readonlyStatuses: ExpenseStatus[] = [
    ExpenseStatusGenerated['Declined'],
    ExpenseStatusGenerated['Reimbursed'],
  ];
  return (
    readonly ||
    !allExpenseStatuses.includes(status) ||
    readonlyStatuses.includes(status)
  );
}
