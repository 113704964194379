import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../../../../theme';
import {
  getCancelInfoStr,
  getGoalTypeStr,
  getRateInfoStr,
} from '../../translations';
import {
  GoalEmployee,
  GoalRateInfo,
  GoalStatus,
  GoalTag,
  GoalType,
  GoalVisibility,
} from '../../types';
import { GoalLabel } from './GoalLabel';
import { GoalTagLabels } from './GoalTagLabels';
import { GoalVisibilityLabel } from './GoalVisibilityLabel';

type Props = {
  goalType: GoalType;
  rateInfo: GoalRateInfo;
  status: GoalStatus;
  periodName: string | undefined;
  visiblity: GoalVisibility;
  sharedWith?: GoalEmployee[]; // When visibility == protected
  goalId: string;
  tags: GoalTag[];
  cancelReason?: string;
  statusChangedBy?: GoalEmployee;
};

// todo: In next PR. "Add Canceled by or not implement!?
export const GoalCardLabels: React.FC<Props> = ({
  goalType,
  status,
  periodName,
  goalId,
  visiblity,
  sharedWith,
  rateInfo,
  tags,
  cancelReason,
  statusChangedBy,
}) => {
  const allLabels = status === 'BEHIND' || status === 'ON_TRACK';
  const isRatedVisible =
    rateInfo.rate && (rateInfo.rate !== 'OPEN' || status === 'DONE');
  //Canceled by John Doe (HR) due to “change on priorities”
  const isCancelVisible =
    status === 'CANCELED' && cancelReason && statusChangedBy;

  return (
    <Container>
      <GoalLabel
        icon="bullseye-outline"
        label={getGoalTypeStr(goalType)}
        className="me-3"
        noWrap={true}
      />
      {isRatedVisible && (
        <GoalLabel
          icon="information-circle-filled"
          label={getRateInfoStr(rateInfo)}
          className="me-3"
        />
      )}
      {isCancelVisible && (
        <GoalLabel
          icon="information-circle-filled"
          label={getCancelInfoStr(cancelReason, statusChangedBy)}
          className="me-3"
        />
      )}
      {allLabels && (
        <>
          {periodName && (
            <GoalLabel
              icon="tilde-outline"
              label={periodName}
              className="me-3"
            />
          )}
          <GoalVisibilityLabel
            goalId={goalId}
            visiblity={visiblity}
            sharedWith={sharedWith}
          />
          <GoalTagLabels goalId={goalId} tags={tags} />
        </>
      )}
    </Container>
  );
};

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  color: Theme.color.dark,
  fontSize: Theme.font.size.sm,
});
