import { isValidTimeStr } from '../../../../../../utils/time';
import i18next from 'i18next';
import {
  DateAndTime,
  DateAndTimeIntervalType,
  TimeEntryBreakItem,
  TimeEntryItem,
  toMomentDateTime,
} from '.';

const NOTES_MAX_LENGTH = 32768;

export type ValidationResult = Map<string, string[]>;

export const emptyValidationResult = () => new Map<string, string[]>();

export const validateTimeEntry = (
  timeEntry: Pick<TimeEntryItem, 'start' | 'end' | 'breaks' | 'notes'>,
): ValidationResult => {
  const validationResult = new Map<string, string[]>();
  validateInterval('', validationResult, timeEntry);
  validateNotes('', validationResult, timeEntry.notes);
  if (validationResult.size > 0) {
    return validationResult;
  }
  timeEntry.breaks.forEach((curBreak: TimeEntryBreakItem) => {
    const breakPath = `breaks.${curBreak.uniqueId}.`;
    validateInterval(breakPath, validationResult, curBreak);
    validateBreakInsideTimeEntry(
      breakPath,
      validationResult,
      curBreak,
      timeEntry,
    );
  });
  validateBreaksNotOverlap('breaks.', validationResult, timeEntry.breaks);
  return validationResult;
};

export const getFirstErrorFromPath = (
  errors: ValidationResult,
  basePath: string,
): string | null => {
  const result = getAllErrorsFromPath(errors, basePath);
  return result.length > 0 ? result[0] : null;
};

export const getAllErrorsFromPath = (
  errors: ValidationResult,
  basePath: string,
): string[] => {
  let result: string[] = [];
  errors.forEach((values: string[], key: string) => {
    if (key.startsWith(basePath)) {
      result = result.concat(values);
    }
  });
  return result;
};

const validateInterval = (
  path: string,
  validationResult: ValidationResult,
  interval: DateAndTimeIntervalType,
): boolean => {
  if (!validateDateAndTime(path + 'start.', validationResult, interval.start)) {
    return false;
  }
  if (interval.end !== null) {
    if (!validateDateAndTime(path + 'end.', validationResult, interval.end)) {
      return false;
    }
  }
  if (interval.end !== null) {
    const start = toMomentDateTime(interval.start);
    const end = toMomentDateTime(interval.end);
    if (end.isBefore(start)) {
      addError(
        validationResult,
        path + 'end',
        i18next.t('timeTracking.controlling.dayCard.validations.startTime'),
      );
      return false;
    }
  }
  return true;
};

const validateNotes = (
  path: string,
  validationResult: ValidationResult,
  notes: string | null,
): boolean => {
  if (notes === null) {
    return false;
  }
  if (notes.trim().length > NOTES_MAX_LENGTH) {
    addError(
      validationResult,
      path + 'notes',
      i18next.t('validations.number.max', { max: NOTES_MAX_LENGTH }),
    );
    return false;
  }
  return true;
};

const validateBreakInsideTimeEntry = (
  path: string,
  validationResult: ValidationResult,
  curBreak: TimeEntryBreakItem,
  timeEntry: DateAndTimeIntervalType,
) => {
  const breakStart = toMomentDateTime(curBreak.start);
  const timeEntryEnd = timeEntry.end ? toMomentDateTime(timeEntry.end) : null;
  const breakEnd = curBreak.end ? toMomentDateTime(curBreak.end) : null;
  if (timeEntryEnd !== null && curBreak.end === null) {
    addError(
      validationResult,
      path + 'end',
      i18next.t(
        'timeTracking.controlling.dayCard.validations.breakEndTimeEmpty',
      ),
    );
    return;
  }
  const timeEntryStart = toMomentDateTime(timeEntry.start);
  if (breakStart.isBefore(timeEntryStart)) {
    addError(
      validationResult,
      path + 'start',
      i18next.t('timeTracking.controlling.dayCard.validations.breakStartTime'),
    );
  } else if (
    timeEntryEnd !== null &&
    breakEnd !== null &&
    breakEnd.isAfter(timeEntryEnd)
  ) {
    addError(
      validationResult,
      path + 'end',
      i18next.t('timeTracking.controlling.dayCard.validations.breakEndTime'),
    );
  }
};
const validateDateAndTime = (
  path: string,
  validationResult: ValidationResult,
  dateAndTime: DateAndTime,
): boolean => {
  if (!isValidTimeStr(dateAndTime.time)) {
    addError(
      validationResult,
      path + 'time',
      i18next.t('validations.time.format'),
    );
    return false;
  }
  return true;
};

const validateBreaksNotOverlap = (
  path: string,
  validationResult: ValidationResult,
  breaks: TimeEntryBreakItem[],
): boolean => {
  const overlapedBreakId = getOverlapedBreakId(breaks);
  if (overlapedBreakId !== null) {
    addError(
      validationResult,
      path + overlapedBreakId + '.end',
      i18next.t('timeTracking.controlling.dayCard.validations.breaksOverlap'),
    );
    return false;
  }
  return true;
};

const getOverlapedBreakId = (breaks: TimeEntryBreakItem[]): string | null => {
  const filteredBreaks = breaks.filter((x) => x.end !== null);
  if (filteredBreaks.length < 2) {
    return null;
  }
  const intervals = filteredBreaks.map((x) => ({
    start: toMomentDateTime(x.start),
    end: toMomentDateTime(x.end!),
    uniqueId: x.uniqueId,
  }));
  intervals.sort((a, b) => a.start.unix() - b.start.unix());
  for (let i = 0; i < intervals.length - 1; i++) {
    if (intervals[i].end.isAfter(intervals[i + 1].start)) {
      return intervals[i].uniqueId;
    }
  }
  return null;
};

const addError = (
  validationResult: ValidationResult,
  path: string,
  error: string,
) => {
  if (!validationResult.has(path)) {
    validationResult.set(path, []);
  }
  validationResult.get(path)!.push(error);
};
