import { objectToBase64 } from '../../../../../utils/base64';
import { TeamCalendarFiltersType } from '../TeamCalendar/Table/TeamCalendarFilters/types';

type ICalendarFilters = Omit<TeamCalendarFiltersType, 'absentStatus'>;

const ABSENCES_ICS_URL = `${window.location.origin}/api/ical/v2`;
const FEEDBACK_MEETING_URL = `${window.location.origin}/api/ical/feedback-meeting`;

const convertToWebcalProtocol = (icsUrl: string): string => {
  const webcalUrl = new URL(icsUrl);
  webcalUrl.protocol = 'webcal:';
  return webcalUrl.toString();
};

export const generateIcsLink: (
  icalToken: string,
  filterData: TeamCalendarFiltersType,
  locale: string,
) => string = (
  icalToken: string,
  filterData: TeamCalendarFiltersType,
  locale: string,
) => {
  const { absentStatus, ...filters } = filterData;
  const base64 = objectToBase64<ICalendarFilters>(filters);
  return `${ABSENCES_ICS_URL}/${icalToken}.ics?filters=${base64}&locale=${encodeURIComponent(
    locale,
  )}`;
};

export const generateFeedbackMeetingIcsLink: (
  icalToken: string,
  meetingId: string,
  locale: string,
) => string = (icalToken, meetingId, locale: string) => {
  return `${FEEDBACK_MEETING_URL}/${icalToken}.ics?meetingId=${meetingId}&locale=${encodeURIComponent(
    locale,
  )}`;
};

export const generateGoogleLink: (icsUrl: string) => string = (
  icsUrl: string,
) => {
  return `https://www.google.com/calendar/render?cid=${convertToWebcalProtocol(
    icsUrl,
  )}`;
};

export const encodeFiltersToURL = (filterData: { [x: string]: string }) => {
  const queryString = Object.keys(filterData)
    .map(
      (filterKey: string) =>
        `${filterKey}=${encodeURIComponent(filterData[filterKey])}`,
    )
    .join('&');

  return queryString === '' ? '' : `?${queryString}`;
};
