import { isSameYear, startOfMonth, startOfYear } from 'date-fns';
import _, { orderBy } from 'lodash';
import { parseDate, toISODateOnly } from '../../../../../utils/dateUtils';
import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import {
  CelebrationColleagueFragment,
  CelebrationFeed,
  CelebrationFragment,
  CelebrationRecipientType,
  TeamFragment,
} from '../../../__generated__/graphql';
import { DEFAULT_IMAGE_ID, getPredefinedImageUrl, isImageId } from '../images';
import { getCelebrationType } from '../mappings';
import {
  Celebration,
  CelebrationGroup,
  CelebrationRecipient,
  CelebrationsView,
  Employee,
  Team,
} from '../types';
import { mapLoomVideo } from '../../../components/LoomVideo';

export const mapCelebration = (src: CelebrationFragment): Celebration => ({
  id: src.Id,
  message: src.flair__Message__c ?? '',
  date: parseDate(src.CreatedDate),
  isPublic: src.flair__Public__c,
  from: mapEmployee(src.sender),
  recipients: orderBy(src.recipients, (x) => x.flair__Order__c).map(
    mapRecipient,
  ),
  type: getCelebrationType(src.flair__Predefined_Image__c),
  imageUrl: isImageId(src.flair__Predefined_Image__c)
    ? getPredefinedImageUrl(src.flair__Predefined_Image__c)
    : getPredefinedImageUrl(DEFAULT_IMAGE_ID),
  loomVideo: mapLoomVideo(src.loomVideo),
  commentsCount: src.commentsCount,
  reactions: src.reactions,
});

export const mapRecipient = (
  src: CelebrationFragment['recipients'][0],
): CelebrationRecipient => {
  if (
    src.flair__Recipient_Type__c === CelebrationRecipientType.Employee &&
    src.employee !== null
  ) {
    return {
      type: 'employee',
      id: src.Id,
      employee: mapEmployee(src.employee),
    };
  } else if (
    src.flair__Recipient_Type__c === CelebrationRecipientType.Team &&
    src.team !== null
  ) {
    return {
      type: 'team',
      id: src.Id,
      team: mapTeam(src.team),
    };
  }
  // todo: add team mapping in text PR
  throw new Error('todo: add team support');
};

export const mapEmployee = (src: CelebrationColleagueFragment): Employee => ({
  id: src.Id,
  name: src.Name,
  initials: getEmployeeInitialsFromName(src.Name),
  avatarUrl: src.avatar?.url ?? null,
  department: src.department?.Name ?? null,
});

export const mapTeam = (src: TeamFragment): Team => ({
  id: src.Id,
  name: src.Name,
});

type GroupWithCelebrations = CelebrationGroup & {
  celebrations: Celebration[];
};

export const groupCelebrations = (
  celebrations: Celebration[],
  thisYearDate: Date,
): GroupWithCelebrations[] => {
  const thisYearStartDate = startOfYear(thisYearDate);
  return _.orderBy(celebrations, (x) => x.date, 'desc').reduce((acc, x) => {
    let lastGroup: GroupWithCelebrations | null =
      acc.length > 0 ? acc[acc.length - 1] : null;
    const newGroup: CelebrationGroup = isSameYear(thisYearStartDate, x.date)
      ? {
          groupType: 'month',
          firstGroupDay: toISODateOnly(startOfMonth(x.date)),
        }
      : {
          groupType: 'year',
          firstGroupDay: toISODateOnly(startOfYear(x.date)),
        };
    if (lastGroup === null || !isGroupsEqual(lastGroup, newGroup)) {
      lastGroup = {
        ...newGroup,
        celebrations: [],
      };
      acc.push(lastGroup);
    }
    lastGroup.celebrations.push(x);
    return acc;
  }, new Array<GroupWithCelebrations>());
};

export const mapCelebrationFeed = (
  celebrationView: CelebrationsView,
): CelebrationFeed => {
  switch (celebrationView) {
    case 'feed':
      return CelebrationFeed.Public;
    case 'received':
      return CelebrationFeed.Received;
    case 'sent':
      return CelebrationFeed.Sent;
  }
};

function isGroupsEqual(a: CelebrationGroup, b: CelebrationGroup): boolean {
  return a.groupType === b.groupType && a.firstGroupDay === b.firstGroupDay;
}
