import React from 'react';
import { Form } from 'react-bootstrap';
import { ValidationError } from '../../initializers/yup';
import { isString } from './utils';
import { useTranslation } from 'react-i18next';

type Props = {
  error: ValidationError | string | undefined;
  className?: string;
};

const InputError: React.FC<Props> = ({ error, className }) => {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  return (
    <>
      <Form.Control.Feedback type="invalid" className={className}>
        {isString(error) ? error : t(error.key, error.values)}
      </Form.Control.Feedback>
    </>
  );
};

export default InputError;
