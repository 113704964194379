import React from 'react';
import { WorkflowItem } from '../WorkflowItems/types';
import { EmployeeCell } from './EmployeeCell';
import { TeamCell } from './TeamCell';

type Props = {
  value: WorkflowItem['responsible'];
};

export const ResponsibleCell: React.FC<Props> = ({ value }) => {
  if (value === null) {
    return null;
  }
  switch (value.type) {
    case 'employee':
      return (
        <EmployeeCell
          name={value.name}
          avatarUrl={value.avatarUrl}
          position={value.position ?? undefined}
          department={value.department ?? undefined}
        />
      );
    case 'team':
      return <TeamCell name={value.name} employees={value.employees} />;
  }
};
