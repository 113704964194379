import React, { useEffect } from 'react';

// Redirect to storybook distribution (.github/actions/deploy-s3/action.yml)
export const RedirectToStorybook: React.FC = () => {
  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      window.location.href = '/storybook/index.html';
    }
  }, []);
  return (
    <div>
      {import.meta.env.MODE === 'production'
        ? 'Redirecting to storybook'
        : 'Please run "yarn storybook" to see storybook on the local environment'}
    </div>
  );
};
