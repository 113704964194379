import * as React from 'react';

const ExpandOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 6.50476C2.22386 6.50476 2 6.27877 2 6V3.20496C2 2.92618 2.22386 2.7002 2.5 2.7002H6C6.27614 2.7002 6.5 2.92618 6.5 3.20496C6.5 3.48373 6.27614 3.70972 6 3.70972H3V6C3 6.27877 2.77614 6.50476 2.5 6.50476ZM13.5 6.50476C13.2239 6.50476 13 6.27877 13 6V3.70972H10C9.72386 3.70972 9.5 3.48373 9.5 3.20496C9.5 2.92618 9.72386 2.7002 10 2.7002H13.5C13.7761 2.7002 14 2.92618 14 3.20496V6C14 6.27877 13.7761 6.50476 13.5 6.50476ZM6.5 12.7954C6.5 13.0742 6.27614 13.3002 6 13.3002H2.5C2.22386 13.3002 2 13.0742 2 12.7954V10C2 9.72123 2.22386 9.49524 2.5 9.49524C2.77614 9.49524 3 9.72123 3 10V12.2907H6C6.27614 12.2907 6.5 12.5167 6.5 12.7954ZM14 12.7954C14 13.0742 13.7761 13.3002 13.5 13.3002H10C9.72386 13.3002 9.5 13.0742 9.5 12.7954C9.5 12.5167 9.72386 12.2907 10 12.2907H13V10C13 9.72123 13.2239 9.49524 13.5 9.49524C13.7761 9.49524 14 9.72123 14 10V12.7954Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ExpandOutlineIcon;
