import { DashboardAnniversaries_ColleagueFragment } from '../../../__generated__/graphql';
import i18next from '../../../../../initializers/i18next';
import { differenceInCalendarYears } from 'date-fns';
import { WidgetEmployee } from '../CommonComponents/WidgetSections/types';
import { mapCommonColleagueFields } from '../common/mappings';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';
import { formatWidgetDate } from '../common/logic';

export const mapAnniversary = (
  anniversary: DashboardAnniversaries_ColleagueFragment,
): WidgetEmployee => {
  if (
    !anniversary.flair__Start_Date__c ||
    !anniversary.flair__Upcoming_Anniversary__c
  ) {
    throw new Error('Dates should be present');
  }

  const today = new Date();
  const todayDateOnly = new Date(
    Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()),
  );

  const startDate = new Date(anniversary.flair__Start_Date__c);
  const anniversaryDate = new Date(anniversary.flair__Upcoming_Anniversary__c);

  const startDateOnly = new Date(
    Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
    ),
  );
  const anniversaryDateOnly = new Date(
    Date.UTC(
      anniversaryDate.getFullYear(),
      anniversaryDate.getMonth(),
      anniversaryDate.getDate(),
    ),
  );

  const isToday = todayDateOnly.getTime() === anniversaryDateOnly.getTime();

  const yearDiff = differenceInCalendarYears(
    anniversaryDateOnly,
    startDateOnly,
  );

  let additionalInfo = i18next.t('dashboard.anniversaries.year', {
    count: yearDiff,
  });

  if (!isToday) {
    const formattedStartDate = formatWidgetDate(
      anniversaryDate,
      FlairDateFormat.DayMonthLongYearFull,
    );
    additionalInfo = `${formattedStartDate} - ` + additionalInfo;
  }

  return {
    ...mapCommonColleagueFields(anniversary),
    startDate: new Date(startDate),
    date: new Date(anniversary.flair__Upcoming_Anniversary__c as string),
    additionalInfo,
  };
};
