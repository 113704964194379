import classNames from 'classnames';
import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import SoftBadge from '../SoftBadge';

type BadgeProps = {
  icon: React.ReactNode;
  label?: string;
  isDeleteVisible?: boolean;
  popover?: OverlayChildren;
  onDeleteClick?: () => void;
};

const Badge: React.FC<BadgeProps> = ({
  icon,
  label,
  popover,
  isDeleteVisible,
  onDeleteClick,
}) => {
  const content = (
    <SoftBadge pill>
      <BadgeTextContainer>
        {icon}
        {label ? <span>{label}</span> : null}
        {isDeleteVisible && (
          <FlairIcon
            role="button"
            icon="close-outline"
            size="xs"
            onClick={onDeleteClick}
          />
        )}
      </BadgeTextContainer>
    </SoftBadge>
  );

  if (popover) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={popover}>
        {content}
      </OverlayTrigger>
    );
  }

  return content;
};

const BadgeTextContainer = ({
  className,
  ...rest
}: React.HtmlHTMLAttributes<HTMLElement>) => (
  <small
    className={classNames(
      'd-flex',
      'align-items-center',
      'flex-nowrap',
      'flex-row',
      'gap-2',
      'fs-6',
    )}
    {...rest}
  />
);

export default Badge;
