import { Theme } from '../../../../../theme';
import {
  AscDescEnum,
  LoomVideoFragment,
  WorkflowFragment,
  WorkflowItemFieldsFragment,
  WorkflowItemFileFormat,
  WorkflowItemResponsibleType,
  WorkflowItemsFilterInput2,
  WorkflowItemsForWorkflowQuery,
  WorkflowItemStatus as WorkflowItemStatusServer,
  WorkflowItemTableFieldsFragment,
  WorkflowItemType as WorkflowItemTypeServer,
  WorkflowsItemsListSortFieldEnum,
  WorkflowsItemsListSortInput,
  WorkflowsItemsQuery,
} from '../../../__generated__/graphql';
import {
  isWorkflowItemStatusGraphql,
  WorkflowStep,
  WorkflowTeamEmployeeInfo,
} from '../types';
import { mapEmployee } from '../WorkflowsTable/mappings';
import {
  WorkflowItem,
  WorkflowItemBase,
  WorkflowItemCycleAssignment,
  WorkflowItemDataRequest,
  WorkflowItemEvaluationRequest,
  WorkflowItemFileRequest,
  WorkflowItemRegular,
  WorkflowItemStatus,
  WorkflowItemVideoRequest,
  WorkflowSubTask,
} from './types';
import { mapLoomVideo, RecordedLoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';
import { DocumentFile } from '../../Documents2';
import {
  WorkflowItemFilterBase,
  WorkflowItemFilterForMe,
  WorkflowItemFilterForOthers,
  WorkflowItemFilterForWorkflow,
} from '../Filters/types';
import { parseDate, toISODateOnly } from '../../../../../utils/dateUtils';
import { startOfYear } from 'date-fns';
import { SortingRule } from 'react-table';
import { WorkflowResponse } from 'server/src/apps/internal/__generated__/graphql';

type ContentDocumentLink = WorkflowItemFieldsFragment['attachments'][0];

export const mapWorkflowItemsForWorkflow = (
  src: WorkflowItemsForWorkflowQuery,
): WorkflowItem[] => {
  return src.workflowItems.items
    .map((workflowItem) => {
      return mapWorkflowTableItem(src.workflow, workflowItem);
    })
    .filter((x): x is WorkflowItem => x !== null);
};

export const groupItemsByStep = (
  workflowItems: WorkflowItem[],
): WorkflowStep[] => {
  const stepMap = new Map<string, WorkflowStep>();
  workflowItems.forEach((item) => {
    if (!stepMap.has(item.workflowStep.id)) {
      stepMap.set(item.workflowStep.id, {
        ...item.workflowStep,
        workflowItems: [item],
      });
    } else {
      stepMap.get(item.workflowStep.id)?.workflowItems.push(item);
    }
  });
  return Array.from(stepMap.values());
};

export const mapWorkflowItems = (src: WorkflowsItemsQuery): WorkflowItem[] => {
  return src.workflowItems.items
    .map((workflowItem) => {
      return mapWorkflowTableItem(workflowItem.workflow, workflowItem);
    })
    .filter((x): x is WorkflowItem => x !== null);
};

const mapWorkflowTableItem = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItem | null => {
  if (
    src.flair__Type__c === WorkflowItemTypeServer.Unknown ||
    src.flair__Status__c === WorkflowItemStatusServer.Unknown
  ) {
    return null;
  }
  switch (src.flair__Type__c) {
    case WorkflowItemTypeServer.Regular:
      return mapWorkflowItemRegular(workflow, src);
    case WorkflowItemTypeServer.DataRequest:
      return mapWorkflowItemDataRequest(workflow, src);
    case WorkflowItemTypeServer.FileRequest:
      return mapWorkflowItemFileRequest(workflow, src);
    case WorkflowItemTypeServer.CycleAssignment:
      return mapWorkflowItemCycleAssignment(workflow, src);
    case WorkflowItemTypeServer.EvaluationRequest:
      return mapWorkflowItemEvaluationRequest(workflow, src);
    case WorkflowItemTypeServer.VideoRequest:
      return mapWorkflowItemVideoRequest(workflow, src);
    default:
      return null;
  }
};

const mapWorkflowItemRegular = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItemRegular => ({
  ...mapWorkflowItemBase(workflow, src),
  itemType: 'REGULAR',
});

const mapWorkflowItemCycleAssignment = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItemCycleAssignment => ({
  ...mapWorkflowItemBase(workflow, src),
  itemType: 'CYCLE_ASSIGNMENT',
});

const mapWorkflowItemEvaluationRequest = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItemEvaluationRequest => ({
  ...mapWorkflowItemBase(workflow, src),
  itemType: 'EVALUATION_REQUEST',
  evaluationId: src.flair__Employee_Evaluation__c,
});

const mapWorkflowItemVideoRequest = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItemVideoRequest => ({
  ...mapWorkflowItemBase(workflow, src),
  itemType: 'VIDEO_REQUEST',
  loomVideo: mapLoomRecordedVideo(src.loomVideo),
  loomVideoId: src.flair__Loom_Video__c,
});

const mapLoomRecordedVideo = (
  loomVideo: Maybe<LoomVideoFragment>,
): Maybe<RecordedLoomVideo> => {
  if (!loomVideo) {
    return null;
  }

  const mappedLoomVideo = mapLoomVideo(loomVideo);
  if (!mappedLoomVideo) {
    return null;
  }

  return {
    ...mappedLoomVideo,
    loomPublicAppId: loomVideo.flair__Loom_Public_App_Id__c,
    loomId: loomVideo.flair__Loom_Id__c,
  };
};

const mapWorkflowItemDataRequest = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItemDataRequest => ({
  ...mapWorkflowItemBase(workflow, src),
  itemType: 'DATA_REQUEST',
  dataFieldNames: src.flair__Data_Fields__c?.split('\n') ?? [],
});

const mapWorkflowItemFileRequest = (
  workflow: WorkflowFragment,
  src: WorkflowItemTableFieldsFragment,
): WorkflowItemFileRequest => ({
  ...mapWorkflowItemBase(workflow, src),
  itemType: 'FILE_REQUEST',
  fileCategoryId: src.flair__Document_Category__c || '',
  fileFormat: src.flair__File_Format__c || WorkflowItemFileFormat.Any,
});

const mapWorkflowItemBase = (
  workflow: WorkflowFragment,
  item: WorkflowItemTableFieldsFragment,
): WorkflowItemBase => {
  return {
    id: item.Id,
    name: item.Name,
    description: item.flair__Description__c,
    attachments: item.attachments.map((attach) => mapAttachedFile(attach)),
    readonly: !item.canUpdate,
    workflowTemplateId: workflow.flair__Workflow_Template__c,
    workflowName: workflow.Name,
    workflowId: workflow.Id,
    itemTemplateId: item.flair__Workflow_Template_Item__c,
    employee: mapEmployee(workflow.employee),
    isMandatory: item.flair__Is_Mandatory__c,
    status: item.flair__Status__c as WorkflowItemStatus,
    dueDate: item.flair__Due_Date__c,
    responsible: mapResponsible(item),
    instructionsLoomUrl: item.flair__Instructions_Loom_Url__c,
    subTasks: mapSubTasks(item.subTasks),
    workflowStep: mapWorkflowStep(item.workflowStep),
    commentsCount: item.commentsCount,
  };
};

const mapWorkflowStep = (
  src: WorkflowItemTableFieldsFragment['workflowStep'],
): WorkflowStep => ({
  id: src.Id,
  name: src.flair__Step_Name__c,
  order: src.flair__Order__c,
  activatingTrigger: src.flair__Activating_Trigger__c,
  activationDate: src.flair__Activation_Date__c,
  isActive: src.flair__Is_Active__c,
  workflowItems: [],
});

const mapSubTasks = (
  subTasks: WorkflowItemTableFieldsFragment['subTasks'],
): WorkflowSubTask[] => {
  return subTasks.map((subTask) => ({
    id: subTask.id,
    text: subTask.text,
    checked: subTask.checked,
  }));
};

const mapResponsible = (
  src: WorkflowItemTableFieldsFragment,
): WorkflowItem['responsible'] => {
  if (
    src.flair__Responsible_Type2__c === WorkflowItemResponsibleType.Team &&
    src.responsibleTeam
  ) {
    return {
      type: 'team',
      id: src.responsibleTeam.Id,
      name: src.responsibleTeam.Name,
      employees: src.responsibleTeam.employees.map(
        (x): WorkflowTeamEmployeeInfo => ({
          id: x.Id,
          name: x.Name,
          avatarUrl: x.avatar?.url ?? undefined,
        }),
      ),
    };
  }
  return src.responsible ? mapEmployee(src.responsible) : null;
};
const mapAttachedFile = (
  contentDocumentLink: ContentDocumentLink,
): DocumentFile => ({
  documentId: contentDocumentLink.Id,
  contentDocumentId: contentDocumentLink.ContentDocumentId,
  fileName: contentDocumentLink.ContentDocument.FileExtension
    ? `${contentDocumentLink.ContentDocument.Title}.${contentDocumentLink.ContentDocument.FileExtension}`
    : contentDocumentLink.ContentDocument.Title,
  date: new Date(contentDocumentLink.ContentDocument.CreatedDate),
  downloadPublicUrl: contentDocumentLink.downloadUrl,
  fileType: contentDocumentLink.ContentDocument.FileType,
  viewLink: contentDocumentLink.viewUrl,
});

export const getWorkflowItemStatusColor = (
  status: WorkflowItemStatus,
): string => Theme.workflows.status[status].color;

export const getWorkflowItemStatusSoftColor = (
  status: WorkflowItemStatus,
): string => Theme.workflows.status[status].softColor;

export const mapWorkflowItemsFilterInput = (
  workflowId: string,
  filter: WorkflowItemFilterForWorkflow,
): WorkflowItemsFilterInput2 => ({
  workflowId,
  responsibleId: filter.responsibleId ? filter.responsibleId : null,
  status: isWorkflowItemStatusGraphql(filter.status) ? filter.status : null,
  myTasks: filter.onlyMyTasks === 'true',
  workflowType: null,
  year: null,
  employeeId: null,
  workflowItemTemplateId: null,
  workflowTemplateId: null,
});

export const mapWorkflowItemsFilterInput2 = (
  filter: WorkflowItemFilterForOthers &
    WorkflowItemFilterBase &
    WorkflowItemFilterForMe,
): WorkflowItemsFilterInput2 => ({
  workflowId: null,
  employeeId: filter.employeeId ?? null,
  responsibleId: filter.responsibleId ?? null,
  workflowItemTemplateId: filter.workflowItemTemplateId ?? null,
  status: isWorkflowItemStatusGraphql(filter.status) ? filter.status : null,
  myTasks: filter.onlyMyTasks === 'true',
  workflowType: null,
  workflowTemplateId: filter.workflowTemplateId,
  year: filter.year ? toISODateOnly(startOfYear(parseDate(filter.year))) : null,
});

const mapItemsSortField = (sortId: string): WorkflowsItemsListSortFieldEnum => {
  switch (sortId) {
    case 'name':
      return WorkflowsItemsListSortFieldEnum.Task;
    case 'employee':
      return WorkflowsItemsListSortFieldEnum.EmployeeName;
    case 'workflowType':
      return WorkflowsItemsListSortFieldEnum.WorkflowType;
    case 'dueDate':
      return WorkflowsItemsListSortFieldEnum.DueDate;
    case 'status':
      return WorkflowsItemsListSortFieldEnum.WorkflowStatus;
    case 'responsible':
      return WorkflowsItemsListSortFieldEnum.Responsible;
    case 'workflowName':
      return WorkflowsItemsListSortFieldEnum.Workflow;
    default:
      return WorkflowsItemsListSortFieldEnum.Workflow;
  }
};

export const mapItemsSortBy = (
  sortBy: SortingRule<WorkflowResponse>[],
): WorkflowsItemsListSortInput | null => {
  if (sortBy.length < 1) {
    return null;
  }
  return {
    field: mapItemsSortField(sortBy[0].id),
    order: sortBy[0].desc ? AscDescEnum.Desc : AscDescEnum.Asc,
  };
};
