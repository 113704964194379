import React from 'react';

import { useContext } from 'react';
import { TimeSheetApproveContext } from './TimeSheetApproveContext';
import { TimeSheetApproveModal } from './TimeSheetApproveModal';

export const TimeSheetApproveModalConnected = () => {
  const { approveModalVisible, onApproveModalClose, onApproveModalSubmit } =
    useContext(TimeSheetApproveContext);
  return (
    <TimeSheetApproveModal
      visible={approveModalVisible}
      onClose={onApproveModalClose}
      onSubmit={onApproveModalSubmit}></TimeSheetApproveModal>
  );
};
