import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { EmployeeTimeSheetsTable } from './EmployeeTimeSheetsTable';
import {
  EmployeeCustomizeTableColumnsIcon,
  useEmployeeTimeSheetColumnSettings,
} from './EmployeeCustomizeTableColumns';
import { useTimeSheetsExpand } from '../../../components/TimeSheetsTable/useTimeSheetsExpand';
import {
  createDefaultSortInput,
  mapTimeSheet,
  mapToEmployeeTableRows,
  updateTableRows,
} from '../../../components/TimeSheetsTable/mappings';
import { useEmployeeTimeSheetsQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { TimeBalancesProvider } from '../../../components/TimeSheetsTable/TimeBalanceProvider/TimeBalancesProvider';
import { EmployeeTimeSheetFilters } from './Filter/EmployeeTimeSheetFilters';
import { EmployeeTimeSheetsFilter, defaultFilter } from './Filter/types';
import { useFlairPersistentFilter } from '../../../../../hooks/useFlairPersistentFilter';
import { routes } from '../../../routes';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import { useTimeSheetDetailsPrefetch } from '../../../components/TimeSheetsTable/useTimeSheetDetailsPrefetch';
import { useIsTimeBalanceEnabled } from '../../../hooks/timeTrackingSettings/useIsTimeBalanceEnabled';

type Props = {};

const PAGE_SIZE = 30;

export const EmployeeTimeSheets: React.FC<Props> = () => {
  const isTimeBalanceEnabled = useIsTimeBalanceEnabled();
  const [timeSheetColumnSettings] = useEmployeeTimeSheetColumnSettings({
    isTimeBalanceEnabled,
  });

  const [filter, setFilter] =
    useFlairPersistentFilter<EmployeeTimeSheetsFilter>({
      defaultFilter: defaultFilter,
      storageKey: 'employee_timeSheets',
      createRouteUrl: (queryParams) =>
        routes.allTimeSheets.route.withQueryParams(queryParams).create({}),
    });

  const viewByEmployee = 'employee';

  const { data, loading, error, fetchMore } = useEmployeeTimeSheetsQuery({
    variables: {
      filter: {
        year: filter.year,
        month: filter.month,
        status: filter.status,
      },
      sort: createDefaultSortInput(viewByEmployee),
      offset: 0,
      limit: PAGE_SIZE,
      skipPaginationInfo: false,
    },
  });

  const paginationInfo = useMemo(() => {
    return data?.me.timeSheetsPagination;
  }, [data]);

  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: paginationInfo,
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo: true,
        },
      }).then();
    },
  });

  const tableRows = useMemo(
    () =>
      data
        ? mapToEmployeeTableRows(
            data.me.timeSheetsPagination.items.map(mapTimeSheet),
          )
        : [],
    [data],
  );

  const { expandedRowIds, handleExpandedChanged } = useTimeSheetsExpand();

  const tableRowsWithState = updateTableRows(tableRows, [], [], expandedRowIds);

  useTimeSheetDetailsPrefetch(tableRowsWithState);

  if (loading || !data) {
    return <Loading />;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <Card className="h7-regular">
      <Card.Header className="flexible-height">
        <Row>
          <Col>
            <EmployeeTimeSheetFilters filter={filter} onChange={setFilter} />
          </Col>
          <Col className="col-auto d-flex align-items-center p-2">
            <EmployeeCustomizeTableColumnsIcon
              isTimeBalanceEnabled={isTimeBalanceEnabled}
            />
          </Col>
        </Row>
      </Card.Header>
      <TimeBalancesProvider>
        <EmployeeTimeSheetsTable
          columnSettings={timeSheetColumnSettings}
          tableRows={tableRowsWithState}
          onExpandedChanged={(row) =>
            handleExpandedChanged(row.id, !row.expanded)
          }
          isTimeBalanceEnabled={isTimeBalanceEnabled}
        />
        {LoadingMore}
      </TimeBalancesProvider>
    </Card>
  );
};

const Loading: React.FC = () => (
  <SkeletonTable className="card-table" columns={8} rows={15} />
);
