import React from 'react';
import { GoalsPage } from '../../pages/PerformanceReview/Goals2/GoalsPage';
import { EngagementContainer } from '../EngagementContainer';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

export const ManagerGoalsPage: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems.teamDevelopment');

  useFlairBreadcrumbHook([
    { label: t('title') },
    { label: t('subMenuItems.goals.title') },
  ]);

  return (
    <EngagementContainer title={t('subMenuItems.goals.title')}>
      <GoalsPage isManagerView={true} />
    </EngagementContainer>
  );
};
