import React, { ChangeEvent, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { QuestionProps } from '../../index';

export type FreeTextInputProps = Omit<
  QuestionProps,
  | 'type'
  | 'choices'
  | 'notes'
  | 'onChangeNotes'
  | 'showOptionalComment'
  | 'requiredChoices'
>;

const FreeText: React.FC<FreeTextInputProps> = ({
  value,
  onChange,
  readOnly,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <TextareaAutosize
      className="form-control"
      value={value}
      onChange={handleChange}
      readOnly={readOnly}
      disabled={readOnly}
    />
  );
};

export default FreeText;
