import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ServerError from '../../../../../../components/ServerError';
import { useEmployeePerformanceReviewMeetingQuery } from '../../../../__generated__/graphql';
import { ComparedFeedback } from './ComparedFeedback';
import { LoadingEmployeePerformanceReviewMeeting } from './LoadingEmployeePerformanceReviewMeeting';
import { PageHeader } from './PageHeader';
import { useFlairBreadcrumbHook } from '../../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { managerRoutes } from '../../../../manager/routes';

type Params = {
  feedbackId: string;
};

const Content: React.FC<Params> = ({ feedbackId }) => {
  const tNav = useNamespacedTranslation('navigation.menuItems.teamDevelopment');
  const { data, loading, error } = useEmployeePerformanceReviewMeetingQuery({
    variables: {
      feedbackId,
    },
  });

  useFlairBreadcrumbHook(
    [
      { label: tNav('title') },
      {
        label: tNav('subMenuItems.reviews.title'),
        url: managerRoutes.reviews.route.create({}),
      },
      { label: data?.employeeFeedback.feedbackCycle.Name ?? '' },
    ],
    loading,
  );

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingEmployeePerformanceReviewMeeting />;
  }

  const {
    reviewee: { Name: employeeName },
    feedbackCycle,
  } = data.employeeFeedback;

  return (
    <>
      <PageHeader
        title={feedbackCycle.Name}
        subtitle={employeeName}
        feedbackId={feedbackId}
      />
      <ComparedFeedback />
    </>
  );
};

export const EmployeePerformanceReviewMeeting: React.FC = () => {
  const { feedbackId } = useParams<Params>();

  return (
    <>
      <Row className="justify-content-center">
        <Col lg={12}>
          <Content feedbackId={feedbackId} />
        </Col>
      </Row>
    </>
  );
};
