import React from 'react';
import PageHeader from '../../components/PageHeader';

type Props = {
  title: string;
};

export const EngagementContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <PageHeader title={title} />
      {children}
    </>
  );
};
