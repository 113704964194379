import React, { useState } from 'react';
import { Employee } from '..';
import {
  Absence,
  EmployeeCertificate,
  EmployeeFeedback,
  EmployeeSalary,
  EmployeeWorkload,
  useEmployeePageHistoryQuery,
} from '../../../__generated__/graphql';
import { Card } from 'react-bootstrap';
import {
  getChangeType,
  getHistoryDataFiltered,
  i18EmployeehistoryPrefix,
} from './utils';
import {
  mapJoinedDateToEmployeeHistory,
  mapAbsenceToEmployeeHistoryArray,
  mapCertificatesToEmployeeHistoryArray,
  mapSalariesToEmployeeHistoryArray,
  mapWorkloadsToEmployeeHistoryArray,
  mapFeedbackToEmployeeHistoryArray,
  mapEmployeeHistoryArray,
} from './mappings';
import Loading from '../Loading';
import ServerError from '../../../../../components/ServerError';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import GlobalTableFilter from '../../../components/GlobalTableFilter';
import EmployeeHistoryTable from './EmployeeHistoryTable';
import { useTranslation } from 'react-i18next';
import { useCurrencyFormatter } from '../../../../../hooks/useCurrencyFormatter';
import { EmployeeHistory, HistoryFieldType } from './types';
import { changePeriodByUnit, formatDate } from '../../../../../utils/dateUtils';

type Props = {
  employee: Employee;
};

const EmployeeHistoryPage: React.FC<Props> = ({ employee }) => {
  const { start, end } = changePeriodByUnit(new Date(), { unit: 'year' });
  const { data, loading, error } = useEmployeePageHistoryQuery({
    variables: {
      employeeId: employee.Id,
      from: formatDate(start.setDate(start.getDate() - 1), 'yyyy-MM-dd'),
      to: formatDate(end, 'yyyy-MM-dd'),
    },
  });

  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormatter();
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState('');
  const history = data?.manager.employee?.employeeHistory as EmployeeHistory[];
  const salaries = data?.manager.employee?.salaries as EmployeeSalary[];
  const certificates = data?.manager.employee
    ?.employeeCertificates as EmployeeCertificate[];
  const workloads = data?.manager.employee
    ?.currentWorkloads as EmployeeWorkload[];
  const absences = data?.manager.employee?.absences as Absence[];
  const feedbacks = data?.manager.employee?.feedbacks as EmployeeFeedback[];
  const joinedDate = data?.manager.employee?.flair__Start_Date__c;

  const historyData = [
    ...mapEmployeeHistoryArray(history, t),
    ...mapSalariesToEmployeeHistoryArray(salaries, formatCurrency, t),
    ...mapCertificatesToEmployeeHistoryArray(certificates),
    ...mapWorkloadsToEmployeeHistoryArray(workloads),
    ...mapAbsenceToEmployeeHistoryArray(absences),
    ...mapFeedbackToEmployeeHistoryArray(feedbacks),
  ];

  if (joinedDate) {
    historyData.push(mapJoinedDateToEmployeeHistory(joinedDate));
  }

  const historyDataFiltered = getHistoryDataFiltered(
    historyData,
    filterType,
    searchText,
    t,
  );

  const getFilterOptions = (history: EmployeeHistory[]) => {
    const uniqueTypeNames = Array.from(
      new Set(
        history.map((item: EmployeeHistory) =>
          t(getChangeType(item.Name as HistoryFieldType)),
        ),
      ),
    );
    return uniqueTypeNames.map((name: string) => ({
      value: name,
      label: name,
    }));
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <Card>
      <Card.Header className="h-auto">
        <GlobalTableFilter
          value={searchText}
          placeholder={t(`${i18EmployeehistoryPrefix}.table.filter.search`)}
          onFilterChange={setSearchText}
        />
        <span className="ms-auto">
          {t(`${i18EmployeehistoryPrefix}.table.filter.filterBy`)}:
        </span>
        <SingleFilter
          value={filterType}
          className="me-3 ms-3"
          options={getFilterOptions(historyData)}
          onChange={(selectedOption) => setFilterType(selectedOption || '')}
          placeholder={t(
            `${i18EmployeehistoryPrefix}.table.filter.placeholder`,
          )}
          isSearchable={true}
          styles={{
            menu: (provided) => ({
              ...provided,
              position: 'absolute',
              right: 0,
              bottom: 0,
              zIndex: 1,
              minWidth: '12.5rem',
            }),
          }}
        />
      </Card.Header>
      <Card.Body>
        <EmployeeHistoryTable historyData={historyDataFiltered} />
      </Card.Body>
    </Card>
  );
};

export default EmployeeHistoryPage;
