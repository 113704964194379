import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  useWorkflowsFilterOptionsQuery,
  WorkflowContext,
} from '../../../__generated__/graphql';
import {
  Props as WorkflowsFilterProps,
  WorkflowsFilter,
} from './WorkflowsFilter';
import { WorkflowsFilterLoading } from './WorkflowsFilterLoading';
import { mapCommonOption } from './mappings';

type Props = Omit<
  WorkflowsFilterProps,
  'workflowTemplateOptions' | 'employeeOptions'
> & {
  workflowContext: WorkflowContext;
};

export const WorkflowsFilterConnected: React.FC<Props> = ({
  workflowContext,
  ...props
}) => {
  const t = useNamespacedTranslation('workflows.filter');
  const { data, loading, error } = useWorkflowsFilterOptionsQuery({
    variables: {
      workflowContext,
    },
  });

  if (error) {
    return <div>{t('errorLoadingOptions')}</div>;
  }

  if (loading || !data) {
    return <WorkflowsFilterLoading type="workflowsForOthers" />;
  }

  const { workflowTemplates, employees } = data.workflowsFilterOptions;

  const workflowTemplateOptions = workflowTemplates.map(mapCommonOption);
  const employeeOptions = employees.map(mapCommonOption);

  return (
    <WorkflowsFilter
      {...props}
      employeeOptions={employeeOptions}
      workflowTemplateOptions={workflowTemplateOptions}></WorkflowsFilter>
  );
};
