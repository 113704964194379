import { useArchiveNotificationMutation } from '../../../../../__generated__/graphql';
import { useUserInfo } from '../../../../../context/UserInfo';

export const useArchiveNotification = () => {
  const { id: meId } = useUserInfo();

  return useArchiveNotificationMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const recordId = response.data.notifications.archiveNotification.recordId;
      if (!recordId) {
        return;
      }
      const meCacheId: string | undefined = cache.identify({
        __typename: 'Me',
        Id: meId,
      });

      // uncache archive notifications
      cache.evict({
        id: meCacheId,
        fieldName: 'archivedNotifications',
      });
      cache.gc();

      // remove from inbox notifications
      cache.modify({
        id: meCacheId,
        fields: {
          inboxNotifications: (existing, { readField }) => {
            if (!existing?.items) return existing;

            const newItems = existing.items.filter(
              (notificationRef: any) =>
                readField('Id', notificationRef) !== recordId,
            );
            const isRead = existing.items.some(
              (notificationRef: any) =>
                readField('Id', notificationRef) === recordId &&
                readField('flair__Is_Read__c', notificationRef),
            );
            return {
              ...existing,
              unreadCount: isRead
                ? existing.unreadCount
                : existing.unreadCount - 1,
              items: newItems,
              paginationInfo: {
                ...existing.paginationInfo,
                totalCount: existing.paginationInfo.totalCount - 1,
              },
            };
          },
          taskNotifications: (existing, { readField }) => {
            if (!existing?.items) return existing;

            const newItems = existing.items.filter(
              (notificationRef: any) =>
                readField('Id', notificationRef) !== recordId,
            );
            const isRead = existing.items.some(
              (notificationRef: any) =>
                readField('Id', notificationRef) === recordId &&
                readField('flair__Is_Read__c', notificationRef),
            );
            return {
              ...existing,
              unreadCount: isRead
                ? existing.unreadCount
                : existing.unreadCount - 1,
              items: newItems,
              paginationInfo: {
                ...existing.paginationInfo,
                totalCount: existing.paginationInfo.totalCount - 1,
              },
            };
          },
        },
      });
    },
    optimisticResponse: ({ id }) => {
      return {
        notifications: {
          archiveNotification: {
            recordId: id,
            error: null,
            __typename: 'NotificationPayload',
          },
        },
      };
    },
  });
};
