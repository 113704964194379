import React, { useMemo, useState } from 'react';
import { StepProp } from '../../../../components/Step';
import { FieldValues } from 'react-hook-form';
import { TicketModalContext } from './TicketModalContext';
import ExpandModal from '../../../../components/ExpandModal';
import { Form as CustomForm } from '../../../../../../components/form/Form';
import { Modal } from 'react-bootstrap';
import { Footer } from './Footer';
import { StepForm } from './StepForm';
import { FormItem, StepNamesType } from './types';
import { useAddTicketHeaderInfo } from '../hooks/useAddTicketHeaderInfo';
import { TicketPolicy } from '../types';

type Props = {
  formList: FormItem[];
  steps: StepProp<StepNamesType>[];
  visible: boolean;
  children?: React.ReactElement;
  onClose: () => void;
  onSubmit: (data: FieldValues) => void;
  isSubmitting: boolean;
  ticketPolicy: TicketPolicy | null;
};

export const BaseNewTicketModal: React.FC<Props> = ({
  onClose,
  steps,
  formList,
  visible,
  onSubmit,
  isSubmitting,
  ticketPolicy,
}) => {
  const [data, setData] = useState<FieldValues | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  // TODO if the expand modal functionality will be needed, this should be changed to use state
  const isExpanded = false;
  const maxStep = formList?.length || 0;

  const onCancel = () => {
    if (isExpanded || currentStep === 0) {
      onClose();
      return;
    }
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const formsList = useMemo(
    () => formList.map((item) => item.form),
    [formList],
  );

  const getValidationScheme = () => {
    if (isExpanded) {
      return formList.reduce(
        (acc, formItem) => {
          if (!formItem.validationScheme) return acc;

          return acc.concat(formItem.validationScheme);
        },
        [formList[0].validationScheme],
      );
    }
    return formList[currentStep].validationScheme;
  };

  const onNext = (formData: FieldValues) => {
    const newData = { ...data, ...formData };
    setData((prevState) => {
      return { ...prevState, ...newData };
    });
    if (isExpanded || currentStep >= maxStep - 1) {
      onSubmit(newData);
      return;
    }
    setCurrentStep(Math.min(currentStep + 1, formList?.length - 1 || 0));
  };

  const renderBody = () => {
    if (isExpanded) {
      return;
    }
    return <StepForm list={formsList} />;
  };
  const onChange = (formData: { [key: string]: FieldValues }) => {
    const newData = { ...data, ...formData };
    setData(newData);
  };
  const providerProps = {
    value: {
      steps,
      isExpanded,
      maxStep,
      currentStep,
      onCancel,
      onChange,
      onChangeStep: (step: number) => setCurrentStep(step),
      value: data,
      policy: ticketPolicy,
    },
  };

  const { title, description } = useAddTicketHeaderInfo({
    stepName: steps[currentStep].stepName,
    categoryName: data?.preview.category,
  });

  return (
    <TicketModalContext.Provider {...providerProps}>
      <ExpandModal
        title={title}
        description={description}
        isExpanded={isExpanded}
        hasExpand={false}
        visible={visible}
        onClose={onClose}>
        <CustomForm
          isChangeable
          defaultValues={data ? data : undefined}
          validationSchema={getValidationScheme()}
          onSubmit={onNext}>
          <Modal.Body className="p-0">{renderBody()}</Modal.Body>
          <Modal.Footer className="border-top-0">
            <Footer
              isSubmitting={isSubmitting}
              isExpanded={isExpanded}></Footer>
          </Modal.Footer>
        </CustomForm>
      </ExpandModal>
    </TicketModalContext.Provider>
  );
};
