import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

type Props = {
  year: number;
  goToNextYear: () => void;
  goToPrevYear: () => void;
};

const YearNav: React.FC<Props> = ({ year, goToNextYear, goToPrevYear }) => {
  return (
    <ButtonGroup size="sm" className="bg-gray rounded p-1">
      <Button variant="gray" onClick={goToPrevYear}>
        ← {year - 1}
      </Button>
      <Button variant="white" className="rounded">
        {year}
      </Button>
      <Button variant="gray" onClick={goToNextYear}>
        {year + 1} →
      </Button>
    </ButtonGroup>
  );
};

export default YearNav;
