import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { EmployeeFeedbackProps } from './types';
import FormattedDate from '../../../../components/date/FormattedDate';
import { TableContent } from '../../manager/components/TableContent';
import { EmptyStateCardBody } from '../EmptyStateCard';
import { Feedback } from './types';
import { EmployeesAvatarGroup } from '../../../../atomic/molecules/EmployeesAvatarGroup/EmployeeAvatarGroup';
import { mapToFeedback } from './mappings';

type Props = {
  feedbacks: ReadonlyArray<EmployeeFeedbackProps>;
  detailsLink: (feedbackId: string, cycleName: string) => React.ReactNode;
  showReviewers: boolean;
  showEmployees: boolean;
};

const EmptyFeedbacks: React.FC = () => {
  const { t } = useTranslation();

  return <EmptyStateCardBody title={t('performanceReview.goals.emptyState')} />;
};

const i18Path = 'employeePage.performanceReview.pastPerformanceReviews.table';

const PerformanceReviewTable: React.FC<Props> = ({
  feedbacks,
  detailsLink,
  showReviewers,
  showEmployees,
}) => {
  const { t } = useTranslation();

  //TODO:: sort is by default on name, there shouldn't be any sort at the beginning
  const columns: Column<Feedback>[] = useMemo(
    () =>
      [
        {
          Header: () => (
            <Trans
              t={t}
              i18nKey="employeePage.performanceReview.pastPerformanceReviews.table.cycleName"
            />
          ),
          accessor: 'cycleName',
          Cell: (props: any) =>
            detailsLink(props.row.original.id, props.row.original.cycleName),
        },
        {
          Header: () => (
            <Trans
              t={t}
              i18nKey="employeePage.performanceReview.pastPerformanceReviews.table.dueDate"
            />
          ),
          accessor: 'dueDate',
          Cell: ({ value }: any) =>
            value ? (
              <FormattedDate day={value} format="short" />
            ) : (
              <span>-</span>
            ),
        },
        showReviewers
          ? {
              Header: () => (
                <Trans
                  t={t}
                  i18nKey="employeePage.performanceReview.pastPerformanceReviews.table.reviewer"
                />
              ),
              accessor: 'reviewer',
            }
          : null,
        showEmployees
          ? {
              Header: () => (
                <Trans
                  t={t}
                  i18nKey="employeePage.performanceReview.pastPerformanceReviews.table.employee"
                />
              ),
              accessor: 'employee',
            }
          : null,
        {
          Header: () => (
            <Trans
              t={t}
              i18nKey="employeePage.performanceReview.pastPerformanceReviews.table.peers"
            />
          ),
          accessor: 'peerFeedbacks',
          Cell: (props: any) =>
            props.row.original.peers.length ? (
              <EmployeesAvatarGroup
                avatarSize="xs"
                gapSize={20}
                recipients={props.row.original.peers}
                maxAvatarsToShow={3}
                showAvatarOnly={true}
              />
            ) : (
              <span>-</span>
            ),
        },
        {
          Header: () => <Trans t={t} i18nKey={`${i18Path}.completed`} />,
          accessor: 'id',
          Cell: () => t(`${i18Path}.yes`),
        },
      ].filter((x) => x !== null) as Column<Feedback>[],
    [showReviewers, showEmployees, t, detailsLink],
  );

  const data = useMemo(
    () => sortBy(feedbacks.map(mapToFeedback), ['cycleName']),
    [feedbacks],
  );

  if (!data.length) {
    return <EmptyFeedbacks />;
  }

  return (
    <TableContent
      columns={columns}
      data={data}
      getRowProps={() => ({ role: 'button' })}
    />
  );
};

export default PerformanceReviewTable;
