import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import { Theme } from '../../../../../../theme';
import { CandidateEvaluation } from '../types';
import { Params } from './types';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { EmptyStateCardBody } from '../../../../components/EmptyStateCard';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import Section from '../Section';
import { TableContent } from '../../../../manager/components/TableContent';
import { Column } from 'react-table';
import ScoreContent from './ScoreContent';
import { getEmployeeInitialsFromName } from '../../../../utils/employeeInitials';
import FeedbackSidebarDetails from './FeedbackSidePanel/FeedbackSidebarDetails';
import Avatar from '../../../../../../atomic/molecules/avatar/Avatar';

export const Icon: React.FC<{ title?: string }> = ({ title }) => (
  <FlairIcon tooltip={title} size="sm" icon="thumbs-up-outline" />
);

export const IconRotated: React.FC<{ title?: string }> = ({ title }) => (
  <FlairIcon
    tooltip={title}
    size="sm"
    icon="thumbs-up-outline"
    style={{ transform: 'rotate(175)' }}
  />
);

const mapEvaluated = (candidateEvaluation: CandidateEvaluation) =>
  candidateEvaluation.flair__Evaluated__c;

export const EvaluationItemContainer = styled.div({
  borderBottom: `1px solid ${Theme.color.paper3}`,
});

export const StyledLine = styled.hr({
  color: Theme.color.paper3,
  margin: 0,
});

const i18Path = 'recruitment.candidate.detailsPage';

const TeamFeedback: React.FC<Params> = ({ candidateEvaluations }) => {
  const evaluatedEvaluations = candidateEvaluations.filter(mapEvaluated);
  const t = useNamespacedTranslation(i18Path);
  const [selectedEvaluationIndex, setSelectedEvaluationIndex] =
    useState<number>(-1);

  const columns: Column<CandidateEvaluation>[] = useMemo(
    () =>
      [
        {
          Header: t('evaluator'),
          accessor: 'employee',
          Cell: ({ value: { Name, avatar } }: any) => (
            <div className="d-flex align-items-center flex-nowrap">
              <Avatar
                avatarUrl={avatar?.url ?? ''}
                initials={getEmployeeInitialsFromName(Name)}
                size="xs"
              />
              <div className="ms-2">{Name}</div>
            </div>
          ),
        },
        {
          Header: t('template'),
          accessor: 'evaluationTemplate',
          Cell: ({ value }: any) => value?.Name ?? t('quickEvaluation'),
        },
        {
          Header: t('score'),
          accessor: 'flair__Final_Score__c',
          Cell: ({ value }: any) =>
            value == null ? (
              '--'
            ) : (
              <ScoreContent
                score={value}
                labelPosition="right"
                labelClassName="pr-3"
                showScore={true}
                useSplitter={true}
              />
            ),
        },
        {
          Header: '',
          accessor: 'id',
          Cell: () => (
            <FlairIcon
              icon="chevron-forward-outline"
              aria-label="Edit"
              role="button"
            />
          ),
        },
      ].filter((x) => x !== null) as Column<CandidateEvaluation>[],
    [t],
  );

  return (
    <Section title={t('teamFeedback')} bodyClassName="p-0">
      {!evaluatedEvaluations || evaluatedEvaluations.length === 0 ? (
        <EmptyStateCardBody title={t('noTeamFeedbackYet')} />
      ) : (
        <>
          <TableContent
            columns={columns}
            onRowClick={(selected) =>
              setSelectedEvaluationIndex(
                evaluatedEvaluations.findIndex((e) => e.Id === selected.Id),
              )
            }
            data={evaluatedEvaluations}
            pageSize={10}
          />
          {selectedEvaluationIndex !== -1 && (
            <FeedbackSidebarDetails
              evaluations={evaluatedEvaluations}
              selectedEvaluationIndex={selectedEvaluationIndex}
              setSelectedEvaluationIndex={setSelectedEvaluationIndex}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default TeamFeedback;
