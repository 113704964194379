import styled from '@emotion/styled';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/button';
import {
  AutoBreaksRules,
  TimeEntryStats,
} from '../../../../components/AutoBreaks/shared';
import SoftBadge from '../../../../components/SoftBadge';
import DateAndTimeReadonly from '../components/DateAndTimeReadonly';
import TimeBreakInputs from '../components/TimeBreakInputs';
import {
  calculateDurationInMinutes,
  DateAndTime,
  isMultiday,
  TimeEntryBreakItem,
  toDateTime,
} from '../logic';
import { ValidationResult } from '../logic/validation';
import { BreakDuration } from './BreakDuration';
import { TimeEntryWorkingTimeIndicator } from './TimeEntryWorkingTimeIndicator';

type Props = {
  autoBreakRules: AutoBreaksRules | null;
  timeStats: TimeEntryStats;
  start: DateAndTime;
  clockOutDate: Date;
  breaks: TimeEntryBreakItem[];
  validationErrors: ValidationResult;
  isDirty: boolean;
  isAutoAdjusted: boolean;
  onBreakChange: (value: TimeEntryBreakItem) => void;
  onBreakDelete: (value: TimeEntryBreakItem) => void;
  onAddBreak: () => void;
  onAutoAdjustBreaks: () => void;
  onUndo: () => void;
};

export const TimeEntryClockoutDumb: React.FC<Props> = ({
  start,
  breaks,
  autoBreakRules,
  timeStats,
  clockOutDate,
  validationErrors,
  isDirty,
  isAutoAdjusted,
  onBreakChange,
  onBreakDelete,
  onAddBreak,
  onAutoAdjustBreaks,
  onUndo,
}) => {
  const { t } = useTranslation();

  const isMultidayTimeEntry = isMultiday({ start, end: null }, clockOutDate);

  const allErrors = Array.from(validationErrors.values()).flatMap((x) => x);

  return (
    <Container>
      <Row className="flex-xs-wrap">
        <LeftColumn>{t('timeTrackingNew.clockoutCard.startTime')}</LeftColumn>
        <div>
          <DateAndTimeReadonly value={start} isDateVisible={false} />
        </div>
      </Row>
      {breaks.map((breakItem: TimeEntryBreakItem) => (
        <BreakRow
          key={breakItem.uniqueId}
          breakItem={breakItem}
          minBreakDuration={autoBreakRules?.minumumBreakDuration ?? 0}
          isMultidayTimeEntry={isMultidayTimeEntry}
          validationErrors={validationErrors}
          onChange={onBreakChange}
          onDelete={onBreakDelete}
        />
      ))}
      <Row className="flex-xs-wrap">
        <LeftColumn>{t('timeTrackingNew.clockoutCard.endTime')}</LeftColumn>
        <div>
          <DateAndTimeReadonly
            value={toDateTime(clockOutDate)}
            isDateVisible={false}
          />
        </div>
      </Row>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            variant="link"
            className="ps-0 me-3"
            size="sm"
            label={t('timeTrackingNew.clockoutCard.addBreak')}
            onClick={onAddBreak}
          />
        </div>
        <div className="d-flex align-items-center">
          {!isAutoAdjusted && !isDirty && (
            <Button
              variant="link"
              size="sm"
              label={t('timeTrackingNew.clockoutCard.fixBreakTime')}
              onClick={onAutoAdjustBreaks}
            />
          )}
          {isDirty && (
            <Button
              variant="link"
              size="sm"
              label={t('timeTrackingNew.clockoutCard.undoChanges')}
              onClick={onUndo}
            />
          )}
          <TimeEntryWorkingTimeIndicator
            timeEntryId={'clockoutTimeEntryId'}
            timeStats={timeStats}
            breaksDeductionStatus={undefined}
          />
        </div>
      </div>
      {isAutoAdjusted && (
        <Row className="mb-2">
          <SoftBadge>
            {t('timeTrackingNew.clockoutCard.autoAdjusted')}
          </SoftBadge>
        </Row>
      )}
      {allErrors.map((error, i) => (
        <Row key={`error_${i}`} className="mb-2">
          <SoftBadge variant="danger">{error}</SoftBadge>
        </Row>
      ))}
    </Container>
  );
};

type BreakRowProps = {
  breakItem: TimeEntryBreakItem;
  minBreakDuration: number;
  isMultidayTimeEntry: boolean;
  validationErrors: ValidationResult;
  onChange: (value: TimeEntryBreakItem) => void;
  onDelete: (value: TimeEntryBreakItem) => void;
};

const BreakRow: React.FC<BreakRowProps> = ({
  breakItem,
  minBreakDuration,
  isMultidayTimeEntry,
  validationErrors,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation();
  // const durationError = getFirstErrorFromPath(
  //   validationErrors,
  //   `breaks.${breakItem.uniqueId}`,
  // );

  return (
    <Row className="flex-xs-wrap">
      <LeftColumn>{t('timeTrackingNew.clockoutCard.break')}</LeftColumn>
      <RightColumn>
        <TimeBreakInputs
          isMultiday={isMultidayTimeEntry}
          readonly={false}
          validationErrors={validationErrors}
          pathPrefix={`breaks.${breakItem.uniqueId}.`}
          breakItem={breakItem}
          isDeleteVisible={breakItem.breakId === null}
          isHighlighted={breakItem.isBreakAutoFixed}
          onChange={onChange}
          onDelete={onDelete}
        />
      </RightColumn>
      <DurationColumn>
        <BreakDuration
          actualBreakDuration={calculateDurationInMinutes(breakItem)}
          minBreakDuration={minBreakDuration}
        />
      </DurationColumn>
    </Row>
  );
};

const Container = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('fs-5', className)} />
);

const Row = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={classNames('d-flex', 'mb-3', 'position-relative', className)}
  />
);

const LeftColumn = styled.div({
  minWidth: 100,
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 576px)': {
    minWidth: 70,
  },
});

const RightColumn = styled.div({
  flexGrow: 1,
});

const DurationColumn = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});
