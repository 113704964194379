import React from 'react';
import { Theme } from '../../../../theme';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type Props = {
  onClick: () => void;
  className?: string;
};

export const DeleteOptionIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <FlairIcon
      icon="close-outline"
      onClick={onClick}
      color={Theme.color.paper1}
      className={className}
      cursor="pointer"
    />
  );
};
