import React from 'react';
import { SmileySurvey } from './SmileySurvey';
import Loading from './Loading';
import { useMyCheckinsSurveysQuery } from '../../../__generated__/graphql';

const CheckinSurveys: React.FC = () => {
  const { data, loading, error } = useMyCheckinsSurveysQuery();

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return null;
  }

  const surveys = data.me.checkinsSurveys;
  if (surveys.length === 0) {
    return null;
  }

  return (
    <>
      {surveys.map((survey) =>
        survey.cycles.map((cycle) => (
          <SmileySurvey
            key={survey.id + cycle.id}
            survey={survey}
            cycle={cycle}
          />
        )),
      )}
    </>
  );
};

export default CheckinSurveys;
