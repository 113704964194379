import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import SkeletonInput from '../../../dashboard/components/Skeleton/Input';

const Loading: React.FC = () => {
  return (
    <Row>
      <Col>
        <Row>
          <Col className="text-center">
            <Skeleton height={35} width={150} className="mb-3" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Skeleton height={22} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <SkeletonInput />
          </Col>
        </Row>
        <Row>
          <Col>
            <SkeletonInput />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Loading;
