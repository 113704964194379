import React from 'react';
import { uniqBy } from 'lodash';
import { AbsenceRequestMultipleFilter } from './AbsenceRequestMultipleFilter';
import {
  TYPE_FILTER,
  COLLEAGUES_IDS_FILTER,
  AbsenceRequestFiltersType,
  filterDefaultData,
} from '../types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { routes as mainRoutes } from '../../../../routes';
import ClearFilters from '../../../../components/ClearFilters';
import { AbsenceRequest } from '../../types';

const i18Path = 'absences.myAbsences.table';

type AbsenceRequestFilterProps = {
  filterData: AbsenceRequestFiltersType;
  data: AbsenceRequest[];
};

const AbsenceRequestFilter: React.FC<AbsenceRequestFilterProps> = ({
  filterData,
  data,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const route = mainRoutes.pendingAbsenceRequests.route
    .withQueryParams({ ...filterDefaultData })
    .create({});

  const typeOptions = uniqBy(
    data
      .map((c) => c.categoryName)
      .map((s) => ({
        label: s || '',
        value: s || '',
      }))
      .filter((r) => !!r.value),
    'value',
  );

  const employeeOptions = uniqBy(
    data
      .map((c) => c.requester)
      .map((s) => ({
        label: s.name || '',
        value: s.id || '',
      }))
      .filter((r) => !!r.value),
    'value',
  );

  return (
    <div className="d-flex gap-2 flex-wrap align-items-center">
      <span className="me-3 text-nowrap">{t('filterBy')}</span>
      <AbsenceRequestMultipleFilter
        className="me-3"
        options={typeOptions}
        filterData={filterData}
        filterKey={TYPE_FILTER}
        placeholder={t('filterType')}
      />
      <AbsenceRequestMultipleFilter
        className="me-3"
        options={employeeOptions}
        filterData={filterData}
        filterKey={COLLEAGUES_IDS_FILTER}
        placeholder={t('employee')}
      />
      <ClearFilters route={route} />
    </div>
  );
};

export default AbsenceRequestFilter;
