import React from 'react';
import { useTranslation } from 'react-i18next';
import { CandidatesFilter } from './types';
import ClearFilters from './ClearFilters';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';
import { useCandidateListFilterOptionsQuery } from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import Skeleton from 'react-loading-skeleton';

type Props = {
  filterData: CandidatesFilter;
  handleFilterChange: (filter: CandidatesFilter) => void;
};

const FilterItem: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => {
  return <div className={`me-3 ${className}`}>{children}</div>;
};

const CandidatesListFilter: React.FC<Props> = ({
  filterData,
  handleFilterChange,
}) => {
  const { t } = useTranslation();

  const { data, loading, error } = useCandidateListFilterOptionsQuery();

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.candidateListFilterOptions) return <ServerError />;

  const stageOptions = data.candidateListFilterOptions.stages.filter((s) => s);
  const sourceOptions = data.candidateListFilterOptions.sources.filter(
    (s) => s,
  );

  return (
    <div className="d-none d-md-flex align-items-center">
      <span className="me-3 text-nowrap">
        {t('employeePage.teamCalendar.filterBy')}
      </span>
      <FilterItem>
        <FlairMultiSelectDropdown
          options={stageOptions}
          selectedOptions={filterData.stages ?? []}
          onChange={(selectedOptions) =>
            handleFilterChange({
              ...filterData,
              stages: selectedOptions.map((o) => o.value),
            })
          }
          placeholder={t(
            'recruitment.jobCandidatesPage.table.filters.funnelStage',
          )}
          isSearchable={true}
        />
      </FilterItem>
      <FilterItem>
        <SingleFilter
          placeholder={t(
            'recruitment.jobCandidatesPage.table.filters.applicationSource',
          )}
          isSearchable={true}
          options={sourceOptions}
          value={filterData.source}
          onChange={(value) =>
            handleFilterChange({ ...filterData, source: value })
          }
        />
      </FilterItem>

      <FilterItem>
        <ClearFilters />
      </FilterItem>
    </div>
  );
};

const Loading: React.FC = () => (
  <div className="d-flex align-items-center">
    <Skeleton width={200} height={27} />
  </div>
);

export default CandidatesListFilter;
