import React, { ReactNode, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup } from './FormGroup';
import InputError from './InputError';
import InputLabel from './InputLabel';
import ScrollToError from './ScrollToError';
import { getError } from './utils';

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  children?: ReactNode;
  disabled?: boolean;
  required?: boolean;
};

export const MultiSelect: React.FC<Props> = ({
  label,
  name,
  disabled,
  required,
  ...rest
}) => {
  const ref = useRef(null);
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext();

  const error = getError(name, errors);

  return React.useMemo(
    () => (
      <FormGroup ref={ref}>
        <InputLabel label={label} required={required} />
        <Controller
          as={
            <Form.Control
              disabled={disabled}
              isInvalid={!!error}
              readOnly={isSubmitting}
              as="select"
              multiple
            />
          }
          name={name}
          control={control}
          onChange={([event]) => {
            const options = event.currentTarget
              .options as HTMLOptionsCollection;

            const selected = [];
            for (let i = 0; i < options.length; i++) {
              const option = options[i];

              if (option.selected) {
                selected.push(option.value);
              }
            }

            return selected;
          }}
          {...rest}
        />

        <InputError error={error} />
        <ScrollToError name={name} inputContainerRef={ref} />
      </FormGroup>
    ),
    [control, error, isSubmitting, label, name, disabled, required, rest, ref],
  );
};
