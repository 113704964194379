import * as React from 'react';

const PersonCircleIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="28" height="28" rx="14" fill="#CCF5F2" />
      <path
        d="M16.75 10.5C16.6275 12.1522 15.375 13.5 14 13.5C12.625 13.5 11.3703 12.1525 11.25 10.5C11.125 8.78125 12.3438 7.5 14 7.5C15.6563 7.5 16.875 8.8125 16.75 10.5Z"
        stroke="#00CBBF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0001 15.5C11.2813 15.5 8.52195 17 8.01133 19.8313C7.94976 20.1725 8.14289 20.5 8.50008 20.5H19.5001C19.8576 20.5 20.0507 20.1725 19.9891 19.8313C19.4782 17 16.7188 15.5 14.0001 15.5Z"
        stroke="#00CBBF"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default PersonCircleIcon;
