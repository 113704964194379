import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import ServerError from '../../../../components/ServerError';
import { useEmployeeByTeamSelectQuery } from '../../__generated__/graphql';
import { EmployeeByTeamSelect } from './EmployeeByTeamSelect';
import { EmployeeByTeamSelectWithButtons } from './EmployeeByTeamSelectButtons';
import {
  mapEmployeeOption,
  mapTeamWithEmployeeOption,
  mapToEmployeeInfo,
} from './mappings';
import { EmployeeInfo } from './types';

type Props = {
  employeeIds: string[];
  onAddEmployees: (employees: EmployeeInfo[]) => void;
  onRemoveAll: () => void;
  disabled?: boolean;
  removeAllDisabled?: boolean;
  selectAllReportsVisible?: boolean;
  inline?: boolean;
};

export const EmployeeByTeamSelectConnected: React.FC<Props> = ({
  employeeIds,
  onAddEmployees,
  onRemoveAll,
  disabled,
  removeAllDisabled,
  selectAllReportsVisible,
  inline = false,
}) => {
  const { data, loading, error } = useEmployeeByTeamSelectQuery();

  const getEmployeeInfos = useCallback(
    (employeeIds: string[]): EmployeeInfo[] => {
      if (!data) {
        return [];
      }
      const employees: EmployeeInfo[] = employeeIds
        .map((employeeId) => {
          const employeeObj = data.activeEmployees.find(
            (x) => x.Id === employeeId,
          );
          return employeeObj ? mapToEmployeeInfo(employeeObj) : null;
        })
        .filter((x): x is EmployeeInfo => x !== null);
      return employees;
    },
    [data],
  );

  const handleAddEmployees = useCallback(
    (employeeIds: string[]) => {
      onAddEmployees(getEmployeeInfos(employeeIds));
    },
    [getEmployeeInfos, onAddEmployees],
  );

  const handleSelectAllReports = useCallback(() => {
    if (!data) {
      return;
    }
    const reportIds = data.manager.employees.map((x) => x.Id);
    onAddEmployees(getEmployeeInfos(reportIds));
  }, [data, getEmployeeInfos, onAddEmployees]);

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return (
      <Loading
        selectAllReportsVisible={selectAllReportsVisible}
        inline={inline}
      />
    );
  }

  const allEmployeeOptions = data.activeEmployees.map(mapEmployeeOption);
  const teamWithMembersOptions = data.teams.map(mapTeamWithEmployeeOption);

  return (
    <Row>
      <Col sm={inline ? '6' : '12'}>
        <EmployeeByTeamSelect
          employeeOptions={allEmployeeOptions}
          employeeIds={employeeIds}
          teamWithMembersOptions={teamWithMembersOptions}
          onAddEmployees={handleAddEmployees}
        />
      </Col>
      <Col
        sm={inline ? '6' : '12'}
        className={`d-flex align-items-center ${!inline ? 'mt-3' : ''}`}>
        <EmployeeByTeamSelectWithButtons
          disabled={disabled}
          selectAllReportsVisible={selectAllReportsVisible}
          onSelectAllReports={handleSelectAllReports}
          removeAllDisabled={removeAllDisabled}
          onRemoveAll={onRemoveAll}
        />
      </Col>
    </Row>
  );
};

const Loading: React.FC<{
  selectAllReportsVisible?: boolean;
  inline?: boolean;
}> = ({ selectAllReportsVisible, inline }) => {
  return (
    <Row>
      <Col sm={inline ? '6' : '12'} className="d-flex">
        <Skeleton width={60} height={29} className="me-2" />
        <Skeleton width={70} height={29} className="me-2" />
        <Skeleton width={90} height={29} className="me-2" />
        <Skeleton width={240} height={29} />
      </Col>
      <Col
        sm={inline ? '6' : '12'}
        className={`d-flex align-items-center ${!inline ? 'mt-3' : ''}`}>
        {selectAllReportsVisible && (
          <Skeleton width={140} height={30} className="me-2" />
        )}
        <Skeleton width={80} height={30} />
      </Col>
    </Row>
  );
};
