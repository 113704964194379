import React from 'react';
import { Theme } from '../../../../../theme';

type Props = {
  max: number;
  cur: number;
  warningThreshold: number;
  errorThreshold: number;
};

export const MessageLengthCounter: React.FC<Props> = ({
  max,
  cur,
  warningThreshold,
  errorThreshold,
}) => {
  const getColor = () => {
    if (cur > errorThreshold) {
      return Theme.color.danger;
    }
    if (cur > warningThreshold) {
      return Theme.color.warning;
    }
    return Theme.color.dark;
  };

  return (
    <small style={{ color: getColor() }}>
      {cur}/{max}
    </small>
  );
};
