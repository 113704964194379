import styled from '@emotion/styled';
import { times } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import {
  CellProps,
  Column,
  useFilters,
  useRowSelect,
  useTable,
} from 'react-table';
import FormattedDate from '../../../../../components/date/FormattedDate';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { Link } from '../../../../../Router';
import Checkbox from '../../../components/Checkbox';
import CompactColumn from '../../../components/Table/CompactColumn';
import WorkingTimeDiff from '../../../components/TimeEntryChangeRequest/WorkingTimeDiff';
import { routes } from '../../../routes';
import { TimeEntryChangeRequestTable_TimeEntryChangeRequestFragment } from '../../../__generated__/graphql';
import { BulkActionsAlert, RowAction } from './Actions';
import Hint from '../../../../../components/hint';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type TimeEntryChangeRequestProps =
  TimeEntryChangeRequestTable_TimeEntryChangeRequestFragment;

type Props = {
  requests: ReadonlyArray<TimeEntryChangeRequestProps>;
  filter: string;
};

type EmployeeRow = {
  id: string;
  name: string;
};

type BreakChange = {
  type: 'break';
  newValue: string;
  oldValue: string | null;
  newSecondValue: string | null;
  oldSecondValue: string | null;
};

type StartDatetimeChange = {
  type: 'startDatetime';
  newValue: string;
  oldValue: string | null;
};

type EndDatetimeChange = {
  type: 'endDatetime';
  newValue: string;
  oldValue: string | null;
};

type Change = BreakChange | StartDatetimeChange | EndDatetimeChange;

type WorkingTime = {
  oldValue: number | null;
  newValue: number;
};

type RequestRow = {
  id: string;
  employee: EmployeeRow;
  date: string;
  notes: string | null; // todo: map from flair__Creation_Time_Entry_Notes__c
  changes: Change[];
  workingTime: WorkingTime | null;
  totalBreak: WorkingTime | null;
};

const Strike = styled.span`
  text-decoration: line-through;
`;

const Dl = styled.dl`
  display: grid;
  grid-template-columns: max-content auto;
  margin-block-end: 0;
`;

const Dt = styled.dt`
  grid-column-start: 1;
`;

const Dd = styled.dd`
  grid-column-start: 2;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Tr = styled.tr`
  button {
    visibility: hidden;
  }

  &:hover button {
    visibility: visible;
  }
`;

const DisplayChange: React.FC<{ changes: Change[] }> = ({ changes }) => {
  const { t } = useTranslation();

  return (
    <Dl>
      {changes.map((change, i) => (
        <React.Fragment key={i}>
          <Dt>
            {change.type === 'startDatetime' && (
              <Trans
                t={t}
                i18nKey="timeTracking.changeRequests.row.startTime"
              />
            )}
            {change.type === 'break' && (
              <Trans t={t} i18nKey="timeTracking.changeRequests.row.break" />
            )}
            {change.type === 'endDatetime' && (
              <Trans t={t} i18nKey="timeTracking.changeRequests.row.endTime" />
            )}
          </Dt>
          <Dd className="ms-4">
            {change.oldValue &&
              moment(change.oldValue).diff(
                moment(change.newValue),
                'minutes',
              ) !== 0 && (
                <>
                  <Strike>
                    <FormattedDateTime
                      dateTime={change.oldValue}
                      format={
                        moment(change.oldValue).date() ===
                        moment(change.newValue).date()
                          ? 'time'
                          : 'compact'
                      }></FormattedDateTime>
                  </Strike>{' '}
                </>
              )}
            <FormattedDateTime
              dateTime={change.newValue}
              format="time"></FormattedDateTime>
            {change.type === 'break' && (
              <>
                {change.newSecondValue && <>{' - '}</>}
                {change.newSecondValue &&
                  change.oldSecondValue &&
                  moment(change.oldSecondValue).diff(
                    moment(change.newSecondValue),
                    'minutes',
                  ) !== 0 && (
                    <>
                      <Strike>
                        <FormattedDateTime
                          dateTime={change.oldSecondValue}
                          format="time"></FormattedDateTime>
                      </Strike>{' '}
                    </>
                  )}
                {change.newSecondValue && (
                  <FormattedDateTime
                    dateTime={change.newSecondValue}
                    format="time"></FormattedDateTime>
                )}
              </>
            )}
          </Dd>
        </React.Fragment>
      ))}
    </Dl>
  );
};

const useTableColumns = (): Column<RequestRow>[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          Header: () => (
            <Trans t={t} i18nKey="timeTracking.changeRequests.table.employee" />
          ),
          accessor: 'employee',
          Cell: (props) => (
            <div className="d-flex gap-2 align-items-center">
              <Link
                to={routes.employeePage.route}
                employeeId={props.row.original.employee.id}>
                {props.value.name}
              </Link>
              {props.row.original.notes?.length && (
                <Hint
                  id={props.row.original.id + '_notes'}
                  mode="popover"
                  text={props.row.original.notes}>
                  <FlairIcon
                    className="d-flex align-items-center"
                    size="sm"
                    icon="sticky-note-outline"
                  />
                </Hint>
              )}
            </div>
          ),
          filter: (rows, id, value: string) =>
            rows.filter((row) => {
              const rowValue: EmployeeRow = row.values[id[0]];

              return rowValue.name.toLowerCase().includes(value.toLowerCase());
            }),
        },
        {
          Header: () => (
            <Trans t={t} i18nKey="timeTracking.changeRequests.table.date" />
          ),
          accessor: 'date',
          Cell: (props) => <FormattedDate day={props.value} />,
        },
        {
          Header: () => (
            <Trans t={t} i18nKey="timeTracking.changeRequests.table.changes" />
          ),
          accessor: 'changes',
          Cell: (props) => <DisplayChange changes={props.value} />,
        },
        {
          Header: () => (
            <Trans
              t={t}
              i18nKey="timeTracking.changeRequests.table.workingTime"
            />
          ),
          accessor: 'workingTime',
          Cell: ({ value }) =>
            value ? (
              <WorkingTimeDiff
                oldValue={value.oldValue}
                newValue={value.newValue}
              />
            ) : (
              <></>
            ),
        },
        {
          Header: () => (
            <Trans
              t={t}
              i18nKey="timeTracking.changeRequests.table.totalBreak"
            />
          ),
          accessor: 'totalBreak',
          Cell: ({ value }) =>
            value ? (
              <WorkingTimeDiff
                oldValue={value.oldValue}
                newValue={value.newValue}
              />
            ) : (
              <></>
            ),
        },
        {
          id: 'approve',
          accessor: 'id',
          Cell: (props) => <RowAction requestId={props.value} />,
        },
      ] as Column<RequestRow>[],
    [t],
  );
};

const buildChanges = (req: TimeEntryChangeRequestProps): Change[] => {
  let changes: Change[] = [
    {
      type: 'startDatetime',
      newValue: req.flair__Start_Datetime__c,
      oldValue: req.timeEntry ? req.timeEntry.flair__Start_Datetime__c : null,
    },
  ];

  times(req.breaks.length, (i) => {
    const b = req.breaks[i];
    const breakEntry = b.timeEntryBreak;

    changes.push({
      type: 'break',
      newValue: b.flair__Start_Datetime__c,
      oldValue: breakEntry ? breakEntry.flair__Start_Datetime__c : null,
      newSecondValue: b.flair__End_Datetime__c,
      oldSecondValue: breakEntry ? breakEntry.flair__End_Datetime__c : null,
    });
  });

  if (req.flair__End_Datetime__c)
    changes.push({
      type: 'endDatetime',
      newValue: req.flair__End_Datetime__c,
      oldValue: req.timeEntry ? req.timeEntry.flair__End_Datetime__c : null,
    });

  return changes;
};

const useTableData = (
  requests: ReadonlyArray<TimeEntryChangeRequestProps>,
): RequestRow[] =>
  useMemo(
    () =>
      requests.map((req) => {
        const changes = buildChanges(req);

        return {
          id: req.Id,
          employee: {
            id: req.employee.Id,
            name: req.employee.Name,
          },
          notes: req.flair__Creation_Time_Entry_Notes__c,
          date: req.flair__Start_Datetime__c,
          changes: changes,
          workingTime: {
            newValue: req.flair__Working_Period_In_Hours__c,
            oldValue: req.timeEntry
              ? req.timeEntry.flair__Working_Period_In_Hours__c
              : null,
          },
          totalBreak: {
            newValue: req.flair__Total_Break_Period_In_Hours__c,
            oldValue: req.timeEntry
              ? req.timeEntry.flair__Total_Break_Period_In_Hours__c
              : null,
          },
        };
      }),
    [requests],
  );

const TimeEntryChangeRequestsTable: React.FC<Props> = ({
  filter,
  requests,
}) => {
  const columns = useTableColumns();
  const data = useTableData(requests);
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    headers,
    prepareRow,
    setFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const { onChange, checked } = getToggleAllRowsSelectedProps();
            return (
              <Checkbox id="select-all" checked={checked} onChange={onChange} />
            );
          },
          Cell: ({ row }: CellProps<RequestRow>) => {
            const { onChange, checked } = row.getToggleRowSelectedProps();
            return (
              <Checkbox
                id={row.original.id}
                checked={checked}
                onChange={onChange}
              />
            );
          },
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    setFilter('employee', filter);
  }, [setFilter, filter]);

  return (
    <>
      <Table
        className="card-table table-nowrap"
        size="sm"
        responsive
        hover
        {...getTableProps()}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th {...header.getHeaderProps()}>{header.render('Header')}</th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let classNames = ['align-top'];
                  if (cell.column.id === 'approve') {
                    classNames.push('text-end');
                  }

                  return cell.column.id === 'selection' ? (
                    <CompactColumn
                      {...cell.getCellProps({
                        className: classNames.join(' '),
                      })}>
                      {cell.render('Cell')}
                    </CompactColumn>
                  ) : (
                    <td
                      {...cell.getCellProps({
                        className: classNames.join(' '),
                      })}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <BulkActionsAlert
        onClose={toggleAllRowsSelected}
        selectedIds={selectedFlatRows.map((s) => s.original.id)}
      />
    </>
  );
};

export default TimeEntryChangeRequestsTable;
