import * as React from 'react';
import FlairCard from '../../../../../../atomic/templates/FlairCard/FlairCard';
import classNames from 'classnames';
import { TimeSince } from '../../../../../../components/TimeSince';
import './SimpleUpdateCard.css';

type Props = {
  children: React.ReactNode;
  createdDate: Date;
  avatar: React.ReactNode;
  contentClassName?: string;
};

const SimpleUpdateCard: React.FC<Props> = ({
  children,
  createdDate,
  avatar,
  contentClassName,
}) => {
  return (
    <FlairCard
      cardContainerClassName="simple-update-card-container"
      contentClassName={contentClassName}>
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex-all-center">{avatar}</div>
          <TimeSince
            date={createdDate}
            className={classNames('h5-regular', 'created-date')}
          />
        </div>
        <div className="mt-3-5">{children}</div>
      </div>
    </FlairCard>
  );
};

export default SimpleUpdateCard;
