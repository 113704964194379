import styled from '@emotion/styled';
import { Moment } from 'moment';
import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { toFormattedDateInterval } from '../../../../../utils/dateInterval';

import { PureQueryOptions } from '@apollo/client';
import { Theme } from '../../../../../theme';
import DurationString from '../Common/DurationString';
import { getNotAvailableIcon } from '../Common/logic';
import ShiftLabelsTags from '../Common/ShiftsPopover/ShiftLabelsTags';
import ShiftPopover from '../Common/ShiftsPopover/ShiftsPopover';
import {
  DailyOpenShiftInfo,
  DailyShiftInfo,
  ShiftBlockStylingProp,
} from './dailyShiftsTypes';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  day: Moment;
  shift: DailyShiftInfo | DailyOpenShiftInfo;
  refetchQueries: Array<PureQueryOptions>;
  shiftBlockStylingProp: ShiftBlockStylingProp;
};

const Container = styled.div<{
  shiftBlockStylingProp: ShiftBlockStylingProp;
}>(
  {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    height: '23px',
    lineHeight: '23px',
    padding: '0 12px',
    cursor: 'pointer',
    color: Theme.color.black,
  },
  ({
    shiftBlockStylingProp: {
      background,
      borderColor,
      borderRight,
      borderLeft,
      borderRadius,
      onHoverBackground,
    },
  }) => ({
    background: background,
    border: `1px solid ${borderColor}`,
    borderRadius,
    borderRight: `${borderRight}`,
    borderLeft: `${borderLeft}`,
    ':hover': {
      background: onHoverBackground,
    },
  }),
);

const TimeInterval = styled.div({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const ShiftsDailyBlock: React.FC<Props> = ({
  shift,
  refetchQueries,
  shiftBlockStylingProp,
}) => {
  const formattedInterval = toFormattedDateInterval(shift);

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      overlay={<ShiftPopover shift={shift} refetchQueries={refetchQueries} />}
      rootClose={true}>
      <Container shiftBlockStylingProp={shiftBlockStylingProp}>
        <TimeInterval>
          <b>{`${formattedInterval.start.time} - ${formattedInterval.end.time} `}</b>{' '}
          &nbsp; (<DurationString start={shift.start} end={shift.end} />){' '}
          <span className="ms-2">
            <ShiftLabelsTags shiftLabels={shift.shiftLabels} />
          </span>
        </TimeInterval>
        {'state' in shift && shift.state === 'not-available' && (
          <FlairIcon
            icon={getNotAvailableIcon(shift.notAvailableReason!)}
            size="sm"
            color={Theme.color.black}
            style={{ marginLeft: '10px' }}
          />
        )}
      </Container>
    </OverlayTrigger>
  );
};

export default ShiftsDailyBlock;
