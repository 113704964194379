import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import DurationFormat from '../../components/DurationFormat';

const FulfillmentPopover: React.FC<{
  target: number | null;
  tracked: number;
}> = ({ target, tracked }) => {
  const { t } = useTranslation();

  return (
    <>
      {target !== null && (
        <div className="popover-body d-flex align-items-center justify-content-center">
          <span className="popover-body-label me-auto">
            <Trans
              t={t}
              i18nKey={
                'timeTracking.controlling.employeesTable.timeFulfillment.tooltips.targetHours'
              }
            />
          </span>

          <span className="popover-body-label ms-3">
            <DurationFormat minutes={target} />
          </span>
        </div>
      )}

      <div className="popover-body d-flex align-items-center justify-content-center">
        <span className="popover-body-label me-auto">
          <Trans
            t={t}
            i18nKey={
              'timeTracking.controlling.employeesTable.timeFulfillment.tooltips.trackedHours'
            }
          />
        </span>
        <span className="popover-body-label ms-3">
          <DurationFormat minutes={tracked} />
        </span>
      </div>
      {target !== null && tracked > target && (
        <div className="popover-body d-flex align-items-center justify-content-center">
          <span className="popover-body-label me-auto">
            <Trans
              t={t}
              i18nKey={
                'timeTracking.controlling.employeesTable.timeFulfillment.tooltips.overtimeHours'
              }
            />
          </span>
          <span className="popover-body-label ms-3">
            <DurationFormat minutes={tracked - target} />
          </span>
        </div>
      )}
    </>
  );
};

export default FulfillmentPopover;
