import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ModalSidebar from '../../../../components/ModalSidebar';
import { Tab, Tabs } from 'react-bootstrap';
import { InventoryInformationTab } from './InventoryInformationTab/InventoryInformationTab';
import CommentListWrapper from '../../../../components/Comment/CommentListWrapper/CommentListWrapper';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import { sidebarTabKeys as tabKeys } from '../../common';
import ServerError from '../../../../../../components/ServerError';
import { ApolloError } from '@apollo/client';
import { InventoryItem } from '../types';
import Skeleton from 'react-loading-skeleton';
import { LoadingInventorySidebarModal } from './LoadingInventorySidebarModal';
import { InventorySupportTab } from './InventorySupportTab/InventorySupportTab';

type Props = {
  visible: boolean;
  inventoryItemId: string;
  onClose: () => void;
  defaultTab: string | null;
  setSelectedTabKey: Dispatch<SetStateAction<string | null>>;
  recordId: string;
  isLoading: boolean;
  error: ApolloError | undefined;
  inventoryItem: InventoryItem | null;
  isManager: boolean;
  showComments: boolean;
};

const i18Prefix = 'myData.inventory.sidebarModal';
export const InventorySidebarModal: React.FC<Props> = ({
  visible,
  onClose,
  defaultTab,
  setSelectedTabKey,
  recordId,
  isLoading,
  error,
  inventoryItem,
  isManager,
  showComments,
}) => {
  const t = useNamespacedTranslation(i18Prefix);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (showComments) {
      setSelectedTabKey(tabKeys.COMMENTS);
    }
  }, [showComments, setSelectedTabKey]);

  const renderContent = () => {
    if (isLoading || !inventoryItem) {
      return (
        <ModalSidebar
          show={visible}
          onClose={handleClose}
          header={<HeaderLoader />}
          content={<LoadingInventorySidebarModal />}></ModalSidebar>
      );
    }

    return (
      <ModalSidebar
        show={visible}
        onClose={handleClose}
        header={inventoryItem.inventory.name}
        content={
          <Tabs
            className="mx-4"
            activeKey={defaultTab ?? tabKeys.INFORMATION}
            onSelect={(item) => setSelectedTabKey(item)}>
            <Tab
              eventKey={tabKeys.INFORMATION}
              className="m-4 mt-4"
              title={t('tabs.information')}>
              <InventoryInformationTab
                isManager={isManager}
                inventoryItem={inventoryItem}
              />
            </Tab>
            <Tab
              eventKey={tabKeys.SUPPORT}
              className="m-4 mt-4"
              title={t('tabs.support')}>
              <InventorySupportTab data={inventoryItem} />
            </Tab>
            <Tab
              eventKey={tabKeys.COMMENTS}
              title={t('tabs.comments')}
              className="h-100 comments-tab">
              <CommentListWrapper
                recordId={recordId}
                relatedObjectName={RelatedObjectNames.Inventory}
              />
            </Tab>
          </Tabs>
        }></ModalSidebar>
    );
  };

  if (error) {
    return <ServerError />;
  }

  return renderContent();
};

const HeaderLoader = () => {
  return <Skeleton width={100} />;
};
