import { DashboardAnniversaries_ColleagueFragment } from '../../../../__generated__/graphql';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import React from 'react';
import { mapAnniversary } from '../mapping';

export const mapAnniversaryForModal = (
  anniversary: DashboardAnniversaries_ColleagueFragment,
) => {
  const employee = {
    name: anniversary.Name,
    avatarUrl: anniversary.avatar?.url,
  };

  return {
    ...mapAnniversary(anniversary),
    employee: <EmployeeWithAvatar employee={employee} />,
  };
};
