import * as React from 'react';

const CheckBoxOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M9.99761 4.50269L5.7986 9.5015L3.99902 7.50198"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4965 1.00342H2.49867C1.67044 1.00342 0.999023 1.67483 0.999023 2.50306V11.5009C0.999023 12.3292 1.67044 13.0006 2.49867 13.0006H11.4965C12.3248 13.0006 12.9962 12.3292 12.9962 11.5009V2.50306C12.9962 1.67483 12.3248 1.00342 11.4965 1.00342Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default CheckBoxOutlineIcon;
