import React, { MutableRefObject, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ProjectViewMode } from '../types';
import { ProjectFormData, ProjectFormSaveInfo } from './types';

import { useTranslation } from 'react-i18next';
import { i18prefix } from '../helpers';
import AutoSaveInputLabel from '../../../../../components/form/AutoSaveInputLabel';
import { ControlledDateInput } from '../../../../../components/form/Datepicker';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { InputWithSaveControlled } from '../../../../../components/form/InputsWithSave/InputWithSave';
import { RichtextInputWithSaveControlled } from '../../../../../components/form/InputsWithSave/RichtextInputWithSave';
import ScrollToError from '../../../../../components/form/ScrollToError';
import { ControlledSwitchInput } from '../../../../../components/form/SwitchInput';
import { InfoHint } from '../../../../../components/hint/InfoHint';

type Props = {
  viewMode: ProjectViewMode;
  saveInfo?: ProjectFormSaveInfo;
};

export const ProjectFields: React.FC<Props> = ({ viewMode, saveInfo = {} }) => {
  const { t } = useTranslation();

  const getSaveInfo = (name: keyof ProjectFormData) => saveInfo[name] ?? {};
  // TODO to be discussed: Based on a discussion with Evgenii and Pierre, we decided for now to disable editing the project from the hub.
  // Since based on the current logic, if you're a manager of any random employee, you will be able to modify the projects assigned to you, which seems
  // to be an incorrect behavior
  const readonly = true;

  const refs: Partial<Record<keyof ProjectFormData, MutableRefObject<null>>> = {
    name: useRef(null),
    startDate: useRef(null),
    endDate: useRef(null),
  };

  return (
    <>
      <FormGroup>
        <InputWithSaveControlled
          readOnly={readonly}
          name="name"
          labelComponent={
            <AutoSaveInputLabel
              label={t(`${i18prefix}.fields.projectName`)}
              {...getSaveInfo('name')}
            />
          }
          disabled={readonly}
        />
        <ScrollToError name="name" inputContainerRef={refs.name!} />
      </FormGroup>
      <div>
        <FormGroup>
          <RichtextInputWithSaveControlled
            name="description"
            labelComponent={
              <AutoSaveInputLabel
                label={t(`${i18prefix}.fields.description`)}
                optional
                {...getSaveInfo('description')}
              />
            }
            readOnly={readonly}
          />
        </FormGroup>
      </div>

      <Row>
        <Col lg={6}>
          <FormGroup>
            <AutoSaveInputLabel
              label={t(`${i18prefix}.fields.startDate`)}
              optional={true}
              {...getSaveInfo('startDate')}
            />
            <ControlledDateInput name="startDate" disabled={readonly} />
            <ScrollToError
              name="startDate"
              inputContainerRef={refs.startDate!}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <AutoSaveInputLabel
              label={t(`${i18prefix}.fields.endDate`)}
              optional={true}
              {...getSaveInfo('endDate')}
            />
            <ControlledDateInput name="endDate" disabled={readonly} />
            <ScrollToError name="endDate" inputContainerRef={refs.endDate!} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <FormGroup>
            <InputWithSaveControlled
              name="estimation"
              readOnly={readonly}
              type="number"
              className="m-2"
              placeholder={t(`${i18prefix}.estimationPlaceholder`)}
              labelComponent={
                <>
                  <AutoSaveInputLabel
                    label={t(`${i18prefix}.fields.estimation`)}
                    optional={true}
                    hint={
                      <InfoHint
                        text={t(`${i18prefix}.fields.estimationHelp`)}
                      />
                    }
                    {...getSaveInfo('estimation')}
                  />
                </>
              }
              disabled={readonly}
            />
          </FormGroup>
        </Col>
        <Col lg={4} className="text-end">
          <FormGroup>
            <div>
              <AutoSaveInputLabel
                label={t(`${i18prefix}.fields.billable`)}
                {...getSaveInfo('billable')}
              />
            </div>
            <ControlledSwitchInput
              id="billable-switch"
              name="billable"
              className="d-inline-block ms-2"
              disabled={readonly}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
