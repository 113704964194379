import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { TicketRequest } from '../NewTicketModal/types';
import {
  TicketVisibility,
  useCreateTicketMutation,
  useTicketPolicyQuery,
} from '../../../../__generated__/graphql';
import { getCurrentLanguage } from '../../../../../../locales/locale';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { useUserInfo } from '../../../../context/UserInfo';
import { useApolloCache } from '../../../../hooks/useApolloCache';
import { TicketPolicy } from '../types';
import { mapTicketPolicy } from '../mappings';

export const useAddTicket = (isManager: boolean) => {
  const locale = getCurrentLanguage();
  const errorHandler = useMutationErrorHandler();
  const cache = useApolloCache();

  const { data: ticketPolicyResponse, error: policyError } =
    useTicketPolicyQuery();

  const { id: meId } = useUserInfo();
  const [openTicketModalVisible, setOpenTicketModalVisible] = useState(false);
  const [openTicketSuccessModalVisible, setOpenTicketSuccessModalVisible] =
    useState(false);
  const [openTicketMutation, { loading }] = useCreateTicketMutation();
  const [modalIndex, setModalIndex] = useState(Date.now());
  const handleAddTicketModalClose = () => {
    setOpenTicketModalVisible(false);
    setModalIndex(Date.now());
  };

  const policy = ticketPolicyResponse?.ticketPolicy
    ? mapTicketPolicy(ticketPolicyResponse?.ticketPolicy)
    : null;

  const openTicket = (data: FieldValues) => {
    openTicketMutation({
      variables: {
        lang: locale,
        input: {
          ...mapToTicketRequest(data, meId, isManager, policy),
        },
      },
      update: (cache, response) => {
        if (!response.data) {
          return;
        }
        const managerCacheId = cache.identify({
          __typename: 'Manager',
          Id: meId,
        });

        const newTicket = response?.data?.ticket?.createTicket?.record;
        if (!newTicket) {
          return;
        }

        cache.modify({
          id: managerCacheId,
          fields: {
            tickets(existent, { readField }) {
              return existent.items.concat(newTicket);
            },
          },
        });
      },
    })
      .then(() => {
        setOpenTicketSuccessModalVisible(true);
      })
      .catch(errorHandler)
      .finally(() => {
        cache.evict({
          fieldName: 'ticketPagination',
        });
        cache.gc();
        handleAddTicketModalClose();
      });
  };

  return {
    setOpenTicketModalVisible,
    handleAddTicketModalClose,
    openTicketModalVisible,
    openTicketSuccessModalVisible,
    setOpenTicketSuccessModalVisible,
    modalIndex,
    openTicket,
    openTicketLoading: loading,
    ticketPolicy: policy,
    policyError,
  };
};

const mapToTicketRequest = (
  data: FieldValues | null,
  employeeId: string,
  isManager: boolean,
  policy: TicketPolicy | null,
): TicketRequest => {
  return {
    flair__Category__c: data?.category,
    flair__Subject__c: data?.issue,
    flair__Details__c: data?.details,
    flair__Employee__c: data?.employee ? data?.employee : employeeId,
    flair__Visibility__c: handleVisibilityField(
      data?.shareWithManager,
      isManager,
    ),
    flair__Anonymous__c: handleAnonymousField(data?.stayAnonymous, policy),
  };
};

const handleAnonymousField = (
  stayAnonymous: boolean,
  policy: TicketPolicy | null,
): boolean => {
  if (policy?.ticketVisibility === 'ALWAYS_ANONYMOUS') {
    return true;
  }

  return stayAnonymous ?? false;
};
const handleVisibilityField = (
  shareWithManager: boolean,
  isManager: boolean,
) => {
  if (isManager) {
    return TicketVisibility.HrManagerEmployee;
  }

  return shareWithManager
    ? TicketVisibility.HrManagerEmployee
    : TicketVisibility.HrEmployee;
};
