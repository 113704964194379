import React from 'react';
import { useNamespacedTranslation } from '../../../hooks/useNamespacedTranslation';
import { FlairDateFormat } from '../../atoms/FlairFormattedDate/types';
import { getRelativeDateText } from './logic';
import classNames from 'classnames';

export type RelativeDateLabelProps = {
  endDate: Date;
  className?: string;
  displayFormat?: FlairDateFormat;
};

const RelativeDateLabel: React.FC<RelativeDateLabelProps> = ({
  endDate,
  className = '',
  displayFormat,
}) => {
  const t = useNamespacedTranslation('date');
  const displayText = getRelativeDateText(endDate, t, displayFormat);

  if (!displayText) {
    return null;
  }

  return (
    <div className={classNames('d-flex-all-center', className)}>
      {displayText}
    </div>
  );
};

export default RelativeDateLabel;
