import * as React from 'react';

const SadTearOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M5.74862 8.00114C6.16369 8.00114 6.50017 7.66466 6.50017 7.24959C6.50017 6.83453 6.16369 6.49805 5.74862 6.49805C5.33355 6.49805 4.99707 6.83453 4.99707 7.24959C4.99707 7.66466 5.33355 8.00114 5.74862 8.00114Z"
        fill="currentColor"
      />
      <path
        d="M8 9.99694C9.09767 9.99694 10.0211 10.6311 10.3109 11.4966"
        stroke="black"
        strokeWidth="0.999764"
        strokeLinecap="round"
      />
      <path
        d="M4.5625 10.7786C4.5625 10.2745 5.25257 9.19699 5.58041 8.71405C5.66338 8.59206 5.83606 8.59206 5.91903 8.71405C6.24687 9.19699 6.93694 10.2745 6.93694 10.7786C6.93694 11.4541 6.40539 11.9968 5.74972 11.9968C5.09405 11.9968 4.5625 11.4541 4.5625 10.7786Z"
        fill="currentColor"
      />
      <path
        d="M10.2496 8.00114C10.6647 8.00114 11.0011 7.66466 11.0011 7.24959C11.0011 6.83453 10.6647 6.49805 10.2496 6.49805C9.83453 6.49805 9.49805 6.83453 9.49805 7.24959C9.49805 7.66466 9.83453 8.00114 10.2496 8.00114Z"
        fill="currentColor"
      />
      <path
        d="M7.99944 14.496C11.5884 14.496 14.4979 11.5865 14.4979 7.99749C14.4979 4.40849 11.5884 1.49902 7.99944 1.49902C4.41044 1.49902 1.50098 4.40849 1.50098 7.99749C1.50098 11.5865 4.41044 14.496 7.99944 14.496Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default SadTearOutlineIcon;
