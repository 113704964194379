import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../components/button';
import { CancelButton } from '../../../../../components/button/CancelButton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

export type Props = {
  show: boolean;
  skillIdToRemove: string;
  removeSkillText: string;
  inProgress: boolean;
  onClose: () => void;
  onSkillRemoved: (id: string) => void;
};

export const RemoveEmployeeSkillDialog: React.FC<Props> = ({
  show,
  onClose,
  skillIdToRemove,
  removeSkillText,
  inProgress,
  onSkillRemoved,
}) => {
  const t = useNamespacedTranslation('skills.removeEmployeeSkill');

  const handleConfirm = useCallback(() => {
    onSkillRemoved(skillIdToRemove);
  }, [onSkillRemoved, skillIdToRemove]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t('title')}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div>{removeSkillText}</div>
          <div className="mt-3 fw-bold">{t('confirmText')}</div>
          <div className="d-flex justify-content-end mt-4">
            <CancelButton
              className="me-3"
              disabled={inProgress}
              onClick={onClose}
            />
            <Button
              label={t('confirm')}
              onClick={handleConfirm}
              isProcessing={inProgress}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
