import { TicketsFilter } from './Tickets/Filters/types';

export const i18DocumentsPrefix = 'support';

export type TicketsRouteNames = 'managerTickets' | 'tickets';

export const emptyTicketsFilter: TicketsFilter = {
  category: null,
  assignee: null,
  month: null,
  year: null,
  searchTerm: null,
  status: null,
};

export const sidebarTabKeys = {
  INFORMATION: 'information',
  COMMENTS: 'comments',
};
