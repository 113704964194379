import { DashboardAbsentToday_ColleagueFragment } from '../../../../__generated__/graphql';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import React from 'react';
import { mapAbsences } from '../mapping';
import AdditionalInfo from '../AdditionalInfo';

export const mapAbsenceForModal = (
  absence: DashboardAbsentToday_ColleagueFragment,
  t: Function,
) => {
  const employee = {
    name: absence.employee?.Name || '',
    avatarUrl: absence.employee?.avatar?.url,
  };

  const additionalInfo = (
    <AdditionalInfo absence={absence} t={t} isModal={true} />
  );

  return {
    ...mapAbsences(absence, t),
    employee: <EmployeeWithAvatar employee={employee} />,
    additionalInfo: additionalInfo,
  };
};
