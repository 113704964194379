import { useTableWithPagination } from './useTableWithPagination';
import { Filter, UsePersistentFilterArg } from './usePersistentFilter';
import { FlairLocalStorageKey } from '../apps/dashboard/hooks/useFlairLocalStorage';
import { useFlairPersistentFilter } from './useFlairPersistentFilter';
import { useCallback } from 'react';

export const useFlairTableWithPaginationAndFilter = <
  TItem,
  TFilter extends Filter,
>(
  a: UsePersistentFilterArg<TFilter, FlairLocalStorageKey>,
) => {
  const [storedFilter, setStoredFilter] = useFlairPersistentFilter(a);
  const {
    tableState,
    handleFilterChange: handleFilterChangeInternal,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  } = useTableWithPagination<TItem, TFilter>(storedFilter);

  const handleFilterChange = useCallback(
    function (newFilter: TFilter) {
      handleFilterChangeInternal(newFilter);
      setStoredFilter(newFilter);
    },
    [handleFilterChangeInternal, setStoredFilter],
  );

  return {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  };
};
