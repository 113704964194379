import { DashboardEndOfProbation_ColleagueFragment } from '../../../__generated__/graphql';
import i18next from '../../../../../initializers/i18next';
import { WidgetEmployee } from '../CommonComponents/WidgetSections/types';
import { differenceInCalendarMonths } from 'date-fns';
import { mapCommonColleagueFields } from '../common/mappings';
import { formatWidgetDate } from '../common/logic';

export const mapProbation = (
  employee: DashboardEndOfProbation_ColleagueFragment,
): WidgetEmployee => {
  const probationDate = new Date(
    employee.flair__End_Of_Probation_Period__c as string,
  );
  const startDate = new Date(employee.flair__Start_Date__c as string);
  const monthDiff = differenceInCalendarMonths(probationDate, startDate);

  const probationDuration =
    monthDiff > 0
      ? i18next.t('dashboard.probations.month', {
          count: monthDiff,
        })
      : null;

  const formattedDate = formatWidgetDate(probationDate);

  return {
    ...mapCommonColleagueFields(employee),
    date: probationDate,
    startDate: startDate,
    additionalInfo: probationDuration
      ? `${probationDuration} - ${formattedDate}`
      : formattedDate,
  };
};
