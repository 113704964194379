import * as React from 'react';
import { useMyTimeTrackingQuery } from '../../../__generated__/graphql';
import { useIsMyTimeTrackingEnabled } from '../../../hooks/timeTracking/useMyTrackingTypes';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import LoadingWidget from '../CommonComponents/LoadingWidget';
import StartTimeTrackingButton from './StartTimeTrackingButton';
import TimeBadge from './TimeBadge';

const i18Path = 'dashboard.timeTracking';

const TimeTrackingWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useMyTimeTrackingQuery();
  const isMyTimeTrackingEnabled = useIsMyTimeTrackingEnabled();

  if (loading) return <LoadingWidget />;

  if (error || data === undefined || !isMyTimeTrackingEnabled) return null;

  const currentTimeEntry = data.me.currentTimeEntry;
  const status = currentTimeEntry ? t('running') : t('notRunning');

  const rightSideElm = currentTimeEntry ? (
    <TimeBadge currentTimeEntry={currentTimeEntry} />
  ) : (
    <StartTimeTrackingButton />
  );

  return (
    <FlairCard
      cardContainerClassName="time-tracking-card"
      data-testid="time-tracking-card"
      contentClassName="py-4 d-flex justify-content-between align-items-center">
      <div>
        <div className="h2-mini fs-2">{t('title')}</div>
        <div className="h4-regular status" data-testid="time-tracking-status">
          {status}
        </div>
      </div>
      {rightSideElm}
    </FlairCard>
  );
};

export default TimeTrackingWidget;
