import React from 'react';
import { Nav } from 'react-bootstrap';
import { i18prefix } from '../helpers';
import { NavLink } from '../../../../../Router';
import { routes } from '../../../routes';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

const ProjectNav: React.FC = () => {
  const t = useNamespacedTranslation(i18prefix);

  return (
    <Nav variant="tabs" className="header-tabs" as="ul">
      <Nav.Item as="li">
        <NavLink
          className="nav-link"
          activeClassName="active"
          exact={true}
          to={routes.projectsOverviewManager.route}>
          {t(`nav.overview`)}
        </NavLink>
      </Nav.Item>
      <Nav.Item as="li">
        <NavLink
          className="nav-link"
          activeClassName="active"
          exact={true}
          to={routes.projectsControllingTimeSheets.route}>
          {t(`nav.timesheets`)}
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default ProjectNav;
