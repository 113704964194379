import styled from '@emotion/styled';
import { Theme } from '../../../../../../theme';

type Props = {
  header?: boolean;
};

export const TotalHoursCol = styled.td<Props>(({ header }) => ({
  width: `6.25rem`,
  maxWidth: '6.25rem',
  minWidth: '6.25rem',
  textAlign: 'right',
  position: 'sticky',
  right: 0,
  zIndex: 1,
  backgroundColor: `${
    header ? Theme.color.paper5 : Theme.color.white
  } !important`,
}));
