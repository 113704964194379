import React from 'react';
import { InventoryStatus } from '../../types';
import { getInventoryItemStatusColor } from '../../mappings';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { StatusCircle } from '../../../../../components/StatusCircle';

type Props = {
  status: InventoryStatus;
};
const i18Prefix = 'myData.inventory.table.inventoryStatus';
export const InventoryItemStatus: React.FC<Props> = ({ status }) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <div className="d-flex flex-row align-items-center gap-sm-2">
      <StatusCircle color={getInventoryItemStatusColor(status)} size={5} />
      <span>{t(`${status}`)}</span>
    </div>
  );
};
