import * as React from 'react';

const LockClosedOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_55004)">
        <path
          d="M10.4959 5.4999V3.4999C10.4959 2.83701 10.2326 2.20128 9.76383 1.73255C9.2951 1.26382 8.65937 1.00049 7.99648 1.00049C7.3336 1.00049 6.69786 1.26382 6.22913 1.73255C5.7604 2.20128 5.49707 2.83701 5.49707 3.4999V5.4999"
          stroke="currentColor"
          strokeWidth="0.999764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.499 6.49951H4.50062C3.67239 6.49951 3.00098 7.17093 3.00098 7.99916V13.4979C3.00098 14.3261 3.67239 14.9975 4.50062 14.9975H11.499C12.3272 14.9975 12.9986 14.3261 12.9986 13.4979V7.99916C12.9986 7.17093 12.3272 6.49951 11.499 6.49951Z"
          stroke="currentColor"
          strokeWidth="0.999764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00024 10.4979C7.44796 10.4979 7.00024 10.9456 7.00024 11.4979C7.00024 12.0501 7.44796 12.4979 8.00024 12.4979C8.55253 12.4979 9.00024 12.0501 9.00024 11.4979C9.00024 10.9456 8.55253 10.4979 8.00024 10.4979Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_55004">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default LockClosedOutlineIcon;
