import React, { useRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { toFlatpickrLocale } from '../form/flatpickrUtils';
import { useCurrentLocale } from '../../context/I18n';
import { useTranslation } from 'react-i18next';
import { toDateStr } from '../../apps/dashboard/pages/TimeTracking/TimeTrackingEntries/logic';
import monthSelectPlugin, { Config } from 'flatpickr/dist/plugins/monthSelect';
import { parseDate } from '../../utils/dateUtils';
import { DeleteOptionIcon } from '../../apps/dashboard/components/Selects/DeleteOptionIcon';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { NORMAL_HEIGHT_CSS } from '../Select/SelectSingleStyled';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/flatpickr.css';

type MonthPickerProps = {
  name: string;
  placeholder: string;
  value: Date | string | null;
  error?: string;
  onChange: (value: string | null) => void;
};

type Options = NonNullable<NonNullable<DateTimePickerProps['options']>>;

const MonthPicker: React.FC<MonthPickerProps> = ({
  name,
  value,
  onChange,
  placeholder,
}) => {
  const locale = toFlatpickrLocale(useCurrentLocale());
  const { t } = useTranslation();
  const fp = useRef<any>(null);

  const config: Config = {
    shorthand: true,
    dateFormat: 'F Y',
    altFormat: 'F Y',
    theme: 'light',
  };

  const defaultOptions: Partial<Options> = {
    disableMobile: true,
    locale,
    dateFormat: t('flatpickr.dateFormat'),
    plugins: [monthSelectPlugin(config) as any],
  };

  const handleDelete = () => {
    if (!fp?.current?.flatpickr) return;
    fp.current.flatpickr.clear();
    onChange(null);
  };

  return (
    <InputGroup className="input-group-sm">
      <Container className="form-control d-flex justify-content-between align-items-center">
        <FlatpickrStyled
          ref={fp}
          name={name}
          size={9}
          options={defaultOptions}
          onChange={(date) => onChange(toDateStr(date[0]))}
          value={value ? parseDate(value) : undefined}
          placeholder={placeholder}
        />
        {value !== null && (
          <DeleteOptionIcon className="me-2" onClick={handleDelete} />
        )}
      </Container>
    </InputGroup>
  );
};

const FlatpickrStyled = styled(Flatpickr)({
  flexGrow: 1,
  border: 0,
  color: Theme.input.value.color,
  '::placeholder': {
    color: Theme.input.placeholder.color,
  },
});

const Container = styled.div({
  height: NORMAL_HEIGHT_CSS,
  overflow: 'hidden',
  borderRadius: Theme.border.radius + ' !important',
});

export default MonthPicker;
