import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';
import {
  createRequestInfoInApolloOperation,
  addRequestInfoToErrorResponse,
} from '../../../hooks/errorHandlingRequestInfo';
import apolloCacheConfig from './apolloCacheConfig';
import { getApiBaseUrl } from '../../../utils/config';

const buildClient = (apiToken: string, logout: () => void) =>
  new ApolloClient({
    name: 'web',
    version: import.meta.env.VITE_APP_CODE_VERSION,
    connectToDevTools: true,
    link: ApolloLink.from([
      new ApolloLink((operation, forward) => {
        createRequestInfoInApolloOperation(operation);
        return forward(operation);
      }),
      onError((errorResponse) => {
        addRequestInfoToErrorResponse(errorResponse);
        const { graphQLErrors } = errorResponse;
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions }) => {
            if (extensions && extensions.code === `UNAUTHENTICATED`) {
              logout();
            }
          });
        }
      }),
      new HttpLink({
        uri: getApiBaseUrl() + '/internal/graphql',
        headers: {
          Authorization: apiToken,
        },
      }),
    ]),
    cache: new InMemoryCache(apolloCacheConfig),
  });

export const Provider: React.FC = ({ children }) => {
  const { apiToken, logout } = useAuth();

  if (!apiToken) {
    logout();
    return <></>;
  }

  const client = buildClient(apiToken, logout);

  return <ApolloProvider client={client} children={children} />;
};
