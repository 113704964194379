import * as React from 'react';

const UnlikeIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M16.3623 9.03906L15.5186 2.28906C15.4672 1.88117 15.2687 1.50606 14.9603 1.23418C14.6519 0.962306 14.2549 0.812359 13.8438 0.8125H1.75C1.45163 0.8125 1.16548 0.931026 0.954505 1.142C0.743526 1.35298 0.625 1.63913 0.625 1.9375V8.125C0.625 8.42337 0.743526 8.70952 0.954505 8.9205C1.16548 9.13147 1.45163 9.25 1.75 9.25H4.77766L7.43406 14.5642C7.48083 14.6577 7.5527 14.7363 7.64162 14.7911C7.73054 14.846 7.833 14.8751 7.9375 14.875C8.68342 14.875 9.39879 14.5787 9.92624 14.0512C10.4537 13.5238 10.75 12.8084 10.75 12.0625V10.9375H14.6875C14.927 10.9376 15.1637 10.8867 15.382 10.7882C15.6003 10.6898 15.7951 10.546 15.9535 10.3664C16.1119 10.1868 16.2303 9.97559 16.3008 9.74674C16.3713 9.51788 16.3923 9.27665 16.3623 9.03906ZM4.5625 8.125H1.75V1.9375H4.5625V8.125ZM15.1094 9.62195C15.057 9.68225 14.9921 9.73051 14.9193 9.7634C14.8465 9.79629 14.7674 9.81304 14.6875 9.8125H10.1875C10.0383 9.8125 9.89524 9.87176 9.78975 9.97725C9.68426 10.0827 9.625 10.2258 9.625 10.375V12.0625C9.62511 12.4527 9.49003 12.8308 9.24274 13.1326C8.99545 13.4343 8.65124 13.6411 8.26867 13.7177L5.6875 8.55461V1.9375H13.8438C13.9808 1.93745 14.1131 1.98744 14.2159 2.07806C14.3187 2.16869 14.3849 2.29372 14.402 2.42969L15.2458 9.17969C15.2563 9.25888 15.2496 9.33942 15.226 9.41577C15.2025 9.49211 15.1627 9.56245 15.1094 9.62195Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default UnlikeIcon;
