import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTableContentPageSize } from '../../../manager/components/useTableContent';
import { Card } from 'react-bootstrap';
import { CardHeaderFilter } from '../../../manager/components/CardHeaderFilter';
import JobCandidatesTable from './JobCandidatesTable';
import { recruitmentRoutes } from '../../routes';
import { JobPageType } from '../types';
import CandidatesListFilter from './CandidatesListFilter';
import { emptyFilter } from './CandidatesListFilter/types';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';

const INITIAL_PAGE_SIZE = 50;

type Props = {
  job: JobPageType;
};

export const CandidatesList: React.FC<Props> = ({ job: jobProp }) => {
  const { t } = useTranslation();

  const [pageSize, onPageSizeChange] =
    useTableContentPageSize(INITIAL_PAGE_SIZE);

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: emptyFilter,
    storageKey: 'CANDIDATES_LIST_FILTERS',
    createRouteUrl: (queryParams) =>
      recruitmentRoutes.jobCandidates.route
        .withQueryParams(queryParams)
        .create({ jobId: jobProp.Id }),
  });

  const onQuickSearchChange = (searchTerm: string) => {
    //TODO:: enhance with debounce
    handleFilterChange({ ...tableState.filter, searchTerm });
  };

  return (
    <>
      <div className="mb-3">
        <CandidatesListFilter
          filterData={tableState.filter}
          handleFilterChange={handleFilterChange}
        />
      </div>
      <Card>
        <CardHeaderFilter
          value={tableState.filter.searchTerm ?? ''}
          onFilterChange={onQuickSearchChange}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          placeholder={t(
            'recruitment.jobCandidatesPage.table.filterPlaceholder',
          )}
        />
        <JobCandidatesTable
          tableState={tableState}
          pageSize={pageSize}
          handleSortByChanged={handleSortByChanged}
          tableStatePageIndex={tableState.pageIndex}
          handlePageIndexChanged={handlePageIndexChanged}
          initialSortBy={tableState.sortBy}
        />
      </Card>
    </>
  );
};

export default CandidatesList;
