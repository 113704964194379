import React from 'react';
import { Popover } from '../../../../../../components/popover';
import { useSingleVisiblePopoverContext } from '../../../../../../context/SingleVisiblePopoverContext';
import { EmployeeDetailsPopoverContentConnected } from './EmployeeDetailsPopoverContentConnected';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../../theme';

type Props = {
  rowId: string;
  employeeId: string;
  employeeName: string;
};

export const EmployeeDetailsPopover: React.FC<Props> = ({
  rowId,
  employeeId,
  employeeName,
}) => {
  const { visiblePopoverId, onPopoverToggle } =
    useSingleVisiblePopoverContext();

  const employeePopoverId = `${rowId}-employee`;

  return (
    <Popover
      headerText={employeeName}
      show={visiblePopoverId === employeePopoverId}
      onToggle={(visible) => onPopoverToggle(employeePopoverId, visible)}
      popoverContent={
        <EmployeeDetailsPopoverContentConnected employeeId={employeeId} />
      }>
      <FlairIcon
        icon="information-circle-outline"
        size="sm"
        color={Theme.color.blue3}
      />
    </Popover>
  );
};
