import { MutationHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import {
  SubmitTimeEntryChangeRequestMutation,
  SubmitTimeEntryChangeRequestMutationVariables,
  useSubmitTimeEntryChangeRequestMutation,
} from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';

type HookReturnType = ReturnType<
  typeof useSubmitTimeEntryChangeRequestMutation
>;

export const useSubmitTimeEntryChangeRequest = (
  baseOptions?: MutationHookOptions<
    SubmitTimeEntryChangeRequestMutation,
    SubmitTimeEntryChangeRequestMutationVariables
  >,
): HookReturnType => {
  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();
  const [submit, ...rest] =
    useSubmitTimeEntryChangeRequestMutation(baseOptions);

  const submitWithErrorHandling = useCallback(
    async (args) => {
      let result = await submit(args);
      const errors =
        result.data?.timeTracking.submitTimeEntryChangeRequest.errors2;
      showErrorsAndWarningsToasts({ errors });
      return result;
    },
    [submit, showErrorsAndWarningsToasts],
  );

  return [submitWithErrorHandling, ...rest];
};
