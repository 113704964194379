import React, { useMemo } from 'react';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { split } from '../../../../../../utils/string';
import { MultiValue } from 'react-select';
import { AbsenceRequestFiltersKeys, AbsenceRequestFiltersType } from '../types';
import { routes } from '../../../../routes';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

type Props = Omit<ReactSelectProps<FilterSelectOption, true>, 'options'> & {
  options: FilterSelectOption[];
  filterKey: AbsenceRequestFiltersKeys;
  filterData: AbsenceRequestFiltersType;
};

export const AbsenceRequestMultipleFilter: React.FC<
  Props & ReactSelectProps
> = ({ filterData, filterKey, options, ...rest }) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const selectedOptionsString = filterData[filterKey];
  const selectedOptionsAsArray = useMemo(
    () => split(selectedOptionsString),
    [selectedOptionsString],
  );

  const handleChange = (selectedOptions: MultiValue<FilterSelectOption>) => {
    history.push(
      routes.pendingAbsenceRequests.route
        .withQueryParams({
          ...queryParams,
          [filterKey]: selectedOptions.map((o) => o.value).join(','),
        })
        .create({}),
    );
  };

  return (
    <FlairMultiSelectDropdown
      options={options}
      selectedOptions={selectedOptionsAsArray}
      onChange={handleChange}
      {...rest}
    />
  );
};
