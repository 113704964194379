import React, { useEffect, useMemo } from 'react';
import {
  Cell,
  CellPropGetter,
  Column,
  ColumnInstance,
  HeaderPropGetter,
  Row,
  RowPropGetter,
  SortingRule,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useRowSelect,
} from 'react-table';
import {
  TableContentCommon,
  TableContentCommonProps,
} from './TableContentCommon';

type Props<T extends object> = {
  data: T[];
  columns: Column<T>[];
  filter?: string;
  getRowProps?: (row: Row<T>) => RowPropGetter<T>;
  getColumnProps?: (column: ColumnInstance<T>) => CellPropGetter<T>;
  getHeaderProps?: (column: ColumnInstance<T>) => HeaderPropGetter<T>;
  getCellProps?: (cell: Cell<T>) => CellPropGetter<T>;
  onRowClick?: (row: T) => void;

  initialSortBy?: Array<SortingRule<T>>;
  onSortByChanged?: (sortBy: SortingRule<T>[]) => void;
  pageSize?: number;
  onPageIndexChanged?: (pageIndex: number) => void;
  itemsCount?: number | undefined;
  initialPageIndex?: number;
};

const defaultPropGetter = () => ({});

const hasPagination = (pageSize: Props<{}>['pageSize']): pageSize is number =>
  pageSize !== undefined;

export const TableContentManual = <T extends object>({
  data,
  columns,
  pageSize,
  filter,
  initialSortBy = [],
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  onRowClick = defaultPropGetter,
  onSortByChanged,
  onPageIndexChanged,
  itemsCount,
  initialPageIndex,
}: Props<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    //todo: remove it
    page,
    headers,
    prepareRow,
    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    state: { pageIndex, sortBy },
    setGlobalFilter,
  } = useTable<T>(
    {
      columns,
      data,
      autoResetSortBy: false,
      sortTypes: React.useMemo(
        () => ({
          caseInsensitive: (a, b, id) => {
            const valueA = a.values[id].toLowerCase();
            const valueB = b.values[id].toLowerCase();
            return valueB > valueA ? -1 : valueB < valueA ? 1 : 0;
          },
        }),
        [],
      ),
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: hasPagination(pageSize) ? pageSize : undefined,
        sortBy: initialSortBy,
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount:
        itemsCount !== undefined && pageSize !== undefined
          ? Math.ceil(itemsCount / pageSize)
          : undefined,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    if (hasPagination(pageSize)) {
      setPageSize(pageSize);
    }
  }, [pageSize, setPageSize]);

  useEffect(() => {
    if (onSortByChanged) {
      onSortByChanged(sortBy);
    }
  }, [sortBy, onSortByChanged]);

  useEffect(() => {
    if (onPageIndexChanged) {
      onPageIndexChanged(pageIndex);
    }
  }, [pageIndex, onPageIndexChanged]);

  useEffect(() => {
    setGlobalFilter(filter);
  }, [setGlobalFilter, filter, data]);

  const rowsToRender = hasPagination(pageSize) ? page : rows;
  const showPagination = useMemo(() => {
    if (pageSize === undefined) {
      return false;
    }
    return pageCount > 1;
  }, [pageSize, pageCount]);

  const tableCommonProps: TableContentCommonProps<T> = {
    pagination: {
      showPagination,
      pageCount,
      pageIndex,
      pageSize,
      gotoPage,
      previousPage,
      nextPage,
    },
    getTableProps,
    headers,
    getTableBodyProps,
    prepareRow,
    getRowProps,
    getColumnProps,
    getCellProps,
    getHeaderProps,
    onRowClick,
    rowsToRender,
  };

  return <TableContentCommon {...tableCommonProps} />;
};
