import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { Modal } from 'react-bootstrap';
import { MentionEmployee } from '../../types';
import Button from '../../../../../../components/button';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import './MentionedEmployeesWithNoAccessWarning.css';

type Props = {
  show: boolean;
  onProceed: () => void;
  onClose: () => void;
  employees: MentionEmployee[];
};

const i18Path = 'comments';

const MentionedEmployeesWithNoAccessWarning: React.FC<Props> = ({
  show,
  onProceed,
  onClose,
  employees,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const handleOnProceed = () => {
    onProceed();
    onClose();
  };

  if (employees.length === 0) {
    return <></>;
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      id="mentioned-employees-with-no-access-modal">
      <Modal.Body className="d-flex flex-column shadow-lg align-items-center p-3">
        <div className="d-flex">
          <div className="p-3"></div>
          <div className="d-flex flex-column align-items-center px-3">
            <div className="exclamation-mark-container">!</div>
            <div className="mt-4 text-center">
              {employees.length > 1
                ? t('employeesDoesntHaveAccess')
                : t('employeeDoesntHaveAccess', {
                    employeeName: employees[0].name,
                  })}
            </div>
          </div>

          <FlairIcon icon="close-outline" onClick={onClose} size="lg" />
        </div>

        <div className="mt-4">
          <Button onClick={handleOnProceed} label={t('gotIt')} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MentionedEmployeesWithNoAccessWarning;
