import React, { useCallback } from 'react';

import { GoalId } from '../types';
import { GoalOption } from '../useGoalSelectOptions';
import {
  SelectSingleBase,
  Props as SelectSingleBaseProps,
  RenderOptionParams,
} from '../../../../components/Selects/SelectSingleBase';
import { OptionLabel } from '../../../../components/Selects/OptionLabel';

export type Props = {
  value: GoalId | null;
  options: GoalOption[];
  onChange: (value: GoalId | null) => void;
} & Pick<
  SelectSingleBaseProps<GoalOption>,
  'placeholder' | 'noOptionsMessage' | 'className' | 'isDisabled'
>;

export const SelectSingleGoal: React.FC<Props> = ({
  value,
  onChange,
  options,
  ...rest
}) => {
  const renderOption = useCallback(
    (renderProps: RenderOptionParams<GoalOption>) => (
      <OptionLabel {...renderProps} />
    ),
    [],
  );

  const handleOnChange = useCallback(
    (value: string | null) => {
      if (value === null) {
        onChange(null);
      } else {
        const option = options.find((x) => x.value === value);
        onChange(option ? { id: option.value, type: option.goalType } : null);
      }
    },
    [onChange, options],
  );

  return (
    <SelectSingleBase
      {...rest}
      options={options}
      value={value ? value.id : null}
      onChange={handleOnChange}
      renderOption={renderOption}
    />
  );
};
