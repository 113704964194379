import React from 'react';
import { times } from 'lodash';
import { DaysOfMonthProps } from './types';
import styled from '@emotion/styled';
import EmployeeAbsenceCalendarCardDayCell from './EmployeeAbsenceCalendarCardDayCell';
import { Theme } from '../../../../../theme';

const NotADateCell = styled.td({
  background: `${Theme.color.gray5} !important`,
});
const DaysOfMonth: React.FC<DaysOfMonthProps> = ({
  days,
  daysInMonth,
  onHover,
  hoveredDay,
}) => {
  return (
    <>
      {days.map((day) => (
        <EmployeeAbsenceCalendarCardDayCell
          hoveredDay={hoveredDay}
          key={day.day.format('YYYYMMDD')}
          onHover={onHover}
          day={day}
        />
      ))}
      {times(31 - daysInMonth, (i) => (
        <NotADateCell key={i} />
      ))}
    </>
  );
};
export default DaysOfMonth;
