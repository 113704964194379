import { NotificationItemData, NotificationQueryItem } from '../../types';
import { t } from 'i18next';
import { TimeBalanceWithdrawalNotificationRenderInfoFieldsFragment } from '../../../../__generated__/graphql';
import { formattedDuration } from '../../../DurationFormat';

const i18prefix = 'notifications.timeBalance.timeBalanceWithdrawal';
export const mapTimeBalanceWithdrawalNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  if (
    src.renderInfo.__typename !== 'TimeBalanceWithdrawalNotificationRenderInfo'
  ) {
    return null;
  }
  const withdrawal = src.renderInfo.withdrawal ?? null;
  return {
    description: t(`${i18prefix}.title`),
    comment: getComment(withdrawal),
    urlLabel: '',
    url: '',
  };
};

const getComment = (
  withdrawal:
    | TimeBalanceWithdrawalNotificationRenderInfoFieldsFragment['withdrawal']
    | null,
): string => {
  if (!withdrawal) {
    return t(`${i18prefix}.withdrawalDeleted`);
  }
  const formatDuration = formattedDuration(t);
  const timeBalance = formatDuration(withdrawal.flair__Amount__c);
  return t(
    `${i18prefix}.withdrawalTypes.${withdrawal.flair__Withdrawal_Type__c}`,
    {
      timeBalance,
    },
  );
};
