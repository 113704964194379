import React from 'react';
import { CompanyAvatar } from '../../../../../../../atomic/molecules/CompanyAvatar';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import './styles.css';
import { Theme } from '../../../../../../../theme';
import { PeerFeedback } from '../../EmployeeEvaluation/types';
import { calcRatingScoreAvg } from '../logic';
import classNames from 'classnames';

type Props = {
  onRowClick: (peerFeedbackId: string) => void;
  onDelete?: (peerFeedbackId: string) => void;
  isViewMode: boolean;
  peerFeedbacks: PeerFeedback[];
};

export const PeersReviewList: React.FC<Props> = ({
  peerFeedbacks,
  onRowClick,
  isViewMode,
  onDelete,
}) => {
  return (
    <div className="d-flex flex-column gap-3">
      {peerFeedbacks.map((peerFeedback) => (
        <PeerReviewRow
          key={peerFeedback.id}
          peerFeedback={peerFeedback}
          onRowClick={() => onRowClick(peerFeedback.id)}
          onDelete={onDelete}
          isViewMode={isViewMode}
        />
      ))}
    </div>
  );
};

type PeerReviewRowProps = {
  peerFeedback: PeerFeedback;
  onRowClick: () => void;
  onDelete?: (peerFeedbackId: string) => void;
  isViewMode: boolean;
};

export const PeerReviewRow: React.FC<PeerReviewRowProps> = ({
  peerFeedback,
  onRowClick,
  isViewMode,
  onDelete,
}) => {
  const peer = peerFeedback.reviewer;
  const isSubmitted = !!peerFeedback.submittedAt;

  const handleOnPeerClick = () => {
    if (isSubmitted) {
      onRowClick();
    }
  };

  return (
    <div
      key={peerFeedback.id}
      className={classNames(
        'd-flex justify-content-between pb-3 align-items-center w-100 row-container',
        isSubmitted ? 'cu-pointer' : '',
      )}
      onClick={handleOnPeerClick}>
      <div className="d-flex gap-3">
        <CompanyAvatar
          title={peer?.title}
          titleClass="h4-medium"
          subtitleClass="h5-regular"
          subtitle={peer?.subtitle}
          avatarSize="sm"
          avatarSrc={peer.avatarSrc}
        />
      </div>
      <RightSideContent
        peerFeedback={peerFeedback}
        isViewMode={isViewMode}
        onDelete={() => onDelete && onDelete(peerFeedback.id)}
      />
    </div>
  );
};

type ActionIconProps = {
  peerFeedback: PeerFeedback;
  isViewMode: boolean;
  onDelete: () => void;
};

const RightSideContent: React.FC<ActionIconProps> = ({
  peerFeedback,
  isViewMode,
  onDelete,
}) => {
  const isSubmitted = !!peerFeedback.submittedAt;

  const deleteBtn = (
    <FlairIcon
      icon="trash-outline"
      color={Theme.color.red2}
      onClick={onDelete}
    />
  );

  if (isViewMode) {
    if (isSubmitted) {
      const ratingScoreAvg = calcRatingScoreAvg(peerFeedback.answers);

      if (ratingScoreAvg !== null) {
        return (
          <div className="rating-score-container h5-medium">
            {ratingScoreAvg}
          </div>
        );
      }

      return (
        <FlairIcon icon="checkmark-circle-outline" color={Theme.color.teal1} />
      );
    }

    return (
      <div className="flex-row">
        <FlairIcon icon="hour-glass-outline" className="me-2" size="sm" />
        {deleteBtn}
      </div>
    );
  }

  return deleteBtn;
};
