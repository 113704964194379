import React, { useCallback } from 'react';
import Checkbox from '../../../../components/Checkbox';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import {
  emptyFeedbackFilter,
  Feedback,
  FeedbacksFilter,
  Option,
} from '../types';
import _ from 'lodash';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';
import { t } from 'i18next';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import './FeedbackFilter.css';

type Props = {
  filter: FeedbacksFilter;
  cycleNameOptions: Option[];
  reviewerOptions: Option[] | null;
  revieweeOptions: Option[] | null;
  yearOptions: Option[];
  statusOptions: Option[];
  onChange: (filter: FeedbacksFilter) => void;
};

export const FeedbackFilter: React.FC<Props> = ({
  filter,
  cycleNameOptions,
  reviewerOptions,
  revieweeOptions,
  yearOptions,
  statusOptions,
  onChange,
}) => {
  const i18Path = 'performanceReview.table';
  const t = useNamespacedTranslation(i18Path);
  const handleCycleNameChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'cycleName'),
    [filter, onChange],
  );

  const handleReviewerNameChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'reviewerName'),
    [filter, onChange],
  );

  const handleRevieweeNameChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'revieweeName'),
    [filter, onChange],
  );

  const handleYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  const handleStatusChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );

  const handleIsActiveChanged = useCallback(
    (value) => {
      const checkedValue = value.target.checked ? 'true' : 'false';
      handleFilterChange(checkedValue, filter, onChange, 'onlyActive');
    },
    [filter, onChange],
  );

  const handleClearFilter = useCallback(() => {
    onChange(emptyFeedbackFilter);
  }, [onChange]);

  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <div className="d-flex gap-2 flex-wrap align-items-center">
        <span className="d-none d-md-block">{t('filter.filterBy')}</span>
        <SingleFilter
          className="max-12"
          placeholder={t('filter.cycle')}
          isSearchable={true}
          options={cycleNameOptions}
          onChange={handleCycleNameChanged}
          value={filter.cycleName}
        />
        {reviewerOptions?.length ? (
          <SingleFilter
            className="max-12"
            placeholder={t('filter.reviewer')}
            isSearchable={true}
            options={reviewerOptions}
            onChange={handleReviewerNameChanged}
            value={filter.reviewerName}
          />
        ) : null}
        {revieweeOptions?.length ? (
          <SingleFilter
            className="max-12"
            placeholder={t('filter.reviewee')}
            isSearchable={true}
            options={revieweeOptions}
            onChange={handleRevieweeNameChanged}
            value={filter.revieweeName}
          />
        ) : null}
        <SingleFilter
          className="max-12"
          placeholder={t('filter.year')}
          isSearchable={true}
          options={yearOptions}
          onChange={handleYearChanged}
          value={filter.year}
        />
        <SingleFilter
          className="max-12"
          placeholder={t('filter.status')}
          isSearchable={true}
          options={statusOptions}
          onChange={handleStatusChanged}
          value={filter.status}
        />
        <ClearFiltersLink onClick={handleClearFilter} />
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-end">
        <div className="d-flex ms-2">
          <Checkbox
            label={t('filter.showOnlyActive')}
            id={''}
            onChange={handleIsActiveChanged}
          />
        </div>
      </div>
    </div>
  );
};

export const collectCycleNameFilterOptions = (
  feedbacks: Feedback[],
): Option[] => {
  return _.sortBy(
    _.uniqBy(
      feedbacks.map((feedback) => {
        return {
          label: feedback.cycleName,
          value: feedback.cycleName,
        };
      }),
      'value',
    ),
    'label',
  );
};

export const collectReviewerFilterOptions = (
  feedbacks: Feedback[],
): Option[] => {
  return _.sortBy(
    _.uniqBy(
      feedbacks.map((feedback) => {
        return {
          label: feedback.reviewer ? feedback.reviewer : '',
          value: feedback.reviewer ? feedback.reviewer : '',
        };
      }),
      'value',
    ),
    'label',
  );
};

export const collectRevieweeFilterOptions = (
  feedbacks: Feedback[],
): Option[] => {
  return _.sortBy(
    _.uniqBy(
      feedbacks.map((feedback) => {
        return {
          label: feedback.reviewee ? feedback.reviewee : '',
          value: feedback.reviewee ? feedback.reviewee : '',
        };
      }),
      'value',
    ),
    'label',
  );
};

export const collectYearFilterOptions = (feedbacks: Feedback[]): Option[] => {
  return _.sortBy(
    _.uniqBy(
      feedbacks.map((feedback) => {
        const year = feedback.assignDate.getFullYear().toString();
        return { label: year, value: year };
      }),
      'value',
    ),
    'label',
  );
};

export const collectStatusFilterOptions = (feedbacks: Feedback[]): Option[] => {
  return _.sortBy(
    _.uniqBy(
      feedbacks.map((feedback) => {
        return {
          label: t(`performanceReview.table.status.${feedback.status}`),
          value: feedback.status,
        };
      }),
      'value',
    ),
    'label',
  );
};

const handleFilterChange = (
  value: string | null,
  filter: FeedbacksFilter,
  onChange: (filter: FeedbacksFilter) => void,
  filterKey: keyof FeedbacksFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
