import * as React from 'react';

const ChatBubbleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M2.73478 11.8728C2.77196 11.736 2.68979 11.5457 2.61137 11.4085C2.58695 11.3676 2.56047 11.328 2.53201 11.2898C1.85944 10.2698 1.50096 9.07489 1.50101 7.85312C1.49007 4.34614 4.39814 1.50024 7.99416 1.50024C11.1303 1.50024 13.7481 3.67286 14.3598 6.55686C14.4515 6.98445 14.4978 7.42051 14.4979 7.85781C14.4979 11.3698 11.702 14.2604 8.10601 14.2604C7.53427 14.2604 6.76258 14.1166 6.34174 13.9989C5.9209 13.8811 5.50069 13.7249 5.39228 13.683C5.2814 13.6404 5.16364 13.6185 5.04486 13.6183C4.91511 13.6178 4.78661 13.6437 4.66714 13.6942L2.54795 14.4591C2.50152 14.4791 2.45233 14.4919 2.40204 14.4972C2.36237 14.4971 2.3231 14.4891 2.28654 14.4737C2.24997 14.4583 2.21682 14.4358 2.18901 14.4075C2.1612 14.3792 2.13928 14.3456 2.12453 14.3088C2.10977 14.2719 2.10247 14.2326 2.10305 14.1929C2.10566 14.158 2.11194 14.1236 2.1218 14.0901L2.73478 11.8728Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
});

export default ChatBubbleOutlineIcon;
