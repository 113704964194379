import XRegExp from 'xregexp';

export const split = (
  value: string,
  { separator = ',', ignoreFalsy = true } = {},
): string[] => {
  const values = value.split(separator);
  return ignoreFalsy ? values.filter((v) => v) : values;
};

export const isEqualAlphanumerically = (a: string = '', b: string = '') => {
  return (
    a.replace(/[^a-z0-9]/gi, '').toLowerCase() ===
    b.replace(/[^a-z0-9]/gi, '').toLowerCase()
  );
};

export const trimWhitespacesAndNewLinesInHTML = (htmlStr: string) =>
  htmlStr.trim().replace(/^(\s*<br>|&nbsp;|\s)+|(\s*<br>|&nbsp;|\s)+$/g, '');

export const wrapLinksInAnchorTags = (text: string) => {
  const urlRegex = XRegExp(
    '(?<!href="|">)(https?://[\\w-]+(\\.[\\w-]+)*(:\\d+)?(/\\S*)?)',
    'gi',
  );

  // Replace matched URLs with HTML links
  return text.replace(urlRegex, function (match) {
    return `<a href="${match}">${match}</a>`;
  });
};
