import React, { useCallback, useEffect, useState } from 'react';
import { SortingRule } from 'react-table';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Button, Card } from 'react-bootstrap';
import { InventoryFiltersConnected } from './Filters/InventoryFiltersConnected';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import { InventoryTable } from './Table/InventoryTable';
import { applyInventoryFilter, hasInventoryFilter } from './Filters/filters';
import { InventorySidebarModal } from './InventorySidebarModal/InventorySidebarModal';
import AddInventoryItemModal from './components/InventoryModal/AddInventoryItemModal';
import {
  InventoryListQuery,
  useInventorySidebarLazyQuery,
} from '../../../__generated__/graphql';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import ServerError from '../../../../../components/ServerError';
import { TableItem as InventoryTableRowType } from './Table/InventoryTable';
import { sidebarTabKeys } from '../common';
import { mapInventoryList } from './mappings';
import { mapInventorySidebarData } from './InventorySidebarModal/mappings';
import { ApolloError } from '@apollo/client';
import { InventoryFilter } from './Filters/types';
import { InventoryListItem } from './types';
import { getCurrentLanguage } from '../../../../../locales/locale';
import { AddInventoryItemSuccessModal } from './components/InventoryModal/AddInventoryItemSuccessModal';
import { useAddInventoryItem } from './hooks/useAddInventoryItem';
import {
  ADD_INVENTORY_ITEM_FEATURE,
  useFeature,
} from '../../../../../context/FeaturesContext';
import { routes } from '../../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import { managerRoutes } from '../../../manager/routes';

const i18Path = 'myData.inventory';

type Props = {
  employeeId?: string;
  handleFilterChange: (filter: InventoryFilter) => void;
  handlePageIndexChanged: (pageIndex: number) => void;
  handleSortByChanged: (sortBy: SortingRule<InventoryListItem>[]) => void;
  data: InventoryListQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  pageSize: number;
  tableStateSortBy: Array<SortingRule<InventoryListItem>>;
  tableStateFilter: InventoryFilter;
  tableStatePageIndex: number;
  isManager?: boolean;
};

export const Inventory: React.FC<Props> = ({
  employeeId,
  handleFilterChange,
  handlePageIndexChanged,
  handleSortByChanged,
  data,
  loading,
  error,
  pageSize,
  tableStateSortBy,
  tableStateFilter,
  tableStatePageIndex,
  isManager = false,
}) => {
  const locale = getCurrentLanguage();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const showComments = queryParams.get('show-comments') === 'true';
  const inventoryItemId = queryParams.get('inventoryItemId');
  const isSidebarVisible = !!inventoryItemId;

  const [sidebarTab, setSidebarTab] = useState<string | null>(
    sidebarTabKeys.INFORMATION,
  );
  const t = useNamespacedTranslation(i18Path);

  const [
    fetchSidebarData,
    { data: response, loading: isInventoryItemLoading, error: sidebarError },
  ] = useInventorySidebarLazyQuery();

  const {
    addInventoryItemModalVisible,
    setAddInventoryItemModalVisible,
    handleAddInventoryItemModalClose,
    modalIndex,
    addInventoryItem,
    isLoading,
    addInventoryItemSuccessModalVisible,
    setAddInventoryItemSuccessModalVisible,
  } = useAddInventoryItem();

  const tableItemsTotalCount =
    data?.inventoryItemsPagination.paginationInfo?.totalCount;
  const onClick = (row: InventoryTableRowType) => {
    handleSideBarOpen(row);
  };

  const handleSetQueryParams = (rowId: string) => {
    const queryParams = { inventoryItemId: rowId };

    if (isManager && employeeId) {
      history.push(
        managerRoutes.employeeInventory.route
          .withQueryParams(queryParams)
          .create({ employeeId }),
      );
    } else {
      history.push(
        routes.inventory.route.withQueryParams(queryParams).create({}),
      );
    }
  };

  const handleClearQueryParams = () => {
    if (isManager && employeeId) {
      history.push(
        managerRoutes.employeeInventory.route.create({ employeeId }),
      );
    } else {
      history.push(routes.inventory.route.create({}));
    }
  };
  const handleSideBarOpen = async (row: InventoryTableRowType) => {
    handleSetQueryParams(row.id);
  };

  const handleFetchSidebarData = useCallback(async () => {
    if (inventoryItemId) {
      await fetchSidebarData({
        variables: {
          id: inventoryItemId.replace(/\?show-comments=true$/, ''),
          lang: locale,
        },
      });
    }
  }, [fetchSidebarData, inventoryItemId, locale]);

  useEffect(() => {
    handleFetchSidebarData();
  }, [inventoryItemId, handleFetchSidebarData]);

  const onSupport = (row: InventoryTableRowType) => {
    setSidebarTab(sidebarTabKeys.SUPPORT);
    handleSideBarOpen(row);
  };

  const handleSidebarModalClose = () => {
    setSidebarTab(sidebarTabKeys.INFORMATION);
    handleClearQueryParams();
  };
  const allInventoryItems = data ? mapInventoryList(data) : undefined;

  const isAddInventoryItemVisible = useFeature(ADD_INVENTORY_ITEM_FEATURE);
  const renderContent = () => {
    if (loading || !allInventoryItems) {
      return <Loading />;
    }
    const inventoryItems = applyInventoryFilter(
      tableStateFilter,
      allInventoryItems,
    );

    return (
      <InventoryTable
        inventoryItems={inventoryItems}
        onClick={onClick}
        onSupport={onSupport}
        pageSize={pageSize}
        itemsCount={tableItemsTotalCount}
        initialSortBy={tableStateSortBy}
        initialPageIndex={tableStatePageIndex}
        onPageIndexChanged={handlePageIndexChanged}
        onSortByChanged={handleSortByChanged}
        hasInventoryFilter={hasInventoryFilter(tableStateFilter)}
      />
    );
  };

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <Card>
        <Card.Header className="h-auto">
          <InventoryFiltersConnected
            filter={tableStateFilter}
            onChange={handleFilterChange}></InventoryFiltersConnected>
        </Card.Header>
        <Card.Header>
          <div className="d-flex gap-3 justify-content-between align-items-center small">
            <div className="d-flex align-items-center">
              <SubheaderStyled className="me-2 h7-regular">
                {t('table.subHeader.title', {
                  count: tableItemsTotalCount,
                })}
              </SubheaderStyled>
            </div>
            {isAddInventoryItemVisible && (
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <Button
                  onClick={() => setAddInventoryItemModalVisible(true)}
                  variant="primary"
                  className="d-flex align-items-center">
                  <FlairIcon icon="add-outline" />
                  <span className="ms-2 small">{t('table.buttons.add')}</span>
                </Button>
              </div>
            )}
          </div>
        </Card.Header>
        {renderContent()}
      </Card>
      <AddInventoryItemModal
        visible={addInventoryItemModalVisible}
        modalIndex={modalIndex}
        addInventoryItem={addInventoryItem}
        isLoading={isLoading}
        onClose={() =>
          handleAddInventoryItemModalClose()
        }></AddInventoryItemModal>
      {isSidebarVisible && (
        <InventorySidebarModal
          showComments={showComments}
          visible={isSidebarVisible}
          inventoryItemId={inventoryItemId!}
          onClose={handleSidebarModalClose}
          setSelectedTabKey={setSidebarTab}
          isManager={isManager}
          recordId={inventoryItemId!}
          isLoading={isInventoryItemLoading}
          inventoryItem={mapInventorySidebarData(response?.inventoryItemById)}
          error={sidebarError}
          defaultTab={sidebarTab}></InventorySidebarModal>
      )}
      <AddInventoryItemSuccessModal
        visible={addInventoryItemSuccessModalVisible}
        onClose={() =>
          setAddInventoryItemSuccessModalVisible(false)
        }></AddInventoryItemSuccessModal>
    </>
  );
};

const SubheaderStyled = styled.span`
  color: ${Theme.color.gray2};
`;

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5}></SkeletonTable>;
};
