import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../../../../../../../theme';
import { InventoryModalContext } from '../InventoryModalContext';
import { parseDate } from '../../../../../../../../utils/dateUtils';
import FormattedDate from '../../../../../../../../components/date/FormattedDate';
import { useCurrencyFormatter } from '../../../../../../../../hooks/useCurrencyFormatter';
import { useNamespacedTranslation } from '../../../../../../../../hooks/useNamespacedTranslation';
import { i18Path } from '../constants';
import { StepNamesType } from '../types';

const ResponsibleEmployeeColumn: React.FC<{
  handleChangeStep: () => void;
  employeeName?: string;
  locationName?: string;
}> = ({ handleChangeStep, employeeName, locationName }) => {
  const t = useNamespacedTranslation(i18Path);
  const responsibleEmployee = employeeName
    ? employeeName === 'Me'
      ? t('responsibleOptions.me', {})
      : t('responsibleOptions.other', {
          employeeName,
        })
    : null;

  if (employeeName || locationName) {
    return (
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <ItemAttributeLight
            visible={!!responsibleEmployee}
            className="mb-1 fw-light h7-regular">
            {responsibleEmployee}
          </ItemAttributeLight>
          <ItemAttributeLight
            visible={!!locationName}
            className="fw-light h7-regular">
            {`${t('responsibleOptions.location')}  ${locationName}`}
          </ItemAttributeLight>
        </div>
        <EditButton className="h5-regular" onClick={handleChangeStep}>
          {t('buttons.edit')}
        </EditButton>
      </div>
    );
  }
  return <></>;
};

export const InventoryItemPreview: React.FC = () => {
  const { value, onChangeStep, additionalValues, steps } = useContext(
    InventoryModalContext,
  );
  const INIT_STEP_INDEX = 0;
  const formatCurrency = useCurrencyFormatter();
  const isNewInventory = additionalValues && additionalValues.isNewInventory;
  const t = useNamespacedTranslation(i18Path);

  const navigateToStepByName = (stepName: StepNamesType) => {
    if (steps) {
      const stepIndex = steps.findIndex((item) => item.stepName === stepName);
      if (stepIndex >= INIT_STEP_INDEX) {
        onChangeStep(stepIndex);
      }
    }
  };

  if (value) {
    return (
      <PreviewCardWrapper className="mb-4 mx-4 rounded">
        <div className="p-4">
          {value.model && (
            <div className="mb-4 d-flex justify-content-between  align-items-center">
              <div>
                <p className="mb-0 fw-bold h3-regular">{value.model}</p>
              </div>
              <EditButton
                disabled={!isNewInventory}
                onClick={() => navigateToStepByName('DETAILS')}>
                {t('buttons.edit')}
              </EditButton>
            </div>
          )}
          <div className="mb-3 d-flex justify-content-between  align-items-center">
            <div>
              <ItemAttributeLight
                visible={!!value.serialNumber}
                className="mb-1 h7-regular">
                {t('previewLabels.serialNumber')}: {value.serialNumber}
              </ItemAttributeLight>
              <ItemAttributeLight
                visible={!!value.purchaseDate}
                className="mb-1 h7-regular">
                {t('previewLabels.purchasedOn')}&nbsp;
                <FormattedDate
                  day={parseDate(value.purchaseDate)}
                  format="short"
                />
              </ItemAttributeLight>
              <ItemAttributeLight
                visible={!!value.netPurchasedPrice}
                className="mb-1 h7-regular">
                {t('previewLabels.purchased')}&nbsp;
                {formatCurrency({
                  value: value.netPurchasedPrice,
                  currency: 'eur', // TODO support currencies depends on user preferences
                })}
              </ItemAttributeLight>
            </div>
            <EditButton onClick={() => navigateToStepByName('DETAILS')}>
              {t('buttons.edit')}
            </EditButton>
          </div>
          <ResponsibleEmployeeColumn
            handleChangeStep={() => navigateToStepByName('RESPONSIBLE')}
            employeeName={additionalValues?.responsibleEmployeeName}
            locationName={additionalValues?.locationName}
          />

          {value.note && (
            <div className="mb-3 d-flex justify-content-between  align-items-center">
              <div>
                <ItemNote className="fw-light fst-italic mb-0 h7-regular">
                  {value.note}
                </ItemNote>
              </div>
              <EditButton onClick={() => navigateToStepByName('NOTE')}>
                {t('buttons.edit')}
              </EditButton>
            </div>
          )}
        </div>
      </PreviewCardWrapper>
    );
  }
  return <></>;
};

const PreviewCardWrapper = styled.div({
  background: Theme.color.paper5,
});

const ItemAttributeLight = styled.p<{ visible: boolean }>(
  {
    marginBottom: 0,
  },
  ({ visible }) => ({
    display: visible ? 'block' : 'none',
  }),
);

const ItemNote = styled.p({
  color: Theme.color.gray2,
});

const EditButton = styled.p<{ disabled?: boolean }>(
  {
    marginBottom: 0,
  },
  ({ disabled }) => ({
    color: disabled ? Theme.color.gray2 : Theme.color.blue3,
    opacity: disabled ? 0.7 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
);
