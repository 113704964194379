import { TicketsFilter } from './types';
import { TicketsListItem } from '../types';
import { getMonth } from 'date-fns';
import { parseDate } from '../../../../../../utils/dateUtils';

export const hasTicketFilter = (filter: TicketsFilter): boolean => {
  if (filter.category !== null) {
    return true;
  }
  if (filter.month !== null) {
    return true;
  }
  if (filter.year !== null) {
    return true;
  }
  if (filter.assignee !== null) {
    return true;
  }

  return false;
};
export const applyTicketFilter = (
  filter: TicketsFilter,
  tickets: TicketsListItem[],
): TicketsListItem[] => tickets.filter((x) => isMatchTicketFilter(filter, x));

const isMatchTicketFilter = (
  filter: TicketsFilter,
  ticket: TicketsListItem,
): boolean => {
  if (filter.category !== null && ticket.category.id !== filter.category) {
    return false;
  }

  if (filter.assignee !== null && ticket.assignee?.id !== filter.assignee) {
    return false;
  }

  if (filter.month !== null && ticket.lastUpdate !== null) {
    if (String(getMonth(parseDate(ticket.lastUpdate)) + 1) !== filter.month) {
      return false;
    }
  }
  if (filter.year !== null && ticket.lastUpdate !== null) {
    if (parseDate(ticket.lastUpdate).getFullYear() !== parseInt(filter.year)) {
      return false;
    }
  }

  return true;
};
