import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import {
  DateAndTimeIntervalType,
  TimeEntryItem,
  TimeEntryReadOnlyReason,
} from '../logic';
import { useTranslation } from 'react-i18next';
import { ValidationResult } from '../logic/validation';
import ButtonLink from '../components/ButtonLink';
import { Actions } from './actions';
import TimeEntryDumbContent from './TimeEntryDumbContent';
import TimeEntryActions from '../components/TimeEntryActions';
import StatusBadge from '../components/StatusBadge';
import { StatusDotVariant } from '../../../../components/StatusDot';
import { TimeEntryWorkingTimeIndicator } from './TimeEntryWorkingTimeIndicator';
import { useAutoBreaksLogic } from '../logic/useAutoBreaksLogic';
import { AutoBreaksRules } from '../../../../components/AutoBreaks/shared';
import { isLegalTimeEntryStats } from '../../../../components/AutoBreaks/shared/autoBreaksCalculation/helpers';
import { PendingChangesBadge } from './PendingChangesBadge';
import { CostCenterProps } from '..';
import { WarningModal } from '../../components/WarningModal/WarningModal';
import { useTimeTrackingRestriction } from '../../../../hooks/timeTrackingSettings';

type Props = {
  timeEntry: TimeEntryItem;
  costCenters: readonly CostCenterProps[];
  readonly: boolean;
  readonlyReasons: TimeEntryReadOnlyReason[];
  readonlyIntervalToCompare?: DateAndTimeIntervalType;
  notesVisible: boolean;
  costCenterVisible: boolean;
  addNotesButtonVisible: boolean;
  addAssignCostCenterButtonVisible: boolean;
  isDeleteBreakAllowed: boolean;
  isEditNotesAllowed: boolean;
  isDeleteTimeEntryAllowed: boolean;
  forceShowEndDate: boolean;
  validationErrors: ValidationResult;
  dispatch: React.Dispatch<Actions>;
  onTimeEntryDelete?: () => void;
  isTimeEntryDeleting?: boolean;
  autoBreakRules: AutoBreaksRules | null;
  isDeleteChangeRequestAllowed?: boolean;
  onDeleteChangeRequestClick?: () => void;
};

const TopRightContainer = styled.div({
  position: 'absolute',
  right: 0,
  marginRight: 10,
  zIndex: 1,
});

const TimeEntryActionsContainer = styled.div({
  marginRight: 5,
});

const TimeEntryDumb: React.FC<Props> = (props) => {
  const {
    timeEntry,
    readonly,
    readonlyReasons,
    addNotesButtonVisible,
    isEditNotesAllowed,
    addAssignCostCenterButtonVisible,
    isDeleteTimeEntryAllowed,
    isTimeEntryDeleting,
    onTimeEntryDelete,
    dispatch,
    autoBreakRules,
    isDeleteChangeRequestAllowed,
    onDeleteChangeRequestClick,
  } = props;

  const { t } = useTranslation();
  const {
    showRestrictedWarningModal,
    setShowRestrictedWarningModal,
    hasOngoingRestrictedAbsenceForDay,
  } = useTimeTrackingRestriction();
  const isChangeRequest = timeEntry.changeRequestId !== null;

  const handleDelete = useCallback(
    () => onTimeEntryDelete && onTimeEntryDelete(),
    [onTimeEntryDelete],
  );

  const handleAddBreak = () => {
    if (hasOngoingRestrictedAbsenceForDay(timeEntry.start.date)) {
      setShowRestrictedWarningModal(true);
    } else {
      dispatch({ type: 'newBreak', breakRules: autoBreakRules });
    }
  };

  const readonlyReasonBadge = (readonlyReasons: TimeEntryReadOnlyReason[]) => {
    let label = '';
    let variant: StatusDotVariant = 'success';
    if (readonlyReasons.includes('payrollCompleted')) {
      label = t('timeTracking.timeSheet.payrollCompleted');
      variant = 'success';
    } else if (readonlyReasons.includes('timeSheetApproved')) {
      label = t('timeTracking.timeSheet.timeSheetApproved');
      variant = 'info';
    }
    return <StatusBadge label={label} variant={variant} />;
  };

  const { validationPlusAutoBreaksErrors, timeEntryStats } =
    useAutoBreaksLogic(props);

  const isTracking = timeEntry.end === null;
  const isFixBreaksVisible =
    autoBreakRules &&
    !isLegalTimeEntryStats(timeEntryStats) &&
    !readonly &&
    !isTracking;

  return (
    <div>
      <WarningModal
        visible={showRestrictedWarningModal}
        onClose={() => setShowRestrictedWarningModal(false)}
      />
      <TopRightContainer>
        {isChangeRequest && (
          <PendingChangesBadge
            isDeleteAllowed={isDeleteChangeRequestAllowed ?? false}
            onDeleteClick={onDeleteChangeRequestClick}
          />
        )}
        {!!readonlyReasons.length && readonlyReasonBadge(readonlyReasons)}
        {isDeleteTimeEntryAllowed && !isChangeRequest && (
          <TimeEntryActionsContainer role="button">
            <TimeEntryActions
              onDelete={handleDelete}
              isProcessing={isTimeEntryDeleting}
            />
          </TimeEntryActionsContainer>
        )}
      </TopRightContainer>
      <TimeEntryDumbContent
        {...props}
        readonly={readonly}
        validationErrors={validationPlusAutoBreaksErrors}
      />

      <div className="d-flex flex-wrap justify-content-between">
        <div>
          {!readonly && (
            <>
              <ButtonLink
                title={t('timeTrackingNew.dayCard.addBreak')}
                onClick={() => handleAddBreak()}
              />

              {addNotesButtonVisible && isEditNotesAllowed && (
                <ButtonLink
                  className="ms-3"
                  title={t('timeTrackingNew.dayCard.addNotes')}
                  onClick={() => dispatch({ type: 'addNotes' })}
                />
              )}

              {addAssignCostCenterButtonVisible && (
                <ButtonLink
                  className="ms-3"
                  title={t('timeTrackingNew.dayCard.assignCostCenter')}
                  onClick={() => dispatch({ type: 'assignCostCenter' })}
                />
              )}
            </>
          )}
        </div>
        <div className="d-flex align-items-center">
          {isFixBreaksVisible && (
            <ButtonLink
              className="me-3"
              title={t('timeTrackingNew.dayCard.fixBreaks')}
              onClick={() =>
                dispatch({ type: 'autoAdjust', breakRules: autoBreakRules })
              }
            />
          )}
          <TimeEntryWorkingTimeIndicator
            timeEntryId={timeEntry.uniqueId}
            timeStats={timeEntryStats}
            breaksDeductionStatus={timeEntry.breaksDeductionStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeEntryDumb;
