import { StringBoolean } from '../../../../../hooks/usePersistentFilter';
import { WorkflowItemStatus } from '../WorkflowItems/types';

export type WorkflowFilterBase = {
  workflowTemplateId: string | null;
  workflowType: string | null;
  year: string | null;
  workflowStatus: string | null;
  startDateFrom?: string | null;
  startDateTo?: string | null;
  employeeId?: string | null;
};

export type WorkflowFilterForMe = WorkflowFilterBase & {};

export type WorkflowFilter = WorkflowFilterBase & {
  employeeId: string | null;
};

export const emptyWorkflowFilterForMe: WorkflowFilterForMe = {
  workflowTemplateId: null,
  workflowType: null,
  workflowStatus: null,
  year: null,
  startDateFrom: null,
  startDateTo: null,
};

export const emptyWorkflowFilter: WorkflowFilter = {
  ...emptyWorkflowFilterForMe,
  employeeId: null,
};

export type Option = {
  label: string;
  value: string;
};

export type WorkflowItemFilterBase = {
  workflowItemTemplateId: string | null;
  workflowTemplateId: string | null;
  responsibleId: string | null;
  year: string | null;
  startDateFrom: null;
  startDateTo: null;
  status: WorkflowItemStatus | null;
  employeeId: string | null;
  onlyMyTasks: StringBoolean;
};

export type WorkflowItemFilterForMe = WorkflowItemFilterBase & {
  employeeId: string | null;
  onlyMyTasks: StringBoolean;
};

export type WorkflowItemFilterForOthers = WorkflowItemFilterBase & {
  employeeId: string | null;
  onlyMyTasks: StringBoolean;
};

export type WorkflowItemFilterForWorkflow = {
  responsibleId: string | null;
  status: WorkflowItemStatus | null;
  employeeId: string | null;
  onlyMyTasks: StringBoolean;
};

export const emptyWorkflowItemFilterForWorkflow: WorkflowItemFilterForWorkflow =
  {
    responsibleId: null,
    employeeId: null,
    status: null,
    onlyMyTasks: 'false',
  };

export type WorkflowItemFilterAllOptions = WorkflowItemFilterForOthers;

export const emptyWorkflowsItemsFilterBase: WorkflowItemFilterBase = {
  workflowTemplateId: null,
  workflowItemTemplateId: null,
  year: null,
  responsibleId: null,
  status: null,
  startDateFrom: null,
  startDateTo: null,
  employeeId: null,
  onlyMyTasks: 'true',
};

export const emptyWorkflowItemFilterForMe: WorkflowItemFilterForMe = {
  ...emptyWorkflowsItemsFilterBase,
  onlyMyTasks: 'true',
};

export const emptyWorkflowItemFilterForOthers: WorkflowItemFilterForOthers = {
  ...emptyWorkflowsItemsFilterBase,
  onlyMyTasks: 'false',
};
