import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ExpandedInventoryForm } from './ExpandedInventoryForm';
import { StepForm } from './StepForm';
import { Footer } from './Footers';
import ExpandModal from '../../../../../components/ExpandModal';
import { Form as CustomForm } from '../../../../../../../components/form/Form';
import {
  AdditionalValues,
  InventoryModalContext,
} from './InventoryModalContext';
import type { StepProp } from '../../../../../components/Step';
import { ObjectSchema } from 'yup';
import { FieldValues } from 'react-hook-form';
import { SupportedInventoryCategory } from '../../types';
import { useGetStepTitle } from '../../hooks/useGetStepTitle';

export type FormItem = {
  form: JSX.Element;
  titleKey: string;
  validationScheme?: ObjectSchema;
};

export type Props = {
  formList: FormItem[];
  steps: StepProp[];
  visible: boolean;
  children?: React.ReactElement;
  commentsCount?: number;
  onClose: () => void;
  onSubmit: (data: FieldValues, isNewInventory: boolean) => void;
  onCategoryChange: (value: SupportedInventoryCategory) => void;
  isSubmitting: boolean;
};

export const InventoryItemModal = ({
  onClose,
  formList,
  steps,
  onSubmit,
  visible,
  onCategoryChange,
  isSubmitting,
}: Props) => {
  const [data, setData] = useState<FieldValues | null>(null);
  const [isExpanded, setIsExpand] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [additionalValues, setAdditionalValues] =
    useState<AdditionalValues | null>(null);
  const maxStep = formList?.length || 0;

  const formsList = useMemo(
    () => formList.map((item) => item.form),
    [formList],
  );

  useEffect(() => {
    if (data && !data.category) return;
    setData((prevState) => {
      return { ...prevState, category: data?.category };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.category, setData]);

  const renderBody = () => {
    if (isExpanded) {
      return <ExpandedInventoryForm onCategoryChange={onCategoryChange} />;
    }
    return <StepForm list={formsList} />;
  };

  const onChange = (formData: { [key: string]: FieldValues }) => {
    const newData = { ...data, ...formData };
    setData(newData);
  };

  const onChangeAdditionalValues = (data: AdditionalValues) => {
    const newData = { ...additionalValues, ...data };
    setAdditionalValues(newData);
  };

  const onNext = (formData: FieldValues) => {
    const newData = { ...data, ...formData };
    const isNewInventory = !!additionalValues?.isNewInventory;
    setData((prevState) => {
      return { ...prevState, ...newData };
    });
    if (isExpanded || currentStep >= maxStep - 1) {
      onSubmit(newData, isNewInventory);
      return;
    }
    setCurrentStep(Math.min(currentStep + 1, formList?.length - 1 || 0));
  };

  const onCancel = () => {
    if (isExpanded || currentStep === 0) {
      onClose();
      return;
    }
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const getValidationScheme = () => {
    if (isExpanded) {
      return formList.reduce(
        (acc, formItem) => {
          if (!formItem.validationScheme) return acc;

          return acc.concat(formItem.validationScheme);
        },
        [formList[0].validationScheme],
      );
    }
    return formList[currentStep].validationScheme;
  };

  const providerProps = {
    value: {
      steps,
      isExpanded,
      maxStep,
      currentStep,
      onCancel,
      onChange,
      onChangeStep: (step: number) => setCurrentStep(step),
      value: data,
      additionalValues,
      onChangeAdditionalValues,
    },
  };

  const { title, description } = useGetStepTitle({
    isExpanded,
    currentStep,
    category: data?.category,
    inventoryName: additionalValues?.inventoryName,
    titleKey: formList[currentStep].titleKey,
  });

  return (
    <InventoryModalContext.Provider {...providerProps}>
      <ExpandModal
        description={description}
        title={title}
        isExpanded={isExpanded}
        visible={visible}
        onExpand={setIsExpand}
        onClose={onClose}>
        <CustomForm
          isChangeable
          onSubmit={onNext}
          defaultValues={data ? data : undefined}
          validationSchema={getValidationScheme()}>
          <Modal.Body className="p-0">{renderBody()}</Modal.Body>
          <Modal.Footer>
            <Footer isSubmitting={isSubmitting} isExpanded={isExpanded} />
          </Modal.Footer>
        </CustomForm>
      </ExpandModal>
    </InventoryModalContext.Provider>
  );
};
