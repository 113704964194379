import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import ServerError from '../../../../../components/ServerError';

import { useUserInfo } from '../../../context/UserInfo';
import { useProjectsQuery } from '../../../__generated__/graphql';

import Loading from '../Loading';
import { mapProject, mapTimesheet } from '../mapping';

import { ProjectDetailsSidebar } from '../ProjectSidebar/ProjectDetailsSidebar';

import { ProjectsTimesheets } from './ProjectsTimesheets';
import { Project, ProjectViewType, ProjectsFilter, Timesheet } from '../types';
import ProjectsHeader from '../ProjectsHeader';
import { projectsDefaultFilter } from '../ProjectsFiltration';
import { routes } from '../../../routes';
import ProjectsTopPanel from '../ProjectsTopPanel';
import { filterProject, i18prefix, timesheetsRange } from '../helpers';
import SelectionPanel from '../ProjectsTimesheets/SelectionPanel';
import EmptyStateCard from '../../../components/EmptyStateCard';
import {
  useMaxDailyTimeLimitTracking,
  useTimeTrackingRestriction,
} from '../../../hooks/timeTrackingSettings';
import { useFlairPersistentFilter } from '../../../../../hooks/useFlairPersistentFilter';
import { useQueryParameter } from '../../../../../hooks/useQueryParameter';

const Projects: React.FC = () => (
  <>
    <ProjectsHeader />
    <Content />
  </>
);

const TIME_SHEET_ID_PARAM = 'timeSheetId';

export const createProjectTimesheetsQueryParams = (timeSheetId: string) => {
  return {
    [TIME_SHEET_ID_PARAM]: timeSheetId,
  };
};

const Content: React.FC = () => {
  const { isManagerModeAvailable } = useUserInfo();
  // This is just to pre-fetch the data. The code will work without it
  // But there will be some delay to gray out the absences if we remove it
  // so it's better to keep it
  const { timeTrackingRestrictedAbsenceLoading } = useTimeTrackingRestriction();
  const { loading: maxDailyTimeLimitLoading } = useMaxDailyTimeLimitTracking();

  const [selectedTimesheetId, setSelectedTimesheetId] =
    useQueryParameter(TIME_SHEET_ID_PARAM);

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null,
  );

  const [filter, setFilter] = useFlairPersistentFilter<ProjectsFilter>({
    defaultFilter: projectsDefaultFilter,
    storageKey: 'projectsTracking',
    createRouteUrl: (queryParams) =>
      routes.projectsTracking.route.withQueryParams(queryParams).create({}),
  });

  const { data, loading, error } = useProjectsQuery({
    variables: timesheetsRange(),
  });

  const handleItemClick = useCallback((project: Project) => {
    setSelectedProjectId(project.id);
  }, []);

  const handleViewTypeChange = useCallback(
    (viewType: ProjectViewType) => {
      setFilter({ ...filter, viewType });
    },
    [filter, setFilter],
  );

  if (error) {
    return <ServerError />;
  }

  if (
    loading ||
    !data ||
    timeTrackingRestrictedAbsenceLoading ||
    maxDailyTimeLimitLoading
  ) {
    return <Loading />;
  }

  const timesheets = _.sortBy(data.myTimeSheets.map(mapTimesheet), 'startDate');
  if (timesheets.length === 0) {
    return <EmptyTimesheets />;
  }

  if (!data.projects.length) {
    return <EmptyProjects />;
  }

  const projects = data.projects
    .map(mapProject)
    .filter((project) => filterProject(project, filter));

  if (!selectedTimesheetId && timesheets.length > 0) {
    setSelectedTimesheetId(findCurrentTimesheet(timesheets).id);
  }

  const selectedTimesheet = timesheets.find(
    (timesheet) => timesheet.id === selectedTimesheetId,
  );

  const selectedItem = projects.find(
    (project) => project.id === selectedProjectId,
  );

  return (
    <>
      <ProjectsTopPanel filter={filter} onFiltrationChange={setFilter}>
        {selectedTimesheet && (
          <SelectionPanel
            timesheets={timesheets}
            selectedTimesheet={selectedTimesheet}
            selectedViewType={filter.viewType}
            onTimesheetChange={setSelectedTimesheetId}
            onViewTypeChange={handleViewTypeChange}></SelectionPanel>
        )}
      </ProjectsTopPanel>
      {selectedTimesheet && (
        <ProjectsTimesheets
          key={selectedTimesheet.id} //Using key to rebuild the component, otherwise isDirty is comparing incorrectly
          viewType={filter.viewType}
          projects={[...projects]}
          costCenters={data.costCenters}
          timesheet={selectedTimesheet}
          onProjectClick={handleItemClick}
        />
      )}

      <ProjectDetailsSidebar
        project={selectedItem || null}
        timeTracked={selectedItem?.timeTrackedInMinutes || 0}
        viewMode={isManagerModeAvailable ? 'MANAGER_VIEW' : 'USER_VIEW'}
        onClose={() => setSelectedProjectId(null)}
      />
    </>
  );
};

const EmptyTimesheets: React.FC = () => {
  const { t } = useTranslation();

  return <EmptyStateCard title={t(`${i18prefix}.emptyStateForTimesheets`)} />;
};

const EmptyProjects: React.FC = () => {
  const { t } = useTranslation();

  return <EmptyStateCard title={t(`${i18prefix}.emptyState`)} />;
};

export default Projects;

function findCurrentTimesheet(timesheets: Timesheet[]) {
  const today = new Date();
  const currentTimesheet = timesheets.find(
    (timesheet) => timesheet.startDate < today && timesheet.endDate > today,
  );

  return currentTimesheet || timesheets[timesheets.length - 1];
}
