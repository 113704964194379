import React from 'react';
import DurationFormat from '../DurationFormat';
import { useTimeBalanceForDay } from './useTimeBalanceForDay';

type Props = {
  day: string;
  employeeId: string;
};

// It shows TimeSheet balance only if it is already presented in cache.
// That component doesn't make additional request to the server
// Tradeof here: UX - is good, DX - is bad
export const TimeBalanceForDay: React.FC<Props> = ({ day, employeeId }) => {
  const timeBalance = useTimeBalanceForDay({
    employeeId,
    day,
    onlyFromCache: true,
  });
  if (timeBalance === null) {
    return null;
  }
  return <DurationFormat minutes={timeBalance} />;
};
