import React from 'react';
import { Project, ProjectViewMode } from '../types';
import { ProjectForm } from './ProjectForm';

type Props = {
  viewMode: ProjectViewMode;
  project: Project;
  timeTracked: number;
  onClose: () => void;
};

export const ProjectDetailsContent: React.FC<Props> = ({
  viewMode,
  project,
  timeTracked,
  onClose,
}) => {
  return (
    <ProjectForm
      project={project}
      timeTracked={timeTracked}
      viewMode={viewMode}
      onClose={onClose}
    />
  );
};
