import { useEffect, useState } from 'react';
import {
  TimeSheetPrefetchDataFragment,
  TimeSheetPrefetchDataFragmentDoc,
  useTimeSheetDetailsPrefetchLazyQuery,
} from '../../__generated__/graphql';
import { TableRow, TableRowTimeSheet, isTableRowTimeSheet } from './types';
import { useApolloCache } from '../../hooks/useApolloCache';
import { ApolloCache } from '@apollo/client';

// Each time we get new timeSheets from server (at firstRequest and on every fetchMore)
// we prefetch timeSheet details which are required for showing ChartsPopover and expanding days
// The code will work without it, but it hugly improves the UI responsiveness
export const useTimeSheetDetailsPrefetch = (tableRows: TableRow[]) => {
  const cache = useApolloCache();
  const notLoadedSubRowsTimeSheetIds = tableRows
    .filter(
      (x): x is TableRowTimeSheet =>
        isTableRowTimeSheet(x) && !isSubRowsLoaded(cache, x.timeSheetId),
    )
    .map((x) => x.timeSheetId);

  const [prefetchTimeSheets] = useTimeSheetDetailsPrefetchLazyQuery();
  const [prefetchedIds, setPrefetchedIds] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const idsToPrefetch = notLoadedSubRowsTimeSheetIds.filter(
    (x) => !prefetchedIds.includes(x),
  );

  useEffect(() => {
    if (inProgress || !idsToPrefetch.length) {
      return;
    }
    setInProgress(true);
    prefetchTimeSheets({
      variables: {
        ids: idsToPrefetch,
      },
    })
      .then((queryResult) => {
        // it is more safe to use queryResult.variables because I spotted that sometimes prefetchTimeSheets could be canceled
        // and the idsToPrefetch will contains wrong values from the first canceled request
        const prefetchedIds = queryResult.variables?.ids;
        if (!prefetchedIds) {
          return;
        }
        setPrefetchedIds((prev) => [...prev, ...prefetchedIds]);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [
    idsToPrefetch,
    prefetchTimeSheets,
    setPrefetchedIds,
    inProgress,
    setInProgress,
  ]);
};

const isSubRowsLoaded = (
  cache: ApolloCache<object>,
  timeSheetId: string,
): boolean => {
  // there is a room for optimization there, we can store already loaded id
  const timeSheetCacheId: string | undefined = cache.identify({
    __typename: 'TimeSheet',
    Id: timeSheetId,
  });
  if (!timeSheetCacheId) {
    return false;
  }
  const cacheRes = cache.readFragment<TimeSheetPrefetchDataFragment>({
    id: timeSheetCacheId,
    fragment: TimeSheetPrefetchDataFragmentDoc,
    fragmentName: 'TimeSheetPrefetchData',
  });
  return cacheRes !== null;
};
