import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from '../../../../Router';
import { NavLinkLabel } from '../../../atoms/navigation/NavLinkLabel';
import { SubItem } from '../types';

export type SubNavItemProps = {
  menuItem: SubItem;
  onClick?: () => void;
};

export const SubNavItem: React.FC<SubNavItemProps> = ({
  menuItem,
  onClick,
}) => {
  if (!menuItem.shouldRender) {
    return null;
  }

  return (
    <Nav.Item onClick={onClick}>
      <NavLink
        exact={menuItem.route.template() === '/'}
        to={menuItem.route}
        className={'nav-link d-flex align-items-center'}>
        <div className="submenu-line"></div>
        <NavLinkLabel data-testid={menuItem.label}>
          {menuItem.label}
        </NavLinkLabel>
        {menuItem.suffix}
      </NavLink>
    </Nav.Item>
  );
};
