import React from 'react';
import FlairIcon, {
  FlairIconProps,
} from '../../../../../atomic/atoms/FlairIcon';

type Props = Pick<FlairIconProps, 'onClick'>;

export const CustomizeTableColumnsIcon: React.FC<Props> = ({ onClick }) => {
  return <FlairIcon icon="settings-outline" onClick={onClick} />;
};
