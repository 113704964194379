import React, { useCallback, useMemo, useState } from 'react';
import {
  getFileNameWithoutExtension,
  getFileType,
} from '../../../../../utils/file';
import {
  FILE_SIZE_LIMIT,
  FileRowType,
} from '../DocumentUploadModal/DocumentUploadModal';
import { TOptions } from 'i18next';
import { Options } from 'react-toast-notifications';

type Props = {
  t: (key: string, options?: TOptions) => string;
  addError: (e: React.ReactNode, options?: Omit<Options, 'appearance'>) => void;
};
export const useDocumentUploadModal = (props: Props) => {
  const { t, addError } = props;

  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputId = `${Math.random()}`;
  const [files, setFiles] = useState<Map<File, FileRowType>>(new Map());

  const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files && e.dataTransfer.files[0]) {
      handleFilesChange(e.dataTransfer.files);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.files) {
      handleFilesChange(target.files);
    }
    e.target.value = '';
  };
  const handleFilesChange: (filesList: FileList) => void = (
    filesList: FileList,
  ) => {
    Array.from(filesList).map((file) => {
      if (file.size > FILE_SIZE_LIMIT) {
        addError(t('sizeLimitError', { file: file.name }));
      } else {
        files.set(file, {
          file,
          category: null,
          label: getFileNameWithoutExtension(file.name),
          type: getFileType(file.name).toUpperCase(),
        });
      }
      return files;
    });
    setFiles(new Map(files));
  };

  const handleRemoveFile: (fileRow: FileRowType) => void = useCallback(
    (fileRow) => {
      files.delete(fileRow.file);
      setFiles(new Map(files));
    },
    [files],
  );

  const handleUpdateFileName: (fileRow: FileRowType, name: string) => void =
    useCallback(
      (fileRow, name) => {
        const nextEntry = {
          ...files.get(fileRow.file)!,
          label: name,
        };
        files.set(fileRow.file, nextEntry);
        setFiles(new Map(files));
      },
      [files],
    );

  const handleSetFileCategory: (
    fileRow: FileRowType,
    category: string | null,
  ) => void = useCallback(
    (fileRow, category) => {
      const nextEntry = {
        ...files.get(fileRow.file)!,
        category,
      };
      files.set(fileRow.file, nextEntry);
      setFiles(new Map(files));
    },
    [files],
  );

  const handleValidateFiles = useCallback(() => {
    const filesData = [...files.values()].map((item) => {
      return !(!item.category || !item.label);
    });
    return filesData.includes(false);
  }, [files]);

  const fileRows = useMemo(() => Array.from(files.values()), [files]);

  return {
    handleSetFileCategory,
    handleUpdateFileName,
    handleRemoveFile,
    handleChange,
    handleDrop,
    handleDrag,
    files,
    isFilesValid: handleValidateFiles(),
    dragActive,
    inputId,
    fileRows,
  };
};
