import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  startDate: Moment;
  endDate: Moment;
};

const AbsenceDateDuration: React.FC<Props> = ({ startDate, endDate }) => {
  const { t } = useTranslation();

  const monthFormat =
    startDate.isSame(moment(), 'year') && endDate.isSame(moment(), 'year')
      ? t('date.month')
      : t('date.month-with-year');

  if (startDate.isSame(endDate, 'date')) {
    return <>{startDate.format(`D ${monthFormat}`)}</>;
  } else if (startDate.isSame(endDate, 'month')) {
    return (
      <>
        {startDate.date()}
        &nbsp;-&nbsp;
        {endDate.date()}
        &nbsp;
        {startDate.format(`${monthFormat}`)}
      </>
    );
  } else {
    return (
      <>
        {startDate.format(`DD ${monthFormat}`)}
        &nbsp;-&nbsp;
        {endDate.format(`DD ${monthFormat}`)}
      </>
    );
  }
};

export default AbsenceDateDuration;
