export type EmployeeHistory = {
  Id: string;
  Name: string;
  CreatedDate: string;
  flair__Employee__c?: string;
  flair__Old_Value__c?: string | null;
  flair__New_Value__c?: string;
};

/* eslint-disable no-unused-vars */
export enum HistoryFieldTypes {
  CompanyEmail = 'flair__Company_Email__c',
  FeedbackManager = 'flair__Feedback_Manager__c',
  Manager = 'flair__Manager__c',
  Position = 'flair__Position__c',
  Department = 'flair__Department__c',
  EmployeeCertificate = 'flair__Employee_Certificate__c',
  Location = 'flair__Location__c',
  Street = 'flair__Street__c',
  City = 'flair__City__c',
  Country = 'flair__Country__c',
  PostalCode = 'flair__Postal_Code__c',
  Name = 'Name',
  PrivateEmail = 'flair__Private_Email__c',
  StartDate = 'flair__Start_Date__c',
  Salary = 'flair__Salary__c',
  IBAN = 'flair__IBAN__c',
  BIC = 'flair__BIC__c',
  BankAccountOwner = 'flair__Bank_Account_Owner__c',
  Absence = 'flair__Absence__c',
  EmployeeFeedbackRequest = 'flair__Employee_Feedback_Request__c',
  EmployeeWorkingHours = 'flair__Employee_Working_Hours__c',
  EmployeeTaxClass = 'flair__Tax_Class__c',
  EmployeeTaxNumber = 'flair__Tax_Number__c',
}

export type HistoryFieldType =
  | HistoryFieldTypes.CompanyEmail
  | HistoryFieldTypes.FeedbackManager
  | HistoryFieldTypes.Manager
  | HistoryFieldTypes.Position
  | HistoryFieldTypes.Department
  | HistoryFieldTypes.EmployeeCertificate
  | HistoryFieldTypes.Location
  | HistoryFieldTypes.Street
  | HistoryFieldTypes.City
  | HistoryFieldTypes.Country
  | HistoryFieldTypes.PostalCode
  | HistoryFieldTypes.Name
  | HistoryFieldTypes.PrivateEmail
  | HistoryFieldTypes.StartDate
  | HistoryFieldTypes.Salary
  | HistoryFieldTypes.IBAN
  | HistoryFieldTypes.BIC
  | HistoryFieldTypes.BankAccountOwner
  | HistoryFieldTypes.Absence
  | HistoryFieldTypes.EmployeeFeedbackRequest
  | HistoryFieldTypes.EmployeeWorkingHours
  | HistoryFieldTypes.EmployeeTaxClass
  | HistoryFieldTypes.EmployeeTaxNumber;
