import { Feedback, FeedbacksFilter } from './types';

export const applyFeedbackFilter = (
  filter: FeedbacksFilter,
  feedbacks: Feedback[],
): Feedback[] => feedbacks.filter((x) => isMatchFeedbackFilter(filter, x));

const isMatchFeedbackFilter = (
  filter: FeedbacksFilter,
  feedback: Feedback,
): boolean => {
  if (filter.cycleName !== null && feedback.cycleName !== filter.cycleName) {
    return false;
  }

  if (filter.onlyActive === 'true' && feedback.isEnded) {
    return false;
  }

  if (
    filter.reviewerName !== null &&
    feedback.reviewer !== filter.reviewerName
  ) {
    return false;
  }

  if (
    filter.revieweeName !== null &&
    feedback.reviewee !== filter.revieweeName
  ) {
    return false;
  }

  if (filter.status !== null && feedback.status !== filter.status) {
    return false;
  }

  if (filter.year !== null) {
    if (feedback.assignDate.getFullYear() !== parseInt(filter.year)) {
      return false;
    }
  }

  return true;
};
