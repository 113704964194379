import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SelectableCardsGroupForm } from '../../../../../../../../../components/form/Selects/SelectableCardsGroupForm';
import { InventoryModalContext } from '../../InventoryModalContext';
import { useGetCategoryList } from '../../../../hooks/useGetCategoryList';
import {
  InventoryCategoryOptionsFragment,
  useInventoryCategoryOptionsQuery,
} from '../../../../../../../__generated__/graphql';
import ServerError from '../../../../../../../../../components/ServerError';
import { SupportedInventoryCategory } from '../../../../types';

type Props = {
  onCategoryChange: (value: SupportedInventoryCategory) => void;
};
export const InventoryCategoryFormField: React.FC<Props> = ({
  onCategoryChange,
}) => {
  const { categoryList } = useGetCategoryList();
  const { data, error } = useInventoryCategoryOptionsQuery();

  const { onChange, isExpanded, value } = useContext(InventoryModalContext);

  const categoryOptionsMap = mapCategoryOptions(data?.inventoryCategories);
  const handleChange = ({ category }: { [key: string]: string }) => {
    onChange({ category, recordTypeId: categoryOptionsMap?.[category] });
    onCategoryChange(category as SupportedInventoryCategory);
  };
  if (error) return <ServerError />;
  return (
    <Row>
      <Col className="d-flex justify-content-start px-lg-2" lg={12}>
        <SelectableCardsGroupForm
          fieldName="category"
          onChange={handleChange}
          isExpanded={isExpanded}
          cardList={categoryList}
          defaultActiveButton={value?.category}
        />
      </Col>
    </Row>
  );
};

const mapCategoryOptions = (
  data: ReadonlyArray<InventoryCategoryOptionsFragment> | undefined,
) => {
  return data?.reduce<{ [key: string]: string }>((acc, { Id, Name }) => {
    acc[Name] = Id;
    return acc;
  }, {});
};
