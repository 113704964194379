import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../theme';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type SpanProps = {
  disabled?: boolean;
  withOpacity?: boolean;
};

const Span = styled('span')<SpanProps>(({ disabled, withOpacity }) => ({
  cursor: disabled ? undefined : 'pointer',
  opacity: withOpacity ? '0.5' : '1',
}));

export type IconParams = {
  selected?: boolean;
  disabled?: boolean;
};

const ICON_COLOR = Theme.color.blue3;
const SELECTED_ICON_COLOR = Theme.color.blue3;
const DISABLED_SELECTED_ICON_COLOR = Theme.color.blue5;

const Icon: React.FC<IconParams> = ({ selected, disabled }) => (
  <FlairIcon
    icon={selected ? 'thumbs-up-filled' : 'thumbs-up-outline'}
    color={
      selected
        ? disabled
          ? DISABLED_SELECTED_ICON_COLOR
          : SELECTED_ICON_COLOR
        : ICON_COLOR
    }
    size="sm"
  />
);
const IconRotated: React.FC<IconParams> = ({ selected, disabled }) => (
  <FlairIcon
    icon={selected ? 'thumbs-up-filled' : 'thumbs-up-outline'}
    color={
      selected
        ? disabled
          ? DISABLED_SELECTED_ICON_COLOR
          : SELECTED_ICON_COLOR
        : ICON_COLOR
    }
    size="sm"
    style={{ transform: 'rotate(175deg)' }}
  />
);

export const StrongYesIcon: React.FC<IconParams> = (params) => (
  <Span className="px-3" disabled={params.disabled}>
    <Icon {...params} />
  </Span>
);
export const StrongNoIcon: React.FC<IconParams> = (params) => (
  <Span className="px-3" disabled={params.disabled}>
    <IconRotated {...params} />
  </Span>
);

export const YesIcon: React.FC<IconParams> = (params) => (
  <Span
    className="px-3"
    disabled={params.disabled}
    withOpacity={!params.selected}>
    <Icon {...params} />
  </Span>
);

export const NoIcon: React.FC<IconParams> = (params) => (
  <Span
    className="px-3"
    disabled={params.disabled}
    withOpacity={!params.selected}>
    <IconRotated {...params} />
  </Span>
);
