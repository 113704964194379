import i18next from 'i18next';
import { TimeSheetsFilter, filterWarningsTypeValues } from './types';
import { TimeSheet } from '../Tables/types';

const i18Prefix = 'timeSheetsControlling.filter';

export function getFilterWarningOptions() {
  return filterWarningsTypeValues.map((value) => ({
    value,
    label: i18next.t(`${i18Prefix}.statusOptions.${value}`),
  }));
}

export const isMatchClientFilter = (
  filter: Pick<TimeSheetsFilter, 'onlyWithComments'>,
  item: TimeSheet,
): boolean => {
  return filter.onlyWithComments === 'true' ? item.commentsCount > 0 : true;
};
