import React from 'react';
import {
  TableDayAnySubRow,
  TableRow,
  TimeSheetColumn,
  isTableRowTimeSheet,
} from './types';
import { getTdClasses, getTrClasses } from './helpers';
import { TimeBalanceWithdrawal } from './TimeBalanceWithdrawal/TimeBalanceWithdrawal';

export type Props = {
  columns: TimeSheetColumn[];
  tableRows: TableRow[];
  row: TableRow;
  subRows?: TableDayAnySubRow[];
  highlightIfHasComments: boolean;
};

export const TimeSheetsTableRow: React.FC<Props> = (props) => {
  const { row } = props;
  if (isTableRowTimeSheet(row) && props.subRows?.length && row.expanded) {
    return (
      <>
        <TimeSheetsTableFlatRow key={row.id} {...props} isExpanded={true} />
        {props.subRows.map((subRow) => (
          <TimeSheetsTableFlatRow
            key={subRow.id}
            columns={props.columns}
            tableRows={props.tableRows}
            highlightIfHasComments={props.highlightIfHasComments}
            row={subRow}
            isExpanded={false}
          />
        ))}
      </>
    );
  }
  return <TimeSheetsTableFlatRow {...props} isExpanded={false} />;
};

const TimeSheetsTableFlatRow: React.FC<Props & { isExpanded: boolean }> = ({
  row,
  tableRows,
  columns,
  isExpanded,
  highlightIfHasComments,
}) => {
  if (row.rowType === 'subrow-day-withdraw') {
    return (
      <tr>
        <td colSpan={columns.length} className="no-paddings">
          <TimeBalanceWithdrawal day={row.day} withdraw={row.withdraw} />
        </td>
      </tr>
    );
  }

  return (
    <tr className={getTrClasses(highlightIfHasComments, row).join(' ')}>
      {columns.map((column) => (
        <td
          key={column.accessor}
          data-testid={column.accessor}
          className={getTdClasses(column.accessor, row).join(' ')}>
          {column.Cell
            ? column.Cell({
                value: row[column.accessor],
                row,
                isExpanded,
                allRows: tableRows,
              })
            : null}
        </td>
      ))}
    </tr>
  );
};
