import React, { useCallback } from 'react';
import { OptionLabel } from './OptionLabel';
import {
  SelectMultipleBase,
  Props as SelectMultipleBaseProps,
} from './SelectMultipleBase';
import { RenderOptionParams } from './SelectSingleBase';
import { OptionBase } from '../../../../components/Select/types';

export type Props = Omit<
  SelectMultipleBaseProps<OptionBase>,
  'renderOption'
> & {
  options: OptionBase[];
};

export const SelectMultiple: React.FC<Props> = (props) => {
  const renderOption = useCallback(
    (props: RenderOptionParams<OptionBase>) => <OptionLabel {...props} />,
    [],
  );
  return <SelectMultipleBase {...props} renderOption={renderOption} />;
};
