import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkeletonAccordion from '../../components/Skeleton/Accordion';
import SkeletonCard from '../../components/Skeleton/Card';

const Loading: React.FC = () => {
  return (
    <Row>
      <Col md="7">
        <Row>
          <Col>
            <SkeletonCard height={330} />
          </Col>
        </Row>
      </Col>
      <Col md="5">
        <SkeletonAccordion items={3} />
      </Col>
    </Row>
  );
};

export default Loading;
