import { addMinutes, setSeconds } from 'date-fns';
import {
  DateInterval,
  getIntervalInMiddle,
  getGapMinutes,
  durationMinutes,
} from '../../../../../../utils/dateIntervalUtils';

export const addNewBreakInterval = (
  startTime: Date,
  endTime: Date,
  breaks: DateInterval[],
  durationToCreate: number,
): DateInterval | null => {
  if (durationToCreate === 0) {
    return null;
  }
  const start = setSeconds(startTime, 0);
  const end = setSeconds(endTime, 0);
  if (durationToCreate >= durationMinutes({ start, end })) {
    return null;
  }

  if (breaks.length === 0) {
    return addNewBreakInTheMiddle(start, end, durationToCreate);
  }

  const gaps = getGapMinutes(start, end, breaks);

  for (let i = gaps.length - 1; i >= 0; i--) {
    if (i === gaps.length - 1 && gaps[i].right > durationToCreate) {
      // try to create in the right of last break
      return createBreakInTheMiddleOf(
        start,
        gaps[i].startOffset + gaps[i].duration,
        gaps[i].startOffset + gaps[i].duration + gaps[i].right,
        durationToCreate,
      );
    } else if (gaps[i].left > durationToCreate) {
      // try to create in the left
      return createBreakInTheMiddleOf(
        start,
        gaps[i].startOffset - gaps[i].left,
        gaps[i].startOffset,
        durationToCreate,
      );
    }
  }
  return null;
};

export const addNewBreakInTheMiddle = (
  startTime: Date,
  endTime: Date,
  durationToCreate: number,
): DateInterval | null => {
  return getIntervalInMiddle(
    {
      start: startTime,
      end: endTime,
    },
    durationToCreate,
  );
};

const createBreakInTheMiddleOf = (
  startDate: Date,
  startOffset: number,
  endOffset: number,
  duration: number,
): DateInterval | null => {
  return getIntervalInMiddle(
    {
      start: addMinutes(startDate, startOffset),
      end: addMinutes(startDate, endOffset),
    },
    duration,
  );
};
