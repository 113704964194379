import * as React from 'react';

const SupportIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5018 1.875C8.89486 1.875 7.32398 2.35152 5.98783 3.24431C4.65168 4.1371 3.61028 5.40605 2.99531 6.8907C2.38035 8.37535 2.21945 10.009 2.53295 11.5851C2.84646 13.1612 3.62029 14.6089 4.75659 15.7452C5.89289 16.8815 7.34063 17.6554 8.91673 17.9689C10.4928 18.2824 12.1265 18.1215 13.6111 17.5065C15.0958 16.8916 16.3647 15.8502 17.2575 14.514C18.1503 13.1779 18.6268 11.607 18.6268 10C18.6246 7.84581 17.7678 5.78051 16.2446 4.25727C14.7213 2.73403 12.656 1.87727 10.5018 1.875ZM13.5565 12.1711C14.0087 11.5375 14.2518 10.7784 14.2518 10C14.2518 9.22156 14.0087 8.46254 13.5565 7.82891L15.7831 5.60313C16.8129 6.83689 17.3769 8.39295 17.3769 10C17.3769 11.607 16.8129 13.1631 15.7831 14.3969L13.5565 12.1711ZM8.00183 10C8.00183 9.50555 8.14846 9.0222 8.42316 8.61107C8.69786 8.19995 9.08831 7.87952 9.54513 7.6903C10.0019 7.50108 10.5046 7.45157 10.9896 7.54804C11.4745 7.6445 11.92 7.8826 12.2696 8.23223C12.6192 8.58186 12.8573 9.02732 12.9538 9.51227C13.0503 9.99723 13.0008 10.4999 12.8115 10.9567C12.6223 11.4135 12.3019 11.804 11.8908 12.0787C11.4796 12.3534 10.9963 12.5 10.5018 12.5C9.83879 12.5 9.20291 12.2366 8.73407 11.7678C8.26523 11.2989 8.00183 10.663 8.00183 10ZM14.8987 4.71875L12.6729 6.94531C12.0393 6.49312 11.2803 6.25006 10.5018 6.25006C9.72339 6.25006 8.96438 6.49312 8.33074 6.94531L6.10496 4.71875C7.33872 3.68898 8.89479 3.12492 10.5018 3.12492C12.1089 3.12492 13.6649 3.68898 14.8987 4.71875ZM5.22058 5.60313L7.44715 7.82891C6.99495 8.46254 6.75189 9.22156 6.75189 10C6.75189 10.7784 6.99495 11.5375 7.44715 12.1711L5.22058 14.3969C4.19082 13.1631 3.62676 11.607 3.62676 10C3.62676 8.39295 4.19082 6.83689 5.22058 5.60313ZM6.10496 15.2812L8.33074 13.0547C8.96438 13.5069 9.72339 13.7499 10.5018 13.7499C11.2803 13.7499 12.0393 13.5069 12.6729 13.0547L14.8987 15.2812C13.6649 16.311 12.1089 16.8751 10.5018 16.8751C8.89479 16.8751 7.33872 16.311 6.10496 15.2812Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default SupportIcon;
