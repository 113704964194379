import * as React from 'react';

const EllipsisVerticalCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M8 8.8125C8.44873 8.8125 8.8125 8.44873 8.8125 8C8.8125 7.55127 8.44873 7.1875 8 7.1875C7.55127 7.1875 7.1875 7.55127 7.1875 8C7.1875 8.44873 7.55127 8.8125 8 8.8125Z"
        fill="currentColor"
      />
      <path
        d="M8 11.625C8.44873 11.625 8.8125 11.2612 8.8125 10.8125C8.8125 10.3638 8.44873 10 8 10C7.55127 10 7.1875 10.3638 7.1875 10.8125C7.1875 11.2612 7.55127 11.625 8 11.625Z"
        fill="currentColor"
      />
      <path
        d="M8 6C8.44873 6 8.8125 5.63623 8.8125 5.1875C8.8125 4.73877 8.44873 4.375 8 4.375C7.55127 4.375 7.1875 4.73877 7.1875 5.1875C7.1875 5.63623 7.55127 6 8 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 4.41136 4.41136 1.5 8 1.5C11.5886 1.5 14.5 4.41136 14.5 8C14.5 11.5886 11.5886 14.5 8 14.5C4.41136 14.5 1.5 11.5886 1.5 8ZM8 2.5C4.96364 2.5 2.5 4.96364 2.5 8C2.5 11.0364 4.96364 13.5 8 13.5C11.0364 13.5 13.5 11.0364 13.5 8C13.5 4.96364 11.0364 2.5 8 2.5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default EllipsisVerticalCircleOutlineIcon;
