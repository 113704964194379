import { Emoji } from '../Announcement';
import i18next from 'i18next';

export const findEmployeeEmoji = (emojis: Emoji[], employeeId: string) =>
  emojis.find((e) => e.employee.id === employeeId);

export const getNamesOfEmployeesReacted = (emojis: Emoji[]) => {
  if (emojis.length === 0) {
    return '';
  }
  const names = emojis.map((emoji) => emoji.employee.name);
  const lastEmployeeName = names.pop();
  const andText = i18next.t('reaction.and');
  if (names.length === 0) {
    return lastEmployeeName;
  } else if (names.length === 1) {
    return `${names[0]} ${andText} ${lastEmployeeName}`;
  } else {
    return `${names.join(', ')}, ${andText} ${lastEmployeeName}`;
  }
};
