import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import './LastUpdatesWidget.css';
import Updates from './Updates';
import Button from '../../../../../components/button';
import { Spinner } from 'react-bootstrap';
import ServerError from '../../../../../components/ServerError';
import { useFetchLastUpdates } from './logic';
import { useRef } from 'react';
import LastUpdateFilters from './LastUpdateFilters';
import WidgetSpinner from '../CommonComponents/WidgetSpinner';
import { useIsScreenSizeLargerThanOrEqual } from '../../../../../hooks/useIsScreenSizeLargerThan';

const NUMBER_OF_ITEMS_TO_SHOW = 5;

const i18Path = 'dashboard.lastUpdates';

const LastUpdatesWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const lastUpdateCardRef = useRef<HTMLDivElement>(null);
  const isLargeScreen = useIsScreenSizeLargerThanOrEqual('lg');

  const {
    data,
    loading,
    isLoadingForFetchMore,
    handleShowMore,
    handleShowLess,
    error,
    filterByCategory,
    filterByTeam,
    setFilterByTeam,
    setFilterByCategory,
  } = useFetchLastUpdates();

  if (error) {
    return <ServerError />;
  }

  const isShowLessBtnVisible =
    data && data.lastUpdates.data.length > NUMBER_OF_ITEMS_TO_SHOW;

  const footerActions = () => {
    if (!data || data.lastUpdates.data.length === 0) {
      return null;
    }

    if (isLoadingForFetchMore) {
      return <Spinner animation="grow" size="sm" className="me-2" />;
    }

    return (
      <div className="d-flex-all-center gap-3-5">
        <Button
          className="p-0"
          label={t('showMore')}
          variant="link"
          onClick={handleShowMore}
        />
        {isShowLessBtnVisible && (
          <>
            <div className="vertical-separator" />
            <Button
              className="p-0"
              label={t('showLess')}
              variant="link"
              onClick={handleShowLess}
            />
          </>
        )}
      </div>
    );
  };

  const headerActions = isLargeScreen && (
    <LastUpdateFilters
      filterByCategory={filterByCategory}
      filterByTeam={filterByTeam}
      setFilterByCategory={setFilterByCategory}
      setFilterByTeam={setFilterByTeam}
    />
  );

  const lastUpdates = data?.lastUpdates.data;
  let content;

  if (loading) {
    content = <WidgetSpinner className="py-3-5" />;
  } else if (!lastUpdates) {
    return null;
  } else {
    content = <Updates updates={lastUpdates} />;
  }

  return (
    <div ref={lastUpdateCardRef}>
      <FlairCard
        cardContainerClassName="last-updates-widget"
        contentClassName="d-flex flex-column pt-2-5 pb-0 px-3-5"
        cardTitle={t('title')}
        footerActions={footerActions()}
        headerActions={headerActions}>
        {content}
      </FlairCard>
    </div>
  );
};

export default LastUpdatesWidget;
