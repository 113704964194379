import { CelebrationType } from '../../../Celebrations/types';
import { SectionType } from './types';

export type PredefinedValues = {
  celebrationType: CelebrationType;
  isPublic: boolean;
};

export const getCelebrationPredefinedValues = (
  type: SectionType,
): PredefinedValues => {
  switch (type) {
    case SectionType.BIRTHDAYS:
      return { celebrationType: 'happyBirthday', isPublic: true };
    case SectionType.ANNIVERSARIES:
      return { celebrationType: 'congrats', isPublic: false };
    default:
      return { celebrationType: 'wellDone', isPublic: true };
  }
};
