import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Section from '../../components/Section';
import { Employee, WorkflowItem } from './types';
import FormattedDate from '../../../../components/date/FormattedDate';

type Params = {
  employee: Employee;
  workflowItem: WorkflowItem | null;
};

type EmployeeRowParams = {
  text: string | null | undefined;
  label: string;
  type?: 'text' | 'email' | 'link' | 'date';
};

const InfoDisplay: React.FC<
  Required<Pick<EmployeeRowParams, 'text' | 'type'>>
> = ({ text, type }) => {
  if (!text) return <></>;
  switch (type) {
    case 'email':
      return <a href={`mailto:${text}`}>{text}</a>;
    case 'link':
      return (
        <a href={text} rel="noopener noreferrer" target="_blank">
          {text}
        </a>
      );
    case 'date':
      return <FormattedDate format="short" day={text}></FormattedDate>;
    case 'text':
      return <span>{text}</span>;
  }
};

const CandidateRow: React.FC<EmployeeRowParams> = ({
  text,
  label,
  type = 'text',
}) => {
  if (!text) {
    return <></>;
  }

  return (
    <Row className="small mt-2">
      <Col xs={6} lg={4} className="text-dark">
        {label}
      </Col>
      <Col xs={6} lg={8}>
        <InfoDisplay text={text} type={type} />
      </Col>
    </Row>
  );
};

const CandidateInfo: React.FC<Params> = ({ employee, workflowItem }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <h2>{employee.Name}</h2>
      <CandidateRow
        text={employee.flair__Company_Email__c}
        label={t('evaluations.employee.info.email')}
        type="email"
      />

      {employee.flair__Start_Date__c && (
        <CandidateRow
          text={employee.flair__Start_Date__c}
          label={t('evaluations.employee.info.startDate')}
          type="date"
        />
      )}
      {employee.flair__End_Date__c && (
        <CandidateRow
          text={employee.flair__End_Date__c}
          label={t('evaluations.employee.info.endDate')}
          type="date"
        />
      )}
      {employee.department && (
        <CandidateRow
          text={employee.department.Name}
          label={t('evaluations.employee.info.department')}
          type="text"
        />
      )}
      {employee.location && (
        <CandidateRow
          text={employee.location.Name}
          label={t('evaluations.employee.info.location')}
          type="text"
        />
      )}

      {workflowItem && workflowItem.flair__Due_Date__c && (
        <CandidateRow
          text={workflowItem.flair__Due_Date__c}
          label={t('evaluations.employee.info.dueDate')}
          type="date"
        />
      )}
    </Section>
  );
};

export default CandidateInfo;
