import { groupBy, uniqBy } from 'lodash';
import i18next from 'i18next';
import { Maybe } from '../../../../utils/maybe';
import { AbsenceCategoriesGroup } from '../../__generated__/graphql';

const NULL_KEY = 'NULL';
export type GroupWithCategories<T> = {
  id?: string;
  name?: string;
  groupName?: string;
  key: string;
  isGroup: boolean;
  categories: T[];
};

type CategoriesGroupType = Pick<
  AbsenceCategoriesGroup,
  'Id' | 'Name' | 'flair__Order__c'
>;
type CategoryWithGroup = {
  Name: string; // category name
  group?: Maybe<CategoriesGroupType>;
  order?: number;
  flair__Order__c?: number;
};

export type IQueryCategoryWithGroup = {
  category: CategoryWithGroup;
};

type SortableGroup = {
  name?: string;
  order?: number;
};

const sortByGroupOrder = (a: SortableGroup, b: SortableGroup) => {
  // make sure that data with no group will be at the end

  if (a.order === undefined) {
    return 1;
  }
  if (b.order === undefined) {
    return -1;
  }

  return a.order - b.order;
};

const sortCategories = (
  a: IQueryCategoryWithGroup,
  b: IQueryCategoryWithGroup,
) => {
  if (!a.category.flair__Order__c || !b.category.flair__Order__c) {
    return 0;
  }
  const aOrder = a.category.flair__Order__c;
  const bOrder = b.category.flair__Order__c;
  return aOrder - bOrder;
};

export const groupCategoriesByGroup = <T extends IQueryCategoryWithGroup>(
  absenceCategories: ReadonlyArray<T>,
): GroupWithCategories<T>[] => {
  const groupedCategories = groupBy(
    absenceCategories,
    (c) => c.category.group?.Id || null,
  );

  return Object.values(groupedCategories)
    .map((categoriesArray) => {
      const firstCategory = categoriesArray[0];
      return {
        id: firstCategory.category.group?.Id,
        order: firstCategory.category.group?.flair__Order__c,
        name: !firstCategory.category.group
          ? ''
          : i18next.t('categoriesGroup.groupName', {
              group_name: firstCategory.category.group?.Name,
            }),
        groupName: !firstCategory.category.group
          ? ''
          : firstCategory.category.group?.Name,
        key: firstCategory.category.group?.Id || NULL_KEY,
        isGroup: !!firstCategory.category.group?.Id,
        categories: categoriesArray.sort(sortCategories),
      };
    })
    .sort(sortByGroupOrder);
};

export const getUniqueGroupsByCategories = (
  absenceCategories: ReadonlyArray<CategoryWithGroup>,
) => {
  const allGroups: Array<CategoriesGroupType> = absenceCategories.reduce(
    (acc, cur) => (cur.group != null ? [...acc, cur.group] : acc),
    new Array<CategoriesGroupType>(),
  );

  type groupOption = { name: string; id: string };
  return uniqBy(allGroups, (c) => c.Id)
    .map(
      (g): groupOption => ({
        name: g.Name,
        id: g.Id,
      }),
    )
    .sort(sortByGroupOrder);
};
