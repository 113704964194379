import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import '../BreakModal.css';

type Props = {
  visible: boolean;
  onClose: () => void;
  onStartBreak: () => void;
};

const i18nprefix = 'autoBreaks.breakReminderModal.';

export const BreakReminderModal: React.FC<Props> = React.memo(
  ({ visible, onClose, onStartBreak }) => {
    const { t } = useTranslation();

    return (
      <Modal
        show={visible}
        onHide={onClose}
        centered
        contentClassName="border"
        dialogClassName="break-modal-440">
        <Modal.Header closeButton className="card-header">
          <h4 className="card-header-title">{t(i18nprefix + 'title')}</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div>{t(i18nprefix + 'takeABreakContent')}</div>
            <div className="d-flex justify-content-center">
              <Button
                className="mt-4"
                label={t(i18nprefix + 'startBreakBtn')}
                onClick={onStartBreak}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  },
);
