import * as React from 'react';

const AddCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M5.74902 3.5L10.248 7.99894L5.74902 12.4979"
        stroke="currentColor"
        strokeWidth="1.49965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default AddCircleOutlineIcon;
