import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { StrongNoIcon, StrongYesIcon, YesIcon, NoIcon } from './Icons';
import { Score } from '../Evaluation/EvaluationTemplate/Question/ScoreCard';

type IconTooltipProps = {
  id: string;
  tooltipText: string;
  content: React.ReactNode;
};

const IconTooltip: React.FC<IconTooltipProps> = ({
  id,
  tooltipText,
  content,
}) => (
  <OverlayTrigger
    key="top"
    placement="top"
    overlay={<Tooltip id={`tooltip-${id}`}>{tooltipText}</Tooltip>}>
    <span>{content}</span>
  </OverlayTrigger>
);

const ClickableIcon: React.FC<{
  value: number;
  checked?: boolean;
  disabled?: boolean;
  iconComponent: React.ReactNode;
  onIconClick?: (score: number) => void;
}> = ({ value, iconComponent, onIconClick }) => {
  return (
    <span onClick={() => onIconClick && onIconClick(value)}>
      {iconComponent}
    </span>
  );
};

type ScorecardIconParams = {
  id: string;
  tooltip: string;
  score: 0 | 25 | 75 | 100;
  value?: Score;
  disabled?: boolean;
  onIconClick?: (score: number) => void;
};

const Icon: React.FC<{
  score: number;
  checked?: boolean;
  disabled?: boolean;
}> = ({ score, checked, disabled }) => {
  switch (score) {
    case 0:
      return <StrongNoIcon selected={checked} disabled={disabled} />;
    case 25:
      return <NoIcon selected={checked} disabled={disabled} />;
    case 75:
      return <YesIcon selected={checked} disabled={disabled} />;
    case 100:
      return <StrongYesIcon selected={checked} disabled={disabled} />;
    default:
      return <></>;
  }
};

const ScorecardIcon: React.FC<ScorecardIconParams> = ({
  id,
  tooltip,
  score,
  value,
  disabled,
  onIconClick,
}) => {
  return (
    <IconTooltip
      id={id}
      tooltipText={tooltip}
      content={
        <ClickableIcon
          value={score}
          iconComponent={
            <Icon score={score} checked={score === value} disabled={disabled} />
          }
          onIconClick={onIconClick}
        />
      }
    />
  );
};

export default ScorecardIcon;
