import React, { useState } from 'react';
import { InventoryItemModal } from './InventoryItemModal';
import { InventoryCategoryFormField } from './Forms/FormFields/InventoryCategoryFormField';
import { useFormList } from '../../hooks/useFormList';
import { NameFormField } from './Forms';
import { FieldValues } from 'react-hook-form';
import { SupportedInventoryCategory } from '../../types';
import { categorySchema, inventorySchema } from './schemas';
import { getCategoryTitleKey } from './utils';

type Props = {
  visible: boolean;
  onClose: () => void;
  modalIndex: number;
  addInventoryItem: (data: FieldValues, isNewInventory: boolean) => void;
  isLoading: boolean;
};

const AddInventoryItemModal: React.FC<Props> = ({
  visible,
  onClose,
  modalIndex,
  addInventoryItem,
  isLoading,
}) => {
  const [category, setCategory] = useState<SupportedInventoryCategory | null>(
    null,
  );

  const { categoryFormList } = useFormList(category);

  const formList = [
    {
      form: <InventoryCategoryFormField onCategoryChange={setCategory} />,
      validationScheme: categorySchema,
      isVisibleExpanded: true,
      titleKey: 'selectCategory',
      stepName: 'CATEGORY',
    },
    {
      form: (
        <div className="mb-4">
          <NameFormField />
        </div>
      ),
      titleKey: getCategoryTitleKey(category),
      validationScheme: inventorySchema,
      isVisibleExpanded: true,
      stepName: 'NAME',
    },
    ...categoryFormList,
  ];

  return (
    <InventoryItemModal
      visible={visible}
      key={modalIndex}
      steps={formList.map((item, index) => ({
        title: `title-${index}`,
        stepName: item.stepName,
      }))}
      formList={formList}
      onCategoryChange={(value: SupportedInventoryCategory) =>
        setCategory(value)
      }
      isSubmitting={isLoading}
      onSubmit={(data, isNewInventory) => {
        addInventoryItem(data, isNewInventory);
      }}
      onClose={onClose}></InventoryItemModal>
  );
};

export default AddInventoryItemModal;
