import * as React from 'react';

const FolderOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54947)">
        <path
          d="M1 5.99942H14.9967M13.747 13.4976H2.24971C1.91826 13.4976 1.6004 13.366 1.36603 13.1316C1.13166 12.8973 1 12.5794 1 12.2479V3.74995C1 3.41851 1.13166 3.10064 1.36603 2.86627C1.6004 2.63191 1.91826 2.50024 2.24971 2.50024H4.62071C4.86746 2.50025 5.10869 2.5733 5.31398 2.71019L6.18378 3.29006C6.38907 3.42695 6.6303 3.5 6.87705 3.50001H13.747C14.0784 3.50001 14.3963 3.63167 14.6307 3.86604C14.865 4.1004 14.9967 4.41827 14.9967 4.74971V12.2479C14.9967 12.5794 14.865 12.8973 14.6307 13.1316C14.3963 13.366 14.0784 13.4976 13.747 13.4976Z"
          stroke="currentColor"
          strokeWidth="0.999764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54947">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000732422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default FolderOutlineIcon;
