import { useCallback, useState } from 'react';
import { useToasts } from '../../../../context/Toast';
import { useTranslation } from 'react-i18next';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { AbsenceRequest } from './types';
import {
  useAbsenceRequestApproveMutation,
  useAbsenceRequestRejectMutation,
} from '../../__generated__/graphql';

export const useApproveAbsenceRequestTable = () => {
  const [rejectingModalId, setRejectingModalId] = useState<string | null>(null);
  const [acceptingRequestIds, setAcceptingRequestIds] = useState<string[]>([]);
  const [rejectingRequestIds, setRejectingRequestIds] = useState<string[]>([]);
  const [approve] = useAbsenceRequestApproveMutation();
  const [reject] = useAbsenceRequestRejectMutation();
  const { addError, addSuccess } = useToasts();
  const { t } = useTranslation();
  const errorHandler = useMutationErrorHandler();

  const [resetSelectedRows, setResetSelectedRows] = useState<boolean>(false);
  const [selectAllRows, setSelectAllRows] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const getSelectedRowsCount = useCallback(() => {
    return selectedRowIds?.length ?? 0;
  }, [selectedRowIds]);

  const resetSelected = () => {
    setResetSelectedRows(true);
    setSelectedRowIds([]);
  };

  const addSelectedRow = useCallback((rows: AbsenceRequest[]) => {
    const ids = rows.map((x) => x.id);
    setSelectedRowIds(ids);
    setResetSelectedRows(false);
    setSelectAllRows(false);
  }, []);

  const handleAccept = useCallback(
    (id: string) => {
      setAcceptingRequestIds((prev) => [...prev, id]);
      approve({ variables: { ids: [id] } })
        .then((r) => {
          addSuccess(t('absences.cards.pendingAbsenceRequests.accept.success'));
        })
        .catch(() => {
          addError(t('absences.cards.pendingAbsenceRequests.accept.failure'));
        })
        .finally(() =>
          setAcceptingRequestIds((prev) => prev.filter((x) => x !== id)),
        );
    },
    [approve, addSuccess, t, addError],
  );
  const handleMassAccept = useCallback((): Promise<void> => {
    if (!selectedRowIds.length) return Promise.resolve();
    const ids = selectedRowIds;
    setAcceptingRequestIds((prev) => [...prev, ...selectedRowIds]);
    resetSelected();
    return approve({ variables: { ids: ids } })
      .then(() => {
        addSuccess(
          t('absences.cards.pendingAbsenceRequests.accept.massSuccess', {
            count: ids.length,
          }),
        );
      })
      .catch(errorHandler)
      .finally(() => {
        setAcceptingRequestIds((prev) => prev.filter((x) => !ids.includes(x)));
      });
  }, [addSuccess, approve, errorHandler, selectedRowIds, t]);

  const handleReject = useCallback(
    (id: string, reason: string | null) => {
      setRejectingRequestIds((prev) => [...prev, id]);
      setRejectingModalId(null);
      reject({ variables: { inputs: [{ id, comment: reason ?? '' }] } })
        .then((r) => {
          addSuccess(t('absences.cards.pendingAbsenceRequests.reject.success'));
        })
        .catch(() => {
          addError(t('absences.cards.pendingAbsenceRequests.reject.failure'));
        })
        .finally(() =>
          setRejectingRequestIds((prev) => prev.filter((x) => x !== id)),
        );
    },
    [reject, addSuccess, t, addError],
  );

  const handleMassReject = useCallback((): Promise<void> => {
    if (!selectedRowIds.length) return Promise.resolve();
    const ids = selectedRowIds;
    setRejectingRequestIds((prev) => [...prev, ...selectedRowIds]);
    resetSelected();
    return reject({
      variables: { inputs: ids.map((x) => ({ id: x, comment: '' })) },
    })
      .then(() => {
        addSuccess(
          t('absences.cards.pendingAbsenceRequests.reject.massSuccess', {
            count: ids.length,
          }),
        );
      })
      .catch(errorHandler)
      .finally(() => {
        setRejectingRequestIds((prev) => prev.filter((x) => !ids.includes(x)));
      });
  }, [addSuccess, errorHandler, reject, selectedRowIds, t]);

  const handleRejectButtonClick = useCallback(
    (id: string) => {
      setRejectingModalId(id);
    },
    [setRejectingModalId],
  );
  const onModalClose = () => setRejectingModalId(null);

  const isAccepting = (id: string) => acceptingRequestIds.includes(id);

  const isRejecting = (id: string) => rejectingRequestIds.includes(id);

  const isSelected = (id: string) => selectedRowIds.includes(id);

  const isInProgress = (id: string) =>
    isAccepting(id) || isRejecting(id) || isSelected(id);

  return {
    isAccepting,
    isRejecting,
    isInProgress,
    handleAccept,
    handleReject,
    handleRejectButtonClick,
    onModalClose,
    rejectingModalId,
    handleMassAccept,
    handleMassReject,
    getSelectedRowsCount,
    addSelectedRow,
    setResetSelectedRows,
    setSelectAllRows,
    selectAllRows,
    resetSelectedRows,
  };
};
