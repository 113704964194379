import React, { useCallback } from 'react';
import { SkillLevel } from '../common/types';
import { useAddEmployeeSkill } from '../common/useEmployeeSkillsMutations';
import {
  AddEmployeeSkillDialog,
  Props as AddEmployeeSkillDialogProps,
} from './AddEmployeeSkillDialog';

type Props = Omit<
  AddEmployeeSkillDialogProps,
  'onSkillAdded' | 'inProgress'
> & {
  employeeId: string;
};

export const AddEmployeeSkillDialogConnected: React.FC<Props> = (props) => {
  if (!props.show) {
    return null;
  }
  return <Content {...props} />;
};

const Content: React.FC<Props> = ({ employeeId, ...props }) => {
  const { onClose } = props;

  const { addEmployeeSkill, inProgress: addEmployeeSkillInProgress } =
    useAddEmployeeSkill();

  const handleSkillAdded = useCallback(
    (skillId: string, rate: SkillLevel | undefined) => {
      addEmployeeSkill({
        skillId,
        employeeId,
        rate: rate ?? null,
      }).then(() => {
        onClose();
      });
    },
    [addEmployeeSkill, employeeId, onClose],
  );

  return (
    <AddEmployeeSkillDialog
      {...props}
      inProgress={addEmployeeSkillInProgress}
      onSkillAdded={handleSkillAdded}
    />
  );
};
