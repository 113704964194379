import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InventoryCategoryFormField } from './Forms/FormFields/InventoryCategoryFormField';
import { useFormList } from '../../hooks/useFormList';
import { InventoryModalContext } from './InventoryModalContext';
import { NameFormField } from './Forms';
import { SupportedInventoryCategory } from '../../types';

type Props = {
  onCategoryChange: (value: SupportedInventoryCategory) => void;
};

export const ExpandedInventoryForm: React.FC<Props> = ({
  onCategoryChange,
}) => {
  const { value: context } = useContext(InventoryModalContext);

  const { categoryFormList } = useFormList(context?.category);

  return (
    <Container fluid>
      <Row className="align-items-center justify-content-center">
        <Col lg={12} className="px-lg-6 px-6">
          <Row>
            <InventoryCategoryFormField onCategoryChange={onCategoryChange} />
          </Row>
          <Row className="mb-3 mt-4">
            <NameFormField />
          </Row>
          {categoryFormList
            .filter((item) => item.isVisibleExpanded)
            .map((item) => item.form)}
        </Col>
      </Row>
    </Container>
  );
};
