import React, { useCallback } from 'react';
import { PersonalDocumentFilter, emptyPersonalDocumentFilter } from '../types';
import { useTranslation } from 'react-i18next';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import styled from '@emotion/styled';
import {
  createDocumentStatusOptions,
  createMonthOptions,
  createYearOptions,
} from './mappings';
import { getMonthsOptions } from '../../../../../utils/dateUtils';
import { OptionBase } from '../../../../../components/Select/types';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';

export type Props = {
  filter: PersonalDocumentFilter;
  onChange: (filter: PersonalDocumentFilter) => void;
  categoryOptions: OptionBase[];
  employeeOptions: EmployeeOption[];
};

export const PersonalDocumentsFilters: React.FC<Props> = ({
  filter,
  onChange,
  categoryOptions,
  employeeOptions,
}) => {
  const { t } = useTranslation();
  const yearOptions = createYearOptions(new Date());
  const monthOptions = createMonthOptions(getMonthsOptions());
  const SingleFilterStyled = styled(SingleFilter)({
    maxWidth: '12.5rem',
  });

  const handleStatusChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );

  const handleAddedByChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'addedBy'),
    [filter, onChange],
  );

  const handleYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  const handleMonthChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'month'),
    [filter, onChange],
  );
  const handleCategoryChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'category'),
    [filter, onChange],
  );

  const handleClearFilter = useCallback(() => {
    onChange(emptyPersonalDocumentFilter);
  }, [onChange]);
  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <div className="d-flex flex-wrap align-items-center"></div>
      <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
        <span className="d-none me-3 d-md-block">
          {t('documents2.personal.table.filter.filterBy')}
        </span>
        <SingleFilterStyled
          placeholder={t('documents2.personal.table.filter.category')}
          isSearchable={true}
          options={categoryOptions}
          value={filter.category}
          onChange={handleCategoryChanged}
        />
        <SingleFilterStyled
          placeholder={t('documents2.personal.table.filter.addedBy')}
          isSearchable={true}
          options={employeeOptions}
          value={filter.addedBy}
          onChange={handleAddedByChanged}
        />
        <SingleFilterStyled
          placeholder={t('documents2.personal.table.filter.status')}
          isSearchable={true}
          options={createDocumentStatusOptions()}
          value={filter.status}
          onChange={handleStatusChanged}
        />
        <SingleFilterStyled
          placeholder={t('documents2.personal.table.filter.month')}
          isSearchable={true}
          options={monthOptions}
          value={filter.month}
          onChange={handleMonthChanged}
        />
        <SingleFilterStyled
          placeholder={t('documents2.personal.table.filter.year')}
          isSearchable={true}
          options={yearOptions}
          value={filter.year}
          onChange={handleYearChanged}
        />

        <ClearFiltersLink className="ms-3" onClick={handleClearFilter} />
      </div>
    </div>
  );
};

const handleFilterChange = (
  value: string | null,
  filter: PersonalDocumentFilter,
  onChange: (filter: PersonalDocumentFilter) => void,
  filterKey: keyof PersonalDocumentFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
