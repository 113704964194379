import * as React from 'react';

const LoomIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}>
        <path
          d="M14 6.36407H9.90617L13.4517 4.31716L12.6729 2.96849L9.12742 5.01541L11.1737 1.47049L9.82508 0.691156L7.77875 4.23607V0.142822H6.22125V4.23666L4.17433 0.691156L2.82567 1.46991L4.87258 5.01482L1.32708 2.96849L0.548333 4.31716L4.09383 6.36407H0V7.92157H4.09325L0.548333 9.96849L1.32708 11.3177L4.872 9.27141L2.82508 12.8163L4.17375 13.5951L6.22067 10.0496V14.1428H7.77817V10.0496L9.8245 13.5945L11.1732 12.8157L9.12625 9.27024L12.6717 11.3172L13.4505 9.96791L9.90558 7.92157H13.9988V6.36407H14ZM7 9.26091C6.43608 9.26091 5.89526 9.03689 5.49651 8.63814C5.09776 8.23939 4.87375 7.69857 4.87375 7.13466C4.87375 6.57074 5.09776 6.02992 5.49651 5.63117C5.89526 5.23242 6.43608 5.00841 7 5.00841C7.56392 5.00841 8.10474 5.23242 8.50349 5.63117C8.90224 6.02992 9.12625 6.57074 9.12625 7.13466C9.12625 7.69857 8.90224 8.23939 8.50349 8.63814C8.10474 9.03689 7.56392 9.26091 7 9.26091Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);

export default LoomIcon;
