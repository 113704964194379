import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useEffect } from 'react';
import {
  useUpdateOnboardingFirstOpenDateMutation,
  useWorkflowDashboardQuery,
  WorkflowDashboardQuery,
} from '../../../__generated__/graphql';

export type WokflowDashboardInfo = {
  banner: BannerInfo | null;
};

type BannerInfo = {
  userName: string;
  onboardingWorkflowId: string;
};

const TOP_BANNER_DAYS_COUNT = 90;

export const useWorkflowDashboardInfo = (): WokflowDashboardInfo => {
  const { data } = useWorkflowDashboardQuery();
  const [updateOnboardingFirstOpenDate] =
    useUpdateOnboardingFirstOpenDateMutation();

  const onboardingWorkflow = data?.me?.onboardingWorkflow;
  const isUpdateOfOnboardingFirstDateRequired =
    !onboardingWorkflow?.flair__First_Opened__c;
  const onboardingWorkflowId = onboardingWorkflow?.Id;

  useEffect(() => {
    if (isUpdateOfOnboardingFirstDateRequired && onboardingWorkflowId) {
      updateOnboardingFirstOpenDate({
        variables: {
          input: {
            id: onboardingWorkflowId,
          },
        },
      });
    }
  }, [
    isUpdateOfOnboardingFirstDateRequired,
    onboardingWorkflowId,
    updateOnboardingFirstOpenDate,
  ]);

  if (
    !onboardingWorkflow ||
    !onboardingWorkflow.flair__Show_Onboarding_Widget__c
  ) {
    return {
      banner: null,
    };
  }
  return calculateWorkflowDashboardInfo(data.me, new Date());
};

export const calculateWorkflowDashboardInfo = (
  dataMe: WorkflowDashboardQuery['me'],
  now: Date,
): WokflowDashboardInfo => {
  const onboardingWorkflow = dataMe.onboardingWorkflow;
  const daysSinceFirstOpen = getDaysSinceFirstOpen(onboardingWorkflow, now);
  return {
    banner: calculateBanner(dataMe, daysSinceFirstOpen),
  };
};

const getDaysSinceFirstOpen = (
  onboardingWorkflow: WorkflowDashboardQuery['me']['onboardingWorkflow'],
  now: Date,
): number => {
  if (!onboardingWorkflow) {
    return 0;
  }
  const firstOpenDay = onboardingWorkflow.flair__First_Opened__c
    ? parseISO(onboardingWorkflow.flair__First_Opened__c)
    : now;

  return differenceInCalendarDays(now, firstOpenDay);
};

const getUserName = (dataMe: WorkflowDashboardQuery['me']): string => {
  const firstName = dataMe.flair__First_Name__c;
  const lastName = dataMe.flair__First_Name__c;
  return firstName || lastName || '';
};

const calculateBanner = (
  dataMe: WorkflowDashboardQuery['me'],
  daysSinceFirstOpen: number,
): BannerInfo | null => {
  const onboardingWorkflow = dataMe.onboardingWorkflow;
  if (!onboardingWorkflow || daysSinceFirstOpen >= TOP_BANNER_DAYS_COUNT) {
    return null;
  }

  if (onboardingWorkflow?.flair__Complete_Progress__c === 100) {
    return null;
  }

  return {
    userName: getUserName(dataMe),
    onboardingWorkflowId: onboardingWorkflow.Id,
  };
};
