import React from 'react';
import MonthPicker from '../../../../../../components/MonthPicker/MonthPicker';
import styled from '@emotion/styled';

const Picker = styled.div({
  width: 138,
});

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
  placeholder: string;
};

export const AbsenceRequestMonthFilter: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <Picker>
      <MonthPicker
        name={'month-picker'}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    </Picker>
  );
};
