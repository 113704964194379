import React from 'react';
import { useTranslation } from 'react-i18next';
import { AbsenceApprovalStatus } from '../../../__generated__/graphql';
import StatusIndicator from '../../../components/StatusIndicator';

type Props = {
  status: AbsenceApprovalStatus;
  textFirst?: boolean;
};

const AbsenceStatus: React.FC<Props> = ({ status, textFirst }) => {
  const { t } = useTranslation();

  switch (status) {
    case AbsenceApprovalStatus.Pending: {
      return (
        <StatusIndicator
          variant="warning"
          text={t('absences.status.pending')}
          textFirst={textFirst}
        />
      );
    }
    case AbsenceApprovalStatus.Approved: {
      return (
        <StatusIndicator
          variant="success"
          text={t('absences.status.approved')}
          textFirst={textFirst}
        />
      );
    }
    case AbsenceApprovalStatus.Rejected: {
      return (
        <StatusIndicator
          variant="danger"
          text={t('absences.status.rejected')}
          textFirst={textFirst}
        />
      );
    }
    case AbsenceApprovalStatus.Unknown: {
      return <StatusIndicator text={status} textFirst={textFirst} />;
    }
  }
};

export default AbsenceStatus;
