import React from 'react';
import { ABSENCE_CATEGORIES_FILTER, FilterProps } from './types';
import { useTranslation } from 'react-i18next';
import TeamCalendarMultipleFilter from './TeamCalendarMultipleFilter';

const AbsenceCategoriesFilter: React.FC<FilterProps> = ({
  options,
  filterData,
}) => {
  const { t } = useTranslation();

  return (
    <TeamCalendarMultipleFilter
      options={options}
      filterData={filterData}
      filterKey={ABSENCE_CATEGORIES_FILTER}
      placeholder={t(
        'employeePage.teamCalendar.filters.absenceCategories.placeholder',
      )}
    />
  );
};

export default AbsenceCategoriesFilter;
