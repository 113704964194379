import styled from '@emotion/styled';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { DeleteAttachmentIcon } from './DeleteAttachmentIcon';

export const DEFAULT_ATTACHMENT_WIDTH_REM_CSS = '6.25rem';
export const DEFAULT_ATTACHMENT_HEIGHT_REM_CSS = '4rem';

const DEFAULT_MIN_ROWS = 4;
const DEFAULT_MAX_ROWS = 6;

export type Props = {
  value: string;
  onChange: (value: string) => void;
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  className?: string;
  hasError?: boolean;
  attachment?: React.ReactNode;
  attachmentWidth?: string;
  attachmentHeight?: string;
  onDeleteAttachment?: () => void;
};

export const MessageInput: React.FC<Props> = ({
  minRows,
  maxRows,
  maxLength,
  disabled,
  readOnly,
  className,
  value,
  onChange,
  hasError,
  placeholder,
  attachment,
  onDeleteAttachment,
  attachmentWidth = DEFAULT_ATTACHMENT_WIDTH_REM_CSS,
  attachmentHeight = DEFAULT_ATTACHMENT_HEIGHT_REM_CSS,
}) => {
  const hasAttachment = !!attachment;

  return (
    <Container className={hasError ? 'is-invalid' : ''}>
      <TextareaAutosize
        minRows={minRows ?? DEFAULT_MIN_ROWS}
        maxRows={maxRows ?? DEFAULT_MAX_ROWS}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        className={[
          'form-control',
          className,
          hasError ? 'is-invalid' : '',
        ].join(' ')}
        value={value}
        style={{
          paddingRight: hasAttachment
            ? `calc(0.75rem + ${attachmentWidth})`
            : undefined,
        }}
        onChange={(event) => onChange(event.target.value)}
      />
      {attachment && (
        <AttachmentContainer
          style={{ width: attachmentWidth, height: attachmentHeight }}>
          {attachment}
          <DeleteAttachmentIconStyled
            onClick={() => onDeleteAttachment && onDeleteAttachment()}
          />
        </AttachmentContainer>
      )}
    </Container>
  );
};

const Container = styled.div({
  position: 'relative',
});

const AttachmentContainer = styled.div({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
});

const DeleteAttachmentIconStyled = styled(DeleteAttachmentIcon)({
  position: 'absolute',
  top: '-0.25rem',
  right: '-0.25rem',
});
