import * as React from 'react';

const FolderSimplePlusIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14 5.5V12.5562C14 12.6739 13.9532 12.7868 13.87 12.87C13.7868 12.9532 13.6739 13 13.5562 13H2.5C2.36739 13 2.24021 12.9473 2.14645 12.8536C2.05268 12.7598 2 12.6326 2 12.5V4C2 3.86739 2.05268 3.74021 2.14645 3.64645C2.24021 3.55268 2.36739 3.5 2.5 3.5H5.83125C5.93936 3.50044 6.04449 3.53549 6.13125 3.6L7.86875 4.9C7.95551 4.96451 8.06064 4.99956 8.16875 5H13.5C13.6326 5 13.7598 5.05268 13.8536 5.14645C13.9473 5.24021 14 5.36739 14 5.5Z"
        stroke="#196879"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 9H9.5"
        stroke="#196879"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 7.5V10.5"
        stroke="#196879"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default FolderSimplePlusIcon;
