import styled from '@emotion/styled';
import classNames from 'classnames';
import { getDate, isToday } from 'date-fns';
import { Moment } from 'moment';
import React from 'react';
import { Theme } from '../../../../../../theme';
import { AbsenceColleague } from '../Content';
import ColleagueFilter from './TeamCalendarFilters/ColleagueFilter';
import { TeamCalendarFiltersType } from './TeamCalendarFilters/types';
import { formatDate } from '../../../../../../utils/dateUtils';

type DaysOfMonthProps = {
  days: Moment[];
  colleagues: AbsenceColleague[];
  filterData: TeamCalendarFiltersType;
};

type DayProps = {
  day: Date;
};

const HighlightedDay = styled.div<{ highlighted: boolean }>(({ highlighted }) =>
  highlighted
    ? {
        color: Theme.color.primary,
        '::after': {
          content: '""',
          position: 'absolute',
          borderBottom: `3px solid ${Theme.color.primary}`,
          bottom: 0,
          right: 0,
          left: 0,
        },
      }
    : {},
);

const DayCell: React.FC<DayProps> = ({ day }) => (
  <td className="position-relative">
    <HighlightedDay
      className={classNames('d-flex', 'flex-column', 'px-2', 'py-3')}
      highlighted={isToday(day)}>
      <span className="fs-4">{getDate(day)}</span>
      <span>{formatDate(day, 'EEE')}</span>
    </HighlightedDay>
  </td>
);

const StickyHeader = styled.th({
  position: 'sticky',
  left: 0,
  backgroundColor: `${Theme.color.white} !important`,
  zIndex: 3,
});

const DaysOfMonth: React.FC<DaysOfMonthProps> = ({
  days,
  colleagues,
  filterData,
}) => (
  <tr>
    <StickyHeader>
      <ColleagueFilter colleagues={colleagues} filterData={filterData} />
    </StickyHeader>
    {days.map((day) => (
      <DayCell key={day.date()} day={day.toDate()} />
    ))}
  </tr>
);

export default DaysOfMonth;
