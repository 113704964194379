import React from 'react';
import { Card } from 'react-bootstrap';
import { TimeBalanceDetailsPopover } from './TimeBalanceDetailsPopover';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { DifferenceWithSign } from '../DifferenceWithSign';

type Props = {
  timeBalance: number | null;
};

// packages/web/src/apps/dashboard/manager/TimeSheetsControlling/TopPanel/TimeSheetTopPanel.tsx
export const TimeBalancePanel: React.FC<Props> = ({ timeBalance }) => {
  const t = useNamespacedTranslation('components.timeBalancePanel');
  return (
    <Card className="px-3">
      <div className="d-flex align-items-center justify-content-between py-3 mx-3 gap-3">
        <TextContainer className="d-none d-sm-flex align-items-center h5-regular color-gray-2">
          {t('text')}
        </TextContainer>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex justify-content-between">
            <div className="h6-regular color-paper-1 me-2">{t('current')}</div>
            <TimeBalanceDetailsPopover />
          </div>
          <TimeBalanceContainer
            className="h1-medium text-nowrap"
            data-testid="current-time-balance">
            {timeBalance !== null ? (
              <DifferenceWithSign value={timeBalance} withColor={true} />
            ) : (
              <div className="text-success">-</div>
            )}
          </TimeBalanceContainer>
        </div>
      </div>
    </Card>
  );
};

const TextContainer = styled.div({
  maxWidth: '50rem',
});

const TimeBalanceContainer = styled.div({
  maxWidth: '50rem',
});
