import { minutesToMilliseconds } from 'date-fns';
import React from 'react';
import { Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { usePauseTimeTracking } from '../../../hooks/timeTracking';
import { Working } from '../../../hooks/useCurrentTimeTrackingTimeline';
import TimeUntilNow from './TimeUntilNow';

type Props = {
  step: Working;
  handleShowModal: () => void;
};

const WorkingStep: React.FC<Props> = ({ step, handleShowModal }) => {
  const { timeEntryId } = step;
  const { t } = useTranslation();
  const [pauseTimeEntry, { loading: pausing }] =
    usePauseTimeTracking(timeEntryId);

  return (
    <>
      <ListGroup.Item>
        <Row>
          <Col className="col-auto">
            <div className="avatar avatar-sm">
              <div className="avatar-title fs-3 bg-primary-soft rounded-circle text-primary">
                <Spinner animation="grow" />
              </div>
            </div>
          </Col>
          <Col className="ms-n2 align-self-center">
            <h4 className="mb-1">
              <TimeUntilNow
                date={step.startTime}
                offset={minutesToMilliseconds(step.totalBreakTime)}
              />
            </h4>
            <small className="text-dark">
              {t('timeTracking.current.steps.working.workingTime')}
            </small>
          </Col>
          <Col className="col-auto align-self-center">
            <Button
              size="sm"
              variant="outline-warning"
              className="me-2 lift"
              onClick={pauseTimeEntry}
              icon="pause-outline"
              isProcessing={pausing}
              tooltip={t(
                'timeTracking.current.steps.working.startBreak.tooltip',
              )}
            />
            <Button
              size="sm"
              variant="outline-danger"
              className="lift"
              onClick={handleShowModal}
              icon="stop-outline"
              disabled={pausing}
              tooltip={t('timeTracking.current.steps.working.clockOut.tooltip')}
            />
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  );
};

export default WorkingStep;
