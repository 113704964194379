import * as React from 'react';

const bullseyeOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.50049C4.96243 2.50049 2.5 4.96292 2.5 8.00049C2.5 11.0381 4.96243 13.5005 8 13.5005C11.0376 13.5005 13.5 11.0381 13.5 8.00049C13.5 4.96292 11.0376 2.50049 8 2.50049ZM1.5 8.00049C1.5 4.41064 4.41015 1.50049 8 1.50049C11.5899 1.50049 14.5 4.41064 14.5 8.00049C14.5 11.5903 11.5899 14.5005 8 14.5005C4.41015 14.5005 1.5 11.5903 1.5 8.00049Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.25049C6.48122 5.25049 5.25 6.48171 5.25 8.00049C5.25 9.51927 6.48122 10.7505 8 10.7505C9.51878 10.7505 10.75 9.51927 10.75 8.00049C10.75 6.48171 9.51878 5.25049 8 5.25049ZM4.25 8.00049C4.25 5.92942 5.92893 4.25049 8 4.25049C10.0711 4.25049 11.75 5.92942 11.75 8.00049C11.75 10.0716 10.0711 11.7505 8 11.7505C5.92893 11.7505 4.25 10.0716 4.25 8.00049Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8.00049C7 7.4482 7.44772 7.00049 8 7.00049C8.55228 7.00049 9 7.4482 9 8.00049C9 8.55277 8.55228 9.00049 8 9.00049C7.44772 9.00049 7 8.55277 7 8.00049Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default bullseyeOutlineIcon;
