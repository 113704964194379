import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../../components/button';
import ListAlert from '../../../components/ListAlert';
import Checkbox from '../../../components/Checkbox';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  visible: boolean;
  timeSheetIds: string[];
  onApprove: (timeSheetIds: string[]) => void;
  onClose?: () => void;
};

export const TimeSheetsBulkActions: React.FC<Props> = ({
  visible,
  onClose,
  timeSheetIds,
  onApprove,
}) => {
  const t = useNamespacedTranslation('timeSheetsControlling');

  const uniqueTimeSheetIdsSet = new Set(timeSheetIds);
  const uniqueTimeSheetIdsArray = Array.from(uniqueTimeSheetIdsSet);

  return (
    <ListAlert variant="dark" show={visible} onClose={onClose} dismissible>
      <Row className="align-items-center">
        <Col>
          <Checkbox
            id="alert-bulk-actions-checkbox"
            className="d-flex align-items-center gap-2"
            label={
              uniqueTimeSheetIdsArray.length === timeSheetIds.length
                ? t('bulkActions.timeSheetSelected', {
                    count: uniqueTimeSheetIdsArray.length,
                  })
                : t('bulkActions.timeSheetSelectedWithUnique', {
                    count: timeSheetIds.length,
                    uniqueCount: uniqueTimeSheetIdsArray.length,
                  })
            }
            labelClassName="text-light"
            checked
            readOnly
          />
        </Col>
        <Col className="col-auto me-n3">
          <Button
            label={t('approve')}
            size="sm"
            onClick={() => onApprove(uniqueTimeSheetIdsArray)}
          />
        </Col>
      </Row>
    </ListAlert>
  );
};
