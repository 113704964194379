import { ManagerEmployeeReviewsQuery } from '../../../../../__generated__/graphql';
import { RecipientEmployee } from '../../../../../components/PastPerformanceReviewCard/types';
import { getEmployeeInitialsFromName } from '../../../../../utils/employeeInitials';

export const mapToRecipientEmployee = (
  peer: ManagerEmployeeReviewsQuery['manager']['performanceReviews'][0]['peerFeedbacks'][0]['reviewer'],
): RecipientEmployee => ({
  type: 'employee',
  id: peer.Id,
  employee: {
    id: peer.Id,
    name: peer.Name,
    initials: getEmployeeInitialsFromName(peer.Name),
    avatarUrl: peer.avatar?.url ?? '',
    department: null,
  },
});
