import { HiringManagerJobsQuery } from '../../__generated__/graphql';
import { ArrayElement } from '../../../../utils/arrayElement';

export type Job = ArrayElement<HiringManagerJobsQuery['hiringManager']['jobs']>;

export const NAME_FILTER = 'name';
export const LOCATIONS_FILTER = 'locations';
export const DEPARTMENT_FILTER = 'departments';

export const JobsFiltersKeysArray = [
  NAME_FILTER,
  DEPARTMENT_FILTER,
  LOCATIONS_FILTER,
] as const;
// NAME_FILTER | DEPARTMENT_FILTER | LOCATIONS_FILTER | ....
export type JobsFiltersKeys = (typeof JobsFiltersKeysArray)[number];
export type JobsFiltersType = Record<JobsFiltersKeys, string>;
