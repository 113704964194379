import React, { useContext, useState } from 'react';
import { useTimeSheetsTopPanelQuery } from '../../../__generated__/graphql';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import ServerError from '../../../../../components/ServerError';
import {
  Props as TimeSheetTopPanelProps,
  TimeSheetTopPanel,
  TimeSheetTopPanelLoading,
} from './TimeSheetTopPanel';
import { TimeSheetApproveContext } from '../../../components/TimeSheetApprove';

type Props = Omit<
  TimeSheetTopPanelProps,
  'withoutWarningsIds' | 'withWarningsIds' | 'onApprove' | 'approveInProgress'
> & {
  reportsMode: ManagerReportsMode;
};

export const TimeSheetTopPanelConnected: React.FC<Props> = ({
  reportsMode,
  ...restProps
}) => {
  const { data, loading, error } = useTimeSheetsTopPanelQuery({
    variables: { managerReportsType: mapManagerReportsType(reportsMode) },
  });

  const [approveInProgress, setApproveInProgress] = useState<boolean>(false);
  const { approveTimeSheets } = useContext(TimeSheetApproveContext);

  const handleOnApprove = (timeSheetIds: string[]) => {
    setApproveInProgress(true);
    approveTimeSheets(timeSheetIds).finally(() => setApproveInProgress(false));
  };

  if (loading || !data) {
    return <TimeSheetTopPanelLoading />;
  }

  if (error) {
    return <ServerError />;
  }

  const pendingItems = data.manager.timeSheetsPagination.items;

  const withoutWarningsIds = pendingItems
    .filter((x) => x.warnings.length === 0)
    .map((x) => x.Id);

  const withWarningsIds = pendingItems
    .filter((x) => x.warnings.length > 0)
    .map((x) => x.Id);

  return (
    <TimeSheetTopPanel
      withoutWarningsIds={withoutWarningsIds}
      withWarningsIds={withWarningsIds}
      approveInProgress={approveInProgress}
      onApprove={handleOnApprove}
      {...restProps}
    />
  );
};
