import { recruitmentRoutes } from '../../routes';
import React from 'react';
import { JobPageType } from '../types';
import CandidatesList from '../CandidatesList';
import Overview from '../Overview';
import JobEvaluations from '../JobEvaluations';

export const routes = () => ({
  candidates: {
    route: recruitmentRoutes.jobCandidates.route,
    render: (job: JobPageType) => <CandidatesList job={job} />,
  },
  overview: {
    route: recruitmentRoutes.jobOverview.route,
    render: (job: JobPageType) => <Overview job={job} />,
  },
  evaluations: {
    route: recruitmentRoutes.jobEvaluations.route,
    render: (job: JobPageType) => <JobEvaluations job={job} />,
  },
});
