import { useCallback } from 'react';
import { QueryString } from '../utils/router';
import { usePersistentFilterBase, FilterBase } from './usePersistentFilterBase';

type FilterValue = string | string[] | StringBoolean | null;
export type Filter = Record<string, FilterValue>;

export type StringBoolean = 'true' | 'false';

export type UsePersistentFilterArg<
  T extends Filter,
  K extends string = string,
> = {
  defaultFilter: T;
  storageKey: K;
  createRouteUrl: (params: QueryString) => string;
};

export const usePersistentFilter = <
  T extends Filter,
  K extends string = string,
>(
  a: UsePersistentFilterArg<T, K>,
): [T, (filter: T) => void] => {
  const [filterBase, setFilterBase] = usePersistentFilterBase({
    ...a,
    defaultFilter: toFilterBase(a.defaultFilter),
  });

  const setFilter = useCallback(
    (value) => {
      setFilterBase(toFilterBase(value));
    },
    [setFilterBase],
  );

  return [fromFilterBase<T>(filterBase), setFilter];
};

const toFilterBase = (filter: Filter): FilterBase => {
  const allKeys = Object.keys(filter);
  return allKeys.reduce((acc, curKey) => {
    return {
      ...acc,
      [curKey]: toFilterBaseValue(filter[curKey]),
    };
  }, {} as FilterBase);
};

const fromFilterBase = <T extends Filter>(filter: FilterBase): T => {
  const allKeys = Object.keys(filter);
  return allKeys.reduce((acc, curKey) => {
    return {
      ...acc,
      [curKey]: fromFilterBaseValue(filter[curKey]),
    };
  }, {} as T);
};

const toFilterBaseValue = (src: FilterValue): string | null => {
  if (src === null || typeof src === 'string') {
    return src;
  }
  if (Array.isArray(src)) {
    return JSON.stringify(src);
  }
  return null;
};

const fromFilterBaseValue = (src: string | null): FilterValue => {
  if (src === null) {
    return null;
  }
  try {
    const parsedObj: unknown = JSON.parse(src);
    if (Array.isArray(parsedObj)) {
      return parsedObj;
    }
  } catch {}
  return src;
};
