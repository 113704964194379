import { DashboardEndOfProbation_ColleagueFragment } from '../../../../__generated__/graphql';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import React from 'react';
import { mapProbation } from '../mapping';

export const mapProbationsForModal = (
  probation: DashboardEndOfProbation_ColleagueFragment,
) => {
  const employee = {
    name: probation.Name,
    avatarUrl: probation.avatar?.url,
  };

  return {
    ...mapProbation(probation),
    employee: <EmployeeWithAvatar employee={employee} />,
  };
};
