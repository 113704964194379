import React from 'react';
import ManagerDashboard from '.';
import { param, route } from '../../../utils/router';
import RedirectToHome from '../components/RedirectToHome';
import Expenses from './Expenses';
import EmployeePage from './EmployeePage';
import MyTeam from './MyTeam';
import { ManagerRole } from '../__generated__/graphql';
import { EmployeeDocuments } from './EmployeePage/EmployeeDocuments/EmployeeDocuments';
import { managerRolesWhoHasAccessToDocuments } from '../pages/Documents2/constants';
import { AbsenceRequestsTabs } from './Absences/tabs';
import { EmployeeInventory } from './EmployeePage/EmployeeInventory/EmployeeInventory';
import { SupportPage } from '../pages/Support/SupportPage';
import { UserInfo } from '../context/UserInfo/types';
import { AnalyticsOverview } from '../pages/Analytics';
import { AbsenceRatePage } from '../pages/Analytics/AbsenceRate';
import { EmployeeTurnoverRatePage } from '../pages/Analytics/EmployeeTurnoverRate';
import { EmployeeOvertimePage } from '../pages/Analytics/EmployeeOvertime';

export const renderIfActive =
  (node: React.ReactNode) =>
  ({ isManagerModeAvailable, isActive }: UserInfo) =>
    isManagerModeAvailable && isActive ? node : <RedirectToHome />;

export const managerRoutes = {
  employeePage: {
    route: route('manager', 'employees', param('employeeId')),
    rolesWithAccess: [
      ManagerRole.DirectManager,
      ManagerRole.FeedbackManager,
      ManagerRole.AbsenceApprover,
      ManagerRole.TimeEntryApprover,
      ManagerRole.Compensation,
      ManagerRole.SeeEmployeeData,
    ],
    render: renderIfActive(<EmployeePage />),
  },
  pendingAbsenceRequests: {
    route: route('absences', 'pending-requests'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.AbsenceApprover],
    render: renderIfActive(<AbsenceRequestsTabs />),
  },
  allAbsenceRequests: {
    route: route('absences', 'all-requests'),
    render: renderIfActive(<AbsenceRequestsTabs />),
  },
  reviews: {
    route: route('manager', 'reviews'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.FeedbackManager],
    render: renderIfActive(<ManagerDashboard />),
  },
  goals: {
    route: route('manager', 'goals'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.FeedbackManager],
    render: renderIfActive(<ManagerDashboard />),
  },
  skills: {
    route: route('manager', 'skills'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.FeedbackManager],
    render: renderIfActive(<ManagerDashboard />),
  },
  myTeam: {
    route: route('my-team'),
    rolesWithAccess: [
      ManagerRole.DirectManager,
      ManagerRole.FeedbackManager,
      ManagerRole.AbsenceApprover,
      ManagerRole.TimeEntryApprover,
      ManagerRole.Compensation,
      ManagerRole.SeeEmployeeData,
    ],
    render: renderIfActive(<MyTeam />),
  },
  documents: {
    route: route('manager', 'employees', param('employeeId'), 'documents'),
    rolesWithAccess: managerRolesWhoHasAccessToDocuments,
    render: renderIfActive(<EmployeeDocuments />),
  },
  employeeInventory: {
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.SeeEmployeeData],
    route: route('manager', 'employees', param('employeeId'), 'inventory'),
    render: renderIfActive(<EmployeeInventory />),
  },
  employeeNotes: {
    rolesWithAccess: [ManagerRole.DirectManager],
    route: route('manager', 'employees', param('employeeId'), 'notes'),
    render: renderIfActive(<ManagerDashboard />),
  },
  expense: {
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.ExpenseApprover],
    route: route('manager', 'expense', param('id')),
    render: renderIfActive(<Expenses />),
  },
  expenses: {
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.ExpenseApprover],
    route: route('manager', 'expenses'),
    render: renderIfActive(<Expenses />),
  },
  managerTickets: {
    rolesWithAccess: [
      ManagerRole.DirectManager,
      ManagerRole.FeedbackManager,
      ManagerRole.AbsenceApprover,
      ManagerRole.TimeEntryApprover,
      ManagerRole.Compensation,
      ManagerRole.SeeEmployeeData,
    ],
    route: route('manager', 'support', 'tickets'),
    render: renderIfActive(<SupportPage isManager={true} />),
  },
  analytics: {
    rolesWithAccess: [ManagerRole.DirectManager],
    route: route('analytics', 'overview'),
    render: renderIfActive(<AnalyticsOverview />),
  },
  absenceRate: {
    rolesWithAccess: [ManagerRole.DirectManager],
    route: route('analytics', 'absence-rate'),
    render: renderIfActive(<AbsenceRatePage />),
  },
  employeeTurnoverRate: {
    rolesWithAccess: [ManagerRole.DirectManager],
    route: route('analytics', 'employee-turnover-rate'),
    render: renderIfActive(<EmployeeTurnoverRatePage />),
  },
  employeeOvertime: {
    rolesWithAccess: [ManagerRole.DirectManager],
    route: route('analytics', 'employee-overtime'),
    render: renderIfActive(<EmployeeOvertimePage />),
  },
};
