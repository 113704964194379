import React, { useEffect } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import styled from '@emotion/styled';

type Props = {
  data: ChartData<'bar'>;
  options: ChartOptions<'bar'>;
};

const Bar: React.FC<Props> = ({ data, options }) => {
  const chartRef = React.createRef<HTMLCanvasElement>();
  const chart = React.useRef<Chart>();

  useEffect(() => {
    if (chartRef.current) {
      const myChartRef = chartRef.current.getContext('2d');

      if (myChartRef) {
        chart.current = new Chart(myChartRef, {
          data,
          type: 'bar',
          options,
        });
      }
    }

    return () => {
      chart.current?.destroy();
    };
  }, [chartRef, data, chart, options]);

  return (
    <StyledChart className="chart" data-testid="bar-chart">
      <canvas className="chart-canvas" ref={chartRef} />
    </StyledChart>
  );
};

const StyledChart = styled.div`
  height: 200px;
`;

export default Bar;
