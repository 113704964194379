import React from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, useParams } from 'react-router-dom';
import RedirectToHome from '../../components/RedirectToHome';
import JobPageHeader from './JobPageHeader';
import { routes } from './JobPageHeader/routes';
import { JobPageType, Params } from './types';
import { useJobPageQuery } from '../../__generated__/graphql';
import RedirectToRecruitmentHome from '../RedirectToRecruitmentHome';
import Loading from './Loading';

type Props = {
  job: JobPageType;
};

const Content: React.FC<Props> = ({ job }) => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render(job)}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

const JobPage: React.FC = () => {
  const { jobId } = useParams<Params>();

  const { data, loading, error } = useJobPageQuery({
    variables: {
      id: jobId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.hiringManager?.job) {
    return <RedirectToRecruitmentHome />;
  }

  const job = data.hiringManager.job;
  return (
    <Container>
      <JobPageHeader job={job} />
      <Content job={job} />
    </Container>
  );
};

export default JobPage;
