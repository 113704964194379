import React from 'react';
import CancelButton from '../components/CancelButton';
import SubmitButton from '../components/SubmitButton';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

type Props = {
  isProcessing: boolean;
  submitDisabled: boolean;
  onCancel: () => void;
};

export const TimeEntryEmployeeButtons: React.FC<Props> = ({
  isProcessing,
  submitDisabled,
  onCancel,
}) => {
  const t = useNamespacedTranslation('timeTrackingNew.dayCard');
  return (
    <>
      <CancelButton
        disabled={isProcessing}
        onClick={onCancel}
        title={t('cancel')}
      />
      <SubmitButton
        disabled={submitDisabled}
        isProcessing={isProcessing}
        title={t('saveChanges')}
      />
    </>
  );
};
