import React from 'react';
import { Tickets } from '../../pages/Support/Tickets/Tickets';
import { useFlairTableWithPaginationAndFilter } from '../../../../hooks/useFlairTableWithPaginationAndFilter';
import { emptyTicketsFilter } from '../../pages/Support/common';
import { routes as mainRoutes } from '../../routes';
import { getCurrentLanguage } from '../../../../locales/locale';
import { useUserInfo } from '../../context/UserInfo';
import { useTicketsQuery } from '../../__generated__/graphql';
import { mapTicketsListFilterInput } from '../../pages/Support/Tickets/Filters/mappings';
import { mapSortBy } from '../../pages/Support/Tickets/mappings';
import { mapManagerTickets } from './mappings';
import { TicketsListItem } from '../../pages/Support/Tickets/types';
import { TicketsFilter } from '../../pages/Support/Tickets/Filters/types';

const PAGE_SIZE = 50;
export const ManagerTicketsConnected: React.FC = () => {
  const isManagerView = true;
  const locale = getCurrentLanguage();
  const { id: meId } = useUserInfo();

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter<TicketsListItem, TicketsFilter>({
    defaultFilter: { ...emptyTicketsFilter, status: 'OPEN_IN_PROGRESS' },
    storageKey: 'manager_tickets_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.managerTickets.route.withQueryParams(queryParams).create({}),
  });
  const { data, loading, error } = useTicketsQuery({
    variables: {
      employeeId: meId,
      lang: locale,
      filter: mapTicketsListFilterInput(tableState.filter),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });
  const tableItemsTotalCount = data?.manager.tickets.paginationInfo?.totalCount;

  const allTickets = data ? mapManagerTickets(data) : [];

  return (
    <Tickets
      isManager={isManagerView}
      pageSize={PAGE_SIZE}
      data={allTickets}
      tableItemsTotalCount={tableItemsTotalCount}
      tableStateFilter={tableState.filter}
      tableStateSortBy={tableState.sortBy}
      tableStatePageIndex={tableState.pageIndex}
      handleFilterChange={handleFilterChange}
      handlePageIndexChanged={handlePageIndexChanged}
      handleSortByChanged={handleSortByChanged}
      loading={loading}
      error={error}
    />
  );
};
