import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import {
  createWorkflowStatusOptions,
  createWorkflowTypeOptions,
  createYearOptions,
} from './mappings';
import { emptyWorkflowFilter, Option, WorkflowFilter } from './types';

export type Props = {
  employeeOptions?: Option[];
  workflowTemplateOptions: Option[];
  filter: WorkflowFilter;
  onChange: (filter: WorkflowFilter) => void;
};

export const WorkflowsFilter: React.FC<Props> = ({
  filter,
  onChange,
  workflowTemplateOptions,
  employeeOptions,
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  const yearOptions = createYearOptions(new Date());

  const handleClearFilter = useCallback(() => {
    onChange(emptyWorkflowFilter);
  }, [onChange]);

  const handleWorkflowTemplateIdChanged = useCallback(
    (value) =>
      handleFilterChange(value, filter, onChange, 'workflowTemplateId'),
    [filter, onChange],
  );

  const handleEmployeeChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'employeeId'),
    [filter, onChange],
  );

  const handleWorkflowTypeChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'workflowType'),
    [filter, onChange],
  );

  const handleWorkflowStatusChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'workflowStatus'),
    [filter, onChange],
  );

  const handleYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  return (
    <div className="d-flex gap-2 flex-wrap align-items-center">
      <span className="d-none d-md-block">{t('filterBy')}</span>
      <SingleFilterStyled
        placeholder={t('workflowTemplate')}
        isSearchable={true}
        options={workflowTemplateOptions}
        value={filter.workflowTemplateId}
        onChange={handleWorkflowTemplateIdChanged}
      />

      {employeeOptions && (
        <SingleFilterStyled
          placeholder={t('employee')}
          options={employeeOptions}
          isSearchable={true}
          value={filter.employeeId}
          onChange={handleEmployeeChanged}
        />
      )}

      <SingleFilterStyled
        placeholder={t('workflowType')}
        options={createWorkflowTypeOptions()}
        value={filter.workflowType}
        onChange={handleWorkflowTypeChanged}
      />
      <SingleFilterStyled
        placeholder={t('year')}
        options={yearOptions}
        value={filter.year}
        onChange={handleYearChanged}
      />
      <SingleFilterStyled
        placeholder={t('workflowStatus')}
        options={createWorkflowStatusOptions()}
        value={filter.workflowStatus}
        onChange={handleWorkflowStatusChanged}
      />

      <ClearFiltersLink onClick={handleClearFilter} />
    </div>
  );
};

const SingleFilterStyled = styled(SingleFilter)({
  maxWidth: '200px',
});

const handleFilterChange = (
  value: string | null,
  filter: WorkflowFilter,
  onChange: (filter: WorkflowFilter) => void,
  filterKey: keyof WorkflowFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
