import React from 'react';
import { AlertProps, Alert } from 'react-bootstrap';

const ListAlert: React.FC<AlertProps> = ({ children, className, ...rest }) => (
  <Alert className={`list-alert ${className}`} {...rest}>
    {children}
  </Alert>
);

export default ListAlert;
