import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useApproveTimeEntryChangeRequest } from './useApproveRejectTimeEntryChangeRequestMutations';

const BATCH_SIZE = 5;

export type BatchProcessStatus = 'notstarted' | 'inprogress' | 'finished';

export type BatchProcessState = {
  total: number;
  status: BatchProcessStatus;
  successCount: number;
  failedCount: number;
  errors: string[];
};

export const useApproveTimeEntryChangeRequestBatchUI = (
  btnInitialLabel: string,
) => {
  const { t } = useTranslation();
  const { addSuccess, addWarning, addError } = useToasts();

  const { approve, batchState } = useApproveTimeEntryChangeRequestBatch();
  const errorHandler = useMutationErrorHandler(() =>
    t('timeTracking.changeRequests.bulkActions.approve.failure'),
  );

  useEffect(() => {
    if (batchState.status !== 'finished') {
      return;
    }
    if (batchState.successCount > 0) {
      addSuccess(
        t('timeTracking.changeRequests.batchApproveAllSuccessToast', {
          count: batchState.successCount,
        }),
      );
    }
    if (
      batchState.successCount < batchState.total &&
      batchState.errors.length === 0
    ) {
      addWarning(t('timeTracking.changeRequests.batchApproveNotAllSuccess'));
    } else if (batchState.errors.length > 0) {
      addError(
        t('timeTracking.changeRequests.batchApproveHasSomeErrors', {
          errors: batchState.errors.join('; '),
        }),
      );
    }
  }, [t, addSuccess, addWarning, addError, batchState]);

  const btnLabel = useMemo(() => {
    if (batchState.status !== 'inprogress') {
      return btnInitialLabel;
    }
    const progressPercent = getBatchProgressInPercent(batchState);
    if (progressPercent === 0) {
      return btnInitialLabel;
    }
    return `${btnInitialLabel} (${progressPercent}%)`;
  }, [btnInitialLabel, batchState]);

  const handleOnApprove = useCallback(
    (requestIds) => {
      approve(requestIds).catch(errorHandler);
    },
    [approve, errorHandler],
  );

  return {
    handleOnApprove,
    inProgress: batchState.status === 'inprogress',
    btnLabel,
  };
};

export const useApproveTimeEntryChangeRequestBatch = () => {
  const [batchState, setBatchState] = useState<BatchProcessState>(
    createInitialState(),
  );

  const [batchApprove] = useApproveTimeEntryChangeRequest();

  const approve = useCallback(
    async (ids: string[]) => {
      const total = ids.length;
      setBatchState({
        ...createInitialState(),
        total,
        status: 'inprogress',
      });
      for (
        let curBatchIndex = 0;
        curBatchIndex < Math.ceil(total / BATCH_SIZE);
        curBatchIndex++
      ) {
        const startI = curBatchIndex * BATCH_SIZE;
        const endI = Math.min(startI + BATCH_SIZE, ids.length);
        const batchIds = ids.slice(startI, endI);

        try {
          const approveRes = await batchApprove({
            variables: { input: { ids: batchIds } },
          });

          if (approveRes.data) {
            const successIdsCount: number =
              approveRes.data?.timeTracking.approveTimeEntryChangeRequests
                .recordIds.length ?? 0;
            setBatchState((prev) => ({
              ...prev,
              successCount: prev.successCount + successIdsCount,
            }));
          } else if (approveRes.errors?.length) {
            const errorMesssages = approveRes.errors.map((x) => x.message);
            setBatchState((prev) => ({
              ...prev,
              failedCount: prev.failedCount + batchIds.length,
              errors: [...prev.errors, ...errorMesssages],
            }));
          }
        } catch (e) {
          setBatchState((prev) => ({
            ...prev,
            failedCount: prev.failedCount + batchIds.length,
            errors: [...prev.errors, getError(e)],
          }));
        }
      }
      setBatchState((prev) => ({
        ...prev,
        status: 'finished',
      }));
    },
    [batchApprove, setBatchState],
  );

  return { approve, batchState };
};

export function getBatchProgressInPercent(state: BatchProcessState): number {
  if (state.status === 'notstarted') {
    return 0;
  }
  if (state.total === 0) {
    return 100;
  }
  return Math.round(
    (100 * (state.failedCount + state.successCount)) / state.total,
  );
}

function createInitialState(): BatchProcessState {
  return {
    total: 0,
    status: 'notstarted',
    successCount: 0,
    failedCount: 0,
    errors: [],
  };
}

function getError(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  }
  return 'Unknown error';
}
