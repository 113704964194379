import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SelectMultiple,
  Props as SelectMultipleProps,
} from '../../../apps/dashboard/components/Selects/SelectMultiple';

type Props = Omit<SelectMultipleProps, 'values' | 'onChange'> & {
  name: string;
};

export const SelectMultipleControlled: React.FC<Props> = (props) => {
  const { control } = useFormContext();

  return (
    <Controller
      as={({ value, onChange }) => {
        return <SelectMultiple {...props} values={value} onChange={onChange} />;
      }}
      name={props.name}
      control={control}
    />
  );
};
