import styled from '@emotion/styled';
import React from 'react';
import { SkillIcon } from './SkillIcon';

type Props = {
  name: string;
  rateable: boolean;
  className?: string;
  maxWidth?: number;
};

export const SkillNameWithIcon: React.FC<Props> = ({
  className,
  name,
  maxWidth,
  rateable,
}) => {
  return (
    <Container className={className} title={name} maxWidth={maxWidth}>
      <SkillIcon rateable={rateable} />
      <span className="ps-2">{name}</span>
    </Container>
  );
};

const Container = styled.div<{ maxWidth?: number }>(
  ({ maxWidth = undefined }) => ({
    maxWidth,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
);
