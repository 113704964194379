import { TicketCategoriesQuery } from '../../../../__generated__/graphql';
import { SelectableRowType } from '../../../../../../components/SelectableRowGroup/types';

export const mapTicketCategories = (
  data: TicketCategoriesQuery,
): SelectableRowType[] => {
  return (
    data?.dictionaryItemsByType
      .map((category) => ({
        value: category.Id,
        label: category.label ? category.label : category.Name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || []
  );
};

export const applyCategorySearch = (
  search: string | null,
  categories: SelectableRowType[],
): SelectableRowType[] => {
  if (!search) return categories;
  return (
    categories
      .filter((category) => {
        const label = category.label.toLowerCase();
        return label.includes(search.toLowerCase());
      })
      .sort((a, b) => a.label.localeCompare(b.label)) || []
  );
};
