import { SelectableRowSize } from './types';

export const getRowSize = (size?: SelectableRowSize) => {
  switch (size) {
    case 'md':
      return {
        height: '4rem',
      };
    case 'sm':
      return {
        height: '2.875rem',
      };
    default:
      return {
        height: '2.875rem',
      };
  }
};
