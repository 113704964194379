import React, { useMemo } from 'react';
import { TableContent } from '../../manager/components/TableContent';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { Colleague, Expense, ExpenseStatus, ExpenseStatusInfo } from './types';
import FormattedDate from '../../../../components/date/FormattedDate';
import { EmployeeWithAvatar } from '../../components/Employee';
import { ExpenseStatusCell } from './Common/ExpenseStatusCell';
import { EmptyExpenses } from './EmptyExpenses';
import { useCurrencyFormatter } from '../../../../hooks/useCurrencyFormatter';
import { ExpenseCategory } from '../../__generated__/graphql';
import styled from '@emotion/styled';
import { categorySort, employeeSort } from './Common/sorting';
import SoftBadge from '../../components/SoftBadge';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Item } from './MyExpensesTable';

type TableItem = {
  id: string;
  employee: Colleague;
  name: string;
  merchant: string;
  category: ExpenseCategory | null;
  amount: number;
  currency: string;
  expenseDate: Date;
  status: string;
  updatingToStatus: ExpenseStatus | null;
  isNew: boolean;
};

type Props = {
  onItemClick: (item: Maybe<Item>) => void;
  expenses: Expense[];
  updatingItems: ExpenseStatusInfo[];
  onStatusChange: (info: ExpenseStatusInfo) => void;
};

const EMPTY_VALUE = '---';

export const ExpensesTable: React.FC<Props> = ({
  onItemClick,
  expenses,
  updatingItems,
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const items = expenses.map((x) => mapToTableItem(x, updatingItems));
  const formatCurrency = useCurrencyFormatter();

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.employee" />,
        accessor: 'employee',
        Cell: ({ value, row }: { value: Colleague; row: Row<TableItem> }) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <a
                href="stub"
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick({ id: row.original.id });
                }}>
                <EmployeeWithAvatar
                  employee={{
                    firstName: value.flair__First_Name__c ?? '',
                    lastName: value.flair__Last_Name__c ?? '',
                    avatarUrl: value.avatar?.url,
                  }}
                />
              </a>
              {row.original.isNew && (
                <SoftBadge variant="primary">
                  {t('expenses.table.newLabel')}
                </SoftBadge>
              )}
            </div>
          );
        },
        sortType: (rowA: Row<TableItem>, rowB: Row<TableItem>) =>
          employeeSort(rowA.original.employee, rowB.original.employee),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.name" />,
        accessor: 'name',
        Cell: ({ value }: { value: string }) => (
          <ExpenseNameContainer title={value} className="text-truncate">
            {value}
          </ExpenseNameContainer>
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.merchant" />,
        accessor: 'merchant',
        Cell: ({ value }: { value: string }) => (
          <div>{value ? value : EMPTY_VALUE}</div>
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.category" />,
        accessor: 'category',
        Cell: ({ value }: { value: ExpenseCategory | null }) => {
          return <div>{value?.Name ?? EMPTY_VALUE}</div>;
        },
        sortType: (rowA: Row<TableItem>, rowB: Row<TableItem>) =>
          categorySort(rowA.original.category, rowB.original.category),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.amount" />,
        accessor: 'amount',
        Cell: ({ value, row }: { value: number; row: Row<TableItem> }) => (
          <div>
            {formatCurrency({
              value: value,
              currency: row.original.currency,
            })}
          </div>
        ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="expenses.table.header.expenseDate" />
        ),
        accessor: 'expenseDate',
        Cell: ({ value }: { value: Date }) => (
          <FormattedDate day={value} format="short" />
        ),
      },
      {
        Header: () => <Trans t={t} i18nKey="expenses.table.header.status" />,
        accessor: 'status',
        Cell: ({
          value,
          row,
        }: {
          value: ExpenseStatus;
          row: Row<TableItem>;
        }) => {
          const readonly = false;
          const id = row.original.id;
          const statusInfo: ExpenseStatusInfo = {
            expenseId: id,
            status: value,
            declineReason: null,
          };
          const updatingToStatus = row.original.updatingToStatus;
          return (
            <ExpenseStatusCell
              value={statusInfo}
              readonly={readonly}
              onChange={onStatusChange}
              updatingToStatus={updatingToStatus}
            />
          );
        },
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [formatCurrency, onItemClick, onStatusChange, t]);

  if (!items.length) {
    return <EmptyExpenses />;
  }

  return (
    <TableContent
      columns={columns}
      data={items}
      getRowProps={() => ({ role: 'button' })}
    />
  );
};

function mapToTableItem(
  src: Expense,
  updatingItems: ExpenseStatusInfo[],
): TableItem {
  const updatingToStatus =
    updatingItems.find((x) => x.expenseId === src.id)?.status ?? null;
  return {
    ...src,
    updatingToStatus,
  };
}

const ExpenseNameContainer = styled.div({
  maxWidth: '270px',
});
