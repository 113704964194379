import React from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  onClose: () => void;
};
const i18Path = 'documents2.personal.moveModal';

const DocumentMoveModalLoading: React.FC<Props> = ({ onClose }) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Modal.Body className={'pt-0'}>
      <div className="d-none d-md-flex flex-column align-items-center justify-content-center">
        <div>
          <Skeleton width={550} height={35} />
        </div>
        <div className="d-md-flex flex-column">
          <Skeleton width={550} height={35} />
          <Skeleton width={550} height={35} />
        </div>
        <div className="d-flex justify-content-center mt-4 gap-3">
          <Button
            variant="outline-primary"
            label={t('buttons.cancel')}
            onClick={() => onClose()}
          />
          <Button
            disabled={true}
            type="submit"
            label={t('buttons.move')}
            variant="primary"
          />
        </div>
      </div>
    </Modal.Body>
  );
};

export default DocumentMoveModalLoading;
