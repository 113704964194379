import * as React from 'react';

const TimeFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M10 3.125C8.51664 3.125 7.0666 3.56487 5.83323 4.38898C4.59986 5.21309 3.63856 6.38443 3.07091 7.75487C2.50325 9.12532 2.35472 10.6333 2.64411 12.0882C2.9335 13.543 3.64781 14.8794 4.6967 15.9283C5.7456 16.9772 7.08197 17.6915 8.53683 17.9809C9.99168 18.2703 11.4997 18.1218 12.8701 17.5541C14.2406 16.9864 15.4119 16.0251 16.236 14.7918C17.0601 13.5584 17.5 12.1084 17.5 10.625C17.4977 8.63657 16.7068 6.73024 15.3008 5.32421C13.8948 3.91818 11.9884 3.12727 10 3.125ZM13.5672 7.94219L10.4422 11.0672C10.3841 11.1253 10.3152 11.1713 10.2393 11.2027C10.1634 11.2342 10.0821 11.2503 10 11.2503C9.91788 11.2503 9.83656 11.2342 9.76069 11.2027C9.68482 11.1713 9.61588 11.1253 9.55782 11.0672C9.49975 11.0091 9.45368 10.9402 9.42226 10.8643C9.39083 10.7884 9.37466 10.7071 9.37466 10.625C9.37466 10.5429 9.39083 10.4616 9.42226 10.3857C9.45368 10.3098 9.49975 10.2409 9.55782 10.1828L12.6828 7.05781C12.7409 6.99974 12.8098 6.95368 12.8857 6.92225C12.9616 6.89083 13.0429 6.87465 13.125 6.87465C13.2071 6.87465 13.2884 6.89083 13.3643 6.92225C13.4402 6.95368 13.5091 6.99974 13.5672 7.05781C13.6253 7.11588 13.6713 7.18482 13.7027 7.26069C13.7342 7.33656 13.7504 7.41788 13.7504 7.5C13.7504 7.58212 13.7342 7.66344 13.7027 7.73931C13.6713 7.81518 13.6253 7.88412 13.5672 7.94219ZM7.5 1.25C7.5 1.08424 7.56585 0.925268 7.68306 0.808058C7.80027 0.690848 7.95924 0.625 8.125 0.625H11.875C12.0408 0.625 12.1997 0.690848 12.3169 0.808058C12.4342 0.925268 12.5 1.08424 12.5 1.25C12.5 1.41576 12.4342 1.57473 12.3169 1.69194C12.1997 1.80915 12.0408 1.875 11.875 1.875H8.125C7.95924 1.875 7.80027 1.80915 7.68306 1.69194C7.56585 1.57473 7.5 1.41576 7.5 1.25Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default TimeFilledIcon;
