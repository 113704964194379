import * as React from 'react';

const FileAudioIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.25 3V8.25H19.5"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 21H18.75C18.9489 21 19.1397 20.921 19.2803 20.7803C19.421 20.6397 19.5 20.4489 19.5 20.25V8.25L14.25 3H5.25C5.05109 3 4.86032 3.07902 4.71967 3.21967C4.57902 3.36032 4.5 3.55109 4.5 3.75V12"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 19.125V16.125H6.75L9 14.25V21L6.75 19.125H4.5Z"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 15.2812C12.3517 15.5623 12.6357 15.9189 12.8309 16.3246C13.026 16.7303 13.1273 17.1748 13.1273 17.625C13.1273 18.0752 13.026 18.5197 12.8309 18.9254C12.6357 19.3311 12.3517 19.6877 12 19.9688"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default FileAudioIcon;
