import React from 'react';
import Hint from '.';
import { Theme } from '../../theme';
import FlairIcon from '../../atomic/atoms/FlairIcon';

type Props = {
  text: string;
  className?: string;
  color?: string;
};

export const InfoHint: React.FC<Props> = ({
  text,
  className,
  color = Theme.color.paper1,
}) => {
  return (
    <Hint id="info-hint" mode="popover" text={text}>
      <FlairIcon
        className={className}
        size="sm"
        icon="information-circle-outline"
        role="button"
        color={color}
      />
    </Hint>
  );
};
