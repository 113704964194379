import React, { ChangeEvent, useCallback } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import ReactSlider from 'react-slider';
import AutoSaveInputLabel from '../../../../../../components/form/AutoSaveInputLabel';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import Hint from '../../../../../../components/hint';
import { EvaluationTemplateQuestionType } from '../../../../__generated__/graphql';
import { EvaluationQuestionParams } from './types';
import './FreeSlider.css';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type FreeSliderParams = EvaluationQuestionParams & {
  defaultValue: string;
};

const FreeSlider: React.FC<FreeSliderParams> = ({
  question,
  disabled,
  defaultValue,
  loading,
  onUpdateAnswer,
}) => {
  const handleChange = useCallback(
    (value) => {
      onUpdateAnswer({
        questionType: EvaluationTemplateQuestionType.FreeSlider,
        value: String(value),
      });
    },
    [onUpdateAnswer],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUpdateAnswer({
        questionType: EvaluationTemplateQuestionType.FreeSlider,
        value: event.target.value,
      });
    },
    [onUpdateAnswer],
  );

  return (
    <FormGroup>
      <AutoSaveInputLabel
        label={question.flair__Name__c}
        required={question.flair__Required__c}
        loading={loading}
        completed={!!defaultValue}
        hint={
          question.flair__Hint__c && (
            <Hint id={`hint_${question.Id}`} text={question.flair__Hint__c} />
          )
        }
      />
      <Row>
        <Col>
          <Row className="align-items-center">
            <Col>
              <ReactSlider
                className={`horizontal-slider ${
                  disabled ? 'disabled-slider' : ''
                }`}
                thumbClassName="horz-thumb cu-pointer rounded-circle text-center"
                trackClassName="horz-track"
                onChange={handleChange}
                disabled={disabled}
                snapDragDisabled={true}
                value={+defaultValue}
                renderThumb={(props) => (
                  <div {...props}>
                    <FlairIcon icon="smile-outline" size="lg" />
                  </div>
                )}
              />
            </Col>
            <Col xs="auto">
              <Form.Control
                value={+defaultValue}
                className="slider-input"
                type="number"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </FormGroup>
  );
};

export default FreeSlider;
