import * as React from 'react';

const LikeIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9531 4.63344C15.7947 4.45395 15.6 4.31021 15.3818 4.21177C15.1635 4.11334 14.9269 4.06245 14.6875 4.0625H10.75V2.9375C10.75 2.19158 10.4537 1.47621 9.92624 0.948762C9.39879 0.421316 8.68342 0.125 7.9375 0.125C7.833 0.124925 7.73054 0.153963 7.64162 0.208856C7.5527 0.263749 7.48083 0.342328 7.43406 0.435781L4.77766 5.75H1.75C1.45163 5.75 1.16548 5.86853 0.954505 6.0795C0.743526 6.29048 0.625 6.57663 0.625 6.875V13.0625C0.625 13.3609 0.743526 13.647 0.954505 13.858C1.16548 14.069 1.45163 14.1875 1.75 14.1875H13.8438C14.2549 14.1876 14.6519 14.0377 14.9603 13.7658C15.2687 13.4939 15.4672 13.1188 15.5186 12.7109L16.3623 5.96094C16.3923 5.7233 16.3712 5.48201 16.3007 5.25313C16.2301 5.02424 16.1116 4.813 15.9531 4.63344ZM1.75 6.875H4.5625V13.0625H1.75V6.875ZM15.2458 5.82031L14.402 12.5703C14.3849 12.7063 14.3187 12.8313 14.2159 12.9219C14.1131 13.0126 13.9808 13.0625 13.8438 13.0625H5.6875V6.44539L8.26867 1.28234C8.65124 1.35891 8.99545 1.56567 9.24274 1.86744C9.49003 2.16921 9.62511 2.54735 9.625 2.9375V4.625C9.625 4.77418 9.68426 4.91726 9.78975 5.02275C9.89524 5.12824 10.0383 5.1875 10.1875 5.1875H14.6875C14.7673 5.18747 14.8462 5.20443 14.919 5.23726C14.9918 5.27008 15.0567 5.31801 15.1095 5.37787C15.1623 5.43773 15.2018 5.50814 15.2253 5.58442C15.2488 5.66071 15.2558 5.74112 15.2458 5.82031Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);

export default LikeIcon;
