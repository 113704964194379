import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Route } from '../../../../utils/router';
import PageHeaderNav from '../../components/PageHeaderNav';
import { routes } from '../../routes';

type TabItem = {
  label: string;
  route: Route<string[]>;
};

export const TimeTrackingNav: React.FC = () => {
  const t = useNamespacedTranslation('timeTracking.tabs');

  const tabItems: TabItem[] = [
    {
      label: t('currentTimeSheet'),
      route: routes.timeTracking.route,
    },
    {
      label: t('allTimesheets'),
      route: routes.allTimeSheets.route,
    },
  ];

  return <PageHeaderNav tabItems={tabItems} exact={false} />;
};
