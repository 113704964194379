import classNames from 'classnames';
import React from 'react';
import { Card, Col, Form, FormCheck, Row } from 'react-bootstrap';
import { useForm, FormContext } from 'react-hook-form';
import SubmitButton from '../../../../../components/form/SubmitButton';
import ServerError from '../../../../../components/ServerError';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import SkeletonCard from '../../../components/Skeleton/Card';
import {
  useCustomerSupportQuery,
  useUpdateCustomerSupportMutation,
} from '../../../__generated__/graphql';

type Props = {};

const i18Path = 'settings.customerSupport';

type FormData = { supportAccess: boolean };

const CustomerSupportForm: React.FC<{ formData: FormData }> = ({
  formData,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const [updateCustomerSupport] = useUpdateCustomerSupportMutation();
  const errorHandler = useMutationErrorHandler();

  const form = useForm<FormData>({
    defaultValues: formData,
  });

  const onSubmit = async (data: FormData) => {
    await updateCustomerSupport({
      variables: {
        input: {
          supportAccess: data.supportAccess,
        },
      },
    })
      .then(() => {
        form.reset(data);
      })
      .catch(errorHandler);
  };

  return (
    <FormContext {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Row className="ms-2">
          <Form.Label
            column="sm"
            className={classNames('pe-4', 'fw-normal')}
            htmlFor={'supportAccess'}>
            {t('supportAccess')}
          </Form.Label>
          <Col className="col-auto">
            <FormCheck.Input
              type="checkbox"
              id={'supportAccess'}
              name={'supportAccess'}
              ref={form.register}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <SubmitButton disabled={!form.formState.dirty} size="sm">
            {t(`saveButton`)}
          </SubmitButton>
        </div>
      </form>
    </FormContext>
  );
};

const Content: React.FC = () => {
  const { data, loading, error } = useCustomerSupportQuery();

  if (loading || !data) {
    return <Loading />;
  }

  if (error) {
    return <ServerError />;
  }

  const formData: FormData = {
    supportAccess: data.me.customerSupport.supportAccess,
  };

  return <CustomerSupportForm formData={formData} />;
};

const Loading: React.FC = () => {
  return <SkeletonCard height={600} />;
};

export const CustomerSupport: React.FC<Props> = () => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Row>
      <Col md={6} sm={12}>
        <Card>
          <Card.Body>
            <Card.Title as="h3">{t(`title`)}</Card.Title>
            <Content />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
