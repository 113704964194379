import React from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import highFiving from '../.././../../../../../vendor/img/high-fiving.png';
import styled from '@emotion/styled';

type Props = {
  visible: boolean;
  onClose: () => void;
};

const i18Path = 'myData.inventory.addInventoryModal.successModal';

export const AddInventoryItemSuccessModal: React.FC<Props> = ({
  visible,
  onClose,
}) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      dialogClassName="break-modal-494"
      contentClassName="border">
      <Header closeButton className="card-header border-0 pt-4">
        <div className="align-content-center text-sm-start">
          <h2 className="card-header-title mb-2">{t('title')}</h2>
          <h6 className="h6-regular color-paper-1 mb-0">{t('subTitle')}</h6>
        </div>
      </Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Image
            src={highFiving}
            alt="High Fiving"
            className="img-fluid mb-4"
          />
          <Button
            size="lg"
            variant="primary"
            className="w-25"
            onClick={onClose}>
            {t('closeButton')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Header = styled(Modal.Header)({
  height: '4.625rem',
});
