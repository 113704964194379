import { t } from 'i18next';
import { NotificationTypes } from 'server/src/shared/NotificationTypes';

import { NotificationItemData, NotificationQueryItem } from '../types';
import { safeJsonParse } from './NotificationJSONBodyParser';
import { toISODateOnly } from '../../../../../utils/dateUtils';
import { formattedDateTime } from '../../../../../components/datetime/FormattedDateTime';
import i18next from '../../../../../initializers/i18next';

const i18prefix = 'notifications.timeLimit';

type TimeLimitNotificationReason = 'MAX_DAILY_LIMIT' | 'CLOCK_OUT_LIMIT';

type AutoClockedOutType = {
  autoClockedOut: boolean;
  notificationReason: TimeLimitNotificationReason;
  timeEntryStartDatetime: string;
  employeeId: string;
  timeEntryId: string;
};

type AutoClockedOutManagerType = {
  timeEntryId: string;
  timeEntryEmployeeId: string;
  timeEntryEmployeeName: string;
  timeEntryStartDatetime: string;
  managerId: string;
  notificationReason: TimeLimitNotificationReason;
  autoClockedOut: boolean;
};

export const mapTimeLimitTimeEntryNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  if (!isTimeLimitNotification(src.renderInfo) || !src.renderInfo.timeEntry) {
    return null;
  }

  const notificationItemData: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };

  switch (src.flair__Type__c) {
    case NotificationTypes.TIME_TRACKING_CLOCK_OUT_LIMIT:
      handleClockOutLimitNotification(src, notificationItemData);
      break;
    case NotificationTypes.TIME_TRACKING_CLOCK_OUT_LIMIT_MANAGER:
      handleClockOutLimitManagerNotification(src, notificationItemData);
      break;
    default:
  }

  return notificationItemData;
};

const handleClockOutLimitNotification = (
  src: NotificationQueryItem,
  notificationItemData: NotificationItemData,
): void => {
  if (
    !isTimeLimitNotification(src.renderInfo) ||
    !src.renderInfo.timeEntry ||
    !src.flair__Body__c
  ) {
    return;
  }
  const body = safeJsonParse<AutoClockedOutType>(src.flair__Body__c);
  if (!body) {
    return;
  }

  notificationItemData.icon = 'stopwatch-outline';
  if (body.notificationReason === 'MAX_DAILY_LIMIT') {
    notificationItemData.title = t(`${i18prefix}.maximumDailyReachedTitle`);
    if (
      body.autoClockedOut &&
      src.renderInfo.timeEntry.flair__End_Datetime__c
    ) {
      notificationItemData.description = t(
        `${i18prefix}.maxDailyLimitAutoClockedOut`,
        {
          date_time: formatDatetime(
            src.renderInfo.timeEntry.flair__End_Datetime__c,
          ),
        },
      );
    } else {
      const startDatetime = new Date(
        src.renderInfo.timeEntry.flair__Start_Datetime__c,
      );
      notificationItemData.description = t(
        `${i18prefix}.maxDailyLimitReached`,
        {
          date: toISODateOnly(startDatetime),
        },
      );
    }
  }
};

const handleClockOutLimitManagerNotification = (
  src: NotificationQueryItem,
  notificationItemData: NotificationItemData,
): void => {
  if (
    !isTimeLimitNotification(src.renderInfo) ||
    !src.renderInfo.timeEntry ||
    !src.flair__Body__c
  ) {
    return;
  }
  const body = safeJsonParse<AutoClockedOutManagerType>(src.flair__Body__c);
  if (!body) {
    return;
  }

  notificationItemData.icon = 'stopwatch-outline';
  if (body.notificationReason === 'MAX_DAILY_LIMIT') {
    notificationItemData.title = t(`${i18prefix}.maximumDailyReachedTitle`);
    const employeeName = src.renderInfo.timeEntry?.employee?.Name;

    if (
      body.autoClockedOut &&
      src.renderInfo.timeEntry?.flair__End_Datetime__c
    ) {
      notificationItemData.description = t(
        `${i18prefix}.maxDailyLimitAutoClockedOutManager`,
        {
          employeeName,
          date_time: formatDatetime(
            src.renderInfo.timeEntry.flair__End_Datetime__c,
          ),
        },
      );
    } else {
      const startDatetime = new Date(
        src.renderInfo.timeEntry.flair__Start_Datetime__c,
      );
      notificationItemData.description = t(
        `${i18prefix}.maxDailyLimitReachedManager`,
        {
          employeeName,
          date: toISODateOnly(startDatetime),
        },
      );
    }
  }
};

const formatDatetime = (datetime: string) => {
  const formatDateTime = formattedDateTime(i18next.t);
  return formatDateTime({
    dateTime: datetime,
    format: 'readable',
  });
};

const isTimeLimitNotification = (
  renderInfo: NotificationQueryItem['renderInfo'],
): renderInfo is NotificationQueryItem['renderInfo'] & {
  __typename: 'TimeLimitTimeEntryNotificationRenderInfo';
} => renderInfo.__typename === 'TimeLimitTimeEntryNotificationRenderInfo';
