import React from 'react';
import { ProjectsTimeSheetProjectInfo } from '../types';
import { formatDateShort } from '../../../../../../utils/dateUtils';
import Hint from '../../../../../../components/hint';
import i18next from 'i18next';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

type Props = {
  projectInfo: Pick<
    ProjectsTimeSheetProjectInfo,
    'name' | 'billable' | 'startDay' | 'endDay'
  >;
};

const i18nPrefix = 'timeTracking.projects.controlling.table';

/**
 * outputs cases: (From: Jan 1, 2023), (Until: Dec 31, 2023), (Jan 1, 2023 - Dec 31, 2023), (-)
 */
const formatProjectDate = ({
  startDay,
  endDay,
}: Pick<Props['projectInfo'], 'startDay' | 'endDay'>) => {
  const { t } = i18next;

  if (startDay && endDay) {
    return `${formatDateShort(startDay)} - ${formatDateShort(endDay)}`;
  } else if (startDay) {
    return t(`${i18nPrefix}.projectPeriod.fromDate`, {
      date: formatDateShort(startDay),
      interpolation: { escapeValue: false },
    });
  } else if (endDay) {
    return t(`${i18nPrefix}.projectPeriod.untilDate`, {
      date: formatDateShort(endDay),
      interpolation: { escapeValue: false },
    });
  }
  return '';
};

export const ProjectPeriodCell: React.FC<Props> = ({ projectInfo }) => {
  const t = useNamespacedTranslation(i18nPrefix);

  return (
    <div className="d-flex align-items-center gap-2">
      <span className="text-nowrap color-gray-1 h7-medium">
        {projectInfo.name}
      </span>
      <span className="text-nowrap color-paper-1 h7-regular">
        {formatProjectDate(projectInfo)}
      </span>
      {projectInfo.billable && (
        <span className="color-paper-1">
          <Hint
            id={`${projectInfo}_billable_icon`}
            text={t('billable')}
            flairIconName={'dollar-circle'}></Hint>
        </span>
      )}
    </div>
  );
};
