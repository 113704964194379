import React from 'react';
import PageHeader from '../PageHeader';
import { Card, Container, Spinner } from 'react-bootstrap';
import EmptyState from '../../../../vendor/img/empty-state.png';
import './PageLoading.css';

type Props = {
  header: string;
  text: string;
  isLoading: boolean;
};

export const PageLoading: React.FC<Props> = ({ header, isLoading, text }) => {
  return (
    <>
      <PageHeader title={header}></PageHeader>
      <Container>
        <Card>
          <img className="img-loading m-3" src={EmptyState} alt="Profile" />
          <Card.Body className="text-center">
            {isLoading && <Spinner className="mb-2" animation="border" />}
            <div>{text}</div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
