import React from 'react';
import { SingleVisiblePopoverProvider } from '../../../../context/SingleVisiblePopoverContext';
import { TimeBalancePanelConnected } from '../../components/TimeBalancePanel';
import { EmployeeTimeSheets } from './EmployeeTimeSheets/EmployeeTimeSheets';

type Props = {};

export const TimeTrackingAllTimesheetsPage: React.FC<Props> = () => {
  return (
    <SingleVisiblePopoverProvider>
      <TimeBalancePanelConnected />
      <EmployeeTimeSheets />
    </SingleVisiblePopoverProvider>
  );
};
