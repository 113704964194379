import React from 'react';
import { Row } from 'react-bootstrap';
import LanguageSection from '../../PersonalData/PersonalData/LanguageSection';

type Props = {};

export const General: React.FC<Props> = () => {
  return (
    <Row>
      <LanguageSection />
    </Row>
  );
};
