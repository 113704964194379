import * as React from 'react';

const PottedPlantIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        opacity="0.2"
        d="M23 19L21.1737 27.2175C21.1243 27.4395 21.0006 27.638 20.8231 27.7803C20.6456 27.9225 20.425 28 20.1975 28H11.8025C11.575 28 11.3544 27.9225 11.1769 27.7803C10.9994 27.638 10.8757 27.4395 10.8263 27.2175L9 19H23Z"
        fill="#1D1D1D"
      />
      <path
        d="M25 18.0001H15.4125L15.7063 17.7063L18.2138 15.1988C19.2265 15.7089 20.3424 15.9808 21.4762 15.9938C22.6175 15.9968 23.7375 15.6845 24.7125 15.0913C27.6775 13.2963 29.2662 9.14133 28.9625 3.97633C28.9482 3.73169 28.8446 3.50081 28.6713 3.32753C28.498 3.15424 28.2671 3.05062 28.0225 3.03633C22.8575 2.73383 18.7025 4.32134 16.9063 7.28633C15.7375 9.21759 15.7075 11.5501 16.7988 13.7863L15 15.5851L13.4738 14.0588C14.2238 12.4026 14.17 10.6838 13.3 9.24883C11.9413 7.00008 8.825 5.80009 4.96625 6.02759C4.72203 6.04214 4.49162 6.14571 4.31862 6.31871C4.14563 6.4917 4.04205 6.72212 4.0275 6.96633C3.8 10.8251 5 13.9401 7.25 15.3001C8.00275 15.7597 8.86803 16.002 9.75 16.0001C10.5501 15.9921 11.3394 15.8136 12.065 15.4763L13.5863 17.0001L12.5863 18.0001H7C6.73478 18.0001 6.48043 18.1054 6.29289 18.293C6.10536 18.4805 6 18.7349 6 19.0001C6 19.2653 6.10536 19.5197 6.29289 19.7072C6.48043 19.8947 6.73478 20.0001 7 20.0001H8.19875L9.85 27.4338C9.94636 27.8793 10.193 28.278 10.5485 28.5632C10.904 28.8484 11.3468 29.0026 11.8025 29.0001H20.1987C20.6544 29.0022 21.0969 28.8478 21.4523 28.5627C21.8077 28.2776 22.0545 27.8791 22.1513 27.4338L23.8025 20.0001H25C25.2652 20.0001 25.5196 19.8947 25.7071 19.7072C25.8946 19.5197 26 19.2653 26 19.0001C26 18.7349 25.8946 18.4805 25.7071 18.293C25.5196 18.1054 25.2652 18.0001 25 18.0001ZM18.625 8.32259C19.9325 6.16509 23.0287 4.94758 27 5.00133C27.0513 8.97758 25.8362 12.0738 23.6787 13.3763C22.24 14.2513 20.5037 14.1938 18.7688 13.2288C17.8025 11.5001 17.75 9.76134 18.625 8.32259ZM11.5138 13.5138C10.3638 14.1288 9.225 14.1576 8.285 13.5888C6.8475 12.7176 6.01875 10.6638 6 8.00008C8.66375 8.01884 10.7188 8.84758 11.5888 10.2851C12.1588 11.2251 12.125 12.3638 11.5138 13.5138ZM20.1987 27.0001H11.8025L10.25 20.0001H21.75L20.1987 27.0001Z"
        fill="#1D1D1D"
      />
    </svg>
  );
});

export default PottedPlantIcon;
