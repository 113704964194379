import { routes } from '../../../routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from '../../../../../utils/router';
import PageHeaderNav from '../../../components/PageHeaderNav';

type TabItem = {
  label: string;
  route: Route<string[]>;
};

const SettingsNav: React.FC = () => {
  const { t } = useTranslation();

  const tabItems: TabItem[] = [
    {
      label: t('settings.subMenuItems.general.title'),
      route: routes.general.route,
    },
    {
      label: t('settings.subMenuItems.notificationSettings.title'),
      route: routes.notificationSettings.route,
    },
    {
      label: t('settings.subMenuItems.customerSupport.title'),
      route: routes.customerSupport.route,
    },
  ];

  return <PageHeaderNav tabItems={tabItems} />;
};

export default SettingsNav;
