import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useServerFeatures } from '../../../../hooks/useServerFeature';
import { Nullable } from '../../../../utils/nullable';
import { useUserInfo } from '../../context/UserInfo';
import { managerRoutes } from '../../manager/routes';
import { routes } from '../../routes';
import {
  ManagerRole,
  usePendingTimeEntryChangeRequestsQuery,
} from '../../__generated__/graphql';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../hooks/useManagerReportsMode';
import { useIsManagerMode } from '../../hooks/useIsManagerMode';
import { SidebarBadge } from '../SidebarBadge';
import { usePendingAbsenceRequests } from '../../manager/Absences/PendingRequests/usePendingAbsenceRequests';
import { HR_HELP_DESK, useFeature } from '../../../../context/FeaturesContext';
import { getMenuItems } from './utils';
import {
  MenuHeaderProps,
  MenuItemsSectionType,
  MenuItemType,
} from '../../../../atomic/molecules/navigation/types';
import MenuBar from '../../../../atomic/organisms/navigation/MenuBar/MenuBar';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import FlairLogo from '../../../../vendor/img/flair-logo.svg';
import { MenuBarContext } from '../../context/MenuBar';
import { useIsScreenSizeLargerThanOrEqual } from '../../../../hooks/useIsScreenSizeLargerThan';
import './MainMenuBar.css';
import { useMyTrackingTypes } from '../../hooks/timeTracking/useMyTrackingTypes';
import ExpiryWrapper from '../../../../components/ExpiryWrapper/ExpiryWrapper';
import { ExpiryDateEnums } from '../../../../components/ExpiryWrapper/enums/ExpiryDateEnums';

export const MainMenuBar: React.FC = () => {
  const { isActive, isHiringManager, organisation, name, logoUrl } =
    useUserInfo();
  const { isManagerModeAvailable, hasAnyOfManagerRole } = useIsManagerMode();

  const { isTimeTrackingEnabled, isProjectTrackingEnabled } =
    useMyTrackingTypes();

  const isLargeScreen = useIsScreenSizeLargerThanOrEqual('lg');
  const { isExpanded, setIsExpanded } = useContext(MenuBarContext);
  const isMenuBarExpanded = isLargeScreen ? true : isExpanded;

  const isInManagerModeWithRoles = useCallback(
    (roles: ManagerRole[]) => {
      return isManagerModeAvailable && hasAnyOfManagerRole(roles);
    },
    [isManagerModeAvailable, hasAnyOfManagerRole],
  );

  const isFeatureVisible = useServerFeatures();

  const [pendingTimeEntriesReportsMode] = useManagerReportsMode(
    'pendingTimeEntryChangeRequests',
  );
  const [absencesReportsMode] = useManagerReportsMode('absences');
  const pendingAbsencesQuery = usePendingAbsenceRequests(
    mapManagerReportsType(absencesReportsMode),
  );

  const { t } = useTranslation();
  const tNavigationTranslation = useNamespacedTranslation(
    'navigation.menuItems',
  );

  const handleOnNavigationalItemClick = () => {
    setIsExpanded(false);
  };

  const pendingTimeEntryChangeRequestsQuery =
    usePendingTimeEntryChangeRequestsQuery({
      variables: {
        managerReportsType: mapManagerReportsType(
          pendingTimeEntriesReportsMode,
        ),
      },
    });

  const isActiveHiringManager = isActive && isHiringManager;
  const isHRHelpDeskFeatureEnabled = useFeature(HR_HELP_DESK);
  const isHRHelpDeskFeatureVisible =
    isHRHelpDeskFeatureEnabled && isFeatureVisible('HR_HelpDesk');

  const nullablePersonalMenuItems: Nullable<MenuItemType>[] = [
    {
      label: t('dashboard.title'),
      route: routes.home.route,
      shouldRender: true,
      icon: <FlairIcon icon="dashboard-outline" size="lg" />,
      activeIcon: <FlairIcon icon="dashboard-filled" size="lg" />,
    },
    {
      label: tNavigationTranslation(`profile.title`),
      route: routes.personalData.route,
      shouldRender: true,
      icon: <FlairIcon icon="profile-outline" size="lg" />,
      activeIcon: <FlairIcon icon="profile-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(
            `profile.subMenuItems.personalData.title`,
          ),
          route: routes.personalData.route,
          shouldRender: isFeatureVisible('Personal_Data'),
        },
        {
          label: tNavigationTranslation(`profile.subMenuItems.documents.title`),
          route: routes.documentsPersonal.route,
          shouldRender: true,
        },
        {
          label: tNavigationTranslation('inventory.title'),
          route: routes.inventory.route,
          shouldRender: true,
        },
        {
          label: tNavigationTranslation(`profile.subMenuItems.expenses.title`),
          route: routes.myExpenses.route,
          shouldRender: isActive && isFeatureVisible('Expenses'),
        },
        {
          label: tNavigationTranslation(
            `profile.subMenuItems.certificates.title`,
          ),
          route: routes.myCertificates.route,
          shouldRender: isActive && isFeatureVisible('Certificates'),
        },
      ],
    },
    {
      label: tNavigationTranslation(`company.title`),
      route: routes.announcements.route,
      shouldRender: isActive,
      icon: <FlairIcon icon="company-outline" size="lg" />,
      activeIcon: <FlairIcon icon="company-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(`company.subMenuItems.directory.title`),
          route: routes.peopleDirectory.route,
          shouldRender: isFeatureVisible('Directory'),
        },
        {
          label: tNavigationTranslation(`company.subMenuItems.orgChart.title`),
          route: routes.orgChart.route,
          shouldRender: isFeatureVisible('Org_Chart'),
        },
        {
          label: tNavigationTranslation(
            `company.subMenuItems.announcement.title`,
          ),
          route: routes.announcements.route,
          shouldRender: isFeatureVisible('Announcements'),
        },
      ],
    },
    {
      label: tNavigationTranslation(`tasks.submenuItems.workflows`),
      route: routes.workflowsAllWorkflows.route,
      shouldRender: true,
      icon: <FlairIcon icon="workflow-outline" size="lg" />,
      activeIcon: <FlairIcon icon="workflow-filled" size="lg" />,
    },
    {
      label: tNavigationTranslation(`personalAbsences.title`),
      route: routes.absences.route,
      shouldRender: isActive && isFeatureVisible('Absences'),
      icon: <FlairIcon icon="absence-outline" size="lg" />,
      activeIcon: <FlairIcon icon="absence-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(
            `personalAbsences.subMenuItems.overview.title`,
          ),
          route: routes.absences.route,
          shouldRender: true,
        },
        {
          label: tNavigationTranslation(
            `personalAbsences.subMenuItems.myAbsences.title`,
          ),
          route: routes.myAbsences.route,
          shouldRender: true,
        },
        {
          label: tNavigationTranslation(
            `personalAbsences.subMenuItems.calendar.title`,
          ),
          route: routes.absencesCalendar.route,
          shouldRender: true,
        },
        {
          label: tNavigationTranslation(
            `personalAbsences.subMenuItems.colleagues.title`,
          ),
          route: routes.teamCalendar.route,
          shouldRender: true,
        },
      ],
    },
    //   show in a main menu item only if the time tracking not enabled
    {
      label: tNavigationTranslation(`time.subMenuItems.projects.title`),
      route: routes.projectsTracking.route,
      shouldRender:
        isActive &&
        isFeatureVisible('Projects') &&
        isProjectTrackingEnabled &&
        !isTimeTrackingEnabled,
      icon: <FlairIcon icon="time-outline" size="lg" />,
      activeIcon: <FlairIcon icon="time-filled" size="lg" />,
    },
    {
      label: tNavigationTranslation(`time.title`),
      route: routes.timeTracking.route,
      shouldRender: isFeatureVisible('Time_Tracking') && isTimeTrackingEnabled,
      icon: <FlairIcon icon="time-outline" size="lg" />,
      activeIcon: <FlairIcon icon="time-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(`time.subMenuItems.timeTracking.title`),
          route: routes.timeTracking.route,
          shouldRender: isActive && isTimeTrackingEnabled,
        },
        {
          label: tNavigationTranslation(`time.subMenuItems.projects.title`),
          route: routes.projectsTracking.route,
          shouldRender:
            isActive &&
            isFeatureVisible('Projects') &&
            isProjectTrackingEnabled,
        },
        {
          label: t('shiftPlanner.title'),
          route: routes.personalShifts.route,
          shouldRender: isActive && isFeatureVisible('Shift_Planner'),
        },
      ],
    },
    {
      label: tNavigationTranslation(`personalDevelopment.title`),
      route: routes.performanceReview.route,
      shouldRender: isActive,
      icon: <FlairIcon icon="performance-outline" size="lg" />,
      activeIcon: <FlairIcon icon="performance-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(
            `personalDevelopment.subMenuItems.reviews.title`,
          ),
          route: routes.performanceReview.route,
          shouldRender: isActive && isFeatureVisible('Assessments'),
        },
        {
          label: tNavigationTranslation(
            `personalDevelopment.subMenuItems.goals.title`,
          ),
          route: routes.performanceReviewGoals.route,
          shouldRender: isActive && isFeatureVisible('Goals'),
        },
        {
          label: tNavigationTranslation(
            `personalDevelopment.subMenuItems.skills.title`,
          ),
          route: routes.performanceReviewSkills.route,
          shouldRender: isActive && isFeatureVisible('Skills'),
        },
      ],
    },
    {
      label: tNavigationTranslation(`engagement.title`),
      route: routes.celebrationsFeed.route,
      shouldRender: true,
      icon: <FlairIcon icon="engagement-outline" size="lg" />,
      activeIcon: <FlairIcon icon="engagement-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(`engagement.subMenuItems.cheers.title`),
          route: routes.celebrationsFeed.route,
          shouldRender: isActive && isFeatureVisible('Cheers'),
        },
        {
          label: tNavigationTranslation(
            `engagement.subMenuItems.engagementSurveys.title`,
          ),
          route: routes.performanceReviewEngagementSurveys.route,
          shouldRender: isActive && isFeatureVisible('Surveys'),
        },
      ],
    },
    {
      label: tNavigationTranslation(`recruitment.title`),
      route: routes.employeeEvaluations.route,
      shouldRender: isActive && isFeatureVisible('Recruiting'),
      icon: <FlairIcon icon="recruitment-outline" size="lg" />,
      activeIcon: <FlairIcon icon="recruitment-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(
            `recruitment.subMenuItems.myEvaluations.title`,
          ),
          route: routes.employeeEvaluations.route,
          shouldRender: isActive,
        },
        {
          label: t('recruitment.jobsPage.title'),
          route: routes.recruitment.route,
          shouldRender: isActiveHiringManager,
        },
      ],
    },
    {
      label: t('support.title'),
      route: routes.tickets.route,
      shouldRender: isActive && isHRHelpDeskFeatureVisible,
      icon: <FlairIcon icon="help-desk-outline" size="lg" />,
      activeIcon: <FlairIcon icon="help-desk-filled" size="lg" />,
    },
  ];

  const nullableManagerMenuItems: Nullable<MenuItemType>[] = [
    // my team
    {
      label: tNavigationTranslation(`people.title`),
      route: managerRoutes.myTeam.route,
      shouldRender: isInManagerModeWithRoles(
        managerRoutes.myTeam.rolesWithAccess,
      ),
      icon: <FlairIcon icon="team-outline" size="lg" />,
      activeIcon: <FlairIcon icon="team-filled" size="lg" />,
    },
    {
      label: tNavigationTranslation(`teamAbsences.title`),
      shouldRender:
        isInManagerModeWithRoles(
          managerRoutes.pendingAbsenceRequests.rolesWithAccess,
        ) && isFeatureVisible('Absences'),
      icon: <FlairIcon icon="absence-outline" size="lg" />,
      activeIcon: <FlairIcon icon="absence-filled" size="lg" />,
      route: managerRoutes.pendingAbsenceRequests.route,
      subItems: [
        {
          label: tNavigationTranslation(
            `teamAbsences.subMenuItems.absenceRequests.title`,
          ),
          route: managerRoutes.pendingAbsenceRequests.route,
          shouldRender: true,
          suffix: (
            <SidebarBadge loading={pendingAbsencesQuery.loading}>
              {pendingAbsencesQuery.pendingAbsenceRequestToMe.length}
            </SidebarBadge>
          ),
        },
      ],
    },
    {
      label: tNavigationTranslation(`timeManagement.title`),
      route: routes.timeSheetsControllingPending.route,
      shouldRender:
        (isActive ||
          isInManagerModeWithRoles(
            routes.timeSheetsControllingWeek.rolesWithAccess,
          )) &&
        isFeatureVisible('Time_Tracking'),
      icon: <FlairIcon icon="time-outline" size="lg" />,
      activeIcon: <FlairIcon icon="time-filled" size="lg" />,
      subItems: [
        {
          label: tNavigationTranslation(
            `timeManagement.subMenuItems.controlling.title`,
          ),
          route: routes.timeSheetsControllingPending.route,
          shouldRender: isInManagerModeWithRoles(
            routes.timeSheetsControllingPending.rolesWithAccess,
          ),
        },
        {
          label: tNavigationTranslation(
            `timeManagement.subMenuItems.changeRequests.title`,
          ),
          route: routes.pendingTimeEntryChangeRequests.route,
          shouldRender: isInManagerModeWithRoles(
            routes.timeSheetsControllingPending.rolesWithAccess,
          ),
          suffix: (
            <SidebarBadge loading={pendingTimeEntryChangeRequestsQuery.loading}>
              {pendingTimeEntryChangeRequestsQuery.data?.manager
                .pendingTimeEntryChangeRequests.length ?? 0}
            </SidebarBadge>
          ),
        },
        {
          label: tNavigationTranslation(
            `timeManagement.subMenuItems.projects.title`,
          ),
          route: routes.projectsOverviewManager.route,
          shouldRender: isInManagerModeWithRoles(
            routes.projectsControllingTimeSheets.rolesWithAccess,
          ),
        },
      ],
    },

    {
      label: tNavigationTranslation(`teamDevelopment.title`),
      shouldRender: isInManagerModeWithRoles(
        managerRoutes.reviews.rolesWithAccess,
      ),
      icon: <FlairIcon icon="performance-outline" size="lg" />,
      activeIcon: <FlairIcon icon="performance-filled" size="lg" />,
      route: managerRoutes.reviews.route,
      subItems: [
        {
          label: tNavigationTranslation(
            `teamDevelopment.subMenuItems.reviews.title`,
          ),
          route: managerRoutes.reviews.route,
          shouldRender:
            isManagerModeAvailable && isFeatureVisible('Assessments'),
        },
        {
          label: tNavigationTranslation(
            `teamDevelopment.subMenuItems.goals.title`,
          ),
          route: managerRoutes.goals.route,
          shouldRender: isManagerModeAvailable && isFeatureVisible('Goals'),
        },
        {
          label: tNavigationTranslation(
            `teamDevelopment.subMenuItems.skills.title`,
          ),
          route: managerRoutes.skills.route,
          shouldRender: isManagerModeAvailable && isFeatureVisible('Skills'),
        },
      ],
    },
    {
      label: tNavigationTranslation(`expenses.title`),
      route: managerRoutes.expenses.route,
      shouldRender:
        isInManagerModeWithRoles(managerRoutes.expenses.rolesWithAccess) &&
        isFeatureVisible('Expenses'),
      icon: <FlairIcon icon="expenses-outline" size="lg" />,
      activeIcon: <FlairIcon icon="expenses-filled" size="lg" />,
    },
    {
      label: tNavigationTranslation('analytics.title'),
      route: managerRoutes.analytics.route,
      shouldRender: isInManagerModeWithRoles(
        managerRoutes.analytics.rolesWithAccess,
      ),
      icon: <FlairIcon icon="chart-bar-outline" size="lg" />,
      activeIcon: <FlairIcon icon="chart-bar-filled" size="lg" />,
      // TODO: Remove suffix prop after ANALYTICS_EXPIRY_DATE
      suffix: (
        <ExpiryWrapper expiryDate={ExpiryDateEnums.ANALYTICS_EXPIRY_DATE}>
          <SidebarBadge>{tNavigationTranslation('sufix.new')}</SidebarBadge>
        </ExpiryWrapper>
      ),
    },
    {
      label: t('support.title'),
      route: managerRoutes.managerTickets.route,
      shouldRender: isManagerModeAvailable && isHRHelpDeskFeatureVisible,
      icon: <FlairIcon icon="help-desk-outline" />,
      activeIcon: <FlairIcon icon="help-desk-filled" />,
    },
  ];

  const personalMenuItems: MenuItemType[] = getMenuItems(
    nullablePersonalMenuItems,
  );
  const managerMenuItems: MenuItemType[] = getMenuItems(
    nullableManagerMenuItems,
  );

  const header: MenuHeaderProps = {
    title: organisation,
    subtitle: name,
    logoSrc: logoUrl ?? FlairLogo,
  };

  const sections: MenuItemsSectionType[] = [
    {
      section: {
        sectionName: t('navigation.sections.personal'),
        visible: true,
      },
      items: personalMenuItems,
    },
    {
      section: {
        sectionName: t('navigation.sections.myTeam'),
        visible: isManagerModeAvailable,
      },
      items: managerMenuItems,
    },
  ];

  return (
    <MenuBar
      header={header}
      sections={sections}
      isExpanded={isMenuBarExpanded}
      hideSmallScreenBurgerMenu={true}
      className="main-menu-bar"
      onNavigationalItemClick={handleOnNavigationalItemClick}
    />
  );
};

export default MainMenuBar;
