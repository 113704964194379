import React from 'react';
import classNames from 'classnames'; // Add this library
import './FlairLabel.css';

type Variants = 'primary' | 'secondary';

const TypeToClassNameMap: Record<Variants, string> = {
  primary: 'primary-text',
  secondary: 'secondary-text',
};

export type FlairLabelProps = {
  text: string;
  className?: string;
  type?: keyof typeof TypeToClassNameMap;
};

export const FlairLabel: React.FC<FlairLabelProps> = ({
  text,
  className = '',
  type = 'primary',
}) => {
  const baseClass = TypeToClassNameMap[type];

  return <span className={classNames(baseClass, className)}>{text}</span>;
};
