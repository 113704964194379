import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmModalSimple,
  ConfirmModalSimpleProps,
} from '../../../../../../components/ConfirmModalSimple';

type Props = Omit<ConfirmModalSimpleProps, 'message'> & {
  name: string;
};

export const GoalDeleteConfirmation = ({ name, ...restProps }: Props) => {
  const { t } = useTranslation();

  const message = t('performanceReview.goals2.deleteConfirmation', {
    name,
  });

  return <ConfirmModalSimple {...restProps} message={message} />;
};

export const KeyResultDeleteConfirmation: React.FC<Props> = ({
  name,
  ...restProps
}: Props) => {
  const { t } = useTranslation();

  const message = t('performanceReview.goals2.deleteKeyResultConfirmation', {
    name,
  });

  return <ConfirmModalSimple {...restProps} message={message} />;
};
