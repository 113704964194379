import i18next from 'i18next';
import { allExpenseStatuses } from '../types';
import { Option } from './types';

export const createExpenseStatusOptions = (): Option[] => {
  return allExpenseStatuses.map((x) => ({
    value: x,
    label: i18next.t(`expenses.approvalStatus.${x}`),
  }));
};
