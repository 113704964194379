import {
  MyTimeEntryTimeTrackingFragment,
  MyTimeEntryTimeTrackingFragmentDoc,
} from '../__generated__/graphql';
import { ApolloCache } from '@apollo/client';

export const readMyTimeEntryTimeTrackingFromCache = (
  cache: ApolloCache<object>,
  timeEntryId: string,
): MyTimeEntryTimeTrackingFragment | null => {
  const timeEntryCacheId = cache.identify({
    __typename: 'MyTimeEntry',
    Id: timeEntryId,
  });
  return cache.readFragment<MyTimeEntryTimeTrackingFragment>({
    id: timeEntryCacheId,
    fragment: MyTimeEntryTimeTrackingFragmentDoc,
    fragmentName: 'MyTimeEntryTimeTracking',
  });
};

export const createOptimisticId = () =>
  `${OPTIMISTIC_ID}_${new Date().getTime()}`;

export const isOptimisticId = (idToTest: string): boolean => {
  return idToTest.startsWith(OPTIMISTIC_ID);
};

const OPTIMISTIC_ID = 'OPTIMISTIC_ID';
