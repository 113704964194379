import React from 'react';
import { Absence } from '../../__generated__/graphql';
import { toMoment } from './Utils';

import AbsenceTimeDuration from './AbsenceTimeDuration';
import AbsenceDateDuration from './AbsenceDateDuration';

type AbsenceProps = Pick<
  Absence,
  | 'flair__Type__c'
  | 'flair__Start_Date__c'
  | 'flair__Start_Time__c'
  | 'flair__Start_Date_Half_Day__c'
  | 'flair__End_Date__c'
  | 'flair__End_Time__c'
  | 'flair__End_Date_Half_Day__c'
>;

type Props = {
  absence: AbsenceProps;
};

const AbsenceDuration: React.FC<Props> = ({ absence }) => {
  const startDate = toMoment(
    absence.flair__Start_Date__c,
    absence.flair__Start_Time__c,
  );
  const endDate = toMoment(
    absence.flair__End_Date__c,
    absence.flair__End_Time__c,
  );

  return (
    <>
      <AbsenceDateDuration startDate={startDate} endDate={endDate} />
      <AbsenceTimeDuration startDate={startDate} endDate={endDate} />
    </>
  );
};

export default AbsenceDuration;
