import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../components/form/FormGroup';
import {
  ActiveEmployeesQuery,
  useActiveEmployeesQuery,
} from '../../__generated__/graphql';
import { EmployeeOption } from '../Selects/EmployeeOptionLabel';
import { SelectSingleEmployee } from '../Selects/SelectSingleEmployee';

export type Props = {
  selectedEmployee: string | null;
  onChange: (value: string | null) => void;
};

type ActiveEmployee = ActiveEmployeesQuery['activeEmployees'][0];

const mapEmployeeToOption = (employee: ActiveEmployee): EmployeeOption => ({
  value: employee.Id,
  label: employee.Name,
  initials: employee.Name.split(' ').reduce(
    (initials, name) => `${initials}${name[0]}`,
    '',
  ),
});

const mapToEmployeeOptions = (
  activeEmployees: readonly ActiveEmployee[],
): EmployeeOption[] =>
  activeEmployees ? activeEmployees.map(mapEmployeeToOption) : [];

const LoadingInput = () => {
  const { t } = useTranslation();

  return (
    <div className="py-2">
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"></span>
      <span className="ps-2">{t('general.loading')}</span>
    </div>
  );
};

const EmployeeInput: React.FC<Props> = ({ selectedEmployee, onChange }) => {
  const { data, loading } = useActiveEmployeesQuery();
  const { t } = useTranslation();

  const employees =
    data && data.activeEmployees
      ? mapToEmployeeOptions(data.activeEmployees)
      : [];

  return (
    <FormGroup>
      <Form.Label>
        {t(
          'employeePage.performanceReview.nextPerformanceReview.changeReviewer.reviewer',
        )}
      </Form.Label>
      <div>
        {loading ? (
          <LoadingInput />
        ) : (
          <SelectSingleEmployee
            options={employees}
            value={selectedEmployee}
            onChange={onChange}
          />
        )}
      </div>
    </FormGroup>
  );
};

export default EmployeeInput;
