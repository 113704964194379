import { ValidationError } from '../../initializers/yup';

export const isString = (v: any): v is string => typeof v === 'string';

export const getError = (
  name: string,
  errors: Record<string, any>,
): ValidationError | string | undefined => {
  name = checkFieldArrayName(name);
  const message = errors[name]?.message;
  if (isString(message)) {
    return message;
  }

  return message;
};

// As by documentation, names for the fieldArrays should be like this: timeEntries.0.projectId ,
// But in erros we are getting like this: timeEntries[10].hours
// This function is to convert the name and find the error message
function checkFieldArrayName(name: string) {
  if (!name.includes('.')) {
    return name;
  }

  const splittedName = name.split('.');

  return splittedName.reduce((newName, str, index) => {
    if (index === 0) {
      return newName + str;
    } else if (splittedName.length === index + 1) {
      return `${newName}.${str}`;
    } else {
      return `${newName}[${str}]`;
    }
  }, '');
}
