import * as React from 'react';

const FlagBannerIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M21.6339 4.84969C21.5664 4.74266 21.4729 4.65447 21.362 4.59333C21.2512 4.53219 21.1267 4.50008 21.0002 4.5H3.00019C2.84899 4.49986 2.70127 4.54543 2.57643 4.63073C2.45158 4.71602 2.35543 4.83706 2.30058 4.97797C2.24574 5.11888 2.23477 5.27307 2.26911 5.42032C2.30345 5.56758 2.38149 5.70101 2.493 5.80313L6.43894 9.41906L2.44894 13.7409C2.34993 13.8481 2.28431 13.9817 2.26011 14.1256C2.23591 14.2694 2.25418 14.4172 2.31268 14.5508C2.37118 14.6844 2.46738 14.7981 2.58949 14.8779C2.7116 14.9576 2.85433 15.0001 3.00019 15H15.5271L12.8233 20.6775C12.7781 20.7667 12.7512 20.8641 12.7442 20.9639C12.7372 21.0637 12.7502 21.1638 12.7825 21.2585C12.8147 21.3532 12.8656 21.4405 12.9321 21.5152C12.9986 21.5899 13.0794 21.6505 13.1697 21.6936C13.26 21.7366 13.358 21.7611 13.458 21.7657C13.5579 21.7703 13.6577 21.7548 13.7516 21.7203C13.8454 21.6857 13.9314 21.6327 14.0045 21.5644C14.0776 21.4961 14.1363 21.4138 14.1771 21.3225L21.6771 5.5725C21.7315 5.45817 21.7561 5.33193 21.7485 5.20555C21.741 5.07916 21.7016 4.95673 21.6339 4.84969ZM16.2405 13.5H4.70925L8.04769 9.88406C8.11475 9.81153 8.16682 9.72647 8.20092 9.63376C8.23501 9.54105 8.25046 9.44251 8.24636 9.34381C8.24227 9.24512 8.21872 9.1482 8.17706 9.05863C8.1354 8.96906 8.07647 8.8886 8.00363 8.82188L4.92863 6H19.8124L16.2405 13.5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default FlagBannerIcon;
