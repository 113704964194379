import { TimeSheetStatus } from '../../../../__generated__/graphql';

export type EmployeeTimeSheetsFilter = {
  year: string | null;
  month: string | null;
  status: TimeSheetStatus | null;
};

export const defaultFilter: EmployeeTimeSheetsFilter = {
  year: null,
  month: null,
  status: null,
};
