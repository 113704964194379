import styled from '@emotion/styled';
import React from 'react';
import { CertificateStatus } from '../types';
import { CertificateStatusComponent } from './CertificateStatus';

type Props = {
  value: CertificateStatus;
};

export const CertificateStatusCell: React.FC<Props> = ({ value }) => {
  return (
    <CertificateStatusReadonlyContainer>
      <CertificateStatusComponent status={value} />
    </CertificateStatusReadonlyContainer>
  );
};

const CertificateStatusReadonlyContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  minHeight: '2.25rem',
});
