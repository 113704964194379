import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  CandidateDetail_JobCandidateFragment,
  ContentDocumentLinkDataFragment,
} from '../../../../__generated__/graphql';
import Section from '../Section';
import { Attachment, ContentDocumentLinks } from '../types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { mapToAttachments } from '../mappings';
import Button from '../../../../../../components/button';

export type Maybe<T> = T | null;

type Params = {
  candidate: CandidateDetail_JobCandidateFragment;
};

type AttachmentsSectionParams = {
  attachments: Attachment[];
};

const missingAttachments = (contentDocumentLinks: ContentDocumentLinks) =>
  !contentDocumentLinks || contentDocumentLinks.length === 0;

type ItemProps = {
  label: string;
  onClick: () => void;
};

const i18Path = 'recruitment.candidate.info.attachments';

const Item: React.FC<ItemProps> = ({ label, onClick }) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      role="button"
      onClick={onClick}>
      <span className="w-50 text-primary">{label}</span>
      <Button variant="outline-primary w-25" label={t('view')} />
    </div>
  );
};

const Attachments: React.FC<AttachmentsSectionParams> = ({ attachments }) => {
  const handleClick = (link: string) => {
    window.open(link);
  };

  const filteredAttachments = attachments.filter((a) => !!a.viewLink);

  return (
    <div className="d-flex flex-column gap-3">
      {filteredAttachments.map((attachment, i) => (
        <React.Fragment key={attachment.id}>
          <Item
            key={attachment.id}
            onClick={() => handleClick(attachment.viewLink!)}
            label={attachment.name}
          />
          {i !== filteredAttachments.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </div>
  );
};

const Resume: React.FC<Params> = ({ candidate }) => {
  const t = useNamespacedTranslation(i18Path);
  const [showCoverLetterModal, setShowCoverLetterModal] =
    useState<boolean>(false);

  const recruitingDocuments: ReadonlyArray<ContentDocumentLinkDataFragment> =
    candidate.recruitingDocuments.flatMap(
      (recruitingDocument) => recruitingDocument.ContentDocumentLinks__r ?? [],
    );

  const combinedAttachments = (candidate.ContentDocumentLinks__r || []).concat(
    recruitingDocuments,
  );

  if (
    !candidate.flair__Cover_Letter__c &&
    missingAttachments(combinedAttachments)
  ) {
    return <></>;
  }

  return (
    <Section title={t('title')}>
      <Attachments
        attachments={
          combinedAttachments ? mapToAttachments(combinedAttachments) : []
        }
      />

      {candidate.flair__Cover_Letter__c && (
        <>
          <hr className="mt-4 mb-4" />
          <Item
            onClick={() => setShowCoverLetterModal(true)}
            label={t('coverLetter')}
          />
        </>
      )}

      <Modal show={showCoverLetterModal} scrollable={true}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className="mt-2">{t('coverLetter')}</Modal.Title>
          <Button
            onClick={() => setShowCoverLetterModal(false)}
            variant="outline-primary"
            size="sm"
            label={t('close')}
          />
        </Modal.Header>
        <Modal.Body>{candidate.flair__Cover_Letter__c}</Modal.Body>
      </Modal>
    </Section>
  );
};

export default Resume;
