import * as React from 'react';
import { AbsenceItemType } from '../types';
import StatusIcon from '../../../../../../atomic/atoms/StatusIcon/StatusIcon';
import FlairDateRange from '../../../../../../atomic/molecules/DateRange/FlairDateRange';
import CommentsPopover from '../../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import AbsenceCategoryIcon from '../../../../components/AbsenceCategoryIcon';
import AbsenceAmount from '../../../Absences/components/AbsenceAmount';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import './AbsenceItem.css';
import { FlairDateFormat } from '../../../../../../atomic/atoms/FlairFormattedDate/types';

type Props = {
  absence: AbsenceItemType;
};

const AbsenceItem: React.FC<Props> = ({ absence }) => {
  const {
    status,
    categoryIcon,
    categoryName,
    startDateTime,
    endDateTime,
    id,
    commentsCount,
  } = absence;

  return (
    <div className="absence-item d-flex gap-3-5">
      <StatusIcon status={status} />
      <div className="d-flex flex-column gap-1">
        <div>
          <AbsenceCategoryIcon icon={categoryIcon} />
          <span className="ms-2-5">{categoryName}</span>
          <FlairIcon icon="ellipse-filled" size="xxxs" className="mx-3 mb-1" />
          <AbsenceAmount
            type={absence.type}
            amount={absence.workingAmount}
            variant="work"
          />
        </div>

        <FlairDateRange
          className="h4-regular absence-date-range-container"
          dateFrom={startDateTime}
          dateTo={endDateTime}
          format={FlairDateFormat.WeekdayShortMonthDayYear}
        />

        <CommentsPopover
          recordId={id}
          relatedObjectName={RelatedObjectNames.Absence}
          commentsCount={commentsCount}
          mode="text"
        />
      </div>
    </div>
  );
};

export default AbsenceItem;
