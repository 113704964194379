import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { WarningsProps } from './types';

const WarningIcon: React.FC<{ warnings: WarningsProps }> = ({ warnings }) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id="day-warning-popover">
          <Popover.Body>
            {warnings.exceededWorkingHours &&
              t('timeTracking.timeSheet.warnings.exceededWorkingHours')}
          </Popover.Body>
        </Popover>
      }>
      <span>
        <FlairIcon icon="alert-circle-outline" className="text-warning" />
      </span>
    </OverlayTrigger>
  );
};

export default WarningIcon;
