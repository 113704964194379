import { addMinutes } from 'date-fns';
import { orderBy, sortBy } from 'lodash';
import { setTime } from '../../../../../../utils/dateUtils';
import {
  calculateBreakingTime,
  getMinutesAfterClockin,
  isInsideInterval,
  mapBreakScheduledToInterval,
} from '../timeTrackingHelper';
import { TimeTrackingState } from '../types';
import {
  BreakReminder,
  BreakReminderScheduled,
  BreakReminderWorkingTime,
} from './types';

export const getReminderToShow = (
  breakReminders: BreakReminder[],
  timeTrackingState: TimeTrackingState | null,
  now: Date,
): BreakReminder | null => {
  if (!timeTrackingState) {
    return null;
  }
  const scheduledReminder = getScheduledReminder(
    breakReminders.filter(
      (r) => r.reminderType === 'scheduled',
    ) as BreakReminderScheduled[],
    now,
  );
  const workingTimeReminder = getWorkingTimeReminder(
    breakReminders.filter(
      (r) => r.reminderType === 'working-time',
    ) as BreakReminderWorkingTime[],
    getMinutesAfterClockin(timeTrackingState, now),
    calculateBreakingTime(timeTrackingState, now),
  );

  return chooseOneReminder(
    scheduledReminder,
    workingTimeReminder,
    timeTrackingState,
    now,
  );
};

export const getWorkingTimeReminder = (
  breakReminders: BreakReminderWorkingTime[],
  minutesAfterClockin: number,
  breakingTimeMinutes: number,
): BreakReminderWorkingTime | null => {
  const sortedReminders = orderBy(breakReminders, 'workingTime');
  for (let i = sortedReminders.length - 1; i >= 0; i--) {
    if (minutesAfterClockin >= sortedReminders[i].workingTime) {
      return breakingTimeMinutes < sortedReminders[i].breakDuration
        ? { ...sortedReminders[i] }
        : null;
    }
  }
  return null;
};

const getScheduledReminder = (
  breakReminders: BreakReminderScheduled[],
  now: Date,
): BreakReminderScheduled | null => {
  const matchedScheduledIntervals = breakReminders.filter((reminder) =>
    isInsideInterval(mapBreakScheduledToInterval(reminder, now), now),
  );
  return matchedScheduledIntervals.length ? matchedScheduledIntervals[0] : null;
};

const chooseOneReminder = (
  scheduledReminder: BreakReminderScheduled | null,
  workingTimeReminder: BreakReminderWorkingTime | null,
  timeTrackingState: TimeTrackingState,
  now: Date,
): BreakReminder | null => {
  const reminders = [scheduledReminder, workingTimeReminder].filter(
    (x): x is BreakReminder => x !== null,
  );
  if (reminders.length === 0) {
    return null;
  }
  if (reminders.length === 1) {
    return reminders[0];
  }
  const mappedReminders = reminders.map((reminder) => ({
    reminder,
    activationDate: getReminderActivationDate(reminder, timeTrackingState, now),
  }));
  return sortBy(mappedReminders, 'activationDate')[0].reminder;
};

const getReminderActivationDate = (
  reminder: BreakReminder,
  timeTrackingState: TimeTrackingState,
  now: Date,
): Date => {
  if (reminder.reminderType === 'scheduled') {
    return setTime(now, reminder.startTime);
  } else if (reminder.reminderType === 'working-time') {
    return addMinutes(timeTrackingState.start, reminder.workingTime);
  }
  throw new Error('Not implemented');
};

export const isOnBreak = (timeTrackingState: TimeTrackingState): boolean => {
  if (!timeTrackingState.breaks.length) {
    return false;
  }
  return (
    timeTrackingState.breaks[timeTrackingState.breaks.length - 1].end ===
    undefined
  );
};
