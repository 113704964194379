import * as React from 'react';

const TrashOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M3.49805 3.5L4.1229 13.4976C4.15258 14.0753 4.57279 14.4974 5.12266 14.4974H10.8713C11.4234 14.4974 11.8358 14.0753 11.8711 13.4976L12.4959 3.5"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.5 3.5H13.4974H2.5Z" fill="black" />
      <path
        d="M2.5 3.5H13.4974"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.248 5.49979L9.99802 12.4981M5.99896 3.50026V2.25056C5.99868 2.15201 6.01787 2.05438 6.05545 1.96327C6.09303 1.87217 6.14825 1.7894 6.21794 1.71971C6.28762 1.65002 6.3704 1.5948 6.4615 1.55722C6.5526 1.51964 6.65024 1.50045 6.74879 1.50074H9.2482C9.34675 1.50045 9.44438 1.51964 9.53548 1.55722C9.62659 1.5948 9.70936 1.65002 9.77905 1.71971C9.84873 1.7894 9.90395 1.87217 9.94153 1.96327C9.97911 2.05438 9.99831 2.15201 9.99802 2.25056V3.50026H5.99896ZM7.99849 5.49979V12.4981V5.49979ZM5.74902 5.49979L5.99896 12.4981L5.74902 5.49979Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default TrashOutlineIcon;
