import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Workload } from '.';
import ListGroupItem from '../../../../components/ListGroup/Item';

type Props = {
  workload: Workload;
};

const Label: React.FC = ({ children }) => <small>{children}</small>;
const Value: React.FC = ({ children }) => <small>{children}</small>;

const Data: React.FC<Props> = ({ workload }) => {
  const { t } = useTranslation();

  return (
    <ListGroup variant="flush">
      <ListGroupItem
        label={
          <Label>{t('employeePage.overview.workloadCard.nameLabel')}</Label>
        }
        value={<Value>{workload.Name}</Value>}
      />
      <ListGroupItem
        label={
          <Label>
            {t('employeePage.overview.workloadCard.dailyHoursLabel')}
          </Label>
        }
        value={
          <Value>
            {t('general.hours', {
              count: workload.flair__Hours_Per_Day__c ?? 0,
            })}
          </Value>
        }
      />
      <ListGroupItem
        label={
          <Label>
            {t('employeePage.overview.workloadCard.weeklyHoursLabel')}
          </Label>
        }
        value={
          <Value>
            {t('general.hours', { count: workload.flair__Hours__c ?? 0 })}
          </Value>
        }
      />
    </ListGroup>
  );
};

export default Data;
