import React from 'react';
import {
  ApproveTimeSheetsWithModalProps,
  useApproveTimeSheetsWithModal,
} from './useApproveTimeSheetsWithModal';

export const TimeSheetApproveContext =
  React.createContext<ApproveTimeSheetsWithModalProps>({
    approvingIds: [],
    approveTimeSheets: () => Promise.resolve(),
    approveModalVisible: false,
    onApproveModalSubmit: () => Promise.resolve(),
    onApproveModalClose: () => {},
  });

export const TimeSheetApproveContextProvider: React.FC = ({ children }) => {
  const value = useApproveTimeSheetsWithModal();
  return (
    <TimeSheetApproveContext.Provider value={value}>
      {children}
    </TimeSheetApproveContext.Provider>
  );
};
