import React from 'react';
import ModalSidebar from '../../../../components/ModalSidebar';
import { getHeaderText } from './mappings';
import { GoalSidebarCreateContent } from './GoalSidebarCreateContent';

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const GoalSidebarCreate: React.FC<Props> = ({ visible, onClose }) => {
  const header = getHeaderText('create');

  return (
    <ModalSidebar
      show={visible}
      onClose={onClose}
      header={header}
      content={<GoalSidebarCreateContent onClose={onClose} />}
    />
  );
};
