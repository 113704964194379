import React, { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';
import { TableContent } from '../../../manager/components/TableContent';
import { EmployeeWithAvatar } from '../../../components/Employee';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../utils/dateUtils';
import {
  CorporateDocument,
  DocumentEmployee,
  DocumentFilesType,
  File,
} from '../types';
import { EmptyDocuments } from '../EmptyDocuments';
import { DocumentCell } from './DocumentCell';
import { FilteredEmptyDocuments } from '../FilteredEmptyDocuments';

type Props = {
  documents: CorporateDocument[];
  onClick: (file: File | undefined) => void;
  hasCorporateDocumentFilter: boolean;
};

type TableItem = {
  id: string;
  name: string;
  employee?: DocumentEmployee;
  addedOn: string;
  type: DocumentFilesType;
  file?: File;
  subRows?: TableItem[];
};

const i18Prefix = 'documents2.corporate';

export const CorporateDocumentsTable: React.FC<Props> = ({
  documents,
  onClick,
  hasCorporateDocumentFilter,
}) => {
  const { t } = useTranslation();
  const items = documents.map(mapToTableItem);

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.name`)}`} />
        ),
        accessor: 'name',
        id: 'expander',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) => {
          return (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth}rem`,
                },
              })}>
              <DocumentCell
                name={value}
                hasSubRow={!!row.original.subRows?.length || false}
                isExpanded={row.isExpanded}
                onClick={() => {
                  onClick(row.original.file);
                }}
                fileType={row.original.type.type}></DocumentCell>
            </div>
          );
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.addedBy`)}`} />
        ),
        accessor: 'employee',
        Cell: ({ value }: { value: DocumentEmployee }) => {
          return (
            <EmployeeWithAvatar
              employee={{
                name: value.name,
                avatarUrl: value.avatarUrl,
              }}></EmployeeWithAvatar>
          );
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.addedOn`)}`} />
        ),
        accessor: 'addedOn',
        Cell: ({ value }: { value: string | null }) =>
          value ? (
            <FormattedDate day={parseDate(value)} format="short" />
          ) : (
            <span>-</span>
          ),
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [onClick, t]);

  if (!items.length && !hasCorporateDocumentFilter) {
    return <EmptyDocuments />;
  }
  if (!items.length && hasCorporateDocumentFilter) {
    return <FilteredEmptyDocuments />;
  }

  return <TableContent columns={columns} data={items}></TableContent>;
};

function mapToTableItem(src: CorporateDocument): TableItem {
  return {
    id: src.id,
    name: src.name,
    employee: src.employee,
    addedOn: src.addedOn,
    type: src.type,
    ...(src.files?.length === 1 && { file: src.files[0] }),
    ...(src.files &&
      src.files?.length > 1 && {
        subRows: src.files.map((file) => mapToTableSubItem(src, file)),
      }),
  };
}
function mapToTableSubItem(src: CorporateDocument, file: File): TableItem {
  return {
    id: file.id,
    name: file.title,
    employee: src.employee,
    addedOn: src.addedOn,
    type: { filesCount: 1, type: file.type },
    file: file,
  };
}
