import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Route } from '../../../../../utils/router';
import PageHeaderNav from '../../../components/PageHeaderNav';
import { routes } from '../../../routes';

type TabItem = {
  label: string;
  route: Route<string[]>;
};

export const CelebrationsNav: React.FC = () => {
  const t = useNamespacedTranslation('cheers.tabs');

  const tabItems: TabItem[] = [
    {
      label: t('feed'),
      route: routes.celebrationsFeed.route,
    },
    {
      label: t('received'),
      route: routes.celebrationsReceived.route,
    },
    {
      label: t('sent'),
      route: routes.celebrationsSent.route,
    },
  ];

  return <PageHeaderNav tabItems={tabItems} exact={false} />;
};
