import { parseISO } from 'date-fns';
import { sortBy } from 'lodash';
import {
  ActivityType,
  ProjectActivity,
  SFColleague,
  SFProject,
  SFProjectHistoryItem,
} from '../types';
import { getEmployeeInitials } from '../../../../dashboard/utils/employeeInitials';
import { ChangeEvent } from '../../../__generated__/graphql';

const isCreateEvent = (history: SFProjectHistoryItem) =>
  history.changeEvent === ChangeEvent.Insert;
const isUpdateEvent = (history: SFProjectHistoryItem) =>
  history.changeEvent === ChangeEvent.Update;

type ActivityMap = {
  [key: string]: ActivityType;
};

const fieldsActivityMap: ActivityMap = {
  Name: 'SET_NAME',
  flair__Description__c: 'SET_DESCRIPTION',
  flair__Estimation__c: 'SET_ESTIMATION',
  flair__Start_Date__c: 'SET_START_DATE',
  flair__End_Date__c: 'SET_END_DATE',
};

export const mapActivities = (src: SFProject): ProjectActivity[] => {
  const items = [
    activityCreate(src.historyItems.filter(isCreateEvent)[0]),
    ...src.historyItems.filter(isUpdateEvent).map(activityFromHistory),
  ].filter((i) => i);

  return sortBy(
    items.filter((x): x is ProjectActivity => x !== null),
    'updatedAt',
  );
};

const activityFromHistory = (
  src: SFProjectHistoryItem,
): ProjectActivity | null => {
  switch (src.fieldApiName) {
    case 'Name':
    case 'flair__Description__c':
    case 'flair__Estimation__c':
    case 'flair__Start_Date__c':
    case 'flair__End_Date__c':
      return {
        ...mapCommonActivityFields(src),
        type: fieldsActivityMap[src.fieldApiName],
        value: src.newValue ?? '',
      };
    case 'flair__Billable__c':
      return activityBillableChanged(src);
    default:
      return null;
  }
};

const activityBillableChanged = (
  src: SFProjectHistoryItem,
): ProjectActivity | null => {
  if (src.newValue === 'true') {
    return {
      ...mapCommonActivityFields(src),
      type: 'MARK_AS_BILLABLE',
    };
  } else if (src.oldValue === 'true' && src.newValue === 'false') {
    return {
      ...mapCommonActivityFields(src),
      type: 'MARK_AS_NON_BILLABLE',
    };
  }
  return null;
};

const mapCommonActivityFields = (src: SFProjectHistoryItem) => ({
  id: src.id,
  employee: src.changedBy ? mapEmployee(src.changedBy) : undefined,
  updatedAt: parseISO(src.date),
});

const activityCreate = (src: SFProjectHistoryItem) => {
  return (
    src && {
      id: src.id,
      type: 'CREATE',
      employee: src.changedBy ? mapEmployee(src.changedBy) : undefined,
      updatedAt: parseISO(src.date),
    }
  );
};

export const mapEmployee = (src: SFColleague) =>
  src
    ? {
        id: src.Id,
        name: src.Name,
        initials: getEmployeeInitials(src),
      }
    : undefined;
