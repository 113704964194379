import * as React from 'react';

const PriceTagIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.5907 1.41314C14.4612 1.28212 14.3069 1.17818 14.1368 1.10738C13.9667 1.03658 13.7842 1.00032 13.6 1.00074H9.75934C9.5074 1.00121 9.26581 1.10101 9.087 1.27848L1.40912 8.9548C1.14714 9.21732 1 9.57306 1 9.94394C1 10.3148 1.14714 10.6706 1.40912 10.9331L5.06451 14.5885C5.3271 14.8506 5.68295 14.9978 6.05396 14.9978C6.42497 14.9978 6.78083 14.8506 7.04342 14.5885L14.7182 6.91528C14.896 6.73675 14.9962 6.49523 14.9969 6.24325V2.40041C14.998 2.21714 14.9627 2.03547 14.8929 1.86598C14.8232 1.69649 14.7205 1.54255 14.5907 1.41314ZM11.9976 4.99979C11.7998 4.99979 11.6065 4.94116 11.4421 4.8313C11.2777 4.72145 11.1496 4.5653 11.0739 4.38262C10.9982 4.19994 10.9784 3.99892 11.017 3.80498C11.0556 3.61105 11.1508 3.43291 11.2906 3.29309C11.4304 3.15327 11.6086 3.05805 11.8025 3.01947C11.9965 2.9809 12.1975 3.0007 12.3802 3.07637C12.5628 3.15204 12.719 3.28018 12.8288 3.44459C12.9387 3.609 12.9973 3.80229 12.9973 4.00003C12.9973 4.26518 12.892 4.51948 12.7045 4.70697C12.517 4.89446 12.2627 4.99979 11.9976 4.99979Z"
        fill="black"
      />
    </svg>
  );
});

export default PriceTagIcon;
