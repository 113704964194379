import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import { getApiBaseUrl } from '../../utils/config';

const client = new ApolloClient({
  name: 'web',
  version: import.meta.env.VITE_APP_CODE_VERSION,
  uri: getApiBaseUrl() + '/public/graphql',
  cache: new InMemoryCache(),
});

export const Provider: React.FC = ({ children }) => (
  <ApolloProvider client={client} children={children} />
);
