import React from 'react';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  MyEnpsSurveysDocument,
  MyEnpsSurveysQuery,
  useAnswerEnpsSurveyCycleMutation,
  useDismissEnpsSurveyCycleMutation,
} from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';
import { EnpsData, EnpsSurveyUI } from './EnpsSurveyUI';

type Props = {
  survey: MyEnpsSurveysQuery['me']['enpsSurveys'][number];
  cycle: MyEnpsSurveysQuery['me']['enpsSurveys'][number]['cycles'][number];
};

const i18Prefix = 'home.enpsCard';

export const EnpsSurvey: React.FC<Props> = ({ survey, cycle }) => {
  const { id: employeeId } = useUserInfo();
  const t = useNamespacedTranslation(i18Prefix);
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const [answerSurvey, { loading: loadingAnswer }] =
    useAnswerEnpsSurveyCycleMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [{ query: MyEnpsSurveysDocument }],
    });

  const [dismissSurvey, { loading: loadingDismiss }] =
    useDismissEnpsSurveyCycleMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [{ query: MyEnpsSurveysDocument }],
    });
  const loading = loadingAnswer || loadingDismiss;

  const handleSubmit = (data: EnpsData) => {
    answerSurvey({
      variables: {
        input: {
          surveyId: survey.id,
          surveyCycleId: cycle.id,
          employeeId: employeeId,
          score: data.score,
          comment: data.comment,
        },
      },
    })
      .then(() => {
        addSuccess(t('submitSuccess'));
      })
      .catch(errorHandler);
  };

  const handleDismiss = (callback?: () => void) => {
    dismissSurvey({
      variables: {
        input: {
          surveyId: survey.id,
          surveyCycleId: cycle.id,
          employeeId: employeeId,
        },
      },
    })
      .then(() => {
        addSuccess(t('dismissConfirmation.successMessage'));
        !!callback && callback();
      })
      .catch(errorHandler);
  };

  return (
    <EnpsSurveyUI
      headerTitle={t('cardTitle')}
      questionText={survey.questionText}
      commentPromptText={
        survey.allowComments ? t('commentQuestionPrompt') : t('commentPrompt')
      }
      handleSubmit={handleSubmit}
      handleDismiss={handleDismiss}
      allowComments={survey.allowComments}
      allowDismiss={!survey.compulsory}
      submitLoading={loading}
    />
  );
};
