import React from 'react';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import { Employee } from '../../../../components/Employee/types';

type DashboardEmployeeWithAvatarProps = {
  employee: Employee;
  additionalInfo?: React.ReactNode;
  onClick?: () => void;
  rightSideElement?: React.ReactNode;
};

const DashboardEmployeeWithAvatar: React.FC<
  DashboardEmployeeWithAvatarProps
> = ({ employee, additionalInfo, onClick, rightSideElement }) => {
  return (
    <div className="d-flex justify-content-between align-items-center dashboard-employee-with-avatar">
      <EmployeeWithAvatar
        employee={employee}
        additionalInfo={additionalInfo}
        size="sm"
        avatarClassName="me-3"
        additionalInfoContainerClassName="h5-regular"
        onEmployeeClick={onClick}
      />
      {rightSideElement}
    </div>
  );
};

export default DashboardEmployeeWithAvatar;
