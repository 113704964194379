import { getISOWeek, getMonth } from 'date-fns';
import { WorkloadType } from '../../__generated__/graphql';
import { formatDateShort, parseDate } from '../../../../utils/dateUtils';
import { t } from 'i18next';

const i18prefix = 'timeSheetsControlling';

export function formatPeriodName(period: {
  startDay: string;
  type: WorkloadType;
}): string {
  switch (period.type) {
    case WorkloadType.Weekly:
      return (
        t(`${i18prefix}.week`) +
        ' ' +
        getISOWeek(parseDate(period.startDay)).toString()
      );
    case WorkloadType.Monthly:
      return (
        t(`${i18prefix}.month`) +
        ' ' +
        (getMonth(parseDate(period.startDay)) + 1).toString()
      );
  }
}

// todo: In next PR: Improve to '2 to 6 Jan. 2022 - 5 days', if we need this
export function formatPeriodInterval(period: {
  startDay: string;
  endDay: string;
}): string {
  return (
    formatDateShort(period.startDay) + ' - ' + formatDateShort(period.endDay)
  );
}
