import * as React from 'react';

const HomeFolderIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M18 8.83268V17.4999C18 17.8977 17.842 18.2792 17.5607 18.5605C17.2794 18.8418 16.8978 18.9999 16.5 18.9999H12.75C12.3522 18.9999 11.9706 18.8418 11.6893 18.5605C11.408 18.2792 11.25 17.8977 11.25 17.4999V13.7499C11.25 13.551 11.171 13.3602 11.0303 13.2195C10.8897 13.0789 10.6989 12.9999 10.5 12.9999H7.5C7.30109 12.9999 7.11032 13.0789 6.96967 13.2195C6.82902 13.3602 6.75 13.551 6.75 13.7499V17.4999C6.75 17.8977 6.59196 18.2792 6.31066 18.5605C6.02936 18.8418 5.64782 18.9999 5.25 18.9999H1.5C1.10218 18.9999 0.720644 18.8418 0.43934 18.5605C0.158035 18.2792 1.73323e-08 17.8977 1.73323e-08 17.4999V8.83268C-3.15402e-05 8.62508 0.0430305 8.41973 0.126461 8.22964C0.209891 8.03954 0.331873 7.86883 0.484687 7.72831L7.98469 0.652058L7.995 0.641745C8.27113 0.39062 8.63097 0.251465 9.00422 0.251465C9.37747 0.251465 9.7373 0.39062 10.0134 0.641745C10.0166 0.645414 10.0201 0.648859 10.0238 0.652058L17.5238 7.72831C17.675 7.86957 17.7954 8.04061 17.8774 8.23067C17.9594 8.42074 18.0011 8.6257 18 8.83268Z"
        fill="#196879"
      />
    </svg>
  );
});

export default HomeFolderIcon;
