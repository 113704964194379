import React from 'react';
import { EmployeeNotificationSettingsQuery } from '../../../../../__generated__/graphql';
import { Form } from 'react-bootstrap';
import { omit } from 'lodash';
import { useUpdateEmployeeNotificationSettings } from './useUpdateNotificationSettings';
import { NotificationChannel } from '../../types';

type NotificationSettingToggleProps = {
  data: EmployeeNotificationSettingsQuery;
  name: string;
  channel: NotificationChannel;
};

const NotificationSettingToggle: React.FC<NotificationSettingToggleProps> = ({
  data,
  name,
  channel,
}) => {
  const getFieldLabel = (
    notificationName: string,
    channel: NotificationChannel,
  ) => {
    const capitalizedChannel =
      channel.charAt(0).toUpperCase() + channel.slice(1);
    return `flair__${notificationName}_${capitalizedChannel}__c`;
  };

  const settingsValueForFiledLabel = (fieldLabel: string) =>
    data.me.notificationSettings[
      fieldLabel as keyof typeof data.me.notificationSettings
    ];

  const fieldLabel = getFieldLabel(name, channel);
  const isSettingDefined = settingsValueForFiledLabel(fieldLabel) !== undefined;

  const [updateNotificationSettings, { loading }] =
    useUpdateEmployeeNotificationSettings(fieldLabel);

  const handleChange = async () => {
    const currentValues = omit(data.me.notificationSettings, [
      'Name',
      'flair__Employee__c',
      '__typename',
    ]);
    await updateNotificationSettings({
      variables: {
        input: {
          ...currentValues,
          [fieldLabel]: !settingsValueForFiledLabel(fieldLabel),
        },
      },
    });
  };

  return (
    <>
      {isSettingDefined && (
        <Form.Check
          className="ms-2"
          type="switch"
          checked={!!settingsValueForFiledLabel(fieldLabel)}
          id={fieldLabel}
          disabled={loading}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default NotificationSettingToggle;
