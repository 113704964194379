import React from 'react';
import { Col, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from '../../../../../../../Router';
import Header, {
  PageHeaderRow as HeaderRow,
} from '../../../../../components/PageHeader';
import { routes } from '../../../../../routes';

type Props = {
  title: string;
  subtitle: string;
  feedbackId: string;
};

export const PageHeader: React.FC<Props> = ({
  title,
  subtitle,
  feedbackId,
}) => {
  const { t } = useTranslation();
  return (
    <Header title={title} subtitle={subtitle}>
      <HeaderRow>
        <Col>
          <Nav variant="tabs" className="header-tabs" as="ul">
            <Nav.Item as="li">
              <NavLink
                className="nav-link"
                activeClassName="active"
                feedbackId={feedbackId}
                exact={true}
                to={routes.performanceReviewerMeeting.route}>
                {t(
                  'employeePage.performanceReview.meeting.compareFeedback.title',
                )}
              </NavLink>
            </Nav.Item>
          </Nav>
        </Col>
      </HeaderRow>
    </Header>
  );
};
