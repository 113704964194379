import styled from '@emotion/styled';
import classnames from 'classnames';
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Theme } from '../../theme';

export type Button = {
  title: string;
  name: string;
};

type Variant = 'primary' | 'secondary' | 'secondary-2';

export type Props = {
  value?: string;
  defaultValue: string;
  buttons: Button[];
  onChange?: (value: string) => void;
  variant?: Variant;
  containerClassName?: string;
};

const ToggleGroup: React.FC<Props> = ({
  value,
  defaultValue,
  buttons,
  onChange,
  variant = 'primary',
  containerClassName = '',
}) => {
  return (
    <StyledToggleGroup
      size="sm"
      type="radio"
      name="options"
      variant={variant}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      className={classnames('rounded', 'p-1', containerClassName)}>
      {buttons.map((button) => (
        <StyledToggleButton
          key={button.name}
          id={button.name}
          value={button.name}
          variant={variant}
          className={classnames('border-0', 'rounded')}>
          {button.title}
        </StyledToggleButton>
      ))}
    </StyledToggleGroup>
  );
};

export default ToggleGroup;

const StyledToggleGroup = styled(ToggleButtonGroup)<{ variant: Variant }>(
  ({ variant }) => {
    switch (variant) {
      case 'primary': {
        return { backgroundColor: Theme.color.blue2 };
      }
      case 'secondary': {
        return { backgroundColor: Theme.color.paper4 };
      }
      case 'secondary-2': {
        return { backgroundColor: Theme.color.bgGray1 };
      }
    }
  },
);

const StyledToggleButton = styled(ToggleButton)<{ variant: Variant }>(
  ({ variant, checked }) => {
    switch (variant) {
      case 'primary': {
        const backgroundColor = checked ? Theme.color.blue4 : Theme.color.blue2;
        return {
          backgroundColor: `${backgroundColor} !important`,
          color: Theme.color.white,
        };
      }
      case 'secondary': {
        return {
          backgroundColor: checked ? Theme.color.paper2 : Theme.color.paper4,
          color: Theme.color.black,
        };
      }
      case 'secondary-2': {
        return {
          backgroundColor: `${
            checked ? Theme.color.white : Theme.color.bgGray1
          } !important`,
          color: Theme.color.black,
        };
      }
    }
  },
);
