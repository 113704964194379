import React, { ErrorInfo } from 'react';
import { ReportingError } from './ReportingError';
import {
  ErrorBoundary as RollbarErrorBoundary,
  LEVEL_ERROR,
} from '@rollbar/react';
import { FallbackUI } from './FallbackUI';

export const ErrorBoundary: React.FC = ({ children }) => {
  const getExtra = (error: Error, _errorInfo: ErrorInfo) => {
    if (error instanceof ReportingError) {
      return { reportData: error.reportData };
    }
    return {};
  };

  return (
    <RollbarErrorBoundary
      level={LEVEL_ERROR}
      children={children}
      extra={getExtra}
      fallbackUI={FallbackUI}
    />
  );
};
