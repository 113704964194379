import {
  GoalStatusWithRate,
  ObjectiveFormData,
  ObjectiveFormSaveInfo,
} from './types';
import * as yup from 'yup';
import { useForm, useFormContext } from 'react-hook-form';
import useYupValidationResolver from '../../../../../../components/form/useYupValidationResolver';
import { GoalId, GoalType } from '../types';
import { useCallback, useState } from 'react';
import { fromPairs, mapValues, pick } from 'lodash';
import { useUpdateObjective } from './useObjectiveMutations';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import i18next from 'i18next';

const i18prefix = 'performanceReview.goals2';

const validationSchema = yup.object().shape({
  employeeId: yup
    .string()
    .nullable(true)
    .when('type', (type: GoalType, schema: yup.StringSchema) => {
      if (type === 'employee') {
        return schema.required();
      }
      return schema;
    }),
  teamId: yup
    .string()
    .nullable(true)
    .when('type', (type: GoalType, schema: yup.StringSchema) => {
      if (type === 'team') {
        return schema.required();
      }
      return schema;
    }),
  timePeriodId: yup.string().required(),
  name: yup.string().max(80).required(),
  dueDate: yup
    .date()
    .nullable(true)
    .min(
      yup.ref('startDate'),
      i18next.t(`${i18prefix}.validationEndDateShouldBeGreater`),
    ),
  statusWithRate: yup
    .object()
    .test(
      'cancelReason',
      i18next.t(`${i18prefix}.validationCancelReasonEmpty`),
      function (value: GoalStatusWithRate) {
        if (
          value.status === 'CANCELED' &&
          (!value.cancelReason || !value.cancelReason.length)
        ) {
          return false;
        }
        return true;
      },
    ),
});

const getDefaultValues = (employeeId: string): ObjectiveFormData => ({
  type: 'employee',
  name: '',
  employeeId,
  teamId: undefined,
  description: '',
  visibility: 'public',
  timePeriodId: '',
  startDate: null,
  dueDate: null,
  statusWithRate: {
    status: 'DRAFT',
    rate: 'OPEN',
  },
  tagIds: [],
  sharedWithEmployeeIds: [],
  keyResults: [],
  parentObjectiveId: null,
  manualProgress: false,
  goalOnTrackAutoState: true,
});

export const useCreateObjectiveForm = (meId: string) => {
  const form = useForm<ObjectiveFormData>({
    defaultValues: getDefaultValues(meId),
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return form;
};

export const useUpdateObjectiveForm = (values: ObjectiveFormData) => {
  const form = useForm<ObjectiveFormData>({
    defaultValues: values,
    mode: 'onChange', // this is require for validation works properly
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return form;
};

export const useSubmitUpdate = (meId: string, goalId: GoalId) => {
  const { handleSubmit: handleFormSubmit, reset: formReset } =
    useFormContext<ObjectiveFormData>();
  const errorHandler = useMutationErrorHandler();
  const [saveInfo, setSaveInfo] = useState<ObjectiveFormSaveInfo>({});
  const [updateObjective] = useUpdateObjective(meId, goalId);

  const submitUpdate = useCallback(
    (fieldsToUpdate: Set<string>) => {
      handleFormSubmit((formData: ObjectiveFormData) => {
        setSaveInfo(
          createSavingInfo(fieldsToUpdate as Set<keyof ObjectiveFormData>),
        );

        const formDataToSubmit = pick(
          formData,
          Array.from(fieldsToUpdate.values()),
        );

        return updateObjective(formDataToSubmit)
          .then(() => {
            // form reset will be at parent component (GoalUpdateForm)
            setSaveInfo((prev) => setCompleted(prev));
          })
          .catch((e) => {
            formReset();
            setSaveInfo((prev) => setCompleted(prev, false));
            errorHandler(e);
          });
      })();
    },
    [errorHandler, formReset, handleFormSubmit, updateObjective, setSaveInfo],
  );

  return { submitUpdate, saveInfo };
};

const createSavingInfo = (
  fields: Set<keyof ObjectiveFormData>,
): Partial<ObjectiveFormSaveInfo> =>
  fromPairs(Array.from(fields.keys()).map((key) => [key, { loading: true }]));

const setCompleted = (
  savingInfo: Partial<ObjectiveFormSaveInfo>,
  completed: boolean = true,
) => mapValues(savingInfo, () => ({ loading: false, completed }));
