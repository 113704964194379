import * as React from 'react';

const ArrowBackOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.62296 12.4981L3.12402 7.99918L7.62296 3.50024M3.74888 7.99918H12.8717"
        stroke="currentColor"
        strokeWidth="1.49965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ArrowBackOutlineIcon;
