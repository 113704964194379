import React from 'react';
import { Employee } from '../../../People/types';

export type WidgetEmployee = Employee & {
  date: Date;
  startDate?: Date;
  additionalInfo?: React.ReactNode;
};

export type Section = {
  key: SectionCategory;
  label: string;
  items: WidgetEmployee[];
};

/* eslint-disable no-unused-vars */
export enum SectionCategory {
  TODAY,
  UPCOMING,
}

export enum SectionType {
  ANNIVERSARIES,
  BIRTHDAYS,
  PROBATION,
  ABSENT_TODAY,
}
