import React from 'react';
import {
  ConfirmModalSimple,
  ConfirmModalSimpleProps,
} from '../../../../../components/ConfirmModalSimple';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = Omit<ConfirmModalSimpleProps, 'message'>;

const i18nPrefix = 'cheers.employeePage';

export const CelebrationDeleteConfirmation = ({ ...restProps }: Props) => {
  const t = useNamespacedTranslation(i18nPrefix);

  const message = t('deleteConfirmation');

  return <ConfirmModalSimple {...restProps} message={message} />;
};
