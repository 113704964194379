import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { SingleVisiblePopoverProvider } from '../../../../../context/SingleVisiblePopoverContext';
import { ProjectsControllingTimeSheetTable } from './ProjectsControllingTimeSheetTable';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import { useFlairPersistentFilter } from '../../../../../hooks/useFlairPersistentFilter';
import { routes } from '../../../routes';
import { ProjectsTimeSheetFiltersConnected } from './Filters/ProjectsTimeSheetFiltersConnected';
import { defaultFilter, ProjectsTimeSheetsFilter } from './Filters/types';
import ProjectsManagerHeader from '../ProjectsManagerHeader';
import { ManagerReportsModeSwitch } from '../../../manager/components/ManagerReportsModeSwitch';
import {
  TimeSheetApproveContextProvider,
  TimeSheetApproveModalConnected,
} from '../../../components/TimeSheetApprove';

const ProjectsControllingTimeSheets: React.FC = () => (
  <>
    <ProjectsManagerHeader />
    <Content />
  </>
);

const Content: React.FC = () => {
  const [reportsMode, setReportsMode] = useManagerReportsMode(
    'projectsTimeTrackingControlling',
    'direct',
  );

  const [filter, setFilter] =
    useFlairPersistentFilter<ProjectsTimeSheetsFilter>({
      defaultFilter: defaultFilter,
      storageKey: 'projects_timeSheets_controlling',
      createRouteUrl: (queryParams) =>
        routes.projectsControllingTimeSheets.route
          .withQueryParams(queryParams)
          .create({}),
    });

  return (
    <SingleVisiblePopoverProvider>
      <TimeSheetApproveContextProvider>
        <div className="d-flex justify-content-end mt-n4">
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        </div>
        <Card className="h7-regular">
          <Card.Header>
            <Row>
              <Col>
                <ProjectsTimeSheetFiltersConnected
                  filter={filter}
                  onChange={setFilter}
                  reportsMode={reportsMode}
                />
              </Col>
            </Row>
          </Card.Header>
          <ProjectsControllingTimeSheetTable
            filter={filter}
            reportsMode={reportsMode}
          />
          <TimeSheetApproveModalConnected />
        </Card>
      </TimeSheetApproveContextProvider>
    </SingleVisiblePopoverProvider>
  );
};

export default ProjectsControllingTimeSheets;
