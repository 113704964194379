import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ServerError from '../../../../../components/ServerError';
import { useEmployeesQuery } from '../../../__generated__/graphql';
import { CardHeaderFilter, PageSize } from '../../components/CardHeaderFilter';
import EmployeesTable from './EmployeesTable';
import Loading from './Loading';
import {
  useTableContentFiltration,
  useTableContentPageSize,
} from '../../components/useTableContent';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../../hooks/useManagerReportsMode';

type CardContentProps = {
  filter: string;
  pageSize: number;
};

const CardContent: React.FC<CardContentProps> = ({ filter, pageSize }) => {
  const [reportsMode] = useManagerReportsMode('myteam');
  const { data, loading, error } = useEmployeesQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });

  if (loading) {
    return <Loading pageSize={pageSize} />;
  }

  if (error || data === undefined) {
    return <ServerError />;
  }

  return (
    <EmployeesTable
      data={data.manager.employees}
      filter={filter}
      pageSize={pageSize}
    />
  );
};

const INITIAL_PAGE_SIZE: PageSize = 50;

const EmployeesList: React.FC = () => {
  const { t } = useTranslation();
  const [filter, onFilterChange] = useTableContentFiltration();
  const [pageSize, onPageSizeChange] =
    useTableContentPageSize(INITIAL_PAGE_SIZE);

  return (
    <Card>
      <CardHeaderFilter
        onFilterChange={onFilterChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        placeholder={t('employees.all.table.filterPlaceholder')}
      />
      <CardContent filter={filter} pageSize={pageSize} />
    </Card>
  );
};

export default EmployeesList;
