import React, { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import { TicketModalContext } from './TicketModalContext';

type TStepForm = {
  list: JSX.Element[];
};

export const StepForm = ({ list }: TStepForm) => {
  const { currentStep = 0 } = useContext(TicketModalContext);

  return (
    <Container fluid className="p-0">
      <Row className="align-items-center flex-fill g-0 justify-content-center">
        {list[currentStep]}
      </Row>
    </Container>
  );
};
