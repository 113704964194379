import { filesize } from 'filesize';

export const toBase64: (file: File) => Promise<string> = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const result = reader.result as string;
      const data = result.split(',')[1];
      resolve(data);
    };
    reader.onerror = (error) => reject(error);
  });

export const isImageFile = (fileExtension: string) => {
  const pattern = /jpeg|jpg|gif|png$/i;
  return pattern.test(fileExtension);
};

export type FileType = {
  fileName: string;
  fileBase64: string;
  size: number;
  fileType: string;
};

export const collectFiles = async (
  files: File[] | FileList,
): Promise<FileType[]> => {
  const filePromises = Array.from(files).map(async (file) => ({
    fileName: file.name,
    fileBase64: await toBase64(file),
    size: file.size,
    fileType: file.type,
  }));

  return Promise.all(filePromises);
};

export const FILE_SIZE_LIMIT = 3.5 * 1024 * 1024;

export const fileSizeFormatter = (size: number): string => {
  return filesize(size, { base: 2, standard: 'jedec' }) as string;
};

export function getFileNameWithoutExtension(fileName: string): string {
  if (fileName.endsWith('.')) {
    return fileName;
  }
  const dotIndex = fileName.lastIndexOf('.');
  if (dotIndex <= 0) {
    return fileName;
  }
  return fileName.substring(0, dotIndex);
}

export const getFileType: (fileName: string) => string = (filename) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1 || dotIndex === 0) {
    return '';
  }
  return filename.substring(dotIndex + 1);
};
