import React from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import './customizeItems.css';
import FlairIcon from '../../atomic/atoms/FlairIcon';

export type CustomizableItem = {
  id: string;
  checked: boolean;
  name: string;
};

type Props = {
  onChange: (items: CustomizableItem[]) => void;
  items: CustomizableItem[];
};

export const CustomizeItems: React.FC<Props> = ({ items, onChange }) => {
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  const handleSort = () => {
    let tempItems = [...items];
    const draggedItemContent = tempItems.splice(dragItem.current, 1)[0];
    tempItems.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    onChange(tempItems);
  };

  const toggleChecked = (currentItem: CustomizableItem) => {
    onChange(
      items.map((item) =>
        item.id === currentItem.id ? { ...item, checked: !item.checked } : item,
      ),
    );
  };

  return (
    <ListGroup variant="flush">
      {items.map((item, index) => (
        <ListGroup.Item
          key={index}
          as="li"
          className="d-flex justify-content-between align-items-start"
          draggable
          onDragStart={() => (dragItem.current = index)}
          onDragEnter={() => (dragOverItem.current = index)}
          onDragEnd={handleSort}
          onDragOver={(e) => e.preventDefault()}>
          <div className="draggable-icon-container">
            <FlairIcon icon="handle-icon" size="xs" />
          </div>
          <div className="ms-2 me-auto">
            <div className="fw-bold">{item.name}</div>
          </div>

          <div>
            <Form.Check
              type="switch"
              checked={item.checked}
              onChange={() => toggleChecked(item)}
            />
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
