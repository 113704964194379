import { useState } from 'react';
import {
  LastUpdateFilterByCategory,
  LastUpdateFilterByTeam,
  useDashboardLastUpdatesQuery,
} from '../../../__generated__/graphql';
import { useServerFeatures } from '../../../../../hooks/useServerFeature';
import { useUserInfo } from '../../../context/UserInfo';

const NUMBER_OF_ITEMS_TO_SHOW = 5;

const initialOffset = {
  announcementOffset: 0,
  celebrationOffset: 0,
  newHiresOffset: 0,
};

export const useFetchLastUpdates = () => {
  const { isActive } = useUserInfo();
  const isFeatureVisible = useServerFeatures();

  const isCheersVisible = isActive && isFeatureVisible('Cheers');
  const isNewHiresVisible = isActive && isFeatureVisible('Home_New_Hires_Card');
  const isAnnouncementsVisible = isActive && isFeatureVisible('Announcements');

  const [isLoadingForFetchMoreLess, setIsLoadingForFetchMoreLess] =
    useState<boolean>(false);

  const initialSelectedCategories = [
    isAnnouncementsVisible && LastUpdateFilterByCategory.Announcements,
    isCheersVisible && LastUpdateFilterByCategory.Cheers,
    isNewHiresVisible && LastUpdateFilterByCategory.NewJoiners,
  ].filter(Boolean) as LastUpdateFilterByCategory[];

  const [filterByTeam, setFilterByTeam] = useState<LastUpdateFilterByTeam>(
    LastUpdateFilterByTeam.AllCompany,
  );

  const [filterByCategory, setFilterByCategory] = useState<
    LastUpdateFilterByCategory[]
  >(initialSelectedCategories);

  const filter = {
    filterByTeam,
    filterByCategory,
  };

  const { data, error, refetch, fetchMore, loading } =
    useDashboardLastUpdatesQuery({
      variables: {
        filter,
        paginationInfo: {
          limit: NUMBER_OF_ITEMS_TO_SHOW,
          offset: initialOffset,
        },
      },
    });

  const handleShowLess = () => {
    setIsLoadingForFetchMoreLess(true);
    refetch().finally(() => setIsLoadingForFetchMoreLess(false));
  };

  const handleShowMore = () => {
    if (!data) {
      return;
    }

    setIsLoadingForFetchMoreLess(true);
    const { __typename, ...offset } = data.lastUpdates.offset;

    fetchMore({
      variables: {
        filter,
        paginationInfo: {
          limit: NUMBER_OF_ITEMS_TO_SHOW,
          offset,
        },
      },
    }).finally(() => setIsLoadingForFetchMoreLess(false));
  };

  return {
    data,
    loading,
    isLoadingForFetchMore: isLoadingForFetchMoreLess,
    handleShowMore,
    handleShowLess,
    error,
    setFilterByTeam,
    setFilterByCategory,
    filterByTeam,
    filterByCategory,
  };
};
