import React from 'react';
import { TableRow, isTableDaySubRow, isTableRowTimeSheet } from '../types';
import { DifferenceCell } from './DifferenceCell';

type Props = {
  row: TableRow;
};

export const WorkedMinutesDifferenceCell: React.FC<Props> = ({ row }) => {
  if (isTableDaySubRow(row)) {
    return (
      <DifferenceCell
        differenceMinutes={row.workedDifferenceMinutes}
        changeTo={row.workedDifferenceMinutesWithChanges}
        differenceTrackedMinutes={row.trackedDifferenceMinutes}
        changeToTrackedMinutes={row.trackedDifferenceMinutesWithChanges}
      />
    );
  } else if (isTableRowTimeSheet(row)) {
    return (
      <DifferenceCell
        differenceMinutes={row.workedDifferenceMinutes}
        changeTo={null}
        differenceTrackedMinutes={row.differenceMinutes}
        changeToTrackedMinutes={null}
      />
    );
  } else {
    return null;
  }
};
