import * as React from 'react';

const PersonOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M9.74677 4.41018C9.6243 6.10433 8.3721 7.48638 6.99742 7.48638C5.62275 7.48638 4.36835 6.10465 4.24807 4.41018C4.1231 2.64778 5.34156 1.33398 6.99742 1.33398C8.65328 1.33398 9.87174 2.67982 9.74677 4.41018Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99768 9.53809C4.27958 9.53809 1.52085 11.0762 1.01035 13.9793C0.948799 14.3293 1.14188 14.6651 1.49898 14.6651H12.4964C12.8538 14.6651 13.0469 14.3293 12.9853 13.9793C12.4745 11.0762 9.71579 9.53809 6.99768 9.53809Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default PersonOutlineIcon;
