import React from 'react';
import { Col } from 'react-bootstrap';
import PageHeader, { PageHeaderRow } from '../../../components/PageHeader';
import PersonalDataNav from '../SettingsNav';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

const i18Path = 'settings';

const SettingsHeader: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  useFlairBreadcrumbHook([{ label: t('title') }]);

  return (
    <PageHeader title={t('title')}>
      <PageHeaderRow>
        <Col>
          <PersonalDataNav />
        </Col>
      </PageHeaderRow>
    </PageHeader>
  );
};

export default SettingsHeader;
