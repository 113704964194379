import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Filter: React.FC<{ width?: number }> = ({ width = 125 }) => (
  <div className="me-3 ">
    <Skeleton width={width} height={25} />
  </div>
);

const FiltersLoading: React.FC = () => (
  <div className="d-none d-md-flex justify-content-between">
    <div className="d-flex align-items-center">
      <Skeleton className="me-3 mt-2" width={50} height={15} />
      <Filter />
      <Filter />
      <Skeleton className="me-3" width={65} height={15} />
    </div>
    <div className="d-flex align-items-center">
      <Skeleton className="me-3 mt-2" width={50} height={15} />
      <Filter width={85} />
    </div>
  </div>
);

export default FiltersLoading;
