import React from 'react';
import { JobPageType } from '../types';
import { recruitmentRoutes } from '../../routes';
import Evaluations from '../../Evaluations';
import { useEvaluationsQuery } from '../../../__generated__/graphql';

type Props = {
  job: JobPageType;
};

const JobEvaluations: React.FC<Props> = ({ job }) => {
  const { data, loading, error } = useEvaluationsQuery({
    variables: {
      filter: {
        jobId: job.Id,
        evaluatorId: null,
        status: [],
        managerId: null,
      },
    },
  });

  return (
    <Evaluations
      data={data?.evaluations}
      route={recruitmentRoutes.jobEvaluations.route}
      storageKey="EVALUATIONS_LIST_FILTERS"
      jobId={job.Id}
      error={error}
      loading={loading}
      isManagerMode
    />
  );
};

export default JobEvaluations;
