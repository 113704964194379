import { ExecutionResult } from 'graphql';
import React, { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form } from '../../../../components/form/Form';
import { Input } from '../../../../components/form/Input';
import InputLabel from '../../../../components/form/InputLabel';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useLogin } from '../../../../hooks/useLogin';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { Link } from '../../../../Router';
import { routes } from '../../routes';
import { LoginMutation, useLoginMutation } from '../../__generated__/graphql';

type FormData = {
  email: string;
  password: string;
};

const defaultValues: FormData = {
  email: '',
  password: '',
};

const formSchema = yup.object().shape<FormData>({
  email: yup.string().trim().required().email(),
  password: yup.string().required().min(8),
});

export type LoginHistoryLocationState = {
  alertMessage?: 'reset-password' | 'forgot-password' | 'change-email';
};

const AlertMessage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<LoginHistoryLocationState>();

  switch (history.location.state?.alertMessage) {
    case 'reset-password': {
      return <Alert variant="info">{t('signIn.info.passwordReset')}</Alert>;
    }

    case 'forgot-password': {
      return <Alert variant="info">{t('signIn.info.forgotPassword')}</Alert>;
    }

    case 'change-email': {
      return <Alert variant="info">{t('signIn.info.changeEmail')}</Alert>;
    }

    default: {
      return <></>;
    }
  }
};

const LoginPasswordForm: React.FC = () => {
  const [loginErrors, setLoginErrors] = useState<boolean>(false);
  const login = useLogin();
  const [doLogin] = useLoginMutation();
  const errorHandler = useMutationErrorHandler();
  const { t } = useTranslation();

  const handleLogin = (result: ExecutionResult<LoginMutation>) => {
    if (result.errors || !result?.data?.login) {
      setLoginErrors(true);
      return;
    }

    login(result.data.login);
  };

  const onSubmit = async (values: FormData) => {
    setLoginErrors(false);
    await doLogin({ variables: values })
      .then((result) => handleLogin(result))
      .catch(errorHandler);
  };

  return (
    <>
      <h1 className="display-4 text-center mb-3">{t('signIn.title')}</h1>
      <p className="text-muted text-center mb-5">{t('signIn.subTitle')}</p>
      <AlertMessage />
      <Form
        onSubmit={onSubmit}
        validationSchema={formSchema}
        defaultValues={defaultValues}>
        <Input
          label={t('form.general.email.label')}
          placeholder={t('form.general.email.placeholder')}
          name="email"
          autoFocus
        />

        <Input
          placeholder={t('form.general.password.placeholder')}
          name="password"
          type="password"
          labelComponent={
            <Row>
              <Col>
                <InputLabel label={t('form.general.password.label')} />
              </Col>
              <Col className="col-auto">
                <Link
                  to={routes.forgotPassword.route}
                  className="form-text small text-muted"
                  tabIndex={-1}>
                  {t('signIn.forgotPasswordLink')}
                </Link>
              </Col>
            </Row>
          }
        />

        {loginErrors ? (
          <p className="text-danger">{t('signIn.invalid')}</p>
        ) : (
          <></>
        )}

        <SubmitButton block>{t('signIn.button')}</SubmitButton>
      </Form>
    </>
  );
};

export default LoginPasswordForm;
