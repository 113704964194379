import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { EmployeeDataFieldState } from '../../__generated__/graphql';
import { FieldItem } from './FieldItem';
import { EmployeeSection } from './types';

type Props = {
  section: EmployeeSection;
  editLink?: React.ReactNode;
};

export const Section: React.FC<Props> = ({ section, editLink }) => {
  const hasEditableFields = !!section.fields.find(
    (f) => f.state !== EmployeeDataFieldState.Readonly,
  );

  return (
    <Card>
      <Card.Body>
        <Row className="justify-content-between">
          <Col>
            <Card.Title as="h3">{section.label}</Card.Title>
          </Col>
          {hasEditableFields && editLink && (
            <Col className="col-auto">{editLink}</Col>
          )}
        </Row>

        <ListGroup variant="flush">
          {section.fields.map((field, i) => (
            <FieldItem field={field} key={`${field.fieldInfo.label}-${i}`} />
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
