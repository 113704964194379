import {
  RowType,
  TableRow,
  isTableDaySubRow,
  isTableRowTimeSheet,
} from './types';

export const getCellMarginClass = (rowType: RowType): string | null => {
  switch (rowType) {
    case 'header-employee':
    case 'header-week':
      return null;
    case 'row-employee':
    case 'row-period':
    case 'subrow-day':
    case 'subrow-day-withdraw':
    case 'subrow-day-loading':
    case 'subrow-day-loading-error':
      return 'ms-3';
  }
};

export const getTdClasses = (
  columnAccessor: string,
  { rowType }: TableRow,
): string[] => {
  if (rowType === 'header-employee' || rowType === 'header-week') {
    return [];
  }
  switch (columnAccessor) {
    case 'workedMinutes':
    case 'workedDifferenceMinutes':
      return ['border-color-paper-4', 'border-highlighted', 'highlighted'];
    default:
      return [];
  }
};

export const getTrClasses = (
  highlightIfHasComments: boolean,
  row: TableRow,
): string[] => {
  const classes = [];
  if (isTableRowTimeSheet(row)) {
    classes.push('row-highlighted');
  }
  if (
    highlightIfHasComments &&
    isTableDaySubRow(row) &&
    row.commentsCount > 0
  ) {
    classes.push('has-comments-highlighted');
  }
  return classes;
};
