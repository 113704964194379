import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CancelButton from '../../../../../components/form/CancelButton';
import { Form } from '../../../../../components/form/Form';
import { Input } from '../../../../../components/form/Input';
import { RadioGroup } from '../../../../../components/form/RadioGroup';
import SubmitButton from '../../../../../components/form/SubmitButton';
import { IdName } from '../common/types';
import { CreateUpdateFormData, skillValidationSchema } from './common';

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: (name: string, rateable: boolean) => void;
  allSkills: IdName[];
};

const defaultValues: CreateUpdateFormData = {
  rateable: 'rated',
  name: '',
};

export const CreateSkillDialog: React.FC<Props> = ({
  show,
  onClose,
  onSubmit,
  allSkills,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onClose}>
      <Form
        defaultValues={defaultValues}
        onSubmit={(form) => {
          return onSubmit(form.name, form.rateable === 'rated');
        }}
        validationSchema={skillValidationSchema(
          allSkills,
          t('skills.alreadyExists'),
        )}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`skills.createSkill.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(`skills.createSkill.description`)}</p>
          <RadioGroup
            itemsClassName={'d-flex'}
            itemClassName={'me-4'}
            name="rateable"
            items={[
              {
                name: 'yesNo',
                label: t('skills.createSkill.radio.yesNo.label'),
                hint: t('skills.createSkill.radio.yesNo.hint'),
              },
              {
                name: 'rated',
                label: t('skills.createSkill.radio.rated.label'),
                hint: t('skills.createSkill.radio.rated.hint'),
              },
            ]}
          />

          <Input
            label={t(`skills.createSkill.input.label`)}
            placeholder={t(`skills.createSkill.input.placeholder`)}
            name="name"
            autoFocus={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={onClose}>
            {t('skills.createSkill.cancel')}
          </CancelButton>
          <SubmitButton>{t('skills.createSkill.save')}</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
