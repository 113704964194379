import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MultiValue } from 'react-select';
import { defaultFilter, ProjectsTimeSheetsFilter } from './types';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { OptionBase } from '../../../../../../components/Select/types';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { TimeSheetStatus } from '../../../../__generated__/graphql';
import { isViewByType, viewByValues } from '../types';
import i18next from 'i18next';
import Checkbox from '../../../../components/Checkbox';

export type Props = {
  employeeOptions: EmployeeOption[];
  projectsOptions: OptionBase[];
  approvalStatusOptions: OptionBase[];
  filter: ProjectsTimeSheetsFilter;
  onChange: (filter: ProjectsTimeSheetsFilter) => void;
};

const i18Prefix = 'timeTracking.projects.controlling.table.filters';

export const ProjectsProjectsTimeSheetFilters: React.FC<Props> = ({
  filter,
  employeeOptions,
  projectsOptions,
  approvalStatusOptions,
  onChange,
}) => {
  const t = useNamespacedTranslation(i18Prefix);

  const handleEmployeeChanged = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onChange({
      ...filter,
      employeeIds: selectedOptions.map((x) => x.value),
    });
  };

  const handleProjectChanged = (
    selectedOptions: MultiValue<FilterSelectOption>,
  ) => {
    onChange({
      ...filter,
      projectsIds: selectedOptions.map((x) => x.value),
    });
  };

  const handleTimeSheetStatusChanged = (value: string | null) => {
    onChange({
      ...filter,
      status: (value as TimeSheetStatus) ?? null,
    });
  };

  const handleViewByChanged = (value: string | null) => {
    onChange({
      ...filter,
      viewBy: isViewByType(value) ? value : null,
    });
  };

  const handleOnlyWithCommentsChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...filter,
      onlyWithComments: e.target.checked ? 'true' : 'false',
    });
  };

  const handleClearFilter = () => {
    // do not clear viewBy
    return onChange({ ...defaultFilter, viewBy: filter.viewBy });
  };

  return (
    <Row className="justify-content-between">
      <Col md={8}>
        <Row className="align-items-center row-cols-auto g-2">
          <Col>
            <span className="text-nowrap">{t('filterBy')}</span>
          </Col>
          <Col>
            <FlairMultiSelectDropdown
              placeholder={t('employee')}
              options={employeeOptions}
              isSearchable={true}
              selectedOptions={filter.employeeIds}
              onChange={handleEmployeeChanged}
            />
          </Col>
          <Col>
            <FlairMultiSelectDropdown
              placeholder={t('project')}
              options={projectsOptions}
              isSearchable={true}
              selectedOptions={filter.projectsIds}
              onChange={handleProjectChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('timeSheetStatus')}
              options={approvalStatusOptions}
              value={filter.status}
              onChange={handleTimeSheetStatusChanged}
            />
          </Col>
          <Col>
            <Checkbox
              id="onlyWithComments"
              className="mt-2"
              labelClassName="align-middle"
              label={t('onlyWithComments')}
              checked={filter.onlyWithComments === 'true'}
              onChange={handleOnlyWithCommentsChanged}
            />
          </Col>

          <Col>
            <ClearFiltersLink onClick={handleClearFilter} />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="align-items-center justify-content-end row-cols-auto g-2">
          <Col>
            <span className="text-nowrap">{t('viewBy')}</span>
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('viewBy')}
              options={getViewByOptions()}
              value={filter.viewBy ?? 'week'}
              onChange={handleViewByChanged}
              isClearable={false}
              heightMode="normal"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

function getViewByOptions() {
  return viewByValues.map((value) => ({
    value,
    label: i18next.t(`${i18Prefix}.viewByOptions.${value}`),
  }));
}
