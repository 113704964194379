import { Theme } from '../../../../theme';
import {
  Certificate,
  certificateSF,
  CertificateStatus,
  ContentDocumentLink,
  DocumentFile,
} from './types';

export const mapCertificate = (cert: certificateSF): Certificate => ({
  id: cert.Id,
  name: cert.certificate?.Name ?? cert.Name,
  expirationDate: cert.flair__Expiration_Date__c
    ? new Date(cert.flair__Expiration_Date__c)
    : null,
  issueDate: cert.flair__Issue_Date__c
    ? new Date(cert.flair__Issue_Date__c)
    : null,
  plannedDate: cert.flair__Planned_Date__c
    ? new Date(cert.flair__Planned_Date__c)
    : null,
  issuedBy: cert.flair__Issued_By__c,
  certificateUrl: cert.flair__Certificate_Link__c,
  status: cert.flair__Status__c,
  certificateId: cert.certificate?.Id ?? null,
  certificateName: cert.certificate?.Name ?? cert.Name,
  documents: cert.ContentDocumentLinks
    ? cert.ContentDocumentLinks.map(mapDocumentFile)
    : [],
  recordId: cert.Id,
  commentsCount: cert.commentsCount,
});

export const getCertificateStatusColor = (
  status: CertificateStatus,
): string => {
  switch (status) {
    case 'Planned':
      return Theme.color.warning;
    case 'Expired':
      return Theme.color.danger;
    case 'Valid':
      return Theme.color.success;
    default:
      return Theme.color.paper3;
  }
};

export const mapDocumentFile = (link: ContentDocumentLink): DocumentFile => ({
  id: link.Id,
  fileName: link.ContentDocument.Title
    ? `${link.ContentDocument.Title}.${link.ContentDocument.FileExtension}`
    : link.ContentDocument.Title,
  downloadPublicUrl: link.downloadUrl,
});
