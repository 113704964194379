import styled from '@emotion/styled';
import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';
import { Theme } from '../../../../theme';
import {
  DuringBreak,
  Working,
} from '../../hooks/useCurrentTimeTrackingTimeline';
import CardHeaderDuringBreakStep from './CardHeaderDuringBreakStep';
import CardHeaderWorkingStep from './CardHeaderWorkingStep';

type Props = {
  step: Working | DuringBreak;
  notes: string | null;
  lastModifiedDate: string;
  accordionToggleKey: string;
  accordionOpen: boolean;
  accordionToggleOpen: () => void;
};

const Container = styled(BootstrapCard.Header)({
  backgroundColor: Theme.color.blue1,
});

const CardHeader: React.FC<Props> = ({
  step,
  notes,
  lastModifiedDate,
  accordionToggleKey,
  accordionOpen,
  accordionToggleOpen,
}) => (
  <Container>
    {step.kind === 'Working' ? (
      <CardHeaderWorkingStep
        step={step}
        notes={notes}
        lastModifiedDate={lastModifiedDate}
        accordionToggleKey={accordionToggleKey}
        accordionOpen={accordionOpen}
        accordionToggleOpen={accordionToggleOpen}
      />
    ) : (
      <CardHeaderDuringBreakStep
        step={step}
        accordionToggleKey={accordionToggleKey}
        accordionOpen={accordionOpen}
        accordionToggleOpen={accordionToggleOpen}
      />
    )}
  </Container>
);

export default CardHeader;
