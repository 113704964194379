import { MyEngagementSurveysQuery } from '../../../__generated__/graphql';
import { isAfter } from 'date-fns';

export type EmployeeEngagementSurvey =
  MyEngagementSurveysQuery['me']['engagementSurveys'][0];

export const isDeadlineOverSurvey = (survey: EmployeeEngagementSurvey) =>
  !!survey.deadline && isAfter(new Date(), new Date(survey.deadline));

export const isResponseSubmitted = (survey: EmployeeEngagementSurvey) =>
  !!survey.submittedAt;

export const isResponseDismissed = (survey: EmployeeEngagementSurvey) =>
  !!survey.dismissedAt;

export const isSurveyToBeAnswered = (survey: EmployeeEngagementSurvey) =>
  !isDeadlineOverSurvey(survey) &&
  !isResponseSubmitted(survey) &&
  !isResponseDismissed(survey);
