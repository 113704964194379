import * as React from 'react';
import { PeerReviewsSection } from '../../PeerReviewsSection/PeerReviewsSection';
import { AssignPeerModal } from '../../AssignPeerModal/AssignPeerModal';
import { useEffect, useState } from 'react';
import {
  useActiveEmployees_PeerReviewersLazyQuery,
  useCreatePeerReviewersMutation,
  useDeletePeerReviewersMutation,
} from '../../../../../__generated__/graphql';
import ServerError from '../../../../../../../components/ServerError';
import { PeerFeedback } from '../types';
import { mapAndFilterPeerOptions } from '../logic';
import { useUserInfo } from '../../../../../context/UserInfo';
import PeerReviewsSidePanel from './PeerReviewsSidePanel';
import { Maybe } from '../../../../../../../utils/maybe';

type Props = {
  maxPeers: number;
  outcomeId: string;
  peerFeedbacks: PeerFeedback[];
  currentFeedbackRequestId: string;
  revieweeId: string;
  isEditingEnabled: boolean;
};

const PeerReviews: React.FC<Props> = ({
  maxPeers,
  outcomeId,
  peerFeedbacks,
  currentFeedbackRequestId,
  revieweeId,
  isEditingEnabled,
}) => {
  const { id: currentEmployeeId } = useUserInfo();
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [selectedFeedbackPeer, setSelectedFeedbackPeer] =
    useState<Maybe<string>>(null);
  const [isSidePanelVisible, setIsSidePanelVisible] = useState<boolean>(false);

  const [getActiveEmployees, { data, error: errorActiveEmployees }] =
    useActiveEmployees_PeerReviewersLazyQuery();

  const [
    createPeerReviewers,
    { loading: loadingCreatePeer, error: errorCreatePeer },
  ] = useCreatePeerReviewersMutation();

  const [
    deletePeerReviewers,
    { loading: loadingDeletePeer, error: errorDeletePeer },
  ] = useDeletePeerReviewersMutation();

  useEffect(() => {
    getActiveEmployees();
  }, [getActiveEmployees]);

  const isLoadingOperations = loadingCreatePeer || loadingDeletePeer;

  if (errorCreatePeer || errorDeletePeer || errorActiveEmployees) {
    return <ServerError />;
  }

  const assignedPeerReviewersIds = peerFeedbacks.map((p) => p.reviewer.id);

  const excludedPeerReviewersIds = [
    currentEmployeeId,
    ...assignedPeerReviewersIds,
    revieweeId,
  ];

  const defaultPeerOptions = mapAndFilterPeerOptions(
    data?.activeEmployees ?? [],
    excludedPeerReviewersIds,
  );

  const handleAssignPeerReviewer = (employeeId: string) => {
    createPeerReviewers({
      variables: {
        input: {
          peerReviewerIds: [employeeId],
          outcomeId,
          currentFeedbackRequestId,
        },
      },
    });
  };

  const handleDeletePeerReviewer = (
    feedbackRequestId: string,
    callback?: () => void,
  ) => {
    deletePeerReviewers({
      variables: {
        input: {
          currentFeedbackRequestId,
          feedbackRequestIds: [feedbackRequestId],
        },
      },
    }).finally(callback);
  };

  const handleLoadOptions = (searchTerm: string) => {
    return getActiveEmployees({ variables: { searchTerm } }).then((result) => {
      if (result.error) {
        throw new Error(result.error.message);
      }

      if (!result.data) {
        throw new Error('no data');
      }

      return mapAndFilterPeerOptions(
        result.data.activeEmployees,
        excludedPeerReviewersIds,
      );
    });
  };

  const handleOnTitleClick = () => {
    setSelectedFeedbackPeer('ALL');
    setIsSidePanelVisible(true);
  };

  const handleOnPeerClick = (peerFeedbackId: string) => {
    setSelectedFeedbackPeer(peerFeedbackId);
    setIsSidePanelVisible(true);
  };

  const handleCloseSidePanel = () => {
    setSelectedFeedbackPeer(null);
    setIsSidePanelVisible(false);
  };

  return (
    <>
      <PeerReviewsSection
        maxPeers={maxPeers}
        openAssignPeersModal={() => setShowAssignModal(true)}
        peerFeedbacks={peerFeedbacks}
        canEdit={isEditingEnabled}
        onPeerClick={handleOnPeerClick}
        onTitleClick={handleOnTitleClick}
        onRemovePeer={handleDeletePeerReviewer}
        isLoading={isLoadingOperations}
      />

      <AssignPeerModal
        visible={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        onAssign={handleAssignPeerReviewer}
        onRemove={handleDeletePeerReviewer}
        maxPeers={maxPeers}
        peerOptions={defaultPeerOptions}
        peerFeedbacks={peerFeedbacks}
        loadingOperations={isLoadingOperations}
        onLoadOptions={handleLoadOptions}
      />

      {isSidePanelVisible && (
        <PeerReviewsSidePanel
          visible={isSidePanelVisible}
          handleClose={handleCloseSidePanel}
          peerFeedbacks={peerFeedbacks}
          defaultSelectedPeerFeedbackId={selectedFeedbackPeer}
        />
      )}
    </>
  );
};

export default PeerReviews;
