import React from 'react';
import GlobalTableFilter from '../../../components/GlobalTableFilter';

type Props = {
  onFilterChange: (value: string) => void;
  placeholder: string;
};

export const ManageSkillsFilter: React.FC<Props> = ({
  onFilterChange,
  placeholder,
}) => {
  return (
    <GlobalTableFilter
      onFilterChange={onFilterChange}
      placeholder={placeholder}
    />
  );
};
