import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { EmployeeWorkload } from '../../../../__generated__/graphql';
import WorkingDays from './WorkingDays';
import Data from './Data';
import { useTranslation } from 'react-i18next';

export type Workload = Pick<
  EmployeeWorkload,
  | 'Name'
  | 'flair__Hours__c'
  | 'flair__Hours_Per_Day__c'
  | 'flair__Working_Days__c'
  | 'flair__Start_Date__c'
  | 'flair__End_Date__c'
  | 'flair__Type__c'
>;

type Props = {
  workload: Workload;
};

const WorkloadCard: React.FC<Props> = ({ workload }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3">
          {t('employeePage.overview.workloadCard.title')}
        </Card.Title>
        <Row className="py-3">
          <Col>
            <WorkingDays workingDays={workload.flair__Working_Days__c} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Data workload={workload} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default WorkloadCard;
