import React from 'react';

import { Route as _Route, RouteParams as _RouteParams } from './utils/router';
import {
  Redirect as ReactRedirect,
  Link as ReactLink,
  NavLink as ReactNavLink,
  NavLinkProps as ReactNavLinkProps,
  LinkProps,
} from 'react-router-dom';

type RouteProps<T> = T extends { to: infer X }
  ? X extends _Route<any>
    ? T & _RouteParams<X>
    : never
  : never;

export const Redirect = <T extends { to: _Route<any> }>({
  to,
  ...props
}: RouteProps<T>): JSX.Element => <ReactRedirect to={to.create(props)} />;

export const Link = <T extends { to: _Route<any> }>({
  to,
  className,
  children,
  target,
  tabIndex,
  ...props
}: RouteProps<T> & Pick<LinkProps<T>, 'tabIndex'>): JSX.Element => (
  <ReactLink
    to={to.create(props)}
    className={className}
    children={children}
    target={target}
    tabIndex={tabIndex}
  />
);

export const NavLink = <T extends { to: _Route<any> }>({
  to,
  className,
  activeClassName,
  exact,
  isActive,
  activeStyle,
  children,
  ...props
}: RouteProps<T> & Omit<ReactNavLinkProps, 'to'>): JSX.Element => {
  const reactNavLinkProps = {
    to: to.create(props),
    className,
    activeClassName,
    exact,
    isActive,
    activeStyle,
    children,
  };

  return <ReactNavLink {...reactNavLinkProps} />;
};
