import dagre from 'dagre';
import { Node, Edge, getOutgoers } from 'react-flow-renderer';
import { EmployeeDetail, OrgChartEmployee } from './types';
import i18next from 'i18next';
import { Theme } from '../../../../theme';

export const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 145;
const nodeHeight = 100;
const nodePosition = { x: 0, y: 0 };

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction = 'TB',
) => {
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export const getNodesAndEdges = (
  employees: OrgChartEmployee[],
  selectedSettings: string[],
) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];
  employees.forEach((e) => {
    nodes.push({
      id: e.id,
      position: nodePosition,
      type: 'employeeNode',
      data: { employee: e, selectedSettings },
    });

    if (e.manager?.id) {
      edges.push({
        id: `e${e.manager.id}-${e.id}`,
        source: e.manager.id,
        target: e.id,
        type: 'smoothstep',
        style: {
          stroke: Theme.color.paper1,
        },
      });
    }
  });

  return getLayoutedElements(nodes, edges);
};

const i18Path = 'people.orgChart';
export const getEmployeeDetailsLabel = (detail: EmployeeDetail) =>
  i18next.t(`${i18Path}.${detail}`);

export const getChildrenIds = (
  node: Node,
  nodes: Node[],
  edges: Edge[],
  childrenIds: string[],
): string[] => {
  const children = getOutgoers(node, nodes, edges);
  childrenIds = childrenIds.concat(children.map((o) => o.id));
  children.forEach(
    (n) => (childrenIds = getChildrenIds(n, nodes, edges, childrenIds)),
  );
  return childrenIds;
};
