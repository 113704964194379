import styled from '@emotion/styled';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AutoSaveIndicator } from '../../../../../components/form/AutoSaveIndicator';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { useToasts } from '../../../../../context/Toast';
import { Theme } from '../../../../../theme';
import { FILE_SIZE_LIMIT, toBase64 } from '../../../../../utils/file';
import { WorkflowItemFileFormat } from '../../../__generated__/graphql';
import { mapWorkflowItemFileFormatToFileSpecifier } from './mappings';
import { FileOnServer } from './types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { fileSizeFormatter } from '../../../../../utils/file';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import LightSectionContainer from './LightSectionContainer';

export type FileInput = {
  fileName: string;
  fileContentBase64: string;
};

type Props = {
  fileFormat: WorkflowItemFileFormat;
  file: FileOnServer | null;
  onSelectFile: (fileInput: FileInput) => void;
  onDeleteFile: (fileId: string) => void;
  readonly: boolean;
  saving: boolean;
  saved: boolean;
};

export const WorkflowItemFileField: React.FC<Props> = ({
  file,
  fileFormat,
  saving,
  saved,
  readonly,
  onSelectFile,
  onDeleteFile,
}) => {
  const { t } = useTranslation();
  const tSidebar = useNamespacedTranslation(
    'performanceReview.workflowsItems.sidebar',
  );
  const { addError } = useToasts();

  const fileInputAcceptType =
    mapWorkflowItemFileFormatToFileSpecifier(fileFormat);

  const sizeLimit = fileSizeFormatter(FILE_SIZE_LIMIT);

  const selectFileLabel = fileInputAcceptType
    ? tSidebar('selectFileWithFormatAndSize', {
        fileFormat: t(
          `performanceReview.workflowsItems.fileFormats.${fileFormat}`,
        ),
        size: sizeLimit,
      })
    : tSidebar('selectFileWithSize', {
        size: sizeLimit,
      });

  const handleFileSelect = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (saving) {
      target.value = '';
      return;
    }
    if (target.validity.valid && target.files) {
      const [file] = Array.from(target.files);

      if (file.size > FILE_SIZE_LIMIT) {
        addError(t('documents.upload.sizeLimitError'));
        target.value = '';
      } else {
        getFileInputToUpload(file).then(onSelectFile);
      }
    }
  };

  const handleDelete = () => {
    if (saving || !file) {
      return;
    }
    onDeleteFile(file.id);
  };

  if (readonly) {
    return (
      <FormGroup>
        <Form.Label>{tSidebar('file')}:</Form.Label>
        <div className="d-flex flex-row">
          <span>{file ? file.name : tSidebar('notUploadedYet')}</span>
        </div>
      </FormGroup>
    );
  }

  return (
    <LightSectionContainer>
      <h4 className="mb-4">{tSidebar('uploadFileToComplete')}</h4>
      <FormGroup>
        <Form.Label>
          {selectFileLabel}
          <div className="d-inline-block ms-2">
            <AutoSaveIndicator loading={saving} completed={saved} />
          </div>
        </Form.Label>
        {file === null ? (
          <Form.Control
            id="file"
            type="file"
            accept={fileInputAcceptType}
            onChange={handleFileSelect}
          />
        ) : (
          <div className="d-flex flex-row">
            <span>{file.name}</span>
            <DeleteButton onClick={handleDelete}>
              <FlairIcon icon="trash-outline" />
            </DeleteButton>
          </div>
        )}
      </FormGroup>
      <small>{tSidebar('informationAutomaticallySaved')}</small>
    </LightSectionContainer>
  );
};

const getFileInputToUpload = (file: File): Promise<FileInput> => {
  return toBase64(file).then((fileContentBase64) => ({
    fileName: file.name,
    fileContentBase64,
  }));
};

const DeleteButton = styled.div({
  cursor: 'pointer',
  color: Theme.color.gray3,
  transition: 'color 0.15s',
  marginLeft: '0.75rem',
  ':hover': {
    color: Theme.color.gray2,
  },
});
