import {
  NotificationItem,
  NotificationItemData,
  NotificationQueryItem,
} from '../types';
import { mapAbsenceNotificationRenderInfo } from './AbsenceNotificationRenderInfoMapping';
import { mapAbsenceApprovalRequestNotificationRenderInfo } from './AbsenceApprovalRequestNotificationRenderInfoMapping';
import { mapCommentNotificationRenderInfo } from './CommentNotificationRenderInfoMapping';
import { mapCelebrationNotificationRenderInfo } from './CelebrationNotificationRenderInfoMapping';
import { mapCandidateEvaluationNotificationRenderInfo } from './CandidateEvaluationNotificationRenderInfoMapping';
import { mapWorkflowItemNotificationRenderInfo } from './WorkflowItemNotificationRenderInfoMapping';
import { mapTimeEntryChangeRequestNotificationRenderInfo } from './TimeEntryChangeRequestNotificationRenderInfoMapping';
import {
  ArchivedNotificationsQuery,
  InboxNotificationsQuery,
  SnoozedNotificationsQuery,
  TaskNotificationsQuery,
} from '../../../__generated__/graphql';
import { mapTicketClosedNotificationRenderInfo } from './Ticket/TicketClosedNotificationRenderInfoMapping';
import { mapTicketSharedNotificationRenderInfo } from './Ticket/TicketSharedNotificationRenderInfoMapping';
import { mapTicketStatusChangedNotificationRenderInfo } from './Ticket/TicketStatusChangedNotificationRenderInfoMapping';
import { mapTicketSubmittedNotificationRenderInfo } from './Ticket/TicketSubmittedNotificationRenderInfoMapping';
import { mapTicketManagerClosedNotificationRenderInfo } from './Ticket/TicketManagerClosedNotificationRenderInfoMapping';
import { mapTicketManagerStatusChangedNotificationRenderInfo } from './Ticket/TicketManagerStatusChangedNotificationRenderInfoMapping';
import { mapTimeBalanceWithdrawalNotificationRenderInfo } from './TimeBalance/TimeBalanceWithdrawalNotificationRenderInfoMapping';
import { mapTimeLimitTimeEntryNotificationRenderInfo } from './TimeLimitTimeEntryNotificationRenderInfoMapping';

const DEFAULT_DATA: NotificationItemData = {
  comment: '',
  urlLabel: '',
  url: '',
  description: '',
};

const mapRenderInfoByTypename = (
  currentUserId: string,
  src: NotificationQueryItem,
): NotificationItemData | null => {
  switch (src.renderInfo.__typename) {
    case 'AbsenceNotificationRenderInfo':
      return mapAbsenceNotificationRenderInfo(src);
    case 'AbsenceApprovalRequestNotificationRenderInfo':
      return mapAbsenceApprovalRequestNotificationRenderInfo(src);
    case 'CommentNotificationRenderInfo':
      return mapCommentNotificationRenderInfo(currentUserId, src);
    case 'CelebrationNotificationRenderInfo':
      return mapCelebrationNotificationRenderInfo(src);
    case 'CandidateEvaluationNotificationRenderInfo':
      return mapCandidateEvaluationNotificationRenderInfo(src);
    case 'TimeEntryChangeRequestNotificationRenderInfo':
      return mapTimeEntryChangeRequestNotificationRenderInfo(src);
    case 'TimeLimitTimeEntryNotificationRenderInfo':
      return mapTimeLimitTimeEntryNotificationRenderInfo(src);
    case 'WorkflowItemNotificationRenderInfo':
      return mapWorkflowItemNotificationRenderInfo(src);
    case 'TicketClosedNotificationRenderInfo':
      return mapTicketClosedNotificationRenderInfo(src);
    case 'TicketSharedNotificationRenderInfo':
      return mapTicketSharedNotificationRenderInfo(src);
    case 'TicketStatusChangedNotificationRenderInfo':
      return mapTicketStatusChangedNotificationRenderInfo(src);
    case 'TicketSubmittedNotificationRenderInfo':
      return mapTicketSubmittedNotificationRenderInfo(src);
    case 'TicketManagerClosedNotificationRenderInfo':
      return mapTicketManagerClosedNotificationRenderInfo(src);
    case 'TicketManagerStatusChangedNotificationRenderInfo':
      return mapTicketManagerStatusChangedNotificationRenderInfo(src);
    case 'TimeBalanceWithdrawalNotificationRenderInfo':
      return mapTimeBalanceWithdrawalNotificationRenderInfo(src);
    default:
      return { ...DEFAULT_DATA, isUnsupportedNotificationType: true };
  }
};

const mapRenderInfo = (
  currentUserId: string,
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const mappedData = mapRenderInfoByTypename(currentUserId, src);
  return mappedData !== null ? { ...DEFAULT_DATA, ...mappedData } : null;
};

const mapNotification = (
  currentUserId: string,
  src: NotificationQueryItem,
): NotificationItem | null => {
  const data = mapRenderInfo(currentUserId, src);
  const recordId = src.flair__Record_Id__c;
  const objectType = src.flair__Object_Type__c;
  const type = src.flair__Type__c;
  if (
    data !== null &&
    recordId !== null &&
    objectType !== null &&
    type !== null
  ) {
    return {
      id: src.Id,
      createdDate: new Date(src.CreatedDate),
      isRead: src.flair__Is_Read__c,
      readAt: src.flair__Read_At__c ? src.flair__Read_At__c : null,
      isArchived: src.flair__Is_Archived__c,
      snoozedAt: src.flair__Snoozed_At__c
        ? new Date(src.flair__Snoozed_At__c)
        : null,
      unsnoozeAt: src.flair__Unsnooze_At__c
        ? new Date(src.flair__Unsnooze_At__c)
        : null,
      employeeId: src.flair__Employee__c,
      type,
      recordId,
      objectType,
      data,
    };
  } else {
    return null;
  }
};

const isNotificationSupported = (notification: NotificationItem): boolean =>
  notification.data !== null &&
  notification.recordId !== null &&
  notification.objectType !== null &&
  !notification.data.isUnsupportedNotificationType;

type InboxNotificationsQueryItems =
  InboxNotificationsQuery['me']['inboxNotifications']['items'];
type TaskNotificationsQueryItems =
  TaskNotificationsQuery['me']['taskNotifications']['items'];
type SnoozedNotificationsQueryItems =
  SnoozedNotificationsQuery['me']['snoozedNotifications']['items'];
type ArchivedNotificationsQueryItems =
  ArchivedNotificationsQuery['me']['archivedNotifications']['items'];

type NotificationsQueryItems =
  | InboxNotificationsQueryItems
  | TaskNotificationsQueryItems
  | SnoozedNotificationsQueryItems
  | ArchivedNotificationsQueryItems;

export const mapNotifications = (
  currentUserId: string,
  notificationQueryItems: NotificationsQueryItems,
): NotificationItem[] =>
  notificationQueryItems
    .map((x) => mapNotification(currentUserId, x))
    .filter((notification): notification is NotificationItem => !!notification)
    .filter(isNotificationSupported); //Skip notifications that we don't know how to render;
