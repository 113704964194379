import { MutationResult } from '@apollo/client';
import { useCallback } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import {
  createOptimisticId,
  readMyTimeEntryTimeTrackingFromCache,
} from '../../utils/apolloCacheHelpers';
import {
  MyTimeEntryBreakFragment,
  MyTimeEntryTimeTrackingFragment,
  PauseMyTimeEntryMutation,
  usePauseMyTimeEntryMutation,
} from '../../__generated__/graphql';
import { useApolloCache } from '../useApolloCache';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';

type Result = [() => Promise<void>, MutationResult<PauseMyTimeEntryMutation>];

export const usePauseTimeTracking = (timeEntryId: string): Result => {
  const cache = useApolloCache();
  const [pauseTimeEntry, mutationResult] = usePauseMyTimeEntryMutation({
    optimisticResponse: () => {
      const timeEntryFromCache = readMyTimeEntryTimeTrackingFromCache(
        cache,
        timeEntryId,
      )!;

      const updatedTimeEntry = addNewBreak(timeEntryFromCache);
      return {
        timeTracking: {
          pauseMyTimeEntry: {
            recordId: timeEntryId,
            record: {
              ...updatedTimeEntry,
              // will be overriden with real response soon
              changeRequest: null,
            },
            me: null,
            errors2: [],
          },
        },
      };
    },
  });

  const errorHandler = useMutationErrorHandler();

  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const handleOnPause = useCallback(() => {
    return pauseTimeEntry({
      variables: { id: timeEntryId },
    })
      .then((response) => {
        const errors = response.data?.timeTracking.pauseMyTimeEntry.errors2;
        showErrorsAndWarningsToasts({ errors });
      })
      .catch(errorHandler);
  }, [pauseTimeEntry, timeEntryId, errorHandler, showErrorsAndWarningsToasts]);

  return [handleOnPause, mutationResult];
};

const addNewBreak = (
  timeEntry: MyTimeEntryTimeTrackingFragment,
): MyTimeEntryTimeTrackingFragment => {
  const newBreak: MyTimeEntryBreakFragment = {
    __typename: 'MyTimeEntryBreak',
    Id: createOptimisticId(),
    flair__Start_Datetime__c: new Date().toISOString(),
    flair__End_Datetime__c: null,
    flair__Total_Break_Period_in_Minutes__c: 0,
    flair__Auto_adjusted_Datetime__c: null,
    LastModifiedDate: new Date().toISOString(),
  };
  return {
    ...timeEntry,
    breaks: [...timeEntry.breaks, newBreak],
    LastModifiedDate: new Date().toISOString(),
  };
};
