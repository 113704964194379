import * as React from 'react';
import { useRef, useState } from 'react';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import {
  useInboxNotificationsUnreadCountQuery,
  useTaskNotificationsUnreadCountQuery,
} from '../../../__generated__/graphql';
import { useMarkAllInboxNotificationsAsRead } from '../../Notifications/NotificationsConnected/useMarkAllInboxNotificationsAsRead';
import { useMarkAllTaskNotificationsAsRead } from '../../Notifications/NotificationsConnected/useMarkAllTaskNotificationsAsRead';
import { Overlay, Spinner } from 'react-bootstrap';
import { clickedInElementWithClassOrItsChild } from '../../Notifications/utils';
import NotificationsContent from '../../Notifications/NotificationsContent';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import './Notifications.css';
import FlairButtonIcon from '../../../../../atomic/molecules/FlairButtonIcon';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

//TODO:: remove old notifications which is used in old sidemenu after releasing the new dashboard
const Notifications: React.FC = () => {
  const t = useNamespacedTranslation('dashboard');
  const [notificationsVisible, setNotificationsVisible] =
    useState<boolean>(false);
  const errorHandler = useMutationErrorHandler();
  const [tasksViewed, setTasksViewed] = useState<boolean>(false);

  const target = useRef(null);
  const { data, loading, error } = useInboxNotificationsUnreadCountQuery();
  const { data: taskData } = useTaskNotificationsUnreadCountQuery();

  const [markAllInboxNotificationsAsRead] =
    useMarkAllInboxNotificationsAsRead();
  const [markAllTaskNotificationsAsRead] = useMarkAllTaskNotificationsAsRead();

  const unreadInboxNotificationCount =
    data?.me.inboxNotifications.unreadCount ?? 0;
  const unreadTaskNotificationCount =
    taskData?.me.taskNotifications.unreadCount ?? 0;
  const unreadNotificationCount =
    unreadInboxNotificationCount + unreadTaskNotificationCount;

  const notificationOverlay = (props: OverlayInjectedProps) => (
    <div
      {...props}
      className="position-absolute notification-overlay"
      style={props.style}>
      <NotificationsContent
        inboxUnreadCount={unreadInboxNotificationCount}
        onTasksViewed={setTasksViewed}
        onClose={() => setNotificationsVisible(false)}
      />
    </div>
  );

  if (error) return <></>;

  const notificationIcon =
    unreadNotificationCount > 0
      ? 'notification-new-outline-icon'
      : 'notification-outline-icon';

  const handleOnNotificationToggle = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  const handleOnHideNotificationsOverlay = (e: Event) => {
    // Sometimes Overlay is hidden by clicking on elements inside it (e.g. another popover or Flatpickr)
    const clickedInSnoozePopover = clickedInElementWithClassOrItsChild(
      e.target,
      'snooze-popover',
    );
    const clickedInFlatpickr = clickedInElementWithClassOrItsChild(
      e.target,
      'flatpickr-calendar',
    );
    if (!clickedInSnoozePopover && !clickedInFlatpickr) {
      setNotificationsVisible(false);
      if (unreadInboxNotificationCount > 0) {
        markAllInboxNotificationsAsRead().catch(errorHandler);
      }
      if (tasksViewed && unreadTaskNotificationCount > 0) {
        markAllTaskNotificationsAsRead().catch(errorHandler);
      }
    }
  };

  if (loading) {
    return <Spinner animation="border" role="status" size="sm" />;
  }

  return (
    <>
      <FlairButtonIcon
        ref={target}
        icon={notificationIcon}
        onClick={handleOnNotificationToggle}
        size="xl"
        dataTestId="notifications"
        isActive={notificationsVisible}
        tooltip={t('notifications')}
        tooltipPlacement="bottom"
        variant="text"
      />
      <Overlay
        target={target.current}
        show={notificationsVisible}
        rootClose={true}
        onHide={handleOnHideNotificationsOverlay}
        placement="bottom-start">
        {notificationOverlay}
      </Overlay>
    </>
  );
};

export default Notifications;
