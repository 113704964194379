import * as React from 'react';

const FrownOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M5.74862 8.00114C6.16369 8.00114 6.50017 7.66466 6.50017 7.24959C6.50017 6.83453 6.16369 6.49805 5.74862 6.49805C5.33355 6.49805 4.99707 6.83453 4.99707 7.24959C4.99707 7.66466 5.33355 8.00114 5.74862 8.00114Z"
        fill="currentColor"
      />
      <path
        d="M5.68652 11.4966C5.9787 10.6311 6.90189 9.99694 7.99957 9.99694C9.09724 9.99694 10.0207 10.6311 10.3104 11.4966"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
      />
      <path
        d="M10.2496 8.00114C10.6647 8.00114 11.0011 7.66466 11.0011 7.24959C11.0011 6.83453 10.6647 6.49805 10.2496 6.49805C9.83453 6.49805 9.49805 6.83453 9.49805 7.24959C9.49805 7.66466 9.83453 8.00114 10.2496 8.00114Z"
        fill="currentColor"
      />
      <path
        d="M7.99944 14.496C11.5884 14.496 14.4979 11.5865 14.4979 7.99749C14.4979 4.40849 11.5884 1.49902 7.99944 1.49902C4.41044 1.49902 1.50098 4.40849 1.50098 7.99749C1.50098 11.5865 4.41044 14.496 7.99944 14.496Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default FrownOutlineIcon;
