import { useMemo } from 'react';
import { EmployeeField } from '../types';
import { FormData } from './types';
import { FieldType } from '../../../__generated__/graphql';
import { parseISO } from 'date-fns';

export const defaultValue = (type: FieldType, value: string | null) => {
  switch (type) {
    case FieldType.Boolean: {
      return value ? value === 'true' : false;
    }
    case FieldType.Time: {
      return value ? value.replace('Z', '') : '';
    }
    case FieldType.Date:
    case FieldType.Datetime: {
      return value ? parseISO(value) : null;
    }
    case FieldType.Multipicklist: {
      return value ? value.split(';') : [];
    }
    default: {
      return value ?? '';
    }
  }
};

export const useDefaultValues = (fields: EmployeeField[]) =>
  useMemo(
    () =>
      fields.reduce((result, field) => {
        result[field.fieldInfo.name] = defaultValue(
          field.fieldInfo.type,
          field.value,
        );
        return result;
      }, {} as FormData),
    [fields],
  );
