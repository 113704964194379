import * as React from 'react';

const LightSectionContainer: React.FC = ({ children }) => {
  return (
    <div className="full-width-section bg-light-soft px-4 border py-4">
      {children}
    </div>
  );
};

export default LightSectionContainer;
