import * as React from 'react';
import FlairIcon, { FlairIconName } from '../FlairIcon';
import './IconAvatar.css';

export type IconAvatarProps = {
  title?: string;
  subTitle?: string;
  iconName: FlairIconName;
};

const IconAvatar: React.FC<IconAvatarProps> = ({
  title,
  subTitle,
  iconName,
}) => (
  <div className="d-flex align-items-center">
    <div className="icon-avatar-container d-flex-all-center me-2-5">
      <FlairIcon icon={iconName} size="xl" />
    </div>
    <div>
      <div className="h3-medium mb-0">{title}</div>
      <div className="h5-regular text-secondary">{subTitle}</div>
    </div>
  </div>
);
export default IconAvatar;
