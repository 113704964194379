import * as React from 'react';

const SearchFilledNewIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M15.0565 13.8145L11.7486 10.5066C12.545 9.44645 12.9749 8.15598 12.9735 6.83C12.9735 3.44305 10.2179 0.6875 6.83098 0.6875C3.44402 0.6875 0.688477 3.44305 0.688477 6.83C0.688477 10.217 3.44402 12.9725 6.83098 12.9725C8.15696 12.9739 9.44743 12.544 10.5076 11.7477L13.8155 15.0555C13.9829 15.2052 14.2013 15.2851 14.4258 15.2788C14.6503 15.2725 14.8639 15.1805 15.0227 15.0217C15.1815 14.8629 15.2735 14.6493 15.2798 14.4248C15.2861 14.2003 15.2062 13.9819 15.0565 13.8145ZM2.44348 6.83C2.44348 5.96223 2.7008 5.11396 3.1829 4.39244C3.66501 3.67091 4.35024 3.10856 5.15195 2.77648C5.95366 2.4444 6.83584 2.35751 7.68693 2.5268C8.53803 2.6961 9.3198 3.11397 9.93341 3.72757C10.547 4.34117 10.9649 5.12295 11.1342 5.97404C11.3035 6.82513 11.2166 7.70731 10.8845 8.50902C10.5524 9.31073 9.99006 9.99597 9.26854 10.4781C8.54702 10.9602 7.69874 11.2175 6.83098 11.2175C5.66777 11.2161 4.5526 10.7534 3.73009 9.93089C2.90757 9.10838 2.44487 7.99321 2.44348 6.83Z"
        fill="#8D8C7D"
      />
    </svg>
  );
});

export default SearchFilledNewIcon;
