import { InventoryFilter } from './Inventory/Filters/types';

export const i18MyDataPrefix = 'myData';

export const emptyInventoryFilter: InventoryFilter = {
  category: null,
  location: null,
  status: null,
  month: null,
  year: null,
  searchTerm: null,
};

export const sidebarTabKeys = {
  INFORMATION: 'information',
  SUPPORT: 'support',
  COMMENTS: 'comments',
};
