import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalSidebar from '../../../../components/ModalSidebar';
import { useUserInfo } from '../../../../context/UserInfo';
import { useIsManagerMode } from '../../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../../__generated__/graphql';
import { GoalFilter } from '../../Goals2/GoalsFilter/GoalsFilter';
import { GoalsDropdownActionsConnected } from '../../Goals2/GoalSidebar/GoalDropdownActionsConnected';
import { GoalSidebarCreateContent } from '../../Goals2/GoalSidebar/GoalSidebarCreateContent';
import { GoalSidebarUpdateContent } from '../../Goals2/GoalSidebar/GoalSidebarUpdateContent';
import { getHeaderText } from '../../Goals2/GoalSidebar/mappings';
import { GoalsListWithFilter } from '../../Goals2/GoalsList/GoalsListWithFilter';
import { canUpdateGoal } from '../../Goals2/logic';
import { GoalId } from '../../Goals2/types';
import { getDefaultFilter } from './logic';

type Props = {
  visible: boolean;
  onClose: () => void;
  employeeName: string;
  employeeId: string;
};

const i18prefix = 'performanceReview.goals2';

export const ReviewGoalsSidebar: React.FC<Props> = ({
  visible,
  onClose,
  employeeName,
  employeeId,
}) => {
  const [selectedGoalId, setSelectedGoalId] = useState<
    GoalId | null | 'createGoal'
  >(null);

  const { id: currentEmployeeId } = useUserInfo();
  const { hasManagerRole } = useIsManagerMode();
  const isDirectManager = hasManagerRole(ManagerRole.DirectManager);

  const [listFilter, setListFilter] = useState<GoalFilter>(
    getDefaultFilter(isDirectManager, true, currentEmployeeId, employeeId),
  );

  const { t } = useTranslation();

  const header = useMemo(() => {
    if (selectedGoalId === null) {
      return t(`${i18prefix}.goalSidebar.listHeader`, { name: employeeName });
    } else if (selectedGoalId === 'createGoal') {
      return getHeaderText('create');
    } else {
      return getHeaderText('view');
    }
  }, [selectedGoalId, employeeName, t]);

  const handleCreateGoal = useCallback(() => {
    setSelectedGoalId('createGoal');
  }, [setSelectedGoalId]);

  const handleGoalClick = useCallback(
    (goalId: GoalId) => {
      setSelectedGoalId(goalId);
    },
    [setSelectedGoalId],
  );

  const handleBackButtonClick = useCallback(() => {
    setSelectedGoalId(null);
  }, [setSelectedGoalId]);

  const handleClose = useCallback(() => {
    setSelectedGoalId(null);
    onClose();
  }, [setSelectedGoalId, onClose]);

  const getContent = () => {
    if (selectedGoalId === null) {
      const isManagerView = isDirectManager && currentEmployeeId !== employeeId;

      return (
        <ListContainer className="p-2">
          <GoalsListWithFilter
            isReviewGoals={true}
            isManagerView={isManagerView}
            filter={listFilter}
            onFilterChange={setListFilter}
            onCreateGoal={handleCreateGoal}
            onGoalClick={handleGoalClick}
          />
        </ListContainer>
      );
    } else if (selectedGoalId !== 'createGoal') {
      return (
        <GoalSidebarUpdateContent
          goalId={selectedGoalId}
          mode={
            canUpdateGoal(isDirectManager, selectedGoalId.type)
              ? 'update'
              : 'view'
          }
          onClose={handleClose}
        />
      );
    } else if (selectedGoalId === 'createGoal') {
      return (
        <GoalSidebarCreateContent
          employeeId={employeeId}
          onClose={handleClose}
        />
      );
    }
    return null;
  };

  const getHeaderActions = () => {
    if (selectedGoalId === null) {
      return null;
    } else if (selectedGoalId !== 'createGoal') {
      return (
        <GoalsDropdownActionsConnected
          goalId={selectedGoalId}
          onComplete={onClose}
        />
      );
    }
    return null;
  };

  return (
    <ModalSidebar
      show={visible}
      onClose={onClose}
      size={selectedGoalId === null ? 'xl' : undefined}
      header={header}
      content={getContent()}
      headerActions={getHeaderActions()}
      backButton={selectedGoalId !== null}
      onBackButtonClick={handleBackButtonClick}
    />
  );
};

const ListContainer = styled('div')({
  overflowY: 'auto',
  overflowX: 'clip',
});
