import React from 'react';
import { DayChanges } from '../../Tables/types';
import { ValueChangeRow } from './ValueChangeRow';
import { formatTime24h } from '../../../../../../utils/dateUtils';
import { useTranslation } from 'react-i18next';
import { formattedDuration } from '../../../../components/DurationFormat';
import CancelButton from '../../../../pages/TimeTracking/TimeTrackingEntries/components/CancelButton';
import SubmitButton from '../../../../pages/TimeTracking/TimeTrackingEntries/components/SubmitButton';
import { InfoHint } from '../../../../../../components/hint/InfoHint';

export type Props = {
  timeSheetId: string;
  employeeId: string;
  day: string;
  dayChanges: DayChanges;
  singleChangeRequestId: string | null;
  isRejecting: boolean;
  isApproving: boolean;
  onApprove: (changeRequestId: string) => void;
  onReject: (changeRequestId: string) => void;
};

const i18nprefix = 'timeSheetsControlling.popovers.changes';

export const DayTimeEntriesChangesPopoverContent: React.FC<Props> = ({
  dayChanges,
  singleChangeRequestId,
  isApproving,
  isRejecting,
  onReject,
  onApprove,
}) => {
  const { startTime, endTime, breakMinutes } = dayChanges;
  const { t } = useTranslation();
  const formatDuration = formattedDuration(t);
  const isProcessing = isApproving || isRejecting;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!singleChangeRequestId) {
          return;
        }
        return onApprove(singleChangeRequestId);
      }}>
      <div className="p-3 h7-regular d-flex flex-column gap-3 ">
        {startTime.changeTo !== undefined && (
          <ValueChangeRow
            title={t(`${i18nprefix}.startTime`)}
            prevValue={startTime.value ? formatTime24h(startTime.value) : null}
            nextValue={formatTime24h(startTime.changeTo)}></ValueChangeRow>
        )}
        {breakMinutes.changeTo !== undefined && (
          <ValueChangeRow
            title={t(`${i18nprefix}.break`)}
            prevValue={formatDuration(breakMinutes.value)}
            nextValue={formatDuration(breakMinutes.changeTo)}></ValueChangeRow>
        )}
        {endTime.changeTo !== undefined && (
          <ValueChangeRow
            title={t(`${i18nprefix}.endTime`)}
            prevValue={endTime.value ? formatTime24h(endTime.value) : null}
            nextValue={formatTime24h(endTime.changeTo)}></ValueChangeRow>
        )}
        <div className="mt-2 d-flex justify-content-end align-items-center gap-1">
          {singleChangeRequestId === null && (
            <InfoHint
              className="d-flex"
              text={t(`${i18nprefix}.manyChangeRequestsHint`)}
            />
          )}
          <CancelButton
            disabled={isProcessing || singleChangeRequestId === null}
            isProcessing={isRejecting}
            onClick={() => {
              if (singleChangeRequestId) {
                onReject(singleChangeRequestId);
              }
            }}
            title={t('timeTrackingNew.dayCard.reject')}
          />
          <SubmitButton
            disabled={isProcessing || singleChangeRequestId === null}
            isProcessing={isApproving}
            title={t('timeTrackingNew.dayCard.approve')}
          />
        </div>
      </div>
    </form>
  );
};
