import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Hint from '../hint';
import { FormGroup } from './FormGroup';
import InputLabel from './InputLabel';
import { getError } from './utils';

type Item = {
  name: string;
  label: string;
  hint: string | null;
};

export type RadioGroupProps = {
  label?: string;
  name: string;
  items: Item[];
  hint?: string | null;
  className?: string;
  itemsClassName?: string;
  itemClassName?: string;
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  label,
  items,
  hint,
  className,
  itemsClassName,
  itemClassName,
}) => {
  const { register, errors } = useFormContext();
  const error = getError(name, errors);

  return (
    <FormGroup className={className}>
      <InputLabel label={label} hint={hint} />
      <div className={itemsClassName}>
        {items &&
          items.map((item) => (
            <Form.Check
              className={itemClassName}
              key={item.label}
              label={
                <>
                  {item.label}{' '}
                  {item.hint && (
                    <Hint text={item.hint} id={`hint_${item.name}`} />
                  )}
                </>
              }
              type="radio"
              name={name}
              value={item.name}
              ref={register}
              isInvalid={!!error}
            />
          ))}
      </div>
    </FormGroup>
  );
};
