import React, { createContext } from 'react';
import { useTimeBalancesRefetchLazyQuery } from '../../../__generated__/graphql';
import { toISODateOnly } from '../../../../../utils/dateUtils';
import { addMonths } from 'date-fns';

type InvalidateDaysParams = {
  employeeId: string;
  day: string;
};

export const TimeBalancesInvalidateContext = createContext<
  (invalidateParams: InvalidateDaysParams) => void
>(() => {});

// Refetch time balances from server.
// Room for improvements:
// - we can show spinners for during time balance reload
// - we can requery time balances inside mutation
export const TimeBalancesProvider: React.FC = ({ children }) => {
  const [refetchTimeBalances] = useTimeBalancesRefetchLazyQuery({
    fetchPolicy: 'network-only',
  });

  // That should be the last TimeSheet_Day__c which we display for employee.
  // We have several ways to get it:
  // 1 Read TimeSheet_Day__c from apollo cache and try to find the last one for employee
  // 2 During fetching of subdays notify that component about loaded days and store the last day for employee here
  // 3 Just assume the last loaded day somewhere in the future (one month from now), that should cover monthly timesheets as well

  // I choosed 3 so far. That is suboptimal but much easy to implement, later we can improve it if required
  const endDay = addMonths(new Date(), 1);

  const invalidateDay = (invalidateParams: InvalidateDaysParams) => {
    refetchTimeBalances({
      variables: {
        request: {
          employeeId: invalidateParams.employeeId,
          startDay: invalidateParams.day,
          endDay: toISODateOnly(endDay),
        },
      },
    }).then();
  };

  return (
    <TimeBalancesInvalidateContext.Provider value={invalidateDay}>
      {children}
    </TimeBalancesInvalidateContext.Provider>
  );
};
