import React, { useCallback, useState } from 'react';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  useUpdateWorkflowItemStatusMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';
import { WorkflowItemStatusInfo } from './types';
import {
  WorkflowItemsTable,
  Props as WorkflowItemsTableProps,
} from './WorkflowItemsTable';
import { TableItem } from '../../Documents2/Tables/PersonalDocumentsTable';

type Props = Omit<
  WorkflowItemsTableProps<TableItem>,
  'onStatusChange' | 'updatingItems'
>;

export const WorkflowItemsTableConnected: React.FC<Props> = (props) => {
  const [updateItemStatus] = useUpdateWorkflowItemStatusMutation();
  const t = useNamespacedTranslation('workflows.workflowItems');
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const [updatingItems, setUpdatingItems] = useState<WorkflowItemStatusInfo[]>(
    [],
  );

  const handleStatusChange = useCallback(
    (info: WorkflowItemStatusInfo) => {
      setUpdatingItems((prev) => [...prev, info]);
      updateItemStatus({
        variables: {
          input: {
            id: info.workflowItemId,
            status: info.status as WorkflowItemStatusGenerated,
          },
        },
      })
        .then(() => {
          addSuccess(t('statusUpdatedToast'));
        })
        .catch(errorHandler)
        .finally(() => {
          setUpdatingItems((prev) =>
            prev.filter((x) => x.workflowItemId !== info.workflowItemId),
          );
        });
    },
    [updateItemStatus, setUpdatingItems, addSuccess, errorHandler, t],
  );

  return (
    <WorkflowItemsTable
      {...props}
      onStatusChange={handleStatusChange}
      updatingItems={updatingItems}
    />
  );
};
