import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PageHeader from '../../../components/PageHeader';
import { useOrgChartQuery } from '../../../__generated__/graphql';
import { OrgChart } from '../../OrgChart';
import { mapEmployees } from './helpers';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

const Content: React.FC = () => {
  const { data, loading } = useOrgChartQuery();

  if (loading) {
    return <Skeleton height={560} className="mb-3" />;
  }

  const employees = mapEmployees(data?.orgChartEmployees);

  return <OrgChart employees={employees} />;
};

const i18Path = 'navigation.menuItems.company';

const OrgChartContainer: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const title = t('subMenuItems.orgChart.title');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  return (
    <>
      <PageHeader title={title} />
      <Content />
    </>
  );
};

export default OrgChartContainer;
