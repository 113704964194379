import React, { useMemo } from 'react';
import { useTaskNotificationsQuery } from '../../../__generated__/graphql';
import { mapNotifications } from '../RenderInfoMappings';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import NotificationsContentConnectedItems from './NotificationsContentConnectedItems';
import { getCurrentLanguage } from '../../../../../locales/locale';

const PAGE_SIZE = 10;

const TaskNotificationsContentConnected: React.FC = () => {
  const locale = getCurrentLanguage();

  const { data, loading, error, fetchMore } = useTaskNotificationsQuery({
    variables: {
      lang: locale,
      offset: 0,
      limit: PAGE_SIZE,
      skipPaginationInfo: false,
    },
  });

  const notificationPaginationInfo = useMemo(() => {
    return data?.me.taskNotifications;
  }, [data]);
  const alreadyKnowTotalCount =
    notificationPaginationInfo?.paginationInfo?.totalCount !== undefined;

  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: notificationPaginationInfo,
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo: alreadyKnowTotalCount,
        },
      }).then();
    },
  });
  const notifications =
    data?.me && mapNotifications(data.me.Id, data.me.taskNotifications.items);
  return (
    <NotificationsContentConnectedItems
      error={!!error}
      loading={loading}
      loadingMore={LoadingMore}
      notifications={notifications}
      type="task"
    />
  );
};

export default TaskNotificationsContentConnected;
