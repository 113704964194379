import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../../../Router';
import CompactColumn from '../../../components/Table/CompactColumn';
import { routes } from '../../../routes';
import { ManagerTimeSheetFragment } from '../../../__generated__/graphql';
import TimeSheetEntryCurrentTimeEntry from './TimeSheetEntryCurrentTimeEntry';
import TimeSheetEntryDaysWorked from './TimeSheetEntryDaysWorked';
import TimeSheetEntryTimeFulfillment from './TimeSheetEntryTimeFulfillment';
import TimeSheetEntryWarningBadge from './TimeSheetEntryWarningBadge';

type Props = {
  employeeNameFilter: string;
  timeSheets: ReadonlyArray<ManagerTimeSheetFragment>;
};

const CurrentTimeSheets: React.FC<Props> = ({
  employeeNameFilter,
  timeSheets,
}) => {
  const { t } = useTranslation();

  const filteredTimeSheets = timeSheets.filter((timeSheet) =>
    timeSheet.employee.Name.toLowerCase().includes(
      employeeNameFilter.toLowerCase(),
    ),
  );

  return (
    <Table className="card-table" size="sm" hover responsive>
      <thead>
        <tr>
          <th>{t('timeTracking.controlling.employee')}</th>
          <th>{t('timeTracking.controlling.timeFulfillment')}</th>
          <th>{t('timeTracking.controlling.daysWorked')}</th>
          <th></th>
          <th>{t('timeTracking.controlling.today')}</th>
        </tr>
      </thead>
      <tbody>
        {filteredTimeSheets.map((timeSheet) => (
          <tr key={timeSheet.Id}>
            <td className="text-nowrap">
              <Link to={routes.timeSheet.route} timeSheetId={timeSheet.Id}>
                {timeSheet.employee.Name}
              </Link>
            </td>
            <td>
              <TimeSheetEntryTimeFulfillment timeSheet={timeSheet} />
            </td>
            <td>
              <TimeSheetEntryDaysWorked timeSheet={timeSheet} />
            </td>
            <CompactColumn>
              <TimeSheetEntryWarningBadge trackedTime={timeSheet.trackedTime} />
            </CompactColumn>
            <td>
              <TimeSheetEntryCurrentTimeEntry timeSheet={timeSheet} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CurrentTimeSheets;
