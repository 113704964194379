import { parseISO, setSeconds } from 'date-fns';
import {
  MyTimeEntryTimeTrackingFragment,
  TimeFrameworkFragment,
} from '../../../__generated__/graphql';
import { AdjustTimeEntryRequest, AutoBreaksRules } from '../shared';
import { mapBreakLegislationRule } from '../mappings';
import {
  DateAndTime,
  TimeEntryBreakItem,
  TimeEntryItem,
  toDateTime,
} from '../../../pages/TimeTracking/TimeTrackingEntries/logic';
import {
  ClockoutBreaksState,
  TimeTrackingBreak,
  TimeTrackingStateWithEndedBreaks,
} from './clockoutBreaksReducer';
import { orderBy } from 'lodash';

export const mapTimeTrackingStateWithEndedBreaks = (
  src: MyTimeEntryTimeTrackingFragment,
): TimeTrackingStateWithEndedBreaks => ({
  start: parseISO(src.flair__Start_Datetime__c),
  breaks: src.breaks.map((srcBreak) => ({
    breakId: srcBreak.Id,
    uniqueId: srcBreak.Id,
    start: setSeconds(parseISO(srcBreak.flair__Start_Datetime__c), 0),
    end: srcBreak.flair__End_Datetime__c
      ? setSeconds(parseISO(srcBreak.flair__End_Datetime__c), 0)
      : new Date(),
  })),
});

export const isValidateAutoBreaksInsideTimeEntry = (
  timeFramework: TimeFrameworkFragment,
): boolean => {
  return (
    timeFramework.flair__Automatically_Deduct_Unused_Break__c ||
    timeFramework.flair__Suggest_Adding_Break__c
  );
};

export const mapAutoBreakRules = (
  timeFramework: TimeFrameworkFragment,
): AutoBreaksRules => {
  const legislationRules = orderBy(
    timeFramework.breakLegislationRules.map(mapBreakLegislationRule),
    (x) => x.workingTime,
  );

  return {
    deductPartially: true, // We can add it to Time_Framework__c if required
    minumumBreakDuration: timeFramework.flair__Minimal_Break_Length__c,
    legislationRules,
  };
};

export const fromTimeEntryBreakItem = (
  src: TimeEntryBreakItem,
): TimeTrackingBreak => ({
  uniqueId: src.uniqueId,
  breakId: src.breakId,
  start: fromDateTime(src.start),
  end: src.end ? fromDateTime(src.end) : new Date(),
});

export const toTimeEntryBreakItem = (
  src: TimeTrackingBreak,
): TimeEntryBreakItem => ({
  uniqueId: src.uniqueId,
  breakId: src.breakId,
  start: toDateTime(src.start),
  end: toDateTime(src.end),
  isBreakAutoFixed: src.isBreakAutoFixed,
  isNew: src.breakId !== null,
  breakChangeRequestId: null,
});

export const toTimeEntry = (
  start: Date,
  end: Date,
  breaks: TimeTrackingBreak[],
): Pick<TimeEntryItem, 'start' | 'end' | 'breaks' | 'notes'> => ({
  start: toDateTime(start),
  breaks: breaks.map(toTimeEntryBreakItem),
  end: toDateTime(end),
  notes: '',
});

const fromDateTime = (dateAndTime: DateAndTime): Date => {
  return parseISO(`${dateAndTime.date}T${dateAndTime.time}`);
};

export const mapToAdjustTimeEntryRequest = (
  src: ClockoutBreaksState,
): AdjustTimeEntryRequest => ({
  start: src.start,
  end: src.clockout,
  breaks: src.breaks.map((x) => ({
    start: x.start,
    end: x.end,
    breakId: x.uniqueId,
  })),
});
