import * as React from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useUserInfo } from '../../../context/UserInfo';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Maybe } from '../../../../../utils/maybe';
import Actions from './Actions';
import './CommentInput.css';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { actionsEnum } from '../../../../../components/UploadFile/types';
import DocumentItem from '../DocumentItem/DocumentItem';
import { Comment, CommentFile, MentionEmployee, UpsertComment } from '../types';
import { collectFiles } from '../../../../../utils/file';
import SearchEmployeesPopup from './SearchedEmployeesPopup/SearchEmployeesPopup';
import useEmployeeMentionHook from '../Hooks/useEmployeeMentionHook';
import MentionedEmployeesWithNoAccessWarning from './MentionedEmployeesWithNoAccessWarning/MentionedEmployeesWithNoAccessWarning';
import { getWindowRange } from '../logic';

export type CommentInputProps = {
  placeholder?: string;
  disabledInput?: boolean;
  onCancel?: () => void;
  onSubmit?: (comment: UpsertComment) => Promise<void> | void;
  loading?: boolean;
  hideMainActions?: boolean;
  hideSecondaryActions?: boolean;
  className?: string;
  comment?: Comment;
  ref?: React.Ref<HTMLDivElement>;
  onFocusChange?: (isFocused: boolean) => void;
};

const i18Path = 'comments.input';

const CommentInput: React.FC<CommentInputProps> = forwardRef<
  HTMLDivElement,
  CommentInputProps
>((props, ref) => {
  const {
    placeholder,
    disabledInput = false,
    onCancel,
    onSubmit,
    loading = false,
    hideMainActions = false,
    hideSecondaryActions = false,
    className = '',
    comment,
    onFocusChange,
  } = props;
  const t = useNamespacedTranslation(i18Path);
  const [text, setText] = useState<string>(comment?.text ?? '');
  const [files, setFiles] = useState<Maybe<File[]>>(null);
  const [images, setImages] = useState<Maybe<File[]>>(null);
  const [mappedFiles, setMappedFiles] = useState<CommentFile[]>([]);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [
    showWarningForEmployeesWithoutAccess,
    setShowWarningForEmployeesWithoutAccess,
  ] = useState<boolean>(false);
  const [mentionedEmployees, setMentionedEmployees] = useState<
    MentionEmployee[]
  >([]);

  //Range is being used to track the last cursor position
  const [range, setRange] = useState<Maybe<Range>>(null);

  const {
    setRef,
    startDetectingUsersInput,
    mentionEmployees,
    searchEmployeesForMentionLoading,
    searchEmployeesForMentionError,
    handleOnKeyUpForMention,
    handleOnKeyDownForMention,
    handleOnMentionEmployee,
    handleOnInputForMention,
  } = useEmployeeMentionHook(range, setRange, setMentionedEmployees);

  const { id, name, avatarUrl } = useUserInfo();
  const textareaRef = useRef<HTMLDivElement>(null);

  const isDisabled = loading || disabledInput;

  useEffect(() => {
    if (textareaRef.current && comment?.text) {
      textareaRef.current.innerHTML = comment.text;
    }
  }, [comment?.text]);

  useEffect(() => {
    if (textareaRef) {
      setRef(textareaRef);
    }
  }, [setRef, textareaRef]);

  useEffect(() => {
    files ? collectFiles(files).then(setMappedFiles) : setMappedFiles([]);
  }, [files]);

  const handleSubmit = async () => {
    if (!onSubmit || !isValid()) {
      return;
    }

    const isAnyEmployeeWithNoAccess = mentionedEmployees.some(
      (e) => !e.hasAccess,
    );

    if (isAnyEmployeeWithNoAccess) {
      setShowWarningForEmployeesWithoutAccess(true);
    } else {
      proceedToSubmit();
    }
  };

  const proceedToSubmit = async () => {
    const [collectedFiles, collectedImages] = await Promise.all([
      files ? collectFiles(files) : [],
      images ? collectFiles(images) : [],
    ]);

    const mappedFiles = [...collectedFiles, ...collectedImages];

    const data = {
      id: comment?.id,
      employee: {
        id,
        name,
      },
      text,
      createdDate: new Date(),
      files: mappedFiles,
      mentionedEmployees,
    };

    if (onSubmit) {
      await onSubmit(data);
      clearData();
    }
  };

  const clearData = () => {
    setText('');
    setFiles(null);
    setImages(null);
    setIsInputFocused(false);
    onFocusChange && onFocusChange(false);
    if (textareaRef.current) {
      textareaRef.current.innerHTML = '';
    }
  };

  const isValid = () => text.length !== 0 || files !== null || images !== null;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }

    handleOnKeyDownForMention(e);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    handleOnKeyUpForMention(e);
  };

  const handleInput = () => {
    if (textareaRef.current) {
      const currentValue = textareaRef.current.innerHTML;
      setText(currentValue);
      handleOnInputForMention(text);
    }
  };

  const handleOnBlur = () => {
    setRange(getWindowRange());
  };

  const renderDocument = () => {
    //Multiple files will be handled later
    return (
      <DocumentItem
        action={actionsEnum.UPLOAD}
        document={mappedFiles[0]}
        onDelete={() => setFiles(null)}
      />
    );
  };

  const handleOnInputFocus = () => {
    setIsInputFocused(true);
    onFocusChange && onFocusChange(true);
  };

  const handleOnPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const items = Array.from(event.clipboardData?.items || []);
    const imageItem = items.find((item) => item.type.indexOf('image') !== -1);

    if (imageItem) {
      event.preventDefault();
      const file = imageItem.getAsFile();
      if (file) {
        setImages([file]);
      }
    }
  };

  const handleOnCancelInput = () => {
    clearData();
    onCancel && onCancel();
  };

  const renderImage = () => {
    return (
      images &&
      Array(images).length > 0 && (
        <>
          <img
            className="w-50"
            alt={images[0].name}
            src={URL.createObjectURL(images[0])}
          />
          <div
            className="close-btn-container"
            role="button"
            onClick={() => setImages(null)}>
            <FlairIcon icon="close-outline" size="1x" />
          </div>
        </>
      )
    );
  };

  const handleOnFilesChange = (files: FileList) => {
    setFiles(Array.from(files));
  };

  const handleOnImagesChange = (files: FileList) => {
    setImages(Array.from(files));
  };

  return (
    <div className={className} ref={ref}>
      <Row className="flex-nowrap">
        <Col className="col-auto">
          <Avatar
            avatarUrl={avatarUrl}
            size="sm"
            initials={getEmployeeInitialsFromName(name)}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <div
                className={`textarea-container d-flex gap-1 p-2 ${
                  isDisabled ? 'disabled' : ''
                }`}>
                <div
                  ref={textareaRef}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  onBlur={handleOnBlur}
                  onFocus={handleOnInputFocus}
                  onPaste={handleOnPaste}
                  onInput={handleInput}
                  contentEditable={!isDisabled}
                  className={`w-${images ? 50 : 100} textarea p-1`}
                  placeholder={placeholder ?? t('placeholder')}
                  data-testid="comment-input-container"
                />
                {loading && (
                  <Spinner
                    animation="grow"
                    size="sm"
                    className="me-2 align-self-center"
                  />
                )}
                {renderImage()}
              </div>
              <SearchEmployeesPopup
                startDetectingUsersInput={startDetectingUsersInput}
                mentionEmployees={mentionEmployees}
                onMentionUser={(e) => handleOnMentionEmployee(e, setText)}
                isLoading={searchEmployeesForMentionLoading}
                ref={textareaRef}
                error={searchEmployeesForMentionError}
              />
            </Col>
          </Row>
          {!!mappedFiles && mappedFiles.length > 0 && (
            <Row className="pt-2">
              <Col>{renderDocument()}</Col>
            </Row>
          )}
          <Row className="mt-2 justify-content-between align-items-center">
            <Col>
              {!disabledInput && isInputFocused && (
                <Actions
                  onFilesChange={handleOnFilesChange}
                  onImagesChange={handleOnImagesChange}
                  text={text}
                  setText={setText}
                  textareaRef={textareaRef}
                  range={range}
                  onCancel={handleOnCancelInput}
                  onSubmit={handleSubmit}
                  loading={loading}
                  isSubmitDisabled={isDisabled}
                  hideMainActions={hideMainActions}
                  hideSecondaryActions={hideSecondaryActions}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {showWarningForEmployeesWithoutAccess && (
        <MentionedEmployeesWithNoAccessWarning
          show={showWarningForEmployeesWithoutAccess}
          onProceed={proceedToSubmit}
          onClose={() => setShowWarningForEmployeesWithoutAccess(false)}
          employees={mentionedEmployees.filter((e) => !e.hasAccess)}
        />
      )}
    </div>
  );
});

export default CommentInput;
