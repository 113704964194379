import { createContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { StepProp } from '../../../../components/Step';
import { StepNamesType } from './types';
import { TicketPolicy } from '../types';

export type TContextProps = {
  steps: StepProp<StepNamesType>[];
  isExpanded: boolean;
  maxStep: number;
  currentStep: number;
  onCancel: () => void;
  onChange: (step: FieldValues) => void;
  onChangeStep: (step: number) => void;
  value: FieldValues | null;
  policy: TicketPolicy | null;
};

export const TicketModalContext = createContext<TContextProps>({
  steps: [],
  isExpanded: false,
  maxStep: 0,
  currentStep: 0,
  onCancel: () => {},
  onChange: () => {},
  onChangeStep: () => {},
  value: null,
  policy: null,
});
