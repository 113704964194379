import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardHeader from '../../components/CardHeader';
import TimeDistChart from '../../components/TimeSheet/TimeDistChart';
import { TimeSheet } from './types';

type Props = {
  timeSheet: TimeSheet;
};

const TimeDistChartCard: React.FC<Props> = ({ timeSheet }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>{t('timeTracking.graphs.timeDist.title')}</CardHeader>
      <Card.Body>
        <TimeDistChart timeSheet={timeSheet} />
      </Card.Body>
    </Card>
  );
};

export default TimeDistChartCard;
