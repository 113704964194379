import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '../apps/dashboard/hooks/useLocalStorage';
import { QueryString } from '../utils/router';
import { useQueryParams } from './useQueryParams';

// Persist filter in both "query string" and "local storage"
// Load filter:
// 1 Load filter from query string
// 2 If all keys of filter are empty then try to load it from localStorage

export type FilterBase = Record<string, string | null>;

export type UsePersistentFilterBaseArg<
  T extends FilterBase,
  K extends string = string,
> = {
  defaultFilter: T;
  storageKey: K;
  createRouteUrl: (params: QueryString) => string;
};

export const usePersistentFilterBase = <T extends FilterBase>({
  defaultFilter,
  storageKey,
  createRouteUrl,
}: UsePersistentFilterBaseArg<T>): [T, (filter: T) => void] => {
  const allKeys: (keyof T)[] = Object.keys(defaultFilter);
  const history = useHistory();
  const queryParams = useQueryParams();
  /* eslint-disable-next-line no-restricted-syntax */
  const [storedFilter, setStoredFilter] = useLocalStorage<
    Record<string | number | symbol, string>
  >(storageKey, {});

  const isFilterSetFromQuery = useMemo(() => {
    const result = Object.keys(queryParams).some((key) =>
      isFilterKey(allKeys, key),
    );
    return result;
  }, [queryParams, allKeys]);

  const createFilterFromQueryParams = useCallback(
    (allKeys: (keyof T)[], queryParams: any): T => {
      const filterStoredInQuery = allKeys.reduce((acc, cur) => {
        return queryParams[cur]
          ? {
              ...acc,
              [cur]: queryParams[cur],
            }
          : acc;
      }, {} as Record<keyof T, string>);
      return fromStoreFilterObject(filterStoredInQuery, defaultFilter);
    },
    [defaultFilter],
  );

  const updateQueryParamsWithFilterLocal = useCallback(
    (filterToSave: Record<keyof T, string>) => {
      const newQueryParams = _.omitBy(
        allKeys.reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: filterToSave[cur] ?? null,
          };
        }, queryParams),
        _.isEmpty,
      );
      history.push(createRouteUrl(newQueryParams));
    },
    [queryParams, history, createRouteUrl, allKeys],
  );

  const filter: T = isFilterSetFromQuery
    ? createFilterFromQueryParams(allKeys, queryParams)
    : fromStoreFilterObject(storedFilter, defaultFilter);

  const setFilter = useCallback(
    (filter: T) => {
      const filterToSave = toStoreFilterObject(filter, defaultFilter);
      setStoredFilter(filterToSave);
      updateQueryParamsWithFilterLocal(filterToSave);
    },
    [setStoredFilter, updateQueryParamsWithFilterLocal, defaultFilter],
  );

  // update query string if filter is loaded from localStorage
  useEffect(() => {
    if (!isFilterSetFromQuery) {
      const filterFromQuery = createFilterFromQueryParams(allKeys, queryParams);

      const filterFromStorage = fromStoreFilterObject(
        storedFilter,
        defaultFilter,
      );
      if (!_.isEqual(filterFromQuery, filterFromStorage)) {
        updateQueryParamsWithFilterLocal(
          toStoreFilterObject(filterFromStorage, defaultFilter),
        );
      }
    }
  }, [
    storedFilter,
    isFilterSetFromQuery,
    createFilterFromQueryParams,
    allKeys,
    queryParams,
    setStoredFilter,
    setFilter,
    defaultFilter,
    updateQueryParamsWithFilterLocal,
  ]);

  return [filter, setFilter];
};

function isFilterKey(
  allKeys: (string | number | symbol)[],
  queryKey: string,
): boolean {
  return allKeys.some((x) => x === queryKey);
}

// returns object which contains only values differs from defaults
export function toStoreFilterObject<T extends FilterBase>(
  filter: T,
  defaultFilter: T,
): Record<keyof T, string> {
  const allKeys = Object.keys(filter);
  const filterToSave = _.omitBy(
    allKeys.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: filter[cur] !== defaultFilter[cur] ? filter[cur] : null,
      };
    }, {}),
    _.isEmpty,
  );
  return filterToSave as Record<keyof T, string>;
}

export function fromStoreFilterObject<T extends FilterBase>(
  savedFilter: Partial<Record<keyof T, string>>,
  defaultFilter: T,
): T {
  const allKeys = Object.keys(defaultFilter);
  return allKeys.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: savedFilter[cur] ? savedFilter[cur] : defaultFilter[cur],
    };
  }, {} as T);
}
