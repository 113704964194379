import { chunk } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCurrentLocale } from '../../../../../context/I18n';
import { Link } from '../../../../../Router';
import { utoa } from '../../../../../utils/base64';
import {
  mapEmployeeRelatedList,
  mapEmployeeSection,
  RelatedList,
  Section,
} from '../../../components/EmployeeData';
import { useUserInfo } from '../../../context/UserInfo';
import { routes } from '../../../routes';
import {
  PersonalDataQuery,
  usePersonalDataQuery,
} from '../../../__generated__/graphql';
import Loading from './Loading';

const EditLink: React.FC<{ sectionLabel: string }> = ({ sectionLabel }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={routes.editPersonalData.route}
      className="small"
      section={utoa(sectionLabel)}>
      {t('personalData.editButton')}
    </Link>
  );
};

type SectionProps = PersonalDataQuery['me']['data']['sections'][0];
type RelatedListProps = PersonalDataQuery['me']['data']['relatedLists'][0];

const SectionCard: React.FC<{ section: SectionProps }> = ({ section }) => {
  const { hasLeftCompany } = useUserInfo();

  return (
    <Section
      section={mapEmployeeSection(section)}
      editLink={!hasLeftCompany && <EditLink sectionLabel={section.label} />}
    />
  );
};

type WithRelatedListProps = {
  relatedLists: ReadonlyArray<RelatedListProps>;
  sections: ReadonlyArray<SectionProps>;
};

const WithRelatedLists: React.FC<WithRelatedListProps> = ({
  relatedLists,
  sections,
}) => (
  <Row>
    <Col xl={6}>
      {sections.map((section, i) => (
        <SectionCard section={section} key={`${section.label}-${i}`} />
      ))}
    </Col>
    <Col xl={6}>
      {relatedLists.map((relatedList, i) => (
        <RelatedList
          relatedList={mapEmployeeRelatedList(relatedList)}
          key={`${relatedList.label}-${i}`}
        />
      ))}
    </Col>
  </Row>
);

type WithoutRelatedListsProps = Omit<WithRelatedListProps, 'relatedLists'>;

const WithoutRelatedLists: React.FC<WithoutRelatedListsProps> = ({
  sections,
}) => (
  <Row>
    {chunk(sections, Math.ceil(sections.length / 2)).map(
      (chunkedSections, i) => (
        <Col key={`section-col-${i}`} xl={6}>
          {chunkedSections.map((section, j) => (
            <SectionCard section={section} key={`${section.label}-${j}`} />
          ))}
        </Col>
      ),
    )}
  </Row>
);

const PersonalData: React.FC = () => {
  const locale = useCurrentLocale();
  const { data, loading } = usePersonalDataQuery({
    variables: { locale: locale },
  });

  if (loading || !data) return <Loading />;

  const {
    me: {
      data: { sections, relatedLists },
    },
  } = data;
  const hasRelatedLists = relatedLists.length > 0;

  return (
    <>
      {hasRelatedLists ? (
        <WithRelatedLists relatedLists={relatedLists} sections={sections} />
      ) : (
        <WithoutRelatedLists sections={sections} />
      )}
    </>
  );
};

export default PersonalData;
