import { PureQueryOptions } from '@apollo/client';
import styled from '@emotion/styled';
import { range } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../../theme';
import { hourStr } from '../shiftsLogic';
import ShiftsWeeklyColumn from './ShiftsWeeklyColumn';
import { toKey, WeeklyShiftPod } from './weeklyShiftsLogic';

type Props = {
  weekStart: Moment;
  minHour: number;
  maxHour: number;
  podsGroupedByDay: Map<string, WeeklyShiftPod[]>;
  openShiftPodsGroupedByDays: Map<string, WeeklyShiftPod[]>;
  refetchQueries?: Array<PureQueryOptions>;
};

const BORDER_COLOR = Theme.color.paper3;
const SKIP_TOP_ROWS = 1;
const OPEN_SHIFT_ROWS = 1;
const SKIP_LEFT_COLUMNS = 1;
const DAYS_IN_WEEK = 7;

const GridContainer = styled.div({
  width: '100%',
  overflowY: 'auto',
});

const Grid = styled.div({
  display: 'grid',
  gridTemplateRows: '88px',
  gridAutoRows: 'minmax(38px, auto)',
  gridTemplateColumns: '56px repeat(7, minmax(100px, 1fr))',
});

const HeaderCell = styled.div<{ highlighted: boolean }>(({ highlighted }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: highlighted ? Theme.color.blue3 : Theme.color.black,
  borderBottom: highlighted
    ? `3px solid ${Theme.color.blue3}`
    : `1px solid ${BORDER_COLOR}`,
}));

const FirstColumnCell = styled.div<{ isOpenShift?: boolean }>(
  {
    fontSize: Theme.font.size.sm,
    display: 'flex',
    justifyContent: 'center',
  },
  ({ isOpenShift }) => ({
    borderBottom: `${isOpenShift ? 3 : 1}px solid ${BORDER_COLOR}`,
    alignItems: isOpenShift ? 'unset' : 'center',
    paddingTop: isOpenShift ? '15px' : 'unset',
  }),
);

const WeekDay = styled.div({
  fontSize: Theme.font.size.sm,
});

// just for borders
const GridColumn = styled.div<{ hoursCount: number }>(({ hoursCount }) => ({
  borderLeft: `1px solid ${BORDER_COLOR}`,
  gridRow: `${1 + SKIP_TOP_ROWS} / ${
    1 + SKIP_TOP_ROWS + OPEN_SHIFT_ROWS + hoursCount
  }`,
}));
const GridRow = styled.div<{ isOpenShift: boolean }>(
  {
    gridColumn: `${1 + SKIP_LEFT_COLUMNS} / ${
      1 + SKIP_LEFT_COLUMNS + DAYS_IN_WEEK
    }`,
  },
  ({ isOpenShift }) => ({
    borderBottom: `${isOpenShift ? 3 : 1}px solid ${BORDER_COLOR}`,
  }),
);

const ShiftsWeeklyCalendar: React.FC<Props> = ({
  weekStart,
  minHour,
  maxHour,
  refetchQueries,
  podsGroupedByDay,
  openShiftPodsGroupedByDays,
}) => {
  const { t } = useTranslation();
  const hoursCount = maxHour - minHour;

  const weekDays: Moment[] = range(0, DAYS_IN_WEEK).map((dayIndex) =>
    weekStart.clone().add(dayIndex, 'day'),
  );

  const headerCell = (date: Moment, highlighted: boolean) => {
    return (
      <HeaderCell key={`header-${date.date()}`} highlighted={highlighted}>
        <div>{date.date()}</div>
        <WeekDay>{date.format('ddd')}</WeekDay>
      </HeaderCell>
    );
  };

  const today = moment().startOf('day');

  const header = () => weekDays.map((x) => headerCell(x, today.isSame(x)));

  const firstColumn = () => {
    return (
      <>
        <FirstColumnCell
          key="first-open-shift-column"
          isOpenShift={true}
          style={{
            gridColumn: 1,
            gridRow: 1 + SKIP_TOP_ROWS,
            height: '65px',
          }}>
          <div>{t('shiftPlanner.openShifts.title')}</div>
        </FirstColumnCell>
        {range(minHour, maxHour).map((hour, index) => (
          <FirstColumnCell
            key={`first-${hour}`}
            style={{
              gridColumn: 1,
              gridRow: 1 + SKIP_TOP_ROWS + ++index,
              borderBottom: hour === maxHour - 1 ? 'none' : undefined,
            }}>
            <div>{hourStr(hour)}</div>
          </FirstColumnCell>
        ))}
      </>
    );
  };

  const innerGridBorders = () => {
    const columns = range(0, DAYS_IN_WEEK).map((_weekDay, i) => (
      <GridColumn
        key={`col-${i}`}
        style={{ gridColumn: `${1 + SKIP_TOP_ROWS + i}` }}
        hoursCount={hoursCount}
      />
    ));

    const rowsCount = maxHour + OPEN_SHIFT_ROWS - minHour;
    const rows = range(rowsCount).map((_hour, i) => (
      <GridRow
        key={`row-${i}`}
        isOpenShift={i === 0}
        style={{
          gridRow: `${1 + SKIP_TOP_ROWS + i}`,
          borderBottom: i === rowsCount - 1 ? 'none' : undefined,
        }}
      />
    ));

    return [...columns, ...rows];
  };

  const dayColumns = () =>
    weekDays.map((day, dayIndex) => {
      const dayKey = toKey(day);
      return (
        <React.Fragment key={dayIndex}>
          <div
            className="open-shift"
            style={{
              gridColumn: `${1 + SKIP_LEFT_COLUMNS + dayIndex}`,
              gridRow: `${1 + SKIP_TOP_ROWS} / ${
                1 + SKIP_TOP_ROWS + OPEN_SHIFT_ROWS
              }`,
              padding: '7px 5px',
            }}>
            {openShiftPodsGroupedByDays.has(dayKey) && (
              <ShiftsWeeklyColumn
                day={day}
                minHour={minHour}
                maxHour={maxHour}
                refetchQueries={refetchQueries}
                pods={openShiftPodsGroupedByDays.get(dayKey)!}
              />
            )}
          </div>
          <div
            className="shifts"
            style={{
              gridColumn: `${1 + SKIP_LEFT_COLUMNS + dayIndex}`,
              gridRow: `${1 + SKIP_TOP_ROWS + OPEN_SHIFT_ROWS} / ${
                1 + SKIP_TOP_ROWS + OPEN_SHIFT_ROWS + hoursCount
              }`,
              padding: '0 5px',
            }}>
            {podsGroupedByDay.has(dayKey) && (
              <ShiftsWeeklyColumn
                day={day}
                minHour={minHour}
                maxHour={maxHour}
                refetchQueries={refetchQueries}
                pods={podsGroupedByDay.get(dayKey)!}
              />
            )}
          </div>
        </React.Fragment>
      );
    });

  const zeroCell = () => (
    <div style={{ borderBottom: `1px solid ${BORDER_COLOR}` }}></div>
  );

  return (
    <GridContainer>
      <Grid>
        {zeroCell()}
        {header()}
        {firstColumn()}
        {innerGridBorders()}
        {dayColumns()}
      </Grid>
    </GridContainer>
  );
};

export default ShiftsWeeklyCalendar;
