import i18next from 'i18next';
import { Option } from './types';
import { allDocumentStatuses } from '../types';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';
import { OptionBase } from '../../../../../components/Select/types';

export const createDocumentStatusOptions = (): Option[] => {
  return allDocumentStatuses.map((x) => ({
    value: x,
    label: i18next.t(`documents2.personal.table.documentStatus.${x}`),
  }));
};

export const createYearOptions = (now: Date): Option[] => {
  const currentYear = now.getFullYear();
  return [currentYear - 1, currentYear, currentYear + 1].map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
};

export const createMonthOptions = (months: OptionBase[]): Option[] => {
  return months.map((month) => ({ value: month.value, label: month.value }));
};

export const HREmployee: EmployeeOption = {
  value: 'HR',
  label: 'HR',
  initials: 'HR',
  avatarUrl: null,
};
