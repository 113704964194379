import React from 'react';
import { CategoryType } from '../../types';

export type TContextProps = {
  startOfPeriod: Date;
  periodType: 'previous' | 'current' | 'next';
  category?: CategoryType;
  isHourly?: boolean;
};

export const CategoryAccordionBodyContext = React.createContext<TContextProps>({
  startOfPeriod: new Date(),
  periodType: 'current',
  isHourly: false,
});
