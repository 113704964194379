import React, { useCallback, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Theme } from '../../../../theme';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type Props = {
  tooltip?: string;
  onChange?: (isSelected: boolean) => void;
  disabled?: boolean;
  isSelected: boolean;
};

type StarIconProps = {
  color: string;
  isSelected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const StarIcon: React.FC<StarIconProps> = ({
  onClick,
  disabled,
  isSelected,
  color,
}) => {
  return (
    <span
      onClick={onClick}
      className="p-1"
      style={{ cursor: !disabled ? 'pointer' : undefined }}>
      <FlairIcon
        size="sm"
        icon={isSelected ? 'star-filled' : 'star-outline'}
        color={color}
      />
    </span>
  );
};

export const Star: React.FC<Props> = ({
  isSelected,
  onChange,
  disabled,
  tooltip,
}) => {
  const handleClick = useCallback(() => {
    if (disabled || !onChange) {
      return;
    }
    onChange(!isSelected);
  }, [onChange, isSelected, disabled]);
  const color = useMemo(() => {
    if (disabled) {
      return isSelected ? Theme.color.bgHoney : Theme.color.gray3;
    }
    return isSelected ? Theme.color.honey1 : Theme.color.accentGray;
  }, [disabled, isSelected]);

  return (
    <>
      {tooltip ? (
        <OverlayTrigger overlay={<Tooltip id={`tooltip`}>{tooltip}</Tooltip>}>
          <span>
            <StarIcon
              isSelected={isSelected}
              onClick={handleClick}
              disabled={disabled}
              color={color}
            />
          </span>
        </OverlayTrigger>
      ) : (
        <StarIcon
          isSelected={isSelected}
          onClick={handleClick}
          disabled={disabled}
          color={color}
        />
      )}
    </>
  );
};
