import React from 'react';
import { GoalRate } from '../types';
import styled from '@emotion/styled';
import Hint from '../../../../../../components/hint';
import { getGoalRateStr } from '../translations';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = {
  rate: GoalRate | undefined;
};

export const GoalRateIcon: React.FC<Props> = ({ rate }) => {
  const icon = () => {
    switch (rate) {
      case 'FULFILLED':
        return <FlairIcon icon="checkmark-outline" color="#27AE60" size="xs" />;
      case 'EXCEEDED':
        return (
          <FlairIcon icon="checkmark-done-outline" color="#27AE60" size="xs" />
        );
      case 'NOT_FULFILLED':
        return <FlairIcon icon="close-outline" color="#EB5757" size="xs" />;
      case 'OPEN':
        return <Stub />;
      default:
        return <Stub />;
    }
  };

  if (!rate) {
    return <Stub />;
  }

  return (
    <Hint id="rate" text={getGoalRateStr(rate)}>
      {icon()}
    </Hint>
  );
};

const Stub = styled('div')({
  width: '0.4em',
});
