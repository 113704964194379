import React from 'react';
import Button, { ButtonProps } from '.';
import { useNamespacedTranslation } from '../../hooks/useNamespacedTranslation';

type Props = ButtonProps;

//TODO: Make CancelButton refactoring and try use as much as possible
export const CancelButton: React.FC<Props> = ({ label, variant, ...props }) => {
  const t = useNamespacedTranslation('components.cancelButton');
  return (
    <Button
      {...props}
      label={label ?? t('cancel')}
      variant={variant ?? 'outline-secondary'}></Button>
  );
};
