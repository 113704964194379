import * as React from 'react';

const FlagCircleIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="28" height="28" rx="14" fill="#EEEAE2" />
      <path
        d="M8.5 20.5V8.12937C8.50015 8.08564 8.51178 8.04271 8.53371 8.00487C8.55565 7.96704 8.58712 7.93562 8.625 7.91375C8.86906 7.77063 9.52875 7.5 11 7.5C13 7.5 15.5312 9 17 9C17.8292 8.99785 18.6497 8.83031 19.4134 8.50719C19.4229 8.50322 19.4333 8.50167 19.4435 8.50266C19.4538 8.50366 19.4636 8.50717 19.4722 8.5129C19.4808 8.51862 19.4878 8.52637 19.4926 8.53546C19.4975 8.54455 19.5 8.5547 19.5 8.565V15.42C19.4999 15.4442 19.4928 15.4679 19.4796 15.4881C19.4663 15.5084 19.4475 15.5243 19.4253 15.5341C19.1678 15.6469 18.2603 16 17 16C15.5 16 13 15 11 15C9 15 8.5 15.5 8.5 15.5"
        stroke="#8D8C7D"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
});

export default FlagCircleIcon;
