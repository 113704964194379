import { useEffect, useState } from 'react';

export const useNow = (updateInterval: number) => {
  const [now, setNow] = useState<Date>(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, updateInterval);
    return () => clearInterval(interval);
  }, [updateInterval]);
  return { now };
};
