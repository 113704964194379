import * as React from 'react';

const DocumentSolidIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.375 7H9C8.60218 7 8.22064 6.84196 7.93934 6.56066C7.65804 6.27936 7.5 5.89782 7.5 5.5V1.125C7.5 1.09185 7.48683 1.06005 7.46339 1.03661C7.43995 1.01317 7.40815 1 7.375 1H4.5C3.96957 1 3.46086 1.21071 3.08579 1.58579C2.71071 1.96086 2.5 2.46957 2.5 3V13C2.5 13.5304 2.71071 14.0391 3.08579 14.4142C3.46086 14.7893 3.96957 15 4.5 15H11.5C12.0304 15 12.5391 14.7893 12.9142 14.4142C13.2893 14.0391 13.5 13.5304 13.5 13V7.125C13.5 7.09185 13.4868 7.06005 13.4634 7.03661C13.4399 7.01317 13.4082 7 13.375 7Z"
        fill="currentColor"
      />
      <path
        d="M13.1006 5.89346L8.60656 1.3994C8.59782 1.39071 8.58671 1.3848 8.57462 1.38241C8.56253 1.38002 8.55001 1.38126 8.53862 1.38597C8.52723 1.39068 8.51749 1.39865 8.51062 1.40888C8.50375 1.41911 8.50005 1.43114 8.5 1.44346V5.50003C8.5 5.63263 8.55268 5.75981 8.64645 5.85358C8.74021 5.94735 8.86739 6.00003 9 6.00003H13.0566C13.0689 5.99997 13.0809 5.99628 13.0911 5.98941C13.1014 5.98254 13.1093 5.9728 13.1141 5.96141C13.1188 5.95002 13.12 5.93749 13.1176 5.92541C13.1152 5.91332 13.1093 5.9022 13.1006 5.89346Z"
        fill="currentColor"
      />
      <path
        d="M13.1006 5.89346L8.60656 1.3994C8.59782 1.39071 8.58671 1.3848 8.57462 1.38241C8.56253 1.38002 8.55001 1.38126 8.53862 1.38597C8.52723 1.39068 8.51749 1.39865 8.51062 1.40888C8.50375 1.41911 8.50005 1.43114 8.5 1.44346V5.50003C8.5 5.63263 8.55268 5.75981 8.64645 5.85358C8.74021 5.94735 8.86739 6.00003 9 6.00003H13.0566C13.0689 5.99997 13.0809 5.99628 13.0911 5.98941C13.1014 5.98254 13.1093 5.9728 13.1141 5.96141C13.1188 5.95002 13.12 5.93749 13.1176 5.92541C13.1152 5.91332 13.1093 5.9022 13.1006 5.89346Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default DocumentSolidIcon;
