import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  title: string;
  disabled: boolean;
  isProcessing?: boolean;
};

const SubmitButton: React.FC<Props> = ({ title, disabled, isProcessing }) => {
  return (
    <Button
      type="submit"
      size="sm"
      disabled={disabled}
      className="ms-2 d-flex align-items-center">
      {isProcessing && (
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"></span>
      )}
      <span>{title}</span>
    </Button>
  );
};

export default SubmitButton;
