import { useMemo } from 'react';
import { DataWithLoadingState } from '../../../../../utils/dataWithLoadingState';
import { useUserInfo } from '../../../context/UserInfo';
import {
  SkillFragment,
  useEmployeeSkillsQuery,
} from '../../../__generated__/graphql';
import { mapSkill } from '../common/mappings';
import { Skill } from './types';

export const useEmployeeSkills = (employeeId: string) => {
  return useEmployeeSkillsQuery({
    variables: { id: employeeId },
  });
};

export const useCanEditSkills = (employeeId: string): boolean => {
  const { id: meId } = useUserInfo();
  const { data } = useEmployeeSkillsQuery({
    variables: { id: employeeId },
    skip: meId === employeeId,
  });

  return useMemo(() => {
    if (meId === employeeId) {
      return true;
    }
    if (data?.manager.employee?.Id === employeeId) {
      return true;
    }
    return false;
  }, [meId, employeeId, data]);
};

export const useEmployeeAvailableSkills = (employeeId: string) => {
  const { data, loading, error } = useEmployeeSkills(employeeId);

  const availableSkills: DataWithLoadingState<Skill> = useMemo(() => {
    if (error) {
      return 'error';
    }
    if (!data || loading) {
      return 'loading';
    }
    if (data.activeEmployees.length < 1) {
      return [];
    }
    const employee = data.activeEmployees[0];
    const employeeSkillIds = employee.employeeSkills.map(
      (x) => x.flair__Skill__c,
    );
    return getAvailableSkills(data.skills, employeeSkillIds);
  }, [data, loading, error]);
  return { availableSkills };
};

const getAvailableSkills = (
  skills: readonly SkillFragment[],
  employeeSkillIds: string[],
) => {
  const allSkills = skills.map(mapSkill);
  return allSkills.filter((skill) => !employeeSkillIds.includes(skill.id));
};
