import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import RedirectToHome from '../../components/RedirectToHome';
import PerformanceReviewNav from './PerformanceReviewNav';
import { routes } from './routes';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

export const PerformanceReview: React.FC = () => {
  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.personalDevelopment.title') },
    { label: t('performanceReview.subMenuItems.reviews.title') },
  ]);

  return (
    <>
      <PageHeader title={t('performanceReview.subMenuItems.reviews.title')}>
        <PerformanceReviewNav />
      </PageHeader>

      <Content />
    </>
  );
};
