import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Celebration } from '../types';
import { CelebrationForm } from './CelebrationForm';

type Props = {
  show: boolean;
  celebration: Celebration | null;
  onClose: () => void;
};

const i18nPrefix = 'cheers.form.update';

export const CelebrationUpdateModal: React.FC<Props> = ({
  show,
  celebration,
  onClose,
}) => {
  const t = useNamespacedTranslation(i18nPrefix);
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t('modalTitle')}</h4>
      </Modal.Header>
      <Modal.Body>
        <CelebrationForm onClose={onClose} celebration={celebration} />
      </Modal.Body>
    </Modal>
  );
};
