import { Moment } from 'moment';
import React from 'react';
import styled from '@emotion/styled';
import { WeeklyShiftPod } from './weeklyShiftsLogic';
import { PureQueryOptions } from '@apollo/client';
import ShiftsWeeklyPod from './ShiftsWeeklyPod';

const Container = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
});

type Props = {
  day: Moment;
  minHour: number;
  maxHour: number;
  pods: WeeklyShiftPod[];
  refetchQueries?: Array<PureQueryOptions>;
};

const ShiftsWeeklyColumn: React.FC<Props> = ({
  day,
  pods,
  minHour,
  maxHour,
  refetchQueries,
}) => {
  return (
    <Container>
      {pods.map((pod, i) => (
        <ShiftsWeeklyPod
          pod={pod}
          key={`${day.toString() + minHour.toString()}-${i}`}
          day={day}
          minHour={minHour}
          maxHour={maxHour}
          refetchQueries={refetchQueries}
        />
      ))}
    </Container>
  );
};

export default ShiftsWeeklyColumn;
