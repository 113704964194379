import React from 'react';
import { TableRow, isTableDaySubRow, isTableRowTimeSheet } from '../types';
import { TrackedTimeCell } from './TrackedTimeCell';

type Props = {
  row: TableRow;
};

export const WorkedMinutesCell: React.FC<Props> = ({ row }) => {
  if (isTableDaySubRow(row)) {
    return (
      <TrackedTimeCell
        value={row.workedMinutes}
        changeTo={row.workedMinutesWithChanges}
        trackedMinutes={row.trackedMinutes}
        changeToTrackedMinutes={row.trackedMinutesWithChanges}
      />
    );
  } else if (isTableRowTimeSheet(row)) {
    return (
      <TrackedTimeCell
        value={row.workedMinutes}
        changeTo={null}
        trackedMinutes={row.trackedMinutes}
        changeToTrackedMinutes={null}
      />
    );
  } else {
    return null;
  }
};
