import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { FlairLabel } from '../../../../../atomic/atoms/Flairlabel/FlairLabel';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useDashboardCompensatoryTimeQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import { useHourlyAbsenceAmountFormatter } from '../../../hooks/useHourlyAbsenceAmountFormatter';
import classNames from 'classnames';
import './CompensatoryTimeWidget.css';
import LoadingWidget from '../CommonComponents/LoadingWidget';
import { useTimeBalanceForToday } from '../../../components/TimeBalanceForDay/useTimeBalanceForDay';
import { useIsTimeBalanceEnabled } from '../../../hooks/timeTrackingSettings/useIsTimeBalanceEnabled';

const i18Path = 'dashboard.compensatoryTime';

const CompensatoryTimeWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDashboardCompensatoryTimeQuery();
  const isTimeBalanceEnabled = useIsTimeBalanceEnabled();
  const timeBalance = useTimeBalanceForToday();

  if (loading) {
    return <LoadingWidget height={74} />;
  }

  if (!data) {
    return null;
  }

  const compensatoryTime =
    data.me.compensatoryTimeCategory?.allowancesSummary.availableAmount;

  const isTimeBalanceVisible = isTimeBalanceEnabled && timeBalance !== null;

  if (compensatoryTime === undefined && !isTimeBalanceVisible) {
    return null;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <FlairCard contentClassName="py-4 d-flex flex-column gap-3">
      {compensatoryTime !== undefined && (
        <div className="d-flex justify-content-between align-items-center">
          <FlairLabel className="h2-mini" text={t('title')} />
          <BalanceIndicator amount={compensatoryTime} />
        </div>
      )}
      {isTimeBalanceVisible && (
        <div className="d-flex justify-content-between align-items-center">
          <FlairLabel className="h2-mini" text={t('timeBalance')} />
          <BalanceIndicator amount={timeBalance / 60} />
        </div>
      )}
    </FlairCard>
  );
};

const BalanceIndicator: React.FC<{ amount: number }> = ({ amount }) => {
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();
  const formattedHours = hourlyFormatter(Math.abs(amount));
  const isNegative = amount < 0;

  const balance = `${isNegative ? '-' : '+'}${formattedHours}`;
  const variant = isNegative ? 'danger' : 'success';

  return (
    <div
      className={classNames(
        variant,
        'compensatory-time-balance-container',
        'h3',
        'm-0',
      )}>
      {balance}
    </div>
  );
};

export default CompensatoryTimeWidget;
