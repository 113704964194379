import * as React from 'react';
import ModalSidebar from '../../../../../../components/ModalSidebar';
import { useNamespacedTranslation } from '../../../../../../../../hooks/useNamespacedTranslation';
import { PeerFeedback } from '../../types';
import { SelectSingleStyled } from '../../../../../../../../components/Select/SelectSingleStyled';
import { Maybe } from '../../../../../../../../utils/maybe';
import { useState } from 'react';
import { getAnswerIdToPeer, mapSections } from './logic';
import { AnswerProps } from '../../../../../../hooks/useComparedFeedbackSectionsMapper';
import { FeedbackAnswer } from '../../../../../../components/EmployeeFeedback/FeedbackAnswer';
import { FeedbackAnswerSection } from '../../../../../../components/EmployeeFeedback/SideBySideAnswers';
import { EmployeeFeedbackAccordion } from '../../../../../../components/EmployeeFeedbackQuestionnaire/EmployeeFeedbackAccordion';
import './PeerReviewsSidePanel.css';

type Props = {
  visible: boolean;
  handleClose: () => void;
  peerFeedbacks: PeerFeedback[];
  defaultSelectedPeerFeedbackId: Maybe<string>;
};

const i18Path = 'employeePage.performanceReview.peerReviews';

const PeerReviewsSidePanel: React.FC<Props> = ({
  visible,
  handleClose,
  peerFeedbacks,
  defaultSelectedPeerFeedbackId,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const [selectedPeerFeedbackId, setSelectedPeerFeedbackId] = useState<
    Maybe<string>
  >(defaultSelectedPeerFeedbackId);

  const peerOptions = [{ label: t('allPeers'), value: 'ALL' }];

  peerOptions.push(
    ...peerFeedbacks
      .filter((p) => !!p.submittedAt)
      .map((p) => ({
        label: p.reviewer.title,
        value: p.id,
      })),
  );

  let feedbacksToView = peerFeedbacks.filter((p) => !!p.submittedAt);
  if (selectedPeerFeedbackId && selectedPeerFeedbackId !== 'ALL') {
    feedbacksToView = feedbacksToView.filter(
      (feedback) => feedback.id === selectedPeerFeedbackId,
    );
  }

  const allAnswers = feedbacksToView.reduce(
    (allAnswers: ReadonlyArray<AnswerProps>[], current) => {
      return [...allAnswers, current.unmappedAnswers];
    },
    [[]],
  );

  const questions = peerFeedbacks[0].questions;
  const resolutions = peerFeedbacks[0].resolutions;

  const sections = mapSections({
    questions,
    answers: allAnswers,
    resolutions,
  });

  const answerIdToPeerReviewer = getAnswerIdToPeer(peerFeedbacks);

  const getEmployeeName = (answerId: Maybe<string>) => {
    if (answerId) {
      return answerIdToPeerReviewer[answerId]?.title ?? '';
    }

    return '';
  };

  return (
    <ModalSidebar
      show={visible}
      onClose={handleClose}
      header={t('title')}
      size="xl"
      content={
        <div className="p-4">
          <SelectSingleStyled
            options={peerOptions}
            value={peerOptions.find((p) => p.value === selectedPeerFeedbackId)}
            onChange={(newValue) => setSelectedPeerFeedbackId(newValue?.value)}
          />

          <div className="mt-4">
            {sections.map((section) => (
              <EmployeeFeedbackAccordion
                key={section.name}
                title={section.name}
                as="h2"
                className="section-accordion px-4">
                {Object.values(section.resolutions).map((answers) => {
                  return (
                    <EmployeeFeedbackAccordion
                      key={answers[0].question.id}
                      title={answers[0].question.value}
                      as="h4"
                      className="question-accordion px-4">
                      {answers.map((answer) => {
                        const employeeName = getEmployeeName(answer.answers.id);

                        return (
                          <FeedbackAnswerSection
                            questionVisibility={
                              answer.question.questionVisibility
                            }
                            answerVisibility={answer.question.answerVisibility}
                            isAnswerAuthor={false}
                            isEmployeeSection={true}
                            employeeName={employeeName}>
                            <div className="mb-4 feedback-answer-container">
                              <FeedbackAnswer
                                answer={answer.answers}
                                employeeName={employeeName}
                                side="left"
                              />
                            </div>
                          </FeedbackAnswerSection>
                        );
                      })}
                    </EmployeeFeedbackAccordion>
                  );
                })}
              </EmployeeFeedbackAccordion>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default PeerReviewsSidePanel;
