import React, { useContext } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import TimeSheetStatusIndicator from '../../../components/TimeSheet/TimeSheetStatusIndicator';
import { TimeSheetStatus } from '../../../__generated__/graphql';
import { TimeSheet } from '../types';
import styled from '@emotion/styled';
import { formatDateShort, parseDate } from '../../../../../utils/dateUtils';
import Hint from '../../../../../components/hint';
import { TimeSheetApproveContext } from '../../../components/TimeSheetApprove';

type Props = {
  timeSheet: TimeSheet;
};

const TimeSheetApproveStatus: React.FC<Props> = ({ timeSheet }) => {
  const { t } = useTranslation();

  const { approvingIds, approveTimeSheets } = useContext(
    TimeSheetApproveContext,
  );

  const isApproveButtonVisible =
    timeSheet.flair__Approval_Status__c === TimeSheetStatus.Pending;

  const isApproveDisabled =
    parseDate(timeSheet.flair__End_Date__c) > new Date();

  const handleApproveTimeSheet = () => {
    approveTimeSheets([timeSheet.Id]);
  };

  return (
    <Container>
      <TimeSheetStatusIndicator status={timeSheet.flair__Approval_Status__c} />
      {isApproveButtonVisible && (
        <>
          <Button
            className="ms-3 d-none d-md-inline-block"
            label={t('timeTracking.controlling.actions.approve')}
            isProcessing={approvingIds.length > 0}
            disabled={isApproveDisabled}
            onClick={handleApproveTimeSheet}
            variant="primary"
          />
          {isApproveDisabled && (
            <Hint
              id={`tooltip`}
              containerClassName="ms-2 d-flex"
              text={t('timeTracking.controlling.approveModal.disabledReason', {
                endDate: formatDateShort(timeSheet.flair__End_Date__c),
                interpolation: { escapeValue: false },
              })}
            />
          )}
          {!isApproveDisabled && (
            <DropdownButton
              size="sm"
              className="ms-3 d-inline-block d-md-none"
              title={''}
              variant="secondary">
              <Dropdown.Item onClick={handleApproveTimeSheet}>
                {t('timeTracking.controlling.actions.approve')}
              </Dropdown.Item>
            </DropdownButton>
          )}
        </>
      )}
    </Container>
  );
};

export default TimeSheetApproveStatus;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
