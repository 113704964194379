import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { getVisibilityStr } from '../../translations';
import { GoalEmployee, GoalVisibility } from '../../types';
import { GoalLabel } from './GoalLabel';
import { GoalLabelWithTooltip } from './GoalLabelWithTooltip';

type Props = {
  goalId: string;
  visiblity: GoalVisibility;
  sharedWith?: GoalEmployee[]; // When visibility == protected
};

export const GoalVisibilityLabel: React.FC<Props> = ({
  goalId,
  visiblity,
  sharedWith,
}) => {
  const label = getVisibilityStr(visiblity);
  if (visiblity === 'protected' && sharedWith && sharedWith.length) {
    const tooltip = (
      <Tooltip id={`${goalId}-sharedWith`}>
        {sharedWith.map((employee) => (
          <div key={employee.id}>{employee.name}</div>
        ))}
      </Tooltip>
    );
    return (
      <GoalLabelWithTooltip
        tooltip={tooltip}
        icon="eye-filled"
        label={label}
        className="me-3"
      />
    );
  } else {
    return (
      <GoalLabel
        icon="eye-filled"
        label={label}
        className="me-3"
        noWrap={true}
      />
    );
  }
};
