import {
  EmployeeFeedbackOutcome,
  EmployeeFeedbackState,
  EmployeeMeeting,
  FeedbackCycleTimelineTypes,
} from '../../../__generated__/graphql';

export type EmployeeMeetingProps = Pick<
  EmployeeMeeting,
  'flair__Start_Datetime__c'
>;

export type EmployeeFeedbackOutcomeProps = Pick<
  EmployeeFeedbackOutcome,
  'Id' | 'flair__Ended_At__c' | 'flair__Accepted_At__c' | 'CreatedDate'
> & {
  meeting: EmployeeMeetingProps | null;
};

export type Feedback = {
  id: string;
  cycleName: string;
  dueDate: Date | null;
  status: FeedbackStatus;
  reviewer?: string;
  reviewee?: string;
  isEnded: boolean;
  steps: FeedbackStep[];
  assignDate: Date;
  timelineType: FeedbackCycleTimelineTypes;
  employeeFeedbackState: EmployeeFeedbackState | undefined;
  isPeerReviewer: boolean;
};

export type Option = {
  label: string;
  value: string;
};

export type FeedbacksFilter = {
  cycleName: string | null;
  reviewerName: string | null;
  onlyActive: string | null;
  year: string | null;
  status: string | null;
  revieweeName: string | null;
};

export const emptyFeedbackFilter: FeedbacksFilter = {
  cycleName: null,
  reviewerName: null,
  year: null,
  status: null,
  onlyActive: null,
  revieweeName: null,
};

export type Employee = {
  id: string;
  name: string;
  avatarUrl?: string | null;
};

export type FeedbackStatus =
  | FeedbackStatusTypes.InProgress
  | FeedbackStatusTypes.Completed
  | FeedbackStatusTypes.Meeting
  | FeedbackStatusTypes.NotStarted;

/* eslint-disable no-unused-vars */
export enum FeedbackStatusTypes {
  Meeting = 'MEETING',
  Completed = 'COMPLETED',
  InProgress = 'INPROGRESS',
  NotStarted = 'NOTSTARTED',
  /* eslint-enable no-unused-vars */
}

export type FeedbackStep = {
  label: string;
  value: boolean;
};
