import React from 'react';
import Button from '../../../../components/button';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {
  disabled?: boolean;
  selectAllReportsVisible?: boolean;
  removeAllDisabled?: boolean;
  onSelectAllReports?: () => void;
  onRemoveAll: () => void;
};

export const EmployeeByTeamSelectWithButtons: React.FC<Props> = ({
  onSelectAllReports,
  onRemoveAll,
  selectAllReportsVisible,
  disabled,
  removeAllDisabled,
}) => {
  const t = useNamespacedTranslation('components.employeeByTeamSelect');

  return (
    <div className="d-flex">
      {selectAllReportsVisible && onSelectAllReports && (
        <Button
          className="me-2"
          size="sm"
          label={t('selectAllReports')}
          disabled={disabled}
          onClick={onSelectAllReports}
        />
      )}
      <Button
        size="sm"
        variant="danger"
        label={t('removeAll')}
        disabled={disabled || removeAllDisabled}
        onClick={onRemoveAll}
      />
    </div>
  );
};
