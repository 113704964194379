import * as React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Data, Header } from './types';
import Loading from '../../../apps/dashboard/components/Loading';
import './TableModal.css';

export type TableModalProps = {
  title: string;
  show?: boolean;
  onClose: () => void;
  headers: Header[];
  data: Data[];
  loading?: boolean;
};

const TableModal: React.FC<TableModalProps> = ({
  title,
  headers,
  data,
  show = true,
  onClose,
  loading = false,
}) => {
  return (
    <Modal
      scrollable={true}
      enforceFocus={false}
      show={show}
      centered
      onHide={onClose}
      contentClassName="border dashboard-table-modal"
      size="lg">
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{title}</h4>
      </Modal.Header>
      <Table size="sm" className="card-table" responsive>
        <thead>
          <tr>
            {headers.map((h) => (
              <th key={h.key}>{h.title}</th>
            ))}
          </tr>
        </thead>
        <tbody className="dashboard-table-modal-body">
          {loading ? (
            <Loading />
          ) : (
            data.map((c) => (
              <tr key={c.id}>
                {headers.map((h) => (
                  <td key={h.key}>{c[h.key]}</td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Modal>
  );
};

export default TableModal;
