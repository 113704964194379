import React, { useState } from 'react';
import ServerError from '../../../../../components/ServerError';
import PageHeader from '../../../components/PageHeader';
import LineChart from '../charts/LineChart';
import SkeletonGraph from '../../../components/Skeleton/Graph';
import SkeletonPageHeader from '../../../components/Skeleton/PageHeader';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { AbsenceRateTable } from './components/AbsenceRateTable';
import { Card } from 'react-bootstrap';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import {
  AbsenceRatePeriodFilter,
  Period,
} from './components/AbsenceRatePeriodFilter';
import { useMyAbsenceRateReportData, prepareChartData } from './logic';
import { FlairLabel } from '../../../../../atomic/atoms/Flairlabel/FlairLabel';
import { managerRoutes } from '../../../manager/routes';

const i18NavigationPath = 'navigation.menuItems.analytics';
const i18Path = 'analytics.absenceRatePage';

export const AbsenceRatePage: React.FC = () => {
  const tNavigation = useNamespacedTranslation(i18NavigationPath);
  useFlairBreadcrumbHook([
    {
      label: tNavigation('title'),
      url: managerRoutes.analytics.route.create({}),
    },
    { label: tNavigation('submenuItems.absenceRate') },
  ]);

  const t = useNamespacedTranslation(i18Path);

  const [period, setPeriod] = useState<Period>('last6Months');

  const { data, error, loading } = useMyAbsenceRateReportData(period);

  if (error) {
    return <ServerError />;
  }

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!data?.reports?.absenceRate) {
    return null;
  }

  const { categories, seriesData, overallAbsenceRate } = prepareChartData(
    data.reports.absenceRate,
  );

  return (
    <>
      <PageHeader title={t('title')} />
      <Card>
        <Card.Header className="small">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FlairLabel text={t('show')} className="h6-regular me-2-5" />
              <AbsenceRatePeriodFilter value={period} onChange={setPeriod} />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <LineChart
            seriesName={t('percentage')}
            seriesData={seriesData}
            categories={categories}
            anotationsYAxis={overallAbsenceRate ?? 0}
            height={520}
          />
          <AbsenceRateTable categories={categories} absenceRates={seriesData} />
        </Card.Body>
      </Card>
    </>
  );
};

const LoadingSkeleton = () => (
  <>
    <SkeletonPageHeader />
    <SkeletonGraph height={520} />
    <SkeletonTable rows={2} columns={6} />
  </>
);
