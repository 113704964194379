import React from 'react';
import { ManagerEmployeeNotePolarity } from '../../__generated__/graphql';
import FlairIcon, { FlairIconProps } from '../../../../atomic/atoms/FlairIcon';

type Props = Omit<FlairIconProps, 'icon' | 'ref'> & {
  polarity: ManagerEmployeeNotePolarity;
};

export const PolarityIcon: React.FC<Props> = ({ polarity, ...rest }) => {
  switch (polarity) {
    case ManagerEmployeeNotePolarity.Positive: {
      return <FlairIcon {...rest} icon="smile-outline" />;
    }
    case ManagerEmployeeNotePolarity.Neutral: {
      return <FlairIcon {...rest} icon="meh-outline" />;
    }
    case ManagerEmployeeNotePolarity.Negative: {
      return <FlairIcon {...rest} icon="frown-outline" />;
    }
    case ManagerEmployeeNotePolarity.Unknown: {
      return null;
    }
  }
};
