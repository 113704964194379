import { Theme } from '../../../../../theme';

export type GoalsFilterType =
  | 'myGoals'
  | 'colleaguesGoals'
  | 'allGoals'
  | 'myReportsGoals';

export type GoalBase = {
  id: string;
  type: GoalType;
  name: string;
  dueDate: string | null;
  status: GoalStatus;
  progress: number;
  expectedProgress: number;
  visiblity: GoalVisibility;
  sharedWith?: GoalEmployee[]; // When visibility == protected
  keyResults: GoalKeyResult[];
  employee?: GoalEmployee; // undefined if type team or company
  period?: GoalPeriod;
  tags: GoalTag[];
  cancelReason?: string;
  statusChangedBy?: GoalEmployee;
} & GoalRateInfo;

export type GoalKeyResult = {
  id: string;
  name: string;
  type: GoalKeyResultType;
  initialValue: number;
  targetValue: number;
  currentValue: number;
  progress: number;
};

export type GoalPeriod = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
};

export type GoalRateInfo = {
  rate: GoalRate;
  rateEmployee?: GoalEmployee;
  ratedAt?: Date;
};

export type GoalEmployee = {
  id: string;
  name: string;
  initials: string;
  avatarUrl?: string;
};

export type GoalActivity =
  | GoalActivityCreate
  | GoalActivityChangeStatus
  | GoalActivityChangeStatusCanceled
  | GoalActivityChangeStatusToDoneAndRate
  | GoalActivityChangeResponsible
  | GoalActivityChangeResponsibleTeam
  | GoalActivitySetValue
  | GoalActivityKeyResultUpdate
  | GoalMarkAsDraft
  | GoalRemoveDraft
  | GoalKeyResultAddDelete
  | GoalTagAddDelete
  | GoalSharedWithAddDelete;

type GoalActivityCreate = GoalActivityBase & {
  type: 'CREATE';
};

type GoalActivityChangeStatus = GoalActivityBase & {
  type: 'CHANGE_STATUS';
  status: GoalStatus;
};

type GoalActivityChangeResponsible = GoalActivityBase & {
  type: 'CHANGE_RESPONSIBLE';
  from: string;
  to: string;
};

type GoalActivityChangeResponsibleTeam = GoalActivityBase & {
  type: 'CHANGE_RESPONSIBLE_TEAM';
  from: string;
  to: string;
};

type GoalActivityChangeStatusCanceled = GoalActivityBase & {
  type: 'CHANGE_STATUS_CANCELED';
  status: GoalStatus;
  cancelReason?: string;
};

type GoalActivityChangeStatusToDoneAndRate = GoalActivityBase & {
  type: 'CHANGE_STATUS_DONE_AND_RATE';
  status: GoalStatus;
  rate: GoalRate;
};

type GoalActivitySetValue = GoalActivityBase & {
  type: 'SET_DESCRIPTION' | 'SET_TITLE';
  value: string;
};

type GoalActivityKeyResultUpdate = GoalActivityBase & {
  type: 'KEY_RESULT_UPDATE';
  keyResultName: string;
  keyResultValue: string;
  comment?: string;
};

type GoalMarkAsDraft = GoalActivityBase & {
  type: 'MARK_AS_DRAFT';
};

type GoalRemoveDraft = GoalActivityBase & {
  type: 'REMOVE_DRAFT';
};

type GoalKeyResultAddDelete = GoalActivityBase & {
  type: 'KEY_RESULT_ADD' | 'KEY_RESULT_DELETE';
  keyResultName: string;
};

type GoalTagAddDelete = GoalActivityBase & {
  type: 'TAG_ADD' | 'TAG_DELETE';
  name: string;
};

type GoalSharedWithAddDelete = GoalActivityBase & {
  type: 'SHARED_WITH_ADD' | 'SHARED_WITH_DELETE';
  name: string;
};

type GoalActivityBase = {
  id: string;
  employee?: GoalEmployee;
  updatedAt: Date;
};

export type ActivityType =
  | 'CREATE'
  | 'CHANGE_STATUS'
  | 'CHANGE_STATUS_CANCELED'
  | 'CHANGE_STATUS_DONE_AND_RATE'
  | 'CHANGE_RESPONSIBLE'
  | 'CHANGE_RESPONSIBLE_TEAM'
  | 'SET_DESCRIPTION'
  | 'SET_TITLE'
  | 'KEY_RESULT_UPDATE'
  | 'MARK_AS_DRAFT'
  | 'REMOVE_DRAFT'
  | 'KEY_RESULT_ADD'
  | 'KEY_RESULT_DELETE'
  | 'TAG_ADD'
  | 'TAG_DELETE'
  | 'SHARED_WITH_ADD'
  | 'SHARED_WITH_DELETE';

export type GoalId = {
  id: string;
  type: GoalType;
};

export type GoalTag = {
  id: string;
  name: string;
};

// flair__Status__c
export type GoalStatus = 'DRAFT' | 'BEHIND' | 'ON_TRACK' | 'DONE' | 'CANCELED';

export type GoalType = 'employee' | 'team' | 'company';

export type GoalVisibility = 'public' | 'manager' | 'protected';

// flair__Rate__c, 'Open' - default value
export type GoalRate = 'OPEN' | 'NOT_FULFILLED' | 'FULFILLED' | 'EXCEEDED';

export type GoalKeyResultType = 'NUMERIC' | 'BOOLEAN' | 'PERCENTAGE';

export const allVisibilityOptions: GoalVisibility[] = [
  'public',
  'manager',
  'protected',
];

export const allGoalRates: GoalRate[] = [
  'OPEN',
  'NOT_FULFILLED',
  'FULFILLED',
  'EXCEEDED',
];

export const allGoalStatuses: GoalStatus[] = [
  'DRAFT',
  'BEHIND',
  'ON_TRACK',
  'DONE',
  'CANCELED',
];

export const getStatusColor = (status: GoalStatus): string =>
  Theme.goals.statusBar[status].color;

export const getStatusInputColor = (status: GoalStatus): string =>
  Theme.goals.statusInput[status].color;
