import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useSearchEmployeesForMentionLazyQuery } from '../../../__generated__/graphql';
import { debounce } from 'lodash';
import {
  createSpanForMentionedEmployees,
  getCursorPosition,
  getRemovedMentionedEmployeeIds,
  getWindowRange,
  mapSearchedEmployees,
} from '../logic';
import { MentionEmployee } from '../types';
import { CommentContext } from '../Context/CommentContext';
import { Maybe } from '../../../../../utils/maybe';

const useEmployeeMentionHook = (
  range: Maybe<Range>,
  setRange: React.Dispatch<React.SetStateAction<Maybe<Range>>>,
  setMentionedEmployees: React.Dispatch<
    React.SetStateAction<MentionEmployee[]>
  >,
) => {
  const [mentionEmployeeSearchTerm, setMentionEmployeeSearchTerm] =
    useState<string>('');
  const [startDetectingUsersInput, setStartDetectingUsersInput] =
    useState<boolean>(false);
  const [ref, setRef] = useState<React.RefObject<HTMLDivElement>>();
  const { recordId } = useContext(CommentContext);

  const [
    searchEmployeesForMention,
    {
      data: searchEmployeesForMentionData,
      loading: searchEmployeesForMentionLoading,
      error: searchEmployeesForMentionError,
    },
  ] = useSearchEmployeesForMentionLazyQuery();

  useEffect(() => {
    debounce(() => {
      searchEmployeesForMention({
        variables: {
          filter: {
            searchTerm: mentionEmployeeSearchTerm,
            recordId,
          },
        },
      }).then((res) => {
        if (
          res.data?.searchEmployeesForMention.length === 0 &&
          /\s+$/.test(mentionEmployeeSearchTerm)
        ) {
          setStartDetectingUsersInput(false);
          setMentionEmployeeSearchTerm('');
        }
      });
    }, 500)();
  }, [mentionEmployeeSearchTerm, recordId, searchEmployeesForMention]);

  const mentionEmployees = mapSearchedEmployees(
    searchEmployeesForMentionData?.searchEmployeesForMention ?? [],
  );

  const handleOnInputForMention = (oldText: string) => {
    if (ref?.current) {
      //Remove mentioned employees if exist
      const removedMentionedEmployeeIds = getRemovedMentionedEmployeeIds(
        oldText,
        ref.current.innerHTML,
      );

      setMentionedEmployees((prevMentionedEmployees) =>
        prevMentionedEmployees.filter(
          (e) => !removedMentionedEmployeeIds.has(e.id),
        ),
      );
    }
  };

  const handleOnKeyUpForMention = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const key = e.key;

    if (ref?.current) {
      if (key === 'Backspace') {
        if (startDetectingUsersInput) {
          //This case when the user removed the @ character
          if (mentionEmployeeSearchTerm.length === 0) {
            setStartDetectingUsersInput(false);
          } else {
            const searchTermWithRemovedLastCharacter =
              mentionEmployeeSearchTerm.substring(
                0,
                mentionEmployeeSearchTerm.length - 1,
              );

            setMentionEmployeeSearchTerm(searchTermWithRemovedLastCharacter);
          }
        }
      }
    }
  };

  const handleOnKeyDownForMention = (
    e: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    const key = e.key;

    if (ref?.current) {
      if (key === '@') {
        setStartDetectingUsersInput(true);
        setMentionEmployeeSearchTerm('');
        setRange(getWindowRange());
      } else if (
        //key.length === 1 is used to detect if the key is a character or number
        //and to ignore other keyboard keys like shift, ctrl, alt, etc
        startDetectingUsersInput &&
        key.length === 1
      ) {
        setMentionEmployeeSearchTerm(mentionEmployeeSearchTerm + key);
      }
    }
  };

  const handleOnMentionEmployee = (
    employee: MentionEmployee,
    setText: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const currentRef = ref?.current as HTMLDivElement;
    if (currentRef) {
      const mentionTextWithTag = `@${mentionEmployeeSearchTerm}`;

      // Create the span for the mentioned employee
      const span = createSpanForMentionedEmployees(employee, recordId);
      const replacedContent = `${span.outerHTML}&nbsp;`; // Add a space after the span

      const cursorPosition = getCursorPosition(currentRef, range);

      if (range) {
        // If the startContainer is a text node, split it to separate the content before and after the range
        const contentBeforeRange = currentRef.innerHTML.slice(
          0,
          cursorPosition,
        );
        const contentAfterRange = currentRef.innerHTML.slice(cursorPosition);

        // Find the mentionTextWithTag preceding the current range
        const lastMentionIndex =
          contentBeforeRange.lastIndexOf(mentionTextWithTag);

        if (lastMentionIndex !== -1) {
          // Remove or replace the last occurrence of mentionTextWithTag
          const updatedContentBeforeRange = contentBeforeRange.substring(
            0,
            lastMentionIndex,
          );

          currentRef.innerHTML = `${updatedContentBeforeRange}${replacedContent}${contentAfterRange}`;
        }
      }

      setStartDetectingUsersInput(false);
      setMentionEmployeeSearchTerm('');
      setText(currentRef.innerHTML);
      setCursorAtTheEnd(currentRef);

      setMentionedEmployees((prevMentionedEmployees) => [
        ...prevMentionedEmployees,
        employee,
      ]);
    }
  };

  return {
    setRef,
    mentionEmployeeSearchTerm,
    setMentionEmployeeSearchTerm,
    startDetectingUsersInput,
    setStartDetectingUsersInput,
    mentionEmployees,
    searchEmployeesForMentionLoading,
    searchEmployeesForMentionError,
    handleOnKeyUpForMention,
    handleOnKeyDownForMention,
    handleOnMentionEmployee,
    handleOnInputForMention,
  };
};

const setCursorAtTheEnd = (ref: HTMLDivElement) => {
  //Set the cursor at the end
  const newRange = document.createRange();
  const newSelection = window.getSelection();

  // Select all child nodes of the editable div
  const childNodes = Array.from(ref.childNodes);

  // Place the cursor after the last childNode
  if (childNodes.length > 0) {
    newRange.setStartAfter(childNodes[childNodes.length - 1]);
    newRange.setEndAfter(childNodes[childNodes.length - 1]);

    newSelection?.removeAllRanges();
    newSelection?.addRange(newRange);
  }

  ref.focus();
};

export default useEmployeeMentionHook;
