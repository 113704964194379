import React, { useCallback } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { ControlledInput } from '../../../../components/form/Input';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import {
  TimeEntryNotesFormData,
  useTimeEntryNotesForm,
} from '../../hooks/useTimeEntryNotesForm';
import { useUpdateMyTimeEntryNotesMutation } from '../../__generated__/graphql';

type Props = {
  timeEntryId: string;
  notes: string | null;
};

//TODO: Test this case!
const NotesFormListGroupItem = React.forwardRef<HTMLFormElement, Props>(
  ({ notes, timeEntryId }, ref) => {
    const { t } = useTranslation();
    const { form, fieldName } = useTimeEntryNotesForm({ notes: notes });
    const [updateNotes] = useUpdateMyTimeEntryNotesMutation();
    const errorHandler = useMutationErrorHandler();

    const onSubmit = useCallback(
      async (form: TimeEntryNotesFormData) => {
        await updateNotes({
          variables: {
            input: {
              id: timeEntryId,
              notes: form.notes || null,
            },
          },
        }).catch(errorHandler);
      },
      [timeEntryId, updateNotes, errorHandler],
    );

    const onCancel = useCallback(() => {
      form.reset();
    }, [form]);

    const showButtons = form.formState.dirty;

    return (
      <ListGroup.Item>
        <FormContext {...form}>
          <form ref={ref} onSubmit={form.handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <ControlledInput
                  name={fieldName}
                  type="textarea"
                  minRows={4}
                  size="sm"
                />
              </Col>
            </Row>
            {showButtons && (
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <SubmitButton size="sm">
                    {t('form.general.saveButton')}
                  </SubmitButton>
                  <Button
                    type="reset"
                    className="ms-3"
                    size="sm"
                    label={t('form.general.cancelButton')}
                    variant="outline-secondary"
                    onClick={onCancel}
                  />
                </Col>
              </Row>
            )}
          </form>
        </FormContext>
      </ListGroup.Item>
    );
  },
);

export default NotesFormListGroupItem;
