import React from 'react';
import { Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { WorkflowItemStatus } from '../WorkflowItems/types';
import { WorkflowItemStatusComponent } from './WorkflowItemStatus';

export const WorkflowItemStatusFieldReadonly: React.FC<{
  status: WorkflowItemStatus;
}> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Form.Label>
        {t('performanceReview.workflowsItems.sidebar.task')}
      </Form.Label>
      <div>
        <WorkflowItemStatusComponent status={status} />
      </div>
    </FormGroup>
  );
};
