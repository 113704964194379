import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeOption, EmployeeOptionLabel } from './EmployeeOptionLabel';
import {
  SelectSingleBase,
  RenderOptionParams,
  Props as SelectSingleBaseProps,
} from './SelectSingleBase';

export type Props = Omit<
  SelectSingleBaseProps<EmployeeOption>,
  'renderOption'
> & {
  options: EmployeeOption[];
  employeeOptionLabelClassName?: string;
  hideSelectedOptions?: boolean;
};

export const SelectSingleEmployee: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const renderOption = useCallback(
    ({ option }: RenderOptionParams<EmployeeOption>) => {
      return (
        <EmployeeOptionLabel
          employee={option}
          isNameVisible={true}
          className={props.employeeOptionLabelClassName}
        />
      );
    },
    [props.employeeOptionLabelClassName],
  );
  return (
    <SelectSingleBase
      {...props}
      renderOption={renderOption}
      placeholder={
        props.placeholder ?? t('select.selectSingleEmployee.placeholder')
      }
      noOptionsMessage={
        props.noOptionsMessage ?? t('select.selectSingleEmployee.noOptions')
      }
    />
  );
};
