import React from 'react';
import CancelButton from '../components/CancelButton';
import SubmitButton from '../components/SubmitButton';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

type Props = {
  mode: 'approve' | 'submit';
  isInProgress: boolean;
  isSaving: boolean;
  isRejecting: boolean;
  isApproving: boolean;
  submitDisabled: boolean;
  onCancel: () => void;
  onReject: () => void;
};

export const TimeEntryManagerButtons: React.FC<Props> = ({
  mode,
  isInProgress,
  submitDisabled,
  isSaving,
  isRejecting,
  isApproving,
  onCancel,
  onReject,
}) => {
  const t = useNamespacedTranslation('timeTrackingNew.dayCard');

  const renderButtons = () => {
    return (
      <>
        <CancelButton
          disabled={isInProgress}
          onClick={onCancel}
          title={t('cancel')}
        />
        <SubmitButton
          disabled={submitDisabled}
          isProcessing={isSaving}
          title={t('saveChanges')}
        />
      </>
    );
  };

  const renderApproveButtons = () => {
    return (
      <>
        <CancelButton
          disabled={isInProgress}
          isProcessing={isRejecting}
          onClick={onReject}
          title={t('reject')}
        />
        <SubmitButton
          disabled={isInProgress}
          isProcessing={isApproving}
          title={t('approve')}
        />
      </>
    );
  };

  switch (mode) {
    case 'approve':
      return renderApproveButtons();
    case 'submit':
      return renderButtons();
    default:
      return null;
  }
};
