import * as React from 'react';

const DownloadOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M10.4992 5.49951H11.7489C12.0804 5.49951 12.3982 5.63118 12.6326 5.86554C12.867 6.09991 12.9986 6.41777 12.9986 6.74922V13.2477C12.9986 13.5791 12.867 13.897 12.6326 14.1314C12.3982 14.3657 12.0804 14.4974 11.7489 14.4974H4.25068C3.91924 14.4974 3.60137 14.3657 3.36701 14.1314C3.13264 13.897 3.00098 13.5791 3.00098 13.2477V6.74922C3.00098 6.41777 3.13264 6.09991 3.36701 5.86554C3.60137 5.63118 3.91924 5.49951 4.25068 5.49951H5.50039"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49707 8.49884L7.99648 10.9982L10.4959 8.49884M7.99648 1.50049V10.4984"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default DownloadOutlineIcon;
