import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

type Item = {
  key: string;
  name: React.ReactNode;
  value: React.ReactNode;
};

type Props = {
  items: Item[];
  itemsToShow?: number;
  actionLink?: React.ReactNode;
};

type ListItemProps = {
  item: Item;
};

const ListItem: React.FC<ListItemProps> = ({ item }) => (
  <Row>
    <Col className="text-truncate">{item.name}</Col>
    <Col className={classNames('col-auto', 'text-end')}>{item.value}</Col>
  </Row>
);

const ActionLink: React.FC = ({ children }) => <div>{children}</div>;

const LightList: React.FC<Props> = ({ items, itemsToShow = 3, actionLink }) => {
  const itemsToRender = items.slice(0, itemsToShow);

  return (
    <div className={classNames('d-grid', 'gap-2', 'fs-5')}>
      {itemsToRender.map((i) => (
        <ListItem key={i.key} item={i} />
      ))}
      {actionLink && <ActionLink>{actionLink}</ActionLink>}
    </div>
  );
};

export default LightList;
