import React from 'react';
import { Card } from 'react-bootstrap';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import FiltersLoading from './FiltersLoading';

const Loading: React.FC = () => (
  <Card>
    <Card.Header>
      <FiltersLoading />
    </Card.Header>
    <SkeletonTable className="card-table" columns={5} rows={7} />
  </Card>
);

export default Loading;
