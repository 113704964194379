import * as React from 'react';

const CallOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.0919 11.6852C13.5958 11.1853 12.3942 10.4558 11.8112 10.1618C11.052 9.77942 10.9895 9.74818 10.3928 10.1915C9.99478 10.4874 9.73015 10.7517 9.26432 10.6523C8.7985 10.553 7.78624 9.99281 6.89988 9.10927C6.01353 8.22572 5.42086 7.1841 5.32119 6.71983C5.22153 6.25556 5.49021 5.99406 5.78327 5.5951C6.1963 5.03273 6.16506 4.939 5.81201 4.1798C5.53677 3.58932 4.78601 2.39897 4.28425 1.90534C3.7475 1.37515 3.7475 1.46888 3.40164 1.6126C3.12008 1.73106 2.84995 1.87507 2.59465 2.04281C2.09477 2.37492 1.81733 2.65079 1.62331 3.06538C1.4293 3.47997 1.34213 4.45193 2.34408 6.27212C3.34603 8.09232 4.04899 9.02304 5.50396 10.4739C6.95893 11.9249 8.07773 12.705 9.71328 13.6223C11.7366 14.7554 12.5126 14.5346 12.9285 14.3408C13.3443 14.1471 13.6214 13.8722 13.9542 13.3723C14.1223 13.1175 14.2667 12.8476 14.3853 12.5663C14.5293 12.2217 14.6231 12.2217 14.0919 11.6852Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default CallOutlineIcon;
