import { feedbackStatus, isFeedbackEnded } from '../helpers';
import { FeedbackProps } from '../types';

import { Feedback, FeedbackStatusTypes, FeedbackStep } from './types';

export const mapFeedback = (feedback: FeedbackProps): Feedback => {
  const status = feedbackStatus(feedback);
  return {
    id: feedback.Id,
    cycleName: feedback.feedbackCycle.Name,
    dueDate: feedback.flair__Deadline__c
      ? new Date(feedback.flair__Deadline__c)
      : null,
    status,
    reviewer: feedback.outcome.reviewer.Name,
    reviewee: feedback.reviewee.Name,
    isEnded: isFeedbackEnded(feedback),
    steps: mapStep(feedback, status),
    assignDate: new Date(feedback.CreatedDate),
    timelineType: feedback.feedbackCycle.flair__Timeline_Type__c,
    employeeFeedbackState: feedback.employeeFeedback?.flair__State__c,
    isPeerReviewer: feedback.flair__Is_Peer_Reviewer__c ?? false,
  };
};

export function mapStep(
  feedback: FeedbackProps,
  feedbackStatus: FeedbackStatusTypes,
) {
  const isWithoutReviewer = feedback.feedbackCycle.flair__Without_Reviewer__c;
  const firstStep: FeedbackStep = {
    label: 'assessments',
    value: feedbackStatus !== FeedbackStatusTypes.NotStarted,
  };

  if (isWithoutReviewer) {
    return [firstStep];
  }

  const secondStep: FeedbackStep = {
    label: 'meeting',
    value:
      feedbackStatus === FeedbackStatusTypes.Meeting ||
      feedbackStatus === FeedbackStatusTypes.Completed,
  };

  const thirdStep: FeedbackStep = {
    label: 'signature',
    value: feedbackStatus === FeedbackStatusTypes.Completed,
  };

  return [firstStep, secondStep, thirdStep];
}
