import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Col, Container as BootstrapContainer, Row } from 'react-bootstrap';
import {
  JobCandidate,
  useCandidateDetailsQuery,
} from '../../../__generated__/graphql';
import Loading from '../Loading';
import PageHeader from '../../../components/PageHeader';
import Resume from './Resume';
import MyFeedback from './MyFeedback';
import TeamFeedback from './TeamFeedback';
import { useUserInfo } from '../../../context/UserInfo';
import { routes } from '../../../routes';

import CandidateInfo from './CandidateInfo';
import './CandidateDetails.css';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { recruitmentRoutes } from '../../routes';
import { CandidateFlairAiCard } from './FlairAI/CandidateFlairAiCard';

type RouteParams = {
  candidateId: string;
  jobId?: string;
};

type Params = {
  candidate: JobCandidate;
};

type Props = {
  candidate: JobCandidate;
  children: React.ReactNode;
};

const i18Path = 'recruitment.candidate.detailsPage';

const Container: React.FC<Props> = ({ children, candidate }) => {
  const t = useNamespacedTranslation(i18Path);
  const tRecruitmentNavigation = useNamespacedTranslation(
    'navigation.menuItems.recruitment',
  );
  const history = useHistory();

  const goBackButtonAction = () => {
    history.goBack();
  };

  useFlairBreadcrumbHook([
    { label: tRecruitmentNavigation('title') },
    {
      label: tRecruitmentNavigation('subMenuItems.jobs.title'),
      url: recruitmentRoutes.jobsList.route.create({}),
    },
    {
      label:
        candidate.job?.Name ?? tRecruitmentNavigation('unsolicitedApplication'),
      url: candidate.job
        ? recruitmentRoutes.jobCandidates.route.create({
            jobId: candidate.job.Id,
          })
        : '',
    },
    { label: candidate.flair__Full_Name__c },
  ]);

  return (
    <BootstrapContainer>
      <PageHeader
        goBackButtonAction={goBackButtonAction}
        title={t('title')}
        actions={
          <CommentsPopover
            recordId={candidate.Id}
            relatedObjectName={RelatedObjectNames.Candidate}
            mode="button"
            commentsCount={candidate.commentsCount}
          />
        }></PageHeader>
      {children}
    </BootstrapContainer>
  );
};

const Content: React.FC<Params> = ({ candidate }) => {
  const { id: myId } = useUserInfo();

  if (!candidate.candidateEvaluations) {
    return <></>;
  }
  return (
    <Row>
      <Col xl={8}>
        <CandidateInfo candidate={candidate} />
        <MyFeedback
          candidateEvaluations={candidate.candidateEvaluations?.filter(
            (candidateEvaluation) =>
              candidateEvaluation.flair__Employee__c === myId,
          )}
        />

        <CandidateFlairAiCard candidate={candidate}></CandidateFlairAiCard>
        <TeamFeedback candidateEvaluations={candidate.candidateEvaluations} />
      </Col>
      <Col xl={4}>
        <Resume candidate={candidate} />
      </Col>
    </Row>
  );
};

const CandidateDetails = () => {
  const { candidateId } = useParams<RouteParams>();

  const { data, loading, error } = useCandidateDetailsQuery({
    variables: {
      id: candidateId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.candidate) {
    return <Redirect to={routes.home.route.create({})} />;
  }

  return (
    // TODO
    <Container candidate={data.candidate as JobCandidate}>
      <Content candidate={data.candidate as JobCandidate} />
    </Container>
  );
};

export default CandidateDetails;
