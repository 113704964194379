import React from 'react';
import EmptyStateCard from '../../components/EmptyStateCard';
import RequestAbsenceButton from '../../components/RequestAbsenceButton';
import { MyAbsencesQuery } from '../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type CategoryType = MyAbsencesQuery['me']['absenceCategories'][0];

type Props = {
  categories: ReadonlyArray<CategoryType>;
};

const i18Path = 'absences.cards.noAbsences';
const NoAbsencesCard: React.FC<Props> = ({ categories }) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <EmptyStateCard
      title={t('headline')}
      action={
        <RequestAbsenceButton
          buttonLabel={t('addAnAbsence')}
          categoryId={categories[0]?.Id ?? ''}
          categories={categories}
        />
      }
    />
  );
};

export default NoAbsencesCard;
