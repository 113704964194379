import React from 'react';
import { useParams } from 'react-router-dom';
import { Inventory } from '../../../pages/MyData/Inventory/Inventory';
import { managerRoutes } from '../../routes';
import { emptyInventoryFilter } from '../../../pages/MyData/common';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { useInventoryListQuery } from '../../../__generated__/graphql';
import { mapInventoryListFilterInput } from '../../../pages/MyData/Inventory/Filters/mappings';
import { mapSortBy } from '../../../pages/MyData/Inventory/mappings';
import { getCurrentLanguage } from '../../../../../locales/locale';

type RouteParams = {
  employeeId: string;
};

const PAGE_SIZE = 50;

export const EmployeeInventory: React.FC = () => {
  const { employeeId } = useParams<RouteParams>();
  const locale = getCurrentLanguage();
  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: emptyInventoryFilter,
    storageKey: 'employee_inventory_filter',
    createRouteUrl: (queryParams) =>
      managerRoutes.employeeInventory.route
        .withQueryParams(queryParams)
        .create({ employeeId }),
  });

  const { data, loading, error } = useInventoryListQuery({
    variables: {
      employeeId: employeeId,
      lang: locale,
      filter: mapInventoryListFilterInput(tableState.filter),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });

  return (
    <Inventory
      employeeId={employeeId}
      pageSize={PAGE_SIZE}
      data={data}
      loading={loading}
      error={error}
      isManager={true}
      tableStateFilter={tableState.filter}
      tableStateSortBy={tableState.sortBy}
      tableStatePageIndex={tableState.pageIndex}
      handleFilterChange={handleFilterChange}
      handlePageIndexChanged={handlePageIndexChanged}
      handleSortByChanged={handleSortByChanged}
    />
  );
};
