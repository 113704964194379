import React, { useMemo } from 'react';
import InventoryFiltersLoading from './InventoryFiltersLoading';
import ServerError from '../../../../../../components/ServerError';
import { InventoryFilters } from './InventoryFilters';
import { OptionBase } from '../../../../../../components/Select/types';
import { useInventoryFilterQuery } from '../../../../__generated__/graphql';
import { InventoryFilter } from './types';

type Props = {
  filter: InventoryFilter;
  onChange: (filter: InventoryFilter) => void;
};
export const InventoryFiltersConnected: React.FC<Props> = ({
  filter,
  onChange,
}) => {
  const { data, loading, error } = useInventoryFilterQuery();

  const categories = useMemo<OptionBase[]>(() => {
    return (
      data?.inventoryCategories
        .map((category) => ({
          value: category.Name,
          label: category.Name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [data]);

  const locations: OptionBase[] = useMemo(() => {
    return (
      data?.locations.map((location) => ({
        value: location.Id,
        label: location.Name,
      })) || []
    );
  }, [data]);

  if (loading) {
    return <InventoryFiltersLoading />;
  }
  if (error) return <ServerError />;

  return (
    <InventoryFilters
      categoryOptions={categories}
      locationOptions={locations}
      filter={filter}
      onChange={onChange}
    />
  );
};
