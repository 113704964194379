import { AttachmentFile } from './InventorySidebarModal/types';

export const allInventoryStatuses = [
  'APPROVED',
  'PENDING',
  'REJECTED',
] as const;

export type InventoryStatus = (typeof allInventoryStatuses)[number];

export const inventoryCategories = [
  'App & Software',
  'Electronic Device',
  'Office Equipment',
  'Other',
  'Uniforms & Workwear',
  'Vehicle',
] as const;

export type InventoryCategory = (typeof inventoryCategories)[number];
export type EmptyReason = 'empty' | 'filtered-empty';

export type InventoryListItem = {
  id: string;
  name: string | null;
  inventory: Inventory;
  location: InventoryLocation | null;
  createdOn: string;
  category: Category;
  status: InventoryStatus;
  commentsCount: number;
};

export type Category = {
  name: string | null;
};

export type Inventory = {
  id: string;
  name: string;
  manufacturer: DictionaryItem | null;
  model: string | null;
  supportVideoUrl: string | null;
  supportInstruction: string | null;
  supportAdvisor: InventorySupportAdvisor | null;
};

export type InventoryItemEmployee = {
  id: string;
  name: string;
  avatarUrl: string | undefined;
};

export type DictionaryItem = {
  id: string;
  name: string;
  label: string | null;
};

export type InventoryLocation = {
  id: string;
  name: string;
};

export type InventorySupportAdvisor = {
  id: string;
  name: string;
  avatarUrl: string | undefined;
};

export type InventoryItem = InventoryItemBase & {
  serialNumber: string | null;
  appCatDownloadOrConnectLink: string | null;
  appCatEditor: string | null;
  appCatVersion: string | null;
  warrantyInformation: string | null;
  warrantyDate: string | null;
  vehiclePlateNumber: string | null;
  vehicleModelYear: number | null;
  vehicleInsurancePolicyNumber: string | null;
  vehicleInsuranceCompanyContact: string | null;
  vehicleInsuranceCompany: string | null;
  vehicleIdentificationVIN: string | null;
  uniformCatSize: string | null;
  vehicleFuelType: DictionaryItem | null;
  uniformCatSizeSystem: DictionaryItem | null;
  uniformCatType: DictionaryItem | null;
  electronicsCatOperatingSystem: DictionaryItem | null;
  attachments: AttachmentFile[] | null;
};

export type InventoryItemBase = {
  id: string;
  name: string | null;
  purchaseDate: string | null;
  purchasePrice: number | null;
  location: InventoryLocation | null;
  employee: InventoryItemEmployee | null;
  inventory: Inventory;
  category: Category;
  notes: string | null;
  status: InventoryStatus;
  currency: string | null;
};

export type InventoryItemRequest = {
  flair__Electronics_Cat_Operating_System__c: string | null;
  flair__Employee__c: string | null;
  flair__Location__c: string | null;
  flair__Serial_Number__c: string | null;
  flair__Purchase_Date__c: string | null;
  flair__Net_Purchase_Price__c: number | null;
  flair__Warranty_Date__c: string | null;
  flair__Warranty_Information__c: string | null;
  flair__Notes__c: string | null;
  RecordTypeId: string;
  flair__App_Cat_Download_Or_Connect_Link__c: string | null;
  flair__App_Cat_Editor__c: string | null;
  flair__App_Cat_Version__c: string | null;
  flair__Supplier__c: string | null;
  flair__Uniform_Cat_Size__c: string | null;
  flair__Uniform_Cat_Size_System__c: string | null;
  flair__Uniform_Cat_Type__c: string | null;
  flair__Vehicle_Cat_Fuel_Type__c: string | null;
  flair__Vehicle_Cat_Identification_Number_VIN__c: string | null;
  flair__Vehicle_Cat_Insurance_Company__c: string | null;
  flair__Vehicle_Cat_Insurance_Company_Contact__c: string | null;
  flair__Vehicle_Cat_Insurance_policy_number__c: string | null;
  flair__Vehicle_Cat_Model_Year__c: number | null;
  flair__Vehicle_Cat_Serial_Plate_Number__c: string | null;
};

// types are the current supported RecordTypes of the InventoryItem
export type SupportedInventoryCategory =
  | 'Electronic Device'
  | 'Office Equipment'
  | 'Other';
