import React from 'react';
import { TableRow, TableRowTimeSheet } from './types';
import { EmployeeCell } from './EmployeeCell';
import { EmployeeDetailsPopover } from '../Popovers/EmployeeDetailsPopover/EmployeeDetailsPopover';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import { getCellMarginClass } from '../../../components/TimeSheetsTable/helpers';
import {
  PeriodCell,
  TimeSheetRowCell,
} from '../../../components/TimeSheetsTable';
import { ManagerDayRowCell } from './ManagerDayRowCell';
import { SubRowDaysLoading } from '../../../components/TimeSheetsTable/Cells/SubRowDaysLoading';

type Props = {
  row: TableRow;
  isExpanded: boolean;
  onExpandedChanged: (
    timeSheetRow: TableRowTimeSheet,
    expanded: boolean,
  ) => void;
};

export const MainCell: React.FC<Props> = ({
  row,
  isExpanded,
  onExpandedChanged,
}) => {
  const renderCell = () => {
    const { rowType } = row;
    switch (rowType) {
      case 'header-week':
        return <PeriodCell period={row.headerWeek} />;
      case 'header-employee':
        return (
          <div className="d-flex align-items-center gap-2">
            <EmployeeCell employee={row.headerEmployee} />
            <EmployeeDetailsPopover
              rowId={row.id}
              employeeId={row.headerEmployee.id}
              employeeName={row.headerEmployee.name}
            />
          </div>
        );
      case 'row-employee':
        return (
          <TimeSheetRowCell
            isExpanded={isExpanded}
            onExpandedChanged={(newExpanded) =>
              onExpandedChanged(row, newExpanded)
            }
            period={row.period}
            employeeName={row.employee.name}
            employeeId={row.employee.id}
            timeSheetId={row.timeSheetId}>
            <div className="d-flex align-items-center gap-2">
              <Link to={routes.timeSheet.route} timeSheetId={row.timeSheetId}>
                <EmployeeCell employee={row.employee} />
              </Link>
              <EmployeeDetailsPopover
                rowId={row.id}
                employeeId={row.employee.id}
                employeeName={row.employee.name}
              />
            </div>
          </TimeSheetRowCell>
        );
      case 'row-period':
        return (
          <TimeSheetRowCell
            isExpanded={isExpanded}
            onExpandedChanged={(newExpanded) =>
              onExpandedChanged(row, newExpanded)
            }
            period={row.period}
            employeeName={row.employee.name}
            employeeId={row.employee.id}
            timeSheetId={row.timeSheetId}>
            <Link to={routes.timeSheet.route} timeSheetId={row.timeSheetId}>
              <PeriodCell period={row.period} />
            </Link>
          </TimeSheetRowCell>
        );
      case 'subrow-day-loading':
        return <SubRowDaysLoading />;
      case 'subrow-day-loading-error':
        return <span className="ms-4 text-danger">{row.error}</span>;
      case 'subrow-day':
        return (
          <ManagerDayRowCell
            className="ms-4"
            day={row.day}
            originalDayStartDateTime={row.originalDayStartDateTime}
            hasTimeEntryChangeRequests={row.dayChangeRequestIds.length > 0}
            hasEntriesWithNotes={row.hasEntriesWithNotes}
            timeSheetId={row.timeSheetId}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className={getCellMarginClass(row.rowType) ?? ''}>{renderCell()}</div>
  );
};
