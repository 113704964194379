import { TFunc } from '../../../../../../../hooks/useNamespacedTranslation';
import React from 'react';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import { ControlledSwitchInput } from '../../../../../../../components/form/SwitchInput';
import { FieldValues } from 'react-hook-form';

type Props = {
  t: TFunc;
  onFieldChange: (step: FieldValues) => void;
  formFieldValues: FieldValues | null;
};

export const StayAnonymousFormField: React.FC<Props> = ({
  t,
  onFieldChange,
  formFieldValues,
}) => {
  const handleFieldChange = (isStayAnonymous: string) => {
    onFieldChange({
      ...formFieldValues,
      stayAnonymous: isStayAnonymous,
      preview: {
        ...formFieldValues?.preview,
        stayAnonymous: isStayAnonymous,
      },
    });
  };
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-row align-items-center gap-1">
        <h5 className="h5-regular mb-0">{t('form.fields.stayAnonymous')}</h5>
        <FlairIcon
          icon="alert-circle-outline"
          tooltip={t('form.fields.stayAnonymousTooltip')}
          tooltipPlacement="bottom"
          size="sm"
        />
      </div>
      <ControlledSwitchInput
        className="mb-0"
        onChange={handleFieldChange}
        disabled={formFieldValues?.shareWithManager}
        name="stayAnonymous"></ControlledSwitchInput>
    </div>
  );
};
