import React from 'react';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';
import { routes as mainRoutes } from '../../routes';
import { emptyPersonalDocumentFilter } from './types';
import { useUserInfo } from '../../context/UserInfo';
import { DocumentPageConnected } from './Components/DocumentsPageConnected';

export const PersonalDocuments: React.FC = () => {
  const { id: meId } = useUserInfo();

  const [filter, setFilter] = useFlairPersistentFilter({
    defaultFilter: emptyPersonalDocumentFilter,
    storageKey: 'personal_document_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.documentsPersonal.route
        .withQueryParams(queryParams)
        .create({}),
  });

  return (
    <DocumentPageConnected
      employeeId={meId}
      filter={filter}
      setFilter={setFilter}
    />
  );
};
