import { useUserInfo } from '../../../context/UserInfo';
import { useCallback, useState } from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { isFolderNameValid } from '../common';
import { useToasts } from '../../../../../context/Toast';
import {
  NavigatableErrorCode,
  NavigatableIdInput,
  useRenameDocumentMutation,
} from '../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';

const i18Path = 'documents2.personal';

type Row = { name: string };

export const useDocumentRename = () => {
  const { id: meId } = useUserInfo();
  const [name, setName] = useState<string | null>(null);
  const [rowToRename, setRowToRename] = useState<
    (NavigatableIdInput & Row) | null
  >(null);
  const t = useNamespacedTranslation(i18Path);
  const { addWarning } = useToasts();
  const [renameDocument, { loading: renameInProgress }] =
    useRenameDocumentMutation();
  const errorHandler = useMutationErrorHandler();

  const handleOpenRenameModal = useCallback((row: any) => {
    setName(row.name);
    setRowToRename(() => {
      return { ...row };
    });
  }, []);
  const handleCloseRenameModal = useCallback(() => {
    setRowToRename(null);
    setName(null);
  }, []);

  const handleSetNewName = (value: string) => {
    setName(value);
  };

  const handleRenameModalClose = () => {
    resetState();
  };

  const resetState = () => {
    setName(null);
    setRowToRename(null);
  };

  const rename = useCallback(() => {
    if (!name || !rowToRename) {
      return;
    }

    if (!isFolderNameValid(name)) {
      addWarning(t('errors.invalidFolderName'));
      return;
    }

    renameDocument({
      variables: {
        employeeId: meId,
        input: {
          name: name,
          navigatableId: { id: rowToRename.id, type: rowToRename.type },
        },
      },
    })
      .then((error) => {
        if (
          error.data?.document.rename.errorCode &&
          error.data?.document.rename.errorCode ===
            NavigatableErrorCode.NameAlreadyExists
        ) {
          addWarning(t('errors.nameAlreadyExists', { name: name }));
          return;
        }
      })
      .catch(errorHandler)
      .finally(() => {
        resetState();
      });
  }, [addWarning, errorHandler, meId, name, renameDocument, rowToRename, t]);

  return {
    rename,
    renameInProgress,
    setName,
    name,
    handleOpenRenameModal,
    handleCloseRenameModal,
    handleRenameModalClose,
    rowToRename,
    handleSetNewName,
  };
};
