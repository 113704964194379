import { useEffect, useState } from 'react';
import { isTableRowTimeSheet, TableRow, TableRowTimeSheet } from '../types';
import {
  ProjectTimeEntriesFilterInput,
  ProjectsTimeSheetDetailsFragment,
  ProjectsTimeSheetDetailsFragmentDoc,
  useProjectsTimeSheetDetailsPrefetchLazyQuery,
} from '../../../../__generated__/graphql';
import { ApolloCache } from '@apollo/client';
import { useApolloCache } from '../../../../hooks/useApolloCache';

export const useProjectsTimeSheetDetailsPrefetch = (
  tableRows: TableRow[],
  projectsTimeEntriesQueryFilter: ProjectTimeEntriesFilterInput,
) => {
  const cache = useApolloCache();
  const notLoadedSubRowsTimeSheetIds = tableRows
    .filter(
      (x): x is TableRowTimeSheet =>
        isTableRowTimeSheet(x) &&
        !isSubRowsLoaded(cache, x.id, projectsTimeEntriesQueryFilter),
    )
    .map((x) => x.timeSheetId);

  const [prefetchTimeSheets] = useProjectsTimeSheetDetailsPrefetchLazyQuery();
  const [prefetchedIds, setPrefetchedIds] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const idsToPrefetch = notLoadedSubRowsTimeSheetIds.filter(
    (x) => !prefetchedIds.includes(x),
  );

  useEffect(() => {
    if (inProgress || !idsToPrefetch.length) {
      return;
    }
    setInProgress(true);
    prefetchTimeSheets({
      variables: {
        ids: idsToPrefetch,
        projectTimeEntriesFilter: projectsTimeEntriesQueryFilter,
      },
    })
      .then((queryResult) => {
        // it is more safe to use queryResult.variables because I spotted that sometimes prefetchTimeSheets could be canceled
        // and the idsToPrefetch will contains wrong values from the first canceled request
        const prefetchedIds = queryResult.variables?.ids;
        if (!prefetchedIds) {
          return;
        }
        setPrefetchedIds((prev) => [...prev, ...prefetchedIds]);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [
    idsToPrefetch,
    prefetchTimeSheets,
    setPrefetchedIds,
    inProgress,
    setInProgress,
    projectsTimeEntriesQueryFilter,
  ]);
};

const isSubRowsLoaded = (
  cache: ApolloCache<object>,
  timeSheetId: string,
  projectsTimeEntriesQueryFilter: ProjectTimeEntriesFilterInput,
): boolean => {
  // there is a room for optimization there, we can store already loaded id
  const timeSheetCacheId: string | undefined = cache.identify({
    __typename: 'TimeSheet',
    Id: timeSheetId,
  });
  if (!timeSheetCacheId) {
    return false;
  }
  const cacheRes = cache.readFragment<ProjectsTimeSheetDetailsFragment>({
    id: timeSheetCacheId,
    fragment: ProjectsTimeSheetDetailsFragmentDoc,
    fragmentName: 'ProjectsTimeSheetDetails',
    variables: {
      projectTimeEntriesFilter: projectsTimeEntriesQueryFilter,
    },
  });
  return cacheRes !== null;
};
