import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  inputContainerRef: React.RefObject<HTMLElement>;
};

const ScrollToError: React.FC<Props> = ({ name, inputContainerRef }) => {
  const {
    errors,
    formState: { submitCount, isValid },
  } = useFormContext();

  useEffect(() => {
    if (!isValid) {
      const keys = Object.keys(errors);
      if (keys[0] === name) {
        inputContainerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        inputContainerRef.current
          ?.querySelector('input')
          ?.focus({ preventScroll: true });
      }
    }
  }, [submitCount, isValid, errors, name, inputContainerRef]);

  return null;
};

export default ScrollToError;
