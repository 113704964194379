import React from 'react';
import Button from '../../../../../components/button';
import { TimeSheetStatus } from '../../../__generated__/graphql';
import { TableRowTimeSheet } from './types';
import { useTranslation } from 'react-i18next';

type Props = {
  timeSheetRow: TableRowTimeSheet;
  onApprove: (timeSheetRow: TableRowTimeSheet) => void;
};

export const ApproveStatusCell: React.FC<Props> = ({
  timeSheetRow,
  onApprove,
}) => {
  const { t } = useTranslation();

  switch (timeSheetRow.approveStatus) {
    case TimeSheetStatus.Pending:
      return (
        <Button
          label={t(`timeSheetsControlling.approve`)}
          variant="outline-primary"
          size="sm"
          isProcessing={timeSheetRow.approving}
          onClick={() => onApprove(timeSheetRow)}
        />
      );
    case TimeSheetStatus.Approved:
      return (
        <span className="text-success">
          {t(`timeSheet.status.${timeSheetRow.approveStatus.toLowerCase()}`)}
        </span>
      );
    case TimeSheetStatus.Skipped:
    case TimeSheetStatus.Unknown:
      return (
        <span>
          {t(`timeSheet.status.${timeSheetRow.approveStatus.toLowerCase()}`)}
        </span>
      );
    default:
      return null;
  }
};
