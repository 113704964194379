import { DashboardBirthdayColleagueFieldsFragment } from '../../../__generated__/graphql';
import { WidgetEmployee } from '../CommonComponents/WidgetSections/types';
import { mapCommonColleagueFields } from '../common/mappings';
import { formatWidgetDate } from '../common/logic';

export const mapBirthday = (
  colleague: DashboardBirthdayColleagueFieldsFragment,
): WidgetEmployee => {
  return {
    ...mapCommonColleagueFields(colleague),
    additionalInfo: formatWidgetDate(
      colleague.flair__Birthday_this_year__c ?? '',
    ),
    date: new Date(colleague.flair__Birthday_this_year__c as string),
  };
};
