import styled from '@emotion/styled';
import React, { useMemo, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { AutoSaveIndicator } from '../../../../../components/form/AutoSaveIndicator';
import Hint from '../../../../../components/hint';
import { Theme } from '../../../../../theme';
import { QuestionnaireInput } from '../QuestionnaireInput';
import {
  ChoiceOption,
  onUpdateAnswerRet,
  onUpdateNotesRet,
  QuestionnaireType,
} from '../types';
import { NotRequiredAnswer } from './NotRequiredAnswer';
import { debounce } from 'lodash';
import StringToHtml from '../../../../../components/StringToHtml';
import { DEFAULT_DEBOUNCE_TIME } from '../../../../../utils/time';

export type IQuestionnaireQuestion = {
  id: string;
  questionText: string | null;
  description: string | null;
  hint: string | null;
  choices: ReadonlyArray<ChoiceOption>;
  sectionId: string | null;
  type: QuestionnaireType;
  value: string | null;
  notes: string | null;
  isOptional: boolean;
  isComplete: boolean;
  requiredChoices: ReadonlyArray<string>;
  showOptionalComment: boolean;
  showOnlyQuestionName?: boolean;
};

export type IQuestionAnswer = Pick<
  IQuestionnaireQuestion,
  'id' | 'type' | 'isOptional' | 'isComplete' | 'sectionId' | 'value' | 'notes'
>;

export type QuestionnaireFieldProps = IQuestionnaireQuestion & {
  onUpdateAnswer: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateAnswerRet;
  onUpdateNotes: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateNotesRet;
  readOnly: boolean;
};

const FieldContainer = styled.div({
  marginBottom: '2.25rem',
});

const HintSpan = styled.span({
  color: Theme.color.gray2,
});

export const QuestionnaireField: React.FC<QuestionnaireFieldProps> = ({
  onUpdateAnswer,
  onUpdateNotes,
  ...questionObject
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [currentValue, setCurrentValue] = useState<string>(
    questionObject.value ?? '',
  );
  const [currentState, setCurrentState] = useState<string>(
    questionObject.notes ?? '',
  );

  const debouncedOnUpdateAnswer = useMemo(
    () =>
      debounce((questionInfo: IQuestionAnswer, value: string) => {
        Promise.resolve(onUpdateAnswer(questionInfo, value)).then(() => {
          setLoading(false);
        });
      }, DEFAULT_DEBOUNCE_TIME),
    [onUpdateAnswer],
  );
  const debouncedOnUpdateNotes = useMemo(
    () =>
      debounce((questionInfo: IQuestionAnswer, value: string) => {
        Promise.resolve(onUpdateNotes(questionInfo, value)).then(() => {
          setLoading(false);
        });
      }, 1000),
    [onUpdateNotes],
  );

  const onChange = (value: string) => {
    setCurrentValue(value);
    setLoading(true);
    debouncedOnUpdateAnswer(questionObject, value);
  };

  const onChangeNotes = (notes: string) => {
    setCurrentState(notes);
    setLoading(true);
    debouncedOnUpdateNotes(questionObject, notes);
  };

  const {
    isOptional,
    isComplete,
    showOptionalComment,
    description,
    questionText,
    choices,
    requiredChoices,
    type,
    id,
    hint,
    readOnly,
    showOnlyQuestionName,
  } = questionObject;

  return (
    <FieldContainer>
      <Form.Label className="row align-items-center">
        <Col>
          <h3 className="mb-0">
            {!isOptional && <abbr className="text-danger me-1">*</abbr>}
            <span>{questionText}</span>
            {hint && (
              <HintSpan className="ms-1">
                <Hint id={`hint_${id}`} text={hint} />
              </HintSpan>
            )}
          </h3>
        </Col>
        {!readOnly && (
          <Col className="col-auto align-self-start">
            <AutoSaveIndicator loading={loading} completed={isComplete} />
          </Col>
        )}
      </Form.Label>
      {description && (
        <Form.Text>
          <span>
            <StringToHtml text={description} />
          </span>
        </Form.Text>
      )}

      {!showOnlyQuestionName ? (
        <QuestionnaireInput
          id={questionObject.id}
          value={currentValue || ''}
          onChange={onChange}
          choices={choices}
          requiredChoices={requiredChoices}
          showOptionalComment={showOptionalComment}
          type={type}
          notes={currentState || ''}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
        />
      ) : (
        <NotRequiredAnswer />
      )}
    </FieldContainer>
  );
};
