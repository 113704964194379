import {
  Day,
  TimeEntryProps,
  AbsenceProps,
  HolidayProps,
  TimeEntryChangeRequestProps,
  TimeSheetDayProps,
  CalendarPeriodProps,
} from './types';
import {
  ApprovalStatus,
  WorkloadDayFragment,
  LoomVideoFragment,
  TimeFrameworkFragment,
  TimeSheet,
  WorkloadWeekFragment,
} from '../../../__generated__/graphql';
import { TrackedTime, calcWarnings } from '../../TrackedTime';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';

import { getISODay } from 'date-fns';
import { parseDate } from '../../../../../utils/dateUtils';
import { IsoWeekDay, getWorkloadDay } from '../../AutoBreaks/mappings';
import { calculateDayCurrentTimeEntries } from './logic';
import { getAutoBreakRules } from '../../AutoBreaks/getAutoBreakRules';
import { Maybe } from '../../../../../utils/maybe';
import { mapLoomRecordVideo } from '../../LoomVideo/mappings';

export type TimeSheetProps = Pick<
  TimeSheet,
  | 'Id'
  | 'flair__Employee__c'
  | 'flair__Start_Date__c'
  | 'flair__End_Date__c'
  | 'flair__Approval_Status__c'
> & {
  trackedTime: TrackedTime;
  timeEntries: ReadonlyArray<TimeEntryProps>;
  paidAbsences: ReadonlyArray<AbsenceProps>;
  holidays: ReadonlyArray<HolidayProps>;
  calendarPeriods: ReadonlyArray<CalendarPeriodProps>;
  timeEntryChangeRequests?: ReadonlyArray<TimeEntryChangeRequestProps>;
  workloads: ReadonlyArray<WorkloadProps>;
  timeSheetDays: ReadonlyArray<TimeSheetDayProps>;
};

type WorkloadProps = {
  Id: string;
  flair__Start_Date__c: string;
  flair__End_Date__c: string | null;
  timeFramework: TimeFrameworkFragment | null;
  workloadWeeks: ReadonlyArray<WorkloadWeekFragment>;
};

export type TimeSheetDay = {
  id: string;
  commentsCount: number;
  loomVideo: Maybe<LoomVideoFragment>;
};

export const useTimeSheetDays = (timeSheet: TimeSheetProps): Day[] =>
  useMemo(
    () => calculateDays(timeSheet, timeSheet.timeEntryChangeRequests || []),
    [timeSheet],
  );

const calculateDays = (
  timeSheet: TimeSheetProps,
  timeEntryChangeRequests: ReadonlyArray<TimeEntryChangeRequestProps>,
): Day[] => {
  const days: Day[] = [];
  var currDate = moment(timeSheet.flair__Start_Date__c);
  var lastDate = moment(moment(timeSheet.flair__End_Date__c)).startOf('day');

  const dateToTimeSheetDayIdMap: Record<string, TimeSheetDay> = {};

  timeSheet.timeSheetDays.forEach(
    (t) =>
      (dateToTimeSheetDayIdMap[t.flair__Day__c] = {
        id: t.Id,
        commentsCount: t.commentsCount,
        loomVideo: t.loomVideo,
      }),
  );

  while (currDate <= lastDate) {
    const timeEntries = filterTimeEntries(timeSheet.timeEntries, currDate);
    const timeEntriesChangeRequests = filterChangeRequests(
      timeEntryChangeRequests,
      currDate,
    );

    const timeSheetDay = dateToTimeSheetDayIdMap[currDate.format('YYYY-MM-DD')];

    days.push({
      day: currDate.clone(),
      timeSheetId: timeSheet.Id,
      timeSheetDayId: timeSheetDay.id,
      commentsCount: timeSheetDay.commentsCount,
      timeEntries,
      warnings: null,
      absences: filterAbsences(timeSheet.paidAbsences, currDate),
      holidays: filterHolidays(timeSheet.holidays, currDate),
      calendarPeriods: filterCalendarPeriods(
        timeSheet.calendarPeriods,
        currDate,
      ),
      timeEntriesChangeRequests,
      currentEntries: calculateDayCurrentTimeEntries(
        timeEntries,
        timeEntriesChangeRequests,
      ),
      workload: getWorkload(timeSheet, currDate),
      autoBreakRules: getAutoBreakRules(timeSheet.workloads, currDate.toDate()),
      loomVideo: mapLoomRecordVideo(timeSheetDay.loomVideo),
    });
    currDate.add(1, 'days');
  }
  updateDaysWarnings(days, timeSheet);
  return days;
};

const filterTimeEntries = (
  timeEntries: ReadonlyArray<TimeEntryProps>,
  currDate: Moment,
) =>
  timeEntries.filter((timeEntry) =>
    moment(timeEntry.flair__Start_Datetime__c).isSame(currDate, 'day'),
  );

export const filterAbsences = (
  paidAbsences: ReadonlyArray<AbsenceProps>,
  currDate: Moment,
) =>
  paidAbsences.filter(
    (a) =>
      moment(a.flair__Start_Date__c).isSameOrBefore(currDate, 'day') &&
      moment(a.flair__End_Date__c + ' ' + a.flair__End_Time__c)
        .subtract(1, 'second')
        .isSameOrAfter(currDate, 'day'),
  );

export const filterHolidays = (
  holidays: ReadonlyArray<HolidayProps>,
  currDate: Moment,
) => holidays.filter((h) => moment(h.flair__Day__c).isSame(currDate, 'day'));

export const filterCalendarPeriods = (
  calendarPeriods: ReadonlyArray<CalendarPeriodProps>,
  currDate: Moment,
) =>
  calendarPeriods.filter(
    (period) =>
      moment(period.flair__Start_Date__c).isSameOrBefore(currDate, 'day') &&
      moment(period.flair__End_Date__c).isSameOrAfter(currDate, 'day'),
  );

const filterChangeRequests = (
  timeEntryChangeRequests: ReadonlyArray<TimeEntryChangeRequestProps>,
  currDate: Moment,
) =>
  timeEntryChangeRequests?.filter(
    (req) =>
      req.flair__Status__c === ApprovalStatus.Pending &&
      moment(req.flair__Start_Datetime__c).isSame(currDate, 'day'),
  ) ?? [];

const updateDaysWarnings = (days: Day[], timesheet: TimeSheetProps) => {
  const warnings = calcWarnings(timesheet.trackedTime);
  days.forEach((day) => {
    const hasWarnings =
      warnings.workingHoursExceeded.find((trackedDay) =>
        moment(trackedDay.day).isSame(day.day, 'day'),
      ) !== undefined;
    day.warnings = hasWarnings ? { exceededWorkingHours: true } : null;
  });
};

const getWorkloadForDate = (
  timeSheet: TimeSheetProps,
  currDate: Moment,
): TimeSheetProps['workloads'][0] | null => {
  return (
    timeSheet.workloads.find(
      (workload) =>
        moment(workload.flair__Start_Date__c).isSameOrBefore(currDate, 'day') &&
        (!workload.flair__End_Date__c ||
          moment(workload.flair__End_Date__c).isSameOrAfter(currDate, 'day')),
    ) ?? null
  );
};

const getWorkload = (
  timeSheet: TimeSheetProps,
  currDate: Moment,
): WorkloadDayFragment | null => {
  let workloadDay = null;
  const workloadWeek = getWorkloadForDate(timeSheet, currDate);

  if (workloadWeek && workloadWeek.workloadWeeks?.length > 0) {
    workloadDay = getWorkloadDay(
      workloadWeek.workloadWeeks[0],
      getISODay(parseDate(currDate.format('YYYY-MM-DD'))) as IsoWeekDay,
    );
  }
  return workloadDay;
};
