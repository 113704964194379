import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Props as SelectDropdownProps } from '../../Select/CreatableDropdown';
import { getError } from '../utils';
import InputError from '../InputError';
import { CreatableDropdown } from '../../Select/CreatableDropdown';

type Props = Omit<SelectDropdownProps, 'value' | 'onChange'> & {
  name: string;
  onSelect?: (value: string | null) => void;
};

export const CreatableDropdownControlled: React.FC<Props> = ({
  name,
  onSelect,
  ...props
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  return (
    <>
      <Controller
        as={({ value, onChange }) => {
          const handleChange = (value: string | null) => {
            onChange(value);
            if (onSelect) onSelect(value);
          };

          return (
            <CreatableDropdown
              {...props}
              className={[props.className, error ? 'is-invalid' : null]
                .filter((x) => !!x)
                .join(' ')}
              value={value}
              onChange={handleChange}
              error={error !== undefined}
            />
          );
        }}
        name={name}
        control={control}
      />
      <InputError error={error} />
    </>
  );
};
