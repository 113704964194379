import React from 'react';
import { AbsenceApprovalRequestToMeQuery } from '../../__generated__/graphql';
import { Card, ListGroup, Row, Col } from 'react-bootstrap';
import AbsenceAmount from '../Absences/components/AbsenceAmount';
import AbsenceDateTime from '../Absences/components/AbsenceDateTime';
import CardHeader from '../../components/CardHeader';
import { useTranslation } from 'react-i18next';

type Props = {
  absences: AbsenceApprovalRequestToMeQuery['me']['absenceRequestToMe']['absence']['overlappingAbsences'];
};

const OverlappingAbsences: React.FC<Props> = ({ absences }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>{t('absenceRequest.overlappingAbsences.title')}</CardHeader>
      <Card.Body>
        {absences.length > 0 ? (
          <ListGroup variant="flush" className="my-n4">
            {absences.map((absence, i) => (
              <ListGroup.Item key={i}>
                <Row className="align-items-center">
                  <Col>
                    <h4>{absence.employee.Name}</h4>
                    <p className="card-text small text-muted mb-1">
                      {absence.flair__Category_Name__c}&nbsp;(
                      <AbsenceAmount
                        type={absence.flair__Type__c}
                        amount={absence.flair__Working_Amount__c}
                        variant="work"
                      />
                      )
                    </p>
                    <p className="card-text small text-muted">
                      <AbsenceDateTime
                        date={absence.flair__Start_Date__c}
                        time={absence.flair__Start_Time__c}
                        type={absence.flair__Type__c}
                        halfDay={absence.flair__Start_Date_Half_Day__c}
                      />
                      &nbsp;-&nbsp;
                      <AbsenceDateTime
                        date={absence.flair__End_Date__c}
                        time={absence.flair__End_Time__c}
                        type={absence.flair__Type__c}
                        halfDay={absence.flair__End_Date_Half_Day__c}
                      />
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p className="text-center">
            {t('absenceRequest.overlappingAbsences.emptyState')}
          </p>
        )}
      </Card.Body>
    </Card>
  );
};

export default OverlappingAbsences;
