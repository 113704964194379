import classNames from 'classnames';
import React from 'react';
import {
  Col,
  ColProps,
  OverlayTrigger,
  Row,
  RowProps,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { i18prefix } from '../helpers';
import { minutesToHoursAndMinutes } from '../../../../../utils/time';

type Props = {
  timeTrackedInMinutes: number;
  estimationInHours: number;
  overtime: number | null;
};

export const ProjectTimeEntriesInfo: React.FC<Props> = ({
  timeTrackedInMinutes,
  estimationInHours,
  overtime,
}) => {
  const { t } = useTranslation();
  const [hours, minutes] = minutesToHoursAndMinutes(timeTrackedInMinutes);

  return (
    <StyledRow className="no-gutters">
      <TimeTrackedCol className="text-end py-3 px-4">
        <div className="d-inline-block text-center">
          <InfoLabel>{t(`${i18prefix}.sidebar.timeTracked`)}</InfoLabel>
          <p className="h3 m-0">
            {t(`${i18prefix}.sidebar.timeTrackedFormat`, {
              hours,
              minutes,
            })}
          </p>
        </div>
      </TimeTrackedCol>
      <Col className="py-3 px-4">
        <div className="d-inline-block text-center">
          <InfoLabel>{t(`${i18prefix}.sidebar.exceeded`)}</InfoLabel>
          <div className="h3 m-0">
            {overtime !== null ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="item-tooltip">
                    {t(`${i18prefix}.sidebar.overtimeTooltipMessage`, {
                      estimation: estimationInHours,
                    })}
                  </Tooltip>
                }>
                <span>
                  {t(`${i18prefix}.sidebar.overtimeFormat`, {
                    overtime: overtime > 0 ? overtime : 0,
                  })}
                </span>
              </OverlayTrigger>
            ) : (
              <span>{t('general.na')}</span>
            )}
          </div>
        </div>
      </Col>
    </StyledRow>
  );
};

const StyledRow = ({ className, ...rest }: RowProps) => (
  <Row
    className={classNames('border', 'border-primary', 'rounded', className)}
    {...rest}
  />
);

const TimeTrackedCol = ({ className, ...rest }: ColProps) => (
  <Col
    className={classNames('border-end', 'border-primary', className)}
    {...rest}
  />
);

const InfoLabel = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={classNames('h5', 'fw-normal', 'text-uppercase', className)}
    {...rest}
  />
);
