import React from 'react';
import { InventoryOther } from '../types';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { EmployeeWithAvatar } from '../../../../../components/Employee';
import { InventoryItemFieldRow } from './InventoryItemFieldRow';
import { InventoryStatusSidebar } from './InventoryStatusSidebar';
import { InventoryStatus } from '../../types';
import { useCurrencyFormatter } from '../../../../../../../hooks/useCurrencyFormatter';

type Props = {
  inventoryItem: InventoryOther;
  isManager: boolean;
  isStatusUpdateLoading: boolean;
  handleStatusUpdate: (status: InventoryStatus) => void;
};
const i18Path = 'myData.inventory.sidebarModal.information';

export const InventoryOtherFields: React.FC<Props> = ({
  inventoryItem,
  isManager,
  isStatusUpdateLoading,
  handleStatusUpdate,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const formatCurrency = useCurrencyFormatter();

  return (
    <div className="d-flex flex-column gap-4">
      <InventoryItemFieldRow
        label={t('category')}
        value={inventoryItem.category.name}
      />

      {inventoryItem.inventory.manufacturer && (
        <InventoryItemFieldRow
          label={t('manufacturer')}
          value={inventoryItem.inventory.manufacturer.label}
        />
      )}
      <InventoryItemFieldRow
        label={t('type')}
        value={inventoryItem.inventory.name}
      />
      {inventoryItem.inventory.model && (
        <InventoryItemFieldRow
          label={t('brand')}
          value={inventoryItem.inventory.model}
        />
      )}
      <InventoryItemFieldRow
        label={t('serialNumber')}
        value={inventoryItem.serialNumber}
      />
      {inventoryItem.purchasePrice && (
        <InventoryItemFieldRow
          label={t('purchasePrice')}
          value={
            inventoryItem.currency
              ? formatCurrency({
                  value: inventoryItem.purchasePrice,
                  currency: inventoryItem.currency,
                })
              : inventoryItem.purchasePrice
          }
        />
      )}
      {inventoryItem.purchaseDate && (
        <InventoryItemFieldRow
          label={t('purchaseDate')}
          value={inventoryItem.purchaseDate}
        />
      )}
      {inventoryItem.location && (
        <InventoryItemFieldRow
          label={t('location')}
          value={inventoryItem.location?.name}
        />
      )}
      {inventoryItem.employee && (
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('responsible')}</h4>
          <EmployeeWithAvatar
            employee={{
              name: inventoryItem.employee.name,
              avatarUrl: inventoryItem.employee.avatarUrl,
            }}></EmployeeWithAvatar>
        </div>
      )}
      {inventoryItem.notes && (
        <InventoryItemFieldRow label={t('note')} value={inventoryItem.notes} />
      )}
      {inventoryItem.status && (
        <InventoryStatusSidebar
          hasUpdateAccess={isManager}
          isLoading={isStatusUpdateLoading}
          handleStatusUpdate={handleStatusUpdate}
          label={t('status')}
          status={inventoryItem.status}
        />
      )}
    </div>
  );
};
