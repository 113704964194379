import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ReactFlow, {
  MiniMap,
  ReactFlowInstance,
  ReactFlowProvider,
  useEdgesState,
  useKeyPress,
  useNodesState,
  useReactFlow,
} from 'react-flow-renderer';
import { Node } from 'react-flow-renderer/dist/esm/types';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Theme } from '../../../../theme';
import { EmployeeInfoSidebar } from '../../components/EmployeeInfoSidebar';
import { DetailsFilter } from './Actions/DetailsFilter';
import { OrgChartIconButton } from './Actions/OrgChartIconButton';
import EmployeeNode from './EmployeeNode';
import { getChildrenIds, getNodesAndEdges } from './helper';
import './index.css';
import { Search } from './Search';
import { EmployeeDetail, OrgChartEmployee } from './types';

type Props = {
  employees: OrgChartEmployee[];
};

const nodeTypes = {
  employeeNode: EmployeeNode,
};

export const Content: React.FC<Props> = ({ employees }) => {
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const { setCenter } = useReactFlow();
  const fullScreenHandle = useFullScreenHandle();
  const [selectedEmployee, setSelectedEmployee] =
    useState<OrgChartEmployee | null>(null);

  const [modifiedEmployees, setModifiedEmployees] = useState<
    OrgChartEmployee[]
  >([]);
  const [searchedId, setSearchedId] = useState<string | null>(null);
  const [searchMode, setSearchMode] = useState<string>('focus');
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [detailsSettings] = useState<EmployeeDetail[]>([
    'name',
    'avatar',
    'position',
    'department',
  ]);

  //Equal is used to handle some keyboards that have a key with two flavor (=, +)
  //Same for Minus
  const plusKeyboardPressed = useKeyPress(['Equal', 'NumpadAdd']);
  const subtractKeyboardPressed = useKeyPress(['Minus', 'NumpadSubtract']);

  const [selectedSettings, setSelectedSettings] =
    useState<string[]>(detailsSettings);

  useEffect(() => {
    setModifiedEmployees(employees);
  }, [employees]);

  useEffect(() => {
    const { nodes, edges } = getNodesAndEdges(
      modifiedEmployees,
      selectedSettings,
    );
    setNodes(nodes);
    setEdges(edges);
  }, [
    modifiedEmployees,
    setEdges,
    setNodes,
    selectedSettings,
    reactFlowInstance,
  ]);

  useEffect(() => {
    if (searchedId !== null && searchMode === 'focus') {
      let searchedNode = nodes.find((n) => n.data.employee.id === searchedId);
      if (searchedNode != null) {
        // @ts-ignore
        const x = searchedNode.position.x + searchedNode.width / 2;
        // @ts-ignore
        const y = searchedNode.position.y + searchedNode.height / 2;
        const zoom = 3;
        setCenter(x, y, { zoom, duration: 1000 });
      }
    }
  }, [nodes, searchMode, searchedId, setCenter]);

  useEffect(() => {
    if (searchedId !== null && searchMode === 'find') {
      let employee = employees.find((e) => e.id === searchedId);
      employee!.isCollapsed = true;
      setModifiedEmployees([employee!]);
    } else {
      setModifiedEmployees(employees);
    }
  }, [employees, searchedId, searchMode]);

  useEffect(() => {
    reactFlowInstance?.zoomIn();
  }, [reactFlowInstance, plusKeyboardPressed]);

  useEffect(() => {
    reactFlowInstance?.zoomOut();
  }, [reactFlowInstance, subtractKeyboardPressed]);

  const collapse = (node: Node) => {
    const childrenIds = getChildrenIds(node, nodes, edges, []);
    setModifiedEmployees((employees) => {
      const filteredEmployees = employees.filter(
        (e) => !childrenIds.includes(e.id),
      );
      filteredEmployees.find((e) => e.id === node.id)!.isCollapsed = true;
      return filteredEmployees;
    });
  };

  const expand = (node: Node) => {
    const children = employees.filter((e) => e.manager?.id === node.id);
    children.map((c) => (c.isCollapsed = true));
    setModifiedEmployees((employees) => {
      employees.find((e) => e.id === node.id)!.isCollapsed = false;
      return employees.concat(children);
    });
  };

  const isClickOnExpandCollapse = (_event: React.MouseEvent) => {
    // @ts-ignore
    const { dataset, tagName } = _event.target;
    return (
      dataset.id === 'no-of-employees-container' ||
      tagName === 'path' ||
      tagName === 'svg'
    );
  };

  const onNodeClick = (_event: React.MouseEvent, node: Node) => {
    if (isClickOnExpandCollapse(_event)) {
      if (node.data.employee.isCollapsed) {
        expand(node);
      } else {
        collapse(node);
      }
    } else {
      setSelectedEmployee(node.data.employee);
    }
  };

  const onCloseSidebar = () => setSelectedEmployee(null);

  return (
    <FullScreen handle={fullScreenHandle}>
      <Card>
        <Card.Header>
          <div
            className={classNames(
              'd-flex',
              'justify-content-between',
              'align-items-center',
              'small',
            )}>
            <Search
              employees={employees}
              searchedId={searchedId}
              setSearchedId={setSearchedId}
              searchMode={searchMode}
              setSearchMode={setSearchMode}
            />
            <div
              className={classNames('d-flex', 'align-items-center', 'gap-4')}>
              <DetailsFilter
                detailsSettings={detailsSettings}
                selectedIds={selectedSettings}
                onSelectedIdsChange={setSelectedSettings}
              />
              <div
                className={classNames('d-flex', 'align-items-center', 'gap-3')}>
                {fullScreenHandle.active ? (
                  <OrgChartIconButton
                    icon="compress-outline"
                    onClick={() => fullScreenHandle.exit()}
                  />
                ) : (
                  <OrgChartIconButton
                    icon="expand-outline"
                    onClick={() => fullScreenHandle.enter()}
                  />
                )}
                <OrgChartIconButton
                  icon="remove-outline"
                  onClick={() => reactFlowInstance?.zoomOut()}
                />
                <OrgChartIconButton
                  icon="add-outline"
                  onClick={() => reactFlowInstance?.zoomIn()}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{ height: fullScreenHandle.active ? '100vh' : '75vh' }}>
          <ReactFlow
            style={{ cursor: 'grab' }}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            onInit={setReactFlowInstance}
            fitView>
            <MiniMap nodeColor={Theme.color.blue3} nodeStrokeWidth={1} />
          </ReactFlow>
        </Card.Body>

        <EmployeeInfoSidebar
          show={!!selectedEmployee}
          onClose={onCloseSidebar}
          employee={selectedEmployee}
        />
      </Card>
    </FullScreen>
  );
};

export const OrgChart: React.FC<Props> = (props) => (
  <ReactFlowProvider>
    <Content {...props} />
  </ReactFlowProvider>
);
