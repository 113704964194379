import { parseISO } from 'date-fns';
import { toISODateOnly } from '../../../../../../utils/dateUtils';
import {
  CreateEmployeeObjectiveInput,
  CreateObjectiveKeyResultInput,
  CreateTeamObjectiveInput,
  EmployeeObjectiveDetailsFragment,
  ObjectiveCurrentStatusType,
  ObjectiveDetailsFragment,
  ObjectiveIdUpdateInput,
  ObjectiveQuery,
  StringUpdateInput,
  SyncObjectiveKeyResultInput,
  UpdateEmployeeObjectiveFieldsInput,
  UpdateObjectiveStatusInput,
  UpdateTeamObjectiveFieldsInput,
} from '../../../../__generated__/graphql';
import {
  fromKeyResultType,
  fromRate,
  fromStatus,
  fromVisibility,
  mapGoalType,
  mapKeyResult,
  mapRate,
  mapVisibility,
  toObjectiveIdInput,
} from '../mappings';
import { GoalId, GoalStatus } from '../types';
import {
  ObjectiveFormData,
  GoalKeyResult,
  EmployeeObjectiveFormData,
  TeamObjectiveFormData,
  updateEmployeeObjectiveFieldsInputKeys,
  updateTeamObjectiveFieldsInputKeys,
  GoalStatusWithRate,
  GoalSidebarMode,
  GoalStatusEx,
} from './types';
import i18next from 'i18next';

const i18prefix = 'performanceReview.goals2';

export const mapToObjectiveFormData = (
  src: Exclude<ObjectiveQuery['objective'], null>,
): ObjectiveFormData => {
  switch (src.__typename) {
    case 'EmployeeObjective':
      return {
        ...mapCommonFormData(src),
        type: 'employee',
        employeeId: src.flair__Responsible__c ?? '',
        manualProgress: src.flair__Manual_Progress__c,
        goalOnTrackAutoState: getGoalOnTrackAutoState(
          src.flair__Current_Status__c,
        ),
        keyResults: src.keyResults.map(mapKeyResult),
        statusWithRate: mapToGoalStatusWithRate(src),
        parentObjectiveId: src.parentObjective
          ? {
              id: src.parentObjective.Id,
              type: mapGoalType(src.__typename),
            }
          : null,
      };
    case 'TeamObjective':
      return {
        ...mapCommonFormData(src),
        type: 'team',
        teamId: src.flair__Team__c ?? '',
        manualProgress: src.flair__Manual_Progress__c,
        goalOnTrackAutoState: getGoalOnTrackAutoState(
          src.flair__Current_Status__c,
        ),
        keyResults: src.keyResults.map(mapKeyResult),
        statusWithRate: mapToGoalStatusWithRate(src),
        parentObjectiveId: src.parentObjective
          ? {
              id: src.parentObjective.Id,
              type: mapGoalType(src.__typename),
            }
          : null,
      };
    // We are presenting Company objective as autogoal..
    //TODO: Test how company works
    case 'CompanyObjective':
      return {
        ...mapCommonFormData(src),
        goalOnTrackAutoState: getGoalOnTrackAutoState(
          src.flair__Current_Status__c,
        ),
        statusWithRate: {
          status: mapToGoalStatusExFromAuto(src.flair__Current_Status__c),
          rate: 'OPEN',
        },
        type: 'company',
      };
  }
  throw new Error('Not implemented yet');
};

const mapCommonFormData = (src: ObjectiveDetailsFragment) => ({
  visibility: mapVisibility(src.flair__Visibility__c),
  sharedWithEmployeeIds: src.sharedWith.map((x) => x.Id),
  timePeriodId: src.flair__Objective_Time_Period__c,
  startDate: src.flair__Start_Date__c
    ? parseISO(src.flair__Start_Date__c)
    : null,
  dueDate: src.flair__Due_Date__c ? parseISO(src.flair__Due_Date__c) : null,
  name: src.Name,
  description: src.flair__Description__c ?? '',
  tagIds: src.tags.map((x) => x.Id),
});

export const mapToCreateEmployeeObjectiveInput = (
  formData: EmployeeObjectiveFormData,
): CreateEmployeeObjectiveInput => ({
  ...mapToCreateObjectiveInput(formData),
  employeeId: formData.employeeId,
});

export const mapToCreateTeamObjectiveInput = (
  formData: TeamObjectiveFormData,
): CreateTeamObjectiveInput => ({
  ...mapToCreateObjectiveInput(formData),
  teamId: formData.teamId,
});

type ObjectiveStatusAndRateProps = ObjectiveStatusProps &
  Pick<
    EmployeeObjectiveDetailsFragment, // TeamObjectiveDetailsFragment also match
    'flair__Rate__c' | 'flair__Status_Reason__c'
  >;

type ObjectiveStatusProps = Pick<
  EmployeeObjectiveDetailsFragment,
  'flair__Manual_Progress__c' | 'flair__Current_Status__c'
>;

const mapToGoalStatusWithRate = (
  src: ObjectiveStatusAndRateProps,
): GoalStatusWithRate => {
  return {
    status: mapToGoalStatusEx(src),
    rate: src.flair__Rate__c ? mapRate(src.flair__Rate__c) : 'OPEN',
    cancelReason: src.flair__Status_Reason__c ?? undefined,
  };
};

const mapToGoalStatusEx = (src: ObjectiveStatusProps): GoalStatusEx => {
  if (src.flair__Manual_Progress__c) {
    return mapToGoalStatusExFromManual(src.flair__Current_Status__c);
  }
  return mapToGoalStatusExFromAuto(src.flair__Current_Status__c);
};

const mapToGoalStatusExFromManual = (
  src: ObjectiveCurrentStatusType,
): GoalStatusEx => {
  return src as GoalStatus;
};

const mapToGoalStatusExFromAuto = (
  src: ObjectiveCurrentStatusType,
): GoalStatusEx => {
  return src === ObjectiveCurrentStatusType.OnTrack ||
    src === ObjectiveCurrentStatusType.Behind
    ? 'BEHIND_OR_ON_TRACK'
    : (src as GoalStatus);
};

const getGoalOnTrackAutoState = (src: ObjectiveCurrentStatusType) => {
  if (src === ObjectiveCurrentStatusType.OnTrack) {
    return true;
  } else if (src === ObjectiveCurrentStatusType.Behind) {
    return false;
  }
  return undefined;
};

export const isEmployeeObjectiveFieldsUpdate = (
  formData: Partial<ObjectiveFormData>,
) => {
  return updateEmployeeObjectiveFieldsInputKeys.some((x) => x in formData);
};

export const isTeamObjectiveFieldsUpdate = (
  formData: Partial<ObjectiveFormData>,
) => {
  return updateTeamObjectiveFieldsInputKeys.some((x) => x in formData);
};

export const mapToUpdateEmployeeObjectiveFieldsInput = (
  id: string,
  formData: Partial<EmployeeObjectiveFormData>,
): UpdateEmployeeObjectiveFieldsInput => ({
  ...mapToUpdateBaseObjectiveFieldsInput(formData),
  id,
  employeeId:
    formData['employeeId'] !== undefined ? formData['employeeId'] : null,
});

export const mapToUpdateTeamObjectiveFieldsInput = (
  id: string,
  formData: Partial<TeamObjectiveFormData>,
): UpdateTeamObjectiveFieldsInput => ({
  ...mapToUpdateBaseObjectiveFieldsInput(formData),
  id,
  teamId: formData['teamId'] !== undefined ? formData['teamId'] : null,
});

export const mapToUpdateObjectiveStatusInput = (
  goalId: GoalId,
  statusWithRate: GoalStatusWithRate,
): UpdateObjectiveStatusInput => {
  return {
    objectiveId: toObjectiveIdInput(goalId),
    status: fromStatus(statusWithRate.status),
    rate: fromRate(statusWithRate.rate),
    cancelReason: statusWithRate.cancelReason ?? null,
  };
};

// null - means do not update
// '' - means delete value
const mapToUpdateBaseObjectiveFieldsInput = (
  formData: Partial<ObjectiveFormData>,
) => ({
  name: formData['name'] !== undefined ? formData['name'] : null,

  visibility:
    formData['visibility'] !== undefined
      ? fromVisibility(formData['visibility'])
      : null,
  sharedWithEmployeeIds:
    formData['sharedWithEmployeeIds'] !== undefined
      ? formData.sharedWithEmployeeIds
      : null,
  timePeriodId:
    formData['timePeriodId'] !== undefined ? formData['timePeriodId'] : null,

  tagIds: formData['tagIds'] !== undefined ? formData.tagIds : null,
  keyResults:
    formData['keyResults'] !== undefined
      ? formData.keyResults.map(createSyncObjectiveKeyResultInput)
      : null,
  manualProgress:
    formData['manualProgress'] !== undefined
      ? formData['manualProgress']
      : null,
  // these fields could be set to null
  startDate: dateToStringUpdateInput(formData['startDate']),
  dueDate: dateToStringUpdateInput(formData['dueDate']),
  description: toStringUpdateInput(formData['description']),
  parentObjectiveId: toObjectiveIdUpdateInput(formData['parentObjectiveId']),
});

// string - set value
// null - delete value
// undefined - do not update

const dateToStringUpdateInput = (
  dateValue: Date | null | undefined,
): StringUpdateInput | null => {
  if (dateValue === undefined) {
    return null;
  }
  return { value: dateValue !== null ? toISODateOnly(dateValue) : null };
};

const toStringUpdateInput = (
  strValue: string | null | undefined,
): StringUpdateInput | null => {
  if (strValue === undefined) {
    return null;
  }
  return { value: strValue };
};

const toObjectiveIdUpdateInput = (
  goalId: GoalId | null | undefined,
): ObjectiveIdUpdateInput | null => {
  if (goalId === undefined) {
    return null;
  }
  return { objectiveId: goalId !== null ? toObjectiveIdInput(goalId) : null };
};

const mapToCreateObjectiveInput = (
  formData: TeamObjectiveFormData | EmployeeObjectiveFormData,
) => ({
  visibility: fromVisibility(formData.visibility),
  sharedWithEmployeeIds: formData.sharedWithEmployeeIds ?? [],
  timePeriodId: formData.timePeriodId,
  startDate: formData.startDate ? toISODateOnly(formData.startDate) : null,
  dueDate: formData.dueDate ? toISODateOnly(formData.dueDate) : null,
  name: formData.name,
  description: formData.description,
  tagIds: formData.tagIds,
  keyResults: formData.keyResults.map(createObjectiveKeyResultInput),
  manualProgress: formData.manualProgress,
  parentObjectiveId: formData.parentObjectiveId
    ? toObjectiveIdInput(formData.parentObjectiveId)
    : null,
  status: fromStatus(formData.statusWithRate.status),
  rate: fromRate(formData.statusWithRate.rate),
  cancelReason: formData.statusWithRate.cancelReason ?? null,
});

export const createObjectiveKeyResultInput = (
  src: GoalKeyResult,
): CreateObjectiveKeyResultInput => ({
  name: src.name,
  type: fromKeyResultType(src.type),
  initialValue: src.initialValue,
  currentValue: src.currentValue,
  targetValue: src.targetValue,
});

export const createSyncObjectiveKeyResultInput = (
  src: GoalKeyResult,
): SyncObjectiveKeyResultInput => {
  if (src.id) {
    return {
      keepKeyResultId: src.id,
      createKeyResult: null,
    };
  } else {
    return {
      keepKeyResultId: null,
      createKeyResult: {
        name: src.name,
        type: fromKeyResultType(src.type),
        initialValue: src.initialValue,
        currentValue: src.currentValue,
        targetValue: src.targetValue,
      },
    };
  }
};

export const getHeaderText = (viewMode: GoalSidebarMode): string => {
  switch (viewMode) {
    case 'create':
      return i18next.t(`${i18prefix}.goalSidebar.createHeader`);
    case 'update':
      return i18next.t(`${i18prefix}.goalSidebar.updateHeader`);
    case 'view':
      return i18next.t(`${i18prefix}.goalSidebar.viewHeader`);
  }
};
