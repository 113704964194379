import * as React from 'react';

const SadTearSolidIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_1465_43013)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9478 3.04977C12.2991 2.39467 11.5274 1.87419 10.6769 1.51824C9.82647 1.16228 8.91407 0.977876 7.99214 0.975607C7.0702 0.973338 6.15691 1.15325 5.30472 1.50501C4.45253 1.85677 3.67824 2.37345 3.02633 3.02535C2.37442 3.67726 1.85775 4.45155 1.50599 5.30374C1.15423 6.15593 0.974315 7.06923 0.976584 7.99116C0.978853 8.91309 1.16326 9.82549 1.51921 10.6759C1.87516 11.5264 2.39564 12.2981 3.05075 12.9468C3.69944 13.6019 4.47118 14.1224 5.32163 14.4784C6.17207 14.8343 7.08447 15.0187 8.00641 15.021C8.92834 15.0233 9.84163 14.8433 10.6938 14.4916C11.546 14.1398 12.3203 13.6231 12.9722 12.9712C13.6241 12.3193 14.1408 11.545 14.4926 10.6928C14.8443 9.84066 15.0242 8.92736 15.022 8.00543C15.0197 7.0835 14.8353 6.1711 14.4793 5.32065C14.1234 4.4702 13.6029 3.69846 12.9478 3.04977ZM5.75105 8.00216C6.16612 8.00216 6.5026 7.66568 6.5026 7.25062C6.5026 6.83555 6.16612 6.49907 5.75105 6.49907C5.33599 6.49907 4.99951 6.83555 4.99951 7.25062C4.99951 7.66568 5.33599 8.00216 5.75105 8.00216ZM11.0019 7.25062C11.0019 7.66568 10.6655 8.00216 10.2504 8.00216C9.83533 8.00216 9.49885 7.66568 9.49885 7.25062C9.49885 6.83555 9.83533 6.49907 10.2504 6.49907C10.6655 6.49907 11.0019 6.83555 11.0019 7.25062ZM10.4693 11.9717C10.7311 11.884 10.8723 11.6007 10.7846 11.3389C10.4163 10.2389 9.27799 9.4981 7.99972 9.4981C7.72364 9.4981 7.49984 9.72191 7.49984 9.99799C7.49984 10.2741 7.72364 10.4979 7.99972 10.4979C8.9168 10.4979 9.62533 11.0254 9.83657 11.6563C9.92422 11.9181 10.2075 12.0593 10.4693 11.9717ZM5.58017 8.71506C5.25233 9.198 4.56226 10.2755 4.56226 10.7796C4.56226 11.4551 5.09381 11.9978 5.74948 11.9978C6.40515 11.9978 6.9367 11.4551 6.9367 10.7796C6.9367 10.2755 6.24663 9.198 5.91879 8.71506C5.83582 8.59307 5.66314 8.59307 5.58017 8.71506Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1465_43013">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default SadTearSolidIcon;
