import { ApolloCache, MutationHookOptions, Reference } from '@apollo/client';
import { useUserInfo } from '../../context/UserInfo';
import {
  ApproveTimeEntryChangeRequestMutation,
  ApproveTimeEntryChangeRequestMutationVariables,
  RejectTimeEntryChangeRequestMutation,
  RejectTimeEntryChangeRequestMutationVariables,
  useApproveTimeEntryChangeRequestMutation,
  useRejectTimeEntryChangeRequestMutation,
} from '../../__generated__/graphql';

export const useApproveTimeEntryChangeRequest = (
  baseOptions?: MutationHookOptions<
    ApproveTimeEntryChangeRequestMutation,
    ApproveTimeEntryChangeRequestMutationVariables
  >,
) => {
  const { id: managerId } = useUserInfo();

  return useApproveTimeEntryChangeRequestMutation({
    ...(baseOptions ?? {}),
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const idsToRemove: ReadonlyArray<string> =
        response.data.timeTracking.approveTimeEntryChangeRequests.recordIds;
      if (!idsToRemove.length) {
        return;
      }
      removeFromPendingTimeEntryChangeRequests(cache, managerId, idsToRemove);
    },
  });
};

export const useRejectTimeEntryChangeRequest = (
  baseOptions?: MutationHookOptions<
    RejectTimeEntryChangeRequestMutation,
    RejectTimeEntryChangeRequestMutationVariables
  >,
) => {
  const { id: managerId } = useUserInfo();

  return useRejectTimeEntryChangeRequestMutation({
    ...(baseOptions ?? {}),
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const idsToRemove: ReadonlyArray<string> =
        response.data.timeTracking.rejectTimeEntryChangeRequests.recordIds;
      if (!idsToRemove.length) {
        return;
      }
      removeFromPendingTimeEntryChangeRequests(cache, managerId, idsToRemove);
    },
  });
};

function removeFromPendingTimeEntryChangeRequests(
  cache: ApolloCache<object>,
  managerId: string,
  idsToRemove: ReadonlyArray<string>,
) {
  cache.modify({
    id: cache.identify({
      __typename: 'Manager',
      Id: managerId,
    }),
    fields: {
      pendingTimeEntryChangeRequests(
        existent: readonly Reference[],
        { readField },
      ) {
        return existent.filter(
          (documentRef) =>
            !idsToRemove.includes(readField('Id', documentRef) ?? ''),
        );
      },
    },
  });
}
