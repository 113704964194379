import moment from 'moment';
import React from 'react';

import PerformanceReviewTable from './PerformanceReviewTable';

import { EmployeeFeedbackProps } from './types';

type Props = {
  selectedReviewer: string | null;
  selectedEmployee: string | null;
  selectedDueDateYear: number | null;
  feedbacks: EmployeeFeedbackProps[];
  detailsLink: (feedbackId: string, cycleName: string) => React.ReactNode;
  showReviewers: boolean;
  showEmployees: boolean;
};

const PerformanceReviewCardBody: React.FC<Props> = ({
  feedbacks,
  selectedReviewer,
  selectedEmployee,
  selectedDueDateYear,
  detailsLink,
  showReviewers,
  showEmployees,
}) => {
  const filterByReviewer = (feedback: EmployeeFeedbackProps) =>
    selectedReviewer
      ? feedback.outcome.reviewer.Name === selectedReviewer
      : true;

  const filterByEmployee = (feedback: EmployeeFeedbackProps) =>
    selectedEmployee ? feedback.reviewee.Name === selectedEmployee : true;

  const filterByDueDate = (feedback: EmployeeFeedbackProps) =>
    selectedDueDateYear
      ? feedback.feedbackCycle.flair__Deadline__c &&
        moment(feedback.feedbackCycle.flair__Deadline__c).year() ===
          selectedDueDateYear
      : true;

  const filteredFeedbacks = feedbacks.filter(
    (feedback) =>
      filterByEmployee(feedback) &&
      filterByReviewer(feedback) &&
      filterByDueDate(feedback),
  );

  return (
    <PerformanceReviewTable
      feedbacks={filteredFeedbacks}
      detailsLink={detailsLink}
      showReviewers={showReviewers}
      showEmployees={showEmployees}
    />
  );
};

export default PerformanceReviewCardBody;
