import * as React from 'react';

const ThumbsUpFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54545)">
        <path
          d="M3.92095 13.9686V6.97022C3.92095 6.41817 3.48199 5.99858 2.94071 5.99858H0.980238C0.438963 5.99858 0 6.44628 0 6.97022V13.9405C0 14.4925 0.438963 14.9121 0.980238 14.9121H2.94071C3.48291 14.9683 3.92095 14.5466 3.92095 13.9686ZM15.6838 7.00147C15.6838 6.17354 15.0258 5.50244 14.2134 5.50244H9.72579C10.4233 4.31804 10.7832 2.97554 10.7832 2.50253C10.7826 1.76583 10.2159 0.999756 9.26631 0.999756C7.33248 0.999756 8.45884 3.37888 5.95188 5.42371L5.45257 5.82987C5.09111 6.12667 4.90731 6.56094 4.90425 6.99834C4.90353 6.99907 4.90425 6.99834 4.90425 6.99834L4.90119 11.9972C4.90119 12.4689 5.11908 12.9135 5.48933 13.1966L6.53512 13.9961C7.38241 14.6465 8.41473 14.9965 9.47461 14.9965H11.2727C12.0851 14.9965 12.7431 14.3257 12.7431 13.4974C12.7431 13.3839 12.7284 13.2743 12.7049 13.1678C13.2945 12.9719 13.7233 12.4158 13.7233 11.7472C13.7233 11.4614 13.6407 11.197 13.5046 10.9699C14.1859 10.8412 14.7036 10.232 14.7036 9.50088C14.7036 9.11034 14.5537 8.75793 14.3139 8.49111C15.0773 8.43863 15.6838 7.79503 15.6838 7.00147Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54545">
          <rect width="15.6838" height="15.9962" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default ThumbsUpFilledIcon;
