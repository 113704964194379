import React from 'react';
import { Col, Row } from 'react-bootstrap';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
};

export const PageHeader: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <div className="header mt-md-3">
      <div className="header-body">
        <Row className="row align-items-center">
          <Col>
            {subtitle && <h6 className="header-pretitle">{subtitle}</h6>}
            <h1 className="header-title">{title}</h1>
          </Col>
        </Row>
        {children}
      </div>
    </div>
  );
};
