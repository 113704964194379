import React from 'react';
import { OptionBase } from '../../../../../components/Select/types';
import ServerError from '../../../../../components/ServerError';
import {
  PersonalDocumentsFilters,
  Props as PersonalDocumentsFiltersType,
} from './PersonalDocumentsFilters';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';
import PersonalFiltersLoading from './PersonalFiltersLoading';
import { ApolloError } from '@apollo/client';

type Props = Omit<
  PersonalDocumentsFiltersType,
  'employeeOptions' | 'categoryOptions'
>;

type FilterProps = {
  categories: OptionBase[];
  employees: EmployeeOption[];
  loading: boolean;
  error: ApolloError | undefined;
};

type DocumentFiltersProps = Props & FilterProps;

export const PersonalDocumentsFiltersConnected: React.FC<
  DocumentFiltersProps
> = (props) => {
  const { categories, employees, loading, error } = props;

  if (loading) {
    return <PersonalFiltersLoading />;
  }

  if (error) return <ServerError />;

  return (
    <PersonalDocumentsFilters
      employeeOptions={employees}
      categoryOptions={categories}
      {...props}
    />
  );
};
