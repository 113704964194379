import React, { useState } from 'react';
import { RowFileTypeEnums } from '../Enums/RowFileTypeEnums';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

type Props = {
  type: string;
  url: string;
  width?: string | number;
};

export const DocumentPreview: React.FC<Props> = ({
  type,
  url,
  width = '70%',
}) => {
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);

  const renderPreviewLoader = () => {
    if (!isPreviewLoading) {
      return null;
    }
    return (
      <PreviewLoader className="w-100 position-absolute mt-n1">
        <SkeletonWrapper
          width={width}
          height={'100%'}
          className="rounded-0 position-relative"
        />
      </PreviewLoader>
    );
  };

  const renderPreview = () => {
    switch (type) {
      case RowFileTypeEnums.Pdf:
        // I think position-absolute was made in order to have independent scrolling
        return (
          <iframe
            onLoad={() => setIsPreviewLoading(false)}
            className="position-absolute top-0 left-0 border-end"
            height="100%"
            width={width}
            src={url}
            title="id"></iframe>
        );

      case RowFileTypeEnums.Png:
      case RowFileTypeEnums.Jpg:
      case RowFileTypeEnums.Jpeg:
      case RowFileTypeEnums.Gif:
        return (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Img
              src={url}
              onLoad={() => setIsPreviewLoading(false)}
              alt="preview"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderPreviewLoader()}
      {renderPreview()}
    </>
  );
};

const Img = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const SkeletonWrapper = styled(Skeleton)`
  top: -0.063rem;
`;

const PreviewLoader = styled.div`
  width: 70%;
  z-index: 1;
  height: 100%;
`;
