import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ServerError from '../../../../../components/ServerError';
import useAnniversariesData from './logic';
import _ from 'lodash';
import { useState } from 'react';
import WidgetFilter from '../CommonComponents/WidgetFilter';
import Button from '../../../../../components/button';
import AllAnniversariesModal from './AllAnniversariesModal';
import { mapAnniversary } from './mapping';
import { getFilterOption } from '../CommonComponents/WidgetFilter/logic';
import Sections from '../CommonComponents/WidgetSections/Sections';
import {
  SectionCategory,
  SectionType,
} from '../CommonComponents/WidgetSections/types';

const i18Path = 'dashboard.anniversaries';

const AnniversariesWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { data, loading, error, filterBy, handleChangeFilter } =
    useAnniversariesData();

  if (error) {
    return <ServerError />;
  }

  const headerActions = (
    <WidgetFilter
      selectedFilter={getFilterOption(filterBy)}
      onChange={handleChangeFilter}
    />
  );

  const anniversaries =
    data?.anniversaries
      .filter(
        (a) =>
          a.flair__Upcoming_Anniversary__c !== null &&
          a.flair__Start_Date__c !== null,
      )
      .map(mapAnniversary) ?? [];

  const today = new Date().toLocaleDateString();

  const [todayAnniversaries, otherAnniversaries] = _.partition(
    anniversaries,
    (i) => i.date.toLocaleDateString() === today,
  );

  const sections = [
    {
      key: SectionCategory.TODAY,
      label: t('today'),
      items: todayAnniversaries,
    },
    {
      key: SectionCategory.UPCOMING,
      label: t('upcoming'),
      items: otherAnniversaries,
    },
  ].filter((i) => i.items.length > 0);

  const footerActions = (
    <Button
      className="p-0"
      label={t('seeMore')}
      variant="link"
      onClick={() => setIsModalVisible(true)}
    />
  );

  return (
    <>
      <FlairCard
        cardTitle={t('title')}
        cardContainerClassName="anniversaries-widget"
        footerActions={footerActions}
        headerActions={headerActions}>
        <Sections
          sections={sections}
          type={SectionType.ANNIVERSARIES}
          emptyStateTitle={t('title')}
          loading={loading}
        />
      </FlairCard>
      {isModalVisible && (
        <AllAnniversariesModal
          filterBy={filterBy}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};

export default AnniversariesWidget;
