import React, { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import moment from 'moment';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { TableContent } from '../../../manager/components/TableContent';
import {
  AbsenceApprovalStatus,
  AbsenceType,
  useDeleteUpcomingAbsenceMutation,
  useEmployeeAbsencesQuery,
} from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import Loading from './Loading';
import MyAbsenceFilters from './MyAbsenceFilters';
import useAbsenceFilters from './MyAbsenceFilters/useAbsenceFilters';
import RequestAbsenceButton from '../../../components/RequestAbsenceButton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  TYPE_FILTER,
  STATUS_FILTER,
  MyAbsenceFiltersType,
  Absence,
} from './MyAbsenceFilters/types';
import Approvers from '../components/Approvers';
import { formatDateShort, parseDate } from '../../../../../utils/dateUtils';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import AbsenceCategoryIcon from '../../../components/AbsenceCategoryIcon';
import { useEmployeeAbsencesRefetchOption } from '../useMyAbsencesRefetchOption';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../context/Toast';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import AbsenceStatus from '../components/AbsenceStatus';
import { mapAbsences } from './mappings';
import AbsenceDateTimeRange from '../components/AbsenceDateTimeRange';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import SoftBadge from '../../../components/SoftBadge';
import { useYearFromQueryString } from '../../../../../hooks/useYearFromQueryString';
import { routes } from '../../../routes';
import YearNav from '../../../components/YearNav';
import Hint from '../../../../../components/hint';
import { currentYearPeriod } from '../../../../../utils/date';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { startOfDay } from 'date-fns';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { LoomVideo } from '../../../components/LoomVideo';
import { Maybe } from '../../../../../utils/maybe';
import LoomVideoPreviewInModal from '../../../components/LoomVideo/LoomVideoPreviewInModal';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { Link } from '../../../../../Router';
import { useUserInfo } from '../../../context/UserInfo';

const i18Path = 'absences';
const YEAR_PARAM_NAME = 'year';

const buildPath = (year: number, queryParams: any) =>
  routes.myAbsences.route
    .withQueryParams({
      ...queryParams,
      [YEAR_PARAM_NAME]: String(year),
    })
    .create({});

type Props = {
  absences: Absence[];
  year: number;
  goToNextYear: () => void;
  goToPrevYear: () => void;
  loading: boolean;
};

const canDeleteAbsence = (absence: Absence) =>
  (absence.categoryRestrictAbsenceDeletion &&
    absence.approvalStatus !== AbsenceApprovalStatus.Approved) ||
  !absence.categoryRestrictAbsenceDeletion;

const Spinner: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border spinner-border-sm" role="status"></div>
  </div>
);

const EmptyAbsenceRequestsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateCardBody
      title={t('absences.myAbsences.table.emptyStateMessage')}
    />
  );
};

const getQueryFiltersData = (query: URLSearchParams): MyAbsenceFiltersType => {
  return {
    status: query.get(STATUS_FILTER) || '',
    type: query.get(TYPE_FILTER) || '',
  };
};

const Content: React.FC<Props> = ({
  absences,
  year,
  goToNextYear,
  goToPrevYear,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const { id: meId } = useUserInfo();
  const [deletingAbsence, setDeletingAbsence] = useState<string>('');
  const [selectedLoomVideo, setSelectedLoomVideo] =
    useState<Maybe<LoomVideo>>(null);
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filterDataFromUrl = getQueryFiltersData(query);
  const filteredAbsences = useAbsenceFilters(filterDataFromUrl, absences);
  const { addSuccess } = useToasts();
  const period = year ? currentYearPeriod(year) : undefined;
  const employeeAbsencesRefetchQuery = useEmployeeAbsencesRefetchOption(
    meId,
    period,
  );
  const [deleteUpcomingAbsence, { loading }] = useDeleteUpcomingAbsenceMutation(
    {
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: employeeAbsencesRefetchQuery,
    },
  );
  const errorHandler = useMutationErrorHandler(() =>
    t('cards.upcomingAbsences.delete.failure'),
  );

  const handleDelete = (id: string) => {
    setDeletingAbsence(id);
    deleteUpcomingAbsence({
      variables: { id },
    })
      .then(() => addSuccess(t('cards.upcomingAbsences.delete.success')))
      .catch(errorHandler)
      .finally(() => setDeletingAbsence(''));
  };
  const isDeleting = (id: string): boolean => loading && deletingAbsence === id;
  const isUpcoming = (date: string): boolean =>
    moment(date).isAfter(new Date());

  const columns: Column<Absence>[] = [
    {
      Header: t('myAbsences.table.duration'),
      accessor: 'endDate',
      Cell: (props) => (
        <Link
          to={routes.myAbsenceDetail.route}
          absenceId={props.row.original.id}>
          <div className="d-flex align-items-center gap-2">
            <AbsenceDateTimeRange absence={props.row.original} />
            {isUpcoming(props.row.original.startDate) && (
              <SoftBadge variant="primary">
                {t('myAbsences.table.upcoming')}
              </SoftBadge>
            )}
          </div>
        </Link>
      ),
    },
    {
      Header: t('myAbsences.table.type'),
      accessor: 'categoryName',
      Cell: (props) => (
        <div className="d-flex gap-2">
          <AbsenceCategoryIcon icon={props.row.original.categoryIcon} />
          <span>{props.row.original.categoryName}</span>
        </div>
      ),
    },
    {
      Header: t('myAbsences.table.workingTime'),
      accessor: 'workingAmount',
      Cell: (props) =>
        props.row.original.type === AbsenceType.Daily
          ? t('myAbsences.table.days', {
              count: props.row.original.workingAmount,
            })
          : t('myAbsences.table.hours', {
              count: props.row.original.workingAmount,
            }),
    },
    {
      Header: t('myAbsences.table.calendarTime'),
      accessor: 'amount',
      Cell: (props) =>
        props.row.original.type === AbsenceType.Daily
          ? t('myAbsences.table.days', {
              count: props.row.original.amount,
            })
          : t('myAbsences.table.hours', {
              count: props.row.original.amount,
            }),
    },
    {
      Header: t('myAbsences.table.comments'),
      accessor: 'recordId',
      Cell: ({ value, row }) => (
        <div className="pt-2">
          <CommentsPopover
            recordId={value}
            relatedObjectName={RelatedObjectNames.Absence}
            commentsCount={row.original.commentsCount}
            mode="icon"
          />
        </div>
      ),
    },
    {
      Header: t('myAbsences.table.loom'),
      accessor: 'loomVideo',
      Cell: ({ value }) => (
        <div className="pt-2">
          {value ? (
            <FlairIcon
              icon="loom"
              className="text-primary"
              onClick={() => setSelectedLoomVideo(value)}
            />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      Header: t('myAbsences.table.status'),
      accessor: 'approvalStatus',
      Cell: (props) => {
        const rejectedRequest = props.row.original.approvalRequests.find(
          (req) => req.approvalStatus === 'REJECTED',
        );
        const hasRejectionComment =
          rejectedRequest && rejectedRequest.approverComment;
        return (
          <div className="d-flex gap-1 align-items-center">
            <AbsenceStatus status={props.row.original.approvalStatus} />
            {hasRejectionComment && (
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip`}>
                    {rejectedRequest.approverComment}
                  </Tooltip>
                }>
                <span>
                  <FlairIcon
                    icon="chatbubble-outline"
                    role="button"
                    className={'ms-2 cursor-auto text-primary'}
                  />
                </span>
              </OverlayTrigger>
            )}
          </div>
        );
      },
    },
    {
      Header: t('myAbsences.table.reviewal'),
      accessor: 'approvalRequests',
      Cell: (props) => (
        <Approvers approvalRequests={props.row.original.approvalRequests} />
      ),
    },
    {
      Header: t('myAbsences.table.requested'),
      accessor: 'createdDate',
      Cell: (props) => (
        <div className="d-flex align-items-center">
          <span>
            {formatDateShort(parseDate(props.row.original.createdDate))}
          </span>
          {canDeleteAbsence(props.row.original) && (
            <div className="ms-auto mt-2">
              {isDeleting(props.row.original.id) ? (
                <Spinner />
              ) : (
                <DropdownActions id={`${props.row.original.id}-actions`}>
                  <div className="d-flex align-items-center">
                    <DropdownActionItem
                      onClick={() => {
                        handleDelete(props.row.original.id);
                      }}
                      title={props.row.original.deleteDisabled?.reason}
                      disabled={
                        loading ||
                        props.row.original.deleteDisabled !== undefined
                      }>
                      {t('cards.upcomingAbsences.actions.delete')}
                    </DropdownActionItem>
                    {props.row.original.deleteDisabled?.reason && (
                      <div className="text-muted me-3">
                        <Hint
                          id={props.row.original.id}
                          text={props.row.original.deleteDisabled?.reason}
                        />
                      </div>
                    )}
                  </div>
                </DropdownActions>
              )}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col className="small">
            <MyAbsenceFilters data={absences} filterData={filterDataFromUrl} />
          </Col>
          <Col className="col-auto text-end">
            <YearNav
              year={year}
              goToNextYear={goToNextYear}
              goToPrevYear={goToPrevYear}
            />
          </Col>
        </Row>
      </Card.Header>

      {absences.length > 0 ? (
        <TableContent
          columns={columns}
          data={filteredAbsences}
          onRowClick={() => {}}
          pageSize={50}
        />
      ) : (
        <EmptyAbsenceRequestsCard />
      )}
      {selectedLoomVideo && (
        <LoomVideoPreviewInModal
          loomVideo={selectedLoomVideo}
          onHide={() => setSelectedLoomVideo(null)}
        />
      )}
    </Card>
  );
};

const MyAbsencesPage: React.FC<{ actions?: React.ReactNode }> = ({
  actions,
  children,
}) => {
  const t = useNamespacedTranslation('navigation.menuItems.absences');
  const title = t('submenuItems.myAbsences');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  return (
    <>
      <PageHeader title={title} actions={actions} />
      {children}
    </>
  );
};

const MyAbsences: React.FC = () => {
  const year = useYearFromQueryString(YEAR_PARAM_NAME);
  const history = useHistory();
  const queryParams = useQueryParams();

  const goToPrevYear = useCallback(() => {
    history.push(buildPath(year - 1, queryParams));
  }, [year, history, queryParams]);

  const goToNextYear = useCallback(() => {
    history.push(buildPath(year + 1, queryParams));
  }, [year, history, queryParams]);

  const { data, loading, error } = useEmployeeAbsencesQuery({
    variables: currentYearPeriod(year),
  });

  const today = startOfDay(new Date());
  const absences = useMemo(
    () => (data ? mapAbsences(today, data.me.absences) : undefined),
    [today, data],
  );

  if (error) {
    return (
      <MyAbsencesPage>
        <ServerError />
      </MyAbsencesPage>
    );
  }

  if (loading || !absences || !data) {
    return (
      <MyAbsencesPage>
        <Loading />
      </MyAbsencesPage>
    );
  }

  const categories = data.me.absenceCategories;

  return (
    <MyAbsencesPage
      actions={
        <RequestAbsenceButton categories={categories} categoryId={null} />
      }>
      <Content
        absences={absences}
        year={year}
        goToNextYear={goToNextYear}
        goToPrevYear={goToPrevYear}
        loading={loading}
      />
    </MyAbsencesPage>
  );
};

export default MyAbsences;
