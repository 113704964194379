import styled from '@emotion/styled';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Announcement, {
  AnnouncementCardSkeleton,
} from '../../../components/Announcement';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import GlobalTableFilter from '../../../components/GlobalTableFilter';
import PageHeader from '../../../components/PageHeader';
import { useAnnouncementQuery } from '../../../__generated__/graphql';
import { mapAnnouncements, searchItems, useEmojiChange } from './logic';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

const i18Path = 'people.announcements';

const Content: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  const { data, loading } = useAnnouncementQuery();

  const [searchText, setSearchText] = useState<string>('');

  const announcements = useMemo(
    () => searchItems(mapAnnouncements(data?.announcements), searchText),
    [data?.announcements, searchText],
  );

  const { onEmojiChange, updatingItems } = useEmojiChange(announcements);

  const onSearch = useCallback(
    (filter: string) => {
      debounce(setSearchText, 300)(filter);
    },
    [setSearchText],
  );

  if (loading) {
    return (
      <div>
        <AnnouncementCardSkeleton />
        <AnnouncementCardSkeleton />
      </div>
    );
  }

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <GlobalTableFilter
              placeholder={t('search')}
              onFilterChange={onSearch}
            />
          </Col>
        </Row>
      </Card.Header>
      <CardsBody>
        {announcements.length > 0 ? (
          announcements.map((a, i) => (
            <Announcement
              key={i}
              id={a.id}
              title={a.title}
              body={a.body}
              createdDate={a.createdDate}
              employee={a.employee}
              emojis={a.emojis}
              onEmojiChange={onEmojiChange}
              loadingEmojis={updatingItems.includes(a.id)}
              commentsCount={a.commentsCount}
            />
          ))
        ) : (
          <EmptyStateCardBody
            title={t('noAnnouncementsToShow')}
            subtitle={t('thereAreNoAnnouncements')}
          />
        )}
      </CardsBody>
    </Card>
  );
};

const AnnouncementContainerI18Path = 'navigation.menuItems.company';

const AnnouncementContainer: React.FC = () => {
  const t = useNamespacedTranslation(AnnouncementContainerI18Path);
  const title = t('subMenuItems.announcement.title');

  useFlairBreadcrumbHook([
    { label: t('title') },
    {
      label: title,
    },
  ]);

  return (
    <>
      <PageHeader title={title} />
      <Content />
    </>
  );
};

const CardsBody = styled(Card.Body)`
  > .card:last-child {
    margin-bottom: 0;
  }
`;

export default AnnouncementContainer;
