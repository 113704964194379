import React from 'react';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../components/Comment/types';

type Props = {
  timesheetDayId: string;
  commentsCount: number;
  onCommentsChanged?: () => void;
};

const TimesheetDayCommentsPopover: React.FC<Props> = ({
  timesheetDayId,
  commentsCount,
  onCommentsChanged,
}) => {
  return (
    <CommentsPopover
      recordId={timesheetDayId}
      onCommentsChanged={onCommentsChanged}
      relatedObjectName={RelatedObjectNames.TimeSheetDay}
      commentsCount={commentsCount}
      mode="icon"
    />
  );
};

export default TimesheetDayCommentsPopover;
