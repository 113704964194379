import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  components as selectComponents,
  GroupBase,
  SelectComponentsConfig,
  SingleValue,
  StylesConfig,
} from 'react-select';
import {
  HeightMode,
  LARGE_HEIGHT_CSS,
  NORMAL_HEIGHT_CSS,
  SelectSingleStyled,
  SelectSingleStyledProps,
} from '../../../../components/Select/SelectSingleStyled';
import { OptionBase } from '../../../../components/Select/types';
import { Theme } from '../../../../theme';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

export type Props<TOption extends OptionBase> = Omit<
  SelectSingleStyledProps<TOption>,
  'value' | 'options' | 'onChange'
> & {
  value: string | null;
  options: TOption[];
  onChange: (value: string | null) => void;
  renderOption: RenderOptionFunc<TOption>;
  noOptionsMessage?: React.ReactNode;
  defaultMenuIsOpen?: boolean;
  hideSelectedOptions?: boolean;
};

export type RenderOptionFunc<TOption extends OptionBase> = (
  params: RenderOptionParams<TOption>,
) => React.ReactNode;

export type RenderOptionParams<TOption extends OptionBase> = {
  option: TOption;
  isInsideSelect: boolean;
};

const createDefaultStyles = <TOption extends OptionBase>(): StylesConfig<
  TOption,
  false
> => ({
  dropdownIndicator: (provided, { isFocused }) => ({
    ...provided,
    paddingRight: '10px',
    color: isFocused ? Theme.input.border.focusColor : Theme.input.border.color,
    ':hover': {
      color: Theme.input.border.focusColor,
    },
  }),
});

const createDefaultComponents: <TOption extends OptionBase>(
  renderOption: RenderOptionFunc<TOption>,
) => SelectComponentsConfig<TOption, false, GroupBase<TOption>> = (
  renderOption,
) => ({
  IndicatorSeparator: null,
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      {renderOption({ option: data, isInsideSelect: true })}
    </selectComponents.Option>
  ),
  DropdownIndicator: ({ ...props }) => (
    <selectComponents.DropdownIndicator {...props}>
      <FlairIcon icon="search-filled" size="xs" />
    </selectComponents.DropdownIndicator>
  ),
});

export function SelectSingleBase<TOption extends OptionBase>(
  props: Props<TOption>,
) {
  const selectedOption = props.options.find((o) => o.value === props.value);

  const handleDeleteClick = () => {
    if (props.isDisabled) {
      return;
    }
    props.onChange(null);
  };

  if (selectedOption && !props.hideSelectedOptions) {
    return (
      <ContainerWithHeight
        className="d-flex align-items-center"
        heightMode={props.heightMode}>
        {props.renderOption({
          option: selectedOption,
          isInsideSelect: false,
        })}
        <FlairIcon
          role="button"
          icon="close-outline"
          onClick={handleDeleteClick}
          color={Theme.color.paper1}
          className="ms-2"
        />
      </ContainerWithHeight>
    );
  }
  return <SelectBase {...props} />;
}

// todo: refactor to use common properties
export function SelectBase<TOption extends OptionBase>({
  value,
  options,
  onChange,
  renderOption,
  placeholder,
  error,
  className,
  noOptionsMessage,
  isDisabled,
  defaultMenuIsOpen,
  styles,
  heightMode = 'large',
  ...restProps
}: Props<TOption>) {
  const { t } = useTranslation();
  const defaultComponents = useMemo(
    () => createDefaultComponents(renderOption),
    [renderOption],
  );

  const handleOnChange = useCallback(
    (value: SingleValue<TOption>) => {
      onChange(value?.value ?? null);
    },
    [onChange],
  );

  const defaultStyles = useMemo(() => createDefaultStyles<TOption>(), []);
  return (
    <SelectSingleStyled
      {...restProps}
      value={options.find((o) => o.value === value) ?? null}
      onChange={handleOnChange}
      options={options}
      placeholder={placeholder ?? t('select.selectSingleBase.placeholder')}
      noOptionsMessage={() =>
        noOptionsMessage ?? t('select.selectSingleBase.noOptions')
      }
      isSearchable={true}
      getOptionValue={(option) => option.label}
      components={defaultComponents}
      styles={{ ...defaultStyles, ...styles }}
      menuPlacement={'auto'}
      heightMode={heightMode}
      error={error}
      isDisabled={isDisabled}
      isClearable={false}
      defaultMenuIsOpen={defaultMenuIsOpen}
    />
  );
}

export const ContainerWithHeight = styled.div<{ heightMode?: HeightMode }>(
  ({ heightMode }) => ({
    height: heightMode === 'normal' ? NORMAL_HEIGHT_CSS : LARGE_HEIGHT_CSS,
  }),
);
