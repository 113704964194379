import * as React from 'react';

const InformationCircleFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.99853 1.75024C4.55309 1.75024 1.75 4.55333 1.75 7.99877C1.75 11.4442 4.55309 14.2473 7.99853 14.2473C11.444 14.2473 14.2471 11.4442 14.2471 7.99877C14.2471 4.55333 11.444 1.75024 7.99853 1.75024ZM7.99853 4.31214C8.15919 4.31214 8.31624 4.35978 8.44982 4.44904C8.5834 4.5383 8.68752 4.66516 8.749 4.81359C8.81048 4.96202 8.82657 5.12535 8.79523 5.28292C8.76388 5.44049 8.68652 5.58523 8.57291 5.69884C8.45931 5.81244 8.31457 5.88981 8.157 5.92115C7.99943 5.95249 7.8361 5.93641 7.68767 5.87492C7.53924 5.81344 7.41237 5.70933 7.32312 5.57574C7.23386 5.44216 7.18622 5.28511 7.18622 5.12445C7.18622 4.90901 7.2718 4.7024 7.42414 4.55006C7.57647 4.39772 7.78309 4.31214 7.99853 4.31214ZM9.49817 11.373H6.74882C6.61624 11.373 6.4891 11.3203 6.39535 11.2266C6.30161 11.1328 6.24894 11.0057 6.24894 10.8731C6.24894 10.7405 6.30161 10.6134 6.39535 10.5196C6.4891 10.4259 6.61624 10.3732 6.74882 10.3732H7.62361V7.62386H7.12373C6.99116 7.62386 6.86401 7.57119 6.77026 7.47745C6.67652 7.3837 6.62385 7.25655 6.62385 7.12398C6.62385 6.9914 6.67652 6.86425 6.77026 6.77051C6.86401 6.67676 6.99116 6.62409 7.12373 6.62409H8.1235C8.25607 6.62409 8.38322 6.67676 8.47697 6.77051C8.57071 6.86425 8.62338 6.9914 8.62338 7.12398V10.3732H9.49817C9.63075 10.3732 9.7579 10.4259 9.85164 10.5196C9.94539 10.6134 9.99805 10.7405 9.99805 10.8731C9.99805 11.0057 9.94539 11.1328 9.85164 11.2266C9.7579 11.3203 9.63075 11.373 9.49817 11.373Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default InformationCircleFilledIcon;
