import { parseISO } from 'date-fns';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { formatDateShort } from '../../../../../utils/dateUtils';
import { WorkflowItemBase } from '../WorkflowItems/types';
import StringToHtml from '../../../../../components/StringToHtml';
import { WorkflowItemSubTasks } from './WorkflowItemSubTasks';

type Props = {
  workflowItem: WorkflowItemBase;
  onStatusLoading?: (isSaving: boolean) => void;
};

export const WorkflowItemCommonFields: React.FC<Props> = ({
  workflowItem,
  onStatusLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormGroup>
        <Form.Label>
          {t('performanceReview.workflowsItems.sidebar.task')}
        </Form.Label>
        <p>{workflowItem.name}</p>
      </FormGroup>
      {workflowItem.description && (
        <FormGroup>
          <Form.Label>
            {t('performanceReview.workflowsItems.sidebar.description')}
          </Form.Label>

          <p>
            <span>
              <StringToHtml text={workflowItem.description} />
            </span>
          </p>
        </FormGroup>
      )}
      <FormGroup>
        <Form.Label>
          {t('performanceReview.workflowsItems.sidebar.dueDate')}
        </Form.Label>
        <p>
          {workflowItem.dueDate
            ? formatDateShort(parseISO(workflowItem.dueDate))
            : '-'}
        </p>
      </FormGroup>
      {workflowItem.subTasks.length > 0 && (
        <WorkflowItemSubTasks
          workflowItem={workflowItem}
          subTasks={workflowItem.subTasks}
          readonly={workflowItem.readonly}
          onStatusLoading={onStatusLoading}
        />
      )}
    </>
  );
};
