import { EmployeeNotificationSettingsQuery } from '../../../__generated__/graphql';

export type NotificationSettingsType =
  EmployeeNotificationSettingsQuery['me']['notificationSettings'];

export type NotificationCategory =
  | 'general'
  | 'absence'
  | 'openShift'
  | 'timesheet'
  | 'recruitment'
  | 'performanceReview'
  | 'cheer'
  | 'workflows'
  | 'comments'
  | 'ticket';

export const NOTIFICATION_ALL_CHANNELS = ['slack', 'email', 'push'] as const;
type NotificationChannelTuple = typeof NOTIFICATION_ALL_CHANNELS;
export type NotificationChannel = NotificationChannelTuple[number];

export type NotificationItem = {
  showCondition?: boolean;
  name: string;
};

export type NotificationCategoryItem = {
  category: NotificationCategory;
  showCondition?: boolean;
  notifications: NotificationItem[];
};
