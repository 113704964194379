import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { FormContext, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalSidebarContent } from '../../../../components/ModalSidebar';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { useUserInfo } from '../../../../context/UserInfo';
import { GoalsActivities } from '../GoalsActivities/GoalsActivities';
import { GoalActivity, GoalId } from '../types';
import { GoalOptions } from '../useGoalSelectOptions';
import { GoalCommonFields } from './GoalCommonFields';
import { GoalSidebarMode, ObjectiveFormData } from './types';
import { useSubmitUpdate, useUpdateObjectiveForm } from './useObjectiveForm';

type Props = {
  owner?: EmployeeOption;
  goalId: GoalId;
  mode: GoalSidebarMode;
  options: GoalOptions;
  formData: ObjectiveFormData;
  activities: GoalActivity[];
  onClose: () => void;
};

export const GoalUpdateForm: React.FC<Props> = (props) => {
  const form = useUpdateObjectiveForm(props.formData);

  const { reset } = form;

  useEffect(() => {
    reset(props.formData);
  }, [reset, props.formData]);

  return (
    <FormContext {...form}>
      <Form>
        <GoalUpdateFormContent {...props} />
      </Form>
    </FormContext>
  );
};

const GoalUpdateFormContent: React.FC<Props> = ({
  goalId,
  formData,
  mode,
  activities,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation();
  const form = useFormContext<ObjectiveFormData>();
  const { id: meId } = useUserInfo();
  const { formState } = form;
  const { dirtyFields, isSubmitting, isValid } = formState;

  const { submitUpdate, saveInfo } = useSubmitUpdate(meId, goalId);

  useEffect(() => {
    if (isSubmitting || !isValid || mode !== 'update') {
      return;
    }
    if (dirtyFields.size > 0) {
      submitUpdate(dirtyFields);
    }
  }, [dirtyFields, submitUpdate, isSubmitting, mode, isValid]);

  return (
    <ModalSidebarContent
      body={
        <div>
          <input type="hidden" name="type" ref={form.register} />
          <GoalCommonFields {...restProps} mode={mode} saveInfo={saveInfo} />
          <ActivityHeader className="mb-3">
            {t('performanceReview.goals2.activityTitle')}
          </ActivityHeader>
          <GoalsActivities activities={activities} />
        </div>
      }
      footer={null}
    />
  );
};

const Form = styled('form')({
  display: 'contents',
});

const ActivityHeader = styled('div')({
  fontWeight: 600,
  fontSize: 15,
});
