import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import ListGroupItem from '../../../../components/ListGroup/Item';
import { Link } from '../../../../../../Router';
import { routes } from '../../../../routes';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { EmployeeDetails } from './types';

type Props = {
  employee: EmployeeDetails;
};

const Label: React.FC = ({ children }) => <h5 className="mb-0">{children}</h5>;

const Value: React.FC = ({ children }) => (
  <span className="small">{children}</span>
);

const i18Path = 'timeTracking.timeSheet.employeeInfo';

export const EmployeeDetailsPopoverContent: React.FC<Props> = ({
  employee,
}) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Container className="my-n3">
      <ListGroup variant="flush" className="p-3">
        <ListGroupItem
          label={<Label>{t('name')}</Label>}
          value={
            <Value>
              <Link to={routes.employeePage.route} employeeId={employee?.id}>
                {employee?.name}
              </Link>
            </Value>
          }
        />
        {employee?.department && (
          <ListGroupItem
            label={<Label>{t('department')}</Label>}
            value={<Value>{employee?.department}</Value>}
          />
        )}
        {employee?.location && (
          <ListGroupItem
            label={<Label>{t('location')}</Label>}
            value={<Value>{employee?.location}</Value>}
          />
        )}
        {employee?.position && (
          <ListGroupItem
            label={<Label>{t('position')}</Label>}
            value={<Value>{employee.position}</Value>}
          />
        )}
        {employee?.companyEmail && (
          <ListGroupItem
            label={<Label>{t('companyEmail')}</Label>}
            value={
              <Value>
                <a href={`mailto:${employee?.companyEmail}`}>
                  {employee?.companyEmail}
                </a>
              </Value>
            }
          />
        )}
        {employee?.startDate && (
          <ListGroupItem
            label={<Label>{t('joined')}</Label>}
            value={
              <Value>
                <FormattedDate day={employee?.startDate} format="short" />
              </Value>
            }
          />
        )}
      </ListGroup>
    </Container>
  );
};
