import { PureQueryOptions } from '@apollo/client';
import { Moment } from 'moment';
import React from 'react';
import styled from '@emotion/styled';
import { WeeklyShiftPod } from './weeklyShiftsLogic';
import { ShiftInfoBlock } from '../Common/types';
import OpenShiftWeeklyBlockSmart from './ShiftsWeeklyBlock/OpenShiftWeeklyBlockSmart';
import EmployeeShiftWeeklyBlockDumb from './ShiftsWeeklyBlock/EmployeeShiftWeeklyBlockDumb';
import ShiftPopover from '../Common/ShiftsPopover/ShiftsPopover';
import { OverlayTrigger } from 'react-bootstrap';
import { hoursToMinutes } from '../../../../../utils/time';

const ShiftBlockContainer = styled.div({
  position: 'absolute',
  marginTop: -1,
  marginLeft: -1,
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
});

type Props = {
  day: Moment;
  minHour: number;
  maxHour: number;
  pod: WeeklyShiftPod;
  refetchQueries?: Array<PureQueryOptions>;
};

type Interval = {
  start: number;
  end: number;
};

const ShiftsWeeklyPod: React.FC<Props> = ({
  day,
  minHour,
  maxHour,
  pod,
  refetchQueries,
}) => {
  const renderShift = (
    shiftsInPod: number,
    shift: ShiftInfoBlock,
    index: number,
  ) => {
    const yInterval = toYInterval(day, minHour, maxHour, shift);
    const xInterval = toXInterval(shiftsInPod, index);

    return (
      <OverlayTrigger
        key={`overlay-trigger-${index}`}
        trigger="click"
        overlay={<ShiftPopover shift={shift} refetchQueries={refetchQueries} />}
        rootClose={true}>
        <ShiftBlockContainer
          key={index}
          style={{
            left: `${xInterval.start * 100}%`,
            right: `${(1 - xInterval.end) * 100}%`,
            top: `${yInterval.start * 100}%`,
            bottom: `${yInterval.end * 100}%`,
          }}>
          {shift.type === 'open-shift' && (
            <OpenShiftWeeklyBlockSmart
              shift={shift}
              refetchQueries={refetchQueries}
            />
          )}
          {shift.type === 'employee-shift' && (
            <EmployeeShiftWeeklyBlockDumb shift={shift} />
          )}
        </ShiftBlockContainer>
      </OverlayTrigger>
    );
  };

  return (
    <React.Fragment key={pod.start.toString()}>
      {pod.shifts.map((shift, i) => renderShift(pod.shifts.length, shift, i))}
    </React.Fragment>
  );
};

const toXInterval = (shiftsInPod: number, index: number): Interval => {
  if (shiftsInPod === 1) {
    return {
      start: 0,
      end: 1,
    };
  }
  let width = 1 / shiftsInPod;
  return {
    start: index * width,
    end: 1 - (shiftsInPod - index - 1) * width,
  };
};

const toYInterval = (
  startOfDay: Moment,
  minHour: number,
  maxHour: number,
  shift: ShiftInfoBlock,
): Interval => {
  if (shift.type === 'open-shift' && shift.state !== 'requested')
    return { start: 0, end: 0 };

  let { start, end } = shift.blockInfo;

  const startMinutes = totalMinutesSince(startOfDay, start);
  const endMinutes = totalMinutesSince(startOfDay, end);
  const totalMinutes = hoursToMinutes(maxHour - minHour);
  const minMinutes = hoursToMinutes(minHour);
  const maxMinutes = hoursToMinutes(maxHour);

  return {
    start: (startMinutes - minMinutes) / totalMinutes,
    end: start.isSame(end, 'day')
      ? (maxMinutes - endMinutes) / totalMinutes
      : 0,
  };
};

const totalMinutesSince = (since: Moment, date: Moment): number =>
  date.diff(since, 'minutes');

export default ShiftsWeeklyPod;
