import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import DurationFormat, { formattedDuration } from '../../DurationFormat';
import { InfoHint } from '../../../../../components/hint/InfoHint';

type Props = {
  value: number;
  trackedMinutes: number;
  changeTo: number | null;
  changeToTrackedMinutes: number | null;
};

export const TrackedTimeCell: React.FC<Props> = (props) => {
  const { value, changeTo } = props;

  return (
    <div className={classNames('d-flex gap-1 align-items-center')}>
      <DurationFormat minutes={value} />
      {changeTo !== null && (
        <span className="text-warning">
          (<DurationFormat minutes={changeTo} />)
        </span>
      )}
      <TrackedTimeWarnings {...props} />
    </div>
  );
};

const i18nPrefix = 'components.timeSheetsTable.trackedTimeCell.warnings';

const TrackedTimeWarnings: React.FC<Props> = ({
  trackedMinutes,
  value,
  changeTo,
  changeToTrackedMinutes,
}: Props) => {
  const { t } = useTranslation();
  const format = formattedDuration(t);
  const warnings: string[] = [];
  if (trackedMinutes && trackedMinutes !== value) {
    warnings.push(
      t(`${i18nPrefix}.trackedMinutesDiffers`, {
        trackedMinutes: format(trackedMinutes),
      }),
    );
  }
  if (
    changeTo !== null &&
    changeToTrackedMinutes &&
    changeTo !== changeToTrackedMinutes
  ) {
    warnings.push(
      t(`${i18nPrefix}.trackedMinutesDiffersAfterChange`, {
        trackedMinutes: format(changeToTrackedMinutes),
      }),
    );
  }
  return warnings.length > 0 ? (
    <InfoHint className="d-flex" text={warnings.join('\n')} />
  ) : null;
};
