import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import Checkbox from '../../../components/Checkbox';
import ListAlert from '../../../components/ListAlert';
import {
  TimeTrackingMutationsContext,
  useApproveTimeEntryChangeRequestBatchUI,
  useRejectTimeEntryChangeRequest,
} from '../../../hooks/timeTracking';

type Props = {
  onClose?: () => void;
  selectedIds: string[];
};

export const RowAction: React.FC<{ requestId: string }> = ({ requestId }) => {
  const { t } = useTranslation();

  const { approveMutation } = React.useContext(TimeTrackingMutationsContext);
  const isApproving = approveMutation.processingIds.includes(requestId);

  const handleOnApprove = () => {
    approveMutation.processItem(requestId);
  };

  return (
    <Button
      label={t('timeTracking.changeRequests.bulkActions.actions.approve')}
      size="sm"
      variant="outline-primary"
      onClick={handleOnApprove}
      isProcessing={isApproving}
      disabled={isApproving}
    />
  );
};

export const BulkActionsAlert: React.FC<Props> = ({ onClose, selectedIds }) => {
  const { t } = useTranslation();
  const [processingCount, setProcessingCount] = useState<number>(0);
  const {
    handleOnApprove,
    inProgress: approving,
    btnLabel: btnApproveLabel,
  } = useApproveTimeEntryChangeRequestBatchUI(
    t('timeTracking.changeRequests.bulkActions.actions.approve'),
  );

  const [reject, { loading: rejecting }] = useRejectTimeEntryChangeRequest();
  const errorHandlerForReject = useMutationErrorHandler(() =>
    t('timeTracking.changeRequests.bulkActions.reject.failure'),
  );

  const handleApprove = useCallback(() => {
    setProcessingCount(selectedIds.length);
    handleOnApprove(selectedIds);
  }, [selectedIds, handleOnApprove, setProcessingCount]);

  const handleOnReject = useCallback(() => {
    setProcessingCount(selectedIds.length);
    reject({
      variables: { input: { ids: selectedIds } },
    }).catch(errorHandlerForReject);
  }, [reject, selectedIds, errorHandlerForReject, setProcessingCount]);

  const isProcessing = approving || rejecting;
  const isVisible = selectedIds.length > 0 || isProcessing;

  const countToRender = useMemo(() => {
    return isProcessing ? processingCount : selectedIds.length;
  }, [isProcessing, processingCount, selectedIds]);

  return (
    <ListAlert variant="dark" show={isVisible} onClose={onClose} dismissible>
      <Row className="align-items-center">
        <Col>
          <Checkbox
            id="alert-bulk-actions-checkbox"
            label={t(
              'timeTracking.changeRequests.bulkActions.timeEntriesSelected',
              {
                count: countToRender,
              },
            )}
            checked
            readOnly
          />
        </Col>
        <Col className="col-auto me-n3">
          <Button
            label={btnApproveLabel}
            size="sm"
            onClick={handleApprove}
            isProcessing={approving}
            disabled={isProcessing}
          />
          <Button
            label={t('timeTracking.changeRequests.bulkActions.actions.reject')}
            size="sm"
            variant="danger"
            className="ms-2"
            onClick={handleOnReject}
            isProcessing={rejecting}
            disabled={isProcessing}
          />
        </Col>
      </Row>
    </ListAlert>
  );
};
