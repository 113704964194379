import { ApolloError } from '@apollo/client';
import { useRejectAbsenceRequestMutation } from '../../__generated__/graphql';

type Result = [
  (id: string, comment: string) => Promise<void>,
  { loading: boolean; error?: ApolloError },
];

export const useRejectAbsenceRequest = (): Result => {
  const [rejectAbsenceRequest, { loading, error }] =
    useRejectAbsenceRequestMutation();

  const callback = async (id: string, comment: string) => {
    await rejectAbsenceRequest({ variables: { id, comment } });
  };

  return [
    callback,
    {
      loading,
      error,
    },
  ];
};
