import { FilterSelectOption } from '../../../../../../../atomic/molecules/FlairDropdown/types';

export type FilterProps = {
  options: FilterSelectOption[];
  filterData: TeamCalendarFiltersType;
};

export const TEAMS_FILTER = 'teams';
export const DEPARTMENT_FILTER = 'departments';
export const LOCATIONS_FILTER = 'locations';
export const ABSENCE_CATEGORIES_FILTER = 'absenceCategories';
export const ABSENT_STATUS_FILTER = 'absentStatus';
export const COLLEAGUES_IDS_FILTER = 'colleaguesIds';

export const TeamCalendarFiltersKeysArray = [
  TEAMS_FILTER,
  DEPARTMENT_FILTER,
  LOCATIONS_FILTER,
  ABSENCE_CATEGORIES_FILTER,
  ABSENT_STATUS_FILTER,
  COLLEAGUES_IDS_FILTER,
] as const;
// TEAMS_FILTER | LOCATIONS_FILTER | ....
export type TeamCalendarFiltersKeys =
  (typeof TeamCalendarFiltersKeysArray)[number];
export type TeamCalendarFiltersType = Record<TeamCalendarFiltersKeys, string>;

export const filterDefaultData: TeamCalendarFiltersType = {
  teams: 'my_team',
  departments: '',
  locations: '',
  absenceCategories: '',
  colleaguesIds: '',
  absentStatus: '',
};
