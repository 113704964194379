import { PureQueryOptions } from '@apollo/client';
import React from 'react';
import { OpenShiftBlock } from '../../Common/types';
import { useOpenShiftsActions } from '../../useOpenShiftsActions';
import ShiftsWeeklyBlockDumb from './OpenShiftWeeklyBlockDumb';

type Props = {
  shift: OpenShiftBlock;
  refetchQueries?: Array<PureQueryOptions>;
};

const OpenShiftWeeklyBlockSmart: React.FC<Props> = ({
  shift,
  refetchQueries,
}) => {
  const [createAssignmentRequest, deleteAssignmentRequest, isRequesting] =
    useOpenShiftsActions(refetchQueries);

  return (
    <ShiftsWeeklyBlockDumb
      shift={shift}
      onRequestShift={createAssignmentRequest}
      onDeleteAssignmentRequest={deleteAssignmentRequest}
      isRequesting={isRequesting}
    />
  );
};

export default OpenShiftWeeklyBlockSmart;
