import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { EmployeeTimeSheetsFilter, defaultFilter } from './types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { TimeSheetStatus } from '../../../../__generated__/graphql';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import {
  createMonthOptions,
  createYearOptions,
  getTimeSheetStatusOptions,
} from '../../../../components/TimeSheetsTable/filterOptions';
import { getMonthsOptions } from '../../../../../../utils/dateUtils';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';

export type Props = {
  filter: EmployeeTimeSheetsFilter;
  onChange: (filter: EmployeeTimeSheetsFilter) => void;
};

const i18Prefix = 'timeSheetsControlling.filter';

export const EmployeeTimeSheetFilters: React.FC<Props> = ({
  filter,
  onChange,
}) => {
  const t = useNamespacedTranslation(i18Prefix);

  const handleTimeSheetStatusChanged = (value: string | null) => {
    onChange({
      ...filter,
      status: (value as TimeSheetStatus) ?? null,
    });
  };

  const handleYearChanged = (value: string | null) => {
    onChange({
      ...filter,
      year: value ?? null,
    });
  };

  const handleMonthChanged = (value: string | null) => {
    onChange({
      ...filter,
      month: value ?? null,
    });
  };

  const handleClearFilter = () => {
    return onChange(defaultFilter);
  };

  return (
    <Row className="justify-content-between g-2">
      <Col className="col-auto">
        <Row className="align-items-center row-cols-auto g-2">
          <Col>
            <span className="text-nowrap">{t('filterBy')}</span>
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('timeSheetStatus')}
              options={getTimeSheetStatusOptions()}
              value={filter.status}
              onChange={handleTimeSheetStatusChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('year')}
              options={createYearOptions(new Date())}
              value={filter.year}
              onChange={handleYearChanged}
            />
          </Col>
          <Col>
            <SingleFilter
              placeholder={t('month')}
              options={createMonthOptions(getMonthsOptions())}
              value={filter.month}
              onChange={handleMonthChanged}
            />
          </Col>
          <Col>
            <ClearFiltersLink onClick={handleClearFilter} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
