import React, { useCallback } from 'react';
import {
  SelectComponentsConfig,
  components as selectComponents,
  GroupBase,
  SingleValue,
} from 'react-select';
import {
  SelectSingleStyled,
  SelectSingleStyledProps,
} from '../Select/SelectSingleStyled';
import { CreatableDropdownOption } from './types';

export type Props = Omit<
  SelectSingleStyledProps<CreatableDropdownOption>,
  'options' | 'value' | 'onChange' | 'onChange'
> & {
  value: string | null;
  options: CreatableDropdownOption[];
  onChange: (value: string | null) => void;
  onCreate: (value: string) => void;
};

const defaultComponents: SelectComponentsConfig<
  CreatableDropdownOption,
  false,
  GroupBase<CreatableDropdownOption>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <Option data={data} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <Option data={data} />
    </selectComponents.Option>
  ),
};

const Option: React.FC<{ data: CreatableDropdownOption }> = ({
  data: { label },
}) => {
  return <span>{label}</span>;
};

export const CreatableDropdown: React.FC<Props> = ({
  options,
  value,
  onChange,
  onCreate,
  ...props
}) => {
  const handleOnChange = useCallback(
    (value: SingleValue<CreatableDropdownOption>) => {
      if (value?.__isNew__) {
        onCreate(value?.value ?? null);
      }

      onChange(value?.value ?? null);
    },
    [onChange, onCreate],
  );

  return (
    <SelectSingleStyled
      {...props}
      options={options}
      value={options.find((o) => o.value === value) ?? null}
      onChange={handleOnChange}
      isSearchable={true}
      components={defaultComponents}
      menuPlacement={'auto'}
      isClearable={true}
      isCreatable={true}
    />
  );
};
