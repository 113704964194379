import { GoalBase, GoalType } from './types';

export const getGoalDueDate = (
  goal: Pick<GoalBase, 'dueDate' | 'period'>,
): string | null => {
  return goal.dueDate || goal.period?.endDate || null;
};

type CurrentUser = {
  id: string;
  isDirectManager: boolean;
};

export const canRateGoal = (user: CurrentUser, ownerId: string) => {
  return user.isDirectManager || user.id === ownerId;
};

export const canUpdateGoal = (
  isDirectManager: boolean,
  goalType: GoalType,
): boolean => {
  if (goalType === 'company') {
    return false;
  }
  if (goalType === 'team' && !isDirectManager) {
    return false;
  }
  return true;
};

export const canCloneGoal = (
  { id, isDirectManager }: CurrentUser,
  goalOwnerId: string,
  goalType: GoalType,
): boolean => {
  if (goalType !== 'employee') {
    return false;
  }
  return isDirectManager || id === goalOwnerId;
};

export const canDeleteGoal = (
  { id, isDirectManager }: CurrentUser,
  goalOwnerId: string,
  goalType: GoalType,
) => {
  if (goalType === 'company') {
    return false;
  }
  return isDirectManager || id === goalOwnerId;
};
