import {
  ActiveEmployees_PeerReviewersQuery,
  FeedbackQuestionType,
  PeerFeedbackFragment,
} from '../../../../__generated__/graphql';
import { Answer, PeerFeedback, Question } from './types';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { getEmployeeInitialsFromName } from '../../../../utils/employeeInitials';
import { calcRatingScoreAvg } from '../PeerReviewsSection/logic';

export const mapPeerFeedbacks = (
  peerFeedbacks: ReadonlyArray<PeerFeedbackFragment>,
): PeerFeedback[] =>
  peerFeedbacks.map((p) => {
    const answers = p.answers.map(mapAnswer);
    return {
      id: p.Id,
      progress: p.flair__Progress__c,
      submittedAt: p.flair__Submitted_At__c,
      reviewer: {
        id: p.reviewer.Id,
        name: p.reviewer.Name,
        avatarSrc: p.reviewer.avatar?.url ?? '',
        subtitle: p.reviewer.flair__Position__c ?? '',
        title: p.reviewer.Name,
        feedbackRequestId: p.Id,
        department: p.reviewer.department?.Name ?? '',
      },
      hasFilledAnswers: p.hasFilledAnswers,
      ratingAvgScore: calcRatingScoreAvg(answers),
      answers,

      unmappedAnswers: p.answers,
      questions: p.feedbackCycle.questions.filter(
        (item) =>
          item.flair__Feedback_Question_Type__c !==
            FeedbackQuestionType.YesNo &&
          item.flair__Feedback_Question_Visibility__c.includes('PEERS'),
      ),
      resolutions: p.outcome.resolutions,
    };
  });

export const mapActiveEmployees = (
  activeEmployees: ActiveEmployees_PeerReviewersQuery['activeEmployees'],
): EmployeeOption[] =>
  activeEmployees.map((e) => ({
    value: e.Id,
    label: e.Name,
    initials: getEmployeeInitialsFromName(e.Name),
  }));

export const mapAnswer = (
  answer: PeerFeedbackFragment['answers'][0],
): Answer => ({
  id: answer.Id,
  feedback: answer.flair__Feedback__c,
  question: mapQuestion(answer.question),
});

export const mapQuestion = (
  question: PeerFeedbackFragment['answers'][0]['question'],
): Question => ({
  id: question.Id,
  questionType: question.flair__Feedback_Question_Type__c,
  isOptional: question.flair__Optional__c,
});
