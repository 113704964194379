import React from 'react';
import { Accordion } from 'react-bootstrap';
import { SectionHeaderToggle } from './SectionHeaderToggle';
import {
  IQuestionAnswer,
  IQuestionnaireQuestion,
  QuestionnaireField,
} from '../QuestionnaireField';
import { QuestionnaireQuestionCard } from '../QuestionnaireQuestion';
import { onUpdateAnswerRet, onUpdateNotesRet } from '../types';

export type QuestionnaireSectionData = {
  sectionId: string;
  sectionName: string | null;
  questions: IQuestionnaireQuestion[];
  readOnly: boolean;
};
export type QuestionnaireSectionProps = QuestionnaireSectionData & {
  onUpdateQuestionAnswer: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateAnswerRet;
  onUpdateQuestionNotes: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateNotesRet;
};

export const QuestionnaireSection: React.FC<QuestionnaireSectionProps> = ({
  sectionId,
  sectionName,
  questions,
  readOnly,
  onUpdateQuestionAnswer,
  onUpdateQuestionNotes,
}) => {
  return (
    <Accordion defaultActiveKey={sectionId} as="section">
      <SectionHeaderToggle eventKey={sectionId}>
        {sectionName}
      </SectionHeaderToggle>
      <Accordion.Collapse eventKey={sectionId}>
        <div>
          {questions.map((question) => (
            <QuestionnaireQuestionCard key={question.id}>
              <QuestionnaireField
                {...question}
                onUpdateAnswer={onUpdateQuestionAnswer}
                onUpdateNotes={onUpdateQuestionNotes}
                readOnly={readOnly}
              />
            </QuestionnaireQuestionCard>
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};
