import React from 'react';
type Props = {
  label: string;
  value: string | number | null | undefined;
};
export const InventoryItemFieldRow: React.FC<Props> = ({ label, value }) => {
  return (
    <div className="d-flex flex-column gap-2">
      <h4 className="card-header-title">{label}</h4>
      <h4 className="card-header-title fw-normal">{value}</h4>
    </div>
  );
};
