import React, { useCallback, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Button from '../../../../../components/button';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import PageHeader from '../../../components/PageHeader';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { useUserInfo } from '../../../context/UserInfo';
import { EmployeeSkillsTable } from '../common/EmployeeSkillsTable';
import { mapEmployeeSkillAssociation } from '../common/mappings';
import { SkillsChoosenStatus } from '../common/SkillsChoosenStatus';
import { MAX_TOP_SKILLS_NUMBER, SkillLevel } from '../common/types';
import {
  useEmployeeAvailableSkills,
  useEmployeeSkills,
} from '../common/useEmployeeAvailableSkills';
import {
  useUpdateSkillRate,
  useUpdateSkillTop,
} from '../common/useEmployeeSkillsMutations';
import { AddEmployeeSkillDialogConnected } from '../dialog/AddEmployeeSkillDialogConnected';
import { RemoveEmployeeSkillDialogConnected } from '../dialog/RemoveEmployeeSkillDialogConnected';
import { SkillsRadarChartConnected } from '../radarChart/SkillsRadarChartConnected';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

export const SkillsEmployeePage: React.FC = () => {
  const t = useNamespacedTranslation('skills.employeePage');
  const tNavigationMenu = useNamespacedTranslation('navigation.menuItems');
  const tRemoveDialog = useNamespacedTranslation('skills.removeEmployeeSkill');
  const tPerformanceReview = useNamespacedTranslation('performanceReview');
  const { id: employeeId } = useUserInfo();
  const { data, loading, error } = useEmployeeSkills(employeeId);
  const { availableSkills } = useEmployeeAvailableSkills(employeeId);

  const { updateSkillRate } = useUpdateSkillRate();
  const { updateSkillTop } = useUpdateSkillTop();

  useFlairBreadcrumbHook([
    { label: tNavigationMenu('personalDevelopment.title') },
    { label: tPerformanceReview('subMenuItems.skills.title') },
  ]);

  const employeeSkills = useMemo(
    () =>
      data?.activeEmployees
        .flatMap((x) => x.employeeSkills)
        .map(mapEmployeeSkillAssociation),
    [data],
  );

  const [skillToRemove, setSkillToRemove] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [addSkillDialogVisible, setAddSkillDialogVisible] =
    useState<boolean>(false);

  const handleRemoveSkill = useCallback(
    (id: string) => {
      const skill = employeeSkills?.find((x) => x.id === id);
      if (skill) {
        setSkillToRemove({ id, name: skill.name });
      }
    },
    [setSkillToRemove, employeeSkills],
  );

  const handleAddSkillClick = useCallback(() => {
    setAddSkillDialogVisible(true);
  }, [setAddSkillDialogVisible]);

  const handleRemoveSkillDialogClose = useCallback(() => {
    setSkillToRemove(null);
  }, [setSkillToRemove]);

  const handleChangeSkillLevel = useCallback(
    (id: string, level: SkillLevel) => {
      updateSkillRate({
        id,
        employeeId,
        rate: level,
      });
    },
    [updateSkillRate, employeeId],
  );

  const handleChooseSkill = useCallback(
    (id: string, isChoosen: boolean) => {
      updateSkillTop({
        id,
        employeeId,
        top: isChoosen,
      });
    },
    [updateSkillTop, employeeId],
  );

  const handleAddSkillDialogClose = useCallback(() => {
    setAddSkillDialogVisible(false);
  }, [setAddSkillDialogVisible]);

  const renderContent = () => {
    if (error) {
      return <ServerError />;
    }

    if (loading || !employeeSkills) {
      return <Loading />;
    }
    return (
      <EmployeeSkillsTable
        employeeSkills={employeeSkills}
        onChangeSkillLevel={handleChangeSkillLevel}
        onChooseSkill={handleChooseSkill}
        onRemoveSkill={handleRemoveSkill}
        maxTopLimit={MAX_TOP_SKILLS_NUMBER}
        isReadonly={false}
      />
    );
  };

  return (
    <>
      <PageHeader title={tPerformanceReview('subMenuItems.skills.title')} />
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="fw-bold">{t('header')}</div>
              <div className="d-flex align-items-center">
                {employeeSkills && employeeSkills.length > 0 && (
                  <SkillsChoosenStatus
                    className="me-3"
                    totalSkills={employeeSkills.length}
                    choosenSkills={employeeSkills.filter((x) => x.isTop).length}
                  />
                )}
                <Button
                  label={t(`assignSkill`)}
                  size="sm"
                  onClick={handleAddSkillClick}
                />
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              {renderContent()}
              <RemoveEmployeeSkillDialogConnected
                onClose={handleRemoveSkillDialogClose}
                removeSkillText={tRemoveDialog('removeSkillTextForEmployee', {
                  skill: skillToRemove?.name,
                })}
                skillIdToRemove={skillToRemove?.id ?? null}
                employeeId={employeeId}
              />
              <AddEmployeeSkillDialogConnected
                employeeId={employeeId}
                availableSkills={availableSkills}
                show={addSkillDialogVisible}
                onClose={handleAddSkillDialogClose}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>
              <div className="fw-bold">{t('headerChart')}</div>
            </Card.Header>
            <Card.Body>
              <SkillsRadarChartConnected employeeId={employeeId} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const Loading: React.FC = () => {
  return (
    <div>
      <SkeletonTable columns={3} rows={5} />
    </div>
  );
};
