import { addDays } from 'date-fns';
import React, { useState, useEffect } from 'react';

type Auth = {
  apiToken: string | null;
  login: (apiToken: string) => void;
  logout: () => void;
};

const AuthenticatedContext = React.createContext<Auth>({
  apiToken: null,
  login: () => {},
  logout: () => {},
});

const API_TOKEN_STORAGE_KEY = 'flairEmployeeHubApiToken';
const API_DOCUMENTS_TOKEN_COOKIE = 'flairDocumentsToken';

export const AuthProvider: React.FC = (props) => {
  const [apiToken, setApiToken] = useState<string | null>(
    localStorage.getItem(API_TOKEN_STORAGE_KEY),
  );

  useEffect(() => {
    if (apiToken) {
      localStorage.setItem(API_TOKEN_STORAGE_KEY, apiToken);
    } else {
      localStorage.removeItem(API_TOKEN_STORAGE_KEY);
    }
  }, [apiToken]);

  const logout = () => {
    setApiToken(null);
  };

  const login = (apiToken: string) => {
    setApiToken(apiToken);
  };

  return (
    <AuthenticatedContext.Provider
      value={{ apiToken, login, logout }}
      {...props}
    />
  );
};

export const useAuth = (): Auth => React.useContext(AuthenticatedContext);

export const useIsLoggedIn = (): boolean => {
  // set cookie
  const apiToken = useAuth().apiToken;
  if (!apiToken) {
    deleteCookie();
    return false;
  } else {
    createCookie(apiToken);
    return true;
  }
};

const deleteCookie = () => {
  document.cookie = `${API_DOCUMENTS_TOKEN_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/api/documents/`;
};

const createCookie = (apiToken: string) => {
  const expire = addDays(new Date(), 100);
  document.cookie = `${API_DOCUMENTS_TOKEN_COOKIE}=${apiToken};expires=${expire.toUTCString()};secure;path=/`;
};
