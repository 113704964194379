import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Hint from '../hint';
import AutoSaveInputLabel from './AutoSaveInputLabel';
import { FormGroup } from './FormGroup';

type Item = {
  name: string | number;
  label: string;
  hint: string | null;
};

export type CheckboxGroupParams = {
  groupLabel: string;
  groupName: string;
  items: Item[];
  defaultValue?: Item['name'][];
  disabled?: boolean;
  required?: boolean;
  hint: string | null;
  groupClassName?: string;
  itemClassName?: string;
  loading?: boolean;
  isComplete?: boolean;
  onChange?: (selectedItems: Item['name'][]) => void;
};

const CheckboxGroup: React.FC<CheckboxGroupParams> = ({
  items,
  groupName,
  groupLabel,
  groupClassName,
  itemClassName = '',
  defaultValue = [],
  disabled,
  required,
  hint,
  loading,
  isComplete,
  onChange,
}) => {
  const [selected, setSelected] = useState<Item['name'][]>(defaultValue);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedList = e.target.checked
      ? [...selected, e.target.value]
      : selected.filter((id) => id !== e.target.value);

    setSelected(selectedList);
    onChange && onChange(selectedList);
  };

  return (
    <FormGroup className={groupClassName}>
      <AutoSaveInputLabel
        label={groupLabel}
        required={required}
        loading={loading}
        completed={isComplete}
        hint={hint && <Hint text={hint} id={`hint_${groupName}`} />}
      />
      {items.map((item) => (
        <Form.Check
          key={item.name}
          id={'id_' + item.name}
          className={itemClassName}
          type="checkbox"
          label={
            <small>
              {item.label}
              {item.hint && (
                <span className="ps-1">
                  <Hint text={item.hint} id={`hint_${item.name}`} />
                </span>
              )}
            </small>
          }
          disabled={disabled}
          value={item.name}
          checked={selected && selected.indexOf(item.name) !== -1}
          onChange={handleCheckboxChange}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxGroup;
