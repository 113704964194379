import React from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../../../../components/form/SubmitButton';

type Props = {
  disabled?: boolean;
};

export const GoalSaveButton: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <SubmitButton className="w-100" disabled={disabled}>
      {t('form.general.saveButton')}
    </SubmitButton>
  );
};
