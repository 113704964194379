import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './NotificationSkeleton.css';

type NotificationTextWidth = 'small' | 'medium' | 'large';

const notificationTextWidthToValue: Record<NotificationTextWidth, number> = {
  small: 150,
  medium: 250,
  large: 500,
};

type NotificationSkeletonProps = {
  notificationTextWidth?: NotificationTextWidth;
};

const NotificationSkeleton: React.FC<NotificationSkeletonProps> = ({
  notificationTextWidth = 'medium',
}) => {
  const notificationTextWidthValue =
    notificationTextWidthToValue[notificationTextWidth];

  return (
    <div className="d-flex flex-row align-items-center p-2">
      <Skeleton className="avatar-skeleton rounded-circle p-2" />
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-row">
          <Skeleton width={notificationTextWidthValue} />
        </div>
        <div className="d-flex flex-row mb-1">
          <Skeleton width={100} />
        </div>
      </div>
    </div>
  );
};

export default NotificationSkeleton;
