import React from 'react';
import { TeamWithPopup } from '../../../components/Team';
import { WorkflowTeamEmployeeInfo } from '../types';

type Props = {
  name: string;
  employees: WorkflowTeamEmployeeInfo[];
};

export const TeamCell: React.FC<Props> = ({ name, employees }) => {
  return <TeamWithPopup name={name} employees={employees} />;
};
