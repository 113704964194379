import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import ServerError from '../../../../../components/ServerError';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { useTableContentFiltration } from '../../../manager/components/useTableContent';
import {
  useDeleteSkillMutation,
  useManagerSkillsQuery,
  useUpdateSkillMutation,
} from '../../../__generated__/graphql';
import { DeleteSkillDialog } from '../dialog/DeleteSkillDialog';
import { UpdateSkillDialog } from '../dialog/UpdateSkillDialog';
import { mapManagerSkill } from './mappings';
import { ManageSkillsFilter } from './ManageSkillsFilter';
import { ManageSkillsTable } from './ManageSkillTable';
import { ManagerSkill } from './types';
import { IdName } from '../common/types';

const FilterWrapper = styled.div({
  paddingLeft: '1rem',
  paddingRight: '1rem',
});

export const ManageSkillsConnected: React.FC = () => {
  const { t } = useTranslation();
  const [filter, onFilterChange] = useTableContentFiltration();
  const [skillToUpdate, setSkillToUpdate] = useState<IdName | null>(null);
  const [skillToDelete, setSkillToDelete] = useState<IdName | null>(null);

  const { data, loading, error } = useManagerSkillsQuery();

  const skills: ManagerSkill[] | undefined = useMemo(
    () => data?.skills.map(mapManagerSkill),
    [data],
  );

  const [updateSkill] = useUpdateSkillMutation();
  const [deleteSkill] = useDeleteSkillMutation();
  const errorHandler = useMutationErrorHandler();
  const { addSuccess, addError } = useToasts();

  const handleDeleteSkill = useCallback(async () => {
    if (skillToDelete === null) {
      return;
    }
    await deleteSkill({
      variables: {
        input: {
          id: skillToDelete.id,
        },
      },
    })
      .then((r) => {
        if (r.data?.skills.deleteSkill.error) {
          addError(r.data?.skills.deleteSkill.error);
          return;
        }
        addSuccess(t('skills.deleteSkill.success'));
      })
      .catch(errorHandler)
      .finally(() => {
        setSkillToDelete(null);
      });
  }, [
    deleteSkill,
    errorHandler,
    addSuccess,
    addError,
    t,
    setSkillToDelete,
    skillToDelete,
  ]);
  const handleUpdateSkill = useCallback(
    async (name: string) => {
      if (skillToUpdate === null) {
        return;
      }
      await updateSkill({
        variables: {
          input: {
            id: skillToUpdate.id,
            name: name,
          },
        },
      })
        .then((r) => {
          if (r.data?.skills.updateSkill.error) {
            addError(r.data?.skills.updateSkill.error);
            return;
          }
          addSuccess(t('skills.updateSkill.success'));
        })
        .catch(errorHandler)
        .finally(() => {
          setSkillToUpdate(null);
        });
    },
    [
      updateSkill,
      errorHandler,
      addSuccess,
      addError,
      t,
      setSkillToUpdate,
      skillToUpdate,
    ],
  );

  const handleUpdateClick = useCallback(
    (skillId) => {
      const skill = skills?.find((x) => x.id === skillId);
      if (skill) {
        setSkillToUpdate(skill);
      }
    },
    [setSkillToUpdate, skills],
  );

  const handleDeleteClick = useCallback(
    (skillId) => {
      const skill = skills?.find((x) => x.id === skillId);
      if (skill) {
        setSkillToDelete(skill);
      }
    },
    [setSkillToDelete, skills],
  );

  const handleSkillUpdateClose = useCallback(() => {
    setSkillToUpdate(null);
  }, [setSkillToUpdate]);

  const handleSkillDeleteClose = useCallback(() => {
    setSkillToDelete(null);
  }, [setSkillToDelete]);

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <FilterWrapper>
        <ManageSkillsFilter
          onFilterChange={onFilterChange}
          placeholder={t('skills.manager.filter.placeholder')}
        />
      </FilterWrapper>
      {!loading && skills ? (
        <ManageSkillsTable
          onDeleteClick={handleDeleteClick}
          onUpdateClick={handleUpdateClick}
          filter={filter}
          data={skills}
        />
      ) : (
        <Loading />
      )}
      {skillToUpdate !== null && (
        <UpdateSkillDialog
          show={true}
          skill={skillToUpdate}
          onClose={handleSkillUpdateClose}
          onSubmit={handleUpdateSkill}
          allSkills={skills ?? []}
        />
      )}
      <DeleteSkillDialog
        show={skillToDelete !== null}
        skillName={skillToDelete?.name ?? ''}
        handleClose={handleSkillDeleteClose}
        onSubmit={handleDeleteSkill}
      />
    </>
  );
};

const Loading = () => {
  return (
    <>
      <Skeleton height={'3rem'} />
      <SkeletonTable columns={4} rows={5} />
    </>
  );
};
