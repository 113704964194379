import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { calculateMinMaxHours, notLeastThanDefault } from '../shiftsLogic';
import ShiftsDailyCalendar from './ShiftsDailyCalendar';
import ShiftsDailyFilter from './ShiftsDailyFilter';
import { routes } from '../../../routes';
import {
  CalendarQueryDocument,
  useCalendarQueryQuery,
} from '../../../__generated__/graphql';
import { SkeletonTable } from '../../Skeleton/Table';
import ServerError from '../../../../../components/ServerError';
import { DailyFilter } from './dailyShiftsLogic';
import {
  dayFilterFromQuery,
  dayFilterToQueryParams,
} from '../shiftsQueryUtils';
import { getShifts, mapWaitingApprovalOpenShifts } from '../shiftsMappings';
import { DailyEmployeeShiftInfo, DailyOpenShiftInfo } from './dailyShiftsTypes';
import { filterColleagues } from '../../../pages/AllShifts/logic';
import { ShiftsContext } from '../../../pages/AllShifts';
import { useUserInfo } from '../../../context/UserInfo';

type Props = {};

const Loading: React.FC = () => <SkeletonTable columns={8} rows={15} />;

const buildPath = (dayFilter: DailyFilter) =>
  routes.allShifts.route
    .withQueryParams(dayFilterToQueryParams(dayFilter))
    .create({});

const ShiftsDaily: React.FC<Props> = () => {
  const { id } = useUserInfo();
  const { filter } = useContext(ShiftsContext);
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const dayFilter = dayFilterFromQuery(query);
  const variables = {
    from: dayFilter.day.toISOString(),
    to: dayFilter.day.clone().endOf('day').toISOString(),
  };

  const { loading, data } = useCalendarQueryQuery({ variables });

  const refetchQueries = [
    {
      query: CalendarQueryDocument,
      variables,
    },
  ];

  if (loading) return <Loading />;

  if (!data) return <ServerError />;

  const { day } = dayFilter;

  const activeColleagues = filterColleagues(data.me.activeColleagues, filter);
  const invitedColleagues = filterColleagues(data.me.invitedColleagues, filter);

  const filteredData = {
    ...data,
    me: { ...data.me, activeColleagues, invitedColleagues },
  };

  let { allOpenShifts, allEmployeeShifts } = getShifts(
    filteredData,
    filteredData.me.activeColleagues.map((colleage) => colleage.Id),
    'daily',
  );

  let { allAvailableOpenShifts, allEmployeeShiftsWithRequested } =
    mapWaitingApprovalOpenShifts(
      {
        allOpenShifts: allOpenShifts as DailyOpenShiftInfo[],
        allEmployeeShifts: allEmployeeShifts as DailyEmployeeShiftInfo[],
      },
      id,
    );

  const shiftsIntervals = allEmployeeShiftsWithRequested.flatMap(
    (x) => x.shifts,
  );

  const openShiftsIntervals = allAvailableOpenShifts.map(({ start, end }) => ({
    start,
    end,
  }));

  const { min, max } = notLeastThanDefault(
    calculateMinMaxHours(day, [...shiftsIntervals, ...openShiftsIntervals]),
  );

  const handleFilterChanged = (newFilter: DailyFilter) => {
    const path = buildPath(newFilter);
    history.push(path);
  };

  return (
    <div>
      <div className="mb-2">
        <ShiftsDailyFilter
          filter={dayFilter}
          onFilterChanged={handleFilterChanged}
        />
      </div>
      <ShiftsDailyCalendar
        day={day}
        allOpenShifts={allAvailableOpenShifts}
        employeeShifts={allEmployeeShiftsWithRequested}
        refetchQueries={refetchQueries}
        minHour={min}
        maxHour={max}
      />
    </div>
  );
};

export default ShiftsDaily;
