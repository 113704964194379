import { useAllTimeFrameworksQuery } from '../../__generated__/graphql';
import { isTimeBalanceCalculationEnabled } from './useIsTimeBalanceEnabled';

export const useIsTimeBalanceEnabledGlobal = (): boolean => {
  const { data } = useAllTimeFrameworksQuery();

  if (!data) {
    return false;
  }

  return data.manager.timeFrameworks.some(
    (timeFramework) =>
      timeFramework.timeBalanceSettings &&
      isTimeBalanceCalculationEnabled(timeFramework.timeBalanceSettings),
  );
};
