import React from 'react';
import { HeaderAmountProps } from '../../../types';
import { AbsenceType } from '../../../../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';

export const HeaderAmount: React.FC<HeaderAmountProps> = ({
  category,
  labelValue,
}) => {
  const t = useNamespacedTranslation('absences.cards.absenceCategories');

  if (!labelValue) {
    return null;
  }

  switch (category.category.flair__Type__c) {
    case AbsenceType.Daily:
      return <>{t('days', { count: labelValue })}</>;
    case AbsenceType.Hourly:
      return (
        <>
          {t('hours', {
            count: labelValue,
          })}
        </>
      );
    case AbsenceType.Unknown:
      return <>{''}</>;
  }
};
