import React from 'react';
import { mapTimeBalanceSettings } from './mappings';
import { TimeBalanceDetails } from './TimeBalanceDetails';
import { TimeBalanceCalculationMode } from '../../__generated__/graphql';
import { useTimeBalanceForToday } from '../TimeBalanceForDay/useTimeBalanceForDay';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useTimeTrackingSettings } from '../../hooks/timeTrackingSettings';

type Props = {};

export const TimeBalanceDetailsConnected: React.FC<Props> = () => {
  const t = useNamespacedTranslation('components.timeBalanceDetails');
  const { loading, timeBalanceSettings } = useTimeTrackingSettings();
  const timeBalance = useTimeBalanceForToday();

  if (loading) {
    return null;
  }

  const timeBalanceEnabled =
    timeBalanceSettings &&
    timeBalanceSettings.flair__Calculation_Mode__c !==
      TimeBalanceCalculationMode.NotTrack;

  if (!timeBalanceSettings || !timeBalanceEnabled) {
    return <div>{t('notEnabled')}</div>;
  }

  return (
    <TimeBalanceDetails
      settings={mapTimeBalanceSettings(timeBalanceSettings)}
      timeBalance={timeBalance ?? 0}
    />
  );
};
