import React, { useCallback, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { SkeletonTable } from '../../components/Skeleton/Table';
import {
  useCertificatesQuery,
  useDeleteEmployeeCertificateMutation,
} from '../../__generated__/graphql';
import { Certificate, emptyCertificateFilter } from './types';
import { mapCertificate } from './mappings';
import ServerError from '../../../../components/ServerError';
import { MyCertificatesTable } from './MyCertificatesTable';
import {
  CertificatesFilter,
  collectExpirationDateYearFilterOptions,
  collectIssueDateYearFilterOptions,
  collectIssuedByFilterOptions,
} from './Filters/CertificatesFilter';
import { routes as mainRoutes } from '../../routes';
import { applyCertificateFilter } from './filters';
import { CertificateDeleteConfirmation } from './DeleteConfirmations';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { CertificateSaveModal } from './CertificateSaveModal';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

const MyCertificates: React.FC = () => {
  const { data, loading, error } = useCertificatesQuery();
  const [deleteEmployeeCertificate, { loading: deleteInProgress }] =
    useDeleteEmployeeCertificateMutation();
  const errorHandler = useMutationErrorHandler();
  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.profile.title') },
    { label: t('certificates.title') },
  ]);

  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [selectedCertificate, setSelectedCertificate] = useState<string | null>(
    null,
  );
  const [saveModalVisible, setModalBarVisible] = useState<boolean>(false);

  const { addSuccess, addError } = useToasts();

  const certificateDelete = useCallback(async () => {
    if (deleteItemId === null) {
      return;
    }
    await deleteEmployeeCertificate({
      variables: {
        id: deleteItemId,
      },
    })
      .then((r) => {
        if (r.data?.certificates.deleteEmployeeCertificate.error) {
          addError(r.data?.certificates.deleteEmployeeCertificate.error);
          return;
        }
        addSuccess(t('certificates.CertificateDeletedToast'));
      })
      .catch(errorHandler)
      .finally(() => {
        setDeleteItemId(null);
      });
  }, [
    addError,
    addSuccess,
    deleteEmployeeCertificate,
    deleteItemId,
    errorHandler,
    t,
  ]);

  const [filter, setFilter] = useFlairPersistentFilter({
    defaultFilter: emptyCertificateFilter,
    storageKey: 'my_certificate_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.myCertificates.route.withQueryParams(queryParams).create({}),
  });

  const handleCreateBtnClick = useCallback(() => {
    setModalBarVisible(true);
  }, []);

  const handleSaveSidebarClose = useCallback(() => {
    setModalBarVisible(false);
    setSelectedCertificate(null);
  }, []);

  if (error) {
    return <ServerError />;
  }

  let allCertificates: Certificate[] =
    data?.me.certificates.map(mapCertificate) || [];

  const handleDelete = (certificateId: string) => {
    setDeleteItemId(certificateId);
  };

  const handleDeleteClose = () => {
    setDeleteItemId(null);
  };

  const handleEdit = (certificateId: string) => {
    setSelectedCertificate(certificateId);
    setModalBarVisible(true);
  };

  const renderContent = () => {
    if (loading || !data) {
      return <Loading />;
    }

    const certificates = applyCertificateFilter(filter, allCertificates);

    return (
      <MyCertificatesTable
        certificates={certificates}
        onDelete={handleDelete}
        onEdit={handleEdit}></MyCertificatesTable>
    );
  };

  let selectedItem =
    allCertificates?.find((x) => x.id === selectedCertificate) ?? null;

  return (
    <>
      <PageHeader title={t('certificates.title')}></PageHeader>
      <Card>
        <Card.Header className="h-auto">
          <CertificatesFilter
            filter={filter}
            issuedByOptions={collectIssuedByFilterOptions(allCertificates)}
            issueDateYearOptions={collectIssueDateYearFilterOptions(
              allCertificates,
            )}
            expirationDateYearOptions={collectExpirationDateYearFilterOptions(
              allCertificates,
            )}
            onChange={setFilter}
            onCreateBtnClick={handleCreateBtnClick}></CertificatesFilter>
        </Card.Header>
        {renderContent()}
      </Card>

      <CertificateDeleteConfirmation
        visible={!!deleteItemId}
        submitInProgress={deleteInProgress}
        onClose={handleDeleteClose}
        onSubmit={certificateDelete}
      />
      <CertificateSaveModal
        show={saveModalVisible}
        onSave={handleSaveSidebarClose}
        onClose={handleSaveSidebarClose}
        certificate={selectedItem}
      />
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={5} rows={5} />;
};

export default MyCertificates;
