import React from 'react';
import { InputGroup } from 'react-bootstrap';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type Props = {
  value?: string;
  onFilterChange: (filter: string) => void;
  placeholder?: string;
};

const GlobalTableFilter: React.FC<Props> = ({
  value,
  onFilterChange,
  placeholder,
}) => {
  const inputProps = value !== undefined ? { value: value || '' } : {};

  return (
    <InputGroup className="input-group-flush input-group-merge input-group-reverse">
      <input
        className="list-search form-control"
        type="search"
        onChange={(e) => onFilterChange(e.currentTarget.value)}
        placeholder={placeholder}
        {...inputProps}
      />
      <InputGroup.Text>
        <FlairIcon icon="search-filled" />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default GlobalTableFilter;
