import { useCallback } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useCreateMyTimeEntryMutation } from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';

type Result = {
  handleStartTracking: () => Promise<void>;
  loading: boolean;
};

export const useStartTimeTracking = (): Result => {
  const errorHandler = useMutationErrorHandler();
  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const [startTimeTracking, mutationResult] = useCreateMyTimeEntryMutation({
    update(cache, cacheResult) {
      const createdMyTimeEntry =
        cacheResult.data?.timeTracking.createMyTimeEntry?.me?.currentTimeEntry;
      if (!createdMyTimeEntry) {
        return;
      }
      // Updating timeSheets
      if (createdMyTimeEntry.timeSheetId) {
        const timeSheetCacheId = cache.identify({
          Id: createdMyTimeEntry.timeSheetId,
          __typename: 'MyTimeSheet',
        });
        // I prefer to use evict here, it just force to reread all queries which use timeSheetId.timeEntries
        // Other way is to add newly created object to timeEntries and clarify that we use same fragments
        cache.evict({
          id: timeSheetCacheId,
          fieldName: 'timeEntries',
        });
      }
    },
  });

  const handleStartTracking: () => Promise<void> = useCallback(() => {
    return startTimeTracking()
      .then((response) => {
        const errors = response.data?.timeTracking.createMyTimeEntry.errors2;
        const warnings = response.data?.timeTracking.createMyTimeEntry.warnings;
        showErrorsAndWarningsToasts({
          errors,
          warnings,
        });
      })
      .catch(errorHandler);
  }, [startTimeTracking, errorHandler, showErrorsAndWarningsToasts]);

  return {
    handleStartTracking,
    loading: mutationResult.loading,
  };
};
