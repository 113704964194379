import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../components/form/FormGroup';
import InputLabel from '../../../../components/form/InputLabel';
import LoomVideoRecordWithPreview from '../LoomVideo/LoomVideoRecordWithPreview';
import { RecordedLoomVideo } from '../LoomVideo';
import { Maybe } from '../../../../utils/maybe';

type Props = {
  absenceCategoriesGroupSelect: React.ReactNode;
  absenceCategorySelect: React.ReactNode;
  absenceRequestPolicySelect?: React.ReactNode;
  startDateInput: React.ReactNode;
  startDateTimeInput: React.ReactNode;
  endDateInput: React.ReactNode;
  endDateTimeInput: React.ReactNode;
  fileInput?: React.ReactNode;
  noteInput: React.ReactNode;
  absenceRequestPolicyRules?: React.ReactNode;
  specialRules?: React.ReactNode;
  requestingAmountBadge?: React.ReactNode;
  overlappingAbsences?: React.ReactNode;
  requireNote: boolean;
  loomVideo?: Maybe<RecordedLoomVideo>;
  onDeleteLoomVideo?: () => void;
  onRecordLoomVideo?: (loomVideo: RecordedLoomVideo) => void;
};

const DateInputContainer: React.FC = ({ children }) => (
  <div className="mb-2">{children}</div>
);

const FormBody: React.FC<Props> = ({
  absenceCategoriesGroupSelect,
  absenceCategorySelect,
  absenceRequestPolicySelect,
  absenceRequestPolicyRules,
  startDateInput,
  startDateTimeInput,
  endDateInput,
  endDateTimeInput,
  fileInput,
  noteInput,
  specialRules,
  requestingAmountBadge,
  overlappingAbsences,
  requireNote,
  loomVideo,
  onDeleteLoomVideo,
  onRecordLoomVideo,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="justify-content-center">
      <Col>
        <Row>
          <Col>
            <FormGroup>{absenceCategoriesGroupSelect}</FormGroup>
            <FormGroup>{absenceCategorySelect}</FormGroup>
            {absenceRequestPolicySelect && (
              <FormGroup>{absenceRequestPolicySelect}</FormGroup>
            )}
          </Col>
        </Row>
        {absenceRequestPolicyRules && (
          <Row>
            <Col>{absenceRequestPolicyRules}</Col>
          </Row>
        )}
        {specialRules && (
          <Row>
            <Col>{specialRules}</Col>
          </Row>
        )}
        <Row>
          <Col>
            <FormGroup>
              <InputLabel label={t('requestAbsence.form.period')} />

              <Row>
                <Col>
                  <Row>
                    <Form.Label column className="col-auto">
                      {t('requestAbsence.form.startDate')}
                    </Form.Label>
                    <Col>
                      <DateInputContainer>{startDateInput}</DateInputContainer>
                      {startDateTimeInput}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Form.Label column className="col-auto">
                      {t('requestAbsence.form.endDate')}
                    </Form.Label>
                    <Col>
                      <DateInputContainer>{endDateInput}</DateInputContainer>
                      {endDateTimeInput}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        {fileInput}
        <Row>
          <Col>
            <FormGroup>
              <InputLabel
                label={t('requestAbsence.form.note')}
                required={requireNote}
              />
              {noteInput}
            </FormGroup>
          </Col>
        </Row>
        {requestingAmountBadge && (
          <Row>
            <Col>
              <FormGroup>{requestingAmountBadge}</FormGroup>
            </Col>
          </Row>
        )}
        {overlappingAbsences && (
          <Row>
            <Col>
              <FormGroup>{overlappingAbsences}</FormGroup>
            </Col>
          </Row>
        )}
        {onRecordLoomVideo && (
          <LoomVideoRecordWithPreview
            loomVideo={loomVideo}
            onDelete={onDeleteLoomVideo}
            onRecord={onRecordLoomVideo}
          />
        )}
      </Col>
    </Row>
  );
};

export default FormBody;
