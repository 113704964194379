import {
  CelebrationFragment,
  useUpdateCelebrationMutation,
} from '../../__generated__/graphql';

export const useUpdateCelebration = () => {
  return useUpdateCelebrationMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const updatedCelebration: CelebrationFragment | null =
        response.data.celebrations.updateCelebration.record;
      if (!updatedCelebration) {
        return;
      }
      cache.evict({
        fieldName: 'celebrationsPagination',
      });
      cache.gc();
    },
  });
};
