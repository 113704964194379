import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { PeersReviewList } from '../PeerReviewsSection/PeersReviewList/PeersReviewList';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

import './styles.css';
import { SelectMultipleEmployees } from '../../../../components/Selects/SelectMultipleEmployees';
import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import {
  DeleteConfirmationModalDetails,
  PeerFeedback,
} from '../EmployeeEvaluation/types';
import { PeerDeleteConfirmationModal } from './PeerDeleteConfirmationModal';
import { Maybe } from '../../../../../../utils/maybe';
import WidgetSpinner from '../../../Dashboard/CommonComponents/WidgetSpinner';

type Props = {
  visible: boolean;
  onClose: () => void;
  onAssign: (employeeId: string) => void;
  onRemove: (id: string, callback?: () => void) => void;
  maxPeers: number;
  peerOptions: EmployeeOption[];
  peerFeedbacks: PeerFeedback[];
  loadingOperations: boolean;
  onLoadOptions?: (searchTerm: string) => void;
};

const i18Path =
  'employeePage.performanceReview.nextPerformanceReview.assignPeerModal';

export const AssignPeerModal: React.FC<Props> = ({
  visible,
  onClose,
  onAssign,
  onRemove,
  maxPeers,
  peerOptions,
  peerFeedbacks,
  loadingOperations,
  onLoadOptions,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [deleteConfirmationModalDetails, setDeleteConfirmationModalDetails] =
    useState<DeleteConfirmationModalDetails>({
      visible: false,
      feedbackRequestId: null,
    });

  const reachedMax = peerFeedbacks.length === maxPeers;

  const handlePeerSelect = (selectedOptions: string[]) => {
    onAssign(selectedOptions[selectedOptions.length - 1]);
  };

  const handleOnDelete = (feedbackRequestId: string) => {
    const peerFeedback = peerFeedbacks.find((p) => p.id === feedbackRequestId);
    if (peerFeedback) {
      if (peerFeedback.hasFilledAnswers) {
        setDeleteConfirmationModalDetails({
          visible: true,
          feedbackRequestId,
        });
      } else {
        onRemove(feedbackRequestId);
      }
    }
  };

  const handleCloseDeleteConfirmationModal = () => {
    setDeleteConfirmationModalDetails({
      visible: false,
      feedbackRequestId: null,
    });
  };

  const handleSubmitConfirmationModal = (feedbackRequestId: Maybe<string>) => {
    if (feedbackRequestId) {
      onRemove(feedbackRequestId, handleCloseDeleteConfirmationModal);
    }
  };

  const renderContent = () => {
    if (loadingOperations) {
      return <WidgetSpinner />;
    }

    if (!peerFeedbacks.length) {
      return (
        <div className="d-flex gap-2 flex-column align-items-center justify-content-center mb-3">
          <FlairIcon icon="user-circle-plus" size="xxl" />
          <span className="h4-medium text-gray-900">
            {t('noPeersAssigned.title')}
          </span>
          <span className="h4-regular no-assigned-peer-description">
            {t('noPeersAssigned.description', { maxPeers })}
          </span>
        </div>
      );
    }

    return (
      <PeersReviewList
        isViewMode={false}
        peerFeedbacks={peerFeedbacks}
        onDelete={handleOnDelete}
        onRowClick={() => {}}
      />
    );
  };

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      className="assign-peer-modal">
      <Modal.Header closeButton className="card-header border-0 mb-3">
        <h2 className="modal-title text-center">{t('title')}</h2>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div>
          <div className="mb-4">
            <SelectMultipleEmployees
              isAsync={true}
              renderMode="block"
              values={peerFeedbacks.map((p) => p.reviewer.id)}
              onChange={handlePeerSelect}
              placeholder={t('searchPlaceholder')}
              defaultOptions={peerOptions}
              options={peerOptions}
              loadOptions={onLoadOptions}
              isDisabled={loadingOperations || reachedMax}
            />
          </div>
          {renderContent()}
        </div>
        <PeerDeleteConfirmationModal
          visible={deleteConfirmationModalDetails.visible}
          onClose={handleCloseDeleteConfirmationModal}
          submitInProgress={loadingOperations}
          onSubmit={() =>
            handleSubmitConfirmationModal(
              deleteConfirmationModalDetails.feedbackRequestId,
            )
          }
        />
      </Modal.Body>
    </Modal>
  );
};
