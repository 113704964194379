import React, { useContext } from 'react';
import Button from '../../../../../../components/button';
import { SimpleStep } from '../../../../components/Step';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { TicketModalContext } from './TicketModalContext';

type Props = {
  isExpanded: boolean;
  isSubmitting: boolean;
};
const i18Path = 'support.tickets.newTicket.footer';

// TODO if the expand modal feature will be added add the condition and the ExpandedFooter
export const Footer: React.FC<Props> = ({
  isExpanded = false,
  isSubmitting,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const { onCancel, currentStep, maxStep, steps } =
    useContext(TicketModalContext);

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <Button
        onClick={onCancel}
        disabled={isSubmitting}
        variant="outline-primary"
        label={t(`buttons.${currentStep === 0 ? 'cancel' : 'back'}`)}
        className="d-flex align-items-center"></Button>
      <SimpleStep steps={steps} activeStep={currentStep} />
      <Button
        type="submit"
        isProcessing={isSubmitting}
        label={t(`buttons.${currentStep >= maxStep - 1 ? 'submit' : 'next'}`)}
        variant="primary"
        className="d-flex align-items-center"></Button>
    </div>
  );
};
