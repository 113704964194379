import React from 'react';
import { AbsenceInfo } from '../types';
import { Trans, useTranslation } from 'react-i18next';
import AbsenceHint from './AbsenceHint';
import styled from '@emotion/styled';
import { formatDate } from '../../../../../../../utils/dateUtils';

type Props = {
  absences: AbsenceInfo[];
  year: number;
};

const i18nKey = 'absences.cards.absencesHint';

export const AbsencesHint: React.FC<Props> = ({ absences, year }) => {
  const { t } = useTranslation();

  if (!absences.length) {
    return null;
  }
  const totalDays = absences.reduce((acc, cur) => acc + cur.workindDays, 0);

  return (
    <AbsenceHint
      text={
        <small>
          <Trans
            t={t}
            i18nKey={`${i18nKey}.message`}
            values={{
              count: totalDays,
              period: year,
            }}
            components={[<strong />]}
          />
          <AbsencesList absences={absences} />
        </small>
      }
    />
  );
};

const AbsencesList: React.FC<{ absences: AbsenceInfo[] }> = ({ absences }) => {
  const { t } = useTranslation();

  return (
    <StyledList className="ps-3">
      {absences.map((item) => (
        <li key={item.absenceId}>
          <Trans
            t={t}
            i18nKey={`${i18nKey}.info`}
            values={{
              startDate: formatDate(item.startDay, 'dd MMMM yyyy'),
              endDate: formatDate(item.endDay, 'dd MMMM yyyy'),
              count: item.workindDays,
            }}
            components={[<strong />]}
          />
        </li>
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul`
  list-style-type: none;
`;
