import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PageHeader from '../../../components/PageHeader';
import { AbsenceRequestsTable } from './AbsenceRequestsTable';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  tab?: string;
};
const i18Path = 'absences';
export const AbsencesRequests: React.FC<Props> = ({ tab }) => {
  const t = useNamespacedTranslation(i18Path);
  const titleToDisplay = t('subMenuItems.absenceRequests.title');
  const needsPageHeader = tab !== 'all';

  return (
    <>
      {needsPageHeader ? (
        <PageHeader title={titleToDisplay} subtitle={t('title')} />
      ) : null}

      <Row>
        <Col>
          <AbsenceRequestsTable />
        </Col>
      </Row>
    </>
  );
};
