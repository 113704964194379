import * as React from 'react';

const CafeOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M2 12.998H12.4975M11.4978 2.50049H13.4973C13.6299 2.50049 13.757 2.55315 13.8508 2.6469C13.9445 2.74065 13.9972 2.86779 13.9972 3.00037V4.06262C13.9972 4.44378 13.8458 4.80933 13.5762 5.07885C13.3067 5.34837 12.9412 5.49978 12.56 5.49978H11.4978V2.50049ZM2.99976 2.50049H11.4978V8.49907C11.4978 8.8273 11.4331 9.15231 11.3075 9.45556C11.1819 9.7588 10.9978 10.0343 10.7657 10.2664C10.5336 10.4985 10.2581 10.6826 9.95483 10.8082C9.65159 10.9338 9.32658 10.9985 8.99835 10.9985H5.49917C4.83629 10.9985 4.20055 10.7352 3.73182 10.2664C3.26309 9.79769 2.99976 9.16196 2.99976 8.49907V2.50049Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default CafeOutlineIcon;
