import React from 'react';
import Time from '../components/Time';
import { useNow } from '../../../../../hooks/useNow';

const millisecondsToHours = (milliseconds: number): number =>
  milliseconds / 1_000 / 60 / 60;

const millisecondsDifference = (date: Date, now: Date): number =>
  now.getTime() - date.getTime();

const INTERVAL = 1000;

const TimeUntilNow: React.FC<{ date: Date; offset?: number }> = ({
  date,
  offset = 0,
}) => {
  const { now } = useNow(INTERVAL);

  return (
    <Time
      hours={millisecondsToHours(
        Math.max(millisecondsDifference(date, now) - offset, 0),
      )}
      format="hh:mm:ss"
    />
  );
};

export default TimeUntilNow;
