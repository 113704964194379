import React, { useEffect, useState } from 'react';
import { ExtractProps } from '../../../../../../utils/react';
import ModalSidebar from '../../../../components/ModalSidebar';
import { WorkflowItem, WorkflowItemStatus } from '../../WorkflowItems/types';
import { WorkflowItemDataRequestComponent } from '../WorkflowItemDataRequest';
import { WorkflowItemEvaluationRequestForm } from '../WorkflowItemEvaluationRequestForm';
import { WorkflowItemFileRequestComponent } from '../WorkflowItemFileRequest';
import { WorkflowItemRegularForm } from '../WorkflowItemRegular';
import { Tab, Tabs } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import CommentListWrapper from '../../../../components/Comment/CommentListWrapper/CommentListWrapper';
import { Maybe } from '../../../../../../utils/maybe';
import { WorkflowItemVideoRequestForm } from '../WorkflowItemVideoRequestForm/WorkflowItemVideoRequestForm';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import './WorkflowItemSidebar.css';
import { WorkflowItemPRRequestForm } from '../WorkflowItemPRRequestForm';
import VideoAndAttachments from './VideoAndAttachments';
import {
  useUpdateWorkflowItemRegularMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';

type ModalSidebarProps = Pick<ExtractProps<typeof ModalSidebar>, 'onClose'>;

type Props = ModalSidebarProps & {
  workflowId: string | null;
  workflowItem: WorkflowItem | null;
  showComments: Maybe<boolean>;
};

type ContentProps = Pick<Props, 'onClose'> & {
  workflowId: string;
  workflowItem: WorkflowItem;
};

const Content: React.FC<ContentProps> = ({ workflowItem, ...rest }) => {
  if (workflowItem.itemType === 'REGULAR') {
    return <WorkflowItemRegularForm {...rest} workflowItem={workflowItem} />;
  } else if (workflowItem.itemType === 'CYCLE_ASSIGNMENT') {
    return <WorkflowItemPRRequestForm {...rest} workflowItem={workflowItem} />;
  } else if (workflowItem.itemType === 'VIDEO_REQUEST') {
    return (
      <WorkflowItemVideoRequestForm {...rest} workflowItem={workflowItem} />
    );
  } else if (workflowItem.itemType === 'DATA_REQUEST') {
    return (
      <WorkflowItemDataRequestComponent {...rest} workflowItem={workflowItem} />
    );
  } else if (workflowItem.itemType === 'FILE_REQUEST') {
    return (
      <WorkflowItemFileRequestComponent {...rest} workflowItem={workflowItem} />
    );
  } else if (workflowItem.itemType === 'EVALUATION_REQUEST') {
    return (
      <WorkflowItemEvaluationRequestForm
        {...rest}
        workflowItem={workflowItem}
      />
    );
  }
  return null;
};

const i18Path = 'performanceReview.workflowsItems.sidebar';

const tabKeys = {
  INFORMATION: 'information',
  COMMENTS: 'comments',
};

export const WorkflowItemSidebar: React.FC<Props> = ({
  workflowItem,
  workflowId,
  onClose,
  showComments,
  ...rest
}) => {
  const t = useNamespacedTranslation(i18Path);
  const errorHandler = useMutationErrorHandler();
  const [selectedTabKey, setSelectedTabKey] = useState<string | null>(
    tabKeys.INFORMATION,
  );

  const [updateItem] = useUpdateWorkflowItemRegularMutation();

  useEffect(() => {
    if (showComments) {
      setSelectedTabKey(tabKeys.COMMENTS);
    }
  }, [showComments]);

  const isSelectedTab = (tabKey: string) => tabKey === selectedTabKey;

  const handleClose = () => {
    setSelectedTabKey(tabKeys.INFORMATION);
    onClose();
  };

  const handleStatusChange = (status: WorkflowItemStatus) => {
    if (!workflowItem) {
      return;
    }

    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
        },
      },
    }).catch(errorHandler);
  };

  const onPlayVideo = () => {
    if (
      workflowItem &&
      !workflowItem.readonly &&
      workflowItem.status === WorkflowItemStatusGenerated.NotStarted
    ) {
      const status = WorkflowItemStatusGenerated.InProgress;
      handleStatusChange(status);
    }
  };

  return (
    <ModalSidebar
      show={!!workflowItem}
      onClose={handleClose}
      header={t('title')}
      content={
        workflowItem &&
        workflowId && (
          <div className="d-flex">
            <VideoAndAttachments
              workflowItem={workflowItem}
              onPlayVideo={onPlayVideo}
            />
            <div className="flex-grow-1 main-details-container">
              <Tabs
                activeKey={selectedTabKey ?? tabKeys.INFORMATION}
                className="mx-4"
                onSelect={setSelectedTabKey}>
                <Tab eventKey={tabKeys.INFORMATION} title={t('information')}>
                  {isSelectedTab(tabKeys.INFORMATION) && (
                    <div className="workflow-side-panel-body">
                      <Content
                        {...rest}
                        workflowId={workflowId}
                        workflowItem={workflowItem}
                        onClose={onClose}
                      />
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey={tabKeys.COMMENTS}
                  title={t('comments', { count: workflowItem.commentsCount })}
                  className="h-100 comments-tab">
                  {isSelectedTab(tabKeys.COMMENTS) && (
                    <CommentListWrapper
                      recordId={workflowItem.id}
                      relatedObjectName={RelatedObjectNames.WorkflowItem}
                    />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        )
      }
    />
  );
};
