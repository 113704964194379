import { getEmployeeInitials } from '../../utils/employeeInitials';
import {
  EmployeeByTeamSelectQuery,
  EmployeeSelectInfoColleagueFragment,
  EmployeeSelectInfoFragment,
} from '../../__generated__/graphql';
import { EmployeeOption } from '../Selects/EmployeeOptionLabel';
import { EmployeeInfo, TeamWithMembersOption } from './types';

export const mapEmployeeOption = (
  src: EmployeeSelectInfoFragment | EmployeeSelectInfoColleagueFragment,
): EmployeeOption => ({
  label: src.Name,
  value: src.Id,
  initials: getEmployeeInitials(src),
});

export const mapTeamWithEmployeeOption = (
  src: EmployeeByTeamSelectQuery['teams'][0],
): TeamWithMembersOption => ({
  label: src.Name,
  value: src.Id,
  employeeIds: src.employees.map((x) => x.Id),
});

export const mapToEmployeeInfo = (
  src: EmployeeSelectInfoColleagueFragment,
): EmployeeInfo => ({
  id: src.Id,
  name: src.Name,
  initials: getEmployeeInitials(src),
  avatarUrl: src.avatar?.url ?? null,
});
