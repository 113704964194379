import * as React from 'react';

const HelpDeskFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM7.5 10C7.5 9.50555 7.64663 9.0222 7.92133 8.61107C8.19603 8.19995 8.58648 7.87952 9.04329 7.6903C9.50011 7.50108 10.0028 7.45157 10.4877 7.54804C10.9727 7.6445 11.4181 7.8826 11.7678 8.23223C12.1174 8.58186 12.3555 9.02732 12.452 9.51227C12.5484 9.99723 12.4989 10.4999 12.3097 10.9567C12.1205 11.4135 11.8001 11.804 11.3889 12.0787C10.9778 12.3534 10.4945 12.5 10 12.5C9.33696 12.5 8.70108 12.2366 8.23224 11.7678C7.7634 11.2989 7.5 10.663 7.5 10ZM14.3969 4.71875L12.1711 6.94531C11.5375 6.49312 10.7784 6.25006 10 6.25006C9.22156 6.25006 8.46254 6.49312 7.82891 6.94531L5.60313 4.71875C6.83689 3.68898 8.39296 3.12492 10 3.12492C11.607 3.12492 13.1631 3.68898 14.3969 4.71875ZM5.60313 15.2812L7.82891 13.0547C8.46254 13.5069 9.22156 13.7499 10 13.7499C10.7784 13.7499 11.5375 13.5069 12.1711 13.0547L14.3969 15.2812C13.1631 16.311 11.607 16.8751 10 16.8751C8.39296 16.8751 6.83689 16.311 5.60313 15.2812Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default HelpDeskFilledIcon;
