import { Candidate, Evaluation, Evaluator, Job, Score, Status } from './types';
import { isAfter, isSameDay } from 'date-fns';
import { EvaluationsQuery } from '../../../__generated__/graphql';
import i18next from 'i18next';

export const mapEvaluations = (
  evaluations: EvaluationsQuery['evaluations'],
): Evaluation[] =>
  evaluations.map((ev) => ({
    id: ev.Id,
    candidate: mapCandidate(ev.candidate),
    score: ev.flair__Evaluated__c ? ev.flair__Final_Score__c : null,
    scoreRange: getScoreRange(ev.flair__Final_Score__c, ev.flair__Evaluated__c),
    overallComment: ev.flair__Overall_Comment__c,
    evaluator: mapEvaluator(ev.employee),
    stage: ev.candidate.flair__Funnel_Stage__c ?? '--',
    dueDate: ev.flair__Due_Date__c ?? '-',
    status: getStatus(ev),
    jobName: ev.candidate.job?.Name,
    isEvaluated: ev.flair__Evaluated__c,
  }));

const getStatus = (ev: EvaluationsQuery['evaluations'][0]): Status => {
  const i18Path = 'recruitment.jobPage.evaluations';

  if (ev.flair__Evaluated__c) {
    return {
      label: i18next.t(`${i18Path}.completed`),
      value: 'completed',
      variant: 'success',
    };
  }

  if (
    ev.flair__Due_Date__c &&
    isAfter(new Date(), new Date(ev.flair__Due_Date__c)) &&
    !isSameDay(new Date(), new Date(ev.flair__Due_Date__c))
  ) {
    return {
      label: i18next.t(`${i18Path}.overdue`),
      value: 'overdue',
      variant: 'danger',
    };
  }

  if (
    ['REJECTED', 'HIRED'].includes(ev.candidate.flair__Funnel_Stage__c || '')
  ) {
    return {
      label: i18next.t(`${i18Path}.completed`),
      value: 'completed',
      variant:
        ev.candidate.flair__Funnel_Stage__c === 'HIRED' ? 'success' : 'danger',
    };
  }

  return {
    label: i18next.t(`${i18Path}.pending`),
    value: 'pending',
    variant: 'warning',
  };
};

export const getScoreRange = (score: Score, isEvaluated: boolean): Score => {
  if (score == null || !isEvaluated) {
    return null;
  }
  if (score > 75) {
    return 100;
  } else if (score >= 50) {
    return 75;
  } else if (score > 0) {
    return 25;
  } else {
    return 0;
  }
};

export const mapCandidate = (
  candidate: EvaluationsQuery['evaluations'][0]['candidate'],
): Candidate => ({
  id: candidate.Id,
  name: candidate.flair__Full_Name__c,
  job: mapJob(candidate.job),
});

const mapJob = (
  job: EvaluationsQuery['evaluations'][0]['candidate']['job'],
): Job | null =>
  job
    ? {
        id: job.Id,
        name: job.Name,
      }
    : null;

const mapEvaluator = (
  evaluator: EvaluationsQuery['evaluations'][0]['employee'],
): Evaluator => ({
  id: evaluator.Id,
  name: evaluator.Name,
  avatar: evaluator.avatar?.url ?? '',
});
