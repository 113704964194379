import { DirectoryQuery } from '../../../__generated__/graphql';
import { EmployeeRow } from './types';

export const mapEmployees = (
  employees: DirectoryQuery['activeEmployees'] | undefined,
): EmployeeRow[] => {
  if (!employees) {
    return [];
  }

  return employees.map((e) => ({
    id: e.Id,
    name: e.Name,
    department: e.department?.Name ?? '-',
    position: e.flair__Position__c ?? '-',
    location: e.location?.Name ?? '-',
    companyEmail: e.flair__Company_Email__c ?? '-',
    phone: e.flair__Company_Phone_Number__c ?? '-',
    managerName: e.manager?.Name ?? '-',
    manager: e.manager
      ? {
          id: e.manager?.Id,
          name: e.manager?.Name,
        }
      : null,
    avatarUrl: e.avatar?.url ?? '',
  }));
};
