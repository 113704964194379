import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Loading: React.FC = () => {
  return (
    <div className="d-none d-md-flex align-items-center">
      <Skeleton className="me-3" width={125} height={25} />
      <Skeleton className="me-3" width={125} height={25} />
    </div>
  );
};

export default Loading;
