import { ApolloError } from '@apollo/client';
import { OptionBase } from '../../../../../components/Select/types';
import { EmployeeOption } from '../../../components/Selects/EmployeeOptionLabel';
import {
  GoalsDetailSelectOptionsQuery,
  ObjectiveTimePeriodFragment,
  TagFragment,
  TeamFragment,
  useGoalsDetailSelectOptionsQuery,
  useGoalsEmployeesQuery,
  useGoalsFilterSelectOptionsQuery,
} from '../../../__generated__/graphql';
import { mapEmployeeOption, mapGoalType } from './mappings';
import { getGoalPeriodStr, getGoalTypeStr } from './translations';
import { GoalBase, GoalType } from './types';
import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import i18next from 'i18next';
import { GoalType as GoalTypeEnum } from '../../../__generated__/graphql';
import { TFunction, useTranslation } from 'react-i18next';

export type GoalFilterOptions = {
  tags: OptionBase[];
  periods: GoalPeriodOption[];
  allPeriods: GoalPeriodOption[];
  employees: EmployeeOption[];
  colleagues: EmployeeOption[];
  managerReports: EmployeeOption[];
  goalTypes: OptionBase[];
};

export type GoalOptions = GoalFilterOptions & {
  teams: OptionBase[];
  allGoals: GoalOption[];
};

export type GoalPeriodOption = OptionBase & {
  startDate: string;
  endDate: string;
  periodStr: string;
  active: boolean;
};

type UseGoalSelectOptionsResult<TResult> =
  | {
      loading: true;
      error?: undefined;
      options?: undefined;
    }
  | {
      loading: false;
      error: ApolloError;
      options?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      options: TResult;
    };

export type GoalOption = OptionBase & {
  goalType: GoalType;
};

export const useGoalFilterOptions = (
  fetchedGoals: GoalBase[] | undefined,
): UseGoalSelectOptionsResult<GoalFilterOptions> => {
  const {
    data: dataEmployees,
    loading: loadingEmployees,
    error: errorEmployees,
  } = useGoalsEmployeesQuery();

  const { t } = useTranslation();
  const { data, loading, error } = useGoalsFilterSelectOptionsQuery();

  //This is done to handle the case when some goals have inactive periods
  const periodsToShow = useMemo<GoalPeriodOption[]>(() => {
    const fetchedGoalsPeriods = uniqBy(
      (fetchedGoals ?? []).filter((g) => g.period?.id).map((g) => g.period?.id),
      (id) => id,
    );

    return (data?.objectivePeriods ?? [])
      .filter((p) => {
        if (p.flair__Active__c) {
          return true;
        } else {
          return fetchedGoalsPeriods.includes(p.Id);
        }
      })
      .map(mapGoalPeriod);
  }, [fetchedGoals, data?.objectivePeriods]);

  if (error) {
    return {
      loading: false,
      error,
    };
  }
  if (errorEmployees) {
    return {
      loading: false,
      error: errorEmployees,
    };
  }

  if (!data || !dataEmployees || loading || loadingEmployees) {
    return {
      loading: true,
    };
  }
  return {
    options: {
      tags: data?.tags.map(mapGoalTag),
      periods: periodsToShow,
      allPeriods: data?.objectivePeriods.map(mapGoalPeriod),
      employees: dataEmployees?.activeEmployees.map(mapEmployeeOption),
      colleagues: dataEmployees?.me.allColleagues.map(mapEmployeeOption),
      managerReports: dataEmployees?.manager.employees.map(mapEmployeeOption),
      goalTypes: getGoalTypes(t),
    },
    loading: false,
  };
};

export const useGoalSelectOptions =
  (): UseGoalSelectOptionsResult<GoalOptions> => {
    const { data, loading, error } = useGoalsDetailSelectOptionsQuery();

    const {
      data: dataEmployees,
      loading: loadingEmployees,
      error: errorEmployees,
    } = useGoalsEmployeesQuery();

    const { t } = useTranslation();

    if (error) {
      return {
        loading: false,
        error,
      };
    }
    if (errorEmployees) {
      return {
        loading: false,
        error: errorEmployees,
      };
    }

    if (!data || !dataEmployees || loading || loadingEmployees) {
      return {
        loading: true,
      };
    }

    return {
      options: {
        employees: dataEmployees?.activeEmployees.map(mapEmployeeOption),
        colleagues: dataEmployees?.me.allColleagues.map(mapEmployeeOption),
        managerReports: dataEmployees?.manager.employees.map(mapEmployeeOption),
        tags: data?.tags.map(mapGoalTag),
        periods: data?.objectivePeriods
          .filter((p) => p.flair__Active__c)
          .map(mapGoalPeriod),
        allPeriods: data?.objectivePeriods.map(mapGoalPeriod),
        teams: data?.teams.map(mapTeam),
        allGoals: data?.allObjectives
          .filter(isAbleToBeUsedAsParent)
          .map(mapGoalOption),
        goalTypes: getGoalTypes(t),
      },
      loading: false,
    };
  };

const mapGoalTag = (src: TagFragment): OptionBase => ({
  label: src.Name,
  value: src.Id,
});

const mapGoalPeriod = (src: ObjectiveTimePeriodFragment): GoalPeriodOption => ({
  label:
    src.Name +
    (src.flair__Active__c
      ? ''
      : ` (${i18next.t('performanceReview.goals2.inactive')})`),
  value: src.Id,
  startDate: src.flair__Start_Date__c,
  endDate: src.flair__End_Date__c,
  periodStr: getGoalPeriodStr(src.flair__Start_Date__c, src.flair__End_Date__c),
  active: src.flair__Active__c,
});

const mapTeam = (src: TeamFragment): OptionBase => ({
  label: src.Name,
  value: src.Id,
});

const isAbleToBeUsedAsParent = (
  src: GoalsDetailSelectOptionsQuery['allObjectives'][0],
): boolean => {
  if (src.__typename === 'CompanyObjective') {
    return src.flair__Nested_Goals_Allowed__c;
  }
  return true;
};

const mapGoalOption = (
  src: GoalsDetailSelectOptionsQuery['allObjectives'][0],
): GoalOption => ({
  label: getGoalOptionLabel(src),
  value: src.Id,
  goalType: mapGoalType(src.__typename!),
});

const getGoalOptionLabel = (
  src: GoalsDetailSelectOptionsQuery['allObjectives'][0],
): string => {
  if (src.__typename === 'EmployeeObjective') {
    return src.Name;
  }

  const goalTypeName = getGoalTypeStr(mapGoalType(src.__typename!));
  return `${src.Name} (${goalTypeName})`;
};

function getGoalTypes(t: TFunction): OptionBase[] {
  return Object.values(GoalTypeEnum).map((type) => ({
    label: t(`performanceReview.goals2.goalType.${type}`),
    value: type,
  }));
}
