import React from 'react';
import { WorkflowStepStatusInfo } from '../types';
import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  steps: WorkflowStepStatusInfo[];
};

type StepWithColorVariant = WorkflowStepStatusInfo & {
  colorVariant: 'success' | 'warning' | 'muted';
};

const getStepColorVariant = (step: WorkflowStepStatusInfo) => {
  if (step.completedItemsCount === step.itemsCount) {
    return 'success';
  }

  if (step.completedItemsCount === 0) {
    return 'muted';
  }

  return 'warning';
};

export const WorkflowStepsProgress: React.FC<Props> = ({ steps }) => {
  const stepsWithColor: StepWithColorVariant[] = steps.map((step) => {
    return {
      ...step,
      colorVariant: getStepColorVariant(step),
    };
  });

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id={`workflow-steps-progress}`}>
          <Popover.Body>
            <WorkflowStepsPopupInfo steps={stepsWithColor} />
          </Popover.Body>
        </Popover>
      }>
      <div className="d-flex">
        {stepsWithColor.map((step) => (
          <WorkflowStepStatus key={step.id} step={step} />
        ))}
      </div>
    </OverlayTrigger>
  );
};

const WorkflowStepStatus: React.FC<{ step: StepWithColorVariant }> = ({
  step,
}) => {
  return (
    <ProgressBar
      style={{ flexGrow: 1 }}
      className="progress-sm me-1"
      variant={step.colorVariant}
      now={step.completedItemsCount}
      max={step.itemsCount}
    />
  );
};

const WorkflowStepsPopupInfo: React.FC<{ steps: StepWithColorVariant[] }> = ({
  steps,
}) => {
  const t = useNamespacedTranslation('workflows.table.stepStatusProgressBar');

  return (
    <div>
      {steps.map((step) => (
        <div key={step.id} className="d-flex justify-content-between">
          <p className="fw-bold me-3">{step.name} </p>
          <p className={`text-${step.colorVariant}`}>
            {t('completedPercent', {
              completedCount: step.completedItemsCount,
              itemsCount: step.itemsCount,
            })}
          </p>
        </div>
      ))}
    </div>
  );
};
