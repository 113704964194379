import { useCallback, useState } from 'react';
import { TableRowTimeSheet } from './types';

export const useTimeSheetsExpand = () => {
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
  const handleExpandedChanged = useCallback(
    (rowId: string, newExpanded: boolean) => {
      setExpandedRowIds((prev) => {
        if (newExpanded) {
          return [...prev, rowId];
        } else {
          // return all except not expanded
          return prev.filter((curRowId) => curRowId !== rowId);
        }
      });
    },
    [setExpandedRowIds],
  );

  return { expandedRowIds, handleExpandedChanged };
};
