import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ServerError from '../../../../../components/ServerError';
import CardHeader from '../../../components/CardHeader';
import { useIsMyTimeTrackingEnabled } from '../../../hooks/timeTracking/useMyTrackingTypes';
import { useMyTimeTrackingQuery } from '../../../__generated__/graphql';
import Loading from './Loading';
import StartTimeTracking from './StartTimeTracking';
import TimeTracking from './TimeTracking';

const Content: React.FC = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useMyTimeTrackingQuery();
  const isMyTimeTrackingEnabled = useIsMyTimeTrackingEnabled();

  if (loading) return <Loading />;

  if (error || data === undefined) return <ServerError />;

  if (!isMyTimeTrackingEnabled) {
    return (
      <p className="text-center">{t('timeTracking.current.notEnabled')}</p>
    );
  }
  const entry = data.me.currentTimeEntry;

  return entry ? <TimeTracking entry={entry} /> : <StartTimeTracking />;
};

const CurrentTimeTracking: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>{t('timeTracking.current.title')}</CardHeader>
      <Card.Body>
        <Content />
      </Card.Body>
    </Card>
  );
};

export default CurrentTimeTracking;
