import React, { useEffect } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useCreateSlackInstallationMutation } from '../../__generated__/graphql';

export const SlackConnect: React.FC = () => {
  const [createInstallationUri, { data }] =
    useCreateSlackInstallationMutation();
  const errorHandler = useMutationErrorHandler();

  useEffect(() => {
    createInstallationUri().catch(errorHandler);
  }, [createInstallationUri, errorHandler]);

  useEffect(() => {
    const uri = data?.slack.slackAppAuthorizeUrl.installationUri;
    if (uri) {
      window.location.href = uri;
    }
  }, [data]);

  return <></>;
};
