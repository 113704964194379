import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  PredefinedImageInput,
  Props as PredefinedImageInputProps,
} from './PredefinedImageInput';

type Props = Omit<PredefinedImageInputProps, 'value' | 'onChange'> & {
  name: string;
};

export const PredefinedImageInputControlled: React.FC<Props> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();

  const renderAs = useCallback((props: any) => {
    return (
      <PredefinedImageInput
        {...props}
        value={props.value}
        onChange={props.onChange}
      />
    );
  }, []);

  return <Controller as={renderAs} name={name} control={control} />;
};
