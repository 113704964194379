import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { Accordion, AccordionProps, Card, CardProps } from 'react-bootstrap';
import { CardHeaderProps } from 'react-bootstrap/esm/CardHeader';

export const AccordionContainer = React.forwardRef<
  HTMLDivElement,
  AccordionProps
>(({ className, ...rest }, ref) => (
  <Accordion
    {...rest}
    className={classNames('d-flex', 'flex-column', 'gap-2', className)}
    ref={ref}
  />
));

export const AccordionCard = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, ...rest }, ref) => (
    <Card {...rest} className={classNames('mb-0', className)} ref={ref} />
  ),
);

export const AccordionHeader = React.forwardRef<
  HTMLDivElement,
  CardHeaderProps
>(({ role, ...rest }, ref) => (
  <Card.Header {...rest} role={role ?? 'button'} ref={ref} />
));

export const AccordionBody = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <Card.Body
    {...rest}
    className={classNames('p-0', 'border-top', className)}
    ref={ref}
  />
));
