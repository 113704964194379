import moment, { Moment } from 'moment';
import { Theme } from '../../../../../theme';
import { DateInterval } from '../../../../../utils/dateInterval';
import { minutesToHours } from '../../../../../utils/time';
import { getShiftBlockColorsInfo } from '../Common/logic';
import { ShiftInfo } from '../Common/types';
import { BaseFilter } from '../shiftsLogic';
import {
  DailyOpenShiftInfo,
  DailyShiftInfo,
  ShiftBlockStylingInfo,
  ShiftBlockStylingProp,
  ShiftRowIndexInfo,
} from './dailyShiftsTypes';

const SHIFT_CONTAINER_Y_MARGIN = 12;
const SHIFTS_CONTAINER_PADDING = 22;
const SHIFT_CONTAINER_HEIGT = 23;

export type DailyEmployeeShiftRow = ShiftRowIndexInfo & {
  shifts: ShiftInfo[];
  employeeName: string;
  employeePosition: string | null;
  isCurrentEmployee: boolean;
};

export type DailyFilter = BaseFilter & {
  day: Moment;
  employee: string | null;
};

export const isOverlap = (prevShift: DateInterval, shift: DateInterval) => {
  return (
    (shift.start.isSameOrAfter(prevShift.start) &&
      shift.start.isSameOrBefore(prevShift.end)) ||
    (prevShift.start.isSameOrAfter(shift.start) &&
      prevShift.start.isSameOrBefore(shift.end))
  );
};

export const mapMultiRowShifts = (
  shifts: (DailyShiftInfo | DailyOpenShiftInfo)[],
): {
  shifts: (DailyShiftInfo | DailyOpenShiftInfo)[];
  shiftsContainerHeight: number;
} => {
  let maxRowIndex = 0;

  shifts.map((shift, index) => {
    if (index === 0) return { ...shift, rowIndex: 0 };

    shifts.slice(0, index).forEach((prevShift) => {
      if (
        isOverlap(prevShift, shift) &&
        shift.rowIndex === prevShift.rowIndex
      ) {
        shift.rowIndex++;
        if (shift.rowIndex > maxRowIndex) maxRowIndex = shift.rowIndex;
      }
    });

    return { ...shift };
  });

  const shiftsContainerHeight =
    (maxRowIndex + 1) * SHIFT_CONTAINER_HEIGT +
    SHIFTS_CONTAINER_PADDING * 2 +
    maxRowIndex * SHIFT_CONTAINER_Y_MARGIN;

  return { shifts, shiftsContainerHeight };
};

export const getDailyShiftBlockStylingInfo = (
  shift: DateInterval,
  day: Moment,
  minHour: number,
  maxHour: number,
  hoursCount: number,
): ShiftBlockStylingInfo => {
  let isRightBorderVisible, isLeftBorderVisible;
  isRightBorderVisible = isLeftBorderVisible = true;

  let start = shift.start;
  let end = shift.end;
  let hoursDiff = moment.duration(end.diff(start)).asHours();
  let left =
    ((start.hour() + start.minute() / 60 - minHour) * 100) / hoursCount;

  if (day.isAfter(start, 'day')) {
    left = 0;
    hoursDiff = end.hour() + end.minute() / 60 - minHour;
    isLeftBorderVisible = false;
  } else if (end.isAfter(start, 'day')) {
    hoursDiff = maxHour - (start.hour() + minutesToHours(start.minute()));
    isRightBorderVisible = false;
  }

  return {
    width: (hoursDiff * 100) / hoursCount,
    left,
    isRightBorderVisible,
    isLeftBorderVisible,
  };
};

export const getDailyShiftBlockStylingProp = (
  shift: DailyShiftInfo | DailyOpenShiftInfo,
  day: Moment,
  minHour: number,
  maxHour: number,
  hoursCount: number,
  isCurrentEmployee?: boolean,
): ShiftBlockStylingProp => {
  let { background, onHoverBackground } = getShiftBlockColorsInfo(
    shift,
    isCurrentEmployee,
  );

  let topLeftBorderRad,
    topRightBorderRad,
    bottomRightBorderRad,
    bottomLeftBorderRad,
    borderLeft,
    borderRight,
    borderColor = Theme.color.paper3;

  topLeftBorderRad =
    topRightBorderRad =
    bottomRightBorderRad =
    bottomLeftBorderRad =
      '4px';

  borderLeft = borderRight = `1px solid ${borderColor}`;

  let stylingInfo = getDailyShiftBlockStylingInfo(
    shift,
    day,
    minHour,
    maxHour,
    hoursCount,
  );

  if (!stylingInfo.isLeftBorderVisible) {
    topLeftBorderRad = bottomLeftBorderRad = '0';
    borderLeft = 'unset';
  } else if (!stylingInfo.isRightBorderVisible) {
    topRightBorderRad = bottomRightBorderRad = '0';
    borderRight = 'unset';
  }

  return {
    width: stylingInfo.width,
    left: stylingInfo.left,
    background,
    onHoverBackground,
    borderColor,
    borderRadius: `${topLeftBorderRad} ${topRightBorderRad} ${bottomRightBorderRad} ${bottomLeftBorderRad}`,
    borderLeft,
    borderRight,
  };
};

export const getBreakIntervalString = ({ start, end }: DateInterval) =>
  `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
