import React, { useMemo } from 'react';
import { AbsenceType } from '../../../__generated__/graphql';
import AbsenceAmount from './AbsenceAmount';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  type: AbsenceType;
  amount: number;
  unlimited: boolean;
};

const i18Path = 'absences.cards.absenceCategories';

const CategoryAmount: React.FC<Props> = ({ type, amount, unlimited }) => {
  const t = useNamespacedTranslation(i18Path);

  return useMemo(() => {
    if (unlimited) {
      return <span className="time-text me-3">{t('unlimited')}</span>;
    }

    return (
      <span className="time-text me-3">
        <AbsenceAmount type={type} amount={amount} variant="normal" />
      </span>
    );
  }, [unlimited, type, amount, t]);
};

export default CategoryAmount;
