import React from 'react';
import Button from '../../../../../../components/button';

type CheerButtonProps = {
  onClick: () => void;
};

export const CheerButton: React.FC<CheerButtonProps> = ({ onClick }) => {
  return (
    <Button
      icon="confetti"
      variant="outline-primary"
      iconSize="xl"
      onClick={onClick}
      size="sm"
      className="cheer-btn"
    />
  );
};
