import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../../../theme';

type Variant = 'workday' | 'non_workday';

const theme = Theme.chart.workloadDays;
const backgroundColor = (variant: Variant) => {
  switch (variant) {
    case 'workday': {
      return theme.workday.color;
    }
    case 'non_workday': {
      return theme.nonWorkday.color;
    }
  }
};

const Container = styled.div<{ variant: Variant }>(
  {
    position: 'relative',
    width: '12%',
    paddingBottom: '12%',
    borderRadius: '0.5rem',
  },
  ({ variant }) => ({
    backgroundColor: backgroundColor(variant),
  }),
);

const ContentContainer = styled.div({
  position: 'absolute',
  height: '100%',
  width: '100%',
});

const ContentTable = styled.div({
  display: 'table',
  height: '100%',
  width: '100%',
});

const ContentCell = styled.div({
  display: 'table-cell',
  verticalAlign: 'middle',
  textAlign: 'center',
});

const Content: React.FC = ({ children }) => {
  return (
    <ContentContainer>
      <ContentTable>
        <ContentCell>{children}</ContentCell>
      </ContentTable>
    </ContentContainer>
  );
};

type Props = {
  variant: Variant;
  label: string;
};

const Day: React.FC<Props> = ({ variant, label }) => {
  return (
    <Container variant={variant}>
      <Content>{label}</Content>
    </Container>
  );
};

export default Day;
