import { StepNamesType } from '../NewTicketModal/types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

const i18Path = 'support.tickets.newTicket';

type Props = {
  stepName: StepNamesType;
  categoryName: string;
};
export const useAddTicketHeaderInfo = (props: Props) => {
  const t = useNamespacedTranslation(i18Path);
  const { stepName, categoryName } = props;
  if (stepName === 'CATEGORY') {
    return {
      title: t(`titles.${stepName}`),
      description: t(`descriptions.${stepName}`),
    };
  }

  return {
    title: categoryName,
    description: t(`descriptions.${stepName}`),
  };
};
