import React from 'react';
import { GoalStatus } from '../../types';
import { AutoGoalInfo, GoalStatusEx } from '../types';
import { StatusButton } from './GoalStatusButton';
import { GoalStatusTwinButton } from './GoalStatusTwinButton';

export type Props = {
  value: GoalStatusEx;
  onChange: (value: GoalStatusEx) => void;
  availableStatuses: GoalStatusEx[];
  isDisabled?: boolean;
  autoGoalInfo: AutoGoalInfo;
};

export const GoalStatusInput: React.FC<Props> = ({
  value,
  onChange,
  availableStatuses,
  isDisabled,
  autoGoalInfo,
}) => {
  const renderStatusBtn = (status: GoalStatus) => (
    <StatusButton
      key={status}
      value={status}
      onClick={onChange}
      isSelected={status === value}
      disabled={
        (isDisabled || availableStatuses.every((x) => x !== status)) ?? false
      }
    />
  );

  const renderTwinButton = (isGoalOnTrack: undefined | boolean) => {
    return (
      <GoalStatusTwinButton
        key={'onTrackOnBehind'}
        isSelected={value === 'BEHIND_OR_ON_TRACK'}
        isGoalOnTrack={isGoalOnTrack}
        disabled={
          isDisabled ||
          availableStatuses.every((x) => x !== 'BEHIND_OR_ON_TRACK')
        }
        onClick={() => onChange('BEHIND_OR_ON_TRACK')}
      />
    );
  };

  return (
    <div className="d-flex">
      {renderStatusBtn('DRAFT')}
      {autoGoalInfo.isAuto ? (
        renderTwinButton(autoGoalInfo.goalOnTrackAutoState)
      ) : (
        <>
          {renderStatusBtn('BEHIND')}
          {renderStatusBtn('ON_TRACK')}
        </>
      )}
      {renderStatusBtn('DONE')}
      {renderStatusBtn('CANCELED')}
    </div>
  );
};
