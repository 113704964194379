import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useYupValidationResolver from '../../../components/form/useYupValidationResolver';

export type ExpenseDeclineFormData = {
  declineReason: string;
};

const validationSchema = yup.object().shape<ExpenseDeclineFormData>({
  declineReason: yup.string().max(32768).ensure().trim(),
});

type Props = {
  declineReason: string | null;
};

const fieldName = 'declineReason';

export const useExpenseDeclineForm = ({ declineReason }: Props) => {
  const defaultValues = {
    declineReason: declineReason ?? '',
  };
  const form = useForm<ExpenseDeclineFormData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return useMemo(
    () => ({
      fieldName,
      form,
    }),
    [form],
  );
};
