import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSheet } from '../types';
import { WorkloadType } from '../../../__generated__/graphql';

type Props = {
  timeSheet: TimeSheet;
  classes?: string;
};

const TimesheetDatesInfo: React.FC<Props> = ({ timeSheet, classes }) => {
  const { t } = useTranslation();

  let datesInfo = '';
  if (timeSheet.flair__Type__c === WorkloadType.Weekly) {
    datesInfo = `${t('timeTracking.timeSheet.actionsPanel.week')} ${moment(
      timeSheet.flair__Start_Date__c,
    ).isoWeek()}`;
  } else if (timeSheet.flair__Type__c === WorkloadType.Monthly) {
    datesInfo = `${t('timeTracking.timeSheet.actionsPanel.month')} ${
      moment(timeSheet.flair__Start_Date__c).month() + 1
    }`;
  }
  return (
    <div className={`${classes}`}>
      <div className="me-3"> {datesInfo}</div>
      <div className="">
        {`${moment(timeSheet.flair__Start_Date__c).format(
          'MMMM DD',
        )} - ${moment(timeSheet.flair__End_Date__c).format('MMMM DD, YYYY')}`}
      </div>
    </div>
  );
};
export default TimesheetDatesInfo;
