import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { hoursToMinutes, minutesToHours } from '../../../../utils/time';

const DurationFormat: React.FC<{ minutes: number }> = ({ minutes }) => {
  const { t } = useTranslation();
  const formatDuration = formattedDuration(t);

  return <>{formatDuration(minutes)}</>;
};

export const formattedDuration = (t: TFunction) => (timeInMinutes: number) => {
  const isNegative = timeInMinutes < 0;
  const minutesAbs = Math.abs(timeInMinutes);
  const hours = Math.floor(minutesToHours(minutesAbs));
  const minutes = Math.floor(minutesAbs - hoursToMinutes(hours));

  const format = (result: string) => `${isNegative ? '-' : ''}${result}`;

  if (hours > 0 && minutes === 0) {
    return format(t('durationFormat.hours', { hours }));
  }

  if (hours === 0) {
    return format(t('durationFormat.minutes', { minutes }));
  }

  return format(t('durationFormat.full', { hours, minutes }));
};

export default DurationFormat;
