import * as React from 'react';
import { useCurrentTimeTrackingTimelineLastStep } from '../../../../hooks/useCurrentTimeTrackingTimeline';
import { Spinner } from 'react-bootstrap';
import TimeUntilNow from '../../../TimeTracking/CurrentTimeTracking/TimeUntilNow';
import { minutesToMilliseconds } from 'date-fns';
import classNames from 'classnames';
import './TimeBadge.css';
import { CurrentTimeEntryFragment } from '../../../../__generated__/graphql';

type Props = {
  currentTimeEntry: CurrentTimeEntryFragment;
};

const TimeBadge: React.FC<Props> = ({ currentTimeEntry }) => {
  const step = useCurrentTimeTrackingTimelineLastStep(currentTimeEntry);

  const isWorking = step.kind === 'Working';

  const offset = isWorking ? minutesToMilliseconds(step.totalBreakTime) : 0;

  const badgeClassName = isWorking
    ? 'working-step-badge'
    : 'during-break-badge';

  return (
    <div
      className={classNames(
        'rounded-2 py-2-5 px-3-5 d-flex-all-center',
        badgeClassName,
      )}>
      <Spinner animation="grow" size="sm" className="me-2" />
      <TimeUntilNow date={step.startTime} offset={offset} />
    </div>
  );
};

export default TimeBadge;
