import React from 'react';
import { Card } from 'react-bootstrap';
import { SkeletonTable } from '../../../../../components/Skeleton/Table';

const Loading: React.FC = () => (
  <Card>
    <SkeletonTable className="card-table" columns={1} rows={3} />
  </Card>
);

export default Loading;
