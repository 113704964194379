import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useYupValidationResolver from '../../../../../../components/form/useYupValidationResolver';

export type UpdateKeyResultFormData = {
  currentValue: number;
  comments: string | null;
};

const validationSchema = yup.object().shape<UpdateKeyResultFormData>({
  comments: yup.string().max(32768).ensure().trim(),
  currentValue: yup.number().required(),
});

type Props = {
  comments: string | null;
  currentValue: number;
};

const commentsField = 'comments';
const currentValueField = 'currentValue';

export const useUpdateKeyResultForm = ({ comments, currentValue }: Props) => {
  const defaultValues = {
    comments: comments ?? '',
    currentValue: currentValue ?? '',
  };
  const form = useForm<UpdateKeyResultFormData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return useMemo(
    () => ({
      form,
      commentsField,
      currentValueField,
    }),
    [form],
  );
};
