import { t } from 'i18next';
import { NotificationTypes } from 'server/src/shared/NotificationTypes';

import {
  NotificationIconType,
  NotificationItemData,
  NotificationQueryItem,
} from '../types';
import AbsenceDateTimeRange from '../../AbsenceDateTimeRange';
import { Absence, AbsenceType } from '../../../__generated__/graphql';
import React from 'react';
import { routes } from '../../../routes';

const i18prefix = 'notifications.absenceRequest';

const iconsByType = new Map<string, NotificationIconType>([
  [NotificationTypes.ABSENCE_REQUEST_CREATED, 'umbrella-outline'],
]);

const descriptionsByType = new Map([
  [NotificationTypes.ABSENCE_REQUEST_CREATED, t(`${i18prefix}.created`)],
]);

export const mapAbsenceApprovalRequestNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };
  data.icon = iconsByType.get(src.flair__Type__c);
  data.title = descriptionsByType.get(src.flair__Type__c) || '';

  if (
    src.renderInfo.__typename === 'AbsenceApprovalRequestNotificationRenderInfo'
  ) {
    if (!src.renderInfo.absenceApprovalRequest?.absence?.employee?.Id) {
      data.description = t(`${i18prefix}.absenceRequestWasDeleted`);
      return data;
    }
    data.url = routes.pendingAbsenceRequests.route.create({});
    const requesterName =
      src.renderInfo.absenceApprovalRequest?.absence?.employee?.Name;
    if (requesterName) {
      data.description = requesterName;
    }
    const requestId = src.renderInfo.absenceApprovalRequest?.Id;
    if (requestId) {
      data.url = routes.absenceRequestToMe.route.create({
        absenceRequestId: requestId,
      });
    }
    const absence = src.renderInfo.absenceApprovalRequest?.absence;
    if (absence) {
      type AbsenceWithType = Pick<
        Absence,
        | 'flair__Type__c'
        | 'flair__Start_Date__c'
        | 'flair__Start_Date_Half_Day__c'
        | 'flair__Start_Time__c'
        | 'flair__End_Date__c'
        | 'flair__End_Date_Half_Day__c'
        | 'flair__End_Time__c'
      >;
      let absenceWithType: AbsenceWithType = {
        flair__Type__c: AbsenceType.Daily,
        ...absence,
      };
      data.urlLabel = (
        <AbsenceDateTimeRange absence={absenceWithType} dateFormat="short" />
      );
    }
  }

  return data;
};
