import React from 'react';
import { Card } from 'react-bootstrap';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { useTranslation } from 'react-i18next';

export const EngagementSurveysEmptyStateCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <EmptyStateCardBody
        title={t('engagementSurvey.listingPage.emptyStateMessage')}
      />
    </Card>
  );
};
