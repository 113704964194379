import React from 'react';
import { TFunc } from '../../../../../../../hooks/useNamespacedTranslation';
import InputLabel from '../../../../../../../components/form/InputLabel';
import TextareaAutosize from 'react-textarea-autosize';
import { FieldValues } from 'react-hook-form';

type Props = {
  t: TFunc;
  onFieldChange: (step: FieldValues) => void;
  value: FieldValues | null;
};
export const DetailsFormField: React.FC<Props> = ({
  t,
  onFieldChange,
  value,
}) => {
  return (
    <>
      <InputLabel label={t('form.fields.details')} />
      <TextareaAutosize
        defaultValue={value ? value.details : ''}
        className="form-control"
        name="details"
        onChange={(event) =>
          onFieldChange({
            details: event.target.value,
            preview: { ...value?.preview, details: event.target.value },
          })
        }
        placeholder={t('form.fields.detailsPlaceholder')}
        minRows={4}
      />
    </>
  );
};
