import { TFunction } from 'i18next';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Doughnut from '../../../../components/charts/Doughnut';
import { Theme } from '../../../../theme';
import { hoursToMinutes } from '../../../../utils/time';
import {
  MyTimeSheetGoalChartFragment,
  TimeSheetGoalChartFragment,
} from '../../__generated__/graphql';
import { formattedDuration } from '../DurationFormat';
import { ChartData, ChartOptions } from 'chart.js';
import {
  TFunc,
  useNamespacedTranslation,
} from '../../../../hooks/useNamespacedTranslation';
import {
  getTimesheetOvertimeMinutes,
  isHourlyBasedWeek,
} from '../../utils/timesheetHelper';

type TimeSheet = TimeSheetGoalChartFragment | MyTimeSheetGoalChartFragment;

type Props = {
  timeSheet: TimeSheet;
};

type InsideTextProps = {
  title: string;
  subtitle: string;
};

const InsideText: React.FC<InsideTextProps> = ({ title, subtitle }) => {
  return (
    <>
      <p className="mb-0 h2">{title}</p>
      <p className="text-dark mb-0 ">{subtitle}</p>
    </>
  );
};

const isCurrentWeek = (
  timeSheet: Pick<TimeSheet, 'flair__Start_Date__c'>,
): boolean => {
  return moment(timeSheet.flair__Start_Date__c).isSameOrAfter(
    moment().startOf('isoWeek').format(),
    'day',
  );
};

const chartOptions = (
  durationFormatter: (n: number) => string,
): ChartOptions<'doughnut'> => ({
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          const minutes = context.dataset.data[context.dataIndex];

          return durationFormatter(minutes);
        },
      },
    },
  },
});

type Data = {
  title: string;
  subtitle: string;
  labels: string[];
  data: number[];
  backgroundColor: string[];
};

const theme = Theme.chart.timeTracking;
const chartDataBuilder =
  (t: TFunction, goalT: TFunc) =>
  ({ timeSheet }: Props): Data => {
    const tracked = timeSheet.trackedTime;
    const durationFormatter = formattedDuration(t);

    const workedMinutes = Math.round(tracked.totalWorkedMinutes);

    if (isHourlyBasedWeek(timeSheet)) {
      return {
        title: durationFormatter(workedMinutes),
        subtitle: goalT('innerText.worked'),
        labels: [goalT('worked')],
        data: [workedMinutes],
        backgroundColor: [theme.tracked.color],
      };
    }

    const targetMinutes = hoursToMinutes(timeSheet.flair__Target_Hours__c);
    const overtimeMinutes = Math.max(0, getTimesheetOvertimeMinutes(timeSheet));

    if (overtimeMinutes > 0) {
      return {
        title: durationFormatter(overtimeMinutes),
        subtitle: goalT('innerText.overtime'),
        labels: [goalT('workload'), goalT('overtime')],
        data: [targetMinutes, overtimeMinutes],
        backgroundColor: [theme.tracked.color, theme.overtime.color],
      };
    }

    const deficitMinutes = Math.max(0, targetMinutes - workedMinutes);

    return {
      title: durationFormatter(deficitMinutes),
      subtitle: isCurrentWeek(timeSheet)
        ? goalT('innerText.reach', {
            hours: durationFormatter(targetMinutes),
          })
        : goalT('innerText.deficit'),
      labels: [goalT('worked'), goalT('deficit')],
      data: [workedMinutes, deficitMinutes],
      backgroundColor: [theme.tracked.color, theme.deficit.color],
    };
  };

const i18Path = 'timeTracking.graphs.goal';

const GoalChart: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const goalT = useNamespacedTranslation(i18Path);

  const durationFormatter = formattedDuration(t);
  const buildChartData = chartDataBuilder(t, goalT);
  const chartData = buildChartData(props);

  const doughnutData: ChartData<'doughnut'> = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        backgroundColor: chartData.backgroundColor,
      },
    ],
  };

  return (
    <Doughnut data={doughnutData} options={chartOptions(durationFormatter)}>
      <InsideText title={chartData.title} subtitle={chartData.subtitle} />
    </Doughnut>
  );
};

export default GoalChart;
