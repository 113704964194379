import * as React from 'react';
import { QuestionAndOptions } from '../types';
import { EvaluationTemplateQuestionType } from '../../../../../__generated__/graphql';
import { Form, FormGroup } from 'react-bootstrap';
import ScoreContent from '../ScoreContent';
import { EvaluationAnswers } from './types';

type Props = {
  answers: EvaluationAnswers[];
  options: QuestionAndOptions['options'];
};

export const FeedbackAnswers: React.FC<Props> = ({ answers, options }) => {
  if (!answers || answers?.length === 0) {
    return <></>;
  }

  switch (answers[0].type) {
    case EvaluationTemplateQuestionType.FreeText:
      return <div className="text-muted line-break">{answers[0].answer}</div>;
    case EvaluationTemplateQuestionType.SingleChoice:
      const answer = answers[0].templateOption;
      const optionName = !!answer && options[answer];
      return (
        <Form.Check
          className="text-muted d-flex align-items-center gap-2"
          type="radio"
          label={<small>{optionName}</small>}
          readOnly={true}
          defaultChecked={true}
        />
      );
    case EvaluationTemplateQuestionType.MultipleChoice:
      return (
        <FormGroup>
          {answers.map((a, i) => {
            const optionId = a.templateOption;
            if (!optionId) {
              return <></>;
            }
            return (
              <Form.Check
                key={i}
                className="text-muted"
                type="radio"
                label={<small>{options[optionId]}</small>}
                readOnly={true}
                defaultChecked={true}
              />
            );
          })}
        </FormGroup>
      );
    case EvaluationTemplateQuestionType.Scorecard:
      return (
        <div className="d-flex flex-column gap-2 mt-4">
          {answers.map((a, i) => {
            const optionId = a.templateOption;
            if (!optionId) {
              return <></>;
            }

            return (
              <React.Fragment key={i}>
                <div className="d-flex justify-content-between">
                  <div>{options[optionId]}</div>
                  <div className="text-center">
                    {a.score != null ? (
                      <ScoreContent
                        score={a.score}
                        labelPosition="right"
                        showScore={true}
                        useSplitter={true}
                      />
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
                <hr />
              </React.Fragment>
            );
          })}
        </div>
      );
    case 'ONLY_SCORE':
      return answers[0].score != null ? (
        <ScoreContent
          score={answers[0].score}
          labelPosition="right"
          showScore={true}
          useSplitter={true}
        />
      ) : null;
  }

  return <div></div>;
};
