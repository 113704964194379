import React from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import { DocumentFileTypeIcon } from '../DocumentFileType';

type Props = {
  hasSubRow: boolean;
  onClick: () => void;
  name: string;
  fileType: string | undefined;
  isExpanded: boolean;
};
export const DocumentCell: React.FC<Props> = ({
  hasSubRow,
  onClick,
  fileType,
  isExpanded,
  name,
}) => {
  return (
    <>
      <div className="d-flex flex-row align-items-center" onClick={onClick}>
        <div className="d-flex flex-row me-2 align-items-center">
          <DocumentFileTypeIcon fileType={fileType} />
        </div>
        {hasSubRow && (
          <FlairIcon
            className="me-1"
            icon={
              isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'
            }></FlairIcon>
        )}
        <DocumentNameStyled
          title={name}
          className="d-inline-block cu-pointer h7-medium text-truncate">
          {name}
        </DocumentNameStyled>
      </div>
    </>
  );
};

const DocumentNameStyled = styled.span`
  color: ${Theme.color.blue3};
  max-width: 14.5rem;
`;
