import * as React from 'react';

const StopCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 7.99822C1.5 4.41042 4.41067 1.49975 7.99847 1.49975C11.5863 1.49975 14.4969 4.41042 14.4969 7.99822C14.4969 11.586 11.5863 14.4967 7.99847 14.4967C4.41067 14.4967 1.5 11.586 1.5 7.99822ZM7.99847 2.49951C4.96283 2.49951 2.49976 4.96257 2.49976 7.99822C2.49976 11.0339 4.96283 13.4969 7.99847 13.4969C11.0341 13.4969 13.4972 11.0339 13.4972 7.99822C13.4972 4.96257 11.0341 2.49951 7.99847 2.49951Z"
        fill="currentColor"
      />
      <circle cx="7.99673" cy="7.9985" r="2.49941" fill="currentColor" />
    </svg>
  );
});

export default StopCircleOutlineIcon;
