import React from 'react';
import { Trans } from 'react-i18next';

const WorkflowTableMandatoryHintText: React.FC = () => {
  return (
    <div>
      <Trans
        i18nKey="workflows.filter.mandatoryHint"
        components={[<span className="text-danger">*</span>]}></Trans>
    </div>
  );
};

export default WorkflowTableMandatoryHintText;
