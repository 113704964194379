import React from 'react';
import SkeletonCard from '../../../../../components/Skeleton/Card';

export const LoadingComparedFeedback: React.FC = () => {
  return (
    <>
      <SkeletonCard height={250} />
      <SkeletonCard height={250} />
      <SkeletonCard height={250} />
    </>
  );
};
