import { Option } from '../../../Documents2/Fliters/types';
import { OptionBase } from '../../../../../../components/Select/types';
import { TicketsFilter, TicketsListFilterInput } from './types';
import { parseDate, toISODateOnly } from '../../../../../../utils/dateUtils';
import { setMonth, startOfYear } from 'date-fns';
import { TicketStatus } from '../../../../__generated__/graphql';
import i18next from 'i18next';

export const createYearOptions = (now: Date): Option[] => {
  const currentYear = now.getFullYear();
  return [currentYear - 1, currentYear, currentYear + 1].map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
};

export const createMonthOptions = (months: OptionBase[]): Option[] => {
  return months.map((month) => ({ value: month.value, label: month.label }));
};

const allTicketStatuses = ['OPEN', 'PENDING', 'CLOSED', 'OPEN_IN_PROGRESS'];
export const createTicketStatusOptions = (): Option[] => {
  return allTicketStatuses.map((x) => ({
    value: x,
    label: i18next.t(`support.tickets.table.status.${x}`),
  }));
};
export const mapTicketsListFilterInput = (
  filter: TicketsFilter,
): TicketsListFilterInput => {
  return {
    searchTerm: filter.searchTerm,
    categoryId: filter.category,
    month: filter.month
      ? toISODateOnly(setMonth(new Date(), Number(filter.month) - 1))
      : null,
    year: filter.year
      ? toISODateOnly(startOfYear(parseDate(filter.year)))
      : null,
    assigneeId: filter.assignee,
    status: mapTicketStatus(filter.status),
  };
};
const mapTicketStatus = (status: string | null): TicketStatus[] | null => {
  if (!status) {
    return null;
  }
  if (status === 'OPEN_IN_PROGRESS') {
    return [TicketStatus.Open, TicketStatus.Pending];
  }
  return [status as TicketStatus];
};
