import styled from '@emotion/styled';
import React from 'react';
import { getKeyResultCurrentValueStr } from '../translations';
import { GoalKeyResultType } from '../types';

type Props = {
  type: GoalKeyResultType;
  target: number;
  current: number;
};

export const KeyResultValue: React.FC<Props> = ({ type, current, target }) => {
  switch (type) {
    case 'NUMERIC':
    case 'PERCENTAGE':
      return (
        <span className="text-nowrap">
          {getKeyResultCurrentValueStr(type, current, target)}
        </span>
      );
    case 'BOOLEAN':
      return (
        <div className="d-flex align-items-center">
          <Circle color={current === 0 ? '#E4EBF6' : '#2F80ED'}></Circle>
          <span className="ms-2">
            {getKeyResultCurrentValueStr(type, current, target)}
          </span>
        </div>
      );
  }
};

const Circle = styled.div<{ color: string }>(({ color }) => ({
  width: 4,
  height: 4,
  borderRadius: 2,
  backgroundColor: color,
}));
