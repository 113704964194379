import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/button';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
  leftComponent?: React.ReactNode;
  alignBackButton?: boolean;
  goBackButtonAction?: () => void;
};

export const PageHeaderRow: React.FC<{ children: ReactNode }> = ({
  children,
}) => <Row className="row align-items-center">{children}</Row>;

export type DashboardHistoryLocationState = {
  alertMessage?: 'change-email';
};

const BackButton = styled(Button)({
  marginLeft: '-35px',
  lineHeight: '2.16',
});

const AlertMessage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<DashboardHistoryLocationState>();

  switch (history.location.state?.alertMessage) {
    case 'change-email': {
      return (
        <Alert className={'mt-3 mb-1'} variant="info">
          {t('signIn.info.changeEmail')}
        </Alert>
      );
    }
    default: {
      return <></>;
    }
  }
};

const PageHeader: React.FC<Props> = ({
  title,
  subtitle,
  actions,
  leftComponent,
  alignBackButton,
  goBackButtonAction,
  children,
}) => (
  <>
    <AlertMessage />
    <div className="header mt-md-3 mb-4">
      <div className="header-body border-0">
        <PageHeaderRow>
          {!!goBackButtonAction && (
            <Col className="col-auto g-0">
              <BackButton
                variant="outline-dark"
                className={`me-2 rounded-circle ${
                  alignBackButton ? 'ms-3' : ''
                }`}
                size="sm"
                icon="arrow-back-outline"
                onClick={goBackButtonAction}
              />
            </Col>
          )}
          <Col className="d-flex align-items-center justify-content-between">
            <div>
              {subtitle && <h6 className="header-pretitle">{subtitle}</h6>}
              <h1 className="header-title">{title}</h1>
            </div>
            {leftComponent ? <div>{leftComponent}</div> : <></>}
          </Col>
          {actions ? <Col className="col-auto">{actions}</Col> : <></>}
        </PageHeaderRow>

        {children}
      </div>
    </div>
  </>
);

export default PageHeader;
