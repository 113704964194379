import { ContentDocumentLinks, Attachment } from './types';

export const mapToAttachments = (
  documentLinks: ContentDocumentLinks,
): Attachment[] =>
  documentLinks
    ? documentLinks.map((documentLink) => ({
        id: documentLink.Id,
        downloadLink: documentLink.downloadUrl,
        viewLink: documentLink.viewUrl,
        name: documentLink.ContentDocument.Title,
        extenstion: documentLink.ContentDocument.FileExtension || '',
      }))
    : [];
