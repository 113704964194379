export type DirectoryFilter = {
  locations: string[];
  teams: string[];
  departments: string[];
};

export const TEAMS_FILTER = 'teams';
export const DEPARTMENT_FILTER = 'departments';
export const LOCATIONS_FILTER = 'locations';

export const DirectoryFiltersKeysArray = [
  TEAMS_FILTER,
  DEPARTMENT_FILTER,
  LOCATIONS_FILTER,
] as const;

export type DirectoryFiltersKeys = (typeof DirectoryFiltersKeysArray)[number];
export type DirectoryFiltersType = Record<DirectoryFiltersKeys, string>;
