import React, { ChangeEvent, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactSlider from 'react-slider';
import { EmployeeFeedbackNotes } from '../../EmployeeFeedbackNotes';
import { Props } from '../index';
import './FreeSlider.css';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type FreeSliderInputProps = Omit<
  Props,
  'type' | 'choices' | 'requiredChoices' | 'onChange'
> & { onChange?: (value: string) => void };

const FreeSlider: React.FC<FreeSliderInputProps> = ({
  value,
  onChange,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
}) => {
  const handleChange = useCallback(
    (value) => {
      onChange && onChange(String(value));
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <Row>
      <Col>
        <Row className="align-items-center">
          <Col>
            <ReactSlider
              className={`horizontal-slider ${
                readOnly ? 'disabled-slider' : ''
              }`}
              thumbClassName="horz-thumb cu-pointer rounded-circle text-center"
              trackClassName="horz-track"
              disabled={readOnly}
              onChange={handleChange}
              snapDragDisabled={true}
              value={+value}
              renderThumb={(props) => (
                <div {...props}>
                  <FlairIcon icon="smile-outline" size="lg" />
                </div>
              )}
            />
          </Col>
          <Col xs="auto">
            <Form.Control
              value={+value}
              className="slider-input"
              type="number"
              onChange={handleInputChange}
            />
          </Col>
        </Row>

        <Col xs={12}>
          {showOptionalComment && (
            <EmployeeFeedbackNotes
              value={notes}
              onChange={onChangeNotes}
              readOnly={readOnly}
            />
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default FreeSlider;
