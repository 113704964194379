import {
  ApprovalStatus,
  TimeEntryChangeRequestFieldsFragment,
  TimeSheetTimeEntriesDetailsFragment,
} from '../../../__generated__/graphql';
import { addHours } from 'date-fns';
import { parseDate } from '../../../../../utils/dateUtils';
import { mapTimeEntries, TimeEntryItem } from '../../../pages/TimeTracking';
import { calculateDayCurrentTimeEntries } from '../../../components/TimeSheet/DayCard/logic';
import { DayChanges } from '../Tables/types';

export const getTimeEntriesForDay = (
  src: TimeSheetTimeEntriesDetailsFragment,
  day: string,
  dayStartDateTime: Date,
): TimeEntryItem[] => {
  const srcDay = src.trackedTime.days.find((x) => x.day === day);
  if (!srcDay) {
    return [];
  }

  const dayTimeEntryChangeRequests = getDayChangeRequests(
    src.timeEntryChangeRequests,
    dayStartDateTime,
  );

  const currentEntries = calculateDayCurrentTimeEntries(
    srcDay.timeEntries,
    dayTimeEntryChangeRequests,
  );

  return mapTimeEntries(
    {
      timeSheetId: src.Id,
      timeEntries: srcDay.timeEntries,
      currentEntries,
    },
    'manager',
  );
};

// Returns changeRequests related to dayStartDateTime or to timeEntryIds
export const getDayChangeRequests = (
  timeEntryChangeRequests: ReadonlyArray<TimeEntryChangeRequestFieldsFragment>,
  dayStartDateTime: Date,
  timeEntryIds: ReadonlyArray<string> = [],
): TimeEntryChangeRequestFieldsFragment[] => {
  const dayEndDateTime = addHours(dayStartDateTime, 24);
  const timeEntyIdsSet = new Set(timeEntryIds);

  return timeEntryChangeRequests.filter((changeRequest) => {
    if (
      changeRequest.flair__Status__c === ApprovalStatus.Pending &&
      changeRequest.flair__Time_Entry__c &&
      timeEntyIdsSet.has(changeRequest.flair__Time_Entry__c)
    ) {
      return true;
    }
    const changeRequestStartDate = parseDate(
      changeRequest.flair__Start_Datetime__c,
    );
    return (
      changeRequest.flair__Status__c === ApprovalStatus.Pending &&
      changeRequestStartDate >= dayStartDateTime &&
      changeRequestStartDate < dayEndDateTime
    );
  });
};

export const getAllChangeRequestIds = (dayChanges: DayChanges): Set<string> => {
  const result = new Set<string>(dayChanges.breakMinutes.changeRequestIds);
  if (dayChanges.startTime.changeRequestId) {
    result.add(dayChanges.startTime.changeRequestId);
  }
  if (dayChanges.endTime.changeRequestId) {
    result.add(dayChanges.endTime.changeRequestId);
  }
  return result;
};
