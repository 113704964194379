import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import Skeleton from 'react-loading-skeleton';
import SlackImg from './slack.svg';
import Button from '../../../../../components/button';
import { learningHubUrls } from '../../../../../learningHubUrls';
import { Theme } from '../../../../../theme';

type Props = {
  enabled: boolean;
  isEmployeeAuthorized: boolean;
  onAuthorizeEmployee: () => void;
  onChange: () => void;
};
const i18prefix = 'components.slackStatusAutoUpdateSettings';

export const SlackStatusAutoUpdateSettingsView: React.FC<Props> = ({
  enabled,
  onChange,
  isEmployeeAuthorized,
  onAuthorizeEmployee,
}) => {
  const t = useNamespacedTranslation(i18prefix);

  const handleChange = () => {
    if (!isEmployeeAuthorized) {
      onAuthorizeEmployee();
      return;
    }
    onChange();
  };

  return (
    <SlackStatusAutoUpdateSettingsLayout>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="d-flex align-items-center gap-2">
            <div>{t('autoUpdateSlackBasedOnAbsence')}</div>
            {!isEmployeeAuthorized && (
              <InfoHint
                className="d-flex"
                text={t('authorizeHint')}
                color={Theme.color.red1}
              />
            )}
          </div>
          <Form.Check
            className="ms-2"
            type="switch"
            checked={enabled}
            onChange={handleChange}
          />
        </div>
      </div>
    </SlackStatusAutoUpdateSettingsLayout>
  );
};

export const SlackStatusAutoUpdateSettingsViewLoading: React.FC = () => {
  return (
    <SlackStatusAutoUpdateSettingsLayout>
      <div className="d-flex">
        <Skeleton height={30} width={300} />
      </div>
    </SlackStatusAutoUpdateSettingsLayout>
  );
};

export const SlackStatusAutoUpdateSettingsLayout: React.FC = ({ children }) => {
  const t = useNamespacedTranslation(i18prefix);

  const handleOnClick = () => {
    window.open(learningHubUrls.slack, '_blank');
  };

  return (
    <Card className="h5-regular">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="d-flex align-items-center">
            <img src={SlackImg} alt="slack-logo" width="20" height="20" />
            <span className="h5-medium ms-2">{t('connectToSlackPromo')}</span>
          </div>
          <Button
            size="sm"
            variant="outline-primary"
            label={t('goToLearningHubBtn')}
            onClick={handleOnClick}
          />
        </div>
        <hr />
        {children}
      </Card.Body>
    </Card>
  );
};
