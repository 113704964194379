import React from 'react';
import './Avatar.css';
import { AvatarContainer } from '../../../atoms/avatar/AvatarContainer';
import { AvatarSize } from '../../../atoms/avatar/AvatarContainer/types';
import { Initials } from '../../../atoms/avatar/Initials';
import { Maybe } from '../../../../utils/maybe';
import { Shape } from '../../../atoms/avatar/Initials/types';

export type AvatarProps = {
  initials: string;
  avatarUrl?: Maybe<string>;
  size?: AvatarSize;
  shape?: Shape;
  className?: string;
  containerClassName?: string;
  title?: string;
};

const Avatar: React.FC<AvatarProps> = React.forwardRef<
  HTMLDivElement,
  AvatarProps
>(
  (
    { initials, avatarUrl, size, shape, className, containerClassName, title },
    ref,
  ) => (
    <AvatarContainer
      ref={ref}
      size={size}
      title={title}
      className={containerClassName}>
      {avatarUrl ? (
        <img
          className={['d-flex', className].join(' ')}
          src={avatarUrl}
          alt="avatar"
        />
      ) : (
        <Initials initials={initials} shape={shape} className={className} />
      )}
    </AvatarContainer>
  ),
);

export default Avatar;
