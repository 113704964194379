import styled from '@emotion/styled';
import React from 'react';
import FlairIcon, {
  SizeProp as FlairIconSize,
} from '../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../theme';
import { AvatarContainer } from '../../../../atomic/atoms/avatar/AvatarContainer';
import { AvatarSize } from '../../../../atomic/atoms/avatar/AvatarContainer/types';

// can be extended if required
export type TeamAvatarSize = Extract<AvatarSize, 'xs' | 'sm' | 'md' | 'lg'>;

type Props = {
  size?: TeamAvatarSize;
  title?: string;
  className?: string;
};

export const TeamAvatar = React.forwardRef<HTMLDivElement, Props>(
  ({ className, title, size = 'xs' }, ref) => {
    return (
      <AvatarContainerStyled
        ref={ref}
        size={size}
        title={title}
        className={`d-flex align-items-center justify-content-center ${className}`}>
        <FlairIcon icon="people-outline" size={getIconSize(size)} />
      </AvatarContainerStyled>
    );
  },
);

const getIconSize = (avatarSize: TeamAvatarSize): FlairIconSize => {
  switch (avatarSize) {
    case 'xs':
      return 'sm';
    case 'sm':
      return 'lg';
    case 'md':
      return '2x';
    case 'lg':
      return '2x';
  }
};

const AvatarContainerStyled = styled(AvatarContainer)({
  backgroundColor: Theme.color.gray3,
  color: Theme.color.white,
});
