import React, { useCallback } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '../../../../components/button';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {
  onSubmit: () => void;
  onClose: () => void;
  setComment: (text: string) => void;
  visible: boolean;
  submitInProgress: boolean;
  commentPromptText: string;
  allowComment: boolean;
};

const i18Prefix = 'home.surveySubmitModal';

export const SurveySubmitModal = ({
  onSubmit,
  onClose,
  setComment,
  visible,
  submitInProgress,
  commentPromptText,
  allowComment,
}: Props) => {
  const t = useNamespacedTranslation(i18Prefix);

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(e.target.value);
    },
    [setComment],
  );

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      className="confirm-modal"
      contentClassName="border"
      backdropClassName="confirm-modal-backdrop">
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="py-0">
        <div className="d-flex justify-content-center flex-column align-items-center gap-3">
          <h2 className="card-header-title">{t('title')}</h2>
          <h4 className="card-header-title text-center fw-normal lh-sm">
            {commentPromptText}
          </h4>
          {allowComment && (
            <TextareaAutosize
              className="form-control my-2"
              onChange={handleTextChange}
              minRows={3}
              placeholder={t('placeholder')}
            />
          )}
        </div>
      </Modal.Body>
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center">
        <ModalFooter className="border-0 pt-2 d-flex flex-row flex-nowrap gap-3">
          <Button
            variant="outline-primary"
            onClick={onClose}
            label={t('button.cancel')}
            size="lg"
          />
          <Button
            onClick={onSubmit}
            variant="primary"
            label={t('button.submit')}
            isProcessing={submitInProgress}
            size="lg"
          />
        </ModalFooter>
      </div>
    </Modal>
  );
};
