import React, { useContext } from 'react';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { i18Path } from '../../constants';
import { InventoryModalContext } from '../../InventoryModalContext';
import TextareaAutosize from 'react-textarea-autosize';
import InputLabel from '../../../../../../../../../components/form/InputLabel';

export const NoteFormField = () => {
  const t = useNamespacedTranslation(i18Path);
  const { onChange: onFieldChange, value } = useContext(InventoryModalContext);
  return (
    <>
      <InputLabel label={t('form.fields.note')} />
      <TextareaAutosize
        defaultValue={value ? value.note : ''}
        className="form-control"
        name="note"
        onChange={(event) => onFieldChange({ note: event.target.value })}
        placeholder={t('form.fields.notePlaceHolder')}
        minRows={4}
      />
    </>
  );
};
