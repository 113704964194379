import * as React from 'react';

const MehSolidIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_1465_43004)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6769 1.51824C11.5274 1.87419 12.2991 2.39467 12.9478 3.04977C13.6029 3.69846 14.1234 4.4702 14.4793 5.32065C14.8353 6.1711 15.0197 7.0835 15.022 8.00543C15.0242 8.92736 14.8443 9.84066 14.4926 10.6928C14.1408 11.545 13.6241 12.3193 12.9722 12.9712C12.3203 13.6231 11.546 14.1398 10.6938 14.4916C9.84163 14.8433 8.92834 15.0233 8.00641 15.021C7.08447 15.0187 6.17207 14.8343 5.32163 14.4784C4.47118 14.1224 3.69944 13.6019 3.05075 12.9468C2.39564 12.2981 1.87516 11.5264 1.51921 10.6759C1.16326 9.82549 0.978853 8.91309 0.976584 7.99116C0.974315 7.06923 1.15423 6.15593 1.50599 5.30374C1.85775 4.45155 2.37442 3.67726 3.02633 3.02535C3.67824 2.37345 4.45253 1.85677 5.30472 1.50501C6.15691 1.15325 7.0702 0.973338 7.99214 0.975607C8.91407 0.977876 9.82647 1.16228 10.6769 1.51824ZM6.5026 7.25039C6.5026 7.66545 6.16612 8.00193 5.75105 8.00193C5.33599 8.00193 4.99951 7.66545 4.99951 7.25039C4.99951 6.83532 5.33599 6.49884 5.75105 6.49884C6.16612 6.49884 6.5026 6.83532 6.5026 7.25039ZM10.2504 8.00193C10.6655 8.00193 11.0019 7.66545 11.0019 7.25039C11.0019 6.83532 10.6655 6.49884 10.2504 6.49884C9.83533 6.49884 9.49885 6.83532 9.49885 7.25039C9.49885 7.66545 9.83533 8.00193 10.2504 8.00193ZM5.68661 11.2475C5.41053 11.2475 5.18672 11.0237 5.18672 10.7476C5.18672 10.4715 5.41053 10.2477 5.68661 10.2477H10.3105C10.5866 10.2477 10.8104 10.4715 10.8104 10.7476C10.8104 11.0237 10.5866 11.2475 10.3105 11.2475H5.68661Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1465_43004">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default MehSolidIcon;
