import classNames from 'classnames';
import React, { useContext } from 'react';
import { useUserInfo } from '../../context/UserInfo';
import { Emojis } from '../../pages/People/Announcements/types';
import { Emoji } from '../Announcement';
import ReactionButton from '../ReactionButton';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { Spinner } from 'react-bootstrap';
import CollapsedEmojiButton from './ConnectedReactions/CollapsedEmojiButton';
import { findEmployeeEmoji, getNamesOfEmployeesReacted } from './logic';
import './Reaction.css';
import { InnerModalContext } from '../../../../context/InnerModalContext';
const EmojiPicker = React.lazy(() => import('../EmojiPicker'));

type ReactionsProps = {
  emojis: Emojis;
  onSelect: (name: string) => void;
  onRemove: (e: Emoji) => void;
  loading?: boolean;
  maxNumberOfReactions?: number;
};

const Reactions: React.FC<ReactionsProps> = ({
  emojis,
  onSelect,
  onRemove,
  loading,
  maxNumberOfReactions,
}) => {
  const { id: employeeId } = useUserInfo();

  const { setIsInnerModalOpen } = useContext(InnerModalContext);

  const onSelectEmoji = (name: string) => {
    const isSelected = emojis[name]?.find((e) => e.employee.id === employeeId);
    if (!isSelected) {
      onSelect(name);
    }
    setIsInnerModalOpen(false);
  };

  const maxNumber = maxNumberOfReactions ?? Object.values(emojis).length;

  const allEmojis = Object.values(emojis).sort((a, b) => b.length - a.length);
  const normalEmojis = allEmojis.slice(0, maxNumber);
  const collapsedEmojis = allEmojis.slice(maxNumber);

  const spinner = <Spinner animation="border" size="sm" />;

  return (
    <div
      className={classNames(
        'd-flex',
        'gap-3',
        'align-items-center',
        'flex-wrap',
      )}>
      {loading ? (
        spinner
      ) : (
        <React.Suspense fallback={spinner}>
          <EmojiPicker
            onEmojiSelect={onSelectEmoji}
            onToggle={setIsInnerModalOpen}>
            <div
              role="button"
              className="text-success d-flex align-items-center">
              <FlairIcon icon="smile-outline" size="lg" />
              <FlairIcon icon="add-outline" size="lg" />
            </div>
          </EmojiPicker>
        </React.Suspense>
      )}
      {normalEmojis.map((e) => {
        const emoji = findEmployeeEmoji(e, employeeId);
        const isSelected = !!emoji;
        return (
          <ReactionButton
            key={e[0].name}
            isSelected={isSelected}
            onClick={() => {
              isSelected ? onRemove(emoji) : onSelectEmoji(e[0].name);
            }}
            counter={e.length}
            emoji={e[0].name}
            popover={
              <div className="selected-emojis-popover-list">
                {getNamesOfEmployeesReacted(e)}
              </div>
            }
          />
        );
      })}
      {collapsedEmojis.length > 0 && (
        <CollapsedEmojiButton
          counter={collapsedEmojis.length}
          emojis={collapsedEmojis}
          onRemoveEmoji={onRemove}
          onSelectEmoji={onSelectEmoji}
        />
      )}
    </div>
  );
};

export default Reactions;
