import React, { useMemo } from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../theme';
import { Popover } from '../../../../../components/popover';
import { formatPeriodName } from '../periodFormatter';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useSingleVisiblePopoverContext } from '../../../../../context/SingleVisiblePopoverContext';
import { TimeSheetPeriodInfo } from '../types';
import { TimeSheetChartsPopoverConnected } from '../../TimeSheet/TimeSheetChartsPopover/TimeSheetChartsPopoverConnected';

type Props = {
  timeSheetId: string;
  period: TimeSheetPeriodInfo;
  employeeName: string;
  employeeId: string;
  isExpanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;
  children: React.ReactNode;
};

export const TimeSheetRowCell: React.FC<Props> = ({
  timeSheetId,
  period,
  employeeName,
  employeeId,
  isExpanded,
  onExpandedChanged,
  children,
}) => {
  const t = useNamespacedTranslation('timeSheetsControlling');
  const chartPopoverHeaderText = t('popovers.charts.headerText', {
    period: formatPeriodName(period),
    employeeName,
  });
  const chartPopoverId = `${timeSheetId}-charts`;

  const {
    visiblePopoverId: visibleChartId,
    onPopoverToggle: onChartPopoverToggle,
  } = useSingleVisiblePopoverContext();

  const handleExpandClick = (e: React.MouseEvent<SVGSVGElement>) => {
    onExpandedChanged(!isExpanded);
  };

  // to prevent appearing css animation on Charts
  const timeSheetChartsPopoverContent = useMemo(() => {
    return <TimeSheetChartsPopoverConnected timeSheetId={timeSheetId} />;
  }, [timeSheetId]);

  return (
    <div
      className="d-flex align-items-center gap-2"
      data-testid={`time-sheet-row-cell_${employeeId}`}>
      <FlairIcon
        onClick={handleExpandClick}
        className="me-1"
        data-testid="expand-btn"
        icon={
          isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'
        }></FlairIcon>
      {children}
      <Popover
        headerText={chartPopoverHeaderText}
        show={visibleChartId === chartPopoverId}
        onToggle={(visible) => onChartPopoverToggle(chartPopoverId, visible)}
        popoverContent={timeSheetChartsPopoverContent}>
        <FlairIcon
          icon="bar-chart-outline"
          size="sm"
          color={Theme.color.blue3}
        />
      </Popover>
    </div>
  );
};
