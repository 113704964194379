import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import ServerError from '../../../../../components/ServerError';
import {
  WorkflowContext,
  useWorkflowsQuery,
} from '../../../__generated__/graphql';
import { mapSortBy, mapWorkflowsFilterInput, mapWorkflows } from './mappings';
import { WorkflowsTable } from './WorkflowsTable';
import { WorkflowsFilterForMeConnected } from '../Filters/WorkflowsFilterForMeConnected';
import { emptyWorkflowFilterForMe } from '../Filters/types';
import { routes as mainRoutes, routes } from '../../../routes';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { useHistory } from 'react-router-dom';
import { Toggle } from '../Common/Toggle';
import { CardHeaderWithFilter } from '../Common/CardHeaderWithFilter';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';

const PAGE_SIZE = 50;

export const WorkflowsForMe: React.FC = () => {
  const history = useHistory();

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: emptyWorkflowFilterForMe,
    storageKey: 'workflow_filter_for_me',
    createRouteUrl: (queryParams) =>
      mainRoutes.workflowsMeWorkflows.route
        .withQueryParams(queryParams)
        .create({}),
  });

  const { data, loading, error } = useWorkflowsQuery({
    variables: {
      workflowContext: WorkflowContext.My,
      filter: mapWorkflowsFilterInput(tableState.filter),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });
  const tableItemsTotalCount = data?.workflows.paginationInfo?.totalCount;

  const handleItemClick = useCallback(
    (workflowId: string) => {
      history.push(routes.workflowsMeItems.route.create({ workflowId }));
    },
    [history],
  );

  if (error) {
    return <ServerError />;
  }

  const workflows = data ? mapWorkflows(data) : undefined;

  const renderContent = () => {
    if (loading || !workflows) {
      return <Loading />;
    }
    return (
      <WorkflowsTable
        workflows={workflows}
        employeeColumnVisible={false}
        onItemClick={handleItemClick}
        pageSize={PAGE_SIZE}
        itemsCount={tableItemsTotalCount}
        initialSortBy={tableState.sortBy}
        onSortByChanged={handleSortByChanged}
        initialPageIndex={tableState.pageIndex}
        onPageIndexChanged={handlePageIndexChanged}
      />
    );
  };

  return (
    <Card>
      <CardHeaderWithFilter>
        <WorkflowsFilterForMeConnected
          filter={tableState.filter}
          onChange={handleFilterChange}
        />
        <Toggle mode="forMe" value="workflows" />
      </CardHeaderWithFilter>
      {renderContent()}
    </Card>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={4} rows={5} />;
};
