import * as React from 'react';

const FileImageIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.25 21.0001L10.0594 14.7189C10.0264 14.6666 9.98079 14.6235 9.92669 14.5936C9.87259 14.5638 9.8118 14.5481 9.75 14.5481C9.6882 14.5481 9.62741 14.5638 9.57331 14.5936C9.51921 14.6235 9.47356 14.6666 9.44062 14.7189L7.48125 17.6626C7.4464 17.7143 7.3992 17.7566 7.34391 17.7855C7.28863 17.8143 7.22702 17.829 7.16465 17.828C7.10228 17.8271 7.04113 17.8106 6.98673 17.7801C6.93234 17.7496 6.88642 17.706 6.85312 17.6532L5.94375 16.2376C5.90857 16.1854 5.8611 16.1426 5.80552 16.1131C5.74994 16.0835 5.68795 16.068 5.625 16.068C5.56205 16.068 5.50006 16.0835 5.44448 16.1131C5.3889 16.1426 5.34143 16.1854 5.30625 16.2376L2.25 21.0001H14.25Z"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.25 3V8.25H19.5"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 21H18.75C18.9489 21 19.1397 20.921 19.2803 20.7803C19.421 20.6397 19.5 20.4489 19.5 20.25V8.25L14.25 3H5.25C5.05109 3 4.86032 3.07902 4.71967 3.21967C4.57902 3.36032 4.5 3.55109 4.5 3.75V12.75"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default FileImageIcon;
