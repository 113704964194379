import { WorkflowEmployee } from '../types';

export const employeeSort = (
  employeeA: WorkflowEmployee,
  employeeB: WorkflowEmployee,
) => {
  const a = employeeA.name;
  const b = employeeB.name;
  return a.localeCompare(b);
};

export const compareInt = (a: number, b: number): number => {
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};
