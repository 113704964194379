import * as React from 'react';

const LocationCircleIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="28" height="28" rx="14" fill="#EEEEF7" />
      <path
        d="M14 7.5C11.5156 7.5 9.5 9.41844 9.5 11.7812C9.5 14.5 12.5 18.8084 13.6016 20.2966C13.6473 20.3594 13.7072 20.4105 13.7765 20.4457C13.8457 20.481 13.9223 20.4994 14 20.4994C14.0777 20.4994 14.1543 20.481 14.2235 20.4457C14.2928 20.4105 14.3527 20.3594 14.3984 20.2966C15.5 18.8091 18.5 14.5022 18.5 11.7812C18.5 9.41844 16.4844 7.5 14 7.5Z"
        stroke="#50565E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.5C14.8284 13.5 15.5 12.8284 15.5 12C15.5 11.1716 14.8284 10.5 14 10.5C13.1716 10.5 12.5 11.1716 12.5 12C12.5 12.8284 13.1716 13.5 14 13.5Z"
        stroke="#50565E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default LocationCircleIcon;
