import React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import DirectoryMultipleFilter from './DirectoryMultipleFilter';
import { DEPARTMENT_FILTER, LOCATIONS_FILTER } from './types';
import { useLocation } from 'react-router-dom';
import { useDirectoryFiltersQuery } from '../../../../__generated__/graphql';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import ServerError from '../../../../../../components/ServerError';
import { filterFromQuery } from './logic';
import ClearFilters from '../../../../components/ClearFilters';
import { routes } from '../../../../routes';
import Loading from './Loading';
import classNames from 'classnames';

const i18Path = 'people.directory';

const DirectoryFilters: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDirectoryFiltersQuery();
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filter = filterFromQuery(query);
  const route = routes.peopleDirectory.route.withQueryParams({}).create({});

  if (loading) return <Loading />;

  if (error || data === undefined) return <ServerError />;

  const locationOptions: FilterSelectOption[] = data.locations.map((a) => ({
    label: a.Name,
    value: a.Id,
  }));

  const departmentOptions: FilterSelectOption[] = data.departments.map((a) => ({
    label: a.Name,
    value: a.Id,
  }));

  return (
    <div
      className={classNames(
        'd-none',
        'd-md-flex',
        'align-items-center',
        'gap-3',
      )}>
      <span className="text-nowrap">{t('filterBy')}</span>
      <DirectoryMultipleFilter
        options={locationOptions}
        filter={filter}
        filterKey={LOCATIONS_FILTER}
        placeholder={t('location')}
      />
      <DirectoryMultipleFilter
        options={departmentOptions}
        filter={filter}
        filterKey={DEPARTMENT_FILTER}
        placeholder={t('department')}
      />
      <ClearFilters route={route} />
    </div>
  );
};

export default DirectoryFilters;
