import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import InputLabel from '../../../../../../../../../components/form/InputLabel';
import { SelectDropdownControlled } from '../../../../../../../../../components/form/Selects';
import {
  InventoryItemLocationListQuery,
  useInventoryItemLocationListQuery,
} from '../../../../../../../__generated__/graphql';
import { OptionBase } from '../../../../../../../../../components/Select/types';
import ServerError from '../../../../../../../../../components/ServerError';
import { i18Path } from '../../constants';

export const LocationFormField: React.FC<{
  onValueChange: (val: string) => void;
}> = ({ onValueChange }) => {
  const t = useNamespacedTranslation(i18Path);
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = useInventoryItemLocationListQuery();

  const locationOptions = mapLocationsOptions(locations);

  const handleSetLocationName = (locationId: string | null) => {
    if (locationId) {
      const location = locationOptions.find(
        (item) => item.value === locationId,
      );
      location && onValueChange(location.label);
    }
  };

  if (locationsError) return <ServerError />;
  return (
    <>
      <InputLabel label={t('form.fields.location')} />
      <SelectDropdownControlled
        onValueChange={handleSetLocationName}
        isLoading={locationsLoading}
        name="location"
        options={locationOptions}
        heightMode="large"
        placeholder={t('form.fields.locationPlaceholder')}
      />
    </>
  );
};
const mapLocationsOptions = (
  data: InventoryItemLocationListQuery | undefined,
): OptionBase[] => {
  if (!data || !data.locations?.length) {
    return [];
  }
  return data.locations?.map(({ Id, Name }) => ({
    value: Id,
    label: Name,
  }));
};
