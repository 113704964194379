import * as React from 'react';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { useUserInfo } from '../../../../context/UserInfo';
import { FilterOption } from './types';

type Props = {
  selectedFilter: FilterOption;
  onChange: (selectedFilter: string) => void;
};

const i18Path = 'dashboard.common.widgetFilter';

const WidgetFilter: React.FC<Props> = ({ selectedFilter, onChange }) => {
  const { teams } = useUserInfo();
  const t = useNamespacedTranslation(i18Path);

  if (teams.length === 0) {
    return null;
  }

  const filterOptions = [
    { label: t('myTeam'), value: FilterOption.MY_TEAM.toString() },
    {
      label: t('allCompany'),
      value: FilterOption.ALL_COMPANY.toString(),
    },
  ];

  return (
    <SingleFilter
      options={filterOptions}
      value={selectedFilter.toString()}
      onChange={(value) => {
        if (value) {
          onChange(value);
        }
      }}
      isClearable={false}
      minWidth="8.75rem"
    />
  );
};

export default WidgetFilter;
