import { Moment } from 'moment';
import i18next from 'i18next';
import { BlockInfo } from '../apps/dashboard/components/Shifts/Common/types';

// TODO: Please remove ot together with Moment. Use dateIntevalUtils instead
export type DateInterval = { start: Moment; end: Moment };

export type FormattedDatetime = {
  date: string;
  time: string;
};

export type FormattedDateInterval = {
  start: FormattedDatetime;
  end: FormattedDatetime;
};

export const isOverlap = (a: DateInterval, b: DateInterval): boolean =>
  Math.max(a.start.valueOf(), b.start.valueOf()) <
  Math.min(a.end.valueOf(), b.end.valueOf());

export const toFormattedDateInterval = (
  interval: DateInterval,
): FormattedDateInterval => ({
  start: toFormattedDatetime(interval.start),
  end: toFormattedDatetime(interval.end),
});

export const toFormattedDatetime = (date: Moment): FormattedDatetime => ({
  date: date.format(i18next.t('date.short')),
  time: date.format('HH:mm'),
});

export const isValidInterval = (interval: DateInterval): boolean =>
  interval.end.isSameOrAfter(interval.start);

export const sortIntervals = <T extends DateInterval>(intervals: T[]): T[] =>
  [...intervals].sort(sortCompare);

export const sortBlockInfoIntervals = <T extends BlockInfo>(
  intervals: T[],
): T[] => [...intervals].sort((a, b) => sortCompare(a.blockInfo, b.blockInfo));

export const isSameIntervals = (a: DateInterval, b: DateInterval): boolean =>
  a.start.isSame(b.start) && a.end.isSame(b.end);

// O(n^2) - i think there is a room for improvement
// (but the average count of breaks is 1, so it doesn't matter at all)
export const isSameIntervalArrays = (
  a: DateInterval[],
  b: DateInterval[],
): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  // the most used case
  if (a.length === 1) {
    return isSameIntervals(a[0], b[0]);
  }
  if (!a.every((aI) => b.some((bI) => isSameIntervals(aI, bI)))) {
    return false;
  }
  if (!b.every((bI) => a.some((aI) => isSameIntervals(aI, bI)))) {
    return false;
  }
  return true;
};

export const isMultiday = (interval: DateInterval): boolean =>
  !interval.start.isSame(interval.end, 'day');

const sortCompare = (a: DateInterval, b: DateInterval) => {
  if (a.start.isBefore(b.start)) {
    return -1;
  }
  if (a.start.isAfter(b.start)) {
    return 1;
  }
  // same start, lets compate end
  if (a.end.isBefore(b.end)) {
    return -1;
  }
  if (a.end.isAfter(b.end)) {
    return 1;
  }
  return 0;
};
