import React, { useCallback, useState } from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import ModalSidebar from '../../../components/ModalSidebar';
import { useEmployeeAvailableSkills } from '../common/useEmployeeAvailableSkills';
import { AddEmployeeSkillDialogConnected } from '../dialog/AddEmployeeSkillDialogConnected';
import { EmployeeSkillsSidebarConnected } from './EmployeeSkillsSidebarConnected';
import { EmployeeSkillsSidebarHeaderActions } from './EmployeeSkillsSidebarHeaderActions';

type Props = {
  employeeId: string;
  employeeName: string;
  visible: boolean;
  onClose: () => void;
};

export const EmployeeSkillsSidebar: React.FC<Props> = ({
  visible,
  onClose,
  employeeId,
  employeeName,
}) => {
  const t = useNamespacedTranslation('skills.employeeSkills');

  const { availableSkills } = useEmployeeAvailableSkills(employeeId);

  const [addSkillDialogVisible, setAddSkillDialogVisible] =
    useState<boolean>(false);

  const handleAddSkillClick = useCallback(() => {
    setAddSkillDialogVisible(true);
  }, [setAddSkillDialogVisible]);

  const handleAddSkillDialogClose = useCallback(() => {
    setAddSkillDialogVisible(false);
  }, [setAddSkillDialogVisible]);

  return (
    <>
      <ModalSidebar
        show={visible}
        onClose={onClose}
        header={t('header', { employee: employeeName })}
        headerActions={
          <EmployeeSkillsSidebarHeaderActions
            employeeId={employeeId}
            onAddSkillClick={handleAddSkillClick}
          />
        }
        content={
          <EmployeeSkillsSidebarConnected
            employeeId={employeeId}
            employeeName={employeeName}
          />
        }
      />
      <AddEmployeeSkillDialogConnected
        employeeId={employeeId}
        availableSkills={availableSkills}
        show={addSkillDialogVisible}
        onClose={handleAddSkillDialogClose}
      />
    </>
  );
};
