import { routes } from '../../../routes';
import React from 'react';
import { Route } from '../../../../../utils/router';
import PageHeaderNav from '../../../components/PageHeaderNav';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type TabItem = {
  label: string;
  route: Route<string[]>;
};

const i18Path = 'shifts.subMenuItems';

const ShiftsNav: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  const tabItems: TabItem[] = [
    {
      label: t('myShifts.title'),
      route: routes.personalShifts.route,
    },
    {
      label: t('allShifts.title'),
      route: routes.allShifts.route,
    },
  ];

  return <PageHeaderNav tabItems={tabItems} />;
};

export default ShiftsNav;
