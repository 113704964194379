import i18next from 'i18next';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translations.json';
import translationEN_US from '../locales/en_US/translations.json';
import translationDE from '../locales/de/translations.json';
import translationDE_CH from '../locales/de_CH/translations.json';
import translationIT from '../locales/it/translations.json';
import translationES from '../locales/es/translations.json';
import translationRU from '../locales/ru/translations.json';
import translationFR from '../locales/fr/translations.json';

i18next
  .use(i18nextLanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    resources: {
      en: { translation: translationEN },
      'en-US': { translation: translationEN_US },
      de: { translation: translationDE },
      'de-CH': { translation: translationDE_CH },
      it: { translation: translationIT },
      es: { translation: translationES },
      ru: { translation: translationRU },
      fr: { translation: translationFR },
    },
    supportedLngs: ['en', 'en-US', 'de', 'de-CH', 'it', 'es', 'ru', 'fr'],
  });

export default i18next;
