import React from 'react';
import { Props as ReactSelectProps } from 'react-select/dist/declarations/src';
import { useHistory } from 'react-router-dom';
import { FilterSelectOption } from '../../../../atomic/molecules/FlairDropdown/types';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { MultiValue } from 'react-select';
import { CalendarFilter, CalendarFiltersKeys } from './types';
import { Route } from '../../../../utils/router';
import { FlairMultiSelectDropdown } from '../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

type Props = Omit<ReactSelectProps<FilterSelectOption, true>, 'options'> & {
  options: FilterSelectOption[];
  filter: CalendarFilter;
  filterKey: CalendarFiltersKeys;
  route: Route<['shifts', 'all-shifts']>;
};

const CalendarMultipleFilter: React.FC<Props & ReactSelectProps> = ({
  filter,
  filterKey,
  options,
  route,
  ...rest
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const selectedOptionsString = filter[filterKey];

  const handleChange = (selectedOptions: MultiValue<FilterSelectOption>) => {
    history.push(
      route
        .withQueryParams({
          ...queryParams,
          [filterKey]: selectedOptions.map((o) => o.value).join(','),
        })
        .create({}),
    );
  };

  return (
    <FlairMultiSelectDropdown
      options={options}
      selectedOptions={selectedOptionsString}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default CalendarMultipleFilter;
