import React from 'react';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../utils/dateUtils';

type Props = {
  dueDate: string | null;
};

export const WorkflowItemDueDateCell: React.FC<Props> = ({ dueDate }) => {
  return dueDate ? (
    <FormattedDate day={parseDate(dueDate)} format="short" />
  ) : (
    <span>-</span>
  );
};
