import {
  TimeLimitMaxDailyRestrictionType,
  TimeLimitSettingsFragment,
  WorkingDay,
  WorkloadType,
} from '../../../__generated__/graphql';
import { Nullable } from '../../../../../utils/nullable';

export type MaxDailyLimit = {
  maxDailyLimitEnabled: boolean;
  maxDailyLimitMinutesInMinutes: number;
};

export const timeLimitNotEnabledResponse: MaxDailyLimit = {
  maxDailyLimitEnabled: false,
  maxDailyLimitMinutesInMinutes: 0,
};

type DayOfWeek =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export type GetMaxDailyLimitParams = {
  timeLimitSettings: Nullable<
    Pick<
      TimeLimitSettingsFragment,
      | 'flair__Maximum_Daily_Restriction_Type__c'
      | 'flair__Maximum_Daily_Restriction_Custom_Minutes__c'
      | 'flair__Restrict_Tracking_On_Non_Working_Days__c'
    >
  >;
  workloadWeek: Nullable<{
    // eslint-disable-next-line no-unused-vars
    [_ in DayOfWeek]: { workingMinutes: number };
  }>;
  workload: Nullable<{
    flair__Type__c: WorkloadType;
    flair__Hours_Per_Day__c?: Nullable<number>;
    flair__Working_Days__c: ReadonlyArray<WorkingDay>;
  }>;
};

export const getMaxDailyLimit = (
  params: GetMaxDailyLimitParams | null | undefined,
  inputDate: Date,
): MaxDailyLimit => {
  if (!params) {
    return timeLimitNotEnabledResponse;
  }

  const { timeLimitSettings, workloadWeek, workload } = params;

  if (!timeLimitSettings) {
    return timeLimitNotEnabledResponse;
  }

  if (
    timeLimitSettings.flair__Maximum_Daily_Restriction_Type__c ===
    TimeLimitMaxDailyRestrictionType.NoRestriction
  ) {
    return timeLimitNotEnabledResponse;
  }

  const daysOfWeek: DayOfWeek[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  const dayIndex = inputDate.getDay(); // Returns a number between 0 (sunday) and 6 (saturday)
  const dayKey: DayOfWeek = daysOfWeek[dayIndex];

  let workloadWorkingTime: number | null;
  if (workload?.flair__Type__c === WorkloadType.Monthly) {
    if (!workload?.flair__Hours_Per_Day__c) {
      workloadWorkingTime = null;
    } else {
      workloadWorkingTime = workload.flair__Working_Days__c
        .join(';')
        .includes(dayKey.toUpperCase())
        ? workload.flair__Hours_Per_Day__c * 60
        : 0;
    }
  } else {
    workloadWorkingTime = workloadWeek?.[dayKey]?.workingMinutes ?? null;
  }

  if (
    timeLimitSettings.flair__Maximum_Daily_Restriction_Type__c ===
    TimeLimitMaxDailyRestrictionType.CustomMinutesDuration
  ) {
    return {
      maxDailyLimitEnabled: true,
      maxDailyLimitMinutesInMinutes:
        workloadWorkingTime === 0 &&
        timeLimitSettings.flair__Restrict_Tracking_On_Non_Working_Days__c ===
          true
          ? 0
          : timeLimitSettings.flair__Maximum_Daily_Restriction_Custom_Minutes__c ??
            0,
    };
  } else if (
    timeLimitSettings.flair__Maximum_Daily_Restriction_Type__c ===
    TimeLimitMaxDailyRestrictionType.BasedOnWorkload
  ) {
    if (workloadWorkingTime === null) {
      return timeLimitNotEnabledResponse; // since they have no hours specified in the workload
    }

    return {
      maxDailyLimitEnabled: true,
      maxDailyLimitMinutesInMinutes: workloadWorkingTime,
    };
  }
  return timeLimitNotEnabledResponse;
};
