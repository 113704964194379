import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

const TimeEntryForm: React.FC<Props> = ({ onSubmit, onCancel, children }) => {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          onCancel();
        }
      }}>
      {children}
    </Form>
  );
};

export default TimeEntryForm;
