import React from 'react';
import { InputGroup } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { toFlatpickrLocale } from '../form/flatpickrUtils';
import { useCurrentLocale } from '../../context/I18n';
import { useTranslation } from 'react-i18next';
import { toDateStr } from '../../apps/dashboard/pages/TimeTracking/TimeTrackingEntries/logic';
import { Form } from 'react-bootstrap';
import styled from '@emotion/styled';
import FlairIcon from '../../atomic/atoms/FlairIcon';

type DateInputProps = {
  name: string;
  value?: string;
  error?: string;
  readonly: boolean;
  onChange: (value: string) => void;
};

const StyledReadOnlyInput = styled(Form.Control)({
  maxWidth: '5.5rem',
});

const DateInput: React.FC<DateInputProps> = ({
  name,
  readonly,
  value,
  onChange,
}) => {
  const locale = toFlatpickrLocale(useCurrentLocale());
  const { t } = useTranslation();
  const defaultOptions = {
    locale,
    dateFormat: t('flatpickr.dateFormat'),
    enableTime: false,
  };

  return (
    <InputGroup className="input-group-merge input-group-sm">
      {!readonly ? (
        <Flatpickr
          className="form-control form-control-appended"
          name={name}
          size={9}
          options={defaultOptions}
          onChange={(date) => onChange(toDateStr(date[0]))}
          value={moment(value).toDate()}
        />
      ) : (
        <StyledReadOnlyInput
          className="form-control-appended"
          size="sm"
          type="text"
          readOnly
          value={value}
        />
      )}
      <InputGroup.Text>
        <FlairIcon icon="calendar-clear-outline" />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default DateInput;
