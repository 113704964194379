import {
  Comment as CommentItem,
  CommentFile,
  MentionEmployee,
  CommentPointer,
} from './types';
import {
  CommentItemFragment,
  SearchEmployeesForMentionQuery,
} from '../../__generated__/graphql';
import { isImageFile } from '../../../../utils/file';
import {
  trimWhitespacesAndNewLinesInHTML,
  wrapLinksInAnchorTags,
} from '../../../../utils/string';
import { Maybe } from '../../../../utils/maybe';

export const URL_PARAM_SHOW_COMMENT = 'showComment';

type Attachments = {
  files: CommentFile[];
  images: CommentFile[];
};

export const mapComments = (
  comments: ReadonlyArray<CommentItemFragment>,
): CommentItem[] =>
  comments.map((c) => {
    const attachments = mapAttachments(c.ContentDocumentLinks);

    if (!c.employee) {
      throw new Error("Employee doesn't exist");
    }

    return {
      id: c.Id,
      text: c.flair__Comment__c,
      createdDate: new Date(c.CreatedDate),
      employee: {
        id: c.flair__Employee__c ?? '',
        name: c.employee.Name,
        avatarUrl: c.employee.avatar?.url ?? '',
        position: c.employee.flair__Position__c,
      },
      files: attachments.files,
      images: attachments.images,
      reactions: c.reactions,
      mentionedEmployees: mapMentions(c.mentions),
    };
  });

export const mapMentions = (
  mentions: CommentItemFragment['mentions'],
): MentionEmployee[] => {
  return mentions
    .filter((e) => e.employee !== null)
    .map((m) => mapMentionEmployee(m));
};

const mapMentionEmployee = (
  m: CommentItemFragment['mentions'][0],
): MentionEmployee => ({
  hasAccess: m.hasAccess,
  ...mapEmployee(m.employee),
});

const mapEmployee = (e: CommentItemFragment['mentions'][0]['employee']) => ({
  id: e.Id,
  name: e.Name,
  avatarUrl: e.avatar?.url ?? '',
  position: e.flair__Position__c ?? '',
  department: e.department?.Name ?? '',
  location: e.location?.Name ?? '',
  companyEmail: e.flair__Company_Email__c ?? '',
  phone: e.flair__Company_Phone_Number__c ?? '',
  manager: e.manager ? { id: e.manager.Id, name: e.manager.Name } : null,
});

const mapAttachments = (
  contentDocumentLink: CommentItemFragment['ContentDocumentLinks'],
): Attachments => {
  const images: CommentFile[] = [];
  const files: CommentFile[] = [];
  contentDocumentLink?.forEach((c) => {
    const data = {
      fileName: c.ContentDocument.Title,
      fileType: c.ContentDocument.FileExtension?.toUpperCase() ?? '',
      link: c.ContentDocument.downloadUrl,
    };

    if (
      c.ContentDocument.FileExtension &&
      isImageFile(c.ContentDocument.FileExtension)
    ) {
      images.push(data);
    } else {
      files.push(data);
    }
  });

  return {
    files,
    images,
  };
};

export const parseText = (text: string) => {
  return parseToHtmlText(replaceSpanMentionsToTag(text));
};

export const parseToHtmlText = (text: string) => {
  return wrapLinksInAnchorTags(trimWhitespacesAndNewLinesInHTML(text));
};

export const replaceSpanMentionsToTag = (text: string) => {
  const regex = /<span[^>]*data-employee-id="([^"]+)".*?>(.*?)<\/span>/g;
  return text.replace(regex, (match, id) => `[~mention-employee~id~${id}]`);
};

export const replaceTagMentionsWithSpan = (
  id: string,
  text: Maybe<string>,
  mentions: MentionEmployee[],
) => {
  if (!text) return '';

  const employeeIdToMentionMap = new Map(
    mentions.map((mention) => [mention.id, mention]),
  );

  const regex = /\[~mention-employee~id~([^\]]+)\]/g;
  return text.replace(regex, (match, employeeId) => {
    const employee = employeeIdToMentionMap.get(employeeId);
    if (!employee) {
      return 'Error fetching the employee';
    }

    return createSpanForMentionedEmployees(employee, id).outerHTML;
  });
};

export const createSpanForMentionedEmployees = (
  employee: MentionEmployee,
  commentId: string,
) => {
  const span = document.createElement('span');
  span.textContent = `@${employee.name}`;

  span.classList.add(
    employee.hasAccess ? 'teal-2-color' : 'accent-honey-color',
  );
  span.classList.add('mention');

  // Add the employee.id as a data attribute to the span
  span.dataset.employeeId = employee.id;
  span.dataset.commentId = commentId;
  span.dataset.hasAccess = employee.hasAccess.toString();

  span.setAttribute('contentEditable', 'false');
  span.setAttribute('role', 'button');

  return span;
};

export const getRemovedMentionedEmployeeIds = (
  previousValue: string,
  currentValue: string,
): Set<string> => {
  const removedItemRegex =
    /<span[^>]*data-employee-id="([^"]+)"[^>]*>[^<]+<\/span>/g;
  const removedItems: Set<string> = new Set();
  let match;
  while ((match = removedItemRegex.exec(previousValue))) {
    const [, employeeId] = match;
    if (!currentValue.includes(match[0])) {
      removedItems.add(employeeId);
    }
  }
  return removedItems;
};

//TODO:: enhance later from REST API in SF
//SearchedEmployee should equal to MentionEmployee
export const mapSearchedEmployees = (
  employees: SearchEmployeesForMentionQuery['searchEmployeesForMention'],
): MentionEmployee[] =>
  employees.map((e) => ({
    ...e,
    id: e.id,
    name: e.name,
    avatarUrl: e.avatarUrl,
    position: e.position ?? '',
    hasAccess: e.hasAccess,
    department: '',
    location: '',
    companyEmail: '',
    phone: '',
    manager: null,
  }));

export const getCursorPosition = (
  element: HTMLDivElement,
  range: Maybe<Range>,
): number => {
  let cursorPosition = -1;

  if (range) {
    // Create a new range to encompass the entire editable content
    const contentRange = document.createRange();
    contentRange.selectNodeContents(element);

    // Create a new range to select the content before the cursor
    const preCursorRange = document.createRange();
    preCursorRange.setStart(
      contentRange.startContainer,
      contentRange.startOffset,
    );
    preCursorRange.setEnd(range.startContainer, range.startOffset);

    // Clone the preCursorRange contents and convert to HTML
    const cursorContainer = document.createElement('div');
    cursorContainer.appendChild(preCursorRange.cloneContents());
    const cursorHTML = cursorContainer.innerHTML;

    // Find the index of the cursorHTML within the editable content
    cursorPosition = element.innerHTML.indexOf(cursorHTML);
    if (cursorPosition !== -1) {
      cursorPosition += cursorHTML.length;
    }
  }

  return cursorPosition;
};

export const getWindowRange = () => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    return selection.getRangeAt(0);
  }

  return null;
};

export const getCommentPointerFromQueryParam = (
  paramStr: string | undefined | null,
): CommentPointer | null => {
  if (typeof paramStr !== 'string') {
    return null;
  }
  const splits = paramStr.split(':');
  if (splits.length < 1) {
    return null;
  }
  if (!splits[0].length) {
    return null;
  }
  return {
    recordId: splits[0],
    commentId: splits.length >= 1 ? splits[1] : undefined,
  };
};

export const commentPointerToQueryParam = (
  commentId: CommentPointer,
): string => {
  return `${commentId.recordId}${
    commentId.commentId ? `:${commentId.commentId}` : ''
  }`;
};

export const createShowCommentQueryParams = (commentId: CommentPointer) => {
  return {
    [URL_PARAM_SHOW_COMMENT]: commentPointerToQueryParam(commentId),
  };
};
