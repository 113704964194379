import { PureQueryOptions } from '@apollo/client';
import { useCallback } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import {
  useCreateOpenShiftAssignmentRequestMutation,
  useDeleteOpenShiftAssignmentRequestMutation,
} from '../../__generated__/graphql';

type useOpenShiftsActionsResult = [
  (shiftId: string) => void,
  (requestId: string) => void,
  boolean,
];

export const useOpenShiftsActions = (
  refetchQueries?: Array<PureQueryOptions>,
): useOpenShiftsActionsResult => {
  const [createAssignmentRequest, { loading: isCreating }] =
    useCreateOpenShiftAssignmentRequestMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries,
    });
  const [deleteAssignmentRequest, { loading: isDeleting }] =
    useDeleteOpenShiftAssignmentRequestMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries,
    });

  const isRequesting = isCreating || isDeleting;

  const errorHandler = useMutationErrorHandler();

  const handleRequestShift = useCallback(
    (shiftId: string) => {
      createAssignmentRequest({
        variables: {
          input: {
            openShiftId: shiftId,
          },
        },
      }).catch(errorHandler);
    },
    [createAssignmentRequest, errorHandler],
  );

  const handleDeleteAssignmentRequest = useCallback(
    (assignmentRequestId: string) => {
      deleteAssignmentRequest({
        variables: {
          id: assignmentRequestId,
        },
      }).catch(errorHandler);
    },
    [deleteAssignmentRequest, errorHandler],
  );

  return [handleRequestShift, handleDeleteAssignmentRequest, isRequesting];
};
