import React from 'react';
import { Employee } from '..';
import { useHistory, useParams } from 'react-router-dom';
import TimeSheetContent from '../../../pages/TimeSheet/TimeSheetContent';
import { employeePageRoutes } from '../PageHeader/routes';
import { TimeSheet } from '../../../pages/TimeSheet/types';

type Props = {
  employee: Employee;
};

type Params = {
  timeSheetId: string;
};

const EmployeeTimeSheet: React.FC<Props> = () => {
  const { timeSheetId } = useParams<Params>();
  const history = useHistory();

  const goToAllTimesheetsAction = (timeSheet?: TimeSheet) => {
    if (timeSheet) {
      history.push(
        employeePageRoutes().timeSheetList.route.create({
          employeeId: timeSheet.flair__Employee__c,
        }),
      );
    }
  };
  return (
    <TimeSheetContent
      goToAllTimesheetsAction={goToAllTimesheetsAction}
      timeSheetId={timeSheetId}
    />
  );
};

export default EmployeeTimeSheet;
