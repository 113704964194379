import * as React from 'react';

const PencilOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M11.3769 3.91436L2.71858 12.592L2 13.9979L3.40561 13.2793L12.0836 4.62106L11.3769 3.91436ZM13.1439 2.14727L12.4372 2.85398L13.1439 3.561L13.8506 2.85398C13.9443 2.76024 13.9969 2.63315 13.9969 2.50063C13.9969 2.36811 13.9443 2.24101 13.8506 2.14727V2.14727C13.7569 2.0536 13.6298 2.00098 13.4973 2.00098C13.3648 2.00098 13.2377 2.0536 13.1439 2.14727V2.14727Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default PencilOutlineIcon;
