import React, { useMemo } from 'react';
import {
  EmployeeSelectInfoColleagueFragment,
  useTeamWithPopupQuery,
} from '../../__generated__/graphql';
import {
  TeamPopupContent,
  Props as TeamPopupContentProps,
} from './TeamPopupContent';
import { EmployeeInfo } from './types';

type Props = Omit<TeamPopupContentProps, 'employees'> & {
  id: string;
};

export const TeamPopupContentConnected: React.FC<Props> = ({
  id,
  ...props
}) => {
  const { data, error } = useTeamWithPopupQuery({
    variables: {
      teamId: id,
    },
  });

  const employees = useMemo(() => {
    if (error) {
      return 'error';
    }
    if (!data) {
      return 'loading';
    }
    if (data.teams.length < 1) {
      return 'error';
    }
    return data.teams[0].employees.map(mapEmployee);
  }, [data, error]);

  return <TeamPopupContent {...props} employees={employees} />;
};

const mapEmployee = (
  src: EmployeeSelectInfoColleagueFragment,
): EmployeeInfo => ({
  id: src.Id,
  name: src.Name,
  avatarUrl: src.avatar?.url ?? undefined,
});
