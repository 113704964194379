import React from 'react';
import Button from '../../../../../../components/button';

type ButtonLinkProps = {
  title: string;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
};

const ButtonLink: React.FC<ButtonLinkProps> = ({
  title,
  disabled = false,
  className = '',
  onClick,
}) => {
  return (
    <Button
      disabled={disabled}
      className={`p-0 ${className}`}
      size="sm"
      variant="link"
      onClick={onClick}
      label={title}
    />
  );
};

export default ButtonLink;
