import React, { useCallback } from 'react';
import ToggleGroup from '../../../../../components/button/ToggleGroup';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  ToggleContext,
  WorkflowViewMode,
  useWorkflowsViewMode,
} from '../useWorkflowsViewMode';

type Props = {
  value: WorkflowViewMode;
  mode: ToggleContext;
};

export const Toggle: React.FC<Props> = ({ value, mode }) => {
  const t = useNamespacedTranslation('workflows.toggle');
  const { redirect } = useWorkflowsViewMode();

  const handleToggleChange = useCallback(
    (name) => redirect(mode, name),
    [redirect, mode],
  );

  return (
    <ToggleGroup
      value={value}
      defaultValue={value}
      buttons={[
        { name: 'workflows', title: t('workflows') },
        { name: 'workflowItems', title: t('tasks') },
      ]}
      onChange={handleToggleChange}
      variant="secondary-2"
    />
  );
};
