import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  GoalKeyResultsInput,
  Props as GoalKeyResultsInputProps,
} from './GoalKeyResultsInput';

type Props = Omit<GoalKeyResultsInputProps, 'values' | 'onChange'> & {
  name: string;
};

export const GoalKeyResultsInputControlled: React.FC<Props> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      as={({ value, onChange }) => (
        <GoalKeyResultsInput {...props} values={value} onChange={onChange} />
      )}
      name={name}
      control={control}
    />
  );
};
