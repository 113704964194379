import {
  FlairLocalStorageKey,
  useFlairLocalStorage,
} from '../../../hooks/useFlairLocalStorage';
import {
  TimeSheetColumnId,
  TimeSheetColumnSetting,
  defaultColumns,
  timeSheetColumnIds,
} from './types';

type ReturnValue = [
  TimeSheetColumnSetting[],
  (value: TimeSheetColumnSetting[]) => void,
];

export const useTimeSheetColumnSettings = (
  storageKey: Extract<
    FlairLocalStorageKey,
    'timeSheets_columns' | 'employee_timeSheets_columns'
  >,
  supportedColumns: Set<TimeSheetColumnId>,
): ReturnValue => {
  const [timeSheetColumnsFromStorage, setTimeSheetColumnsFromStorage] =
    useFlairLocalStorage(storageKey, []);

  const verifiedColumnsFromStorage = timeSheetColumnsFromStorage.filter((col) =>
    supportedColumns.has(col.id),
  );
  const columnsInStorage: Set<string> = verifiedColumnsFromStorage.reduce(
    (resultSet, curStoredItem) => resultSet.add(curStoredItem.id),
    new Set<string>(),
  );
  const defaultColumnsNotExistentInStorage = timeSheetColumnIds
    .filter(
      (colId) => !columnsInStorage.has(colId) && supportedColumns.has(colId),
    )
    .map((id) => ({
      id,
      checked: defaultColumns[id],
    }));
  const timeSheetColumnSettings: TimeSheetColumnSetting[] = [
    ...verifiedColumnsFromStorage,
    ...defaultColumnsNotExistentInStorage,
  ];

  return [timeSheetColumnSettings, setTimeSheetColumnsFromStorage];
};
