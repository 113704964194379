import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  UpdateSlackUserResult,
  useUpdateSlackUserMutation,
} from '../../__generated__/graphql';
import {
  SlackConnectEmployeeContent,
  Props as SlackConnectEmployeeContentProps,
} from './SlackConnectEmployeeContent';

type RouteParams = {
  slackUser?: string;
};

export const SlackConnectEmployee: React.FC = () => {
  const { slackUser } = useParams<RouteParams>();

  const [connect] = useUpdateSlackUserMutation();
  const [state, setState] = useState<SlackConnectEmployeeContentProps>({
    result: 'connecting',
  });

  useEffect(() => {
    if (!slackUser) {
      setState({
        result: 'error',
        errorMessage: 'Slack user is not defined',
      });
      return;
    }
    connect({
      variables: {
        slackUser,
      },
    })
      .then((resp) => {
        if (!resp.data) {
          setState({ result: 'error' });
          return;
        }
        const updateResult = resp.data.slack.connectToSlackUser.result;
        if (updateResult === UpdateSlackUserResult.Success) {
          setState({ result: 'success' });
        } else if (
          updateResult ===
          UpdateSlackUserResult.SlackIsNotEnabledForOrganization
        ) {
          setState({ result: 'slack-not-enabled-for-organization' });
        } else {
          setState({ result: 'error', errorMessage: 'unknown result' });
        }
      })
      .catch((err) => {
        setState({
          result: 'error',
          errorMessage: err && err.message ? err.message : '',
        });
      });
  }, [slackUser, connect, setState]);

  return <SlackConnectEmployeeContent {...state} />;
};
