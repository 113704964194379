import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { Provider as GraphQLProvider } from './apollo';
import { routes } from './routes';

const Routes: React.FC = () => (
  <Switch>
    {Object.values(routes).map(({ component, route }, i) => (
      <Route
        key={i}
        exact={route.template() === '/'}
        path={route.template()}
        component={component}
      />
    ))}
  </Switch>
);

const imgUrl = new URL('/signin-cover.jpg', import.meta.url).href;

const App: React.FC = () => (
  <GraphQLProvider>
    <div className="d-flex align-items-center bg-auth border-top border-top-2 border-primary overflow-hidden">
      <Container fluid>
        <Row className="align-items-center justify-content-center">
          <Col md={5} lg={6} xl={4} className="px-lg-6 my-5">
            <Routes />
          </Col>
          <Col md={7} lg={6} xl={8} className="d-none d-lg-block">
            <div
              className="bg-cover vh-100 mt-n1 me-n3"
              style={{
                backgroundImage: `url(${imgUrl})`,
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  </GraphQLProvider>
);

export default App;
