import styled from '@emotion/styled';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ComparedFeedbackResolution } from '../../hooks/useComparedFeedbackSectionsMapper';
import { ResolutionLabel } from '../EmployeeFeedback/ResolutionLabel';

type Props = {
  resolution: ComparedFeedbackResolution;
};

const ResolutionText = styled.span({
  whiteSpace: 'pre-wrap',
});

export const Resolution: React.FC<Props> = ({ resolution }) => {
  if (!resolution.value) {
    return null;
  }

  return (
    <Row>
      <Col>
        <ResolutionLabel />
        <ResolutionText>{resolution.value}</ResolutionText>
      </Col>
    </Row>
  );
};
