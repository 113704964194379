import { PureQueryOptions } from '@apollo/client';
import React, { useCallback, useReducer, useState } from 'react';
import { ConfirmModalSimple } from '../../../../../../components/ConfirmModalSimple';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { AutoBreaksRules } from '../../../../components/AutoBreaks/shared';
import { useUserInfo } from '../../../../context/UserInfo';
import {
  useDeleteTimeEntryChangeRequest,
  useSubmitTimeEntryChangeRequest,
} from '../../../../hooks/timeTracking';
import TimeEntryForm from '../components/TimeEntryForm';
import {
  isDirtyTimeEntryItem,
  isNotesDirty,
  shouldCreateChangeRequest,
  TimeEntryItem,
  TimeEntryReadOnlyReason,
} from '../logic';
import {
  mapTimeEntryNotesChangedInput,
  mapToSubmitTimeEntryChangeRequestInput,
} from '../logic/mapping';
import { validateTimeEntry } from '../logic/validation';
import { getInitialState, timeEntryStateReducer } from './state';
import TimeEntryDumb from './TimeEntryDumb';
import styled from '@emotion/styled';
import { TimeEntryEmployeeButtons } from './TimeEntryEmployeeButtons';
import { TimeEntryCardLayout } from '../components/TimeEntryCardLayout';
import { useUpdateTimeEntryNotesMutation } from '../../../../__generated__/graphql';
import { CostCenterProps } from '..';

type Props = {
  value: TimeEntryItem;
  costCenters: readonly CostCenterProps[];
  autoBreakRules: AutoBreaksRules | null;
  readonlyReasons: TimeEntryReadOnlyReason[];
  refetchQueriesForEmployeeMode?: PureQueryOptions[];
  onCancelCreatingNewTimeEntry: () => void;
};

const TimeEntryEmployeeSmart: React.FC<Props> = ({
  value,
  costCenters,
  autoBreakRules,
  readonlyReasons,
  refetchQueriesForEmployeeMode,
  onCancelCreatingNewTimeEntry,
}) => {
  const [state, dispatch] = useReducer(
    timeEntryStateReducer,
    getInitialState(value),
  );

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [deleteTimeEntryChangeRequestId, setDeleteTimeEntryChangeRequestId] =
    useState<string | null>(null);
  const { id: meId } = useUserInfo();

  // we will remove refetchQueries after we merge all time entry graphql types into one (as we did for absences)
  // https://linear.app/flair/issue/CHR-538/merge-all-timeentry-graphql-types-into-one
  const [submitChangeRequest] = useSubmitTimeEntryChangeRequest({
    awaitRefetchQueries: true,
    /* eslint-disable-next-line no-restricted-syntax */
    refetchQueries: refetchQueriesForEmployeeMode,
  });
  const [deleteTimeEntryChangeRequest] = useDeleteTimeEntryChangeRequest(
    value.timeSheetId,
  );

  const [updateTimeEntryNote] = useUpdateTimeEntryNotesMutation({
    awaitRefetchQueries: true,
    /* eslint-disable-next-line no-restricted-syntax */
    refetchQueries: refetchQueriesForEmployeeMode,
  });

  const errorHandler = useMutationErrorHandler();

  const { current, notesVisible, validationErrors, costCenterVisible } = state;

  const isDirty = isDirtyTimeEntryItem(value, state.current);

  const isDeleteChangeRequestAllowed = current.changeRequestEmployeeId === meId;

  const handleDeleteClick = useCallback(() => {
    setDeleteTimeEntryChangeRequestId(current.changeRequestId);
  }, [setDeleteTimeEntryChangeRequestId, current.changeRequestId]);

  const handleDeleteConfirmationClose = () => {
    setDeleteTimeEntryChangeRequestId(null);
  };

  const handleCancel = () => {
    if (value.isNew) {
      onCancelCreatingNewTimeEntry();
    } else {
      dispatch({ type: 'reset', initialTimeEntry: value });
    }
  };

  const handleSubmit = () => {
    const { current } = state;
    if (isProcessing) {
      return;
    }
    const errors = validateTimeEntry(current);
    if (errors.size !== 0) {
      return;
    }

    if (!current.isNew && isNotesDirty(value, current)) {
      handleNotesChanged();
    }

    if (shouldCreateChangeRequest(value, current)) {
      handleSubmitChangeRequest();
    }
  };

  const handleNotesChanged = () => {
    const { current } = state;
    const input = mapTimeEntryNotesChangedInput(current);
    setIsProcessing(true);
    updateTimeEntryNote({
      variables: {
        input,
      },
    })
      .catch(errorHandler)
      .finally(() => setIsProcessing(false));
  };

  const handleSubmitChangeRequest = () => {
    const { current } = state;
    const input = mapToSubmitTimeEntryChangeRequestInput(current);
    setIsProcessing(true);
    submitChangeRequest({
      variables: {
        input,
      },
    })
      .then(() => {
        onCancelCreatingNewTimeEntry();
      })
      .catch(errorHandler)
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleDeleteConfirmationSubmit = useCallback(() => {
    if (!deleteTimeEntryChangeRequestId) {
      return;
    }
    setDeleteTimeEntryChangeRequestId(null);
    setIsProcessing(true);
    deleteTimeEntryChangeRequest({
      variables: {
        id: deleteTimeEntryChangeRequestId,
      },
    })
      .catch(errorHandler)
      .finally(() => {
        setIsProcessing(false);
      });
  }, [
    deleteTimeEntryChangeRequest,
    errorHandler,
    setIsProcessing,
    deleteTimeEntryChangeRequestId,
  ]);

  const readonly = readonlyReasons.length > 0;

  const timeEntryButtons =
    !readonly && isDirty ? (
      <TimeEntryEmployeeButtons
        onCancel={handleCancel}
        isProcessing={isProcessing}
        submitDisabled={isProcessing || validationErrors.size > 0}
      />
    ) : null;

  return (
    <Container>
      <TimeEntryForm onSubmit={handleSubmit} onCancel={handleCancel}>
        <TimeEntryCardLayout
          isProcessing={isProcessing}
          highlight={isDirty}
          buttons={timeEntryButtons}>
          <TimeEntryDumb
            timeEntry={current}
            costCenters={costCenters}
            validationErrors={validationErrors}
            readonly={readonly}
            readonlyReasons={readonlyReasons}
            notesVisible={notesVisible}
            costCenterVisible={costCenterVisible}
            addNotesButtonVisible={!notesVisible}
            addAssignCostCenterButtonVisible={
              costCenters.length > 0 && !costCenterVisible
            }
            isDeleteBreakAllowed={false}
            isDeleteTimeEntryAllowed={false}
            isEditNotesAllowed={true}
            forceShowEndDate={false}
            autoBreakRules={autoBreakRules}
            isDeleteChangeRequestAllowed={isDeleteChangeRequestAllowed}
            onDeleteChangeRequestClick={handleDeleteClick}
            dispatch={dispatch}
          />
        </TimeEntryCardLayout>
        <ConfirmModalSimple
          visible={!!deleteTimeEntryChangeRequestId}
          onClose={handleDeleteConfirmationClose}
          onSubmit={handleDeleteConfirmationSubmit}
        />
      </TimeEntryForm>
    </Container>
  );
};

const Container = styled('div')({
  marginBottom: '2.2rem',
});

export default TimeEntryEmployeeSmart;
