import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SelectMultipleEmployees,
  Props as SelectMultipleEmployeesProps,
} from '../../../apps/dashboard/components/Selects/SelectMultipleEmployees';

type Props = Omit<SelectMultipleEmployeesProps, 'values' | 'onChange'> & {
  name: string;
};

export const SelectMultipleEmployeesControlled: React.FC<Props> = (props) => {
  const { control } = useFormContext();

  return (
    <Controller
      as={({ value, onChange }) => {
        return (
          <SelectMultipleEmployees
            {...props}
            values={value}
            onChange={onChange}
          />
        );
      }}
      name={props.name}
      control={control}
    />
  );
};
