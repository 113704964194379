import React, { useCallback, useState } from 'react';
import { GoalId } from './types';
import { GoalSidebar } from './GoalSidebar/GoalSidebar';
import { useGoalFilter } from './useGoalFilter';
import { GoalFilter } from './GoalsFilter/GoalsFilter';
import { GoalsListWithFilter } from './GoalsList/GoalsListWithFilter';

const CREATE_GOAL = 'createGoal';

type Props = {
  isManagerView?: boolean;
};

export const GoalsPage: React.FC<Props> = ({ isManagerView = false }) => {
  const [selectedGoalId, setSelectedGoalId] = useState<
    GoalId | null | 'createGoal'
  >(null);

  const [filter, setFilter] = useGoalFilter(
    isManagerView,
    !isManagerView ? 'myGoals' : 'myReportsGoals',
  );

  const handleFilterChange = useCallback(
    (filter: GoalFilter) => {
      setFilter(filter);
    },
    [setFilter],
  );

  const handleCloseSidebar = useCallback(
    () => setSelectedGoalId(null),
    [setSelectedGoalId],
  );

  const handleCreateGoal = useCallback(
    () => setSelectedGoalId(CREATE_GOAL),
    [setSelectedGoalId],
  );

  const handleGoalClick = useCallback(
    (goalId: GoalId) => {
      setSelectedGoalId(goalId);
    },
    [setSelectedGoalId],
  );

  return (
    <>
      <GoalsListWithFilter
        isManagerView={isManagerView}
        onCreateGoal={handleCreateGoal}
        onGoalClick={handleGoalClick}
        filter={filter}
        onFilterChange={handleFilterChange}
      />
      <GoalSidebar
        goalId={selectedGoalId !== CREATE_GOAL ? selectedGoalId : null}
        visible={selectedGoalId !== null}
        onClose={handleCloseSidebar}
      />
    </>
  );
};
