import React, { useCallback } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { SelectDropdown } from '../../../../../components/Select/SelectDropdown';
import { OptionBase } from '../../../../../components/Select/types';
import { useUpdateMyLanguageMutation } from '../../../__generated__/graphql';

const LanguageSection: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [employeeData, { loading: updateInProgress }] =
    useUpdateMyLanguageMutation();

  const handleLanguageSelected = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }

      employeeData({
        variables: {
          language: value,
        },
      });
    },
    [employeeData],
  );

  if (!i18n.options.supportedLngs) {
    return <></>;
  }

  const languageOptions: OptionBase[] = i18n.options.supportedLngs
    .filter((language) => language !== 'cimode')
    .map((language) => ({
      value: language,
      label: t(`personalData.languages.${language}`),
    }));

  return (
    <Col xs={6}>
      <Card>
        <Card.Body>
          <Row className="justify-content-between">
            <Col>
              <Card.Title as="h3">
                {t('personalData.sections.language')}
              </Card.Title>
            </Col>
          </Row>

          <Row className="justify-content-between">
            <Col>
              <FormGroup>
                <div>
                  <small className="me-2">
                    {t('personalData.languageInputLabel')}
                  </small>
                </div>
                <SelectDropdown
                  fullWidthMenu
                  options={languageOptions}
                  value={i18n.language}
                  placeholder={t('personalData.sections.language')}
                  onChange={handleLanguageSelected}
                  isLoading={updateInProgress}
                  isDisabled={updateInProgress}
                />
              </FormGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default LanguageSection;
