import React from 'react';
import { CarryOver } from '../types';
import i18next from 'i18next';
import { formatDate } from '../../../../../../../utils/dateUtils';
import AbsenceHint from './AbsenceHint';

type Props = {
  available: number;
  carriedOverFromPrevYear: CarryOver[];
};

const i18Path =
  'absences.cards.absenceCategories.listItem.currentYear.carriedOverHint';

export const CarriedOverHint: React.FC<Props> = ({
  available,
  carriedOverFromPrevYear,
}) => {
  const prevPeriodTooltip = getPrevPeriodTooltip(
    available,
    carriedOverFromPrevYear,
  );
  if (!prevPeriodTooltip) {
    return null;
  }
  return <AbsenceHint text={prevPeriodTooltip} />;
};

function getPrevPeriodTooltip(
  available: number,
  carriedOvers: CarryOver[],
): string | null {
  const prevCarriedOver = carriedOvers.reduce(
    (acc, cur) => acc + cur.amount,
    0,
  );
  const prevUsed = carriedOvers.reduce((acc, cur) => acc + cur.used, 0);
  const prevExpired = carriedOvers.reduce((acc, cur) => acc + cur.expired, 0);

  const line0 =
    available > 0 ? i18next.t(`${i18Path}.carryoverLineFinal`) : null;
  const line1 = i18next.t(`${i18Path}.carryoverFromPrevPeriod`, {
    count: prevCarriedOver,
  });
  const line2 = i18next.t(`${i18Path}.takenFromPrevPeriod`, {
    count: prevUsed,
  });
  const line3 =
    prevExpired > 0
      ? i18next.t(`${i18Path}.expiredFromPrevPeriod`, {
          count: prevExpired,
        })
      : null;
  const expiredLines = carriedOvers
    .map(toCarriedOverExpireLineStr)
    .filter((x) => x !== null);
  return [
    line0,
    line0 ? '' : null, // break after line0 if required
    line1,
    line2,
    line3,
    ...expiredLines,
  ]
    .filter((x) => x !== null)
    .join('\n');
}

function toCarriedOverExpireLineStr(carriedOver: CarryOver): string | null {
  if (carriedOver.expired > 0 && carriedOver.expireOn) {
    return (
      ' • ' +
      i18next.t(`${i18Path}.carryoverExpiredTooltipLine`, {
        count: carriedOver.expired,
        expirationDate: formatDate(carriedOver.expireOn, 'do MMMM yyyy'),
      })
    );
  }

  const remainingAmount = carriedOver.amount - carriedOver.used;
  if (
    remainingAmount > 0 &&
    carriedOver.expired === 0 &&
    carriedOver.expireOn
  ) {
    return (
      ' • ' +
      i18next.t(`${i18Path}.carryoverWillExpireTooltipLine`, {
        count: remainingAmount,
        expirationDate: formatDate(carriedOver.expireOn, 'do MMMM yyyy'),
      })
    );
  }
  return null;
}
