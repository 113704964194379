import { EmployeeOption } from '../../../../components/Selects/EmployeeOptionLabel';
import { ActiveEmployees_PeerReviewersQuery } from '../../../../__generated__/graphql';
import { mapActiveEmployees } from './mapping';

export const mapAndFilterPeerOptions = (
  activeEmployees: ActiveEmployees_PeerReviewersQuery['activeEmployees'],
  excludeIds: string[],
) => filterPeerOptions(mapActiveEmployees(activeEmployees), excludeIds);

export const filterPeerOptions = (
  options: EmployeeOption[],
  excludeIds: string[],
) => options.filter((e) => !excludeIds.includes(e.value));
