import React from 'react';
import { Section, SectionCategory, SectionType, WidgetEmployee } from './types';
import { Employee } from '../../../People/types';
import RelativeDateLabel from '../../../../../../atomic/molecules/RelativeDateLabel';
import { CheerButton } from '../CheerButton';
import { useServerFeatures } from '../../../../../../hooks/useServerFeature';

type RightSideElementProps = {
  section: Section;
  type: SectionType;
  employee: WidgetEmployee;
  userId: string;
  setCelebrationEmployee: React.Dispatch<React.SetStateAction<Employee | null>>;
};

const RightSideElement: React.FC<RightSideElementProps> = (props) => {
  const { section, type, employee, userId, setCelebrationEmployee } = props;
  const isFeatureVisible = useServerFeatures();
  const isCheersVisible = isFeatureVisible('Cheers');

  const shouldIncludeCheers = [
    SectionType.BIRTHDAYS,
    SectionType.ANNIVERSARIES,
  ].includes(type);

  const isToday = section.key === SectionCategory.TODAY;
  const isNotCurrentUser = employee.id !== userId;
  const shouldRenderCheer =
    isCheersVisible && shouldIncludeCheers && isToday && isNotCurrentUser;

  if (!shouldIncludeCheers) {
    return (
      <RelativeDateLabel
        endDate={employee.date}
        className="relative-date-label"
      />
    );
  }

  return shouldRenderCheer ? (
    <CheerButton onClick={() => setCelebrationEmployee(employee)} />
  ) : null;
};

export default RightSideElement;
