import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import ServerError from '../../../../components/ServerError';
import AbsenceDateTimeRange from '../Absences/components/AbsenceDateTimeRange';
import Loading from './Loading';
import PageHeader from '../../components/PageHeader';
import {
  AbsenceApprovalStatus,
  useAbsenceApprovalRequestToMeQuery,
} from '../../__generated__/graphql';
import { useApproveAbsenceRequest } from '../Absences/useApproveAbsenceRequest';
import Detail from './Detail';
import OverlappingAbsences from './OverlappingAbsences';
import AbsenceStatus from '../Absences/components/AbsenceStatus';
import { ApproverCommentModal } from '../Absences/components/ApproverCommentModal';
import { useRejectAbsenceRequest } from '../Absences/useRejectAbsenceRequest';
import { RelatedObjectNames } from '../../components/Comment/types';
import { useToasts } from '../../../../context/Toast';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { managerRoutes } from '../../manager/routes';
import { mapAbsenceToDateTime } from './logic';
import CommentsPopover from '../../components/Comment/CommentsPopover';

type RouteParams = {
  absenceRequestId: string;
};

type ContentProps = {
  absenceRequestId: string;
};

const Content: React.FC<ContentProps> = ({ absenceRequestId }) => {
  const { t } = useTranslation();
  const tNavigation = useNamespacedTranslation('navigation.menuItems.absences');

  const { data, loading, error } = useAbsenceApprovalRequestToMeQuery({
    variables: { id: absenceRequestId },
  });

  useFlairBreadcrumbHook(
    [
      { label: tNavigation('title') },
      {
        label: tNavigation('submenuItems.absenceRequests'),
        url: managerRoutes.pendingAbsenceRequests.route.create({}),
      },
      {
        label: data?.me.absenceRequestToMe.absence.employee.Name ?? '',
      },
    ],
    loading,
  );

  const { addError, addSuccess } = useToasts();
  const history = useHistory();
  const goBackButtonAction = useCallback(() => history.goBack(), [history]);
  const [rejectingModalId, setRejectingModalId] = useState<string | null>(null);
  const [approveAbsenceRequest, { loading: approvingRequest }] =
    useApproveAbsenceRequest();

  const [rejectAbsenceRequest, { loading: rejectingRequest }] =
    useRejectAbsenceRequest();

  const handleAccept = useCallback(() => {
    approveAbsenceRequest(absenceRequestId)
      .then((r) => {
        addSuccess(t('absences.cards.pendingAbsenceRequests.accept.success'));
        goBackButtonAction();
      })
      .catch(() => {
        addError(t('absences.cards.pendingAbsenceRequests.accept.failure'));
      });
  }, [
    approveAbsenceRequest,
    absenceRequestId,
    addSuccess,
    t,
    goBackButtonAction,
    addError,
  ]);

  const handleReject = useCallback(
    (id: string, reason: string | null) => {
      setRejectingModalId(null);
      rejectAbsenceRequest(id, reason ?? '')
        .then((r) => {
          addSuccess(t('absences.cards.pendingAbsenceRequests.reject.success'));
          goBackButtonAction();
        })
        .catch(() => {
          addError(t('absences.cards.pendingAbsenceRequests.reject.failure'));
        });
    },
    [addError, addSuccess, goBackButtonAction, rejectAbsenceRequest, t],
  );

  const handleRejectButtonClick = useCallback(() => {
    setRejectingModalId(absenceRequestId);
  }, [setRejectingModalId, absenceRequestId]);
  const onModalClose = () => setRejectingModalId(null);

  if (loading) return <Loading />;

  if (error) return <ServerError />;

  const processing = approvingRequest || rejectingRequest;
  const absence = data?.me.absenceRequestToMe.absence;
  const hasActions =
    absence?.flair__Approval_Status__c === AbsenceApprovalStatus.Pending;
  const overlappingAbsences = absence?.overlappingAbsences;
  const dateTimeProps = mapAbsenceToDateTime(absence);

  return (
    <>
      <PageHeader
        title={
          absence && (
            <AbsenceDateTimeRange
              dateFormat="short"
              dateTimeFormat="short"
              absence={dateTimeProps}
            />
          )
        }
        subtitle={absence?.employee?.Name}
        alignBackButton
        goBackButtonAction={goBackButtonAction}
        leftComponent={
          absence && (
            <AbsenceStatus status={absence?.flair__Approval_Status__c} />
          )
        }
        actions={
          <div className="d-flex align-items-center">
            {hasActions && (
              <>
                <Button
                  label={t('absenceRequest.actions.accept')}
                  variant="outline-primary"
                  className="me-2"
                  icon="checkmark-outline"
                  size="sm"
                  isProcessing={approvingRequest}
                  disabled={processing}
                  onClick={handleAccept}
                />
                <Button
                  label={t('absenceRequest.actions.reject')}
                  variant="outline-danger"
                  className="me-2"
                  isProcessing={rejectingRequest}
                  disabled={processing}
                  icon="close-outline"
                  size="sm"
                  onClick={handleRejectButtonClick}
                />
              </>
            )}
            {absence && (
              <CommentsPopover
                recordId={absence.Id}
                relatedObjectName={RelatedObjectNames.Absence}
                mode="button"
                commentsCount={absence.commentsCount}
              />
            )}
          </div>
        }></PageHeader>

      <Row className="justify-content-center">
        <Col lg="7">{absence && <Detail absence={absence} />}</Col>
        {overlappingAbsences && (
          <Col>
            <OverlappingAbsences absences={overlappingAbsences} />
          </Col>
        )}
      </Row>
      <ApproverCommentModal
        requestId={rejectingModalId}
        onClose={onModalClose}
        onRejected={handleReject}
      />
    </>
  );
};

const AbsenceApprovalRequestToMe: React.FC = () => {
  const { absenceRequestId } = useParams<RouteParams>();

  return <Content absenceRequestId={absenceRequestId} />;
};

export default AbsenceApprovalRequestToMe;
