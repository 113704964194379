import React from 'react';
import { useHistory } from 'react-router-dom';
import Line from './graph.svg';
import Bar from './bar.svg';
import './AnalyticsCard.css';
import { AnalyticsType } from './types';

const typeToImageMap: Record<AnalyticsType, string> = {
  [AnalyticsType.LINE]: Line,
  [AnalyticsType.BAR]: Bar,
};

type Props = {
  type: AnalyticsType;
  title: string;
  description: string;
  to: string;
};

const AnalyticsCard: React.FC<Props> = (Props) => {
  const { type, title, description, to } = Props;
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
  };

  return (
    <div className="analytics-card" onClick={handleClick}>
      <div className="analytics-card-header">
        {typeToImageMap[type] && <img src={typeToImageMap[type]} alt={type} />}
      </div>
      <div className="analytics-card-body">
        <div className="analytics-card-title">{title}</div>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
};

export default AnalyticsCard;
