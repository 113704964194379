import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { WorkflowContext } from '../../../__generated__/graphql';

type Props = {
  workflowContext: WorkflowContext;
};

export const WorkflowItemsFilterLoading: React.FC<Props> = ({
  workflowContext,
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  return (
    <div className="d-flex align-items-center">
      <span className="me-3">{t('filterBy')}</span>
      <Skeleton className="me-3" width={80} height={24} />
      <Skeleton className="me-3" width={110} height={24} />
      {workflowContext !== WorkflowContext.My && (
        <Skeleton className="me-3" width={111} height={24} />
      )}
      <Skeleton className="me-3" width={126} height={24} />
      <Skeleton className="me-3" width={81} height={24} />
      <Skeleton className="me-3" width={91} height={24} />
    </div>
  );
};
