import React from 'react';
import { useWorkflowItemFileRequestQuery } from '../../../__generated__/graphql';
import { mapFileOnServer } from './mappings';
import { WorkflowItemFileRequest } from '../WorkflowItems/types';
import { WorkflowItemFileRequestForm } from './WorkflowItemFileRequestForm';
import { WokflowItemFileRequestLoading } from './WorkflowItemFileRequestLoading';

type Props = {
  workflowId: string;
  workflowItem: WorkflowItemFileRequest;
};

export const WorkflowItemFileRequestComponent: React.FC<Props> = ({
  workflowId,
  workflowItem,
}) => {
  const { data } = useWorkflowItemFileRequestQuery({
    variables: {
      workflowId,
      workflowItemId: workflowItem.id,
    },
  });

  if (!data) {
    return <WokflowItemFileRequestLoading workflowItem={workflowItem} />;
  }

  const uploadedFile = data?.workflow.item?.uploadedFile;

  const mappedUploadedFile = uploadedFile
    ? mapFileOnServer(uploadedFile)
    : null;

  return (
    <WorkflowItemFileRequestForm
      workflowItem={workflowItem}
      fileOnServer={mappedUploadedFile}
    />
  );
};
