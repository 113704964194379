import { ApolloCache, DocumentNode, TypedDocumentNode } from '@apollo/client';
import { useCallback } from 'react';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useApolloCache } from '../../../hooks/useApolloCache';
import {
  AddSkillToEmployeeInput,
  RemoveSkillFromEmployeeInput,
  UpdateSkillRateInput,
  UpdateSkillRateResponseFragment,
  UpdateSkillRateResponseFragmentDoc,
  UpdateSkillTopInput,
  UpdateSkillTopResponseFragment,
  UpdateSkillTopResponseFragmentDoc,
  useAddEmployeeSkillMutation,
  useRemoveEmployeeSkillMutation,
  useUpdateSkillRateMutation,
  useUpdateSkillTopMutation,
} from '../../../__generated__/graphql';

export const useAddEmployeeSkill = () => {
  const [addEmployeeSkillMutation, { loading }] = useAddEmployeeSkillMutation();
  const errorHandler = useMutationErrorHandler();

  const { addSuccess } = useToasts();
  const t = useNamespacedTranslation('skills.employeeSkills');

  const addEmployeeSkill = useCallback(
    (input: AddSkillToEmployeeInput) => {
      return addEmployeeSkillMutation({
        variables: {
          input,
        },
      })
        .then((data) => {
          if (data.data?.employeeSkills.addSkill.error) {
            throw new Error(data.data?.employeeSkills.addSkill.error);
          }
          addSuccess(t(`toastAddedSuccess`));
        })
        .catch(errorHandler);
    },
    [addEmployeeSkillMutation, t, errorHandler, addSuccess],
  );

  return { addEmployeeSkill, inProgress: loading };
};

export const useRemoveEmployeeSkill = () => {
  const [removeEmployeeSkillMutation, { loading }] =
    useRemoveEmployeeSkillMutation();
  const errorHandler = useMutationErrorHandler();

  const { addSuccess } = useToasts();
  const t = useNamespacedTranslation('skills.employeeSkills');

  const removeEmployeeSkill = useCallback(
    (input: RemoveSkillFromEmployeeInput) => {
      return removeEmployeeSkillMutation({
        variables: {
          input,
        },
      })
        .then((data) => {
          if (data.data?.employeeSkills.removeSkill.error) {
            throw new Error(data.data?.employeeSkills.removeSkill.error);
          }
          addSuccess(t(`toastRemovedSuccess`));
        })
        .catch(errorHandler);
    },
    [removeEmployeeSkillMutation, t, errorHandler, addSuccess],
  );

  return { removeEmployeeSkill, inProgress: loading };
};

export const useUpdateSkillTop = () => {
  const cache = useApolloCache();
  const [updateSkillTopMutation, { loading }] = useUpdateSkillTopMutation({
    optimisticResponse: ({ input }) => {
      const employeeSkillFragment = readUpdateSkillTopResponseFragment(
        cache,
        input.id,
      );
      const updatedFragemnt = {
        ...employeeSkillFragment,
        flair__Top__c: input.top,
      };
      return {
        employeeSkills: {
          updateSkillTop: {
            error: null,
            record: updatedFragemnt,
          },
        },
      };
    },
  });
  const errorHandler = useMutationErrorHandler();

  const updateSkillTop = useCallback(
    (input: UpdateSkillTopInput) => {
      return updateSkillTopMutation({
        variables: {
          input,
        },
      })
        .then((data) => {
          if (data.data?.employeeSkills.updateSkillTop.error) {
            throw new Error(data.data?.employeeSkills.updateSkillTop.error);
          }
        })
        .catch(errorHandler);
    },
    [updateSkillTopMutation, errorHandler],
  );

  return { updateSkillTop, inProgress: loading };
};

export const useUpdateSkillRate = () => {
  const cache = useApolloCache();

  const [updateSkillRateMutation, { loading }] = useUpdateSkillRateMutation({
    optimisticResponse: ({ input }) => {
      const employeeSkillFragment = readUpdateSkillRateResponseFragment(
        cache,
        input.id,
      );
      const updatedFragemnt = {
        ...employeeSkillFragment,
        flair__Rate__c: input.rate,
      };
      // Drop flair__Top__c if we set rate as n/a
      if (input.rate === null) {
        updatedFragemnt.flair__Top__c = false;
      }
      return {
        employeeSkills: {
          updateSkillRate: {
            error: null,
            record: updatedFragemnt,
          },
        },
      };
    },
  });
  const errorHandler = useMutationErrorHandler();

  const updateSkillRate = useCallback(
    (input: UpdateSkillRateInput) => {
      return updateSkillRateMutation({
        variables: {
          input,
        },
      })
        .then((data) => {
          if (data.data?.employeeSkills.updateSkillRate.error) {
            throw new Error(data.data?.employeeSkills.updateSkillRate.error);
          }
        })
        .catch(errorHandler);
    },
    [updateSkillRateMutation, errorHandler],
  );

  return { updateSkillRate, inProgress: loading };
};

function readEmployeeSkillFragment<TFragment>(
  cache: ApolloCache<object>,
  id: string,
  fragment: DocumentNode | TypedDocumentNode,
  fragmentName: string,
): TFragment {
  const employeeSkillFragment: TFragment | null = cache.readFragment<TFragment>(
    {
      id: cache.identify({
        __typename: 'EmployeeSkillAssociation',
        Id: id,
      }),
      fragment,
      fragmentName,
    },
  );

  if (employeeSkillFragment === null) {
    throw new Error('Can not find fragment in cache');
  }
  return employeeSkillFragment;
}

function readUpdateSkillTopResponseFragment(
  cache: ApolloCache<object>,
  id: string,
): UpdateSkillTopResponseFragment {
  return readEmployeeSkillFragment<UpdateSkillTopResponseFragment>(
    cache,
    id,
    UpdateSkillTopResponseFragmentDoc,
    'UpdateSkillTopResponse',
  );
}

function readUpdateSkillRateResponseFragment(
  cache: ApolloCache<object>,
  id: string,
): UpdateSkillRateResponseFragment {
  return readEmployeeSkillFragment<UpdateSkillRateResponseFragment>(
    cache,
    id,
    UpdateSkillRateResponseFragmentDoc,
    'UpdateSkillRateResponse',
  );
}
