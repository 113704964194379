import React, { useCallback, useState } from 'react';
import Button, { ButtonProps } from '.';
import ConfirmModal from '../Modal/ConfirmModal';
import { isPromise } from '../../utils/promise';

export type Props = ButtonProps & {
  confirmTitle: string;
  confirmText: string;
  onConfirm: () => Promise<void> | void;
};

const ConfirmButton: React.FC<Props> = ({
  confirmTitle,
  confirmText,
  onConfirm,
  ...rest
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleButtonClick = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setLoading(true);

    const confirmCallback = onConfirm();
    if (isPromise(confirmCallback)) {
      confirmCallback.then(() => setShowConfirmDialog(false));
    } else {
      setShowConfirmDialog(false);
    }
  }, [onConfirm]);

  return (
    <>
      <Button onClick={handleButtonClick} {...rest} />
      <ConfirmModal
        show={showConfirmDialog}
        title={confirmTitle}
        text={confirmText}
        loading={loading}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ConfirmButton;
