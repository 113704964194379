import React, { useCallback } from 'react';
import { BreakReminderModal } from './BreakReminderModal';
import { useReminderToShow } from './useReminderToShow';
import { usePauseTimeTracking } from '../../../hooks/timeTracking';
import { useAutoBreaksTimeTrackingFeatures } from '../useAutoBreaksTimeTrackingFeatures';

export const BreakReminders: React.FC = () => {
  const features = useAutoBreaksTimeTrackingFeatures();
  if (features.reminders) {
    return <BreakRemindersInternal />;
  }
  return null;
};

const BreakRemindersInternal: React.FC = () => {
  const { reminderToShowId, closeReminderModal, currentTimeEntryId } =
    useReminderToShow();

  const [pauseTimeEntry] = usePauseTimeTracking(currentTimeEntryId!);

  const handleClose = useCallback(() => {
    if (reminderToShowId) {
      closeReminderModal(reminderToShowId);
    }
  }, [reminderToShowId, closeReminderModal]);

  const handleStartBreak = useCallback(() => {
    if (!currentTimeEntryId) {
      return;
    }
    pauseTimeEntry().finally(() => {
      handleClose();
    });
  }, [currentTimeEntryId, pauseTimeEntry, handleClose]);

  return (
    <BreakReminderModal
      visible={reminderToShowId !== null}
      onStartBreak={handleStartBreak}
      onClose={handleClose}></BreakReminderModal>
  );
};
