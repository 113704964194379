import React from 'react';
import styled from '@emotion/styled';
import { PureQueryOptions } from '@apollo/client';
import OpenShiftBlockSmart from './OpenShiftBlockSmart';
import { OpenShiftUI } from './openShiftsLogic';

type DayColumnProps = {
  shift: OpenShiftUI | null;
  refetchQueries?: Array<PureQueryOptions>;
};

const Td = styled.td`
  height: 1px;
`;

const DayColumn: React.FC<DayColumnProps> = ({ shift, refetchQueries }) => {
  return (
    <Td className="text-center p-1">
      {shift && (
        <OpenShiftBlockSmart shift={shift} refetchQueries={refetchQueries} />
      )}
    </Td>
  );
};

type Props = {
  allDaysShifts: (OpenShiftUI | null)[];
  refetchQueries?: Array<PureQueryOptions>;
};

const OpenShiftRow: React.FC<Props> = ({ allDaysShifts, refetchQueries }) => (
  <tr>
    <th>&nbsp;</th>
    {allDaysShifts.map((shift, i) => (
      <DayColumn key={i} shift={shift} refetchQueries={refetchQueries} />
    ))}
  </tr>
);

export default OpenShiftRow;
