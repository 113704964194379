import moment, { Moment } from 'moment';
import React from 'react';
import {
  Absence as AbsenceGraphqlType,
  EmployeeShift,
  Location,
  ShiftLabel,
} from '../../__generated__/graphql';
import AbsenceBlock from './AbsenceBlock';
import ShiftBlock from './ShiftBlock';
import styled from '@emotion/styled';

export type Shift = Pick<
  EmployeeShift,
  | 'flair__Start_Datetime__c'
  | 'flair__End_Datetime__c'
  | 'flair__Total_Break_Period_in_Minutes__c'
> & { location: Pick<Location, 'Id' | 'Name'> | null } & {
  shiftLabels: ReadonlyArray<
    Pick<ShiftLabel, 'Id' | 'Name' | 'flair__Tag_Name__c'>
  >;
};

export type Absence = Pick<
  AbsenceGraphqlType,
  | 'flair__Start_Date__c'
  | 'flair__Start_Time__c'
  | 'flair__Start_Date_Half_Day__c'
  | 'flair__End_Date__c'
  | 'flair__End_Time__c'
  | 'flair__End_Date_Half_Day__c'
  | 'flair__Category_Name__c'
  | 'flair__Type__c'
>;

type DayColumnProps = {
  currentLocationId: string | null;
  shifts: ReadonlyArray<Shift>;
  absences: ReadonlyArray<Absence>;
  day: Moment;
};

const Td = styled.td`
  height: 1px;
`;

const DayColumn: React.FC<DayColumnProps> = ({
  currentLocationId,
  shifts,
  day,
  absences,
}) => {
  const shiftsOfDay = shifts.filter(
    ({ flair__Start_Datetime__c }) =>
      flair__Start_Datetime__c &&
      moment(flair__Start_Datetime__c).isSame(day, 'day'),
  );

  const absencesOfDay = absences.filter(
    ({ flair__Start_Date__c, flair__End_Date__c }) =>
      day.isSameOrAfter(flair__Start_Date__c, 'day') &&
      day.isSameOrBefore(flair__End_Date__c, 'day'),
  );

  const slotsPerDay = shiftsOfDay.length + absencesOfDay.length;

  return (
    <Td className="text-center p-1">
      <ShiftBlock
        currentLocationId={currentLocationId}
        shifts={shiftsOfDay}
        slotsPerDay={slotsPerDay}
      />
      <AbsenceBlock
        absences={absencesOfDay}
        day={day}
        slotsPerDay={slotsPerDay}
      />
    </Td>
  );
};

type Employee = {
  Name: string;
  shifts: ReadonlyArray<Shift>;
  absences2: ReadonlyArray<Absence>;
};

type Props = {
  currentLocationId: string | null;
  employee: Employee;
  days: Moment[];
};

const EmployeeRow: React.FC<Props> = ({
  currentLocationId,
  employee,
  days,
}) => (
  <tr>
    <th>{employee.Name}</th>
    {days.map((day, i) => (
      <DayColumn
        currentLocationId={currentLocationId}
        key={i}
        day={day}
        shifts={employee.shifts || []}
        absences={employee.absences2 || []}
      />
    ))}
  </tr>
);

export default EmployeeRow;
