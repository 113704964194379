import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { SelectSingle } from '../../../components/Selects/SelectSingle';
import SkeletonCard from '../../../components/Skeleton/Card';
import { SkillsRadarChart } from '../radarChart/SkillsRadarChart';
import { useEmployeeSkills } from '../common/useEmployeeAvailableSkills';
import { useUpdateSkillTop } from '../common/useEmployeeSkillsMutations';
import { mapChartSkill } from './mappings';
import { SkillChartLabel } from '../radarChart/SkillChartLabel';
import { MAX_TOP_SKILLS_NUMBER } from '../common/types';

type Props = {
  employeeId: string;
};

const MAX_SKILLS_SHOWN = MAX_TOP_SKILLS_NUMBER;
const MIN_SKILLS_SHOWN = 2;

// TODO: Refactor: extract EmployeeSkillsRadar and EmployeeSkillsRadarConnected
export const EmployeeSkillsRadar: React.FC<Props> = ({ employeeId }) => {
  const t = useNamespacedTranslation('skills.employeeSkillCard');

  const { data, loading, error } = useEmployeeSkills(employeeId);
  const { updateSkillTop } = useUpdateSkillTop();

  const chartSkills = useMemo(
    () =>
      data?.activeEmployees
        .flatMap((x) => x.employeeSkills)
        .filter((s) => s.flair__Rate__c),
    [data],
  );

  const availableSkills = useMemo(
    () => chartSkills?.filter((s) => !s.flair__Top__c).map(mapChartSkill) ?? [],
    [chartSkills],
  );

  const shownSkills = useMemo(() => {
    return chartSkills?.filter((s) => s.flair__Top__c).map(mapChartSkill) ?? [];
  }, [chartSkills]);

  const description = useMemo(() => {
    if (shownSkills.length === 0) {
      return t('threeSkillsToGo');
    }

    if (shownSkills.length === 1) {
      return t('twoSkillsToGo');
    }

    if (shownSkills.length === 2) {
      return t('oneSkillsToGo');
    }

    if (availableSkills.length + shownSkills.length > MIN_SKILLS_SHOWN) {
      return t('description');
    }
    return t('employeeHasNoRatedSkills');
  }, [availableSkills, shownSkills, t]);

  const handleSkillChange = useCallback(
    (id: string | null, isChosen: boolean) => {
      if (id) {
        updateSkillTop({
          id,
          employeeId,
          top: isChosen,
        });
      }
    },
    [updateSkillTop, employeeId],
  );

  if (error) {
    return <ServerError />;
  }
  if (loading) {
    return <SkeletonCard height={250} />;
  }

  return (
    <>
      <div className="small mb-2 ms-1">{description}</div>

      {shownSkills.length > MIN_SKILLS_SHOWN && (
        <div className="mb-3">
          <SkillsRadarChart skills={shownSkills} />
        </div>
      )}

      {availableSkills.length > 0 && shownSkills.length < MAX_SKILLS_SHOWN && (
        <div className="mb-2">
          <SelectSingle
            placeholder={t('filter.placeholder')}
            options={availableSkills.map((s) => {
              return { label: s.name, value: s.id };
            })}
            heightMode="normal"
            className="small"
            borderless={true}
            value=""
            onChange={(value) => {
              handleSkillChange(value, true);
            }}
          />
        </div>
      )}

      <LabelsContainer>
        {shownSkills.map((s) => (
          <SkillChartLabel
            key={s.id}
            label={s.name}
            onClick={() => handleSkillChange(s.id, false)}
          />
        ))}
      </LabelsContainer>
    </>
  );
};

const LabelsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
});
