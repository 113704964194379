import React, { useCallback, useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import styled from '@emotion/styled';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { DocumentPreview } from '../types';
import { Theme } from '../../../../../theme';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import CommentListWrapper from '../../../components/Comment/CommentListWrapper/CommentListWrapper';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { PersonalDocumentInfoFields } from './PersonalDocumentInfoFields';
import { CorporateDocumentInfoFields } from './CorporateDocumentInfoFields';
import { DocumentFileTypeIcon } from '../DocumentFileType';
import classNames from 'classnames';
import { OptionBase } from '../../../../../components/Select/types';
import { DocumentPreview as DocumentPreviewComponent } from './DocumentPreview';

type Props = {
  visible: boolean;
  document: DocumentPreview | null;
  onClose: () => void;
  onDownload: (url?: string) => void;
  isPersonal: boolean;
  isManager?: boolean;
  onDelete?: any;
  isDeleteModalVisible?: boolean;
  documentCategories?: OptionBase[];
};
const i18Prefix = 'documents2.documentPreview';

const tabKeys = {
  INFORMATION: 'information',
  COMMENTS: 'comments',
  ACTIVITY: 'activity',
};
export const DocumentPreviewModal: React.FC<Props> = ({
  visible,
  onClose,
  document,
  onDownload,
  onDelete,
  documentCategories,
  isPersonal,
  isDeleteModalVisible,
  isManager = false,
}) => {
  const t = useNamespacedTranslation(i18Prefix);

  const [selectedTabKey, setSelectedTabKey] = useState<string | null>(
    tabKeys.INFORMATION,
  );
  const isSelectedTab = (tabKey: string) => tabKey === selectedTabKey;
  const handleClose = () => {
    setSelectedTabKey(tabKeys.INFORMATION);
    onClose();
  };

  const renderInfoContent = useCallback(() => {
    if (isPersonal) {
      return (
        <PersonalDocumentInfoFields
          isManager={isManager}
          document={document}
          documentCategories={documentCategories}
          category={document?.category}
          categoryId={document?.categoryId}
          employee={document?.employee!}
          type={document?.type!}
          status={document?.status!}
          size={document?.size!}
          location={document?.location!}
          note={document?.note!}></PersonalDocumentInfoFields>
      );
    } else {
      return (
        <CorporateDocumentInfoFields
          employee={document?.employee!}
          type={document?.type!}
          size={document?.size!}
          note={document?.note!}></CorporateDocumentInfoFields>
      );
    }
  }, [isManager, document, documentCategories, isPersonal]);

  return (
    <PreviewModal
      show={visible}
      onHide={() => handleClose()}
      dialogClassName="break-modal-full"
      centered
      className={classNames('confirm-modal', {
        'opacity-50': isDeleteModalVisible,
      })}
      contentClassName="border"
      backdropClassName="confirm-modal-backdrop">
      <div className="h-100">
        <Modal.Header className="card-header d-flex align-items-center">
          <div className="d-flex align-items-center gap-3">
            <DocumentFileTypeIcon
              color={Theme.color.black}
              fileType={document?.type!}></DocumentFileTypeIcon>
            <h2 className="card-header-title">{document?.title}</h2>
          </div>
          <div>
            <FlairIcon icon="close-outline" onClick={() => handleClose()} />
          </div>
        </Modal.Header>
        <ModalBody className="p-0">
          <div className="d-flex h-100 flex-row gap-3 overflow-auto">
            <PreviewWrapper>
              {document?.viewLink && (
                <DocumentPreviewComponent
                  type={document.type}
                  url={document.viewLink}
                />
              )}
            </PreviewWrapper>
            <RightSectionWrapper>
              <Tabs
                activeKey={selectedTabKey ?? tabKeys.INFORMATION}
                onSelect={setSelectedTabKey}>
                <Tab
                  eventKey={tabKeys.INFORMATION}
                  className="mt-4 me-4"
                  title={t('tabs.information')}>
                  {renderInfoContent()}
                </Tab>
                {isPersonal && (
                  <Tab
                    className="comments-tab documents-preview-comments-tab position-relative"
                    eventKey={tabKeys.COMMENTS}
                    title={t('tabs.comments')}>
                    {isSelectedTab(tabKeys.COMMENTS) && (
                      <CommentListWrapper
                        recordId={document?.recordId!}
                        relatedObjectName={RelatedObjectNames.Document}
                      />
                    )}
                  </Tab>
                )}
              </Tabs>
            </RightSectionWrapper>
          </div>
        </ModalBody>
        <ModalFooter>
          <FooterButtonsWrapper
            isPersonal={isPersonal}
            className="d-flex w-100">
            {isPersonal && (
              <Button
                onClick={() => onDelete()}
                variant="outline-primary"
                className="d-flex align-items-center">
                <span className="small">{t('buttons.delete')}</span>
              </Button>
            )}

            <Button
              variant="primary"
              onClick={() => {
                onDownload(document?.downloadLink);
              }}
              className="d-flex align-items-center">
              <span className="small">{t('buttons.download')}</span>
            </Button>
          </FooterButtonsWrapper>
        </ModalFooter>
      </div>
    </PreviewModal>
  );
};

const PreviewModal = styled(Modal)`
  position: absolute;

  .modal-content {
    height: 90vh;
  }
`;

const PreviewWrapper = styled.div`
  width: 70%;
`;

const ModalBody = styled(Modal.Body)`
  height: 87%;
  @media (max-height: 68.75rem) {
    height: calc(87% - 1rem);
  }
  @media (max-height: 56.5rem) {
    height: calc(87% - 2rem);
  }
  @media (max-height: 46.5rem) {
    height: calc(87% - 3rem);
  }
`;

const ModalFooter = styled(Modal.Footer)`
  padding: 1rem 1.25rem;
  @media (max-height: 46.5rem) {
    padding: 0.5rem 1.25rem;
  }
  justify-content: ${(props) =>
    props.primary ? 'justify-content-between' : 'flex-end'};
`;

const FooterButtonsWrapper = styled.div<{ isPersonal: boolean }>`
  justify-content: ${(props) =>
    props.isPersonal ? 'space-between' : 'flex-end'};
`;

const RightSectionWrapper = styled.div`
  width: 29%;

  .tab-content {
    height: calc(100% - 3.5rem);
  }
`;
