import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';

import { Option } from './types';
import _ from 'lodash';

import ClearFiltersLink from '../../../components/ClearFiltersLink';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import {
  Certificate,
  CertificateFilter,
  emptyCertificateFilter,
} from '../types';
import { createCertificateStatusOptions } from './mappings';

type Props = {
  filter: CertificateFilter;
  issuedByOptions: Option[];
  issueDateYearOptions: Option[];
  expirationDateYearOptions: Option[];
  onChange: (filter: CertificateFilter) => void;
  onCreateBtnClick?: () => void;
};

export const CertificatesFilter: React.FC<Props> = ({
  filter,
  issuedByOptions,
  issueDateYearOptions,
  expirationDateYearOptions,
  onChange,
  onCreateBtnClick,
}) => {
  const { t } = useTranslation();

  const SingleFilterStyled = styled(SingleFilter)({
    maxWidth: '12.5rem',
  });

  const handleClearFilter = useCallback(() => {
    onChange(emptyCertificateFilter);
  }, [onChange]);

  const handleStatusChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );

  const handleIssuedByChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'issuedBy'),
    [filter, onChange],
  );

  const handleIssueYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'issueDate'),
    [filter, onChange],
  );

  const handleExpirationYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'expirationDate'),
    [filter, onChange],
  );

  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <div className="d-flex gap-2 flex-wrap align-items-center">
        <span className="d-none d-md-block">
          {t('certificates.table.filter.filterBy')}
        </span>

        <SingleFilterStyled
          placeholder={t('certificates.table.filter.status')}
          isSearchable={true}
          options={createCertificateStatusOptions()}
          value={filter.status}
          onChange={handleStatusChanged}
        />

        <SingleFilterStyled
          placeholder={t('certificates.table.filter.issuedBy')}
          isSearchable={true}
          options={issuedByOptions}
          value={filter.issuedBy}
          onChange={handleIssuedByChanged}
        />

        <SingleFilterStyled
          placeholder={t('certificates.table.filter.issuedYear')}
          isSearchable={true}
          options={issueDateYearOptions}
          value={filter.issueDate}
          onChange={handleIssueYearChanged}
        />
        <SingleFilterStyled
          placeholder={t('certificates.table.filter.expirationYear')}
          isSearchable={true}
          options={expirationDateYearOptions}
          value={filter.expirationDate}
          onChange={handleExpirationYearChanged}
        />
        <ClearFiltersLink onClick={handleClearFilter} />
      </div>

      {onCreateBtnClick && (
        <div className="d-flex flex-wrap gap-3 justify-content-end">
          <Button
            size="sm"
            variant="primary"
            onClick={onCreateBtnClick}
            label={t('certificates.newCertificate')}
          />
        </div>
      )}
    </div>
  );
};

export const collectIssuedByFilterOptions = (
  certificates: Certificate[],
): Option[] => {
  return _.sortBy(
    _.uniqBy(
      certificates.map((certificate) => {
        return {
          label: certificate.issuedBy,
          value: certificate.issuedBy,
        };
      }),
      'value',
    ),
    'label',
  );
};

export const collectIssueDateYearFilterOptions = (
  certificates: Certificate[],
): Option[] => {
  return _.sortBy(
    _.uniqBy(
      certificates.map((certificate) => {
        const year = certificate.issueDate
          ? certificate.issueDate.getFullYear().toString()
          : '';
        return { label: year, value: year };
      }),
      'value',
    ),
    'label',
  );
};

export const collectExpirationDateYearFilterOptions = (
  certificates: Certificate[],
): Option[] => {
  return _.sortBy(
    _.uniqBy(
      certificates.map((certificate) => {
        const year = certificate.expirationDate
          ? certificate.expirationDate.getFullYear().toString()
          : '';
        return { label: year, value: year };
      }),
      'value',
    ),
    'label',
  );
};

const handleFilterChange = (
  value: string | null,
  filter: CertificateFilter,
  onChange: (filter: CertificateFilter) => void,
  filterKey: keyof CertificateFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
