import importScript from './importScript';

importScript();

export type IdentifyUserInput = {
  id: string;
  organization: string;
  locale: string;
  manager: boolean;
};

export const identify = (input: IdentifyUserInput) =>
  window.analytics.identify(input.id, {
    organization: input.organization,
    manager: input.manager,
  });

export const pageView = () => window.analytics.page();
