import React from 'react';
import { Card } from 'react-bootstrap';
import { endOfDay, formatDistance } from 'date-fns';

import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { formatDateShort } from '../../../../../utils/dateUtils';
import FlairIcon, {
  FlairIconName,
} from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  isAnonymous: Boolean;
  closingDate: Date | null;
  today: Date;
};

export const AboutSurveyCard: React.FC<Props> = ({
  isAnonymous,
  closingDate,
  today,
}) => {
  const t = useNamespacedTranslation('engagementSurvey.aboutSurveyCard');

  const anonymousIcon: FlairIconName = isAnonymous
    ? 'eye-off-outline'
    : 'eye-outline';
  const anonymousText = isAnonymous
    ? t('anonymousText')
    : t('notAnonymousText');
  return (
    <Card>
      <Card.Header className="h-auto fw-bold">{t('title')}</Card.Header>
      <Card.Body>
        {!!closingDate && (
          <div className="d-flex align-items-center">
            <FlairIcon icon="calendar-check-outline" />
            <span className="ps-2 fs-sm">
              {t('closingOnText', {
                closingDate: formatDateShort(closingDate),
                numOfDays: formatDistance(endOfDay(closingDate), today),
                interpolation: { escapeValue: false },
              })}
            </span>
          </div>
        )}
        <div className="mt-2 d-flex align-items-center">
          <FlairIcon icon={anonymousIcon} />
          <span className="ps-2 fs-sm">{anonymousText}</span>
        </div>
      </Card.Body>
    </Card>
  );
};
