import { useSetSlackStatusAutoUpdateMutation } from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';

export const useSetSlackStatusAutoUpdate = () => {
  const { id } = useUserInfo();

  return useSetSlackStatusAutoUpdateMutation({
    optimisticResponse: ({ autoUpdate }) => {
      return {
        slack: {
          setSlackStatusAutoUpdate: {
            query: {
              slack: {
                __typename: 'SlackInfo',
                employeeSlackConnection: {
                  __typename: 'EmployeeSlackConnection',
                  employeeId: id,
                  slackStatusAutoUpdate: autoUpdate,
                },
              },
            },
          },
        },
      };
    },
  });
};
