import styled from '@emotion/styled';
import React from 'react';
import { TableRow, isTableDayAnySubRow } from '../types';
import { getCellMarginClass } from '../helpers';

type Props = {
  id: string;
  row: TableRow;
  onChange: (newChecked: boolean) => void;
};

export const CheckboxCell: React.FC<Props> = ({ id, row, onChange }) => {
  if (isTableDayAnySubRow(row)) {
    return null;
  }
  return (
    <CheckboxDiv>
      <input
        id={id}
        type="checkbox"
        checked={row.selected}
        disabled={row.selectDisabled}
        onChange={(e) => onChange(e.currentTarget.checked)}
        className={`form-check-input ${getCellMarginClass(row.rowType)}`}
      />
    </CheckboxDiv>
  );
};

const CheckboxDiv = styled.div({
  maxWidth: '1rem',
});
