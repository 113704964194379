import React from 'react';
import { WorkflowContext } from '../../../__generated__/graphql';
import { routes as mainRoutes } from '../../../routes';
import { Workflows } from './Workflows';

export const WorkflowsForOthers: React.FC = () => {
  return (
    <Workflows
      workflowContext={WorkflowContext.Other}
      route={mainRoutes.workflowsOthersWorkflows.route}
      itemRoute={mainRoutes.workflowsOthersItems.route}
    />
  );
};
