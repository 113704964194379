export type Reaction = {
  id: string;
  text: string;
  relatedObjectName: string;
  relatedId: string;
  employee: ReactionEmployee;
};

export type ReactionEmployee = {
  id: string;
  name: string;
};

/* eslint-disable no-unused-vars */
export enum ReactionsRelatedObjectNames {
  Celebration = 'flair__Celebration__c',
  Comment = 'flair__Comment__c',
}

export type ReactionsRelatedObjectName =
  | ReactionsRelatedObjectNames.Celebration
  | ReactionsRelatedObjectNames.Comment;

/* eslint-disable no-unused-vars */
export enum RelatedObjectTypeNames {
  Celebration = 'Celebration',
  Comment = 'Comment',
}

export type RelatedObjectTypeName =
  | RelatedObjectTypeNames.Celebration
  | RelatedObjectTypeNames.Comment;
