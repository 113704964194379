import { useMakeCelebrationPublicMutation } from '../../__generated__/graphql';

export const useMakeCelebrationPublic = () => {
  return useMakeCelebrationPublicMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const celebrationId =
        response.data.celebrations.makeCelebrationPublic.recordId;
      if (!celebrationId) {
        return;
      }
      cache.evict({
        fieldName: 'celebrationsPagination',
      });
      cache.gc();
    },
    optimisticResponse: ({ input }) => {
      return {
        celebrations: {
          makeCelebrationPublic: {
            __typename: 'CelebrationPayload',
            error: null,
            recordId: input.id,
          },
        },
      };
    },
  });
};
