import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCurrentLocale } from '../../../../../context/I18n';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import {
  EmployeeField,
  FieldInput,
  useDefaultValues,
  useFormDataToUpdateFields,
  useValidationSchema,
} from '../../../components/EmployeeData';
import ModalSidebar, {
  ModalSidebarFormContent,
} from '../../../components/ModalSidebar';
import { ExtractProps } from '../../../../../utils/react';
import {
  EmployeeDataFieldState,
  useUpdateWorkflowItemDataRequestMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';

import { WorkflowItemCommonFields } from './WorkflowItemCommonFields';
import useYupValidationResolver from '../../../../../components/form/useYupValidationResolver';
import {
  WorkflowItemDataRequest,
  WorkflowItemStatus,
} from '../WorkflowItems/types';

import { WorkflowItemStatusAutoSave } from './WorkflowItemStatusAutoSave';
import { WorkflowItemSubmitButton } from './WorkflowItemSubmitButton';

type ModalSidebarProps = Pick<ExtractProps<typeof ModalSidebar>, 'onClose'>;

type Props = ModalSidebarProps & {
  workflowItem: WorkflowItemDataRequest;
  dataFields: EmployeeField[];
  onClose: () => void;
};

type FormData = {
  status: WorkflowItemStatus;
  [key: string]: any;
};

export const WorkflowItemDataRequestForm: React.FC<Props> = ({
  workflowItem,
  dataFields,
  onClose,
}) => {
  const { status } = workflowItem;
  const dataFieldsWithState = useMemo(
    () => dataFields.map((x) => ({ ...x, state: EmployeeDataFieldState.Edit })),
    [dataFields],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const locale = useCurrentLocale();

  const [updateItem] = useUpdateWorkflowItemDataRequestMutation();

  const errorHandler = useMutationErrorHandler();

  const formDataToInputFields = useFormDataToUpdateFields();
  const dataFieldsDefaultValues = useDefaultValues(dataFields);
  const validationSchema = useValidationSchema(dataFieldsWithState);

  const defaultValues: FormData = {
    status,
    ...dataFieldsDefaultValues,
  };

  const form = useForm<FormData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  const dirtyFields = form.formState.dirtyFields;

  const handleSubmit = (formData: FormData, dirtyFields: Set<string>) => {
    setIsLoading(true);
    const updateFields = formDataToInputFields(
      dataFields,
      formData,
      dirtyFields,
    );

    let status = formData.status ? formData.status : workflowItem.status;

    //Automatically update status if any field is updated
    if (
      !workflowItem.readonly &&
      //Updating status is not from the status dropdown
      !formData.status &&
      status === WorkflowItemStatusGenerated.NotStarted
    ) {
      status = WorkflowItemStatusGenerated.InProgress;
    }

    return updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
          dataFields: updateFields,
        },
        locale,
      },
    })
      .then(() => {
        //If not updating the status from the dropdown, then close the sidebar
        //This was used previously as a workaround to overcome an issue with apollo cache
        if (!formData.status) {
          onClose();
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onStatusChange = (status: WorkflowItemStatus) => {
    handleSubmit({ status }, dirtyFields);
  };

  const handleStatusLoading = (isStatusLoading: boolean) => {
    setIsLoading(isStatusLoading);
  };

  return (
    <ModalSidebarFormContent
      form={form}
      onSubmit={(formData) => handleSubmit(formData, dirtyFields)}
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={handleStatusLoading}
          />
          {dataFields.map((dataField, index) => (
            <FieldInput
              key={index}
              fieldInfo={{
                ...dataField.fieldInfo,
                disabled: workflowItem.readonly || isLoading,
              }}
            />
          ))}
          {!workflowItem.readonly ? (
            <WorkflowItemSubmitButton disabled={isLoading} />
          ) : undefined}
          <WorkflowItemStatusAutoSave
            value={status}
            onChange={onStatusChange}
            readonly={workflowItem.readonly}
            saving={isLoading}
            saved={!isLoading}
            disabled={isLoading}
          />
        </>
      }
    />
  );
};
