import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import { formattedDuration } from '../../DurationFormat';
import { DifferenceWithSign } from '../../DifferenceWithSign';

type Props = {
  differenceMinutes: number;
  changeTo: number | null;
  differenceTrackedMinutes: number;
  changeToTrackedMinutes: number | null;
};

export const DifferenceCell: React.FC<Props> = (props) => {
  const { differenceMinutes, changeTo } = props;
  return (
    <div className={classNames('d-flex gap-1 align-items-center')}>
      <DifferenceWithSign value={differenceMinutes} withColor />
      {changeTo !== null && (
        <span className="text-warning">
          (<DifferenceWithSign value={changeTo} />)
        </span>
      )}
      <DifferenceTimeWarnings {...props} />
    </div>
  );
};

const i18nPrefix = 'timeSheetsControlling.differenceCell.warnings';

const DifferenceTimeWarnings: React.FC<Props> = ({
  differenceTrackedMinutes,
  differenceMinutes,
  changeTo,
  changeToTrackedMinutes,
}: Props) => {
  const { t } = useTranslation();
  const format = formattedDuration(t);
  const warnings: string[] = [];
  if (
    differenceTrackedMinutes &&
    differenceTrackedMinutes !== differenceMinutes
  ) {
    warnings.push(
      t(`${i18nPrefix}.trackedMinutesDiffers`, {
        trackedMinutes: format(differenceTrackedMinutes),
      }),
    );
  }
  if (
    changeTo !== null &&
    changeToTrackedMinutes &&
    changeTo !== changeToTrackedMinutes
  ) {
    warnings.push(
      t(`${i18nPrefix}.trackedMinutesDiffersAfterChange`, {
        trackedMinutes: format(changeToTrackedMinutes),
      }),
    );
  }
  return warnings.length > 0 ? (
    <InfoHint className="d-flex" text={warnings.join('\n')} />
  ) : null;
};
