import { FlairIconName } from '../../../../../atomic/atoms/FlairIcon';
import { formatDateShort } from '../../../../../utils/dateUtils';
import { TFunction } from 'react-i18next';
import { EmployeeHistory, HistoryFieldType, HistoryFieldTypes } from './types';

export const i18EmployeehistoryPrefix = 'employeePage.employeeHistory';
const i18TypesPrefix = `${i18EmployeehistoryPrefix}.types`;

export const getIcon = (type?: HistoryFieldType): FlairIconName => {
  switch (type) {
    case HistoryFieldTypes.CompanyEmail:
    case HistoryFieldTypes.FeedbackManager:
    case HistoryFieldTypes.Manager:
    case HistoryFieldTypes.Position:
      return 'company-circle';

    case HistoryFieldTypes.Department:
    case HistoryFieldTypes.EmployeeCertificate:
      return 'information-circle';

    case HistoryFieldTypes.Location:
      return 'location-circle';

    case HistoryFieldTypes.Street:
    case HistoryFieldTypes.City:
    case HistoryFieldTypes.Country:
    case HistoryFieldTypes.PostalCode:
      return 'flag-circle';

    case HistoryFieldTypes.Name:
    case HistoryFieldTypes.PrivateEmail:
      return 'person-circle';

    case HistoryFieldTypes.StartDate:
      return 'information-circle';

    case HistoryFieldTypes.Salary:
    case HistoryFieldTypes.IBAN:
    case HistoryFieldTypes.BIC:
    case HistoryFieldTypes.BankAccountOwner:
      return 'money-circle';

    case HistoryFieldTypes.Absence:
      return 'calendar-circle';

    case HistoryFieldTypes.EmployeeFeedbackRequest:
      return 'star-circle';

    case HistoryFieldTypes.EmployeeWorkingHours:
      return 'time-circle';

    default:
      return 'information-circle';
  }
};

export const getChangeType = (type: HistoryFieldType): string => {
  switch (type) {
    case HistoryFieldTypes.CompanyEmail:
    case HistoryFieldTypes.PrivateEmail:
      return `${i18TypesPrefix}.contact`;

    case HistoryFieldTypes.Department:
      return `${i18TypesPrefix}.department`;

    case HistoryFieldTypes.Manager:
      return `${i18TypesPrefix}.manager`;

    case HistoryFieldTypes.FeedbackManager:
      return `${i18TypesPrefix}.feedbackManager`;

    case HistoryFieldTypes.Location:
      return `${i18TypesPrefix}.location`;

    case HistoryFieldTypes.Street:
    case HistoryFieldTypes.City:
    case HistoryFieldTypes.Country:
    case HistoryFieldTypes.PostalCode:
      return `${i18TypesPrefix}.address`;

    case HistoryFieldTypes.Position:
      return `${i18TypesPrefix}.position`;

    case HistoryFieldTypes.Name:
      return `${i18TypesPrefix}.personalInformation`;

    case HistoryFieldTypes.StartDate:
      return `${i18TypesPrefix}.joinedDate`;

    case HistoryFieldTypes.Salary:
      return `${i18TypesPrefix}.salary`;

    case HistoryFieldTypes.EmployeeCertificate:
      return `${i18TypesPrefix}.certificate`;

    case HistoryFieldTypes.EmployeeWorkingHours:
      return `${i18TypesPrefix}.workload`;

    case HistoryFieldTypes.Absence:
      return `${i18TypesPrefix}.absence`;

    case HistoryFieldTypes.EmployeeFeedbackRequest:
      return `${i18TypesPrefix}.performanceReview`;

    case HistoryFieldTypes.IBAN:
      return `${i18TypesPrefix}.paymentInformation`;

    case HistoryFieldTypes.BIC:
      return `${i18TypesPrefix}.paymentInformation`;

    case HistoryFieldTypes.BankAccountOwner:
      return `${i18TypesPrefix}.paymentInformation`;

    case HistoryFieldTypes.EmployeeTaxClass:
    case HistoryFieldTypes.EmployeeTaxNumber:
      return `${i18TypesPrefix}.taxInformation`;

    default:
      return '';
  }
};

export function getHistoryDataFiltered(
  history: EmployeeHistory[],
  filterType: string,
  searchText: string,
  t: TFunction,
): EmployeeHistory[] {
  const lowerCaseSearchText = searchText.toLowerCase();

  return history
    .filter((item) => {
      const itemType = t(getChangeType(item.Name as HistoryFieldType));
      const lowerCaseNewValue = item?.flair__New_Value__c?.toLowerCase();
      const lowerCaseOldValue = item?.flair__Old_Value__c?.toLowerCase();
      const dateText = formatDateShort(item.CreatedDate);

      if (searchText !== '') {
        if (
          lowerCaseNewValue?.includes(lowerCaseSearchText) ||
          (lowerCaseOldValue?.includes(lowerCaseSearchText) &&
            !lowerCaseNewValue)
        ) {
          return true;
        }

        if (dateText.includes(lowerCaseSearchText)) {
          return true;
        }
      } else if (filterType === '' || itemType === filterType) {
        return true;
      }

      return false;
    })
    .sort((a, b) => b.CreatedDate.localeCompare(a.CreatedDate));
}
