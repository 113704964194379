import React, { useMemo } from 'react';
import { TableDayAnySubRow, isTableRowTimeSheet } from './types';
import {
  TimeSheetsTableRow,
  Props as TimeSheetsTableRowProps,
} from './TimeSheetsTableRow';
import {
  createTableDaySubRowLoading,
  createTableDaySubRowLoadingError,
  mapToDaySubRows,
} from './mappings';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useSystemErrorMessageHandler } from '../../../../hooks/useMutationErrorHandler';
import { useTimeSheetDetailsQuery } from '../../__generated__/graphql';

type Props = Omit<TimeSheetsTableRowProps, 'subRows'>;

export const TimeSheetsTableRowConnected: React.FC<Props> = (props) => {
  const { row } = props;

  const t = useNamespacedTranslation('components.timeSheetsTable');
  const getErrorMessage = useSystemErrorMessageHandler();
  const timeSheetId = isTableRowTimeSheet(row) ? row.timeSheetId : '';

  const skipSubrowsGetting =
    !timeSheetId || !isTableRowTimeSheet(row) || !row.expanded;

  const { data, loading, error } = useTimeSheetDetailsQuery({
    variables: {
      id: timeSheetId,
    },
    skip: skipSubrowsGetting,
  });

  const subRows: TableDayAnySubRow[] | undefined = useMemo(() => {
    if (!timeSheetId.length) {
      return [];
    }
    if (loading) {
      return [createTableDaySubRowLoading(timeSheetId)];
    }
    if (error) {
      return [
        createTableDaySubRowLoadingError(
          timeSheetId,
          t('errorLoading', {
            error: getErrorMessage(error),
          }),
        ),
      ];
    }
    if (data) {
      return mapToDaySubRows(data.timeSheet);
    }
    return [];
  }, [t, timeSheetId, getErrorMessage, data, loading, error]);

  return <TimeSheetsTableRow {...props} subRows={subRows} />;
};
