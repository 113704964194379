import React, { useState } from 'react';
import FeedbackModal from './FeedbackModal'; // Import the FeedbackModal component
import FeedbackIcon from './suggestion.svg'; // Placeholder for the actual import path
import './FeedbackCard.css'; // Import the CSS file
import classNames from 'classnames';

type FeedbackCardProps = {
  title: string;
  description: string;
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  imageClassName?: string;
};

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  title,
  description,
  className,
  titleClassName,
  descriptionClassName,
  imageClassName,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <div
        className={` ${classNames('feedback-card', className)}`}
        onClick={openModal}>
        <div
          className={` ${classNames('feedback-card-header', imageClassName)}`}>
          <img src={FeedbackIcon} alt="Feedback" />
        </div>
        <div className="feedback-card-body">
          <div
            className={`${classNames('feedback-card-title', titleClassName)}`}>
            {title}
          </div>
          <p className={` ${classNames('card-text', descriptionClassName)}`}>
            {description}
          </p>
        </div>
      </div>
      <FeedbackModal onClose={closeModal} show={isModalOpen} />
    </>
  );
};

export default FeedbackCard;
