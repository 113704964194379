import { setLocale, string } from 'yup';
import { TIME_REGEX } from '../utils/time';

export type ValidationError = {
  key: string;
  values: any[];
};

const wrap = (key: string) => (values: Record<string, any>) => ({
  key,
  values,
});

setLocale({
  mixed: {
    default: wrap('validations.mixed.not_valid'),
    required: wrap('validations.mixed.required'),
    oneOf: wrap('validations.mixed.oneOf'),
    notOneOf: wrap('validations.mixed.notOneOf'),
  },
  string: {
    length: wrap('validations.string.length'),
    min: wrap('validations.string.min'),
    max: wrap('validations.string.max'),
    email: wrap('validations.string.email'),
    url: wrap('validations.string.url'),
  },
  number: {
    min: wrap('validations.number.min'),
    max: wrap('validations.number.max'),
    lessThan: wrap('validations.number.lessThan'),
    moreThan: wrap('validations.number.moreThan'),
    positive: wrap('validations.number.positive'),
    negative: wrap('validations.number.negative'),
    integer: wrap('validations.number.integer'),
  },
  date: {
    min: wrap('validations.date.min'),
    max: wrap('validations.date.max'),
  },
});

export const yupTimeSchema = () =>
  string().matches(TIME_REGEX, {
    message: () => ({
      key: 'validations.time.format',
    }),
  });
