import {
  AbsenceYearlySummaryDocument,
  DashboardUpcomingAbsencesDocument,
  EmployeeAbsencesDocument,
  MyAbsencesDocument,
} from '../../__generated__/graphql';
import { PureQueryOptions } from '@apollo/client';
import { currentYearPeriod } from '../../../../utils/date';

type Period = {
  from: string;
  to: string;
};

export const useMyAbsencesRefetchOption = (
  period?: Period,
): PureQueryOptions => {
  const range = period ?? currentYearPeriod();
  return {
    query: MyAbsencesDocument,
    variables: {
      ...range,
    },
  };
};

export const useEmployeeAbsencesRefetchOption = (
  employeeId: string,
  period?: Period,
): PureQueryOptions[] => {
  const range = period ?? currentYearPeriod();
  return [
    {
      query: EmployeeAbsencesDocument,
      variables: {
        ...range,
      },
    },
    {
      query: AbsenceYearlySummaryDocument,
      variables: {
        employeeId,
      },
    },
  ];
};

export const dashboardUpcomingAbsencesRefetchOption = {
  query: DashboardUpcomingAbsencesDocument,
  variables: {
    limit: 3,
  },
};
