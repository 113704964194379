import React, { useMemo } from 'react';
import {
  BadgesCell,
  RowCommentsCell,
  StartEndTimeCell,
  TableRowTimeSheet,
  TimeBalanceCell,
  TimeSheetColumn,
  WorkedMinutesCell,
  WorkedMinutesDifferenceCell,
  isTableDaySubRow,
} from '../../../components/TimeSheetsTable';
import {
  TimeSheetColumnId,
  TimeSheetColumnSetting,
} from '../../../components/TimeSheetsTable/CustomizeTableColumns';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { MainCell } from './MainCell';
import DurationFormat from '../../../components/DurationFormat';
import { getDaySubRowTimezoneWarning } from '../../../components/TimeSheetsTable/mappings';
import { DurationCell } from '../../../components/TimeSheetsTable/Cells/DurationCell';

type Props = {
  columnSettings: TimeSheetColumnSetting[];
  isTimeBalanceEnabled: boolean;
  onExpandedChanged: (
    timeSheetRow: TableRowTimeSheet,
    expanded: boolean,
  ) => void;
};

export const useEmployeeTimeSheetsTableColumns = ({
  columnSettings,
  onExpandedChanged,
  isTimeBalanceEnabled,
}: Props): TimeSheetColumn[] => {
  const t = useNamespacedTranslation('timeSheetsControlling.table');

  const columns: TimeSheetColumn[] = useMemo(() => {
    // The most important thing to ux and performance is to do not rerender columns on any data changes
    // It can be easy check by uncommenting line below
    // It should happen ideally only once
    // console.log('columns rerener!');

    const secondColumn: TimeSheetColumn = {
      Header: () => t('header.timeSheet'),
      accessor: 'rowType',
      Cell: ({ row, isExpanded }) => {
        return (
          <MainCell
            row={row}
            isExpanded={isExpanded}
            onExpandedChanged={onExpandedChanged}
          />
        );
      },
    };

    const warningsColumn: TimeSheetColumn = {
      Header: () => null,
      accessor: 'warnings',
      Cell: ({ row }) => {
        if (row.rowType === 'subrow-day') {
          return <BadgesCell badges={row.badges} />;
        }
        return null;
      },
    };

    const customizableColumns: Record<TimeSheetColumnId, TimeSheetColumn> = {
      targetMinutes: {
        Header: () => t('header.targetMinutes'),
        accessor: 'targetMinutes',
        Cell: ({ value }) =>
          value !== undefined ? <DurationFormat minutes={value} /> : null,
      },
      startTime: {
        Header: () => t('header.startTime'),
        accessor: 'startTime',
        Cell: ({ row }) => {
          if (!isTableDaySubRow(row)) {
            return null;
          }
          const timeZoneInfo = getDaySubRowTimezoneWarning(row);
          return (
            <StartEndTimeCell value={row.startTime} infoText={timeZoneInfo} />
          );
        },
      },
      endTime: {
        Header: () => t('header.endTime'),
        accessor: 'endTime',
        Cell: ({ row }) => {
          if (!isTableDaySubRow(row)) {
            return null;
          }
          return <StartEndTimeCell value={row.endTime} />;
        },
      },
      breakMinutes: {
        Header: () => t('header.breakMinutes'),
        accessor: 'breakMinutes',
        Cell: ({ row }) => {
          if (!isTableDaySubRow(row)) {
            return null;
          }
          return <DurationCell value={row.breakMinutes} />;
        },
      },
      workedMinutes: {
        Header: () => t('header.workedMinutes'),
        accessor: 'workedMinutes',
        Cell: ({ row }) => <WorkedMinutesCell row={row} />,
      },
      workedDifferenceMinutes: {
        Header: () => t('header.workedDifferenceMinutes'),
        accessor: 'workedDifferenceMinutes',
        Cell: ({ row }) => <WorkedMinutesDifferenceCell row={row} />,
      },
      commentsCount: {
        Header: () => t('header.commentsCount'),
        accessor: 'commentsCount',
        Cell: ({ row }) => <RowCommentsCell row={row} />,
      },
      timeBalanceAccumulatedMinutes: {
        Header: () => t('header.timeBalanceAccumulatedMinutes'),
        accessor: 'timeBalanceAccumulatedMinutes',
        Cell: ({ row }) => <TimeBalanceCell row={row} />,
      },
    };

    return [
      secondColumn,
      ...columnSettings
        .filter((columnSetting) => {
          if (
            columnSetting.id === 'timeBalanceAccumulatedMinutes' &&
            !isTimeBalanceEnabled
          ) {
            return false;
          }
          return columnSetting.checked;
        })
        .map((columnSetting) => customizableColumns[columnSetting.id]),
      warningsColumn,
    ];
  }, [t, columnSettings, onExpandedChanged, isTimeBalanceEnabled]);

  return columns;
};
