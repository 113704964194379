import moment, { Moment } from 'moment';
import { sortBy } from 'lodash';
import {
  MyWorkload,
  EmployeeWorkload,
  AbsenceApprovalStatus,
} from '../../__generated__/graphql';
import { CalendarAbsence, CalendarWorkload, CalendarHoliday } from './types';
import { NonEmptyArray } from '../../../../utils/array';
import { Theme } from '../../../../theme';
import { dayOfWeekToWokingDay } from '../../manager/TimeSheetsControlling/CurrentTimeSheetsTab/TimeSheetEntryDaysWorked';

type CategoryType =
  | 'group'
  | 'vacation'
  | 'special'
  | 'sick'
  | 'homeoffice'
  | 'trip'
  | 'multiabsences';

type AbsenceDateRange = {
  startDate: Date;
  endDate: Date;
  absence: CalendarAbsence;
};

const isPendingAbsence = (absence: CalendarAbsence): boolean =>
  absence.flair__Approval_Status__c === AbsenceApprovalStatus.Pending;
const isPendingAbsences = (absences?: CalendarAbsence[]): boolean =>
  !!absences?.find(isPendingAbsence);

export const toDate = (date: string, time: string) =>
  toMoment(date, time).toDate();

export const toMoment = (date: string, time: string) =>
  moment(`${date}T${time}`);

const convertToDateRange = (absences: CalendarAbsence[]): AbsenceDateRange[] =>
  absences.map((absence) => ({
    startDate: toDate(
      absence.flair__Start_Date__c,
      absence.flair__Start_Time__c,
    ),
    endDate: toDate(absence.flair__End_Date__c, absence.flair__End_Time__c),
    absence,
  }));

export type CellVariant =
  | 'warning'
  | 'workday'
  | 'weekend'
  | 'absence'
  | 'absenceWeekend'
  | 'holiday'
  | 'pending';

export type CategoryTypeTheme = {
  type: CategoryType;
  icon: string;
  mainColor: string;
  iconColor: string;
  bgColor: string;
  background?: string;
};

export const getAbsenceTheme = (
  absences: NonEmptyArray<CalendarAbsence>,
  day: Moment,
): CategoryTypeTheme => {
  const sortedAbsences: AbsenceDateRange[] = sortBy(
    convertToDateRange(absences),
    'startDate',
  );

  const firstAbsence = sortedAbsences[0];
  const startOfAbsence = day.isSame(firstAbsence.startDate, 'date');
  const endOfAbsence = day.isSame(firstAbsence.endDate, 'date');
  const middleDay = day.clone().set({ hour: 12, minute: 0 });

  const theme = iconToCategoryTypeTheme(
    firstAbsence.absence.flair__Category_Icon__c,
  );

  if (absences.length > 1) {
    const lastAbsence = sortedAbsences[sortedAbsences.length - 1].absence;
    const lastAbsenceTheme = iconToCategoryTypeTheme(
      lastAbsence.flair__Category_Icon__c,
    );

    theme.icon = 'fe-layers';
    theme.type = 'multiabsences';
    theme.background = `linear-gradient(135deg, ${theme.bgColor} 50%, ${lastAbsenceTheme.bgColor} 50%)`;
  } else {
    if (endOfAbsence && firstAbsence.endDate.getTime() <= middleDay.valueOf()) {
      theme.background = `linear-gradient(135deg, ${theme.bgColor} 50%, transparent 50%)`;
    }

    if (
      startOfAbsence &&
      firstAbsence.startDate.getTime() >= middleDay.valueOf()
    ) {
      theme.background = `linear-gradient(135deg, transparent 50%, ${theme.bgColor} 50%)`;
    }
  }

  return theme;
};

export const iconToCategoryTypeTheme = (
  icon: string | null,
): CategoryTypeTheme => {
  switch (icon) {
    case 'custom:custom92': {
      return {
        type: 'vacation',
        icon: 'fe-sun',
        mainColor: Theme.absence.vacationDays.color,
        bgColor: Theme.absence.vacationDays.bgColor,
        iconColor: Theme.absence.vacationDays.accentColor,
      };
    }
    case 'custom:custom5': {
      return {
        type: 'special',
        icon: 'fe-feather',
        mainColor: Theme.absence.specialLeave.color,
        bgColor: Theme.absence.specialLeave.bgColor,
        iconColor: Theme.absence.specialLeave.accentColor,
      };
    }
    case 'custom:custom86': {
      return {
        type: 'sick',
        icon: 'fe-thermometer',
        mainColor: Theme.absence.sickness.color,
        bgColor: Theme.absence.sickness.bgColor,
        iconColor: Theme.absence.sickness.accentColor,
      };
    }
    case 'custom:custom107': {
      return {
        type: 'homeoffice',
        icon: 'fe-home',
        mainColor: Theme.absence.homeOffice.color,
        bgColor: Theme.absence.homeOffice.bgColor,
        iconColor: Theme.absence.homeOffice.accentColor,
      };
    }
    case 'custom:custom36': {
      return {
        type: 'trip',
        icon: 'fe-truck',
        mainColor: Theme.absence.businessTrip.color,
        bgColor: Theme.absence.businessTrip.bgColor,
        iconColor: Theme.absence.businessTrip.accentColor,
      };
    }
    default: {
      return {
        type: 'vacation',
        icon: 'fe-sun',
        mainColor: Theme.absence.vacationDays.color,
        bgColor: Theme.absence.vacationDays.bgColor,
        iconColor: Theme.absence.vacationDays.accentColor,
      };
    }
  }
};

export const workloadIncludesDay = (
  workload: MyWorkload | EmployeeWorkload | CalendarWorkload,
  day: Moment,
) => {
  return workload.flair__Working_Days__c.includes(
    dayOfWeekToWokingDay(day.day()),
  );
};

export const calcAbsencesByDays = (absences: readonly CalendarAbsence[]) =>
  absences.reduce((a, v) => {
    let itDate = moment(v.flair__Start_Date__c);

    while (itDate <= moment(v.flair__End_Date__c)) {
      const absences = a.get(itDate.format('YYYYMMDD')) || [];
      absences.push(v);
      a.set(itDate.format('YYYYMMDD'), absences);
      itDate.add(1, 'days');
    }

    return a;
  }, new Map<string, CalendarAbsence[]>());

export const calcWorkloadsByDays = (
  workloads: readonly CalendarWorkload[],
  toDate: Moment,
) =>
  workloads.reduce((a, v) => {
    let itDate = moment(v.flair__Start_Date__c);
    const endDate = v.flair__End_Date__c
      ? moment(v.flair__End_Date__c)
      : toDate;

    while (itDate <= endDate) {
      a.set(itDate.format('YYYYMMDD'), v);
      itDate.add(1, 'days');
    }

    return a;
  }, new Map<String, CalendarWorkload>());

export const calcHolidaysByDay = (holidays: readonly CalendarHoliday[]) =>
  holidays.reduce((a, v) => {
    let itDate = moment(v.flair__Day__c);

    while (itDate <= moment(v.flair__Day__c)) {
      a.set(itDate.format('YYYYMMDD'), v);
      itDate.add(1, 'days');
    }

    return a;
  }, new Map<string, CalendarHoliday>());

export const calcVariant = (
  workday: boolean,
  attention: boolean,
  absences?: CalendarAbsence[],
  holiday?: CalendarHoliday,
) => {
  if (attention) {
    return 'warning';
  }

  if (isPendingAbsences(absences)) {
    return 'pending';
  }

  if (holiday) {
    return 'holiday';
  }
  if (absences && absences.length > 0) {
    if (workday) {
      return 'absence';
    }
    return 'absenceWeekend';
  }

  if (workday) {
    return 'workday';
  }

  return 'weekend';
};

export const isOverlapped = (absences: CalendarAbsence[]): boolean =>
  sortBy(convertToDateRange(absences), 'startDate').reduce<boolean>(
    (overlapped, current, index, arr) => {
      if (index === 0) {
        return false;
      }
      const previous = arr[index - 1];

      const previousEnd = previous.endDate.getTime();
      const currentStart = current.startDate.getTime();

      const overlap = previousEnd > currentStart;
      if (overlap) {
        overlapped = true;
      }

      return overlapped;
    },
    false,
  );
