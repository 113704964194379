import React from 'react';
import classNames from 'classnames';
import DurationFormat from '../../../components/DurationFormat';
import { MinutesWithChangeRequest } from '../types';

type Props = {
  value: MinutesWithChangeRequest;
  popover?: React.ReactElement;
};

export const DurationCell: React.FC<Props> = ({ value, popover }) => {
  const valueToRender =
    value.changeTo !== undefined ? value.changeTo : value.value;
  return (
    <div
      className={classNames('d-flex gap-1 align-items-center', {
        'text-warning': value.changeTo !== undefined,
      })}>
      <DurationFormat minutes={valueToRender} />
      {value.changeTo !== undefined && popover}
    </div>
  );
};
