import { useMemo } from 'react';
import { FeedbackProps } from '../../pages/PerformanceReview/types';

export type FeedbacksCollection = {
  nextFeedbacks: FeedbackProps[];
  pastFeedbacks: FeedbackProps[];
};

export const useFeedbacks = (data: readonly FeedbackProps[] | undefined) => {
  const feedbacks: FeedbacksCollection = useMemo(() => {
    if (!data) {
      return {
        nextFeedbacks: [],
        pastFeedbacks: [],
      };
    }
    return data.reduce(
      (result, feedback) => {
        if (feedback.outcome.flair__Ended_At__c) {
          const pastFeedbacks = [...result.pastFeedbacks, feedback];
          return {
            ...result,
            pastFeedbacks,
          };
        }
        return {
          ...result,
          nextFeedbacks: [...result.nextFeedbacks, feedback],
        };
      },
      {
        nextFeedbacks: [] as FeedbackProps[],
        pastFeedbacks: [] as FeedbackProps[],
      },
    );
  }, [data]);

  return { feedbacks };
};
