import React from 'react';
import { useTranslation } from 'react-i18next';

type Props<T extends number> = {
  onPageSizeChange: (pageSize: T) => void;
  options: ReadonlyArray<T>;
  pageSize: T;
};

const PageSizeSelect = <T extends number>({
  onPageSizeChange,
  pageSize,
  options,
}: Props<T>) => {
  const { t } = useTranslation();
  return (
    <select
      className="form-select form-control form-control-sm form-control-flush"
      onChange={(e) => onPageSizeChange(parseFloat(e.currentTarget.value) as T)}
      value={pageSize}>
      {options.map((size) => (
        <option key={`option-${size}`} value={size}>
          {t('pagination.perPage', { pageSize: size })}
        </option>
      ))}
    </select>
  );
};

export default PageSizeSelect;
