import styled from '@emotion/styled';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Theme } from '../../../../../../theme';
import { WeeklyShiftStylingInfo } from '../weeklyShiftsTypes';

export type ZPriority = 0 | 1 | 2 | 3 | 4 | 5;

const Container = styled.div<{
  zPriority: ZPriority;
  weeklyShiftBlockStylingInfo: WeeklyShiftStylingInfo;
}>(
  ({
    weeklyShiftBlockStylingInfo: {
      background,
      onHoverBackground,
      border,
      borderBottom,
      borderTop,
      borderRadius,
    },
    zPriority,
  }) => ({
    background,
    border,
    borderBottom,
    borderTop,
    // expand effect on hover
    overflow: 'hidden',
    minHeight: '100%',
    height: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius,
    fontSize: Theme.font.size.sm,
    zIndex: zPriority,
    ':hover': {
      background: onHoverBackground,
    },
  }),
);

const SpinnerContainer = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type Props = {
  weeklyShiftBlockStylingInfo: WeeklyShiftStylingInfo;
  zPriority?: 0 | 1 | 2 | 3 | 4 | 5;
  spinnerVisible: boolean;
  children: React.ReactNode;
  hoverContent?: React.ReactNode;
};

const ShiftWeeklyBlockContainer: React.FC<Props> = ({
  weeklyShiftBlockStylingInfo,
  spinnerVisible,
  children,
  zPriority = 0,
}) => {
  return (
    <Container
      weeklyShiftBlockStylingInfo={weeklyShiftBlockStylingInfo}
      zPriority={zPriority}>
      {children}
      {spinnerVisible && (
        <SpinnerContainer>
          <Spinner animation="border" />
        </SpinnerContainer>
      )}
    </Container>
  );
};

export default ShiftWeeklyBlockContainer;
