import React from 'react';
import { Table } from 'react-bootstrap';
import { useEmployeeTimeSheetsTableColumns } from './useEmployeeTimeSheetsTableColumns';
import { TimeSheetColumnSetting } from '../../../components/TimeSheetsTable/CustomizeTableColumns';
import {
  TableRow,
  TableRowTimeSheet,
} from '../../../components/TimeSheetsTable';
import { TimeSheetsTableRowConnected } from '../../../components/TimeSheetsTable/TimeSheetsTableRowConnected';

type Props = {
  columnSettings: TimeSheetColumnSetting[];
  tableRows: TableRow[];
  isTimeBalanceEnabled: boolean;
  onExpandedChanged: (
    timeSheetRow: TableRowTimeSheet,
    expanded: boolean,
  ) => void;
};

export const EmployeeTimeSheetsTable: React.FC<Props> = ({
  tableRows,
  ...columnProperties
}) => {
  const columns = useEmployeeTimeSheetsTableColumns(columnProperties);

  return (
    <Table className="card-table" size="sm" hover responsive>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.accessor}>
              {column.Header({ allRows: tableRows })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row) => (
          <TimeSheetsTableRowConnected
            key={row.id}
            columns={columns}
            tableRows={tableRows}
            highlightIfHasComments={false}
            row={row}
          />
        ))}
      </tbody>
    </Table>
  );
};
