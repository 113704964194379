import { useState } from 'react';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../../context/Toast';
import { useCloseTicketMutation } from '../../../../__generated__/graphql';
import { useApolloCache } from '../../../../hooks/useApolloCache';
import { getCurrentLanguage } from '../../../../../../locales/locale';
import { Reference } from '@apollo/client';
import { useUserInfo } from '../../../../context/UserInfo';

export const useTicketClose = (isManager: boolean) => {
  const locale = getCurrentLanguage();
  const [isVisible, setIslVisible] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState<string | null>(null);
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();
  const [closeTicketMutation, { loading }] = useCloseTicketMutation();
  const cache = useApolloCache();
  const { id: meId } = useUserInfo();

  const handleModalClose = () => {
    setIslVisible(false);
    setSelectedTicketId(null);
  };

  const handleModalOpen = (id: string) => {
    setIslVisible(true);
    setSelectedTicketId(id);
  };

  const closeTicket = (reason: string | null) => {
    if (!selectedTicketId) return;
    closeTicketMutation({
      variables: {
        lang: locale,
        input: {
          ticketId: selectedTicketId,
          reason: reason,
        },
      },
      update: (cache, response) => {
        const managerCacheId = cache.identify({
          __typename: 'Manager',
          Id: meId,
        });
        cache.modify({
          id: managerCacheId,
          fields: {
            tickets(existent, { readField }) {
              if (!existent) {
                return existent;
              }
              let result = { ...existent };
              if (existent.items) {
                const items: Reference[] = existent.items;
                result.items = items.filter(
                  (changeReqRef) =>
                    readField('Id', changeReqRef) !== selectedTicketId,
                );
              }

              if (existent.paginationInfo) {
                result.paginationInfo = {
                  ...existent.paginationInfo,
                  totalCount: existent.paginationInfo.totalCount - 1,
                };
              }
              return result;
            },
          },
        });
      },
    })
      .then(() => {
        addSuccess('Ticket closed successfully');
      })
      .catch(errorHandler)
      .finally(() => {
        cache.evict({
          fieldName: 'ticketPagination',
        });
        cache.gc();
        handleModalClose();
      });
  };

  return {
    isCloseTicketModal: isVisible,
    handleCloseTicketModalClose: handleModalClose,
    handleCloseTicketModalOpen: handleModalOpen,
    closeTicketLoading: loading,
    submitTicketClose: closeTicket,
  };
};
