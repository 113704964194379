import React from 'react';
import Section from '../Section';
import { CandidateEvaluations } from '../types';
import FeedbackSection from './FeedbackSection';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

type Params = {
  candidateEvaluations: CandidateEvaluations;
};

export const StyledLabel: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <span
    className={['text-dark', 'text-uppercase', 'small', className].join(' ')}>
    {children}
  </span>
);

const i18Path = 'recruitment.candidate.detailsPage';

const MyFeedback: React.FC<Params> = ({ candidateEvaluations }) => {
  const t = useNamespacedTranslation(i18Path);

  if (!candidateEvaluations || candidateEvaluations.length === 0) {
    return <></>;
  }

  return (
    <Section title={t('yourFeedback')}>
      <div className="d-flex flex-column gap-3">
        {candidateEvaluations?.map((candidateEvaluation, i) => (
          <React.Fragment key={candidateEvaluation.Id}>
            <FeedbackSection
              key={candidateEvaluation.Id}
              candidateEvaluation={candidateEvaluation}
            />
            {i !== candidateEvaluations.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </div>
    </Section>
  );
};

export default MyFeedback;
