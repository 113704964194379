import React from 'react';
import { FeedbackCycle } from '../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import StringToHtml from '../../../../../components/StringToHtml';

type Props = {
  feedbackCycle: Pick<FeedbackCycle, 'Name' | 'flair__Introduction_Text__c'>;
};

export const FeedbackCycleIntroductionText: React.FC<Props> = ({
  feedbackCycle,
}) => {
  const { t } = useTranslation();
  const hasIntroductionText = feedbackCycle.flair__Introduction_Text__c != null;
  const feedbackCycleIntroductionText =
    feedbackCycle.flair__Introduction_Text__c === null
      ? ''
      : feedbackCycle.flair__Introduction_Text__c;
  return (
    <>
      {hasIntroductionText && (
        <div>
          <h1>{t('performanceReview.feedbackCycle.introductionText')}</h1>
          <div>
            <StringToHtml text={feedbackCycleIntroductionText} />
          </div>
          <hr />
        </div>
      )}
    </>
  );
};
