import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/button';
import { Theme } from '../../../../../../theme';
import { getKeyResultCurrentValueStr } from '../translations';
import { CreateKeyResultModal } from './CreateKeyResultModal';
import { KeyResultDeleteConfirmation } from './DeleteConfirmations';
import { GoalKeyResult } from './types';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

export type Props = {
  values: GoalKeyResult[];
  onChange: (values: GoalKeyResult[]) => void;
  confirmDeletion?: boolean;
  isDisabled?: boolean;
};

const i18prefix = 'performanceReview.goals2.goalSidebar';

export const GoalKeyResultsInput: React.FC<Props> = ({
  values,
  onChange,
  isDisabled,
  confirmDeletion,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [keyResultToConfirmDeletion, setKeyResultToConfirmDeletion] =
    useState<GoalKeyResult | null>(null);
  const { t } = useTranslation();

  const deleteKeyResult = useCallback(
    (keyResult: GoalKeyResult) =>
      onChange(values.filter((x) => x !== keyResult)),
    [onChange, values],
  );

  const handleDelete = useCallback(
    (keyResult: GoalKeyResult) => {
      if (isDisabled) {
        return;
      }
      if (!confirmDeletion) {
        deleteKeyResult(keyResult);
        setModalVisible(false);
      } else {
        setKeyResultToConfirmDeletion(keyResult);
      }
    },
    [
      setModalVisible,
      isDisabled,
      confirmDeletion,
      setKeyResultToConfirmDeletion,
      deleteKeyResult,
    ],
  );

  const handleModalShow = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleKeyResultConfirmClose = useCallback(() => {
    setKeyResultToConfirmDeletion(null);
  }, [setKeyResultToConfirmDeletion]);

  const handleKeyResultConfirmSubmit = useCallback(() => {
    setKeyResultToConfirmDeletion(null);
    if (keyResultToConfirmDeletion !== null) {
      deleteKeyResult(keyResultToConfirmDeletion);
    }
  }, [
    setKeyResultToConfirmDeletion,
    deleteKeyResult,
    keyResultToConfirmDeletion,
  ]);

  const handleAddKeyResult = useCallback(
    (keyResult: GoalKeyResult) => {
      onChange([...values, keyResult]);
      setModalVisible(false);
    },
    [values, onChange, setModalVisible],
  );

  return (
    <div>
      {values.map((keyResult, i) => (
        <KeyResult key={i} value={keyResult} onDelete={handleDelete} />
      ))}
      <Button
        className="mt-2"
        label={t(`${i18prefix}.addKeyResult`)}
        size="sm"
        variant="outline-primary"
        disabled={isDisabled}
        onClick={handleModalShow}
      />
      <CreateKeyResultModal
        show={modalVisible}
        onClose={handleModalClose}
        onSubmit={handleAddKeyResult}
      />
      <KeyResultDeleteConfirmation
        visible={keyResultToConfirmDeletion !== null}
        name={keyResultToConfirmDeletion?.name ?? ''}
        onClose={handleKeyResultConfirmClose}
        onSubmit={handleKeyResultConfirmSubmit}
      />
    </div>
  );
};

type KeyResultProps = {
  value: GoalKeyResult;
  onDelete: (value: GoalKeyResult) => void;
  className?: string;
};

const KeyResult: React.FC<KeyResultProps> = ({
  value,
  onDelete,
  className,
}) => {
  const handleClick = () => {
    onDelete(value);
  };

  const keyResultValueStr = getKeyResultCurrentValueStr(
    value.type,
    value.currentValue,
    value.targetValue,
  );
  const title = `${value.name} (${keyResultValueStr})`;
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <KeyResultTitle>{title}</KeyResultTitle>
      <StyledDeleteIcon
        icon="close-outline"
        onClick={handleClick}
        color={Theme.color.paper1}
        className="ms-2 me-2"
        size="sm"
      />
    </div>
  );
};

const StyledDeleteIcon = styled(FlairIcon)({
  cursor: 'pointer',
});

const KeyResultTitle = styled('div')({
  color: Theme.color.black,
  fontSize: Theme.font.size.sm,
});
