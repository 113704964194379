import {
  ReactionFragment,
  useCreateReactionMutation,
} from '../../../../__generated__/graphql';
import { RelatedObjectTypeName } from '../types';
import { UserInfo } from '../../../../context/UserInfo/types';

const OPTIMISTIC_ID = 'OPTIMISTIC_ID';

type ReactionObject = {
  Id: string;
  flair__Text__c: string;
  flair__Employee__c: string;
  flair__Related_Object_Name__c: string;
  flair__Related_Id__c: string;
  employee: {
    Id: string;
    Name: string;
    __typename: 'Employee';
  };
  __typename: 'Reaction';
};

//Reading reactions from cache won't help here because triggering the same mutation multiple times
//will lead to wrong cached data since the cache is still not updated
//Therefore, reactions are passed here
export const useCreateReaction = (
  objectTypeName: RelatedObjectTypeName,
  recordId: string,
  reactions: ReadonlyArray<ReactionFragment>,
  userInfo: UserInfo,
) =>
  useCreateReactionMutation({
    optimisticResponse: ({
      input: { relatedObjectName, text, employeeId },
    }) => {
      const optimisticNewReactionObject = generateReactionResponse(
        OPTIMISTIC_ID,
        text,
        employeeId,
        relatedObjectName,
        recordId,
        userInfo.name,
      );

      return {
        reaction: {
          createReaction: {
            recordId: OPTIMISTIC_ID,
            record: optimisticNewReactionObject,
            related: {
              __typename: objectTypeName,
              Id: recordId,
              reactions: reactions.concat(optimisticNewReactionObject),
            },
            error: null,
          },
        },
      };
    },
    update: (cache, response) => {
      if (!response.data) {
        return;
      }

      const reactionId = response.data.reaction.createReaction.recordId;
      const relatedId = response.data.reaction.createReaction.related?.Id;
      if (!reactionId || !relatedId) {
        return;
      }

      const newReaction = response.data.reaction.createReaction.record;
      if (!newReaction) {
        return;
      }

      cache.modify({
        id: cache.identify({
          __typename: objectTypeName,
          id: relatedId,
        }),
        fields: {
          reactions(existingReactions = []) {
            return existingReactions.concat(newReaction);
          },
        },
      });
    },
  });

const generateReactionResponse = (
  id: string,
  text: string,
  employeeId: string,
  relatedObjectName: string,
  recordId: string,
  employeeName: string,
): ReactionObject => ({
  Id: id,
  flair__Text__c: text,
  flair__Employee__c: employeeId,
  flair__Related_Object_Name__c: relatedObjectName,
  flair__Related_Id__c: recordId,
  employee: {
    Id: employeeId,
    Name: employeeName,
    __typename: 'Employee',
  },
  __typename: 'Reaction',
});
