import { sortBy } from 'lodash';
import { TimeEntryChangeRequestProps, TimeEntryProps } from './types';

export const calculateDayCurrentTimeEntries = (
  dayTimeEntries: ReadonlyArray<TimeEntryProps>,
  dayTimeEntryChangeRequests: ReadonlyArray<TimeEntryChangeRequestProps>,
): (TimeEntryProps | TimeEntryChangeRequestProps)[] => {
  const changeRequestWithoutTimeEntry = dayTimeEntryChangeRequests.filter(
    (r) => !r.flair__Time_Entry__c,
  );
  const timeEntriesWithoutChanges = dayTimeEntries.filter(
    (t) =>
      !dayTimeEntryChangeRequests.find((r) => r.flair__Time_Entry__c === t.Id),
  );
  const changeRequests = dayTimeEntryChangeRequests.filter(
    (r) => r.flair__Time_Entry__c,
  );
  return sortBy(
    [
      ...changeRequestWithoutTimeEntry,
      ...timeEntriesWithoutChanges,
      ...changeRequests,
    ],
    ['flair__Start_Datetime__c'],
  );
};
