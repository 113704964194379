import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../hooks/useNamespacedTranslation';

type Props = {
  loadingMessage?: string;
};

export const LoadingMoreItemsSpinner: React.FC<Props> = ({
  loadingMessage,
}) => {
  const t = useNamespacedTranslation('components.loadingMoreItemsSpinner');

  return (
    <div className="d-flex justify-content-center align-items-center mb-2">
      <Spinner animation="border" size="sm" />
      <small className="ms-2 text-center">{loadingMessage || t('text')}</small>
    </div>
  );
};
