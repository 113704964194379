export type NonEmptyArray<T> = [T, ...T[]];

export const isNonEmptyArray = <T>(arr: T[]): arr is NonEmptyArray<T> =>
  arr.length > 0;

export const isEmptyArray = <T>(arr: T[]): arr is NonEmptyArray<T> =>
  !isNonEmptyArray(arr);

export const isLastIndex = (index: number, arr: any[]): boolean =>
  index === arr.length - 1;

export const median = (array: number[]): number | undefined => {
  if (array.length === 0) {
    return undefined;
  }
  const sorted = array.sort();
  if (sorted.length % 2 === 0) {
    // array with even number elements
    return (sorted[sorted.length / 2] + sorted[sorted.length / 2 - 1]) / 2;
  } else {
    return sorted[(sorted.length - 1) / 2]; // array with odd number elements
  }
};

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}
