import { getCurrentLanguage } from '../../../../../../locales/locale';
import {
  InventoryOptionsQuery,
  useInventoryOptionsQuery,
} from '../../../../__generated__/graphql';
import { useMemo, useState } from 'react';
import { CreatableDropdownOption } from '../../../../../../components/Select/types';

export const useGetInventoryOptions = () => {
  const locale = getCurrentLanguage();
  const [options, setOptions] = useState<CreatableDropdownOption[]>([]);

  const { data, error, loading } = useInventoryOptionsQuery({
    variables: {
      lang: locale,
    },
  });

  useMemo(() => {
    const options = mapInventoryOptions(data);

    if (!loading && options.length) {
      setOptions(options);
    }
  }, [loading, data]);

  const findInventoryById = (inventoryId: string) => {
    return data?.inventories.find((x) => x.Id === inventoryId);
  };

  return {
    inventoryOptions: options,
    setOptions,
    findInventoryById,
    loading,
    error,
  };
};

const mapInventoryOptions = (
  src: InventoryOptionsQuery | undefined,
): CreatableDropdownOption[] => {
  if (!src || !src.inventories?.length) {
    return [];
  }

  return src.inventories.map(({ Id, Name }) => ({
    value: Id,
    label: Name,
  }));
};
