import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import styled from '@emotion/styled';
import { Theme } from '../../../../theme';

type Props = {
  title?: string;
  description?: string;
  visible: boolean;
  isExpanded?: boolean;
  onExpand?: (val: boolean) => void;
  onClose: () => void;
  hasExpand?: boolean;
};

const ExpandModal: React.FC<Props> = ({
  title,
  description,
  visible,
  isExpanded = false,
  onExpand = () => {},
  onClose,
  children,
  hasExpand = true,
}) => {
  return (
    <Modal
      show={visible}
      onHide={onClose}
      size={isExpanded ? 'xl' : 'sm'}
      centered
      className={classNames('confirm-modal')}
      contentClassName="border"
      dialogClassName={classNames('modal-expand', {
        'break-modal-494': !isExpanded,
        'break-modal-full': isExpanded,
      })}
      backdropClassName="confirm-modal-backdrop">
      <div className="h-100">
        <Modal.Header className="card-header d-flex border-bottom-0 align-items-center">
          <div className="d-flex flex-column">
            {!!title && (
              <h2 className={`card-header-title ${!isExpanded ? 'mt-4' : ''}`}>
                {title}
              </h2>
            )}
            {!!description && !isExpanded && (
              <StyledDescription className="fw-light h5-regular">
                {description}
              </StyledDescription>
            )}
          </div>
          {hasExpand && (
            <div className="mx-3">
              <FlairIcon icon="expand" onClick={() => onExpand(!isExpanded)} />
            </div>
          )}
          <div>
            <FlairIcon icon="close-outline" onClick={onClose} />
          </div>
        </Modal.Header>
        <div className="m-4" />
        {children}
      </div>
    </Modal>
  );
};

const StyledDescription = styled.p({
  color: Theme.color.paper1,
});

export default ExpandModal;
