import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from '@emotion/styled';

type Props = {
  width?: number;
  height?: number;
  className?: string;
};

const Container = styled.div({
  display: 'inline-block',
});

export const SkeletonButton: React.FC<Props> = ({
  width = 100,
  height = 40,
  className,
}) => (
  <Container className={className}>
    <Skeleton width={width} height={height} />
  </Container>
);
