import * as React from 'react';
import { CommentFile } from '../types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import './DocumentItem.css';
import {
  actionsEnum,
  actionsType,
} from '../../../../../components/UploadFile/types';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  document: CommentFile;
  action?: actionsType;
  onDelete?: () => void;
  onClick?: () => void;
};

const i18Path = 'comments';

const DocumentItem: React.FC<Props> = ({
  document: { fileName, fileType },
  action = actionsEnum.VIEW,
  onDelete,
  onClick,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const isLink = fileType === 'link';

  return (
    <div className="d-flex justify-content-between align-items-center p-3 rounded document-container">
      <div className="filename text-truncate" role="button" onClick={onClick}>
        {fileName}
      </div>
      <div className="d-flex gap-3 align-items-center filetype">
        {action === actionsEnum.VIEW ? (
          <>
            {!isLink && <div>{fileType}</div>}
            <FlairIcon
              icon={isLink ? 'link-outline' : 'document-outline'}
              size="lg"
            />
          </>
        ) : (
          <Button
            variant="outline-primary"
            label={t('delete')}
            size="sm"
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentItem;
