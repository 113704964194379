import React from 'react';
import {
  Route,
  Switch,
  useParams,
  Redirect as RedirectRow,
} from 'react-router-dom';
import { useCurrentLocale } from '../../../../context/I18n';
import { Redirect } from '../../../../Router';
import { hasAnyRoles } from '../../context/UserInfo';
import {
  EmployeePageQuery,
  ManagerRole,
  useEmployeePageQuery,
} from '../../__generated__/graphql';
import { managerRoutes } from '../routes';
import Loading from './Loading';
import PageHeader from './PageHeader';
import { employeePageRoutes } from './PageHeader/routes';
import {
  addDateByUnit,
  changePeriodByUnit,
  formatDate,
} from '../../../../utils/dateUtils';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

const RedirectToManagerDashboard: React.FC = () => (
  <Redirect to={managerRoutes.myTeam.route} />
);

export type Employee = NonNullable<EmployeePageQuery['manager']['employee']>;

const Content: React.FC<{ employee: Employee }> = ({ employee }) => {
  return (
    <Switch>
      {Object.values(employeePageRoutes()).map(
        (
          { render, route, rolesWithAccessForThatEmployee: rolesWithAccess },
          i,
        ) => (
          <Route
            key={i}
            exact={true}
            path={route.template()}
            render={() => {
              if (
                hasAnyRoles(employee.managerRolesForEmployee, rolesWithAccess)
              ) {
                return render(employee);
              }
              const availableRoute = getFirstAvailableRoute(
                employee.managerRolesForEmployee,
              )?.route;
              return (
                <RedirectRow
                  to={
                    availableRoute
                      ? availableRoute.create({
                          employeeId: employee.Id,
                          // todo: This variable is not used. Please remove it, if you know how to do it (typescript challenge)
                          timeSheetId: '',
                        })
                      : managerRoutes.myTeam.route.create({})
                  }
                />
              );
            }}
          />
        ),
      )}
      <Route path="*">
        <RedirectToManagerDashboard />
      </Route>
    </Switch>
  );
};

type Params = {
  employeeId: string;
};

const currentPeriod = () => {
  const { start, end } = changePeriodByUnit(new Date(), { unit: 'year' });
  return {
    from: formatDate(start.setDate(start.getDate() - 1), 'yyyy-MM-dd'),
    to: formatDate(end, 'yyyy-MM-dd'),
  };
};

const timeSheetsPastWeeks = () => {
  const startingWeek = addDateByUnit(new Date(), -10, 'week');
  const endingWeek = addDateByUnit(new Date(), -1, 'week');
  const { start } = changePeriodByUnit(startingWeek, { unit: 'week' });
  const { end } = changePeriodByUnit(endingWeek, { unit: 'week' });

  return {
    timeSheetsFrom: formatDate(start, 'yyyy-MM-dd'),
    timeSheetsTo: formatDate(end, 'yyyy-MM-dd'),
  };
};

type Props = {
  employeeId: string;
};

const Page: React.FC<Props> = ({ employeeId }) => {
  const t = useNamespacedTranslation('navigation.menuItems.people');
  const locale = useCurrentLocale();
  const { data, loading, error } = useEmployeePageQuery({
    variables: {
      id: employeeId,
      ...currentPeriod(),
      ...timeSheetsPastWeeks(),
      locale,
    },
  });

  useFlairBreadcrumbHook(
    [
      { label: t('title') },
      {
        label: `${data?.manager.employee?.Name} (${data?.manager.employee?.flair__Position__c})`,
      },
    ],
    loading,
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.manager.employee) {
    return <RedirectToManagerDashboard />;
  }

  const employee = data.manager.employee;

  return (
    <>
      <PageHeader employee={data.manager.employee} />
      <Content employee={employee} />
    </>
  );
};

const EmployeePage: React.FC = () => {
  const { employeeId } = useParams<Params>();

  return <Page employeeId={employeeId} />;
};

const getFirstAvailableRoute = (meEmployeeRoles: readonly ManagerRole[]) => {
  return Object.values(employeePageRoutes()).find(
    (route) =>
      'canBeUsedForAsDefaultRoute' in route &&
      route.canBeUsedForAsDefaultRoute === true &&
      hasAnyRoles(route.rolesWithAccessForThatEmployee, meEmployeeRoles),
  );
};

export default EmployeePage;
