import { addMonths, isEqual, startOfMonth } from 'date-fns';
import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import i18next from 'i18next';
import { formatDate, parseDate } from '../../../../utils/dateUtils';
import {
  CelebrationCard,
  CelebrationCardSkeleton,
} from './CelebrationCard/CelebrationCard';
import { Celebration, CelebrationGroup, CelebrationsView } from './types';
import Skeleton from 'react-loading-skeleton';

type Props = {
  now: string;
  group: CelebrationGroup;
  celebrations: Celebration[];
  onMakePublic: (id: string) => void;
  onUpdate: (celebration: Celebration) => void;
  onDelete: (id: string) => void;
  topRightAction?: React.ReactNode;
  view: CelebrationsView;
  className?: string;
};

const i18nPrefix = 'cheers.cheersGroup';

export const CelebrationsGroup: React.FC<Props> = ({
  group,
  celebrations,
  onMakePublic,
  onUpdate,
  onDelete,
  now,
  topRightAction,
  view,
  className,
}) => {
  const { firstGroupDay, groupType } = group;
  const t = useNamespacedTranslation(i18nPrefix);
  const headerText = getHeaderText(group, now);
  const monthText = formatDate(parseDate(firstGroupDay), 'MMMM yyyy');
  const celebrationsCountText = t('cheersCount', {
    count: celebrations.length,
  });
  return (
    <div className={className}>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2 className="mb-2">{headerText}</h2>
          {groupType === 'month' && (
            <div className="text-dark">{monthText}</div>
          )}
          <small className="text-dark">{celebrationsCountText}</small>
        </div>
        {topRightAction}
      </div>
      <div className="d-flex flex-wrap gap-4 mt-3">
        {celebrations.map((celebration) => {
          return (
            <CelebrationCard
              key={celebration.id}
              celebration={celebration}
              variant={view}
              canManage={view === 'sent'}
              onMakePublic={onMakePublic}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          );
        })}
      </div>
    </div>
  );
};

export const CelebrationsGroupSkeleton: React.FC = () => {
  return (
    <div>
      <div className="d-flex flex-column">
        <Skeleton className="mb-2" width="6rem" height="1.5rem" />
        <Skeleton width="5rem" height="1.25rem" />
        <Skeleton width="7rem" height="1.25rem" />
      </div>
      <div className="d-flex flex-wrap gap-4 mt-3">
        {[1, 2, 3].map((_, i) => {
          return <CelebrationCardSkeleton key={i.toString()} />;
        })}
      </div>
    </div>
  );
};

function getHeaderText(group: CelebrationGroup, now: string) {
  if (group.groupType === 'month') {
    const thisMonthStart = startOfMonth(parseDate(now));
    const lastMonthStart = addMonths(thisMonthStart, -1);
    const mStart = startOfMonth(parseDate(group.firstGroupDay));
    if (isEqual(thisMonthStart, mStart)) {
      return i18next.t(`${i18nPrefix}.thisMonth`);
    } else if (isEqual(lastMonthStart, mStart)) {
      return i18next.t(`${i18nPrefix}.lastMonth`);
    }
    return formatDate(mStart, 'MMMM');
  } else if (group.groupType === 'year') {
    return formatDate(parseDate(group.firstGroupDay), 'yyyy');
  }
}
