import { differenceInDays, endOfDay, format, formatDistance } from 'date-fns';
import React from 'react';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { parseDate } from '../../../../../utils/dateUtils';
import {
  MyCheckinsSurveysQuery,
  useAnswerCheckinsSurveyCycleMutation,
  useDismissCheckinsSurveyCycleMutation,
} from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';
import { SmileySurveyData, SmileySurveyUi } from './SmileySurveyUi';

type Props = {
  survey: MyCheckinsSurveysQuery['me']['checkinsSurveys'][number];
  cycle: MyCheckinsSurveysQuery['me']['checkinsSurveys'][number]['cycles'][number];
};

export const SmileySurvey: React.FC<Props> = ({ survey, cycle }) => {
  const { id: employeeId } = useUserInfo();
  const t = useNamespacedTranslation('home.smileyCheckins');
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const [answerSurvey, { loading: loadingAnswer }] =
    useAnswerCheckinsSurveyCycleMutation({
      awaitRefetchQueries: true,
    });

  const [dismissSurvey, { loading: loadingDismiss }] =
    useDismissCheckinsSurveyCycleMutation({
      awaitRefetchQueries: true,
    });
  const loading = loadingAnswer || loadingDismiss;

  const today = new Date();
  const cycleStartDate = parseDate(cycle.startDate);
  const cycleEndDate = !!cycle.endDate ? parseDate(cycle.endDate) : null;

  const numberOfDueDays = !!cycleEndDate
    ? Math.ceil(differenceInDays(endOfDay(cycleEndDate), endOfDay(today)))
    : 0;
  const dueInMessage = !!cycleEndDate
    ? numberOfDueDays === 0
      ? t('dueIn0Days')
      : t('dueInDays', {
          days: formatDistance(endOfDay(cycleEndDate), endOfDay(today)),
        })
    : '';

  const leftSide = !!cycleEndDate
    ? `${format(cycleStartDate, 'MMM do')} - ${format(cycleEndDate, 'MMM do')}`
    : t('title');

  const handleSubmit = (data: SmileySurveyData) => {
    if (data.score !== null) {
      answerSurvey({
        variables: {
          input: {
            surveyId: survey.id,
            surveyCycleId: cycle.id,
            employeeId: employeeId,
            score: data.score,
            comment: data.comment,
          },
        },
      })
        .then(() => {
          addSuccess(t('submitSuccess'));
        })
        .catch(errorHandler);
    }
  };

  const handleDismiss = (callback?: () => void) => {
    dismissSurvey({
      variables: {
        input: {
          surveyId: survey.id,
          surveyCycleId: cycle.id,
          employeeId: employeeId,
        },
      },
    })
      .then(() => {
        addSuccess(t('dismissConfirmation.successMessage'));
        !!callback && callback();
      })
      .catch(errorHandler);
  };

  return (
    <SmileySurveyUi
      headerLeft={leftSide}
      headerRight={dueInMessage}
      questionText={survey.questionText}
      commentPromptText={
        survey.allowComments ? t('commentQuestionPrompt') : t('commentPrompt')
      }
      handleSubmit={handleSubmit}
      handleDismiss={handleDismiss}
      allowComments={survey.allowComments}
      allowDismiss={!survey.compulsory}
      submitLoading={loading}
    />
  );
};
