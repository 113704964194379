import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApproverAvatar } from '../MyAbsences/MyAbsenceFilters/types';

type Props = {
  initials: string;
  fullName: string;
  avatar?: ApproverAvatar;
};

const Avatar: React.FC<Props> = ({ initials, fullName, avatar }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={`absence-approver-${initials}`}>{fullName}</Tooltip>
      }>
      <div className="avatar avatar-xs d-flex align-items-center">
        {avatar?.url ? (
          <img
            src={avatar.url}
            alt="approver"
            className="avatar-img rounded-circle"></img>
        ) : (
          <span className="avatar-title rounded-circle">{initials}</span>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default Avatar;
