export const iconToCategoryType = (
  icon: string | null,
): 'vacation' | 'special' | 'sick' | 'homeoffice' | 'trip' | 'vacation' => {
  switch (icon) {
    case 'custom:custom92': {
      return 'special';
    }
    case 'custom:custom5': {
      return 'vacation';
    }
    case 'custom:custom86': {
      return 'sick';
    }
    case 'custom:custom107': {
      return 'homeoffice';
    }
    case 'custom:custom36': {
      return 'trip';
    }
    default: {
      return 'vacation';
    }
  }
};
