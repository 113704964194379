import { Moment } from 'moment';
import React from 'react';
import AbsenceTime from './AbsenceTime';

type Props = {
  startDate: Moment;
  endDate: Moment;
};

const AbsenceTimeDuration: React.FC<Props> = ({ startDate, endDate }) => {
  const startHours = startDate.format('HH:mm');
  const endHours = endDate.format('HH:mm');

  if (startHours === '00:00' && endHours === '23:59') {
    return <></>;
  }

  return (
    <span className="d-block">
      <AbsenceTime time={startHours} />
      &nbsp;-&nbsp;
      <AbsenceTime time={endHours} />
    </span>
  );
};

export default AbsenceTimeDuration;
