import React from 'react';
import { Popover } from 'react-bootstrap';
import FormattedDate from '../../../../components/date/FormattedDate';
import HolidayIcon from '../HolidayIcon';
import { HolidayPopoverFragment } from '../../__generated__/graphql';

type Props = {
  holiday: HolidayPopoverFragment;
};

const HolidayPopover: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ holiday, ...rest }, ref) => {
    const popoverProps = {
      ref,
      ...rest,
    };

    return (
      <Popover id={holiday.Id} {...popoverProps}>
        <Popover.Header>
          <HolidayIcon />
          <span className="ms-2">{holiday.Name}</span>
        </Popover.Header>
        <Popover.Body>
          <FormattedDate day={holiday.flair__Day__c} format="long" />
        </Popover.Body>
      </Popover>
    );
  },
);

export default HolidayPopover;
