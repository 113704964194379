import * as React from 'react';
import TableModal from '../../../../../../atomic/organisms/TableModal';
import {
  EmployeeScopeFilter,
  useDashboardAnniversariesQuery,
} from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { mapAnniversaryForModal } from './mapping';

type Props = {
  onClose: () => void;
  filterBy: EmployeeScopeFilter;
};

const i18Path = 'dashboard.anniversaries.modal';

const AllAnniversariesModal: React.FC<Props> = ({ onClose, filterBy }) => {
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useDashboardAnniversariesQuery({
    variables: {
      filter: {
        limit: null,
        filterBy,
      },
    },
  });

  const headers = [
    {
      key: 'employee',
      title: t('employee'),
    },
    {
      key: 'department',
      title: t('department'),
    },
    {
      key: 'location',
      title: t('location'),
    },
    {
      key: 'additionalInfo',
      title: t('anniversary'),
    },
  ];

  if (error) {
    return <ServerError />;
  }

  const anniversaries =
    data?.anniversaries
      .filter(
        (a) =>
          a.flair__Upcoming_Anniversary__c !== null &&
          a.flair__Start_Date__c !== null,
      )
      .map(mapAnniversaryForModal) ?? [];

  return (
    <TableModal
      title={t('title')}
      onClose={onClose}
      headers={headers}
      data={anniversaries}
      loading={loading}
    />
  );
};

export default AllAnniversariesModal;
