import * as React from 'react';

const ArrowUpSquareOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28571 2.9375C2.85102 2.9375 2.5 3.28911 2.5 3.72115V12.2788C2.5 12.7109 2.85102 13.0625 3.28571 13.0625H12.7143C13.149 13.0625 13.5 12.7109 13.5 12.2788V3.72115C13.5 3.28911 13.149 2.9375 12.7143 2.9375H3.28571ZM1.5 3.72115C1.5 2.73531 2.30025 1.9375 3.28571 1.9375H12.7143C13.6998 1.9375 14.5 2.73531 14.5 3.72115V12.2788C14.5 13.2647 13.6998 14.0625 12.7143 14.0625H3.28571C2.30025 14.0625 1.5 13.2647 1.5 12.2788V3.72115Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64782 4.95757C7.84277 4.76414 8.15723 4.76414 8.35218 4.95757L10.8522 7.4382C11.0482 7.6327 11.0494 7.94928 10.8549 8.1453C10.6604 8.34132 10.3438 8.34255 10.1478 8.14805L8.5 6.513V10.6875C8.5 10.9636 8.27614 11.1875 8 11.1875C7.72386 11.1875 7.5 10.9636 7.5 10.6875V6.513L5.85218 8.14805C5.65616 8.34255 5.33958 8.34132 5.14507 8.1453C4.95057 7.94928 4.9518 7.6327 5.14782 7.4382L7.64782 4.95757Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ArrowUpSquareOutlineIcon;
