import React from 'react';
import { hoursToMinutes } from '../../../../../utils/time';
import { TrackedTime } from '../../../components/TrackedTime';
import TimeFulfillment from './TimeFulfillment';
import { EmployeeWorkload } from '../../../__generated__/graphql';
import { isHourlyBasedWeek } from '../../../utils/timesheetHelper';

type TimeSheetEntry = {
  flair__Start_Date__c: string;
  flair__Target_Hours__c: number;
  trackedTime: TrackedTime;
  workloads: ReadonlyArray<
    Pick<
      EmployeeWorkload,
      'flair__Workload_Model__c' | 'flair__Start_Date__c' | 'flair__End_Date__c'
    >
  >;
};

type Props = {
  timeSheet: TimeSheetEntry;
};

const TimeSheetEntryTimeFulfillment: React.FC<Props> = ({ timeSheet }) => {
  return (
    <TimeFulfillment
      target={
        isHourlyBasedWeek(timeSheet)
          ? null
          : hoursToMinutes(timeSheet.flair__Target_Hours__c)
      }
      tracked={timeSheet.trackedTime.totalTrackedMinutes}
    />
  );
};

export default TimeSheetEntryTimeFulfillment;
