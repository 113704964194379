import styled from '@emotion/styled';
import React from 'react';
import { useAccordionButton } from 'react-bootstrap';

type Props = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  accordionToggleKey: string;
  accordionToggleOpen: () => void;
};

const Title = styled.span({
  cursor: 'pointer',
});

const CardTitle: React.FC<Props> = ({
  title,
  subtitle,
  accordionToggleOpen,
  accordionToggleKey,
}) => {
  const handleAccordionClick = useAccordionButton(
    accordionToggleKey,
    accordionToggleOpen,
  );
  return (
    <Title className="text-white" onClick={handleAccordionClick}>
      {title}
      <small className="text-muted ms-2">{subtitle}</small>
    </Title>
  );
};

export default CardTitle;
