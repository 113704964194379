import * as React from 'react';
import { BreadcrumbItem } from '../atomic/molecules/FlairBreadcrumb/types';
import { useState } from 'react';

export const FlairBreadcrumbContext = React.createContext({
  breadcrumbItems: [] as BreadcrumbItem[],
  setBreadcrumbItems: (_items: BreadcrumbItem[]) => {},
});

export const FlairBreadcrumbProvider: React.FC = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  return (
    <FlairBreadcrumbContext.Provider
      value={{ breadcrumbItems, setBreadcrumbItems }}
      {...props}
    />
  );
};
