import * as React from 'react';

const MailOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.25 3.00366H2.75C2.05964 3.00366 1.5 3.56331 1.5 4.25366V11.7537C1.5 12.444 2.05964 13.0037 2.75 13.0037H13.25C13.9404 13.0037 14.5 12.444 14.5 11.7537V4.25366C14.5 3.56331 13.9404 3.00366 13.25 3.00366Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 5.00366L8 8.50366L12.5 5.00366"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default MailOutlineIcon;
