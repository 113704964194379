import React from 'react';
import { routes as r } from '../../../routes';
import { CustomerSupport } from '../CustomerSupport';
import NotificationSettings2 from '../NotificationSettings2';
import { General } from '../General';

export const routes = () => ({
  general: {
    route: r.general.route,
    render: () => <General />,
  },
  notificationSettings: {
    route: r.notificationSettings.route,
    render: () => <NotificationSettings2 />,
  },
  customerSupport: {
    route: r.customerSupport.route,
    render: () => <CustomerSupport />,
  },
});
