import { toISODateOnly } from '../../../../../utils/dateUtils';
import {
  ProjectTimeEntriesInput,
  WorkloadType,
} from '../../../__generated__/graphql';
import {
  WeekDay,
  ProjectDayTimeTracked,
  Timesheet,
  Project,
  ProjectTimeTrackingMap,
  ProjectTimeEntry,
  ProjectDay,
  ProjectDayTimeTrackedToSave,
} from '../types';
import { generateWeekDays, generateMonthDays } from '../helpers';
import { Moment } from 'moment';
import { getProjectStatus, UNIQUE_ID_PREFFIX } from './helpers';
import moment from 'moment';

export const buildProjectsTimeTrackings = (
  projects: Project[],
  timesheet: Timesheet,
): ProjectTimeTrackingMap => {
  const days =
    timesheet.timesheetType === WorkloadType.Monthly
      ? generateMonthDays(timesheet.startDate)
      : generateWeekDays(timesheet.startDate);
  return projects.reduce<ProjectTimeTrackingMap>((object, project) => {
    object[project.id] = buildProjectTimeTrackings(
      project,
      timesheet.id,
      days,
      timesheet.projectTimeTracked,
    );
    return object;
  }, {});
};

export const buildProjectTimeTrackings = (
  project: Project,
  timesheetId: string,
  days: WeekDay[],
  projectTimeTrackings: ProjectDayTimeTracked[],
): ProjectDay[] =>
  days.map(({ date, dateId }, index) => {
    const timesTracked: ProjectDayTimeTracked[] = projectTimeTrackings.filter(
      (timeTracking) =>
        timeTracking.dateId === dateId && project.id === timeTracking.projectId,
    );

    return {
      date,
      dateId,
      status: getProjectStatus(project, moment(date)),
      timesTracked:
        timesTracked.length > 0
          ? timesTracked
          : [
              {
                id: null,
                date,
                dateId,
                timesheetId,
                projectId: project.id,
                costCenterId: null,
                notes: '',
              },
            ],
    };
  }, {});

export const totalMinutes = (days: ProjectDay[]) =>
  days
    .map((day) => day.timesTracked)
    .flat()
    .reduce((sum, timeTracked) => sum + (timeTracked.minutes || 0), 0);

export const mapToTimeEntryInput = (
  timeEntry: ProjectDayTimeTrackedToSave,
): ProjectTimeEntriesInput => {
  return {
    id: timeEntry.id,
    date: toISODateOnly(timeEntry.date),
    minutes: timeEntry.minutes !== undefined ? timeEntry.minutes : null,
    projectId: timeEntry.projectId,
    costCenterId: timeEntry.costCenterId,
    notes: timeEntry.notes,
  };
};

export function mapToProjectDayTimeTracked(
  timeEntry: ProjectTimeEntry,
  day: Moment,
): ProjectDayTimeTrackedToSave | null {
  if (!timeEntry.projectId) {
    return null;
  }

  return {
    id: timeEntry.id?.includes(UNIQUE_ID_PREFFIX) ? null : timeEntry.id,
    date: day.toDate(),
    projectId: timeEntry.projectId,
    costCenterId: timeEntry.costCenterId,
    minutes: timeEntry.minutes,
    notes: timeEntry.notes,
  };
}
