import * as React from 'react';
import { ActionType, ActionTypes } from '../types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Maybe } from '../../../../../utils/maybe';
import './Actions.css';
import UploadFile from '../../../../../components/UploadFile';
import Button from '../../../../../components/button';
import { RefObject, useContext, useState } from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../../theme';
import { getTextWithoutHtmlTags } from '../../../../../utils/html';
import { InnerModalContext } from '../../../../../context/InnerModalContext';
import { Spinner } from 'react-bootstrap';
const EmojiPicker = React.lazy(() => import('../../EmojiPicker'));

const acceptExtensions = {
  [ActionTypes.File]: '.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf',
  [ActionTypes.Image]: 'image/*',
};

type Props = {
  onFilesChange?: (files: FileList) => void;
  onImagesChange?: (files: FileList) => void;
  text: string;
  setText: (text: string) => void;
  textareaRef: RefObject<HTMLDivElement>;
  range: Maybe<Range>;
  onCancel?: () => void;
  onSubmit?: () => void;
  isSubmitDisabled: boolean;
  loading: boolean;
  hideMainActions: boolean;
  hideSecondaryActions: boolean;
};

const i18Path = 'comments.input';

const Actions: React.FC<Props> = ({
  onFilesChange,
  onImagesChange,
  text,
  setText,
  textareaRef,
  range,
  onCancel,
  onSubmit,
  isSubmitDisabled,
  loading,
  hideMainActions = false,
  hideSecondaryActions = false,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [link, setLink] = useState<string>('');
  const [inputFieldType, setInputFieldType] = useState<Maybe<ActionType>>(null);
  const { setIsInnerModalOpen } = useContext(InnerModalContext);

  const handleInputFieldChange = (type: ActionType) => {
    if (inputFieldType === type) {
      setInputFieldType(null);
    } else {
      setInputFieldType(type);
    }
  };

  const renderInputField = () => {
    if (!inputFieldType) {
      return;
    }

    const handleUploadFileChange = async (fileList: FileList) => {
      switch (inputFieldType) {
        case ActionTypes.Image:
          onImagesChange && onImagesChange(fileList);
          setInputFieldType(null);
          break;
        case ActionTypes.File:
          onFilesChange && onFilesChange(fileList);
          setInputFieldType(null);
          break;
      }
    };

    const handleAddLink = (link: string) => {
      const selection = window.getSelection();
      if (textareaRef && selection?.toString()) {
        let a = document.createElement('a');
        a.href = link;
        a.title = selection.toString();
        selection.getRangeAt(0).surroundContents(a);
        setText(textareaRef.current?.innerHTML ?? '');
        setLink('');
        setInputFieldType(null);
      }
    };

    switch (inputFieldType) {
      case ActionTypes.Image:
      case ActionTypes.File:
        return (
          <UploadFile
            onChange={handleUploadFileChange}
            acceptExtensions={acceptExtensions[inputFieldType]}
          />
        );
      case ActionTypes.Link:
        return (
          <div className="link-field-container p-3 mt-2 rounded d-flex gap-3">
            <input
              className="form-control"
              placeholder="https://www.link.com"
              value={link}
              onChange={({ target: { value } }) => setLink(value)}
            />
            <Button
              label={t('save')}
              size="sm"
              variant="primary"
              onClick={() => handleAddLink(link)}
              disabled={link.length === 0}
            />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const handleSelectEmoji = (name: string) => {
    const currentRef = textareaRef?.current;
    if (currentRef) {
      const span = document.createElement('span');
      span.innerHTML = name;
      span.classList.add('emoji-container');
      if (range) {
        range.insertNode(span);
        range.collapse(false);
      } else {
        currentRef.innerHTML += span.innerHTML;
      }
      setText(currentRef.innerHTML);
    }
    setIsInnerModalOpen(false);
  };

  return (
    <div className="position-relative">
      <React.Suspense fallback={<Spinner animation="border" size="sm" />}>
        <div className="mb-2">{renderInputField()}</div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            {!hideSecondaryActions && (
              <>
                <EmojiPicker
                  onEmojiSelect={handleSelectEmoji}
                  onToggle={setIsInnerModalOpen}
                />
                <FlairIcon
                  icon={
                    inputFieldType === ActionTypes.Image
                      ? 'image-solid'
                      : 'image-outline'
                  }
                  size="lg"
                  onClick={() => handleInputFieldChange(ActionTypes.Image)}
                  color={Theme.color.blue3}
                />
                <FlairIcon
                  icon={
                    inputFieldType === ActionTypes.File
                      ? 'document-solid'
                      : 'document-outline'
                  }
                  onClick={() => handleInputFieldChange(ActionTypes.File)}
                  color={Theme.color.blue3}
                />
                {/*TODO:: will be added later*/}
                {/*<FlairIcon*/}
                {/*  icon="link-outline"*/}
                {/*  size="lg"*/}
                {/*  onClick={() => handleInputFieldChange(ActionTypes.Link)}*/}
                {/*  color={Theme.color.blue3}*/}
                {/*/>*/}
              </>
            )}
          </div>
          <div>
            {!hideMainActions && (
              <div className="d-flex align-items-center">
                <div className="text-secondary pe-3">
                  {getTextWithoutHtmlTags(text).length}
                </div>
                <Button
                  label={t('cancel')}
                  variant="link"
                  size="sm"
                  onClick={onCancel}
                  disabled={loading}
                />
                <Button
                  label={t('post')}
                  size="sm"
                  onClick={onSubmit}
                  isProcessing={loading}
                  disabled={isSubmitDisabled}
                />
              </div>
            )}
          </div>
        </div>
      </React.Suspense>
    </div>
  );
};

export default Actions;
