import React, { useCallback } from 'react';
import { useRemoveEmployeeSkill } from '../common/useEmployeeSkillsMutations';
import {
  RemoveEmployeeSkillDialog,
  Props as RemoveEmployeeSkillDialogProps,
} from './RemoveEmployeeSkillDialog';

type Props = Omit<
  RemoveEmployeeSkillDialogProps,
  'onSkillRemoved' | 'show' | 'inProgress' | 'skillIdToRemove'
> & {
  employeeId: string;
  skillIdToRemove: string | null;
};

export const RemoveEmployeeSkillDialogConnected: React.FC<Props> = ({
  employeeId,
  skillIdToRemove,
  ...props
}) => {
  const { onClose } = props;

  const { removeEmployeeSkill, inProgress: removeEmployeeSkillInProgress } =
    useRemoveEmployeeSkill();

  const handleSkillRemoved = useCallback(
    (id: string) => {
      removeEmployeeSkill({
        id,
        employeeId,
      }).then(() => {
        onClose();
      });
    },
    [removeEmployeeSkill, employeeId, onClose],
  );

  if (!skillIdToRemove) {
    return null;
  }
  return (
    <RemoveEmployeeSkillDialog
      {...props}
      show={true}
      skillIdToRemove={skillIdToRemove}
      inProgress={removeEmployeeSkillInProgress}
      onSkillRemoved={handleSkillRemoved}
    />
  );
};
