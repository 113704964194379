import styled from '@emotion/styled';
import classNames from 'classnames';
import * as React from 'react';
import { TimeSince } from '../../../../components/TimeSince';

type ActivityProps = {
  iconComponent: React.ReactNode;
  text: string;
  date: Date;
};

const Activity: React.FC<ActivityProps> = ({ iconComponent, text, date }) => {
  return (
    <Container>
      <IconContainer>{iconComponent}</IconContainer>
      <LabelContainer>
        <span>{text}</span>
        <span className="text-secondary">
          <TimeSince date={date} />
        </span>
      </LabelContainer>
    </Container>
  );
};

type DivProps = React.HTMLAttributes<HTMLDivElement>;

const Container = ({ className, ...rest }: DivProps) => (
  <div
    className={classNames('d-flex', 'align-items-center', 'gap-2', className)}
    {...rest}
  />
);

const LabelContainer = ({ className, ...rest }: DivProps) => (
  <div
    className={classNames(
      'fs-5',
      'd-flex',
      'gap-2',
      'align-items-center',
      className,
    )}
    {...rest}
  />
);

const IconContainer = styled.div({
  minWidth: '16px',
  textAlign: 'center',
});

export default Activity;
