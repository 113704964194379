import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { SelectionBottomActionButton } from '../../../components/SelectionBottomPanel/SelectionBottomActionButton';

type TableBottomBannerProps = {
  onDelete: () => void;
  onMove: () => void;
  isManager: boolean;
  onDownload?: () => void;
};

const i18Prefix = 'documents2.personal.massActions';

export const TableBottomBanner: React.FC<TableBottomBannerProps> = ({
  onDelete,
  onMove,
  isManager,
}) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <>
      {/*<SelectionBottomActionButton*/}
      {/*  label={t(`buttons.download`)}*/}
      {/*  onClick={onDownload}*/}
      {/*/>*/}
      <SelectionBottomActionButton
        label={t(`buttons.moveTo`)}
        onClick={onMove}
      />
      {!isManager && (
        <SelectionBottomActionButton
          label={t(`buttons.delete`)}
          onClick={onDelete}
        />
      )}
    </>
  );
};
