import React from 'react';
import type { StepProp } from '../../../../../components/Step';
import { FieldValues } from 'react-hook-form';

export type AdditionalValues = {
  inventoryName?: string;
  isNewInventory?: boolean;
  locationName?: string;
  responsibleEmployeeName?: string;
};

export type TContextProps = {
  steps: StepProp[];
  isExpanded: boolean;
  maxStep: number;
  currentStep: number;
  onCancel: () => void;
  onChange: (data: FieldValues) => void;
  onChangeStep: (step: number) => void;
  value: FieldValues | null;
  additionalValues: AdditionalValues | null;
  onChangeAdditionalValues: (data: AdditionalValues) => void;
};

export const InventoryModalContext = React.createContext<TContextProps>({
  steps: [],
  isExpanded: false,
  maxStep: 0,
  currentStep: 0,
  onCancel: () => {},
  onChange: () => {},
  onChangeStep: () => {},
  onChangeAdditionalValues: () => {},
  additionalValues: null,
  value: null,
});
