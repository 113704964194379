import React from 'react';
import { routes as r } from '../../routes';
import { WorkflowItemsForAll } from './WorkflowItems/WorkflowItemsForAll';
import { WorkflowItemsForMe } from './WorkflowItems/WorkflowItemsForMe';
import { WorkflowItemsForOthers } from './WorkflowItems/WorkflowItemsForOthers';
import { WorkflowsForAll } from './WorkflowsTable/WorkflowsForAll';
import { WorkflowsForMe } from './WorkflowsTable/WorkflowsForMe';
import { WorkflowsForOthers } from './WorkflowsTable/WorkflowsForOthers';

export const routes = () => ({
  me: {
    route: r.workflowsMeWorkflows.route,
    render: () => <WorkflowsForMe />,
  },
  others: {
    route: r.workflowsOthersWorkflows.route,
    render: () => <WorkflowsForOthers />,
  },
  all: {
    route: r.workflowsAllWorkflows.route,
    render: () => <WorkflowsForAll />,
  },
  allAllItems: {
    route: r.workflowsAllWorkflowItemsAll.route,
    render: () => <WorkflowItemsForAll />,
  },
  meAllItems: {
    route: r.workflowsMeWorkflowItemsAll.route,
    render: () => <WorkflowItemsForMe />,
  },
  otherAllItems: {
    route: r.workflowsOthersWorkflowItemsAll.route,
    render: () => <WorkflowItemsForOthers />,
  },
});
