import React, { ReactElement, forwardRef } from 'react';
import {
  OverlayTrigger,
  Popover as BootstrapPopover,
  OverlayTriggerProps,
} from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';
import './popover.css';
import FlairIcon from '../../atomic/atoms/FlairIcon';
import { Theme } from '../../theme';

type Props = Omit<OverlayTriggerProps, 'overlay'> & {
  headerText: string;
  show: boolean;
  placement?: Placement;
  onToggle?: (nextShow: boolean) => void;
  children?: ReactElement;
  popoverContent: ReactElement;
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
};

export const Popover: React.FC<Props> = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      show,
      onToggle,
      headerText,
      children,
      popoverContent,
      placement = 'auto',
      trigger = 'click',
      className,
      ...rest
    } = props;

    const popover = (
      <BootstrapPopover ref={ref} className={`flair-popover ${className}`}>
        <BootstrapPopover.Header className="flair-popover_header">
          <div>{headerText}</div>
          <FlairIcon
            icon="close-outline"
            onClick={() => onToggle && onToggle(false)}
          />
        </BootstrapPopover.Header>
        <BootstrapPopover.Body>{popoverContent}</BootstrapPopover.Body>
      </BootstrapPopover>
    );

    return (
      <OverlayTrigger
        {...rest}
        show={show}
        onToggle={onToggle}
        overlay={popover}
        trigger={trigger}
        placement={placement}>
        {children ? (
          children
        ) : (
          <FlairIcon
            size="sm"
            icon="information-circle-outline"
            role="button"
            color={Theme.color.paper1}
          />
        )}
      </OverlayTrigger>
    );
  },
);
