import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import PageHeader from '../../../components/PageHeader';
import { i18DocumentsPrefix } from '../common';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

type Props = {
  isManager?: boolean;
};

export const SupportHeader: React.FC<Props> = ({ isManager = false }) => {
  const t = useNamespacedTranslation(i18DocumentsPrefix);
  useFlairBreadcrumbHook([{ label: t('title') }]);

  return <PageHeader title={t('title')}></PageHeader>;
};
