import { useEffect, useState, useCallback } from 'react';
import { useUserInfo } from '../context/UserInfo';

declare global {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-definitions */
  /* eslint-disable-next-line no-unused-vars */
  interface WindowEventMap {
    'use-local-storage': CustomEvent;
  }
}

const USE_LOCAL_STORAGE_EVENT = 'use-local-storage';

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, typeof setValue] => {
  const user = useUserInfo();
  const localStorageKey = getUserStorageKey(user.id, key);

  const getValueToStore = (value: T) => {
    return value instanceof Function ? value(storedValue) : value;
  };

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(localStorageKey);
      if (!item) {
        const valueToStore = getValueToStore(initialValue);
        localStorage.setItem(localStorageKey, JSON.stringify(valueToStore));
      }
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error in useState in useLocalStorage.ts`, error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((prevState: T) => T)): void => {
      try {
        setStoredValue((prevValue) => {
          let valueToStore: T;
          if (value instanceof Function) {
            valueToStore = value(prevValue);
          } else {
            valueToStore = value;
          }
          localStorage.setItem(localStorageKey, JSON.stringify(valueToStore));
          window.dispatchEvent(
            new CustomEvent(USE_LOCAL_STORAGE_EVENT, {
              detail: {
                key,
                value: valueToStore,
              },
            }),
          );
          return valueToStore;
        });
      } catch (error) {
        console.error(`Error in setValue in useLocalStorage.ts`, error);
      }
    },
    [key, setStoredValue, localStorageKey],
  );

  useEffect(() => {
    const handler = ({ detail }: CustomEvent) => {
      if (key !== detail.key) {
        return;
      }
      setStoredValue(detail.value);
    };
    window.addEventListener(USE_LOCAL_STORAGE_EVENT, handler);
    return () => window.removeEventListener(USE_LOCAL_STORAGE_EVENT, handler);
  }, [key, setStoredValue]);

  return [storedValue, setValue];
};

export const getUserStorageKey = (userId: string, key: string) =>
  `${userId}_${key}`;
