import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/button';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../utils/dateUtils';
import LightList from '../../../components/LightList';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import { routes } from '../../../routes';
import { Employee, Expense } from '../../../__generated__/graphql';
import { SectionTitle } from '../Heading';

type EmployeeProps = Pick<Employee, 'Name'>;
type ExpenseProps = Pick<Expense, 'Id' | 'flair__Expense_Date__c'> & {
  employee: EmployeeProps;
};

type Props = {
  expenses: ReadonlyArray<ExpenseProps>;
};

const PendingExpenses: React.FC<Props> = ({ expenses }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [myTeamReportMode] = useManagerReportsMode('myteam');
  const [targetReportMode, setTargetReportsMode] =
    useManagerReportsMode('expenses');
  const items = expenses.map((item) => ({
    key: item.Id,
    name: item.employee.Name,
    value: (
      <FormattedDate
        day={parseDate(item.flair__Expense_Date__c)}
        format="short"
      />
    ),
  }));

  const count = expenses.length;

  return (
    <Row>
      <Col xs={12} className="mb-1">
        <SectionTitle>
          {t('home.pendingApprovalCard.pendingExpenses.title', {
            count: count,
          })}
        </SectionTitle>
      </Col>
      <Col>
        <LightList
          items={items}
          actionLink={
            <Button
              variant="link"
              size="sm"
              className="p-0 m-0"
              label={t('home.pendingApprovalCard.pendingExpenses.actionLink')}
              onClick={() => {
                // set manager mode to the same as in myTeam page
                if (myTeamReportMode !== targetReportMode) {
                  setTargetReportsMode(myTeamReportMode);
                }
                history.push(routes.expenses.route.create({}));
              }}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default PendingExpenses;
