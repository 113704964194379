import styled from '@emotion/styled';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CancelButton from '../../../../../components/form/CancelButton';
import { Form } from '../../../../../components/form/Form';
import SubmitButton from '../../../../../components/form/SubmitButton';

type Props = {
  show: boolean;
  skillName: string;
  handleClose: () => void;
  onSubmit: () => void;
};

const StyledParagraph = styled.p({
  fontWeight: 600,
});

export const DeleteSkillDialog: React.FC<Props> = ({
  show,
  skillName,
  handleClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('skills.deleteSkill.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('skills.deleteSkill.description', { skillName: skillName })}</p>
          <StyledParagraph>
            {t('skills.deleteSkill.confirmation')}
          </StyledParagraph>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleClose}>
            {t('skills.deleteSkill.cancel')}
          </CancelButton>
          <SubmitButton>{t('skills.deleteSkill.save')}</SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
