import React from 'react';
import { PendingRequests } from './PendingRequests/PendingRequests';
import { routes as r } from '../../routes';
import { AbsencesRequests } from './AbsenceRequests/AbsencesRequests';

export const routes = () => ({
  pending: {
    route: r.pendingAbsenceRequests.route,
    render: () => <PendingRequests />,
  },
  all: {
    route: r.allAbsenceRequests.route,
    render: () => <AbsencesRequests tab="all" />,
  },
});
