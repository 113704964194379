import React from 'react';
import { TimeSheet } from '../types';
import Button from '../../../../../components/button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../../../../../utils/maybe';

import { generatePath } from 'react-router';
import TimesheetDatesInfo from './TimesheetDatesInfo';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  timeSheet: TimeSheet;
  goToAllTimesheetsAction?: (timeSheet: TimeSheet) => void;
};

const TimeSheetNavigation: React.FC<Props> = ({
  timeSheet,
  goToAllTimesheetsAction,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { params, path } = useRouteMatch();

  const changeTimeSheetTo = (timeSheetId: Maybe<string>) => {
    if (timeSheetId) {
      history.push(generatePath(path, { ...params, timeSheetId }));
    }
  };
  const handleGoToNextTimesheet = () => {
    changeTimeSheetTo(timeSheet.nextTimeSheet?.Id);
  };
  const handleGoToPreviousTimesheet = () => {
    changeTimeSheetTo(timeSheet.previousTimeSheet?.Id);
  };
  const handleGoToCurrentTimesheet = () => {
    changeTimeSheetTo(timeSheet.employee.currentTimeSheet?.Id);
  };
  const handleViewAllTimesheets = () => {
    goToAllTimesheetsAction && goToAllTimesheetsAction(timeSheet);
  };

  return (
    <>
      <div className={'d-flex align-items-center'}>
        <TimesheetDatesInfo classes="d-none d-lg-flex" timeSheet={timeSheet} />
        <div>
          <button
            disabled={!timeSheet.previousTimeSheet}
            className="ms-1 ms-md-3 bg-transparent border-0 fw-bold "
            onClick={handleGoToPreviousTimesheet}>
            <FlairIcon icon="chevron-back-outline" className="me-2" />
          </button>

          <button
            disabled={!timeSheet.nextTimeSheet}
            className="ms-1 ms-md-3 bg-transparent border-0 fw-bold"
            onClick={handleGoToNextTimesheet}>
            <FlairIcon icon="chevron-forward-outline" className="me-2" />
          </button>
        </div>
        {!!timeSheet.employee.currentTimeSheet && !!goToAllTimesheetsAction && (
          <Button
            className="ms-1 ms-md-3 btn-sm d-none d-md-block"
            label={t('timeTracking.timeSheet.actionsPanel.currentTimesheet')}
            onClick={handleGoToCurrentTimesheet}
            variant="outline-primary"
          />
        )}
        <Button
          className="ms-1 ms-md-3 border-0 btn-sm"
          label={t('timeTracking.timeSheet.actionsPanel.viewAllTimesheets')}
          onClick={handleViewAllTimesheets}
          variant="outline-primary"
        />
      </div>
    </>
  );
};

export default TimeSheetNavigation;
