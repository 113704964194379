import { useCallback, useMemo, useState } from 'react';
import {
  ApproveTimeSheetAction,
  useTimeSheetTimeEntryChangeRequestsLazyQuery,
} from '../../__generated__/graphql';
import { useApproveTimeSheets } from './useApproveTimeSheets';

export type ApproveTimeSheetsWithModalProps = {
  approvingIds: string[];
  approveTimeSheets: (timeSheetIds: string[]) => Promise<void>;
  approveModalVisible: boolean;
  onApproveModalSubmit: (action: ApproveTimeSheetAction) => Promise<void>;
  onApproveModalClose: () => void;
};

export const useApproveTimeSheetsWithModal =
  (): ApproveTimeSheetsWithModalProps => {
    const [
      getTimeEntryChangeRequests,
      { loading: getTimeEntryChangeRequestsInProgress },
    ] = useTimeSheetTimeEntryChangeRequestsLazyQuery({
      fetchPolicy: 'network-only',
    });
    const [approveModalVisible, setApproveModalVisible] = useState(false);
    const [lastTimeSheetIds, setLastTimeSheetIds] = useState<string[]>([]);

    const {
      approvingIds: approvingIdsBase,
      approveTimeSheets: approveTimeSheetsBase,
    } = useApproveTimeSheets();

    const getTimeEntryChangeRequestIds = useMemo(() => {
      return getTimeEntryChangeRequestsInProgress ? lastTimeSheetIds : [];
    }, [getTimeEntryChangeRequestsInProgress, lastTimeSheetIds]);

    const approvingIds = useMemo(() => {
      return [...approvingIdsBase, ...getTimeEntryChangeRequestIds];
    }, [approvingIdsBase, getTimeEntryChangeRequestIds]);

    const hasAnyTimeEntryChangeRequests = useCallback(
      async (ids: string[]) => {
        const { data: timeEntryChangeRequestsData } =
          await getTimeEntryChangeRequests({
            variables: {
              ids,
            },
          });
        return (
          timeEntryChangeRequestsData?.timeSheets.some(
            (x) => x.timeEntryChangeRequests.length > 0,
          ) ?? false
        );
      },
      [getTimeEntryChangeRequests],
    );

    const approveTimeSheets = useCallback(
      async (timeSheetIds: string[]): Promise<void> => {
        setLastTimeSheetIds(timeSheetIds);
        const hasChangeRequests = await hasAnyTimeEntryChangeRequests(
          timeSheetIds,
        );
        if (!hasChangeRequests) {
          return approveTimeSheetsBase(
            timeSheetIds,
            ApproveTimeSheetAction.RejectAllChangeRequests,
          );
        } else {
          setApproveModalVisible(true);
        }
      },
      [
        approveTimeSheetsBase,
        hasAnyTimeEntryChangeRequests,
        setApproveModalVisible,
        setLastTimeSheetIds,
      ],
    );

    const onApproveModalSubmit = useCallback(
      (action: ApproveTimeSheetAction) => {
        setApproveModalVisible(false);
        return approveTimeSheetsBase(lastTimeSheetIds, action);
      },
      [setApproveModalVisible, lastTimeSheetIds, approveTimeSheetsBase],
    );

    const onApproveModalClose = useCallback(() => {
      setApproveModalVisible(false);
    }, [setApproveModalVisible]);

    return {
      approvingIds,
      approveTimeSheets,
      onApproveModalSubmit,
      approveModalVisible,
      onApproveModalClose,
    };
  };
