export type Skill = IdName & {
  isRateable: boolean;
  createdDate: string;
};

export type EmployeeSkillAssociation = {
  id: string;
  name: string;
  skillId: string;
  employeeId: string;
  isTop: boolean;
  isRateable: boolean;
  level: SkillLevel | undefined;
};

export type Employee = {
  id: string;
  name: string;
  initials: string;
  avatarUrl?: string | null;
};

export type IdName = {
  id: string;
  name: string;
};

export type SkillLevel = null | 1 | 2 | 3 | 4 | 5;
export const allSkillLevels: SkillLevel[] = [null, 1, 2, 3, 4, 5];
export const isSkillLevel = (
  value: number | null | undefined,
): value is SkillLevel => {
  if (value === undefined) {
    return false;
  }
  if (value === null) {
    return true;
  }
  return value >= 1 && value <= 5;
};

export const MAX_TOP_SKILLS_NUMBER = 7;
