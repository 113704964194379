import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading: React.FC = () => (
  <Spinner
    animation="border"
    style={{
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    }}
  />
);

export default Loading;
