import React from 'react';
import { Table, TableProps } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { times } from 'lodash';

type Props = TableProps & {
  columns?: number;
  rows?: number;
  className?: string;
};

const SkeletonColumn: React.FC = () => (
  <td>
    <Skeleton />
  </td>
);

const SkeletonRow: React.FC<{ columns: number }> = ({ columns }) => (
  <tr>
    {times(columns, String).map((i) => (
      <SkeletonColumn key={`column-${i}`} />
    ))}
  </tr>
);

export const SkeletonTable: React.FC<Props> = ({
  columns = 1,
  rows = 1,
  className,
  ...tableProps
}) => (
  <Table className={className} {...tableProps}>
    <tbody>
      {times(rows, String).map((i) => (
        <SkeletonRow key={`row-${i}`} columns={columns} />
      ))}
    </tbody>
  </Table>
);
