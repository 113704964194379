import i18next from 'i18next';
import { Theme } from '../../../../../theme';
import { ShiftInfo } from '../ShiftsDaily/dailyShiftsTypes';
import {
  OpenShiftInfo,
  OpenShiftNotAvailableReason,
  ShiftBlockColorsInfo,
} from './types';
import { FlairIconName } from '../../../../../atomic/atoms/FlairIcon';

export const getShiftBlockColorsInfo = (
  shift: ShiftInfo | OpenShiftInfo,
  isCurrentEmployee?: boolean,
): ShiftBlockColorsInfo => {
  const border = (color: string) => `1px solid ${color}`;
  const linearGradientColor = (color1: string, color2: string) =>
    `repeating-linear-gradient(-45deg,${color1}, ${color1} 20px, ${color2} 20px, ${color2} 40px)`;

  switch (shift.type) {
    case 'open-shift': {
      switch (shift.state) {
        case 'available':
        case 'not-available': {
          return {
            background: Theme.shift.open.available.bg,
            onHoverBackground: Theme.shift.open.available.hoverBg,
            border: border(Theme.shift.self.border),
          };
        }
        case 'requested':
        case 'confirmed': {
          return {
            background: linearGradientColor(
              Theme.shift.open.available.bg,
              Theme.shift.open.requested.bg,
            ),
            onHoverBackground: linearGradientColor(
              Theme.shift.open.available.hoverBg,
              Theme.shift.open.requested.hoverBg,
            ),
            border: border(Theme.shift.self.border),
          };
        }
      }
      break;
    }
    case 'employee-shift': {
      if (isCurrentEmployee) {
        return {
          background: Theme.shift.self.bg,
          onHoverBackground: Theme.shift.self.border,
          border: border(Theme.shift.self.border),
        };
      }

      return {
        background: Theme.shift.other.bg,
        onHoverBackground: Theme.shift.other.border,
        border: border(Theme.shift.other.border),
      };
    }
  }
};

export const notAvailableReasonText = (
  notAvailableReason: OpenShiftNotAvailableReason,
): string => {
  if (notAvailableReason === null) {
    return '';
  }
  switch (notAvailableReason) {
    case 'no-free-slots':
      return i18next.t('shiftPlanner.openShifts.alreadyTaken');
    case 'employee-has-no-time':
      return i18next.t('shiftPlanner.openShifts.employeeHasNoTime');
    case 'past-shift':
      return i18next.t('shiftPlanner.openShifts.pastOpenShift');
    case 'overlapped':
      return i18next.t('shiftPlanner.openShifts.shiftIsOverlapping');
  }
};

export const getNotAvailableIcon = (
  notAvailableReason: OpenShiftNotAvailableReason,
): FlairIconName => {
  return notAvailableReason === 'past-shift'
    ? 'information-circle-outline'
    : 'alert-circle-outline';
};
