import React from 'react';
import { param, route } from '../../utils/router';
import RedirectToHome from './components/RedirectToHome';
import RedirectToTimeTracking from './components/RedirectToTimeTracking';
import { managerRoutes } from './manager/routes';
import AbsenceApprovalRequestToMe from './pages/AbsenceApprovalRequestToMe';
import { Absences } from './pages/Absences';
import AbsencesCalendar from './pages/Absences/AbsencesCalendar';
import TeamCalendar from './pages/Absences/TeamCalendar';
import EditPersonalData from './pages/EditPersonalData';
import PendigTimeEntryChangeRequests from './pages/PendigTimeEntryChangeRequests';
import { PerformanceReview } from './pages/PerformanceReview';
import { PastPerformanceReviewPage } from './pages/PerformanceReview/Overview/PastPerformanceReviewPage';
import { SelfPerformanceEvaluation } from './pages/PerformanceReview/SelfPerformanceEvaluation';
import PersonalData from './pages/PersonalData';
import { SlackConnect } from './pages/SlackConnect';
import TimeSheet from './pages/TimeSheet';
import { TimeTracking } from './pages/TimeTracking';
import { recruitmentRoutes } from './recruitment/routes';
import { EmployeeEvaluation } from './pages/PerformanceReview/EmployeePerformanceReview/EmployeeEvaluation';
import { EmployeePerformanceReviewMeeting } from './pages/PerformanceReview/EmployeePerformanceReview/EmployeePerformanceReviewMeeting';
import { PastEmployeeFeedbackPage } from './pages/PerformanceReview/EmployeePerformanceReview/PastEmployeeFeedbackPage';
import ProjectsTimesheets from './pages/Projects/ProjectsTimesheets';
import ProjectsOverview, {
  ProjectsManagerOverview,
} from './pages/Projects/ProjectsOverview';
import ProjectsControllingTimeSheets from './pages/Projects/ProjectsControllingTimeSheets';

import Evaluation from './pages/EmployeeEvaluation';

import {
  WorkflowItemsForWorkflow,
  WorkflowsMainPage,
  WorkflowsRouter,
} from './pages/Workflows';
import OrgChart from './pages/People/OrgChart';
import { Redirect } from '../../Router';
import Directory from './pages/People/Directory';
import Announcements from './pages/People/Announcements';
import Goals from './pages/PerformanceReview/Goals2';
import { SkillsEmployeePage } from './pages/Skills';
import Settings from './pages/Settings';
import MyExpenses from './pages/Expenses/MyExpenses';
import Shifts from './pages/Shifts';
import { CelebrationsEmployeePage } from './pages/Celebrations';
import MyCertificates from './pages/Certificates/MyCertificates';
import MyAbsences from './pages/Absences/MyAbsences';
import { ManagerRole } from './__generated__/graphql';
import EngagementSurveysListPage from './pages/EngagementSurvey/EngagementSurveysListPage';
import EngagementSurveyResponsePage from './pages/EngagementSurvey/EngagementSurveyResponsePage';
import { SlackConnectEmployee } from './pages/SlackConnectEmployee';
import { OrganizationInfoPage } from './customerSupport/OrganizationInfoPage';
import { LoginForCustomerSupport } from './customerSupport/LoginForCustomerSupport';
import { DocumentsPage } from './pages/Documents2';
import { TimeSheetsControllingPage } from './manager/TimeSheetsControlling';
import { TimeSheetsControllingWeekPage } from './manager/TimeSheetsControlling/TimeSheetsControllingWeekPage';
import { SlackAuthorizeEmployee } from './pages/SlackAuthorizeEmployee';
import { SlackDebug } from './pages/SlackDebug/SlackDebug';
import LoomVideoPreviewPage from './pages/Loom';
import { SupportPage } from './pages/Support/SupportPage';
import Dashboard from './pages/Dashboard';
import MyAbsenceDetail from './pages/Absences/MyAbsences/MyAbsenceDetail';
import { InventoryPage } from './pages/MyData/InventoryPage';
import { UserInfo } from './context/UserInfo/types';
import { DeepLinkComment } from './pages/DeepLinks';

const renderIfActive =
  (node: React.ReactNode) =>
  ({ isActive }: UserInfo) =>
    isActive ? node : <RedirectToHome />;

const renderIfDidNotLeaveCompany =
  (node: React.ReactNode) =>
  ({ hasLeftCompany }: UserInfo) =>
    !hasLeftCompany ? node : <RedirectToHome />;

const renderForControlling =
  (node: React.ReactNode) =>
  ({ isManagerModeAvailable, isActive }: UserInfo) => {
    if (!isActive) {
      return <RedirectToHome />;
    }

    if (!isManagerModeAvailable) {
      return <RedirectToTimeTracking />;
    }

    return node;
  };

const workflowRoutes = {
  // Back compatibility
  workflowItemsBackCompartibility: {
    route: route('performance-review', 'workflows', param('workflowId')),
    render: () => <WorkflowItemsForWorkflow mode="forAll" />,
  },
  // this was referenced from SF
  workflowsBackCompartibility: {
    route: route('performance-review', 'workflows'),
    render: () => <Redirect to={workflowRoutes.workflowsAllWorkflows.route} />,
  },
  workflowsMeItems: {
    route: route('workflows', 'me', param('workflowId'), 'items'),
    render: () => <WorkflowItemsForWorkflow mode="forMe" />,
  },
  workflowsOthersItems: {
    route: route('workflows', 'others', param('workflowId'), 'items'),
    render: () => <WorkflowItemsForWorkflow mode="forOthers" />,
  },
  workflowsMeWorkflows: {
    route: route('workflows', 'me', 'workflows'),
    render: () => <WorkflowsMainPage />,
  },
  workflowsOthersWorkflowItemsAll: {
    route: route('workflows', 'others', 'allitems'),
    render: () => <WorkflowsMainPage />,
  },
  workflowsOthersWorkflows: {
    route: route('workflows', 'others', 'workflows'),
    render: () => <WorkflowsMainPage />,
  },
  workflowsMeWorkflowItemsAll: {
    route: route('workflows', 'me', 'allitems'),
    render: () => <WorkflowsMainPage />,
  },
  workflowsAllWorkflowItems: {
    route: route('workflows', 'all', param('workflowId'), 'items'),
    render: () => <WorkflowItemsForWorkflow mode="forAll" />,
  },
  workflowsAllWorkflows: {
    route: route('workflows', 'all', 'workflows'),
    render: () => <WorkflowsMainPage />,
  },
  workflowsAllWorkflowItemsAll: {
    route: route('workflows', 'all', 'allitems'),
    render: () => <WorkflowsMainPage />,
  },
  workflowsMe: {
    route: route('workflows', 'me'),
    render: () => <WorkflowsRouter />,
  },
  workflowsOthers: {
    route: route('workflows', 'others'),
    render: () => <WorkflowsRouter />,
  },
  workflowsAll: {
    route: route('workflows', 'all'),
    render: () => <WorkflowsRouter />,
  },
};

const celebrationRoutes = {
  celebrationsFeed: {
    route: route('celebrations', 'feed'),
    render: () => <CelebrationsEmployeePage />,
  },
  celebrationsReceivedDetails: {
    route: route('celebrations', 'received', param('celebrationId')),
    render: () => <CelebrationsEmployeePage />,
  },
  celebrationsReceived: {
    route: route('celebrations', 'received'),
    render: () => <CelebrationsEmployeePage />,
  },
  celebrationsSent: {
    route: route('celebrations', 'sent'),
    render: () => <CelebrationsEmployeePage />,
  },
};

const slackConnectRoutes = {
  slackConnectEmployee: {
    route: route('connect', 'slack', 'employee', param('slackUser')),
    render: (_userInfo: UserInfo) => <SlackConnectEmployee />,
  },
  slackAuthorizeEmployee: {
    route: route('connect', 'slack', 'employee'),
    render: (_userInfo: UserInfo) => <SlackAuthorizeEmployee />,
  },
  slackConnect: {
    route: route('connect', 'slack'),
    render: (_userInfo: UserInfo) => <SlackConnect />,
  },
  slackDebug: {
    route: route('slack', 'debug'),
    render: (_userInfo: UserInfo) => <SlackDebug />,
  },
};

const customerSupportRoutes = {
  organizationInfo: {
    // todo: add customer-support route and fix notion
    route: route('organization-info'),
    render: (_userInfo: UserInfo) => <OrganizationInfoPage />,
  },
  loginAsEmployee: {
    route: route('customerSupport/login'),
    render: (_userInfo: UserInfo) => <LoginForCustomerSupport />,
  },
};

const documentsRoutes = {
  documentsPersonal: {
    route: route('documents', 'personal'),
    render: () => <DocumentsPage />,
  },
  documentsCompany: {
    route: route('documents', 'company'),
    render: () => <DocumentsPage />,
  },
};

const supportRoutes = {
  tickets: {
    route: route('support', 'tickets'),
    render: () => <SupportPage />,
  },
};

const myDataRoutes = {
  inventory: {
    route: route('mydata', 'inventory'),
    render: (_userInfo: UserInfo) => <InventoryPage />,
  },
};

const timeSheetControllingRoutes = {
  timeSheetsControllingPending: {
    route: route('timetracking', 'controlling', 'pending'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.TimeEntryApprover],
    render: renderForControlling(<TimeSheetsControllingPage />),
  },
  timeSheetsControllingWeek: {
    route: route('timetracking', 'controlling', 'week'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.TimeEntryApprover],
    render: renderForControlling(<TimeSheetsControllingWeekPage />),
  },
  timeSheet: {
    route: route('timetracking', 'controlling', param('timeSheetId')),
    render: renderForControlling(<TimeSheet />),
  },
  // Back compatibility
  timeSheetsControllingOld: {
    route: route('timetracking', 'controlling'),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.TimeEntryApprover],
    render: renderForControlling(<TimeSheetsControllingWeekPage />),
  },
};

const timeTrackingRoutes = {
  timeTracking: {
    route: route('timetracking', 'overview'),
    render: renderIfActive(<TimeTracking />),
  },
  allTimeSheets: {
    route: route('timetracking', 'alltimesheets'),
    render: renderIfActive(<TimeTracking />),
  },
};

const deepLinksRoutes = {
  commentDeepLink: {
    route: route('show', 'comment', param('commentId')),
    render: () => <DeepLinkComment />,
  },
};

export const routes = {
  ...myDataRoutes,
  ...workflowRoutes,
  ...celebrationRoutes,
  ...slackConnectRoutes,
  ...customerSupportRoutes,
  ...documentsRoutes,
  ...timeSheetControllingRoutes,
  ...timeTrackingRoutes,
  ...supportRoutes,
  ...deepLinksRoutes,
  home: {
    route: route(''),
    render: (_userInfo: UserInfo) => <Dashboard />,
  },

  general: {
    route: route('settings', 'general'),
    render: (_userInfo: UserInfo) => <Settings />,
  },
  notificationSettings: {
    route: route('settings', 'notification-settings'),
    render: (_userInfo: UserInfo) => <Settings />,
  },
  customerSupport: {
    route: route('settings', 'customer-support'),
    render: (_userInfo: UserInfo) => <Settings />,
  },
  personalData: {
    route: route('mydata', 'personal-data'),
    render: (_userInfo: UserInfo) => <PersonalData />,
  },
  myData: {
    route: route('mydata'),
    render: (_userInfo: UserInfo) => <PersonalData />,
  },
  orgChart: {
    route: route('people', 'org-chart'),
    render: (_userInfo: UserInfo) => <OrgChart />,
  },
  peopleDirectory: {
    route: route('people', 'directory'),
    render: (_userInfo: UserInfo) => <Directory />,
  },
  announcements: {
    route: route('people', 'announcements'),
    render: (_userInfo: UserInfo) => <Announcements />,
  },
  allShifts: {
    route: route('shifts', 'all-shifts'),
    render: renderIfActive(<Shifts />),
  },
  personalShifts: {
    route: route('shifts', 'my-shifts'),
    render: renderIfActive(<Shifts />),
  },
  editPersonalData: {
    route: route('data', 'edit', param('section')),
    render: renderIfDidNotLeaveCompany(<EditPersonalData />),
  },
  employeeEvaluation: {
    route: route('evaluation', param('employeeEvaluationId')),
    render: (_userInfo: UserInfo) => <Evaluation />,
  },
  absencesCalendar: {
    route: route('absences', 'calendar'),
    render: renderIfActive(<AbsencesCalendar />),
  },
  teamCalendar: {
    route: route('absences', 'team'),
    render: renderIfActive(<TeamCalendar />),
  },
  absenceRequestToMe: {
    route: route('absences', 'requests', param('absenceRequestId')),
    render: renderIfActive(<AbsenceApprovalRequestToMe />),
  },
  requestAbsence: {
    route: route('absences', param('categoryId'), 'new'),
    render: renderIfActive(<Absences />),
  },
  absences: {
    route: route('absences', 'overview'),
    render: renderIfActive(<Absences />),
  },
  myAbsenceDetail: {
    route: route('absences', 'my-absences', param('absenceId')),
    render: renderIfActive(<MyAbsenceDetail />),
  },
  myAbsences: {
    route: route('absences', 'my-absences'),
    render: renderIfActive(<MyAbsences />),
  },
  pendingTimeEntryChangeRequests: {
    route: route('timetracking', 'pending-change-requests'),
    render: renderForControlling(<PendigTimeEntryChangeRequests />),
  },
  projectsOverview: {
    route: route('timetracking', 'projects', 'overview'),
    render: renderIfActive(<ProjectsOverview />),
  },
  projectsOverviewManager: {
    route: route('timetracking', 'projects', 'overview-manager'),
    render: renderIfActive(<ProjectsManagerOverview />),
  },
  projectsControllingTimeSheets: {
    route: route('timetracking', 'projects', 'controlling-timesheets'),
    render: renderIfActive(<ProjectsControllingTimeSheets />),
    rolesWithAccess: [ManagerRole.DirectManager, ManagerRole.TimeEntryApprover],
  },
  projectsTracking: {
    route: route('projects-tracking', 'overview'),
    render: renderIfActive(<ProjectsTimesheets />),
  },

  loom: {
    route: route('loom'),
    params: [param('sharedUrl'), param('embedUrl')],
    render: renderIfActive(<LoomVideoPreviewPage />),
  },
  selfPerformanceEvaluation: {
    route: route(
      'performance-review',
      'feedbacks',
      param('feedbackId'),
      'evaluate',
    ),
    render: renderIfActive(<SelfPerformanceEvaluation />),
  },
  engagementSurveyResponsePage: {
    route: route(
      'performance-review',
      'engagement-surveys',
      param('surveyId'),
      'response',
    ),
    render: renderIfActive(<EngagementSurveyResponsePage />),
  },
  pastPerformanceReview: {
    route: route('performance-review', 'feedbacks', param('feedbackId')),
    render: renderIfActive(<PastPerformanceReviewPage />),
  },
  performanceReviewerEvaluate: {
    route: route(
      'performance-review',
      'reviews',
      param('feedbackId'),
      'evaluate',
    ),
    render: renderIfActive(<EmployeeEvaluation />),
  },
  performanceReviewerMeeting: {
    route: route(
      'performance-review',
      'reviews',
      param('feedbackId'),
      'meeting',
    ),
    render: renderIfActive(<EmployeePerformanceReviewMeeting />),
  },
  performanceReviewerPreviousReviews: {
    route: route('performance-review', 'reviews', param('feedbackId')),
    render: renderIfActive(<PastEmployeeFeedbackPage />),
  },
  performanceReviewsForReviewer: {
    route: route('performance-review', 'reviews'),
    render: renderIfActive(<PerformanceReview />),
  },
  performanceReviewGoals: {
    route: route('performance-review', 'goals'),
    render: renderIfActive(<Goals />),
  },
  performanceReviewSkills: {
    route: route('performance-review', 'skills'),
    render: renderIfActive(<SkillsEmployeePage />),
  },
  performanceReviewEngagementSurveys: {
    route: route('performance-review', 'engagement-surveys'),
    render: renderIfActive(<EngagementSurveysListPage />),
  },
  performanceReview: {
    route: route('performance-review', 'me'),
    render: renderIfActive(<PerformanceReview />),
  },
  myExpense: {
    route: route('expense', param('id')),
    render: renderIfActive(<MyExpenses />),
  },
  myExpenses: {
    route: route('expenses'),
    render: renderIfActive(<MyExpenses />),
  },
  myCertificates: {
    route: route('certificates'),
    render: renderIfActive(<MyCertificates />),
  },
  ...managerRoutes,
  ...recruitmentRoutes,
};

export const root = routes.home;

export const paths: string[] = Object.values(routes).map(({ route }) =>
  route.template(),
);
