import React from 'react';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { LoomVideo, LoomVideoPreview } from '../../components/LoomVideo';
import { Container } from '../../components/LoomVideo/LoomVideoRecord';

const LoomVideoPreviewPage: React.FC = () => {
  const { sharedUrl, embedUrl } = useQueryParams();

  const loomVideo: LoomVideo = {
    sharedUrl: sharedUrl,
    embedUrl: embedUrl,
    width: 800,
    height: 500,
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <Container className="p-3">
        <LoomVideoPreview loomVideo={loomVideo} previewWidth={800} />
      </Container>
    </div>
  );
};

export default LoomVideoPreviewPage;
