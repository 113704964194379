import React from 'react';
import { AbsenceColleague } from '../../Content';
import { TeamCalendarFiltersType } from './types';
import { useTranslation } from 'react-i18next';
import TeamCalendarMultipleFilter from './TeamCalendarMultipleFilter';

type Props = {
  colleagues: AbsenceColleague[];
  filterData: TeamCalendarFiltersType;
};

const ColleagueFilter: React.FC<Props> = ({ colleagues, filterData }) => {
  const { t } = useTranslation();

  return (
    <div className="small fw-normal">
      <TeamCalendarMultipleFilter
        filterData={filterData}
        options={colleagues.map((c) => ({ label: c.name, value: c.id }))}
        filterKey={'colleaguesIds'}
        placeholder={t(
          'employeePage.teamCalendar.filters.colleaguesIds.placeholder',
        )}
        menuPlacement={'bottom'}
        menuPosition={'fixed'}
      />
    </div>
  );
};
export default ColleagueFilter;
