import React from 'react';
import Avatar from './Avatar';
import { ApprovalRequest } from '../MyAbsences/MyAbsenceFilters/types';

type Props = {
  approvalRequests: ApprovalRequest[];
};

const Approvers: React.FC<Props> = ({ approvalRequests }) => {
  const approvedAbsenceRequest = approvalRequests.find(
    (request) => request.approvalStatus === 'APPROVED',
  );
  const isRequestApproved = !!approvedAbsenceRequest;
  const listOfApproversInitials = approvalRequests.map((request) => {
    const firstName = request.approver.firstName;
    const lastName = request.approver.lastName;
    return {
      initials: `${firstName?.[0]}${lastName?.[0]}`,
      fullName: request.approver.name,
      avatar: request.approver.avatar,
    };
  });

  if (isRequestApproved) {
    const { firstName, lastName, name, avatar } =
      approvedAbsenceRequest.approver;
    const initials = `${firstName?.[0]}${lastName?.[0]}`;
    return <Avatar initials={initials} fullName={name} avatar={avatar} />;
  }
  return (
    <div className="avatar-group d-flex align-items-center">
      {listOfApproversInitials.map((props) => (
        <Avatar key={props.initials} {...props} />
      ))}
    </div>
  );
};

export default Approvers;
