import styled from '@emotion/styled';
import React from 'react';
import { OptionBase } from '../../../../components/Select/types';
import { Theme } from '../../../../theme';

type Props = {
  option: OptionBase;
  isInsideSelect: boolean;
};

export const OptionLabel: React.FC<Props> = ({ option, isInsideSelect }) => {
  if (isInsideSelect) {
    return <Container>{option.label}</Container>;
  }
  return <Container className="text-nowrap">{option.label}</Container>;
};

const Container = styled('div')({
  color: Theme.color.black,
  fontSize: Theme.font.size.sm,
});
