import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

type DropdownActionItemProps = {
  size?: 'sm';
} & DropdownItemProps;

export const DropdownActionItem: React.FC<DropdownActionItemProps> = ({
  children,
  size,
  className,
  onClick,
  ...props
}) => {
  const classNames = [
    'd-flex align-items-center',
    className,
    size === 'sm' ? 'small px-3 py-1' : undefined,
  ].join(' ');

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick && onClick(e);
  };

  return (
    <Dropdown.Item
      {...props}
      as="button"
      className={classNames}
      onClick={handleClick}>
      {children}
    </Dropdown.Item>
  );
};
