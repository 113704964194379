import * as React from 'react';

const PerformanceFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M16.875 3.125H3.125C2.79348 3.125 2.47554 3.2567 2.24112 3.49112C2.0067 3.72554 1.875 4.04348 1.875 4.375V15.625C1.875 15.9565 2.0067 16.2745 2.24112 16.5089C2.47554 16.7433 2.79348 16.875 3.125 16.875H16.875C17.2065 16.875 17.5245 16.7433 17.7589 16.5089C17.9933 16.2745 18.125 15.9565 18.125 15.625V4.375C18.125 4.04348 17.9933 3.72554 17.7589 3.49112C17.5245 3.2567 17.2065 3.125 16.875 3.125ZM15.625 15H4.375C4.20924 15 4.05027 14.9342 3.93306 14.8169C3.81585 14.6997 3.75 14.5408 3.75 14.375V5.625C3.75 5.45924 3.81585 5.30027 3.93306 5.18306C4.05027 5.06585 4.20924 5 4.375 5C4.54076 5 4.69973 5.06585 4.81694 5.18306C4.93415 5.30027 5 5.45924 5 5.625V11.6164L7.68281 8.93281C7.74086 8.8747 7.80979 8.8286 7.88566 8.79715C7.96154 8.7657 8.04287 8.74951 8.125 8.74951C8.20713 8.74951 8.28846 8.7657 8.36434 8.79715C8.44021 8.8286 8.50914 8.8747 8.56719 8.93281L10 10.3664L13.4914 6.875H11.875C11.7092 6.875 11.5503 6.80915 11.4331 6.69194C11.3158 6.57473 11.25 6.41576 11.25 6.25C11.25 6.08424 11.3158 5.92527 11.4331 5.80806C11.5503 5.69085 11.7092 5.625 11.875 5.625H15C15.1658 5.625 15.3247 5.69085 15.4419 5.80806C15.5592 5.92527 15.625 6.08424 15.625 6.25V9.375C15.625 9.54076 15.5592 9.69973 15.4419 9.81694C15.3247 9.93415 15.1658 10 15 10C14.8342 10 14.6753 9.93415 14.5581 9.81694C14.4408 9.69973 14.375 9.54076 14.375 9.375V7.75859L10.4422 11.6922C10.3841 11.7503 10.3152 11.7964 10.2393 11.8279C10.1635 11.8593 10.0821 11.8755 10 11.8755C9.91787 11.8755 9.83654 11.8593 9.76066 11.8279C9.68479 11.7964 9.61586 11.7503 9.55781 11.6922L8.125 10.2586L5 13.3836V13.75H15.625C15.7908 13.75 15.9497 13.8158 16.0669 13.9331C16.1842 14.0503 16.25 14.2092 16.25 14.375C16.25 14.5408 16.1842 14.6997 16.0669 14.8169C15.9497 14.9342 15.7908 15 15.625 15Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default PerformanceFilledIcon;
