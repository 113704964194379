import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../../components/EmptyStateCard';

const EmptyStateContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body className="text-center">
        <Row className="justify-content-center">
          <Col>
            <EmptyState
              title={t('managerPerformanceReviews.emptyState.title')}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EmptyStateContent;
