import { addDays, getDaysInMonth } from 'date-fns';
import moment from 'moment';
import { Project, ProjectsFilter, WeekDay } from './types';
import { getProjectStatus } from './ProjectsTimesheets/helpers';
import i18next from 'i18next';

export const i18prefix = 'timeTracking.projects';

const startWeek = moment().startOf('isoWeek');
const minWeek = startWeek.clone().add(-20, 'weeks');
const maxWeek = startWeek.clone().add(+20, 'weeks');

export const timesheetsRange = () => ({
  from: minWeek.format('YYYY-MM-DD'),
  to: maxWeek.format('YYYY-MM-DD'),
});

export const generateWeekDays = (startdate: Date): WeekDay[] =>
  Array(7)
    .fill(null)
    .map((_val, key) => {
      const newDate = moment(startdate).add(key, 'days');
      return {
        date: newDate.toDate(),
        dateId: toDateId(newDate),
      };
    });

export const generateMonthDays = (startdate: Date): WeekDay[] => {
  return Array(getDaysInMonth(startdate))
    .fill(null)
    .map((_val, key) => {
      const newDate = addDays(startdate, key);
      return {
        date: newDate,
        dateId: toDateId(newDate),
      };
    });
};

export const toDateId = (date: Date | moment.Moment | string) =>
  moment(date).format('YYYYMMDD');

export const filterProject = (
  project: Project,
  { billable, status }: ProjectsFilter,
) => {
  const selectedBillable = billable === 'true';
  const projectStatus = getProjectStatus(project, moment());
  if (billable && project.billable !== selectedBillable) {
    return false;
  }
  if (status && projectStatus !== status) {
    return false;
  }

  return true;
};

export const calculateOvertime = (
  timeTracked: number,
  estimation: number,
): number =>
  timeTracked && estimation
    ? Math.round(((timeTracked - estimation) / estimation) * 100)
    : 0;

export const EmptyProjectName = i18next.t(
  'timeTracking.projects.controlling.emptyProject',
);
