import React from 'react';
import { Card, Table } from 'react-bootstrap';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { useCurrencyFormatter } from '../../../../../hooks/useCurrencyFormatter';
import CardHeader from '../../../components/CardHeader';
import { orderBy } from 'lodash';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { AdditionalCompensationCard_AdditionalCompensationFragment } from '../../../__generated__/graphql';

type Props = {
  additionalCompensations: ReadonlyArray<AdditionalCompensationCard_AdditionalCompensationFragment>;
};

const AdditionalCompensationsCard: React.FC<Props> = ({
  additionalCompensations,
}) => {
  const t = useNamespacedTranslation(
    'employeePage.overview.additionalCompensationsCard',
  );
  const formatCurrency = useCurrencyFormatter();
  return (
    <Card>
      <CardHeader>{t('title')}</CardHeader>
      <Table className="card-table text-nowrap" bordered size="sm" responsive>
        <thead>
          <tr>
            <th>{t('type')}</th>
            <th>{t('amount')}</th>
            <th>{t('recurrence')}</th>
            <th>{t('startDate')}</th>
            <th>{t('endDate')}</th>
          </tr>
        </thead>
        <tbody>
          {orderBy(additionalCompensations, 'flair__Start_Date__c', 'desc').map(
            (comp) => (
              <tr key={comp.Id}>
                <td>{comp.flair__Compensation_Type__c}</td>
                <td>
                  {formatCurrency({
                    value: comp.flair__Amount__c,
                    currency: comp.CurrencyIsoCode,
                  })}
                </td>
                <td>
                  {t('recurrenceValue', {
                    count: comp.flair__Recurring_Period_in_Months__c,
                  })}
                </td>
                <td>
                  <FormattedDate
                    day={comp.flair__Start_Date__c}
                    format="short"
                  />
                </td>
                <td>
                  {comp.flair__End_Date__c && (
                    <FormattedDate
                      day={comp.flair__End_Date__c}
                      format="short"
                    />
                  )}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default AdditionalCompensationsCard;
