import React from 'react';
import StatusIndicator from '../../../../components/StatusIndicator';
import { StatusDotVariant } from '../../../../components/StatusDot';
import Badge from '../../../../components/TimeSheet/Badge';

type Props = {
  label: string;
  variant: StatusDotVariant;
  isDeleteVisible?: boolean;
  onDeleteClick?: () => void;
};

const StatusBadge: React.FC<Props> = ({
  label,
  variant,
  isDeleteVisible,
  onDeleteClick,
}) => {
  return (
    <Badge
      icon={<StatusIndicator variant={variant} />}
      label={label}
      isDeleteVisible={isDeleteVisible}
      onDeleteClick={onDeleteClick}
    />
  );
};

export default React.memo(StatusBadge);
