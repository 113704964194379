import React, { createContext, useState } from 'react';
import { Employee } from '../../People/types';

type DashboardContextType = {
  selectedEmployee: Employee | null;
  setSelectedEmployee: React.Dispatch<React.SetStateAction<Employee | null>>;
};

export const DashboardContext = createContext<DashboardContextType>({
  selectedEmployee: null,
  setSelectedEmployee: () => {},
});

export const DashboardProvider: React.FC = ({ children }) => {
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  return (
    <DashboardContext.Provider
      value={{ selectedEmployee, setSelectedEmployee }}>
      {children}
    </DashboardContext.Provider>
  );
};
