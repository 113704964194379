import * as React from 'react';
import CommentList from '../CommentList/CommentList';
import ServerError from '../../../../../components/ServerError';
import { mapComments, parseText } from '../logic';
import {
  CommentFileInput,
  useCommentsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from '../../../__generated__/graphql';
import { MentionEmployee, RelatedObjectName, UpsertComment } from '../types';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useUserInfo } from '../../../context/UserInfo';
import { CommentContext } from '../Context/CommentContext';
import { Maybe } from '../../../../../utils/maybe';
import { EmployeeInfoSidebar } from '../../EmployeeInfoSidebar';
import { InnerModalContext } from '../../../../../context/InnerModalContext';
import { CommentCardLoading } from '../CommentCard/CommentCardLoading';

type Props = {
  recordId: string;
  relatedObjectName: RelatedObjectName;
  // It is not good approach, better to use apollo reactive queries
  onCommentsChanged?: () => void;
};

const CommentListWrapper: React.FC<Props> = ({
  recordId,
  relatedObjectName,
  onCommentsChanged,
}) => {
  const userInfo = useUserInfo();
  const { setIsInnerModalOpen } = useContext(InnerModalContext);
  const [createComment, { loading: submitting }] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const errorHandler = useMutationErrorHandler();
  const { data, loading, error } = useCommentsQuery({
    variables: {
      commentableId: {
        recordId,
        objectName: relatedObjectName,
      },
    },
  });

  const [selectedEmployee, setSelectedEmployee] =
    useState<Maybe<MentionEmployee>>(null);

  const commentsContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  // that value set from children in handleHighlighted,
  // We don't want to trigger another re-render, that is why we use useRef instead of useState
  const highlightedCommentHtmlElement = useRef<HTMLElement>();

  const scrollToEnd = () => {
    //Timeout is being used to load the images before scrolling
    //without this it will not scroll to the end if there are images
    setTimeout(() => {
      if (commentsContainerRef?.current) {
        commentsContainerRef.current.scrollTo({
          top: commentsContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 500);
  };

  const scrollToCommentDiv = (commentElement: HTMLElement) => {
    //Timeout is being used to load the images before scrolling
    //without this it will not scroll to the end if there are images
    setTimeout(() => {
      commentElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 500);
  };

  useEffect(() => {
    if (!loading && isFirstRender) {
      if (highlightedCommentHtmlElement.current) {
        scrollToCommentDiv(highlightedCommentHtmlElement.current);
      } else {
        scrollToEnd();
      }
      setIsFirstRender(false);
    }
  }, [isFirstRender, loading, highlightedCommentHtmlElement]);

  const handleHighlighted = (_commentId: string, htmlElement: HTMLElement) => {
    highlightedCommentHtmlElement.current = htmlElement;
  };

  if (error) {
    return <ServerError />;
  }

  const handleSubmitComment = async (c: UpsertComment) => {
    const files: CommentFileInput[] = [];
    c.files.forEach((f) => {
      if (f.fileBase64) {
        files.push({
          fileContentBase64: f.fileBase64,
          fileName: f.fileName,
        });
      }
    });

    createComment({
      variables: {
        input: {
          relatedId: recordId,
          comment: parseText(c.text),
          employeeId: userInfo.id,
          files,
          relatedObjectName,
        },
      },
    })
      .then(() => {
        scrollToEnd();
        if (onCommentsChanged) {
          onCommentsChanged();
        }
      })
      .catch(errorHandler);
  };

  const handleUpdateComment = async (c: UpsertComment) => {
    if (c.id) {
      return updateComment({
        variables: {
          input: {
            id: c.id,
            text: parseText(c.text),
          },
        },
      })
        .then(() => {
          if (onCommentsChanged) {
            onCommentsChanged();
          }
        })
        .catch(errorHandler);
    }
    return;
  };

  const handleDeleteComment = async (id: string) =>
    deleteComment({ variables: { id } })
      .then(() => {
        if (onCommentsChanged) {
          onCommentsChanged();
        }
      })
      .catch(errorHandler);

  const comments = mapComments(data?.commentable.comments ?? []);
  return loading ? (
    <div className="px-3">
      <CommentCardLoading />
    </div>
  ) : (
    <CommentContext.Provider
      value={{ recordId, selectedEmployee, setSelectedEmployee }}>
      <CommentList
        ref={commentsContainerRef}
        comments={comments}
        onSubmit={handleSubmitComment}
        onUpdate={handleUpdateComment}
        onDelete={handleDeleteComment}
        submitting={submitting}
        scrollToEnd={scrollToEnd}
        onHighlighted={handleHighlighted}
      />
      {selectedEmployee && (
        <EmployeeInfoSidebar
          show={!!selectedEmployee}
          onClose={() => {
            setSelectedEmployee(null);
            setIsInnerModalOpen(false);
          }}
          employee={selectedEmployee}
        />
      )}
    </CommentContext.Provider>
  );
};

export default CommentListWrapper;
