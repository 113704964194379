import { CertificatesQuery } from '../../__generated__/graphql';

export type Certificate = {
  id: string;
  name: string;
  issuedBy: string;
  expirationDate: Date | null;
  issueDate: Date | null;
  plannedDate: Date | null;
  certificateUrl: string | null;
  status: string;
  certificateId: string | null;
  certificateName: string;
  documents: DocumentFile[];
  recordId: string;
  commentsCount: number;
};

export type certificateSF = CertificatesQuery['me']['certificates'][0];

export type Option = {
  label: string;
  value: string;
};

export type CertificateFilter = {
  status: CertificateStatus | null;
  issuedBy: string | null;
  issueDate: string | null;
  expirationDate: string | null;
};

export const emptyCertificateFilter: CertificateFilter = {
  status: null,
  issuedBy: null,
  issueDate: null,
  expirationDate: null,
};

export type CertificateStatus = 'Valid' | 'Planned' | 'Expired' | 'UNKNOWN';

export const allCertificateStatuses: CertificateStatus[] = [
  'Valid',
  'Planned',
  'Expired',
];

export type DocumentFile = {
  id: string;
  fileName: string;
  downloadPublicUrl?: string;
};

export type ContentDocumentLink =
  CertificatesQuery['me']['certificates'][0]['ContentDocumentLinks'][0];
