import {
  Absence,
  AbsenceApprovalStatus,
  EmployeeCertificate,
  EmployeeFeedback,
  EmployeeFeedbackState,
  EmployeeSalary,
  EmployeeWorkload,
} from '../../../__generated__/graphql';
import {
  formatDateWithLocaleDay,
  parseDate,
} from '../../../../../utils/dateUtils';
import { TFunction } from 'react-i18next';
import { EmployeeHistory } from './types';
import { i18EmployeehistoryPrefix } from './utils';

export const mapEmployeeHistoryArray = (
  history: EmployeeHistory[],
  t: TFunction,
): EmployeeHistory[] => {
  return history.map((item) => {
    let updatedOldValue;
    let updatedNewValue;
    const createdDate = getISOStringDate(item.CreatedDate);

    switch (item.Name) {
      case 'flair__Tax_Class__c':
        updatedOldValue =
          item.flair__Old_Value__c &&
          item.flair__Old_Value__c +
            ` (${t(`${i18EmployeehistoryPrefix}.fieldValues.taxClass`)})`;
        updatedNewValue =
          item.flair__New_Value__c &&
          item.flair__New_Value__c +
            ` (${t(`${i18EmployeehistoryPrefix}.fieldValues.taxClass`)})`;
        return {
          ...item,
          flair__Old_Value__c: updatedOldValue,
          flair__New_Value__c: updatedNewValue,
          CreatedDate: createdDate,
        };
      default:
        return {
          ...item,
          CreatedDate: createdDate,
        };
    }
  });
};

export const mapSalariesToEmployeeHistoryArray = (
  salaries: EmployeeSalary[],
  formatCurrency: ({
    value,
    currency,
  }: {
    value: number;
    currency: string;
  }) => String,
  t: TFunction,
): EmployeeHistory[] => {
  if (salaries.length > 0) {
    const currentDateTime = new Date(Date.now());
    const salariesHistory: EmployeeHistory[] = salaries
      .filter(
        (s) =>
          s.flair__Start_Date__c !== null &&
          parseDate(s.flair__Start_Date__c) <= currentDateTime,
      )
      .map((salary) => {
        const salaryTypeValue =
          salary.flair__Salary_Type__c === 'Unknown'
            ? `(${t(
                `${i18EmployeehistoryPrefix}.fieldValues.otherSalaryType`,
              )})`
            : `(${salary.flair__Salary_Type__c})`;
        return {
          Id: salary.Id,
          Name: 'flair__Salary__c',
          flair__Old_Value__c: null,
          flair__New_Value__c: `${formatCurrency({
            value: salary.flair__Amount__c,
            currency: salary.CurrencyIsoCode,
          })} ${salaryTypeValue}`,
          CreatedDate: getISOStringDate(salary.flair__Start_Date__c),
        };
      });
    return salariesHistory;
  }
  return [];
};

export const mapCertificatesToEmployeeHistoryArray = (
  certificates: EmployeeCertificate[],
): EmployeeHistory[] => {
  if (certificates.length > 0) {
    const certificateHistory: EmployeeHistory[] = certificates
      .filter((c) => c.flair__Status__c !== 'Planned')
      .map((certificate) => ({
        Id: certificate.Id,
        Name: 'flair__Employee_Certificate__c',
        flair__Old_Value__c: null,
        flair__New_Value__c: certificate.certificate?.Name,
        CreatedDate: getISOStringDate(certificate.CreatedDate),
      }));
    return certificateHistory;
  }
  return [];
};

export const mapWorkloadsToEmployeeHistoryArray = (
  workloads: EmployeeWorkload[],
): EmployeeHistory[] => {
  if (workloads.length > 0) {
    const workloadHistory: EmployeeHistory[] = workloads.map((workload) => ({
      Id: workload.Id,
      Name: 'flair__Employee_Working_Hours__c',
      flair__Old_Value__c: null,
      flair__New_Value__c: workload.Name || '',
      CreatedDate: getISOStringDate(workload.flair__Start_Date__c),
    }));
    return workloadHistory;
  }
  return [];
};

export const mapAbsenceToEmployeeHistoryArray = (
  absences: Absence[],
): EmployeeHistory[] => {
  if (absences.length > 0) {
    const currentDateTime = new Date(Date.now());
    const absenceHistory: EmployeeHistory[] = absences
      .filter(
        (a) =>
          parseDate(a.flair__Start_Date__c) <= currentDateTime &&
          a.flair__Approval_Status__c === AbsenceApprovalStatus.Approved,
      )
      .map((absence) => ({
        Id: absence.Id,
        Name: 'flair__Absence__c',
        flair__Old_Value__c: null,
        flair__New_Value__c:
          formatDateWithLocaleDay(absence.flair__Start_Date__c) +
          ' - ' +
          formatDateWithLocaleDay(absence.flair__End_Date__c),
        CreatedDate: getISOStringDate(absence.flair__Start_Date__c),
      }));
    return absenceHistory;
  }
  return [];
};

export const mapFeedbackToEmployeeHistoryArray = (
  feedbacks: EmployeeFeedback[],
): EmployeeHistory[] => {
  if (feedbacks.length > 0) {
    const feedbackHistory: EmployeeHistory[] = feedbacks
      .filter(
        (f) =>
          (f.outcome.flair__Accepted_At__c !== null ||
            f.outcome.flair__Ended_At__c !== null) &&
          f.flair__State__c === EmployeeFeedbackState.Submitted,
      )
      .map((feedback) => ({
        Id: feedback.Id,
        Name: 'flair__Employee_Feedback_Request__c',
        flair__Old_Value__c: null,
        flair__New_Value__c: feedback.feedbackCycle.Name,
        CreatedDate: getISOStringDate(
          feedback.outcome.flair__Accepted_At__c ||
            feedback.outcome.flair__Ended_At__c ||
            '',
        ),
      }));
    return feedbackHistory;
  }
  return [];
};

export const mapJoinedDateToEmployeeHistory = (
  joinedDate: string,
): EmployeeHistory => {
  return {
    Id: '',
    Name: 'flair__Start_Date__c',
    flair__Old_Value__c: null,
    flair__New_Value__c: formatDateWithLocaleDay(joinedDate),
    CreatedDate: parseDate(joinedDate).toISOString(),
  };
};

const getISOStringDate = (dateValue: Date | number | string) => {
  return parseDate(dateValue).toISOString();
};
