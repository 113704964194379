import {
  addMonths,
  addQuarters,
  addYears,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import { TimeBalanceAutoResetMode } from '../../__generated__/graphql';
import { formatDateLong } from '../../../../utils/dateUtils';

export const getNextResetDate = (
  autoResetMode: TimeBalanceAutoResetMode,
  now: Date,
): Date | null => {
  switch (autoResetMode) {
    case TimeBalanceAutoResetMode.Monthly:
      return addMonths(startOfMonth(now), 1);
    case TimeBalanceAutoResetMode.Quarterly:
      return addQuarters(startOfQuarter(now), 1);
    case TimeBalanceAutoResetMode.Yearly:
      return addYears(startOfYear(now), 1);
    case TimeBalanceAutoResetMode.None:
      return null;
  }
};

export const formatNextResetDate = (
  autoResetMode: TimeBalanceAutoResetMode,
  now: Date,
): string => {
  const result = getNextResetDate(autoResetMode, now);
  return result ? formatDateLong(result) : '-';
};
