import * as React from 'react';

const ImageOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.44772 3 2 3.44772 2 4V12C2 12.5523 2.44772 13 3 13H13C13.5523 13 14 12.5523 14 12V4C14 3.44772 13.5523 3 13 3H3ZM1 4C1 2.89543 1.89543 2 3 2H13C14.1046 2 15 2.89543 15 4V12C15 13.1046 14.1046 14 13 14H3C1.89543 14 1 13.1046 1 12V4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5C10.2239 5 10 5.22386 10 5.5C10 5.77614 10.2239 6 10.5 6C10.7761 6 11 5.77614 11 5.5C11 5.22386 10.7761 5 10.5 5ZM9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5C12 6.32843 11.3284 7 10.5 7C9.67157 7 9 6.32843 9 5.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.96399 7.25109C5.24994 6.99721 5.62206 6.86214 6.00428 6.87346C6.38637 6.88478 6.74971 7.04155 7.0201 7.31173C7.02012 7.31175 7.02014 7.31177 7.02016 7.31179C7.02024 7.31187 7.02032 7.31195 7.02039 7.31202L9.85323 10.1395C10.0487 10.3346 10.049 10.6512 9.85389 10.8467C9.65881 11.0421 9.34223 11.0424 9.14678 10.8473L6.31337 8.01921C6.22323 7.92909 6.10207 7.8768 5.97466 7.87302C5.84728 7.86925 5.72327 7.91425 5.62796 7.99884C5.62794 7.99886 5.62792 7.99888 5.6279 7.9989L1.83223 11.3737C1.62586 11.5571 1.30983 11.5386 1.12634 11.3322C0.942857 11.1259 0.961409 10.8098 1.16778 10.6263L4.96399 7.25109Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8817 9.96742C11.7858 9.88777 11.6636 9.84681 11.539 9.85259C11.4144 9.85837 11.2964 9.91054 11.2083 9.99885L7.35355 13.8536C7.15829 14.0488 6.84171 14.0488 6.64645 13.8536C6.45118 13.6583 6.45118 13.3417 6.64645 13.1465L10.5005 9.29246C10.5005 9.29239 10.5006 9.29232 10.5007 9.29225C10.7651 9.02744 11.1189 8.87101 11.4927 8.85366C11.8666 8.83632 12.2334 8.95939 12.5212 9.19871L14.8202 11.116C15.0323 11.2929 15.0609 11.6082 14.884 11.8203C14.7071 12.0323 14.3918 12.0609 14.1798 11.884L11.8819 9.9676C11.8818 9.96754 11.8817 9.96748 11.8817 9.96742Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ImageOutlineIcon;
