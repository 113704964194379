import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../routes';
import { CandidateEvaluation } from '../types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import StatusIndicator from '../../../../components/StatusIndicator';
import ScoreContent from '../TeamFeedback/ScoreContent';
import Button from '../../../../../../components/button';

type Params = {
  candidateEvaluation: CandidateEvaluation;
};

const i18Path = 'recruitment.candidate.detailsPage';

const FeedbackSection: React.FC<Params> = ({ candidateEvaluation }) => {
  const t = useNamespacedTranslation(i18Path);
  const history = useHistory();

  const handleViewMore = () => {
    history.push(
      routes.evaluation.route.create({
        candidateEvaluationId: candidateEvaluation.Id,
      }),
    );
  };

  const handleGiveFeedback = () => {
    history.push(
      routes.evaluation.route.create({
        candidateEvaluationId: candidateEvaluation.Id,
      }),
    );
  };

  const isEvaluated = candidateEvaluation.flair__Evaluated__c;

  return (
    <div className="d-flex justify-content-between">
      <div>
        <div>
          {candidateEvaluation.evaluationTemplate
            ? candidateEvaluation.evaluationTemplate.Name
            : t('quickEvaluation')}
        </div>
        {isEvaluated && candidateEvaluation.flair__Final_Score__c != null ? (
          <ScoreContent
            score={candidateEvaluation.flair__Final_Score__c}
            labelPosition="right"
            labelClassName="pe-3"
            showScore={true}
            useSplitter={true}
            containerClassName="small"
          />
        ) : (
          <StatusIndicator
            containerClassName="small"
            variant="warning"
            text={t('pending')}
          />
        )}
      </div>
      <Button
        variant="outline-primary"
        onClick={isEvaluated ? handleViewMore : handleGiveFeedback}
        label={t(isEvaluated ? 'viewDetails' : 'giveFeedback')}
      />
    </div>
  );
};

export default FeedbackSection;
