import React, { useContext, useMemo } from 'react';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import ServerError from '../../../../../components/ServerError';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import {
  TimeSheetFilterInput,
  useAllTimeSheetsQuery,
} from '../../../__generated__/graphql';
import { FilterWarningsType, TimeSheetsFilter } from '../Filters/types';
import { TimeSheetsBulkActions } from './TimeSheetsBulkActions';
import { TimeSheetsTable } from './TimeSheetsTable';
import { useTimeSheetsTableSelection } from '../../../components/TimeSheetsTable/useTimeSheetsTableSelection';
import { useManagerTimeSheetColumnSettings } from './ManagerCustomizeTableColumns';
import {
  createDefaultSortInput,
  mapTimeSheet,
  mapToTableRows,
  updateTableRows,
} from '../../../components/TimeSheetsTable/mappings';
import { useTimeSheetsExpand } from '../../../components/TimeSheetsTable/useTimeSheetsExpand';
import { useTimeSheetDetailsPrefetch } from '../../../components/TimeSheetsTable/useTimeSheetDetailsPrefetch';
import { useIsTimeBalanceEnabledGlobal } from '../../../hooks/timeTrackingSettings/useIsTimeBalanceEnabledGlobal';
import { TimeSheetApproveContext } from '../../../components/TimeSheetApprove';
import { isMatchClientFilter } from '../Filters/mappings';

type Props = {
  filter: TimeSheetsFilter;
  reportsMode: ManagerReportsMode;
};

const PAGE_SIZE = 30;

export const TimeSheets: React.FC<Props> = ({ reportsMode, filter }) => {
  const isTimeBalanceEnabled = useIsTimeBalanceEnabledGlobal();

  const viewBy = useMemo(() => filter.viewBy ?? 'week', [filter.viewBy]);

  const queryFilter: TimeSheetFilterInput = useMemo(
    () => ({
      managerReportsType: mapManagerReportsType(reportsMode),
      employeeName: null,
      employeeIds: filter.employeeIds,
      withWarnings: toFilterWithWarnings(filter.warnings),
      year: filter.year,
      month: filter.month,
      status: filter.status,
      projectTimeEntriesFilter: null,
    }),
    [reportsMode, filter],
  );

  const sort = useMemo(() => {
    return createDefaultSortInput(viewBy);
  }, [viewBy]);

  const { data, loading, error, fetchMore } = useAllTimeSheetsQuery({
    variables: {
      filter: queryFilter,
      sort,
      offset: 0,
      limit: PAGE_SIZE,
      skipPaginationInfo: false,
    },
  });

  const [timeSheetColumnSettings] = useManagerTimeSheetColumnSettings({
    isTimeBalanceEnabled,
  });

  const timeSheets = useMemo(
    () =>
      data
        ? data.manager.timeSheetsPagination.items
            .map(mapTimeSheet)
            .filter((item) => isMatchClientFilter(filter, item))
        : [],
    [data, filter],
  );

  const tableRows = useMemo(
    () => mapToTableRows(timeSheets, viewBy),
    [timeSheets, viewBy],
  );

  const { approvingIds, approveTimeSheets } = useContext(
    TimeSheetApproveContext,
  );

  const { selectedRowIds, selectedTimeSheetIds, onSelectAll, onSelectRow } =
    useTimeSheetsTableSelection(tableRows, approvingIds);

  const { expandedRowIds, handleExpandedChanged } = useTimeSheetsExpand();

  const handleBulkActionsClose = () => {
    onSelectAll(tableRows, false);
  };

  const paginationInfo = useMemo(() => {
    return data?.manager.timeSheetsPagination;
  }, [data]);

  const handleBulkActionsApprove = (timeSheetIds: string[]) => {
    onSelectAll(tableRows, false);
    approveTimeSheets(timeSheetIds);
  };

  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: paginationInfo,
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo: true,
        },
      }).then();
    },
  });

  const tableRowsWithState = updateTableRows(
    tableRows,
    approvingIds,
    selectedRowIds,
    expandedRowIds,
  );

  useTimeSheetDetailsPrefetch(tableRowsWithState);

  if (loading || !data) {
    return <Loading />;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <>
      <TimeSheetsTable
        columnSettings={timeSheetColumnSettings}
        isTimeBalanceEnabled={isTimeBalanceEnabled}
        highlightIfHasComments={filter.onlyWithComments === 'true'}
        tableRows={tableRowsWithState}
        onApprove={(timeSheet) => approveTimeSheets([timeSheet.id])}
        onExpandedChanged={(row) =>
          handleExpandedChanged(row.id, !row.expanded)
        }
        onSelectAll={onSelectAll}
        onSelectRow={onSelectRow}
      />
      {LoadingMore}
      <TimeSheetsBulkActions
        visible={selectedTimeSheetIds.length > 0}
        onClose={handleBulkActionsClose}
        timeSheetIds={selectedTimeSheetIds}
        onApprove={handleBulkActionsApprove}
      />
    </>
  );
};

const Loading: React.FC = () => (
  <SkeletonTable className="card-table" columns={8} rows={15} />
);

function toFilterWithWarnings(
  value: FilterWarningsType | null,
): boolean | null {
  if (value === null) {
    return null;
  }
  return value === 'withWarnings';
}
