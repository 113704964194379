import React from 'react';
import { EmployeeShiftBlock } from '../../Common/types';
import ShiftMainLabel from './MainLabel';
import ShiftWeeklyBlockContainer from './ShiftWeeklyBlockContainer';
import { getWeeklyShiftBlockStylingInfo } from '../weeklyShiftsLogic';

type Props = {
  shift: EmployeeShiftBlock;
};

const EmployeeShiftWeeklyBlockDumb: React.FC<Props> = ({ shift }) => {
  let weeklyShiftBlockStylingInfo = getWeeklyShiftBlockStylingInfo(shift);
  return (
    <ShiftWeeklyBlockContainer
      zPriority={shift.isMy ? 5 : 0}
      weeklyShiftBlockStylingInfo={weeklyShiftBlockStylingInfo}
      spinnerVisible={false}>
      <ShiftMainLabel {...shift} />
    </ShiftWeeklyBlockContainer>
  );
};

export default EmployeeShiftWeeklyBlockDumb;
