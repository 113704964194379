import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { i18Path } from '../components/InventoryModal/constants';

type Props = {
  isExpanded: boolean;
  category: string;
  inventoryName: string | undefined;
  titleKey: string;
  currentStep: number;
};
export const useGetStepTitle = (props: Props) => {
  const { isExpanded, category, currentStep, inventoryName, titleKey } = props;
  const t = useNamespacedTranslation(i18Path);
  const NAME_STEP_INDEX = 1;
  const handleStepTitle = () => {
    const showInventoryName = currentStep > NAME_STEP_INDEX && inventoryName;
    if (isExpanded && category) return category;
    if (showInventoryName) {
      return inventoryName;
    }
    return t(`titles.${titleKey}`);
  };

  return {
    title: handleStepTitle(),
    description: t(`descriptions.${titleKey}`),
  };
};
