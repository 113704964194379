import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { ModalSidebarContent } from '../../../components/ModalSidebar';
import { routes } from '../../../routes';
import {
  useUpdateWorkflowItemRegularMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';
import {
  WorkflowItemEvaluationRequest,
  WorkflowItemStatus,
} from '../WorkflowItems/types';
import { WorkflowItemCommonFields } from './WorkflowItemCommonFields';
import { WorkflowItemStatusAutoSave } from './WorkflowItemStatusAutoSave';
import LightSectionContainer from './LightSectionContainer';
import Button from '../../../../../components/button';
import { useHistory } from 'react-router-dom';

type Props = {
  workflowItem: WorkflowItemEvaluationRequest;
};

export const WorkflowItemEvaluationRequestForm: React.FC<Props> = ({
  workflowItem,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const [updateItem] = useUpdateWorkflowItemRegularMutation();

  const errorHandler = useMutationErrorHandler();

  const handleStatusChange = (status: WorkflowItemStatus) => {
    setIsStatusLoading(true);
    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
        },
      },
    })
      .catch(errorHandler)
      .finally(() => {
        setIsStatusLoading(false);
      });
  };

  const handleOnOpenEvaluation = () => {
    if (workflowItem.evaluationId) {
      history.push(
        routes.employeeEvaluation.route.create({
          employeeEvaluationId: workflowItem.evaluationId,
        }),
      );

      let status = workflowItem.status;
      if (
        !workflowItem.readonly &&
        status === WorkflowItemStatusGenerated.NotStarted
      ) {
        status = WorkflowItemStatusGenerated.InProgress;
        handleStatusChange(status);
      }
    }
  };

  const onStatusLoading = (isStatusLoading: boolean) => {
    setIsStatusLoading(isStatusLoading);
  };

  return (
    <ModalSidebarContent
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={onStatusLoading}
          />
          <FormGroup>
            <LightSectionContainer>
              {workflowItem.evaluationId && (
                <Button
                  label={t(
                    'performanceReview.workflowsItems.sidebar.openEvaluation',
                  )}
                  className="w-100"
                  variant="outline-primary"
                  onClick={handleOnOpenEvaluation}
                />
              )}
            </LightSectionContainer>
          </FormGroup>
          <WorkflowItemStatusAutoSave
            value={workflowItem.status}
            onChange={handleStatusChange}
            readonly={workflowItem.readonly}
            saving={isStatusLoading}
            saved={!isStatusLoading}
          />
        </>
      }
    />
  );
};
