import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { EmployeeWithAvatar } from './EmployeeWithAvatar';
import { EmployeePopupContent } from './EmployeePopupContent';
import { Employee, EmployeeAdditionalInfo } from './types';

type Props = Employee & {
  popupInfo?: EmployeeAdditionalInfo;
};

export const EmployeeWithAvatarAndPopup: React.FC<Props> = ({
  popupInfo,
  ...props
}) => {
  if (popupInfo === undefined) {
    return <EmployeeWithAvatar employee={props} />;
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id="employee-avatar-popup">
          <Popover.Body>
            <EmployeePopupContent {...popupInfo} />
          </Popover.Body>
        </Popover>
      }>
      <div>
        <EmployeeWithAvatar employee={props} />
      </div>
    </OverlayTrigger>
  );
};
