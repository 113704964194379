import styled from '@emotion/styled';
import React from 'react';
import { Popover, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { useHourlyAbsenceAmountFormatter } from '../../../hooks/useHourlyAbsenceAmountFormatter';
import {
  AbsenceAllowance as AbsenceAllowanceType,
  AbsenceType,
} from '../../../__generated__/graphql';
import { CategoryTypeTheme } from '../../AbsenceCalendar/Utils';
import RequestAbsenceButton from '../../RequestAbsenceButton';
import { CalendarEmployeeAbsenceCategory } from '../../AbsenceCalendar/types';

type Allowance = Pick<
  AbsenceAllowanceType,
  'Id' | 'Name' | 'flair__Available_Amount__c' | 'flair__Expiration_Date__c'
>;

type CategoryInfoProps = {
  id: string;
  name: string;
  unlimited: boolean;
  allowances: ReadonlyArray<Allowance>;
  theme: CategoryTypeTheme;
  readOnly?: boolean;
  type: AbsenceType;
  categories: ReadonlyArray<CalendarEmployeeAbsenceCategory>;
};

const CategoryTable = styled(Table)`
  th {
    font-size: 0.7125rem;
    padding: 0.6rem;
  }

  td {
    font-size: 0.7125rem;
    padding: 0.6rem;
  }
`;

const CategoryInfo: React.FC<CategoryInfoProps> = ({
  id,
  name,
  allowances,
  unlimited,
  theme,
  readOnly,
  type,
  categories,
}) => {
  const { t } = useTranslation();
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();
  const formatAmount = (amount: number) =>
    type === AbsenceType.Hourly ? hourlyFormatter(amount) : amount;
  const hasAllowances = allowances.length > 0;

  return (
    <>
      <Popover.Header as="h3">
        <span className={['fe', 'me-2', theme.icon].join(' ')}></span>
        {name}
      </Popover.Header>
      <Popover.Body>
        <div className="mt-3 mb-3">
          {hasAllowances ? (
            <CategoryTable size="sm" bordered>
              <thead>
                <tr>
                  <th>{t('absences.calendar.categories.period')}</th>
                  <th>{t('absences.calendar.categories.amount')}</th>
                  <th>{t('absences.calendar.categories.exp')}</th>
                </tr>
              </thead>

              <tbody>
                {allowances.map((allowance, i) => (
                  <tr key={i}>
                    <td>
                      <b className="text-nowrap">{allowance.Name}</b>
                    </td>
                    <td>
                      {formatAmount(allowance.flair__Available_Amount__c)}
                    </td>
                    <td>
                      {allowance.flair__Expiration_Date__c ? (
                        <FormattedDate
                          format="long"
                          day={allowance.flair__Expiration_Date__c}
                        />
                      ) : (
                        t('absences.calendar.categories.noLimit')
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </CategoryTable>
          ) : unlimited ? (
            t('absences.calendar.categories.unlimited')
          ) : (
            t('absences.calendar.categories.noAllowances')
          )}
        </div>
        {!readOnly && (
          <div className="text-center">
            <RequestAbsenceButton
              size="sm"
              variant="outline-primary"
              categoryId={id}
              categories={categories}
              buttonLabel={t('absences.calendar.categories.request')}
            />
          </div>
        )}
      </Popover.Body>
    </>
  );
};

export default CategoryInfo;
