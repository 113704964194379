import React from 'react';
import PageHeader from '../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { TimeTrackingNav } from './TimeTrackingNav';
import { Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import RedirectToHome from '../../components/RedirectToHome';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

const i18Path = 'navigation.menuItems.time';

const TimeTracking: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const title = t('subMenuItems.timeTracking.title');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: title }]);

  return (
    <>
      <PageHeader title={title}>
        <TimeTrackingNav />
      </PageHeader>
      <Content />
    </>
  );
};

export default TimeTracking;
