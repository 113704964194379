import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../../hooks/useMutationErrorHandler';
import { useUserInfo } from '../../../../../context/UserInfo';
import { useIsManagerMode } from '../../../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../../../__generated__/graphql';
import { GoalId } from '../../types';
import { useCloneEmployeeObjective } from '../useObjectiveMutations';
import { GoalCloneModal } from './GoalCloneModal';

type Props = {
  show: boolean;
  goalId: GoalId;
  goalName: string;
  onClose: () => void;
};

export const GoalCloneModalConnected: React.FC<Props> = ({
  show,
  goalId,
  goalName,
  onClose,
}) => {
  const { t } = useTranslation();

  const { id: currentUserId } = useUserInfo();
  const { hasManagerRole } = useIsManagerMode();

  const { addSuccess, addError } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const [cloneEmployeeObjective, { loading: isCloning }] =
    useCloneEmployeeObjective(currentUserId);

  const handleClone = useCallback(
    (employeeIds: string[]) => {
      if (goalId.type !== 'employee') {
        return;
      }
      cloneEmployeeObjective({
        variables: {
          input: {
            id: goalId.id,
            employeeIds,
          },
        },
      })
        .then((data) => {
          if (data.data?.objectives.cloneEmployeeObjective.error) {
            addError(data.data?.objectives.cloneEmployeeObjective.error);
            return;
          }
          addSuccess(t(`performanceReview.goals2.toastCloneSuccess`));
          onClose();
        })
        .catch(errorHandler);
    },
    [
      goalId,
      cloneEmployeeObjective,
      addSuccess,
      onClose,
      errorHandler,
      addError,
      t,
    ],
  );

  return (
    <GoalCloneModal
      show={show}
      goalName={goalName}
      isCloning={isCloning}
      isManager={hasManagerRole(ManagerRole.DirectManager)}
      onClose={onClose}
      onSubmit={handleClone}
    />
  );
};
