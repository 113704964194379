import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CancelButton from '../../../../../components/form/CancelButton';
import { Input } from '../../../../../components/form/Input';
import SubmitButton from '../../../../../components/form/SubmitButton';
import { ModalFooter } from '../../../../../components/Modal';
import {
  TimeEntryNotesFormData,
  useTimeEntryNotesForm,
} from '../../../hooks/useTimeEntryNotesForm';

type Props = {
  timeEntryId: string;
  notes: string | null;
  show: boolean;
  onClose: () => void;
  onSubmit: (notes: string | null) => Promise<void>;
};

const StopTrackingModal: React.FC<Props> = ({
  show,
  onClose,
  onSubmit,
  notes,
}) => {
  const { t } = useTranslation();

  const { form, fieldName } = useTimeEntryNotesForm({ notes: notes });

  const handleSubmit = useCallback(
    (form: TimeEntryNotesFormData) => onSubmit(form.notes),
    [onSubmit],
  );

  return (
    <Modal show={show} onHide={onClose} centered>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton className="card-header">
            <h4 className="card-header-title">
              {t('timeTracking.current.stopTrackingModal.title')}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <Input
              name={fieldName}
              type="textarea"
              label={t('timeTracking.current.stopTrackingModal.content')}
              placeholder={t(
                'timeTracking.current.stopTrackingModal.notesPlaceholder',
              )}
              minRows={4}
            />
          </Modal.Body>
          <ModalFooter>
            <CancelButton size="sm" onClick={onClose}>
              {t('timeTracking.current.stopTrackingModal.cancelButton')}
            </CancelButton>

            <SubmitButton size="sm">
              {t('timeTracking.current.stopTrackingModal.confirmButton')}
            </SubmitButton>
          </ModalFooter>
        </form>
      </FormContext>
    </Modal>
  );
};

export default StopTrackingModal;
