import React from 'react';
import { useCallback, useState } from 'react';

type ContextValue = {
  visiblePopoverId: string | null;
  onPopoverToggle: (popoverId: string, visible: boolean) => void;
};

const SingleVisiblePopoverContext = React.createContext<ContextValue>({
  visiblePopoverId: null,
  onPopoverToggle: (popoverId: string, visible: boolean) => {},
});

export const SingleVisiblePopoverProvider: React.FC = (props) => {
  const [visiblePopoverId, setVisiblePopoverId] = useState<string | null>(null);

  const onPopoverToggle = useCallback(
    (popoverId: string, visible: boolean) => {
      setVisiblePopoverId(visible ? popoverId : null);
    },
    [setVisiblePopoverId],
  );

  return (
    <SingleVisiblePopoverContext.Provider
      value={{ visiblePopoverId, onPopoverToggle }}
      {...props}
    />
  );
};

export const useSingleVisiblePopoverContext = (): ContextValue =>
  React.useContext(SingleVisiblePopoverContext);
