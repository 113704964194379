import React from 'react';
import { PageHeaderRow } from '../PageHeader';
import { Col, Nav } from 'react-bootstrap';
import { NavLink } from '../../../../Router';
import { Route } from '../../../../utils/router';

export type TabItem = {
  label: string;
  route: Route<string[]>;
  [key: string]: any;
};

type Props = {
  tabItems: TabItem[];
  actionColumn?: React.ReactNode;
  exact?: boolean;
};

const PageHeaderNav: React.FC<Props> = ({
  tabItems,
  actionColumn,
  exact = true,
}) => {
  return (
    <PageHeaderRow>
      <Col>
        <Nav variant="tabs" className="header-tabs" as="ul">
          {tabItems.map(({ label, route, ...props }, i) => (
            <Nav.Item key={i} as="li">
              <NavLink
                className="nav-link"
                activeClassName="active"
                exact={exact}
                to={route}
                {...props}>
                {label}
              </NavLink>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
      {actionColumn && <Col className="col-auto text-end">{actionColumn}</Col>}
    </PageHeaderRow>
  );
};

export default PageHeaderNav;
