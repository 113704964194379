import * as React from 'react';

const BriefcaseOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54951)">
        <path
          d="M13.4971 3.99951H2.49965C1.67141 3.99951 1 4.67093 1 5.49916V12.4975C1 13.3257 1.67141 13.9972 2.49965 13.9972H13.4971C14.3253 13.9972 14.9967 13.3257 14.9967 12.4975V5.49916C14.9967 4.67093 14.3253 3.99951 13.4971 3.99951Z"
          stroke="currentColor"
          strokeWidth="0.999764"
          strokeLinejoin="round"
        />
        <path
          d="M9.99788 7.4987V8.24853C9.99788 8.31481 9.97154 8.37839 9.92467 8.42526C9.8778 8.47213 9.81422 8.49847 9.74794 8.49847H6.24876C6.18247 8.49847 6.1189 8.47213 6.07203 8.42526C6.02515 8.37839 5.99882 8.31481 5.99882 8.24853V7.4987M4.49917 3.99953V2.99976C4.49917 2.73461 4.60451 2.48032 4.792 2.29282C4.97949 2.10533 5.23378 2 5.49894 2H10.4978C10.7629 2 11.0172 2.10533 11.2047 2.29282C11.3922 2.48032 11.4975 2.73461 11.4975 2.99976V3.99953H4.49917ZM14.9967 7.4987H1H14.9967Z"
          stroke="currentColor"
          strokeWidth="0.999764"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54951">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default BriefcaseOutlineIcon;
