import React, { useCallback, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.core.css';
import { FormGroup } from './FormGroup';
import InputError from './InputError';
import InputLabel from './InputLabel';
import ScrollToError from './ScrollToError';
import { getError } from './utils';

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  onChange?: (value: string) => void;
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const EMPTY_CONTENT = '<p><br></p>';

export const RichtextInputStyled: React.FC<ReactQuillProps> = (props) => {
  return <ReactQuill {...props} modules={modules} />;
};

export const RichtextInput: React.FC<Props> = ({
  name,
  label,
  required,
  disabled,
  onChange,
}) => {
  const ref = useRef(null);
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext();

  const error = getError(name, errors);

  const handleChange = useCallback(
    ([value]) => {
      const res = value === EMPTY_CONTENT ? '' : value;
      if (onChange) {
        onChange(res);
      }
      return res;
    },
    [onChange],
  );

  return (
    <FormGroup ref={ref}>
      {label && <InputLabel label={label} required={required} />}
      <Controller
        name={name}
        control={control}
        as={
          <RichtextInputStyled
            className={[error ? 'is-invalid' : ''].join(' ')}
            readOnly={isSubmitting || disabled}
          />
        }
        onChange={handleChange}
      />
      <InputError error={error} />

      <ScrollToError name={name} inputContainerRef={ref} />
    </FormGroup>
  );
};
