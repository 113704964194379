import React from 'react';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { useTranslation } from 'react-i18next';
import ConfirmButton from '../../../../../../components/button/ConfirmButton';
import Section from '../../../../components/Section';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

export const FeedbackSignButton: React.FC<{
  isFinishing: boolean;
  onFinish: () => Promise<void>;
  variant?: ButtonVariant;
}> = ({ isFinishing, onFinish, variant }) => {
  const { t } = useTranslation();

  return (
    <ConfirmButton
      size="sm"
      variant={variant}
      onConfirm={onFinish}
      isProcessing={isFinishing}
      label={t('employeePage.performanceReview.meeting.signFinish')}
      confirmTitle={t('employeePage.performanceReview.meeting.confirmTitle')}
      confirmText={t('employeePage.performanceReview.meeting.confirmText')}
    />
  );
};
const i18Path = 'employeePage.performanceReview.meeting';
const FeedbackStatusSection: React.FC<{
  ended: boolean;
  readonly: boolean;
  signatureRequired: boolean;
}> = ({ ended, readonly, signatureRequired }) => {
  const t = useNamespacedTranslation(i18Path);

  let statusText = '';
  if (signatureRequired) {
    if (ended) {
      statusText = t('signed');
    } else if (!ended && readonly) {
      statusText = t('pending');
    } else {
      statusText = t('signFinish');
    }
  }
  //no signature required
  else {
    if (ended) {
      statusText = t('completed');
    } else {
      statusText = t('completeFinish');
    }
  }

  return (
    <Section>
      <div className="fw-bold">
        <div className="d-flex">
          <div className="me-auto">{t('feedbackStatus')}</div>
          <div>
            <span>{statusText}</span>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default FeedbackStatusSection;
