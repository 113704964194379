import React from 'react';
import { getEmployeeInitialsFromName } from '../../utils/employeeInitials';
import Avatar from '../../../../atomic/molecules/avatar/Avatar';
import { Employee } from './types';
import { AvatarSize } from '../../../../atomic/atoms/avatar/AvatarContainer/types';
import classNames from 'classnames';

export type Props = {
  employee: Employee;
  size?: AvatarSize;
  rightSideInfo?: React.ReactNode;
  additionalInfo?: React.ReactNode;
  onClick?: () => void;
  dataTestId?: string;
  showAvatarOnly?: boolean;
  avatarClassName?: string;
  nameContainerClassName?: string;
  additionalInfoContainerClassName?: string;
  onEmployeeClick?: () => void;
};

// TODO: remove this component and switch to AvatarWithInfo (atomic design) when implemented
export const EmployeeWithAvatar: React.FC<Props> = ({
  employee,
  size = 'xs',
  rightSideInfo,
  additionalInfo,
  onClick,
  dataTestId,
  showAvatarOnly = false,
  avatarClassName,
  nameContainerClassName = '',
  additionalInfoContainerClassName = '',
  onEmployeeClick,
}) => {
  const { fullName, initials } = extractFullNameAndInitials(employee);

  return (
    <div
      className="d-flex align-items-center flex-nowrap"
      onClick={onClick}
      role={onClick ? 'button' : ''}
      data-testid={dataTestId}>
      <div>
        <Avatar
          initials={initials}
          size={size}
          className="align-middle"
          containerClassName={avatarClassName}
          avatarUrl={employee.avatarUrl ?? undefined}
        />
      </div>
      {!showAvatarOnly && (
        <>
          <div className="ms-2 flex-grow-1">
            <span
              onClick={onEmployeeClick}
              role={onEmployeeClick ? 'button' : ''}
              className={classNames(
                'd-block align-middle text-nowrap text-truncate',
                onEmployeeClick && 'link-primary',
                nameContainerClassName,
              )}>
              {fullName}
            </span>
            {additionalInfo && (
              <div
                className={classNames(
                  'text-dark',
                  'small',
                  additionalInfoContainerClassName,
                )}>
                {additionalInfo}
              </div>
            )}
          </div>
          {rightSideInfo}
        </>
      )}
    </div>
  );
};

export const extractFullNameAndInitials = (employee: Employee) => {
  if ('name' in employee) {
    return {
      fullName: employee.name,
      initials: getEmployeeInitialsFromName(employee.name),
    };
  }

  return {
    fullName: `${employee.firstName} ${employee.lastName}`,
    initials: `${employee.firstName[0]}${employee.lastName[0]}`,
  };
};
