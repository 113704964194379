import classNames from 'classnames';
import React, { HTMLAttributes, useCallback, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Theme } from '../../../../../theme';
import { useHourlyAbsenceAmountFormatter } from '../../../hooks/useHourlyAbsenceAmountFormatter';
import { AbsenceType } from '../../../__generated__/graphql';
import { CalendarEmployeeAbsenceCategory } from '../../AbsenceCalendar/types';
import { CategoryTypeTheme } from '../../AbsenceCalendar/Utils';
import { Category } from './index';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  name: string;
  amount: number;
  unlimited: boolean;
  theme: CategoryTypeTheme;
  type: AbsenceType;
};

type CategoryLeftButtonProps = HTMLAttributes<HTMLDivElement> & {
  theme: CategoryTypeTheme;
};
const CategoryLeftButton = React.forwardRef<
  HTMLDivElement,
  CategoryLeftButtonProps
>(({ theme, className, style = {}, role = 'button', ...rest }, ref) => (
  <div
    {...rest}
    className={classNames('btn', 'text-nowrap', className)}
    style={{
      color: Theme.color.white,
      backgroundColor: theme.mainColor,
      borderColor: theme.mainColor,
      ...style,
    }}
    role={role}
    ref={ref}
  />
));

const LabelButton = React.forwardRef<HTMLDivElement, CategoryLeftButtonProps>(
  ({ theme, className, style = {}, role = 'button', ...rest }, ref) => (
    <div
      {...rest}
      className={classNames('btn', 'text-nowrap', className)}
      style={{
        color: Theme.color.black,
        backgroundColor: Theme.color.white,
        borderColor: theme.mainColor,
        ...style,
      }}
      role={role}
      ref={ref}
    />
  ),
);

const CategoryButton = React.forwardRef<HTMLDivElement, Props>(
  ({ name, amount, unlimited, theme, type, ...props }, ref) => {
    const hourlyFormatter = useHourlyAbsenceAmountFormatter();
    const availableAmount = unlimited
      ? '∞'
      : type === AbsenceType.Hourly
      ? hourlyFormatter(amount)
      : amount;

    return (
      <ButtonGroup size="sm" {...props} ref={ref}>
        <CategoryLeftButton theme={theme}>{availableAmount}</CategoryLeftButton>
        <LabelButton theme={theme}>{name}</LabelButton>
      </ButtonGroup>
    );
  },
);

type CategoriesGroupButtonProps = {
  name: string;
  categories: CalendarEmployeeAbsenceCategory[];
};

const groupTheme: CategoryTypeTheme = {
  type: 'group',
  icon: 'folder-outline',
  mainColor: Theme.absence.group.color,
  bgColor: Theme.absence.group.bgColor,
  iconColor: Theme.absence.group.accentColor,
};

export const CategoriesGroupButton: React.FC<CategoriesGroupButtonProps> = ({
  name,
  categories,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const toggleButton = useCallback(
    () => setIsCollapsed((prevState) => !prevState),
    [],
  );

  return (
    <>
      {!isCollapsed ? (
        <ButtonGroup onClick={toggleButton} size="sm">
          <CategoryLeftButton theme={groupTheme}>
            <FlairIcon icon="folder-outline" />
          </CategoryLeftButton>
          <LabelButton theme={groupTheme}>
            {name}
            <FlairIcon
              className="ms-1"
              icon="chevron-forward-outline"
              size="sm"
            />
          </LabelButton>
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          <div className={'d-flex align-items-center'}>
            <FlairIcon
              size="sm"
              role="button"
              onClick={toggleButton}
              className={'ms-2'}
              icon="chevron-back-outline"
            />
            {categories.map((category) => (
              <div key={category.Id} className={'ms-2'}>
                <Category category={category} categories={categories} />
              </div>
            ))}
          </div>
        </ButtonGroup>
      )}
    </>
  );
};

export default CategoryButton;
