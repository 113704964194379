import * as React from 'react';

const EyeFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54835)">
        <path
          d="M7.9966 9.99857C9.10091 9.99857 9.99613 9.10335 9.99613 7.99904C9.99613 6.89473 9.10091 5.99951 7.9966 5.99951C6.89229 5.99951 5.99707 6.89473 5.99707 7.99904C5.99707 9.10335 6.89229 9.99857 7.9966 9.99857Z"
          fill="black"
        />
        <path
          d="M15.3359 7.45569C14.5092 6.17724 13.4366 5.09125 12.2344 4.31487C10.9044 3.45507 9.43601 3.00049 7.98823 3.00049C6.65979 3.00049 5.35354 3.38009 4.10571 4.12866C2.8332 4.89192 1.68034 6.00697 0.679018 7.44256C0.565978 7.60481 0.503722 7.797 0.500162 7.99471C0.496601 8.19242 0.551897 8.38672 0.659023 8.55293C1.48414 9.84419 2.54608 10.9317 3.72955 11.6975C5.06205 12.5607 6.49608 12.9981 7.98823 12.9981C9.44757 12.9981 10.9191 12.5473 12.2435 11.6947C13.4451 10.9208 14.5154 9.83075 15.339 8.54168C15.4424 8.37933 15.4971 8.19072 15.4966 7.99823C15.496 7.80573 15.4402 7.61744 15.3359 7.45569ZM7.99885 10.9986C7.40565 10.9986 6.82577 10.8227 6.33254 10.4931C5.8393 10.1636 5.45488 9.69514 5.22787 9.14709C5.00086 8.59904 4.94146 7.99598 5.05719 7.41418C5.17292 6.83237 5.45858 6.29795 5.87803 5.87849C6.29749 5.45903 6.83191 5.17338 7.41372 5.05765C7.99553 4.94192 8.59858 5.00131 9.14663 5.22832C9.69468 5.45533 10.1631 5.83976 10.4927 6.33299C10.8222 6.82622 10.9981 7.40611 10.9981 7.99931C10.9972 8.79449 10.6809 9.55685 10.1187 10.1191C9.55639 10.6814 8.79404 10.9977 7.99885 10.9986Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54835">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.0012207)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default EyeFilledIcon;
