import * as React from 'react';
import { RecordedLoomVideo } from '../types';
import { Maybe } from '../../../../../utils/maybe';
import { Container, LoomVideoRecord } from '../LoomVideoRecord';
import { LoomVideoPreview } from '../LoomVideoPreview';
import { Spinner } from 'react-bootstrap';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  isLoading?: boolean;
  loomVideo: Maybe<RecordedLoomVideo>;
  previewWidth?: number;
  previewHeight?: number;
  onDelete?: () => void;
  onRecord?: (loomVideo: RecordedLoomVideo) => void;
};

const DEFAULT_PREVIEW_WIDTH = 154;
const DEFAULT_PREVIEW_HEIGHT = 100;

const i18Path = 'components.loomVideo';

//This component is combining both LoomVideoRecord and LoomVideoPreview
//With ability to delete the video beside the loading state
const LoomVideoRecordWithPreview: React.FC<Props> = ({
  isLoading = false,
  loomVideo,
  previewWidth = DEFAULT_PREVIEW_WIDTH,
  previewHeight = DEFAULT_PREVIEW_HEIGHT,
  onDelete,
  onRecord,
}) => {
  const t = useNamespacedTranslation(i18Path);
  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : loomVideo ? (
    <Container className="p-3 d-flex align-items-center justify-content-between">
      <LoomVideoPreview
        loomVideo={loomVideo}
        previewWidth={previewWidth}
        previewHeight={previewHeight}
      />
      <Button
        label={t('delete')}
        variant="outline-primary"
        className="align-self-end delete-loom-btn"
        size="sm"
        disabled={isLoading}
        onClick={() => onDelete && onDelete()}
      />
    </Container>
  ) : (
    <LoomVideoRecord
      text={t('pressToStartRecording')}
      onVideoRecorded={(v) => onRecord && onRecord(v)}
    />
  );
};

export default LoomVideoRecordWithPreview;
