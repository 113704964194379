import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AutoSaveInputLabel from '../../../../../../components/form/AutoSaveInputLabel';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import { SelectDropdownControlled } from '../../../../../../components/form/Selects';
import { OptionBase } from '../../../../../../components/Select/types';
import { getGoalTypeStr } from '../translations';
import { GoalType } from '../types';

const i18prefix = 'performanceReview.goals2.goalSidebar';

type Props = {
  isDisabled?: boolean;
};

export const GoalCreateTypeField: React.FC<Props> = ({ isDisabled }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <FormGroup>
          <AutoSaveInputLabel label={t(`${i18prefix}.formFields.type`)} />
          <SelectDropdownControlled
            name="type"
            options={goalOptions}
            isDisabled={isDisabled}
            heightMode="large"
          />
        </FormGroup>
      </Col>
      <Col>&nbsp;</Col>
    </Row>
  );
};

const creatGoalTypeOption = (value: GoalType): OptionBase => ({
  value,
  label: getGoalTypeStr(value),
});

const goalOptions = [
  creatGoalTypeOption('employee'),
  creatGoalTypeOption('team'),
];
