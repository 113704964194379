import React, { useContext } from 'react';
import { SimpleStep } from '../../../../../../components/Step';
import { useNamespacedTranslation } from '../../../../../../../../hooks/useNamespacedTranslation';
import { InventoryModalContext } from '../InventoryModalContext';
import Button from '../../../../../../../../components/button';
import { i18Path } from '../constants';

type Props = {
  isLoading: boolean;
};
export const StepFooter: React.FC<Props> = ({ isLoading }) => {
  const t = useNamespacedTranslation(i18Path);
  const { onCancel, currentStep, maxStep, steps } = useContext(
    InventoryModalContext,
  );

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <Button
        onClick={onCancel}
        variant="outline-primary"
        label={t(`buttons.${currentStep === 0 ? 'cancel' : 'back'}`)}
        className="d-flex align-items-center"></Button>
      <SimpleStep steps={steps} activeStep={currentStep} />
      <Button
        type="submit"
        isProcessing={isLoading}
        label={t(`buttons.${currentStep >= maxStep - 1 ? 'add' : 'next'}`)}
        variant="primary"
        className="d-flex align-items-center"></Button>
    </div>
  );
};
