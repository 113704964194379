import React from 'react';
import SubmitButton from '../../../../../components/form/SubmitButton';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
};

export const WorkflowItemSubmitButton: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <SubmitButton className="w-100 mt-4" disabled={disabled}>
      {t('form.general.saveButton')}
    </SubmitButton>
  );
};
