import classNames from 'classnames';
import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { DataWithLoadingState } from '../../../../utils/dataWithLoadingState';
import { EmployeeWithAvatar, EmployeeWithAvatarSkeleton } from '../Employee';
import { EmployeeInfo } from './types';
import { AvatarSize } from '../../../../atomic/atoms/avatar/AvatarContainer/types';

export type Props = {
  header?: string;
  employees: DataWithLoadingState<EmployeeInfo>;
  employeeAvatarSize?: AvatarSize;
};

const MAX_NUMBERS_OF_EMPLOYEES_TO_SHOW = 20;

export const TeamPopupContent: React.FC<Props> = ({
  header,
  employees,
  employeeAvatarSize = 'xs',
}) => {
  const t = useNamespacedTranslation('components.teamWithPopup');

  const renderEmployees = () => {
    if (employees === 'error') {
      return <div>{t('errorLoadingMembers')}</div>;
    }
    if (employees === 'loading') {
      return (
        <div className={classNames('d-flex', 'flex-column', 'gap-2', 'fs-5')}>
          {Array.from(Array(5).keys()).map((i) => (
            <EmployeeWithAvatarSkeleton
              size={employeeAvatarSize}
              key={i.toString()}
            />
          ))}
        </div>
      );
    }

    return (
      <div className={classNames('d-flex', 'flex-column', 'gap-2', 'fs-5')}>
        {employees
          .slice(0, MAX_NUMBERS_OF_EMPLOYEES_TO_SHOW)
          .map((employee) => (
            <EmployeeWithAvatar
              key={employee.id}
              size={employeeAvatarSize}
              employee={{
                name: employee.name,
                avatarUrl: employee.avatarUrl,
              }}
            />
          ))}
        {employees.length > MAX_NUMBERS_OF_EMPLOYEES_TO_SHOW && (
          <div key="has-more" className="small text-muted">
            {t('teamContainsMoreNEmployees', {
              count: employees.length - MAX_NUMBERS_OF_EMPLOYEES_TO_SHOW,
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="text-primary">{header ?? t('teamMembers')}</div>
      {renderEmployees()}
    </div>
  );
};
