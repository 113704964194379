import React from 'react';
import { useSingleVisiblePopoverContext } from '../../../../context/SingleVisiblePopoverContext';
import { Popover } from '../../../../components/popover';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { TimeBalanceDetailsConnected } from './TimeBalanceDetailsConnected';
import { Theme } from '../../../../theme';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {};

const popoverId = 'TimeBalanceDetailsPopover';

export const TimeBalanceDetailsPopover: React.FC<Props> = () => {
  const t = useNamespacedTranslation('components.timeBalanceDetails');

  const { visiblePopoverId, onPopoverToggle } =
    useSingleVisiblePopoverContext();

  return (
    <Popover
      headerText={t('details')}
      show={visiblePopoverId === popoverId}
      onToggle={(visible) => onPopoverToggle(popoverId, visible)}
      popoverContent={
        <div className="px-4 py-3">
          <TimeBalanceDetailsConnected />
        </div>
      }>
      <FlairIcon
        icon="information-circle-outline"
        size="sm"
        color={Theme.color.blue3}
      />
    </Popover>
  );
};
