import * as React from 'react';

const RemoveSquareOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28571 2.9375C2.85102 2.9375 2.5 3.28911 2.5 3.72115V12.2788C2.5 12.7109 2.85102 13.0625 3.28571 13.0625H12.7143C13.149 13.0625 13.5 12.7109 13.5 12.2788V3.72115C13.5 3.28911 13.149 2.9375 12.7143 2.9375H3.28571ZM1.5 3.72115C1.5 2.73531 2.30025 1.9375 3.28571 1.9375H12.7143C13.6998 1.9375 14.5 2.73531 14.5 3.72115V12.2788C14.5 13.2647 13.6998 14.0625 12.7143 14.0625H3.28571C2.30025 14.0625 1.5 13.2647 1.5 12.2788V3.72115Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8C5 7.72386 5.22386 7.5 5.5 7.5H10.5C10.7761 7.5 11 7.72386 11 8C11 8.27614 10.7761 8.5 10.5 8.5H5.5C5.22386 8.5 5 8.27614 5 8Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default RemoveSquareOutlineIcon;
