import React, { useCallback } from 'react';
import {
  useWorkflowsQuery,
  WorkflowContext,
} from '../../../__generated__/graphql';
import { emptyWorkflowFilter } from '../Filters/types';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import ServerError from '../../../../../components/ServerError';
import { mapSortBy, mapWorkflows, mapWorkflowsFilterInput } from './mappings';
import { WorkflowsTable } from './WorkflowsTable';
import { WorkflowsFilterConnected } from '../Filters/WorkflowsFilterConnected';
import { Card } from 'react-bootstrap';
import { Toggle } from '../Common/Toggle';
import { useHistory } from 'react-router-dom';
import { CardHeaderWithFilter } from '../Common/CardHeaderWithFilter';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { Route } from '../../../../../utils/router';
import { ToggleContext } from '../useWorkflowsViewMode';
import { FlairLocalStorageKey } from '../../../hooks/useFlairLocalStorage';

const PAGE_SIZE = 50;

type Props = {
  workflowContext: WorkflowContext;
  route: Route<['workflows']>;
  itemRoute: Route<['workflows']>;
};

export const Workflows: React.FC<Props> = ({
  workflowContext,
  route,
  itemRoute,
}) => {
  const history = useHistory();
  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: emptyWorkflowFilter,
    storageKey: getStorageKey(workflowContext),
    createRouteUrl: (queryParams) =>
      route.withQueryParams(queryParams).create({}),
  });

  const {
    data: withPagination,
    error,
    loading,
  } = useWorkflowsQuery({
    variables: {
      workflowContext,
      filter: mapWorkflowsFilterInput(tableState.filter),
      sort: mapSortBy(tableState.sortBy),
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });
  const tableItemsTotalCount =
    withPagination?.workflows.paginationInfo?.totalCount;

  const handleItemClick = useCallback(
    (workflowId: string) => {
      history.push(itemRoute.create({ workflowId }));
    },
    [history, itemRoute],
  );

  if (error) {
    return <ServerError />;
  }

  const workflows = withPagination ? mapWorkflows(withPagination) : undefined;

  const renderContent = () => {
    if (loading || !workflows) {
      return <Loading />;
    }

    return (
      <WorkflowsTable
        workflows={workflows}
        employeeColumnVisible={true}
        onItemClick={handleItemClick}
        pageSize={PAGE_SIZE}
        itemsCount={tableItemsTotalCount}
        initialSortBy={tableState.sortBy}
        onSortByChanged={handleSortByChanged}
        initialPageIndex={tableState.pageIndex}
        onPageIndexChanged={handlePageIndexChanged}
      />
    );
  };

  return (
    <Card>
      <CardHeaderWithFilter>
        <WorkflowsFilterConnected
          workflowContext={workflowContext}
          filter={tableState.filter}
          onChange={handleFilterChange}
        />
        <Toggle mode={mapToggleContext(workflowContext)} value="workflows" />
      </CardHeaderWithFilter>
      {renderContent()}
    </Card>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={5} rows={5} />;
};

const mapToggleContext = (src: WorkflowContext): ToggleContext => {
  switch (src) {
    case WorkflowContext.All:
      return 'forAll';
    case WorkflowContext.Other:
      return 'forOthers';
    case WorkflowContext.My:
      return 'forMe';
  }
};

const getStorageKey = (src: WorkflowContext): FlairLocalStorageKey => {
  switch (src) {
    case WorkflowContext.All:
      return 'workflow_filter_for_all';
    case WorkflowContext.Other:
      return 'workflow_filter_for_others';
    case WorkflowContext.My:
      return 'workflow_filter_for_me';
  }
};
