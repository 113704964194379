import * as React from 'react';

const PeopleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6673 4.96402C11.3302 5.32346 11.1229 5.8514 11.1702 6.5183L11.1702 6.51836C11.2655 7.86375 12.2454 8.75 13.1249 8.75C14.0038 8.75 14.9827 7.86399 15.0797 6.51759L15.0797 6.51748C15.1707 5.25706 14.3103 4.375 13.1249 4.375C12.5102 4.375 12.0059 4.6029 11.6673 4.96402ZM10.7555 4.10893C11.3482 3.47698 12.1915 3.125 13.1249 3.125C15.0356 3.125 16.4698 4.62262 16.3264 6.60752C16.1945 8.43841 14.8163 10 13.1249 10C11.4341 10 10.0531 8.43941 9.92335 6.6067M10.7555 4.10893C10.1613 4.74254 9.85346 5.62082 9.92335 6.60664L10.7555 4.10893Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9116 15.4507C7.6201 12.6063 10.415 11.25 13.1251 11.25C15.8297 11.25 18.6311 12.5631 19.3395 15.4532C19.494 16.0827 19.1028 16.875 18.2747 16.875H7.97584C7.14978 16.875 6.75368 16.0844 6.91155 15.4509M8.15869 15.625H18.0925C17.5233 13.5919 15.4648 12.5 13.1251 12.5C10.793 12.5 8.72973 13.6245 8.15869 15.625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74234 4.375C4.145 4.375 2.92951 5.6239 3.04858 7.30738C3.15542 8.81657 4.30699 10.1562 5.74234 10.1562C7.17832 10.1562 8.32744 8.81557 8.43603 7.3082C8.55534 5.65243 7.34379 4.375 5.74234 4.375ZM4.29546 7.21915C4.22783 6.2628 4.86624 5.625 5.74233 5.625C6.61432 5.625 7.25668 6.28273 7.18926 7.21836C7.11505 8.24849 6.35948 8.90625 5.74233 8.90625C5.12456 8.90625 4.36832 8.24822 4.29546 7.21915Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.657097 14.3312C1.24254 11.9825 3.54655 10.8808 5.74206 10.8808C6.62623 10.8808 7.49771 11.0141 8.30708 11.3849C8.62089 11.5287 8.75872 11.8996 8.61495 12.2134C8.47117 12.5272 8.10022 12.6651 7.78641 12.5213C7.18563 12.246 6.50398 12.1308 5.74206 12.1308C3.97907 12.1308 2.44517 12.9493 1.94714 14.375H6.0155C6.36067 14.375 6.6405 14.6548 6.6405 15C6.6405 15.3452 6.36067 15.625 6.0155 15.625H1.62956C0.871006 15.625 0.516363 14.8993 0.656883 14.3321L0.657097 14.3312Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default PeopleOutlineIcon;
