import React from 'react';
import { Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DuringBreak } from '../../../hooks/useCurrentTimeTrackingTimeline';
import ResumeTimeTrackingButton from './ResumeTimeTrackingButton';
import TimeUntilNow from './TimeUntilNow';

type Props = {
  step: DuringBreak;
};

const DuringBreakStep: React.FC<Props> = ({ step }) => {
  const { timeEntryId } = step;
  const { t } = useTranslation();

  return (
    <ListGroup.Item>
      <Row>
        <Col className="col-auto">
          <div className="avatar avatar-sm">
            <div className="avatar-title fs-3 bg-primary-soft rounded-circle text-primary">
              <Spinner animation="grow" variant="warning" />
            </div>
          </div>
        </Col>
        <Col className="ms-n2 align-self-center">
          <h4 className="mb-1">
            <TimeUntilNow date={step.startTime} />
          </h4>
          <small className="text-dark">
            {t('timeTracking.current.steps.duringBreak.breakTime')}
          </small>
        </Col>
        <Col className="col-auto align-self-center">
          <ResumeTimeTrackingButton
            className="lift"
            timeEntryId={timeEntryId}
          />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default DuringBreakStep;
