import * as React from 'react';
import { EmployeeWithAvatar } from '../../../apps/dashboard/components/Employee';
import FlairIcon from '../../atoms/FlairIcon';
import { Employee } from '../../../apps/dashboard/components/Employee/types';
import { DropdownActions } from '../../templates/DropdownActions';
import { useEffect, useRef, useState } from 'react';
import './AvatarWithActionsMenu.css';

export type AvatarWithActionsMenuProps = {
  employee: Employee;
  actions: React.ReactNode;
  icon?: typeof FlairIcon;
  showAvatarOnly?: boolean;
  avatarClassName?: string;
};

const AvatarWithActionsMenu: React.FC<AvatarWithActionsMenuProps> = ({
  employee,
  actions,
  icon,
  showAvatarOnly = false,
  avatarClassName,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const toggleIcon = icon ?? (
    <FlairIcon icon="chevron-down-outline" size="lg" className="ms-3" />
  );

  const handleOnClick = () => {
    setShow(!show);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => document.removeEventListener('click', handleClickOutside);
  }, [show]);

  const containerClassName = [
    'd-flex',
    'align-items-center',
    'avatar-with-actions-menu-container',
    show ? 'active' : '',
    'p-1',
    'rounded-1',
  ].join(' ');

  return (
    <div
      className={containerClassName}
      onClick={handleOnClick}
      role="button"
      ref={ref}>
      <EmployeeWithAvatar
        employee={employee}
        showAvatarOnly={showAvatarOnly}
        avatarClassName={avatarClassName}
      />
      {!showAvatarOnly && toggleIcon}
      <DropdownActions
        id="employee-user-menu-actions"
        className="employee-user-menu"
        dataTestId="employee-user-menu"
        icon={<></>}
        children={actions}
        drop="down"
        show={show}
      />
    </div>
  );
};

export default AvatarWithActionsMenu;
