import React from 'react';

import { useUnsnoozeNotification } from './useUnsnoozeNotification';
import Button from '../../../../../../../components/button';
import { NotificationItem } from '../../../types';

const UnsnoozeAction: React.FC<{
  notification: NotificationItem;
}> = ({ notification }) => {
  const [unsnoozeNotification] = useUnsnoozeNotification();
  return (
    <Button
      variant="link"
      style={{ color: 'var(--bs-flair-honey-1)' }}
      label="Unsnooze"
      onClick={() => {
        unsnoozeNotification({
          variables: {
            id: notification.id,
          },
        });
      }}
    />
  );
};
export default UnsnoozeAction;
