import React from 'react';
import ServerError from '../../../../components/ServerError';
import { Link } from '../../../../Router';
import { QueryString } from '../../../../utils/router';
import { PastPerformanceReviewCard } from '../../components/PastPerformanceReviewCard';
import { EmptyEmployeeFeedbacks } from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/EmptyEmployeeFeedbacks';
import { LoadingEmployeeFeedbacks } from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/LoadingEmployeeFeedbacks';
import NextPerformanceReview from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/NextPerformanceReview';
import { routes } from '../../routes';
import { useManagerEmployeeReviewsQuery } from '../../__generated__/graphql';
import { employeePageRoutes } from '../EmployeePage/PageHeader/routes';
import { useFeedbacks } from './useFeedbacks';
import { EngagementContainer } from '../EngagementContainer';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { mapPeerFeedbacks } from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeEvaluation/mapping';

const ReviewsList: React.FC = () => {
  const { data, loading, error } = useManagerEmployeeReviewsQuery();
  const t = useNamespacedTranslation('navigation.menuItems.teamDevelopment');
  const reviewsSubTitle = t('subMenuItems.reviews.title');

  useFlairBreadcrumbHook([{ label: t('title') }, { label: reviewsSubTitle }]);

  const { feedbacks } = useFeedbacks(data?.manager.performanceReviews);

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return (
      <EngagementContainer title={reviewsSubTitle}>
        <LoadingEmployeeFeedbacks />
      </EngagementContainer>
    );
  }

  const employeeFeedbacks = data.manager.performanceReviews;

  const pastFeedbacks = feedbacks.pastFeedbacks.map((pastFeedback) => ({
    ...pastFeedback,
    peerFeedbacks: mapPeerFeedbacks(pastFeedback.peerFeedbacks),
  }));

  return (
    <EngagementContainer title={reviewsSubTitle}>
      {employeeFeedbacks.length ? (
        <>
          <NextPerformanceReview
            feedbacks={feedbacks.nextFeedbacks}
            canChangeReviewer={true}
          />
          <PastPerformanceReviewCard
            feedbacks={pastFeedbacks}
            detailsLink={(feedbackId, cycleName) => (
              <Link
                to={routes.performanceReviewerPreviousReviews.route}
                feedbackId={feedbackId}>
                {cycleName}
              </Link>
            )}
            filtrationRoute={(params: QueryString) => {
              return employeePageRoutes()
                .managerPerformanceReview.route.withQueryParams(params)
                .create({});
            }}
            showReviewers={true}
            showEmployees={true}
          />
        </>
      ) : (
        <EmptyEmployeeFeedbacks />
      )}
    </EngagementContainer>
  );
};

export default ReviewsList;
