import React from 'react';
import { EmptyStateCardBody } from '../../components/EmptyStateCard';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

const i18Path = 'absences.myAbsences.table';

export const EmptyAbsenceRequestsCard: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  return <EmptyStateCardBody title={t('emptyStateMessage')} />;
};
