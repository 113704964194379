import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import {
  CreatedByColleagueFragment,
  EmployeeSkillAssociationFragment,
  SkillNamesFragment,
} from '../../../__generated__/graphql';
import {
  Employee,
  EmployeeSkillAssociation,
  isSkillLevel,
  Skill,
  SkillLevel,
} from './types';

export const mapEmployee = (src: CreatedByColleagueFragment): Employee => ({
  id: src.Id,
  name: src.Name,
  initials: getEmployeeInitialsFromName(src.Name),
  avatarUrl: src.avatar?.url ?? undefined,
});

export const mapSkill = (src: SkillNamesFragment): Skill => ({
  id: src.Id,
  name: src.Name,
  isRateable: src.flair__Rateable__c,
  createdDate: src.CreatedDate,
});

export const mapEmployeeSkillAssociation = (
  src: EmployeeSkillAssociationFragment,
): EmployeeSkillAssociation => ({
  id: src.Id,
  skillId: src.skill.Id,
  isRateable: src.skill.flair__Rateable__c,
  employeeId: src.flair__Employee__c,
  name: src.skill.Name,
  isTop: src.flair__Top__c,
  level: mapSkillLevel(src.flair__Rate__c),
});

export const mapSkillLevel = (src: number | null): SkillLevel => {
  return isSkillLevel(src) ? src : null;
};
