import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { times } from 'lodash';
import { AbsenceCalendarContainer } from '../../../components/AbsenceCalendar/AbsenceCalendarContainer';
import { Table as BootstrapTable } from 'react-bootstrap';

const DaysOfMonthSkeleton: React.FC = () => {
  const Header: React.FC = () => {
    return (
      <td key={`loading-month-header`}>
        <div
          style={{
            width: '205px',
            height: '90px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Skeleton width={150} height={20} />
        </div>
      </td>
    );
  };
  const DayOfMonth: React.FC = () => {
    return (
      <td>
        <div
          style={{
            width: '44px',
            height: '90px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Skeleton width={8} />
          <Skeleton width={16} />
        </div>
      </td>
    );
  };
  return (
    <tr>
      <Header />
      {times(30, (i) => (
        <DayOfMonth key={`loading-month-${i}`} />
      ))}
    </tr>
  );
};

const ColleagueRowsSkeleton: React.FC = () => {
  const EmployeeName: React.FC = () => (
    <td>
      <div
        style={{
          width: '205px',
          height: '55px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Skeleton width={150} />
      </div>
    </td>
  );

  const DayCell: React.FC = () => (
    <td>
      <div
        style={{
          width: '44px',
          height: '55px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Skeleton width={8} />
        <Skeleton width={16} />
      </div>
    </td>
  );
  return (
    <>
      {times(10, (i) => (
        <tr key={`loading-day-${i}-tr`}>
          <EmployeeName key={`loading-day-${i}-header`} />
          {times(30, (j) => (
            <DayCell key={`loading-day-${i}-${j}`} />
          ))}
        </tr>
      ))}
    </>
  );
};

const Loading: React.FC = () => {
  return (
    <AbsenceCalendarContainer>
      <BootstrapTable size="sm" responsive>
        <tbody>
          <DaysOfMonthSkeleton />
          <ColleagueRowsSkeleton />
        </tbody>
      </BootstrapTable>
    </AbsenceCalendarContainer>
  );
};

export default Loading;
