import React, { useContext } from 'react';
import { BodyProps, CategoryAccordionProps } from '../../types';
import { AbsenceType } from '../../../../__generated__/graphql';
import { Accordion, Col, Row } from 'react-bootstrap';
import { CategoryAccordionBodyTitle } from './components/CategoryAccordionBodyTitle';
import CompensatoryTimeChart from '../../../../components/TimeSheet/CompensatoryTimeChart';
import { AllowanceListGroup } from './components/AllowanceListGroup';
import RequestAbsenceButtonSidebar from '../../../../components/RequestAbsenceButton';
import PeriodNav from '../../../../../../components/PeriodNav';
import {
  addDateByUnit,
  changeDateByUnit,
} from '../../../../../../utils/dateUtils';
import {
  CategoryAccordionBodyContext,
  TContextProps,
} from './CategoryAccordionBodyContext';
import Loading from './components/Loading';
import { AbsencesContext } from '../../AbsencesContext';
import { AbsenceCategorySummary, PeriodType } from './types';
import { createPeriodInfo } from './helpers';

type RequestAbsenceButtonProps = Pick<CategoryAccordionProps, 'categories'> & {
  categoryId: string;
};

const RequestAbsenceButton: React.FC<RequestAbsenceButtonProps> = ({
  categoryId,
  categories,
}) => {
  return (
    <RequestAbsenceButtonSidebar
      categoryId={categoryId}
      categories={categories}
      variant="primary"
      block
    />
  );
};

const Body = ({
  summary,
  onPeriodChanged,
  startOfPeriod,
  category,
  hideRequestButton,
  categories,
  loading,
}: BodyProps) => {
  const isSummaryVisible =
    summary.hasYearlyBasedAccrualPolicy || summary.isUnlimited;
  const { timeSheets } = useContext(AbsencesContext);
  const periodInfo = createPeriodInfo(summary);
  const now = new Date();
  const startOfNowPeriod = changeDateByUnit(now, periodInfo);

  const contextProps: { value: TContextProps } = {
    value: {
      startOfPeriod,
      ...getLabelInfo(summary, startOfPeriod, startOfNowPeriod),
      category,
      isHourly: category.category.flair__Type__c === AbsenceType.Hourly,
    },
  };
  return (
    <CategoryAccordionBodyContext.Provider {...contextProps}>
      <PeriodNav
        periodInfo={periodInfo}
        maxPeriod={addDateByUnit(startOfNowPeriod, 1, periodInfo.unit)}
        minPeriod={addDateByUnit(startOfNowPeriod, -1, periodInfo.unit)}
        currentPeriod={startOfPeriod}
        onPeriodChanged={({ currentPeriod: newPeriod }) =>
          onPeriodChanged(newPeriod)
        }
      />

      <CategoryAccordionBodyTitle
        periodType={contextProps.value.periodType}
        summary={summary}
        category={category}
      />
      <Accordion.Body className="pt-0">
        {!loading && summary ? (
          <>
            {isSummaryVisible && (
              <>
                <Row className="mb-3">
                  <Col>
                    {category.category.flair__Compensatory_Time__c ? (
                      <CompensatoryTimeChart timeSheets={timeSheets} />
                    ) : (
                      <AllowanceListGroup summary={summary} />
                    )}
                  </Col>
                </Row>
              </>
            )}

            {!hideRequestButton &&
              contextProps.value.periodType === 'current' && (
                <Row>
                  <Col>
                    <RequestAbsenceButton
                      categoryId={category.Id}
                      categories={categories}
                    />
                  </Col>
                </Row>
              )}
          </>
        ) : (
          <Loading />
        )}
      </Accordion.Body>
    </CategoryAccordionBodyContext.Provider>
  );
};

const getLabelInfo = (
  summary: AbsenceCategorySummary,
  startOfPeriod: Date,
  startOfNowPeriod: Date,
): { periodType: PeriodType; labelValue: number | null } => {
  if (startOfPeriod > startOfNowPeriod) {
    return {
      periodType: 'next',
      labelValue: summary.nextPeriod.upcoming,
    };
  }
  if (startOfPeriod < startOfNowPeriod) {
    return {
      periodType: 'previous',
      labelValue: summary.prevPeriod.taken,
    };
  }

  return {
    periodType: 'current',
    labelValue: summary.curPeriod.available || 0,
  };
};

export default Body;
