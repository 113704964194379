import styled from '@emotion/styled';
import { Theme } from '../../../../../../theme';

type Props = {
  header?: boolean;
};

export const ProjectTitleCol = styled.td<Props>(({ header }) => ({
  minWidth: `9.375rem`,
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: `${
    header ? Theme.color.paper5 : Theme.color.white
  } !important`,
}));
