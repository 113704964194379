import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { CelebrationForm } from './CelebrationForm';
import { CreateCelebration } from '../types';

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  celebration?: CreateCelebration | null;
};

const i18nPrefix = 'cheers.form.create';

export const CelebrationCreateModal: React.FC<Props> = ({
  show,
  onClose,
  onSuccess,
  celebration,
}) => {
  const t = useNamespacedTranslation(i18nPrefix);
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t('modalTitle')}</h4>
      </Modal.Header>
      <Modal.Body>
        <CelebrationForm
          onClose={onClose}
          onSuccess={onSuccess}
          celebration={celebration}
        />
      </Modal.Body>
    </Modal>
  );
};
