import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { i18prefix } from '../../helpers';
import Hint from '../../../../../../components/hint';

import { ProjectDay } from '../../types';
import { totalMinutes } from '../mappings';
import { Theme } from '../../../../../../theme';
import { convertToHours, convertToMinutes, hoursInputStep } from '../helpers';
import classNames from 'classnames';
import {
  useRestrictFutureEntries,
  useTimeTrackingRestriction,
} from '../../../../hooks/timeTrackingSettings';
import { toISODateOnly } from '../../../../../../utils/dateUtils';

type Props = {
  days: ProjectDay[];
  disabled: boolean;
  onChange: (days: ProjectDay[]) => void;
};

type HourInputProps = {
  day: ProjectDay;
  disabled: boolean;
  onChange: (dateId: string, hours: number) => void;
};

const HourInput: React.FC<HourInputProps> = ({ day, disabled, onChange }) => {
  const { t } = useTranslation();

  if (day.timesTracked.length > 1 || day.status !== 'ACTIVE') {
    return <ReadOnlyHourInput day={day} />;
  }

  const hours = convertToHours(totalMinutes([day]));

  return (
    <StyledInput
      value={hours !== 0 ? hours : undefined}
      disabled={disabled}
      type="number"
      size="sm"
      max={24}
      step={hoursInputStep}
      placeholder={t(`${i18prefix}.timeTrackingPlaceholder`)}
      data-id={day.dateId}
      onChange={(event) => onChange(day.dateId, parseFloat(event.target.value))}
    />
  );
};

const ReadOnlyHourInput: React.FC<Pick<HourInputProps, 'day'>> = ({ day }) => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <Hint
        id={`project-day-hint-${day.dateId}`}
        mode="tooltip"
        text={t(getReadOnlyHintMessageKey(day))}>
        <StyledInput
          defaultValue={convertToHours(totalMinutes([day]))}
          disabled={true}
          size="sm"
        />
      </Hint>
    </StyledDiv>
  );
};

const ProjectWeekTimeTracking: React.FC<Props> = ({
  days,
  disabled,
  onChange,
}) => {
  const { restrictFutureEntriesForADay } = useRestrictFutureEntries();
  const { hasOngoingRestrictedAbsenceForDay } = useTimeTrackingRestriction();

  const handleInputChange = useCallback(
    (dateId: string, hours: number) => {
      onChange(
        days.map((day) => {
          if (day.timesTracked.length > 1 || day.dateId !== dateId) {
            return day;
          }

          return {
            ...day,
            timesTracked: [
              {
                ...day.timesTracked[0],
                minutes: convertToMinutes(hours),
              },
            ],
          };
        }),
      );
    },
    [onChange, days],
  );

  return (
    <>
      {days.map((day) => {
        const hasRestrictedAbsences = hasOngoingRestrictedAbsenceForDay(
          toISODateOnly(day.date),
        );
        return (
          <td
            className={classNames({
              'cell-bl': true,
              highlighted: hasRestrictedAbsences,
            })}
            key={day.dateId}>
            <HourInput
              day={day}
              disabled={
                disabled ||
                restrictFutureEntriesForADay(day.date) ||
                hasRestrictedAbsences
              }
              onChange={handleInputChange}
            />
          </td>
        );
      })}
    </>
  );
};

export default ProjectWeekTimeTracking;

const defaultWidth = '5rem';

const StyledDiv = styled.div({
  width: defaultWidth,
});

const StyledInput = styled(Form.Control)((props) => ({
  width: defaultWidth,
  ...(props.disabled
    ? { backgroundColor: `${Theme.color.bgGray2} !important` }
    : {}),
}));

function getReadOnlyHintMessageKey(day: ProjectDay) {
  if (day.status === 'FINISHED') {
    return `${i18prefix}.projectFinishedMessage`;
  } else if (day.status === 'NOT_STARTED') {
    return `${i18prefix}.projectNotStartedMessage`;
  } else if (day.timesTracked.length > 1) {
    return `${i18prefix}.disabledByProjectsMessage`;
  } else {
    throw new Error('Invalid case: getReadOnlyHintMessageKey');
  }
}
