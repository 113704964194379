import * as React from 'react';

const CalendarClearOutline = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M11.9983 1.50317H2.00062C1.17239 1.50317 0.500977 2.17459 0.500977 3.00282V12.0007C0.500977 12.8289 1.17239 13.5003 2.00062 13.5003H11.9983C12.8265 13.5003 13.4979 12.8289 13.4979 12.0007V3.00282C13.4979 2.17459 12.8265 1.50317 11.9983 1.50317Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinejoin="round"
      />
      <path
        d="M3.00039 0.503662V1.50343M10.9985 0.503662V1.50343M13.4979 4.00283H0.500977"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default CalendarClearOutline;
