import React, { useCallback, useEffect, useState } from 'react';
import { routes as mainRoutes } from '../../../routes';
import { Card } from 'react-bootstrap';
import { WorkflowItemsFilterForMeConnected } from '../Filters/WorkflowItemsFilterForMeConnected';
import ServerError from '../../../../../components/ServerError';
import { Toggle } from '../Common/Toggle';
import { WorkflowItemSidebar } from '../WorkflowItemSidebar/WorkflowItemSidebar/WorkflowItemSidebar';
import { CardHeaderWithFilter } from '../Common/CardHeaderWithFilter';
import { Maybe } from '../../../../../utils/maybe';
import { Item } from './WorkflowItemsTable';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import {
  WorkflowContext,
  useWorkflowsItemsQuery,
} from '../../../__generated__/graphql';
import {
  mapItemsSortBy,
  mapWorkflowItems,
  mapWorkflowItemsFilterInput2,
} from './mappings';
import { WorkflowItemsTableConnected } from './WorkflowItemsTableConnected';
import { emptyWorkflowItemFilterForMe } from '../Filters/types';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { useUserInfo } from '../../../context/UserInfo';

const PAGE_SIZE = 50;

export const WorkflowItemsForMe: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<Maybe<Item>>(null);

  const { id: meId } = useUserInfo();

  const {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  } = useFlairTableWithPaginationAndFilter({
    defaultFilter: emptyWorkflowItemFilterForMe,
    storageKey: 'workflow_items_filter_for_me',
    createRouteUrl: (queryParams) =>
      mainRoutes.workflowsMeWorkflowItemsAll.route
        .withQueryParams(queryParams)
        .create({}),
  });

  const { data, error, loading } = useWorkflowsItemsQuery({
    variables: {
      workflowContext: WorkflowContext.My,
      filter: mapWorkflowItemsFilterInput2(tableState.filter),
      sort: mapItemsSortBy(tableState.sortBy) || null,
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });

  useEffect(() => {
    const totalCount = data?.workflowItems.paginationInfo?.totalCount;
    setTotalItemsCount(totalCount);
  }, [setTotalItemsCount, data]);

  const allWorkflowItems = data ? mapWorkflowItems(data) : undefined;

  const renderContent = useCallback(() => {
    if (loading || !allWorkflowItems || !meId) {
      return <Loading />;
    }
    return (
      <WorkflowItemsTableConnected
        workflowItems={allWorkflowItems}
        employeeColumnVisible={true}
        onItemClick={setSelectedItem}
        pageSize={PAGE_SIZE}
        itemsCount={tableState.totalItemsCount}
        initialSortBy={tableState.sortBy}
        onSortByChanged={handleSortByChanged}
        initialPageIndex={tableState.pageIndex}
        onPageIndexChanged={handlePageIndexChanged}
      />
    );
  }, [
    loading,
    allWorkflowItems,
    meId,
    handleSortByChanged,
    handlePageIndexChanged,
    tableState,
  ]);

  if (error) {
    return <ServerError />;
  }

  const item = allWorkflowItems?.find((x) => x.id === selectedItem?.id) ?? null;

  return (
    <>
      <Card>
        <CardHeaderWithFilter>
          <WorkflowItemsFilterForMeConnected
            filter={tableState.filter}
            onChange={handleFilterChange}
          />
          <Toggle mode="forMe" value="workflowItems" />
        </CardHeaderWithFilter>
        {renderContent()}
      </Card>
      <WorkflowItemSidebar
        workflowId={item?.workflowId ?? null}
        workflowItem={item}
        showComments={selectedItem?.showComments}
        onClose={() => setSelectedItem(null)}
      />
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5} />;
};
