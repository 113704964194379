import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SkeletonCard from '../../../components/Skeleton/Card';
import SkeletonGraph from '../../../components/Skeleton/Graph';

const Loading: React.FC = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <SkeletonGraph />
            </Col>
            <Col md={6}>
              <SkeletonGraph />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <SkeletonCard height={130} />
      <SkeletonCard height={130} />
      <SkeletonCard height={130} />
      <SkeletonCard height={130} />
      <SkeletonCard height={130} />
      <SkeletonCard height={130} />
      <SkeletonCard height={130} />
    </>
  );
};

export default Loading;
