import React from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import Time from '../components/Time';
import { useTranslation } from 'react-i18next';
import { BreakEnded } from '../../../hooks/useCurrentTimeTrackingTimeline';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  step: BreakEnded;
};

const BreakEndedStep: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <ListGroup.Item>
      <Row>
        <Col className="col-auto">
          <div className="avatar avatar-sm">
            <div className="avatar-title fs-3 bg-primary-soft rounded-circle text-primary">
              <FlairIcon
                icon="arrow-back-outline"
                style={{ transform: 'rotate(180deg)' }}
              />
            </div>
          </div>
        </Col>
        <Col className="ms-n2">
          <h5 className="mb-1">
            {t('timeTracking.current.steps.breakEnded.title')}
          </h5>

          <p className="small text-dark mb-0">
            {t('timeTracking.current.steps.breakEnded.breakTime')}{' '}
            <Time minutes={step.duration} format="hh:mm" />
          </p>

          <small className="text-dark">
            <FormattedDateTime dateTime={step.time} format="time" />
          </small>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default BreakEndedStep;
