import { useCallback } from 'react';
import { StringMap, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

type Options = TOptions<StringMap>;
export type TFunc = (key: string, options?: Options) => string;

export const useNamespacedTranslation = (ns: string): TFunc => {
  const { t } = useTranslation();

  return useCallback(
    (key: string, options?: Options) => t(`${ns}.${key}`, options),
    [t, ns],
  );
};
