import React from 'react';
import { EmployeeWithAvatar } from '../../../components/Employee';
import { TimeSheetEmployee } from '../../../components/TimeSheetsTable';

type Props = {
  employee: TimeSheetEmployee;
};

export const EmployeeCell: React.FC<Props> = ({ employee }) => {
  return (
    <EmployeeWithAvatar
      dataTestId="employee-cell"
      employee={{
        name: employee.name,
        avatarUrl: employee.avatarUrl,
      }}></EmployeeWithAvatar>
  );
};
