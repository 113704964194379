import React, { useCallback, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { useCurrencyFormatter } from '../../../../../hooks/useCurrencyFormatter';
import { formatDateShort } from '../../../../../utils/dateUtils';
import { ExpenseStatus } from '../../../__generated__/graphql';
import { ExpenseStatusCell } from '../Common/ExpenseStatusCell';
import { DocumentFile, Expense, ExpenseStatusInfo } from '../types';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  expense: Expense;
  isManager: boolean;
  onStatusChange?: (info: ExpenseStatusInfo) => Promise<void>;
};

const EMPTY_VALUE = '---';

export const ExpenseFields: React.FC<Props> = ({
  expense,
  isManager,
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const readonly = true;
  const id = expense.id;
  const [savingStatus, setSavingStatus] = useState<ExpenseStatus | null>(null);
  const formatCurrency = useCurrencyFormatter();

  const statusInfo: ExpenseStatusInfo = useMemo(
    () => ({
      expenseId: id,
      status: expense.status,
      declineReason: null,
    }),
    [expense.status, id],
  );

  const handleStatusChange = useCallback(
    (status: ExpenseStatus) => {
      if (!onStatusChange) return;

      setSavingStatus(status);

      onStatusChange({ ...statusInfo, status }).then(() =>
        setSavingStatus(null),
      );
    },
    [onStatusChange, statusInfo],
  );

  return (
    <>
      {expense.description && (
        <FormGroup>
          <Form.Label className="fw-bold">
            {t('expenses.expenseSideBar.expenseDescription')}
          </Form.Label>
          <p>{expense.description}</p>
        </FormGroup>
      )}
      <Row>
        <Col>
          <FormGroup>
            <Form.Label className="fw-bold">
              {t('expenses.expenseSideBar.amount')}
            </Form.Label>

            <p>
              {formatCurrency({
                value: expense.amount,
                currency: expense.currency,
              })}
            </p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Form.Label className="fw-bold">
              {t('expenses.expenseSideBar.category')}
            </Form.Label>
            <p>{expense.category?.Name ?? EMPTY_VALUE}</p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Form.Label className="fw-bold">
              {t('expenses.expenseSideBar.merchant')}
            </Form.Label>
            <p>{expense.merchant}</p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Form.Label className="fw-bold">
              {t('expenses.expenseSideBar.expenseDate')}
            </Form.Label>

            <p>{formatDateShort(expense.expenseDate)}</p>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Form.Label className="fw-bold">
              {t('expenses.expenseSideBar.project')}
            </Form.Label>
            <p>{expense.project ? expense.project : EMPTY_VALUE}</p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Form.Label className="fw-bold">
              {t('expenses.expenseSideBar.status')}
            </Form.Label>
            <ExpenseStatusCell
              readonly={readonly}
              onChange={onStatusChange}
              updatingToStatus={null}
              value={statusInfo}
            />
          </FormGroup>
        </Col>
      </Row>
      {expense.documents.length > 0 ? (
        <Row>
          <Col>
            <FormGroup>
              <Form.Label className="fw-bold">
                {t('expenses.expenseSideBar.invoiceAttached')}
              </Form.Label>
              {expense.documents.map((document: DocumentFile) => (
                <div key={document.id}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={document.downloadPublicUrl}>
                    {document.fileName}
                    <span className="ms-2">
                      <FlairIcon size="xs" icon="external-link-outline" />
                    </span>
                  </a>
                </div>
              ))}
            </FormGroup>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {isManager && (
        <Row>
          <Col>
            <FormGroup>
              <Form.Label className="fw-bold">
                {t('expenses.expenseSideBar.requestedBy')}
              </Form.Label>
              <p>{expense.employee.Name}</p>
            </FormGroup>
          </Col>
        </Row>
      )}

      {expense.declineReason && (
        <Row>
          <Col>
            <FormGroup>
              <Form.Label className="fw-bold">
                {t('expenses.expenseSideBar.declineReason')}
              </Form.Label>
              <p>{expense.declineReason}</p>
            </FormGroup>
          </Col>
        </Row>
      )}

      {isManager &&
      expense.status !== ExpenseStatus.Reimbursed &&
      expense.status !== ExpenseStatus.Declined ? (
        <div className="d-flex">
          <Button
            label={t('expenses.buttons.decline')}
            isProcessing={savingStatus === ExpenseStatus.Declined}
            disabled={!!savingStatus}
            size="sm"
            className="me-2"
            variant="outline-danger"
            onClick={() => {
              handleStatusChange(ExpenseStatus.Declined);
            }}
          />
          {expense.status !== ExpenseStatus.Approved ? (
            <Button
              label={t('expenses.buttons.approve')}
              isProcessing={savingStatus === ExpenseStatus.Approved}
              disabled={!!savingStatus}
              size="sm"
              className="me-2"
              variant="outline-primary"
              onClick={() => {
                handleStatusChange(ExpenseStatus.Approved);
              }}
            />
          ) : (
            ''
          )}
          <Button
            size="sm"
            label={t('expenses.buttons.reimbursed')}
            disabled={!!savingStatus}
            isProcessing={savingStatus === ExpenseStatus.Reimbursed}
            onClick={() => {
              handleStatusChange(ExpenseStatus.Reimbursed);
            }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};
