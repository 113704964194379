import React from 'react';
import ServerError from '../../../../components/ServerError';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useGetAuthenticationOptionsQuery } from '../../__generated__/graphql';
import Loading from './Loading';
import LoginPasswordForm from './LoginPasswordForm';
import LoginWithApiToken from './LoginWithApiToken';
import LoginWithSaml from './LoginWithSaml';

const LoginPage: React.FC = () => {
  const { data, loading, error } = useGetAuthenticationOptionsQuery();

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  if (data.getAuthenticationOptions?.saml) {
    return (
      <LoginWithSaml provider={data.getAuthenticationOptions?.saml.name} />
    );
  }

  return <LoginPasswordForm />;
};

const Login: React.FC = () => {
  const { apiToken } = useQueryParams();

  if (apiToken) {
    return <LoginWithApiToken apiToken={apiToken} />;
  }

  return <LoginPage />;
};

export default Login;
