import React from 'react';
import { useCurrentLocale } from '../../../../context/I18n';

type Props = {
  amount: number;
  maximumFractionDigits?: number;
};

const Percentage: React.FC<Props> = ({ amount, maximumFractionDigits }) => {
  const locale = useCurrentLocale();

  return (
    <>
      {Intl.NumberFormat(locale, {
        style: 'percent',
        maximumFractionDigits,
      }).format(amount / 100.0)}
    </>
  );
};

export default Percentage;
