import React from 'react';
import { useAutoBreaksTimeTrackingFeatures } from '../useAutoBreaksTimeTrackingFeatures';
import { BreakingRulesInfoPanelDumb } from './BreakingRulesInfoPanelDumb';
import { mapBreakingRulesInfo } from './mappings';
import { useTimeTrackingSettings } from '../../../hooks/timeTrackingSettings';

export const BreakingRulesInfoPanel: React.FC = () => {
  const { timeFramework } = useTimeTrackingSettings();
  const features = useAutoBreaksTimeTrackingFeatures();
  const breakingRulesInfo = timeFramework
    ? mapBreakingRulesInfo(timeFramework)
    : undefined;

  if (!breakingRulesInfo || !features) {
    return null;
  }

  return <BreakingRulesInfoPanelDumb {...breakingRulesInfo} />;
};
