import React, { useEffect } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useEmployeeAuthorizeUrlMutation } from '../../__generated__/graphql';
import PageHeader from '../../components/PageHeader';
import { Card, Container } from 'react-bootstrap';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

// https://api.slack.com/scopes
const SLACK_USERS_SCOPE = ['users.profile:write'];
const i18prefix = 'components.slackAuthorizeEmployee';

export const SlackAuthorizeEmployee: React.FC = () => {
  const { redirectUrl } = useQueryParams();
  const t = useNamespacedTranslation(i18prefix);

  const successRedirectUrl = `${window.location.origin}${redirectUrl}`;

  const [createInstallationUri, { data }] = useEmployeeAuthorizeUrlMutation({
    variables: {
      userScopes: SLACK_USERS_SCOPE,
      successRedirectUrl,
    },
  });
  const errorHandler = useMutationErrorHandler();

  useEffect(() => {
    createInstallationUri().catch(errorHandler);
  }, [createInstallationUri, errorHandler]);

  useEffect(() => {
    const uri = data?.slack.employeeAuthorizeUrl.installationUri;
    if (uri) {
      window.location.href = uri;
    }
  }, [data]);

  return (
    <>
      <PageHeader title={t('title')}></PageHeader>
      <Container>
        <Card>
          <Card.Body className="text-center">
            <div>{t('authorizing')}</div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
