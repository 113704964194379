import React, { useCallback, useMemo, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useCurrentTimeTrackingTimelineSteps } from '../../../hooks/useCurrentTimeTrackingTimeline';
import { MyTimeTrackingQuery } from '../../../__generated__/graphql';
import BreakEndedStep from './BreakEndedStep';
import BreakStartedStep from './BreakStartedStep';
import ClockInStep from './ClockInStep';
import { ClockoutModals } from './ClockoutModals';
import DuringBreakStep from './DuringBreakStep';
import WorkingStep from './WorkingStep';

type TimeEntry = NonNullable<MyTimeTrackingQuery['me']['currentTimeEntry']>;

const TimeTracking: React.FC<{ entry: TimeEntry }> = ({ entry }) => {
  const [showModalClockoutDate, setShowModalClockoutDate] =
    useState<Date | null>(null);
  const handleCloseModal = useCallback(
    () => setShowModalClockoutDate(null),
    [setShowModalClockoutDate],
  );
  const handleShowModal = useCallback(
    () => setShowModalClockoutDate(new Date()),
    [setShowModalClockoutDate],
  );
  const steps = useCurrentTimeTrackingTimelineSteps(entry);
  const items = useMemo(
    () =>
      steps.map((step) => {
        switch (step.kind) {
          case 'ClockIn': {
            return <ClockInStep step={step} key={`${step.kind}`} />;
          }
          case 'BreakStarted': {
            return (
              <BreakStartedStep
                step={step}
                key={`${step.breakId}-${step.kind}`}
              />
            );
          }
          case 'BreakEnded': {
            return (
              <BreakEndedStep
                step={step}
                key={`${step.breakId}-${step.kind}`}
              />
            );
          }
          case 'DuringBreak': {
            return <DuringBreakStep step={step} key={`${step.kind}`} />;
          }
          case 'Working': {
            return (
              <WorkingStep
                step={step}
                key={`${step.kind}`}
                handleShowModal={handleShowModal}
              />
            );
          }
        }
        return <></>;
      }),
    [steps, handleShowModal],
  );

  return (
    <>
      <ListGroup variant="flush" className="list-group-activity my-n3">
        {items}
      </ListGroup>
      <ClockoutModals
        key={`stop-tracking-modal-${entry.LastModifiedDate}`}
        onClose={handleCloseModal}
        clockOutDate={showModalClockoutDate}
        notes={entry.flair__Notes__c}
      />
    </>
  );
};

export default TimeTracking;
