import * as yup from 'yup';
import {
  CategorySchemaType,
  InventoryDetailsSchemaType,
  InventorySchemaType,
} from './types';

export const categorySchema = yup.object().shape<CategorySchemaType>({
  category: yup.string().required(),
});
export const inventorySchema = yup.object().shape<InventorySchemaType>({
  name: yup.string().nullable().required(),
});
export const inventoryDetailsSchema = yup
  .object()
  .shape<InventoryDetailsSchemaType>({
    manufacturer: yup.string().required(),
    model: yup.string().required(),
    serialNumber: yup.string().required(),
  });
