import styled from '@emotion/styled';
import moment from 'moment';
import React from 'react';
import { Theme } from '../../../../../../theme';
import {
  DateInterval,
  toFormattedDateInterval,
} from '../../../../../../utils/dateInterval';
import DurationString from '../../Common/DurationString';
import { getNotAvailableIcon } from '../../Common/logic';
import ShiftLabelsTags from '../../Common/ShiftsPopover/ShiftLabelsTags';
import {
  OpenShiftNotAvailableReason,
  OpenShiftState,
  ShiftLabelInfo,
  ShiftType,
} from '../../Common/types';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = DateInterval & {
  type: ShiftType;
  blockInfo?: DateInterval;
  state?: OpenShiftState;
  notAvailableReason?: OpenShiftNotAvailableReason;
  shiftLabels: ShiftLabelInfo[];
};

const MainLabelContainer = styled.div<{ small: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    paddingInlineStart: '5px',
  },
  ({ small }) => ({
    paddingBlockStart: small ? 'unset' : '5%',
  }),
);

const HiddenDetails = styled.div({
  marginTop: '-8px',
  fontSize: Theme.font.size.sm,
});

const ShiftMainLabel: React.FC<Props> = ({
  start,
  end,
  blockInfo,
  type,
  state,
  notAvailableReason,
  shiftLabels,
}) => {
  const formattedInterval = toFormattedDateInterval({ start, end });
  let startDate = blockInfo ? blockInfo.start : start;
  let endDate = blockInfo ? blockInfo.end : end;
  const blockDurationInMinutes = moment
    .duration(endDate.diff(startDate))
    .asMinutes();

  let isEmployeeShift = type === 'employee-shift';
  let isRequestedOpenShift = state && state === 'requested';

  return (
    <MainLabelContainer small={blockDurationInMinutes <= 15}>
      {(isEmployeeShift || isRequestedOpenShift) &&
      blockDurationInMinutes <= 30 ? (
        <HiddenDetails className="fw-bold">...</HiddenDetails>
      ) : (
        <>
          <div className="fw-bold">
            <time>
              {formattedInterval.start.time} - {formattedInterval.end.time}
            </time>
          </div>
          <div>
            (<DurationString start={start} end={end} />)
            {state && state === 'not-available' && (
              <FlairIcon
                icon={getNotAvailableIcon(notAvailableReason!)}
                color={Theme.color.blue1}
                style={{ marginLeft: '10px' }}
              />
            )}
          </div>

          {shiftLabels && (
            <div className="d-flex mt-3 mb-2">
              <ShiftLabelsTags shiftLabels={shiftLabels} />
            </div>
          )}
        </>
      )}
    </MainLabelContainer>
  );
};

export default ShiftMainLabel;
