import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ServerError from '../../../../../components/ServerError';
import {
  MyTimeSheetDocument,
  MyTimeTrackingDocument,
  WorkloadType,
  useMyTimeSheetQuery,
} from '../../../__generated__/graphql';
import TimeTrackingEntries from '../TimeTrackingEntries';
import ChartsRow from './ChartsRow';
import Loading from './Loading';
import Nav from './Nav';
import NoTimeSheet from './NoTimeSheet';

import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { getIntervalByParams } from './utils';
import { DateInterval } from '../../../../../utils/dateIntervalUtils';

const DATE_PARAM = 'startDate';
const WORKLOAD_PARAM = 'workloadType';

type Params = {
  fromDate: Date;
  toDate: Date;
  workloadType?: string;
};

const useFilterParams = (): DateInterval => {
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const dateParam = query.get(DATE_PARAM);
  const workloadParam = query.get(WORKLOAD_PARAM);

  return useMemo(
    () => getIntervalByParams(new Date(), dateParam, workloadParam),
    [dateParam, workloadParam],
  );
};

const CardHeader: React.FC<Pick<Params, 'fromDate' | 'workloadType'>> = ({
  fromDate,
  workloadType,
}) => {
  const { t } = useTranslation();

  return useMemo(
    () => (
      <Card.Header>
        <Row>
          <Col>
            <h4 className="mt-2">{t('timeTracking.overview.title')}</h4>
          </Col>
          <Col className="col-auto text-end">
            <Nav
              fromDate={fromDate}
              dateFromName={DATE_PARAM}
              workloadType={workloadType}
            />
          </Col>
        </Row>
      </Card.Header>
    ),
    [fromDate, t, workloadType],
  );
};

export const createTimeSheetPageQueryParams = ({
  startDate,
  workloadType,
}: {
  startDate: string;
  workloadType: WorkloadType;
}) => ({
  startDate,
  workloadType,
});

const MyTimeSheet: React.FC = () => {
  const { start: fromDate, end: toDate } = useFilterParams();
  const { workloadType: workloadTypeParam } = useQueryParams();

  const { data, loading, error } = useMyTimeSheetQuery({
    variables: {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    },
  });

  const timeSheetWorkloadType =
    data?.myTimeSheets[0]?.workloads[0]?.flair__Type__c;

  const workloadType =
    timeSheetWorkloadType ?? workloadTypeParam ?? WorkloadType.Weekly;

  //TODO:: stop using refetchQueries
  const refetchQueries = [
    {
      query: MyTimeTrackingDocument,
    },
    {
      query: MyTimeSheetDocument,
      variables: {
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      },
    },
  ];
  const timeSheet = data?.myTimeSheets[0];

  const cardHeaderProps = {
    fromDate,
    workloadType,
  };

  const cardHeader = <CardHeader {...cardHeaderProps} />;

  if (loading && data?.myTimeSheets[0] === undefined) return <Loading />;

  if (error) return <ServerError />;

  if (!timeSheet) {
    return (
      <Card>
        {cardHeader}
        <Card.Body>
          <NoTimeSheet workloadType={workloadType} />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card>
        {cardHeader}
        <Card.Body>
          <ChartsRow timeSheet={timeSheet} />
        </Card.Body>
      </Card>
      <TimeTrackingEntries
        timeSheet={timeSheet}
        costCenters={data?.costCenters ?? []}
        refetchQueriesForEmployeeMode={refetchQueries}
      />
    </>
  );
};

export default MyTimeSheet;
