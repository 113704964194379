import { useDurationFormatter } from '../../../hooks/useDurationFormatter';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { hoursToMinutes } from '../../../utils/time';

export const useHourlyAbsenceAmountFormatter = () => {
  const { t } = useTranslation();
  const durationFormatter = useDurationFormatter(t);

  return useCallback(
    (hours: number) => durationFormatter(hoursToMinutes(hours)),
    [durationFormatter],
  );
};
