import React from 'react';
import { iconToCategoryTypeTheme } from '../AbsenceCalendar/Utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
  icon: string | null;
  className?: string;
  tooltip?: React.ReactNode;
};

const Icon: React.FC<Pick<Props, 'icon' | 'className'>> = ({
  icon,
  className,
}) => {
  const { icon: categoryIcon } = iconToCategoryTypeTheme(icon);

  return <i className={['fe', categoryIcon, className].join(' ')} />;
};

const AbsenceCategoryIcon: React.FC<Props> = ({ icon, className, tooltip }) =>
  tooltip ? (
    <OverlayTrigger
      overlay={
        <Tooltip id={`absence-category-icon-${icon}`}>{tooltip}</Tooltip>
      }>
      <span className={className}>
        <Icon icon={icon} />
      </span>
    </OverlayTrigger>
  ) : (
    <Icon icon={icon} className={className} />
  );

export default AbsenceCategoryIcon;
