import React from 'react';
import { Button, ButtonProps, Dropdown, DropdownProps } from 'react-bootstrap';
import FlairIcon from '../../atoms/FlairIcon';

export type DropdownActionsProps = Pick<DropdownProps, 'drop'> & {
  id: string;
  icon?: React.ReactNode;
  className?: string;
  iconClassName?: string;
  dataTestId?: string;
  show?: boolean;
};

type DropDownToggleProps = ButtonProps & {
  icon?: React.ReactNode;
  iconClassName?: string;
};

export const DropdownActions: React.FC<DropdownActionsProps> = ({
  id,
  children,
  icon,
  className,
  iconClassName,
  drop = 'start',
  dataTestId,
  show,
}) => (
  <Dropdown
    show={show}
    className={className}
    drop={drop}
    data-testid={dataTestId}>
    <Dropdown.Toggle
      id={id}
      data-testid={`${id}-toggle-btn`}
      icon={icon}
      iconClassName={iconClassName}
      as={DropdownToggle}
    />
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
);

const DropdownToggle = React.forwardRef<HTMLButtonElement, DropDownToggleProps>(
  ({ children, icon, iconClassName, className, onClick, ...rest }, ref) => (
    <Button
      {...rest}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
      variant="link"
      className="dropdown-ellipses p-0">
      {icon ?? (
        <FlairIcon
          icon="ellipsis-vertical-circle-outline"
          className={iconClassName}
          size="lg"
        />
      )}
    </Button>
  ),
);
