import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from '../Modal';
import Button from '../button';

export type ConfirmModalSimpleProps = {
  visible: boolean;
  message?: string;
  submitInProgress?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const ConfirmModalSimple: React.FC<ConfirmModalSimpleProps> = ({
  visible,
  submitInProgress,
  onClose,
  message,
  onSubmit,
}: ConfirmModalSimpleProps) => {
  const { t } = useTranslation();

  return (
    <Modal show={visible} onHide={onClose} centered size="sm">
      <Modal.Body>
        <div>{message ?? t('form.general.defaultConfirmationText')}</div>
      </Modal.Body>
      <ModalFooter>
        <Button
          size="sm"
          onClick={onClose}
          variant="outline-secondary"
          label={t('form.general.cancelButton')}
        />

        <Button
          size="sm"
          isProcessing={submitInProgress}
          onClick={onSubmit}
          label={t('form.general.confirmButton')}
        />
      </ModalFooter>
    </Modal>
  );
};
