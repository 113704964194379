import { Theme } from '../../../../../../theme';

export const top10keyRequirementsFields = [
  'key_requirement_1',
  'key_requirement_2',
  'key_requirement_3',
  'key_requirement_4',
  'key_requirement_5',
  'key_requirement_6',
  'key_requirement_7',
  'key_requirement_8',
  'key_requirement_9',
  'key_requirement_10',
] as const;

// 'key_requirement_1' | 'key_requirement_2' | ...
export type Top10keyRequirementsFieldsType =
  (typeof top10keyRequirementsFields)[number];

export type JobKeyRequirement = {
  key_requirement_description: string;
  key_requirement_importance_score: number;
};

// {key_requirement_1: JobKeyRequirement, key_requirement_2: JobKeyRequirement, ...}
export type JobKeyRequirements = {
  // eslint-disable-next-line no-unused-vars
  [key in Top10keyRequirementsFieldsType]: JobKeyRequirement;
};

export type JobKeyRequirementMatch = {
  does_candidate_satisfies: boolean;
  explanation: string;
  reference_from_resume: string;
};

export type JobKeyRequirementMatches = {
  // eslint-disable-next-line no-unused-vars
  [key in Top10keyRequirementsFieldsType]: JobKeyRequirementMatch;
};

export const flairAIMatchingScoreColor = (score: number) => {
  if (score < 30) {
    return Theme.colorHex.red3;
  } else if (score <= 70) {
    return Theme.colorHex.honey1;
  } else {
    return Theme.colorHex.teal2;
  }
};
