import * as React from 'react';

const AddCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.9972 7.9981C13.9972 4.68638 11.3103 1.99951 7.99859 1.99951C4.68687 1.99951 2 4.68638 2 7.9981C2 11.3098 4.68687 13.9967 7.99859 13.9967C11.3103 13.9967 13.9972 11.3098 13.9972 7.9981Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
      <path
        d="M10.4959 7.99819H5.49707M7.99648 5.49878V10.4976V5.49878Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default AddCircleOutlineIcon;
