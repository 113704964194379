import React, { useEffect } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import styled from '@emotion/styled';

type Props = {
  data: ChartData<'radar'>;
  options: ChartOptions<'radar'>;
};

const Radar: React.FC<Props> = ({ data, options }) => {
  const chartRef = React.createRef<HTMLCanvasElement>();
  const chart = React.useRef<Chart>();

  useEffect(() => {
    if (chartRef.current) {
      const myChartRef = chartRef.current.getContext('2d');

      if (myChartRef) {
        chart.current = new Chart(myChartRef, {
          data: data,
          type: 'radar',
          options: options,
        });
      }
    }

    return () => {
      chart.current?.destroy();
    };
  }, [chartRef, data, chart, options]);

  const StyledChart = styled.div`
    height: 250px;
  `;

  return (
    <StyledChart className="radar">
      <canvas className="chart-canvas" ref={chartRef} />
    </StyledChart>
  );
};

export default Radar;
