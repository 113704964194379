import React, { useContext, useMemo } from 'react';
import { Accordion, AccordionContext, Col, Row } from 'react-bootstrap';
import { AbsenceApprovalStatus } from '../../__generated__/graphql';
import { groupCategoriesByGroup, GroupWithCategories } from './GroupCategories';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import type {
  CategoryType,
  CategoriesAccordionProps,
  CategoryAccordionProps,
} from './types';
import { CategoryAccordionHeader } from './components/CategoryAccordionHeader';
import { CategoryAccordionBodyConnected } from './components/CategoryAccordionBody';

const CategoryAccordion: React.FC<CategoryAccordionProps> = ({
  category,
  hideRequestButton,
  categories,
  isInGroup = false,
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const eventKey = category.Id;
  const active = activeEventKey === eventKey;

  return (
    <Accordion.Item eventKey={category.Id}>
      <Accordion.Header>
        <CategoryAccordionHeader
          category={category}
          active={active}
          isInGroup={isInGroup}
        />
      </Accordion.Header>

      <Accordion.Collapse eventKey={category.Id} className="border-top">
        <CategoryAccordionBodyConnected
          isActive={active}
          category={category}
          hideRequestButton={hideRequestButton}
          categories={categories}
        />
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

const GroupAccordionHeader: React.FC<
  Pick<GroupWithCategories<CategoryType>, 'name'>
> = ({ name }) => {
  return (
    <Row>
      <Col>
        <FlairIcon icon="folder-outline" /> <span className="ms-3">{name}</span>
      </Col>
    </Row>
  );
};

type CategoryGroupAccordionProps = Pick<
  CategoriesAccordionProps,
  'absencesCurrentYear' | 'hideRequestButton'
> & {
  group: Pick<GroupWithCategories<CategoryType>, 'key' | 'name' | 'categories'>;
};

const CategoryGroupAccordion: React.FC<CategoryGroupAccordionProps> = ({
  group,
  ...rest
}) => {
  return (
    <Accordion.Item eventKey={group.key}>
      <Accordion.Header>
        <GroupAccordionHeader name={group.name} />
      </Accordion.Header>

      <Accordion.Collapse eventKey={group.key}>
        <Accordion className="CategoriesAccordion">
          <CategoriesAccordion
            categories={group.categories}
            isGroup={true}
            {...rest}
          />
        </Accordion>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

const CategoriesAccordion: React.FC<CategoriesAccordionProps> = ({
  categories,
  hideRequestButton = false,
  isGroup = false,
  absencesCurrentYear,
}) => {
  const filteredAbsencesCurrentYear = useMemo(
    () =>
      absencesCurrentYear?.filter(
        (a) => a.flair__Approval_Status__c !== AbsenceApprovalStatus.Rejected,
      ) || [],
    [absencesCurrentYear],
  );

  const groupedData = groupCategoriesByGroup<CategoryType>(categories);
  if (isGroup && groupedData.length > 0) {
    groupedData[0].isGroup = false; //now we're in a 2nd level where it's not a group any more(relatively)
  }

  return categories && categories.length > 0 ? (
    <Accordion className="CategoriesAccordion">
      {groupedData &&
        groupedData.length &&
        groupedData.map((g) =>
          g.isGroup && g.id ? (
            <CategoryGroupAccordion
              key={g.id}
              group={g}
              absencesCurrentYear={absencesCurrentYear}
              hideRequestButton={hideRequestButton}
            />
          ) : (
            g.categories.map((category) => (
              <CategoryAccordion
                absencesCurrentYear={filteredAbsencesCurrentYear}
                category={category}
                key={category.Id}
                hideRequestButton={hideRequestButton}
                categories={categories}
                isInGroup={isGroup}
              />
            ))
          ),
        )}
    </Accordion>
  ) : (
    <></>
  );
};

export default CategoriesAccordion;
