import React from 'react';
import { EmployeeShift } from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { ShiftContainer } from './BlockContainer';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import { ShiftLabel } from './ShiftLabel';
import ShiftLabelsTags from '../../components/Shifts/Common/ShiftsPopover/ShiftLabelsTags';
import { mapShiftLabel } from '../../components/Shifts/shiftsMappings';

type Shift = Pick<
  EmployeeShift,
  | 'flair__Start_Datetime__c'
  | 'flair__End_Datetime__c'
  | 'flair__Total_Break_Period_in_Minutes__c'
  | 'location'
  | 'shiftLabels'
>;

type Props = {
  currentLocationId: string | null;
  shifts: Shift[];
  slotsPerDay: number;
};

const Small = styled.small`
  font-size: 0.7rem;
`;

const Block: React.FC<{
  currentLocationId: string | null;
  shift: Shift;
  slotsPerDay: number;
}> = ({ currentLocationId, shift, slotsPerDay }) => {
  const { t } = useTranslation();
  const {
    flair__Start_Datetime__c,
    flair__End_Datetime__c,
    flair__Total_Break_Period_in_Minutes__c,
    location,
    shiftLabels,
  } = shift;
  const showLocationName = location && currentLocationId !== location.Id;

  return (
    <ShiftContainer slotsPerDay={slotsPerDay}>
      <Row className="h-100 align-items-center">
        <Col>
          <Row>
            <Col>
              <ShiftLabel
                start={flair__Start_Datetime__c}
                end={flair__End_Datetime__c}
              />
            </Col>
          </Row>
          {showLocationName && (
            <Row>
              <Col>
                <Small>@ {location?.Name}</Small>
              </Col>
            </Row>
          )}
          {flair__Total_Break_Period_in_Minutes__c > 0 && (
            <Row>
              <Col>
                <Small>
                  {flair__Total_Break_Period_in_Minutes__c}{' '}
                  {t('general.shortMinutes')}
                </Small>
              </Col>
            </Row>
          )}
          {shiftLabels.length > 0 && (
            <div className="mb-3 align-items-center">
              <ShiftLabelsTags shiftLabels={shiftLabels.map(mapShiftLabel)} />
            </div>
          )}
        </Col>
      </Row>
    </ShiftContainer>
  );
};

const ShiftBlock: React.FC<Props> = ({
  currentLocationId,
  shifts,
  slotsPerDay,
}) => {
  return (
    <>
      {shifts.map((shift, i) => (
        <Block
          key={i}
          currentLocationId={currentLocationId}
          shift={shift}
          slotsPerDay={slotsPerDay}
        />
      ))}
    </>
  );
};

export default ShiftBlock;
