import { useCallback, useState } from 'react';
import {
  NavigatableErrorCode,
  NavigatableIdInput,
  useMoveDocumentsToMutation,
} from '../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useToasts } from '../../../../../context/Toast';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { TableItem } from '../Tables/PersonalDocumentsTable';

const i18Path = 'documents2.personal';

type Props = {
  employeeId: string;
};

export const useDocumentMoveTo = ({ employeeId }: Props) => {
  const t = useNamespacedTranslation(i18Path);
  const { addSuccess, addError } = useToasts();
  const [moveDocuments, { loading: moveInProgress }] =
    useMoveDocumentsToMutation();
  const [rowsToMove, setRowsToMove] = useState<NavigatableIdInput[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const errorHandler = useMutationErrorHandler();

  const handleMoveToFolder = (row: NavigatableIdInput) => {
    setRowsToMove([...rowsToMove, row]);
  };

  const handleMassMove = (selectedRows: TableItem[]) => {
    const items = selectedRows.map((item: TableItem) => {
      return { id: item.id, type: item.typeName };
    });
    setRowsToMove([...items]);
  };

  const move = useCallback(
    (resetStateCallback) => {
      if (!rowsToMove.length || !selectedFolderId) {
        return;
      }
      moveDocuments({
        variables: {
          employeeId,
          navigatableIds: rowsToMove,
          moveTo: { folderId: selectedFolderId },
        },
      })
        .then((error) => {
          if (error.data?.document.move.errorCode) {
            if (
              error.data?.document.move.errorCode ===
              NavigatableErrorCode.NameAlreadyExists
            ) {
              addError(t('documentMovedToast.nameAlreadyExists'));
              return;
            }
            addError(error.data?.document.move.errorCode);
            return;
          }
          const toastMessage =
            rowsToMove.length > 1
              ? t('documentMovedToast.single')
              : t('documentMovedToast.multiple', { count: rowsToMove.length });
          addSuccess(toastMessage);
        })
        .catch(errorHandler)
        .finally(() => {
          handleMoveToModalClose();
          resetStateCallback();
        });
    },
    [
      addError,
      addSuccess,
      errorHandler,
      employeeId,
      moveDocuments,
      rowsToMove,
      selectedFolderId,
      t,
    ],
  );

  const handleMoveToModalClose = () => {
    setRowsToMove([]);
    setSelectedFolderId(null);
  };

  return {
    rowsToMove,
    moveInProgress,
    handleMoveToFolder,
    handleMoveToModalClose,
    setSelectedFolderId,
    selectedFolderId,
    move,
    handleMassMove,
    isMoveModalVisible: !!rowsToMove.length,
  };
};
