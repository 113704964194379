import * as React from 'react';

const StopOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M12.25 3H3.75C3.33579 3 3 3.33579 3 3.75V12.25C3 12.6642 3.33579 13 3.75 13H12.25C12.6642 13 13 12.6642 13 12.25V3.75C13 3.33579 12.6642 3 12.25 3Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default StopOutlineIcon;
