import { isEqual } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/button';
import { getError } from '../../../../../../../components/form/utils';
import { GoalStatusWithRate } from '../types';
import {
  GoalStatusInputWithRate,
  Props as GoalStatusInputWithRateProps,
} from './GoalStatusInputWithRate';

export type Props = GoalStatusInputWithRateProps;

export const GoalStatusInputWithRateAndSave: React.FC<Props> = ({
  value,
  onChange,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [curValue, setCurValue] = useState(value);

  const handleChange = useCallback(
    (newValue: GoalStatusWithRate) => {
      const newStatus = newValue.status;
      const isImmediateUpdate =
        newStatus === 'ON_TRACK' ||
        newStatus === 'BEHIND' ||
        newStatus === 'BEHIND_OR_ON_TRACK' ||
        (newStatus === 'DONE' && !restProps.isRateVisible);

      if (value.status !== newStatus && isImmediateUpdate) {
        const rate =
          newStatus === 'ON_TRACK' ||
          newStatus === 'BEHIND' ||
          newStatus === 'BEHIND_OR_ON_TRACK'
            ? 'OPEN'
            : value.rate;
        onChange({
          status: newStatus,
          cancelReason: undefined,
          rate,
        });
        return;
      }
      setCurValue(newValue);
    },
    [value, onChange, setCurValue, restProps.isRateVisible],
  );

  const handleCancel = useCallback(() => {
    setCurValue(value);
  }, [setCurValue, value]);

  const handleSave = useCallback(() => {
    onChange(curValue);
  }, [onChange, curValue]);

  const isDirty = !isEqual(value, curValue);

  return (
    <div>
      <GoalStatusInputWithRate
        {...restProps}
        value={curValue}
        onChange={handleChange}
        availableStatuses={isDirty ? [] : restProps.availableStatuses}
      />

      {(isDirty || restProps.error) && (
        <div className="d-flex justify-content-end mt-2">
          <Button
            label={t('form.general.cancelButton')}
            size="sm"
            className="me-2"
            variant="outline-primary"
            onClick={handleCancel}
          />
          <Button
            size="sm"
            label={t('form.general.saveButton')}
            onClick={handleSave}
          />
        </div>
      )}
    </div>
  );
};

type ControlledProps = Omit<Props, 'value' | 'onChange'> & { name: string };

export const GoalStatusInputWithRateAndSaveControlled: React.FC<
  ControlledProps
> = ({ name, ...props }) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  return (
    <Controller
      as={({ value, onChange }) => {
        return (
          <GoalStatusInputWithRateAndSave
            {...props}
            value={value}
            onChange={onChange}
            error={error}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};
