import React from 'react';
import { Col } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import PageHeader, { PageHeaderRow } from '../../components/PageHeader';
import PageHeaderNav, { TabItem } from '../../components/PageHeaderNav';
import { routes } from '../../routes';
import { i18DocumentsPrefix } from './common';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

export const DocumentsHeader: React.FC = () => {
  const t = useNamespacedTranslation(i18DocumentsPrefix);
  const tNavigationMenu = useNamespacedTranslation('navigation.menuItems');

  useFlairBreadcrumbHook([
    { label: tNavigationMenu('profile.title') },
    { label: tNavigationMenu('profile.subMenuItems.documents.title') },
  ]);

  const tabItems: TabItem[] = [
    {
      label: t('tabs.personal'),
      route: routes.documentsPersonal.route,
    },
    {
      label: t('tabs.corporate'),
      route: routes.documentsCompany.route,
    },
  ];

  return (
    <PageHeader title={t('title')}>
      <PageHeaderRow>
        <Col>
          <PageHeaderNav tabItems={tabItems} />
        </Col>
      </PageHeaderRow>
    </PageHeader>
  );
};
