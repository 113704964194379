import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import { EmptyState } from '../../../components/EmptyStateCard';
import { Star } from '../../../components/Star';
import { TableContent } from '../../../manager/components/TableContent';
import { SkillLevelSelect } from '../common/SkillLevelSelect';
import { SkillNameWithIcon } from '../common/SkillNameWithIcon';
import { EmployeeSkillAssociation, SkillLevel } from './types';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';

type Props = {
  employeeSkills: EmployeeSkillAssociation[];
  onChooseSkill?: (skillId: string, isChoosen: boolean) => void;
  maxTopLimit?: number;
  onChangeSkillLevel: (skillId: string, level: SkillLevel) => void;
  onRemoveSkill: (skillId: string) => void;
  isReadonly: boolean;
};

type StarWrapperProps = {
  isSelected: boolean;
  isReadonly: boolean;
  skill: EmployeeSkillRow;
  onChooseSkill: (skillId: string, isChoosen: boolean) => void;
};

type EmployeeSkillRow = {
  id: string;
  name: string;
  level: SkillLevel | undefined;
  isRateable: boolean;
  isTop: boolean;
  isTopLimitReached: boolean | undefined;
};

export const EmployeeSkillsTable: React.FC<Props> = ({
  maxTopLimit,
  employeeSkills,
  onChooseSkill,
  onChangeSkillLevel,
  onRemoveSkill,
  isReadonly,
}) => {
  const { t } = useTranslation();

  const columns: Column<EmployeeSkillRow>[] = useMemo(() => {
    const chooseColumn = new Array<Column<EmployeeSkillRow>>();
    if (onChooseSkill) {
      chooseColumn.push({
        Header: (
          <Star
            isSelected={false}
            tooltip={t('skills.employeeSkills.table.choosen')}
          />
        ),
        accessor: 'isTop',
        sortType: 'basic',
        Cell: ({ value, row }) => {
          return (
            <StarWrapper
              isSelected={value}
              isReadonly={isReadonly}
              skill={row.original}
              onChooseSkill={onChooseSkill}
            />
          );
        },
      });
    }

    return [
      ...chooseColumn,
      {
        Header: <Trans t={t} i18nKey="skills.employeeSkills.table.skill" />,
        accessor: 'name',
        sortType: 'caseInsensitive',
        Cell: ({ value, row }) => (
          <SkillNameWithIcon rateable={row.original.isRateable} name={value} />
        ),
      },
      {
        Header: <Trans t={t} i18nKey="skills.employeeSkills.table.level" />,
        accessor: 'level',
        sortType: levelSort,
        Cell: ({ row }) => {
          return (
            <SkillLevelSelectCell
              isReadonly={isReadonly}
              skill={row.original}
              onChangeSkillLevel={onChangeSkillLevel}
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        getCellProps: () => {},
        Cell: ({ value }) => (
          <div className="text-end">
            <DropdownActions id="skill">
              <DropdownActionItem
                onClick={() => onRemoveSkill(value)}
                disabled={isReadonly}>
                {t('skills.employeeSkills.unassign')}
              </DropdownActionItem>
            </DropdownActions>
          </div>
        ),
      },
    ];
  }, [t, onChooseSkill, onRemoveSkill, onChangeSkillLevel, isReadonly]);

  const topCount = employeeSkills.reduce(
    (acc, cur) => acc + (cur.isTop ? 1 : 0),
    0,
  );
  const isTopLimitReached = maxTopLimit ? topCount >= maxTopLimit : undefined;

  const data = useMemo(
    () => employeeSkills.map((x) => mapRow(x, isTopLimitReached)),
    [employeeSkills, isTopLimitReached],
  );

  if (!data.length) {
    return <Empty />;
  }

  return (
    <TableContent
      columns={columns}
      data={data}
      getCellProps={(cellInfo) =>
        cellInfo.column.id !== 'name' ? { className: 'p-0' } : {}
      }
      initialSortBy={[{ id: 'name' }]}
    />
  );
};

const mapRow = (
  src: EmployeeSkillAssociation,
  isTopLimitReached: boolean | undefined,
): EmployeeSkillRow => ({
  id: src.id,
  name: src.name,
  isRateable: src.isRateable,
  level: src.isRateable ? src.level : undefined,
  isTop: src.isTop,
  isTopLimitReached,
});

const SkillLevelSelectCell: React.FC<{
  isReadonly: boolean;
  skill: EmployeeSkillRow;
  onChangeSkillLevel: (skillId: string, level: SkillLevel) => void;
}> = ({ isReadonly, skill, onChangeSkillLevel }) => {
  const handleOnChange = useCallback(
    (level: SkillLevel) => {
      onChangeSkillLevel(skill.id, level);
    },
    [skill, onChangeSkillLevel],
  );

  if (!skill.isRateable) {
    return null;
  }
  return (
    <SkillLevelSelect
      value={skill.level ?? null}
      isDisabled={isReadonly}
      onChange={handleOnChange}
    />
  );
};

const Empty: React.FC = () => {
  const t = useNamespacedTranslation('skills.employeeSkills');
  return (
    <div className="d-flex small flex-column align-items-center p-3">
      <EmptyState title={t('empty')} />
    </div>
  );
};

const StarWrapper: React.FC<StarWrapperProps> = ({
  isSelected,
  isReadonly,
  skill,
  onChooseSkill,
}) => {
  const { t } = useTranslation();

  const disabled =
    (!isSelected && skill.isTopLimitReached) ||
    (!isSelected && !skill.level) ||
    !skill.isRateable ||
    isReadonly;

  const tooltip = useMemo(() => {
    if (!isSelected) {
      if (skill.level) {
        if (skill.isTopLimitReached) {
          return t('skills.employeeSkills.maxNumberOfSelectedSkillsTooltip');
        }
      } else {
        if (skill.isRateable) {
          return t('skills.employeeSkills.skillMustHaveLevelTooltip');
        } else {
          return t('skills.employeeSkills.skillMustBeRatedTooltip');
        }
      }
    }
  }, [isSelected, skill, t]);

  return (
    <Star
      isSelected={isSelected}
      disabled={disabled}
      onChange={(val) => onChooseSkill(skill.id, val)}
      tooltip={tooltip}
    />
  );
};

export const levelSort = (
  rowA: Row<EmployeeSkillRow>,
  rowB: Row<EmployeeSkillRow>,
) => {
  const a = rowA.original.level;
  const b = rowB.original.level;

  const aWeight = getSkillLevelSortWeight(a);
  const bWeight = getSkillLevelSortWeight(b);
  return aWeight > bWeight ? 1 : bWeight > aWeight ? -1 : 0;
};

const getSkillLevelSortWeight = (level: SkillLevel | undefined): number => {
  if (level === undefined) {
    return -1;
  }
  if (level === null) {
    return 0;
  }
  return level;
};
