import React from 'react';
import { useParams } from 'react-router-dom';
import ServerError from '../../../../../../components/ServerError';
import { PastPerformanceReviewDetails } from '../../../../components/PastPerformanceReviewDetails';
import {
  FeedbackQuestionType,
  usePastEmployeeFeedbackDetailsQuery,
} from '../../../../__generated__/graphql';
import { Loading } from './Loading';

type Params = {
  feedbackId: string;
};

const Content: React.FC<Params> = ({ feedbackId }) => {
  const { data, loading, error } = usePastEmployeeFeedbackDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      feedbackId,
    },
  });

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const {
    answers,
    feedbackCycle,
    employeeFeedback,
    outcome,
    reviewee: { Id: employeeId, Name: employeeName },
  } = data.employeeFeedback;

  if (!employeeFeedback) {
    return null;
  }

  const seeAllQuestions = feedbackCycle.flair__See_All_Questions__c;

  let feedbackCycleQuestions = feedbackCycle.questions.filter(
    (item) =>
      item.flair__Feedback_Question_Type__c !== FeedbackQuestionType.YesNo &&
      item.flair__Feedback_Question_Visibility__c !== 'PEERS',
  );

  if (!seeAllQuestions) {
    feedbackCycleQuestions = feedbackCycleQuestions.filter((question) =>
      question.flair__Feedback_Question_Visibility__c.includes('MANAGER'),
    );
  }

  const props = {
    outcomeId: outcome.Id,
    employeeName: employeeName,
    employeeId: employeeId,
    managerId: outcome.reviewer.Id,
    managerName: outcome.reviewer.Name,
    feedbackCycle,
    questions: feedbackCycleQuestions,
    employeeAnswers: employeeFeedback.answers,
    managerAnswers: answers,
    resolutions: outcome.resolutions,
    manageGoals: feedbackCycle.flair__Manage_Goals__c,
    canSign: false,
    isFinishing: false,
    onFinish: () => Promise.resolve(),
    employeeFeedback: data.employeeFeedback,
  };

  // rendering for the reviewer(Manager)
  return <PastPerformanceReviewDetails {...props} />;
};

export const PastEmployeeFeedbackPage: React.FC = () => {
  const { feedbackId } = useParams<Params>();

  return (
    <>
      <Content feedbackId={feedbackId} />
    </>
  );
};
