import {
  TimeBalanceCalculationMode,
  TimeBalanceSettingsFragment,
} from '../../__generated__/graphql';
import { useTimeTrackingSettingsAllDays } from './useTimeTrackingSettingsAllDays';

export const useIsTimeBalanceEnabled = (): boolean => {
  const { getAllTimeTrackingSettings } = useTimeTrackingSettingsAllDays();

  const timeTrackingSettings = getAllTimeTrackingSettings();
  if (!timeTrackingSettings) {
    return false;
  }
  return timeTrackingSettings.some(
    (settings) =>
      settings.timeBalanceSettings &&
      isTimeBalanceCalculationEnabled(settings.timeBalanceSettings),
  );
};

export const isTimeBalanceCalculationEnabled = (
  src: Pick<TimeBalanceSettingsFragment, 'flair__Calculation_Mode__c'>,
): boolean => {
  switch (src.flair__Calculation_Mode__c) {
    case TimeBalanceCalculationMode.AfterSpecificTime:
    case TimeBalanceCalculationMode.BasedOnDayTargetTime:
      return true;
    default:
      return false;
  }
};
