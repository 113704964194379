import { useApproveAbsenceRequestMutation } from '../../__generated__/graphql';

type Result = [(id: string) => Promise<void>, { loading: boolean }];

export const useApproveAbsenceRequest = (): Result => {
  const [approveAbsenceRequest, { loading }] =
    useApproveAbsenceRequestMutation();

  const callback = async (id: string) => {
    await approveAbsenceRequest({ variables: { id } });
  };

  return [
    callback,
    {
      loading,
    },
  ];
};
