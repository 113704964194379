import styled from '@emotion/styled';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export const GoalCommonFieldsSkeleton: React.FC = () => {
  return (
    <Container>
      <ContainerInner className="p-4">
        <Row>
          <Col>
            <SkeletonStyled height={70} />
          </Col>
          <Col>
            <SkeletonStyled height={70} />
          </Col>
        </Row>
        <SkeletonStyled height={55} />
        <Row>
          <Col>
            <SkeletonStyled height={70} />
          </Col>
          <Col>
            <SkeletonStyled height={70} />
          </Col>
        </Row>
        <SkeletonStyled height={70} />
        <SkeletonStyled height={70} />
        <SkeletonStyled height={160} />
        <SkeletonStyled height={70} />
        <SkeletonStyled height={70} />
        <SkeletonStyled height={70} />
      </ContainerInner>
    </Container>
  );
};

const SkeletonStyled = styled(Skeleton)({
  marginBottom: 18,
});

const Container = styled('div')({
  display: 'contents',
});

const ContainerInner = styled('div')({
  backgroundColor: 'white',
  overflowY: 'auto',
});
