import React from 'react';
import { Spinner } from 'react-bootstrap';

import Select, {
  components as selectComponents,
  GroupBase,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { Theme } from '../../../../../theme';
import { createWorkflowItemStatusOptions } from '../Filters/mappings';
import { Option } from '../Filters/types';
import { WorkflowItemStatus } from '../WorkflowItems/types';
import { WorkflowItemStatusComponent } from './WorkflowItemStatus';

const defaultStyles: StylesConfig<Option, false> = {
  control: (provided, { isFocused }) => ({
    ...provided,
    border: `${Theme.border.width} solid transparent`,
    borderColor: isFocused ? Theme.input.border.focusColor : 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: Theme.input.border.focusColor,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: Theme.input.placeholder.color,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    color: Theme.input.select.option.color,
    backgroundColor: isSelected
      ? Theme.input.select.option.focusedBg
      : Theme.input.select.option.bg,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: Theme.input.select.option.hoverBg,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: Theme.input.border.color,
    ':hover': {
      color: Theme.input.border.focusColor,
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    border: `${Theme.border.width} solid ${Theme.input.border.focusColor}`,
    borderRadius: Theme.border.radius,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2000,
  }),
};

type Props = {
  value: WorkflowItemStatus | null;
  onChange: (value: WorkflowItemStatus | null) => void;
  isLoading?: boolean;
};

const defaultComponents: SelectComponentsConfig<
  Option,
  false,
  GroupBase<Option>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <WorkflowItemStatusComponent status={data.value as WorkflowItemStatus} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <WorkflowItemStatusComponent status={data.value as WorkflowItemStatus} />
    </selectComponents.Option>
  ),
  LoadingIndicator: () => {
    return <Spinner size="sm" animation="border" />;
  },
};

export const WorkflowItemStatusInput: React.FC<Props> = ({
  onChange,
  value,
  isLoading,
}) => {
  const options: Option[] = createWorkflowItemStatusOptions();

  return (
    <Select
      options={options}
      components={defaultComponents}
      styles={defaultStyles}
      isLoading={isLoading}
      isSearchable={false}
      menuPortalTarget={document.querySelector('body')}
      menuPosition="fixed"
      onChange={(optionValue) => {
        if (optionValue && 'value' in optionValue) {
          onChange((optionValue.value as WorkflowItemStatus) ?? null);
        }
      }}
      value={options.find((x) => x.value === value)}
    />
  );
};
