import * as React from 'react';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { routes } from '../../../routes';
import { Link } from '../../../../../Router';
import { useDashboardUpcomingAbsencesQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import { mapUpcomingAbsence } from './logic';
import AbsenceItem from './AbsenceItem';
import RequestAbsenceButton from '../../../components/RequestAbsenceButton';
import FlairButtonIcon from '../../../../../atomic/molecules/FlairButtonIcon';
import { dashboardUpcomingAbsencesRefetchOption } from '../../Absences/useMyAbsencesRefetchOption';
import LoadingWidget from '../CommonComponents/LoadingWidget';
import EmptyStateContent from '../CommonComponents/EmptyStateContent';

const i18Path = 'dashboard.upcomingAbsences';

const UpcomingAbsencesWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  const { data, loading, error } = useDashboardUpcomingAbsencesQuery({
    variables: { limit: 3 },
  });

  if (loading) {
    return <LoadingWidget />;
  }

  if (error) {
    return <ServerError />;
  }

  const addAbsenceBtn = (
    <FlairButtonIcon icon="add-outline" size="2x" className="rounded-2" />
  );

  const footerActions = <Link to={routes.absences.route}>{t('seeAll')}</Link>;

  const upcomingAbsences =
    data?.me.upcomingAbsences.map(mapUpcomingAbsence) ?? [];

  let content;

  if (upcomingAbsences.length === 0) {
    content = (
      <EmptyStateContent
        icon="calendar"
        title={t('noUpcomingAbsence')}
        helperText={t('noUpcomingAbsenceHelperTxt')}
      />
    );
  } else if (upcomingAbsences.length > 0) {
    content = upcomingAbsences.map((a) => (
      <AbsenceItem absence={a} key={a.id} />
    ));
  }

  const headerActions = (
    <RequestAbsenceButton
      categoryId={null}
      categories={data?.me.absenceCategories ?? []}
      variant="outline-primary"
      size="sm"
      button={addAbsenceBtn}
      isProcessing={loading}
      refetchQueries={[dashboardUpcomingAbsencesRefetchOption]}
    />
  );

  return (
    <FlairCard
      cardTitle={t('title')}
      footerActions={footerActions}
      headerActions={headerActions}>
      <div className="d-flex flex-column gap-4">{content}</div>
    </FlairCard>
  );
};

export default UpcomingAbsencesWidget;
