import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useWorkflowsFilterForMeOptionsQuery } from '../../../__generated__/graphql';
import { WorkflowFilter, WorkflowFilterForMe } from './types';
import {
  Props as WorkflowsFilterProps,
  WorkflowsFilter,
} from './WorkflowsFilter';
import { WorkflowsFilterLoading } from './WorkflowsFilterLoading';
import { mapCommonOption } from './mappings';

type Props = Omit<
  WorkflowsFilterProps,
  'workflowTemplateOptions' | 'filter' | 'onChange'
> & {
  filter: WorkflowFilterForMe;
  onChange: (filter: WorkflowFilterForMe) => void;
};

export const WorkflowsFilterForMeConnected: React.FC<Props> = ({
  filter,
  onChange,
  ...props
}) => {
  const t = useNamespacedTranslation('workflows.filter');

  const { data, loading, error } = useWorkflowsFilterForMeOptionsQuery();

  if (error) {
    return <div>{t('errorLoadingOptions')}</div>;
  }

  if (loading || !data) {
    return <WorkflowsFilterLoading type="workflowsForMe" />;
  }

  const { workflowTemplates } = data.workflowsFilterOptions;
  const workflowTemplateOptions = workflowTemplates.map(mapCommonOption);

  const handleOnChange = (value: WorkflowFilter) => {
    onChange({
      workflowTemplateId: value.workflowTemplateId,
      workflowType: value.workflowType,
      workflowStatus: value.workflowStatus,
      year: value.year,
    });
  };

  return (
    <WorkflowsFilter
      {...props}
      filter={{ ...filter, employeeId: null }}
      onChange={handleOnChange}
      workflowTemplateOptions={workflowTemplateOptions}></WorkflowsFilter>
  );
};
