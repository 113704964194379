import { last } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Employee } from '..';
import AbsenceCategories from './AbsenceCategories';
import PersonalData from './PersonalData';
import SalariesCard from './SalariesCard';
import WorkloadCard from './WorkloadCard';
import AdditionalCompensationsCard from './AdditionalCompensationsCard';
import {
  RelatedList,
  mapEmployeeRelatedList,
} from '../../../components/EmployeeData';
import OneTimeComponsationsCard from './OneTimeCompensationsCard';

type Props = {
  employee: Employee;
};

const Overview: React.FC<Props> = ({ employee }) => {
  const currentWorkload = last(employee.currentWorkloads);
  const showSalaries = employee.salaries.length > 0;
  const showAddtionalCompensations =
    employee.additionalCompensations.length > 0;

  const showOneTimeCompensations = employee.oneTimeCompensations.length > 0;

  return (
    <Row>
      <Col lg={6}>
        <PersonalData employee={employee} />
      </Col>
      <Col lg={6}>
        {currentWorkload && <WorkloadCard workload={currentWorkload} />}
        {showSalaries && <SalariesCard salaries={employee.salaries} />}
        {showAddtionalCompensations && (
          <AdditionalCompensationsCard
            additionalCompensations={employee.additionalCompensations}
          />
        )}
        {showOneTimeCompensations && (
          <OneTimeComponsationsCard
            oneTimeCompensations={employee.oneTimeCompensations}
          />
        )}
        <AbsenceCategories employee={employee} />
        {employee.data.relatedLists.map((relatedList) => (
          <RelatedList
            key={relatedList.label}
            relatedList={mapEmployeeRelatedList(relatedList)}
          />
        ))}
      </Col>
    </Row>
  );
};

export default Overview;
