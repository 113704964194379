import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  AddInventoryInput,
  useAddInventoryItemMutation,
  useAddInventoryMutation,
} from '../../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { getCurrentLanguage } from '../../../../../../locales/locale';
import { toISODateOnly } from '../../../../../../utils/dateUtils';
import { ApolloError } from '@apollo/client';
import { InventoryItemRequest } from '../types';
import { useApolloCache } from '../../../../hooks/useApolloCache';

export const useAddInventoryItem = () => {
  const errorHandler = useMutationErrorHandler();
  const locale = getCurrentLanguage();
  const [addInventoryItemModalVisible, setAddInventoryItemModalVisible] =
    useState(false);
  const [
    addInventoryItemSuccessModalVisible,
    setAddInventoryItemSuccessModalVisible,
  ] = useState(false);
  const [modalIndex, setModalIndex] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const handleAddInventoryItemModalClose = () => {
    setAddInventoryItemModalVisible(false);
    setModalIndex(Date.now());
  };
  const [addInventory] = useAddInventoryMutation();
  const [addInventoryItem] = useAddInventoryItemMutation();
  const cache = useApolloCache();

  const add = async (data: FieldValues, isNewInventory: boolean) => {
    setIsLoading(true);
    const inventoryRequest = mapToInventoryRequest(data);
    const inventoryItemRequest = mapToInventoryItemRequest(data);
    try {
      if (isNewInventory) {
        const res = await addInventory({
          variables: {
            input: { ...inventoryRequest },
          },
        });

        const inventoryId = res.data?.inventory?.addInventory?.recordId;

        await addInventoryItem({
          variables: {
            lang: locale,
            input: {
              flair__Inventory__c: inventoryId!,
              ...inventoryItemRequest,
            },
          },
        });

        setAddInventoryItemSuccessModalVisible(true);
      } else {
        await addInventoryItem({
          variables: {
            lang: locale,
            input: {
              flair__Inventory__c: data.name,
              ...inventoryItemRequest,
            },
          },
        });
        setAddInventoryItemSuccessModalVisible(true);
      }
    } catch (err) {
      setIsLoading(false);
      errorHandler(err as unknown as ApolloError);
    } finally {
      cache.evict({
        fieldName: 'inventoryItemsPagination',
      });
      cache.gc();
      handleAddInventoryItemModalClose();
    }

    setIsLoading(false);
  };

  return {
    addInventoryItemModalVisible,
    setAddInventoryItemModalVisible,
    handleAddInventoryItemModalClose,
    modalIndex,
    addInventoryItem: add,
    isLoading,
    addInventoryItemSuccessModalVisible,
    setAddInventoryItemSuccessModalVisible,
  };
};

const mapToInventoryRequest = (data: FieldValues): AddInventoryInput => {
  return {
    Name: data.name,
    flair__Manufacturer__c: data.manufacturer,
    flair__Model__c: data.model,
    flair__Type__c: data.category,
    flair__Support_Instruction__c: null,
    flair__Support_Video__c: null,
    flair__Support_Advisor__c: null,
  };
};

const mapToInventoryItemRequest = (data: FieldValues): InventoryItemRequest => {
  return {
    flair__Electronics_Cat_Operating_System__c: data.operatingSystem,
    flair__Employee__c: data.responsible,
    flair__Location__c: data.location,
    flair__Serial_Number__c: data.serialNumber,
    flair__Purchase_Date__c: data.purchaseDate
      ? toISODateOnly(data.purchaseDate)
      : null,
    flair__Net_Purchase_Price__c: parseFloat(data.netPurchasedPrice),
    flair__Warranty_Date__c: data.warrantyDate
      ? toISODateOnly(data.warrantyDate)
      : null,
    flair__Warranty_Information__c: data.warrantyInfo,
    flair__Notes__c: data.note,
    RecordTypeId: data.recordTypeId,

    // These fields are for future when we will add other categories
    flair__App_Cat_Download_Or_Connect_Link__c: null,
    flair__App_Cat_Editor__c: null,
    flair__App_Cat_Version__c: null,
    flair__Supplier__c: null,
    flair__Uniform_Cat_Size__c: null,
    flair__Uniform_Cat_Size_System__c: null,
    flair__Uniform_Cat_Type__c: null,
    flair__Vehicle_Cat_Fuel_Type__c: null,
    flair__Vehicle_Cat_Identification_Number_VIN__c: null,
    flair__Vehicle_Cat_Insurance_Company__c: null,
    flair__Vehicle_Cat_Insurance_Company_Contact__c: null,
    flair__Vehicle_Cat_Insurance_policy_number__c: null,
    flair__Vehicle_Cat_Model_Year__c: null,
    flair__Vehicle_Cat_Serial_Plate_Number__c: null,
  };
};
