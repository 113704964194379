import React from 'react';
import ShiftsDaily from '../../components/Shifts/ShiftsDaily';
import { getViewByFromQuery } from '../../components/Shifts/shiftsQueryUtils';
import { useLocation } from 'react-router-dom';
import AllShiftsWeeklyView from './AllShiftsWeeklyView';
import { FilterSelectOption } from '../../../../atomic/molecules/FlairDropdown/types';
import { filterFromQuery, mapToFilterOptions } from './logic';
import { useCalendarFiltersQuery } from '../../__generated__/graphql';
import { CalendarFilter, FilterOptions } from './types';
import { ViewByType } from '../../components/Shifts/shiftsLogic';

type ContextType = {
  filtersLoading: boolean;
  filtersOptions: FilterOptions;
  filter: CalendarFilter;
  viewBy: ViewByType;
};

export const ShiftsContext = React.createContext<ContextType>({
  filtersLoading: false,
  filtersOptions: { departments: [], skills: [] },
  filter: { departments: [], skills: [] },
  viewBy: 'week',
});

const AllShifts: React.FC = () => {
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const viewBy = getViewByFromQuery(query);
  const filter = filterFromQuery(query);
  const { loading: filtersLoading, data } = useCalendarFiltersQuery({});

  const departmentOptions: FilterSelectOption[] =
    data?.departments.map(mapToFilterOptions) ?? [];
  const skillOptions: FilterSelectOption[] =
    data?.skills.map(mapToFilterOptions) ?? [];

  const filtersOptions = {
    departments: departmentOptions,
    skills: skillOptions,
  };

  return (
    <ShiftsContext.Provider
      value={{ filtersLoading, filtersOptions, filter, viewBy }}>
      {viewBy === 'day' ? <ShiftsDaily /> : <AllShiftsWeeklyView />}
    </ShiftsContext.Provider>
  );
};

export default AllShifts;
