import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

type Props = {
  showHeaderPreTitle?: Boolean;
  showHeaderTitle?: Boolean;
};

const SkeletonPageHeader: React.FC<Props> = ({
  showHeaderPreTitle = true,
  showHeaderTitle = true,
}) => (
  <div className="header mt-md-3">
    <div className="header-body">
      <Row className="row align-items-center">
        <Col>
          {showHeaderPreTitle && (
            <h6 className="header-pretitle">
              <Skeleton width={50} />
            </h6>
          )}
          {showHeaderTitle && (
            <h1 className="header-title">
              <Skeleton width={150} />
            </h1>
          )}
        </Col>
      </Row>
    </div>
  </div>
);

export default SkeletonPageHeader;
