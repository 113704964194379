import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoSaveInputLabel from '../../../../../../../components/form/AutoSaveInputLabel';
import InputLabel from '../../../../../../../components/form/InputLabel';
import { SelectDropdown } from '../../../../../../../components/Select/SelectDropdown';
import { getGoalRateStr } from '../../translations';
import { allGoalRates } from '../../types';
import {
  GoalStatusInput,
  Props as GoalStatusInputProps,
} from './GoalStatusInput';
import { AutoGoalInfo, GoalStatusWithRate, SaveInfo } from '../types';
import TextareaAutosize from 'react-textarea-autosize';
import { getError } from '../../../../../../../components/form/utils';
import InputError from '../../../../../../../components/form/InputError';
import { ValidationError } from '../../../../../../../initializers/yup';

export type Props = Omit<GoalStatusInputProps, 'value' | 'onChange'> & {
  value: GoalStatusWithRate;
  onChange: (value: GoalStatusWithRate) => void;
  saveInfo: SaveInfo;
  isRateVisible: boolean;
  autoGoalInfo: AutoGoalInfo;
  className?: string;
  error?: ValidationError | string | undefined;
};

const i18prefix = 'performanceReview.goals2.goalSidebar';

export const GoalStatusInputWithRate: React.FC<Props> = ({
  value,
  onChange,
  saveInfo,
  isDisabled,
  isRateVisible,
  error,
  className,
  autoGoalInfo,
  ...restProps
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (status) => {
      onChange({ ...value, status });
    },
    [value, onChange],
  );

  const handleRateChange = useCallback(
    (rate) => {
      onChange({ ...value, rate });
    },
    [value, onChange],
  );

  const handleCancelReasonChange = useCallback(
    (event) => {
      const cancelReason = (event.target as HTMLInputElement).value;
      onChange({ ...value, cancelReason });
    },
    [value, onChange],
  );

  return (
    <div className={className}>
      <AutoSaveInputLabel
        label={t(`${i18prefix}.formFields.status`)}
        {...saveInfo}
      />
      <GoalStatusInput
        {...restProps}
        isDisabled={isDisabled}
        value={value.status}
        onChange={handleChange}
        autoGoalInfo={autoGoalInfo}
      />
      {value.status === 'DONE' && isRateVisible && (
        <div className="mt-2">
          <InputLabel label={t(`${i18prefix}.formFields.rate`)} />
          <SelectDropdown
            options={rateOptions}
            isDisabled={isDisabled}
            value={value.rate}
            onChange={handleRateChange}
          />
        </div>
      )}
      {value.status === 'CANCELED' && (
        <div className="mt-2">
          <InputLabel label={t(`${i18prefix}.formFields.cancelReason`)} />
          <TextareaAutosize
            disabled={isDisabled}
            readOnly={isDisabled}
            value={value.cancelReason ?? ''}
            onChange={handleCancelReasonChange}
            className={['form-control', 'mb-0', error ? 'is-invalid' : ''].join(
              ' ',
            )}
          />
          <InputError error={error} />
        </div>
      )}
    </div>
  );
};

type ControlledProps = Omit<Props, 'value' | 'onChange'> & { name: string };

export const GoalStatusInputWithRateControlled: React.FC<ControlledProps> = ({
  name,
  ...props
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  return (
    <Controller
      as={({ value, onChange }) => {
        return (
          <GoalStatusInputWithRate
            {...props}
            value={value}
            error={error}
            onChange={(v) => {
              onChange(v);
            }}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};

const rateOptions = allGoalRates.map((value) => ({
  value,
  label: getGoalRateStr(value),
}));
