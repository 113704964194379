import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import { JobCandidate } from './types';
import { normalizeExternalLink } from '../../../../../utils/link';
import ScoreContent from './TeamFeedback/ScoreContent';

type Params = {
  candidate: Pick<
    JobCandidate,
    | 'flair__Full_Name__c'
    | 'flair__Email__c'
    | 'flair__Phone__c'
    | 'flair__LinkedIn__c'
    | 'flair__Funnel_Stage__c'
    | 'flair__Application_Source__c'
    | 'flair__How_did_you_hear_about_us__c'
    | 'job'
    | 'flair__Final_Score__c'
  >;
};

type CandidateRowParams = {
  text: string | null | undefined;
  label: string;
  type?: 'text' | 'email' | 'link' | 'score';
};

const InfoDisplay: React.FC<
  Required<Pick<CandidateRowParams, 'text' | 'type'>>
> = ({ text, type }) => {
  switch (type) {
    case 'email':
      return <a href={`mailto:${text}`}>{text}</a>;
    case 'link':
      if (!text) return <></>;
      return (
        <a
          href={normalizeExternalLink(text)}
          rel="noopener noreferrer"
          target="_blank">
          {text}
        </a>
      );
    case 'text':
      return <span>{text}</span>;
    case 'score':
      return text ? (
        <ScoreContent
          score={Number(text)}
          labelPosition="right"
          labelClassName="pr-3"
          showScore={true}
          useSplitter={true}
        />
      ) : (
        <span>--</span>
      );
  }
};

const CandidateRow: React.FC<CandidateRowParams> = ({
  text,
  label,
  type = 'text',
}) => {
  if (!text && type !== 'score') {
    return <></>;
  }

  return (
    <Row className="small mt-2">
      <Col xs={6} lg={4} className="text-dark">
        {label}
      </Col>
      <Col xs={6} lg={8}>
        <InfoDisplay text={text} type={type} />
      </Col>
    </Row>
  );
};

const CandidateInfo: React.FC<Params> = ({ candidate }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <h2>{candidate.flair__Full_Name__c}</h2>
      <CandidateRow
        text={candidate.flair__Email__c}
        label={t('recruitment.candidate.info.email')}
        type="email"
      />
      <CandidateRow
        text={candidate.job?.Name}
        label={t('recruitment.candidate.info.jobName')}
      />
      <CandidateRow
        text={candidate.flair__Phone__c}
        label={t('recruitment.candidate.info.phone')}
      />
      <CandidateRow
        text={candidate.flair__LinkedIn__c}
        label={t('recruitment.candidate.info.linkedIn')}
        type="link"
      />
      <CandidateRow
        text={candidate.flair__Funnel_Stage__c}
        label={t('recruitment.candidate.info.funnelStage')}
      />
      <CandidateRow
        text={candidate.flair__Application_Source__c}
        label={t('recruitment.candidate.info.applicationSource')}
      />
      <CandidateRow
        text={candidate.flair__How_did_you_hear_about_us__c}
        label={t('recruitment.candidate.info.howDidYouHearAboutUs')}
      />
      <hr />
      <CandidateRow
        text={candidate.flair__Final_Score__c?.toString()}
        label={t('recruitment.candidate.info.overallEvaluationScore')}
        type="score"
      />
    </Section>
  );
};

export default CandidateInfo;
