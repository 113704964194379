import React from 'react';
import { TeamWithPopupConnected } from '../../../apps/dashboard/components/Team/TeamWithPopupConnected';
import Hint from '../../../components/hint';
import Avatar from '../avatar/Avatar';
import { EmployeeWithAvatar } from '../../../apps/dashboard/components/Employee';
import { EmployeeGroup } from './types';
import { getRecipientName } from './mappings';
import './styles.css';
import { AvatarSize } from '../../atoms/avatar/AvatarContainer/types';
import { TeamAvatarSize } from '../../../apps/dashboard/components/Team/TeamAvatar';

type AvatarSizes = AvatarSize | TeamAvatarSize;

type Props = {
  recipients: EmployeeGroup[];
  className?: string;
  maxAvatarsToShow?: number;
  avatarSize?: AvatarSizes;
  gapSize?: number;
  showAvatarOnly?: boolean;
};

const DEFAULT_MAX_AVATARS_TO_SHOW = 5;
const AVATAR_WIDTH_REM = 1.4;

export const EmployeesAvatarGroup: React.FC<Props> = ({
  className,
  recipients,
  maxAvatarsToShow = DEFAULT_MAX_AVATARS_TO_SHOW,
  avatarSize = 'md',
  gapSize = 35,
  showAvatarOnly,
}) => {
  const renderAvatars = (avatars: React.ReactNode[]) => {
    const containerWidth = `${recipients.length * AVATAR_WIDTH_REM}rem`;

    return (
      <div className="avatars-container" style={{ width: containerWidth }}>
        {avatars.map((avatar, i) => (
          <div
            className={`${
              i === 0 ? 'position-relative' : 'position-absolute'
            } avatar-container`}
            key={i.toString()}
            style={{ left: i * gapSize }}>
            {avatar}
          </div>
        ))}
      </div>
    );
  };

  const renderRecipients = () => {
    if (recipients.length === 1) {
      return (
        <SingleRecipient
          avatarSize={avatarSize}
          recipient={recipients[0]}
          showAvatarOnly={showAvatarOnly}
        />
      );
    }
    if (recipients.length > 1 && recipients.length <= maxAvatarsToShow) {
      return renderAvatars(
        recipients.map((x) => (
          <RecipientAvatar avatarSize={avatarSize} recipient={x} />
        )),
      );
    }
    const visibleAvatars = recipients
      .slice(0, maxAvatarsToShow)
      .map((x) => <RecipientAvatar avatarSize={avatarSize} recipient={x} />);
    const manyRecipientsAvatar = (
      <ManyRecipientsAvatar
        avatarSize={avatarSize}
        recipients={recipients.slice(maxAvatarsToShow)}
      />
    );
    return renderAvatars([...visibleAvatars, manyRecipientsAvatar]);
  };

  return (
    <div className={`d-flex gap-2 ${className}`}>{renderRecipients()}</div>
  );
};

const SingleRecipient: React.FC<{
  recipient: EmployeeGroup;
  avatarSize: AvatarSizes;
  showAvatarOnly?: boolean;
}> = ({ recipient, avatarSize, showAvatarOnly }) => {
  if (recipient.type === 'employee') {
    return (
      <Hint id={`avatar-tooltip`} text={recipient.employee.name}>
        <EmployeeWithAvatar
          showAvatarOnly={showAvatarOnly}
          employee={recipient.employee}
          size={avatarSize}
          additionalInfo={recipient.employee.department ?? undefined}
        />
      </Hint>
    );
  } else if (recipient.type === 'team') {
    return (
      <div className="d-flex align-items-center">
        <TeamWithPopupConnected
          teamAvatarSize={avatarSize as TeamAvatarSize}
          isTeamNameVisible={false}
          name={recipient.team.name}
          id={recipient.team.id}
        />
        <div className="ms-2">
          <div className="text-nowrap">{recipient.team.name}</div>
        </div>
      </div>
    );
  }
  return null;
};

const RecipientAvatar: React.FC<{
  recipient: EmployeeGroup;
  avatarSize: AvatarSizes;
}> = ({ recipient, avatarSize }) => {
  if (recipient.type === 'employee') {
    return (
      <Hint id={`${recipient.id}-tooltip`} text={recipient.employee.name}>
        <div>
          <Avatar
            size={avatarSize}
            initials={recipient.employee.initials}
            avatarUrl={recipient.employee.avatarUrl ?? undefined}
          />
        </div>
      </Hint>
    );
  } else if (recipient.type === 'team') {
    return (
      <TeamWithPopupConnected
        teamAvatarSize={avatarSize as TeamAvatarSize}
        isTeamNameVisible={false}
        name={recipient.team.name}
        id={recipient.team.id}
      />
    );
  }
  return null;
};

const ManyRecipientsAvatar: React.FC<{
  recipients: EmployeeGroup[];
  avatarSize: AvatarSize;
}> = ({ recipients, avatarSize }) => {
  return (
    <Hint id={`avatar-tooltip`} text={getRecipientsHintText(recipients)}>
      <Avatar size={avatarSize} initials={`+${recipients.length.toString()}`} />
    </Hint>
  );
};

const getRecipientsHintText = (recipients: EmployeeGroup[]): string => {
  return recipients.map(getRecipientName).join(', ');
};
