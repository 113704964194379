import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  calculateTotalDuration,
  DayInfo,
  EmployeeType,
  TimeEntryItem,
  TimeEntryReadOnlyReason,
} from '../logic';
import { PureQueryOptions } from '@apollo/client';
import ButtonLink from '../components/ButtonLink';
import TimeEntryEmployeeSmart from '../TimeEntry/TimeEntryEmployeeSmart';
import TimeEntryManagerSmart from '../TimeEntry/TimeEntryManagerSmart';
import DayHeader from '../../../../components/TimeSheet/DayCard/DayHeader';
import { TimeEntryCardLayout } from '../components/TimeEntryCardLayout';
import styled from '@emotion/styled';
import { CostCenterProps } from '..';
import { isManagerOrCanChangeOwnTimeEntries } from '../logic/mapping';

type Props = {
  day: DayInfo;
  costCenters: readonly CostCenterProps[];
  readonlyReasons: TimeEntryReadOnlyReason[];
  employeeType: EmployeeType;
  employeeId: string;
  timeEntries: TimeEntryItem[];
  refetchQueriesForEmployeeMode?: PureQueryOptions[];
  onAddNewTimeEntryClick: () => void;
  onCancelCreatingNewTimeEntry: () => void;
};

const DayTimeEntriesDumb: React.FC<Props> = ({
  day,
  costCenters,
  employeeType,
  employeeId,
  readonlyReasons,
  timeEntries,
  refetchQueriesForEmployeeMode,
  onCancelCreatingNewTimeEntry,
  onAddNewTimeEntryClick,
}) => {
  const { t } = useTranslation();
  const readonly = readonlyReasons.length > 0;
  const restrictedFutureEntry = readonlyReasons.includes(
    'restrictedFutureEntries',
  );
  const renderEmptyContainer = () => {
    return (
      <TimeEntryCardLayout>
        {!readonly ? (
          <ButtonLink
            title={t('timeTrackingNew.dayCard.addNewTimeEntry')}
            onClick={onAddNewTimeEntryClick}
          />
        ) : (
          <span>
            {restrictedFutureEntry
              ? t('timeTrackingNew.dayCard.restrictedFutureEntries')
              : t('timeTrackingNew.dayCard.noEntries')}
          </span>
        )}
      </TimeEntryCardLayout>
    );
  };

  const isManagerMode = isManagerOrCanChangeOwnTimeEntries(employeeType);

  return (
    <>
      <DayHeader
        dayInfo={day}
        absences={day.absences}
        holidays={day.holidays}
        calendarPeriods={day.calendarPeriods}
        warnings={day.warnings}
        dayMinutes={calculateTotalDuration(timeEntries)}
        commentsCount={day.commentsCount}
        isManagerMode={isManagerMode}></DayHeader>
      {timeEntries.length === 0 && renderEmptyContainer()}
      {timeEntries.map((item) =>
        !isManagerMode ? (
          <TimeEntryEmployeeSmart
            key={item.uniqueId}
            costCenters={costCenters}
            autoBreakRules={day.autoBreakRules}
            readonlyReasons={readonlyReasons}
            value={item}
            refetchQueriesForEmployeeMode={refetchQueriesForEmployeeMode}
            onCancelCreatingNewTimeEntry={onCancelCreatingNewTimeEntry}
          />
        ) : (
          <TimeEntryManagerSmartContainer key={item.uniqueId}>
            <TimeEntryManagerSmart
              costCenters={costCenters}
              autoBreakRules={day.autoBreakRules}
              readonlyReasons={readonlyReasons}
              employeeId={employeeId}
              employeeType={employeeType}
              value={item}
              refetchQueriesForEmployeeMode={refetchQueriesForEmployeeMode}
              onCancelCreatingNewTimeEntry={onCancelCreatingNewTimeEntry}
            />
          </TimeEntryManagerSmartContainer>
        ),
      )}
    </>
  );
};

const TimeEntryManagerSmartContainer = styled('div')({
  marginBottom: '2.2rem',
});

export default DayTimeEntriesDumb;
