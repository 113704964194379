import { ChartSkill } from './types';
import { EmployeeSkillAssociationFragment } from './../../../__generated__/graphql';
import { mapSkillLevel } from '../common/mappings';
export const mapChartSkill = (
  src: EmployeeSkillAssociationFragment,
): ChartSkill => ({
  id: src.Id,
  name: src.skill.Name,
  level: mapSkillLevel(src.flair__Rate__c),
});
