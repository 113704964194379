import React, { useCallback } from 'react';
import {
  EngagementSurveyEmployeeResponseBySurveyIdQuery,
  useAnswerEngagementSurveyQuestionMutation,
  useDismissEngagementSurveyEmployeeResponseMutation,
  useEngagementSurveyEmployeeResponseBySurveyIdQuery,
  useSubmitEngagementSurveyEmployeeResponseMutation,
} from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import Loading from './Loading';
import EngagementSurveyResponseQuestionnaire from '../EngagementSurveyResponseQuestionnaire';
import { useHistory, useParams } from 'react-router-dom';
import { useUserInfo } from '../../../context/UserInfo';
import PageHeader from '../../../components/PageHeader';
import { IQuestionAnswer } from '../../../components/Questionnaire/QuestionnaireField';
import { routes } from '../../../routes';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type EngagementSurveyEmployeeResponse =
  EngagementSurveyEmployeeResponseBySurveyIdQuery['engagementSurveyEmployeeResponseBySurveyAndEmployee'];
type RouteParams = {
  surveyId: string;
};
type Params = {};
type ContentParams = {
  engagementSurveyEmployeeResponse: NonNullable<EngagementSurveyEmployeeResponse>;
};

const Content: React.FC<ContentParams> = ({
  engagementSurveyEmployeeResponse,
}) => {
  const history = useHistory();
  const goToSurveyListingPage = useCallback(() => {
    history.push(routes.performanceReviewEngagementSurveys.route.create({}));
  }, [history]);

  const [answerQuestion] = useAnswerEngagementSurveyQuestionMutation();

  const [submitResponse] = useSubmitEngagementSurveyEmployeeResponseMutation();
  const [dismissResponse] =
    useDismissEngagementSurveyEmployeeResponseMutation();

  const onSubmit = (responseId: string) => {
    return submitResponse({
      variables: {
        input: {
          responseId,
        },
      },
    }).then(() => {
      goToSurveyListingPage();
    });
  };

  const onDismiss = (responseId: string) => {
    return dismissResponse({
      variables: {
        input: {
          responseId,
        },
      },
    }).then(() => {
      goToSurveyListingPage();
    });
  };

  const onUpdateQuestionAnswerOrNotes = (questionInfo: IQuestionAnswer) => {
    return answerQuestion({
      variables: {
        input: {
          questionId: questionInfo.id,
          responseId: engagementSurveyEmployeeResponse.Id,
          employeeId: engagementSurveyEmployeeResponse.flair__Employee__c,
          answer: questionInfo.value,
          notes: questionInfo.notes,
        },
      },
    });
  };

  const onUpdateQuestionAnswer = (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => {
    return onUpdateQuestionAnswerOrNotes({ ...questionInfo, value });
  };
  const onUpdateQuestionNotes = (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => {
    return onUpdateQuestionAnswerOrNotes({ ...questionInfo, notes: value });
  };

  return (
    <EngagementSurveyResponseQuestionnaire
      onUpdateQuestionAnswer={onUpdateQuestionAnswer}
      onUpdateQuestionNotes={onUpdateQuestionNotes}
      onSubmit={onSubmit}
      onDismiss={onDismiss}
      engagementSurveyEmployeeResponse={engagementSurveyEmployeeResponse}
    />
  );
};
export const EngagementSurveyResponsePage: React.FC<Params> = () => {
  const t = useNamespacedTranslation('navigation.menuItems.engagement');
  const { surveyId } = useParams<RouteParams>();
  const { id: employeeId } = useUserInfo();

  const { data, loading, error } =
    useEngagementSurveyEmployeeResponseBySurveyIdQuery({
      variables: {
        surveyId,
        employeeId,
      },
    });

  const engagementName =
    data?.engagementSurveyEmployeeResponseBySurveyAndEmployee?.engagementSurvey
      .Name;

  useFlairBreadcrumbHook(
    [
      { label: t('title') },
      {
        label: t('subMenuItems.engagementSurveys.fullTitle'),
        url: routes.performanceReviewEngagementSurveys.route.create({}),
      },
      { label: engagementName ?? '' },
    ],
    loading,
  );

  if (loading) return <Loading />;
  if (error || !data?.engagementSurveyEmployeeResponseBySurveyAndEmployee)
    return <ServerError />;

  return (
    <>
      <PageHeader
        title={
          data.engagementSurveyEmployeeResponseBySurveyAndEmployee
            .engagementSurvey.Name
        }
      />
      <Content
        engagementSurveyEmployeeResponse={
          data.engagementSurveyEmployeeResponseBySurveyAndEmployee
        }
      />
    </>
  );
};

export default EngagementSurveyResponsePage;
