import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';

export type FilterProps = {
  options: FilterSelectOption[];
  filterData: CandidatesFiltersType;
};

export const STAGE_FILTER = 'stage';
export const SOURCE_FILTER = 'source';

export const CandidatesFiltersKeysArray = [
  STAGE_FILTER,
  SOURCE_FILTER,
] as const;
// STAGE_FILTER | SOURCE_FILTER | ....
export type CandidatesFiltersKeys = (typeof CandidatesFiltersKeysArray)[number];
export type CandidatesFiltersType = Record<CandidatesFiltersKeys, string>;

export const filterDefaultData: CandidatesFiltersType = {
  stage: '',
  source: '',
};

export type CandidatesFilter = {
  searchTerm: string | null;
  source: string | null;
  stages: string[] | null;
};

export const emptyFilter: CandidatesFilter = {
  searchTerm: null,
  source: null,
  stages: null,
};
