import React from 'react';
import {
  TableDayAnySubRow,
  TableRow,
  ViewBy,
  isTableDaySubRow,
  isTableRowHeaderProject,
  isTableRowHeaderWeek,
  isTableRowTimeSheet,
} from '../types';
import { TimeSheetColumn } from './types';

export type Props = {
  columns: TimeSheetColumn[];
  tableRows: TableRow[];
  row: TableRow;
  subRows?: TableDayAnySubRow[];
  viewBy: ViewBy;
  expandedRowIdSet: Set<string>;
  highlightIfHasComments: boolean;
};

export const TimeSheetsTableRow: React.FC<Props> = (props) => {
  const { row, expandedRowIdSet } = props;
  if (
    isTableRowTimeSheet(row) &&
    props.subRows?.length &&
    expandedRowIdSet.has(row.id)
  ) {
    return (
      <>
        <TimeSheetsTableFlatRow key={row.id} {...props} isExpanded={true} />
        {props.subRows.map((daySubRow) => {
          const isDaySubRowExpanded = expandedRowIdSet.has(daySubRow.id);
          return (
            <>
              <TimeSheetsTableFlatRow
                key={daySubRow.id}
                columns={props.columns}
                tableRows={props.tableRows}
                viewBy={props.viewBy}
                highlightIfHasComments={props.highlightIfHasComments}
                row={daySubRow}
                isExpanded={isDaySubRowExpanded}
              />
              {isTableDaySubRow(daySubRow) &&
                isDaySubRowExpanded &&
                daySubRow.subRows?.map((projectSubRow) => (
                  <TimeSheetsTableFlatRow
                    key={projectSubRow.id}
                    columns={props.columns}
                    tableRows={props.tableRows}
                    viewBy={props.viewBy}
                    highlightIfHasComments={props.highlightIfHasComments}
                    row={projectSubRow}
                    isExpanded={false}
                  />
                ))}
            </>
          );
        })}
      </>
    );
  }
  return <TimeSheetsTableFlatRow {...props} isExpanded={false} />;
};

const TimeSheetsTableFlatRow: React.FC<
  Omit<Props, 'expandedRowIdSet'> & { isExpanded: boolean }
> = ({
  row,
  tableRows,
  columns,
  isExpanded,
  viewBy,
  highlightIfHasComments,
}) => {
  return (
    <tr className={getTrClasses(viewBy, highlightIfHasComments, row).join(' ')}>
      {columns.map((column) => (
        <td
          key={column.accessor}
          data-testid={column.accessor}
          className={getTdClasses(column.accessor, row).join(' ')}>
          {column.Cell
            ? column.Cell({
                value: row[column.accessor],
                row,
                isExpanded,
                allRows: tableRows,
              })
            : null}
        </td>
      ))}
    </tr>
  );
};

const getTdClasses = (
  columnAccessor: string,
  { rowType }: TableRow,
): string[] => {
  if (rowType === 'header-employee' || rowType === 'header-week') {
    return [];
  }
  switch (columnAccessor) {
    case 'trackedMinutes':
      return ['border-color-paper-4', 'border-highlighted'];
    default:
      return [];
  }
};

const getTrClasses = (
  viewBy: ViewBy,
  highlightIfHasComments: boolean,
  row: TableRow,
) => {
  const classNames = [];
  if (
    (viewBy === 'project' &&
      (isTableRowHeaderWeek(row) || isTableRowHeaderProject(row))) ||
    (viewBy === 'employee' && isTableRowTimeSheet(row))
  ) {
    classNames.push('row-height');
    classNames.push('row-highlighted');
  }
  if (
    isTableDaySubRow(row) &&
    highlightIfHasComments &&
    row.commentsCount > 0
  ) {
    classNames.push('has-comments-highlighted');
  }

  return classNames;
};
