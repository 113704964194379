import classNames from 'classnames';
import React from 'react';
import { Overlay } from 'react-bootstrap';

type Props = {
  target: any; //TODO: fix type
  error: string | null;
};

const ErrorTooltip: React.FC<Props> = ({ target, error }) => {
  return (
    <Overlay target={target} show={!!error} placement="top-start">
      <OverlayContainer>
        <span>{error}</span>
      </OverlayContainer>
    </Overlay>
  );
};

const OverlayContainer = React.forwardRef<
  HTMLDivElement,
  React.HtmlHTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={classNames(
      'rounded',
      'bg-danger',
      'text-white',
      'fs-5',
      'px-2',
      'py-1',
      'mb-1',
    )}
    style={{
      zIndex: 1051, // modal has 1050
    }}
    {...rest}
  />
));

export default ErrorTooltip;
