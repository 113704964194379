import styled from '@emotion/styled';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../theme';
import { getRange } from '../../utils/dateUtils';
import FlairIcon from '../../atomic/atoms/FlairIcon';

const PROSPECTIVE_WEEKS_COUNT = 20;

const StyledDropDownMenu = styled(Dropdown.Menu)({
  maxHeight: 300,
  overflow: 'auto',
  borderColor: Theme.color.paper3,
});

const StyledDropDownItem = styled(Dropdown.Item)({
  ':hover': {
    backgroundColor: Theme.color.gray5,
  },
});

type Props = {
  className?: string;
  weekStart: Moment;
  onWeekStartChanged: (newValue: Moment) => void;
  minWeek?: Moment;
  maxWeek?: Moment;
};
const WeekSelector: React.FC<Props> = ({
  className,
  weekStart,
  onWeekStartChanged,
  minWeek,
  maxWeek,
}) => {
  const { t } = useTranslation();

  const firstDay = weekStart.clone().startOf('day');
  const lastDay = firstDay.clone().add(6, 'days');

  return (
    <div
      className={classNames(
        'd-flex',
        'align-items-center',
        'flex-wrap',
        'fs-5',
        'gap-3',
        className,
      )}>
      <WeekDropdown
        minWeek={minWeek || weekStart}
        maxWeek={
          maxWeek || weekStart.clone().add(PROSPECTIVE_WEEKS_COUNT, 'weeks')
        }
        onWeekChange={onWeekStartChanged}>
        <FlairIcon icon="chevron-down-outline" />

        <span className="fw-bolder">
          {t('calendarWeek', { week: weekStart.week() })}{' '}
        </span>
        <span className="fw-light">{`(${toDayStr(firstDay)} - ${toDayStr(
          lastDay,
        )})`}</span>
      </WeekDropdown>
      <div
        className={classNames(
          'd-none',
          'd-sm-flex',
          'gap-2',
          'align-items-center',
        )}>
        <FlairIcon
          icon="chevron-back-outline"
          role="button"
          onClick={() => onWeekStartChanged(weekStart.clone().add(-7, 'days'))}
        />
        <FlairIcon
          icon="chevron-forward-outline"
          role="button"
          onClick={() => onWeekStartChanged(weekStart.clone().add(7, 'days'))}
        />
      </div>
    </div>
  );
};

export default WeekSelector;

type WeekDropdownProps = {
  onWeekChange: (newValue: Moment) => void;
  minWeek: Moment;
  maxWeek: Moment;
};

const WeekDropdown: React.FC<WeekDropdownProps> = ({
  onWeekChange,
  minWeek,
  maxWeek,
  children,
}) => {
  const { t } = useTranslation();

  const menuItem = (itemWeekStart: Moment) => (
    <StyledDropDownItem
      className={classNames('fs-5')}
      key={itemWeekStart.toISOString()}
      eventKey={itemWeekStart.toISOString()}>
      <span className="text-black me-2">
        {t('calendarWeek', { week: itemWeekStart.week() })}
      </span>
      <span className="text-secondary">
        {`${toLongDayStr(itemWeekStart)} - ${toLongDayStr(
          itemWeekStart.clone().add(6, 'days'),
        )}, ${itemWeekStart.format('yyyy')}`}
      </span>
    </StyledDropDownItem>
  );

  return (
    <Dropdown onSelect={(eventKey) => onWeekChange(moment(eventKey))}>
      <Dropdown.Toggle id="change-week" as={DropdownToggle}>
        {children}
      </Dropdown.Toggle>
      <StyledDropDownMenu className="dropdown-menu-right">
        {getRange(minWeek, maxWeek, 'weeks').map((week) => menuItem(week))}
      </StyledDropDownMenu>
    </Dropdown>
  );
};

const toDayStr = (date: Moment) => date.format('D MMM');
const toLongDayStr = (date: Moment) => date.format('MMMM D');

const DropdownToggle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ onClick, children }, ref) => (
  <div
    ref={ref}
    className={classNames('d-flex', 'align-items-center', 'gap-2')}
    role="button"
    onClick={(e) => {
      e.stopPropagation();
      onClick && onClick(e);
    }}>
    {children}
  </div>
));
