import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationCard } from '../../Home/PerformanceReviewCard';
import { useTranslation } from 'react-i18next';
import { useMyEngagementSurveysQuery } from '../../../__generated__/graphql';
import { routes } from '../../../routes';
import { isSurveyToBeAnswered } from '../EngagementSurveysListPage/utils';

type EngagementSurveyNotificationCardUIProps = {
  onButtonClick: () => void;
};
export const EngagementSurveyNotificationCardUI: React.FC<
  EngagementSurveyNotificationCardUIProps
> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <NotificationCard
      title={t('engagementSurvey.notificationCard.title')}
      text={t('engagementSurvey.notificationCard.text')}
      buttonLabel={t('engagementSurvey.notificationCard.goThereButton')}
      onButtonClick={onButtonClick}
    />
  );
};

export const EngagementSurveyNotificationCard: React.FC = () => {
  const history = useHistory();
  const { data, loading, error } = useMyEngagementSurveysQuery();

  const handleGoToSurveyAnswering = useCallback(() => {
    history.push(routes.performanceReviewEngagementSurveys.route.create({}));
  }, [history]);

  if (error || loading || !data) {
    return null;
  }

  const surveyToAnswer = data.me.engagementSurveys.filter((s) =>
    isSurveyToBeAnswered(s),
  );
  return (
    <>
      {surveyToAnswer.length > 0 && (
        <EngagementSurveyNotificationCardUI
          onButtonClick={handleGoToSurveyAnswering}
        />
      )}
    </>
  );
};

export default EngagementSurveyNotificationCard;
