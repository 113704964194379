import React from 'react';
import { param, route } from '../../../utils/router';
import RedirectToHome from '../components/RedirectToHome';
import JobsPage from './JobsPage';
import JobPage from './JobPage';
import CandidateDetails from './JobPage/CandidateDetails';
import Evaluation from './JobPage/Evaluation';
import EmployeeEvaluations from './EmployeeEvaluations';
import { UserInfo } from '../context/UserInfo/types';

export const renderIfNotActiveHiringManager =
  (node: React.ReactNode) =>
  ({ isHiringManager, isActive }: UserInfo) =>
    isHiringManager && isActive ? node : <RedirectToHome />;

export const recruitmentRoutes = {
  candidateDetails: {
    route: route('recruitment', 'candidate', param('candidateId')),
    render: (_userInfo: UserInfo) => <CandidateDetails />,
  },
  jobCandidates: {
    route: route('recruitment', 'job', param('jobId'), 'candidates'),
    render: renderIfNotActiveHiringManager(<JobPage />),
  },
  jobOverview: {
    route: route('recruitment', 'job', param('jobId')),
    render: renderIfNotActiveHiringManager(<JobPage />),
  },
  jobsList: {
    route: route('recruitment', 'jobs'),
    render: renderIfNotActiveHiringManager(<JobsPage />),
  },
  evaluation: {
    route: route('recruitment', 'evaluation', param('candidateEvaluationId')),
    render: (_userInfo: UserInfo) => <Evaluation />,
  },
  jobEvaluations: {
    route: route('recruitment', 'job', param('jobId'), 'evaluations'),
    render: renderIfNotActiveHiringManager(<JobPage />),
  },
  employeeEvaluations: {
    route: route('recruitment', 'evaluations'),
    render: () => <EmployeeEvaluations />,
  },
  recruitment: {
    route: route('recruitment', 'jobs'),
    render: renderIfNotActiveHiringManager(<JobsPage />),
  },
};
