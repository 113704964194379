import { DashboardAbsentToday_ColleagueFragment } from '../../../__generated__/graphql';
import { WidgetEmployee } from '../CommonComponents/WidgetSections/types';
import { mapCommonColleagueFields } from '../common/mappings';
import React from 'react';
import AdditionalInfo from './AdditionalInfo';

export const mapAbsences = (
  absence: DashboardAbsentToday_ColleagueFragment,
  t: Function,
): WidgetEmployee => {
  const additionalInfo = <AdditionalInfo absence={absence} t={t} />;

  return absence.employee
    ? {
        ...mapCommonColleagueFields(absence.employee),
        date: new Date(absence.flair__End_Date__c),
        additionalInfo: additionalInfo,
      }
    : ({} as WidgetEmployee);
};
