import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LocationFormField } from './FormFields/LocationFormField';
import { InventoryModalContext } from '../InventoryModalContext';
import { ResponsibleFormField } from './FormFields/ResponsibleFormField';

export const ResponsibleForm: React.FC = () => {
  const { isExpanded = false, onChangeAdditionalValues } = useContext(
    InventoryModalContext,
  );

  const handleGetResponsibleEmployee = (employeeName: string) => {
    onChangeAdditionalValues({
      responsibleEmployeeName: employeeName,
    });
  };

  const handleGetLocationName = (locationName: string) => {
    onChangeAdditionalValues({
      locationName: locationName,
    });
  };
  return (
    <Row>
      <Col className="mb-3" lg={isExpanded ? 6 : 12}>
        <ResponsibleFormField onValueChange={handleGetResponsibleEmployee} />
      </Col>
      <Col className="mb-3" lg={isExpanded ? 6 : 12}>
        <LocationFormField onValueChange={handleGetLocationName} />
      </Col>
    </Row>
  );
};
