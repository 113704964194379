import { groupBy, uniqBy } from 'lodash';
import { Moment } from 'moment';
import React, { useContext } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormattedDate from '../../../../components/date/FormattedDate';
import {
  CalendarQueryDocument,
  CalendarQueryQuery,
  useCalendarQueryQuery,
} from '../../__generated__/graphql';
import EmployeeRow from './EmployeeRow';
import OpenShiftRow from './OpenShiftRow';
import Loading from './Loading';
import ServerError from '../../../../components/ServerError';
import { PureQueryOptions } from '@apollo/client';
import {
  generateOpenShiftsMatrix,
  getOpenShifts,
  OpenShiftUI,
} from './openShiftsLogic';
import { filterColleagues } from './logic';
import { ShiftsContext } from './index';
import { EmptyState } from '../../components/EmptyStateCard';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {
  days: Moment[];
};

function filterNullable<T>(t: T | undefined | null): t is T {
  return t !== undefined && t !== null;
}

type ContentProps = {
  locationId: string | null;
  days: Moment[];
  colleagues: CalendarQueryQuery['me']['activeColleagues'];
  invitedColleagues: CalendarQueryQuery['me']['invitedColleagues'];
  openShifts: OpenShiftUI[];
  refetchQueries?: Array<PureQueryOptions>;
};

const Content: React.FC<ContentProps> = ({
  locationId,
  refetchQueries,
  days,
  openShifts,
  invitedColleagues,
  colleagues,
}) => {
  const { t } = useTranslation();

  const topHeader = (firstLabel?: string) => {
    return (
      <thead>
        <tr>
          <th>{firstLabel ?? ''}</th>
          {days.map((day, i) => (
            <th key={i} className="text-center">
              <FormattedDate day={day} />
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const headerDelimeter = (label: string) => {
    return (
      <thead>
        <tr>
          <th colSpan={8}>{label}</th>
        </tr>
      </thead>
    );
  };

  const renderOpenShifts = () => {
    if (!openShifts.length) {
      return null;
    }
    const shiftsMatrix = generateOpenShiftsMatrix(days, openShifts);
    return (
      <>
        {headerDelimeter(t('shiftPlanner.table.header.openShifts'))}
        <tbody>
          {shiftsMatrix.map((shiftsRow, i) => (
            <OpenShiftRow
              key={i}
              allDaysShifts={shiftsRow}
              refetchQueries={refetchQueries}></OpenShiftRow>
          ))}
        </tbody>
      </>
    );
  };

  if (invitedColleagues.length > 0 || openShifts.length > 0) {
    const invitedLocations = uniqBy(
      invitedColleagues.map((c) => c.location).filter(filterNullable),
      'Id',
    );
    const groupedByLocationId = groupBy(invitedColleagues, 'location.Id');

    return (
      <>
        {topHeader()}
        {renderOpenShifts()}
        {invitedLocations.map((location, i) => (
          <React.Fragment key={i}>
            {headerDelimeter(
              t('shiftPlanner.table.header.invitendLocation', {
                location: location.Name,
              }),
            )}
            <tbody>
              {groupedByLocationId[location.Id].map((employee, i) => (
                <EmployeeRow
                  currentLocationId={locationId}
                  key={i}
                  days={days}
                  employee={employee}
                />
              ))}
            </tbody>
          </React.Fragment>
        ))}
        {headerDelimeter(t('shiftPlanner.table.header.yourLocation'))}
        <tbody>
          {colleagues.map((employee, i) => (
            <EmployeeRow
              currentLocationId={locationId}
              key={i}
              days={days}
              employee={employee}
            />
          ))}
        </tbody>
      </>
    );
  }

  return (
    <>
      {topHeader(t('shiftPlanner.table.header.yourLocation'))}
      <tbody>
        {colleagues.map((employee, i) => (
          <EmployeeRow
            currentLocationId={locationId}
            key={i}
            days={days}
            employee={employee}
          />
        ))}
      </tbody>
    </>
  );
};

const i18Path = 'shiftPlanner.table';

const Table: React.FC<Props> = ({ days }) => {
  const t = useNamespacedTranslation(i18Path);
  const { filter } = useContext(ShiftsContext);
  const nDays = days.length;
  const variables = {
    from: days[0].toISOString(),
    to: days[nDays - 1].endOf('day').toISOString(),
  };
  const { loading, data } = useCalendarQueryQuery({
    variables,
  });

  const refetchQueries = [
    {
      query: CalendarQueryDocument,
      variables,
    },
  ];

  if (loading) return <Loading />;

  if (!data) return <ServerError />;

  const openShifts = getOpenShifts(data);
  const activeColleagues = filterColleagues(data.me.activeColleagues, filter);
  const invitedColleagues = filterColleagues(data.me.invitedColleagues, filter);

  return (
    <>
      <BootstrapTable className="card-table" bordered size="sm" responsive>
        <Content
          locationId={data.me.flair__Location__c ?? null}
          days={days}
          colleagues={activeColleagues}
          invitedColleagues={invitedColleagues}
          openShifts={openShifts}
          refetchQueries={refetchQueries}
        />
      </BootstrapTable>
      {activeColleagues.length === 0 && invitedColleagues.length === 0 && (
        <div className="text-center p-5">
          <EmptyState title={t('noEmployees')} />
        </div>
      )}
    </>
  );
};

export default Table;
