import React from 'react';
import styled from '@emotion/styled';
import Button from '../../../../components/button';
import { Theme } from '../../../../theme';

type Props = {
  label: string;
  onClick: (v?: any) => void;
  bgColor?: string;
};
export const SelectionBottomActionButton: React.FC<Props> = ({
  label,
  onClick,
  bgColor,
}) => {
  return (
    <ActionButton
      className="fw-normal"
      bgcolor={bgColor}
      label={label}
      onClick={onClick}
    />
  );
};

const ActionButton = styled(Button)<{ bgcolor?: string }>`
  width: 6.25rem;
  height: 2.375rem;
  border-radius: 0.375rem;
  color: ${Theme.color.white};
  background: ${(props) => (props.bgcolor ? props.bgcolor : Theme.color.blue3)};
  border-color: ${(props) =>
    props.bgcolor ? props.bgcolor : Theme.color.blue3};
  &:hover,
  &:focus {
    border-color: ${(props) =>
      props.bgcolor ? props.bgcolor : Theme.color.blue3};
    background: ${(props) =>
      props.bgcolor ? props.bgcolor : Theme.color.blue3};
    color: ${Theme.color.white};
    opacity: 0.8;
  }
`;
