import * as React from 'react';

const NavigateOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3535 1.64641C14.5014 1.79419 14.5417 2.01833 14.4546 2.20834L8.95458 14.2083C8.85664 14.422 8.62309 14.5386 8.39343 14.4885C8.16378 14.4384 8.00005 14.2351 8.00005 14V8.00439H2.00005C1.76502 8.00439 1.56172 7.8407 1.51156 7.61108C1.46141 7.38146 1.57796 7.14791 1.79159 7.04992L13.7916 1.54554C13.9816 1.4584 14.2057 1.49863 14.3535 1.64641ZM4.28934 7.00439H8.25005C8.44896 7.00439 8.63973 7.08341 8.78038 7.22406C8.92103 7.36471 9.00005 7.55548 9.00005 7.75439V11.7091L12.9844 3.01599L4.28934 7.00439Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default NavigateOutlineIcon;
