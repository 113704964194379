import * as React from 'react';

const EllipseOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.99859 13.9969C11.3115 13.9969 13.9972 11.3113 13.9972 7.99834C13.9972 4.68541 11.3115 1.99976 7.99859 1.99976C4.68566 1.99976 2 4.68541 2 7.99834C2 11.3113 4.68566 13.9969 7.99859 13.9969Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default EllipseOutlineIcon;
