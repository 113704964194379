import * as React from 'react';

const AlertCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M13.9972 7.99834C13.9972 4.68662 11.3103 1.99976 7.99859 1.99976C4.68687 1.99976 2 4.68662 2 7.99834C2 11.3101 4.68687 13.9969 7.99859 13.9969C11.3103 13.9969 13.9972 11.3101 13.9972 7.99834Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
      <path
        d="M7.81757 5.18868L7.9969 8.99872L8.17592 5.18868C8.17703 5.16433 8.17315 5.14 8.16454 5.11719C8.15592 5.09438 8.14275 5.07357 8.12582 5.05602C8.10889 5.03848 8.08856 5.02457 8.06607 5.01515C8.04358 5.00573 8.01941 5.00099 7.99503 5.00123V5.00123C7.97093 5.00146 7.94714 5.00654 7.92505 5.01616C7.90296 5.02579 7.88305 5.03977 7.86648 5.05727C7.84992 5.07476 7.83705 5.09542 7.82865 5.118C7.82024 5.14058 7.81647 5.16462 7.81757 5.18868V5.18868Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99738 11.4975C7.87352 11.4975 7.75243 11.4607 7.64944 11.3919C7.54644 11.3231 7.46617 11.2253 7.41877 11.1108C7.37137 10.9964 7.35896 10.8705 7.38313 10.749C7.40729 10.6275 7.46694 10.5159 7.55453 10.4283C7.64212 10.3407 7.75371 10.2811 7.8752 10.2569C7.99669 10.2327 8.12261 10.2451 8.23705 10.2925C8.35149 10.3399 8.44931 10.4202 8.51813 10.5232C8.58694 10.6262 8.62367 10.7473 8.62367 10.8712C8.62367 11.0373 8.55769 11.1966 8.44024 11.314C8.32279 11.4315 8.16349 11.4975 7.99738 11.4975Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default AlertCircleOutlineIcon;
