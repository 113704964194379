import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Filter = () => (
  <div className="me-3 ">
    <Skeleton width={125} height={25} />
  </div>
);
const Loading: React.FC = () => (
  <div className="d-none d-md-flex align-items-baseline">
    <div className="me-3 mt-2">
      <Skeleton width={50} height={15} />
    </div>
    <Filter />
    <Filter />
    <Filter />
    <Filter />
    <Filter />
    <div className="me-3 ">
      <Skeleton width={65} height={15} />
    </div>
  </div>
);

export default Loading;
