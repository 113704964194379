import { useEffect } from 'react';
import {
  ObjectiveFilter,
  ObjectiveFilterManager,
  useAllObjectivesLazyQuery,
  useMyColleaguesObjectivesLazyQuery,
  useMyObjectivesLazyQuery,
  useMyReportsObjectivesLazyQuery,
} from '../../../__generated__/graphql';
import { GoalFilter } from './GoalsFilter/GoalsFilter';
import { mapGoals } from './mappings';
import { GoalType } from './types';

export const useObjectives = (
  filter: GoalFilter,
  onlyTypes: GoalType[] | 'all',
) => {
  const filterType = filter.filterType;

  const [getMyObjectives, myObjectivesResults] = useMyObjectivesLazyQuery({
    variables: {
      filter: toObjectiveFilter(filter),
    },
    notifyOnNetworkStatusChange: true,
  });
  const [getColleaguesObjectives, myColleaguesObjectiveResults] =
    useMyColleaguesObjectivesLazyQuery({
      variables: {
        filter: toObjectiveFilter(filter),
      },
      notifyOnNetworkStatusChange: true,
    });

  const [getAllObjectives, allObjectivesResults] = useAllObjectivesLazyQuery({
    variables: {
      filter: toObjectiveFilterManager(filter),
    },
    notifyOnNetworkStatusChange: true,
  });

  const [getMyReportsObjectives, myReportsObjectivesResult] =
    useMyReportsObjectivesLazyQuery({
      variables: {
        filter: toObjectiveFilterManager(filter),
      },
      notifyOnNetworkStatusChange: true,
    });

  const filterByGoalType = (goal: { type: GoalType }) => {
    if (onlyTypes === 'all') {
      return true;
    } else {
      return onlyTypes.includes(goal.type);
    }
  };
  useEffect(() => {
    switch (filterType) {
      case 'myGoals':
        getMyObjectives();
        break;
      case 'colleaguesGoals':
        getColleaguesObjectives();
        break;
      case 'allGoals':
        getAllObjectives();
        break;
      case 'myReportsGoals':
        getMyReportsObjectives();
        break;
    }
  }, [
    filterType,
    getMyObjectives,
    getColleaguesObjectives,
    getAllObjectives,
    getMyReportsObjectives,
  ]);

  switch (filterType) {
    case 'myGoals': {
      return {
        ...myObjectivesResults,
        goals: mapGoals(myObjectivesResults.data?.me.myObjectives)?.filter(
          filterByGoalType,
        ),
      };
    }
    case 'colleaguesGoals':
      return {
        ...myColleaguesObjectiveResults,
        goals: mapGoals(
          myColleaguesObjectiveResults.data?.me.colleaguesObjectives,
        )?.filter(filterByGoalType),
      };
    case 'allGoals':
      return {
        ...allObjectivesResults,
        goals: mapGoals(allObjectivesResults.data?.allObjectives)?.filter(
          filterByGoalType,
        ),
      };
    case 'myReportsGoals':
      return {
        ...myReportsObjectivesResult,
        goals: mapGoals(
          myReportsObjectivesResult.data?.manager.employeesObjectives,
        )?.filter(filterByGoalType),
      };
  }
};

const toObjectiveFilter = (src: GoalFilter): ObjectiveFilter => ({
  tagId: src.tagId,
  periodId: src.periodId,
  employeeId: src.employeeId,
  goalType: src.goalType,
});

const toObjectiveFilterManager = (src: GoalFilter): ObjectiveFilterManager => ({
  tagId: src.tagId,
  periodId: src.periodId,
  employeeId: src.employeeId,
  //todo: check location filter
  locationId: src.locationId,
  goalType: src.goalType,
});
