import React from 'react';
import { PureQueryOptions } from '@apollo/client';
import { useState } from 'react';
import {
  createNewTimeEntry,
  DayWithTimeEntries,
  TimeEntryItem,
  TimeEntryReadOnlyReason,
} from '../logic';
import DayTimeEntriesDumb from './DayTimeEntriesDumb';
import { CostCenterProps } from '..';
import { WarningModal } from '../../components/WarningModal/WarningModal';
import { useTimeTrackingRestriction } from '../../../../hooks/timeTrackingSettings';
import { toISODateOnly } from '../../../../../../utils/dateUtils';

type Props = {
  day: DayWithTimeEntries;
  costCenters: readonly CostCenterProps[];
  employeeId: string;
  refetchQueriesForEmployeeMode?: PureQueryOptions[];
  readonlyReasons: TimeEntryReadOnlyReason[];
};

const DayTimeEntriesSmart: React.FC<Props> = ({
  day,
  costCenters,
  employeeId,
  readonlyReasons,
  refetchQueriesForEmployeeMode,
}) => {
  const [timeEntryNew, setTimeEntryNew] = useState<TimeEntryItem | null>(null);
  const {
    showRestrictedWarningModal,
    setShowRestrictedWarningModal,
    hasOngoingRestrictedAbsenceForDay,
  } = useTimeTrackingRestriction();

  const handleAddNewTimeEntryClick = () => {
    if (hasOngoingRestrictedAbsenceForDay(toISODateOnly(day.day.toDate()))) {
      setShowRestrictedWarningModal(true);
    } else {
      setTimeEntryNew(
        createNewTimeEntry(day.day, day.timeSheetId, day.workload),
      );
    }
  };

  const handleCancelCreatingNewTimeEntry = () => {
    setTimeEntryNew(null);
  };

  const timeEntries = timeEntryNew ? [timeEntryNew] : day.timeEntries;

  return (
    <>
      <WarningModal
        visible={showRestrictedWarningModal}
        onClose={() => setShowRestrictedWarningModal(false)}
      />
      <DayTimeEntriesDumb
        day={day}
        costCenters={costCenters}
        employeeId={employeeId}
        employeeType={day.employeeType}
        readonlyReasons={readonlyReasons}
        timeEntries={timeEntries}
        refetchQueriesForEmployeeMode={refetchQueriesForEmployeeMode}
        onAddNewTimeEntryClick={handleAddNewTimeEntryClick}
        onCancelCreatingNewTimeEntry={handleCancelCreatingNewTimeEntry}
      />
    </>
  );
};

export default DayTimeEntriesSmart;
