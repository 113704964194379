import React, { useEffect } from 'react';
import { useLogin } from '../../../../hooks/useLogin';

type Props = {
  apiToken: string;
};

const LoginWithApiToken: React.FC<Props> = ({ apiToken }) => {
  const login = useLogin();

  useEffect(() => {
    login(apiToken);
  }, [login, apiToken]);

  return <></>;
};

export default LoginWithApiToken;
