import classNames from 'classnames';
import React from 'react';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

export type Props = {
  position?: string;
  department?: string;
};

export const EmployeePopupContent: React.FC<Props> = ({
  position,
  department,
}) => {
  const t = useNamespacedTranslation('components.employeeWithAvatarAndPopup');
  return (
    <Container>
      {position && (
        <EmployeePopupColumn header={t('position')} value={position} />
      )}
      {department && (
        <EmployeePopupColumn header={t('department')} value={department} />
      )}
    </Container>
  );
};

type ColumnProps = {
  header: string;
  value: string;
};

const EmployeePopupColumn = ({ header, value }: ColumnProps) => {
  return (
    <div>
      <Header>{header}</Header>
      <div>{value}</div>
    </div>
  );
};

const Container = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={classNames('fs-5', 'd-flex', 'flex-column', 'gap-2')}
  />
);

const Header = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('text-primary')} />
);
