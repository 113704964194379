import React from 'react';

import {
  InfinityScrollParams,
  useInifinityScrollBase,
} from '../../hooks/useInifinityScrollBase';
import { LoadingMoreItemsSpinner } from './LoadingMoreItemsSpinner';

export function useInifinityScroll<T>(params: InfinityScrollParams<T>) {
  const { ref, loadingMore } = useInifinityScrollBase(params);
  const LoadingMore = (
    <>
      <div ref={ref} style={{ width: 1, height: 1 }}></div>
      {loadingMore && (
        <LoadingMoreItemsSpinner loadingMessage={params.loadingMessage} />
      )}
    </>
  );
  return { LoadingMore };
}
