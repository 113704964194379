import classNames from 'classnames';
import { times } from 'lodash';
import React from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { HubContainer } from '../../../../components/HubContainer/HubContainer';
import { Theme } from '../../../../theme';
import SkeletonCard from '../../components/Skeleton/Card';
import SkeletonPageHeader from '../../components/Skeleton/PageHeader';

type Props = {
  isManagerModeOn: boolean;
};

const LoadingNavbar: React.FC<Props> = ({ isManagerModeOn, children }) => {
  const navbarProps = {
    expand: 'lg',
    className: classNames('navbar-vertical', 'fixed-start'),
  };

  if (isManagerModeOn) {
    return (
      <Navbar {...navbarProps} variant={'dark'}>
        <SkeletonTheme
          color={Theme.color.blue3}
          highlightColor={Theme.color.blue4}>
          {children}
        </SkeletonTheme>
      </Navbar>
    );
  }

  return (
    <Navbar {...navbarProps} variant={'light'}>
      {children}
    </Navbar>
  );
};

export const Loading: React.FC<Props> = ({ isManagerModeOn }) => {
  return (
    <>
      <LoadingNavbar isManagerModeOn={isManagerModeOn}>
        <Container fluid>
          <Navbar.Toggle aria-controls="hub-navbar-nav-loading" />
          <Navbar.Brand>
            <Skeleton height={55} className="navbar-brand-img" />
          </Navbar.Brand>
          <Navbar.Collapse id="hub-navbar-nav-loading" className="gap-4">
            <Nav>
              {times(15).map((i) => (
                <Nav.Item key={i}>
                  <Nav.Link
                    className={classNames(
                      'd-flex',
                      'align-items-center',
                      'gap-3',
                    )}>
                    <Skeleton width={15} height={15} />
                    <Skeleton width={150} />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </LoadingNavbar>

      <div className="main-content">
        <HubContainer>
          <SkeletonPageHeader />
          <Row>
            <Col lg={8}>
              <SkeletonCard height={290} />
              <SkeletonCard height={400} />
              <SkeletonCard height={500} />
            </Col>
            <Col lg={4}>
              <SkeletonCard height={90} />
              <SkeletonCard height={180} />
              <SkeletonCard height={270} />
            </Col>
          </Row>
        </HubContainer>
      </div>
    </>
  );
};
