import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import SingleFilter, {
  Props as SingleFilterProps,
} from '../../../../../../../components/SelectFilters/SingleFilter';
import { FilterSelectOption } from '../../../../../../../atomic/molecules/FlairDropdown/types';
import { useQueryParams } from '../../../../../../../hooks/useQueryParams';
import { routes } from '../../../../../routes';
import { TeamCalendarFiltersKeys, TeamCalendarFiltersType } from './types';

type Props = Omit<SingleFilterProps, 'options' | 'value' | 'onChange'> & {
  options: FilterSelectOption[];
  filterData: TeamCalendarFiltersType;
  filterKey: TeamCalendarFiltersKeys;
};

const StyledSingleFilter = styled(SingleFilter)({
  maxWidth: '10rem',
});

const TeamCalendarSingleFilter: React.FC<Props> = ({
  options,
  filterData,
  filterKey,
  ...rest
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const handleChange = (value: string | null) => {
    history.push(
      routes.teamCalendar.route
        .withQueryParams({
          ...queryParams,
          [filterKey]: value,
        })
        .create({}),
    );
  };
  return (
    <StyledSingleFilter
      options={options}
      value={filterData[filterKey]}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default TeamCalendarSingleFilter;
