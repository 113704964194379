import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18prefix } from '../../helpers';
import Button from '../../../../../../components/button';
import { minutesToHoursAndMinutes } from '../../../../../../utils/time';

import { Project, ProjectDay, Timesheet } from '../../types';
import { totalMinutes } from '../mappings';
import ProjectWeekTimeTracking from './ProjectWeekTimeTracking';
import { ProjectTitleCol } from './ProjectTitleCol';
import { TotalHoursCol } from './TotalHoursCol';
import { TimeSheetStatus } from '../../../../__generated__/graphql';

type Props = {
  project: Project;
  timesheet: Timesheet;
  days: ProjectDay[];
  isSubmitting: boolean;
  onChange: (projectId: string, days: ProjectDay[]) => void;
  onProjectClick: (project: Project) => void;
};

export const ProjectTimesheet: React.FC<Props> = ({
  project,
  timesheet,
  days,
  isSubmitting,
  onChange,
  onProjectClick,
}) => {
  const { t } = useTranslation();
  const [hours, minutes] = minutesToHoursAndMinutes(totalMinutes(days));

  const disableTimeSheet =
    isSubmitting || timesheet.approvalStatus !== TimeSheetStatus.Pending;
  return (
    <tr className="py-2 align-items-center">
      <ProjectTitleCol>
        <Button
          className="p-0"
          onClick={() => onProjectClick(project)}
          variant="link"
          label={project.name}
        />
      </ProjectTitleCol>
      <ProjectWeekTimeTracking
        onChange={(days) => onChange(project.id, days)}
        disabled={disableTimeSheet}
        days={days}
      />
      <TotalHoursCol className="text-end fw-bold cell-bl">
        {t(`${i18prefix}.sidebar.timeTrackedFormat`, {
          hours,
          minutes,
        })}
      </TotalHoursCol>
    </tr>
  );
};
