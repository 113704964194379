import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../../Router';
import { routes } from '../../routes';

const UserNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h1 className="display-4 mb-3">{t('userNotFound.title')}</h1>
      <p className="text-muted mb-4">{t('userNotFound.description')}</p>
      <Link to={routes.login.route} className="btn btn-primary">
        {t('userNotFound.button')}
      </Link>
    </div>
  );
};

export default UserNotFound;
