import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InventoryModalContext } from '../InventoryModalContext';
import { ModelFormField } from './FormFields/ModelFormField';
import { ManufacturerFormField } from './FormFields/ManufacturerFormField';
import { useGetInventoryOptions } from '../../../hooks/useGetInventoryOptions';

export const InventoryForm = () => {
  const {
    isExpanded = false,
    value,
    additionalValues,
    onChange: onFieldChange,
  } = useContext(InventoryModalContext);
  const isExistingInventory = !(
    additionalValues && additionalValues.isNewInventory
  );
  const { findInventoryById } = useGetInventoryOptions();

  useEffect(() => {
    if (isExistingInventory) {
      const inventory = value ? findInventoryById(value.name) : null;
      if (inventory) {
        onFieldChange({
          manufacturer: inventory.manufacturer?.Id,
          model: inventory.flair__Model__c,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExistingInventory, value?.name]);

  return (
    <Row>
      <Col className="px-lg-2 mb-4" lg={isExpanded ? 6 : 12}>
        <ManufacturerFormField isExistingInventory={isExistingInventory} />
      </Col>
      <Col className="px-lg-2" lg={isExpanded ? 6 : 12}>
        <ModelFormField isExistingInventory={isExistingInventory} />
      </Col>
    </Row>
  );
};
