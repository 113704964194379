import styled from '@emotion/styled';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import Cell from './EmployeeAbsenceCalendarCardCell';
import { Nullable } from '../../../../../utils/nullable';
import { CalendarDay } from '../../AbsenceCalendar/types';
import { calcVariant, isOverlapped } from '../../AbsenceCalendar/Utils';
import AbsenceDisplay from '../../AbsenceCalendar/AbsenceDisplay';
import AbsenceOverlay from '../../AbsenceCalendar/AbsenceOverlay';
import HolidayPopover from '../../HolidayPopover';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  day: CalendarDay;
  onHover: (day: Nullable<CalendarDay>) => void;
  hoveredDay?: Nullable<CalendarDay>;
};

const Content = styled.div<{ visible: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  ({ visible }) => ({
    visibility: visible ? 'visible' : 'hidden',
  }),
);

const EmployeeAbsenceCalendarCardDayCell: React.FC<Props> = ({
  day,
  onHover,
  hoveredDay,
}) => {
  const target = useRef<HTMLTableDataCellElement>(null);

  const absences = day.absences;

  const absence = absences[0];
  const holiday = day.holiday;

  const isHovered = !!hoveredDay?.day.isSame(day.day, 'date');

  const handleMouseEnter = useCallback(() => {
    onHover(day);
  }, [day, onHover]);

  const overlappedAbsences = isOverlapped(absences);

  const variant = calcVariant(
    day.workday,
    overlappedAbsences,
    absences,
    holiday,
  );

  const today = day.day.isSame(moment(), 'date');

  const showVerticalLine =
    !!hoveredDay &&
    hoveredDay.day.date() === day.day.date() &&
    day.day.month() < hoveredDay.day.month();
  const showHorizontalLine =
    !!hoveredDay &&
    hoveredDay.day.month() === day.day.month() &&
    day.day.date() < hoveredDay.day.date();

  return (
    <>
      <Cell
        ref={target}
        hover={day.hover}
        onMouseEnter={handleMouseEnter}
        cellHovered={isHovered}
        variant={variant}
        today={today}
        showVerticalLine={showVerticalLine}
        showHorizontalLine={showHorizontalLine}>
        {absences && (
          <AbsenceDisplay
            day={day}
            absences={absences}
            isHovered={isHovered || today}
          />
        )}
        <Content visible={isHovered || today}>
          {overlappedAbsences ? (
            <FlairIcon icon="alert-circle-outline" />
          ) : (
            <strong>{day.day.date()}</strong>
          )}
          <span>{day.day.format('ddd')}</span>
        </Content>
      </Cell>

      {absence && (
        <AbsenceOverlay
          target={target}
          showPopover={isHovered}
          absence={absence}
          absences={absences}
          holiday={holiday}
        />
      )}

      {holiday && (
        <Overlay
          target={target.current}
          show={!absence && isHovered}
          placement="top">
          {(props) => <HolidayPopover holiday={holiday} {...props} />}
        </Overlay>
      )}
    </>
  );
};
export default EmployeeAbsenceCalendarCardDayCell;
