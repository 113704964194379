import React from 'react';
import { Popover } from 'react-bootstrap';
import FormattedDate from '../../../../components/date/FormattedDate';
import HolidayIcon from '../HolidayIcon';
import { CalendarPeriodPopoverFragment } from '../../__generated__/graphql';

type Props = {
  calendarPeriod: CalendarPeriodPopoverFragment;
};

const CalendarPeriodPopover: React.FC<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(({ calendarPeriod, ...rest }, ref) => {
  const popoverProps = {
    ref,
    ...rest,
  };

  return (
    <Popover id={calendarPeriod.Id} {...popoverProps}>
      <Popover.Header>
        <HolidayIcon />
        <span className="ms-2">{calendarPeriod.Name}</span>
      </Popover.Header>
      <Popover.Body>
        <FormattedDate
          day={calendarPeriod.flair__Start_Date__c}
          format="long"
        />
      </Popover.Body>
    </Popover>
  );
});

export default CalendarPeriodPopover;
