import * as React from 'react';
import FlairCard from '../../../../../../atomic/templates/FlairCard/FlairCard';
import classNames from 'classnames';
import { TimeSince } from '../../../../../../components/TimeSince';
import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import './CelebrationUpdateItem.css';
import ConnectedReactions from '../../../../components/Reactions/ConnectedReactions';
import { ReactionsRelatedObjectNames } from '../../../../components/Reactions/ConnectedReactions/types';
import CommentsPopover from '../../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import SenderAndRecipientsSection from './SenderAndRecipientsSection';
import { CelebrationFragment } from '../../../../__generated__/graphql';
import { mapCelebration } from '../../../Celebrations/employee/mappings';
import { LoomVideoPreview } from '../../../../components/LoomVideo';

const LOOM_VIDEO_PREVIEW_WIDTH = 224;
const LOOM_VIDEO_PREVIEW_HEIGHT = 174;

type Props = {
  celebrationFragment: CelebrationFragment;
};

const CelebrationUpdateCard: React.FC<Props> = ({ celebrationFragment }) => {
  const celebration = mapCelebration(celebrationFragment);
  const {
    id,
    reactions,
    commentsCount,
    from,
    recipients,
    message,
    date,
    loomVideo,
    imageUrl,
    type,
  } = celebration;

  const imageComponent = useMemo(
    () =>
      loomVideo === null ? (
        <Card.Img src={imageUrl} alt={type} />
      ) : (
        <LoomVideoPreview
          loomVideo={loomVideo}
          previewWidth={LOOM_VIDEO_PREVIEW_WIDTH}
          previewHeight={LOOM_VIDEO_PREVIEW_HEIGHT}
        />
      ),
    [imageUrl, type, loomVideo],
  );

  return (
    <FlairCard
      cardContainerClassName="celebration-update-item-container"
      contentClassName="py-3-5">
      <div className="d-flex gap-3-5 mb-3-5 main-content">
        <div className="img-loom-container">{imageComponent}</div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex justify-content-between align-items-center">
            <SenderAndRecipientsSection sender={from} recipients={recipients} />
            <TimeSince
              date={date}
              className={classNames('h5-regular', 'created-date')}
            />
          </div>
          <div className="mt-3-5">{message}</div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center small text-dark">
        <ConnectedReactions
          reactions={reactions}
          recordId={id}
          relatedObjectName={ReactionsRelatedObjectNames.Celebration}
        />
        <CommentsPopover
          recordId={id}
          relatedObjectName={RelatedObjectNames.Celebration}
          commentsCount={commentsCount}
          mode="text"
        />
      </div>
    </FlairCard>
  );
};

export default CelebrationUpdateCard;
