import {
  LastUpdateFilterByCategory,
  LastUpdateFilterByTeam,
} from '../../../../__generated__/graphql';

export const getFilterByTeamOption = (filterBy: string) => {
  if (filterBy === LastUpdateFilterByTeam.MyTeam.toString()) {
    return LastUpdateFilterByTeam.MyTeam;
  }

  return LastUpdateFilterByTeam.AllCompany;
};

export const getFilterByCategoryOption = (filterBy: string) => {
  switch (filterBy) {
    case LastUpdateFilterByCategory.All.toString():
      return LastUpdateFilterByCategory.All;
    case LastUpdateFilterByCategory.Announcements.toString():
      return LastUpdateFilterByCategory.Announcements;
    case LastUpdateFilterByCategory.Cheers.toString():
      return LastUpdateFilterByCategory.Cheers;
    case LastUpdateFilterByCategory.NewJoiners.toString():
      return LastUpdateFilterByCategory.NewJoiners;
    default:
      throw new Error(`Invalid filterBy value: ${filterBy}`);
  }
};
