import { Reference } from '@apollo/client';
import i18next from 'i18next';
import { useCallback } from 'react';
import { useToasts } from '../../../../context/Toast';
import { useUserInfo } from '../../context/UserInfo';
import {
  ApproveTimeSheetError,
  ApproveTimeSheetErrorCode,
  useApproveTimeSheetsMutation,
} from '../../__generated__/graphql';

type HookReturnType = ReturnType<typeof useApproveTimeSheetsMutation>;

export const useApproveTimeSheets = (): HookReturnType => {
  const { id: managerId } = useUserInfo();
  const { addError } = useToasts();

  const [approve, ...rest] = useApproveTimeSheetsMutation({
    update: (cache, response) => {
      if (!response.data) {
        return;
      }
      const approvedIds = response.data.timeSheet.approveTimeSheets.recordIds;
      if (approvedIds.length === 0) {
        return;
      }
      // Query.timeSheet($id)
      approvedIds.forEach((timeSheetId) => {
        cache.evict({
          id: cache.identify({
            __typename: 'TimeSheet',
            Id: timeSheetId,
          }),
        });
      });
      // Manager.timeSheetsPagination
      const managerCacheId = cache.identify({
        __typename: 'Manager',
        Id: managerId,
      });
      cache.modify({
        id: managerCacheId,
        fields: {
          timeSheetsPagination(existent, { readField }) {
            if (!existent) {
              return existent;
            }
            let result = { ...existent };
            if (existent.items) {
              const items: Reference[] = existent.items;
              result.items = items.filter((itemRef) => {
                const itemId = readField('Id', itemRef);
                return !approvedIds.includes(itemId as string);
              });
            }
            if (existent.paginationInfo) {
              result.paginationInfo = {
                ...existent.paginationInfo,
                totalCount: Math.max(
                  0,
                  existent.paginationInfo.totalCount - approvedIds.length,
                ),
              };
            }
            return result;
          },
        },
      });
    },
  });

  const approveWithErrorHandling = useCallback(
    (args) =>
      approve(args).then((result) => {
        const error = result.data?.timeSheet.approveTimeSheets.error;
        if (error) {
          addError(getErrorText(error));
        }
        return result;
      }),
    [approve, addError],
  );

  return [approveWithErrorHandling, ...rest];
};

const i18prefix = 'timeSheet.errors.approve';

export const getErrorText = (error: ApproveTimeSheetError): string => {
  const { errorCode, message } = error;
  switch (errorCode) {
    case ApproveTimeSheetErrorCode.NoAccess:
      return i18next.t(`${i18prefix}.noAccess`, { message });
    case ApproveTimeSheetErrorCode.NotFound:
      return i18next.t(`${i18prefix}.notFound`, { message });
    case ApproveTimeSheetErrorCode.NotInPendingState:
      return i18next.t(`${i18prefix}.notInPendingState`, { message });
    default:
      return i18next.t(`${i18prefix}.unknown`, { message });
  }
};
