import { addDays, getISODay } from 'date-fns';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useNow } from '../../../../../hooks/useNow';
import { mapBreakReminders } from './mappings';
import {
  getReminderToShow,
  breakReminderActions,
  breakRemindersStateReducer,
  calculateReminderToShow,
} from '../shared/breakReminders';
import { useTimeTrackingState } from '../useTimeTrackingState';
import { IsoWeekDay } from '../mappings';
import { useFlairLocalStorage } from '../../../hooks/useFlairLocalStorage';
import { useTimeTrackingSettings } from '../../../hooks/timeTrackingSettings';

export const useReminderToShow = (): {
  reminderToShowId: string | null;
  currentTimeEntryId: string | null;
  closeReminderModal: (reminderId: string) => void;
} => {
  const { timeTrackingState, currentTimeEntryId } = useTimeTrackingState();
  const { breakReminders } = useBreakReminders(
    currentTimeEntryId && timeTrackingState
      ? { id: currentTimeEntryId, start: timeTrackingState.start }
      : undefined,
  );
  const { now } = useNow(1000);
  const { getStoredReminderIds, storeReminderId } = useClosedIdStorage();

  const [state, dispatch] = useReducer(breakRemindersStateReducer, {
    timeTrackingState: null,
    closedReminderIds: getStoredReminderIds(),
    suggestedReminderToShowId: null,
  });

  // dispatch.closeReminder
  const closeReminderModal = useCallback(
    (reminderId: string) => {
      dispatch(breakReminderActions.closeReminder(reminderId));
      storeReminderId(reminderId);
    },
    [dispatch, storeReminderId],
  );

  // dispatch.setTimeTrackingState
  useEffect(() => {
    if (timeTrackingState !== null) {
      dispatch(breakReminderActions.setTimeTrackingState(timeTrackingState));
    }
  }, [dispatch, timeTrackingState]);

  // dispatch.setSuggestedReminderId
  useEffect(() => {
    if (!timeTrackingState || !breakReminders) {
      return;
    }
    const reminderToShowId =
      getReminderToShow(breakReminders, timeTrackingState, now)?.reminderId ??
      null;

    dispatch(breakReminderActions.setSuggestedReminderId(reminderToShowId));
  }, [dispatch, breakReminders, timeTrackingState, now]);

  const reminderToShowId = useMemo(
    () => calculateReminderToShow(state),
    [state],
  );

  return {
    reminderToShowId: reminderToShowId,
    currentTimeEntryId,
    closeReminderModal,
  };
};

const useBreakReminders = (
  currentTimeEntry: { id: string; start: Date } | undefined,
) => {
  const { timeFramework, workloadWeek } = useTimeTrackingSettings();

  const breakReminders = useMemo(() => {
    if (!timeFramework) {
      return null;
    }
    return currentTimeEntry
      ? mapBreakReminders(
          timeFramework,
          workloadWeek,
          currentTimeEntry.id,
          getISODay(currentTimeEntry.start) as IsoWeekDay,
        )
      : [];
  }, [timeFramework, workloadWeek, currentTimeEntry]);

  return { breakReminders };
};

export type StoredIdType = {
  id: string;
  expireAt: Date;
};
const EXPIRATION_DAYS = 3;

const useClosedIdStorage = () => {
  const [storedIds, storeIds] = useFlairLocalStorage(
    'closed_break_reminders',
    [],
  );
  const getStoredReminderIds = () => storedIds.map((x) => x.id);
  const storeReminderId = (reminderId: string) => {
    if (storedIds.some((x) => x.id === reminderId)) {
      return;
    }
    const now = new Date();
    storeIds(
      [
        ...storedIds,
        {
          id: reminderId,
          expireAt: addDays(now, EXPIRATION_DAYS),
        },
      ].filter((x) => x.expireAt > now),
    );
  };
  return { getStoredReminderIds, storeReminderId };
};
