import React, { useMemo, useState } from 'react';
import { TimeSheetColumn } from './Tables/types';
import {
  RowType,
  TableRow,
  TableRowTimeSheet,
  ViewBy,
  isTableDaySubRow,
  isTableRowHeaderEmployee,
  isTableRowHeaderProject,
  isTableRowHeaderWeek,
  isTableRowTimeSheet,
} from './types';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { ProjectPeriodCell } from './Tables/ProjectPeriodCell';
import { TimeSheetPeriodCell } from './Tables/TimeSheetPeriodCell';
import { EmployeeCell } from './Tables/EmployeeCell';
import { EmployeeDetailsPopover } from '../../../manager/TimeSheetsControlling/Popovers/EmployeeDetailsPopover/EmployeeDetailsPopover';
import { TimeSheetRowCell } from './Tables/TimeSheetRowCell';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import { Spinner } from 'react-bootstrap';
import { DayRowCell } from './Tables/DayRowCell';
import { ProjectTrackedTimeFormattedMessage } from '../ProjectTrackedTimeFormattedMessage';
import { TrackedTimeCell } from './Tables/TrackedTimeCell';
import { DayCommentsCell } from './Tables/DayCommentsCell';
import {
  BadgesCell,
  TimeSheetCommentsCell,
} from '../../../components/TimeSheetsTable';
import { ApproveStatusCell } from './Tables/ApproveStatusCell';
import { ProjectTrackingProgressBar } from '../ProjectTrackingProgressBar';

type Props = {
  viewBy: ViewBy;
  onSelectAll: (allTableRows: TableRow[], selected: boolean) => void;
  onSelectRow: (
    allTableRows: TableRow[],
    tableRow: TableRow,
    selected: boolean,
  ) => void;
  onExpandedChanged: (rowId: string, expanded: boolean) => void;
  onApprove: (timeSheetRow: TableRowTimeSheet) => void;
};

export const useProjectTimeSheetsTableColumns = ({
  onSelectAll,
  onSelectRow,
  viewBy,
  onExpandedChanged,
  onApprove,
}: Props): TimeSheetColumn[] => {
  const t = useNamespacedTranslation('timeTracking.projects.controlling.table');

  const [headerCheckboxSelected, setHeaderCheckboxSelected] =
    useState<boolean>(false);

  const columns: TimeSheetColumn[] = useMemo(() => {
    return [
      {
        Header: ({ allRows }) => (
          <input
            id="isAllSelected"
            type="checkbox"
            checked={headerCheckboxSelected}
            onChange={(e) => {
              setHeaderCheckboxSelected(e.currentTarget.checked);
              onSelectAll(allRows, e.currentTarget.checked);
            }}
            className="form-check-input"
          />
        ),
        accessor: 'id',
        Cell: ({ value, row, allRows, isExpanded }) => {
          const tableRow = row;
          const showCheckbox =
            (viewBy === 'project' &&
              (isTableRowHeaderWeek(tableRow) ||
                isTableRowHeaderProject(tableRow))) ||
            (viewBy === 'employee' &&
              (isTableRowTimeSheet(tableRow) ||
                isTableRowHeaderEmployee(tableRow)));

          return (
            <div
              className="d-flex align-items-center"
              style={getCellMarginStyle(tableRow.rowType, viewBy)}>
              {showCheckbox && (
                <CheckboxColumn>
                  <input
                    id={value}
                    type="checkbox"
                    checked={tableRow.selected}
                    disabled={tableRow.selectDisabled}
                    onChange={(e) => {
                      onSelectRow(allRows, tableRow, e.currentTarget.checked);
                    }}
                    className={`form-check-input`}
                  />
                </CheckboxColumn>
              )}
              <SecondColumnContainer>
                <SecondCell
                  row={tableRow}
                  isExpanded={isExpanded}
                  onExpandedChanged={onExpandedChanged}
                  viewBy={viewBy}
                />
              </SecondColumnContainer>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: () => t('header.hours'),
        disableSortBy: true,
        accessor: 'trackedMinutes',
        Cell: ({ value, row }) => {
          const tableRow: TableRow = row;
          if (tableRow.rowType === 'header-project') {
            const project = tableRow.headerProject;
            return (
              <div>
                <ProjectTrackedTimeFormattedMessage
                  trackedTime={project.timeTrackedInMin}
                  projectEstimation={
                    project.estimatedInMin
                  }></ProjectTrackedTimeFormattedMessage>
              </div>
            );
          } else {
            return value !== undefined ? (
              <TrackedTimeCell value={value} />
            ) : null;
          }
        },
      },
      {
        Header: () => t('header.note'),
        disableSortBy: true,
        accessor: 'note',
        Cell: ({ value, row }) => {
          const tableRow: TableRow = row;
          if (viewBy === 'employee' && isTableDaySubRow(tableRow)) {
            return null;
          }
          return (
            <NoteColumnContainer className="text-wrap">
              {value}
            </NoteColumnContainer>
          );
        },
      },
      {
        Header: () => t('header.commentsCount'),
        disableSortBy: true,
        accessor: 'commentsCount',
        Cell: ({ row }) => {
          const original: TableRow = row;
          if (isTableDaySubRow(original) && original.timeSheetDayId) {
            return (
              <DayCommentsCell
                timeSheetDayId={original.timeSheetDayId}
                commentsCount={original.commentsCount}
              />
            );
          } else if (isTableRowTimeSheet(original)) {
            return (
              <TimeSheetCommentsCell commentsCount={original.commentsCount} />
            );
          }
          return null;
        },
      },
      {
        Header: () => null,
        disableSortBy: true,
        accessor: 'badges',
        Cell: ({ row }) => {
          const original: TableRow = row;
          if (isTableDaySubRow(original)) {
            return <BadgesCell badges={original.badges} />;
          }
          return null;
        },
      },
      {
        Header: () => null,
        disableSortBy: true,
        accessor: 'actions',
        Cell: ({ row }) => {
          const tableRow: TableRow = row;
          const { rowType } = tableRow;
          switch (rowType) {
            case 'row-employee':
            case 'row-period':
              return (
                <ApproveStatusCell
                  timeSheetRow={tableRow}
                  onApprove={onApprove}
                />
              );
            case 'header-project':
              const project = tableRow.headerProject;
              return (
                <ProjectTrackingProgressBar
                  trackedTime={project.timeTrackedInMin}
                  projectEstimation={
                    project.estimatedInMin
                  }></ProjectTrackingProgressBar>
              );
            default:
              return null;
          }
        },
      },
    ];
  }, [
    onSelectAll,
    onSelectRow,
    t,
    viewBy,
    headerCheckboxSelected,
    onApprove,
    onExpandedChanged,
  ]);
  return columns;
};

const getCellMarginStyle = (rowType: RowType, viewBy: ViewBy) => {
  let margin: string | undefined;
  switch (rowType) {
    case 'header-employee':
      margin = viewBy === 'project' ? '1.7rem' : '';
      break;
    case 'header-week':
      margin = viewBy === 'project' ? '2.3rem' : '';
      break;
    case 'row-employee':
    case 'row-period':
      margin = '2.6rem';
      break;
    case 'subrow-day':
    case 'subrow-day-loading':
    case 'subrow-day-loading-error':
      margin = viewBy === 'project' ? '4.2rem' : '3.8rem';
      break;
    case 'subrow-project':
      margin = '5.8rem';
      break;
    default:
      margin = '';
  }
  return !!margin ? { marginLeft: margin } : {};
};

const SecondCell: React.FC<{
  row: TableRow;
  isExpanded: boolean;
  viewBy: ViewBy;
  onExpandedChanged: (rowId: string, expanded: boolean) => void;
}> = ({ row, isExpanded, viewBy, onExpandedChanged }) => {
  const t = useNamespacedTranslation('timeTracking.projects.controlling.table');

  const renderCell = () => {
    const { rowType } = row;
    switch (rowType) {
      case 'header-project':
        return (
          <ProjectPeriodCell
            projectInfo={row.headerProject}></ProjectPeriodCell>
        );
      case 'header-week':
        return <TimeSheetPeriodCell period={row.headerWeek} />;
      case 'header-employee':
        return (
          <div className="d-flex align-items-center gap-2">
            <EmployeeCell employee={row.headerEmployee} />
            <EmployeeDetailsPopover
              rowId={row.id}
              employeeId={row.headerEmployee.id}
              employeeName={row.headerEmployee.name}
            />
          </div>
        );
      case 'row-employee':
        return (
          <TimeSheetRowCell
            isExpanded={isExpanded}
            onExpandedChanged={(newExpanded) =>
              onExpandedChanged(row.id, newExpanded)
            }>
            <div className="d-flex align-items-center gap-2">
              <Link to={routes.timeSheet.route} timeSheetId={row.timeSheetId}>
                <EmployeeCell employee={row.employee} />
              </Link>
              <EmployeeDetailsPopover
                rowId={row.id}
                employeeId={row.employee.id}
                employeeName={row.employee.name}
              />
            </div>
          </TimeSheetRowCell>
        );
      case 'row-period':
        return (
          <TimeSheetRowCell
            isExpanded={isExpanded}
            onExpandedChanged={(newExpanded) =>
              onExpandedChanged(row.id, newExpanded)
            }>
            <Link to={routes.timeSheet.route} timeSheetId={row.timeSheetId}>
              <TimeSheetPeriodCell period={row.period} />
            </Link>
          </TimeSheetRowCell>
        );
      case 'subrow-day-loading':
        return (
          <div className="d-flex align-items-center ms-4">
            <span className="me-2">{t('loadingDays')}</span>
            <Spinner animation="border" size="sm" />
          </div>
        );
      case 'subrow-day-loading-error':
        return <span className="ms-4 text-danger">{row.error}</span>;
      case 'subrow-day':
        const subRowDayCellContent = (
          <div className="d-flex align-items-center gap-2">
            <DayRowCell day={row.day} />
          </div>
        );
        if (viewBy === 'project') {
          return subRowDayCellContent;
        } else {
          return (
            <TimeSheetRowCell
              isExpanded={isExpanded}
              onExpandedChanged={(newExpanded) =>
                onExpandedChanged(row.id, newExpanded)
              }>
              {subRowDayCellContent}
            </TimeSheetRowCell>
          );
        }
      case 'subrow-project':
        return (
          <ProjectPeriodCell
            projectInfo={{
              name: row.projectInfo.name,
              billable: row.projectInfo.billable,
              startDay: null,
              endDay: null,
            }}></ProjectPeriodCell>
        );
      default:
        return null;
    }
  };
  return <div className="ms-3 ">{renderCell()}</div>;
};

const CheckboxColumn = styled.div({
  width: '0.75rem',
});

const SecondColumnContainer = styled.div({
  marginLeft: '0.75rem',
});

const NoteColumnContainer = styled.div({
  width: '12rem',
});
