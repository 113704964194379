import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export const CommentCardLoading: React.FC = () => (
  <div className="header mt-md-3">
    <div className="header-body">
      <Row className="row align-items-center">
        <Col>
          <h6 className="header-pretitle">
            <Skeleton width={50} />
          </h6>
          <h1 className="header-title">
            <Skeleton width={150} />
          </h1>
        </Col>
      </Row>
    </div>
  </div>
);
