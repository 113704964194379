import React, { useCallback } from 'react';
import { TicketsFilter } from './types';
import { OptionBase } from '../../../../../../components/Select/types';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import {
  createMonthOptions,
  createTicketStatusOptions,
  createYearOptions,
} from './mappings';
import { getMonthsOptions } from '../../../../../../utils/dateUtils';
import styled from '@emotion/styled';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import { debounce } from 'lodash';
import { emptyTicketsFilter } from '../../common';
import GlobalTableFilter from '../../../../components/GlobalTableFilter';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';

type Props = {
  filter: TicketsFilter;
  onChange: (filter: TicketsFilter) => void;
  categoryOptions: OptionBase[];
  assigneeOptions: OptionBase[];
};

const i18Prefix = 'support.tickets.table.filter';
export const TicketsFilters: React.FC<Props> = ({
  filter,
  onChange,
  categoryOptions,
  assigneeOptions,
}) => {
  const t = useNamespacedTranslation(i18Prefix);
  const yearOptions = createYearOptions(new Date());
  const monthOptions = createMonthOptions(getMonthsOptions());
  const SingleFilterStyled = styled(SingleFilter)({
    maxWidth: '12.5rem',
  });
  const handleCategoryChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'category'),
    [filter, onChange],
  );
  const handleAssigneeChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'assignee'),
    [filter, onChange],
  );
  const handleMonthChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'month'),
    [filter, onChange],
  );

  const handleYearChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'year'),
    [filter, onChange],
  );

  const handleSearch = useCallback(
    (value) => {
      debounce(handleFilterChange, 300)(value, filter, onChange, 'searchTerm');
    },
    [filter, onChange],
  );
  const handleStatusChanged = useCallback(
    (value) => handleFilterChange(value, filter, onChange, 'status'),
    [filter, onChange],
  );
  const handleClearFilter = useCallback(() => {
    onChange(emptyTicketsFilter);
  }, [onChange]);

  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <div className="d-flex flex-wrap align-items-center">
        <GlobalTableFilter
          value={filter.searchTerm || ''}
          placeholder={t('search')}
          onFilterChange={handleSearch}
        />
      </div>
      <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
        <span className="d-none me-3 d-md-block">{t('filterBy')}</span>
        <SingleFilterStyled
          placeholder={t('category')}
          isSearchable={true}
          options={categoryOptions}
          value={filter.category}
          onChange={handleCategoryChanged}
        />
        <SingleFilterStyled
          placeholder={t('month')}
          isSearchable={true}
          options={monthOptions}
          value={filter.month}
          onChange={handleMonthChanged}
        />
        <SingleFilterStyled
          placeholder={t('year')}
          isSearchable={true}
          options={yearOptions}
          value={filter.year}
          onChange={handleYearChanged}
        />
        <SingleFilterStyled
          placeholder={t('assignee')}
          isSearchable={true}
          options={assigneeOptions}
          value={filter.assignee}
          onChange={handleAssigneeChanged}
        />
        <SingleFilterStyled
          placeholder={t('status')}
          isSearchable={true}
          options={createTicketStatusOptions()}
          value={filter.status}
          onChange={handleStatusChanged}
        />
        <ClearFiltersLink className="ms-3" onClick={handleClearFilter} />
      </div>
    </div>
  );
};

const handleFilterChange = (
  value: string | null,
  filter: TicketsFilter,
  onChange: (filter: TicketsFilter) => void,
  filterKey: keyof TicketsFilter,
) => {
  onChange({
    ...filter,
    [filterKey]: value,
  });
};
