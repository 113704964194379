import { StringBoolean } from '../../../../../hooks/usePersistentFilter';
import { TimeSheetStatus } from '../../../__generated__/graphql';
import { ViewBy } from '../../../components/TimeSheetsTable';

export type TimeSheetsFilter = {
  employeeIds: string[];
  warnings: FilterWarningsType | null;
  viewBy: ViewBy | null;
  year: string | null;
  month: string | null;
  status: TimeSheetStatus | null;
  onlyWithComments: StringBoolean | null;
};

export const filterWarningsTypeValues = [
  'withWarnings',
  'withoutWarnings',
] as const;
export type FilterWarningsType = (typeof filterWarningsTypeValues)[number];

export const isWarningsType = (
  src: string | null,
): src is FilterWarningsType => {
  return filterWarningsTypeValues.some((x) => x === src);
};

export const defaultFilter: TimeSheetsFilter = {
  employeeIds: [],
  warnings: null,
  viewBy: null,
  year: null,
  month: null,
  status: null,
  onlyWithComments: null,
};
