import { TimeBalanceDetails_TimeBalanceSettingsFragment } from '../../__generated__/graphql';
import { TimeBalanceSettings } from './types';

export const mapTimeBalanceSettings = (
  src: TimeBalanceDetails_TimeBalanceSettingsFragment,
): TimeBalanceSettings => ({
  calculationMode: src.flair__Calculation_Mode__c,
  calculationAfter: src.flair__Start_Calculate_After_Minutes__c,
  autoResetMode: src.flair__Auto_Reset_Mode__c,
  updateMode: src.flair__Update_Mode__c,
  positiveCap: src.flair__Positive_Capacity_Minutes__c,
  negativeCap: src.flair__Negative_Capacity_Minutes__c,
  maxDailyWorking: src.flair__Maximum_Daily_Working_Minutes__c,
  dailyThreshold: src.flair__Daily_Threshold_Minutes__c,
  threshold: src.flair__Threshold_Minutes__c,
  notDeductDeficit: src.flair__Not_Deduct_Deficit__c,
  premiumLevel1:
    src.flair__Premium_Level_1_Minutes__c &&
    src.flair__Premium_Level_1_Multiplier__c
      ? {
          afterMinutes: src.flair__Premium_Level_1_Minutes__c,
          multiplier: src.flair__Premium_Level_1_Multiplier__c,
        }
      : null,
  premiumLevel2:
    src.flair__Premium_Level_2_Minutes__c &&
    src.flair__Premium_Level_2_Multiplier__c
      ? {
          afterMinutes: src.flair__Premium_Level_2_Minutes__c,
          multiplier: src.flair__Premium_Level_2_Multiplier__c,
        }
      : null,
});
