import { TimeTrackingState } from '..';
import { ReportingError } from '../../../../../../components/ErrorHandling';
import { isOnBreak } from './breakRemindersLogic';

export type BreakRemindersState = {
  timeTrackingState: TimeTrackingState | null;
  closedReminderIds: string[];
  suggestedReminderToShowId: string | null;
};

type BreakReminderAction =
  | {
      type: 'setTimeTrackingState';
      timeTrackingState: TimeTrackingState;
    }
  | { type: 'closeReminder'; reminderId: string }
  | { type: 'setSuggestedReminderId'; reminderId: string | null };

export const breakReminderActions = {
  setTimeTrackingState: (
    timeTrackingState: TimeTrackingState,
  ): BreakReminderAction => ({
    type: 'setTimeTrackingState',
    timeTrackingState,
  }),
  closeReminder: (reminderId: string): BreakReminderAction => ({
    type: 'closeReminder',
    reminderId,
  }),
  setSuggestedReminderId: (reminderId: string | null): BreakReminderAction => ({
    type: 'setSuggestedReminderId',
    reminderId,
  }),
};

export const breakRemindersStateReducer = (
  state: BreakRemindersState,
  action: BreakReminderAction,
): BreakRemindersState => {
  try {
    switch (action.type) {
      case 'setSuggestedReminderId': {
        if (action.reminderId === state.suggestedReminderToShowId) {
          return state;
        }
        return fixClosedReminderIds({
          ...state,
          suggestedReminderToShowId: action.reminderId,
        });
      }
      case 'setTimeTrackingState': {
        if (state.timeTrackingState === action.timeTrackingState) {
          return state;
        }
        return fixClosedReminderIds({
          ...state,
          timeTrackingState: action.timeTrackingState,
        });
      }
      case 'closeReminder': {
        if (
          state.closedReminderIds.some(
            (reminderId) => reminderId === action.reminderId,
          )
        ) {
          return state;
        }
        return {
          ...state,
          closedReminderIds: [...state.closedReminderIds, action.reminderId],
        };
      }
    }
  } catch (e) {
    throw new ReportingError({ state, action }, e);
  }
};

export const calculateReminderToShow = ({
  suggestedReminderToShowId,
  closedReminderIds,
  timeTrackingState,
}: BreakRemindersState): string | null => {
  if (!timeTrackingState) {
    return null;
  }
  if (!suggestedReminderToShowId) {
    return null;
  }
  if (closedReminderIds.some((x) => x === suggestedReminderToShowId)) {
    return null;
  }
  if (isOnBreak(timeTrackingState)) {
    return null;
  }
  return suggestedReminderToShowId;
};

// if we are in a break let's mark current suggestedReminderToShowId as hidden
const fixClosedReminderIds = (
  state: BreakRemindersState,
): BreakRemindersState => {
  if (
    state.timeTrackingState &&
    state.suggestedReminderToShowId &&
    isOnBreak(state.timeTrackingState)
  ) {
    return {
      ...state,
      closedReminderIds: addItemIfRequired(
        state.closedReminderIds,
        state.suggestedReminderToShowId,
      ),
    };
  } else {
    return state;
  }
};

const addItemIfRequired = (array: string[], item: string): string[] => {
  if (array.some((x) => x === item)) {
    return array;
  }
  return [...array, item];
};
