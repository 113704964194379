import * as React from 'react';
import ProfileImg from '../../../../../vendor/img/profile-cover-2.svg';
import { useUserInfo } from '../../../context/UserInfo';
import './HeroImage.css';

const HeroImage: React.FC = () => {
  const { coverImageUrl } = useUserInfo();

  return (
    <div>
      <img
        className="hero-img"
        src={coverImageUrl ? coverImageUrl : ProfileImg}
        alt="Profile"
      />
    </div>
  );
};

export default HeroImage;
