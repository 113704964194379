import React from 'react';
import Hint from '../../../../../components/hint';
import { Theme } from '../../../../../theme';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  text: string;
  className?: string;
};

export const GoalHint: React.FC<Props> = ({ text, className }) => {
  return (
    <Hint id="goal-hint" mode="popover" text={text}>
      <FlairIcon
        className={className}
        size="sm"
        icon="information-circle-outline"
        color={Theme.color.gray2}
        role="button"
      />
    </Hint>
  );
};
