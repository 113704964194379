import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputError from '../../../../../../components/form/InputError';
import { getError } from '../../../../../../components/form/utils';
import {
  SelectSingleGoal,
  Props as SelectSingleGoalProps,
} from './SelectSingleGoal';

type Props = Omit<SelectSingleGoalProps, 'value' | 'onChange'> & {
  name: string;
};

export const SelectSingleGoalControlled: React.FC<Props> = ({
  name,
  ...props
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  return (
    <>
      <Controller
        as={({ value, onChange }) => {
          return (
            <SelectSingleGoal
              {...props}
              value={value}
              onChange={onChange}
              className={error ? 'is-invalid' : ''}
            />
          );
        }}
        name={name}
        control={control}
      />
      <InputError error={error} />
    </>
  );
};
