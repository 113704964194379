import React from 'react';
import StringToHtml from '../../../../../../../components/StringToHtml';

type Props = {
  label: string;
  value: string;
};
export const InventoryItemNoteRow: React.FC<Props> = ({ label, value }) => {
  return (
    <div className="d-flex flex-column gap-2">
      <h4 className="card-header-title">{label}</h4>
      <h4 className="card-header-title fw-normal">
        <div>
          <StringToHtml text={value} />
        </div>
      </h4>
    </div>
  );
};
