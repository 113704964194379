export const hoursToMilliseconds = (hours: number) => hours * 60 * 60 * 1_000;
export const minutesToHours = (minutes: number) => minutes / 60.0;
export const hoursToMinutes = (hours: number) => hours * 60.0;

export const DEFAULT_DEBOUNCE_TIME = 1500;

export const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const isValidTimeStr = (timeStr: string) => TIME_REGEX.test(timeStr);

export const minutesToHoursAndMinutes = (totalMinutes: number) =>
  totalMinutes ? [Math.floor(totalMinutes / 60), totalMinutes % 60] : [0, 0];
