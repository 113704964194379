import { AbsenceItemType } from './types';
import { Status } from '../../../../../atomic/atoms/StatusIcon/StatusIcon';
import { Dashboard_UpcomingAbsencesFragment } from '../../../__generated__/graphql';

export const mapUpcomingAbsence = (
  absence: Dashboard_UpcomingAbsencesFragment,
): AbsenceItemType => ({
  id: absence.Id,
  status: absence.flair__Approval_Status__c as Status,
  startDateTime: `${absence.flair__Start_Date__c} ${absence.flair__Start_Time__c}`,
  endDateTime: `${absence.flair__End_Date__c} ${absence.flair__End_Time__c}`,
  categoryIcon: absence.flair__Category_Icon__c,
  categoryName: absence.flair__Category_Name__c,
  type: absence.flair__Type__c,
  workingAmount: absence.flair__Working_Amount__c,
  commentsCount: absence.commentsCount,
});
