import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import {
  SelectComponentsConfig,
  components as selectComponents,
  GroupBase,
  SingleValue,
  StylesConfig,
} from 'react-select';
import {
  SelectSingleStyled,
  SelectSingleStyledProps,
} from '../../../../../components/Select/SelectSingleStyled';
import { OptionBase } from '../../../../../components/Select/types';
import { SkillLevelLabel } from './SkillLevelLabel';
import { getSkillLevelLabel } from './skillLevels';
import { allSkillLevels, isSkillLevel, SkillLevel } from './types';

export type Props = Omit<
  SelectSingleStyledProps<OptionBase>,
  'options' | 'value' | 'onChange'
> & {
  value: SkillLevel;
  onChange: (value: SkillLevel) => void;
};

export const SkillLevelSelect: React.FC<Props> = ({
  value,
  onChange,
  ...rest
}) => {
  const handleOnChange = useCallback(
    (value: SingleValue<OptionBase>) => {
      const optionValue = fromOptionValue(value?.value ?? null);
      if (optionValue !== undefined) {
        onChange(optionValue);
      }
    },
    [onChange],
  );

  const options = useMemo(() => getSkillLevelOptions(), []);

  return (
    <Container>
      <SelectSingleStyled
        {...rest}
        isSearchable={false}
        isClearable={false}
        options={options}
        value={options.find((o) => o.value === toOptionValue(value)) ?? null}
        onChange={handleOnChange}
        components={defaultComponents}
        styles={styles}
        menuPortalTarget={document.querySelector('body')}
        menuPosition="fixed"
      />
    </Container>
  );
};

const getSkillLevelOptions = (): OptionBase[] =>
  allSkillLevels.map((level) => ({
    value: toOptionValue(level),
    label: getSkillLevelLabel(level),
  }));

const toOptionValue = (level: SkillLevel): string =>
  level ? level.toString() : '';

const fromOptionValue = (value: string | null): SkillLevel => {
  if (value === null || value === '') {
    return null;
  }
  const valueNumber = parseInt(value);
  return isSkillLevel(valueNumber) ? valueNumber : null;
};

const Container = styled.div({
  maxWidth: '7.25rem',
});

const defaultComponents: SelectComponentsConfig<
  OptionBase,
  false,
  GroupBase<OptionBase>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <SkillLevelLabel value={fromOptionValue(data.value)} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <SkillLevelLabel value={fromOptionValue(data.value)} />
    </selectComponents.Option>
  ),
};

const styles: StylesConfig<OptionBase, false> = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    minWidth: 'auto',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
  }),
  menu: (provided) => ({
    ...provided,
    whiteSpace: 'pre-wrap',
    boxShadow: 'none',
    width: 'auto',
  }),
};
