import { isSameDay } from 'date-fns';
import { parseDate } from '../../../utils/dateUtils';
import {
  EmployeeWorkload,
  TimeFramework,
  TimeSheetStatus,
  TimesheetTrackedTimeFieldsFragment,
  WorkloadModel,
} from '../__generated__/graphql';
import { hoursToMinutes } from '../../../utils/time';

export type TimeSheetOvertimeInfo = {
  flair__Approval_Status__c: TimeSheetStatus;
  trackedTime: Pick<TimesheetTrackedTimeFieldsFragment, 'totalWorkedMinutes'>;
  flair__Start_Date__c: string;
  flair__Target_Hours__c: number;
  flair__Given_Compensatory_Time_Level_1__c: number | null;
  flair__Given_Compensatory_Time_Level_2__c: number | null;
  workloads: ReadonlyArray<WorkloadOvertimeInfo>;
};

type WorkloadOvertimeInfo = {
  Id: string;
  flair__Start_Date__c: string;
  flair__End_Date__c: string | null;
  flair__Workload_Model__c: WorkloadModel;
  timeFramework: Pick<TimeFramework, 'flair__Overtime_Policy__c'> | null;
};

export const getTimesheetOvertimeMinutes = (
  timeSheet: TimeSheetOvertimeInfo,
) => {
  const workload = getTimeSheetWorkload(timeSheet);
  if (!workload) {
    return 0;
  }
  if (workload.flair__Workload_Model__c === WorkloadModel.HourlyBased) {
    return 0;
  }
  const tracked = timeSheet.trackedTime;
  if (
    timeSheet.flair__Approval_Status__c === TimeSheetStatus.Approved &&
    !!workload.timeFramework?.flair__Overtime_Policy__c
  ) {
    return hoursToMinutes(
      (timeSheet.flair__Given_Compensatory_Time_Level_1__c ?? 0) +
        (timeSheet.flair__Given_Compensatory_Time_Level_2__c ?? 0),
    );
  }

  const workedMinutes = tracked.totalWorkedMinutes;
  const targetMinutes = hoursToMinutes(timeSheet.flair__Target_Hours__c);

  return workedMinutes - targetMinutes;
};

export const isHourlyBasedWeek = (timeSheet: {
  flair__Start_Date__c: string;
  workloads: ReadonlyArray<
    Pick<
      EmployeeWorkload,
      'flair__Start_Date__c' | 'flair__End_Date__c' | 'flair__Workload_Model__c'
    >
  >;
}) => {
  const workload = getTimeSheetWorkload(timeSheet);
  return workload?.flair__Workload_Model__c === WorkloadModel.HourlyBased;
};

type WorkloadWithStartEndDate = Pick<
  EmployeeWorkload,
  'flair__Start_Date__c' | 'flair__End_Date__c'
>;

export const getTimeSheetWorkload = <
  TWorkload extends WorkloadWithStartEndDate,
>({
  flair__Start_Date__c,
  workloads,
}: {
  flair__Start_Date__c: string;
  workloads: ReadonlyArray<TWorkload>;
}): TWorkload | null => {
  if (!workloads.length) {
    return null;
  }
  if (workloads.length === 1) {
    return workloads[0];
  }
  const workload = getWorkloadForDate<TWorkload>(
    workloads,
    parseDate(flair__Start_Date__c),
  );
  if (workload) {
    return workload;
  }
  return workloads[0];
};

export const getTargetMinutes = (timeSheet: {
  flair__Start_Date__c: string;
  flair__Target_Hours__c: number;
  workloads: ReadonlyArray<
    Pick<
      EmployeeWorkload,
      'flair__Workload_Model__c' | 'flair__Start_Date__c' | 'flair__End_Date__c'
    >
  >;
}): number | null => {
  if (isHourlyBasedWeek(timeSheet)) {
    return null;
  }
  return hoursToMinutes(timeSheet.flair__Target_Hours__c);
};

export const getWorkloadForDate = <T extends WorkloadWithStartEndDate>(
  timeSheetWorkloads: ReadonlyArray<T>,
  currDate: Date,
): T | null => {
  return (
    timeSheetWorkloads.find((workload) =>
      isWorkloadMatch(workload, currDate),
    ) ?? null
  );
};

export const isWorkloadMatch = (
  {
    flair__Start_Date__c,
    flair__End_Date__c,
  }: { flair__Start_Date__c: string; flair__End_Date__c: string | null },
  currDate: Date,
): boolean => {
  const workflowStart: Date = parseDate(flair__Start_Date__c);
  const workflowEnd: Date = flair__End_Date__c
    ? parseDate(flair__End_Date__c)
    : currDate;
  const startDateIsSameOrBefore =
    isSameDay(workflowStart, currDate) || workflowStart <= currDate;
  const endDateIsSameOrAfter =
    isSameDay(workflowEnd, currDate) || workflowEnd >= currDate;
  return startDateIsSameOrBefore && endDateIsSameOrAfter;
};
