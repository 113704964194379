import * as React from 'react';

const RightArrowIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="18"
      height="18"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.43934 2.46026C6.85355 3.07394 6.85355 4.06891 7.43934 4.6826L15.3787 13L7.43934 21.3174C6.85355 21.9311 6.85355 22.9261 7.43934 23.5397C8.02512 24.1534 8.97487 24.1534 9.56066 23.5397L18.5607 14.1112C19.1464 13.4975 19.1464 12.5025 18.5607 11.8888L9.56066 2.46026C8.97487 1.84658 8.02512 1.84658 7.43934 2.46026Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default RightArrowIcon;
