import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SkeletonCard from '../../../components/Skeleton/Card';

export const Loading: React.FC = () => {
  return (
    <Row>
      <Col lg={5} md={6}>
        <SkeletonCard height={260} />
      </Col>
      <Col lg={7} md={6}>
        <Row>
          <Col>
            <SkeletonCard height={260} />
          </Col>
          <Col>
            <SkeletonCard height={260} />
          </Col>
          <Col>
            <SkeletonCard height={260} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SkeletonCard height={260} />
          </Col>
          <Col>
            <SkeletonCard height={260} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
