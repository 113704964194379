import moment from 'moment';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import {
  MyLocation,
  MyTimeEntry,
  MyTimeEntryBreak,
} from '../__generated__/graphql';

type LocationProps = Pick<MyLocation, 'Name'>;
type BreakProps = Pick<
  MyTimeEntryBreak,
  | 'Id'
  | 'flair__Start_Datetime__c'
  | 'flair__End_Datetime__c'
  | 'flair__Total_Break_Period_in_Minutes__c'
>;
type TimeEntryProps = Pick<MyTimeEntry, 'Id' | 'flair__Start_Datetime__c'> & {
  breaks: ReadonlyArray<BreakProps>;
  location: LocationProps | null;
};

type Step = {
  timeEntryId: string;
};

export type ClockIn = Step & {
  kind: 'ClockIn';
  location?: string;
  time: Date;
};

export type BreakStarted = Step & {
  kind: 'BreakStarted';
  time: Date;
  breakId: string;
};

export type BreakEnded = Step & {
  kind: 'BreakEnded';
  duration: number;
  time: Date;
  breakId: string;
};

export type Working = Step & {
  kind: 'Working';
  startTime: Date;
  totalBreakTime: number;
};

export type DuringBreak = Step & {
  kind: 'DuringBreak';
  startTime: Date;
  breakId: string;
};

export type TimelineStep =
  | ClockIn
  | BreakStarted
  | BreakEnded
  | Working
  | DuringBreak;

const buildTimelineSteps = (entry: TimeEntryProps): TimelineStep[] => {
  const ClockIn: ClockIn = {
    timeEntryId: entry.Id,
    kind: 'ClockIn',
    time: moment(entry.flair__Start_Datetime__c).toDate(),
    location: entry.location?.Name,
  };

  const breaks = orderBy(
    entry.breaks.map((b) => ({
      ...b,
      time: moment(b.flair__Start_Datetime__c).toDate(),
    })),
    ['time'],
  )
    .flatMap((b) => {
      const steps: TimelineStep[] = [
        {
          timeEntryId: b.Id,
          kind: 'BreakStarted',
          time: moment(b.flair__Start_Datetime__c).toDate(),
          breakId: b.Id,
        },
      ];

      if (b.flair__End_Datetime__c) {
        steps.push({
          timeEntryId: entry.Id,
          kind: 'BreakEnded',
          time: moment(b.flair__End_Datetime__c).toDate(),
          duration: b.flair__Total_Break_Period_in_Minutes__c,
          breakId: b.Id,
        });
      }

      return steps;
    })
    .reverse();

  const currentBreak = entry.breaks.find((b) => !b.flair__End_Datetime__c);
  const currentStep: TimelineStep = currentBreak
    ? {
        kind: 'DuringBreak',
        startTime: moment(currentBreak.flair__Start_Datetime__c).toDate(),
        timeEntryId: entry.Id,
        breakId: currentBreak.Id,
      }
    : {
        kind: 'Working',
        startTime: moment(entry.flair__Start_Datetime__c).toDate(),
        totalBreakTime: entry.breaks.reduce(
          (r, b) => r + b.flair__Total_Break_Period_in_Minutes__c,
          0,
        ),
        timeEntryId: entry.Id,
      };

  return [currentStep, ...breaks, ClockIn];
};

export const useCurrentTimeTrackingTimelineSteps = (entry: TimeEntryProps) =>
  useMemo(() => buildTimelineSteps(entry), [entry]);

const isCurrentStep = (step: TimelineStep): step is Working | DuringBreak => {
  return step.kind === 'Working' || step.kind === 'DuringBreak';
};

export const useCurrentTimeTrackingTimelineLastStep = (
  entry: TimeEntryProps,
): Working | DuringBreak => {
  const steps = useCurrentTimeTrackingTimelineSteps(entry);
  const currentStep = steps[0];

  if (!isCurrentStep(currentStep)) {
    throw new Error('invalid state');
  }

  return currentStep;
};
