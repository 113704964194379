import { OrgChartQuery } from '../../../__generated__/graphql';
import { OrgChartEmployee } from '../../OrgChart/types';

export const mapEmployees = (
  employees: OrgChartQuery['orgChartEmployees'] | undefined,
): OrgChartEmployee[] => {
  if (!employees) return [];

  return employees.map((e) => ({
    id: e.Id,
    name: e.Name,
    position: e.flair__Position__c ?? '',
    department: e.department?.Name ?? '',
    location: e.location?.Name ?? '-',
    companyEmail: e.flair__Company_Email__c ?? '-',
    phone: e.flair__Company_Phone_Number__c ?? '-',
    manager: e.manager
      ? {
          id: e.manager.Id,
          name: e.manager.Name,
        }
      : null,
    noOfSubEmployees: employees.filter((emp) => emp.flair__Manager__c === e.Id)
      .length,
    isCollapsed: false,
    avatarUrl: e.avatar?.url ?? '',
  }));
};
