import * as React from 'react';
import { useUserInfo } from '../../../context/UserInfo';
import AvatarWithActionsMenu from '../../../../../atomic/molecules/AvatarWithActionsMenu';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useLogout } from '../../../../../hooks/useLogout';
import { useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { routes } from '../../../routes';
import { EmployeeAvatarInputModalConnected } from '../../EmployeeAvatarInput/EmployeeAvatarInputModalConnected';
import './TopNavAvatarWithActions.css';
import { LEARNING_HUB_URL } from '../../../../../constants/hubUrls';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import { useIsScreenSizeLargerThanOrEqual } from '../../../../../hooks/useIsScreenSizeLargerThan';

const i18Path = 'dashboard.dropDownActions';

const TopNavAvatarWithActions: React.FC = () => {
  const employee = useUserInfo();
  const t = useNamespacedTranslation(i18Path);
  const logout = useLogout();
  const history = useHistory();
  const isLargeScreen = useIsScreenSizeLargerThanOrEqual('lg');

  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);

  const handleRedirectToLearningHub = () => {
    window.open(LEARNING_HUB_URL);
  };

  const onSettingClick = useCallback(
    () => history.push(routes.general.route.create({})),
    [history],
  );

  const actions = [
    <DropdownActionItem
      key="changeAvatarAction"
      onClick={() => setUploadModalVisible(true)}>
      {t('changeAvatar')}
    </DropdownActionItem>,
    <DropdownActionItem key="settingsAction" onClick={onSettingClick}>
      {t('settings')}
    </DropdownActionItem>,
    <DropdownActionItem
      key="learningHubAction"
      onClick={handleRedirectToLearningHub}>
      {t('learningHub')}
    </DropdownActionItem>,
    <DropdownActionItem key="logoutAction" onClick={logout}>
      {t('logout')}
    </DropdownActionItem>,
  ];

  return (
    <>
      <AvatarWithActionsMenu
        employee={employee}
        actions={actions}
        showAvatarOnly={!isLargeScreen}
        avatarClassName="top-nav-avatar"
      />
      <EmployeeAvatarInputModalConnected
        show={uploadModalVisible}
        onClose={() => setUploadModalVisible(false)}
      />
    </>
  );
};

export default TopNavAvatarWithActions;
