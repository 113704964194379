import { AccrualPolicy, AccrualPolicyInterval } from '../__generated__/graphql';
import { useCallback } from 'react';

type AccrualPolicyProps = Pick<AccrualPolicy, 'flair__Interval__c'>;
type EmployeeAccrualPolicyProps = { accrualPolicy: AccrualPolicyProps };

type Result = AccrualPolicyInterval.Monthly | AccrualPolicyInterval.Yearly;

export const useEmployeeAccrualPolicyIntervalReduce = () =>
  useCallback((eaps: ReadonlyArray<EmployeeAccrualPolicyProps>): Result => {
    if (!eaps.length) {
      return AccrualPolicyInterval.Yearly;
    }

    const monthlyAcrrualPolicies = eaps.filter(
      (eap) =>
        eap.accrualPolicy.flair__Interval__c === AccrualPolicyInterval.Monthly,
    );

    return monthlyAcrrualPolicies.length === eaps.length
      ? AccrualPolicyInterval.Monthly
      : AccrualPolicyInterval.Yearly;
  }, []);
