import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '../../../../../components/button';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  visible: boolean;
  mode: 'suggestions' | 'deduction';
  initialNotes: string | null;
  onClose: () => void;
  onSubmit: (notes: string | null) => Promise<void>;
  okDisabled: boolean;
  okButtonLabel: string;
  children: React.ReactNode;
};

const i18nprefix = 'autoBreaks.unusedBreakTimeModal.';

export const UnusedBreakTimeModalDumb: React.FC<Props> = ({
  mode,
  initialNotes,
  okButtonLabel,
  visible,
  onClose,
  onSubmit,
  okDisabled,
  children,
}) => {
  const { t } = useTranslation();

  const [notes, setNotes] = useState(initialNotes ?? '');
  const [submiting, setSubmiting] = useState(false);

  const handleOkClick = useCallback(() => {
    setSubmiting(true);
    onSubmit(notes).finally(() => setSubmiting(false));
  }, [onSubmit, notes, setSubmiting]);

  return (
    <Modal show={visible} onHide={onClose} centered contentClassName="border">
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t(i18nprefix + 'title')}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className={classNames('d-flex', 'flex-column')}>
          {mode === 'deduction' && (
            <Alert
              variant="light"
              className={classNames('d-flex', 'align-items-center', 'gap-3')}>
              <FlairIcon icon="information-circle-outline" />
              <div>{t(i18nprefix + 'deductionContent')}</div>
            </Alert>
          )}

          <Container>{children}</Container>
          <TextareaAutosize
            value={notes}
            className={classNames('mt-3', 'form-control')}
            onChange={(e) => setNotes(e.target.value)}
            placeholder={t(
              'timeTracking.current.stopTrackingModal.notesPlaceholder',
            )}
            minRows={4}
          />
          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="outline-secondary"
              label={t(i18nprefix + 'cancel')}
              onClick={onClose}
              className="me-3"
              disabled={submiting}
            />
            <Button
              label={okButtonLabel}
              onClick={handleOkClick}
              disabled={okDisabled || submiting}
              isProcessing={submiting}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Container = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    {...rest}
    className={classNames('border', 'rounded', 'p-4', className)}
  />
);
