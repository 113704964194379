import { useCallback, useState } from 'react';
import { SortingRule } from 'react-table';

export type TableWithPaginationState<TItem, TFilter> = {
  totalItemsCount: number | undefined;
  pageIndex: number;
  sortBy: SortingRule<TItem>[];
  filter: TFilter;
};

export const useTableWithPagination = <TItem, TFilter>(
  initialFilter: TFilter,
) => {
  const [tableState, setTableState] = useState<
    TableWithPaginationState<TItem, TFilter>
  >({
    totalItemsCount: undefined,
    pageIndex: 0,
    sortBy: [],
    filter: initialFilter,
  });

  const handleFilterChange = useCallback(
    function (newFilter: TFilter) {
      setTableState((prev) => ({
        ...prev,
        totalItemsCount: undefined,
        pageIndex: 0,
        filter: newFilter,
      }));
    },
    [setTableState],
  );
  const handlePageIndexChanged = useCallback(
    (value) => {
      setTableState((prev) => ({ ...prev, pageIndex: value }));
    },
    [setTableState],
  );

  const handleSortByChanged = useCallback(
    (value) => {
      setTableState((prev) => ({ ...prev, sortBy: value }));
    },
    [setTableState],
  );

  const setTotalItemsCount = useCallback(
    (value) => {
      if (value !== undefined) {
        setTableState((prev) => ({ ...prev, totalItemsCount: value }));
      }
    },
    [setTableState],
  );

  return {
    tableState,
    handleFilterChange,
    handlePageIndexChanged,
    handleSortByChanged,
    setTotalItemsCount,
  };
};
