import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as yup from 'yup';
import {
  FeedbackData,
  sendFeedback,
} from '../../../../api/productlane/feedbackApi';
import { Form } from '../../../../components/form/Form';
import { Input } from '../../../../components/form/Input';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useToasts } from '../../../../context/Toast';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useUserInfo } from '../../context/UserInfo';

type FeedbackModalProps = {
  onClose: () => void;
  show: boolean;
};

const validationSchema = yup.object().shape<FeedbackData>({
  text: yup.string().required(),
});

const FeedbackModal: React.FC<FeedbackModalProps> = (
  props: FeedbackModalProps,
) => {
  const { onClose, show } = props;
  const userInfo = useUserInfo();
  const t = useNamespacedTranslation('feedbackModal');
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { addSuccess, addError } = useToasts();

  const onSubmit = async (data: FeedbackData) => {
    try {
      setSubmitInProgress(true);
      const response = await sendFeedback({ ...data, email: userInfo?.email });
      if ('id' in response) {
        addSuccess(t('notifications.success'));
        onClose();
      } else {
        addError(response.message);
      }
    } catch (err) {
      addError(t('notifications.error'));
    } finally {
      setSubmitInProgress(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <Modal.Header closeButton>
          <h2 className="card-header-title">{t('title')}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center flex-column">
            <Input
              name="text"
              type="textarea"
              label={t('textLabel')}
              minRows={6}
              maxRows={10}
              size="lg"
              required
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <Button variant="outline-primary" onClick={onClose} size="lg">
              {t('cancelButton')}
            </Button>
            <SubmitButton size="lg" disabled={submitInProgress}>
              {t('submitButton')}
            </SubmitButton>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
