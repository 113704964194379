import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkeletonCard from '../../../components/Skeleton/Card';
import SkeletonPageHeader from '../../../components/Skeleton/PageHeader';

const Loading: React.FC = () => {
  return (
    <>
      <SkeletonPageHeader showHeaderPreTitle={false} />
      <Row>
        <Col xl={8}>
          <SkeletonCard height={143} />
          <SkeletonCard height={143} />
        </Col>
      </Row>
    </>
  );
};

export default Loading;
