import React from 'react';
import { InfoHint } from '../../../../../components/hint/InfoHint';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { formatDateShort, parseDate } from '../../../../../utils/dateUtils';
import { WorkflowEmployee } from '../types';
import { WorkflowType } from '../../../__generated__/graphql';

type Props = {
  workflowType: WorkflowType;
  employee: WorkflowEmployee;
  className?: string;
};

export const WorkflowEmployeeHint: React.FC<Props> = ({
  workflowType,
  employee,
  className,
}) => {
  const t = useNamespacedTranslation('workflows.workflowHint');

  switch (workflowType) {
    case WorkflowType.Onboarding:
      if (employee.startDate) {
        return (
          <InfoHint
            className={className}
            text={t('onboarding', {
              date: formatDateShort(parseDate(employee.startDate)),
              interpolation: { escapeValue: false },
            })}
          />
        );
      }
      break;
    case WorkflowType.Offboarding:
      if (employee.endDate) {
        return (
          <InfoHint
            className={className}
            text={t('offboarding', {
              date: formatDateShort(parseDate(employee.endDate)),
            })}
          />
        );
      }
      break;
  }
  return null;
};
