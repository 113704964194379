import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  type: 'workflowsForMe' | 'workflowsForOthers';
};

export const WorkflowsFilterLoading: React.FC<Props> = ({ type }) => {
  const t = useNamespacedTranslation('workflows.filter');

  return (
    <div className="d-flex align-items-center">
      <span className="me-3">{t('filterBy')}</span>
      <Skeleton className="me-3" width={111} height={24} />
      <Skeleton className="me-3" width={81} height={24} />
      <Skeleton className="me-3" width={81} height={24} />
      {type === 'workflowsForOthers' && (
        <>
          <Skeleton className="me-3" width={80} height={24} />
          <Skeleton className="me-3" width={206} height={24} />
        </>
      )}
    </div>
  );
};
