import styled from '@emotion/styled';
import React, { useCallback, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../theme';
import ScorecardIcon from '../ScorecardIcon';
import { Score } from '../Evaluation/EvaluationTemplate/Question/ScoreCard';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

const BorderedDiv = styled('div')({
  display: 'inline-block',
  borderRadius: Theme.border.radius,
  border: `1px solid ${Theme.color.blue3}`,
});

type ScoreCardGroupParams = {
  name: string;
  selectedScore?: Score;
  disabled?: boolean;
  loading?: boolean;
  isComplete?: boolean;
  onChange?: (value: Score, answerId: string) => void;
  allowUnselect?: boolean;
};

const ScorecardGroup: React.FC<ScoreCardGroupParams> = ({
  name,
  selectedScore,
  disabled,
  loading,
  isComplete,
  onChange,
  allowUnselect = true,
}) => {
  const { t } = useTranslation();
  const [scoreValue, setScoreValue] = useState<Score>(selectedScore);

  const handleIconClick = useCallback(
    (score: Score) => {
      const isSameScoreSelected = selectedScore === score;
      if (disabled || (isSameScoreSelected && !allowUnselect)) {
        return;
      }

      let value = score;
      if (isSameScoreSelected) {
        value = undefined;
      }
      setScoreValue(value);
      onChange && onChange(value, name);
    },
    [allowUnselect, disabled, name, onChange, selectedScore],
  );

  return useMemo(
    () => (
      <>
        <BorderedDiv className="pt-2 pb-1 px-1">
          <ScorecardIcon
            id={`strong-no-${name}`}
            tooltip={t('evaluations.strongNo')}
            score={0}
            value={scoreValue}
            disabled={disabled}
            onIconClick={handleIconClick}
          />
          <ScorecardIcon
            id={`no-${name}`}
            tooltip={t('evaluations.no')}
            score={25}
            value={scoreValue}
            disabled={disabled}
            onIconClick={handleIconClick}
          />
          <ScorecardIcon
            id={`yes-${name}`}
            tooltip={t('evaluations.yes')}
            score={75}
            value={scoreValue}
            disabled={disabled}
            onIconClick={handleIconClick}
          />
          <ScorecardIcon
            id={`strong-yes-${name}`}
            tooltip={t('evaluations.strongYes')}
            score={100}
            value={scoreValue}
            disabled={disabled}
            onIconClick={handleIconClick}
          />
        </BorderedDiv>
        <div className="d-inline-block ms-2">
          {loading ? (
            <Spinner size="sm" animation="border" className="me-n4" />
          ) : (
            isComplete && (
              <FlairIcon
                icon="checkmark-circle-outline"
                className="text-success pt-1 me-n4"
              />
            )
          )}
        </div>
      </>
    ),
    [disabled, name, t, scoreValue, loading, isComplete, handleIconClick],
  );
};

export default ScorecardGroup;
