import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { DocumentEmployee, DocumentPreview, DocumentStatus } from '../types';
import { EmployeeWithAvatar } from '../../../components/Employee';
import { fileSizeFormatter } from '../../../../../utils/file';
import { DocumentItemStatus } from '../Tables/DocumentItemStatus';
import styled from '@emotion/styled';
import { replaceUnderscores } from '../helpers';
import TextareaAutosize from 'react-textarea-autosize';
import { useDocumentUpdate } from '../hooks/useDocumentUpdate';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { AutoSaveIndicator } from '../../../../../components/form/AutoSaveIndicator';
import { useUserInfo } from '../../../context/UserInfo';
import { OptionBase } from '../../../../../components/Select/types';
import { Button } from 'react-bootstrap';
import { useUpdateDocumentStatus } from '../hooks/useUpdateDocumentStatus';

type Props = {
  note: string;
  category?: string | null;
  categoryId?: string | null;
  location: string;
  employee: DocumentEmployee;
  type: string;
  size: number;
  status: DocumentStatus;
  isManager: boolean;
  documentCategories?: OptionBase[];
  document?: DocumentPreview | null;
};

const i18Path = 'documents2.documentInfoFields';
export const PersonalDocumentInfoFields: React.FC<Props> = ({
  note,
  category,
  categoryId,
  location,
  employee,
  size,
  type,
  status,
  documentCategories,
  document,
  isManager,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const {
    currentLoaderType,
    newCategory,
    newNotes,
    onChangeNotes,
    onChangeCategory,
    loading,
  } = useDocumentUpdate({
    defaultCategory: categoryId,
    defaultCategoryNotes: note,
    documentId: document?.id,
  });
  const { updateDocumentStatus, loading: statusUpdateLoading } =
    useUpdateDocumentStatus();
  const { id: meId } = useUserInfo();
  const isEditMode = employee?.id === meId;
  const isSavingNotes = loading && currentLoaderType === 'notes';
  const isSavingCategory = loading && currentLoaderType === 'category';
  const isStatusPending = status === 'PENDING';
  const handleUpdateDocumentStatus = (value: DocumentStatus) => {
    if (document) {
      updateDocumentStatus(document.id, value);
    }
  };
  return (
    <StyledInfoWrapper>
      <div className="d-flex flex-column gap-4">
        {isEditMode ? (
          <>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row gap-3">
                <h4 className="card-header-title mr-2">{t('note')}</h4>
                <AutoSaveIndicator
                  loading={isSavingNotes}
                  completed={isSavingNotes}
                />
              </div>
              <TextareaAutosize
                disabled={isSavingCategory}
                placeholder={t('inputs.notesPlaceholder')}
                className="form-control pb-3"
                value={newNotes}
                onChange={onChangeNotes}
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row gap-3">
                <h4 className="card-header-title">{t('category')}</h4>
                <AutoSaveIndicator
                  loading={isSavingCategory}
                  completed={isSavingCategory}
                />
              </div>
              <SingleFilter
                isDisabled={isSavingNotes}
                placeholder={t('inputs.categoryPlaceholder')}
                isSearchable={true}
                heightMode="large"
                isClearable={false}
                options={documentCategories ? documentCategories : []}
                value={newCategory}
                onChange={onChangeCategory}
              />
            </div>
          </>
        ) : (
          <>
            {note && (
              <div className="d-flex flex-column gap-2">
                <h4 className="card-header-title">{t('note')}</h4>
                <h4 className="card-header-title fw-normal text-break line-break">
                  {note}
                </h4>
              </div>
            )}
            {category && (
              <div className="d-flex flex-column gap-2">
                <h4 className="card-header-title">{t('category')}</h4>
                <h4 className="card-header-title fw-normal">{category}</h4>
              </div>
            )}
          </>
        )}

        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('location')}</h4>
          <h4 className="card-header-title fw-normal">
            {location ? location : '-'}
          </h4>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('uploadedBy')}</h4>
          <EmployeeWithAvatar
            employee={{
              name: employee.name,
              avatarUrl: employee.avatarUrl,
            }}></EmployeeWithAvatar>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('type')}</h4>
          <h4 className="card-header-title fw-normal">
            {replaceUnderscores(type)}
          </h4>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('size')}</h4>
          <h4 className="card-header-title fw-normal">
            {fileSizeFormatter(size)}
          </h4>
        </div>
        <div className="d-flex flex-column gap-2 mb-3">
          <h4 className="card-header-title">{t('status')}</h4>
          <div className="d-flex align-items-center gap-2">
            <DocumentItemStatus status={status} />
            <AutoSaveIndicator
              loading={statusUpdateLoading}
              completed={statusUpdateLoading}
            />
            {isManager && isStatusPending && !statusUpdateLoading && (
              <>
                <StatusActionButton
                  className="text-sm-center p-0 h7-medium"
                  onClick={() => handleUpdateDocumentStatus('APPROVED')}
                  variant="primary">
                  {t('updateStatus.buttons.approve')}
                </StatusActionButton>
                <StatusActionButton
                  className="text-sm-center p-0 h7-medium"
                  onClick={() => handleUpdateDocumentStatus('REJECTED')}
                  variant="outline-primary">
                  {t('updateStatus.buttons.reject')}
                </StatusActionButton>
              </>
            )}
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

const StyledInfoWrapper = styled.div`
  overflow-y: auto;
  max-height: 40rem;
  padding-bottom: 8rem;
`;

const StatusActionButton = styled(Button)({
  width: '3.75rem',
  height: '1.5rem',
});
