import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { i18prefix } from '../../../helpers';
import { HourHint } from '../../HourHint';

type Props = {
  className?: string;
  showCostCenterLabel: boolean;
};

const DayTimeInputLabels: React.FC<Props> = ({
  className,
  showCostCenterLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Row className={className}>
      <Col lg={3} xs={4}>
        <Form.Label className="mb-0">
          {t(`${i18prefix}.timeEntries.form.projectsLabel`)}
        </Form.Label>
      </Col>
      <Col lg={1} xs={2}>
        <Form.Label className="mb-0">
          {t(`${i18prefix}.timeEntries.form.hoursLabel`)}
          <HourHint className="ps-1" text={t(`${i18prefix}.hoursHint`)} />
        </Form.Label>
      </Col>
      <Col lg={4} xs={6}>
        <Form.Label className="mb-0">
          {t(`${i18prefix}.timeEntries.form.notesLabel`)}
          <HourHint
            className="ps-1"
            text={t(`${i18prefix}.timeEntries.form.notesPlaceholder`)}
          />
        </Form.Label>
      </Col>
      {showCostCenterLabel && (
        <Col lg={2} xs={3}>
          <Form.Label className="mb-0">
            {t(`${i18prefix}.timeEntries.form.costCenter`)}
          </Form.Label>
        </Col>
      )}
    </Row>
  );
};

export default DayTimeInputLabels;
