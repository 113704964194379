import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { EmployeeFeedbackMeetingModal } from '../../../components/EmployeeFeedbackMeetingModal';
import Section from '../../../components/Section';
import { PerformanceEmployeeEvaluationDocument } from '../../../__generated__/graphql';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../theme';

type Params = {
  meetingId: string;
  startDateTime: string | null;
  feedbackId: string;
  readonly?: boolean;
};

const FeedbackMeetingSection: React.FC<Params> = ({
  meetingId,
  startDateTime,
  feedbackId,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCloseModal = useCallback(
    () => setShowModal(false),
    [setShowModal],
  );

  const handleOpenModal = useCallback(() => setShowModal(true), [setShowModal]);

  const refetchQueriesOnSave = useMemo(() => {
    return [
      {
        query: PerformanceEmployeeEvaluationDocument,
        variables: {
          feedbackId,
        },
      },
    ];
  }, [feedbackId]);

  return (
    <>
      <Section>
        <div className="fw-bold mb-2">
          <div className="d-flex">
            <div className="me-auto">
              {t(
                'employeePage.performanceReview.nextPerformanceReview.bookMeeting.sectionTitle',
              )}
            </div>
            <div>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={handleOpenModal}
                label={t(
                  startDateTime
                    ? 'employeePage.performanceReview.nextPerformanceReview.bookMeeting.buttonReschedule'
                    : 'employeePage.performanceReview.nextPerformanceReview.bookMeeting.buttonSchedule',
                )}
              />
            </div>
          </div>
        </div>

        {startDateTime ? (
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <FlairIcon
                icon="calendar-check-outline"
                color={Theme.color.green3}
                fill={Theme.color.green3}
              />
            </div>

            <span className="text-dark ps-2">
              <FormattedDateTime dateTime={startDateTime} format="short" />
            </span>
          </div>
        ) : (
          <div className="d-flex">
            <span className="text-dark">
              {t(
                'employeePage.performanceReview.nextPerformanceReview.viewer.meeting.notSet',
              )}
            </span>
          </div>
        )}
      </Section>
      <EmployeeFeedbackMeetingModal
        show={showModal}
        onClose={handleCloseModal}
        meetingId={meetingId}
        startDatetime={startDateTime}
        refetchQueries={refetchQueriesOnSave}
      />
    </>
  );
};

export default FeedbackMeetingSection;
