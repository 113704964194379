import React from 'react';
import { Card } from 'react-bootstrap';
import ServerError from '../../../../../../components/ServerError';

import { routes as mainRoutes } from '../../../../routes';

import { useMyPerformanceReviewsQuery } from '../../../../__generated__/graphql';

import { EmptyPerformanceReview } from '../../Overview/EmptyPerformanceReview';
import { LoadingPerformanceReview } from '../../Overview/LoadingPerformanceReview';
import { mapFeedback } from '../mappings';
import { PerformanceReviewTable } from '../ReviewTable/PerformanceReviewTable';
import {
  collectCycleNameFilterOptions,
  collectReviewerFilterOptions,
  collectStatusFilterOptions,
  collectYearFilterOptions,
  FeedbackFilter,
} from '../Filter/FeedbackFilter';
import { applyFeedbackFilter } from '../filters';
import { useFlairPersistentFilter } from '../../../../../../hooks/useFlairPersistentFilter';
import { emptyFeedbackFilter } from '../types';

const Overview: React.FC = () => {
  const { data, loading, error } = useMyPerformanceReviewsQuery();

  const [filter, setFilter] = useFlairPersistentFilter({
    defaultFilter: emptyFeedbackFilter,
    storageKey: 'feedback_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.performanceReview.route
        .withQueryParams(queryParams)
        .create({}),
  });

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingPerformanceReview />;
  }

  if (!data.me.feedbacks.length) {
    return <EmptyPerformanceReview />;
  }

  const renderContent = () => {
    const feedbacks = applyFeedbackFilter(filter, filteredFeedbacks);

    return <PerformanceReviewTable feedbacks={feedbacks} isReviewer={false} />;
  };

  const filteredFeedbacks = data.me.feedbacks.map(mapFeedback);

  return (
    <>
      <Card>
        <Card.Header className="h-auto">
          <FeedbackFilter
            onChange={setFilter}
            filter={filter}
            cycleNameOptions={collectCycleNameFilterOptions(filteredFeedbacks)}
            reviewerOptions={collectReviewerFilterOptions(filteredFeedbacks)}
            yearOptions={collectYearFilterOptions(filteredFeedbacks)}
            statusOptions={collectStatusFilterOptions(filteredFeedbacks)}
            revieweeOptions={null}
          />
        </Card.Header>
        {renderContent()}
      </Card>
    </>
  );
};

export default Overview;
