import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { FormContext, useFormContext } from 'react-hook-form';
import { ModalSidebarContent } from '../../../components/ModalSidebar';

import { Project, ProjectViewMode } from '../types';
import { ProjectFields } from './ProjectFields';
import { ProjectTimeEntriesInfo } from './ProjectTimeEntriesInfo';

import { ProjectFormData } from './types';
import { useSubmitUpdate, useUpdateProjectForm } from './useProjectForm';
import { ProjectActivities } from '../ProjectActivities/ProjectActivities';

type Props = {
  viewMode: ProjectViewMode;
  project: Project;
  timeTracked: number;
  onClose: () => void;
};

export const ProjectForm: React.FC<Props> = (props) => {
  const form = useUpdateProjectForm(props.project);

  const { reset } = form;

  useEffect(() => {
    reset(props.project);
  }, [reset, props.project]);

  return (
    <FormContext {...form}>
      <Form>
        <ProjectFormContent {...props} />
      </Form>
    </FormContext>
  );
};

const ProjectFormContent: React.FC<Props> = ({
  project,
  viewMode,
  timeTracked,
}) => {
  const form = useFormContext<ProjectFormData>();
  const { formState } = form;
  const { dirtyFields, isSubmitting, isValid } = formState;

  const dirtyFieldsId = Array.from(dirtyFields).join('');

  const { submitUpdate, saveInfo } = useSubmitUpdate();

  useEffect(() => {
    if (isSubmitting || !isValid || viewMode !== 'MANAGER_VIEW') {
      return;
    }

    if (dirtyFields.size > 0) {
      submitUpdate(project.id, dirtyFields);
    }
  }, [
    dirtyFields,
    dirtyFieldsId,
    submitUpdate,
    isSubmitting,
    viewMode,
    isValid,
    project.id,
  ]);

  return (
    <ModalSidebarContent
      body={
        <div>
          <input type="hidden" name="type" ref={form.register} />
          {viewMode === 'MANAGER_VIEW' && (
            <div className="mb-3">
              <ProjectTimeEntriesInfo
                timeTrackedInMinutes={timeTracked}
                estimationInHours={project.estimation || 0}
                overtime={project.overtime}
              />
            </div>
          )}
          <ProjectFields viewMode={viewMode} saveInfo={saveInfo} />
          {viewMode === 'MANAGER_VIEW' && (
            <ProjectActivities activities={project.activities} />
          )}
        </div>
      }
      footer={null}
    />
  );
};

const Form = styled('form')({
  display: 'contents',
});
