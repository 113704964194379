import React from 'react';

type Props = {
  expiryDate: string;
  children: React.ReactNode;
};

const ExpiryWrapper: React.FC<Props> = ({ expiryDate, children }) => {
  const currentDate = new Date();
  const date = new Date(expiryDate);
  const isVisible = currentDate < date;

  return <>{isVisible && children}</>;
};

export default ExpiryWrapper;
