import * as React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BreadcrumbItem } from './types';
import './FlairBreadcrumb.css';
import { useHistory } from 'react-router-dom';

type Props = {
  items: BreadcrumbItem[];
};

const FlairBreadcrumb: React.FC<Props> = ({ items }) => {
  const history = useHistory();

  const onNavigate = (href: string) => {
    history.push(href);
  };

  return (
    <Breadcrumb className="flair-breadcrumb">
      {items.map((item, index) => {
        const isActive =
          item.isActive !== undefined
            ? item.isActive
            : index === items.length - 1;

        const className = ['breadcrumb-item', isActive ? 'active' : ''].join(
          ' ',
        );

        const role = item.url ? 'button' : '';

        return (
          <li className={className} key={index}>
            <span onClick={() => item.url && onNavigate(item.url)} role={role}>
              {item.label}
            </span>
          </li>
        );
      })}
    </Breadcrumb>
  );
};

export default FlairBreadcrumb;
