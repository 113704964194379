import React from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { ModalSidebarFormContent } from '../../../components/ModalSidebar';
import {
  WorkflowItemFileRequest,
  WorkflowItemStatus,
} from '../WorkflowItems/types';
import { WorkflowItemCommonFields } from './WorkflowItemCommonFields';
import { WorkflowItemSubmitButton } from './WorkflowItemSubmitButton';
import { WorkflowItemStatusField } from './WorkflowItemStatusField/WorkflowItemStatusField';

type Props = {
  workflowItem: WorkflowItemFileRequest;
};

type FormData = {
  status: WorkflowItemStatus;
};

export const WokflowItemFileRequestLoading: React.FC<Props> = ({
  workflowItem,
}) => {
  const { status } = workflowItem;

  const defaultValues: FormData = {
    status,
  };

  const form = useForm<FormData>({
    defaultValues,
  });

  return (
    <ModalSidebarFormContent
      form={form}
      onSubmit={() => {}}
      body={
        <>
          <WorkflowItemCommonFields workflowItem={workflowItem} />
          <Skeleton width={120} height={16} style={{ marginBottom: 10 }} />
          <Skeleton height={40} style={{ marginBottom: 20 }} />
          <WorkflowItemStatusField
            name="status"
            readonlyStatus={status}
            readonly={workflowItem.readonly}
          />
        </>
      }
      footerClassName="flex-column"
      footer={<WorkflowItemSubmitButton disabled={true} />}
    />
  );
};
