import { compact, uniq, without } from 'lodash';
import React, { ChangeEvent, useCallback } from 'react';
import { Props } from '..';
import Checkbox from '../../../Checkbox';
import { EmployeeFeedbackNotes } from '../../EmployeeFeedbackNotes';

type MultipleChoiceInputProps = Omit<Props, 'type' | 'requiredChoices'>;

const MultipleChoice: React.FC<MultipleChoiceInputProps> = ({
  id,
  value,
  onChange,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
}) => {
  const values = compact(value.split(';'));
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, checked },
      } = event;
      const newValues = checked
        ? uniq([...values, value])
        : without(values, value);

      onChange(newValues.join(';'));
    },
    [onChange, values],
  );

  return (
    <>
      {choices.map((choice) => (
        <Checkbox
          key={choice}
          id={`${id}-${choice}`}
          value={choice}
          label={choice}
          checked={values.includes(choice)}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly}
        />
      ))}
      {showOptionalComment && (
        <EmployeeFeedbackNotes
          value={notes}
          onChange={onChangeNotes}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default MultipleChoice;
