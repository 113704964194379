import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Certificate } from '../../../../pages/Certificates/types';
import { CertificateForm } from './CertificateForm';

type Props = {
  onClose: () => void;
  onSave: () => void;
  show: boolean;
  certificate: Certificate | null;
  employeeId: string;
};

export const CertificateModal: React.FC<Props> = ({
  show,
  onClose,
  onSave,
  certificate,
  employeeId,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      enforceFocus={false}
      show={show}
      centered
      onHide={onClose}
      header={t('expenses.newExpense')}
      dialogClassName="break-modal-440">
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">
          {certificate
            ? t('certificates.createCertificateModal.editCertificateTitle')
            : t('certificates.createCertificateModal.newCertificateTitle')}
        </h4>
      </Modal.Header>
      <CertificateForm
        onSave={onSave}
        onClose={onClose}
        certificate={certificate}
        employeeId={employeeId}
      />
    </Modal>
  );
};
