import { ChartData, ChartOptions } from 'chart.js';
import _, { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Radar from '../../../../../components/charts/Radar';
import { Theme } from '../../../../../theme';
import { ChartSkill } from './types';

type Props = {
  skills: ChartSkill[];
};

const SkillsRadarChartComponent: React.FC<Props> = ({ skills }) => {
  const { t } = useTranslation();

  return (
    <Radar
      data={getChartData(skills)}
      options={getChartOptions(t('skills.employeeSkills.chart.legend'))}
    />
  );
};

export const SkillsRadarChart = React.memo(
  SkillsRadarChartComponent,
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const getChartData = (skills: ChartSkill[]): ChartData<'radar'> => {
  return {
    labels: skills.map((s) => s.name),
    datasets: [
      {
        data: skills.map((s) => s.level ?? 0),
      },
    ],
  };
};

const theme = Theme.chart.skills;
const getChartOptions = (legend: string): ChartOptions<'radar'> => {
  return {
    elements: {
      line: {
        tension: 0,
        borderWidth: 1,
        borderColor: theme.line.borderColor,
        backgroundColor: theme.line.backgroundColor,
      },
      point: {
        radius: 2,
        pointStyle: 'circle',
        backgroundColor: theme.point.color,
      },
    },
    scales: {
      r: {
        min: 0,
        max: 5,
        angleLines: {
          color: theme.gridLines.color,
        },
        ticks: {
          stepSize: 1,
          color: theme.ticks.color,
        },
        grid: {
          color: theme.gridLines.color,
        },
        pointLabels: {
          color: theme.labels.color,
          callback: (tooltipItem) => {
            return _.truncate(tooltipItem, { length: 16 });
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => {
            return context[0].label;
          },
          label: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return `${legend} ${value}`;
          },
        },
      },
    },
  };
};
