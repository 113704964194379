import React, { useMemo } from 'react';
import { useArchivedNotificationsQuery } from '../../../__generated__/graphql';
import { mapNotifications } from '../RenderInfoMappings';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import NotificationsContentConnectedItems from './NotificationsContentConnectedItems';
import { getCurrentLanguage } from '../../../../../locales/locale';

const PAGE_SIZE = 10;

const ArchivedNotificationsContentConnected: React.FC = () => {
  const locale = getCurrentLanguage();
  const { data, loading, error, fetchMore } = useArchivedNotificationsQuery({
    variables: {
      lang: locale,
      offset: 0,
      limit: PAGE_SIZE,
      skipPaginationInfo: false,
    },
  });

  const notificationPaginationInfo = useMemo(() => {
    return data?.me.archivedNotifications;
  }, [data]);
  const alreadyKnowTotalCount =
    notificationPaginationInfo?.paginationInfo?.totalCount !== undefined;
  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: notificationPaginationInfo,
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo: alreadyKnowTotalCount,
        },
      }).then();
    },
  });

  const notifications =
    notificationPaginationInfo?.items &&
    data?.me &&
    mapNotifications(data?.me.Id, notificationPaginationInfo.items);

  return (
    <>
      <NotificationsContentConnectedItems
        error={!!error}
        loading={loading}
        loadingMore={LoadingMore}
        notifications={notifications}
        type="archived"
      />
    </>
  );
};

export default ArchivedNotificationsContentConnected;
