import * as React from 'react';

const HelpCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.99871 2.49976C6.91116 2.49976 5.84805 2.82225 4.94379 3.42645C4.03953 4.03066 3.33475 4.88944 2.91857 5.8942C2.50238 6.89895 2.39349 8.00456 2.60566 9.0712C2.81783 10.1378 3.34153 11.1176 4.11054 11.8866C4.87954 12.6556 5.85932 13.1793 6.92596 13.3915C7.99261 13.6037 9.09821 13.4948 10.103 13.0786C11.1077 12.6624 11.9665 11.9576 12.5707 11.0534C13.1749 10.1491 13.4974 9.086 13.4974 7.99846C13.4974 6.54011 12.9181 5.1415 11.8869 4.11029C10.8557 3.07908 9.45705 2.49976 7.99871 2.49976Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
      <path
        d="M6.24902 6.32098C6.24902 6.32098 6.27527 5.77424 6.86044 5.30341C7.20755 5.02379 7.6237 4.94287 7.99861 4.93725C8.34009 4.93287 8.64502 4.98942 8.82748 5.07628C9.1399 5.22499 9.7482 5.58803 9.7482 6.36004C9.7482 7.17235 9.21707 7.54132 8.6119 7.94716C8.00673 8.353 7.8424 8.79353 7.8424 9.24873"
        stroke="currentColor"
        strokeWidth="0.874794"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.81184 11.5011C8.15773 11.5011 8.43813 11.2207 8.43813 10.8748C8.43813 10.5289 8.15773 10.2485 7.81184 10.2485C7.46595 10.2485 7.18555 10.5289 7.18555 10.8748C7.18555 11.2207 7.46595 11.5011 7.81184 11.5011Z"
        fill="black"
      />
    </svg>
  );
});

export default HelpCircleOutlineIcon;
