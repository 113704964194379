import { t } from 'i18next';
import { addDays, formatDistanceToNow, isAfter, isBefore } from 'date-fns';

import { NotificationTypes } from 'server/src/shared/NotificationTypes';

import {
  NotificationIconType,
  NotificationItemData,
  NotificationQueryItem,
} from '../types';
import { routes } from '../../../routes';

const i18prefix = 'notifications.candidateEvaluation';

const iconsByType = new Map<string, NotificationIconType>([
  [NotificationTypes.CANDIDATE_EVALUATION_REQUESTED, 'briefcase-outline'],
  [NotificationTypes.CANDIDATE_EVALUATION_COMPLETED, 'checkmark-outline'],
]);

const descriptionsByType = new Map([
  [
    NotificationTypes.CANDIDATE_EVALUATION_REQUESTED,
    t(`${i18prefix}.requested`),
  ],
  [
    NotificationTypes.CANDIDATE_EVALUATION_COMPLETED,
    t(`${i18prefix}.completed`),
  ],
]);

export const mapCandidateEvaluationNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };
  data.icon = iconsByType.get(src.flair__Type__c);
  data.description = descriptionsByType.get(src.flair__Type__c) || '';
  data.title = t(`${i18prefix}.notificationTitle`);

  if (
    src.renderInfo.__typename === 'CandidateEvaluationNotificationRenderInfo'
  ) {
    // Check required fields. If not present, the notification won't be rendered.
    if (!src.renderInfo.candidateEvaluation?.candidate?.job?.Id) {
      return null;
    }

    if (
      src.flair__Type__c === NotificationTypes.CANDIDATE_EVALUATION_REQUESTED
    ) {
      const candidateId = src.renderInfo.candidateEvaluation.candidate.Id;
      if (candidateId) {
        data.url = routes.candidateDetails.route.create({ candidateId });
      } else {
        data.url = routes.employeeEvaluations.route.create({});
      }
      data.urlLabel =
        src.renderInfo.candidateEvaluation.candidate.flair__Full_Name__c;
      const dueDateStr =
        src.renderInfo.candidateEvaluation.flair__Due_Date__c ?? undefined;
      data.dueDate = dueDateStr ? new Date(dueDateStr) : undefined;
      const DAYS_CONSIDERED_DUE_SOON = 2;
      data.dueSoon =
        (data.dueDate &&
          isAfter(data.dueDate, new Date()) &&
          isBefore(
            data.dueDate,
            addDays(new Date(), DAYS_CONSIDERED_DUE_SOON),
          )) ??
        false;
      data.dueFromNow =
        data.dueDate && formatDistanceToNow(data.dueDate, { addSuffix: true });
    } else if (
      src.flair__Type__c === NotificationTypes.CANDIDATE_EVALUATION_COMPLETED
    ) {
      const candidateId = src.renderInfo.candidateEvaluation.candidate.Id;
      const jobName = src.renderInfo.candidateEvaluation.candidate.job.Name;
      data.url = routes.candidateDetails.route.create({ candidateId });
      data.urlLabel = jobName;
    }
  }

  return data;
};
