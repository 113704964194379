import { useUpdateInventoryStatusMutation } from '../../../../__generated__/graphql';
import { useToasts } from '../../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';

const i18Path = 'myData.inventory.statusUpdate';
export const useUpdateInventoryItemStatus = () => {
  const [updateInventoryItemStatus, { loading }] =
    useUpdateInventoryStatusMutation();
  const { addSuccess } = useToasts();
  const t = useNamespacedTranslation(i18Path);
  const errorHandler = useMutationErrorHandler();

  const updateStatus = (inventoryItemId: string, status: string) => {
    if (!inventoryItemId || !status) {
      return;
    }
    updateInventoryItemStatus({
      variables: {
        input: {
          status,
          inventoryItemId,
        },
      },
    })
      .then(() => {
        addSuccess(t('success'));
      })
      .catch(errorHandler);
  };

  return {
    loading,
    updateStatus,
  };
};
