import React, { useContext } from 'react';
import { TicketModalContext } from '../TicketModalContext';
import styled from '@emotion/styled';
import { Theme } from '../../../../../../../theme';
import { TFunc } from '../../../../../../../hooks/useNamespacedTranslation';
import { WhistleBlowerProtectionSection } from '../WhistleBlowerProtectionSection';

type Props = {
  t: TFunc;
  isManager: boolean;
};
export const TicketPreviewStep: React.FC<Props> = ({ t, isManager }) => {
  const { value } = useContext(TicketModalContext);

  const handleVisibilityText = () => {
    if (value?.stayAnonymous && !value.shareWithManager) {
      return t('form.preview.anonymous');
    }
    if (!value?.stayAnonymous && value?.shareWithManager) {
      return t('form.preview.sharedWithManager');
    }
    return null;
  };

  if (value) {
    return (
      <div className="d-flex flex-column mx-4 flex-fill gap-4">
        {value.hasWhistleblowerProtection && (
          <WhistleBlowerProtectionSection></WhistleBlowerProtectionSection>
        )}
        <PreviewCardWrapper className="rounded">
          <div className="p-3 d-flex flex-column gap-3">
            <div>
              <h3 className="h3-bold m-0">{value.preview.category}</h3>
              <h4 className="h4-regular m-0">{value.preview.issue}</h4>
            </div>
            {isManager && (
              <div>
                <h4 className="h4-regular m-0">{value.preview.employee}</h4>
              </div>
            )}
            {value.preview.details && (
              <div>
                <h4 className="h4-regular">{value.preview.details}</h4>
              </div>
            )}
            {!isManager && (
              <div>
                <h4 className="h4-regular m-0">{handleVisibilityText}</h4>
              </div>
            )}
          </div>
        </PreviewCardWrapper>
      </div>
    );
  }
  return <></>;
};

const PreviewCardWrapper = styled.div({
  background: Theme.color.paper7,
});
