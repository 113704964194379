import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';

type Props = {
  id: string;
  onDeleteClick: (id: string) => void;
};

export const SkillDeletor: React.FC<Props> = ({ id, onDeleteClick }) => {
  const { t } = useTranslation();

  return (
    <DropdownActions id="skill">
      <DropdownActionItem onClick={() => onDeleteClick(id)}>
        {t('skills.deleteSkill.title')}
      </DropdownActionItem>
    </DropdownActions>
  );
};
