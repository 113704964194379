import React from 'react';
import { routes as r } from '../../routes';
import { TimeTrackingAllTimesheetsPage } from './TimeTrackingAllTimesheetsPage';
import { TimeTrackingTimeSheetPage } from './TimeTrackingTimeSheetPage';

export const routes = () => ({
  timeSheet: {
    route: r.timeTracking.route,
    render: () => <TimeTrackingTimeSheetPage />,
  },
  timeBalance: {
    route: r.allTimeSheets.route,
    render: () => <TimeTrackingAllTimesheetsPage />,
  },
});
