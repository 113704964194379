import i18next from 'i18next';
import { allCertificateStatuses } from '../types';
import { Option } from './types';

export const createCertificateStatusOptions = (): Option[] => {
  return allCertificateStatuses.map((x) => ({
    value: x,
    label: i18next.t(`certificates.certificateStatus.${x}`),
  }));
};
