import * as React from 'react';
import './VideoAndAttachments.css';
import ToggleGroup from '../../../../../../../components/button/ToggleGroup';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { useState } from 'react';
import { DocumentPreview } from '../../../../Documents2/DocumentInfo/DocumentPreview';
import { WorkflowItem } from '../../../WorkflowItems/types';
import { isLoomUrl } from '../../../../../components/LoomVideo/isLoomUrl';
import { LoomVideoPreview } from '../../../../../components/LoomVideo';
import ReactPlayer from 'react-player';
import { mapLoomVideoFromUrl } from '../../../../../components/LoomVideo/mappings';
import { Maybe } from '../../../../../../../utils/maybe';
import Button from '../../../../../../../components/button';

type Props = {
  workflowItem: WorkflowItem | null;
  onPlayVideo: () => void;
};

const VIEWS = Object.freeze({
  ATTACHMENT: 'ATTACHMENT',
  VIDEO: 'VIDEO',
});

type Attachment = {
  fileName: string;
  viewLink: string;
  fileType: string;
};

const VideoAndAttachments: React.FC<Props> = ({
  workflowItem,
  onPlayVideo,
}) => {
  const t = useNamespacedTranslation(
    'performanceReview.workflowsItems.sidebar',
  );

  const [currentView, setCurrentView] = useState<Maybe<string>>(
    workflowItem && workflowItem.attachments.length > 0
      ? VIEWS.ATTACHMENT
      : VIEWS.VIDEO,
  );
  const isAttachmentView = currentView === VIEWS.ATTACHMENT;

  const [selectedFileToPreview, setSelectedFileToPreview] =
    useState<Maybe<Attachment>>(null);

  if (!workflowItem) {
    return <></>;
  }

  const hasAttachments = workflowItem.attachments.length > 0;
  const hasVideo =
    workflowItem.instructionsLoomUrl !== '' &&
    workflowItem.instructionsLoomUrl != null;

  if (!hasAttachments && !hasVideo) {
    return <></>;
  }

  const renderAttachments = () => {
    if (hasAttachments) {
      const attachments = workflowItem.attachments.filter(
        (attachment) => attachment.viewLink && attachment.fileType,
      ) as Attachment[];

      if (attachments.length === 1 || selectedFileToPreview) {
        const fileToPreview = selectedFileToPreview ?? attachments[0];

        return (
          <>
            {attachments.length > 1 && (
              <Button
                variant="outline-dark"
                className="rounded-circle mb-3 back-btn"
                size="sm"
                icon="arrow-back-outline"
                onClick={() => setSelectedFileToPreview(null)}
              />
            )}
            <DocumentPreview
              url={fileToPreview.viewLink}
              type={fileToPreview.fileType}
              width="100%"
            />
          </>
        );
      } else {
        return attachments.map((attachment) => (
          <li
            className="cu-pointer mb-3 link-primary"
            onClick={() => setSelectedFileToPreview(attachment)}>
            {attachment.fileName}
          </li>
        ));
      }
    }
  };

  const renderVideoPreview = () => {
    if (hasVideo && workflowItem.instructionsLoomUrl) {
      if (isLoomUrl(workflowItem.instructionsLoomUrl)) {
        return (
          <LoomVideoPreview
            loomVideo={mapLoomVideoFromUrl(workflowItem.instructionsLoomUrl)}
            className="rounded"
            onPlay={onPlayVideo}
          />
        );
      } else {
        return (
          <ReactPlayer
            url={workflowItem.instructionsLoomUrl}
            className="overflow-hidden rounded"
            width="100%"
            height="100%"
            controls
            onPlay={onPlayVideo}
          />
        );
      }
    }
  };

  const getToggleBtns = () => {
    let buttons = [];
    if (hasAttachments) {
      const hasMultipleAttachments = workflowItem.attachments.length > 1;
      buttons.push({
        name: VIEWS.ATTACHMENT,
        title: t(hasMultipleAttachments ? 'attachments' : 'attachment'),
      });
    }

    if (hasVideo) {
      buttons.push({ name: VIEWS.VIDEO, title: t('video') });
    }

    return buttons;
  };

  const hasMultipleAttachment =
    hasAttachments && workflowItem.attachments.length > 1;

  const shouldRenderToggles =
    hasMultipleAttachment || (hasAttachments && hasVideo);

  return (
    <div className="video-attachment-container mx-3 w-75">
      {shouldRenderToggles && (
        <ToggleGroup
          buttons={getToggleBtns()}
          defaultValue={getToggleBtns()[0].name}
          variant="secondary-2"
          containerClassName="w-100"
          onChange={setCurrentView}
        />
      )}
      <div className="mt-4 h-100">
        {isAttachmentView ? renderAttachments() : renderVideoPreview()}
      </div>
    </div>
  );
};

export default VideoAndAttachments;
