import * as React from 'react';

const PersonCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M8.09025 1.65246C4.43463 1.60308 1.45025 4.58746 1.49963 8.24308C1.54838 11.749 4.40307 14.6037 7.909 14.6525C11.5653 14.7025 14.549 11.7181 14.499 8.06246C14.4509 4.5559 11.5962 1.70121 8.09025 1.65246ZM12.0409 11.879C12.0284 11.8925 12.0132 11.903 11.9962 11.9099C11.9792 11.9169 11.9609 11.9199 11.9426 11.919C11.9243 11.918 11.9064 11.9131 11.8902 11.9044C11.8741 11.8958 11.86 11.8837 11.849 11.869C11.5695 11.5033 11.2272 11.1902 10.8381 10.9443C10.0424 10.4337 9.03432 10.1525 7.99963 10.1525C6.96494 10.1525 5.95681 10.4337 5.16119 10.9443C4.77209 11.1901 4.42978 11.5031 4.15025 11.8687C4.13927 11.8834 4.12519 11.8955 4.10902 11.9041C4.09284 11.9127 4.07497 11.9177 4.05666 11.9187C4.03835 11.9196 4.02005 11.9165 4.00307 11.9096C3.98608 11.9027 3.97083 11.8922 3.95838 11.8787C3.0414 10.8888 2.52197 9.59537 2.49963 8.24621C2.44869 5.20527 4.944 2.65996 7.98619 2.65246C11.0284 2.64496 13.4996 5.11527 13.4996 8.15246C13.5007 9.53415 12.9796 10.8652 12.0409 11.879Z"
        fill="currentColor"
      />
      <path
        d="M7.99965 4.65234C7.3834 4.65234 6.82621 4.88328 6.43027 5.30297C6.03434 5.72266 5.83652 6.30297 5.88121 6.92578C5.97184 8.15234 6.92215 9.15234 7.99965 9.15234C9.07715 9.15234 10.0256 8.15234 10.1181 6.92609C10.1643 6.30922 9.96809 5.73422 9.56559 5.30672C9.16809 4.88484 8.61184 4.65234 7.99965 4.65234Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default PersonCircleOutlineIcon;
