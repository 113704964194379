import React from 'react';
import EvaluationQuestion from './Question';

import {
  EvaluationTemplate,
  EvaluationAnswer,
  EvaluationTemplateQuestion,
  EvaluationTemplateQuestionType,
} from '../../../__generated__/graphql';
import { IRequiredInvalidFields } from '../helper';

export const getRequiredInvalidFields = (
  questions: EvaluationTemplateQuestion[],
  answers: EvaluationAnswer[],
): IRequiredInvalidFields => {
  const fields: IRequiredInvalidFields = {};
  questions.forEach((question) => {
    if (
      question.flair__Type__c === EvaluationTemplateQuestionType.FreeText &&
      question.flair__Required__c
    ) {
      const questionAnswers = mapQuestionAnswers(answers, question.Id);
      fields[question.Id] =
        questionAnswers.length === 0 || !questionAnswers[0].flair__Answer__c;
    } else if (
      question.flair__Type__c !== EvaluationTemplateQuestionType.Scorecard &&
      question.flair__Required__c
    ) {
      fields[question.Id] =
        mapQuestionAnswers(answers, question.Id).length === 0;
    } else if (
      question.flair__Type__c === EvaluationTemplateQuestionType.Scorecard
    ) {
      question.options.forEach((option) => {
        if (option.flair__Required__c) {
          fields[option.Id] =
            findScorecardAnswers(answers, question.Id, option.Id).length === 0;
        }
      });
    }
  });

  return fields;
};

type Params = {
  candidateEvaluationId: string | null;
  employeeEvaluationId: string | null;
  evaluationTemplate: EvaluationTemplate;
  answers: EvaluationAnswer[];
  disabled?: boolean;
  onFieldsUpdate: (fields: IRequiredInvalidFields) => void;
};

const mapQuestionAnswers = (
  answers: EvaluationAnswer[],
  questionId: string,
): EvaluationAnswer[] =>
  answers.filter(
    (answer) => answer.flair__Evaluation_Template_Question__c === questionId,
  );

const findScorecardAnswers = (
  answers: EvaluationAnswer[],
  questionId: string,
  optionId: string,
): EvaluationAnswer[] =>
  answers.filter(
    (answer) =>
      answer.flair__Evaluation_Template_Question__c === questionId &&
      answer.flair__Evaluation_Template_Option__c === optionId,
  );

const Content: React.FC<Params> = ({
  candidateEvaluationId,
  employeeEvaluationId,
  evaluationTemplate,
  disabled,
  answers,
  onFieldsUpdate,
}) => (
  <div className="questions">
    {evaluationTemplate.questions.map((question) => (
      <EvaluationQuestion
        key={question.Id}
        employeeEvaluationId={employeeEvaluationId}
        candidateEvaluationId={candidateEvaluationId}
        question={question}
        disabled={disabled}
        answers={mapQuestionAnswers(answers, question.Id)}
        onFieldsUpdate={onFieldsUpdate}
      />
    ))}
  </div>
);

export default Content;
