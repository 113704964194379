import classNames from 'classnames';
import React from 'react';

export type StatusDotVariant = 'info' | 'success' | 'warning' | 'danger';

type Props = React.HtmlHTMLAttributes<HTMLSpanElement> & {
  variant: StatusDotVariant;
};

const StatusDot = ({ variant, className, ...rest }: Props) => (
  <span className={classNames(`text-${variant}`, className)} {...rest}>
    ●
  </span>
);

export default StatusDot;
