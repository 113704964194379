import * as React from 'react';
import Reactions from '../index';
import {
  mapReaction,
  groupAndMapReactionsToEmojis,
  getTypeName,
} from './logic';
import { ReactionsRelatedObjectName } from './types';
import { ReactionFragment } from '../../../__generated__/graphql';
import { useUserInfo } from '../../../context/UserInfo';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { Emoji } from '../../Announcement';
import { useDeleteReaction } from './CustomHooks/useDeleteReaction';
import { useCreateReaction } from './CustomHooks/useCreateReaction';

type Props = {
  recordId: string;
  relatedObjectName: ReactionsRelatedObjectName;
  reactions: ReadonlyArray<ReactionFragment>;
};

const ConnectedReactions: React.FC<Props> = ({
  reactions,
  recordId,
  relatedObjectName,
}) => {
  const userInfo = useUserInfo();
  const errorHandler = useMutationErrorHandler();
  const mappedReactions = reactions.map(mapReaction);
  const objectTypeName = getTypeName(relatedObjectName);
  const [createReaction] = useCreateReaction(
    objectTypeName,
    recordId,
    reactions,
    userInfo,
  );
  const [deleteReaction] = useDeleteReaction(
    objectTypeName,
    recordId,
    reactions,
  );

  const emojis = groupAndMapReactionsToEmojis(mappedReactions);
  const handleSelect = (emoji: string) => {
    createReaction({
      variables: {
        input: {
          relatedId: recordId,
          text: emoji,
          employeeId: userInfo.id,
          relatedObjectName,
        },
      },
    })
      .then()
      .catch(errorHandler);
  };

  const handleRemove = ({ id }: Emoji) => {
    deleteReaction({ variables: { id } }).then().catch(errorHandler);
  };

  return (
    <Reactions
      emojis={emojis}
      onSelect={handleSelect}
      onRemove={handleRemove}
      maxNumberOfReactions={2}
    />
  );
};

export default ConnectedReactions;
