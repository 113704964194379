import React, { useContext } from 'react';
import InputLabel from '../../../../../../../components/form/InputLabel';
import { SelectDropdownControlled } from '../../../../../../../components/form/Selects';
import { TFunc } from '../../../../../../../hooks/useNamespacedTranslation';
import ServerError from '../../../../../../../components/ServerError';
import { getCurrentLanguage } from '../../../../../../../locales/locale';
import {
  TicketSubjectsQuery,
  useTicketSubjectsQuery,
} from '../../../../../__generated__/graphql';
import { TicketModalContext } from '../TicketModalContext';
import { OptionBase } from '../../../../../../../components/Select/types';

type Props = {
  t: TFunc;
};
export const IssueFormField: React.FC<Props> = ({ t }) => {
  const locale = getCurrentLanguage();
  const { value, onChange: onFieldChange } = useContext(TicketModalContext);

  const { data, loading, error } = useTicketSubjectsQuery({
    variables: {
      id: value?.category,
      lang: locale,
    },
  });
  const issues = mapIssueOptions(data);

  const handleIssueChange = (issueId: string | null) => {
    if (issueId) {
      const issue = issues.find((item) => item.value === issueId);
      issue &&
        onFieldChange({
          preview: {
            ...value?.preview,
            issue: issue.label,
          },
          issue: issue.value,
        });
    }
  };

  if (error) return <ServerError />;

  return (
    <>
      <InputLabel label={t('form.fields.issue')} />
      <SelectDropdownControlled
        isLoading={loading}
        name="issue"
        options={issues}
        onValueChange={handleIssueChange}
        heightMode="large"
        placeholder={t('form.fields.issuePlaceholder')}
      />
    </>
  );
};

const mapIssueOptions = (
  data: TicketSubjectsQuery | undefined,
): OptionBase[] => {
  if (!data || !data.dictionaryItemsByParent?.length) {
    return [];
  }
  return data.dictionaryItemsByParent?.map(({ Id, Name, label }) => ({
    value: Id,
    label: label ? label : Name,
  }));
};
