import React from 'react';
import { useTranslation } from 'react-i18next';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import Section from '../../../components/Section';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Params = {
  employeeName: string;
  progress: number;
  submittedTime: string | Date | null;
};

const EvaluationSection: React.FC<Params> = ({
  employeeName,
  progress,
  submittedTime,
}) => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="fw-bold mb-2">
        {t('employeePage.performanceReview.employeeEvaluation', {
          employee: employeeName,
          interpolation: { escapeValue: false },
        })}
      </div>

      {submittedTime ? (
        <div className="d-flex">
          <div>
            <FlairIcon
              icon="checkmark-circle-outline"
              className="text-success"
            />
          </div>

          <span className="text-dark ps-2">
            <FormattedDateTime dateTime={submittedTime} format="short" />
          </span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <div className="d-flex">
            <FlairIcon icon="ellipse-outline" />
          </div>
          <span className="text-dark ps-2">
            {t('employeePage.performanceReview.inProgress', { progress })}
          </span>
        </div>
      )}
    </Section>
  );
};

export default EvaluationSection;
