import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import ModalSidebar from '../../../components/ModalSidebar';
import { ManageSkillsConnected } from './ManageSkillsConnected';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';

type Props = {
  visible: boolean;
  onClose: () => void;
  headerActions: () => void;
};

export const ManageSkillsSidebar: React.FC<Props> = ({
  visible,
  onClose,
  headerActions,
}) => {
  const { t } = useTranslation();
  return (
    <ModalSidebar
      show={visible}
      onClose={onClose}
      header={t('skills.manager.title')}
      content={<ManageSkillsConnected />}
      headerActions={
        <DropdownActions id="headerActions">
          <DropdownActionItem onClick={headerActions}>
            {t('skills.createSkill.title')}
          </DropdownActionItem>
        </DropdownActions>
      }
    />
  );
};
