import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import WeekChart from '../../components/TimeSheet/WeekChart';
import CardHeader from '../../components/CardHeader';
import { TimeSheet } from './types';

type Props = {
  startDate: Date;
  timeSheet: TimeSheet;
};

const WeekChartCard: React.FC<Props> = ({ startDate, timeSheet }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>{t('timeTracking.graphs.week.title')}</CardHeader>
      <Card.Body>
        <WeekChart startDate={startDate} tracked={timeSheet.trackedTime} />
      </Card.Body>
    </Card>
  );
};

export default WeekChartCard;
