import * as React from 'react';

const HomeOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M2.49902 5.62378V12.997C2.49902 13.1296 2.55169 13.2568 2.64544 13.3505C2.73918 13.4443 2.86633 13.4969 2.99891 13.4969H5.9982V9.24792C5.9982 9.04906 6.0772 8.85834 6.21781 8.71772C6.35843 8.5771 6.54915 8.4981 6.74802 8.4981H9.24743C9.44629 8.4981 9.63701 8.5771 9.77763 8.71772C9.91825 8.85834 9.99725 9.04906 9.99725 9.24792V13.4969H12.9965C13.1291 13.4969 13.2563 13.4443 13.35 13.3505C13.4438 13.2568 13.4964 13.1296 13.4964 12.997V5.62378"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9957 6.99719L8.3376 0.623692C8.18139 0.458731 7.81616 0.456857 7.65714 0.623692L0.999023 6.99719M12.4963 4.5915V0.998604H10.9967V3.15434"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default HomeOutlineIcon;
