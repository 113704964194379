import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form } from '../../../../components/form/Form';
import { Input } from '../../../../components/form/Input';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { routes } from '../../routes';
import {
  Flair__Employee__C,
  ResetPasswordEmployeeError,
  useResetPasswordMutation,
} from '../../__generated__/graphql';
import { LoginHistoryLocationState } from '../Login/LoginPasswordForm';

type Props = {
  employee: Flair__Employee__C;
  organizationId: string;
  token: string;
};

type FormData = {
  password: string;
};

const formSchema = yup.object().shape<FormData>({
  password: yup.string().required().min(8),
});

const RegistrationForm: React.FC<Props> = ({
  token,
  organizationId,
  employee,
}) => {
  const [serverErrors, setServerErrors] = useState<boolean>(false);
  const { t } = useTranslation();
  const [doResetPassword] = useResetPasswordMutation();
  const errorHandler = useMutationErrorHandler();
  const history = useHistory<LoginHistoryLocationState>();

  const handleSubmit = (
    error: ResetPasswordEmployeeError | undefined | null,
  ) => {
    if (error) {
      setServerErrors(true);
      return;
    }

    history.push(routes.login.route.create({}), {
      alertMessage: 'reset-password',
    });
  };

  const onSubmit = async ({ password }: FormData) => {
    setServerErrors(false);
    await doResetPassword({
      variables: { token, organizationId, password },
    })
      .then(({ data }) => handleSubmit(data?.resetPassword?.error))
      .catch(errorHandler);
  };

  return (
    <>
      <h1 className="display-4 text-center mb-3">
        {t('resetPassword.title', { name: employee.flair__First_Name__c })}
      </h1>
      <p className="text-muted text-center mb-5">
        {t('resetPassword.subTitle')}
      </p>
      <Form
        onSubmit={onSubmit}
        validationSchema={formSchema}
        defaultValues={{ password: '' }}>
        <Input
          label={t('form.general.password.label')}
          placeholder={t('form.general.password.placeholder')}
          name="password"
          type="password"
          autoFocus
        />

        {serverErrors ? (
          <p className="text-danger">{t('signIn.invalid')}</p>
        ) : (
          <></>
        )}

        <SubmitButton block>{t('resetPassword.button')}</SubmitButton>
      </Form>
    </>
  );
};

export default RegistrationForm;
