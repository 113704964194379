import React from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  isSaving?: boolean;
};

export const ResolutionLabel: React.FC<Props> = ({ isSaving }) => {
  const { t } = useTranslation();
  return (
    <Form.Label className="row align-items-center mt-3">
      <Col>
        <span className="h4 mb-0">{t('feedbackMeeting.resolutionLabel')}</span>
      </Col>
      <Col className="col-auto">
        {isSaving && <Spinner animation="border" size="sm" />}
      </Col>
    </Form.Label>
  );
};
