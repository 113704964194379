import styled from '@emotion/styled';
import i18next from 'i18next';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { TimeSince } from '../../../../../../components/TimeSince';
import { Theme } from '../../../../../../theme';
import Avatar from '../../../../../../atomic/molecules/avatar/Avatar';
import { StatusCircle } from '../../../../components/StatusCircle';
import { getGoalRateStr, getStatusStr } from '../translations';
import { GoalActivity, GoalStatus } from '../types';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import StringToHtml from '../../../../../../components/StringToHtml';

type Props = {
  activity: GoalActivity;
};

const i18prefix = 'performanceReview.goals2.goalActivity';

export const GoalActivityItem: React.FC<Props> = ({ activity }) => {
  const renderComment = () => {
    if ('comment' in activity && activity.comment !== undefined) {
      const comment = activity.comment;
      return (
        <OverlayTrigger
          overlay={
            <Popover id={`comment-${activity.id}`}>
              <Popover.Body>
                <div>
                  <StringToHtml text={comment} />
                </div>
              </Popover.Body>
            </Popover>
          }>
          <CommentSpan className="ms-1">
            {i18next.t('performanceReview.goals2.goalActivityComment')}
          </CommentSpan>
        </OverlayTrigger>
      );
    }
    return null;
  };

  return (
    <Container>
      {getActivityIcon(activity)}
      <span className="ms-2">
        <Label>{getText(activity)}</Label>
        {renderComment()}
        <TimeLabel>
          <TimeSince date={activity.updatedAt} />
        </TimeLabel>
      </span>
    </Container>
  );
};

const getText = (activity: GoalActivity): string => {
  switch (activity.type) {
    case 'CREATE':
      return activity.employee?.name
        ? i18next.t(`${i18prefix}.CREATE`, {
            employee: activity.employee?.name,
          })
        : i18next.t(`${i18prefix}.CREATE_NO_EMPLOYEE`);
    case 'CHANGE_STATUS':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        status: getStatusStr(activity.status),
      });
    case 'CHANGE_STATUS_DONE_AND_RATE':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        status: getStatusStr(activity.status),
        rate: getGoalRateStr(activity.rate),
      });
    case 'CHANGE_STATUS_CANCELED':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        status: getStatusStr(activity.status),
        cancelReason: activity.cancelReason,
      });
    case 'SET_DESCRIPTION':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
      });
    case 'SET_TITLE':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        value: activity.value,
      });
    case 'CHANGE_RESPONSIBLE':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        from: activity.from,
        to: activity.to,
      });
    case 'CHANGE_RESPONSIBLE_TEAM':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        from: activity.from,
        to: activity.to,
      });
    case 'KEY_RESULT_UPDATE':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        keyResultName: activity.keyResultName,
        keyResultValue: activity.keyResultValue,
      });
    case 'MARK_AS_DRAFT':
    case 'REMOVE_DRAFT':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
      });
    case 'KEY_RESULT_ADD':
    case 'KEY_RESULT_DELETE':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        keyResultName: activity.keyResultName,
      });
    case 'TAG_ADD':
    case 'TAG_DELETE':
    case 'SHARED_WITH_ADD':
    case 'SHARED_WITH_DELETE':
      return i18next.t(`${i18prefix}.${activity.type}`, {
        employee: activity.employee?.name,
        name: activity.name,
      });
  }
};

const getActivityIcon = (activity: GoalActivity) => {
  switch (activity.type) {
    case 'CREATE':
      return (
        <IconContainer>
          <Avatar initials={activity.employee?.initials ?? ''} size="xxs" />
        </IconContainer>
      );
    case 'CHANGE_STATUS':
    case 'CHANGE_STATUS_DONE_AND_RATE':
    case 'CHANGE_STATUS_CANCELED':
      return (
        <IconContainer>
          <StatusCircleStyled
            color={getStatusColor(activity.status)}
            size={16}
          />
        </IconContainer>
      );
    case 'MARK_AS_DRAFT':
    case 'REMOVE_DRAFT':
      return (
        <IconContainer>
          <StatusCircleStyled
            color={Theme.goals.statusBar['DRAFT'].color}
            size={16}
          />
        </IconContainer>
      );
    default:
      return (
        <IconContainer>
          <FlairIcon size="xs" icon="pencil-outline" />
        </IconContainer>
      );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 11px;
  position: relative;
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 14px;
`;

const TimeLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  margin-left: 5px;
  color: ${Theme.color.paper1};
`;

const IconContainer = styled.div`
  margin-left: 3px;
  min-width: 16px;
  text-align: center;
`;

const StatusCircleStyled = styled(StatusCircle)({
  marginTop: '4px',
});

const CommentSpan = styled.span({
  fontSize: 12,
  color: Theme.color.blue4,
  cursor: 'pointer',
});

const getStatusColor = (status: GoalStatus) =>
  Theme.goals.statusBar[status].color;
