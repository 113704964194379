import {
  AdjustTimeEntryRequest,
  autoBreaksCalculationService,
  AutoBreaksRules,
  TimeEntryStats,
  TimeEntryStatsByLaw,
} from '.';
import { durationMinutes } from '../../../../../../utils/dateIntervalUtils';

export const calculateTimeEntryStats = (
  rules: AutoBreaksRules | null,
  request: AdjustTimeEntryRequest,
): TimeEntryStats => {
  const breakingMinutesTotal = request.breaks.reduce(
    (acc, x) => acc + durationMinutes(x),
    0,
  );

  const breakingTimeStats: TimeEntryStats = {
    breakingMinutesTotal,
    workingMinutes: Math.max(
      durationMinutes(request) - breakingMinutesTotal,
      0,
    ),
    timeEntryStatsByLaw: rules
      ? autoBreaksCalculationService.getStats(rules, request)
      : undefined,
  };

  return breakingTimeStats;
};

export const isLegalTimeEntryStats = (
  timeEntryStats: TimeEntryStats,
): boolean => {
  if (!timeEntryStats.timeEntryStatsByLaw) {
    return true;
  }
  return isLegal(timeEntryStats.timeEntryStatsByLaw);
};

export const isLegal = ({
  breakingMinutesUnusedByLaw,
}: TimeEntryStatsByLaw): boolean => {
  return breakingMinutesUnusedByLaw === 0;
};
