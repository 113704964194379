import React, { useEffect, useState } from 'react';
import ModalSidebar from '../../../components/ModalSidebar';
import { Expense, ExpenseStatusInfo } from '../types';
import { ExpenseFields } from './ExpenseFields';
import { Tab, Tabs } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import CommentListWrapper from '../../../components/Comment/CommentListWrapper/CommentListWrapper';
import './ExpenseSidebar.css';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { Maybe } from 'graphql/jsutils/Maybe';

const tabKeys = {
  EXPENSES: 'expenses',
  COMMENTS: 'comments',
};

type Props = {
  onClose: () => void;
  expense: Expense;
  onStatusChange?: (info: ExpenseStatusInfo) => Promise<void>;
  isManager: boolean;
  showComments: Maybe<boolean>;
};

const i18Path = 'expenses.expenseSideBar';

export const ExpenseSidebar: React.FC<Props> = ({
  onClose,
  expense,
  onStatusChange,
  isManager,
  showComments,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [selectedTabKey, setSelectedTabKey] = useState<string | null>(
    tabKeys.EXPENSES,
  );

  useEffect(() => {
    if (showComments) {
      setSelectedTabKey(tabKeys.COMMENTS);
    }
  }, [showComments]);

  const handleClose = () => {
    setSelectedTabKey(tabKeys.EXPENSES);
    onClose();
  };

  const isSelectedTab = (tabKey: string) => tabKey === selectedTabKey;

  return (
    <ModalSidebar
      show={true}
      onClose={handleClose}
      header={t('title')}
      content={
        <Tabs
          activeKey={selectedTabKey ?? tabKeys.EXPENSES}
          className="mx-4"
          onSelect={setSelectedTabKey}>
          <Tab
            eventKey={tabKeys.EXPENSES}
            title={t('information')}
            className="p-4">
            {isSelectedTab(tabKeys.EXPENSES) && (
              <ExpenseFields
                onStatusChange={onStatusChange}
                expense={expense}
                isManager={isManager}
              />
            )}
          </Tab>
          <Tab
            eventKey={tabKeys.COMMENTS}
            title={t('comments', { count: expense.commentsCount })}
            className="h-100 comments-tab">
            {isSelectedTab(tabKeys.COMMENTS) && (
              <CommentListWrapper
                recordId={expense.id}
                relatedObjectName={RelatedObjectNames.Expense}
              />
            )}
          </Tab>
        </Tabs>
      }></ModalSidebar>
  );
};
