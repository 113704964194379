import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EmployeeOrTeamOption } from './EmployeeOrTeamOptionLabel';
import {
  SelectMultipleEmployeeOrTeam,
  Props as SelectMultipleEmployeeOrTeamProps,
} from './SelectMultipleEmployeeOrTeam';

export type Props = Omit<
  SelectMultipleEmployeeOrTeamProps,
  'options' | 'defaultOptions' | 'loadOptions' | 'isAsync'
> & {
  defaultOptions?: EmployeeOrTeamOption[];
  loadOptions: (input: string) => Promise<EmployeeOrTeamOption[]>;
};

export const SelectMultipleEmployeeOrTeamAsync: React.FC<Props> = ({
  values,
  defaultOptions,
  loadOptions,
  ...props
}) => {
  const [savedOptions, setSavedOptions] =
    useState<EmployeeOrTeamOption[]>(values);

  const selectedIds: Set<string> = useMemo(
    () => values.reduce((acc, val) => acc.add(val.value), new Set<string>()),
    [values],
  );

  const defaultOptionsFiltered = useMemo(
    () => defaultOptions?.filter((x) => !selectedIds.has(x.value)),
    [defaultOptions, selectedIds],
  );

  useEffect(() => {
    if (!defaultOptions) {
      return;
    }
    setSavedOptions((prevOptions) =>
      uniqBy([...(prevOptions ?? []), ...defaultOptions], (x) => x.value),
    );
  }, [defaultOptions, setSavedOptions]);

  const handleLoadOptions = useCallback(
    (input: string) => {
      return loadOptions(input).then((loadedOptions) => {
        const filteredOptions = loadedOptions.filter(
          (x) => !selectedIds.has(x.value),
        );
        setSavedOptions((prevOptions) =>
          uniqBy([...(prevOptions ?? []), ...filteredOptions], (x) => x.value),
        );
        return filteredOptions;
      });
    },
    [loadOptions, selectedIds, setSavedOptions],
  );

  return (
    <SelectMultipleEmployeeOrTeam
      {...props}
      isAsync={true}
      values={values}
      options={savedOptions}
      defaultOptions={defaultOptionsFiltered}
      loadOptions={handleLoadOptions}></SelectMultipleEmployeeOrTeam>
  );
};
