import {
  NavigatableErrorCode,
  NavigatableIdInput,
  useDeleteEmployeeUploadedDocumentMutation,
} from '../../../__generated__/graphql';
import { useCallback, useState } from 'react';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import i18next from '../../../../../initializers/i18next';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useToasts } from '../../../../../context/Toast';
import { TableItem } from '../Tables/PersonalDocumentsTable';

const i18Path = 'documents2.personal';

type RowItem = {
  rowType: string;
};

export type DeletedItem = NavigatableIdInput & RowItem;
type Props = {
  employeeId: string;
};
export const useDocumentDelete = ({ employeeId }: Props) => {
  const t = useNamespacedTranslation(i18Path);
  const { addSuccess, addWarning } = useToasts();

  const [deleteItems, setDeleteItems] = useState<DeletedItem[]>([]);
  const errorHandler = useMutationErrorHandler();
  const [deleteEmployeeUploadedDocument, { loading: deleteInProgress }] =
    useDeleteEmployeeUploadedDocumentMutation();

  const documentDelete = useCallback(
    (resetStateCallback) => {
      if (!deleteItems.length) {
        return;
      }

      deleteEmployeeUploadedDocument({
        variables: {
          employeeId,
          navigatableIds: deleteItems.map(({ id, type }) => {
            return { id, type };
          }),
          recursive: true,
        },
      })
        .then((error) => {
          const errorCode = error.data?.document.delete.errorCode;
          if (errorCode) {
            addWarning(getUserFriendlyErrorText(errorCode));
            return;
          }
          addSuccess(t('documentDeletedToast'));
        })
        .catch(errorHandler)
        .finally(() => {
          setDeleteItems([]);
          resetStateCallback();
        });
    },
    [
      deleteItems,
      employeeId,
      deleteEmployeeUploadedDocument,
      addWarning,
      addSuccess,
      errorHandler,
      t,
    ],
  );

  const handleDeleteClose = () => {
    setDeleteItems([]);
  };

  const handleDelete = (document: NavigatableIdInput, rowType: string) => {
    setDeleteItems([...deleteItems, { ...document, rowType }]);
  };

  const handleMassDelete = (selectedRows: TableItem[]) => {
    const items = selectedRows.map((item: TableItem) => {
      return { id: item.id, type: item.typeName, rowType: item.type.type };
    });
    setDeleteItems([...items]);
  };

  return {
    handleDelete,
    handleMassDelete,
    handleDeleteClose,
    documentDelete,
    deleteItems,
    deleteInProgress,
  };
};

const getUserFriendlyErrorText = (errorCode: NavigatableErrorCode): string => {
  switch (errorCode) {
    case NavigatableErrorCode.DeleteNonEmptyFolderWithoutRecursion:
      return i18next.t(
        `${i18Path}.errors.deleteNonEmptyFolderWithoutRecursion`,
      );
    case NavigatableErrorCode.FolderContainsNonDeletableItems:
      return i18next.t(`${i18Path}.errors.folderContainsNonDeletableItems`);
    case NavigatableErrorCode.DocumentsUploadedByHrNonDeletable:
      return i18next.t(`${i18Path}.errors.documentsUploadedByHrNonDeletable`);
    default:
      return errorCode;
  }
};
