import { GoalFilter } from '../../Goals2/GoalsFilter/GoalsFilter';

export const getDefaultFilter = (
  isManager: boolean,
  isReviewGoals: boolean,
  currentEmployeeId: string,
  employeeId: string,
): GoalFilter => {
  const filter = {
    tagId: null,
    periodId: null,
    locationId: null,
    employeeId: null,
    goalType: null,
  };

  if (isReviewGoals) {
    return {
      ...filter,
      filterType: 'allGoals',
      employeeId,
    };
  } else if (employeeId === currentEmployeeId) {
    return {
      ...filter,
      filterType: 'myGoals',
    };
  }
  return {
    ...filter,
    filterType: isManager ? 'myReportsGoals' : 'allGoals',
    employeeId: employeeId,
  };
};
