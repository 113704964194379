import React from 'react';
import { Card } from 'react-bootstrap';
import CardBody from '../../../pages/Home/components/CardBody';

export const QuestionTitle: React.FC = ({ children }) => (
  <h5 className="card-title">{children}</h5>
);

export const QuestionnaireQuestionCard: React.FC = ({ children }) => {
  return (
    <Card>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
