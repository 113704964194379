import React from 'react';
import FlairFormattedDate from '../../../../../../../../atomic/atoms/FlairFormattedDate';
import { FlairDateFormat } from '../../../../../../../../atomic/atoms/FlairFormattedDate/types';
import { formatDistance, startOfDay } from 'date-fns';
import { parseDate } from '../../../../../../../../utils/dateUtils';

export const ReviewDueDate: React.FC<{ dueDate: string | null }> = ({
  dueDate,
}) => {
  if (!dueDate) {
    return null;
  }
  return (
    <span className="text-dark small">
      <FlairFormattedDate day={dueDate} format={FlairDateFormat.NumericDate} />{' '}
      ({formatDistance(startOfDay(new Date()), parseDate(dueDate))})
    </span>
  );
};
