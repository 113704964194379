import { useUpdateEmployeeNotificationSettingsMutation } from '../../../../../__generated__/graphql';
import { useUserInfo } from '../../../../../context/UserInfo';

export const useUpdateEmployeeNotificationSettings = (fieldName: string) => {
  const { id: meId } = useUserInfo();

  return useUpdateEmployeeNotificationSettingsMutation({
    update: (cache, response) => {
      const recordId = response.data?.updateEmployeeNotificationSettings;
      if (!recordId) {
        return;
      }
      const meCacheId: string | undefined = cache.identify({
        __typename: 'Me',
        Id: meId,
      });

      // Toggle current value
      cache.modify({
        id: meCacheId,
        fields: {
          notificationSettings: (existing, { readField }) => {
            const currentValue = readField(fieldName, existing);
            return {
              ...existing,
              [fieldName]: !currentValue,
            };
          },
        },
      });
    },
    optimisticResponse: ({ input }) => {
      return {
        updateEmployeeNotificationSettings: input.Id,
      };
    },
  });
};
