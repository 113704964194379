import * as React from 'react';

const MailUnreadOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M10 3.00366H2.75C2.41848 3.00366 2.10054 3.13536 1.86612 3.36978C1.6317 3.6042 1.5 3.92214 1.5 4.25366V11.7537C1.5 12.0852 1.6317 12.4031 1.86612 12.6375C2.10054 12.872 2.41848 13.0037 2.75 13.0037H13.2103C13.5418 13.0037 13.8598 12.872 14.0942 12.6375C14.3286 12.4031 14.4603 12.0852 14.4603 11.7537V7.47241"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 5.00366L8 8.50366L10.7188 6.45147"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4994 6.00371C13.1042 6.00371 12.7178 5.88651 12.3891 5.66691C12.0605 5.44732 11.8044 5.13521 11.6531 4.77004C11.5018 4.40488 11.4623 4.00306 11.5394 3.6154C11.6165 3.22774 11.8068 2.87165 12.0863 2.59217C12.3658 2.31268 12.7219 2.12235 13.1095 2.04524C13.4972 1.96813 13.899 2.0077 14.2642 2.15896C14.6293 2.31022 14.9415 2.56636 15.1611 2.895C15.3806 3.22364 15.4979 3.61002 15.4979 4.00527C15.4974 4.53517 15.2868 5.04324 14.9121 5.41793C14.5374 5.79262 14.0293 6.0033 13.4994 6.00371Z"
        fill="#FD553E"
      />
    </svg>
  );
});

export default MailUnreadOutlineIcon;
