import React from 'react';
import { Trans } from 'react-i18next';
import { EmployeeFeedbackState } from '../../../../../../__generated__/graphql';
import { isViewer } from '../../utils';
import { FeedbackProps } from '../../../../types';

type Props = {
  feedback: FeedbackProps;
  myId: string;
};

export const ReviewDescription: React.FC<Props> = ({ feedback, myId }) => {
  const isManager = isViewer(feedback.flair__Feedback_From__c, myId);
  const getDescriptionKey = () => {
    if (
      feedback.flair__State__c === EmployeeFeedbackState.Submitted &&
      feedback.employeeFeedback?.flair__State__c !==
        EmployeeFeedbackState.Submitted
    ) {
      return feedback.feedbackCycle.flair__Meeting_Required__c
        ? 'employeePage.performanceReview.nextPerformanceReview.descriptionForSubmitted'
        : 'employeePage.performanceReview.nextPerformanceReview.descriptionForSubmittedWithoutMeeting';
    }
    if (isManager)
      return 'employeePage.performanceReview.nextPerformanceReview.descriptionForManager';
    return 'employeePage.performanceReview.nextPerformanceReview.description';
  };

  const i18nKey = getDescriptionKey();

  return (
    <Trans
      i18nKey={i18nKey}
      values={{
        employee: feedback.reviewee.Name,
        interpolation: { escapeValue: false },
      }}
      components={[<strong />]}
    />
  );
};
