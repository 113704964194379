import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SkeletonCard from '../../../components/Skeleton/Card';
import SkeletonPageHeader from '../../../components/Skeleton/PageHeader';

const Loading: React.FC = () => {
  return (
    <Container>
      <SkeletonPageHeader />
      <Row>
        <Col xl={8}>
          <SkeletonCard height={545} />
          <SkeletonCard height={316} />
        </Col>
        <Col xl={4}>
          <SkeletonCard height={180} />
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
