import * as React from 'react';

const CalendarPlusIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M19.5038 3H17.2538V2.25C17.2538 2.05109 17.1748 1.86032 17.0341 1.71967C16.8935 1.57902 16.7027 1.5 16.5038 1.5C16.3049 1.5 16.1141 1.57902 15.9735 1.71967C15.8328 1.86032 15.7538 2.05109 15.7538 2.25V3H8.25378V2.25C8.25378 2.05109 8.17477 1.86032 8.03411 1.71967C7.89346 1.57902 7.7027 1.5 7.50378 1.5C7.30487 1.5 7.11411 1.57902 6.97345 1.71967C6.8328 1.86032 6.75378 2.05109 6.75378 2.25V3H4.50378C4.10596 3 3.72443 3.15804 3.44312 3.43934C3.16182 3.72064 3.00378 4.10218 3.00378 4.5V19.5C3.00378 19.8978 3.16182 20.2794 3.44312 20.5607C3.72443 20.842 4.10596 21 4.50378 21H19.5038C19.9016 21 20.2831 20.842 20.5644 20.5607C20.8457 20.2794 21.0038 19.8978 21.0038 19.5V4.5C21.0038 4.10218 20.8457 3.72064 20.5644 3.43934C20.2831 3.15804 19.9016 3 19.5038 3ZM6.75378 4.5V5.25C6.75378 5.44891 6.8328 5.63968 6.97345 5.78033C7.11411 5.92098 7.30487 6 7.50378 6C7.7027 6 7.89346 5.92098 8.03411 5.78033C8.17477 5.63968 8.25378 5.44891 8.25378 5.25V4.5H15.7538V5.25C15.7538 5.44891 15.8328 5.63968 15.9735 5.78033C16.1141 5.92098 16.3049 6 16.5038 6C16.7027 6 16.8935 5.92098 17.0341 5.78033C17.1748 5.63968 17.2538 5.44891 17.2538 5.25V4.5H19.5038V7.5H4.50378V4.5H6.75378ZM19.5038 19.5H4.50378V9H19.5038V19.5ZM15.0038 14.25C15.0038 14.4489 14.9248 14.6397 14.7841 14.7803C14.6435 14.921 14.4527 15 14.2538 15H12.7538V16.5C12.7538 16.6989 12.6748 16.8897 12.5341 17.0303C12.3935 17.171 12.2027 17.25 12.0038 17.25C11.8049 17.25 11.6141 17.171 11.4735 17.0303C11.3328 16.8897 11.2538 16.6989 11.2538 16.5V15H9.75378C9.55487 15 9.36411 14.921 9.22345 14.7803C9.0828 14.6397 9.00378 14.4489 9.00378 14.25C9.00378 14.0511 9.0828 13.8603 9.22345 13.7197C9.36411 13.579 9.55487 13.5 9.75378 13.5H11.2538V12C11.2538 11.8011 11.3328 11.6103 11.4735 11.4697C11.6141 11.329 11.8049 11.25 12.0038 11.25C12.2027 11.25 12.3935 11.329 12.5341 11.4697C12.6748 11.6103 12.7538 11.8011 12.7538 12V13.5H14.2538C14.4527 13.5 14.6435 13.579 14.7841 13.7197C14.9248 13.8603 15.0038 14.0511 15.0038 14.25Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default CalendarPlusIcon;
