import * as React from 'react';

const HomeFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_210_4650)">
        <path
          d="M10.4904 3.95631C10.4322 3.90068 10.3548 3.86963 10.2744 3.86963C10.1939 3.86963 10.1165 3.90068 10.0583 3.95631L2.86694 10.8262C2.8364 10.8554 2.8121 10.8906 2.79552 10.9294C2.77894 10.9683 2.77041 11.0101 2.77046 11.0524L2.76929 17.5001C2.76929 17.8316 2.90098 18.1495 3.1354 18.3839C3.36982 18.6184 3.68777 18.7501 4.01929 18.7501H7.77319C7.93895 18.7501 8.09792 18.6842 8.21513 18.567C8.33235 18.4498 8.39819 18.2908 8.39819 18.1251V12.8126C8.39819 12.7297 8.43112 12.6502 8.48972 12.5916C8.54833 12.533 8.62781 12.5001 8.71069 12.5001H11.8357C11.9186 12.5001 11.9981 12.533 12.0567 12.5916C12.1153 12.6502 12.1482 12.7297 12.1482 12.8126V18.1251C12.1482 18.2908 12.214 18.4498 12.3313 18.567C12.4485 18.6842 12.6074 18.7501 12.7732 18.7501H16.5255C16.8571 18.7501 17.175 18.6184 17.4094 18.3839C17.6438 18.1495 17.7755 17.8316 17.7755 17.5001V11.0524C17.7756 11.0101 17.7671 10.9683 17.7505 10.9294C17.7339 10.8906 17.7096 10.8554 17.6791 10.8262L10.4904 3.95631Z"
          fill="currentColor"
        />
        <path
          d="M19.4496 9.53711L16.5277 6.7418V2.5C16.5277 2.33424 16.4619 2.17527 16.3447 2.05806C16.2275 1.94085 16.0685 1.875 15.9027 1.875H14.0277C13.862 1.875 13.703 1.94085 13.5858 2.05806C13.4686 2.17527 13.4027 2.33424 13.4027 2.5V3.75L11.1402 1.58672C10.9285 1.37266 10.6137 1.25 10.2735 1.25C9.93439 1.25 9.62033 1.37266 9.40861 1.58711L1.10001 9.53633C0.857046 9.7707 0.826577 10.1562 1.04767 10.4102C1.10319 10.4742 1.17117 10.5264 1.24748 10.5633C1.32378 10.6003 1.40681 10.6214 1.49151 10.6253C1.57622 10.6291 1.66083 10.6157 1.74019 10.5859C1.81955 10.556 1.892 10.5103 1.95314 10.4516L10.0586 2.70625C10.1168 2.65062 10.1941 2.61957 10.2746 2.61957C10.3551 2.61957 10.4325 2.65062 10.4906 2.70625L18.5969 10.4516C18.7163 10.5661 18.8762 10.6286 19.0417 10.6253C19.2071 10.6221 19.3645 10.5534 19.4793 10.4344C19.7192 10.1859 19.6992 9.77578 19.4496 9.53711Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_210_4650">
          <rect x="0.273438" width="20" height="20" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default HomeFilledIcon;
