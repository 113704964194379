import { getEmployeeInitialsFromName } from '../../../utils/employeeInitials';
import {
  EmployeeSelectInfoColleagueFragment,
  EmployeeSkillMatrixFragment,
} from '../../../__generated__/graphql';
import { mapSkillLevel } from '../common/mappings';
import { Employee } from '../common/types';
import { EmployeeSkillMatrix } from './types';

export const mapEmployeeSkillMatrix = (
  src: EmployeeSkillMatrixFragment,
): EmployeeSkillMatrix => ({
  id: src.Id,
  skillId: src.flair__Skill__c,
  employeeId: src.flair__Employee__c,
  level: mapSkillLevel(src.flair__Rate__c),
});

export const mapEmployee = (
  src: EmployeeSelectInfoColleagueFragment,
): Employee => ({
  id: src.Id,
  name: src.Name,
  initials: getEmployeeInitialsFromName(src.Name),
  avatarUrl: src.avatar?.url ?? undefined,
});
