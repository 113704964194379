export type Route<Parts extends Array<PathPart<any>>> = {
  create(params: Record<ParamsFromPathArray<Parts>[number], string>): string;
  template(): string;
  withQueryParams: (params: QueryString) => Route<Parts>;
};

export type QueryString = Record<string, string>;

export type PathParam<T extends string> = {
  param: T;
};

export type PathPart<T extends string> = string | PathParam<T>;

export type ParamsFromPathArray<T extends Array<PathPart<any>>> = {
  [K in keyof T]: T[K] extends PathParam<infer ParamName> ? ParamName : never;
};

export type RouteParams<T extends Route<any>> = T extends Route<infer X>
  ? Record<ParamsFromPathArray<X>[number], string>
  : never;

const isParam = (i: any): i is PathParam<any> => {
  return i.param != null;
};

export const param = <T extends string>(t: T): PathParam<T> => {
  return { param: t };
};

type RouteCreator = <K extends Array<PathPart<any>>>(...args: K) => Route<K>;

export const route: RouteCreator = (...pathParts: Array<PathPart<any>>) =>
  _routeCreator(pathParts, {});

const printParamTemplate = <T extends string>(part: PathPart<T>): string =>
  isParam(part) ? `:${part.param}` : part;

const printParam =
  <T extends string>(params: Record<T, string>) =>
  (part: PathPart<T>): string =>
    isParam(part) ? params[part.param] : part;

const _routeCreator = <T extends Array<PathPart<any>>>(
  pathParts: T,
  queryParams: QueryString,
): Route<T> => {
  return {
    template: () => `/${pathParts.map(printParamTemplate).join('/')}`,
    create: (
      params: Record<ParamsFromPathArray<T>[number], string>,
    ): string => {
      const baseUrl = '/' + pathParts.map(printParam(params)).join('/');

      const queryString = Object.keys(queryParams)
        .map((k) => `${k}=${queryParams[k]}`)
        .join('&');

      return queryString === '' ? baseUrl : `${baseUrl}?${queryString}`;
    },
    withQueryParams: (params: QueryString) =>
      _routeCreator(pathParts, { ...params, ...queryParams }),
  };
};
