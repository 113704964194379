import styled from '@emotion/styled';
import React from 'react';
import { getSkillLevelRenderInfo } from './skillLevels';
import { SkillLevel } from './types';

type Props = {
  value: SkillLevel | undefined;
  displayValue?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
};

export const SkillLevelLabel: React.FC<Props> = ({
  value,
  className,
  displayValue,
  width,
  height,
}) => {
  const info = getSkillLevelRenderInfo(value);
  return (
    <Container
      backgroundColor={info.color}
      width={width}
      height={height}
      title={info.title}
      className={className}>
      {displayValue || info.label}
    </Container>
  );
};

export const SKILL_LEVEL_LABEL_WIDTH = '4.5rem';
export const SKILL_LEVEL_LABEL_HEIGHT = '1.5rem';

const Container = styled.div<{
  backgroundColor: string;
  width?: string | number;
  height?: string | number;
}>(
  ({
    backgroundColor,
    width = SKILL_LEVEL_LABEL_WIDTH,
    height = SKILL_LEVEL_LABEL_HEIGHT,
  }) => ({
    width,
    height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '0.7rem',
    backgroundColor,
    borderRadius: '0.25rem',
  }),
);
