import { Expense, ExpenseFilter } from './types';

export const applyExpenseFilter = (
  filter: ExpenseFilter,
  expenses: Expense[],
): Expense[] => expenses.filter((x) => isMatchExpenseFilter(filter, x));

const isMatchExpenseFilter = (
  filter: ExpenseFilter,
  expense: Expense,
): boolean => {
  if (filter.category !== null && expense.category?.Id !== filter.category) {
    return false;
  }
  if (filter.status !== null && expense.status !== filter.status) {
    return false;
  }

  if (filter.year !== null) {
    if (expense.expenseDate.getFullYear() !== parseInt(filter.year)) {
      return false;
    }
  }
  return true;
};
