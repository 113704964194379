import classNames from 'classnames';
import { compact } from 'lodash';
import React, { useCallback } from 'react';
import { Props } from '..';
import ScorecardGroup from '../../../ScorecardGroup';
import { EmployeeFeedbackNotes } from '../../EmployeeFeedbackNotes';
import { IScorecard } from '../../types';
import {
  convertToScorecards,
  convertToString,
  findScorecard,
  getScore,
} from '../../utils';
import { Score } from '../../../Evaluation/EvaluationTemplate/Question/ScoreCard';

type ScorecardInputProps = Omit<Props, 'type'>;

const putScorecard = (scorecards: IScorecard[], name: string, score: Score) => {
  const scorecard = findScorecard(scorecards, name);
  if (scorecard) {
    scorecard.score = score;
  } else {
    scorecards.push({ name, score });
  }
};

const Scorecard: React.FC<ScorecardInputProps> = ({
  value,
  onChange,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  requiredChoices,
  showOptionalComment,
}) => {
  const scorecards = compact(convertToScorecards(value));

  const handleChange = useCallback(
    (score: Score, choice: string) => {
      putScorecard(scorecards, choice, score);
      onChange(convertToString(scorecards));
    },
    [onChange, scorecards],
  );

  return (
    <div className={classNames('d-flex', 'flex-column', 'gap-3')}>
      {choices.map((choice, key) => (
        <div
          className={classNames('d-flex', 'flex-column', 'gap-2')}
          key={`${choice}-${key}`}>
          <div>
            <span className="pe-1">{choice}</span>
            {requiredChoices.indexOf(choice) !== -1 && (
              <abbr className="text-danger me-1">*</abbr>
            )}
          </div>
          <div>
            <ScorecardGroup
              selectedScore={getScore(scorecards, choice)}
              name={choice}
              disabled={readOnly}
              loading={false}
              isComplete={false}
              onChange={handleChange}
            />
          </div>
        </div>
      ))}
      {showOptionalComment && (
        <EmployeeFeedbackNotes
          value={notes}
          onChange={onChangeNotes}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default Scorecard;
