import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Column } from 'react-table';
import { i18prefix } from './helpers';
import FormattedDate from '../../../../components/date/FormattedDate';
import { TableContent } from '../../manager/components/TableContent';
import { Project } from './types';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { ProjectTrackingProgressBar } from './ProjectTrackingProgressBar';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useTranslation } from 'react-i18next';
import { ProjectTrackedTimeFormattedMessage } from './ProjectTrackedTimeFormattedMessage';

type Props = {
  projects: Project[];
  onItemClick: (item: Project) => void;
};

type TimeTracked = {
  trackedInMinutes: number;
  projectEstimationInMinutes: number | null;
};

type TableItem = {
  id: string;
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  billable: boolean;
  timeTracked: TimeTracked;
  actions: null;
};

const mapToTableItem = (project: Project): TableItem => {
  const timeTracked = {
    trackedInMinutes: project.timeTrackedInMinutes,
    projectEstimationInMinutes: !!project.estimation
      ? project.estimation * 60
      : null,
  };
  return {
    id: project.id,
    name: project.name,
    startDate: project.startDate,
    endDate: project.endDate,
    billable: project.billable,
    timeTracked: timeTracked,
    actions: null,
  };
};

const ProjectsTable: React.FC<Props> = ({ projects, onItemClick }) => {
  const projectsT = useNamespacedTranslation(i18prefix);
  const { t } = useTranslation();
  const items = projects.map((project) => mapToTableItem(project));

  const columns: Column<TableItem>[] = useMemo(
    () => [
      {
        Header: () => projectsT('fields.project'),
        accessor: 'name',
      },
      {
        Header: () => projectsT('fields.startDate'),
        accessor: 'startDate',
        Cell: ({ value }) =>
          value ? <FormattedDate day={value} format="short" /> : <span>-</span>,
      },
      {
        Header: () => projectsT('fields.endDate'),
        accessor: 'endDate',
        Cell: ({ value }) =>
          value ? <FormattedDate day={value} format="short" /> : <span>-</span>,
      },
      {
        Header: () => projectsT('fields.billable'),
        accessor: 'billable',

        Cell: ({ value }) => (
          <div className="d-flex align-items-center justify-content-between">
            {value ? t('general.yes') : t('general.no')}
          </div>
        ),
      },
      {
        Header: () => projectsT('fields.timeTracked'),
        accessor: 'timeTracked',
        Cell: ({ value }) => {
          return (
            <div className="d-flex align-items-center">
              <ProjectTrackedTimeFormattedMessage
                trackedTime={value.trackedInMinutes}
                projectEstimation={
                  value.projectEstimationInMinutes
                }></ProjectTrackedTimeFormattedMessage>
              <div className="ms-3">
                <ProjectTrackingProgressBar
                  trackedTime={value.trackedInMinutes}
                  projectEstimation={
                    value.projectEstimationInMinutes
                  }></ProjectTrackingProgressBar>
              </div>
            </div>
          );
        },
      },
      {
        Header: () => '',
        accessor: 'actions',

        Cell: ({ value }) => (
          <div className="d-flex align-items-center justify-content-between">
            <FlairIcon icon="chevron-forward-outline" />
          </div>
        ),
      },
    ],
    [projectsT, t],
  );

  const handleOnRowClick = (item: TableItem) => {
    onItemClick(projects.filter((x) => x.id === item.id)![0]);
  };

  return (
    <Card>
      <TableContent
        columns={columns}
        data={items}
        onRowClick={handleOnRowClick}
        getRowProps={() => ({ role: 'button' })}
      />
    </Card>
  );
};

export default ProjectsTable;
