import { IScorecard } from './types';

//TODO check that
import { Score } from '../../components/Evaluation/EvaluationTemplate/Question/ScoreCard';
import { falsyExceptZero } from '../../../../utils/dataType';

const splitScorecard = (item: string): IScorecard | null =>
  item.includes(':')
    ? {
        name: item.substring(0, item.lastIndexOf(':')),
        score: +item.substring(item.lastIndexOf(':') + 1, item.length),
      }
    : null;

export const convertToScorecards = (value: string): (IScorecard | null)[] =>
  value
    .split(/;|\n/)
    .map(splitScorecard)
    .filter((item) => !!item);

export const convertToString = (scorecards: IScorecard[]): string =>
  scorecards
    .filter((scorecard) => !falsyExceptZero(scorecard.score))
    .map((scorecard) => `${scorecard.name}:${scorecard.score}`)
    .join('\n');

export const getScore = (scorecards: IScorecard[], name: string): Score => {
  const scorecard = findScorecard(scorecards, name);
  return scorecard && scorecard.score;
};

export const findScorecard = (
  scorecards: IScorecard[],
  name: string,
): IScorecard | undefined =>
  scorecards.find((scorecard) => scorecard.name === name);
