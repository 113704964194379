import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import Hint from '../../../../../../components/hint';
import { Theme } from '../../../../../../theme';
import ScorecardGroup from '../../../../components/ScorecardGroup';
import { EvaluationTemplateQuestionType } from '../../../../__generated__/graphql';
import { EvaluationQuestionParams, LoadingItem, ScorecardValue } from './types';
import { Maybe } from '../../../../../../utils/maybe';

const ScoreCardRow = styled.div({
  borderBottom: `1px solid ${Theme.color.paper3}`,
});

type ScorecardParams = Omit<
  EvaluationQuestionParams,
  'defaultValue' | 'loading'
> & {
  defaultValue: ScorecardValue;
  loadingItems: LoadingItem;
};

export type Score = Maybe<number>;

const Scorecard: React.FC<ScorecardParams> = ({
  question,
  defaultValue,
  disabled,
  loadingItems,
  onUpdateAnswer,
}) => {
  const handleChange = useCallback(
    (score: Score, optionId: string) => {
      onUpdateAnswer({
        questionType: EvaluationTemplateQuestionType.Scorecard,
        value: score,
        optionId,
      });
    },
    [onUpdateAnswer],
  );

  return (
    <FormGroup>
      <Form.Label className="mb-0">
        {question.flair__Name__c}{' '}
        {question.flair__Hint__c && (
          <Hint id={`hint_${question.Id}`} text={question.flair__Hint__c} />
        )}
      </Form.Label>
      {question.options.map((item) => (
        <ScoreCardRow
          className="align-items-center d-flex justify-content-between py-3 mx-3"
          key={item.Id}>
          <div>
            <small>
              <span className="pe-1">{item.Name}</span>
              {item.flair__Required__c && (
                <abbr className="text-danger me-1">*</abbr>
              )}
              {item.flair__Hint__c && (
                <Hint id={`hint_${item.Id}`} text={item.flair__Hint__c} />
              )}
            </small>
          </div>
          <div>
            <ScorecardGroup
              name={item.Id}
              disabled={disabled || loadingItems[item.Id]}
              selectedScore={defaultValue[item.Id]}
              loading={loadingItems[item.Id]}
              isComplete={defaultValue[item.Id] !== undefined}
              onChange={handleChange}
            />
          </div>
        </ScoreCardRow>
      ))}
    </FormGroup>
  );
};

export default Scorecard;
