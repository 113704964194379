import { useCallback, useEffect, useState } from 'react';
import {
  NavigatableErrorCode,
  useCreateDocumentFolderMutation,
} from '../../../__generated__/graphql';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { routes as mainRoutes } from '../../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useToasts } from '../../../../../context/Toast';
import { managerRoutes } from '../../../manager/routes';
import { Route } from '../../../../../utils/router';
import { isFolderNameValid } from '../common';

const PATH_PARAM_NAME = 'path';
const usePathFromQueryString = (paramName: string) =>
  new URLSearchParams(useLocation().search).get(paramName);

type RouteConfigs = {
  route: Route<any>;
  params: { employeeId?: string };
};

export const createDocumentsPathQueryParams = (folderId: string) => {
  return {
    [PATH_PARAM_NAME]: folderId,
  };
};

const buildPath = (id: string, configs: RouteConfigs) =>
  configs.route
    .withQueryParams(createDocumentsPathQueryParams(id))
    .create(configs.params);

const i18Path = 'documents2.personal';

type Props = {
  currentFolderId: string;
  rootFolderId: string;
  employeeId: string;
  isManager: boolean;
};

export const useCreateFolder = (props: Props) => {
  const { currentFolderId, rootFolderId, employeeId, isManager } = props;
  const t = useNamespacedTranslation(i18Path);
  const { addWarning } = useToasts();

  const getRouteConfigs = useCallback(() => {
    const route = isManager
      ? managerRoutes.documents.route
      : mainRoutes.documentsPersonal.route;
    const params = isManager ? { employeeId } : {};
    return {
      route,
      params,
    };
  }, [isManager, employeeId]);

  const paramPath = usePathFromQueryString(PATH_PARAM_NAME);

  const [folderName, setFolderName] = useState<string | null>(null);
  const [createFolderVisible, setCreateFolderVisible] =
    useState<boolean>(false);

  const errorHandler = useMutationErrorHandler();
  const history = useHistory();

  useEffect(() => {
    if (paramPath === rootFolderId) {
      history.push(buildPath(rootFolderId, getRouteConfigs()));
    }
  }, [getRouteConfigs, history, paramPath, rootFolderId]);

  const [createDocumentFolder, { loading: createFolderInProgress }] =
    useCreateDocumentFolderMutation();

  const handleCreateFolderClose = () => {
    setCreateFolderVisible(false);
  };

  const addDocumentPath = (id: string) => {
    history.push(buildPath(id, getRouteConfigs()));
  };

  const createFolder = useCallback(() => {
    if (!folderName) {
      return;
    }

    if (!isFolderNameValid(folderName)) {
      addWarning(t('errors.invalidFolderName'));
      return;
    }

    createDocumentFolder({
      variables: {
        employeeId,
        input: {
          name: folderName,
          parentFolderId: currentFolderId,
        },
      },
    })
      .then((error) => {
        if (error.data?.document.createFolder.errorCode) {
          if (
            error.data?.document.createFolder.errorCode ===
            NavigatableErrorCode.NameAlreadyExists
          ) {
            addWarning(t('errors.nameAlreadyExists', { name: folderName }));
          }
          return;
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setFolderName(null);
        handleCreateFolderClose();
      });
  }, [
    folderName,
    createDocumentFolder,
    employeeId,
    currentFolderId,
    errorHandler,
    addWarning,
    t,
  ]);

  return {
    folderName,
    setFolderName,
    createFolder,
    createFolderVisible,
    setCreateFolderVisible,
    handleCreateFolderClose,
    createFolderInProgress,
    addDocumentPath,
    rootFolderId,
  };
};
