import React from 'react';
import ServerError from '../../../../../../components/ServerError';
import { EmployeeDetailsPopoverContent } from './EmployeeDetailsPopoverContent';
import { useEmployeeDetailsQuery } from '../../../../__generated__/graphql';
import Skeleton from 'react-loading-skeleton';
import { ListGroup } from 'react-bootstrap';
import { mapEmployeeDetails } from './mappings';

type Props = {
  employeeId: string;
};

export const EmployeeDetailsPopoverContentConnected: React.FC<Props> = ({
  employeeId,
}) => {
  const { data, error, loading } = useEmployeeDetailsQuery({
    variables: {
      id: employeeId,
    },
  });

  if (!data?.manager?.employee || loading) {
    return <EmployeeDetailsPopoverLoading />;
  }

  if (error) {
    return <ServerError />;
  }
  const employee = mapEmployeeDetails(data.manager.employee);

  return <EmployeeDetailsPopoverContent employee={employee} />;
};

const EmployeeDetailsPopoverLoading: React.FC = () => {
  return (
    <ListGroup className="d-flex gap-3">
      <Skeleton width={380} height={25} className=" mx-3 mt-3" />
      <Skeleton width={380} height={25} className=" mx-3" />
      <Skeleton width={380} height={25} className=" mx-3" />
      <Skeleton width={380} height={25} className=" mx-3" />
      <Skeleton width={380} height={25} className=" mx-3" />
      <Skeleton width={380} height={25} className=" mx-3 mb-3" />
    </ListGroup>
  );
};
