import React from 'react';
import { ABSENT_STATUS_FILTER, FilterProps } from './types';
import { useTranslation } from 'react-i18next';
import TeamCalendarSingleFilter from './TeamCalendarSingleFilter';

const AbsentStatusFilter: React.FC<FilterProps> = ({ options, filterData }) => {
  const { t } = useTranslation();

  return (
    <TeamCalendarSingleFilter
      filterData={filterData}
      options={options}
      filterKey={ABSENT_STATUS_FILTER}
      placeholder={t(
        'employeePage.teamCalendar.filters.absentStatus.placeholder',
      )}
    />
  );
};

export default AbsentStatusFilter;
