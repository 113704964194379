import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectableRowGroup from '../../SelectableRowGroup/SelectableRowGroup';
import { SelectableRowType } from '../../SelectableRowGroup/types';

type Props = {
  rows: SelectableRowType[];
  fieldName: string;
  onChange: (value: { [key: string]: SelectableRowType }) => void;
  defaultActiveButton?: string;
};

export const SelectableRowGroupForm: React.FC<Props> = ({
  rows,
  fieldName,
  onChange,
  defaultActiveButton = '',
}) => {
  const { control, errors } = useFormContext();

  const [activeButton, setActiveButton] = React.useState(defaultActiveButton);
  const handleChange = (row: SelectableRowType) => {
    const category = activeButton !== row.value ? row.value : '';
    setActiveButton(category);
    onChange({ [fieldName]: row });
  };

  return (
    <Controller
      as={
        <SelectableRowGroup
          size="sm"
          list={rows}
          isValid={!errors[fieldName]}
          activeButton={activeButton}
          onClick={handleChange}
        />
      }
      name={fieldName}
      control={control}
    />
  );
};
