import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useIsMdScreen } from '../../../../../../hooks/useIsScreenSizeLargerThan';
import { Theme } from '../../../../../../theme';
import { Nullable } from '../../../../../../utils/nullable';
import { CalendarDay } from '../../../../components/AbsenceCalendar/types';
import Avatar from '../../../../../../atomic/molecules/avatar/Avatar';
import { getEmployeeInitialsFromName } from '../../../../utils/employeeInitials';
import { AbsenceColleague } from '../Content';
import TeamCalendarDayCell from './TeamCalendarDayCell';

type ColleagueRowParams = {
  colleague: AbsenceColleague;
  onHover: (
    day: Nullable<CalendarDay>,
    colleague: Nullable<AbsenceColleague>,
  ) => void;
  hoveredDay?: Nullable<CalendarDay>;
  hoveredColleague?: Nullable<AbsenceColleague>;
};

const StickyHeader = styled.th<{ highlighted: boolean }>(({ highlighted }) => ({
  position: 'sticky',
  left: 0,
  backgroundColor: `${
    highlighted ? Theme.color.gray5 : Theme.color.white
  } !important`,
  zIndex: 999,
}));

const HighlightedRow = styled.tr<{ highlighted: boolean }>(({ highlighted }) =>
  highlighted
    ? {
        color: Theme.color.primary,
        backgroundColor: `${Theme.color.gray5} !important`,
      }
    : {},
);

const ColleagueRow: React.FC<ColleagueRowParams> = ({
  colleague,
  onHover,
  hoveredDay,
  hoveredColleague,
}) => {
  const isMdScreen = useIsMdScreen();
  const isHovered = hoveredColleague?.id === colleague.id;
  const colleagueInitials: string = useMemo(
    () => getEmployeeInitialsFromName(colleague.name),
    [colleague.name],
  );

  return (
    <HighlightedRow highlighted={isHovered}>
      <StickyHeader className="fs-4" highlighted={isHovered}>
        <div className="d-flex align-items-center gap-3">
          <Avatar
            containerClassName="d-none d-md-inline-block"
            initials={colleagueInitials}
            avatarUrl={colleague.avatarUrl}
            size="xs"
          />
          <div className="fw-normal">
            <div>
              {isMdScreen ? colleague.name : colleague.name.split(' ')[0]}
            </div>
          </div>
        </div>
      </StickyHeader>
      {colleague.days.map((day) => (
        <TeamCalendarDayCell
          day={day}
          key={colleague.name + day.day.date()}
          onHover={onHover}
          hoveredDay={hoveredDay}
          hoveredColleague={hoveredColleague}
          colleague={colleague}
        />
      ))}
    </HighlightedRow>
  );
};
export default ColleagueRow;
