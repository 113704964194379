import React from 'react';
import { useHistory } from 'react-router-dom';
import ClearFiltersLink from '../ClearFiltersLink';

type Props = {
  route: string;
};

const ClearFilters: React.FC<Props> = ({ route }) => {
  const history = useHistory();

  const handleClearFilters = () => {
    history.push(route);
  };
  return <ClearFiltersLink onClick={handleClearFilters} />;
};

export default ClearFilters;
