import React, { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { Link } from '../../../../../../Router';
import { routes } from '../../../../routes';

import { TableContent } from '../../../../manager/components/TableContent';

import FormattedDate from '../../../../../../components/date/FormattedDate';
import { EmptyPerformanceReview } from '../../Overview/EmptyPerformanceReview';
import {
  Feedback,
  FeedbackStatus,
  FeedbackStatusTypes,
  FeedbackStep,
} from '../types';
import { ProgressBar } from 'react-bootstrap';
import Hint from '../../../../../../components/hint';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import {
  EmployeeFeedbackState,
  FeedbackCycleTimelineTypes,
} from '../../../../__generated__/graphql';

export type FeedbackItem = {
  id: string;
  cycleName: string;
  reviewer?: string;
  reviewee?: string;
  status: FeedbackStatus;
  isEnded: boolean;
  dueDate: Date | null;
  steps: FeedbackStep[];
  timelineType: FeedbackCycleTimelineTypes;
  employeeFeedbackState: EmployeeFeedbackState | undefined;
  isPeerReviewer: boolean;
};

type Props = {
  feedbacks: Feedback[];
  isReviewer: boolean;
};

export const PerformanceReviewTable: React.FC<Props> = ({
  feedbacks,
  isReviewer,
}) => {
  const i18Path = 'performanceReview.table';
  const t = useNamespacedTranslation(i18Path);

  const columns: Column<FeedbackItem>[] = useMemo(() => {
    const columnsWithNull: (Column<FeedbackItem> | null)[] = [
      {
        Header: t('header.cycle'),
        accessor: 'cycleName',
        Cell: ({ value, row }: { value: string; row: Row<FeedbackItem> }) => {
          return (
            <Link
              to={getFeedbackLink(row.original, isReviewer)}
              feedbackId={row.original.id}>
              {value}
            </Link>
          );
        },
      },

      isReviewer
        ? {
            Header: t('header.reviewee'),
            accessor: 'reviewee',
            Cell: ({ value }) => <div>{value}</div>,
          }
        : {
            Header: t('header.reviewer'),
            accessor: 'reviewer',
            Cell: ({ value }) => <div>{value}</div>,
          },
      {
        Header: t('header.dueDate'),
        accessor: 'dueDate',
        Cell: ({ value }) =>
          value ? (
            <div>
              <FormattedDate day={value} format="short" />
            </div>
          ) : (
            '--'
          ),
      },
      {
        Header: t('header.status'),
        accessor: 'status',
        Cell: ({ value }) => (
          <div className="d-flex align-items-center">
            <span> {t(`status.${value}`)} </span>
          </div>
        ),
      },
      {
        Header: t('header.steps'),
        accessor: 'steps',
        Cell: ({
          value,
          row,
        }: {
          value: FeedbackStep[];
          row: Row<FeedbackItem>;
        }) => {
          return (
            <div className="row">
              {value.map((value) => (
                <div className="col-4" key={value.label}>
                  <Hint
                    id={row.original.id}
                    text={t(`hintText.${value.label}`)}>
                    <ProgressBar
                      className="progress-sm "
                      max={100}
                      now={0}
                      style={{
                        backgroundColor: value.value ? 'green' : '',
                      }}
                    />
                  </Hint>
                </div>
              ))}
            </div>
          );
        },
      },
    ];
    return columnsWithNull.filter((x): x is Column<FeedbackItem> => x !== null);
  }, [isReviewer, t]);

  if (!feedbacks.length) {
    return <EmptyPerformanceReview />;
  }

  return (
    <>
      <TableContent
        columns={columns}
        data={feedbacks}
        getRowProps={() => ({ role: 'button' })}
      />
    </>
  );
};

function getFeedbackLink(feedback: FeedbackItem, isReviewer: boolean) {
  return isReviewer
    ? getReviewerFeedbackLink(feedback)
    : getEmployeeFeedbackLink(feedback);
}

function getReviewerFeedbackLink(feedback: FeedbackItem) {
  if (feedback.isPeerReviewer) {
    return routes.performanceReviewerEvaluate.route;
  }

  switch (feedback.status) {
    case FeedbackStatusTypes.NotStarted:
    case FeedbackStatusTypes.InProgress:
      return routes.performanceReviewerEvaluate.route;
    case FeedbackStatusTypes.Meeting:
      return routes.performanceReviewerMeeting.route;
    case FeedbackStatusTypes.Completed:
      return routes.performanceReviewerPreviousReviews.route;
  }
}

function getEmployeeFeedbackLink(feedback: FeedbackItem) {
  switch (feedback.status) {
    case FeedbackStatusTypes.NotStarted:
    case FeedbackStatusTypes.InProgress:
    case FeedbackStatusTypes.Meeting:
      return routes.selfPerformanceEvaluation.route;
    case FeedbackStatusTypes.Completed:
      return routes.pastPerformanceReview.route;
  }
}
