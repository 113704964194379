import './MatchingBadge.css';
import { Badge } from 'react-bootstrap';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import React from 'react';
import classNames from 'classnames';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';

type MatchingBadgeProps = {
  isMatched: boolean;
};

const MatchingBadge: React.FC<MatchingBadgeProps> = ({ isMatched }) => {
  const t = useNamespacedTranslation(
    'recruitment.candidate.detailsPage.flairAI',
  );

  return (
    <div
      className={classNames({
        'matching-badge-matched': isMatched,
        'matching-badge-not-matched': !isMatched,
      })}>
      <Badge className={classNames('d-flex', 'py-2', 'align-items-center')}>
        <FlairIcon
          icon={isMatched ? 'checkmark-outline' : 'close-outline'}
          className="me-2 badge-icons"
          size="sm"
        />
        {isMatched ? t('match') : t('noMatch')}
      </Badge>
    </div>
  );
};

export default MatchingBadge;
