import React from 'react';
import { useTranslation } from 'react-i18next';

// todo: move to ErrorHandling folder in next PR
const ServerError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h1 className="display-4 mb-3">{t('errors.500.title')}</h1>
      <p className="text-muted mb-4">{t('errors.500.description')}</p>
    </div>
  );
};

export default ServerError;
