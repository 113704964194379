import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHourlyAbsenceAmountFormatter } from '../../hooks/useHourlyAbsenceAmountFormatter';
import { AbsenceType } from '../../__generated__/graphql';

export const useAbsenceAmountFormatter = () => {
  const { t } = useTranslation();
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  return useCallback(
    ({ amount = 0, type }: { amount?: number; type: AbsenceType }) => {
      switch (type) {
        case AbsenceType.Daily:
          return t('requestAbsence.requestingAmount.daily', { count: amount });
        case AbsenceType.Hourly:
          return t('requestAbsence.requestingAmount.hourly', {
            amount: hourlyFormatter(amount),
          });
      }

      return null;
    },
    [t, hourlyFormatter],
  );
};
