import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TimeSheetQuery } from '../../__generated__/graphql';
import FormattedDate from '../../../../components/date/FormattedDate';
import { Link } from '../../../../Router';
import { routes } from '../../routes';
import ListGroupItem from '../../components/ListGroup/Item';

type Employee = TimeSheetQuery['timeSheet']['employee'];

type Params = {
  employee: Employee;
};

const Label: React.FC = ({ children }) => <h5 className="mb-0">{children}</h5>;

const Value: React.FC = ({ children }) => (
  <span className="small">{children}</span>
);

export const EmployeeInfo: React.FC<Params> = ({ employee }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <ListGroup variant="flush" className="my-n3">
          <ListGroupItem
            label={
              <Label>{t('timeTracking.timeSheet.employeeInfo.name')}</Label>
            }
            value={
              <Value>
                <Link to={routes.employeePage.route} employeeId={employee.Id}>
                  {employee.Name}
                </Link>
              </Value>
            }
          />
          {employee.department && (
            <ListGroupItem
              label={
                <Label>
                  {t('timeTracking.timeSheet.employeeInfo.department')}
                </Label>
              }
              value={<Value>{employee.department.Name}</Value>}
            />
          )}
          {employee.location && (
            <ListGroupItem
              label={
                <Label>
                  {t('timeTracking.timeSheet.employeeInfo.location')}
                </Label>
              }
              value={<Value>{employee.location.Name}</Value>}
            />
          )}
          {employee.flair__Position__c && (
            <ListGroupItem
              label={
                <Label>
                  {t('timeTracking.timeSheet.employeeInfo.position')}
                </Label>
              }
              value={<Value>{employee.flair__Position__c}</Value>}
            />
          )}
          {employee.flair__Company_Email__c && (
            <ListGroupItem
              label={
                <Label>
                  {t('timeTracking.timeSheet.employeeInfo.companyEmail')}
                </Label>
              }
              value={
                <Value>
                  <a href={`mailto:${employee.flair__Company_Email__c}`}>
                    {employee.flair__Company_Email__c}
                  </a>
                </Value>
              }
            />
          )}
          {employee.flair__Start_Date__c && (
            <ListGroupItem
              label={
                <Label>{t('timeTracking.timeSheet.employeeInfo.joined')}</Label>
              }
              value={
                <Value>
                  <FormattedDate
                    day={employee.flair__Start_Date__c}
                    format="short"
                  />
                </Value>
              }
            />
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
