import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import RequestAbsenceButton from '../../../../components/RequestAbsenceButton';
import { useProfileWidgetAbsenceCategoriesQuery } from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import FlairButton from '../../../../../../atomic/atoms/FlairButton';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../routes';

const i18Path = 'dashboard.profileWidget';

const RequestAbsence: React.FC = () => {
  const history = useHistory();
  const t = useNamespacedTranslation(i18Path);
  const { data, loading, error } = useProfileWidgetAbsenceCategoriesQuery();

  if (error) {
    return <ServerError />;
  }

  const handleOnSuccess = () => history.push(routes.absences.route.create({}));

  const button = (
    <FlairButton label={t('requestAbsence')} icon="absence-outline" />
  );

  return (
    <RequestAbsenceButton
      categoryId={null}
      categories={data?.me.absenceCategories ?? []}
      variant="outline-primary"
      size="sm"
      button={button}
      isProcessing={loading}
      onSuccess={handleOnSuccess}
    />
  );
};

export default RequestAbsence;
