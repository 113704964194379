import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../theme';
import { AutoSaveIndicator } from './AutoSaveIndicator';
import InputLabel from './InputLabel';

type Params = {
  label: string;
  completed?: boolean;
  required?: boolean;
  optional?: boolean;
  loading?: boolean;
  hint?: React.ReactNode;
};

const AutoSaveInputLabel: React.FC<Params> = ({
  label,
  completed,
  optional,
  required,
  loading,
  hint,
}) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      <>
        <InputLabel label={label} required={required} hint={hint} />
        {optional && (
          <Optional className="ms-2">{t('form.general.optional')}</Optional>
        )}
        <div className="d-inline-block ms-2">
          <AutoSaveIndicator loading={loading} completed={completed} />
        </div>
      </>
    );
  }, [completed, hint, label, loading, required, optional, t]);
};

export default AutoSaveInputLabel;

const Optional = styled('span')({
  color: Theme.color.gray3,
});
