import React from 'react';
import FlairIcon, {
  FlairIconName,
} from '../../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../../theme';
import { NotificationItem } from '../../types';
import styled from '@emotion/styled';

import Avatar from '../../../../../../atomic/molecules/avatar/Avatar';

const NotificationFlairIconVariant = styled(FlairIcon)({
  width: '1.25rem',
  height: '1.25rem',
});
const NotificationFlairIconContainer = styled.div({
  width: '2.25rem',
  height: '2.25rem',
  marginRight: '0.63rem!important', // Can't use boostraps class beacause 0.63rem is between me-2 and me-3
});

const NotificationAvatar = styled.div({
  marginRight: '0.63rem!important',
});

const NotificationIcon: React.FC<{
  notification: NotificationItem;
}> = ({ notification }) => {
  const { icon, employeeName } = notification.data;
  if (employeeName) {
    const { employeeFirstName, employeeLastName, avatarUrl } =
      notification.data;
    if (!employeeFirstName || !employeeLastName) {
      return null;
    }
    const initials = `${employeeFirstName.charAt(0)}${employeeLastName.charAt(
      0,
    )}`.toUpperCase();
    return (
      <NotificationAvatar className="flex-shrink-0">
        <Avatar initials={initials} size="notification" avatarUrl={avatarUrl} />
      </NotificationAvatar>
    );
  } else {
    switch (icon) {
      case 'checkmark-outline':
        return (
          <NotificationFlairIcon
            icon={icon}
            color={Theme.color.blue1}
            bgColor={Theme.color.teal2}
          />
        );
      case 'close-outline':
        return (
          <NotificationFlairIcon
            icon={icon}
            color={Theme.color.blue1}
            bgColor={Theme.color.red4}
          />
        );
      case 'briefcase-outline':
      case 'checkbox-outline':
      case 'stopwatch-outline':
      case 'umbrella-outline':
        return (
          <NotificationFlairIcon
            icon={icon}
            color={Theme.color.white}
            bgColor={Theme.color.gray3}
          />
        );
      default:
        return (
          <NotificationFlairIcon
            icon="megaphone-outline"
            color={Theme.color.white}
            bgColor={Theme.color.gray3}
          />
        );
    }
  }
};

type NotificationFlairIconProps = {
  icon: FlairIconName;
  color: string;
  bgColor: string;
};
const NotificationFlairIcon: React.FC<NotificationFlairIconProps> = ({
  icon,
  color,
  bgColor,
}) => (
  <NotificationFlairIconContainer
    className="rounded-circle d-flex justify-content-center align-items-center flex-shrink-0"
    style={{
      backgroundColor: bgColor,
    }}>
    <NotificationFlairIconVariant
      style={{ backgroundColor: bgColor }}
      color={color}
      size="1x"
      icon={icon}></NotificationFlairIconVariant>
  </NotificationFlairIconContainer>
);

export default NotificationIcon;
