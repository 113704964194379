import React from 'react';
import { StepFooter } from './StepFooter';
import { StandardFooter } from './StandardFooter';

type TFooterProps = {
  isExpanded: boolean;
  isSubmitting: boolean;
};

export const Footer = ({ isExpanded = false, isSubmitting }: TFooterProps) => {
  return isExpanded ? (
    <StandardFooter isLoading={isSubmitting} />
  ) : (
    <StepFooter isLoading={isSubmitting} />
  );
};
