import React from 'react';
import ServerError from '../../../../components/ServerError';
import { useOrganizationInfoQuery } from '../../__generated__/graphql';
import { OrganizationInfo } from '../../../../components/OrganizationInfo';
import { Col, Container, Row } from 'react-bootstrap';

type Props = {};

export const OrganizationInfoPage: React.FC<Props> = () => {
  const { data, loading, error } = useOrganizationInfoQuery({});

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md="6">
          <OrganizationInfo
            salesforceUserId={data.organizationInfo.salesforceUserId}
            flairHubPermissionSet={data.organizationInfo.flairHubPermissionSet}
          />
        </Col>
      </Row>
    </Container>
  );
};
const Loading: React.FC = () => (
  <div className="text-center">
    <div className="spinner-border" role="status"></div>
  </div>
);
