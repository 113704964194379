import React, { useCallback } from 'react';
import { Card, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/button';
import { routes } from '../../../routes';
import {
  EmployeeFeedbackState,
  useHomePerformaceReviewCardQuery,
} from '../../../__generated__/graphql';
import { mapFeedbacks, mapMeetings, onGoingFeedback } from './helpers';
import { MeetingInfo } from './types';

import { formattedDateTime } from '../../../../../components/datetime/FormattedDateTime';
import { useToasts } from '../../../../../context/Toast';
import { useUserInfo } from '../../../context/UserInfo';
import {
  generateFeedbackMeetingIcsLink,
  generateGoogleLink,
} from '../../Absences/ICalendar/ICalendarUtils';
import CardBody from '../components/CardBody';
import { CardTitle } from '../Heading';

const CardSubtitle: React.FC = ({ children }) => (
  <span className="small text-dark">{children}</span>
);

type NotificationCardProps = {
  title: string;
  text: string;
  buttonLabel: string;
  onButtonClick: () => void;
};

export const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  text,
  buttonLabel,
  onButtonClick,
}) => (
  <Card className="rounded-4">
    <CardBody>
      <Row className="align-items-center">
        <Col>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{text}</CardSubtitle>
        </Col>
        <Col className="col-auto">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={onButtonClick}
            label={buttonLabel}
          />
        </Col>
      </Row>
    </CardBody>
  </Card>
);

const ReviewNotificationCard: React.FC<{ onButtonClick: () => void }> = ({
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <NotificationCard
      title={t(
        'performanceReview.nextPerformanceReview.employeeNotificationTitle',
      )}
      text={t(
        'performanceReview.nextPerformanceReview.employeeNotificationText',
      )}
      buttonLabel={t(
        'performanceReview.nextPerformanceReview.employeeNotificationButton',
      )}
      onButtonClick={onButtonClick}
    />
  );
};

const ReviewerNotificationCard: React.FC<{ onButtonClick: () => void }> = ({
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <NotificationCard
      title={t(
        'performanceReview.nextPerformanceReview.reviewerNotificationTitle',
      )}
      text={t(
        'performanceReview.nextPerformanceReview.reviewerNotificationText',
      )}
      buttonLabel={t(
        'performanceReview.nextPerformanceReview.reviewerNotificationButton',
      )}
      onButtonClick={onButtonClick}
    />
  );
};

export const UpcomingMeetingCard: React.FC<
  MeetingInfo & { calendarToken: string }
> = ({ calendarToken, cycleName, meetingId, startDate }) => {
  const { t, i18n } = useTranslation();
  const formatDatetime = formattedDateTime(t);
  const { addSuccess } = useToasts();

  if (!startDate) {
    return <></>;
  }

  const handleGoogleCalendar = () => {
    window.location.href = generateGoogleLink(
      generateFeedbackMeetingIcsLink(calendarToken, meetingId, i18n.language),
    );
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      generateFeedbackMeetingIcsLink(calendarToken, meetingId, i18n.language),
    );
    addSuccess(t('absences.icalendar.copiedIcsUrl'));
  };

  const handleDownloadIcs = () => {
    window.location.href = generateFeedbackMeetingIcsLink(
      calendarToken,
      meetingId,
      i18n.language,
    );
  };

  return (
    <Card className="rounded-4">
      <CardBody>
        <Row className="align-items-center">
          <Col xl={12}>
            <CardTitle>
              {t('performanceReview.nextPerformanceReview.nextMeeting')}
            </CardTitle>
            <CardSubtitle>
              <Trans
                t={t}
                i18nKey="performanceReview.nextPerformanceReview.nextMeetingText"
                values={{
                  dateTime: formatDatetime({
                    dateTime: startDate,
                    format: 'short',
                  }),
                  cycleName,
                  interpolation: { escapeValue: false },
                }}
                components={[<strong />]}
              />
            </CardSubtitle>
          </Col>
          <Col xl={12} className="col-auto mt-2">
            <DropdownButton
              size="sm"
              variant="outline-primary"
              title={t(
                'performanceReview.nextPerformanceReview.nextMeetingButton',
              )}>
              <Dropdown.Item onClick={handleGoogleCalendar}>
                {t('absences.icalendar.addToGoogleCalendar')}
              </Dropdown.Item>
              <Dropdown.Item onClick={handleCopyUrl}>
                {t('absences.icalendar.copyToClipboard')}
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadIcs}>
                {t('absences.icalendar.downloadIcs')}
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const PerformanceReviewCard: React.FC = () => {
  const history = useHistory();
  const { calendarToken } = useUserInfo();
  const { data, loading, error } = useHomePerformaceReviewCardQuery({
    variables: {
      filter: {
        state: EmployeeFeedbackState.Draft,
      },
    },
  });

  const handleGoToPerformanceReviews = useCallback(() => {
    history.push(routes.performanceReview.route.create({}));
  }, [history]);

  const handleGoToReviewerPage = useCallback(() => {
    history.push(routes.performanceReviewsForReviewer.route.create({}));
  }, [history]);

  if (error || loading || !data) {
    return null;
  }

  const waitingForReview = data.me.feedbacks.some(
    (feedback) => feedback.flair__State__c === EmployeeFeedbackState.Draft,
  );

  const needToReview = data.me.feedbacksAsReviewer.some(
    (feedback) => feedback.flair__State__c === EmployeeFeedbackState.Draft,
  );

  const upcomingMeetings = mapMeetings([
    ...mapFeedbacks(data.me.feedbacks).filter(onGoingFeedback),
    ...mapFeedbacks(data.me.feedbacksAsReviewer).filter(onGoingFeedback),
  ]);

  return (
    <>
      {waitingForReview && (
        <ReviewNotificationCard onButtonClick={handleGoToPerformanceReviews} />
      )}

      {needToReview && (
        <ReviewerNotificationCard onButtonClick={handleGoToReviewerPage} />
      )}

      {calendarToken &&
        upcomingMeetings.map((meeting) => (
          <UpcomingMeetingCard
            {...meeting}
            calendarToken={calendarToken}
            key={meeting.meetingId}
          />
        ))}
    </>
  );
};
