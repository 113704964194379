import styled from '@emotion/styled';
import React, { ElementType, useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  eventKey: string;
  as?: ElementType;
};

const Header = styled.h2({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
});

export const SectionHeaderToggle: React.FC<Props> = ({
  children,
  eventKey,
  as = 'h2',
}) => {
  const currentEventKey = useContext(AccordionContext);
  const onClick = useAccordionButton(eventKey);
  const isActive = currentEventKey === eventKey;

  return (
    <Header as={as} onClick={onClick}>
      <span>{children}</span>

      {isActive ? (
        <FlairIcon icon="chevron-up-outline" />
      ) : (
        <FlairIcon icon="chevron-down-outline" />
      )}
    </Header>
  );
};
