import { StoredIdType } from '../components/AutoBreaks/breakReminders/useReminderToShow';
import { TeamCalendarFiltersType } from '../pages/Absences/TeamCalendar/Table/TeamCalendarFilters/types';
import { CertificateFilter } from '../pages/Certificates/types';
import { ExpenseFilter } from '../pages/Expenses/types';
import {
  WorkflowFilter,
  WorkflowFilterForMe,
  WorkflowItemFilterForMe,
  WorkflowItemFilterForOthers,
} from '../pages/Workflows/Filters/types';
import { WorkflowViewMode } from '../pages/Workflows/useWorkflowsViewMode';
import { CandidatesFiltersType } from '../recruitment/JobPage/CandidatesList/CandidatesListFilter/types';
import { useLocalStorage } from './useLocalStorage';
import { ManagerReportsStoreInfo } from './useManagerReportsMode';
import { EvaluationsFilterParams } from '../recruitment/JobPage/JobEvaluations/types';
import { ProjectViewType, ProjectsFilter } from '../pages/Projects/types';
import { FeedbacksFilter } from '../pages/PerformanceReview/Reviews/types';
import { AbsenceRequestFilterType } from '../manager/Absences/AbsenceRequests/types';
import {
  CorporateDocumentFilter,
  PersonalDocumentFilter,
} from '../pages/Documents2/types';
import { TimeSheetsFilter } from '../manager/TimeSheetsControlling/Filters/types';
import { TimeSheetColumnSetting } from '../manager/TimeSheetsControlling';
import { InventoryFilter } from '../pages/MyData/Inventory/Filters/types';
import { TicketsFilter } from '../pages/Support/Tickets/Filters/types';
import { ProjectsTimeSheetsFilter } from '../pages/Projects/ProjectsControllingTimeSheets/Filters/types';
import { EmployeeTimeSheetsFilter } from '../pages/TimeTracking/EmployeeTimeSheets/Filter/types';

export type FlairLocalStorage = {
  // global
  MANAGER_MODE: boolean;
  manager_reports_mode: ManagerReportsStoreInfo;
  // absences
  absence_requests_filter: AbsenceRequestFilterType;
  // expenses
  manager_expense_filter: ExpenseFilter;
  my_expense_filter: ExpenseFilter;
  // skills
  skillsMatrix_employeeIds: string[] | null;
  skillsMatrix_skillIds: string[] | null;
  // certificates
  my_certificate_filter: CertificateFilter;
  employee_certificate_filter: CertificateFilter;
  project_view_type: ProjectViewType;
  // workflows
  workflows_view_mode: WorkflowViewMode;
  workflow_items_filter_for_all: WorkflowItemFilterForOthers;
  workflow_items_filter_for_me: WorkflowItemFilterForMe;
  workflow_items_filter_for_others: WorkflowItemFilterForOthers;
  workflow_filter_for_all: WorkflowFilter;
  workflow_filter_for_me: WorkflowFilterForMe;
  workflow_filter_for_others: WorkflowFilter;
  personal_document_filter: PersonalDocumentFilter;
  employee_document_filter: PersonalDocumentFilter;
  corporate_document_filter: CorporateDocumentFilter;
  inventory_filter: InventoryFilter;
  employee_inventory_filter: InventoryFilter;
  tickets_filter: TicketsFilter;
  manager_tickets_filter: TicketsFilter;
  // break remindes
  closed_break_reminders: StoredIdType[];
  // team calendar
  TEAM_CALENDAR_FILTERS: TeamCalendarFiltersType;
  // candidates
  CANDIDATES_LIST_FILTERS: CandidatesFiltersType;
  feedback_filter: FeedbacksFilter;
  //Evaluations
  EMPLOYEE_EVALUATIONS_LIST_FILTERS: EvaluationsFilterParams;
  EVALUATIONS_LIST_FILTERS: EvaluationsFilterParams;
  // TimeSheets
  timeSheets_controlling_pending: TimeSheetsFilter;
  employee_timeSheets: EmployeeTimeSheetsFilter;

  timeSheets_columns: TimeSheetColumnSetting[];
  projects_timeSheets_controlling: ProjectsTimeSheetsFilter;
  employee_timeSheets_columns: TimeSheetColumnSetting[];
  // projects
  projectsTracking: ProjectsFilter;
  projectsOverview: ProjectsFilter;

  // There are also some dynamic keys:
  //'personal_' + category.name + '_docs_sorting': DocumentSortParams
};

export type FlairLocalStorageKey = keyof FlairLocalStorage;

export const useFlairLocalStorage = <K extends FlairLocalStorageKey>(
  key: K,
  initialValue: FlairLocalStorage[K],
) => {
  /* eslint-disable-next-line no-restricted-syntax */
  return useLocalStorage(key, initialValue);
};
