import {
  isTableRowTimeSheet,
  ProjectsTimeSheet,
  ProjectsTimeSheetEmployee,
  ProjectsTimeSheetFields,
  ProjectsTimeSheetPeriodInfo,
  ProjectsTimeSheetProjectInfo,
  projectTimeSheetTrackFieldsUndefined,
  TableDayBadges,
  TableDaySubRow,
  TableDaySubRowLoading,
  TableDaySubRowLoadingError,
  TableProjectSubRow,
  TableRow,
  TableRowHeaderProject,
  TableRowState,
  ViewBy,
} from '../types';

import {
  AscDescEnum,
  ProjectsTimeSheetDaySubRowsFragment,
  ProjectsTimeSheetDaySubRowTimeSheetDayFragment,
  ProjectsTimeSheetDaySubRowTrackedTimeDayFragment,
  TimeSheetSortFieldEnum,
  TimeSheetSortInput,
  TimeSheetStatus,
} from '../../../../__generated__/graphql';
import { EmptyProjectName } from '../../helpers';

export const mapToTableRows = (
  inputItems: ProjectsTimeSheet[],
  viewBy: ViewBy,
): TableRow[] => {
  if (viewBy === 'project') {
    const projectsLookup = new Map<string, ProjectsTimeSheetProjectInfo>();
    const timeSheets: ProjectsTimeSheet[] = inputItems;

    // Create an empty Map to hold the result
    const resultMap = new Map<
      ProjectsTimeSheetProjectInfo['id'],
      Map<ProjectsTimeSheetPeriodInfo['id'], ProjectsTimeSheet[]>
    >();

    // Loop through the TimeSheet objects
    for (const timeSheet of timeSheets) {
      for (const project of timeSheet.projects) {
        const periodInfo = timeSheet.period;
        // Check if the Project is already in the resultMap
        if (!resultMap.has(project.id)) {
          projectsLookup.set(project.id, project);
          // If not, create a new Map for the Project with an array of TimeSheet objects
          resultMap.set(
            project.id,
            new Map<ProjectsTimeSheetPeriodInfo['id'], ProjectsTimeSheet[]>(),
          );
        }

        const projectMap = resultMap.get(project.id)!;

        // Check if the ProjectsTimeSheetPeriodInfo is already in the projectMap
        if (!projectMap.has(periodInfo.id)) {
          // If not, create a new array for that ProjectsTimeSheetPeriodInfo
          projectMap.set(periodInfo.id, []);
        }

        // Add the TimeSheet to the array in the inner map
        projectMap.get(periodInfo.id)?.push(timeSheet);
      }
    }
    const tableRows: TableRow[] = [];
    for (const [projectId, periodWithTimeSheet] of resultMap.entries()) {
      const projectInfo = projectsLookup.get(projectId)!;
      tableRows.push(createProjectHeader(projectInfo));
      for (const [
        timeSheetPeriodId,
        periodTimeSheets,
      ] of periodWithTimeSheet.entries()) {
        // Create timSheets header (like Week 38)
        tableRows.push(
          createHeader(
            'project',
            periodTimeSheets[0],
            `${projectId}-${timeSheetPeriodId}`,
          ),
        );
        for (const timeSheet of periodTimeSheets) {
          tableRows.push(
            createRow(
              timeSheetPeriodId,
              'project',
              timeSheet,
              projectInfo,
              `${projectId}-${timeSheetPeriodId}-${timeSheet.id}`,
            ),
          );
        }
      }
    }
    return tableRows;
  } else {
    const timeSheetsToProcess = inputItems;
    const employeesLookupMapping = new Map<string, ProjectsTimeSheetEmployee>();

    const resultMap: Map<ProjectsTimeSheetEmployee['id'], ProjectsTimeSheet[]> =
      new Map();

    for (const timeSheet of timeSheetsToProcess) {
      const employeeId = timeSheet.employee.id;

      // Check if the EmployeeId is already in the resultMap
      if (!resultMap.has(employeeId)) {
        employeesLookupMapping.set(employeeId, timeSheet.employee);
        // If not, create a new array for the EmployeeId
        resultMap.set(employeeId, []);
      }

      const employeeList = resultMap.get(employeeId);
      if (employeeList) {
        // Add the TimeSheet to the array in the map
        employeeList.push(timeSheet);
      }
    }

    const tableRows: TableRow[] = [];
    // Map items to table rows and create headers
    for (const [employeeId, timeSheets] of resultMap.entries()) {
      const employeeInfo = employeesLookupMapping.get(employeeId)!;
      tableRows.push(createHeader('employee', timeSheets[0], employeeInfo.id));
      // Create timSheets rows
      for (const timeSheet of timeSheets) {
        tableRows.push(
          createRow(
            employeeId,
            viewBy,
            timeSheet,
            undefined,
            `${employeeId}-${timeSheet.id}`,
          ),
        );
      }
    }
    return tableRows;
  }
};

type TimeSheetDaySubRowProjectTimeEntry =
  ProjectsTimeSheetDaySubRowsFragment['projectsTimeEntries'][0];

export const mapToDaySubRows = (
  timeSheet: ProjectsTimeSheetDaySubRowsFragment,
  projectId: string | undefined,
  showDayProjectEntries: boolean,
): TableDaySubRow[] => {
  const timeSheetDaysMap = timeSheet.timeSheetDays.reduce(
    (map, timeSheetDay) => map.set(timeSheetDay.flair__Day__c, timeSheetDay),
    new Map<string, ProjectsTimeSheetDaySubRowTimeSheetDayFragment>(),
  );
  const timeSheetProjectEntriesMap = timeSheet.projectsTimeEntries.reduce(
    (map, projectEntry) => {
      const dateKey = projectEntry.flair__Date__c;
      if (!map.has(dateKey)) {
        map.set(dateKey, []);
      }
      // Push the current projectEntry to the array associated with the dateKey.
      map.get(dateKey)?.push(projectEntry);

      return map;
    },
    new Map<string, TimeSheetDaySubRowProjectTimeEntry[]>(),
  );

  const daysSubRows = timeSheet.trackedTime.days.map((trackedTimeDay) => {
    return mapDaySubRow(
      timeSheet.Id,
      trackedTimeDay,
      timeSheetProjectEntriesMap.get(trackedTimeDay.day) ?? [],
      projectId,
      timeSheetDaysMap.get(trackedTimeDay.day),
      showDayProjectEntries,
    );
  });
  return daysSubRows;
};

export const updateTableRows = (
  rows: TableRow[],
  approvingIds: string[],
  selectedRowIds: string[],
): TableRow[] => {
  const approvingIdsSet = new Set(approvingIds);
  const selectingIdsSet = new Set(selectedRowIds);

  return rows.map((row) => {
    const tableRowToRet: TableRow = {
      ...row,
      ...calculateRowState(row, approvingIdsSet, selectingIdsSet),
    };
    return tableRowToRet;
  });
};

export const createDefaultSortInput = (
  viewBy: ViewBy,
): TimeSheetSortInput[] => {
  if (viewBy === 'employee') {
    return [sortInputs.employeeNameAsc, sortInputs.startDateDesc];
  }
  return [sortInputs.startDateDesc, sortInputs.employeeNameAsc];
};

const calculateRowState = (
  row: TableRow,
  approvingIdsSet: Set<string>,
  selectingIdsSet: Set<string>,
): TableRowState => {
  const approving = isTableRowTimeSheet(row)
    ? approvingIdsSet.has(row.timeSheetId)
    : undefined;
  return {
    approving,
    selected: selectingIdsSet.has(row.id),
    selectDisabled: isSelectDisabled(row, approvingIdsSet),
  };
};

export const isSelectDisabled = (
  row: TableRow,
  approvingIdsSet: Set<string>,
): boolean => {
  const approving = isTableRowTimeSheet(row)
    ? approvingIdsSet.has(row.timeSheetId)
    : undefined;
  const isTableRowAndNotInPendingState =
    isTableRowTimeSheet(row) && row.approveStatus !== TimeSheetStatus.Pending;
  return approving === true || isTableRowAndNotInPendingState;
};

const createProjectHeader = (
  project: ProjectsTimeSheetProjectInfo,
): TableRowHeaderProject => {
  return {
    ...defaultTableRowState,
    ...projectTimeSheetTrackFieldsUndefined,
    rowType: 'header-project',
    headerProject: {
      id: project.id,
      name: project.name,
      startDay: project.startDay,
      endDay: project.endDay,
      estimatedInMin: project.estimatedInMin,
      billable: project.billable,
      timeTrackedInMin: project.timeTrackedInMin,
      timeEntries: project.timeEntries,
      isEmpty: project.isEmpty,
    },
    id: project.id,
    commentsCount: 0,
  };
};
const createHeader = (
  viewBy: ViewBy,
  anyItemInGroup: ProjectsTimeSheet,
  rowId: string,
): TableRow => {
  switch (viewBy) {
    case 'project': {
      return {
        ...projectTimeSheetTrackFieldsUndefined,
        ...defaultTableRowState,
        id: rowId,
        rowType: 'header-week',
        headerWeek: anyItemInGroup.period,
        commentsCount: 0,
      };
    }
    case 'employee': {
      return {
        ...projectTimeSheetTrackFieldsUndefined,
        ...defaultTableRowState,
        headerEmployee: anyItemInGroup.employee,
        rowType: 'header-employee',
        id: rowId,
        commentsCount: 0,
      };
    }
  }
};

const createRow = (
  headerId: string,
  viewBy: ViewBy,
  item: ProjectsTimeSheet,
  projectInfo: ProjectsTimeSheetProjectInfo | undefined,
  rowId: string,
): TableRow => {
  switch (viewBy) {
    case 'employee':
      return {
        id: rowId,
        rowType: 'row-period',
        projectId: undefined,
        commentsCount: item.commentsCount,
        headerId,
        ...defaultTableRowState,
        ...mapBaseRowProps(item),
      };
    case 'project':
      return {
        id: rowId,
        rowType: 'row-employee',
        projectId: projectInfo?.id || '',
        commentsCount: item.commentsCount,
        headerId,
        ...defaultTableRowState,
        ...mapBaseRowProps({
          ...item,
          projects: item.projects.filter(
            (project) => !projectInfo?.id || project.id === projectInfo?.id,
          ),
        }),
      };
  }
};

const mapBaseRowProps = (src: ProjectsTimeSheet): ProjectsTimeSheetFields => {
  const sumTrackedInMin = src.projects.reduce((acc, project) => {
    const projectTrackedTime = project.timeEntries.reduce(
      (projectAcc, entry) => projectAcc + entry.trackedInMin,
      0,
    );
    return acc + projectTrackedTime;
  }, 0);

  return {
    timeSheetId: src.id,
    approveStatus: src.approveStatus,
    employee: src.employee,
    period: src.period,
    targetMinutes: src.targetMinutes,
    trackedMinutes: sumTrackedInMin,
    note: null,
    actions: undefined,
    badges: undefined,
  };
};

const mapDaySubRow = (
  timeSheetId: string,
  trackedDay: ProjectsTimeSheetDaySubRowTrackedTimeDayFragment['days'][0],
  projectEntries: TimeSheetDaySubRowProjectTimeEntry[],
  projectId: string | undefined,
  timeSheetDay: ProjectsTimeSheetDaySubRowTimeSheetDayFragment | undefined,
  showDayProjectEntries: boolean,
): TableDaySubRow => {
  const id = `${timeSheetId}-day-${trackedDay.day}`;
  return {
    ...projectTimeSheetTrackFieldsUndefined,
    rowType: 'subrow-day',
    id,
    day: trackedDay.day,
    timeSheetId,
    projectId,
    timeSheetDayId: timeSheetDay?.Id ?? null,
    commentsCount: timeSheetDay?.commentsCount ?? 0,
    note: projectEntries
      .map((x) => x.flair__Notes__c)
      .filter((x) => !!x)
      .join(' | '),
    targetMinutes: trackedDay.targetMinutes,
    trackedMinutes: projectEntries.reduce(
      (sum, cur) => sum + (cur.flair__Minutes__c || 0),
      0,
    ),
    badges: mapDayBadges(trackedDay),
    subRows: !showDayProjectEntries
      ? undefined
      : projectEntries.map(mapProjectEntrySubRow),
  };

  function mapProjectEntrySubRow(
    pe: TimeSheetDaySubRowProjectTimeEntry,
  ): TableProjectSubRow {
    return {
      ...projectTimeSheetTrackFieldsUndefined,
      id: `${pe.Id}`,
      rowType: 'subrow-project',
      projectInfo: {
        name: pe.project?.flair__Name__c ?? EmptyProjectName,
        billable: pe.project?.flair__Billable__c ?? false,
      },
      commentsCount: undefined,
      trackedMinutes: pe.flair__Minutes__c ?? 0,
      note: pe.flair__Notes__c,
    };
  }
};

export const createTableDaySubRowLoading = (
  timeSheetId: string,
): TableDaySubRowLoading => ({
  id: `${timeSheetId}-subrow-day-loading`,
  rowType: 'subrow-day-loading',
  commentsCount: 0,
  ...projectTimeSheetTrackFieldsUndefined,
});

export const createTableDaySubRowLoadingError = (
  timeSheetId: string,
  error: string,
): TableDaySubRowLoadingError => ({
  id: `${timeSheetId}-subrow-day-loading-error`,
  rowType: 'subrow-day-loading-error',
  commentsCount: 0,
  error,
  ...projectTimeSheetTrackFieldsUndefined,
});

const defaultTableRowState: TableRowState = {
  selected: false,
  selectDisabled: false,
  approving: undefined,
};

const sortInputs = {
  employeeNameAsc: {
    field: TimeSheetSortFieldEnum.EmployeeName,
    order: AscDescEnum.Asc,
  },
  startDateDesc: {
    field: TimeSheetSortFieldEnum.StartDate,
    order: AscDescEnum.Desc,
  },
};

const mapDayBadges = (
  src: ProjectsTimeSheetDaySubRowTrackedTimeDayFragment['days'][0],
): TableDayBadges => ({
  absences: src.allAbsences,
  holidays: src.holidays,
  absenceMinutes: src.absenceMinutes,
  holidayMinutes: src.holidayMinutes,
});
