import * as React from 'react';

const FolderIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M20.25 6.75005H12.2531L9.64688 4.80005C9.38854 4.60283 9.07188 4.49727 8.74688 4.50005H3.75C3.35218 4.50005 2.97064 4.65809 2.68934 4.93939C2.40804 5.2207 2.25 5.60223 2.25 6.00005V18.7501C2.25 19.1479 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.2501 3.75 20.2501H20.3344C20.7091 20.2476 21.0677 20.0977 21.3327 19.8327C21.5976 19.5678 21.7475 19.2091 21.75 18.8344V8.25005C21.75 7.85223 21.592 7.4707 21.3107 7.18939C21.0294 6.90809 20.6478 6.75005 20.25 6.75005Z"
        fill="#196879"
      />
    </svg>
  );
});

export default FolderIcon;
