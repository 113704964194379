import React, { useState } from 'react';

type Props = {
  onSubmit: (email: string) => void;
};

export const EnterEmailForm: React.FC<Props> = ({ onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    onSubmit(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Employee email address</label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Please enter an employee email"></input>
      </div>
      <button type="submit" className="btn btn-primary">
        Login as employee
      </button>
    </form>
  );
};
