import styled from '@emotion/styled';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ServerError from '../../../../components/ServerError';
import { Theme } from '../../../../theme';
import PageHeader from '../../components/PageHeader';
import CardBody from '../../pages/Home/components/CardBody';
import { useHiringManagerJobsQuery } from '../../__generated__/graphql';
import { recruitmentRoutes } from '../routes';
import JobsFilters from './JobsFilters';
import useJobsFiltration from './JobsFilters/useJobsFiltration';
import './JobsPage.css';
import Loading from './Loading';
import {
  DEPARTMENT_FILTER,
  Job,
  JobsFiltersType,
  LOCATIONS_FILTER,
  NAME_FILTER,
} from './types';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {
  jobs: Job[];
};

const getQueryFiltersData = (query: URLSearchParams): JobsFiltersType => {
  return {
    name: query.get(NAME_FILTER) || '',
    locations: query.get(LOCATIONS_FILTER) || '',
    departments: query.get(DEPARTMENT_FILTER) || '',
  };
};

const JobCard = styled.div({
  minHeight: '130px',
  borderRadius: '8px',
  border: `1px solid ${Theme.color.blue3}`,
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  ':hover': {
    background: Theme.color.gray5,
    cursor: 'pointer',
  },
});

const Content: React.FC<Props> = ({ jobs }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filterDataFromUrl = getQueryFiltersData(query);
  const filteredJobs = useJobsFiltration(filterDataFromUrl, jobs);

  const goToJobPage = (jobId: string) => {
    history.push(
      recruitmentRoutes.jobCandidates.route.create({ jobId: jobId }),
    );
  };

  return (
    <Card>
      <Card.Header>
        <JobsFilters jobs={jobs} filterData={filterDataFromUrl} />
      </Card.Header>
      <CardBody>
        <div className="jobs-list">
          {filteredJobs.map((job) => {
            return (
              <JobCard
                className="job-card"
                onClick={() => goToJobPage(job.Id)}
                key={job.Id}>
                <div>
                  <div className="job-title">{job.Name}</div>
                  <div>{job.location?.Name || '-'}</div>
                  <div>{job.department?.Name || '-'}</div>
                </div>
                <div className="job-candidates-count">
                  {job.candidatesCount} {t('recruitment.jobsPage.candidates')}
                </div>
              </JobCard>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};
export const JobsList: React.FC = () => {
  const { t } = useTranslation();
  const tRecruitmentNavigation = useNamespacedTranslation(
    'navigation.menuItems.recruitment',
  );
  const { data, loading, error } = useHiringManagerJobsQuery();

  useFlairBreadcrumbHook([
    { label: tRecruitmentNavigation('title') },
    { label: tRecruitmentNavigation('subMenuItems.jobs.title') },
  ]);

  if (error) {
    return <ServerError />;
  }

  if (loading) {
    return <Loading />;
  }
  const jobs = data?.hiringManager?.jobs ?? [];

  return (
    <Container>
      <PageHeader title={t('recruitment.jobsPage.title')} />
      <Row>
        <Col>{Array.isArray(jobs) && <Content jobs={jobs} />}</Col>
      </Row>
    </Container>
  );
};

export default JobsList;
