import React from 'react';
import {
  Col,
  ProgressBar,
  Row as BootstrapRow,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import Percentage from '../../../components/Percentage';
import styled from '@emotion/styled';
import FulfillmentPopover from '../../../components/TimeSheet/FulfillmentPopover';

type Props = {
  target: number | null;
  tracked: number;
};

const calcPercentage = (target: number, tracked: number): number =>
  target > 0 ? parseFloat(((tracked * 100.0) / target).toFixed(2)) : 0;

const progressBarVariant = (progress: number) => {
  if (progress === 100) {
    return 'success';
  }

  if (progress > 100) {
    return 'danger';
  }

  return 'secondary';
};

const Progress = styled(ProgressBar)({
  width: '6rem',
});

const TimeFulfillment: React.FC<Props> = ({ target, tracked }) => {
  const percentage = target !== null ? calcPercentage(target, tracked) : null;

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id="popover">
          <FulfillmentPopover target={target} tracked={tracked} />
        </Popover>
      }>
      <BootstrapRow className="d-inline-flex align-items-center">
        {percentage !== null && (
          <>
            <Col className="pe-3 col-auto">
              <Progress
                max={target || 0}
                now={tracked}
                // @ts-ignore
                variant={progressBarVariant(percentage)}
                className="progress-sm"
              />
            </Col>
            <Col className="ps-0">
              <small className="me-2">
                <Percentage amount={percentage} maximumFractionDigits={2} />
              </small>
            </Col>
          </>
        )}
      </BootstrapRow>
    </OverlayTrigger>
  );
};

export default TimeFulfillment;
