import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DuringBreak } from '../../hooks/useCurrentTimeTrackingTimeline';
import ResumeTimeTrackingButton from '../../pages/TimeTracking/CurrentTimeTracking/ResumeTimeTrackingButton';
import TimeUntilNow from '../../pages/TimeTracking/CurrentTimeTracking/TimeUntilNow';
import AccordionToggleButton from './AccordionToggleButton';
import CardTitle from './CardTitle';

type Props = {
  step: DuringBreak;
  accordionToggleKey: string;
  accordionOpen: boolean;
  accordionToggleOpen: () => void;
};

const CardHeaderDuringBreakStep: React.FC<Props> = ({
  step,
  accordionToggleKey,
  accordionOpen,
  accordionToggleOpen,
}) => {
  const { timeEntryId } = step;
  const { t } = useTranslation();

  return (
    <Row className="align-items-center">
      <Col>
        <CardTitle
          title={<TimeUntilNow date={step.startTime} />}
          subtitle={t('timeTracking.current.steps.duringBreak.breakTime')}
          accordionToggleKey={accordionToggleKey}
          accordionToggleOpen={accordionToggleOpen}
        />
      </Col>

      <Col className="col-auto">
        <ResumeTimeTrackingButton
          className="lift btn-rounded-circle me-2"
          timeEntryId={timeEntryId}
        />
        <AccordionToggleButton
          eventKey={accordionToggleKey}
          open={accordionOpen}
          onClick={accordionToggleOpen}
        />
      </Col>
    </Row>
  );
};

export default CardHeaderDuringBreakStep;
