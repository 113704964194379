import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useQueryParams } from './useQueryParams';

export const useLogin = () => {
  const { redirectTo } = useQueryParams();
  const { login } = useAuth();
  const history = useHistory();

  return useCallback(
    (apiToken: string) => {
      login(apiToken);
      history.push(redirectTo ?? '/');
    },
    [history, login, redirectTo],
  );
};
