import React from 'react';
import Button from '../../../../../../../components/button';
import { useUpdateNotificationSettingsByChannel } from './useUpdateNotificationSettingsByChannel';
import { EmployeeNotificationSettingsQuery } from '../../../../../__generated__/graphql';
import { NotificationChannel } from '../../types';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';

/**
 * Checks if all notifications are enabled for a given channel.
 * @param channel - The notification channel to check.
 * @param data - The notification settings data.
 * @returns True if all notifications are enabled, false otherwise.
 */
const isAllNotificationsEnabledForChannel = (
  channel: NotificationChannel,
  data: EmployeeNotificationSettingsQuery,
): boolean => {
  const currentValues = data.me.notificationSettings;
  if (currentValues !== undefined) {
    for (const [key, value] of Object.entries(currentValues)) {
      const isWithinChannel = key
        .toLowerCase()
        .endsWith(`_${channel}__c`.toLowerCase());
      if (isWithinChannel && !value) {
        return false;
      }
    }
  }
  return true;
};

type UpdateNotificationSettingsByChannelButtonProps = {
  channel: NotificationChannel;
  data: EmployeeNotificationSettingsQuery;
};

const UpdateNotificationSettingsByChannelButton: React.FC<
  UpdateNotificationSettingsByChannelButtonProps
> = ({ channel, data }) => {
  const [enableAllNotificationsForChannel, { loading: enableLoading }] =
    useUpdateNotificationSettingsByChannel({
      valueToSet: true,
      channel,
      data,
    });
  const [disableAllNotificationsForChannel, { loading: disableLoading }] =
    useUpdateNotificationSettingsByChannel({
      valueToSet: false,
      channel,
      data,
    });
  const loading = enableLoading || disableLoading;
  const isAllNotificationsEnabledForThisChannel =
    isAllNotificationsEnabledForChannel(channel, data);

  const t = useNamespacedTranslation('personalData.notificationSettings2');

  const buttonLabel = isAllNotificationsEnabledForThisChannel
    ? t(`turnAllOff.${channel}`)
    : t(`turnAllOn.${channel}`);
  const handleClick = isAllNotificationsEnabledForThisChannel
    ? disableAllNotificationsForChannel
    : enableAllNotificationsForChannel;

  return (
    <Button
      className="p-0"
      variant="link"
      label={buttonLabel}
      disabled={loading}
      size="sm"
      onClick={() => handleClick}
    />
  );
};

export default UpdateNotificationSettingsByChannelButton;
