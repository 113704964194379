import React from 'react';
import { DocumentsPathBreadcrumbs } from './DocumentsPathBreadcrumbs';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Breadcrumb } from './types';
import Skeleton from 'react-loading-skeleton';

const i18Path = 'documents2.personal';

type Props = {
  breadcrumbs: Breadcrumb[];
  navigateByFolder: (breadcrumb: Breadcrumb) => void;
  navigateBack: () => void;
  isLoading: boolean;
};

export const FolderBreadcrumbs: React.FC<Props> = React.memo((props) => {
  const { breadcrumbs, navigateByFolder, navigateBack, isLoading } = props;
  const t = useNamespacedTranslation(i18Path);

  if (isLoading) {
    return <Skeleton width={250} height={20} />;
  }

  return breadcrumbs.length === 1 ? (
    <span className="d-none mr-2 d-md-block">{t('table.home')}</span>
  ) : (
    <>
      <DocumentsPathBreadcrumbs
        navigateByFolder={navigateByFolder}
        breadcrumbs={breadcrumbs}
        navigateBack={navigateBack}
      />
    </>
  );
});
