import {
  InventoryDataFragment,
  InventoryDictionaryDataFragment,
  InventoryEmployeeDataFragment,
  InventorySidebarDataFragment,
} from '../../../../__generated__/graphql';
import {
  DictionaryItem,
  Inventory,
  InventoryItem,
  InventoryItemEmployee,
  InventoryStatus,
} from '../types';
import { mapLocation, mapSupportAdvisor } from '../mappings';
import {
  AttachmentFile,
  ContentDocumentLink,
  InventoryElectronicDevice,
  InventoryOfficeEquipment,
  InventoryOther,
} from './types';

export const mapDocumentFile = (link: ContentDocumentLink): AttachmentFile => ({
  id: link.Id,
  fileName: link.ContentDocument.Title
    ? `${link.ContentDocument.Title}.${link.ContentDocument.FileExtension}`
    : link.ContentDocument.Title,
  downloadPublicUrl: link.downloadUrl,
});

export const mapInventorySidebarData = (
  data: InventorySidebarDataFragment | undefined | null,
): InventoryItem | null => {
  if (!data) return null;

  return {
    id: data.Id,
    name: data.Name,
    purchaseDate: data.flair__Purchase_Date__c,
    purchasePrice: data.flair__Net_Purchase_Price__c,
    serialNumber: data.flair__Serial_Number__c,
    notes: data.flair__Notes__c,
    appCatDownloadOrConnectLink:
      data.flair__App_Cat_Download_Or_Connect_Link__c,
    appCatEditor: data.flair__App_Cat_Editor__c,
    appCatVersion: data.flair__App_Cat_Version__c,
    warrantyInformation: data.flair__Warranty_Information__c,
    warrantyDate: data.flair__Warranty_Date__c,
    vehiclePlateNumber: data.flair__Vehicle_Cat_Serial_Plate_Number__c,
    vehicleModelYear: data.flair__Vehicle_Cat_Model_Year__c,
    vehicleInsurancePolicyNumber:
      data.flair__Vehicle_Cat_Insurance_policy_number__c,
    vehicleInsuranceCompanyContact:
      data.flair__Vehicle_Cat_Insurance_Company_Contact__c,
    vehicleInsuranceCompany: data.flair__Vehicle_Cat_Insurance_Company__c,
    vehicleIdentificationVIN:
      data.flair__Vehicle_Cat_Identification_Number_VIN__c,
    uniformCatSize: data.flair__Uniform_Cat_Size__c,
    vehicleFuelType: mapDictionaryItem(data.vehicleCatFuelType),
    inventory: mapInventory(data.inventory),
    uniformCatSizeSystem: mapDictionaryItem(data.uniformCatSizeSystem),
    uniformCatType: mapDictionaryItem(data.uniformCatType),
    electronicsCatOperatingSystem: mapDictionaryItem(
      data.electronicsCatOperatingSystem,
    ),
    status: data.flair__Approval_Status__c as InventoryStatus,
    category: { name: data.inventory.flair__Type__c },
    location: data.location ? mapLocation(data.location) : null,
    employee: data.employee ? mapInventoryItemEmployee(data.employee) : null,
    currency: data.CurrencyIsoCode ?? null,
    attachments: data.ContentDocumentLinks
      ? data.ContentDocumentLinks.map(mapDocumentFile)
      : null,
  };
};
const mapInventoryItemEmployee = (
  employee: InventoryEmployeeDataFragment,
): InventoryItemEmployee => ({
  id: employee.Id,
  name: employee.Name,
  avatarUrl: employee.avatar?.url ?? undefined,
});
const mapDictionaryItem = (
  item: InventoryDictionaryDataFragment | null,
): DictionaryItem | null => {
  if (!item) return null;
  return {
    id: item.Id,
    name: item.Name,
    label: item.label,
  };
};

const mapInventory = (inventory: InventoryDataFragment): Inventory => ({
  id: inventory.Id,
  name: inventory.Name,
  manufacturer: inventory.manufacturer
    ? mapDictionaryItem(inventory.manufacturer)
    : null,
  model: inventory.flair__Model__c ?? null,
  supportVideoUrl: inventory.flair__Support_Video__c,
  supportInstruction: inventory.flair__Support_Instruction__c,
  supportAdvisor: inventory.supportAdvisor
    ? mapSupportAdvisor(inventory.supportAdvisor)
    : null,
});

export const mapInventoryElectronicDevice = (
  src: InventoryItem,
): InventoryElectronicDevice => ({
  id: src.id,
  name: src.name,
  purchaseDate: src.purchaseDate,
  purchasePrice: src.purchasePrice,
  location: src.location,
  employee: src.employee,
  inventory: src.inventory,
  category: src.category,
  notes: src.notes,
  serialNumber: src.serialNumber,
  electronicsCatOperatingSystem: src.electronicsCatOperatingSystem,
  warrantyInformation: src.warrantyInformation,
  warrantyDate: src.warrantyDate,
  status: src.status,
  currency: src.currency,
});

export const mapInventoryOfficeEquipment = (
  src: InventoryItem,
): InventoryOfficeEquipment => ({
  id: src.id,
  name: src.name,
  purchaseDate: src.purchaseDate,
  purchasePrice: src.purchasePrice,
  location: src.location,
  employee: src.employee,
  inventory: src.inventory,
  category: src.category,
  notes: src.notes,
  serialNumber: src.serialNumber,
  warrantyInformation: src.warrantyInformation,
  warrantyDate: src.warrantyDate,
  status: src.status,
  currency: src.currency,
});

export const mapInventoryOther = (src: InventoryItem): InventoryOther => ({
  id: src.id,
  name: src.name,
  purchaseDate: src.purchaseDate,
  purchasePrice: src.purchasePrice,
  location: src.location,
  employee: src.employee,
  inventory: src.inventory,
  category: src.category,
  notes: src.notes,
  serialNumber: src.serialNumber,
  status: src.status,
  currency: src.currency,
});
