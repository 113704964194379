import styled from '@emotion/styled';
import React from 'react';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import { DateAndTime } from '../logic';
import moment from 'moment';

type Props = {
  value: DateAndTime;
  //todo: remove null
  oldValue?: DateAndTime | null;
  isDateVisible: boolean;
};

const Strike = styled.span`
  text-decoration: line-through;
  padding-right: 0.2em;
`;

const DateTimeComponent: React.FC<DateAndTime> = ({ date, time }) => {
  return (
    <span>
      <span>{time}</span> (<FormattedDate day={moment(date)} format="short" />)
    </span>
  );
};

const DateAndTimeReadonly: React.FC<Props> = ({
  value,
  oldValue,
  isDateVisible,
}) => {
  const oldTime =
    oldValue && oldValue.time !== value.time ? oldValue.time : null;
  const oldDate =
    oldValue && oldValue.date !== value.date ? oldValue.date : null;
  if (!isDateVisible) {
    return (
      <span>
        {oldTime && <Strike>{oldTime}</Strike>}
        <span>{value.time}</span>
      </span>
    );
  } else if (isDateVisible && oldTime && oldDate) {
    return (
      <span>
        <Strike>
          <DateTimeComponent {...oldValue!} />
        </Strike>
        <DateTimeComponent {...value} />
      </span>
    );
  } else if (isDateVisible && oldTime) {
    return (
      <span>
        {oldTime && <Strike>{oldTime}</Strike>}
        <DateTimeComponent {...value} />
      </span>
    );
  } else {
    return <DateTimeComponent {...value} />;
  }
};

export default React.memo(DateAndTimeReadonly);
