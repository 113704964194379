import classNames from 'classnames';
import React from 'react';

export const NavLinkIcon = ({
  className,
  isActive,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { isActive?: boolean }) => (
  <div
    {...rest}
    className={classNames('nav-item-icon', className, { active: isActive })}
  />
);
