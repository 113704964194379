import {
  ManagerReportsType,
  usePendingAbsenceRequestToMeQuery,
} from '../../../__generated__/graphql';
import { filterOutNotPending } from './mappings';

export const usePendingAbsenceRequests = (
  managerReportsType: ManagerReportsType,
) => {
  const { data, loading, error } = usePendingAbsenceRequestToMeQuery({
    variables: {
      managerReportsType,
    },
  });

  return {
    pendingAbsenceRequestToMe: filterOutNotPending(
      data?.me.pendingAbsenceRequestToMe ?? [],
    ),
    error,
    loading,
  };
};
