import styled from '@emotion/styled';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  CalendarAbsence,
  CalendarEmployeeAbsenceCategory,
  CalendarHoliday,
  CalendarWorkload,
} from '../AbsenceCalendar/types';
import YearNav from '../YearNav';
import Calendar from './Calendar';
import Categories from './Categories';

type Props = {
  absenceCategories?: ReadonlyArray<CalendarEmployeeAbsenceCategory>;
  absences?: ReadonlyArray<CalendarAbsence>;
  workloads?: ReadonlyArray<CalendarWorkload>;
  holidays?: ReadonlyArray<CalendarHoliday>;
  year: number;
  goToNextYear: () => void;
  goToPrevYear: () => void;
  loading: boolean;
  readOnly?: boolean;
};

const CardHeader = styled(Card.Header)({
  minHeight: '60px',
  height: 'auto',
});

const AbsenceCalendarCard: React.FC<Props> = ({
  absences,
  absenceCategories,
  workloads,
  holidays,
  year,
  goToNextYear,
  goToPrevYear,
  loading,
  readOnly,
}) => {
  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <Row className="align-items-center">
          <Col>
            <Categories categories={absenceCategories} readOnly={readOnly} />
          </Col>
          <Col className="col-auto text-end">
            <YearNav
              year={year}
              goToNextYear={goToNextYear}
              goToPrevYear={goToPrevYear}
            />
          </Col>
        </Row>
      </CardHeader>
      <Calendar
        year={year}
        absences={absences}
        workloads={workloads}
        holidays={holidays}
        loading={loading}
      />
    </Card>
  );
};

export default AbsenceCalendarCard;
