import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../../../../../theme';
import classNames from 'classnames';

export type StepProp<T = string> = {
  title: string;
  stepName: T;
  disabled?: boolean;
};

type Props = {
  isActive: boolean;
  stepNumber: number;
  onClick?: (stepIndex: number) => void;
} & StepProp;

type StyledStepItemProps = {
  isActive: boolean;
  onClick?: (stepIndex: number) => void;
} & Omit<StepProp, 'title' | 'description'>;

const StepItem: React.FC<Props> = ({
  isActive,
  disabled = false,
  stepNumber,
  onClick = () => {},
  stepName,
}) => {
  return (
    <StyledStepItem
      className={classNames({
        'cu-pointer': !isActive || disabled,
        'pe-none': disabled,
      })}
      stepName={stepName}
      disabled={disabled}
      key={`step-${stepNumber}`}
      onClick={() => onClick(stepNumber)}
      isActive={isActive}
    />
  );
};

const StyledStepItem = styled.div<StyledStepItemProps>`
  ${({ isActive, disabled }) =>
    isActive
      ? {
          borderRadius: 24,
          width: '1.5rem',
          background: Theme.chart.skills.line.backgroundColor,
        }
      : {
          borderRadius: '50%',
          width: '.5rem',
          background: !disabled
            ? Theme.carouselItem.iconBackground
            : Theme.chart.skills.gridLines.color,
        }}
  height: .5rem;
`;

export default StepItem;
