import React, { useState } from 'react';
import { useTimeSheetDayTimeEntriesQuery } from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import { getTimeEntriesForDay } from './mappings';
import { TimeEntryCardInPopoverLayout } from './TimeEntryCardInPopoverLayout';
import {
  ButtonLink,
  createNewTimeEntry,
  getReadonlyReasons,
  TimeEntryItem,
  TimeEntryManagerSmart,
} from '../../../pages/TimeTracking/';
import { getAutoBreakRules } from '../../../components/AutoBreaks/getAutoBreakRules';
import Skeleton from 'react-loading-skeleton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { TimeBalancesInvalidateContext } from '../../../components/TimeSheetsTable/TimeBalanceProvider/TimeBalancesProvider';
import { useRestrictFutureEntries } from '../../../hooks/timeTrackingSettings';

type Props = {
  timeSheetId: string;
  day: string;
  dayStartDateTime: Date;
};

export const DayTimeEntriesPopoverConnected: React.FC<Props> = ({
  timeSheetId,
  day,
  dayStartDateTime,
}) => {
  const t = useNamespacedTranslation('timeTrackingNew.dayCard');
  const { restrictFutureEntriesForADay } = useRestrictFutureEntries();
  const [timeEntryNew, setTimeEntryNew] = useState<TimeEntryItem | null>(null);

  const { data, error, loading } = useTimeSheetDayTimeEntriesQuery({
    variables: {
      timeSheetId,
    },
  });

  const invalidateTimeBalance = React.useContext(TimeBalancesInvalidateContext);

  if (!data || loading) {
    return (
      <TimeEntryCardInPopoverLayout>
        <Skeleton width={'100%'} height={'4rem'} />
      </TimeEntryCardInPopoverLayout>
    );
  }
  if (error) {
    return <ServerError />;
  }

  const autoBreakRules = getAutoBreakRules(
    data.timeSheet.workloads,
    new Date(),
  );

  const employeeId = data.timeSheet.flair__Employee__c;

  const dayTimeEntries = getTimeEntriesForDay(
    data.timeSheet,
    day,
    dayStartDateTime,
  );

  const readonlyReasons = getReadonlyReasons(
    data.timeSheet,
    dayTimeEntries,
    new Date(day),
    restrictFutureEntriesForADay,
  );
  const readonly = readonlyReasons.length > 0;

  const timeEntries = timeEntryNew ? [timeEntryNew] : dayTimeEntries;

  const handleOnChanged = () => {
    invalidateTimeBalance({ employeeId, day });
  };

  const handleNewTimeEntry = () => {
    setTimeEntryNew(createNewTimeEntry(day, timeSheetId));
  };

  const handleCancelCreatingNewTimeEntry = () => {
    setTimeEntryNew(null);
  };

  const renderEmptyContainer = () => {
    return (
      <TimeEntryCardInPopoverLayout>
        <div className="text-center mb-2">{t('noEntriesForDay')}</div>
        {!readonly && (
          <ButtonLink
            title={t('addNewTimeEntry')}
            onClick={handleNewTimeEntry}
          />
        )}
      </TimeEntryCardInPopoverLayout>
    );
  };

  return (
    <div>
      {timeEntries.length === 0 && renderEmptyContainer()}
      {timeEntries.map((timeEntry) => (
        <TimeEntryManagerSmart
          key={timeEntry.uniqueId}
          costCenters={data.costCenters}
          autoBreakRules={autoBreakRules}
          value={timeEntry}
          employeeId={employeeId}
          employeeType={'manager'}
          readonlyReasons={readonlyReasons}
          onChanged={handleOnChanged}
          onCancelCreatingNewTimeEntry={handleCancelCreatingNewTimeEntry}
          renderLayout={(renderProps) => (
            <TimeEntryCardInPopoverLayout
              {...renderProps}
              // highlight only if there are more than 1 timeEntry
              highlight={renderProps.highlight && timeEntries.length > 1}
            />
          )}
        />
      ))}
    </div>
  );
};
