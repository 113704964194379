import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Button from '../../../../../../components/button';
import EmployeeEmojiList from '../EmployeeEmojiList';
import { Emoji } from '../../../Announcement';
import { useContext, useState } from 'react';
import './CollapsedEmojiButton.css';
import { InnerModalContext } from '../../../../../../context/InnerModalContext';

type Props = {
  counter: number;
  emojis: Emoji[][];
  onRemoveEmoji: (e: Emoji) => void;
  onSelectEmoji: (name: string) => void;
};

const CollapsedEmojiButton: React.FC<Props> = ({
  counter,
  emojis,
  onSelectEmoji,
  onRemoveEmoji,
}) => {
  const [show, setShow] = useState<boolean>(false);

  const { setIsInnerModalOpen } = useContext(InnerModalContext);

  const handleOnToggle = (show: boolean) => {
    setShow(show);
    setIsInnerModalOpen(show);
  };

  return (
    <OverlayTrigger
      placement="top"
      rootClose
      trigger="click"
      show={show}
      onToggle={handleOnToggle}
      overlay={
        <Popover id="collapsed-reactions-popover">
          <Popover.Body>
            <EmployeeEmojiList
              emojis={emojis}
              onSelectEmoji={onSelectEmoji}
              onRemoveEmoji={onRemoveEmoji}
              handleClose={() => handleOnToggle(false)}
            />
          </Popover.Body>
        </Popover>
      }>
      <Button size="sm" variant="white" label={`+${counter}`} />
    </OverlayTrigger>
  );
};

export default CollapsedEmojiButton;
