import { EmployeeFeedbackAnswerProps } from '../../../../components/EmployeeFeedbackQuestionnaire/types';
import { FeedbackQuestionVisibility } from '../../../../__generated__/graphql';

export const isViewer = (feedbackManagerId: string, myId: string) =>
  feedbackManagerId !== myId;

export const visibleForManager = (answer: EmployeeFeedbackAnswerProps) =>
  answer.question.flair__Feedback_Question_Visibility__c.includes(
    FeedbackQuestionVisibility.Manager,
  ) && !answer.question.flair__Is_Satisfaction_Section__c;

export const visibleForEmployee = (answer: EmployeeFeedbackAnswerProps) =>
  answer.question.flair__Feedback_Question_Visibility__c.includes(
    FeedbackQuestionVisibility.Employee,
  ) && !answer.question.flair__Is_Satisfaction_Section__c;

export const visibleForPeers = (answer: EmployeeFeedbackAnswerProps) =>
  answer.question.flair__Feedback_Question_Visibility__c.includes(
    FeedbackQuestionVisibility.Peers,
  ) && !answer.question.flair__Is_Satisfaction_Section__c;
