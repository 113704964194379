import React from 'react';
import { useTranslation } from 'react-i18next';

const AbsenceTime: React.FC<{ time: string }> = ({ time }) => {
  const { t } = useTranslation();

  if (time === '00:00')
    return <span>{t('calendar.table.absencePeriod.morning')}</span>;
  if (time === '12:00')
    return <span>{t('calendar.table.absencePeriod.lunch')}</span>;
  if (time === '23:59')
    return <span>{t('calendar.table.absencePeriod.afternoon')}</span>;
  return <span>{time}</span>;
};

export default AbsenceTime;
