import React from 'react';
import styled from '@emotion/styled';
import {
  AbsenceProps,
  CalendarPeriodProps,
  HolidayProps,
  WarningsProps,
} from '../types';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import DurationFormat from '../../../DurationFormat';
import HolidaysAndAbsences from './HolidaysAndAbsences';
import WarningIcon from '../WaningIcon';
import classNames from 'classnames';
import LoomVideoWithPopoverEditor from '../../../LoomVideo/LoomVideoWithPopoverEditor';
import { DayInfo } from '../../../../pages/TimeTracking/TimeTrackingEntries/logic';
import { Day } from '../../../../pages/Projects/types';
import TimesheetDayCommentsPopover from '../../../../pages/Projects/ProjectsTimesheets/TimesheetDayCommentsPopover';

type Props = {
  dayInfo: DayInfo | Day;
  dayMinutes: number;
  holidays: HolidayProps[];
  absences: AbsenceProps[];
  calendarPeriods: CalendarPeriodProps[];
  warnings?: WarningsProps | null;
  commentsCount: number;
  isManagerMode?: boolean;
};

const DayHeaderStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 10,
});

const DayHeaderLeft = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const DayHeaderRight = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const DayTitle: React.FC = ({ children }) => (
  <div className={classNames('fw-bold')}>{children}</div>
);

const BadgesContainer = styled.div({
  display: 'flex',
  margin: '-10px 0 10px 0',
});

const WarningContainer = styled('div')({
  minWidth: 20,
  textAlign: 'center',
});

const DayHeader: React.FC<Props> = ({
  dayInfo,
  dayMinutes,
  holidays,
  absences,
  calendarPeriods,
  warnings,
  isManagerMode = false,
}) => {
  return (
    <>
      <DayHeaderStyled>
        <DayHeaderLeft>
          <DayTitle>
            <FormattedDate day={dayInfo.day} format="dayname" />
            &nbsp;-&nbsp;
            <FormattedDate day={dayInfo.day} format="short" />
          </DayTitle>
          <div className="ms-3">
            <TimesheetDayCommentsPopover
              timesheetDayId={dayInfo.timeSheetDayId}
              commentsCount={dayInfo.commentsCount}
            />
          </div>
          <div>
            <LoomVideoWithPopoverEditor
              entityId={dayInfo.timeSheetDayId}
              loomVideo={dayInfo.loomVideo}
              allowActions={!isManagerMode}
            />
          </div>
        </DayHeaderLeft>
        <DayHeaderRight>
          <div>
            <DurationFormat minutes={dayMinutes} />
          </div>
          <WarningContainer>
            {warnings && (
              <div className="d-inline-block">
                <WarningIcon warnings={warnings} />
              </div>
            )}
          </WarningContainer>
        </DayHeaderRight>
      </DayHeaderStyled>
      {(holidays.length > 0 ||
        absences.length > 0 ||
        calendarPeriods.length > 0) && (
        <BadgesContainer>
          <HolidaysAndAbsences
            holidays={holidays}
            absences={absences}
            calendarPeriods={calendarPeriods}
          />
        </BadgesContainer>
      )}
    </>
  );
};

export default DayHeader;
