import React from 'react';
import { TableRow, isTableDaySubRow, isTableRowTimeSheet } from '../types';
import { DayCommentsCell } from './DayCommentsCell';
import { TimeSheetCommentsCell } from './TimeSheetCommentsCell';

type Props = {
  row: TableRow;
};

export const RowCommentsCell: React.FC<Props> = ({ row }) => {
  if (isTableDaySubRow(row) && row.timeSheetDayId) {
    return (
      <DayCommentsCell
        timeSheetDayId={row.timeSheetDayId}
        commentsCount={row.commentsCount}
      />
    );
  } else if (isTableRowTimeSheet(row)) {
    return <TimeSheetCommentsCell commentsCount={row.commentsCount} />;
  }
  return null;
};
