import * as React from 'react';

const CalendarCheckOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M10.3904 8.18346C10.5629 7.96783 10.528 7.65318 10.3124 7.48068C10.0967 7.30817 9.78208 7.34313 9.60958 7.55876L7.18707 10.5869L6.37992 9.64355C6.2004 9.43373 5.88477 9.40917 5.67495 9.5887C5.46513 9.76822 5.44057 10.0839 5.62009 10.2937L6.82009 11.6962C6.91696 11.8094 7.05928 11.8735 7.20826 11.871C7.35724 11.8686 7.49736 11.7998 7.59044 11.6835L10.3904 8.18346Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1.15234C4.27614 1.15234 4.5 1.3762 4.5 1.65234V2.15234H11.5V1.65234C11.5 1.3762 11.7239 1.15234 12 1.15234C12.2761 1.15234 12.5 1.3762 12.5 1.65234V2.15234H13C14.1046 2.15234 15 3.04777 15 4.15234V13.1523C15 14.2569 14.1046 15.1523 13 15.1523H3C1.89543 15.1523 1 14.2569 1 13.1523V4.15234C1 3.04777 1.89543 2.15234 3 2.15234H3.5V1.65234C3.5 1.3762 3.72386 1.15234 4 1.15234ZM2 4.15234C2 3.60006 2.44772 3.15234 3 3.15234H13C13.5523 3.15234 14 3.60006 14 4.15234V4.65234H2V4.15234ZM2 5.65234H14V13.1523C14 13.7046 13.5523 14.1523 13 14.1523H3C2.44772 14.1523 2 13.7046 2 13.1523V5.65234Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default CalendarCheckOutlineIcon;
