import React from 'react';
import { Spinner } from 'react-bootstrap';
import FlairIcon from '../../atomic/atoms/FlairIcon';

type Props = {
  loading?: boolean;
  completed?: boolean;
};

export const AutoSaveIndicator: React.FC<Props> = ({ loading, completed }) => {
  if (loading) {
    return <Spinner size="sm" animation="border" />;
  }
  if (completed) {
    return (
      <FlairIcon
        icon="checkmark-circle-outline"
        className="text-success pt-1"
      />
    );
  }
  return null;
};
