import { EmployeeFeedbackProps, Feedback, RecipientEmployee } from './types';
import { getEmployeeInitialsFromName } from '../../utils/employeeInitials';
import { Peer } from '../../pages/PerformanceReview/EmployeePerformanceReview/PeerReviewsSection/types';

export const mapToFeedback = (feedback: EmployeeFeedbackProps): Feedback => ({
  id: feedback.Id,
  cycleName: feedback.feedbackCycle.Name,
  dueDate: feedback.feedbackCycle.flair__Deadline__c,
  reviewer: feedback.outcome.reviewer.Name,
  employee: feedback.reviewee.Name,
  peers: feedback.peerFeedbacks
    ? feedback.peerFeedbacks.map((p) => mapToRecipientEmployee(p.reviewer))
    : [],
});

export const mapToRecipientEmployee = (peer: Peer): RecipientEmployee => ({
  type: 'employee',
  id: peer.id,
  employee: {
    id: peer.id,
    name: peer.name,
    initials: getEmployeeInitialsFromName(peer.name),
    avatarUrl: peer.avatarSrc,
    department: peer.department,
  },
});
