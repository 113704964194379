import { differenceInMinutes, setSeconds } from 'date-fns';
import { BreakScheduled, TimeTrackingState } from '.';
import { DateInterval } from '../../../../../utils/dateIntervalUtils';
import { setTime } from '../../../../../utils/dateUtils';

export const getMinutesAfterClockin = (
  timeTrackingState: TimeTrackingState,
  now: Date,
): number =>
  Math.max(0, differenceWithoutSeconds(now, timeTrackingState.start));

export const calculateBreakingTime = (
  timeTrackingState: TimeTrackingState,
  now: Date,
): number =>
  timeTrackingState.breaks.reduce((acc, curBreak) => {
    acc += Math.max(
      0,
      differenceWithoutSeconds(curBreak.end ?? now, curBreak.start),
    );
    return acc;
  }, 0);

export const differenceWithoutSeconds = (end: Date, start: Date) =>
  differenceInMinutes(setSeconds(end, 0), setSeconds(start, 0));

export const mapBreakScheduledToInterval = (
  breakScheduled: BreakScheduled,
  now: Date,
): Interval => ({
  start: setTime(now, breakScheduled.startTime),
  end: setTime(now, breakScheduled.endTime),
});

export const isInsideInterval = (interval: Interval, date: Date): boolean => {
  return date >= interval.start && date < interval.end;
};

export const getBreakingTime = (breaks: DateInterval[]): number =>
  breaks.reduce((acc, curBreak) => {
    acc += Math.max(0, differenceWithoutSeconds(curBreak.end, curBreak.start));
    return acc;
  }, 0);
