import * as React from 'react';
import { useUserInfo } from '../../../context/UserInfo';
import { EmployeeWithAvatar } from '../../../components/Employee';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';
import RequestAbsence from './RequestAbsence';
import CheerCoworkers from './CheerCoworkers';
import UploadDocument from './UploadDocument';
import './ProfileWidget.css';
import { useIsScreenSizeLargerThanOrEqual } from '../../../../../hooks/useIsScreenSizeLargerThan';
import { useServerFeatures } from '../../../../../hooks/useServerFeature';

const ProfileWidget: React.FC = () => {
  const isLargeScreen = useIsScreenSizeLargerThanOrEqual('lg');
  const { firstName, lastName, avatarUrl, position, isActive } = useUserInfo();
  const isFeatureVisible = useServerFeatures();

  const isAbsencesVisible = isFeatureVisible('Absences');
  const isDocumentsVisible =
    isFeatureVisible('Personal_Documents') ||
    isFeatureVisible('Corporate_Documents');
  const isCheersVisible = isFeatureVisible('Cheers');

  const employee = {
    firstName: firstName,
    lastName: lastName,
    avatarUrl: avatarUrl,
  };

  const additionalInfo = position ? position : '';

  return (
    <FlairCard
      cardContainerClassName="profile-widget-card"
      contentClassName="py-4">
      <div className="border-bottom pb-4 employee-section">
        <EmployeeWithAvatar
          employee={employee}
          additionalInfo={additionalInfo}
          size="lg"
          avatarClassName="me-3"
          nameContainerClassName="h2 mb-1"
          additionalInfoContainerClassName="h4-regular profile-widget-employee-position"
        />
      </div>
      {isLargeScreen && isActive && (
        <div className="d-flex justify-content-between pt-4 mx-3">
          {isAbsencesVisible && <RequestAbsence />}
          {isCheersVisible && <CheerCoworkers />}
          {isDocumentsVisible && <UploadDocument />}
        </div>
      )}
    </FlairCard>
  );
};

export default ProfileWidget;
