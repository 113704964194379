import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Employee } from '../..';
import CancelButton from '../../../../../../components/form/CancelButton';
import { Input } from '../../../../../../components/form/Input';
import { PolarityInput } from '../../../../../../components/form/PolarityInput';
import SubmitButton from '../../../../../../components/form/SubmitButton';
import useYupValidationResolver from '../../../../../../components/form/useYupValidationResolver';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import CardHeader from '../../../../components/CardHeader';
import {
  ManagerEmployeeNotePolarity,
  useEmployeePageCreateEmployeeNoteMutation,
} from '../../../../__generated__/graphql';

type Props = {
  employee: Employee;
};

type FormData = {
  text: string;
  polarity: string;
};

const validationSchema = yup.object().shape<FormData>({
  text: yup.string().required(),
  polarity: yup.string().required(),
});

const defaultValues = {
  text: '',
  polarity: ManagerEmployeeNotePolarity.Neutral,
};

const CardFooter = styled(Card.Footer)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '> button:not(:first-of-type)': {
    marginLeft: '0.5rem',
  },
});

const InputContainer: React.FC = ({ children }) => (
  <Row>
    <Col>{children}</Col>
  </Row>
);

export const EmployeeNoteForm: React.FC<Props> = ({
  employee: { Id: employeeId, Name: employeeName },
}) => {
  const { t } = useTranslation();
  const [createNote] = useEmployeePageCreateEmployeeNoteMutation();
  const errorHandler = useMutationErrorHandler();

  const form = useForm<FormData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (data: FormData) => {
      await createNote({
        variables: {
          input: {
            employeeId,
            text: data.text,
            polarity: data.polarity as ManagerEmployeeNotePolarity,
          },
          employeeId,
        },
      })
        .then(() => form.reset())
        .catch(errorHandler);
    },
    [createNote, employeeId, form, errorHandler],
  );

  return (
    <Card>
      <CardHeader>
        {t('managerEmployeeNotes.form.cardTitle', {
          employee: employeeName,
        })}
      </CardHeader>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card.Body>
            <InputContainer>
              <Input
                name="text"
                type="textarea"
                className="form-control-flush"
                placeholder={t(
                  'managerEmployeeNotes.form.textInput.placeholder',
                )}
                labelComponent={<></>}
              />
            </InputContainer>
            <InputContainer>
              <PolarityInput name="polarity" />
            </InputContainer>
          </Card.Body>

          <CardFooter>
            <SubmitButton size="sm">
              {t('form.general.saveButton')}
            </SubmitButton>
            <CancelButton size="sm">
              {t('form.general.cancelButton')}
            </CancelButton>
          </CardFooter>
        </form>
      </FormContext>
    </Card>
  );
};
