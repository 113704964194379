import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyInventory } from './EmptyInventory';
import { Column, Row, SortingRule } from 'react-table';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../../utils/dateUtils';
import CommentsPopover from '../../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../../components/Comment/types';
import { DropdownActionItem } from '../../../../../../atomic/molecules/DropdownActionItem';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { InventoryItemStatus } from './StatusCell/InventoryStatus';
import {
  Category,
  InventoryListItem,
  InventoryLocation,
  InventoryStatus,
} from '../types';
import { InventoryNameCell } from './InventoryNameCell/InventoryNameCell';
import { TableContentManual } from '../../../../manager/components/TableContentManual';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';
import {
  INVENTORY_STATUS_FEATURE,
  useFeature,
} from '../../../../../../context/FeaturesContext';

type Props<T> = {
  hasInventoryFilter: boolean;
  inventoryItems: InventoryListItem[];
  onClick: (row: TableItem) => void;
  onSupport: (row: TableItem) => void;
  initialSortBy: Array<SortingRule<T>>;
  onSortByChanged: (sortBy: SortingRule<T>[]) => void;
  pageSize?: number;
  onPageIndexChanged?: (pageIndex: number) => void;
  itemsCount: number | undefined;
  initialPageIndex: number;
};

export type TableItem = {
  id: string;
  name: string | null;
  location: InventoryLocation | null;
  category: Category;
  addedOn: string;
  recordId: string | null;
  status: InventoryStatus | null;
  commentsCount: number;
};

const i18Prefix = 'myData.inventory';

export const InventoryTable: React.FC<Props<InventoryListItem>> = ({
  inventoryItems,
  hasInventoryFilter,
  onClick,
  onSupport,
  pageSize,
  itemsCount,
  initialPageIndex,
  onPageIndexChanged,
  onSortByChanged,
  initialSortBy,
}) => {
  const { t } = useTranslation();

  const items = inventoryItems.map(mapToTableItem);
  const isInventoryStatusFeatureEnabled = useFeature(INVENTORY_STATUS_FEATURE);

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.name`)}`} />
        ),
        accessor: 'name',
        id: 'expander',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) => {
          return (
            <div>
              <InventoryNameCell
                name={value}
                isExpanded={row.isExpanded}
                type={row.original.category.name || ''}
                onClick={() => onClick(row.original)}></InventoryNameCell>
            </div>
          );
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.category`)}`} />
        ),
        accessor: 'category',
        Cell: ({ value }: { value: Category }) => <div>{value.name}</div>,
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.location`)}`} />
        ),
        accessor: 'location',
        Cell: ({ value }: { value: InventoryLocation }) =>
          value ? <div>{value.name}</div> : <span>-</span>,
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.addedOn`)}`} />
        ),
        accessor: 'addedOn',
        Cell: ({ value }: { value: string | null }) =>
          value ? (
            <FormattedDate day={parseDate(value)} format="short" />
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.comments`)}`} />
        ),
        accessor: 'recordId',
        disableSortBy: true,
        Cell: ({ value, row }) =>
          !value ? (
            <span>-</span>
          ) : (
            <div className="mt-2">
              <CommentsPopover
                recordId={value}
                commentsCount={row.original.commentsCount}
                relatedObjectName={RelatedObjectNames.Inventory}
                mode="icon"
              />
            </div>
          ),
      },
      {
        Header: () => {
          if (isInventoryStatusFeatureEnabled) {
            return (
              <Trans
                t={t}
                i18nKey={`${t(`${i18Prefix}.table.header.status`)}`}
              />
            );
          }
          return <></>;
        },
        accessor: 'status',
        Cell: ({ value }: { value: InventoryStatus }) => {
          if (value && isInventoryStatusFeatureEnabled)
            return <InventoryItemStatus status={value} />;
          return <></>;
        },
      },
      {
        id: 'actions',
        Cell: ({ row }: { value: string; row: Row<TableItem> }) => (
          <DropdownActions
            className="d-flex justify-content-end"
            id="inventory"
            icon={<FlairIcon icon="three-dots" />}>
            <DropdownActionItem
              onClick={() => {
                onSupport(row.original);
              }}>
              {t(`${i18Prefix}.table.actions.getSupport`)}
            </DropdownActionItem>
          </DropdownActions>
        ),
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [onClick, onSupport, t, isInventoryStatusFeatureEnabled]);

  if (!items.length && !hasInventoryFilter) {
    return <EmptyInventory emptyReason={'empty'} />;
  }

  if (!items.length && hasInventoryFilter) {
    return <EmptyInventory emptyReason={'filtered-empty'} />;
  }

  return (
    <TableContentManual<TableItem>
      columns={columns}
      data={items}
      getRowProps={() => ({ role: 'button' })}
      pageSize={pageSize}
      itemsCount={itemsCount}
      initialSortBy={initialSortBy}
      onSortByChanged={onSortByChanged}
      initialPageIndex={initialPageIndex}
      onPageIndexChanged={onPageIndexChanged}
    />
  );
};

function mapToTableItem(src: InventoryListItem): TableItem {
  return {
    id: src.id,
    name: src.name,
    category: src.category,
    addedOn: src.createdOn,
    status: src.status,
    location: src.location,
    commentsCount: src.commentsCount,
    recordId: src.id,
  };
}
