import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TimeSheet, Employee } from '../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../routes';
import LightList from '../../../components/LightList';
import { SectionTitle } from '../Heading';
import { isoWeekFromDateString } from '../../../../../utils/date';
import { useHistory } from 'react-router-dom';
import { useManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import Button from '../../../../../components/button';

type EmployeeProps = Pick<Employee, 'Name'>;
type TimesheetProps = Pick<TimeSheet, 'Id' | 'flair__Start_Date__c'> & {
  employee: EmployeeProps;
};

type Props = {
  timeSheets: ReadonlyArray<TimesheetProps>;
};

const PendingTimeSheets: React.FC<Props> = ({ timeSheets }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [myTeamReportMode] = useManagerReportsMode('myteam');
  const [targetReportMode, setTargetReportsMode] = useManagerReportsMode(
    'timeTrackingControlling',
  );
  const items = timeSheets.map((ts) => ({
    key: ts.Id,
    name: ts.employee.Name,
    value: t('calendarWeek', {
      week: isoWeekFromDateString(ts.flair__Start_Date__c),
    }),
  }));

  const count = timeSheets.length;

  return (
    <Row>
      <Col xs={12} className="mb-1">
        <SectionTitle>
          {t('home.pendingApprovalCard.pendingTimeSheets.title', {
            count: count,
          })}
        </SectionTitle>
      </Col>
      <Col>
        <LightList
          items={items}
          actionLink={
            <Button
              variant="link"
              size="sm"
              className="p-0 m-0"
              label={t('home.pendingApprovalCard.pendingTimeSheets.actionLink')}
              onClick={() => {
                // set manager mode to the same as in myTeam page
                if (myTeamReportMode !== targetReportMode) {
                  setTargetReportsMode(myTeamReportMode);
                }
                history.push(
                  routes.timeSheetsControllingPending.route.create({}),
                );
              }}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default PendingTimeSheets;
