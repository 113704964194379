import { differenceInMinutes } from 'date-fns';
import {
  isInsideInterval,
  mapBreakScheduledToInterval,
} from '../timeTrackingHelper';
import { BreakScheduled, TimeTrackingState } from '../types';
import { AllowToEndBreakResult, DenyEndBreakRules } from './types';

export const allowToEndBreak = (
  rules: DenyEndBreakRules | null,
  state: TimeTrackingState,
  now: Date,
): AllowToEndBreakResult => {
  if (rules === null) {
    return {
      result: 'allow',
    };
  }
  const breakingMinutes = getCurrentBreakDuration(state, now);
  const targetMinutes = getTargetMinutes(rules);
  if (breakingMinutes < targetMinutes) {
    return {
      result: 'breakLessThanMinBreak',
      breakingMinutes,
      targetMinutes,
    };
  }
  const breakScheduled = getCurrentScheduledBreak(rules.breaksScheduled, now);
  if (breakScheduled) {
    return {
      result: 'insideScheduledBreak',
      breakScheduled,
    };
  }
  return {
    result: 'allow',
  };
};

export const getTargetMinutes = (rules: DenyEndBreakRules) =>
  rules.minBreakDuration;

export const getCurrentBreakDuration = (
  state: TimeTrackingState,
  now: Date,
): number => {
  const currentBreaks = state.breaks.filter((x) => !x.end);
  if (currentBreaks.length !== 1) {
    return 0;
  }
  const currentBreak = currentBreaks[0];
  return differenceInMinutes(now, currentBreak.start);
};

const getCurrentScheduledBreak = (
  breaksScheduled: BreakScheduled[],
  now: Date,
): BreakScheduled | undefined =>
  breaksScheduled.find((reminder) =>
    isInsideInterval(mapBreakScheduledToInterval(reminder, now), now),
  );
