import { useEffect, useState } from 'react';
import { Absence, MyAbsenceFiltersType } from './types';
import { split } from '../../../../../../utils/string';

const useAbsenceFilters = (
  filterData: MyAbsenceFiltersType,
  absences: Absence[],
): Absence[] => {
  const [filteredAbsences, setFilteredAbsences] = useState<Absence[]>(absences);

  useEffect(() => {
    const filterByStatus = (absence: Absence) => {
      const statusIds = split(filterData.status);
      return !!statusIds.length
        ? statusIds.includes(absence.approvalStatus || '')
        : true;
    };

    const filterByType = (absence: Absence) => {
      const typesIds = split(filterData.type);
      return !!typesIds.length
        ? typesIds.includes(absence.categoryName || '')
        : true;
    };

    const filteredAbsences = absences
      .filter(filterByStatus)
      .filter(filterByType);

    setFilteredAbsences(filteredAbsences);
  }, [absences, setFilteredAbsences, filterData.status, filterData.type]);

  return filteredAbsences;
};

export default useAbsenceFilters;
