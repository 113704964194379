import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Theme } from '../../../../../../theme';

type Props = {
  rating: number;
  fontSize?: string;
  fontWeight?: string;
  startAngle?: number;
  endAngle?: number;
  height?: number;
  width?: number;
};

export const RatingChart: React.FC<Props> = (props) => {
  const {
    rating,
    fontSize = '2.75rem',
    fontWeight = '2.375rem',
    startAngle = -95,
    endAngle = 95,
    height,
    width,
  } = props;

  const convertRatingToPercentage = (): number => {
    const maxRating = 5;
    return (rating / maxRating) * 100;
  };

  const chartData: ApexOptions = {
    chart: {
      type: 'radialBar',
      sparkline: {
        enabled: false,
      },
      parentHeightOffset: 0,
    },
    plotOptions: {
      radialBar: {
        startAngle,
        endAngle,
        track: {
          background: Theme.color.gray5,
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: false,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 4,
            color: Theme.color.teal1,
            fontSize,
            fontWeight,
            formatter(): string {
              return `${rating}`;
            },
          },
        },
      },
    },
    fill: {
      colors: [Theme.color.teal1],
    },
    stroke: {
      lineCap: 'round',
      curve: 'smooth', // Changed to 'smooth' for a smoother curve
    },
  };

  const series = [convertRatingToPercentage()];

  return (
    <ReactApexChart
      options={chartData}
      series={series}
      type="radialBar"
      height={height}
      width={width}
    />
  );
};
