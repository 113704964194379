import * as React from 'react';

const CaretUpOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M12.9344 10.059L8.56735 4.96269C8.49696 4.88059 8.40964 4.81469 8.31139 4.76951C8.21313 4.72432 8.10626 4.70093 7.99811 4.70093C7.88997 4.70093 7.7831 4.72432 7.68484 4.76951C7.58659 4.81469 7.49927 4.88059 7.42887 4.96269L3.06178 10.059C2.645 10.5454 2.99054 11.2968 3.63102 11.2968H12.3665C13.0069 11.2968 13.3525 10.5454 12.9344 10.059Z"
        fill="black"
      />
    </svg>
  );
});

export default CaretUpOutlineIcon;
