import * as React from 'react';
import FlairIcon, {
  FlairIconName,
} from '../../../../../../atomic/atoms/FlairIcon';
import { FlairLabel } from '../../../../../../atomic/atoms/Flairlabel/FlairLabel';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import './EmptyStateContent.css';

type Props = {
  title?: string;
  helperText?: string;
  icon?: FlairIconName;
  contentClassName?: string;
  showResetFiltersBtn?: boolean;
};

const i18Path = 'dashboard.emptyState';

const EmptyStateContent: React.FC<Props> = (props) => {
  const t = useNamespacedTranslation(i18Path);

  const {
    icon = 'potted-plant',
    title = t('title'),
    helperText = t('helperText'),
  } = props;

  return (
    <div className="d-flex flex-column align-items-center gap-2-5 text-center empty-state-content pt-3 pb-2-5">
      <FlairIcon icon={icon} size="xxl" />
      <FlairLabel text={title} className="h4-medium" />
      <FlairLabel text={helperText} className="h4-regular helper-text" />
      {/*//TODO:: add reset filter*/}
      {/*<div className={classNames(contentClassName, 'mb-3-5')}>*/}
      {/*  {children}*/}
      {/*  {showResetFiltersBtn && (*/}
      {/*    <Button label={t('resetFilters')} variant="outline-primary" />*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
};

export default EmptyStateContent;
