import {
  TimeSheetTrackedTimeDayFragment,
  TimesheetTrackedTimeFieldsFragment,
} from '../../__generated__/graphql';
import { minutesToHours } from '../../../../utils/time';

export type TrackedTime = TimesheetTrackedTimeFieldsFragment;
export type TrackedTimeDay = TimeSheetTrackedTimeDayFragment;

export const createEmptyTimesheetTracked = (
  timesheetId: string,
): TrackedTime => ({
  timesheetId,
  totalTrackedMinutes: 0,
  totalDaysTracked: 0,
  totalWorkedMinutes: 0,
  days: [],
});

const MAX_WORKING_HOURS = 10;

export type TrackedTimeWarnings = {
  workingHoursExceeded: TrackedTimeDay[];
};

export const calcWarnings = (trackedTime: TrackedTime): TrackedTimeWarnings =>
  trackedTime.days.reduce<TrackedTimeWarnings>(
    (result, trackedDay) => {
      if (minutesToHours(trackedDay.trackedMinutes) > MAX_WORKING_HOURS) {
        return {
          workingHoursExceeded: [...result.workingHoursExceeded, trackedDay],
        };
      }

      return result;
    },
    { workingHoursExceeded: [] },
  );
