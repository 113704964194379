import React, { useCallback } from 'react';
import {
  SelectComponentsConfig,
  components as selectComponents,
  GroupBase,
  SingleValue,
} from 'react-select';
import {
  SelectSingleStyled,
  SelectSingleStyledProps,
} from '../Select/SelectSingleStyled';
import { OptionBase } from './types';

export type Props = Omit<
  SelectSingleStyledProps<OptionBase>,
  'options' | 'value' | 'onChange'
> & {
  value: string | null;
  options: OptionBase[];
  onChange: (value: string | null) => void;
};

const defaultComponents: SelectComponentsConfig<
  OptionBase,
  false,
  GroupBase<OptionBase>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <Option data={data} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <Option data={data} />
    </selectComponents.Option>
  ),
};

const Option: React.FC<{ data: OptionBase }> = ({ data: { label } }) => {
  return <span>{label}</span>;
};

export const SelectDropdown: React.FC<Props> = ({
  options,
  value,
  onChange,
  ...props
}) => {
  const handleOnChange = useCallback(
    (value: SingleValue<OptionBase>) => {
      onChange(value?.value ?? null);
    },
    [onChange],
  );

  return (
    <SelectSingleStyled
      {...props}
      options={options}
      value={options.find((o) => o.value === value) ?? null}
      onChange={handleOnChange}
      components={defaultComponents}
      menuPlacement={'auto'}
      isClearable={false}
    />
  );
};
