import * as React from 'react';
import { UpdatesFromServer } from '../types';
import AnnouncementUpdateCard from '../AnnouncementUpdateCard';
import CelebrationUpdateCard from '../CelebartionUpdateCard';
import NewJoinerUpdateCard from '../NewJoinerUpdateCard';
import EmptyStateContent from '../../CommonComponents/EmptyStateContent';

type Props = {
  updates: ReadonlyArray<UpdatesFromServer>;
};

const Updates: React.FC<Props> = ({ updates }) => {
  if (updates.length === 0) {
    return <EmptyStateContent />;
  }

  return (
    <>
      {updates.map((u) => {
        switch (u.__typename) {
          case 'Announcement':
            return (
              <AnnouncementUpdateCard key={u.Id} announcementFragment={u} />
            );
          case 'Celebration':
            return <CelebrationUpdateCard key={u.Id} celebrationFragment={u} />;
          case 'Colleague':
            return <NewJoinerUpdateCard key={u.Id} newJoinerFragment={u} />;
        }

        return null;
      })}
    </>
  );
};

export default Updates;
