import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import ToggleGroup from '../../../../../components/button/ToggleGroup';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { SelectSingle } from '../../../components/Selects/SelectSingle';
import { OrgChartEmployee } from '../types';

export type Props = {
  searchedId: string | null;
  setSearchedId: Dispatch<SetStateAction<string | null>>;
  employees: OrgChartEmployee[];
  searchMode: string;
  setSearchMode: Dispatch<SetStateAction<string>>;
};

const i18Path = 'people.orgChart';

export const Search: React.FC<Props> = ({
  searchedId,
  setSearchedId,
  employees,
  searchMode,
  setSearchMode,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const searchOptions = [
    { name: 'find', title: t('find') },
    { name: 'focus', title: t('focus') },
  ];

  return (
    <div className={classNames('d-flex', 'align-items-center', 'gap-3')}>
      <StyledSelectSingle
        options={employees.map((e) => ({ value: e.id, label: e.name }))}
        value={searchedId}
        placeholder={t('search')}
        onChange={setSearchedId}
        renderOption={({ option }) => <div>{option.label}</div>}
      />
      <ToggleGroup
        value={searchMode}
        defaultValue={searchMode}
        buttons={searchOptions}
        onChange={setSearchMode}
        variant="secondary"
      />
    </div>
  );
};

const StyledSelectSingle = styled(SelectSingle)({
  width: '190px',
});
