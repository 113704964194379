import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AutoSaveIndicator } from '../../../../../components/form/AutoSaveIndicator';
import { FormGroup } from '../../../../../components/form/FormGroup';
import { WorkflowItemStatus } from '../WorkflowItems/types';
import { WorkflowItemStatusFieldReadonly } from './WorkflowItemStatusFieldReadonly';
import { WorkflowItemStatusSelect } from './WorkflowItemStatusSelect';
import StatusFieldContainer from './WorkflowItemStatusField/StatusFieldContainer';

type Props = {
  value: WorkflowItemStatus;
  onChange: (status: WorkflowItemStatus) => void;
  readonly: boolean;
  saving: boolean;
  saved: boolean;
  disabled?: boolean;
};

export const WorkflowItemStatusAutoSave: React.FC<Props> = ({
  value,
  onChange,
  readonly,
  saving,
  saved,
  disabled = false,
}) => {
  const { t } = useTranslation();

  let content;

  if (readonly) {
    content = <WorkflowItemStatusFieldReadonly status={value} />;
  } else {
    content = (
      <FormGroup>
        <Form.Label>
          {t('performanceReview.workflowsItems.sidebar.status')}
          <div className="d-inline-block ms-2">
            <AutoSaveIndicator loading={saving} completed={saved} />
          </div>
        </Form.Label>
        <div>
          <WorkflowItemStatusSelect
            name="status"
            value={value}
            isDisabled={disabled}
            onChange={(e) => {
              onChange(e!);
            }}
          />
        </div>
      </FormGroup>
    );
  }

  return <StatusFieldContainer>{content}</StatusFieldContainer>;
};
