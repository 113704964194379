import { CalendarAbsence, CalendarDay } from './types';
import React, { CSSProperties } from 'react';
import moment from 'moment';
import { getAbsenceTheme } from './Utils';
import { isNonEmptyArray } from '../../../../utils/array';
import { Theme } from '../../../../theme';

type AbsenceDisplayProps = {
  isHovered?: boolean;
  absences?: CalendarAbsence[];
  day: CalendarDay;
};

const initialStyle: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  width: '100%',
  height: '50%',
  left: 0,
  right: 0,
  transform: 'translateY(-50%)',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
};

const AbsenceDisplay: React.FC<AbsenceDisplayProps> = ({
  absences,
  day,
  isHovered,
}) => {
  if (absences && isNonEmptyArray<CalendarAbsence>(absences)) {
    const absenceStartDate = moment(absences[0].flair__Start_Date__c);
    const absenceEndDate = moment(absences[0].flair__End_Date__c);

    const startOfAbsence = day.day.isSame(absenceStartDate, 'date');
    const endOfAbsence = day.day.isSame(absenceEndDate, 'date');

    const theme = getAbsenceTheme(absences, day.day);

    const getStyles = (): CSSProperties => {
      const borderRadius = Theme.border.radius;
      let style = {
        ...initialStyle,
        background: theme.background || theme.bgColor,
        border: `1px solid ${theme.bgColor}`,
        opacity: isHovered ? '0.5' : '1',
      };

      if (startOfAbsence) {
        style = {
          ...style,
          borderBottomLeftRadius: borderRadius,
          borderTopLeftRadius: borderRadius,
          marginLeft: '5%',
        };
      }
      if (endOfAbsence) {
        style = {
          ...style,
          borderBottomRightRadius: borderRadius,
          borderTopRightRadius: borderRadius,
          marginLeft: '-5%',
        };
      }
      if (startOfAbsence && endOfAbsence) {
        style = {
          ...style,
          width: '90%',
          margin: 'auto',
        };
      }
      return style;
    };

    return (
      <div style={getStyles()}>
        {startOfAbsence && (
          <i
            style={{ color: theme.iconColor }}
            className={`fe ${theme.icon} fs-4`}
          />
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default React.memo(AbsenceDisplay);
