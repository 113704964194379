import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Theme } from '../../../../theme';
import { LoomVideo } from './types';
import { oembed } from '@loomhq/loom-embed';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { isSupported } from '@loomhq/record-sdk';
import { removeUrlQueryString } from './removeUrlQueryString';
import { isLoomUrl } from './isLoomUrl';

type Props = {
  loomVideo: LoomVideo;
  className?: string;
  previewWidth?: number;
  previewHeight?: number;
  onPlay?: () => void;
};

const i18prefix = 'components.loomVideo';

export const LoomVideoPreview: React.FC<Props> = ({
  loomVideo,
  previewWidth,
  previewHeight,
  className,
  onPlay,
}) => {
  const t = useNamespacedTranslation(i18prefix);

  let width: string | number = '100%';
  let height: string | number = '100%';

  if (previewWidth) {
    const size = calculateFrameSize(loomVideo, {
      previewWidth,
      previewHeight,
    });
    width = size.width;
    height = size.height;
  }

  const [videoExists, setVideoExists] = useState<boolean | undefined>(
    undefined,
  );

  useEffect(() => {
    async function setupLoom() {
      if (!isLoomUrl(loomVideo.sharedUrl)) return;
      const { supported, error } = await isSupported();

      if (!supported) {
        throw new Error(`Loom video setup failed: ${error}`);
      }

      oembed(removeUrlQueryString(loomVideo.sharedUrl))
        .then(() => setVideoExists(true))
        .catch(() => {
          setVideoExists(false);
        });
    }

    setupLoom().then();
  }, [loomVideo, setVideoExists]);

  const renderContent = () => {
    if (!isLoomUrl(loomVideo.sharedUrl)) {
      return (
        <TextContainer>
          <div>{t('videoNotSupported')}</div>
          <small>{t('videoNotSupportedDescription')}</small>
        </TextContainer>
      );
    }

    return !isVideoNotFoundVisible ? (
      <iframe
        className={className}
        src={loomVideo.embedUrl}
        frameBorder="0"
        width={width}
        height={height}
        title="video-preview"
        allowFullScreen
        //TODO:: Doesn't work, we can skip so far and check it later (FEA-535)
        onPlay={onPlay}
      />
    ) : (
      <TextContainer>
        <div>{t('videoNotFound')}</div>
        <small>{t('videoNotFoundDescription')}</small>
      </TextContainer>
    );
  };

  const isVideoNotFoundVisible = videoExists === false;
  return (
    <Container
      style={{
        width: previewWidth,
        height: previewHeight ?? height,
      }}
      className={className}>
      {renderContent()}
    </Container>
  );
};

const calculateFrameSize = (
  video: Pick<LoomVideo, 'width' | 'height'>,
  previewConstrains: { previewWidth: number; previewHeight?: number },
) => {
  const aspectRatio = video.width / video.height;
  const width = Math.min(previewConstrains.previewWidth, video.width);
  let height = width / aspectRatio;
  if (previewConstrains.previewHeight) {
    height = Math.min(
      previewConstrains.previewHeight,
      video.width / aspectRatio,
    );
  }
  return { width, height };
};

const Container = styled.div({
  backgroundColor: Theme.color.black,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TextContainer = styled.div({
  color: Theme.color.white,
  textAlign: 'center',
});
