import * as React from 'react';

const SearchFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M14.2695 13.1664L11.3299 10.2268C12.0376 9.28463 12.4197 8.13782 12.4184 6.95944C12.4184 3.94953 9.9696 1.50073 6.95969 1.50073C3.94977 1.50073 1.50098 3.94953 1.50098 6.95944C1.50098 9.96936 3.94977 12.4182 6.95969 12.4182C8.13806 12.4194 9.28487 12.0374 10.227 11.3297L13.1667 14.2693C13.3155 14.4023 13.5095 14.4733 13.7091 14.4677C13.9086 14.4621 14.0984 14.3804 14.2395 14.2393C14.3806 14.0981 14.4624 13.9083 14.468 13.7088C14.4735 13.5093 14.4025 13.3152 14.2695 13.1664ZM3.06061 6.95944C3.06061 6.18828 3.28929 5.43443 3.71772 4.79323C4.14616 4.15203 4.75511 3.65228 5.46757 3.35716C6.18004 3.06205 6.96401 2.98484 7.72036 3.13528C8.47671 3.28573 9.17146 3.65708 9.71675 4.20238C10.262 4.74767 10.6334 5.44242 10.7838 6.19877C10.9343 6.95512 10.8571 7.73909 10.562 8.45156C10.2669 9.16402 9.7671 9.77297 9.1259 10.2014C8.4847 10.6298 7.73085 10.8585 6.95969 10.8585C5.92597 10.8573 4.93494 10.4461 4.20399 9.71514C3.47304 8.98419 3.06185 7.99316 3.06061 6.95944Z"
        fill="black"
      />
    </svg>
  );
});

export default SearchFilledIcon;
