import React, { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { MAX_TOP_SKILLS_NUMBER, SkillLevel } from '../common/types';
import { RemoveEmployeeSkillDialogConnected } from '../dialog/RemoveEmployeeSkillDialogConnected';
import { mapEmployeeSkillAssociation } from '../common/mappings';
import { EmployeeSkillsTable } from '../common/EmployeeSkillsTable';
import {
  useUpdateSkillRate,
  useUpdateSkillTop,
} from '../common/useEmployeeSkillsMutations';
import {
  useCanEditSkills,
  useEmployeeSkills,
} from '../common/useEmployeeAvailableSkills';
import { SkillsChoosenStatus } from '../common/SkillsChoosenStatus';

type Props = {
  employeeId: string;
  employeeName: string;
};

export const EmployeeSkillsSidebarConnected: React.FC<Props> = ({
  employeeId,
  employeeName,
}) => {
  const tRemoveDialog = useNamespacedTranslation('skills.removeEmployeeSkill');

  const { data, loading, error } = useEmployeeSkills(employeeId);

  const { updateSkillTop } = useUpdateSkillTop();
  const { updateSkillRate } = useUpdateSkillRate();

  const hasAccess = useCanEditSkills(employeeId);

  const employeeSkills = data?.activeEmployees
    .flatMap((x) => x.employeeSkills)
    .map(mapEmployeeSkillAssociation);

  const [skillToRemove, setSkillToRemove] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const handleRemoveSkill = useCallback(
    (id: string) => {
      const skill = employeeSkills?.find((x) => x.id === id);
      if (skill) {
        setSkillToRemove({ id, name: skill.name });
      }
    },
    [setSkillToRemove, employeeSkills],
  );

  const handleRemoveSkillDialogClose = useCallback(() => {
    setSkillToRemove(null);
  }, [setSkillToRemove]);

  const handleChooseSkill = useCallback(
    (id: string, isChoosen: boolean) => {
      updateSkillTop({
        id,
        employeeId,
        top: isChoosen,
      });
    },
    [updateSkillTop, employeeId],
  );

  const handleChangeSkillLevel = useCallback(
    (id: string, level: SkillLevel) => {
      updateSkillRate({
        id,
        employeeId,
        rate: level,
      });
    },
    [updateSkillRate, employeeId],
  );

  if (error) {
    return <ServerError />;
  }

  if (loading || !employeeSkills) {
    return <Loading />;
  }

  const totalSkills = employeeSkills.length;
  const choosenSkills = employeeSkills.filter((x) => x.isTop).length;

  return (
    <>
      <SkillsChoosenStatus
        className="mx-4 mb-2"
        totalSkills={totalSkills}
        choosenSkills={choosenSkills}
      />
      {!hasAccess && <NoEditAlert />}
      <EmployeeSkillsTable
        employeeSkills={employeeSkills}
        onChooseSkill={handleChooseSkill}
        onChangeSkillLevel={handleChangeSkillLevel}
        onRemoveSkill={handleRemoveSkill}
        maxTopLimit={MAX_TOP_SKILLS_NUMBER}
        isReadonly={!hasAccess}
      />
      <RemoveEmployeeSkillDialogConnected
        onClose={handleRemoveSkillDialogClose}
        skillIdToRemove={skillToRemove?.id ?? null}
        employeeId={employeeId}
        removeSkillText={tRemoveDialog('removeSkillTextForManager', {
          skill: skillToRemove?.name,
          employee: employeeName,
        })}
      />
    </>
  );
};

const NoEditAlert: React.FC = () => {
  const t = useNamespacedTranslation('skills.employeeSkills');
  return (
    <div className="mx-4 mt-0 mb-2 alert alert-light small text-center">
      {t('noEditAlert')}
    </div>
  );
};

const Loading: React.FC = () => {
  return (
    <div>
      <Skeleton height={18} width="50%" className="mx-4 mb-3" />
      <SkeletonTable columns={3} rows={5} />
    </div>
  );
};
