import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { QuestionnaireQuestionNotes } from '../../../QuestionnaireQuestionNotes';
import { RatingCard } from './RatingCard';
import './Rating.css';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';

export type RatingInputType = {
  value: string;
  onChange: (value: string) => void;
  choices: ReadonlyArray<string>;
  notes: string;
  onChangeNotes: (value: string) => void;
  readOnly: boolean;
  showOptionalComment?: boolean;
};

const i18Path = 'employeePage.performanceReview';

export const Rating: React.FC<RatingInputType> = ({
  value,
  onChange,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const handleChange = useCallback(
    (value) => {
      if (readOnly) return;
      onChange && onChange(String(value));
    },
    [onChange, readOnly],
  );
  const handleGetNegativeChoice = () => {
    if (choices && choices[0]) return choices[0];
    return t('choices.awful');
  };

  const handleGetPositiveChoice = () => {
    if (choices && choices[1]) return choices[1];
    return t('choices.amazing');
  };

  const ratingScores = ['1', '2', '3', '4', '5'];
  return (
    <Row>
      <Col className="mx-3 mb-4">
        <Row className="align-items-center d-inline-block w-100">
          <Col className="w-100 rating-card-group-container">
            {ratingScores.map((score) => (
              <RatingCard
                disabled={readOnly}
                value={score}
                handleChange={handleChange}
                selected={score === value}></RatingCard>
            ))}
          </Col>
          <Col className="d-flex gap-4 w-100 align-items-center justify-content-between pe-0">
            <div className={`rating-value-text ${readOnly && 'disabled'}`}>
              {handleGetNegativeChoice()}
            </div>
            <div className={`rating-value-border ${readOnly && 'disabled'}`} />
            <div className={`rating-value-text ${readOnly && 'disabled'}`}>
              {handleGetPositiveChoice()}
            </div>
          </Col>
        </Row>
      </Col>

      <Col xs={12}>
        {showOptionalComment && (
          <QuestionnaireQuestionNotes
            value={notes}
            onChange={onChangeNotes}
            readOnly={readOnly}
          />
        )}
      </Col>
    </Row>
  );
};
