import { NavigatableType } from '../../__generated__/graphql';

export type Folder = {
  id: string;
  name: string;
  // ...
};

export type CorporateDocumentFilter = {
  documentName: string | null;
  addedBy: string | null;
  month: string | null;
  year: string | null;
};

export const emptyCorporateDocumentFilter: CorporateDocumentFilter = {
  documentName: null,
  addedBy: null,
  month: null,
  year: null,
};

export type PersonalDocumentFilter = {
  category: string | null;
  documentName: string | null;
  addedBy: string | null;
  status: string | null;
  month: string | null;
  year: string | null;
};

export const emptyPersonalDocumentFilter: PersonalDocumentFilter = {
  category: null,
  documentName: null,
  addedBy: null,
  status: null,
  month: null,
  year: null,
};

export type DocumentStatus = 'APPROVED' | 'PENDING' | 'REJECTED';

export const allDocumentStatuses: DocumentStatus[] = [
  'APPROVED',
  'PENDING',
  'REJECTED',
];

export type DocumentEmployee = {
  id: string;
  name: string;
  avatarUrl?: string;
};
export type DocumentBase = {
  id: string;
  name: string;
  employee: DocumentEmployee;
  addedOn: string;
  type: DocumentFilesType;
  files?: File[];
};

export type Navigatable = DocumentBase & {
  category: string;
  categoryId: string | null;
  status: DocumentStatus | null;
  typeName: NavigatableType;
  recordId: string | null;
  fullPath: string;
  note: string | null;
  commentsCount: number;
  canDelete: boolean;
};

export type CorporateDocument = DocumentBase;

type FilePublicLink = {
  downloadLink: string | undefined;
  viewLink: string | undefined;
};

export type File = {
  id: string;
  documentId: string;
  type: string;
  extension: string | null;
  title: string;
  size: number;
  typeName: NavigatableType;
  publicLink?: FilePublicLink;
};

export type DocumentPreview = {
  id: string;
  title: string;
  note: string | null;
  category: string | null;
  location: string;
  employee?: DocumentEmployee;
  size: number;
  type: string;
  typeName: NavigatableType;
  status: DocumentStatus | null;
  downloadLink: string | undefined;
  viewLink: string | undefined;
  recordId: string | null;
  categoryId: string | null;
};

export type DocumentFilesType = { filesCount: number; type: string };

export type Breadcrumb = {
  id: string;
  name: string;
};

export const createRootFolderId = (employeeId: string): string =>
  `ROOT_FOLDER-${employeeId}`;
