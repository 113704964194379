import { EmployeeDetailsFragment } from '../../../../__generated__/graphql';
import { EmployeeDetails } from './types';

export const mapEmployeeDetails = (
  employee: EmployeeDetailsFragment,
): EmployeeDetails => ({
  id: employee.Id,
  name: employee.Name,
  location: employee.location?.Name,
  position: employee.flair__Position__c,
  companyEmail: employee.flair__Company_Email__c,
  department: employee.department?.Name,
  startDate: employee.flair__Start_Date__c,
});
