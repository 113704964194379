import { useMemo } from 'react';
import { Breadcrumb, createRootFolderId } from '../types';
import { defaultBreadcrumbState } from '../mappings';
import {
  PersonalDocumentsFoldersQuery,
  PersonalDocumentsQuery,
} from '../../../__generated__/graphql';

type Props = {
  currentFolderId: string;
  employeeId: string;
  rootFolderId: string;
  data: PersonalDocumentsQuery | PersonalDocumentsFoldersQuery | undefined;
};
export const useBreadcrumbs = (props: Props) => {
  const { rootFolderId, currentFolderId, data, employeeId } = props;
  const breadcrumbs = useMemo(() => {
    if (!data?.navigatable?.parents) return;
    const { navigatable } = data;
    const parentFolders = data?.navigatable.parents.map((item: any) => {
      return { id: item.Id, name: item.Name };
    });
    const isRootFolder = navigatable.Id === rootFolderId;
    const currentFolder = { id: navigatable.Id, name: navigatable?.Name };
    const defaultBreadcrumbs = defaultBreadcrumbState(rootFolderId);
    return isRootFolder
      ? defaultBreadcrumbs
      : defaultBreadcrumbs.concat(parentFolders).concat(currentFolder);
  }, [data, rootFolderId]);

  const getNextFolderId: (breadcrumb: Breadcrumb) => string = (
    breadcrumb: Breadcrumb,
  ) => {
    if (breadcrumb.id === currentFolderId) {
      return currentFolderId;
    }
    let newFolderId = rootFolderId;
    if (breadcrumb.id !== rootFolderId) {
      newFolderId = breadcrumb.id ?? createRootFolderId(employeeId);
    }
    return newFolderId;
  };

  return {
    breadcrumbs: breadcrumbs ? breadcrumbs : [],
    getNextFolderId,
  };
};
