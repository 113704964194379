import React from 'react';
import { Theme } from '../../../../../theme';

export type Props = {
  highlight?: boolean;
  isProcessing?: boolean;
  buttons?: React.ReactNode;
  children: React.ReactNode;
};

const highlightStyle = {
  backgroundColor: Theme.color.paper6,
  borderTop: `solid 1px ${Theme.color.paper4}`,
  borderBottom: `solid 1px ${Theme.color.paper4}`,
};

export const TimeEntryCardInPopoverLayout: React.FC<Props> = ({
  highlight,
  isProcessing,
  children,
  buttons = null,
}) => {
  // TODO: I Will clean up comments there in next PR
  return (
    <div className="h7-regular my-3 position-relative">
      {/* {isProcessing && (
        <ProcessingContainer>
          <Skeleton width="100%" height="100%"></Skeleton>
        </ProcessingContainer>
      )} */}
      <div className="p-3" style={highlight ? highlightStyle : {}}>
        {children}
        <div className="mt-2 d-flex justify-content-end gap-1">{buttons}</div>
      </div>
    </div>
  );
};

// const ProcessingContainer = styled.div({
//   lineHeight: 1,
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   position: 'absolute',
//   opacity: 0.5,
//   zIndex: 100,
// });
