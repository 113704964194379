import React from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { NavLinkLabel } from '../../../atoms/navigation/NavLinkLabel';
import { NavLinkIcon } from '../../../atoms/navigation/NavLinkIcon';
import { SubNav } from '../../../atoms/navigation/SubNav';
import { SubNavItem } from '../SubNavItem/SubNavItem';
import { MenuItemType } from '../types';
import { useNavItemToggle } from '../../../../hooks/useNavItemToggle';

export type NavItemWithSubItemsProps = {
  menuItem: MenuItemType;
  onSubNavItemClick?: () => void;
};

export const NavItemWithSubItems: React.FC<NavItemWithSubItemsProps> = ({
  menuItem,
  onSubNavItemClick,
}) => {
  const { open, handleClick } = useNavItemToggle(menuItem);

  return (
    <Nav.Item>
      <Nav.Link
        onClick={handleClick}
        className={classNames('d-flex', 'align-items-center')}
        data-bs-toggle="collapse"
        aria-expanded={open}>
        <NavLinkIcon isActive={open}>
          {open ? menuItem.activeIcon : menuItem.icon}
        </NavLinkIcon>
        <NavLinkLabel data-testid={menuItem.label}>
          {menuItem.label}
        </NavLinkLabel>
      </Nav.Link>
      <Collapse in={open}>
        <div>
          <SubNav>
            {menuItem.subItems?.map((item) => (
              <SubNavItem
                key={item.route.template()}
                menuItem={item}
                onClick={onSubNavItemClick}
              />
            ))}
          </SubNav>
        </div>
      </Collapse>
    </Nav.Item>
  );
};
