import * as React from 'react';

const AddCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M12.9986 3.99976L6.00027 11.9979L3.00098 8.99858"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default AddCircleOutlineIcon;
