import React from 'react';
import { routes as r } from '../../../routes';
import PersonalData from '../PersonalData';

export const routes = () => ({
  overview: {
    route: r.personalData.route,
    render: () => <PersonalData />,
  },
});
