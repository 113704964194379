import { useState } from 'react';
import { useTimeTrackingSettingsAllDays } from './useTimeTrackingSettingsAllDays';
import { toISODateOnly } from '../../../../utils/dateUtils';

export const useTimeTrackingRestriction = () => {
  const { hasOngoingRestrictedAbsenceForDay, loading } =
    useTimeTrackingSettingsAllDays();

  const [showRestrictedWarningModal, setShowRestrictedWarningModal] =
    useState<boolean>(false);

  return {
    hasOngoingRestrictedAbsence: (now: Date) =>
      hasOngoingRestrictedAbsenceForDay(toISODateOnly(now), now),
    hasOngoingRestrictedAbsenceForDay,
    showRestrictedWarningModal,
    setShowRestrictedWarningModal,
    timeTrackingRestrictedAbsenceLoading: loading,
  };
};
