import React from 'react';
import ShiftsDailyBlock from './ShiftDailyBlock';
import styled from '@emotion/styled';
import { Moment } from 'moment';
import { PureQueryOptions } from '@apollo/client';
import {
  DailyOpenShiftInfo,
  DailyShiftInfo,
  ShiftBlockStylingProp,
} from './dailyShiftsTypes';
import { getDailyShiftBlockStylingProp } from './dailyShiftsLogic';

const ShiftBlockContainer = styled.div<{
  shiftBlockStylingProp: ShiftBlockStylingProp;
  rowIndex: number;
}>(
  {
    position: 'absolute',
    minWidth: '48px',
  },
  ({ shiftBlockStylingProp: { width, left }, rowIndex }) => ({
    width: width + '%',
    left: left + '%',
    top: `${(rowIndex + 1) * 22 + rowIndex * 12}px`,
  }),
);

type Props = {
  shift: DailyShiftInfo | DailyOpenShiftInfo;
  isOpenShift?: boolean;
  isCurrentEmployee?: boolean;
  minHour: number;
  maxHour: number;
  day: Moment;
  refetchQueries: Array<PureQueryOptions>;
  hoursCount: number;
};

const ShiftRow: React.FC<Props> = ({
  shift,
  isCurrentEmployee,
  minHour,
  maxHour,
  day,
  refetchQueries,
  hoursCount,
}) => {
  let shiftBlockStylingProp = getDailyShiftBlockStylingProp(
    shift,
    day,
    minHour,
    maxHour,
    hoursCount,
    isCurrentEmployee,
  );

  return (
    <ShiftBlockContainer
      shiftBlockStylingProp={shiftBlockStylingProp}
      rowIndex={shift.rowIndex}
      key={shift.start.toString()}>
      <ShiftsDailyBlock
        day={day}
        shift={shift}
        shiftBlockStylingProp={shiftBlockStylingProp}
        refetchQueries={refetchQueries}
      />
    </ShiftBlockContainer>
  );
};

export default ShiftRow;
