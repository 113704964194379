import * as React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

type Props = {
  text: string;
};

const StringToHtml: React.FC<Props> = ({ text }) => {
  return parse(DOMPurify.sanitize(text));
};

export default StringToHtml;
