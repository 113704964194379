import React, { useContext, useMemo } from 'react';
import { useHourlyAbsenceAmountFormatter } from '../../../../../hooks/useHourlyAbsenceAmountFormatter';
import { Col, Row } from 'react-bootstrap';
import VacationDayImg from '../../../../../../../vendor/img/absence/vacation-day.png';
import GenericAbsenceImg from '../../../../../../../vendor/img/absence/generic-absence.png';
import TrainingLeaveImg from '../../../../../../../vendor/img/absence/training-leave.png';
import SickLeaveImg from '../../../../../../../vendor/img/absence/sick-leave.png';
import { AmountLabel } from './AmountLabel';
import { iconToCategoryType } from '../../Utils';
import { CategoryAccordionBodyContext } from '../CategoryAccordionBodyContext';
import { AbsenceCategorySummary, PeriodType } from '../types';
import { getMainPeriodLabelAmount } from '../helpers';
import { CategoryType } from '../../../types';

const BackgroundImageMap = {
  vacation: VacationDayImg,
  special: GenericAbsenceImg,
  sick: SickLeaveImg,
  homeoffice: TrainingLeaveImg,
  trip: GenericAbsenceImg,
};

type Props = {
  periodType: PeriodType;
  summary: AbsenceCategorySummary;
  category: CategoryType;
};

export const CategoryAccordionBodyTitle: React.FC<Props> = (props) => {
  const { isHourly, category } = useContext(CategoryAccordionBodyContext);
  const hourlyFormatter = useHourlyAbsenceAmountFormatter();

  const categoryImage = useMemo(() => {
    if (!category) {
      return iconToCategoryType('vacation');
    }
    return BackgroundImageMap[
      iconToCategoryType(category.category.flair__Icon__c)
    ];
  }, [category]);

  const amount = getMainPeriodLabelAmount(props.summary, props.periodType);

  return (
    <Row className="no-gutters align-items-center justify-content-center">
      {isHourly ? (
        <Col className="col-auto">
          <h1 className="display-2 mb-0">
            {amount ? hourlyFormatter(amount) : '0'}
          </h1>
        </Col>
      ) : (
        <div className="position-relative">
          <img
            src={categoryImage}
            alt={'VacationDayImg'}
            className="img-fluid w-100 h-auto align-items-center border-top border-bottom"
          />
          <AmountLabel {...props} amount={amount} />
        </div>
      )}
    </Row>
  );
};
