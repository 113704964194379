import React from 'react';
import { Table } from 'react-bootstrap';
import Pagination from '../../components/Pagination';

import {
  Cell,
  CellPropGetter,
  ColumnInstance,
  HeaderPropGetter,
  Row,
  RowPropGetter,
  UseTableInstanceProps,
} from 'react-table';
import classNames from 'classnames';

type PaginationInfo = {
  showPagination: boolean;
  pageCount: number;
  pageIndex: number;
  pageSize: number | undefined;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  previousPage: () => void;
  nextPage: () => void;
};

export type TableContentCommonProps<T extends object> = Pick<
  UseTableInstanceProps<T>,
  'getTableProps' | 'headers' | 'getTableBodyProps' | 'prepareRow'
> & {
  pagination: PaginationInfo;
  getRowProps?: (row: Row<T>) => RowPropGetter<T>;
  getColumnProps?: (column: ColumnInstance<T>) => CellPropGetter<T>;
  getCellProps?: (cell: Cell<T>) => CellPropGetter<T>;
  getHeaderProps?: (column: ColumnInstance<T>) => HeaderPropGetter<T>;
  onRowClick?: (row: T) => void;
  rowsToRender: Row<T>[];
};

const defaultPropGetter = () => ({});

export const TableContentCommon = <T extends object>({
  pagination,
  getTableProps,
  headers,
  getTableBodyProps,
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  onRowClick = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  rowsToRender,
  prepareRow,
}: TableContentCommonProps<T>) => {
  const hasNextPage = pagination.pageCount - pagination.pageIndex > 1;
  const goToNextPage = hasNextPage ? pagination.nextPage : () => {};

  return (
    <>
      <Table
        className="card-table table-nowrap"
        size="sm"
        responsive
        hover
        {...getTableProps()}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th {...header.getHeaderProps(getHeaderProps(header))}>
                <span
                  {...header.getSortByToggleProps()}
                  {...(header.isSorted
                    ? {
                        className: header.isSortedDesc ? 'desc' : 'asc',
                        'data-sort': header.id,
                      }
                    : {})}>
                  {header.render('Header')}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rowsToRender.map((row) => {
            prepareRow(row);

            return (
              <tr
                className={classNames({ 'selected-row': row.isSelected })}
                {...row.getRowProps(getRowProps(row))}
                onClick={() => onRowClick(row.original)}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps({
                      ...getColumnProps(cell.column),
                      ...getCellProps(cell),
                    })}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {hasPagination(pagination.pageSize) && pagination.showPagination && (
        <Pagination
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          previousPage={pagination.previousPage}
          nextPage={goToNextPage}
          goToPage={pagination.gotoPage}
        />
      )}
    </>
  );
};

export const hasPagination = (
  pageSize: number | undefined,
): pageSize is number => pageSize !== undefined;
