import * as React from 'react';

const ThermometerOutline = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      {...props}
      ref={ref}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50532 1.35723C8.97416 0.888392 9.61004 0.625 10.2731 0.625C10.9361 0.625 11.572 0.888392 12.0409 1.35723C12.5097 1.82607 12.7731 2.46196 12.7731 3.125V11.3778C13.3291 11.7706 13.7878 12.2867 14.1128 12.8866C14.4644 13.5356 14.6484 14.2621 14.6481 15.0003C14.6475 16.1431 14.1997 17.2404 13.4006 18.0574C12.6014 18.8743 11.5143 19.3461 10.3718 19.3719C9.22923 19.3977 8.12196 18.9754 7.28677 18.1953C6.45158 17.4152 5.95479 16.3393 5.90264 15.1977C5.8681 14.4268 6.03527 13.6598 6.38768 12.9734C6.71449 12.3369 7.1901 11.7897 7.77308 11.3776V3.125C7.77308 2.46196 8.03647 1.82607 8.50532 1.35723ZM11.9545 12.3326C11.8231 12.2483 11.7149 12.1326 11.6395 11.9959C11.5633 11.8576 11.5233 11.7023 11.5231 11.5444L11.5231 3.125C11.5231 2.79348 11.3914 2.47554 11.157 2.24112C10.9225 2.0067 10.6046 1.875 10.2731 1.875C9.94156 1.875 9.62362 2.0067 9.3892 2.24112C9.15478 2.47554 9.02308 2.79348 9.02308 3.125V11.5437C9.02284 11.7013 8.98287 11.8573 8.90688 11.9953C8.83164 12.132 8.72348 12.2478 8.59223 12.3321C8.12892 12.6344 7.75236 13.0522 7.49968 13.5444C7.24665 14.0372 7.12659 14.5875 7.15135 15.141C7.18868 15.9563 7.54352 16.7247 8.14 17.2818C8.73656 17.839 9.52748 18.1407 10.3436 18.1222C11.1597 18.1038 11.9362 17.7668 12.507 17.1833C13.0778 16.5997 13.3976 15.816 13.3981 14.9997M11.9545 12.3326C12.3976 12.6217 12.7617 13.0168 13.0137 13.482C13.2662 13.948 13.3983 14.4697 13.3981 14.9997M10.2731 3.75C10.6183 3.75 10.8981 4.02982 10.8981 4.375V15C10.8981 15.3452 10.6183 15.625 10.2731 15.625C9.92791 15.625 9.64808 15.3452 9.64808 15V4.375C9.64808 4.02982 9.92791 3.75 10.2731 3.75Z"
        fill="currentColor"
      />
      <path
        d="M10.2732 16.875C11.3087 16.875 12.1482 16.0355 12.1482 15C12.1482 13.9645 11.3087 13.125 10.2732 13.125C9.23766 13.125 8.39819 13.9645 8.39819 15C8.39819 16.0355 9.23766 16.875 10.2732 16.875Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ThermometerOutline;
