import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import {
  EmployeeSelectInfoColleagueFragment,
  TeamFragment,
  useEmployeeOrTeamSelectDefaultQuery,
  useEmployeeOrTeamSelectSearchLazyQuery,
} from '../../__generated__/graphql';
import { mapEmployeeOption } from './EmployeeOptionLabel';
import { EmployeeOrTeamOption } from './EmployeeOrTeamOptionLabel';
import {
  SelectMultipleEmployeeOrTeamAsync,
  Props as SelectMultipleEmployeeOrTeamAsyncProps,
} from './SelectMultipleEmployeeOrTeamAsync';
import { mapTeamOption } from './TeamOptionLabel';

export type Props = Omit<
  SelectMultipleEmployeeOrTeamAsyncProps,
  'defaultOptions' | 'loadOptions'
> & {};

const DEFAULT_LOAD_COUNT = 10;
const DEFAULT_SHOW_COUNT = 5;

export const SelectMultipleEmployeeOrTeamAsyncConnected: React.FC<Props> = ({
  values,
  ...props
}) => {
  const [getEmployeeOrTeam] = useEmployeeOrTeamSelectSearchLazyQuery();
  const { data: defaultOptionData } = useEmployeeOrTeamSelectDefaultQuery({
    variables: {
      limit: DEFAULT_LOAD_COUNT,
    },
  });

  const defaultOptions = useMemo(
    () =>
      defaultOptionData
        ? mapOptions(
            defaultOptionData.activeEmployees,
            defaultOptionData.teams,
          ).slice(0, DEFAULT_SHOW_COUNT)
        : undefined,
    [defaultOptionData],
  );

  const handleLoadOptions = (input: string) => {
    return getEmployeeOrTeam({
      variables: {
        searchTerm: input,
      },
    }).then((queryResult) => {
      if (queryResult.error) {
        throw new Error(queryResult.error.message);
      }
      if (!queryResult.data) {
        throw new Error('no data');
      }
      return mapOptions(
        queryResult.data.activeEmployees,
        queryResult.data.teams,
      );
    });
  };

  return (
    <SelectMultipleEmployeeOrTeamAsync
      {...props}
      values={values}
      defaultOptions={defaultOptions}
      loadOptions={handleLoadOptions}></SelectMultipleEmployeeOrTeamAsync>
  );
};

const mapOptions = (
  queryEmployees: readonly EmployeeSelectInfoColleagueFragment[],
  queryTeams: readonly TeamFragment[],
): EmployeeOrTeamOption[] => {
  const employees: EmployeeOrTeamOption[] = queryEmployees
    .map(mapEmployeeOption)
    .map((x) => ({ ...x, type: 'employee' }));
  const teams: EmployeeOrTeamOption[] = queryTeams
    .map(mapTeamOption)
    .map((x) => ({ ...x, type: 'team' }));
  return sortBy([...employees, ...teams], (x) => x.label);
};
