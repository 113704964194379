import { useCallback } from 'react';
import { useUpdateProjectMutation } from '../../../__generated__/graphql';
import { mapToUpdateProjectFieldsInput } from '../mapping';
import { ProjectFormData } from './types';

export const useUpdateProject = () => {
  const [updateProjectMutation] = useUpdateProjectMutation();

  const updateProject = useCallback(
    async (projectId: string, formData: ProjectFormData) => {
      await updateProjectMutation({
        variables: {
          input: mapToUpdateProjectFieldsInput(projectId, formData),
        },
      });
    },
    [updateProjectMutation],
  );

  return [updateProject];
};
