import React from 'react';
import { Moment } from 'moment';
import { times } from 'lodash';
import Skeleton from 'react-loading-skeleton';

const LoadingDaysOfMonth: React.FC<{ month: Moment }> = ({ month }) => {
  return (
    <>
      {times(31, (i) => (
        <td key={`loading-${month.month()}-${i}`}>
          <Skeleton width={8} />
          <Skeleton width={16} />
        </td>
      ))}
    </>
  );
};

export default LoadingDaysOfMonth;
