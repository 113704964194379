import { Reference } from '@apollo/client';
import {
  ReactionFragment,
  useDeleteReactionMutation,
} from '../../../../__generated__/graphql';
import { RelatedObjectTypeName } from '../types';

//Reading reactions from cache won't help here because triggering the same mutation multiple times
//will lead to wrong cached data since the cache is still not updated
//Therefore, reactions are passed here
export const useDeleteReaction = (
  objectTypeName: RelatedObjectTypeName,
  recordId: string,
  reactions: ReadonlyArray<ReactionFragment>,
) =>
  useDeleteReactionMutation({
    optimisticResponse: ({ id }) => {
      return {
        reaction: {
          deleteReaction: {
            recordId: id,
            related: {
              __typename: objectTypeName,
              Id: recordId,
              reactions: reactions.filter((r) => r.Id !== id),
            },
            error: null,
          },
        },
      };
    },
    update: (cache, response) => {
      if (!response.data) {
        return;
      }

      const removedReactionId = response.data.reaction.deleteReaction.recordId;
      const relatedId = response.data.reaction.deleteReaction.related?.Id;
      if (!relatedId || !removedReactionId) {
        return;
      }

      cache.modify({
        id: cache.identify({
          __typename: objectTypeName,
          id: relatedId,
        }),
        fields: {
          reactions(
            existingReactions: ReadonlyArray<Reference> = [],
            { readField },
          ) {
            return existingReactions.filter(
              (r) => removedReactionId !== readField('id', r),
            );
          },
        },
      });
    },
  });
