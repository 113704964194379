import React from 'react';
import { useAccordionButton } from 'react-bootstrap';
import Button from '../../../../components/button';

type Props = {
  eventKey: string;
  open: boolean;
  onClick: () => void;
};

const AccordionToggleButton: React.FC<Props> = ({
  eventKey,
  onClick,
  open,
}) => {
  const handleAccordionClick = useAccordionButton(eventKey, onClick);
  return (
    <Button
      onClick={handleAccordionClick}
      className="lift btn-rounded-circle"
      size="sm"
      variant="outline-primary"
      icon={open ? 'chevron-down-outline' : 'chevron-up-outline'}
    />
  );
};

export default AccordionToggleButton;
