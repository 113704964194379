import styled from '@emotion/styled';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { FormGroup } from '../../../../components/form/FormGroup';
import { useToasts } from '../../../../context/Toast';
import { Theme } from '../../../../theme';
import { FILE_SIZE_LIMIT, toBase64 } from '../../../../utils/file';
import { fileSizeFormatter } from '../../../../utils/file';

export type FileOnServer = {
  id: string;
  name: string;
};

export type FileInput = {
  fileName: string;
  fileContentBase64: string;
};

type Props = {
  file: FileInput | null;
  onSelectFile: (fileInput: FileInput | null) => void;
  saving: boolean;
};

export const AbsenceFileField: React.FC<Props> = ({
  file,
  saving,
  onSelectFile,
}) => {
  const { t } = useTranslation();
  const { addError } = useToasts();

  const selectFileLabel = t('requestAbsence.form.selectFileWithSize', {
    size: fileSizeFormatter(FILE_SIZE_LIMIT),
  });

  const handleFileSelect = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (saving) {
      target.value = '';
      return;
    }
    if (target.validity.valid && target.files) {
      const [file] = Array.from(target.files);

      if (file.size > FILE_SIZE_LIMIT) {
        addError(t('documents.upload.sizeLimitError'));
        target.value = '';
      } else {
        getFileInputToUpload(file).then(onSelectFile);
      }
    }
  };

  const handleDelete = () => {
    if (saving || !file) {
      return;
    }
    onSelectFile(null);
  };

  return (
    <FormGroup>
      <Form.Label>
        {selectFileLabel}
        <abbr className="text-danger ms-1">*</abbr>
      </Form.Label>
      {file === null ? (
        <Form.Control
          id="file"
          type="file"
          onChange={handleFileSelect}
          required
        />
      ) : (
        <div className="d-flex flex-row">
          <span>{file.fileName}</span>
          <DeleteButton onClick={handleDelete}>
            <FlairIcon icon="trash-outline" />
          </DeleteButton>
        </div>
      )}
    </FormGroup>
  );
};

const getFileInputToUpload = (file: File): Promise<FileInput> => {
  return toBase64(file).then((fileContentBase64) => ({
    fileName: file.name,
    fileContentBase64,
  }));
};

const DeleteButton = styled.div({
  cursor: 'pointer',
  color: Theme.color.gray3,
  transition: 'color 0.15s',
  marginLeft: '0.75rem',
  ':hover': {
    color: Theme.color.gray2,
  },
});
