import * as React from 'react';

const MoneyCircleIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="28" height="28" rx="14" fill="#FFE5C8" />
      <path
        d="M7.5 16.5H20.5C20.7761 16.5 21 16.2761 21 16V9C21 8.72386 20.7761 8.5 20.5 8.5L7.5 8.5C7.22386 8.5 7 8.72386 7 9L7 16C7 16.2761 7.22386 16.5 7.5 16.5Z"
        stroke="#FF9822"
        strokeLinejoin="round"
      />
      <path
        d="M8 18H20M9 19.5H19"
        stroke="#FF9822"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15C15.3807 15 16.5 13.8807 16.5 12.5C16.5 11.1193 15.3807 10 14 10C12.6193 10 11.5 11.1193 11.5 12.5C11.5 13.8807 12.6193 15 14 15Z"
        stroke="#FF9822"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11C20.337 11 19.7011 10.7366 19.2322 10.2678C18.7634 9.79893 18.5 9.16304 18.5 8.5M7 11C7.3283 11 7.65339 10.9353 7.95671 10.8097C8.26002 10.6841 8.53562 10.4999 8.76777 10.2678C8.99991 10.0356 9.18406 9.76002 9.3097 9.45671C9.43534 9.15339 9.5 8.8283 9.5 8.5M21 14C20.337 14 19.7011 14.2634 19.2322 14.7322C18.7634 15.2011 18.5 15.837 18.5 16.5M7 14C7.3283 14 7.65339 14.0647 7.95671 14.1903C8.26002 14.3159 8.53562 14.5001 8.76777 14.7322C8.99991 14.9644 9.18406 15.24 9.3097 15.5433C9.43534 15.8466 9.5 16.1717 9.5 16.5"
        stroke="#FF9822"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default MoneyCircleIcon;
