import * as React from 'react';

const ElectronicDeviceIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M20 3.75H18.5V3C18.5 2.40326 18.2629 1.83097 17.841 1.40901C17.419 0.987053 16.8467 0.75 16.25 0.75H2.75C2.15326 0.75 1.58097 0.987053 1.15901 1.40901C0.737053 1.83097 0.5 2.40326 0.5 3V12C0.5 12.5967 0.737053 13.169 1.15901 13.591C1.58097 14.0129 2.15326 14.25 2.75 14.25H13.25V15C13.25 15.5967 13.4871 16.169 13.909 16.591C14.331 17.0129 14.9033 17.25 15.5 17.25H20C20.5967 17.25 21.169 17.0129 21.591 16.591C22.0129 16.169 22.25 15.5967 22.25 15V6C22.25 5.40326 22.0129 4.83097 21.591 4.40901C21.169 3.98705 20.5967 3.75 20 3.75ZM2.75 12.75C2.55109 12.75 2.36032 12.671 2.21967 12.5303C2.07902 12.3897 2 12.1989 2 12V3C2 2.80109 2.07902 2.61032 2.21967 2.46967C2.36032 2.32902 2.55109 2.25 2.75 2.25H16.25C16.4489 2.25 16.6397 2.32902 16.7803 2.46967C16.921 2.61032 17 2.80109 17 3V3.75H15.5C14.9033 3.75 14.331 3.98705 13.909 4.40901C13.4871 4.83097 13.25 5.40326 13.25 6V12.75H2.75ZM20.75 15C20.75 15.1989 20.671 15.3897 20.5303 15.5303C20.3897 15.671 20.1989 15.75 20 15.75H15.5C15.3011 15.75 15.1103 15.671 14.9697 15.5303C14.829 15.3897 14.75 15.1989 14.75 15V6C14.75 5.80109 14.829 5.61032 14.9697 5.46967C15.1103 5.32902 15.3011 5.25 15.5 5.25H20C20.1989 5.25 20.3897 5.32902 20.5303 5.46967C20.671 5.61032 20.75 5.80109 20.75 6V15ZM11.75 16.5C11.75 16.6989 11.671 16.8897 11.5303 17.0303C11.3897 17.171 11.1989 17.25 11 17.25H7.25C7.05109 17.25 6.86032 17.171 6.71967 17.0303C6.57902 16.8897 6.5 16.6989 6.5 16.5C6.5 16.3011 6.57902 16.1103 6.71967 15.9697C6.86032 15.829 7.05109 15.75 7.25 15.75H11C11.1989 15.75 11.3897 15.829 11.5303 15.9697C11.671 16.1103 11.75 16.3011 11.75 16.5ZM19.25 7.5C19.25 7.69891 19.171 7.88968 19.0303 8.03033C18.8897 8.17098 18.6989 8.25 18.5 8.25H17C16.8011 8.25 16.6103 8.17098 16.4697 8.03033C16.329 7.88968 16.25 7.69891 16.25 7.5C16.25 7.30109 16.329 7.11032 16.4697 6.96967C16.6103 6.82902 16.8011 6.75 17 6.75H18.5C18.6989 6.75 18.8897 6.82902 19.0303 6.96967C19.171 7.11032 19.25 7.30109 19.25 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ElectronicDeviceIcon;
