import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconRotated } from '.';
import { Theme } from '../../../../../../theme';
import { Score } from './types';

const getScoreInfo = (score: number): Score => {
  if (score > 75) {
    return {
      scoreLabelKey: 'evaluations.strongYes',
      scoreColor: Theme.color.teal1,
      scoreIcon: <Icon />,
    };
  } else if (score >= 50) {
    return {
      scoreLabelKey: 'evaluations.yes',
      scoreColor: Theme.color.teal1,
      scoreIcon: <Icon />,
    };
  } else if (score > 0) {
    return {
      scoreLabelKey: 'evaluations.no',
      scoreColor: Theme.color.red1,
      scoreIcon: <IconRotated />,
    };
  } else {
    return {
      scoreLabelKey: 'evaluations.strongNo',
      scoreColor: Theme.color.red1,
      scoreIcon: <IconRotated />,
    };
  }
};

export const ScoreSpan = styled.span<{ fg: string }>`
  color: ${(props) => props.fg};
`;

const ScoreContent: React.FC<{
  score: number;
  labelPosition?: 'left' | 'right';
  labelClassName?: string;
  containerClassName?: string;
  showScore?: boolean;
  useSplitter?: boolean;
}> = ({
  score,
  labelPosition = 'left',
  useSplitter = false,
  showScore,
  labelClassName,
  containerClassName,
}) => {
  const { t } = useTranslation();

  const scoreInfo = getScoreInfo(score);

  const label = (
    <span className={labelClassName}>{t(scoreInfo.scoreLabelKey)}</span>
  );

  const elements = [scoreInfo.scoreIcon];
  if (showScore) {
    elements.push(<span>{score}%</span>);
  }

  if (labelPosition === 'left') {
    elements.unshift(label);
  } else {
    elements.push(label);
  }

  if (useSplitter) {
    elements.splice(2, 0, ' - ');
  }

  return (
    <ScoreSpan fg={scoreInfo.scoreColor} className={containerClassName}>
      {elements.map((element, key) => (
        <span key={key} className="pe-1">
          {element}
        </span>
      ))}
    </ScoreSpan>
  );
};

export default ScoreContent;
