import styled from '@emotion/styled';
import i18next from 'i18next';
import React from 'react';
import { getWorkflowItemStatusSoftColor } from '../WorkflowItems/mappings';
import { WorkflowItemStatus } from '../WorkflowItems/types';
import FlairIcon, {
  FlairIconName,
} from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  status: WorkflowItemStatus;
};

type RenderInfoProps = {
  text: string;
  color: string;
  icon: FlairIconName;
};

const getRenderInfo = (status: WorkflowItemStatus): RenderInfoProps => {
  //const text = i18next.t(`performanceReview.workflowsItems.statuses.${status}`);
  const text = i18next.t(`workflows.statuses.${status}`);
  const color = getWorkflowItemStatusSoftColor(status);
  switch (status) {
    case 'NOT_STARTED':
      return {
        text,
        color,
        icon: 'ellipse-outline',
      };
    case 'IN_PROGRESS':
      return {
        text,
        color,
        icon: 'spinner-outline',
      };
    case 'PENDING_APPROVAL':
      return {
        text,
        color,
        icon: 'circle-notch-outline',
      };
    case 'COMPLETED':
      return {
        text,
        color,
        icon: 'checkmark-outline',
      };
    case 'REJECTED':
      return {
        text,
        color,
        icon: 'close-outline',
      };
  }
};

export const WorkflowItemStatusComponent: React.FC<Props> = ({ status }) => {
  const info = getRenderInfo(status);
  return (
    <Container backgroundColor={info.color}>
      <FlairIcon className="me-2" size="sm" icon={info.icon} />
      <span>{info.text}</span>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    display: 'inline-block',
    padding: '0 6px',
    borderRadius: 6,
    lineHeight: '20px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: backgroundColor,
  }),
);
