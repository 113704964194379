import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ExpenseDeclineFormData,
  useExpenseDeclineForm,
} from '../../hooks/useExpenseDeclineForm';
import CancelButton from '../../../../components/form/CancelButton';
import { Input } from '../../../../components/form/Input';
import SubmitButton from '../../../../components/form/SubmitButton';
import { ModalFooter } from '../../../../components/Modal';

type Props = {
  expenseId: string | null;
  show: boolean;
  onClose: () => void;
  onSubmit: (declineReason: string | null, expenseId: string) => Promise<void>;
};

const ConfirmExpenseDeclineModal: React.FC<Props> = ({
  show,
  expenseId,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { form, fieldName } = useExpenseDeclineForm({
    declineReason: '',
  });

  const handleSubmit = useCallback(
    (form: ExpenseDeclineFormData) =>
      expenseId ? onSubmit(form.declineReason, expenseId) : Promise.reject(),
    [expenseId, onSubmit],
  );

  return (
    <Modal show={show} onHide={onClose} centered>
      <FormContext {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Header closeButton className="card-header">
            <h4 className="card-header-title">
              {t('expenses.declineReason.modalTitle')}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <Input
              name={fieldName}
              type="textarea"
              label={t('expenses.declineReason.inputTitle')}
              placeholder={t('expenses.declineReason.inputPlaceholder')}
              minRows={4}
            />

            <div className="mt-3 fw-bold">
              {t('expenses.declineReason.message')}
            </div>
          </Modal.Body>
          <ModalFooter>
            <CancelButton size="sm" onClick={onClose}>
              {t('expenses.declineReason.cancelButton')}
            </CancelButton>

            <SubmitButton size="sm">
              {t('expenses.declineReason.confirmButton')}
            </SubmitButton>
          </ModalFooter>
        </form>
      </FormContext>
    </Modal>
  );
};

export default ConfirmExpenseDeclineModal;
