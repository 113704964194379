import * as React from 'react';

const CalendarCircleIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="28" height="28" rx="14" fill="#CCF5F2" />
      <path
        d="M19 8H17.5V7.5C17.5 7.36739 17.4473 7.24021 17.3536 7.14645C17.2598 7.05268 17.1326 7 17 7C16.8674 7 16.7402 7.05268 16.6464 7.14645C16.5527 7.24021 16.5 7.36739 16.5 7.5V8H11.5V7.5C11.5 7.36739 11.4473 7.24021 11.3536 7.14645C11.2598 7.05268 11.1326 7 11 7C10.8674 7 10.7402 7.05268 10.6464 7.14645C10.5527 7.24021 10.5 7.36739 10.5 7.5V8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9V19C8 19.2652 8.10536 19.5196 8.29289 19.7071C8.48043 19.8946 8.73478 20 9 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8ZM10.5 9V9.5C10.5 9.63261 10.5527 9.75979 10.6464 9.85355C10.7402 9.94732 10.8674 10 11 10C11.1326 10 11.2598 9.94732 11.3536 9.85355C11.4473 9.75979 11.5 9.63261 11.5 9.5V9H16.5V9.5C16.5 9.63261 16.5527 9.75979 16.6464 9.85355C16.7402 9.94732 16.8674 10 17 10C17.1326 10 17.2598 9.94732 17.3536 9.85355C17.4473 9.75979 17.5 9.63261 17.5 9.5V9H19V11H9V9H10.5ZM19 19H9V12H19V19Z"
        fill="#00CBBF"
      />
    </svg>
  );
});

export default CalendarCircleIcon;
