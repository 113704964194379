import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusBadge from '../components/StatusBadge';

type Props = {
  isDeleteAllowed: boolean;
  onDeleteClick?: () => void;
};

export const PendingChangesBadge: React.FC<Props> = ({
  isDeleteAllowed,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  return (
    <StatusBadge
      label={t('timeTracking.timeSheet.pendingChanges')}
      variant="warning"
      isDeleteVisible={isDeleteAllowed}
      onDeleteClick={onDeleteClick}
    />
  );
};
