import * as React from 'react';

const InformationCircleIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <rect width="28" height="28" rx="14" fill="#EAF3F5" />
      <path
        d="M12.125 12.875H14.125V18.25"
        stroke="#145361"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8438 18.375H16.1562"
        stroke="#145361"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14 11C13.8022 11 13.6089 10.9414 13.4444 10.8315C13.28 10.7216 13.1518 10.5654 13.0761 10.3827C13.0004 10.2 12.9806 9.99889 13.0192 9.80491C13.0578 9.61093 13.153 9.43275 13.2929 9.29289C13.4327 9.15304 13.6109 9.0578 13.8049 9.01922C13.9989 8.98063 14.2 9.00043 14.3827 9.07612C14.5654 9.15181 14.7216 9.27998 14.8315 9.44443C14.9414 9.60888 15 9.80222 15 10C15 10.2652 14.8946 10.5196 14.7071 10.7071C14.5196 10.8946 14.2652 11 14 11Z"
        fill="#145361"
      />
    </svg>
  );
});

export default InformationCircleIcon;
