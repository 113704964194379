import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputError from '../InputError';
import { RichtextInputStyled } from '../RichtextInput';
import { getError } from '../utils';
import { InputWithSaveBase } from './InputWithSaveBase';

type Props = {
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  labelComponent?: ReactNode;
  value: string;
  isInEditModeInitial?: boolean;
  readOnly?: boolean;
  onChange: (value?: string) => void;
};

export const RichtextInputWithSave: React.FC<Props> = ({
  containerClassName,
  labelComponent,
  value,
  readOnly,
  onChange,
}) => {
  return (
    <InputWithSaveBase
      containerClassName={containerClassName}
      value={value}
      labelComponent={labelComponent}
      onChange={(value) => onChange(value?.toString())}
      readOnly={readOnly}
      renderInput={(inputProps) => {
        return (
          <RichtextInputStyled
            readOnly={inputProps.readOnly}
            className={inputProps.className}
            value={inputProps.value?.toString()}
            onChange={(value) => inputProps.onChange(value)}
          />
        );
      }}
    />
  );
};

type PropsControlled = { name: string } & Omit<Props, 'value' | 'onChange'>;

export const RichtextInputWithSaveControlled: React.FC<PropsControlled> = ({
  name,
  isInEditModeInitial,
  ...props
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);
  return (
    <>
      <Controller
        as={({ value, onChange }) => {
          return (
            <RichtextInputWithSave
              {...props}
              value={value}
              onChange={onChange}
              isInEditModeInitial={isInEditModeInitial || !!error}
              containerClassName={error ? 'is-invalid' : ''}
              className={error ? 'is-invalid' : ''}
            />
          );
        }}
        name={name}
        control={control}
      />
      <InputError error={error} />
    </>
  );
};
