import styled from '@emotion/styled';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateShort } from '../../../../utils/dateUtils';
import { AbsenceType } from '../../__generated__/graphql';
import SoftBadge from '../SoftBadge';
import { AbsenceRequestValidationError } from './types';
import { useAbsenceAmountFormatter } from './useAbsenceAmountFormatter';
import { WithdrawalAllowance } from './withdrawalAllowances';

type Props = {
  amount?: number;
  loading: boolean;
  type: AbsenceType;
  allowances: WithdrawalAllowance[];
  unlimited: boolean;
  error?: AbsenceRequestValidationError;
};

const MultilineSoftBadge = styled(SoftBadge)({
  whiteSpace: 'normal',
  textAlign: 'initial',
});

const Badge: React.FC<{ variant?: 'danger' }> = ({ variant, children }) => (
  <MultilineSoftBadge variant={variant}>
    <span className="small">{children}</span>
  </MultilineSoftBadge>
);

const LoadingRequestingAmount: React.FC = () => (
  <Badge>
    <Trans
      i18nKey="requestAbsence.requestingAmount.loading"
      components={[<Spinner animation="border" size="sm" />]}
    />
  </Badge>
);

const ValidAmountBadge: React.FC<
  Pick<Props, 'amount' | 'type' | 'allowances' | 'unlimited'>
> = ({ amount, type, allowances, unlimited }) => {
  const amountFormatter = useAbsenceAmountFormatter();

  if (unlimited) {
    return (
      <Badge>
        <Trans
          i18nKey="requestAbsence.requestingAmount.forUnlimited"
          values={{
            workingAmount: amountFormatter({ amount, type }),
          }}
          components={[<strong />]}
        />
      </Badge>
    );
  }

  return (
    <Badge>
      <Trans
        i18nKey="requestAbsence.requestingAmount.label"
        values={{
          workingAmount: amountFormatter({ amount, type }),
          count: allowances.length,
          period: allowances[0]?.period,
        }}
        components={[<strong />]}
      />
    </Badge>
  );
};

const InvalidRequestBadge: React.FC<
  Pick<Props, 'amount' | 'type' | 'error' | 'allowances' | 'unlimited'>
> = ({ amount, type, error, allowances, unlimited }) => {
  const { t } = useTranslation();
  const amountFormatter = useAbsenceAmountFormatter();

  if (!error?.__typename) {
    return null;
  }

  switch (error.__typename) {
    case 'ValidateAbsenceRequestInsufficientCreditsError': {
      return (
        <Badge variant="danger">
          <Trans
            i18nKey="requestAbsence.requestingAmount.noCredits"
            values={{ workingAmount: amountFormatter({ amount, type }) }}
            components={[<strong />]}
          />
        </Badge>
      );
    }
    case 'ValidateAbsenceRequestStartTimeGreaterThanEndTimeError': {
      return (
        <Badge variant="danger">
          <Trans
            i18nKey="requestAbsence.requestingAmount.startEndDate"
            components={[<strong />]}
          />
        </Badge>
      );
    }
    case 'ValidateAbsenceRequestBelowMinAmountError': {
      return (
        <Badge variant="danger">
          <Trans
            i18nKey="requestAbsence.requestingAmount.belowMinAmount"
            values={{
              workingAmount: amountFormatter({ amount, type }),
              minAmount: amountFormatter({ amount: error.minAmount, type }),
            }}
            components={[<strong />]}
          />
        </Badge>
      );
    }
    case 'ValidateAbsenceRequestAboveMaxAmountError': {
      return (
        <Badge variant="danger">
          <Trans
            i18nKey="requestAbsence.requestingAmount.aboveMaxAmount"
            values={{
              workingAmount: amountFormatter({ amount, type }),
              maxAmount: amountFormatter({ amount: error.maxAmount, type }),
            }}
            components={[<strong />]}
          />
        </Badge>
      );
    }
    case 'ValidateAbsenceRequestInsufficientNoticePeriodError': {
      const unit = t(
        `requestAbsence.form.requestPolicyRules.noticePeriodUnits.${error.noticePeriodUnit}`,
        {
          count: error.noticePeriodAmount,
        },
      );

      return (
        <Badge variant="danger">
          <Trans
            i18nKey="requestAbsence.requestingAmount.noticePeriodAmount"
            values={{
              noticePeriodAmount: error.noticePeriodAmount,
              noticePeriodUnit: unit,
            }}
            components={[<strong />]}
          />
        </Badge>
      );
    }
    case 'ValidateAbsenceRequestAboveMaxUsedAmountError': {
      return (
        <Badge variant="danger">
          <Trans
            i18nKey="requestAbsence.requestingAmount.aboveUsedAmount"
            values={{
              requestedAmount: amountFormatter({
                amount: error.requestedAmount,
                type,
              }),
              period: `${formatDateShort(
                error.maxUsedAmountPeriod.startDate,
              )} - ${formatDateShort(error.maxUsedAmountPeriod.endDate)}`,
              amountLeft: amountFormatter({
                amount: error.maxUsedAmount - error.usedAmount,
                type,
              }),
            }}
            components={[<strong />]}
          />
        </Badge>
      );
    }
    case 'ValidateAbsenceRequestOverlappingAbsencesError': {
      return (
        <ValidAmountBadge
          amount={amount}
          type={type}
          allowances={allowances}
          unlimited={unlimited}
        />
      );
    }
  }
};

const RequestingAmountBadge: React.FC<Props> = ({
  amount,
  loading,
  type,
  error,
  allowances,
  unlimited,
}) => {
  if (loading) {
    return <LoadingRequestingAmount />;
  }

  if (error) {
    return (
      <InvalidRequestBadge
        amount={amount}
        type={type}
        error={error}
        allowances={allowances}
        unlimited={unlimited}
      />
    );
  }

  return (
    <ValidAmountBadge
      amount={amount}
      type={type}
      allowances={allowances}
      unlimited={unlimited}
    />
  );
};

export default RequestingAmountBadge;
