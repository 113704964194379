import React from 'react';
import PageHeader from '../../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { CelebrationsNav } from './CelebrationsNav';
import { Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import RedirectToHome from '../../../components/RedirectToHome';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

export const CelebrationsEmployeePage: React.FC = () => {
  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.engagement.title') },
    { label: t('cheers.title') },
  ]);

  return (
    <>
      <PageHeader title={t('cheers.title')}>
        <CelebrationsNav />
      </PageHeader>
      <Content />
    </>
  );
};

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);
