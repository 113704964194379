import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyTimeSheetQuery } from '../../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { ArrayElement } from '../../../../../utils/arrayElement';
import GoalChart from '../../../components/TimeSheet/GoalChart';
import WeekChart from '../../../components/TimeSheet/WeekChart';
import moment from 'moment';

type Props = {
  timeSheet: ArrayElement<MyTimeSheetQuery['myTimeSheets']>;
};

const ChartsRow: React.FC<Props> = ({ timeSheet }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col md={6}>
        <h4 className="text-center mb-4">
          {t('timeTracking.graphs.goal.title')}
        </h4>
        <GoalChart timeSheet={timeSheet} />
      </Col>
      <Col md={6}>
        <h4 className="text-center  mb-4">
          {t('timeTracking.graphs.week.title')}
        </h4>
        <WeekChart
          startDate={moment(timeSheet.flair__Start_Date__c).toDate()}
          tracked={timeSheet.trackedTime}
        />
      </Col>
    </Row>
  );
};

export default ChartsRow;
