import React from 'react';
import { DateTimeValueWithChangeRequest, DayChanges } from './types';
import { DayTimeEntriesChangesPopover } from '../DayTimeEntries/DayTimeEntriesChangesPopover/DayTimeEntriesChangesPopover';
import { StartEndTimeCell } from '../../../components/TimeSheetsTable';

type Props = {
  timeSheetId: string;
  employeeId: string;
  day: string;
  dayChanges: DayChanges;
  value: DateTimeValueWithChangeRequest;
  fieldKey: string;
  infoText?: string | null;
};

export const ManagerStartEndTimeCell: React.FC<Props> = ({
  timeSheetId,
  employeeId,
  day,
  dayChanges,
  value,
  infoText,
  fieldKey,
}) => {
  const popover = value.changeTo ? (
    <DayTimeEntriesChangesPopover
      timeSheetId={timeSheetId}
      employeeId={employeeId}
      fieldKey={fieldKey}
      day={day}
      dayChanges={dayChanges}
    />
  ) : undefined;

  return (
    <StartEndTimeCell value={value} infoText={infoText} popover={popover} />
  );
};
