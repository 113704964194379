import React from 'react';
import moment from 'moment';

type Props = {
  time: string | Date | moment.Moment;
};

const FormattedTime: React.FC<Props> = ({ time }) => {
  return <time>{moment(time).format('HH:mm')}</time>;
};

export default FormattedTime;
