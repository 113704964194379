import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';
import {
  NavigatableIdInput,
  usePersonalDocumentsFoldersQuery,
} from '../../../__generated__/graphql';
import { mapDocumentsFolders } from './mappings';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import styled from '@emotion/styled';
import DocumentMoveModalLoading from './DocumentMoveModalLoading';
import { Breadcrumb, createRootFolderId } from '../types';
import { DocumentFileTypeIcon } from '../DocumentFileType';
import { Theme } from '../../../../../theme';
import { RowFileTypeEnums } from '../Enums/RowFileTypeEnums';
import { useBreadcrumbs } from '../hooks';
import { FolderBreadcrumbs } from '../FolderBreadcrumbs';
import classNames from 'classnames';

type Props = {
  visible: boolean;
  onClose: () => void;
  setSelectedFolderId: (folderId: string) => void;
  currentFolderId: string;
  rowsToMove: NavigatableIdInput[];
  selectedFolderId: string | null;
  onSubmit: () => void;
  moveInProgress: boolean;
  employeeId: string;
  openCreateFolderModal?: () => void;
  isCreateFolderModalVisible?: boolean;
};

type CreateFolderButtonProps = {
  openCreateFolderModal: () => void;
  label: string;
};

const i18Path = 'documents2.personal.moveModal';
export const DocumentMoveModal: React.FC<Props> = (props) => {
  if (!props.visible) {
    return null;
  }

  return <Content {...props} />;
};

const CreateFolderButton: React.FC<CreateFolderButtonProps> = ({
  openCreateFolderModal,
  label,
}) => {
  return (
    <FolderRowStyle
      hasBorder={true}
      className="d-flex justify-content-center py-1 cu-pointer align-items-center">
      <FolderRowSelectedStyle
        isSelected={false}
        onClick={openCreateFolderModal}
        className="d-flex flex-row justify-content-center align-items-center w-100">
        <FlairIcon
          fill={Theme.color.white}
          size="xl"
          icon="folder-simple-plus"
        />
        <FileNameStyle className="ms-2">{label}</FileNameStyle>
      </FolderRowSelectedStyle>
    </FolderRowStyle>
  );
};
const Content: React.FC<Props> = ({
  visible,
  onClose,
  currentFolderId,
  setSelectedFolderId,
  rowsToMove,
  selectedFolderId,
  onSubmit,
  moveInProgress,
  employeeId,
  openCreateFolderModal = () => {},
  isCreateFolderModalVisible = false,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const [folderId, setFolderId] = useState<string>(currentFolderId);
  const rootFolderId = createRootFolderId(employeeId);

  const { data, loading } = usePersonalDocumentsFoldersQuery({
    variables: {
      employeeId,
      folderId: folderId,
    },
  });

  const parentFolderId = _.get(
    data,
    'navigatable.parentFolderId',
    rootFolderId,
  );

  const { breadcrumbs, getNextFolderId } = useBreadcrumbs({
    data,
    currentFolderId: folderId,
    employeeId,
    rootFolderId,
  });

  const folders = data ? mapDocumentsFolders(data, rowsToMove) : [];

  const navigateBack = useCallback(() => {
    setFolderId(parentFolderId);
  }, [parentFolderId]);

  const navigateByFolder = useCallback(
    (breadcrumb: Breadcrumb) => {
      setFolderId(getNextFolderId(breadcrumb));
    },
    [getNextFolderId],
  );
  const selectFolder = useCallback(
    (folder) => () => {
      setSelectedFolderId(folder.id);
    },
    [setSelectedFolderId],
  );

  const navigateToFolder = useCallback(
    (folder) => () => {
      setFolderId(folder.id);
      setSelectedFolderId(folder.id);
    },
    [setSelectedFolderId],
  );
  const renderContent = () => {
    if (loading || !data) {
      return <DocumentMoveModalLoading onClose={onClose} />;
    }
    return (
      <Modal.Body className={'pt-0'}>
        <BreadcrumbWrapper className="d-flex align-items-center">
          <FolderBreadcrumbs
            breadcrumbs={breadcrumbs}
            navigateByFolder={navigateByFolder}
            navigateBack={navigateBack}
            isLoading={loading}
          />
        </BreadcrumbWrapper>
        {folderId !== rootFolderId && !folders.length ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <CreateFolderButton
              label={t('buttons.createFolder')}
              openCreateFolderModal={openCreateFolderModal}
            />
          </div>
        ) : (
          <>
            {currentFolderId !== rootFolderId && (
              <FolderRowStyle
                hasBorder={true}
                className="d-flex justify-content-between py-3 cu-pointer align-items-center">
                <FolderRowSelectedStyle
                  isSelected={selectedFolderId === rootFolderId}
                  className="d-flex flex-row align-items-center w-100 ps-2"
                  onClick={selectFolder({ id: rootFolderId })}>
                  <div className="me-2 pt-1">
                    <DocumentFileTypeIcon
                      fileType={RowFileTypeEnums.Root}
                      size="lg"
                    />
                  </div>
                  <FileNameStyle className="h7-medium">
                    {t('home')}
                  </FileNameStyle>
                </FolderRowSelectedStyle>
              </FolderRowStyle>
            )}
            {!folders.length && (
              <CreateFolderButton
                label={t('buttons.createFolder')}
                openCreateFolderModal={openCreateFolderModal}
              />
            )}
            {folders.map((folder, index) => (
              <FolderRowStyle
                key={index}
                hasBorder={index !== folders.length - 1}
                className="d-flex justify-content-between py-3 cu-pointer align-items-center">
                <FolderRowSelectedStyle
                  isSelected={
                    selectedFolderId !== currentFolderId &&
                    selectedFolderId === folder?.id
                  }
                  className="d-flex flex-row align-items-center w-100 ps-2"
                  onClick={
                    selectedFolderId === currentFolderId
                      ? navigateToFolder(folder)
                      : selectFolder(folder)
                  }>
                  <div className="me-2 pt-1">
                    <DocumentFileTypeIcon fileType={folder!.type.type} />
                  </div>
                  <FileNameStyle className="h7-medium">
                    {folder!.name}
                  </FileNameStyle>
                </FolderRowSelectedStyle>

                {!folder!.isEmpty && (
                  <div className="d-flex">
                    <FlairIcon
                      icon="chevron-forward-outline"
                      onClick={navigateToFolder(folder)}
                    />
                  </div>
                )}
              </FolderRowStyle>
            ))}
          </>
        )}
        <div
          className={classNames('d-flex', 'justify-content-center', 'gap-3', {
            'mt-4': !folders.length,
          })}>
          <Button
            variant="outline-primary"
            label={t('buttons.cancel')}
            onClick={() => onClose()}
          />
          <Button
            disabled={!selectedFolderId}
            type="submit"
            onClick={onSubmit}
            label={t('buttons.move')}
            variant="primary"
            isProcessing={moveInProgress}
          />
        </div>
      </Modal.Body>
    );
  };
  return (
    <ModalWrapper
      isCreateFolderModalVisible={isCreateFolderModalVisible}
      show={visible}
      onHide={onClose}
      centered
      contentClassName="border">
      <Modal.Header closeButton className="card-header border-0">
        <h2 className="card-header-title text-center">
          {t('title', { count: rowsToMove.length })}
        </h2>
      </Modal.Header>
      {renderContent()}
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)<{ isCreateFolderModalVisible: boolean }>(
  ({ isCreateFolderModalVisible }) => ({
    opacity: isCreateFolderModalVisible ? 0.6 : 1,
  }),
);

const FolderRowSelectedStyle = styled.div<{ isSelected: boolean }>(
  ({ isSelected }) => {
    const styles = isSelected
      ? {
          backgroundColor: `${Theme.color.blue6}`,
          border: `1px solid ${Theme.color.blue5}`,
          borderRadius: '0.375rem',
        }
      : {
          border: '1px solid transparent',
        };
    return {
      ...styles,
      height: '2rem',
    };
  },
);

const FolderRowStyle = styled.div<{ hasBorder: boolean }>(({ hasBorder }) => ({
  borderBottom: hasBorder ? `1px solid ${Theme.color.paper3}` : 'none',
}));

const BreadcrumbWrapper = styled.div`
  height: 1.875rem;
  border-bottom: 1px solid ${Theme.color.paper3};
`;

const FileNameStyle = styled.div`
  color: ${Theme.color.blue3};
`;
