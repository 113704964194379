import React from 'react';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useCanEditSkills } from '../common/useEmployeeAvailableSkills';

type Props = {
  employeeId: string;
  onAddSkillClick: () => void;
};

export const EmployeeSkillsSidebarHeaderActions: React.FC<Props> = ({
  onAddSkillClick,
  employeeId,
}) => {
  const hasAccess = useCanEditSkills(employeeId);

  const t = useNamespacedTranslation('skills.employeeSkills');
  return (
    <div className="d-flex">
      <Button
        label={t('assignSkill')}
        size="sm"
        className="me-2"
        onClick={onAddSkillClick}
        disabled={!hasAccess}></Button>
    </div>
  );
};
