import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectableCardsGroup, { CardItem } from '../../SelectableCardsGroup';

type Props = {
  cardList: CardItem[];
  fieldName: string;
  onChange: (value: { [key: string]: string }) => void;
  isExpanded: boolean;
  defaultActiveButton?: string;
};

export const SelectableCardsGroupForm: React.FC<Props> = ({
  cardList,
  fieldName,
  onChange,
  isExpanded,
  defaultActiveButton = '',
}) => {
  const { control, errors } = useFormContext();

  const [activeButton, setActiveButton] = React.useState(defaultActiveButton);
  const handleChange = ({ buttonKey }: CardItem) => {
    const category = activeButton !== buttonKey ? buttonKey : '';
    setActiveButton(category);
    onChange({ [fieldName]: category });
  };

  return (
    <Controller
      as={
        <SelectableCardsGroup
          size={isExpanded ? 'lg' : 'xl'}
          list={cardList}
          isValid={!errors[fieldName]}
          activeButton={activeButton}
          onClick={handleChange}
        />
      }
      name={fieldName}
      control={control}
    />
  );
};
