import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export type DateTimeFormat =
  | 'day'
  | 'short'
  | 'long'
  | 'time'
  | 'relative'
  | 'compact'
  | 'readable';

type Props = {
  dateTime: string | Date | moment.Moment;
  format?: DateTimeFormat;
};

const FormattedDateTime: React.FC<Props> = ({ dateTime, format = 'short' }) => {
  const { t } = useTranslation();
  const formatDatetime = formattedDateTime(t);
  return (
    <time dateTime={moment(dateTime).toISOString()}>
      {formatDatetime({ dateTime, format })}
    </time>
  );
};

export const formattedDateTime =
  (t: TFunction) =>
  ({ dateTime, format }: Props) => {
    const dt = moment(dateTime);

    return format === 'relative'
      ? dt.fromNow()
      : dt.format(t(`dateTime.${format}`));
  };

export default FormattedDateTime;
