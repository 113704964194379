import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from '../../../../../components/button';
import { Datepicker } from '../../../../../components/form/Datepicker';
import { Form as CustomForm } from '../../../../../components/form/Form';
import { Input } from '../../../../../components/form/Input';
import InputLabel from '../../../../../components/form/InputLabel';
import { CreatableDropdownControlled } from '../../../../../components/form/Selects/CreatableDropdownControlled';
import { CreatableDropdownOption } from '../../../../../components/Select/types';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { toISODateOnly } from '../../../../../utils/dateUtils';
import { FILE_SIZE_LIMIT, collectFiles } from '../../../../../utils/file';
import { useUserInfo } from '../../../context/UserInfo';
import {
  useCertificateFormDataQuery,
  useCreateEmployeeCertificateMutation,
  useUpdateEmployeeCertificateMutation,
} from '../../../__generated__/graphql';
import { Certificate, DocumentFile } from '../types';
import { mapCertificate } from './mappings';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { fileSizeFormatter } from '../../../../../utils/file';

type Props = {
  onSave: () => void;
  certificate: Certificate | null;
  onClose: () => void;
};

type FormData = {
  name: string;
  issuedBy: string;
  expirationDate: Date | null;
  issueDate: Date | null;
  plannedDate: Date | null;
  certificateUrl: string | null;
};

type File = {
  fileName: string;
  fileBase64: string;
  size: number;
};

export const CertificateSaveForm: React.FC<Props> = ({
  onSave,
  certificate,
  onClose,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { addSuccess, addError } = useToasts();
  const { t } = useTranslation();
  const errorHandler = useMutationErrorHandler();
  const { data, loading } = useCertificateFormDataQuery();
  const [createEmployeeCertificate, { loading: createInProgress }] =
    useCreateEmployeeCertificateMutation();
  const [updateEmployeeCertificate, { loading: updateInProgress }] =
    useUpdateEmployeeCertificateMutation();
  const [alreadyIssued, setAlreadyIssued] = useState<boolean>(
    certificate?.plannedDate ? false : true,
  );
  const [haveCertificateUrl, setCertificateUrl] = useState<boolean>(
    certificate?.documents?.length ? false : true,
  );
  const [deleteFileId, setDeleteFileId] = useState<string | null>(null);
  const { id: employeeId } = useUserInfo();

  const formSchema = yup.object().shape<FormData>({
    name: yup.string().required(),
    issuedBy: yup.string().required(),
    certificateUrl: yup.string(),
    expirationDate: yup.date().nullable(),
    issueDate: alreadyIssued
      ? yup.date().nullable().required()
      : yup.date().nullable(true),
    plannedDate: alreadyIssued
      ? yup.date().nullable()
      : yup.date().nullable().required(),
  });

  const [certificateOptions, setCertificateOptions] = useState<
    CreatableDropdownOption[]
  >([]);

  const handleClickAlreadyIssued = () => {
    setAlreadyIssued(true);
    defaultValues.plannedDate = null;
  };

  const handleClickNotIssued = () => {
    setAlreadyIssued(false);
    defaultValues.issueDate = null;
    defaultValues.expirationDate = null;
    defaultValues.certificateUrl = '';
  };

  const handleClickCertificateFile = () => {
    setCertificateUrl(false);
    defaultValues.certificateUrl = null;
  };

  const handleClickCertificateUrl = () => {
    setCertificateUrl(true);
    if (certificate) {
      certificate.documents = [];
      setFiles([]);
    }
  };

  useEffect(() => {
    setCertificateOptions(data?.allCertificates.map(mapCertificate) || []);
  }, [data]);
  const defaultValues: FormData = {
    name: certificate?.certificateId ?? '',
    issuedBy: certificate?.issuedBy ?? '',
    certificateUrl: certificate?.certificateUrl ?? '',
    expirationDate: certificate?.expirationDate ?? null,
    issueDate: certificate?.issueDate ?? null,
    plannedDate: certificate?.plannedDate ?? null,
  };

  const handleSubmit = async (formData: FormData) => {
    const certificateOpt = certificateOptions.find(
      (cert) => cert.value === formData.name,
    );

    if (!certificate) {
      await createEmployeeCertificate({
        variables: {
          input: {
            certificateName: certificateOpt
              ? certificateOpt.label
              : formData.name,
            certificateId:
              certificateOpt && certificateOpt.label !== certificateOpt.value
                ? certificateOpt.value
                : null,
            issuedBy: formData.issuedBy,
            issueDate: formData.issueDate
              ? toISODateOnly(formData.issueDate)
              : null,
            expirationDate: formData.expirationDate
              ? toISODateOnly(formData.expirationDate)
              : null,
            plannedDate: formData.plannedDate
              ? toISODateOnly(formData.plannedDate)
              : null,
            certificateUrl: formData.certificateUrl,
            files: files.map((f) => ({
              fileContentBase64: f.fileBase64,
              fileName: f.fileName,
            })),
            employeeId: employeeId,
          },
        },
      })
        .then(() => {
          addSuccess(t('certificates.form.createSuccessText'));
          onSave();
        })
        .catch(errorHandler);
    }

    if (certificate) {
      await updateEmployeeCertificate({
        variables: {
          input: {
            id: certificate.id,
            certificateName: certificateOpt
              ? certificateOpt.label
              : formData.name,
            certificateId:
              certificateOpt && certificateOpt.label !== certificateOpt.value
                ? certificateOpt.value
                : null,
            issuedBy: formData.issuedBy,
            employeeId: employeeId,
            issueDate: formData.issueDate
              ? toISODateOnly(formData.issueDate)
              : null,
            expirationDate: formData.expirationDate
              ? toISODateOnly(formData.expirationDate)
              : null,
            plannedDate: formData.plannedDate
              ? toISODateOnly(formData.plannedDate)
              : null,
            certificateUrl: formData.certificateUrl,
            files: files.map((f) => ({
              fileContentBase64: f.fileBase64,
              fileName: f.fileName,
            })),
            deleteFileId: deleteFileId ? deleteFileId : null,
          },
        },
      })
        .then(() => {
          addSuccess(t('certificates.form.updateSuccessText'));
          onSave();
        })
        .catch(errorHandler);
    }
  };

  const DeleteFile = (documentId: string) => {
    setDeleteFileId(documentId);
    if (certificate) {
      certificate.documents = [];
    }
  };

  const handleAddCertificateOption = useCallback(
    (value) => {
      setCertificateOptions([...certificateOptions, { label: value, value }]);
    },
    [certificateOptions],
  );

  const onFileChange = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.validity.valid && target.files) {
      const files = await collectFiles(target.files);

      const filesTotalSize = files.reduce(
        (totalSize, file) => totalSize + file.size,
        0,
      );

      if (filesTotalSize > FILE_SIZE_LIMIT) {
        addError(t('certificates.form.sizeLimitError'));
        target.value = '';
      } else {
        setFiles(files);
      }
    }
  };

  return (
    <CustomForm
      onSubmit={handleSubmit}
      validationSchema={formSchema}
      defaultValues={defaultValues}>
      <Modal.Body>
        <Row>
          <Col className="px-lg-2" lg={12}>
            <Form.Group>
              <InputLabel
                label={t('certificates.form.fields.nameLabel')}
                required={true}
              />
              <CreatableDropdownControlled
                isLoading={loading}
                name="name"
                placeholder={t('certificates.form.fields.namePlaceholder')}
                options={certificateOptions}
                onCreate={handleAddCertificateOption}
                heightMode="large"
              />
            </Form.Group>
          </Col>
          <Col className="px-lg-2 mt-xxl-3" lg={12}>
            <Input
              label={t('certificates.form.fields.issuedByLabel')}
              placeholder={t('certificates.form.fields.issuedByPlaceholder')}
              type="textarea"
              name="issuedBy"
              size="md"
              required
            />
          </Col>
          <Col className="px-lg-2" lg={12}>
            <div>
              <div>
                {t(
                  'certificates.createCertificateModal.certificateIssueQuestion',
                )}
              </div>
              <div className="small text-muted mb-3">
                {t('certificates.createCertificateModal.certificateIssuehint')}
              </div>
            </div>
          </Col>
          <Col className="px-lg-2 py-3 " lg={6}>
            <Button
              className="mr-2 w-100"
              label={t('certificates.form.buttons.alreadyIssued')}
              size="lg"
              variant={alreadyIssued ? 'primary' : 'light'}
              onClick={handleClickAlreadyIssued}></Button>
          </Col>
          <Col className="px-lg-2 py-3" lg={6}>
            <Button
              className="mr-2 w-100"
              label={t('certificates.form.buttons.notYetIssued')}
              size="lg"
              variant={alreadyIssued ? 'light' : 'primary'}
              onClick={handleClickNotIssued}></Button>
          </Col>

          {alreadyIssued && (
            <>
              <Col className="px-lg-2" lg={6}>
                <Datepicker
                  required
                  name="issueDate"
                  label={t('certificates.form.fields.issueDateLabel')}
                />
              </Col>
              <Col className="px-lg-2" lg={6}>
                <Datepicker
                  name="expirationDate"
                  label={t('certificates.form.fields.expirationDateLabel')}
                />
              </Col>
            </>
          )}
          {!alreadyIssued && (
            <>
              <Col className="px-lg-2" lg={6}>
                <Datepicker
                  required
                  name="plannedDate"
                  label={t('certificates.form.fields.plannedDateLabel')}
                />
              </Col>
            </>
          )}

          {alreadyIssued && (
            <>
              <Col className="px-lg-2" lg={12}>
                <div>
                  <div>
                    {' '}
                    {t(
                      'certificates.createCertificateModal.certificateDocumentLabel',
                    )}
                  </div>
                  <div className="small text-muted mb-3">
                    {t(
                      'certificates.createCertificateModal.certificateDocumentHint',
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <Form.Check
                  id="certificateUrl"
                  type="radio"
                  label={t(
                    'certificates.createCertificateModal.certificateUrl',
                  )}
                  checked={haveCertificateUrl}
                  onChange={handleClickCertificateUrl}
                />
              </Col>
              <Col lg={6}>
                <Form.Check
                  id="certificateFile"
                  type="radio"
                  label={t(
                    'certificates.createCertificateModal.certificateFile',
                  )}
                  checked={!haveCertificateUrl}
                  onChange={handleClickCertificateFile}
                />
              </Col>

              {haveCertificateUrl && (
                <Col className="px-lg-2" lg={12}>
                  <Input
                    placeholder={t(
                      'certificates.form.fields.certificateUrlPlaceholder',
                    )}
                    type="textarea"
                    name="certificateUrl"
                    size="md"
                  />
                </Col>
              )}
              {!haveCertificateUrl && (
                <>
                  {!certificate?.documents?.length && (
                    <Col className="px-lg-2 py-3" lg={12}>
                      <Form.Group>
                        <Form.Label>
                          {t('certificates.form.fields.fileLabelWithSize', {
                            size: fileSizeFormatter(FILE_SIZE_LIMIT),
                          })}
                        </Form.Label>
                        <Form.Control
                          id="docFile"
                          type="file"
                          onChange={onFileChange}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  {certificate &&
                    certificate.documents.map((document: DocumentFile) => (
                      <Col
                        key={document.id}
                        className={classNames(
                          'd-flex',
                          'align-items-center',
                          'justify-content-between',
                          'pt-3',
                          'gap-3',
                        )}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={document.downloadPublicUrl}>
                          {document.fileName}
                          <span className="ms-2">
                            <FlairIcon
                              icon="external-link-outline"
                              size="xs"
                              className="mx-1"
                            />
                          </span>
                        </a>
                        <FlairIcon
                          icon="trash-outline"
                          className="text-dark align-middle"
                          role="button"
                          onClick={() => {
                            DeleteFile(document.id);
                          }}
                        />
                      </Col>
                    ))}
                </>
              )}
            </>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <Button
            label={t('certificates.form.cancel')}
            size="sm"
            className="mr-2 me-2"
            variant="outline-secondary"
            onClick={onClose}
          />
          <Button
            label={t('certificates.form.submitLabel')}
            size="sm"
            className="mr-2"
            variant="primary"
            type="submit"
            isProcessing={updateInProgress || createInProgress}
          />
        </div>
      </Modal.Footer>
    </CustomForm>
  );
};
