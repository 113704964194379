import { useMemo } from 'react';
import { useMyTimeTrackingQuery } from '../../../__generated__/graphql';
import {
  mapTimeTrackingStateWithEndedBreaks,
  mapAutoBreakRules,
} from './mappings';
import { useTimeTrackingSettings } from '../../../hooks/timeTrackingSettings';

export const useSuggestBreakRules = () => {
  const { timeFramework } = useTimeTrackingSettings();

  return useMemo(() => {
    return timeFramework ? mapAutoBreakRules(timeFramework) : null;
  }, [timeFramework]);
};

export const useTimeTrackingState = () => {
  const { data: timeTrackingServerData } = useMyTimeTrackingQuery();
  return useMemo(() => {
    const currentTimeEntry = timeTrackingServerData?.me.currentTimeEntry;
    const timeTrackingState = currentTimeEntry
      ? mapTimeTrackingStateWithEndedBreaks(currentTimeEntry)
      : null;

    return {
      timeTrackingState,
      currentTimeEntryId: currentTimeEntry?.Id ?? null,
    };
  }, [timeTrackingServerData]);
};
