import { SectionType } from './types';
import { CreateCelebration } from '../../../Celebrations/types';
import { getEmployeeInitialsFromName } from '../../../../utils/employeeInitials';
import { Employee } from '../../../People/types';
import { getCelebrationPredefinedValues } from './logic';

export const mapCelebration = (
  selectedEmployee: Employee | null,
  type: SectionType,
): CreateCelebration => {
  const { celebrationType, isPublic } = getCelebrationPredefinedValues(type);
  return {
    recipients: selectedEmployee
      ? [
          {
            employee: {
              ...selectedEmployee,
              initials: getEmployeeInitialsFromName(selectedEmployee.name),
            },
            type: 'employee',
            id: selectedEmployee.id,
          },
        ]
      : [],
    type: celebrationType,
    isPublic: isPublic,
  };
};
