import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { i18prefix } from '../helpers';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import {
  ProjectStatus,
  ProjectsFilter,
  allProjectStatuses,
  isProjectStatus,
} from '../types';
import { SelectOption } from '../../../../../atomic/molecules/FlairDropdown/types';

export const projectsDefaultFilter: ProjectsFilter = {
  billable: null,
  status: null,
  viewType: 'BY_PROJECTS',
};

type Props = {
  filter: ProjectsFilter;
  onChange: (changes: ProjectsFilter) => void;
};

const ProjectsFiltration: React.FC<Props> = ({ filter, onChange }) => {
  const { t } = useTranslation();

  const handleClearFilter = () => {
    onChange({ ...projectsDefaultFilter, viewType: filter.viewType });
  };

  const handleBillableChange = (value: string | null) => {
    onChange({ ...filter, billable: value });
  };

  const handleStatusChange = (value: string | null) => {
    if (value === null || isProjectStatus(value)) {
      onChange({ ...filter, status: value });
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex flex-wrap gap-3 align-items-center">
        <span className="d-none d-md-block">
          {t('performanceReview.workflowsItems.filterBy')}
        </span>

        <SingleFilter
          placeholder={t(`${i18prefix}.projectTimeEntries.filter.billable`)}
          options={createBooleanOptions(t)}
          value={filter.billable}
          onChange={handleBillableChange}
        />
        <SingleFilter
          placeholder={t(
            `${i18prefix}.projectTimeEntries.filter.projectStatus`,
          )}
          options={createProjectStatusOptions(t)}
          value={filter.status}
          onChange={handleStatusChange}
        />

        <ClearFiltersLink onClick={handleClearFilter} />
      </div>
    </div>
  );
};

export default ProjectsFiltration;

const createBooleanOptions = (t: TFunction): SelectOption[] => [
  {
    value: true + '',
    label: t('general.yes'),
  },
  {
    value: false + '',
    label: t('general.no'),
  },
];

const createProjectStatusOptions = (t: TFunction): SelectOption[] =>
  allProjectStatuses.map((status) => createProjectStatusOption(t, status));

const createProjectStatusOption = (
  t: TFunction,
  value: ProjectStatus,
): SelectOption => ({
  value,
  label: t(`${i18prefix}.projectStatus.${value}`),
});
