import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ServerError from '../../../../../components/ServerError';
import SkeletonPageHeader from '../../../components/Skeleton/PageHeader';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { routes, routes as mainRoutes } from '../../../routes';
import { useWorkflowItemsForWorkflowQuery } from '../../../__generated__/graphql';
import { emptyWorkflowItemFilterForWorkflow } from '../Filters/types';
import { WorkflowItemsFilterForWorkflowConnected } from '../Filters/WorkflowItemsFilterForWorkflowConnected';
import { WorkflowItemSidebar } from '../WorkflowItemSidebar/WorkflowItemSidebar/WorkflowItemSidebar';
import {
  groupItemsByStep,
  mapItemsSortBy,
  mapWorkflowItemsFilterInput,
  mapWorkflowItemsForWorkflow,
} from './mappings';
import { PageHeader } from './PageHeader';
import { Maybe } from '../../../../../utils/maybe';
import { Item } from './WorkflowItemsTable';
import { useFlairTableWithPaginationAndFilter } from '../../../../../hooks/useFlairTableWithPaginationAndFilter';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { WorkflowStepsAndItemsTableConnected } from './WorkflowStepsAndItemsTableConnected';
import { orderBy } from 'lodash';
import { useSelectedItemWithComments } from '../../../components/Comment';

type Props = {
  mode: 'forAll' | 'forMe' | 'forOthers';
};

type RouteParams = {
  workflowId: string;
};

const PAGE_SIZE = 300; // TODO: I've increased to 300 since now we have the steps level, until I figure out if I should paginate the steps or not

const i18Path = 'navigation.menuItems.tasks';

export const WorkflowItemsForWorkflow: React.FC<Props> = ({ mode }) => {
  const { workflowId } = useParams<RouteParams>();
  const t = useNamespacedTranslation(i18Path);

  const employeeColumnVisible = mode !== 'forMe';

  const { tableState, handleFilterChange, handleSortByChanged } =
    useFlairTableWithPaginationAndFilter({
      defaultFilter: emptyWorkflowItemFilterForWorkflow,
      storageKey: (() => {
        switch (mode) {
          case 'forAll':
            return 'workflow_filter_for_all';
          case 'forMe':
            return 'workflow_filter_for_me';
          case 'forOthers':
            return 'workflow_filter_for_others';
        }
      })(),
      createRouteUrl: (queryParams) => {
        switch (mode) {
          case 'forAll':
            return mainRoutes.workflowsAllWorkflowItems.route
              .withQueryParams(queryParams)
              .create({ workflowId });
          case 'forMe':
            return mainRoutes.workflowsMeItems.route
              .withQueryParams(queryParams)
              .create({ workflowId });
          case 'forOthers':
            return mainRoutes.workflowsOthersItems.route
              .withQueryParams(queryParams)
              .create({ workflowId });
        }
      },
    });

  const { data, loading, error } = useWorkflowItemsForWorkflowQuery({
    variables: {
      workflowId,
      filter: mapWorkflowItemsFilterInput(workflowId, tableState.filter),
      sort: mapItemsSortBy(tableState.sortBy) || null,
      pagination: {
        offset: tableState.pageIndex * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      skipPaginationInfo: tableState.totalItemsCount !== undefined,
    },
  });
  const { defaultSelectedItem } = useSelectedItemWithComments();
  const [selectedItem, setSelectedItem] =
    useState<Maybe<Item>>(defaultSelectedItem);
  const title = data?.workflow.Name ?? undefined;
  const assigneeName = data?.workflow.employee.Name ?? undefined;

  const getBackRoute = () => {
    switch (mode) {
      case 'forAll':
        return routes.workflowsAllWorkflows.route.create({});
      case 'forMe':
        return routes.workflowsMeWorkflows.route.create({});
      case 'forOthers':
        return routes.workflowsOthersWorkflows.route.create({});
    }
  };
  useFlairBreadcrumbHook(
    [
      { label: t('title') },
      {
        label: t('submenuItems.workflows'),
        url: getBackRoute(),
      },
      { label: title ?? '' },
    ],
    loading,
  );

  if (error) {
    return <ServerError />;
  }

  const allWorkflowItems = data ? mapWorkflowItemsForWorkflow(data) : undefined;

  const stepsWithItems = orderBy(
    groupItemsByStep(allWorkflowItems || []),
    'order',
  );
  const renderContent = () => {
    if (loading || allWorkflowItems === undefined) {
      return <Loading />;
    }
    return (
      <WorkflowStepsAndItemsTableConnected
        workflowSteps={stepsWithItems}
        employeeColumnVisible={employeeColumnVisible}
        onItemClick={setSelectedItem}
        initialSortBy={tableState.sortBy}
        onSortByChanged={handleSortByChanged}
      />
    );
  };

  const renderPageHeader = () => {
    if (loading || title === undefined || assigneeName === undefined) {
      return <SkeletonPageHeader />;
    }
    return <PageHeader title={title} subtitle={assigneeName} />;
  };

  const item = allWorkflowItems?.find((x) => x.id === selectedItem?.id) ?? null;

  return (
    <>
      {renderPageHeader()}
      <Card>
        <Card.Header className="small">
          <div className="d-flex align-items-center justify-content-between">
            <WorkflowItemsFilterForWorkflowConnected
              filter={tableState.filter}
              employeeColumnVisible={employeeColumnVisible}
              onChange={handleFilterChange}
              workflowId={workflowId}
            />
          </div>
        </Card.Header>
        {renderContent()}
      </Card>
      <WorkflowItemSidebar
        workflowId={item?.workflowId ?? null}
        workflowItem={item}
        showComments={selectedItem?.showComments}
        onClose={() => setSelectedItem(null)}
      />
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={4} rows={5} />;
};
