import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GoalTag } from '../../types';
import { GoalLabel } from './GoalLabel';
import { GoalLabelWithTooltip } from './GoalLabelWithTooltip';

type Props = {
  goalId: string;
  tags: GoalTag[];
};

const i18prefix = 'performanceReview.goals2.goalStatusCard.';

export const GoalTagLabels: React.FC<Props> = ({ goalId, tags }) => {
  const { t } = useTranslation();

  if (tags.length <= 2) {
    return (
      <>
        {tags.map((tag) => (
          <GoalLabel
            key={tag.id}
            icon="pricetag"
            label={tag.name}
            className="me-2"
            noWrap={true}
          />
        ))}
      </>
    );
  } else {
    const label = `${tags.length} ${t(i18prefix + 'tags')}`;
    const tooltip = (
      <Tooltip id={`${goalId}-tags`}>
        {tags.map((tag) => (
          <div key={tag.id}>{tag.name}</div>
        ))}
      </Tooltip>
    );
    return (
      <GoalLabelWithTooltip
        tooltip={tooltip}
        icon="pricetag"
        label={label}
        className="me-2"
      />
    );
  }
};
