import React, { useState } from 'react';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { ModalSidebarContent } from '../../../../components/ModalSidebar';
import {
  useDeleteWorkflowItemLoomVideoMutation,
  useUpdateWorkflowItemVideoRequestMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../../__generated__/graphql';
import {
  WorkflowItemStatus,
  WorkflowItemVideoRequest,
} from '../../WorkflowItems/types';
import { WorkflowItemCommonFields } from '../WorkflowItemCommonFields';
import { WorkflowItemStatusAutoSave } from '../WorkflowItemStatusAutoSave';
import {
  LoomVideoPreview,
  LoomVideoRecord,
  RecordedLoomVideo,
} from '../../../../components/LoomVideo';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { Maybe } from '../../../../../../utils/maybe';
import { Container as LoomContainer } from '../../../../components/LoomVideo/LoomVideoRecord';
import Button from '../../../../../../components/button';
import './WorkflowItemVideoRequestForm.css';
import { Spinner } from 'react-bootstrap';

type Props = {
  workflowItem: WorkflowItemVideoRequest;
};

const LOOM_PREVIEW_WIDTH = 154;
const LOOM_PREVIEW_HEIGHT = 100;

const i18nPrefix = 'performanceReview.workflowsItems.sidebar';

export const WorkflowItemVideoRequestForm: React.FC<Props> = ({
  workflowItem,
}) => {
  const t = useNamespacedTranslation(i18nPrefix);

  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const [loomVideo, setLoomVideo] = useState<Maybe<RecordedLoomVideo>>(
    workflowItem.loomVideo,
  );

  const [updateItem] = useUpdateWorkflowItemVideoRequestMutation();
  const [deleteLoomVideo] = useDeleteWorkflowItemLoomVideoMutation();

  const [loomVideoLoading, setLoomVideoLoading] = useState<boolean>(false);

  const errorHandler = useMutationErrorHandler();

  const handleStatusChange = (status: WorkflowItemStatus) => {
    setIsStatusLoading(true);
    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
          loomVideo: null,
        },
      },
    })
      .catch(errorHandler)
      .finally(() => {
        setIsStatusLoading(false);
      });
  };

  const handleDeleteLoomVideo = () => {
    if (loomVideo && workflowItem.loomVideoId) {
      setLoomVideoLoading(true);
      deleteLoomVideo({
        variables: {
          input: {
            id: workflowItem.id,
            loomId: workflowItem.loomVideoId,
          },
        },
      })
        .then(() => setLoomVideo(null))
        .catch(errorHandler)
        .finally(() => setLoomVideoLoading(false));
    }
  };

  const handleVideoRecorded = (loomVideo: RecordedLoomVideo) => {
    setLoomVideoLoading(true);

    let status = workflowItem.status;
    if (status === WorkflowItemStatusGenerated.NotStarted) {
      status = WorkflowItemStatusGenerated.InProgress;
    }
    setIsStatusLoading(true);

    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
          loomVideo: loomVideo,
        },
      },
    })
      .then(() => setLoomVideo(loomVideo))
      .catch(errorHandler)
      .finally(() => {
        setLoomVideoLoading(false);
        setIsStatusLoading(false);
      });
  };

  const onStatusLoading = (isStatusLoading: boolean) => {
    setIsStatusLoading(isStatusLoading);
  };

  return (
    <ModalSidebarContent
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={onStatusLoading}
          />

          <div className="mb-4">
            {loomVideoLoading ? (
              <Spinner animation="border" size="sm" />
            ) : loomVideo ? (
              <LoomContainer className="p-3 d-flex align-items-center justify-content-between">
                <LoomVideoPreview
                  loomVideo={loomVideo}
                  previewWidth={LOOM_PREVIEW_WIDTH}
                  previewHeight={LOOM_PREVIEW_HEIGHT}
                />
                <Button
                  label={t('delete')}
                  variant="outline-primary"
                  className="align-self-end delete-loom-btn"
                  size="sm"
                  disabled={loomVideoLoading}
                  onClick={handleDeleteLoomVideo}
                />
              </LoomContainer>
            ) : (
              <LoomVideoRecord
                text={t('sendVideoText')}
                onVideoRecorded={handleVideoRecorded}
              />
            )}
          </div>
          <WorkflowItemStatusAutoSave
            value={workflowItem.status}
            onChange={handleStatusChange}
            readonly={workflowItem.readonly}
            saving={isStatusLoading}
            saved={!isStatusLoading}
          />
        </>
      }
    />
  );
};
