/* eslint-disable no-unused-vars */
export enum RowFileTypeEnums {
  Pdf = 'PDF',
  Doc = 'DOC',
  Multi = 'MULTI',
  Excel = 'EXCEL_X',
  Csv = 'CSV',
  Folder = 'Folder',
  Jpg = 'JPG',
  Png = 'PNG',
  Jpeg = 'JPEG',
  PowerPoint = 'POWER_POINT',
  PowerPointLocal = 'PPT',
  PowerPointX = 'POWER_POINT_X',
  PowerPointXLocal = 'PPTX',
  Gif = 'GIF',
  Mov = 'MOV',
  Mp4 = 'MP4',
  Avi = 'AVI',
  Mp3 = 'MP3',
  Ogg = 'OGG',
  Wav = 'WAV',
  Zip = 'ZIP',
  Root = 'ROOT',
}

export type RowFileType = RowFileTypeEnums;
