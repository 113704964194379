import React from 'react';
import { TimeSheetCurrentYear } from './types';

export type AbsencesContextProps = {
  employeeId: string;
  timeSheets: ReadonlyArray<TimeSheetCurrentYear>;
};

export const AbsencesContext = React.createContext<AbsencesContextProps>({
  employeeId: '',
  timeSheets: [],
});
