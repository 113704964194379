import React from 'react';
import ServerError from '../../../../../components/ServerError';
import { Toggle } from '../Common/Toggle';
import { Card } from 'react-bootstrap';
import { WorkflowItemSidebar } from '../WorkflowItemSidebar/WorkflowItemSidebar/WorkflowItemSidebar';
import { CardHeaderWithFilter } from '../Common/CardHeaderWithFilter';
import { useAllWorkflowsItems } from '../hooks/useAllWorkflowsItems';
import { WorkflowContext } from '../../../__generated__/graphql';
import { WorkflowItemsFilterConnected } from '../Filters/WorkflowItemsFilterConnected';
import { Route } from '../../../../../utils/router';

type Props = {
  workflowContext: WorkflowContext;
  route: Route<['workflows']>;
};

export const WorkflowItems: React.FC<Props> = (props) => {
  const {
    tableState,
    handleFilterChange,
    error,
    renderContent,
    selectedItem,
    setSelectedItem,
    allWorkflowItems,
  } = useAllWorkflowsItems(props);
  if (error) {
    return <ServerError />;
  }

  const item = allWorkflowItems?.find((x) => x.id === selectedItem?.id) ?? null;

  return (
    <>
      <Card>
        <CardHeaderWithFilter>
          <WorkflowItemsFilterConnected
            workflowContext={props.workflowContext}
            filter={tableState.filter}
            onChange={handleFilterChange}
          />
          <Toggle mode="forOthers" value="workflowItems" />
        </CardHeaderWithFilter>
        {renderContent()}
      </Card>
      <WorkflowItemSidebar
        workflowId={item?.workflowId ?? null}
        workflowItem={item}
        showComments={selectedItem?.showComments}
        onClose={() => setSelectedItem(null)}
      />
    </>
  );
};
