export type FeedbackSuccessResponse = {
  id: string;
};

type FeedbackResponse = FeedbackSuccessResponse | FeedbackErrorResponse;

type FeedbackIssue = {
  message: string;
};

export type FeedbackErrorResponse = {
  code: string;
  issues: FeedbackIssue[];
  message: string;
};

export type FeedbackPainLevel = 'UNKNOWN' | 'LOW' | 'MEDIUM' | 'HIGH';

export type FeedbackData = {
  text: string;
  email?: string;
  painLevel?: FeedbackPainLevel;
  issueId?: string;
  notify?: {
    email?: boolean;
    slack?: boolean;
  };
  origin?: string;
  projectId?: string;
};

const sendFeedback = async (
  feedbackData: FeedbackData,
): Promise<FeedbackSuccessResponse | FeedbackErrorResponse> => {
  const isProdEnv = import.meta.env.MODE === 'production';
  const token = import.meta.env.VITE_APP_PRODUCTLANE_API_KEY;
  const projectId = import.meta.env.VITE_APP_PRODUCTLANE_PROJECT_ID;
  feedbackData = {
    ...feedbackData,
    painLevel: feedbackData.painLevel || 'UNKNOWN',
    projectId: projectId,
    notify: {
      email: isProdEnv,
      slack: isProdEnv,
    },
  };
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(feedbackData),
  };

  try {
    const response = await fetch(
      'https://productlane.com/api/v1/feedback',
      options,
    );
    const jsonResponse = await response.json();
    return jsonResponse as FeedbackResponse;
  } catch (err) {
    throw new Error('Failed to send feedback');
  }
};

export { sendFeedback };
