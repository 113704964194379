import React from 'react';
import { useTranslation } from 'react-i18next';
import { formattedDuration } from '../DurationFormat';

type Props = {
  value: number;
  withColor?: boolean;
};

export const DifferenceWithSign: React.FC<Props> = ({ value, withColor }) => {
  const { t } = useTranslation();
  const durationFormatter = formattedDuration(t);
  const formattedHours = durationFormatter(Math.abs(value));

  if (value === 0) {
    return (
      <span className={withColor ? 'text-success' : undefined}>
        {formattedHours}
      </span>
    );
  }

  if (value >= 0) {
    return (
      <span className={withColor ? 'text-success' : undefined}>
        +{formattedHours}
      </span>
    );
  } else {
    return (
      <span className={withColor ? 'text-danger' : undefined}>
        -{formattedHours}
      </span>
    );
  }
};
