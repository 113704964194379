import styled from '@emotion/styled';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDateLong, parseDate } from '../../../../../utils/dateUtils';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';

type CreatedByProps = {
  name: string;
  initials: string;
  avatarUrl: string;
  date: string;
};

const TooltipContent = styled.div({
  padding: '0.5rem 1rem',
  '& div:first-of-type': {
    fontSize: '15',
    fontWeight: 600,
  },
  '& div:last-of-type': {
    fontSize: '13',
  },
});

export const SkillCreatedBy: React.FC<CreatedByProps> = ({
  name,
  initials,
  avatarUrl,
  date,
}) => {
  return (
    <OverlayTrigger
      key="top"
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${avatarUrl}`}>
          <TooltipContent>
            <div>{name}</div>
            <div>{formatDateLong(parseDate(date))}</div>
          </TooltipContent>
        </Tooltip>
      }>
      <span>
        <Avatar size="xs" initials={initials} avatarUrl={avatarUrl} />
      </span>
    </OverlayTrigger>
  );
};
