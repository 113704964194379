import React from 'react';
import { routes } from './routes';
import { JobPageType } from '../types';
import { useTranslation } from 'react-i18next';
import PageHeaderNav, { TabItem } from '../../../components/PageHeaderNav';

type Props = {
  job: JobPageType;
};

const JobNav: React.FC<Props> = ({ job }) => {
  const { t } = useTranslation();

  const tabItems: TabItem[] = [
    {
      label: t('recruitment.jobPage.tabs.candidatesTab.title'),
      route: routes().candidates.route,
      jobId: job.Id,
    },
    {
      label: t('recruitment.jobPage.tabs.evaluationsTab.title'),
      route: routes().evaluations.route,
      jobId: job.Id,
    },
  ];

  return <PageHeaderNav tabItems={tabItems} />;

  // return (
  //   <Nav variant="tabs" className="header-tabs" as="ul">
  //     {/* TODO enable that later when we add overview tab*/}
  //     {/*<Nav.Item as="li">*/}
  //     {/*  <NavLink*/}
  //     {/*    className="nav-link"*/}
  //     {/*    activeClassName="active"*/}
  //     {/*    exact={true}*/}
  //     {/*    to={routes().overview.route}*/}
  //     {/*    jobId={job.Id}>*/}
  //     {/*    {t('recruitment.jobPage.tabs.overviewTab.title')}*/}
  //     {/*  </NavLink>*/}
  //     {/*</Nav.Item>*/}
  //   </Nav>
  // );
};

export default JobNav;
