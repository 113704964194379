import React from 'react';
import styled from '@emotion/styled';

import { NotificationItem, NotificationsContentType } from '../../types';
import OpenAction from './OpenAction';
import ArchiveAction from './ArchiveAction';
import UnsnoozeAction from './UnsnoozeAction';
import UnarchiveAction from './UnarchiveAction';

const NotificationActionsButtons = styled.div<{ keepActionsVisible: boolean }>(
  ({ keepActionsVisible }) => ({
    display: keepActionsVisible ? 'flex' : 'none',
  }),
);

const NotificationActions: React.FC<{
  notification: NotificationItem;
  type: NotificationsContentType;
  keepActionsVisible: boolean;
  setKeepActionsVisible: (open: boolean) => void;
}> = ({ notification, type, keepActionsVisible }) => {
  return (
    <NotificationActionsButtons
      className="justify-content-end notification-actions align-items-center flex-row flex-shrink-0"
      keepActionsVisible={keepActionsVisible}>
      {type === 'inbox' && (
        <>
          <ArchiveAction notification={notification} />
        </>
      )}
      {type === 'task' && (
        <>
          <ArchiveAction notification={notification} />
          <OpenAction notification={notification} />
        </>
      )}
      {type === 'snoozed' && <UnsnoozeAction notification={notification} />}
      {type === 'archived' && <UnarchiveAction notification={notification} />}
    </NotificationActionsButtons>
  );
};

export default NotificationActions;
