import { CalendarFilter, CalendarFiltersKeysArray } from './types';
import { CalendarQueryQuery } from '../../__generated__/graphql';

type Colleague = Pick<
  CalendarQueryQuery['me']['activeColleagues'][0],
  'employeeSkills' | 'flair__Department__c'
>;

export const filterColleagues = <TColleague extends Colleague>(
  colleagues: readonly TColleague[],
  filter: CalendarFilter,
): readonly TColleague[] => {
  let filteredColleagues = colleagues;
  if (filter.departments.length) {
    filteredColleagues = colleagues.filter((c) =>
      filter.departments.includes(c.flair__Department__c ?? ''),
    );
  }

  if (filter.skills.length) {
    filteredColleagues = filteredColleagues.filter((c) =>
      filter.skills.some((s) =>
        c.employeeSkills.map((e: any) => e.flair__Skill__c).includes(s),
      ),
    );
  }

  return filteredColleagues;
};

export const filterFromQuery = (query: URLSearchParams): CalendarFilter => {
  let filterFromQuery: CalendarFilter = {
    departments: [],
    skills: [],
  };
  CalendarFiltersKeysArray.forEach((f) => {
    const filterStr = query.get(f);
    filterFromQuery[f] = filterStr ? filterStr.split(',') : [];
  });
  return filterFromQuery;
};

export const mapToFilterOptions = (a: { Id: string; Name: string }) => ({
  label: a.Name,
  value: a.Id,
});
