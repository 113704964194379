import * as yup from 'yup';
import { IdName } from '../common/types';

export type CreateUpdateFormData = {
  rateable: string;
  name: string;
};

const SALESFORCE_MAX_NAME_CHARACTERS = 40;

export const skillValidationSchema = (
  allSkills: IdName[],
  skillExistsError: string,
  skillId?: string,
) =>
  yup.object().shape<CreateUpdateFormData>({
    rateable: yup.string(),
    name: yup
      .string()
      .required()
      .max(SALESFORCE_MAX_NAME_CHARACTERS)
      .test('skill-exists', skillExistsError, (value: string) => {
        const skillWithSameName = allSkills.find((x) =>
          isSameName(x.name, value),
        );
        if (skillWithSameName && skillWithSameName.id !== skillId) {
          return false;
        }
        return true;
      }),
  });

const isSameName = (a: string, b: string): boolean =>
  a.toLowerCase() === b.toLowerCase();
