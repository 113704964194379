import React from 'react';
import { routes as globalRoutes } from '../../routes';
import { CorporateDocuments } from './CorporateDocuments';
import { PersonalDocuments } from './PersonalDocuments';

export const routes = () => ({
  personal: {
    route: globalRoutes.documentsPersonal.route,
    render: () => <PersonalDocuments />,
  },
  corporate: {
    route: globalRoutes.documentsCompany.route,
    render: () => <CorporateDocuments />,
  },
});
