import React, { useMemo } from 'react';
import { useInboxNotificationsQuery } from '../../../__generated__/graphql';
import { mapNotifications } from '../RenderInfoMappings';
import { useInifinityScroll } from '../../../../../components/loadingMoreItemsSpinner/useInfinityScroll';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';
import { useArchiveAllInboxNotifications } from '../NotificationContentItem/useArchiveAllInboxNotifications';
import NotificationsContentConnectedItems from './NotificationsContentConnectedItems';
import { getCurrentLanguage } from '../../../../../locales/locale';

const PAGE_SIZE = 10;

const InboxNotificationsContentConnected: React.FC = () => {
  const t = useNamespacedTranslation('notifications');
  const [archiveAllInboxNotifications] = useArchiveAllInboxNotifications();
  const locale = getCurrentLanguage();

  const { data, loading, error, fetchMore } = useInboxNotificationsQuery({
    variables: {
      lang: locale,
      offset: 0,
      limit: PAGE_SIZE,
      skipPaginationInfo: false,
    },
  });

  const notificationPaginationInfo = useMemo(() => {
    return data?.me.inboxNotifications;
  }, [data]);
  const alreadyKnowTotalCount =
    notificationPaginationInfo?.paginationInfo?.totalCount !== undefined;

  const { LoadingMore } = useInifinityScroll({
    objectWithPaginationInfo: notificationPaginationInfo,
    fetchMore: (offset) => {
      return fetchMore({
        variables: {
          offset,
          skipPaginationInfo: alreadyKnowTotalCount,
        },
      }).then();
    },
  });

  const notifications =
    data?.me && mapNotifications(data.me.Id, data.me.inboxNotifications.items);

  const atLeastOneNotification = notifications && notifications?.length > 0;
  return (
    <>
      <NotificationsContentConnectedItems
        error={!!error}
        loading={loading}
        loadingMore={LoadingMore}
        notifications={notifications}
        type="inbox"
      />
      {atLeastOneNotification && (
        <div className="border-top d-flex flex-row justify-content-center w-100 py-1">
          <Button
            variant="link"
            label={t('buttons.archiveAll')}
            onClick={() => {
              archiveAllInboxNotifications();
            }}
          />
        </div>
      )}
    </>
  );
};

export default InboxNotificationsContentConnected;
