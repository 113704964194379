import * as React from 'react';

const CheckmarkDoneOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8286 3.62752C12.0367 3.80898 12.0583 4.12482 11.8769 4.33297L7.62689 9.20797C7.44542 9.41612 7.12958 9.43775 6.92143 9.25629C6.71328 9.07483 6.69165 8.75898 6.87311 8.55083L11.1231 3.67583C11.3046 3.46769 11.6204 3.44605 11.8286 3.62752ZM14.8293 3.62811C15.0371 3.80995 15.0581 4.12584 14.8763 4.33365L7.87629 12.3337C7.78516 12.4378 7.65495 12.4995 7.51664 12.5041C7.37833 12.5087 7.2443 12.4558 7.14645 12.358L4.14645 9.35795C3.95118 9.16269 3.95118 8.84611 4.14645 8.65085C4.34171 8.45559 4.65829 8.45559 4.85355 8.65085L7.47565 11.2729L14.1237 3.67515C14.3056 3.46733 14.6214 3.44627 14.8293 3.62811ZM1.14645 8.65085C1.34171 8.45559 1.65829 8.45559 1.85355 8.65085L4.85355 11.6508C5.04882 11.8461 5.04882 12.1627 4.85355 12.358C4.65829 12.5532 4.34171 12.5532 4.14645 12.358L1.14645 9.35795C0.951184 9.16269 0.951184 8.84611 1.14645 8.65085Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default CheckmarkDoneOutlineIcon;
