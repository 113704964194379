import styled from '@emotion/styled';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Accordion, Card as BootstrapCard, ListGroup } from 'react-bootstrap';
import {
  useCurrentTimeTrackingTimelineLastStep,
  useCurrentTimeTrackingTimelineSteps,
} from '../../hooks/useCurrentTimeTrackingTimeline';
import BreakEndedStep from '../../pages/TimeTracking/CurrentTimeTracking/BreakEndedStep';
import BreakStartedStep from '../../pages/TimeTracking/CurrentTimeTracking/BreakStartedStep';
import ClockInStep from '../../pages/TimeTracking/CurrentTimeTracking/ClockInStep';
import {
  MyTimeTrackingQuery,
  useMyTimeTrackingQuery,
} from '../../__generated__/graphql';
import { BreakReminders } from '../AutoBreaks/breakReminders/BreakReminders';
import CardHeader from './CardHeader';
import NotesFormListGroupItem from './NotesFormListGroupItem';

const Widget = styled.aside({
  right: 0,
  bottom: 0,
  position: 'fixed',
  zIndex: 1000,
});

const Container = styled.div({
  width: '330px',
});

const Card = styled(BootstrapCard)({
  marginBottom: 0,
});

const CardBody = styled(BootstrapCard.Body)({
  padding: '1rem 1.5rem',
  maxHeight: '285px',
  overflow: 'scroll',
});

type TimeEntryProps = NonNullable<
  MyTimeTrackingQuery['me']['currentTimeEntry']
>;

const ACCORDION_EVT_KEY = 'current-time-tracking-widget';

const CurrentTimeTrackingContent: React.FC<{
  entry: TimeEntryProps;
}> = ({ entry }) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), [setOpen]);
  const steps = useCurrentTimeTrackingTimelineSteps(entry);
  const currentStep = useCurrentTimeTrackingTimelineLastStep(entry);
  const notesFormRef = useRef<HTMLFormElement>(null);

  const items = useMemo(
    () =>
      steps
        .slice(1)
        .reverse()
        .map((step) => {
          switch (step.kind) {
            case 'ClockIn': {
              return <ClockInStep step={step} key={`${step.kind}`} />;
            }
            case 'BreakStarted': {
              return (
                <BreakStartedStep
                  step={step}
                  key={`${step.breakId}-${step.kind}`}
                />
              );
            }
            case 'BreakEnded': {
              return (
                <BreakEndedStep
                  step={step}
                  key={`${step.breakId}-${step.kind}`}
                />
              );
            }
            case 'DuringBreak':
            case 'Working': {
              return <></>;
            }
          }
          return <></>;
        }),
    [steps],
  );

  const scrollToNotesInput = useCallback(() => {
    notesFormRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
    notesFormRef.current
      ?.querySelector('textarea')
      ?.focus({ preventScroll: true });
  }, [notesFormRef]);

  return (
    <Widget data-testid="time-tracking-widget">
      <Container>
        <Accordion>
          <Card>
            <CardHeader
              step={currentStep}
              notes={entry.flair__Notes__c}
              lastModifiedDate={entry.LastModifiedDate}
              accordionToggleKey={ACCORDION_EVT_KEY}
              accordionToggleOpen={toggleOpen}
              accordionOpen={open}
            />

            <Accordion.Collapse
              onEntered={scrollToNotesInput}
              eventKey={ACCORDION_EVT_KEY}>
              <CardBody>
                <ListGroup
                  variant="flush"
                  className="list-group-activity my-n3">
                  {items}
                  <NotesFormListGroupItem
                    key={`notes-form-${entry.LastModifiedDate}`}
                    ref={notesFormRef}
                    timeEntryId={entry.Id}
                    notes={entry.flair__Notes__c}
                  />
                </ListGroup>
              </CardBody>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
      <BreakReminders />
    </Widget>
  );
};

const CurrentTimeTrackingWidget: React.FC = () => {
  const { data, loading, error } = useMyTimeTrackingQuery();

  if (loading) return <></>;

  if (error || data === undefined || !data.me.currentTimeEntry) return <></>;

  return <CurrentTimeTrackingContent entry={data.me.currentTimeEntry} />;
};

export default CurrentTimeTrackingWidget;
