import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { PaginationInfo } from '../apps/dashboard/__generated__/graphql';

type ItemsWithPaginationInfo<T> = {
  items?: ReadonlyArray<T>;
  paginationInfo?: PaginationInfo | null;
};

export type InfinityScrollParams<T> = {
  objectWithPaginationInfo: ItemsWithPaginationInfo<T> | undefined;
  fetchMore: (offset: number) => Promise<void>;
  loadingMessage?: string;
};

export type InfinityScrollReturnType = {
  ref: (node?: Element | null) => void;
  loadingMore: boolean;
};

export const useInifinityScrollBase = <T>({
  objectWithPaginationInfo,
  fetchMore,
}: InfinityScrollParams<T>): InfinityScrollReturnType => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const isFullyLoaded: boolean | undefined = useMemo(() => {
    if (
      !objectWithPaginationInfo ||
      !objectWithPaginationInfo.paginationInfo ||
      !objectWithPaginationInfo.items
    ) {
      return undefined;
    }
    return (
      objectWithPaginationInfo.items.length >=
      objectWithPaginationInfo.paginationInfo.totalCount
    );
  }, [objectWithPaginationInfo]);

  const loadedCount = useMemo(
    () => objectWithPaginationInfo?.items?.length,
    [objectWithPaginationInfo],
  );

  useEffect(() => {
    if (
      inView === true &&
      isFullyLoaded === false &&
      loadedCount !== undefined &&
      loadingMore === false
    ) {
      setLoadingMore(true);
      fetchMore(loadedCount).finally(() => {
        setLoadingMore(false);
      });
    }
  }, [
    isFullyLoaded,
    setLoadingMore,
    fetchMore,
    inView,
    loadingMore,
    loadedCount,
  ]);

  return { ref, loadingMore };
};
