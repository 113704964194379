import styled from '@emotion/styled';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getStatusInputColor } from '../../types';

type Props = {
  isGoalOnTrack: undefined | boolean;
  isSelected: boolean;
  disabled: boolean;
  onClick: () => void;
};

const i18prefix = 'performanceReview.goals2';

export const GoalStatusTwinButton: React.FC<Props> = ({
  isSelected,
  disabled,
  isGoalOnTrack,
  onClick,
}) => {
  const { t } = useTranslation();

  const borderColor = isGoalOnTrack
    ? getStatusInputColor('ON_TRACK')
    : getStatusInputColor('BEHIND');
  return (
    <StyledButton
      className="me-2"
      border={isSelected ? `1px solid ${borderColor}` : 'none'}
      disabled={disabled}
      size="sm"
      variant="link"
      onClick={onClick}>
      <BehindText className="me-3" enabled={isGoalOnTrack === false}>
        {t(`${i18prefix}.goalStatus.BEHIND`)}
      </BehindText>
      <OnTrackText enabled={isGoalOnTrack === true}>
        {t(`${i18prefix}.goalStatus.ON_TRACK`)}
      </OnTrackText>
    </StyledButton>
  );
};

const BehindText = styled('span')<{ enabled: boolean }>(({ enabled }) => ({
  color: getStatusInputColor('BEHIND'),
  opacity: enabled ? 1 : 0.7,
}));

const OnTrackText = styled('span')<{ enabled: boolean }>(({ enabled }) => ({
  color: getStatusInputColor('ON_TRACK'),
  opacity: enabled ? 1 : 0.7,
}));

const StyledButton = styled(Button)<{
  border: string | undefined;
}>(({ border }) => ({
  border,
  ':disabled, :hover': {
    border,
  },
}));
