import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';

type FolderModalProps = {
  visible: boolean;
  onClose: () => void;
  createInProgress: boolean;
  setFolderName: (name: string) => void;
  createFolder: () => void;
  folderName: string | null;
};

const i18Path = 'documents2.personal.newFolder';

export const DocumentFolderModal: React.FC<FolderModalProps> = ({
  visible,
  onClose,
  createInProgress,
  createFolder,
  setFolderName,
  folderName,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const saveFolder = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createFolder();
  };

  return (
    <Modal
      show={visible}
      onHide={onClose}
      centered
      contentClassName="border"
      dialogClassName="break-modal-440">
      <Modal.Header closeButton className="card-header border-0">
        <h2 className="card-header-title text-center">{t('folder.title')}</h2>
      </Modal.Header>
      <Modal.Body className={'pt-0'}>
        <form onSubmit={saveFolder}>
          <div className={'form-group'}>
            <input
              autoFocus={true}
              className={'form-control'}
              placeholder={t('folder.input')}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </div>
          <div className={'d-flex justify-content-center gap-3'}>
            <Button
              variant="outline-primary"
              label={t('folder.buttons.cancel')}
              onClick={() => onClose()}
            />
            <Button
              isProcessing={createInProgress}
              disabled={!folderName}
              type="submit"
              label={t('folder.buttons.create')}
              variant="primary"
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
