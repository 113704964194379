import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import FlairIcon, {
  FlairIconName,
} from '../../../../../../atomic/atoms/FlairIcon';
import { RangeDashedHint } from '../../../../../../components/RangeDashedHint';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { Theme } from '../../../../../../theme';
import { Nullable } from '../../../../../../utils/nullable';

export type EmojiOption = {
  icon: FlairIconName;
  iconSolid: FlairIconName;
  value: number;
  label: string;
};

type EmojiSelectProps = {
  label: string;
  emojisOptions?: EmojiOption[];
  onChange?: (emojiKey: Nullable<EmojiOption>) => void;
};

const Icon = styled(FlairIcon)({
  strokeWidth: 0,
  path: {
    stroke: Theme.color.blue3,
  },
});

export const EmojisSelect: React.FC<EmojiSelectProps> = ({
  label,
  emojisOptions,
  onChange,
}) => {
  const t = useNamespacedTranslation('home.smileyCheckins');

  const defaultEmojisSelectOptions: EmojiOption[] = [
    {
      icon: 'sad-tear-outline',
      iconSolid: 'sad-tear-solid',
      value: 0,
      label: t('defaultOptions.0'),
    },
    {
      icon: 'frown-outline',
      iconSolid: 'frown-solid',
      value: 1,
      label: t('defaultOptions.1'),
    },
    {
      icon: 'meh-outline',
      iconSolid: 'meh-solid',
      value: 2,
      label: t('defaultOptions.2'),
    },
    {
      icon: 'smile-outline',
      iconSolid: 'smile-solid',
      value: 3,
      label: t('defaultOptions.3'),
    },
    {
      icon: 'happy-outline',
      iconSolid: 'happy-filled',
      value: 4,
      label: t('defaultOptions.4'),
    },
  ];

  const options = emojisOptions ?? defaultEmojisSelectOptions;

  const [hoveredEmoji, setHoveredEmoji] = useState<Nullable<EmojiOption>>(null);
  const isEmojiHovered = (emoji: EmojiOption) => {
    return hoveredEmoji?.value === emoji.value;
  };

  const handleEmojiClick = (emoji: EmojiOption) => {
    !!onChange && onChange(emoji);
  };

  return (
    <Row>
      <h4 className="text-center mb-3">{label}</h4>
      <div className="d-flex justify-content-between mb-3">
        {options.map((emoji) => (
          <Icon
            icon={isEmojiHovered(emoji) ? emoji.iconSolid : emoji.icon}
            key={emoji.value}
            role="button"
            color={Theme.color.blue3}
            size="3x"
            onMouseEnter={() => setHoveredEmoji(emoji)}
            onMouseLeave={() => setHoveredEmoji(null)}
            onClick={() => handleEmojiClick(emoji)}
            className={`${isEmojiHovered(emoji) ? 'text-primary' : ''}`}
          />
        ))}
      </div>
      <RangeDashedHint startLabel={t('awful')} endLabel={t('amazing')} />
    </Row>
  );
};
