import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  StylesConfig,
  Props as ReactSelectProps,
  MultiValue,
} from 'react-select';
import { useQueryParams } from '../../../../../hooks/useQueryParams';
import { split } from '../../../../../utils/string';
import { FilterSelectOption } from '../../../../../atomic/molecules/FlairDropdown/types';
import { routes } from '../../../routes';
import { JobsFiltersKeys, JobsFiltersType } from '../types';
import { FlairMultiSelectDropdown } from '../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

type Props = {
  options: FilterSelectOption[];
  filterData: JobsFiltersType;
  filterKey: JobsFiltersKeys;
};

const customStyle: StylesConfig<FilterSelectOption, true> = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: '150px',
  }),
};

const JobsMultipleFilter: React.FC<
  Props & ReactSelectProps<FilterSelectOption, true>
> = ({ options, filterData, filterKey, ...rest }) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const selectedOptionsString = filterData[filterKey];
  const selectedOptionsAsArray = useMemo(
    () => split(selectedOptionsString),
    [selectedOptionsString],
  );
  const handleChange = (selectedOptions: MultiValue<FilterSelectOption>) => {
    history.push(
      routes.jobsList.route
        .withQueryParams({
          ...queryParams,
          [filterKey]: selectedOptions.map((o) => o.value).join(','),
        })
        .create({}),
    );
  };
  return (
    <FlairMultiSelectDropdown
      options={options}
      selectedOptions={selectedOptionsAsArray}
      onChange={handleChange}
      styles={customStyle}
      {...rest}
    />
  );
};

export default JobsMultipleFilter;
