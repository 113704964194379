import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import Button from '../../../../../../../components/button';
import { getStatusStr } from '../../translations';
import { getStatusInputColor, GoalStatus } from '../../types';

type Props = {
  value: GoalStatus;
  isSelected: boolean;
  disabled: boolean;
  onClick: (value: GoalStatus) => void;
};

export const StatusButton: React.FC<Props> = ({
  value,
  isSelected,
  disabled,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  const color = getStatusInputColor(value);
  return (
    <StyledButton
      className="me-2"
      color={color}
      border={isSelected ? `1px solid ${color}` : 'none'}
      disabled={disabled}
      label={getStatusStr(value)}
      size="sm"
      variant="link"
      onClick={handleClick}
    />
  );
};

const StyledButton = styled(Button)<{
  color: string;
  border: string | undefined;
}>(({ color, border }) => ({
  color,
  border,
  ':disabled, :hover': {
    color,
    border,
  },
}));
