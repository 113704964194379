import styled from '@emotion/styled';
import React from 'react';
import { Modal, ModalDialogProps } from 'react-bootstrap';
import { FieldValues, FormContext, FormContextValues } from 'react-hook-form';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type Props = {
  header: React.ReactNode;
  headerActions?: React.ReactNode;
  onClose: () => void;
  show: boolean;
  size?: 'sm' | 'lg' | 'xl';
  content: React.ReactNode;
  backButton?: boolean;
  onBackButtonClick?: () => void;
  headerClassName?: string;
};

type DialogProps = ModalDialogProps & {
  className?: string;
};

const ModalVerticalDialog: React.FC<DialogProps> = ({ className, ...rest }) => (
  <Modal.Dialog
    className={['modal-dialog-vertical', className].join(' ')}
    {...rest}
  />
);

type HeaderProps = {
  children: React.ReactNode;
  actions?: React.ReactNode;
  backButton?: boolean;
  onBackButtonClick?: () => void;
  className?: string;
};

const ModalHeader: React.FC<HeaderProps> = ({
  children,
  actions = null,
  backButton,
  onBackButtonClick,
  className,
}) => (
  <Modal.Header className={`card-header border-0 ${className}`} closeButton>
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        {backButton && (
          <FlairIcon
            icon="chevron-back-outline"
            size="sm"
            className="me-3"
            cursor="pointer"
            onClick={() => {
              if (onBackButtonClick) {
                onBackButtonClick();
              }
            }}
          />
        )}
        <h2 className="card-header-title">{children}</h2>
      </div>
      {actions}
    </div>
  </Modal.Header>
);

type FooterProps = {
  className?: String;
  children: React.ReactNode;
};

const ModalFooter: React.FC<FooterProps> = ({ className = '', children }) => (
  <Modal.Footer className={`border-0 ${className}`}>{children}</Modal.Footer>
);

type ModalContentProps = {
  body: React.ReactNode;
  bodyClassName?: string;
  footer?: React.ReactNode;
  footerClassName?: string;
};

export const ModalSidebarContent: React.FC<ModalContentProps> = ({
  footer,
  footerClassName,
  body,
  bodyClassName,
}) => (
  <>
    <Modal.Body className={bodyClassName}>{body}</Modal.Body>
    {footer && <ModalFooter className={footerClassName}>{footer}</ModalFooter>}
  </>
);

type ModalSidebarFormContentProps<T extends FieldValues = FieldValues> =
  ModalContentProps & {
    form: FormContextValues<T>;
    onSubmit: (data: T) => void;
  };

const Form = styled('form')({
  display: 'contents',
});

export const ModalSidebarFormContent = <T extends {}>({
  form,
  onSubmit,
  footer,
  footerClassName,
  body,
}: ModalSidebarFormContentProps<T>) => {
  return (
    <FormContext {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalSidebarContent
          body={body}
          footer={footer}
          footerClassName={footerClassName}
        />
      </Form>
    </FormContext>
  );
};

const ModalSidebar: React.FC<Props> = ({
  show,
  onClose,
  header,
  content,
  headerActions,
  backButton,
  size,
  onBackButtonClick,
  headerClassName,
}) => (
  <Modal
    enforceFocus={false}
    show={show}
    size={size}
    onHide={onClose}
    className={['fixed-right', size ? `size-${size}` : ''].join(' ').trim()}
    dialogAs={ModalVerticalDialog}>
    <ModalHeader
      actions={headerActions}
      backButton={backButton}
      onBackButtonClick={onBackButtonClick}
      className={headerClassName}>
      {header}
    </ModalHeader>
    {content}
  </Modal>
);

export default ModalSidebar;
