import React, { useContext, useState } from 'react';
import { CategorySearch } from '../formFields/CategorySearch';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import styled from '@emotion/styled';
import { CategoryFormField } from '../formFields/CategoryFormField';
import {
  DictionaryItemType,
  TicketCategoriesQuery,
  useTicketCategoriesQuery,
} from '../../../../../__generated__/graphql';
import { getCurrentLanguage } from '../../../../../../../locales/locale';
import ServerError from '../../../../../../../components/ServerError';
import { applyCategorySearch, mapTicketCategories } from '../mappings';
import { SelectableRowType } from '../../../../../../../components/SelectableRowGroup/types';
import { TicketModalContext } from '../TicketModalContext';

const i18Path = 'support.tickets.newTicket';

export const TicketCategoryStep: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [search, setSearch] = useState<string | null>(null);
  const locale = getCurrentLanguage();
  const {
    value: formState,
    onChange: onFieldChange,
    policy,
  } = useContext(TicketModalContext);

  const { data, loading, error } = useTicketCategoriesQuery({
    variables: {
      type: DictionaryItemType.TicketCategory,
      lang: locale,
    },
  });

  const allCategories = data ? mapTicketCategories(data) : [];

  const categories = applyCategorySearch(search, allCategories);

  const onSearch = (value: string) => {
    setSearch(value);
  };
  const handleChange = (value: { [key: string]: SelectableRowType }) => {
    onFieldChange({
      ...formState,
      category: value.category.value,
      hasWhistleblowerProtection: hasSelectedCategoryWhistleblower(
        value.category.value,
        data,
        policy?.categoryKey,
      ),
      preview: { ...formState?.preview, category: value.category.label },
    });
  };

  if (error) return <ServerError />;

  return (
    <div className="mx-4 flex-fill">
      <div className="d-flex flex-column">
        <div className="mb-4">
          <CategorySearch
            isDisabled={loading}
            onSearch={onSearch}
            placeholder={t('form.fields.search')}></CategorySearch>
        </div>
        <CategoryListStyled>
          <CategoryFormField
            handleChange={handleChange}
            isLoading={loading}
            defaultActiveButton={formState?.category}
            list={categories}></CategoryFormField>
        </CategoryListStyled>
      </div>
    </div>
  );
};

const CategoryListStyled = styled.div`
  overflow-y: auto;
  max-height: 15rem;
`;

const hasSelectedCategoryWhistleblower = (
  selectedCategory: string,
  categories: TicketCategoriesQuery | undefined,
  categoryKey: string | null | undefined,
): boolean => {
  if (!categoryKey || !categories) return false;

  const category = categories?.dictionaryItemsByType?.find(
    (category) => category?.Id === selectedCategory,
  );
  return category?.flair__Key__c === categoryKey;
};
