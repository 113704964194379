import React from 'react';
import { Modal } from 'react-bootstrap';
import { CustomizeItems, CustomizableItem } from './CustomizeItems';
import { ModalFooter } from '../Modal';
import Button from '../button';
import { useNamespacedTranslation } from '../../hooks/useNamespacedTranslation';

type Props = {
  header: string;
  onSave: (items: CustomizableItem[]) => void;
  onClose: () => void;
  visible: boolean;
  isProcessing?: boolean;
  items: CustomizableItem[];
};

export const CustomizeItemsModal: React.FC<Props> = ({
  header,
  onClose,
  onSave,
  visible,
  items,
  isProcessing,
}) => {
  const t = useNamespacedTranslation('components.customizeItemsModal');

  const [list, setList] = React.useState(items);

  const handleOnSave = () => {
    onSave(list);
  };

  const handleClose = () => {
    onClose();
    setList(items);
  };

  return (
    <Modal
      scrollable={true}
      enforceFocus={false}
      show={visible}
      centered
      onHide={handleClose}
      contentClassName="border"
      size="lg"
      header={header}>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{header}</h4>
      </Modal.Header>
      <Modal.Body>
        <CustomizeItems items={list} onChange={setList} />
      </Modal.Body>
      <ModalFooter>
        <Button
          size="sm"
          variant="secondary"
          label={t('cancel')}
          onClick={handleClose}
        />
        <Button
          size="sm"
          variant="primary"
          label={t('save')}
          onClick={handleOnSave}
          isProcessing={isProcessing}
        />
      </ModalFooter>
    </Modal>
  );
};
