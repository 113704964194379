import { compact } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  visibleForManager,
  visibleForPeers,
} from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeFeedbacks/utils';
import {
  EmployeeFeedbackAnswer,
  FeedbackQuestionType,
} from '../../__generated__/graphql';
import { EmployeeFeedbackField } from './EmployeeFeedbackField';
import { FeedbackCard } from './EmployeeFeedbackQuestion';
import { EmployeeFeedbackAccordion } from './EmployeeFeedbackAccordion';
import {
  EmployeeFeedbackAnswerProps,
  IEmployeeFeedbackAnswer,
  IScorecard,
} from './types';
import { convertToScorecards } from './utils';
import { Col, Row } from 'react-bootstrap';
import { FeedbackAnswer } from '../EmployeeFeedback/FeedbackAnswer';
import { mapToComparedFeedbackAnswer } from '../../hooks/useComparedFeedbackSectionsMapper';
import { FeedbackAnswerSection } from '../EmployeeFeedback/SideBySideAnswers';
import { Maybe } from '../../../../utils/maybe';

type AnswerForMapType = Pick<
  EmployeeFeedbackAnswer,
  | 'Id'
  | 'flair__Employee_Feedback_Question__c'
  | 'flair__Note__c'
  | 'feedbackAnswer'
>;

type Props = {
  answers: ReadonlyArray<EmployeeFeedbackAnswerProps>;
  setIsComplete: (value: boolean) => void;
  readOnly?: boolean;
  seeAllQuestions?: boolean;
  isPeerReviewer: boolean;
  employeeAnswers?: Maybe<EmployeeAnswers>;
};

const isScorecardComlete = (
  value: string | null,
  requiredChoices: ReadonlyArray<string>,
): boolean => {
  if (!requiredChoices || requiredChoices.length === 0) {
    return true;
  }

  const scorecards: IScorecard[] = compact(convertToScorecards(value || ''));

  for (const requiredChoice of requiredChoices) {
    const scorecard = scorecards.find((item) => item.name === requiredChoice);
    if (!scorecard || scorecard.score === undefined) {
      return false;
    }
  }

  return true;
};

const isFieldOptional = (
  optional: boolean,
  type: FeedbackQuestionType,
  requiredChoices: ReadonlyArray<string>,
) =>
  (type === FeedbackQuestionType.Scorecard && requiredChoices.length === 0) ||
  optional;

const isFieldComplete = (
  value: string | null,
  optional: boolean,
  type: FeedbackQuestionType,
  requiredChoices: ReadonlyArray<string>,
): boolean => {
  switch (type) {
    case FeedbackQuestionType.Scorecard: {
      return isScorecardComlete(value, requiredChoices);
    }

    case FeedbackQuestionType.FreeSlider:
      return optional || (value !== undefined && value !== null);
    case FeedbackQuestionType.FreeText:
    case FeedbackQuestionType.Dropdown:
    case FeedbackQuestionType.SingleChoice:
    case FeedbackQuestionType.MultipleChoice:
    case FeedbackQuestionType.Unknown:
    default:
      return optional || !!value;
  }
};

type SectionProps = {
  sectionName: string | null;
  answers: IEmployeeFeedbackAnswer[];
  onUpdateAnswer: (answer: IEmployeeFeedbackAnswer, value: string) => void;
  readOnly: boolean;
  employeeAnswers?: Maybe<EmployeeAnswers>;
};

type EmployeeAnswers = {
  answers: ReadonlyArray<AnswerForMapType>;
  employeeName: string;
  submittedAt: Maybe<string>;
};

const Section: React.FC<SectionProps> = ({
  sectionName,
  answers,
  onUpdateAnswer,
  readOnly,
  employeeAnswers,
}) => (
  <EmployeeFeedbackAccordion title={sectionName}>
    {answers.map((answer) => {
      let employeeAns;
      let ansMapped;
      let employeeName = '';

      if (employeeAnswers) {
        employeeAns = employeeAnswers.answers.find(
          (ea) =>
            ea.flair__Employee_Feedback_Question__c ===
            answer.questionOriginal.Id,
        );

        ansMapped = employeeAns
          ? mapToComparedFeedbackAnswer(employeeAns, answer.questionOriginal)
          : null;

        employeeName = employeeAnswers.employeeName;
      }

      return (
        <Row className="border-bottom mb-4" key={answer.id}>
          {employeeAnswers && ansMapped && (
            <Col>
              <FeedbackCard>
                <FeedbackAnswerSection
                  questionVisibility={
                    answer.questionOriginal
                      .flair__Feedback_Question_Visibility__c
                  }
                  answerVisibility={
                    answer.questionOriginal.flair__Answer_Visibility__c
                  }
                  isAnswerAuthor={false}
                  isEmployeeSection={true}
                  employeeName={employeeName}
                  isSubmitted={!!employeeAnswers.submittedAt}>
                  <FeedbackAnswer
                    answer={ansMapped}
                    employeeName={employeeName}
                    side="left"
                  />
                </FeedbackAnswerSection>
              </FeedbackCard>
            </Col>
          )}
          <Col>
            <FeedbackCard>
              <EmployeeFeedbackField
                answer={answer}
                onUpdateAnswer={onUpdateAnswer}
                readOnly={readOnly}
              />
            </FeedbackCard>
          </Col>
        </Row>
      );
    })}
  </EmployeeFeedbackAccordion>
);

export const EmployeeFeedbackQuestionnaire: React.FC<Props> = ({
  answers,
  setIsComplete,
  seeAllQuestions,
  readOnly = false,
  isPeerReviewer,
  employeeAnswers,
}) => {
  const [updatedAnswers, setUpdatedAnswers] = useState<
    IEmployeeFeedbackAnswer[]
  >(() =>
    answers.map((answer) => {
      const isVisible = isPeerReviewer
        ? visibleForPeers(answer)
        : visibleForManager(answer);

      const showOnlyQuestionName = seeAllQuestions && !isVisible;
      return {
        id: answer.Id,
        answer: answer.flair__Feedback__c,
        question: answer.question.flair__Question__c,
        description: answer.question.flair__Description__c,
        hint: answer.question.flair__Hint__c,
        choices: answer.question.flair__Choices__c,
        requiredChoices: answer.question.flair__Required_Choice__c,
        notes: answer.flair__Note__c,
        section: answer.question.flair__Section_Name__c,
        type: answer.question.flair__Feedback_Question_Type__c,
        showOptionalComment: answer.question.flair__Optional_Comment__c,
        questionOriginal: answer.question,
        isOptional:
          showOnlyQuestionName ||
          isFieldOptional(
            answer.question.flair__Optional__c,
            answer.question.flair__Feedback_Question_Type__c,
            answer.question.flair__Required_Choice__c,
          ),
        isComplete:
          showOnlyQuestionName ||
          isFieldComplete(
            answer.flair__Feedback__c,
            answer.question.flair__Optional__c,
            answer.question.flair__Feedback_Question_Type__c,
            answer.question.flair__Required_Choice__c,
          ),
        showOnlyQuestionName,
      };
    }),
  );

  const onUpdateAnswer = useCallback(
    (answer: IEmployeeFeedbackAnswer, value: string) => {
      setUpdatedAnswers((answers) => {
        const index = answers.findIndex((a) => a.id === answer.id);

        if (index === -1) {
          return answers;
        }

        const updatedAnswers = [...answers];

        updatedAnswers[index] = {
          ...answer,
          answer: value,
          isComplete: isFieldComplete(
            value,
            answer.isOptional,
            answer.type,
            answer.requiredChoices,
          ),
        };

        return updatedAnswers;
      });
    },
    [setUpdatedAnswers],
  );

  useEffect(() => {
    setIsComplete(updatedAnswers.every((a) => a.isComplete));
  }, [updatedAnswers, setIsComplete]);

  // Group answers by section
  const sections = updatedAnswers.reduce(
    (result, answer) => {
      const sectionName = answer.section;
      const sectionIndex = result.findIndex(
        (s) => s.sectionName === sectionName,
      );

      if (sectionIndex !== -1) {
        const section = result[sectionIndex];

        // append the new answer to that section
        result[sectionIndex] = {
          ...section,
          answers: [...section.answers, answer],
        };

        return result;
      }

      // append a new section with the new answer
      return [
        ...result,
        {
          sectionName: sectionName,
          answers: [answer],
        },
      ];
    },
    [] as {
      sectionName: string | null;
      answers: IEmployeeFeedbackAnswer[];
    }[],
  );

  return (
    <div>
      {sections.map((section) => (
        <Section
          key={section.sectionName ?? 'unknow-section'}
          sectionName={section.sectionName}
          answers={section.answers}
          onUpdateAnswer={onUpdateAnswer}
          readOnly={readOnly}
          employeeAnswers={employeeAnswers}
        />
      ))}
    </div>
  );
};
