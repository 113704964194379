import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormattedDate from '../../../../components/date/FormattedDate';
import FormattedDateTime from '../../../../components/datetime/FormattedDateTime';
import FormattedTime from '../../../../components/time/FormattedTime';
import { useCurrentLocale } from '../../../../context/I18n';
import { FieldType } from '../../__generated__/graphql';
import { FieldOption } from './types';

type Props = {
  value?: string | null;
  type: FieldType;
  options: FieldOption[];
};

const NotAvailable: React.FC = () => {
  const { t } = useTranslation();

  return <span className="text-muted">{t('general.notAvailable')}</span>;
};

export const FieldValue: React.FC<Props> = ({ value, type, options }) => {
  const locale = useCurrentLocale();
  if (!value) {
    return <NotAvailable />;
  }

  switch (type) {
    case FieldType.Boolean: {
      return (
        <input
          type="checkbox"
          className="form-check-input"
          readOnly
          checked={value === 'true'}
        />
      );
    }
    case FieldType.Time: {
      return <FormattedTime time={moment.utc(value, 'HH:mm:ss.SSSZ')} />;
    }
    case FieldType.Date: {
      return <FormattedDate day={value} format="short" />;
    }
    case FieldType.Datetime: {
      return <FormattedDateTime dateTime={value} format="short" />;
    }
    case FieldType.Picklist: {
      const entry = options.find((e) => e.value === value);

      return entry?.label ? <>{entry.label}</> : <>{value}</>;
    }
    case FieldType.Multipicklist: {
      const values = value.split(';');

      return (
        <>
          {values
            .map((v) => {
              const entry = options.find((e) => e.value === v);
              return entry?.label || v;
            })
            .join(';')}
        </>
      );
    }
    case FieldType.Url: {
      return <a href={value}>{value}</a>;
    }
    case FieldType.Email: {
      return <a href={`mailto:${value}`}>{value}</a>;
    }
    case FieldType.Phone: {
      return <a href={`tel:${value}`}>{value}</a>;
    }
    case FieldType.Currency: // TODO: Get the currency symbol
    case FieldType.Integer:
    case FieldType.Long:
    case FieldType.Double: {
      return <>{Intl.NumberFormat(locale).format(parseFloat(value))}</>;
    }
    case FieldType.Percent: {
      return (
        <>
          {Intl.NumberFormat(locale, { style: 'percent' }).format(
            parseFloat(value) / 100.0,
          )}
        </>
      );
    }
    default: {
      return <>{value}</>;
    }
  }
};
