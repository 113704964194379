import React from 'react';
import { DayChanges, MinutesWithChangeRequest } from './types';
import { DayTimeEntriesChangesPopover } from '../DayTimeEntries/DayTimeEntriesChangesPopover/DayTimeEntriesChangesPopover';
import { DurationCell } from '../../../components/TimeSheetsTable/Cells/DurationCell';

type Props = {
  timeSheetId: string;
  employeeId: string;
  day: string;
  dayChanges: DayChanges;
  fieldKey: string;
  value: MinutesWithChangeRequest;
};

export const ManagerDurationCell: React.FC<Props> = ({
  fieldKey,
  timeSheetId,
  employeeId,
  day,
  dayChanges,
  value,
}) => {
  const popover = (
    <DayTimeEntriesChangesPopover
      fieldKey={fieldKey}
      timeSheetId={timeSheetId}
      employeeId={employeeId}
      day={day}
      dayChanges={dayChanges}
    />
  );
  return <DurationCell value={value} popover={popover} />;
};
