import React from 'react';
import styled from '@emotion/styled';
import Day from './Day';
import { WorkingDay } from '../../../../__generated__/graphql';
import { currentWeek, eachDayOfInterval } from '../../../../../../utils/date';
import moment from 'moment';
import { dayOfWeekToWokingDay } from '../../../TimeSheetsControlling/CurrentTimeSheetsTab/TimeSheetEntryDaysWorked';

type Props = {
  workingDays: ReadonlyArray<WorkingDay>;
};

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const WorkingDays: React.FC<Props> = ({ workingDays }) => {
  const days = eachDayOfInterval(currentWeek());

  return (
    <Container>
      {days.map((day) => {
        const mDay = moment(day);
        const label = mDay.format('ddd');
        const variant = workingDays.includes(dayOfWeekToWokingDay(mDay.day()))
          ? 'workday'
          : 'non_workday';

        return <Day key={day.toISOString()} variant={variant} label={label} />;
      })}
    </Container>
  );
};

export default WorkingDays;
