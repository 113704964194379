import React from 'react';
import { Card, Table } from 'react-bootstrap';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { useCurrencyFormatter } from '../../../../../hooks/useCurrencyFormatter';
import CardHeader from '../../../components/CardHeader';
import { orderBy } from 'lodash';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { OneTimeCompensationCard_OneTimeCompensationFragment } from '../../../__generated__/graphql';

type Props = {
  oneTimeCompensations: ReadonlyArray<OneTimeCompensationCard_OneTimeCompensationFragment>;
};

const OneTimeComponsationsCard: React.FC<Props> = ({
  oneTimeCompensations,
}) => {
  const t = useNamespacedTranslation(
    'employeePage.overview.oneTimeCompensationsCard',
  );
  const formatCurrency = useCurrencyFormatter();
  return (
    <Card>
      <CardHeader>{t('title')}</CardHeader>
      <Table className="card-table text-nowrap" bordered size="sm" responsive>
        <thead>
          <tr>
            <th>{t('type')}</th>
            <th>{t('amount')}</th>
            <th>{t('compensationDate')}</th>
            <th>{t('status')}</th>
          </tr>
        </thead>
        <tbody>
          {orderBy(
            oneTimeCompensations,
            'flair__Compensation_Date__c',
            'desc',
          ).map((comp) => (
            <tr key={comp.Id}>
              <td>{comp.flair__Compensation_Type__c}</td>
              <td>
                {formatCurrency({
                  value: comp.flair__Amount__c,
                  currency: comp.CurrencyIsoCode,
                })}
              </td>
              <td>
                <FormattedDate
                  day={comp.flair__Compensation_Date__c}
                  format="short"
                />
              </td>
              <td>{comp.flair__Status__c}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default OneTimeComponsationsCard;
