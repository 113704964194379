import { PureQueryOptions } from '@apollo/client';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Col, Modal, ModalFooter, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import CancelButton from '../../../../components/form/CancelButton';
import { Datepicker } from '../../../../components/form/Datepicker';
import { Form } from '../../../../components/form/Form';
import { Input } from '../../../../components/form/Input';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { yupTimeSchema } from '../../../../initializers/yup';
import { useUpdateEmployeeMeetingMutation } from '../../__generated__/graphql';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

type Props = {
  meetingId: string;
  startDatetime: string | null;
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  refetchQueries?: Array<PureQueryOptions>;
};

type FormData = {
  startDate?: Date;
  startTime?: string;
};

const validationSchema = yup.object().shape<FormData>({
  startDate: yup.date().required(),
  startTime: yupTimeSchema().required(),
});

export const EmployeeFeedbackMeetingModal: React.FC<Props> = ({
  meetingId,
  show,
  startDatetime,
  onClose,
  onSuccess,
  refetchQueries,
}) => {
  const { t } = useTranslation();

  const [updateMeeting] = useUpdateEmployeeMeetingMutation({
    /* eslint-disable-next-line no-restricted-syntax */
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const errorHandler = useMutationErrorHandler();

  const defaultValues = useMemo(() => {
    if (!startDatetime) {
      return {
        startTime: '',
      };
    }

    const dateTime = moment(startDatetime);

    return {
      startDate: dateTime.toDate(),
      startTime: dateTime.format('HH:mm'),
    };
  }, [startDatetime]);

  const onSubmit = useCallback(
    async ({ startDate, startTime }: FormData) => {
      const date = moment(startDate);
      const time = moment(startTime, 'HH:mm');
      date.set({
        h: time.hour(),
        m: time.minutes(),
        s: time.seconds(),
        ms: time.milliseconds(),
      });

      await updateMeeting({
        variables: {
          input: {
            id: meetingId,
            startDatetime: date.toISOString(),
          },
        },
      })
        .then(() => {
          onClose();
          onSuccess && onSuccess();
        })
        .catch(errorHandler);
    },
    [updateMeeting, meetingId, errorHandler, onClose, onSuccess],
  );

  return (
    <Modal show={show} onHide={onClose} centered enforceFocus={false}>
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        <Modal.Header closeButton className="card-header">
          <h4 className="card-header-title">
            {t(
              'employeePage.performanceReview.nextPerformanceReview.bookMeeting.modal.title',
            )}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Datepicker
                name="startDate"
                label={t(
                  'employeePage.performanceReview.nextPerformanceReview.bookMeeting.modal.label.date',
                )}
              />
            </Col>
            <Col>
              <Input
                name="startTime"
                type="time"
                label={t(
                  'employeePage.performanceReview.nextPerformanceReview.bookMeeting.modal.label.timeWithTimeZone',
                  {
                    timeZone,
                    interpolation: { escapeValue: false },
                  },
                )}
              />
            </Col>
          </Row>
        </Modal.Body>

        <ModalFooter>
          <CancelButton size="sm" onClick={onClose}>
            {t('form.general.cancelButton')}
          </CancelButton>

          <SubmitButton size="sm">{t('form.general.saveButton')}</SubmitButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
