import { useMemo } from 'react';
import { useMyTimeTrackingQuery } from '../../__generated__/graphql';
import { mapTimeTrackingState } from './mappings';

export const useTimeTrackingState = () => {
  const { data: timeTrackingServerData } = useMyTimeTrackingQuery();

  const timeTrackingState = useMemo(() => {
    if (!timeTrackingServerData?.me.currentTimeEntry) {
      return null;
    }
    return mapTimeTrackingState(timeTrackingServerData.me.currentTimeEntry);
  }, [timeTrackingServerData]);

  return {
    timeTrackingState,
    currentTimeEntryId: timeTrackingServerData?.me.currentTimeEntry?.Id ?? null,
  };
};
