import React from 'react';
import { TableRow, isTableDaySubRow, isTableRowTimeSheet } from '../types';
import DurationFormat from '../../DurationFormat';
import { TimeBalanceForDay } from '../../TimeBalanceForDay/TimeBalanceForDay';

type Props = {
  row: TableRow;
};

export const TimeBalanceCell: React.FC<Props> = ({ row }) => {
  if (isTableDaySubRow(row)) {
    return <DurationFormat minutes={row.timeBalanceAccumulatedMinutes} />;
  } else if (isTableRowTimeSheet(row)) {
    return (
      <TimeBalanceForDay day={row.period.endDay} employeeId={row.employee.id} />
    );
  } else return null;
};
