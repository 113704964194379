import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../../components/form/SubmitButton';

type Props = {
  error?: string;
  disabled?: boolean;
};

const FormButtons: React.FC<Props> = ({ error, disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      {error && <Error>{error}</Error>}
      <SubmitButton disabled={disabled} block>
        {t('requestAbsence.form.submit')}
      </SubmitButton>
    </>
  );
};

const Error = ({
  className,
  ...rest
}: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div
    className={classNames(
      'd-flex',
      'justify-content-start',
      'w-100',
      'text-danger',
      className,
    )}
    {...rest}
  />
);

export default FormButtons;
