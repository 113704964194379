import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  actions,
  initialState,
  SerialProcessingOptions,
  serialProcessingReducer,
} from './serialProcessingReducer';

type ProcessFunction = (ids: string[]) => Promise<void>;

type CompleteCallback = (id: string) => void;

export type SerialProcessingHookReturnType = {
  processItem: (id: string, onCompleted?: CompleteCallback) => void;
  processingIds: string[];
};

export const useSerialProcessing = (
  processFunction: ProcessFunction,
  options: SerialProcessingOptions,
): SerialProcessingHookReturnType => {
  const onCompleteCallbacks = useRef<Map<string, CompleteCallback>>(new Map());

  const [state, dispatch] = useReducer(
    serialProcessingReducer(options),
    initialState,
  );

  const { toRequestProcessingIds } = state;

  useEffect(() => {
    if (toRequestProcessingIds.length > 0) {
      dispatch(actions.processingStarted());
      processFunction(toRequestProcessingIds).finally(() => {
        dispatch(actions.processingCompleted());
        const onCompleteCallbacksMap = onCompleteCallbacks.current;
        toRequestProcessingIds.forEach((completedId) => {
          if (onCompleteCallbacksMap.has(completedId)) {
            onCompleteCallbacksMap.get(completedId)!(completedId);
            onCompleteCallbacksMap.delete(completedId);
          }
        });
      });
    }
  }, [dispatch, processFunction, toRequestProcessingIds, onCompleteCallbacks]);

  const processItem = useCallback(
    (id: string, onCompleted?: CompleteCallback) => {
      if (onCompleted) {
        onCompleteCallbacks.current.set(id, onCompleted);
      }
      dispatch(actions.addItem(id));
    },
    [dispatch, onCompleteCallbacks],
  );

  const processingIds = useMemo(
    () => [...state.pendingIds, ...state.processingIds],
    [state.pendingIds, state.processingIds],
  );

  return { processingIds, processItem };
};
