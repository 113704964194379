import * as React from 'react';

const UmbrellaOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M6.99847 7.50201V12.5008C6.99847 12.766 6.89313 13.0203 6.70564 13.2078C6.51815 13.3953 6.26386 13.5006 5.9987 13.5006C5.73355 13.5006 5.47925 13.3953 5.29176 13.2078C5.10427 13.0203 4.99894 12.766 4.99894 12.5008M6.99847 7.50201L6.69573 7.27488C6.22479 6.92173 5.64228 6.75029 5.05513 6.79201C4.46797 6.83373 3.91556 7.08582 3.49929 7.50201C3.30236 7.30507 3.06856 7.14884 2.81125 7.04225C2.55394 6.93567 2.27816 6.88081 1.99965 6.88081C1.72113 6.88081 1.44535 6.93567 1.18804 7.04225C0.930728 7.14884 0.696932 7.30507 0.5 7.50201C0.5 3.91286 3.40931 1.00354 6.99847 1.00354M6.99847 7.50201L7.30121 7.27488C7.77214 6.92173 8.35465 6.75029 8.94181 6.79201C9.52896 6.83373 10.0814 7.08582 10.4976 7.50201C10.6946 7.30507 10.9284 7.14884 11.1857 7.04225C11.443 6.93567 11.7188 6.88081 11.9973 6.88081C12.2758 6.88081 12.5516 6.93567 12.8089 7.04225C13.0662 7.14884 13.3 7.30507 13.4969 7.50201C13.4969 3.91286 10.5876 1.00354 6.99847 1.00354M6.99847 1.00354V0.503662"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default UmbrellaOutlineIcon;
