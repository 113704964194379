import React, { useMemo } from 'react';
import {
  TimeSheetsTableRow,
  Props as TimeSheetsTableRowProps,
} from './TimeSheetsTableRow';
import {
  ProjectTimeEntriesFilterInput,
  useProjectsTimeSheetDetailsQuery,
} from '../../../../__generated__/graphql';
import { TableDayAnySubRow, ViewBy, isTableRowTimeSheet } from '../types';
import {
  createTableDaySubRowLoadingError,
  createTableDaySubRowLoading,
  mapToDaySubRows,
} from '../mappings';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { useSystemErrorMessageHandler } from '../../../../../../hooks/useMutationErrorHandler';

type Props = Omit<TimeSheetsTableRowProps, 'subRows'> & {
  projectsTimeEntriesQueryFilter: ProjectTimeEntriesFilterInput;
  viewBy: ViewBy;
};

export const TimeSheetsTableRowConnected: React.FC<Props> = (props) => {
  const { row, expandedRowIdSet } = props;
  const t = useNamespacedTranslation('timeTracking.projects.controlling.table');
  const getErrorMessage = useSystemErrorMessageHandler();

  const timeSheetId = isTableRowTimeSheet(row) ? row.timeSheetId : '';
  const projectId = isTableRowTimeSheet(row) ? row.projectId : undefined;
  const skipSubrowsGetting =
    !timeSheetId || !isTableRowTimeSheet(row) || !expandedRowIdSet.has(row.id);

  const { data, loading, error } = useProjectsTimeSheetDetailsQuery({
    variables: {
      id: timeSheetId,
      projectTimeEntriesFilter: props.projectsTimeEntriesQueryFilter,
    },
    skip: skipSubrowsGetting,
  });

  const subRows: TableDayAnySubRow[] | undefined = useMemo(() => {
    if (!timeSheetId.length) {
      return [];
    }
    if (loading) {
      return [createTableDaySubRowLoading(timeSheetId)];
    }
    if (error) {
      return [
        createTableDaySubRowLoadingError(
          timeSheetId,
          t('errorLoading', {
            error: getErrorMessage(error),
          }),
        ),
      ];
    }
    if (data) {
      return mapToDaySubRows(
        data.timeSheet,
        projectId,
        props.viewBy === 'employee',
      );
    }
    return [];
  }, [
    t,
    timeSheetId,
    getErrorMessage,
    data,
    loading,
    error,
    projectId,
    props.viewBy,
  ]);

  return <TimeSheetsTableRow {...props} subRows={subRows} />;
};
