import React from 'react';
import FlairIcon, {
  FlairIconName,
} from '../../../../../../../atomic/atoms/FlairIcon';

type Props = {
  icon: FlairIconName;
  label: string;
  className?: string;
  noWrap?: boolean;
};

export const GoalLabel: React.FC<Props> = ({
  icon,
  label,
  className,
  noWrap,
}) => (
  <span
    className={[className, noWrap ? 'text-nowrap' : null]
      .filter((x) => x !== null)
      .join(' ')}>
    <FlairIcon icon={icon} size="xs" />
    &nbsp;
    {label}
  </span>
);
