import React from 'react';

import Select, {
  components as selectComponents,
  GroupBase,
  Props as ReactSelectProps,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { Theme } from '../../../../../theme';
import {
  supportedWorkflowItemStatuses,
  WorkflowItemStatus,
} from '../WorkflowItems/types';
import { WorkflowItemStatusComponent } from './WorkflowItemStatus';

const defaultStyles: StylesConfig<OptionType, false> = {
  control: (provided, { isFocused }) => ({
    ...provided,
    minWidth: '13rem',
    borderRadius: Theme.border.radius,
    border: `${Theme.border.width} solid ${Theme.input.border.color}`,
    borderColor: isFocused ? Theme.input.border.focusColor : undefined,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: Theme.input.border.focusColor,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: Theme.input.placeholder.color,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    color: Theme.input.select.option.color,
    backgroundColor: isSelected
      ? Theme.input.select.option.focusedBg
      : Theme.input.select.option.bg,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: Theme.input.select.option.hoverBg,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: Theme.input.border.color,
    ':hover': {
      color: Theme.input.border.focusColor,
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    border: `${Theme.border.width} solid ${Theme.input.border.focusColor}`,
    borderRadius: Theme.border.radius,
  }),
};

type Props = Omit<
  ReactSelectProps<OptionType, false>,
  'value' | 'onChange' | 'options'
> & {
  value: WorkflowItemStatus | null;
  onChange: (value: WorkflowItemStatus | null) => void;
};

type OptionType = {
  label: string;
  value: string;
};

const options: OptionType[] = supportedWorkflowItemStatuses.map((status) => ({
  label: status,
  value: status,
}));

const defaultComponents: SelectComponentsConfig<
  OptionType,
  false,
  GroupBase<OptionType>
> = {
  IndicatorSeparator: null,
  SingleValue: ({ data, ...props }) => (
    <selectComponents.SingleValue data={data} {...props}>
      <WorkflowItemStatusComponent status={data.value as WorkflowItemStatus} />
    </selectComponents.SingleValue>
  ),
  Option: ({ data, ...props }) => (
    <selectComponents.Option data={data} {...props}>
      <WorkflowItemStatusComponent status={data.value as WorkflowItemStatus} />
    </selectComponents.Option>
  ),
};

export const WorkflowItemStatusSelect: React.FC<Props> = ({
  onChange,
  value,
  ...props
}) => {
  return (
    <Select
      menuPlacement="top"
      options={options}
      components={defaultComponents}
      styles={defaultStyles}
      {...props}
      onChange={(optionValue) => {
        if (optionValue && 'value' in optionValue) {
          onChange((optionValue.value as WorkflowItemStatus) ?? null);
        }
      }}
      value={options.find((x) => x.value === value)}
    />
  );
};
