import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

const i18nPrefix = 'cheers.employeePage.makePublic';

export type CelebrationMakePublicConfirmationProps = {
  visible: boolean;
  submitInProgress?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};
export const CelebrationMakePublicConfirmation = ({
  visible,
  submitInProgress,
  onClose,
  onSubmit,
}: CelebrationMakePublicConfirmationProps) => {
  const t = useNamespacedTranslation(i18nPrefix);

  return (
    <Modal show={visible} onHide={onClose} centered>
      <Modal.Body className="d-flex flex-column align-items-center px-4">
        <div className="exclamation-mark-container">!</div>
        <h2 className="mt-4">{t('confirmationTitle')}</h2>
        <div className="mt-2">{t('confirmationText')}</div>
        <div className="mt-4">
          <Button
            className="me-3"
            onClick={onClose}
            variant="outline-secondary"
            label={t('cancel')}
            disabled={submitInProgress}
          />

          <Button
            isProcessing={submitInProgress}
            onClick={onSubmit}
            label={t('continue')}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
