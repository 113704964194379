import React from 'react';
import {
  TimeSheetNavigation,
  TimeSheetNavigationMode,
} from './TimeSheetNavigation';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import { useTimeSheetsTopPanelQuery } from '../../../__generated__/graphql';

type Props = {
  reportsMode: ManagerReportsMode;
  selectedMode: TimeSheetNavigationMode;
};

export const TimeSheetNavigationConnected: React.FC<Props> = ({
  reportsMode,
  selectedMode,
}) => {
  // in general is bad practice to reuse query,
  // but here it makes sense to reuse useTimeSheetsTopPanelQuery to do not requery same things twice
  const { data, loading } = useTimeSheetsTopPanelQuery({
    variables: { managerReportsType: mapManagerReportsType(reportsMode) },
  });

  const pendingItems = data?.manager.timeSheetsPagination.items;

  return (
    <TimeSheetNavigation
      loading={loading}
      pendingApprovalCount={pendingItems ? pendingItems.length : 0}
      selectedMode={selectedMode}
    />
  );
};
