import * as React from 'react';

const FileZipIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 12V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V12"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.25 3V8.25H19.5"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 18.75H17.25C17.6478 18.75 18.0294 18.592 18.3107 18.3107C18.592 18.0294 18.75 17.6478 18.75 17.25C18.75 16.8522 18.592 16.4706 18.3107 16.1893C18.0294 15.908 17.6478 15.75 17.25 15.75H15.75V20.25"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 15.75V20.25"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25 15.75H8.25L5.25 20.25H8.25"
        stroke="#196879"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default FileZipIcon;
