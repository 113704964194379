import * as React from 'react';

const StarOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <g clipPath="url(#clip0_303_54907)">
        <path
          d="M14.9967 6.49955H9.62297L7.99835 1.50073L6.37373 6.49955H1L5.37397 9.49885L3.68687 14.4977L7.99835 11.3734L12.3098 14.4977L10.6227 9.49885L14.9967 6.49955Z"
          stroke="currentColor"
          strokeWidth="0.999764"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_303_54907">
          <rect
            width="15.9962"
            height="15.9962"
            fill="white"
            transform="translate(0 0.000732422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default StarOutlineIcon;
