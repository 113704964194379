import * as React from 'react';

const MagnifyingGlassOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M21.5357 20.4696L16.8417 15.7765C18.2022 14.1431 18.8806 12.048 18.7358 9.92715C18.591 7.80629 17.6341 5.82289 16.0642 4.38956C14.4943 2.95623 12.4323 2.18333 10.307 2.23163C8.18175 2.27993 6.15694 3.14571 4.65377 4.64888C3.1506 6.15205 2.28481 8.17687 2.23651 10.3021C2.18821 12.4274 2.96112 14.4894 4.39444 16.0593C5.82777 17.6293 7.81117 18.5861 9.93203 18.7309C12.0529 18.8757 14.1479 18.1973 15.7813 16.8368L20.4745 21.5308C20.5442 21.6005 20.6269 21.6558 20.7179 21.6935C20.809 21.7312 20.9065 21.7506 21.0051 21.7506C21.1036 21.7506 21.2012 21.7312 21.2923 21.6935C21.3833 21.6558 21.466 21.6005 21.5357 21.5308C21.6054 21.4612 21.6607 21.3784 21.6984 21.2874C21.7361 21.1963 21.7555 21.0988 21.7555 21.0002C21.7555 20.9017 21.7361 20.8041 21.6984 20.713C21.6607 20.622 21.6054 20.5393 21.5357 20.4696ZM3.7551 10.5002C3.7551 9.16519 4.15098 7.86015 4.89268 6.75011C5.63438 5.64008 6.68858 4.77492 7.92198 4.26403C9.15538 3.75314 10.5126 3.61946 11.822 3.87991C13.1313 4.14036 14.3341 4.78324 15.2781 5.72724C16.2221 6.67125 16.8649 7.87398 17.1254 9.18335C17.3858 10.4927 17.2522 11.8499 16.7413 13.0833C16.2304 14.3167 15.3652 15.3709 14.2552 16.1126C13.1452 16.8543 11.8401 17.2502 10.5051 17.2502C8.71549 17.2482 6.99976 16.5364 5.73432 15.271C4.46888 14.0056 3.75708 12.2898 3.7551 10.5002Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default MagnifyingGlassOutlineIcon;
