import React from 'react';
import { Card, Col, Row, Badge } from 'react-bootstrap';
import ServerError from '../../../../../components/ServerError';
import Loading from './Loading';
import PendingTimeSheets from './PendingTimeSheets';
import { useTranslation } from 'react-i18next';
import PendingAbsenceRequests from './PendingAbsenceRequests';
import PendingTimeEntryChangeRequests from './PendingTimeEntryChangeRequests';
import { CardTitle } from '../Heading';
import { useHomePendingApprovalCardQuery } from '../../../__generated__/graphql';
import CardRow from '../components/CardRow';
import CardBody from '../components/CardBody';
import PendingExpenses from './PendingExpenses';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../../hooks/useManagerReportsMode';

const PendingApprovalCard: React.FC = () => {
  const { t } = useTranslation();
  const [reportsMode] = useManagerReportsMode('myteam');
  const { data, loading, error } = useHomePendingApprovalCardQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  const count =
    data.me.pendingAbsenceRequestToMe.length +
    data.manager.pendingTimeEntryChangeRequests.length +
    data.manager.pendingTimeSheets.length +
    data.manager.pendingExpenses.length;

  if (!count) {
    return <></>;
  }

  return (
    <Card className="rounded-4">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <CardTitle>
              {t('home.pendingApprovalCard.title')}
              <Badge className="ms-2" bg="warning">
                {count}
              </Badge>
            </CardTitle>
          </Col>
        </Row>
      </Card.Header>
      <CardBody>
        {data.me.pendingAbsenceRequestToMe.length > 0 && (
          <CardRow>
            <PendingAbsenceRequests
              absenceRequests={data.me.pendingAbsenceRequestToMe}
            />
          </CardRow>
        )}

        {data.manager.pendingTimeEntryChangeRequests.length > 0 && (
          <CardRow>
            <PendingTimeEntryChangeRequests
              changeRequests={data.manager.pendingTimeEntryChangeRequests}
            />
          </CardRow>
        )}
        {data.manager.pendingTimeSheets.length > 0 && (
          <CardRow>
            <PendingTimeSheets timeSheets={data.manager.pendingTimeSheets} />
          </CardRow>
        )}
        {data.manager.pendingExpenses.length > 0 && (
          <CardRow>
            <PendingExpenses expenses={data.manager.pendingExpenses} />
          </CardRow>
        )}
      </CardBody>
    </Card>
  );
};

export default PendingApprovalCard;
