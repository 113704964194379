import styled from '@emotion/styled';
import React from 'react';
import Hint from '../../../../../components/hint';
import Avatar from '../../../../../atomic/molecules/avatar/Avatar';

type Props = {
  hintText?: string;
  initials?: string;
  avatarUrl?: string;
};

export const GoalAvatar: React.FC<Props> = ({
  hintText,
  initials,
  avatarUrl,
}) => {
  if (hintText) {
    return (
      <Hint id={`avatar-tooltip`} text={hintText}>
        <AvatarContainer>
          <Avatar initials={initials ?? ''} size="xs" avatarUrl={avatarUrl} />
        </AvatarContainer>
      </Hint>
    );
  } else {
    return (
      <AvatarContainer>
        <Avatar initials={initials ?? ''} size="xs" avatarUrl={avatarUrl} />
      </AvatarContainer>
    );
  }
};

const AvatarContainer = styled('div')({
  minWidth: 26,
});
