import React, { useContext } from 'react';
import { TicketModalContext } from '../TicketModalContext';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { IssueFormField } from '../formFields/IssueFormField';
import { DetailsFormField } from '../formFields/DetailsFormField';
import { EmployeeFormField } from '../formFields/EmployeeFormField';
import { ShareWithManagerFormField } from '../formFields/ShareWithManagerFormField';
import { StayAnonymousFormField } from '../formFields/StayAnonymousFormField';
import { WhistleBlowerProtectionSection } from '../WhistleBlowerProtectionSection';
type Props = {
  isManager: boolean;
};

const i18Path = 'support.tickets.newTicket';
export const TicketDetailsStep: React.FC<Props> = ({ isManager }) => {
  const {
    value,
    onChange: onFieldChange,
    policy,
  } = useContext(TicketModalContext);
  const t = useNamespacedTranslation(i18Path);
  return (
    <div className="mx-4 d-flex flex-column">
      <div className="px-4 d-flex flex-column gap-4">
        {value?.hasWhistleblowerProtection && (
          <WhistleBlowerProtectionSection />
        )}
        {isManager && (
          <div>
            <EmployeeFormField t={t} />
          </div>
        )}
        <div>
          <IssueFormField t={t} />
        </div>
        <div>
          <DetailsFormField t={t} onFieldChange={onFieldChange} value={value} />
        </div>
        {!isManager && policy?.ticketVisibility === 'EMPLOYEE_CHOICE' && (
          <div className="d-flex gap-3 flex-column">
            <ShareWithManagerFormField
              t={t}
              onFieldChange={onFieldChange}
              formFieldValues={value}
            />
            <StayAnonymousFormField
              t={t}
              onFieldChange={onFieldChange}
              formFieldValues={value}
            />
          </div>
        )}
      </div>
    </div>
  );
};
