import React from 'react';
import { Card } from 'react-bootstrap';

type Props = {
  title?: string;
  bodyClassName?: string;
};

const Section: React.FC<Props> = ({ children, title, bodyClassName }) => {
  return (
    <Card>
      {title && (
        <Card.Header>
          <h3 className="card-header-title">{title}</h3>
        </Card.Header>
      )}
      <Card.Body className={bodyClassName}>{children}</Card.Body>
    </Card>
  );
};

export default Section;
