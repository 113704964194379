import * as React from 'react';

const EllipseFilledIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.99944 14.4972C4.41623 14.4972 1.50098 11.5819 1.50098 7.99871C1.50098 4.41549 4.41623 1.50024 7.99944 1.50024C11.5827 1.50024 14.4979 4.41549 14.4979 7.99871C14.4979 11.5819 11.5827 14.4972 7.99944 14.4972Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default EllipseFilledIcon;
