import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col } from 'react-bootstrap';
import { EmptyState } from '../../components/EmptyStateCard';

const EmptyRequests: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card.Body className="text-center">
      <Row className="justify-content-center">
        <Col>
          <EmptyState title={t('timeTracking.changeRequests.emptyState')} />
        </Col>
      </Row>
    </Card.Body>
  );
};

export default EmptyRequests;
