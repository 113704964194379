import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { Link } from '../../../../../Router';
import SoftBadge from '../../../components/SoftBadge';
import { routes } from '../../../routes';
import {
  Department as DepartmentType,
  Employee as EmployeeType,
  Location as LocationType,
} from '../../../__generated__/graphql';
import { TableContent } from '../../components/TableContent';

type Department = Pick<DepartmentType, 'Name'>;
type Location = Pick<LocationType, 'Name'>;
type Employee = Pick<
  EmployeeType,
  'Id' | 'Name' | 'flair__Position__c' | 'flair__Start_Date__c'
> & {
  department: Department | null;
  location: Location | null;
};

type Props = {
  data: ReadonlyArray<Employee>;
  filter: string;
  pageSize: number;
};
type EmployeeRow = {
  id: string;
  name: string;
  department: string | null;
  location: string | null;
  position: string | null;
  joined: string | null;
};

const transformData = (data: ReadonlyArray<Employee>): EmployeeRow[] =>
  data.map((e) => ({
    id: e.Id,
    name: e.Name,
    department: e.department?.Name ?? null,
    location: e.location?.Name ?? null,
    position: e.flair__Position__c,
    joined: e.flair__Start_Date__c,
  }));

const EmployeesTable: React.FC<Props> = ({ data, filter, pageSize }) => {
  const { t } = useTranslation();

  const columns: Column<EmployeeRow>[] = useMemo(
    () => [
      {
        Header: () => <Trans t={t} i18nKey="employees.all.table.header.name" />,
        accessor: 'name',
        Cell: (props) => (
          <>
            <Link
              to={routes.employeePage.route}
              employeeId={props.row.original.id}>
              {props.value}
            </Link>

            {props.row.original.joined &&
              moment(props.row.original.joined).isSameOrAfter(moment()) && (
                <SoftBadge variant="success" className="ms-2">
                  <Trans t={t} i18nKey="employees.all.table.notStartedYet" />
                </SoftBadge>
              )}
          </>
        ),
      },
      {
        Header: <Trans t={t} i18nKey="employees.all.table.header.location" />,
        accessor: 'location',
      },
      {
        Header: <Trans t={t} i18nKey="employees.all.table.header.department" />,
        accessor: 'department',
      },
      {
        Header: <Trans t={t} i18nKey="employees.all.table.header.position" />,
        accessor: 'position',
      },
      {
        Header: <Trans t={t} i18nKey="employees.all.table.header.joined" />,
        accessor: 'joined',
        Cell: (props) =>
          props.value ? (
            <FormattedDate day={props.value} format="short" />
          ) : (
            <></>
          ),
      },
    ],
    [t],
  );

  const dataMemo = useMemo(() => sortBy(transformData(data), 'name'), [data]);

  return (
    <TableContent
      columns={columns}
      data={dataMemo}
      pageSize={pageSize}
      filter={filter}
    />
  );
};

export default EmployeesTable;
