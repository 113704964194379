import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import ServerError from '../../../../components/ServerError';
import GlobalTableFilter from '../../components/GlobalTableFilter';
import PageHeader from '../../components/PageHeader';
import { useApproveTimeEntryChangeRequestBatchUI } from '../../hooks/timeTracking';
import {
  mapManagerReportsType,
  useManagerReportsMode,
} from '../../hooks/useManagerReportsMode';
import { ManagerReportsModeSwitch } from '../../manager/components/ManagerReportsModeSwitch';
import {
  PendingTimeEntryChangeRequestsQuery,
  PendingTimeEntryChangeRequestsQueryResult,
  usePendingTimeEntryChangeRequestsQuery,
} from '../../__generated__/graphql';
import EmptyRequests from './EmptyRequests';
import TimeEntryChangeRequestsTable from './TimeEntryChangeRequestsTable';
import Loading from './TimeEntryChangeRequestsTable/Loading';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

type CardHeaderProps = {
  onFilterChange: (value: string) => void;
  requests: PendingTimeEntryChangeRequestsQuery['manager']['pendingTimeEntryChangeRequests'];
};

const CardHeader: React.FC<CardHeaderProps> = ({
  onFilterChange,
  requests,
}) => {
  const { t } = useTranslation();

  return (
    <Card.Header>
      <Row>
        <Col>
          <GlobalTableFilter
            onFilterChange={onFilterChange}
            placeholder={t('timeTracking.changeRequests.table.filterEmployees')}
          />
        </Col>
        <Col className="col-auto d-flex align-items-center">
          <ApproveAllAction requests={requests} />
        </Col>
      </Row>
    </Card.Header>
  );
};

const PendigTimeEntryChangeRequestsCard: React.FC<{
  query: PendingTimeEntryChangeRequestsQueryResult;
}> = ({ query }) => {
  const [filter, setFilter] = useState<string>('');

  const { data, loading, error } = query;

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  const requests = data.manager.pendingTimeEntryChangeRequests;

  return requests.length > 0 ? (
    <>
      <CardHeader onFilterChange={setFilter} requests={requests} />
      <TimeEntryChangeRequestsTable requests={requests} filter={filter} />
    </>
  ) : (
    <EmptyRequests />
  );
};

const ApproveAllAction: React.FC<{
  requests: PendingTimeEntryChangeRequestsQuery['manager']['pendingTimeEntryChangeRequests'];
}> = ({ requests }) => {
  const { t } = useTranslation();

  const requestIds = requests.map((r) => r.Id);

  const { handleOnApprove, inProgress, btnLabel } =
    useApproveTimeEntryChangeRequestBatchUI(
      t('timeTracking.changeRequests.approveAll'),
    );

  return (
    <Button
      label={btnLabel}
      onClick={() => handleOnApprove(requestIds)}
      size="sm"
      variant="outline-primary"
      isProcessing={inProgress}
      disabled={inProgress}
    />
  );
};

const PendigTimeEntryChangeRequests: React.FC = () => {
  const t = useNamespacedTranslation('navigation.menuItems.timeManagement');

  useFlairBreadcrumbHook([
    { label: t('title') },
    { label: t('subMenuItems.changeRequests.title') },
  ]);

  const [reportsMode, setReportsMode] = useManagerReportsMode(
    'pendingTimeEntryChangeRequests',
  );

  const query = usePendingTimeEntryChangeRequestsQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });

  return (
    <>
      <PageHeader
        title={t('subMenuItems.changeRequests.title')}
        actions={
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        }
      />
      <Card>
        <PendigTimeEntryChangeRequestsCard query={query} />
      </Card>
    </>
  );
};

export default PendigTimeEntryChangeRequests;
