import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '../../../../theme';
import avatarIcon from './avatarIcon.svg';

type Props = {
  avatarUrl: string | null;
  size: Size;
};

type Size = 'small' | 'medium' | 'large';

export const AvatarIcon: React.FC<Props> = ({ size, avatarUrl }) => {
  const sizeInPixel = getContainerSizeInPixels(size);
  if (!avatarUrl) {
    return (
      <ContainerEmpty style={{ width: sizeInPixel, height: sizeInPixel }}>
        <img src={avatarIcon} style={{ height: '65%' }} alt="Avatar Icon" />
      </ContainerEmpty>
    );
  } else {
    return (
      <Image
        src={avatarUrl}
        style={{
          width: sizeInPixel,
          height: sizeInPixel,
          border: `${Theme.border.width} dashed ${Theme.color.paper3}`,
        }}
      />
    );
  }
};

const getContainerSizeInPixels = (size: Size): number => {
  switch (size) {
    case 'small':
      return 24;
    case 'medium':
      return 153;
    case 'large':
      return 224;
  }
};

const ContainerEmpty = styled.div({
  borderRadius: '50%',
  backgroundColor: Theme.color.gray3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Image = styled.img({
  borderRadius: '50%',
  objectFit: 'cover',
});
