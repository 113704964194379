import React from 'react';
import { routes as r } from '../../routes';
import ForMe from './Reviews/ForMe';
import ForOthers from './Reviews/ForOthers';

export const routes = () => ({
  reviews: {
    route: r.performanceReviewsForReviewer.route,
    render: () => <ForOthers />,
  },
  overview: {
    route: r.performanceReview.route,
    render: () => <ForMe />,
  },
});
