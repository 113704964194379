import * as React from 'react';
import { useCallback } from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import EvaluationsMultipleFilter from './EvaluationsMultipleFilter';
import {
  Evaluation,
  EvaluationsFilterParams,
  filterDefaultData,
  SCORES_FILTER,
  STAGES_FILTER,
  STATUSES_FILTER,
} from '../types';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import { uniqBy } from 'lodash';
import i18next from 'i18next';
import ClearFiltersLink from '../../../../components/ClearFiltersLink';
import GlobalTableFilter from '../../../../components/GlobalTableFilter';

type Props = {
  evaluations: Evaluation[];
  setSearchText: (text: string) => void;
  filterData: EvaluationsFilterParams;
  setFilterData: (filter: EvaluationsFilterParams) => void;
};

const i18Path = 'recruitment.jobPage.evaluations';

const scores = [
  {
    label: `0% ${i18next.t('evaluations.strongNo')}`,
    value: '0',
  },
  {
    label: `25% ${i18next.t('evaluations.no')}`,
    value: '25',
  },
  {
    label: `75% ${i18next.t('evaluations.yes')}`,
    value: '75',
  },
  {
    label: `100% ${i18next.t('evaluations.strongYes')}`,
    value: '100',
  },
];

export const statuses = [
  {
    label: i18next.t(`${i18Path}.completed`),
    value: 'completed',
  },
  {
    label: i18next.t(`${i18Path}.overdue`),
    value: 'overdue',
  },
  {
    label: i18next.t(`${i18Path}.pending`),
    value: 'pending',
  },
];

export const EvaluationsFilters: React.FC<Props> = ({
  evaluations,
  setSearchText,
  filterData,
  setFilterData,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const onSearch = useCallback(
    (searchText: string) => {
      setSearchText(searchText);
    },
    [setSearchText],
  );

  const handleClearFilter = useCallback(() => {
    setFilterData(filterDefaultData);
  }, [setFilterData]);

  const stageOptions: FilterSelectOption[] = uniqBy(
    evaluations
      .map((s) => ({
        label: s.stage || '',
        value: s.stage || '',
      }))
      .filter((r) => !!r.value),
    'value',
  );

  return (
    <div className="d-flex align-items-center small">
      <GlobalTableFilter placeholder={t('search')} onFilterChange={onSearch} />
      <div className="me-auto">
        <div className="d-none d-md-flex align-items-center">
          <span className="me-3 text-muted text-nowrap">{t('filterBy')}</span>
          <EvaluationsMultipleFilter
            className="me-3"
            options={stageOptions}
            filter={filterData}
            filterKey={STAGES_FILTER}
            placeholder={t('stage')}
            setFilterData={setFilterData}
          />
          <EvaluationsMultipleFilter
            className="me-3"
            options={scores}
            filter={filterData}
            filterKey={SCORES_FILTER}
            placeholder={t('score')}
            setFilterData={setFilterData}
          />
          <EvaluationsMultipleFilter
            className="me-3"
            options={statuses}
            filter={filterData}
            filterKey={STATUSES_FILTER}
            placeholder={t('status')}
            setFilterData={setFilterData}
          />
          <ClearFiltersLink onClick={handleClearFilter} />
        </div>
      </div>
    </div>
  );
};
