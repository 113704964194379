import moment, { Moment } from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormattedTime from '../../../../components/time/FormattedTime';
import {
  AbsenceType,
  Absence as AbsenceGraphqlType,
} from '../../__generated__/graphql';
import { AbsenceContainer } from './BlockContainer';
import styled from '@emotion/styled';

const buildDailyAbsenceBlockProps = (
  absence: Absence,
  day: Moment,
): DailyAbsenceBlockProps => {
  const calculatePeriod = (absence: Absence, day: Moment) => {
    if (
      day.isSame(absence.flair__Start_Date__c, 'day') &&
      absence.flair__Start_Date_Half_Day__c
    ) {
      return 'afternoon';
    } else if (
      day.isSame(absence.flair__End_Date__c, 'day') &&
      absence.flair__End_Date_Half_Day__c
    ) {
      return 'morning';
    } else {
      return 'fullday';
    }
  };

  return {
    kind: AbsenceType.Daily,
    categoryName: absence.flair__Category_Name__c,
    period: calculatePeriod(absence, day),
  };
};

const buildHourlyAbsenceBlockProps = (
  absence: Absence,
  day: Moment,
): HourlyAbsenceBlockProps => {
  const calculateStartTime = (absence: Absence, day: Moment): Date =>
    day.isAfter(absence.flair__Start_Date__c, 'day')
      ? moment(absence.flair__Start_Date__c).startOf('day').toDate()
      : moment(
          `${absence.flair__Start_Date__c} ${absence.flair__Start_Time__c}`,
        ).toDate();

  const calculateEndTime = (absence: Absence, day: Moment): Date =>
    day.isBefore(absence.flair__End_Date__c, 'day')
      ? moment(absence.flair__End_Date__c).endOf('day').toDate()
      : moment(
          `${absence.flair__End_Date__c} ${absence.flair__End_Time__c}`,
        ).toDate();

  return {
    kind: AbsenceType.Hourly,
    categoryName: absence.flair__Category_Name__c,
    startTime: calculateStartTime(absence, day),
    endTime: calculateEndTime(absence, day),
  };
};

const buildBlockProps = (absence: Absence, day: Moment): BlockProps =>
  absence.flair__Type__c === AbsenceType.Daily
    ? buildDailyAbsenceBlockProps(absence, day)
    : buildHourlyAbsenceBlockProps(absence, day);

type Absence = Pick<
  AbsenceGraphqlType,
  | 'flair__Start_Date__c'
  | 'flair__Start_Time__c'
  | 'flair__Start_Date_Half_Day__c'
  | 'flair__End_Date__c'
  | 'flair__End_Time__c'
  | 'flair__End_Date_Half_Day__c'
  | 'flair__Category_Name__c'
  | 'flair__Type__c'
>;

type Props = {
  absences: Absence[];
  day: Moment;
  slotsPerDay: number;
};

type DailyAbsenceBlockProps = {
  kind: AbsenceType.Daily;
  categoryName: string;
  period: 'morning' | 'afternoon' | 'fullday';
};

type HourlyAbsenceBlockProps = {
  kind: AbsenceType.Hourly;
  categoryName: string;
  startTime: Date;
  endTime: Date;
};

type BlockProps = DailyAbsenceBlockProps | HourlyAbsenceBlockProps;

const Small = styled.small`
  font-size: 0.7rem;
`;

const BlockTime: React.FC<BlockProps> = (props) => {
  const { t } = useTranslation();
  switch (props.kind) {
    case AbsenceType.Daily:
      return (
        <Small>{t(`shiftPlanner.table.absencePeriod.${props.period}`)}</Small>
      );
    case AbsenceType.Hourly:
      return (
        <Small>
          <FormattedTime time={props.startTime} />
          {' – '}
          <FormattedTime time={props.endTime} />
        </Small>
      );
  }
};

const Block: React.FC<{ block: BlockProps; slotsPerDay: number }> = ({
  block,
  slotsPerDay,
}) => (
  <AbsenceContainer slotsPerDay={slotsPerDay}>
    <Row className="h-100 align-items-center">
      <Col>
        <Row>
          <Col>
            <span>{block.categoryName}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <BlockTime {...block} />
          </Col>
        </Row>
      </Col>
    </Row>
  </AbsenceContainer>
);

const AbsenceBlock: React.FC<Props> = ({ absences, day, slotsPerDay }) => (
  <>
    {absences.map((a, i) => (
      <Block
        key={i}
        block={buildBlockProps(a, day)}
        slotsPerDay={slotsPerDay}
      />
    ))}
  </>
);

export default AbsenceBlock;
