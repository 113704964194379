import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useFlairLocalStorage } from '../../hooks/useFlairLocalStorage';
import { routes } from '../../routes';

export type WorkflowViewMode = 'workflows' | 'workflowItems';
export type ToggleContext = 'forMe' | 'forOthers' | 'forAll';

export const useWorkflowsViewMode = () => {
  const history = useHistory();
  const [storeInfo, setStoreInfo] = useFlairLocalStorage(
    'workflows_view_mode',
    'workflows',
  );

  const redirect = useCallback(
    (context: ToggleContext, toState: WorkflowViewMode) => {
      setStoreInfo(toState);
      const route = getRouteToRedirect(context, toState);
      history.push(route.create({}));
    },
    [history, setStoreInfo],
  );

  const getLastRouteToRedirect = useCallback(
    (context: ToggleContext) => getRouteToRedirect(context, storeInfo),
    [storeInfo],
  );

  return { redirect, getLastRouteToRedirect };
};

const getRouteForWorkflows = (context: ToggleContext) => {
  switch (context) {
    case 'forMe':
      return routes.workflowsMeWorkflows.route;
    case 'forOthers':
      return routes.workflowsOthersWorkflows.route;
    case 'forAll':
      return routes.workflowsAllWorkflows.route;
  }
};

const getRouteForWorkflowItems = (context: ToggleContext) => {
  switch (context) {
    case 'forMe':
      return routes.workflowsMeWorkflowItemsAll.route;
    case 'forOthers':
      return routes.workflowsOthersWorkflowItemsAll.route;
    case 'forAll':
      return routes.workflowsAllWorkflowItemsAll.route;
  }
};

const getRouteToRedirect = (
  context: ToggleContext,
  toState: WorkflowViewMode,
) => {
  switch (toState) {
    case 'workflows':
      return getRouteForWorkflows(context);
    case 'workflowItems':
      return getRouteForWorkflowItems(context);
  }
};
