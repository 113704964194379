import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownActionItem } from '../../../../../../atomic/molecules/DropdownActionItem';
import { DeleteSpinner } from './DeleteSpinner';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';

type Props = {
  isProcessing?: boolean;
  onDelete: () => void;
};

const TimeEntryActions: React.FC<Props> = ({ isProcessing, onDelete }) => {
  const { t } = useTranslation();
  return isProcessing ? (
    <DeleteSpinner />
  ) : (
    <DropdownActions
      id={'delete-time-entry-action-' + Math.ceil(Math.random() * 1000)}>
      <DropdownActionItem onClick={() => onDelete()} size="sm">
        <FlairIcon icon="trash-outline" className="me-2" />
        <span>
          {t('timeTracking.controlling.dayCard.deleteTimeEntry.button')}
        </span>
      </DropdownActionItem>
    </DropdownActions>
  );
};

export default React.memo(TimeEntryActions);
