import { NotificationCategoryItem } from './types';
import { useUserInfo } from '../../../context/UserInfo';
import { useIsManagerMode } from '../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../__generated__/graphql';

export const useNotificationSettingsData = (): NotificationCategoryItem[][] => {
  const { isHiringManager } = useUserInfo();
  const { hasAnyOfManagerRole } = useIsManagerMode();
  const isDirectManager = hasAnyOfManagerRole([ManagerRole.DirectManager]);
  const isAbsenceManager = hasAnyOfManagerRole([
    ManagerRole.DirectManager,
    ManagerRole.AbsenceApprover,
  ]);
  const isTimeEntryManager = hasAnyOfManagerRole([
    ManagerRole.DirectManager,
    ManagerRole.TimeEntryApprover,
  ]);

  const notificationSettingsInfoByCategory: NotificationCategoryItem[] = [
    {
      category: 'absence',
      notifications: [
        {
          name: 'ApprovedAbsence',
        },
        {
          name: 'RejectedAbsence',
        },
        {
          name: 'NewAbsenceApprovalRequest',
          showCondition: isAbsenceManager,
        },
        {
          name: 'DeletedAbsence',
          showCondition: isAbsenceManager,
        },
        {
          name: 'NewAbsence',
          showCondition: isAbsenceManager,
        },
      ],
    },
    {
      category: 'openShift',
      notifications: [
        {
          name: 'OpenShiftRequest',
        },
        {
          name: 'OpenShiftRequestCreated',
          showCondition: isDirectManager,
        },
        {
          name: 'OpenShiftsPublished',
        },
        {
          name: 'EmployeeShiftsPublished',
        },
      ],
    },
    {
      category: 'timesheet',
      notifications: [
        { name: 'TimeEntryAdded' },
        {
          name: 'TimesheetOverview',
        },
        {
          name: 'TimeEntryDeleted',
        },
        {
          name: 'NewTimeEntryChangeRequest',
          showCondition: isTimeEntryManager,
        },
        {
          name: 'TimesheetPendingWeekly',
          showCondition: isTimeEntryManager,
        },
        {
          name: 'TimeEntryChangesApproved',
        },
        { name: 'TimeEntryChangesRejected' },
        { name: 'TimeSheetApproved' },
        { name: 'TimeBalanceWithdrawalCreated' },
        {
          name: 'TimeTrackingAutoClockOut',
        },
        {
          name: 'TimeTrackingAutoClockOutManager',
          showCondition: isTimeEntryManager,
        },
      ],
    },
    {
      category: 'recruitment',
      showCondition: isHiringManager,
      notifications: [
        {
          name: 'InformHiringManager',
        },
        {
          name: 'CandidateEvaluation',
        },
        {
          name: 'CandidateEvaluated',
        },
      ],
    },
    {
      category: 'performanceReview',
      notifications: [
        {
          name: 'PfReviewMeetingAttendee',
        },
        {
          name: 'PfReviewMeetingOrganizer',
        },
        {
          name: 'FC_EmployeeReviewSubmitted',
        },
        {
          name: 'FC_ReviewerReviewSubmitted',
        },
      ],
    },
    {
      category: 'general',
      notifications: [
        {
          name: 'EmployeeDataChanges',
          showCondition: false,
        },
        {
          name: 'EmployeeFeedbackNotification',
        },
        {
          name: 'EmployeeUpload',
          showCondition: isDirectManager,
        },
        {
          name: 'NewEmployeeDocument',
        },
      ],
    },
    {
      category: 'ticket',
      notifications: [
        {
          name: 'TicketSubmitted',
        },
        {
          name: 'TicketShared',
        },
        {
          name: 'TicketStatusChanged',
        },
        {
          name: 'TicketClosed',
        },
      ],
    },
    {
      category: 'cheer',
      notifications: [
        {
          name: 'CelebrationReceived',
        },
      ],
    },
    {
      category: 'workflows',
      notifications: [
        {
          name: 'PendingWorkflowItems',
        },
        {
          name: 'ExpiringWorkflowItems',
        },
      ],
    },
    {
      category: 'comments',
      notifications: [
        {
          name: 'CommentCreated',
        },
      ],
    },
  ];

  return [notificationSettingsInfoByCategory];
};
