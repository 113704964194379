import * as React from 'react';
import './OnboardingWidget.css';
import Button from '../../../../../components/button';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { routes } from '../../../routes';
import { useWorkflowDashboardInfo } from '../../Workflows/Onboarding/useWorkflowDashboardInfo';
import { useHistory } from 'react-router-dom';
import { FlairLabel } from '../../../../../atomic/atoms/Flairlabel/FlairLabel';
import FlairCard from '../../../../../atomic/templates/FlairCard/FlairCard';

const i18Path = 'dashboard.onboardingWidget';

const OnboardingWidget: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { banner } = useWorkflowDashboardInfo();
  const history = useHistory();

  if (!banner) {
    return null;
  }

  const handleClick = () => {
    history.push(
      routes.workflowsMeItems.route
        .withQueryParams({ onlyMyTasks: 'true' })
        .create({
          workflowId: banner.onboardingWorkflowId,
        }),
    );
  };

  return (
    <FlairCard
      cardContainerClassName="onboarding-widget"
      contentClassName="pt-0"
      cardTitle={t('title')}>
      <FlairLabel
        text={t('helperText')}
        className="h4-regular d-block onboarding-helper-text"
      />
      <Button
        variant="warning"
        label={t('startOnboarding')}
        onClick={handleClick}
        className="onboarding-btn w-100 mb-3 h7-medium p-2-5"
      />
    </FlairCard>
  );
};

export default OnboardingWidget;
