import styled from '@emotion/styled';
import React from 'react';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';
import { GoalLabel } from './GoalLabel';
import { FlairIconName } from '../../../../../../../atomic/atoms/FlairIcon';

type Props = {
  icon: FlairIconName;
  label: string;
  tooltip: OverlayChildren;
  className?: string;
};

export const GoalLabelWithTooltip: React.FC<Props> = ({
  tooltip,
  icon,
  label,
  className,
}) => {
  return (
    <OverlayTrigger overlay={tooltip} placement="right">
      <StyledButton variant="label" className={className}>
        <GoalLabel icon={icon} label={label} noWrap={true} />
      </StyledButton>
    </OverlayTrigger>
  );
};

const StyledButton = styled(Button)({
  alignItems: 'center',
  color: '#95AAC9',
  fontSize: 12,
  padding: 0,
});
