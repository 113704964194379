import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Theme } from '../../../../../../theme';
import ErrorTooltip from './ErrorTooltip';

const StyledFormControl = styled(Form.Control, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{ isHighlighted?: boolean }>(({ isHighlighted }) => ({
  borderColor: isHighlighted ? Theme.input.border.focusColor : undefined,
}));

type Props = {
  name: string;
  value?: string;
  readonly: boolean;
  isHighlighted?: boolean;
  error: string | null;
  onChange: (value: string) => void;
  ariaLabelledBy?: string;
};

const TimeInput: React.FC<Props> = ({
  name,
  value,
  readonly,
  error,
  isHighlighted,
  onChange,
  ariaLabelledBy,
}) => {
  const target = useRef(null);

  return (
    <>
      <ErrorTooltip target={target.current} error={error} />
      <StyledFormControl
        className="form-control-sm"
        type="time"
        name={name}
        readOnly={readonly}
        ref={target}
        title={error ?? undefined}
        isInvalid={!!error}
        onChange={(e) => onChange(e.currentTarget.value)}
        value={value}
        isHighlighted={isHighlighted}
        aria-labelledby={ariaLabelledBy}
      />
    </>
  );
};

export default TimeInput;
