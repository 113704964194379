import React from 'react';

import AbsenceCategoriesFilter from './AbsenceCategoriesFilter';
import ServerError from '../../../../../../../components/ServerError';
import OfficeFilter from './OfficeFilter';
import {
  ManagerRole,
  useTeamCalendarFiltersQuery,
} from '../../../../../__generated__/graphql';
import TeamFilter from './TeamFilter';
import AbsentStatusFilter from './AbsentStatusFilter';
import { TeamCalendarFiltersType } from './types';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import DepartmentFilter from './DepartmentFilter';
import { FilterSelectOption } from '../../../../../../../atomic/molecules/FlairDropdown/types';
import ClearFilters from '../../../../../components/ClearFilters';
import { routes } from '../../../../../routes';
import { useQueryParams } from '../../../../../../../hooks/useQueryParams';
import { getUniqueGroupsByCategories } from '../../../GroupCategories';
import { useIsManagerMode } from '../../../../../hooks/useIsManagerMode';

type Props = {
  filterData: TeamCalendarFiltersType;
};

const FilterItem: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => {
  return <div className={`${className}`}>{children}</div>;
};

const TeamCalendarFilters: React.FC<Props> = ({ filterData }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useTeamCalendarFiltersQuery();
  const { hasAnyOfManagerRole } = useIsManagerMode();
  const queryParams = useQueryParams();
  const route = routes.teamCalendar.route
    .withQueryParams({
      startDate: queryParams['startDate'] || '',
    })
    .create({});

  if (loading) return <Loading />;

  if (error || data === undefined) return <ServerError />;

  let absenceCategoriesOptions = data.absencesCategories
    .map((a) => ({
      label: a.Name,
      value: a.Id,
      public: a.flair__Public__c,
      archived: a.flair__Archived_Date__c !== null,
    }))
    .filter((category) => category.public && !category.archived);

  const categoriesGroups = getUniqueGroupsByCategories(
    data.absencesCategories,
  ).map((g) => ({
    label: t('categoriesGroup.groupName', { group_name: g.name }),
    value: g.id,
    public: true,
  }));

  let absenceCategoriesWithGroupsOptions = [
    ...categoriesGroups,
    ...absenceCategoriesOptions,
  ];

  if (data.absencesCategories.find((category) => !category.flair__Public__c))
    absenceCategoriesWithGroupsOptions = [
      ...absenceCategoriesWithGroupsOptions,
      {
        label: t('employeePage.teamCalendar.filters.absenceCategories.Absent'),
        value: 'ABSENT',
        public: false,
      },
    ];

  const officeOptions: FilterSelectOption[] = data.locations.map((a) => ({
    label: a.Name,
    value: a.Id,
  }));
  const teams: FilterSelectOption[] = data.teams.map((a) => ({
    label: a.Name,
    value: a.Id,
  }));
  const departmentOptions: FilterSelectOption[] = data.departments.map((a) => ({
    label: a.Name,
    value: a.Id,
  }));

  const teamFilterOptions: FilterSelectOption[] = [
    {
      label: t('employeePage.teamCalendar.filters.team.options.my_team'),
      value: 'my_team',
    },
    {
      label: t('employeePage.teamCalendar.filters.team.options.all_teams'),
      value: 'all_teams',
    },
    {
      label: t('employeePage.teamCalendar.filters.team.options.reports'),
      value: 'subordinates',
      showCondition: hasAnyOfManagerRole([ManagerRole.DirectManager]),
    },
    ...teams,
  ];

  const absentStatusFilterOptions: FilterSelectOption[] = [
    {
      label: t(
        'employeePage.teamCalendar.filters.absentStatus.options.absent_today',
      ),
      value: 'absent_today',
    },
    {
      label: t(
        'employeePage.teamCalendar.filters.absentStatus.options.absent_this_month',
      ),
      value: 'absent_this_month',
    },
  ];

  return (
    <div className="d-none d-md-flex align-items-center flex-wrap gap-3">
      <span className="text-nowrap d-none d-lg-block">
        {t('employeePage.teamCalendar.filterBy')}
      </span>
      <FilterItem>
        <OfficeFilter filterData={filterData} options={officeOptions} />
      </FilterItem>
      <FilterItem>
        <DepartmentFilter filterData={filterData} options={departmentOptions} />
      </FilterItem>
      <FilterItem>
        <TeamFilter filterData={filterData} options={teamFilterOptions} />
      </FilterItem>
      <FilterItem>
        <AbsenceCategoriesFilter
          filterData={filterData}
          options={absenceCategoriesWithGroupsOptions}
        />
      </FilterItem>
      <FilterItem>
        <AbsentStatusFilter
          filterData={filterData}
          options={absentStatusFilterOptions}
        />
      </FilterItem>
      <FilterItem>
        <ClearFilters route={route} />
      </FilterItem>
    </div>
  );
};

export default TeamCalendarFilters;
