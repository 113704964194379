import React from 'react';
import NotificationContentItem from '../NotificationContentItem';
import ServerError from '../../../../../components/ServerError';
import styled from '@emotion/styled';
import { NotificationItem, NotificationsContentType } from '../types';
import NotificationsContentLoadingSkeleton from './NotificationsContentLoadingSkeleton';
import NotificationsContentConnectedEmptyState from './NotificationsContentConnectedEmptyState';

const ContentContainer = styled.div({
  margin: '0.75rem',
  overflow: 'auto',
  maxHeight: '37rem',
  marginLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const NotificationsContentConnectedItems: React.FC<{
  notifications: NotificationItem[] | undefined;
  error: boolean;
  loading: boolean;
  loadingMore: JSX.Element;
  type: NotificationsContentType;
}> = ({ notifications, error, loading, loadingMore, type }) => {
  const renderContent = () => {
    if (error) return <ServerError />;
    if (loading) return <NotificationsContentLoadingSkeleton />;
    if (!notifications?.length)
      return <NotificationsContentConnectedEmptyState type={type} />;
  };

  return (
    <ContentContainer>
      {renderContent()}
      {notifications && notifications.length > 0 && (
        <>
          {notifications.map((notification, i) => (
            <NotificationContentItem
              key={i}
              type={type}
              notification={notification}
            />
          ))}
        </>
      )}
      {loadingMore}
    </ContentContainer>
  );
};

export default NotificationsContentConnectedItems;
