import React from 'react';
import { ProjectsTimeSheetEmployee } from '../types';
import { EmployeeWithAvatar } from '../../../../components/Employee';

type Props = {
  employee: ProjectsTimeSheetEmployee;
};

export const EmployeeCell: React.FC<Props> = ({ employee }) => {
  return (
    <EmployeeWithAvatar
      employee={{
        name: employee.name,
        avatarUrl: employee.avatarUrl,
      }}></EmployeeWithAvatar>
  );
};
