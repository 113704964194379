import React from 'react';
import { formatPeriodInterval, formatPeriodName } from '../periodFormatter';
import { TimeSheetPeriodInfo } from '../types';

type Props = {
  period: TimeSheetPeriodInfo;
};

export const PeriodCell: React.FC<Props> = ({ period }) => {
  return (
    <div>
      <span className="text-nowrap me-2 color-gray-1 h7-medium">
        {formatPeriodName(period)}
      </span>
      <span className="text-nowrap color-paper-1 h7-regular">
        {formatPeriodInterval(period)}
      </span>
    </div>
  );
};
