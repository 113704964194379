import { useTicketSidebarQuery } from '../../../../__generated__/graphql';
import { getCurrentLanguage } from '../../../../../../locales/locale';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../../../routes';
import { useState } from 'react';
import { sidebarTabKeys as tabKeys } from '../../common';
import { useSelectedItemWithComments } from '../../../../components/Comment';

const ID_PARAM = 'id';

export const createTicketQueryParams = (ticketId: string) => ({
  [ID_PARAM]: ticketId,
});

export const useTicketSidebar = (isManager: boolean) => {
  const locale = getCurrentLanguage();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ticketId = queryParams.get(ID_PARAM);
  const isSidebarVisible = !!ticketId;

  const { defaultSelectedItem } = useSelectedItemWithComments();

  const showComments = defaultSelectedItem?.showComments;

  const [sidebarTab, setSidebarTab] = useState<string | null>(
    showComments ? tabKeys.COMMENTS : tabKeys.INFORMATION,
  );
  const { data, loading, error } = useTicketSidebarQuery({
    variables: { id: ticketId!, lang: locale },
    skip: !ticketId,
  });

  const handleSidebarClose = () => {
    handleClearQueryParams();
    setSidebarTab(tabKeys.INFORMATION);
  };

  const handleClearQueryParams = () => {
    if (isManager) {
      history.push(routes.managerTickets.route.create({}));
    } else {
      history.push(routes.tickets.route.create({}));
    }
  };

  const handleSetQueryParams = (id: string) => {
    const queryParams = { id: id };
    if (isManager) {
      history.push(
        routes.managerTickets.route.withQueryParams(queryParams).create({}),
      );
    } else {
      history.push(
        routes.tickets.route.withQueryParams(queryParams).create({}),
      );
    }
  };

  const handleSidebarOpen = (id: string) => {
    handleSetQueryParams(id);
  };

  return {
    isSidebarVisible,
    handleSidebarClose,
    handleSidebarOpen,
    selectedRecordId: ticketId,
    sidebarResponse: data,
    isSidebarLoading: loading,
    sidebarError: error,
    setSidebarTab,
    sidebarTab,
  };
};
