import styled from '@emotion/styled';
import * as React from 'react';
import { useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Controller, FormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../../components/button';
import { FormGroup } from '../../../../../../../components/form/FormGroup';
import { Input } from '../../../../../../../components/form/Input';
import { SelectDropdownControlled } from '../../../../../../../components/form/Selects/SelectDropdownControlled';
import SubmitButton from '../../../../../../../components/form/SubmitButton';
import { SelectDropdown } from '../../../../../../../components/Select/SelectDropdown';
import { GoalKeyResult } from '../types';
import {
  CreateTimeEntryResultData,
  useCreateKeyResultForm,
} from './useCreateKeyResultForm';

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: (keyResult: GoalKeyResult) => void;
};

const i18prefix = 'performanceReview.goals2.keyResults';

export const CreateKeyResultModal: React.FC<Props> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    form,
    startValueField,
    targetValueField,
    metricValueField,
    titleField,
  } = useCreateKeyResultForm();

  // this is crazy, but IDK how to stop submiting the right side form
  const handleSubmit = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      form.handleSubmit((src) => {
        onSubmit(mapKeyResult(src));
      })();
    },
    [form, onSubmit],
  );

  const { watch, control } = form;
  const { metricValue, startValue } = watch();

  const metricOptions = [
    { label: t(`${i18prefix}.types.percentage`), value: 'PERCENTAGE' },
    { label: t(`${i18prefix}.types.numeric`), value: 'NUMERIC' },
    { label: t(`${i18prefix}.types.boolean`), value: 'BOOLEAN' },
  ];

  const isNumericOrPercentage = () => {
    return ['PERCENTAGE', 'NUMERIC'].includes(metricValue);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{t(`${i18prefix}.addKeyResult`)}</h4>
      </Modal.Header>
      <FormContext {...form}>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div>
              <Input
                name={titleField}
                autoFocus={true}
                label={t(`${i18prefix}.title`)}
              />
            </div>
            <ValuesContainer>
              <FormGroup>
                <Form.Label>{t(`${i18prefix}.metric`)}</Form.Label>
                <SelectDropdownControlled
                  name={metricValueField}
                  options={metricOptions}
                  heightMode="large"
                />
              </FormGroup>
              {isNumericOrPercentage() && (
                <>
                  <Input
                    name={startValueField}
                    label={t(`${i18prefix}.start`)}
                  />
                  <Input
                    name={targetValueField}
                    label={t(`${i18prefix}.target`)}
                  />
                </>
              )}
              {metricValue === 'BOOLEAN' && (
                <>
                  <FormGroup>
                    <Form.Label>{t(`${i18prefix}.start`)}</Form.Label>
                    <Controller
                      as={({ value, onChange }) => {
                        return (
                          <SelectDropdown
                            options={[
                              { value: '0', label: t(`${i18prefix}.no`) },
                              { value: '100', label: t(`${i18prefix}.yes`) },
                            ]}
                            value={value.toString()}
                            onChange={(val) => {
                              onChange(val ? parseInt(val) : 0);
                            }}
                          />
                        );
                      }}
                      name={startValueField}
                      control={control}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label>{t(`${i18prefix}.target`)}</Form.Label>
                    <p className="form-control-plaintext">
                      {startValue === 0 ? 'Yes' : 'No'}
                    </p>
                  </FormGroup>
                </>
              )}
            </ValuesContainer>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={onClose}
              label={t(`form.general.cancelButton`)}
            />

            <SubmitButton>{t('form.general.saveButton')}</SubmitButton>
          </Modal.Footer>
        </form>
      </FormContext>
    </Modal>
  );
};

const ValuesContainer = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 2fr;
  gap: 10px;
`;

const mapKeyResult = (src: CreateTimeEntryResultData): GoalKeyResult => {
  switch (src.metricValue) {
    case 'NUMERIC':
    case 'PERCENTAGE':
    case 'BOOLEAN':
      return {
        name: src.title,
        type: src.metricValue,
        initialValue: src.startValue ?? 0,
        currentValue: src.startValue ?? 0,
        targetValue: src.targetValue ?? 100,
      };
  }
};
