import * as React from 'react';

const TildeOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="10"
      height="4"
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M6.92692 3.25148C6.41847 3.25148 5.98466 3.18151 5.63015 3.04625C5.27563 2.91099 4.94911 2.76174 4.65057 2.59849C4.35203 2.43524 4.06748 2.28599 3.79227 2.15073C3.51705 2.01546 3.20919 1.9455 2.85934 1.9455C2.48616 1.9455 2.16897 2.02479 1.90775 2.17871C1.79579 2.24401 1.69317 2.31397 1.59055 2.38394C1.36198 2.54718 1.04478 2.49588 0.867527 2.27666C0.676276 2.03412 0.722922 1.67964 0.974814 1.5024C1.1614 1.3718 1.36665 1.24121 1.59055 1.11527C2.01503 0.872734 2.47217 0.751465 2.97129 0.751465C3.39577 0.751465 3.76428 0.821428 4.08148 0.95669C4.39868 1.09195 4.70188 1.24121 4.99575 1.39512C5.28963 1.54904 5.59283 1.6983 5.91003 1.83356C6.22722 1.96882 6.60506 2.03878 7.03887 2.03878C7.33741 2.03878 7.64061 1.96882 7.94382 1.82423C8.10242 1.7496 8.26102 1.66098 8.41495 1.56304C8.64818 1.41378 8.96071 1.46975 9.12864 1.69363L9.14264 1.70763C9.31989 1.9455 9.27325 2.28132 9.03535 2.45856C8.80212 2.63114 8.54556 2.78506 8.27034 2.92032C7.82254 3.1442 7.37473 3.25148 6.92692 3.25148Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default TildeOutlineIcon;
