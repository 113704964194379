import * as React from 'react';

const EyeOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.98524 1.23657C5.43434 1.23657 2.81766 2.72944 0.757693 5.71519C0.698894 5.80135 0.666672 5.9032 0.6651 6.00788C0.663527 6.11255 0.692673 6.21534 0.748857 6.30327C2.33163 8.80849 4.91329 10.7677 7.98524 10.7677C11.0238 10.7677 13.6585 8.80253 15.2442 6.29169C15.2991 6.20548 15.3282 6.10513 15.3282 6.00261C15.3282 5.90009 15.2991 5.79974 15.2442 5.71354C13.6549 3.23148 11.0009 1.23657 7.98524 1.23657Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99723 8.64978C9.44328 8.64978 10.6155 7.46445 10.6155 6.00226C10.6155 4.54007 9.44328 3.35474 7.99723 3.35474C6.55117 3.35474 5.37891 4.54007 5.37891 6.00226C5.37891 7.46445 6.55117 8.64978 7.99723 8.64978Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default EyeOutlineIcon;
