import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useComparedFeedbackSectionsMapper } from '../../hooks/useComparedFeedbackSectionsMapper';
import FeedbackStatusSection from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeePerformanceReviewMeeting/FeedbackStatusSection';
import { GoalsSection } from '../../pages/PerformanceReview/EmployeePerformanceReview/Goals2/GoalsSection';
import {
  EmployeeFeedbackAnswer,
  EmployeeFeedbackResolution,
  FeedbackCycle,
  FeedbackQuestion,
  MyPastPerformanceReviewDetailsQuery,
  PastEmployeeFeedbackDetailsQuery,
} from '../../__generated__/graphql';
import { EmployeeFeedbackDetails } from '../EmployeeFeedbackDetails';
import PageHeader from '../PageHeader';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import PeerReviews from '../../pages/PerformanceReview/EmployeePerformanceReview/EmployeeEvaluation/PeerReviews';
import { usePeerReviewsHook } from '../../pages/PerformanceReview/EmployeePerformanceReview/hooks/usePeerReviewsHook';
import { EmployeeSatisfactionModal } from '../../pages/PerformanceReview/SelfPerformanceEvaluation/EmployeeSatisfactionModal';
import Button from '../../../../components/button';
import { Nullable } from '../../../../utils/nullable';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';

type FeedbackCycleProps = Pick<
  FeedbackCycle,
  'Id' | 'Name' | 'flair__Signature_required__c' | 'flair__Without_Reviewer__c'
>;
type FeedbackQuestionProps = Pick<
  FeedbackQuestion,
  | 'Id'
  | 'flair__Employee_Feedback_Question_Section__c'
  | 'flair__Section_Name__c'
  | 'flair__Question__c'
  | 'flair__Description__c'
  | 'flair__Choices__c'
  | 'flair__Feedback_Question_Type__c'
  | 'flair__Optional__c'
  | 'flair__Optional_Comment__c'
  | 'flair__Position__c'
  | 'flair__Required_Choice__c'
  | 'flair__Hint__c'
  | 'flair__Answer_Visibility__c'
  | 'flair__Feedback_Question_Visibility__c'
  | 'flair__Is_Satisfaction_Section__c'
>;
type EmployeeFeedbackResolutionProps = Pick<
  EmployeeFeedbackResolution,
  'Id' | 'flair__Employee_Feedback_Question__c' | 'flair__Resolution__c'
>;
type EmployeeFeedbackAnswerProps = Pick<
  EmployeeFeedbackAnswer,
  | 'Id'
  | 'flair__Employee_Feedback_Question__c'
  | 'feedbackAnswer'
  | 'flair__Note__c'
>;

type Props = {
  feedbackCycle: FeedbackCycleProps;
  employeeName: string;
  employeeId: string;
  managerId: Nullable<string>;
  managerName: Nullable<string>;
  employeeAnswers: ReadonlyArray<EmployeeFeedbackAnswerProps>;
  managerAnswers: ReadonlyArray<EmployeeFeedbackAnswerProps>;
  questions: ReadonlyArray<FeedbackQuestionProps>;
  resolutions: ReadonlyArray<EmployeeFeedbackResolutionProps>;
  manageGoals: boolean;
  pageHeaderActions?: React.ReactNode;
  canSign: boolean;
  isFinishing: boolean;
  onFinish: () => Promise<void>;
  employeeFeedback:
    | PastEmployeeFeedbackDetailsQuery['employeeFeedback']
    | MyPastPerformanceReviewDetailsQuery['me']['feedback'];
};

const i18Path = 'employeePage.performanceReview.meeting';
export const PastPerformanceReviewDetails: React.FC<Props> = ({
  feedbackCycle,
  employeeName,
  employeeId,
  managerId,
  managerName,
  employeeAnswers,
  managerAnswers,
  questions,
  resolutions,
  manageGoals,
  pageHeaderActions,
  canSign,
  isFinishing,
  onFinish,
  employeeFeedback,
}) => {
  const t = useNamespacedTranslation(i18Path);
  const { t: rootT } = useTranslation();

  const isSignatureRequired = feedbackCycle.flair__Signature_required__c;
  const withReviewer = !feedbackCycle.flair__Without_Reviewer__c;
  const signAndCompleteBtnLbl = t(
    isSignatureRequired ? 'signComplete' : 'complete',
  );

  const { isPeerReviewsEnabled, peerFeedbacks } =
    usePeerReviewsHook(employeeFeedback);
  const [showSatisfactionModal, setShowSatisfactionModal] =
    useState<boolean>(false);

  const isAccepted = !!employeeFeedback.outcome.flair__Accepted_At__c;

  const mapSections = useComparedFeedbackSectionsMapper();
  const sections = mapSections({
    questions: questions.filter((q) => !q.flair__Is_Satisfaction_Section__c),
    employeeAnswers,
    managerAnswers,
    resolutions,
  });

  const satisfactionQuestion = questions.find(
    (question) => question.flair__Is_Satisfaction_Section__c,
  );
  const satisfactionAns = employeeAnswers.find(
    (answer) =>
      answer.flair__Employee_Feedback_Question__c === satisfactionQuestion?.Id,
  );

  useFlairBreadcrumbHook([
    { label: rootT('navigation.menuItems.personalDevelopment.title') },
    {
      label: rootT('performanceReview.subMenuItems.reviews.title'),
      url: routes.performanceReview.route.create({}),
    },
    { label: feedbackCycle.Name },
  ]);

  const handleCompleteFeedbackCycle = () => {
    if (
      satisfactionQuestion &&
      satisfactionAns &&
      feedbackCycle.flair__Signature_required__c
    ) {
      setShowSatisfactionModal(true);
    } else {
      onFinish();
    }
  };

  return (
    <>
      <PageHeader
        title={feedbackCycle.Name}
        subtitle={employeeName}
        actions={pageHeaderActions}
      />
      {satisfactionQuestion && satisfactionAns && !isAccepted && (
        <EmployeeSatisfactionModal
          onSubmit={onFinish}
          answer={{
            ...satisfactionAns,
            flair__Feedback__c: satisfactionAns.feedbackAnswer,
            flair__Employee_Feedback_Request__c: employeeFeedback.Id,
            question: satisfactionQuestion,
          }}
          isSignStep={true}
          visible={showSatisfactionModal}
          onClose={() => setShowSatisfactionModal(false)}
          isLoading={isFinishing}
        />
      )}

      <Row>
        <Col xl={8}>
          <EmployeeFeedbackDetails
            employeeId={employeeId}
            employeeName={employeeName}
            managerId={managerId}
            managerName={managerName}
            sections={sections}
          />
        </Col>
        <Col xl={4} style={{ position: 'sticky', top: 0, height: '75vh' }}>
          {manageGoals && (
            <GoalsSection employeeId={employeeId} employeeName={employeeName} />
          )}
          <FeedbackStatusSection
            ended={
              withReviewer
                ? employeeFeedback.outcome.flair__Completed__c
                : !!employeeFeedback.flair__Submitted_At__c
            }
            readonly={false}
            signatureRequired={isSignatureRequired}
          />

          {isPeerReviewsEnabled && (
            <PeerReviews
              maxPeers={
                employeeFeedback.feedbackCycle.flair__Number_of_Peers__c
              }
              outcomeId={employeeFeedback.outcome.Id}
              peerFeedbacks={peerFeedbacks}
              currentFeedbackRequestId={employeeFeedback.Id}
              revieweeId={employeeFeedback.reviewee.Id}
              isEditingEnabled={false}
            />
          )}

          {canSign && (
            <Row className="submit-button">
              <Col className="text-end">
                <Button
                  className="w-100 mb-3"
                  label={signAndCompleteBtnLbl}
                  isProcessing={isFinishing}
                  onClick={handleCompleteFeedbackCycle}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};
