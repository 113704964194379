import styled from '@emotion/styled';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Theme } from '../../../../../theme';
import { DaysRowProps } from './types';

type DayNumberTDProps = {
  isCurrent?: boolean;
  isHovered?: boolean;
};

const DayNumberTD = styled.td<DayNumberTDProps>(
  {},
  ({ isCurrent, isHovered }) => {
    return {
      position: 'relative',
      color: isHovered ? Theme.color.primary : Theme.color.black,
      fontSize: `${Theme.font.size.base} !important`,
      '::after': isCurrent
        ? {
            content: "''",
            position: 'absolute',
            borderBottom: `3px solid ${Theme.color.primary}`,
            bottom: 0,
            left: 0,
            right: 0,
          }
        : undefined,
    };
  },
);

type DayProps = {
  day: number;
  isHovered?: boolean;
  isCurrent?: boolean;
};

const Day: React.FC<DayProps> = ({ day, isHovered, isCurrent }) => {
  return useMemo(
    () => (
      <DayNumberTD isHovered={isHovered} isCurrent={isCurrent}>
        {day}
      </DayNumberTD>
    ),
    [day, isCurrent, isHovered],
  );
};

const DaysRow: React.FC<DaysRowProps> = ({ hoveredDay }) => {
  const currentDay = moment().date();

  return (
    <tr style={{ height: '60px' }}>
      <th />
      {_.range(1, 32).map((day) => (
        <Day
          key={day}
          day={day}
          isCurrent={currentDay === day}
          isHovered={!!(hoveredDay && hoveredDay.day.date() === day)}
        />
      ))}
    </tr>
  );
};
export default DaysRow;
