import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RedirectToHome from '../components/RedirectToHome';
import { routes } from './PageHeader/routes';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ component, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        component={component}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

const ManagerDashboard: React.FC = () => <Content />;

export default ManagerDashboard;
