import {
  AbsenceType,
  RequestedAbsenceFragment,
} from '../../__generated__/graphql';

export const mapAbsenceToDateTime = (absence?: RequestedAbsenceFragment) => ({
  type: absence?.flair__Type__c || AbsenceType.Unknown,
  startTime: absence?.flair__Start_Time__c || '',
  startDate: absence?.flair__Start_Date__c || '',
  startDateHalfDay: absence?.flair__Start_Date_Half_Day__c || false,
  endTime: absence?.flair__End_Time__c || '',
  endDate: absence?.flair__End_Date__c || '',
  endDateHalfDay: absence?.flair__End_Date_Half_Day__c || false,
});
