import React from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {
  height?: number;
};

const SkeletonGraph: React.FC<Props> = ({ height = 200 }) => (
  <Skeleton height={height} />
);

export default SkeletonGraph;
