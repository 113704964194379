import React, { useState } from 'react';
import { Card, CloseButton, Col, Row } from 'react-bootstrap';
import { Nullable } from '../../../../../../utils/nullable';
import { SurveySubmitModal } from '../../../../components/SurveySubmitModal/SurveySubmitModal';
import { EmojiOption, EmojisSelect } from './EmojisSelect';

export type SmileySurveyData = {
  score: Nullable<number>;
  comment: Nullable<string>;
};

type Props = {
  questionText: string;
  commentPromptText: string;
  allowComments: boolean;
  allowDismiss: boolean;
  headerLeft?: string;
  headerRight?: string;
  handleDismiss: (callback?: () => void) => void;
  handleSubmit: (data: SmileySurveyData) => void;
  submitLoading: boolean;
};

export const SmileySurveyUi: React.FC<Props> = ({
  allowDismiss,
  headerLeft,
  headerRight,
  handleSubmit,
  handleDismiss,
  questionText,
  commentPromptText,
  allowComments,
  submitLoading,
}) => {
  const [selectedEmoji, setSelectedEmoji] =
    useState<Nullable<EmojiOption>>(null);
  const [comment, setComment] = useState<Nullable<string>>(null);
  const showModal = selectedEmoji ? true : false;

  const submitData = () => {
    const dataToSubmit: SmileySurveyData = {
      score: selectedEmoji?.value ?? null,
      comment: comment,
    };
    handleSubmit(dataToSubmit);
  };
  return (
    <Card className="rounded-4">
      <Card.Header>
        <Row className="justify-content-center align-items-center">
          <Col className="fw-bold">{headerLeft}</Col>
          <Col className="d-flex justify-content-end">
            <div className="me-2">{headerRight}</div>
            {allowDismiss && (
              <div>
                <CloseButton onClick={() => handleDismiss()} />
              </div>
            )}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <div className="w-75 mx-auto">
            <EmojisSelect
              label={questionText}
              onChange={(emojiItem) => setSelectedEmoji(emojiItem)}
            />
            {selectedEmoji && (
              <SurveySubmitModal
                onSubmit={submitData}
                onClose={() => setSelectedEmoji(null)}
                setComment={setComment}
                visible={showModal}
                submitInProgress={submitLoading}
                commentPromptText={commentPromptText.replace(
                  '{{reaction}}',
                  selectedEmoji.label,
                )}
                allowComment={allowComments}
              />
            )}
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};
