import { differenceInCalendarDays, format } from 'date-fns';
import { FlairDateFormat } from '../../atoms/FlairFormattedDate/types';

export const getRelativeDateText = (
  endDate: Date,
  t: Function,
  displayFormat?: FlairDateFormat,
): string | null => {
  const today = new Date();
  const daysDiff = differenceInCalendarDays(endDate, today);

  if (daysDiff < 0) {
    return null;
  }

  if (daysDiff === 0) {
    return t('unites.today');
  }

  if (daysDiff === 1) {
    return t('unites.tomorrow');
  }

  if (daysDiff <= 7 || !displayFormat) {
    return t('unites.day', { count: daysDiff });
  }

  return format(endDate, t(displayFormat));
};
