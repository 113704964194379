import { NotificationItemData, NotificationQueryItem } from '../../types';
import { routes } from '../../../../routes';

import { t } from 'i18next';

const i18prefix = 'notifications.tickets.manager';
export const mapTicketManagerStatusChangedNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };

  if (
    src.renderInfo.__typename ===
    'TicketManagerStatusChangedNotificationRenderInfo'
  ) {
    if (!src.renderInfo.ticket?.Id) {
      return null;
    }
    data.description = t(`${i18prefix}.statusChanged`, {
      employee: src.renderInfo.ticket?.employee?.Name,
    });
    data.icon = 'help-desk-outline';
    const requestId = src.renderInfo.ticket?.Id;
    if (requestId) {
      data.url = routes.tickets.route.create({
        id: requestId,
      });
    }

    const ticket = src.renderInfo.ticket;
    const subject = ticket?.subject;
    if (subject && subject.label) {
      data.urlLabel = subject.label;
    }
  }
  return data;
};
