import { TrackedTime, TrackedTimeDay } from '../../TrackedTime';
import { format } from 'date-fns';
import { minutesToHours } from '../../../../../utils/time';
import { parseDate } from '../../../../../utils/dateUtils';
import i18next from '../../../../../initializers/i18next';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';

type BarData = {
  regular: number[];
  overtime: number[];
  deficit: number[];
  absence: number[];
  holiday: number[];
};

type TrackedDaysByWeekday = {
  [key: string]: TrackedTimeDay | undefined;
};

export const buildBarData = (tracked: TrackedTime, weekdays: Date[]) => {
  const formatDateStr = i18next.t(`date.${FlairDateFormat.NumericDateHyphen}`);
  const trackedDaysByWeekday = tracked.days.reduce<TrackedDaysByWeekday>(
    (result, day) => ({
      ...result,
      [format(parseDate(day.day), formatDateStr)]: day,
    }),
    {},
  );

  return weekdays.reduce<BarData>(
    (result, wd) => {
      const trackedDay = trackedDaysByWeekday[format(wd, formatDateStr)];
      return {
        absence: [
          ...result.absence,
          minutesToHours(trackedDay?.absenceMinutes ?? 0),
        ],
        holiday: [
          ...result.holiday,
          minutesToHours(trackedDay?.holidayMinutes ?? 0),
        ],
        regular: [
          ...result.regular,
          trackedDay ? getRegularMinutes(trackedDay) : 0,
        ],
        deficit: [
          ...result.deficit,
          minutesToHours(trackedDay?.deficitMinutes ?? 0),
        ],
        overtime: [
          ...result.overtime,
          minutesToHours(trackedDay?.overtimeMinutes ?? 0),
        ],
      };
    },
    { regular: [], absence: [], holiday: [], deficit: [], overtime: [] },
  );
};

function getRegularMinutes(trackedDay: TrackedTimeDay) {
  return Math.max(
    0,
    minutesToHours(trackedDay.workedMinutes - trackedDay.overtimeMinutes),
  );
}
