import * as React from 'react';

const InformationCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.74864 1.99976C4.57408 1.99976 2 4.57384 2 7.7484C2 10.923 4.57408 13.497 7.74864 13.497C10.9232 13.497 13.4973 10.923 13.4973 7.7484C13.4973 4.57384 10.9232 1.99976 7.74864 1.99976Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
      <path
        d="M6.87305 6.87378H7.87281V10.4979"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49805 10.623H9.2474"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.74972 4.0625C7.5887 4.0625 7.43128 4.11025 7.29739 4.19971C7.1635 4.28918 7.05915 4.41633 6.99752 4.56511C6.9359 4.71388 6.91978 4.87758 6.95119 5.03551C6.98261 5.19345 7.06015 5.33852 7.17401 5.45239C7.28788 5.56625 7.43295 5.64379 7.59089 5.67521C7.74882 5.70662 7.91252 5.6905 8.0613 5.62888C8.21007 5.56726 8.33723 5.4629 8.42669 5.32901C8.51615 5.19512 8.5639 5.03771 8.5639 4.87668C8.5639 4.66074 8.47812 4.45365 8.32543 4.30097C8.17275 4.14828 7.96566 4.0625 7.74972 4.0625Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default InformationCircleOutlineIcon;
