import React from 'react';
import { times } from 'lodash';
import SkeletonCard from '../../components/Skeleton/Card';
import { Card, Container } from 'react-bootstrap';
import SkeletonPageHeader from '../../components/Skeleton/PageHeader';
import CardBody from '../../pages/Home/components/CardBody';
import Skeleton from 'react-loading-skeleton';

const Loading: React.FC = () => {
  return (
    <Container>
      <SkeletonPageHeader />
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center">
            <div className="me-3 ">
              <Skeleton width={180} height={25} />
            </div>
            <div className="me-3 ">
              <Skeleton width={125} height={25} />
            </div>
            <div className="me-3 ">
              <Skeleton width={125} height={25} />
            </div>
          </div>
        </Card.Header>
        <CardBody>
          <div className="jobs-list">
            {times(8).map((i) => {
              return <SkeletonCard height={158} key={i} />;
            })}
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Loading;
