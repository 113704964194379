import React from 'react';
import { CategoryAccordionProps } from '../types';
import AbsenceCategoryIcon from '../../../components/AbsenceCategoryIcon';
import { Col, Row } from 'react-bootstrap';
import CategoryAmount from './CategoryAmount';

export const CategoryAccordionHeader: React.FC<
  Pick<CategoryAccordionProps, 'category' | 'isInGroup'> & { active: boolean }
> = ({ category, active, isInGroup }) => {
  const availableAmount = category.allowancesSummary.availableAmount;

  return (
    <Row className="w-100">
      <Col className={!!isInGroup ? 'ms-4' : ''}>
        <AbsenceCategoryIcon icon={category.category.flair__Icon__c} />
        <span className="ms-3">{category.category.Name}</span>
      </Col>
      {!active && (
        <Col className="col-auto text-end">
          <CategoryAmount
            unlimited={category.flair__Unlimited__c}
            type={category.category.flair__Type__c}
            amount={availableAmount}
          />
        </Col>
      )}
    </Row>
  );
};
