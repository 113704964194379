import React from 'react';
import { FilterProps, TEAMS_FILTER } from './types';
import { useTranslation } from 'react-i18next';
import TeamCalendarMultipleFilter from './TeamCalendarMultipleFilter';

const TeamFilter: React.FC<FilterProps> = ({ options, filterData }) => {
  const { t } = useTranslation();

  return (
    <TeamCalendarMultipleFilter
      filterData={filterData}
      options={options}
      filterKey={TEAMS_FILTER}
      placeholder={t('employeePage.teamCalendar.filters.team.placeholder')}
    />
  );
};

export default TeamFilter;
