import styled from '@emotion/styled';
import React from 'react';
import { Tooltip } from 'react-bootstrap';
import Hint from '../../../../../../../components/hint';

type AbsenceHintProps = {
  text: string | React.ReactNode;
};

const AbsenceHint: React.FC<AbsenceHintProps> = ({ text }) => {
  return (
    <Hint
      containerClassName="d-flex align-items-center ms-1"
      id="hint_carryover"
      text={text}
      CustomOverlay={StyledOverlay}
    />
  );
};

const StyledOverlay = styled(Tooltip)`
  .tooltip-inner {
    max-width: 31.5rem;
    text-align: left;
  }
`;

export default AbsenceHint;
