import React, { ChangeEvent, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { i18prefix } from '../../../helpers';
import { FormGroup } from '../../../../../../../components/form/FormGroup';
import { OptionBase } from '../../../../../../../components/Select/types';
import { ProjectWithStatus } from '../../../types';
import { TimeEntryData } from './DayTimeEntriesForm';
import { Theme } from '../../../../../../../theme';
import styled from '@emotion/styled';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import { hoursInputStep } from '../../helpers';
import { CostCenter } from '../../../../../__generated__/graphql';

type Props = {
  timeTracked: TimeEntryData;
  projects: ProjectWithStatus[];
  costCenters: ReadonlyArray<CostCenter>;
  disabled: boolean;
  onDataChange: (timeTracked: TimeEntryData) => void;
  onDelete: (id: string) => void;
};

const StyledSelect = styled(Form.Select)((props) =>
  props.disabled
    ? {
        backgroundColor: `${Theme.color.white} !important`,
      }
    : {},
);

const DayTimeEntry: React.FC<Props> = ({
  timeTracked,
  projects,
  costCenters,
  disabled,
  onDataChange,
  onDelete,
}) => {
  const { t } = useTranslation();

  const availableProjects = projects
    .filter((project) =>
      isProjectAvailable(project, timeTracked.projectId || null),
    )
    .map(mapToOptions);

  const availableCostCenters = [{ Id: '', Name: '' }, ...costCenters];

  const handleHoursChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onDataChange({ ...timeTracked, hours: parseFloat(event.target.value) });
    },
    [onDataChange, timeTracked],
  );

  const handleProjectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onDataChange({ ...timeTracked, projectId: event.target.value });
    },
    [onDataChange, timeTracked],
  );

  const handleCostCenterChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onDataChange({ ...timeTracked, costCenterId: event.target.value });
    },
    [onDataChange, timeTracked],
  );

  const handleNotesChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onDataChange({ ...timeTracked, notes: event.target.value });
    },
    [onDataChange, timeTracked],
  );

  return (
    <Row>
      <Col lg={3} xs={4}>
        <FormGroup>
          <StyledSelect
            disabled={disabled}
            required
            value={timeTracked.projectId || undefined}
            onChange={handleProjectChange}>
            {availableProjects.map((availableProject) => (
              <option
                key={availableProject.value}
                value={availableProject.value}>
                {availableProject.label}
              </option>
            ))}
          </StyledSelect>
        </FormGroup>
      </Col>
      <Col lg={1} xs={2}>
        <Form.Control
          value={timeTracked.hours === 0 ? 0 : timeTracked.hours || ''}
          type="number"
          required
          min={0}
          max={24}
          step={hoursInputStep}
          placeholder={t(`${i18prefix}.timeEntries.form.hoursPlaceholder`)}
          disabled={disabled}
          onChange={handleHoursChange}
        />
      </Col>
      <Col lg={4} xs={5}>
        <Form.Control
          as="textarea"
          rows={1}
          disabled={disabled}
          onChange={handleNotesChange}
          value={timeTracked.notes}
        />
      </Col>
      {costCenters.length > 0 && (
        <Col lg={2} xs={3}>
          <FormGroup>
            <StyledSelect
              disabled={disabled}
              value={timeTracked.costCenterId || undefined}
              onChange={handleCostCenterChange}>
              {availableCostCenters.map((costCenter) => (
                <option key={costCenter.Id} value={costCenter.Id}>
                  {costCenter.Name}
                </option>
              ))}
            </StyledSelect>
          </FormGroup>
        </Col>
      )}
      {!disabled && (
        <Col lg={1} xs={1}>
          <Button
            variant="link"
            disabled={disabled}
            onClick={() => onDelete(timeTracked.id)}>
            <FlairIcon icon="trash-outline" color={Theme.color.gray1} />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default DayTimeEntry;

function mapToOptions(project: ProjectWithStatus): OptionBase {
  return {
    value: project.id,
    label: project.name,
  };
}

function isProjectAvailable(
  project: ProjectWithStatus,
  selectedProjectId: string | null,
) {
  return selectedProjectId === project.id || project.status === 'ACTIVE';
}
