import { useMemo } from 'react';
import {
  TimeFrameworkFragment,
  WorkloadWeekFragment,
} from '../../../__generated__/graphql';
import { getWorkloadDay, IsoWeekDay } from '../mappings';
import { BreakScheduled, DenyEndBreakRules } from '../shared';
import { useTimeTrackingSettings } from '../../../hooks/timeTrackingSettings';

export const useDenyEndBreakRules = (
  timeEntryIsoWeekDayNumber: IsoWeekDay | undefined,
) => {
  const { timeFramework, workloadWeek } = useTimeTrackingSettings();

  return useMemo(() => {
    if (!timeEntryIsoWeekDayNumber) {
      return null;
    }

    return timeFramework
      ? mapDenyEndBreakRules(
          timeFramework,
          workloadWeek,
          timeEntryIsoWeekDayNumber,
        )
      : null;
  }, [timeFramework, workloadWeek, timeEntryIsoWeekDayNumber]);
};

const mapDenyEndBreakRules = (
  src: TimeFrameworkFragment,
  workloadWeek: WorkloadWeekFragment | null,
  isoWeekDay: IsoWeekDay,
): DenyEndBreakRules => ({
  minBreakDuration: src.flair__Minimal_Break_Length__c,
  breaksScheduled: mapBreakScheduled(workloadWeek, isoWeekDay),
});

const mapBreakScheduled = (
  workloadWeek: WorkloadWeekFragment | null,
  isoWeekDay: IsoWeekDay,
): BreakScheduled[] => {
  if (!workloadWeek) {
    return [];
  }
  return (
    getWorkloadDay(workloadWeek, isoWeekDay).breaksScheduled?.map((x) => ({
      startTime: x.startTime,
      endTime: x.endTime,
    })) ?? []
  );
};
