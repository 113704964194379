import classNames from 'classnames';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { TimeSince } from '../../../../components/TimeSince';
import { Emojis, Employee } from '../../pages/People/Announcements/types';
import { getEmployeeInitialsFromName } from '../../utils/employeeInitials';
import Avatar from '../../../../atomic/molecules/avatar/Avatar';
import Reactions from '../Reactions';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import CommentsPopover from '../Comment/CommentsPopover';
import { RelatedObjectNames } from '../Comment/types';
import { AvatarSkeleton } from '../../../../atomic/molecules/avatar/AvatarSkeleton';
import StringToHtml from '../../../../components/StringToHtml';

export type EmployeeEmoji = {
  id: string;
  name: string;
};

export type Emoji = {
  id: string;
  name: string;
  recordId: string;
  employee: EmployeeEmoji;
};

type Props = {
  id: string;
  title: string;
  body: string;
  createdDate: Date;
  employee: Employee | null;
  emojis: Emojis;
  onEmojiChange?: (
    data: Pick<Emoji, 'name' | 'recordId'>,
    operation: string,
  ) => void;
  loadingEmojis: boolean;
  commentsCount: number;
};

export const EMOJI_OPERATIONS = {
  DELETE: 'DELETE',
  CREATE: 'CREATE',
};

const Announcement: React.FC<Props> = ({
  id: announcementId,
  title,
  body,
  createdDate,
  employee,
  emojis,
  onEmojiChange,
  loadingEmojis,
  commentsCount,
}) => {
  const onSelectEmoji = (name: string) => {
    onEmojiChange &&
      onEmojiChange(
        { name, recordId: announcementId },
        EMOJI_OPERATIONS.CREATE,
      );
  };

  const onRemoveEmoji = (e: Emoji) => {
    onEmojiChange &&
      onEmojiChange(
        { name: e.name, recordId: announcementId },
        EMOJI_OPERATIONS.DELETE,
      );
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col className="col-auto">
            {employee ? (
              <Avatar
                avatarUrl={employee?.avatarUrl}
                size="sm"
                initials={getEmployeeInitialsFromName(employee.name)}
              />
            ) : (
              <FlairIcon icon="business-outline" size="2x" />
            )}
          </Col>
          <Col>
            <Row className="d-flex align-items-center mb-4">
              <Col className="h4 mb-0">{title}</Col>
              <Col className="text-end">
                <TimeSince
                  date={createdDate}
                  className={classNames('text-nowrap', 'text-dark')}
                />
              </Col>
            </Row>
            <Row>
              <div className="mb-3">
                <StringToHtml text={body} />
              </div>
            </Row>
            <Row className="d-flex align-items-center justify-content-between">
              <div className="col-10">
                <Reactions
                  emojis={emojis}
                  onRemove={onRemoveEmoji}
                  onSelect={onSelectEmoji}
                  loading={loadingEmojis}
                />
              </div>
              <div className="col-auto text-end">
                <CommentsPopover
                  recordId={announcementId}
                  relatedObjectName={RelatedObjectNames.Announcements}
                  commentsCount={commentsCount}
                  mode="text"
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const AnnouncementCardSkeleton = () => {
  return (
    <Card>
      <Card.Body>
        <Row className="flex-nowrap">
          <Col className="col-auto">
            <AvatarSkeleton size="sm" />
          </Col>
          <Col md={10}>
            <Skeleton width="100%" height="20rem" />
          </Col>
          <Col className="text-end">
            <Skeleton width="4rem" height="1rem" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Announcement;
