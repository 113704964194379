import { ProjectFormData, ProjectFormSaveInfo } from './types';
import * as yup from 'yup';
import { useForm, useFormContext } from 'react-hook-form';
import i18next from 'i18next';
import useYupValidationResolver from '../../../../../components/form/useYupValidationResolver';
import { fromPairs, mapValues } from 'lodash';
import { useCallback, useState } from 'react';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useUpdateProject } from './useProjectMutations';

const i18prefix = 'timeTracking.projects.form';

const validationSchema = yup.object().shape({
  name: yup.string().max(255).required(),
  estimation: yup.number().integer().nullable(true),
  endDate: yup
    .date()
    .nullable(true)
    .min(
      yup.ref('startDate'),
      i18next.t(`${i18prefix}.validationEndDateShouldBeGreater`),
    ),
});

export const useUpdateProjectForm = (values: ProjectFormData) => {
  const form = useForm<ProjectFormData>({
    defaultValues: values,
    mode: 'onChange',
    validationResolver: useYupValidationResolver(validationSchema),
  });

  return form;
};

export const useSubmitUpdate = () => {
  const { handleSubmit: handleFormSubmit, reset: formReset } =
    useFormContext<ProjectFormData>();
  const errorHandler = useMutationErrorHandler();
  const [saveInfo, setSaveInfo] = useState<ProjectFormSaveInfo>({});

  const [updateProject] = useUpdateProject();

  const submitUpdate = useCallback(
    (projectId: string, fieldsToUpdate: Set<string>) => {
      handleFormSubmit((formData: ProjectFormData) => {
        setSaveInfo(
          createSavingInfo(fieldsToUpdate as Set<keyof ProjectFormData>),
        );

        return updateProject(projectId, formData)
          .then(() => {
            setSaveInfo((prev) => setCompleted(prev));
          })
          .catch((e) => {
            formReset();
            setSaveInfo((prev) => setCompleted(prev, false));
            errorHandler(e);
          });
      })();
    },
    [errorHandler, formReset, handleFormSubmit, updateProject, setSaveInfo],
  );

  return { submitUpdate, saveInfo };
};

const createSavingInfo = (
  fields: Set<keyof ProjectFormData>,
): Partial<ProjectFormSaveInfo> =>
  fromPairs(Array.from(fields.keys()).map((key) => [key, { loading: true }]));

const setCompleted = (
  savingInfo: Partial<ProjectFormSaveInfo>,
  completed: boolean = true,
) => mapValues(savingInfo, () => ({ loading: false, completed }));
