import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ConfirmModal.css';

import Button from '../button';

type Props = {
  show: boolean;
  loading: boolean;
  title: string | React.ReactNode;
  text: string | React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
};

// const customStyles = {
//   overlay: { zIndex: 2000 },
// };

const ConfirmModal: React.FC<Props> = ({
  show,
  loading,
  title,
  text,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      className="confirm-modal"
      contentClassName="border"
      backdropClassName="confirm-modal-backdrop">
      <Modal.Header closeButton className="card-header">
        <h4 className="card-header-title">{title}</h4>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>

      <ModalFooter>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={onClose}
          label={t('modal.confirm.no')}
        />

        <Button
          isProcessing={loading}
          size="sm"
          onClick={onConfirm}
          variant="primary"
          label={t('modal.confirm.yes')}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
