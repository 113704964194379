import React from 'react';
import AbsenceCategoryIcon from '../../../../components/AbsenceCategoryIcon';
import './AbsenceIcon.css';

type Props = {
  icon: string;
  variant?: 'rounded' | 'plain';
  className?: string;
};

const AbsenceIcon: React.FC<Props> = ({
  icon,
  className,
  variant = 'rounded',
}) => {
  const categoryIcon = (
    <AbsenceCategoryIcon icon={icon} className={className} />
  );

  if (variant === 'plain') {
    return categoryIcon;
  }

  return <div className="absence-icon-container">{categoryIcon}</div>;
};

export default AbsenceIcon;
