import { ManagerSkillFragment } from '../../../__generated__/graphql';
import { mapEmployee, mapSkillLevel } from '../common/mappings';
import { getSkillLevelMedian } from '../common/skillLevels';
import { ManagerSkill } from './types';

export const mapManagerSkill = (src: ManagerSkillFragment): ManagerSkill => ({
  id: src.Id,
  name: src.Name,
  rateable: src.flair__Rateable__c,
  createdBy: src.createdBy ? mapEmployee(src.createdBy) : undefined,
  createdDate: src.CreatedDate,
  median:
    getSkillLevelMedian(
      src.employeeSkills.map((x) => mapSkillLevel(x.flair__Rate__c)),
    ) ?? 0,
  employeesCount: src.employeeSkills.length,
});
