import React from 'react';
import { FilterWarningsType, TimeSheetsFilter } from '../Filters/types';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import Button from '../../../../../components/button';
import Skeleton from 'react-loading-skeleton';

import StringToHtml from '../../../../../components/StringToHtml';

export type Props = {
  filter: TimeSheetsFilter;
  withoutWarningsIds: string[];
  withWarningsIds: string[];
  approveInProgress: boolean;
  onApprove: (timeSheetIds: string[]) => void;
  onSetWarningFilter: (fitlerType: FilterWarningsType) => void;
};

export const TimeSheetTopPanel: React.FC<Props> = ({
  filter,
  withWarningsIds,
  withoutWarningsIds,
  onSetWarningFilter,
  approveInProgress,
  onApprove,
}) => {
  const t = useNamespacedTranslation('timeSheetsControlling.topPanel');

  if (!withWarningsIds.length && !withoutWarningsIds.length) {
    return null;
  }

  const deviderCssClass = withWarningsIds.length > 0 ? 'border-bottom' : '';
  const approveAllDisabled: boolean =
    !!filter.employeeIds.length || !!filter.month || !!filter.year;

  return (
    <Card className="h4-regular">
      {withoutWarningsIds.length > 0 && (
        <div
          className={`d-flex align-items-center justify-content-between py-3 mx-3 ${deviderCssClass}`}>
          <div className="d-flex align-items-center">
            <FlairIcon
              icon="checkmark-circle-outline"
              className="text-success me-2"
            />

            <span>
              <StringToHtml
                text={t('withoutWarnings', {
                  countHtml: `<span class="text-success">${withoutWarningsIds.length}</span>`,
                  interpolation: { escapeValue: false },
                })}
              />
            </span>
          </div>
          <div className="d-flex gap-2">
            <Button
              label={t(`reviewAll`)}
              className="text-nowrap"
              variant="outline-primary"
              size="sm"
              onClick={() => onSetWarningFilter('withoutWarnings')}
            />
            <OverlayTrigger
              placement="top"
              show={approveAllDisabled}
              overlay={
                <Popover id="approve-all-disable-reason">
                  <Popover.Body>{t('approveAllDisabledReason')}</Popover.Body>
                </Popover>
              }>
              <Button
                label={t(`approveAll`)}
                className="text-nowrap"
                variant="primary"
                disabled={approveAllDisabled}
                isProcessing={approveInProgress}
                size="sm"
                onClick={() => onApprove(withoutWarningsIds)}
              />
            </OverlayTrigger>
          </div>
        </div>
      )}
      {withWarningsIds.length > 0 && (
        <div className="d-flex align-items-center justify-content-between p-3">
          <div className="d-flex align-items-center">
            <FlairIcon
              icon="alert-circle-outline"
              className="text-warning me-2"
            />
            <span>
              <StringToHtml
                text={t('withWarnings', {
                  countHtml: `<span class="text-warning">${withWarningsIds.length}</span>`,
                  interpolation: { escapeValue: false },
                })}
              />
            </span>
          </div>
          <div>
            <Button
              className="text-nowrap"
              label={t(`reviewAll`)}
              variant="outline-primary"
              size="sm"
              onClick={() => onSetWarningFilter('withWarnings')}
            />
          </div>
        </div>
      )}
    </Card>
  );
};

export const TimeSheetTopPanelLoading: React.FC = () => {
  return (
    <Card className="h4-bold">
      <div
        className={`d-flex align-items-center justify-content-between py-3 mx-3`}>
        <div className="w-50">
          <Skeleton width="100%" height="1.5rem" />
        </div>
        <div>
          <Skeleton className="me-2" width="5rem" height="1.4rem" />
        </div>
      </div>
    </Card>
  );
};
