import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SkeletonCard from '../../../../components/Skeleton/Card';
import { useNotificationSettingsData } from '../useNotificationSettingsData';

const HEADER_HEIGHT = 72.75;
const NOTIFICATION_CATEGORY_BASE_HEIGHT = 109;

const NotificationSettingsLoadingSkeleton: React.FC = () => {
  const [notificationSettingsInfoByCategory] = useNotificationSettingsData();

  const notificationCategoryHeight = (numberOfNotifications: number) =>
    NOTIFICATION_CATEGORY_BASE_HEIGHT +
    numberOfNotifications * 51.8 + // Setting toggle height
    (numberOfNotifications - 1) * 25; // HR height between setting toggles

  return (
    <Row>
      <Col>
        <SkeletonCard height={HEADER_HEIGHT} />
        {notificationSettingsInfoByCategory
          .filter(
            (notificationSettingsCategory) =>
              notificationSettingsCategory.showCondition ?? true,
          )
          .map((notificationSettingsCategory, index) => (
            <SkeletonCard
              key={index}
              height={notificationCategoryHeight(
                notificationSettingsCategory.notifications.length,
              )}
            />
          ))}
      </Col>
    </Row>
  );
};

export default NotificationSettingsLoadingSkeleton;
