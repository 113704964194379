import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { ProjectsTimeSheetFiltersQuery } from '../../../../__generated__/graphql';
import {
  EmployeeOption,
  mapEmployeeOption,
} from '../../../../components/Selects/EmployeeOptionLabel';
import { OptionBase } from '../../../../../../components/Select/types';
import { getTimeSheetStatusOptions } from '../../../../components/TimeSheetsTable/filterOptions';

export const useFilterOptions = (
  data: ProjectsTimeSheetFiltersQuery | undefined,
) => {
  const employeeOptions: EmployeeOption[] = useMemo(() => {
    if (!data?.manager?.employees) {
      return [];
    }
    return orderBy(data.manager.employees.map(mapEmployeeOption), 'label');
  }, [data]);

  const projectsOptions: OptionBase[] = useMemo(() => {
    if (!data?.manager?.pendingTimeSheets) {
      return [];
    }
    const projectMap = data.manager.pendingTimeSheets
      .flatMap((x) => x.projectsTimeEntries)
      .reduce((acc, timeEntry) => {
        const project = timeEntry.project;
        // WE don't support filterting by emptry project so far
        if (!project) {
          return acc;
        }
        return acc.set(project.Id, {
          label: project.flair__Name__c,
          value: project.Id,
        });
      }, new Map<string, OptionBase>());
    return Array.from(projectMap.values());
  }, [data]);

  const approvalStatusOptions: OptionBase[] = useMemo(() => {
    return getTimeSheetStatusOptions();
  }, []);

  return {
    employeeOptions,
    projectsOptions,
    approvalStatusOptions,
  };
};
