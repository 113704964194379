import { sortBy } from 'lodash';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formattedDate } from '../../../../../components/date/FormattedDate';
import { formattedDuration } from '../../../components/DurationFormat';
import SoftBadge from '../../../components/SoftBadge';
import { calcWarnings, TrackedTime } from '../../../components/TrackedTime';
import { minutesToHours } from '../../../../../utils/time';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  trackedTime: TrackedTime;
};

const TimeSheetEntryWarningBadge: React.FC<Props> = ({ trackedTime }) => {
  const { t } = useTranslation();
  const formatDuration = formattedDuration(t);
  const formatDate = formattedDate(t);

  const daysExceededWorkingHours = sortBy(
    calcWarnings(trackedTime).workingHoursExceeded,
    'day',
  );

  if (!daysExceededWorkingHours.length) {
    return <></>;
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id="popover">
          <Popover.Body>
            <ul className="ps-4 mb-0">
              {daysExceededWorkingHours.map((trackedDay) => (
                <li key={trackedDay.day}>
                  {t('timeTracking.controlling.warningBadgePopover', {
                    duration: formatDuration(
                      minutesToHours(trackedDay.trackedMinutes),
                    ),
                    day: formatDate({ day: trackedDay.day, format: 'day' }),
                  })}
                </li>
              ))}
            </ul>
          </Popover.Body>
        </Popover>
      }>
      <SoftBadge variant="warning">
        <FlairIcon icon="alert-circle-outline" className="align-text-bottom" />
        <span className="ms-1 align-text-bottom">
          {daysExceededWorkingHours.length}
        </span>
      </SoftBadge>
    </OverlayTrigger>
  );
};

export default TimeSheetEntryWarningBadge;
