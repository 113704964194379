import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getError } from '../../../../../components/form/utils';
import {
  CelebrationMessageInput,
  Props as CelebrationMessageInputProps,
} from './CelebrationMessageInput';

type Props = Omit<
  CelebrationMessageInputProps,
  'value' | 'onChange' | 'error'
> & {
  name: string;
};

export const CelebrationMessageInputControlled: React.FC<Props> = ({
  name,
}) => {
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  const renderAs = useCallback(
    (props: any) => {
      return (
        <CelebrationMessageInput
          {...props}
          error={error}
          value={props.value}
          onChange={props.onChange}
        />
      );
    },
    [error],
  );

  return <Controller as={renderAs} name={name} control={control} />;
};
