import * as React from 'react';
import './PreviewImage.css';
import { useContext, useState } from 'react';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../theme';
import { Modal } from 'react-bootstrap';
import { InnerModalContext } from '../../../../context/InnerModalContext';

type Props = {
  src: string;
  alt: string;
  width?: number;
  className?: string;
};

const PreviewImage: React.FC<Props> = ({ src, alt, width, className }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { setIsInnerModalOpen } = useContext(InnerModalContext);

  const togglePreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
    setIsInnerModalOpen(!isPreviewOpen);
  };

  return (
    <>
      <img
        role="button"
        width={width}
        src={src}
        alt={alt}
        onClick={togglePreview}
        className={className}
        style={{ cursor: 'zoom-in' }}
      />
      <Modal
        centered
        show={isPreviewOpen}
        onHide={togglePreview}
        rootClose
        className="image-preview-modal">
        <FlairIcon
          icon="close-outline"
          color={Theme.color.white}
          size="lg"
          className="mb-1 align-self-end"
          onClick={() => setIsPreviewOpen(false)}
        />
        <img src={src} alt={alt} onClick={togglePreview} />
      </Modal>
    </>
  );
};

export default PreviewImage;
