import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { EmployeeWithAvatar } from '../../../components/Employee';
import { EmployeeInfoSidebar } from '../../../components/EmployeeInfoSidebar';
import GlobalTableFilter from '../../../components/GlobalTableFilter';
import PageHeader from '../../../components/PageHeader';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { TableContent } from '../../../manager/components/TableContent';
import { useDirectoryQuery } from '../../../__generated__/graphql';
import DirectoryFilters from './DirectoryFilters';
import { filterFromQuery } from './DirectoryFilters/logic';
import { mapEmployees } from './helpers';
import { EmployeeRow } from './types';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

const i18Path = 'people.directory';

const Content: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeRow | null>(
    null,
  );
  const [allEmployees, setAllEmployees] = useState<EmployeeRow[]>([]);
  const [employees, setEmployees] = useState<EmployeeRow[]>([]);
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const filter = filterFromQuery(query);
  const { data, loading } = useDirectoryQuery({
    variables: {
      locations: filter.locations,
      teams: filter.teams,
      departments: filter.departments,
    },
  });

  useEffect(() => {
    const employees = mapEmployees(data?.activeEmployees);
    setEmployees(employees);
    setAllEmployees(employees);
  }, [data]);

  const onSearch = useCallback(
    (searchText: string) => {
      if (searchText) {
        setEmployees(
          allEmployees.filter((e) =>
            e.name.toLowerCase().includes(searchText.toLowerCase()),
          ),
        );
      } else {
        setEmployees(allEmployees);
      }
    },
    [allEmployees],
  );

  const columns: Column<EmployeeRow>[] = useMemo(
    () => [
      {
        Header: t('employee'),
        accessor: 'name',
        Cell: (props) => (
          <EmployeeWithAvatar
            employee={{
              name: props.value,
              avatarUrl: props.row.original.avatarUrl,
            }}
          />
        ),
      },
      {
        Header: t('department'),
        accessor: 'department',
      },
      {
        Header: t('companyEmail'),
        accessor: 'companyEmail',
      },
      {
        Header: t('phone'),
        accessor: 'phone',
      },
      {
        Header: t('manager'),
        accessor: 'managerName',
      },
    ],
    [t],
  );

  const onCloseSidebar = () => setSelectedEmployee(null);

  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center small">
          <GlobalTableFilter
            placeholder={t('search')}
            onFilterChange={onSearch}
          />
          <div className="me-auto">
            <DirectoryFilters />
          </div>
        </div>
      </Card.Header>

      {loading ? (
        <SkeletonTable className="card-table" columns={4} rows={15} />
      ) : (
        <>
          <TableContent
            columns={columns}
            data={employees}
            onRowClick={setSelectedEmployee}
            pageSize={10}
            initialSortBy={[{ id: 'name' }]}
          />
          <EmployeeInfoSidebar
            show={!!selectedEmployee}
            onClose={onCloseSidebar}
            employee={selectedEmployee}
          />
        </>
      )}
    </Card>
  );
};

const i18PathDirectory = 'navigation.menuItems.company';

const Directory: React.FC = () => {
  const t = useNamespacedTranslation(i18PathDirectory);

  useFlairBreadcrumbHook([
    { label: t('title') },
    {
      label: t('subMenuItems.directory.title'),
    },
  ]);

  return (
    <>
      <PageHeader title={t('subMenuItems.directory.title')} />
      <Content />
    </>
  );
};

export default Directory;
