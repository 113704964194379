import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export type DateFormat =
  | 'day'
  | 'dayname'
  | 'short'
  | 'long'
  | 'compact'
  | 'day-with-month'
  | 'day-with-weekday'
  | 'weekday-shortMonth-day-year'; // e.g. Fri. Sep 1, 2023;

type Props = {
  day: string | Date | moment.Moment;
  format?: DateFormat;
  className?: string;
};

//TODO: Move to atomic folder (atoms) and add tests and stories
// TODO: Rewrite this function to use date-fns
const FormattedDate: React.FC<Props> = ({
  day,
  format = 'long',
  className,
}) => {
  const { t } = useTranslation();
  const formatDate = formattedDate(t);

  return <span className={className}>{formatDate({ day, format })}</span>;
};

export const formattedDate =
  (t: TFunction) =>
  ({ day, format }: Props) =>
    moment(day).format(t(`date.${format}`));

export default FormattedDate;
