import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { useMutationErrorHandler } from '../../../../../../../hooks/useMutationErrorHandler';
import { ResolutionLabel } from '../../../../../components/EmployeeFeedback/ResolutionLabel';
import { useUpdateEmployeeFeedbackResolutionMutation } from '../../../../../__generated__/graphql';

type Props = {
  resolutionId: string;
  disabled?: boolean;
  value: string | null;
  onUpdateResolution: (params: { resolutionId: string; value: string }) => void;
};

export const ResolutionInput: React.FC<Props> = ({
  resolutionId,
  value,
  disabled,
  onUpdateResolution,
}) => {
  const [updateResolution, { loading }] =
    useUpdateEmployeeFeedbackResolutionMutation();
  const errorHandler = useMutationErrorHandler();
  const [inputValue, setInputValue] = useState<string>(value ?? '');
  const saveChanges = useMemo(
    () =>
      debounce(
        (value: string) =>
          updateResolution({
            variables: {
              input: {
                resolutionId,
                value,
              },
            },
          })
            .then(() => onUpdateResolution({ resolutionId, value }))
            .catch(errorHandler),
        500,
      ),
    [updateResolution, onUpdateResolution, resolutionId, errorHandler],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = event;

      setInputValue(value);
      saveChanges(value);
    },
    [saveChanges, setInputValue],
  );

  return (
    <Row>
      <Col>
        <ResolutionLabel isSaving={loading} />
        <TextareaAutosize
          className="form-control"
          onChange={handleChange}
          value={inputValue}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};
