import { useCallback, useEffect, useState } from 'react';
import { MenuItemType } from '../atomic/molecules/navigation/types';
import { useHistory, useLocation } from 'react-router-dom';

export const useNavItemToggle = (menuItem: MenuItemType) => {
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
    if (!open) {
      const firstSubmenu = menuItem.subItems?.find((s) => s.shouldRender);
      if (!!firstSubmenu) {
        history.push(firstSubmenu.route.template());
      }
    }
  }, [history, menuItem.subItems, open]);

  useEffect(() => {
    const isOpen =
      !!menuItem.subItems?.find((m) =>
        m.route.template().includes(location.pathname),
      ) && location.pathname !== '/';
    setOpen(isOpen);
  }, [location.pathname, menuItem.subItems]);

  return {
    open,
    handleClick,
  };
};
