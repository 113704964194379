import React from 'react';
import { useTranslation } from 'react-i18next';
import { OpenShiftContainer } from './BlockContainer';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import Button from '../../../../components/button';

import { OpenShiftUI } from './openShiftsLogic';
import { ShiftLabel } from './ShiftLabel';
import { getContainerColors } from '../../components/Shifts/shiftsColors';
import {
  OpenShiftNotAvailableReason,
  OpenShiftState,
} from '../../components/Shifts/Common/types';
import { notAvailableReasonText } from '../../components/Shifts/Common/logic';
import { Theme } from '../../../../theme';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type Props = {
  shift: OpenShiftUI;
  isRequesting: boolean;
  onRequestShift: (shiftId: string) => void;
  onDeleteAssignmentRequest: (requestId: string) => void;
};

const OpenShiftBlock: React.FC<Props> = ({
  shift,
  isRequesting,
  onRequestShift,
  onDeleteAssignmentRequest,
}) => {
  const {
    id,
    assignmentRequestId,
    start,
    notAvailableReason,
    end,
    totalBreakPeriod,
    state,
  } = shift;

  const { t } = useTranslation();

  if (isRequesting) {
    return (
      <RootContainer state={state}>
        <Row className="h-100 align-items-center justify-content-center">
          <Col>
            <span
              className="spinner-border spinner-border-sm text-primary"
              role="status"
              aria-hidden="true"></span>
          </Col>
        </Row>
      </RootContainer>
    );
  }
  return (
    <RootContainer state={state}>
      <HoverContainer {...getContainerColors(state)}>
        <HoverContent
          state={state}
          notAvailableReason={notAvailableReason}
          isRequesting={isRequesting}
          onRequestShift={() => onRequestShift(id)}
          onDelete={() =>
            onDeleteAssignmentRequest(assignmentRequestId!)
          }></HoverContent>
      </HoverContainer>
      <Row className="h-100 align-items-center">
        <Col>
          <Row>
            <Col>
              <ShiftLabel start={start} end={end} />
            </Col>
          </Row>
          {totalBreakPeriod > 0 && (
            <Row>
              <Col>
                <small>
                  {totalBreakPeriod} {t('general.shortMinutes')}
                </small>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </RootContainer>
  );
};

export default OpenShiftBlock;

const HoverContainer = styled.div<{
  backgroundColor: string;
  color: string;
  border: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: ${Theme.border.radius};
  z-index: 1000;
  justify-content: center;
  align-items: center;

  display: none;

  .open-shift-container:hover & {
    display: flex;
  }
`;

const RootContainer: React.FC<{
  state: OpenShiftState;
  children: React.ReactNode;
}> = ({ state, children }) => {
  return (
    <OpenShiftContainer
      className="open-shift-container"
      slotsPerDay={1}
      {...getContainerColors(state)}>
      {children}
    </OpenShiftContainer>
  );
};

// HoverContent

const HoverContentContainerRequested = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const HoverContentDeleteAction = styled.div({
  position: 'absolute',
  cursor: 'pointer',
  right: '4px',
  top: '0',
});

const HoverContent: React.FC<{
  state: OpenShiftState;
  notAvailableReason?: OpenShiftNotAvailableReason;
  isRequesting: boolean;
  onRequestShift: () => void;
  onDelete: () => void;
}> = ({
  state,
  notAvailableReason,
  onRequestShift,
  onDelete,
  isRequesting,
}) => {
  const { t } = useTranslation();

  const renderNotAvailable = (
    notAvailableReason: OpenShiftNotAvailableReason,
  ) => <small>{notAvailableReasonText(notAvailableReason)}</small>;

  switch (state) {
    case 'available':
      return (
        <Button
          size="sm"
          label={t('shiftPlanner.openShifts.requestShift')}
          isProcessing={isRequesting}
          onClick={onRequestShift}></Button>
      );
    case 'not-available':
      return renderNotAvailable(notAvailableReason!);
    case 'requested':
      return (
        <HoverContentContainerRequested>
          <HoverContentDeleteAction onClick={onDelete}>
            <FlairIcon icon="close-outline" />
          </HoverContentDeleteAction>
          {t('shiftPlanner.openShifts.requestSent')}
        </HoverContentContainerRequested>
      );
    case 'confirmed':
      return <small>{t('shiftPlanner.openShifts.approved')}</small>;
    default:
      return null;
  }
};
