import styled from '@emotion/styled';
import { Theme } from '../../../../../theme';
import { CellVariant } from '../../AbsenceCalendar/Utils';

type Props = {
  variant: CellVariant;
  hover: boolean;
  cellHovered: boolean;
  today: boolean;
  showHorizontalLine?: boolean;
  showVerticalLine?: boolean;
};

const EmployeeAbsenceCalendarCardCell = styled.td<Props>(
  { color: Theme.color.white },
  ({ variant, cellHovered, today, showHorizontalLine, showVerticalLine }) => {
    let baseStyle: any = {
      cursor: 'pointer',
      position: 'relative',
      zIndex: 1,
    };

    if (today) {
      return {
        ...baseStyle,
        backgroundColor: `${Theme.absence.today.bgColor} !important`,
        borderRadius: Theme.border.radius,
      };
    }

    if (cellHovered) {
      return {
        ...baseStyle,
        backgroundColor: `${Theme.absence.hover.bgColor} !important`,
        opacity: '0.6',
        borderRadius: Theme.border.radius,
      };
    }

    if (showHorizontalLine || showVerticalLine) {
      baseStyle = {
        ...baseStyle,
        '::before': {
          content: "''",
          position: 'absolute',
          opacity: 0.6,
          zIndex: 0,
          ...(showHorizontalLine
            ? {
                left: 0,
                right: 0,
                borderBottom: `3px solid ${Theme.absence.hover.bgColor}`,
                transform: 'translateY(-50%)',
              }
            : {
                top: 0,
                bottom: 0,
                borderLeft: `3px solid ${Theme.absence.hover.bgColor}`,
                transform: 'translateX(-50%)',
              }),
        },
      };
    }

    switch (variant) {
      case 'warning': {
        return {
          ...baseStyle,
          backgroundColor: `${Theme.absence.warning.bgColor} !important`,
        };
      }

      case 'absenceWeekend':
      case 'weekend': {
        return {
          ...baseStyle,
          backgroundColor: `${Theme.absence.nonWorkingDay.bgColor} !important`,
        };
      }

      case 'holiday': {
        return {
          ...baseStyle,
          backgroundColor: `${Theme.absence.holiday.bgColor} !important`,
        };
      }

      case 'pending':
      case 'absence':
      case 'workday': {
        return {
          ...baseStyle,
        };
      }
    }
  },
);

export default EmployeeAbsenceCalendarCardCell;
