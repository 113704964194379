import * as React from 'react';

const PauseCircleOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8.42383C1.5 4.83519 4.41136 1.92383 8 1.92383C11.5886 1.92383 14.5 4.83519 14.5 8.42383C14.5 12.0125 11.5886 14.9238 8 14.9238C4.41136 14.9238 1.5 12.0125 1.5 8.42383ZM8 2.92383C4.96364 2.92383 2.5 5.38747 2.5 8.42383C2.5 11.4602 4.96364 13.9238 8 13.9238C11.0364 13.9238 13.5 11.4602 13.5 8.42383C13.5 5.38747 11.0364 2.92383 8 2.92383Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 5.92383C6.77614 5.92383 7 6.14769 7 6.42383V10.4238C7 10.7 6.77614 10.9238 6.5 10.9238C6.22386 10.9238 6 10.7 6 10.4238V6.42383C6 6.14769 6.22386 5.92383 6.5 5.92383ZM9.5 5.92383C9.77614 5.92383 10 6.14769 10 6.42383V10.4238C10 10.7 9.77614 10.9238 9.5 10.9238C9.22386 10.9238 9 10.7 9 10.4238V6.42383C9 6.14769 9.22386 5.92383 9.5 5.92383Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default PauseCircleOutlineIcon;
