import React from 'react';
import FiltersLoading from './FiltersLoading';
import {
  ProjectsProjectsTimeSheetFilters,
  Props as TimeSheetFiltersProps,
} from './ProjectsProjectsTimeSheetFilters';
import { useProjectsTimeSheetFiltersQuery } from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../../hooks/useManagerReportsMode';
import { useFilterOptions } from './hooks';

type Props = Omit<
  TimeSheetFiltersProps,
  'employeeOptions' | 'projectsOptions' | 'approvalStatusOptions'
> & {
  reportsMode: ManagerReportsMode;
};

export const ProjectsTimeSheetFiltersConnected: React.FC<Props> = ({
  reportsMode,
  ...restProps
}) => {
  const { data, loading, error } = useProjectsTimeSheetFiltersQuery({
    variables: {
      managerReportsType: mapManagerReportsType(reportsMode),
    },
  });

  const { employeeOptions, projectsOptions, approvalStatusOptions } =
    useFilterOptions(data);

  if (!data || loading) {
    return <FiltersLoading />;
  }

  if (error) {
    return <ServerError />;
  }

  return (
    <ProjectsProjectsTimeSheetFilters
      employeeOptions={employeeOptions}
      projectsOptions={projectsOptions}
      approvalStatusOptions={approvalStatusOptions}
      {...restProps}
    />
  );
};
