import { useHistory, useLocation } from 'react-router-dom';

type Value = string | null;

export const useQueryParameter = (
  parameterId: string,
): [Value, (v: Value) => void] => {
  const location = useLocation();
  const history = useHistory();
  const queryParams: URLSearchParams = new URLSearchParams(location.search);
  const value = queryParams.get(parameterId);
  const setValue = (id: string | null) => {
    if (id) {
      queryParams.set(parameterId, id);
    } else {
      queryParams.delete(parameterId);
    }
    const newPath = `${location.pathname}?${queryParams.toString()}`;
    history.push(newPath);
  };
  return [value, setValue];
};
