import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader, { PageHeaderRow } from '../../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { Col } from 'react-bootstrap';
import ProjectNav from './ProjectNav';

const ProjectsHeader: React.FC = () => {
  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.timeManagement.title') },
    { label: t('timeTracking.subMenuItems.projects.title') },
  ]);

  return (
    <PageHeader title={t('timeTracking.subMenuItems.projects.title')}>
      <PageHeaderRow>
        <Col>
          <ProjectNav />
        </Col>
      </PageHeaderRow>
    </PageHeader>
  );
};

export default ProjectsHeader;
