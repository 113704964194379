import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import './FlairCard.css';

type Props = {
  cardTitle?: string;
  cardTitleClassName?: string;
  headerActions?: React.ReactNode;
  headerClassName?: string;
  children: React.ReactNode;
  contentClassName?: string;
  footerActions?: React.ReactNode;
  footerClassName?: string;
  cardContainerClassName?: string;
};

const FlairCard: React.FC<Props> = ({
  cardTitle,
  cardTitleClassName = '',
  headerActions,
  headerClassName = '',
  children,
  contentClassName = 'd-flex flex-column gap-3-5 pt-2-5 pb-3-5',
  footerActions,
  footerClassName = '',
  cardContainerClassName = '',
}) => {
  return (
    <Card className={classNames(cardContainerClassName, 'flair-card mb-3-5')}>
      {(cardTitle || headerActions) && (
        <Card.Header
          className={classNames('border-0 pt-5 pb-5', headerClassName)}>
          <div className={classNames('h2-mini fs-2', cardTitleClassName)}>
            {cardTitle}
          </div>
          <div className="fs-4">{headerActions}</div>
        </Card.Header>
      )}
      <Card.Body className={contentClassName}>{children}</Card.Body>
      {footerActions && (
        <Card.Footer
          className={`d-flex flex-row justify-content-center py-2-5 ${footerClassName}`}>
          {footerActions}
        </Card.Footer>
      )}
    </Card>
  );
};

export default FlairCard;
