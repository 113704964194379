import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import CommentCount from '../CommentCount/CommentCount';
import Button from '../../../../../components/button';

export type Props = {
  commentsCount?: number;
  mode: 'text' | 'icon' | 'button';
};
const i18Path = 'comments';

export const CommentsPopoverButton: React.FC<Props> = ({
  mode,
  commentsCount,
}) => {
  const t = useNamespacedTranslation(i18Path);

  const getCommentsCountForTextMode = () => {
    return commentsCount !== undefined
      ? t('commentsCount', { count: commentsCount })
      : t('commentsCountNotDefined');
  };

  switch (mode) {
    case 'icon':
      return <CommentCount count={commentsCount ?? 0} />;
    case 'text':
      return (
        <div className="h5 text-primary mb-0 comments-popover-btn">
          {getCommentsCountForTextMode()}
        </div>
      );
    case 'button':
      return (
        <Button
          icon="chatbubble-outline"
          label={t('commentsCount2', { count: commentsCount })}
          variant="outline-primary"
          size="sm"
        />
      );
  }
};
