import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useCurrentLocale } from '../../../../context/I18n';
import { atou } from '../../../../utils/base64';
import Loading from '../../components/Loading';
import PageHeader from '../../components/PageHeader';
import { routes } from '../../routes';
import { useEditPersonalDataQuery } from '../../__generated__/graphql';
import Form from './Form';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

type RouteParams = {
  section: string;
};

const RedirectToPersonalData: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push(routes.personalData.route.create({}));
  }, [history]);

  return <></>;
};

type ContentProps = {
  sectionLabel: string;
};

const Content: React.FC<ContentProps> = ({ sectionLabel }) => {
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.profile.title') },
    { label: t('personalData.subMenuTitle') },
  ]);
  const { data, loading } = useEditPersonalDataQuery({
    variables: { locale: locale },
  });

  if (loading || !data) return <Loading />;

  const section = data.me.data.sections.find(
    (s) => s.label === atou(sectionLabel),
  );

  if (!section) {
    return <RedirectToPersonalData />;
  }

  return (
    <Container>
      <PageHeader
        subtitle={t('editPersonalData.title')}
        title={section.label}
      />
      <Row className="justify-content-center mb-5">
        <Col lg={10}>
          <Form section={section} />
        </Col>
      </Row>
    </Container>
  );
};

const EditPersonalData: React.FC = () => {
  const { section: sectionLabel } = useParams<RouteParams>();

  return <Content sectionLabel={sectionLabel} />;
};

export default EditPersonalData;
