import { Answer, PeerFeedback } from '../EmployeeEvaluation/types';
import { Maybe } from '../../../../../../utils/maybe';
import { FeedbackQuestionType } from '../../../../__generated__/graphql';

export const calcRatingScoreAvg = (answers: Answer[]): Maybe<number> => {
  const ratingAnswers = answers.filter(
    (a) =>
      a.feedback &&
      a.question.questionType === FeedbackQuestionType.Rating &&
      !a.question.isOptional,
  );

  if (ratingAnswers.length === 0) {
    return null;
  }

  const sumOfRatingAnswers = ratingAnswers.reduce(
    (total, current) => total + Number(current.feedback),
    0,
  );

  const avgRatingScore = sumOfRatingAnswers / ratingAnswers.length;
  return Number(avgRatingScore.toFixed(1));
};

export const hasAnySubmitted = (peerFeedbacks: PeerFeedback[]) =>
  peerFeedbacks.some((p) => !!p.submittedAt);
