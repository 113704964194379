import * as React from 'react';

const ArrowDownOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M3.49805 8.37401L7.99699 12.8729L12.4959 8.37401M7.99699 12.2481V3.12524"
        stroke="currentColor"
        strokeWidth="1.49965"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ArrowDownOutlineIcon;
