import * as React from 'react';
import FlairCard from '../../../../../../atomic/templates/FlairCard/FlairCard';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { MeetingInfo } from '../../../Home/PerformanceReviewCard/types';
import { formattedDateTime } from '../../../../../../components/datetime/FormattedDateTime';
import { useTranslation } from 'react-i18next';
import './NextMeetingCard.css';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';
import { Button, Dropdown } from 'react-bootstrap';
import {
  generateFeedbackMeetingIcsLink,
  generateGoogleLink,
} from '../../../Absences/ICalendar/ICalendarUtils';
import { useToasts } from '../../../../../../context/Toast';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';

type Props = MeetingInfo & {
  calendarToken: string;
};

const i18Path = 'dashboard.nextMeeting';

const NextMeetingCard: React.FC<Props> = ({
  calendarToken,
  cycleName,
  meetingId,
  startDate,
}) => {
  const tNamedTranslation = useNamespacedTranslation(i18Path);
  const { t, i18n } = useTranslation();
  const { addSuccess } = useToasts();
  const formatDatetime = formattedDateTime(t);

  if (!startDate) {
    return null;
  }

  const dateTime = formatDatetime({
    dateTime: startDate,
    format: 'short',
  });

  const handleGoogleCalendar = () => {
    window.location.href = generateGoogleLink(
      generateFeedbackMeetingIcsLink(calendarToken, meetingId, i18n.language),
    );
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      generateFeedbackMeetingIcsLink(calendarToken, meetingId, i18n.language),
    );
    addSuccess(t('absences.icalendar.copiedIcsUrl'));
  };

  const handleDownloadIcs = () => {
    window.location.href = generateFeedbackMeetingIcsLink(
      calendarToken,
      meetingId,
      i18n.language,
    );
  };

  const dropDownActionBtn = (
    <Button className="d-flex-all-center gap-2 p-2">
      <FlairIcon icon="calendar-plus" size="xl" />
      <FlairIcon icon="chevron-down-outline" />
    </Button>
  );

  return (
    <FlairCard contentClassName="py-4 d-flex justify-content-between align-items-center">
      <div>
        <div className="h2-mini">{tNamedTranslation('title')}</div>
        <div className="meeting-additional-info">
          <span className="h4-medium">{dateTime}</span>{' '}
          <span className="h4-regular">
            {tNamedTranslation('forCycle', {
              cycleName,
            })}
          </span>
        </div>
      </div>
      <DropdownActions
        id="next-meeting-actions"
        icon={dropDownActionBtn}
        drop="down">
        <Dropdown.Item onClick={handleGoogleCalendar}>
          {tNamedTranslation('icalendar.addToGoogleCalendar')}
        </Dropdown.Item>
        <Dropdown.Item onClick={handleCopyUrl}>
          {tNamedTranslation('icalendar.copyToClipboard')}
        </Dropdown.Item>
        <Dropdown.Item onClick={handleDownloadIcs}>
          {tNamedTranslation('icalendar.downloadIcs')}
        </Dropdown.Item>
      </DropdownActions>
    </FlairCard>
  );
};

export default NextMeetingCard;
