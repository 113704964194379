import * as React from 'react';

const ExternalLinkOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M9.498 2.15113C9.22306 2.15113 8.99811 1.92743 8.99811 1.65125C8.99811 1.37506 9.22306 1.15137 9.498 1.15137H14.4968C14.7718 1.15137 14.9967 1.37506 14.9967 1.65125V6.65007C14.9967 6.92501 14.7718 7.14995 14.4968 7.14995C14.2219 7.14995 13.9969 6.92501 13.9969 6.65007V2.85815L6.85174 10.0024C6.65804 10.1992 6.33937 10.1992 6.14566 10.0024C5.94883 9.8087 5.94883 9.49003 6.14566 9.29632L13.2909 2.15113H9.498ZM1 3.65078C1 2.82254 1.6714 2.15113 2.49965 2.15113H6.4987C6.77364 2.15113 6.99858 2.37483 6.99858 2.65101C6.99858 2.9272 6.77364 3.1509 6.4987 3.1509H2.49965C2.22346 3.1509 1.99976 3.37584 1.99976 3.65078V13.6484C1.99976 13.9234 2.22346 14.1483 2.49965 14.1483H12.4973C12.7722 14.1483 12.9972 13.9234 12.9972 13.6484V9.64936C12.9972 9.37443 13.2221 9.14948 13.4971 9.14948C13.772 9.14948 13.9969 9.37443 13.9969 9.64936V13.6484C13.9969 14.4763 13.3252 15.1481 12.4973 15.1481H2.49965C1.6714 15.1481 1 14.4763 1 13.6484V3.65078Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ExternalLinkOutlineIcon;
