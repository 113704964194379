import React, { useContext } from 'react';
import InputLabel from '../../../../../../../components/form/InputLabel';
import { SelectDropdownControlled } from '../../../../../../../components/form/Selects';
import { TFunc } from '../../../../../../../hooks/useNamespacedTranslation';
import ServerError from '../../../../../../../components/ServerError';
import {
  TicketEmployeeOptionsQuery,
  useTicketEmployeeOptionsQuery,
  ManagerReportsType,
} from '../../../../../__generated__/graphql';
import { TicketModalContext } from '../TicketModalContext';
import { OptionBase } from '../../../../../../../components/Select/types';

type Props = {
  t: TFunc;
};
export const EmployeeFormField: React.FC<Props> = ({ t }) => {
  const { value, onChange: onFieldChange } = useContext(TicketModalContext);

  const { data, loading, error } = useTicketEmployeeOptionsQuery({
    variables: {
      managerReportsType: ManagerReportsType.Direct,
    },
  });
  const employeeList = mapEmployeeOptions(data);

  const handleEmployeeChange = (employeeId: string | null) => {
    if (employeeId) {
      const employee = employeeList.find((item) => item.value === employeeId);
      employee &&
        onFieldChange({
          preview: {
            ...value?.preview,
            employee: employee.label,
          },
          employee: employee.value,
        });
    }
  };

  if (error) return <ServerError />;

  return (
    <>
      <InputLabel label={t('form.fields.employee')} />
      <SelectDropdownControlled
        isLoading={loading}
        name="employee"
        options={employeeList}
        onValueChange={handleEmployeeChange}
        heightMode="large"
        placeholder={t('form.fields.employeePlaceholder')}
      />
    </>
  );
};

const mapEmployeeOptions = (
  data: TicketEmployeeOptionsQuery | undefined,
): OptionBase[] => {
  if (!data || !data.manager.employees?.length) {
    return [];
  }
  return data.manager.employees?.map(({ Id, Name }) => ({
    value: Id,
    label: Name,
  }));
};
