import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyStateCard from '../../../../components/EmptyStateCard';

export const EmptyEmployeeFeedbacks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateCard
      title={t('employeePage.performanceReview.emptyState.title')}
    />
  );
};
