import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useContext, useEffect } from 'react';
import { MentionEmployee } from '../types';
import { InnerModalContext } from '../../../../../context/InnerModalContext';
import { CommentContext } from '../Context/CommentContext';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Maybe } from '../../../../../utils/maybe';

const i18Path = 'comments';

const useEmployeeMentionSpansHook = (
  ref: React.RefObject<HTMLDivElement>,
  id: Maybe<string>,
  mentionedEmployees: MentionEmployee[],
  text: string,
) => {
  const { setIsInnerModalOpen } = useContext(InnerModalContext);
  const { setSelectedEmployee } = useContext(CommentContext);
  const t = useNamespacedTranslation(i18Path);

  // This is used to add onClick to mentioned employees span
  // And a tooltip for those who doesn't have access
  useEffect(() => {
    const spans = ref?.current?.querySelectorAll(
      `.mention[data-comment-id="${id}"]`,
    );

    spans?.forEach((span) => {
      const employeeId = span.getAttribute('data-employee-id');
      const employee = mentionedEmployees.find((e) => e.id === employeeId);

      if (!employeeId || !employee) return;

      const handleClick = () => {
        setSelectedEmployee(employee);
        setIsInnerModalOpen(true);
      };

      const attributes: Record<string, string> = {};
      for (const { name, value } of span.attributes) {
        attributes[name] = value;
      }

      let spanItem = (
        <span {...attributes} onClick={handleClick}>
          @{employee.name}
        </span>
      );

      if (!employee.hasAccess) {
        const tooltip = (
          <Tooltip id={`tooltip-${id}-${employeeId}`}>
            {t('cantAccess')}
          </Tooltip>
        );

        spanItem = (
          <OverlayTrigger key={employeeId} overlay={tooltip}>
            {spanItem}
          </OverlayTrigger>
        );
      }

      ReactDOM.render(spanItem, span);
    });
  }, [
    id,
    mentionedEmployees,
    ref,
    setIsInnerModalOpen,
    setSelectedEmployee,
    t,
    text,
  ]);
};

export default useEmployeeMentionSpansHook;
