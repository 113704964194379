import React, { forwardRef, useEffect, useRef } from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';

export const IndeterminateCheckbox = forwardRef(
  (
    { indeterminate, ...rest }: Partial<TableToggleAllRowsSelectedProps>,
    ref,
  ) => {
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const resolvedRef =
      (ref as React.MutableRefObject<HTMLInputElement | null>) || defaultRef;

    useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate as boolean;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        className="form-check-input"
        ref={resolvedRef}
        {...rest}
      />
    );
  },
);
