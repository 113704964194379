import React, { ElementType } from 'react';
import { Accordion } from 'react-bootstrap';
import { SectionHeaderToggle } from './SectionHeaderToggle';

type Props = {
  title: string | null;
  as?: ElementType;
  className?: string;
};
export const EmployeeFeedbackAccordion: React.FC<Props> = ({
  title,
  children,
  as,
  className = '',
}) => {
  const sectionKey = title ?? 'unknown-section';

  return (
    <Accordion defaultActiveKey={sectionKey} as="section" className={className}>
      <SectionHeaderToggle as={as} eventKey={sectionKey}>
        {title}
      </SectionHeaderToggle>
      <Accordion.Collapse eventKey={sectionKey}>
        <div>{children}</div>
      </Accordion.Collapse>
    </Accordion>
  );
};
