import { t } from 'i18next';

import { NotificationItemData, NotificationQueryItem } from '../types';
import { routes } from '../../../routes';

const i18prefix = 'notifications';

export const mapCelebrationNotificationRenderInfo = (
  src: NotificationQueryItem,
): NotificationItemData | null => {
  const data: NotificationItemData = {
    comment: '',
    urlLabel: '',
    url: '',
    description: '',
  };

  if (src.renderInfo.__typename === 'CelebrationNotificationRenderInfo') {
    // Check required fields. If not present, the notification won't be rendered.
    if (!src.renderInfo?.celebrationEmployeeRecipient?.celebration) {
      return null;
    }
    data.url = routes.celebrationsFeed.route.create({});
    data.urlLabel = t(`${i18prefix}.urlLabels.cheer`);
    data.description = t(`${i18prefix}.sentYou`);
    const celebration = src.renderInfo.celebrationEmployeeRecipient.celebration;
    data.employeeName = celebration.sender.Name;
    const employeeFirstName = celebration.sender.flair__First_Name__c;
    data.employeeFirstName =
      employeeFirstName === null ? undefined : employeeFirstName;
    const employeeLastName = celebration.sender?.flair__Last_Name__c;
    data.employeeLastName =
      employeeLastName === null ? undefined : employeeLastName;
    const avatarUrl = celebration.sender?.avatar?.url;
    data.avatarUrl = avatarUrl === null ? undefined : avatarUrl;
  }

  return data;
};
