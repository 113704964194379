import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { MultiValue, Props as ReactSelectProps } from 'react-select';
import { FilterSelectOption } from '../../../../../../../atomic/molecules/FlairDropdown/types';
import { useQueryParams } from '../../../../../../../hooks/useQueryParams';
import { split } from '../../../../../../../utils/string';
import { routes } from '../../../../../routes';
import { TeamCalendarFiltersKeys, TeamCalendarFiltersType } from './types';
import { FlairMultiSelectDropdown } from '../../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';

type Props = Omit<ReactSelectProps<FilterSelectOption, true>, 'options'> & {
  options: FilterSelectOption[];
  filterData: TeamCalendarFiltersType;
  filterKey: TeamCalendarFiltersKeys;
};

const TeamCalendarMultipleFilter: React.FC<Props & ReactSelectProps> = ({
  options,
  filterData,
  filterKey,
  ...rest
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const selectedOptionsString = filterData[filterKey];
  const selectedOptionsAsArray = useMemo(
    () => split(selectedOptionsString),
    [selectedOptionsString],
  );
  const handleChange = (selectedOptions: MultiValue<FilterSelectOption>) => {
    history.push(
      routes.teamCalendar.route
        .withQueryParams({
          ...queryParams,
          [filterKey]: selectedOptions.map((o) => o.value).join(','),
        })
        .create({}),
    );
  };
  return (
    <FlairMultiSelectDropdown
      options={options}
      selectedOptions={selectedOptionsAsArray}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default TeamCalendarMultipleFilter;
