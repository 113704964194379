import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../components/button';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import UploadFile from '../../../../components/UploadFile';
import { useToasts } from '../../../../context/Toast';
import { AvatarIcon } from './AvatarIcon';
import './EmployeeAvatar.css';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { FILE_SIZE_LIMIT } from '../../../../utils/file';

const acceptExtensions = '.png,.jpg';

export type Props = {
  show: boolean;
  avatarUrl: string | null;
  updateInProgress: boolean;
  onClose: () => void;
  onUpdate: (file?: File) => void;
};

type SelectedAvatar = {
  file: File;
  dataUrl: string;
};

const i18prefix = 'employeeAvatarInput.modal';

export const EmployeeAvatarInputModal: React.FC<Props> = ({
  show,
  onClose,
  avatarUrl,
  onUpdate,
  updateInProgress,
}) => {
  const t = useNamespacedTranslation(i18prefix);
  const { addError } = useToasts();
  const [selectedAvatar, setSelectedAvatar] = useState<SelectedAvatar | null>(
    null,
  );
  const [avatarToShowUrl, setAvatarToShowUrl] = useState<string | null>(
    avatarUrl,
  );

  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setSelectedAvatar(null);
      setIsChanged(false);
      setAvatarToShowUrl(avatarUrl ?? null);
    }
  }, [show, setSelectedAvatar, avatarUrl]);

  const handleDeleteAvatar = () => {
    setSelectedAvatar(null);
    setAvatarToShowUrl(null);
    setIsChanged(true);
  };

  const handleSaveClick = useCallback(() => {
    onUpdate(selectedAvatar?.file);
  }, [selectedAvatar, onUpdate]);

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      const file = fileList[0];
      if (file.size > FILE_SIZE_LIMIT) {
        addError(t('sizeLimitError'));
      } else {
        const reader = new FileReader();

        reader.addEventListener(
          'load',
          () => {
            if (typeof reader.result === 'string') {
              setSelectedAvatar({
                file,
                dataUrl: reader.result as string,
              });
              setAvatarToShowUrl(reader.result);
            }
          },
          false,
        );

        if (file) {
          reader.readAsDataURL(file);
        }

        setIsChanged(true);
      }
    },
    [addError, t],
  );

  const getContent = () => {
    return (
      <div className={classNames('d-flex', 'flex-column', 'gap-5')}>
        <div className={classNames('d-flex', 'justify-content-center')}>
          {avatarToShowUrl && (
            <>
              <AvatarIcon size="large" avatarUrl={avatarToShowUrl} />
              <div
                className="rounded-circle delete-button cu-pointer d-flex justify-content-center  align-items-center"
                onClick={handleDeleteAvatar}>
                <FlairIcon icon="trash-outline" size="lg" />
              </div>
            </>
          )}

          {!avatarToShowUrl && (
            <UploadFile
              acceptExtensions={acceptExtensions}
              onChange={handleFileChange}
              content={
                <div className="upload-container empty-container rounded-circle d-flex justify-content-center  align-items-center">
                  <div className="rounded-circle m-auto aling-items-center text-center mx-4 h5-regular">
                    <FlairIcon icon="person-circle-outline" size="xl" />
                    <div className="my-2">
                      {t('dragNdrop')}
                      <span className="h5-medium cu-pointer flair-primary-color">
                        {t('orChoose')}
                      </span>
                    </div>
                    <div> {t('formatText')}</div>
                  </div>
                </div>
              }
            />
          )}
        </div>

        <div className="m-auto aling-items-center gap-3">
          <Button
            onClick={onClose}
            variant="outline-primary"
            label={t('cancel')}
            className="mx-2"
          />
          <Button
            onClick={handleSaveClick}
            isProcessing={updateInProgress}
            label={t('save')}
            className="mx-2"
            disabled={!isChanged}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      size="sm"
      data-testid="employee-avatar-input-modal">
      <Modal.Header closeButton className="card-header no-border-bottom">
        <div>
          <h2 className="card-header-title text-center">{t('title')}</h2>
        </div>
      </Modal.Header>
      <Modal.Body>{getContent()}</Modal.Body>
    </Modal>
  );
};
