import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalSidebar from '../../../components/ModalSidebar';
import { Project, ProjectViewMode } from '../types';
import { ProjectDetailsContent } from './ProjectDetailsContent';

type Props = {
  viewMode: ProjectViewMode;
  project: Project | null;
  timeTracked: number;
  onClose: () => void;
};

export const ProjectDetailsSidebar: React.FC<Props> = ({
  onClose,
  project,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ModalSidebar
      show={!!project}
      onClose={onClose}
      header={t('timeTracking.projects.sidebar.title')}
      content={
        project && (
          <ProjectDetailsContent
            project={project}
            {...rest}
            onClose={onClose}
          />
        )
      }
    />
  );
};
