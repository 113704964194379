import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import RedirectToHome from '../../components/RedirectToHome';
import { useManagerReportsMode } from '../../hooks/useManagerReportsMode';
import { ManagerReportsModeSwitch } from '../components/ManagerReportsModeSwitch';
import { AbsenceRequestNav } from './AbsenceRequestNav';
import { routes } from './routes';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

const Content: React.FC = () => (
  <Switch>
    {Object.values(routes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);

const i18Path = 'absences';
export const AbsenceRequestsTabs: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const [reportsMode, setReportsMode] = useManagerReportsMode('absences');

  useFlairBreadcrumbHook([
    { label: t('title') },
    { label: t('subMenuItems.absenceRequests.title') },
  ]);

  return (
    <>
      <PageHeader
        subtitle={t('title')}
        title={t('subMenuItems.absenceRequests.title')}
        actions={
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        }>
        <AbsenceRequestNav />
      </PageHeader>
      <Content />
    </>
  );
};
