import { useState } from 'react';

export const useDocumentView = () => {
  const [previewVisible, setDocumentPreview] = useState<boolean>(false);
  const [sidebarVisible, setDocumentSidebar] = useState<boolean>(false);

  const handlePreviewClose = () => {
    setDocumentPreview(false);
  };

  const handleSideBarClose = () => {
    setDocumentSidebar(false);
  };

  const handleDownload = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return {
    previewVisible,
    setDocumentPreview,
    sidebarVisible,
    setDocumentSidebar,
    handlePreviewClose,
    handleSideBarClose,
    handleDownload,
  };
};
