import React from 'react';
import Skeleton from 'react-loading-skeleton';

const InventoryFiltersLoading: React.FC = () => (
  <div className="d-none d-md-flex flex-row justify-content-between">
    <div className="d-flex align-items-center">
      <Skeleton width={200} height={27} />
    </div>
    <div className="d-md-flex">
      <div className="me-3 mt-2">
        <Skeleton width={50} height={16} />
      </div>
      <div className="me-3">
        <Skeleton width={90} height={27} />
      </div>
      <div className="me-3">
        <Skeleton width={90} height={27} />
      </div>
      <div className="me-3">
        <Skeleton width={74} height={27} />
      </div>
      <div className="me-3">
        <Skeleton width={74} height={27} />
      </div>
      <div className="me-3">
        <Skeleton width={74} height={27} />
      </div>
      <div className="d-flex align-items-center">
        <Skeleton width={65} height={16} />
      </div>
    </div>
  </div>
);

export default InventoryFiltersLoading;
