import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { TableContent } from '../../../../manager/components/TableContent';

type Props = {
  categories: string[];
  absenceRates: number[];
};

type TableItem = {
  id: string;
  [key: string]: any;
};

export const AbsenceRateTable: React.FC<Props> = ({
  categories,
  absenceRates,
}) => {
  const columns: Column[] = useMemo(() => {
    return categories.map((category, index) => ({
      Header: category,
      accessor: `month${index}`,
    }));
  }, [categories]);

  const data = useMemo(() => {
    const rowData: TableItem = { id: 'absenceRates' };

    absenceRates.forEach((rate, index) => {
      rowData[`month${index}`] = rate + '%';
    });
    return [rowData];
  }, [absenceRates]);

  return <TableContent columns={columns} data={data} />;
};
