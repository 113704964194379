import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUserInfo } from '../../../../context/UserInfo';
import { useIsManagerMode } from '../../../../hooks/useIsManagerMode';
import { ManagerRole } from '../../../../__generated__/graphql';
import { canRateGoal } from '../logic';
import { GoalVisibility, GoalType, allGoalStatuses } from '../types';
import {
  AutoGoalInfo,
  GoalSidebarMode,
  GoalStatusEx,
  GoalStatusWithRate,
} from './types';

export const useCommonFieldsLogic = (
  mode: GoalSidebarMode,
  ownerId: string,
) => {
  const { watch, formState, setValue, getValues } = useFormContext();
  const userInfo = useUserInfo();
  const { hasManagerRole } = useIsManagerMode();

  const visibility = watch('visibility') as GoalVisibility;
  const timePeriodId = watch('timePeriodId') as string | null;
  const goalType = watch('type') as GoalType;
  const isManual = watch('manualProgress');
  const goalOnTrackAutoState: undefined | boolean = watch(
    'goalOnTrackAutoState',
  );

  const autoGoalInfo: AutoGoalInfo = {
    isAuto: !isManual,
    goalOnTrackAutoState,
  };

  /// Some Custom Logic during goal creation

  // when isManual is changed
  useEffect(() => {
    if (mode !== 'create') {
      return;
    }
    // getValues do not trigger re-renders
    const curStatusWithRate: GoalStatusWithRate = getValues('statusWithRate');
    //const employeeId: string | undefined =  getValues('statusWithRate');
    if (isManual && curStatusWithRate.status === 'BEHIND_OR_ON_TRACK') {
      // we do not support this value in manual mode, so better drop
      setValue('statusWithRate', { ...curStatusWithRate, status: 'BEHIND' });
    }
    if (
      !isManual &&
      (curStatusWithRate.status === 'BEHIND' ||
        curStatusWithRate.status === 'ON_TRACK')
    ) {
      // we do not support these values in auto mode, so better drop
      setValue('statusWithRate', {
        ...curStatusWithRate,
        status: 'BEHIND_OR_ON_TRACK',
      });
    }
  }, [mode, isManual, setValue, getValues]);

  /// End of Some Custom Logic during goal creation

  const availableStatuses = useMemo((): GoalStatusEx[] => {
    if (isManual) {
      return allGoalStatuses;
    }
    if (mode === 'create' || mode === 'update') {
      // all disabled
      return ['DRAFT', 'BEHIND_OR_ON_TRACK', 'DONE', 'CANCELED'];
    }
    return [];
  }, [mode, isManual]);

  const readonly = mode === 'view' || formState.isSubmitting;

  const isManualProgressVisible =
    goalType === 'team' || goalType === 'employee';
  const isGoalAlignmentVisible = goalType === 'team' || goalType === 'employee';

  const isManualProgressEnabled = mode === 'create';

  const rateEnabled =
    mode === 'create' ||
    (mode === 'update' &&
      canRateGoal(
        {
          id: userInfo.id,
          isDirectManager: hasManagerRole(ManagerRole.DirectManager),
        },
        ownerId,
      ));

  return {
    readonly,
    availableStatuses,
    isManualProgressVisible,
    isGoalAlignmentVisible,
    isManualProgressEnabled,
    visibility,
    timePeriodId,
    goalType,
    rateEnabled,
    autoGoalInfo,
  };
};
