import { addMonths } from 'date-fns';
import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  cloneDate,
  getMonthName,
  isSameOrAfter,
  isSameOrBefore,
} from '../../utils/dateUtils';
import Button from '../button';

type Props = {
  minMonth?: Date;
  maxMonth?: Date;
  currentMonth: Date;
  onMonthChanged: (newValue: Date) => void;
};
const MonthNav: React.FC<Props> = ({
  currentMonth,
  minMonth,
  maxMonth,
  onMonthChanged,
}) => {
  return (
    <div>
      <ButtonGroup size="sm" className="bg-gray rounded p-1">
        <Button
          disabled={
            minMonth && isSameOrBefore(currentMonth, minMonth, { unit: 'day' })
          }
          label="←"
          variant="gray"
          onClick={() =>
            onMonthChanged &&
            onMonthChanged(addMonths(cloneDate(currentMonth), -1))
          }
        />
        <Button
          variant="white"
          size="sm"
          className="rounded"
          label={getMonthName(currentMonth)}
        />
        <Button
          disabled={
            maxMonth && isSameOrAfter(currentMonth, maxMonth, { unit: 'day' })
          }
          label="→"
          variant="gray"
          onClick={() =>
            onMonthChanged &&
            onMonthChanged(addMonths(cloneDate(currentMonth), 1))
          }></Button>
      </ButtonGroup>
    </div>
  );
};

export default MonthNav;
