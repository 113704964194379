import React from 'react';
import {
  OverlayTrigger,
  OverlayTriggerProps,
  Popover,
  Tooltip,
} from 'react-bootstrap';

import FlairIcon, {
  FlairIconName,
  SizeProp,
} from '../../atomic/atoms/FlairIcon';

type Params = Pick<OverlayTriggerProps, 'placement'> & {
  id: string;
  text: string | React.ReactNode;
  mode?: 'tooltip' | 'popover';
  children?: OverlayTriggerProps['children'];
  containerClassName?: string;
  CustomOverlay?: React.ElementType;
  flairIconName?: FlairIconName;
  flairIconSize?: SizeProp;
};

const Hint: React.FC<Params> = ({
  id,
  text,
  children,
  mode = 'tooltip',
  placement = 'top',
  containerClassName,
  CustomOverlay,
  flairIconName,
  flairIconSize = 'sm',
}) => {
  const getOverlay = () => {
    if (CustomOverlay) {
      return (
        <CustomOverlay id={`overlay-${id}`}>
          <span className="line-break">{text}</span>
        </CustomOverlay>
      );
    }
    switch (mode) {
      case 'tooltip':
        return (
          <Tooltip id={`tooltip-${id}`}>
            <span className="line-break">{text}</span>
          </Tooltip>
        );
      case 'popover':
        return (
          <Popover id={`popover-${id}`}>
            <Popover.Body>
              <span className="line-break">{text}</span>
            </Popover.Body>
          </Popover>
        );
    }
  };

  return (
    <OverlayTrigger
      key={`top-id-${id}`}
      placement={placement}
      overlay={getOverlay()}>
      <span className={containerClassName}>
        {children ? (
          children
        ) : (
          <FlairIcon
            icon={flairIconName || 'question-circle-outline'}
            size={flairIconSize}
          />
        )}
      </span>
    </OverlayTrigger>
  );
};

export default Hint;
