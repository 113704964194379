import * as React from 'react';
import Button, { ButtonProps } from '../../../components/button';
import { getClassName } from './logic';
import classNames from 'classnames';
import './FlairButton.css';

export type FlairButtonVariants = 'primary';

export type FlairButtonProps = ButtonProps & {
  variant?: FlairButtonVariants;
};

const FlairButton = React.forwardRef<HTMLButtonElement, FlairButtonProps>(
  (props, ref) => {
    const { variant = 'primary', iconSize = 'xl' } = props;
    const className = getClassName(variant);

    return (
      <Button
        {...props}
        iconSize={iconSize}
        className={classNames(props.className, className, 'flair-btn')}
        ref={ref}
      />
    );
  },
);

export default FlairButton;
