import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import StartTimeTrackingButton from '../../Dashboard/TimeTrackingWidget/StartTimeTrackingButton';

const i18Path = 'timeTracking';

const StartTimeTracking: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <Row className="text-center">
      <Col>
        <h3>{t('current.emptyState')}</h3>
        <StartTimeTrackingButton />
      </Col>
    </Row>
  );
};

export default StartTimeTracking;
