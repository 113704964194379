import React from 'react';
import { Card } from 'react-bootstrap';
import { times } from 'lodash';
import { useTranslation } from 'react-i18next';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';

type Props = {
  pageCount: number;
  pageIndex: number;
  previousPage: () => void;
  nextPage: () => void;
  goToPage: (index: number) => void;
};

const PaginationTab: React.FC = ({ children }) => (
  <ul className="pagination pagination-tabs card-pagination">{children}</ul>
);
const PageItem: React.FC = ({ children }) => (
  <li className="page-item">{children}</li>
);
const PageLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  className,
  children,
  onClick,
  ...rest
}) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    className={['page-link', className].join(' ')}
    href=""
    onClick={(e) => {
      if (onClick) {
        e.preventDefault();
        onClick(e);
      }
    }}
    {...rest}>
    {children}
  </a>
);

const PaginationPrevious: React.FC<Pick<Props, 'previousPage'>> = ({
  previousPage,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTab>
      <PageItem>
        <PageLink
          className="ps-0 pe-4 border-end d-flex align-items-center"
          onClick={previousPage}>
          <FlairIcon icon="arrow-back-outline" className="me-2" />
          {t('pagination.prev')}
        </PageLink>
      </PageItem>
    </PaginationTab>
  );
};

const PaginationNext: React.FC<Pick<Props, 'nextPage'>> = ({ nextPage }) => {
  const { t } = useTranslation();

  return (
    <PaginationTab>
      <PageItem>
        <PageLink
          className="pe-0 ps-4 border-start d-flex align-items-center"
          onClick={nextPage}>
          {t('pagination.next')}
          <FlairIcon
            icon="arrow-back-outline"
            className="ms-2"
            style={{ transform: 'rotate(180deg)' }}
          />
        </PageLink>
      </PageItem>
    </PaginationTab>
  );
};

const Page: React.FC<Pick<Props, 'goToPage'> & { pageIndex: number }> = ({
  goToPage,
  pageIndex,
}) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    className="page"
    href=""
    onClick={(e) => {
      e.preventDefault();
      goToPage(pageIndex);
    }}>
    {pageIndex + 1}
  </a>
);

const Pages: React.FC<Pick<Props, 'pageCount' | 'pageIndex' | 'goToPage'>> = ({
  pageCount,
  pageIndex,
  goToPage,
}) => (
  <PaginationTab>
    {times(pageCount, (i) => (
      <li className={i === pageIndex ? 'active' : ''} key={i}>
        <Page goToPage={goToPage} pageIndex={i} />
      </li>
    ))}
  </PaginationTab>
);

const Pagination: React.FC<Props> = ({
  pageCount,
  pageIndex,
  nextPage,
  previousPage,
  goToPage,
}) => {
  return (
    <Card.Footer
      style={{ overflow: 'auto' }}
      className="d-flex justify-content-between">
      <PaginationPrevious previousPage={previousPage} />
      <Pages pageCount={pageCount} pageIndex={pageIndex} goToPage={goToPage} />
      <PaginationNext nextPage={nextPage} />
    </Card.Footer>
  );
};

export default Pagination;
