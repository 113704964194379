import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import SkeletonCard from '../../../components/Skeleton/Card';
import { useEmployeeSkills } from '../common/useEmployeeAvailableSkills';
import { mapChartSkill } from './mappings';
import { SkillsRadarChart } from './SkillsRadarChart';

type Props = {
  employeeId: string;
};

export const SkillsRadarChartConnected: React.FC<Props> = ({ employeeId }) => {
  const { data, loading, error } = useEmployeeSkills(employeeId);
  const t = useNamespacedTranslation('skills.employeePage');

  const chartSkills = useMemo(
    () =>
      data?.activeEmployees
        .flatMap((x) => x.employeeSkills)
        .filter((s) => s.flair__Rate__c && s.flair__Top__c)
        .map(mapChartSkill),
    [data],
  );

  if (error) {
    return <ServerError />;
  }
  if (loading || !chartSkills) {
    return <SkeletonCard height={250} />;
  }

  if (chartSkills.length < 3) {
    return (
      <EmptyContainer className="small text-center">
        {t('chartNotEnoughSkills')}
      </EmptyContainer>
    );
  }

  return (
    <div>
      <SkillsRadarChart skills={chartSkills} />
    </div>
  );
};

const EmptyContainer = styled.div({
  width: '100%',
  height: '250px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
