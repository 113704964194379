import i18next from 'i18next';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import * as yup from 'yup';
import CancelButton from '../../../../../components/form/CancelButton';
import { FormGroup } from '../../../../../components/form/FormGroup';
import InputLabel from '../../../../../components/form/InputLabel';
import SubmitButton from '../../../../../components/form/SubmitButton';
import { ControlledSwitchInput } from '../../../../../components/form/SwitchInput';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { DEFAULT_IMAGE_ID } from '../images';
import { getCelebrationType } from '../mappings';
import { PredefinedImageInputControlled } from './PredefinedImageInputControlled';
import {
  CelebrationMessage,
  MAX_MESSAGE_LENGTH,
} from './CelebrationMessageInput';
import { CelebrationMessageInputControlled } from './CelebrationMessageInputControlled';
import { useCreateCelebration } from '../useCreateCelebration';
import { SelectMultipleEmployeeOrTeamConnectedControlled } from '../../../../../components/form/Selects/SelectMultipleEmployeeOrTeamConnectedControlled';
import { CelebrationRecipientType } from '../../../__generated__/graphql';
import { EmployeeOrTeamOption } from '../../../components/Selects/EmployeeOrTeamOptionLabel';
import { useUpdateCelebration } from '../useUpdateCelebration';
import { Celebration, CelebrationRecipient, CreateCelebration } from '../types';

type Props = {
  celebration?: Celebration | CreateCelebration | null;
  onClose: () => void;
  onSuccess?: () => void;
};

const i18nPrefix = 'cheers.form';

type FormData = {
  recipients: EmployeeOrTeamOption[];
  celebrationMessage: CelebrationMessage;
  public: boolean;
  predefinedImageId: string;
};

const defaultValues: FormData = {
  recipients: [],
  celebrationMessage: {
    message: '',
    loomVideo: null,
  },
  public: false,
  predefinedImageId: DEFAULT_IMAGE_ID,
};

const validationSchema = yup.object().shape<Partial<FormData>>({
  //todo: fix error message
  recipients: yup
    .array<EmployeeOrTeamOption>()
    .required(i18next.t(`${i18nPrefix}.employeeOrTeamRequiredMessage`)),
  celebrationMessage: yup
    .object<CelebrationMessage>()
    .test(
      'message-required',
      i18next.t(`${i18nPrefix}.messageRequiredMessage`),
      (value: CelebrationMessage) => {
        return value.message?.length > 0;
      },
    )
    .test(
      'message-max-length',
      i18next.t(`${i18nPrefix}.messageMaxMessage`),
      (value: CelebrationMessage) => {
        if (!value.message?.length) {
          return true;
        }
        return value.message.length <= MAX_MESSAGE_LENGTH;
      },
    ),
});

export const CelebrationForm: React.FC<Props> = ({
  celebration,
  onClose,
  onSuccess,
}) => {
  const t = useNamespacedTranslation(i18nPrefix);
  const isUpdateMode = celebration && celebration.id;
  const [createCelebration] = useCreateCelebration();
  const [updateCelebration] = useUpdateCelebration();
  const errorHandler = useMutationErrorHandler();
  const { addSuccess, addError } = useToasts();

  const mapRecipientsToForm = (
    recipients: CelebrationRecipient[] | undefined,
  ): EmployeeOrTeamOption[] => {
    return (
      recipients?.map((recipient) => {
        if (recipient.type === 'employee') {
          return {
            type: 'employee',
            avatarUrl: recipient.employee?.avatarUrl,
            initials: recipient.employee?.initials,
            label: recipient.employee?.name,
            value: recipient.employee?.id,
          };
        } else {
          return {
            type: 'team',
            label: recipient.team?.name,
            value: recipient.team?.id,
          };
        }
      }) ?? []
    );
  };

  const mapRecipientsToInput = (recipients: EmployeeOrTeamOption[]) => {
    return recipients.map((recipient) => ({
      id: recipient.value,
      type:
        recipient.type === 'employee'
          ? CelebrationRecipientType.Employee
          : CelebrationRecipientType.Team,
    }));
  };

  const formValues: FormData = {
    recipients: mapRecipientsToForm(celebration?.recipients),
    celebrationMessage: {
      message: celebration?.message || defaultValues.celebrationMessage.message,
      loomVideo:
        (celebration?.loomVideo && {
          loomPublicAppId: '',
          loomId: '',
          embedUrl: celebration.loomVideo.embedUrl,
          sharedUrl: celebration.loomVideo.sharedUrl,
          width: celebration.loomVideo.width,
          height: celebration.loomVideo.height,
        }) ||
        defaultValues.celebrationMessage.loomVideo,
    },
    public: celebration?.isPublic || defaultValues.public,
    predefinedImageId: celebration?.type || defaultValues.predefinedImageId,
  };

  const initialValues = formValues ?? defaultValues;
  const form = useForm<FormData>({
    defaultValues: initialValues,
    validationSchema,
  });
  const { handleSubmit, watch, triggerValidation, errors } = form;

  const hasAnyErrors = Object.values(errors).length > 0;

  const celebrationMessage = watch('celebrationMessage');

  useEffect(() => {
    if (celebrationMessage.message.length > 0) {
      triggerValidation('celebrationMessage');
    }
  }, [triggerValidation, celebrationMessage.message]);

  const doSubmitCreate = (formData: FormData): Promise<void> => {
    return createCelebration({
      variables: {
        input: {
          message: formData.celebrationMessage.message || '',
          loomVideo: formData.celebrationMessage.loomVideo,
          recipients: mapRecipientsToInput(formData.recipients),
          public: formData.public,
          type: getCelebrationType(formData.predefinedImageId),
          predefinedImage: formData.predefinedImageId,
        },
      },
    })
      .then((r) => {
        if (r.data?.celebrations.createCelebration.error) {
          addError(r.data?.celebrations.createCelebration.error);
          return;
        }
        addSuccess(t('create.success'));
        onSuccess && onSuccess();
        onClose();
      })
      .catch(errorHandler);
  };

  const doSubmitUpdate = (formData: FormData): Promise<void> => {
    return updateCelebration({
      variables: {
        input: {
          id: celebration?.id || '',
          message: formData.celebrationMessage.message || '',
          loomVideo: formData.celebrationMessage.loomVideo,
          recipients: mapRecipientsToInput(formData.recipients),
          public: formData.public,
          type: getCelebrationType(formData.predefinedImageId),
          predefinedImage: formData.predefinedImageId,
        },
      },
    })
      .then((r) => {
        if (r.data?.celebrations.updateCelebration.error) {
          addError(r.data?.celebrations.updateCelebration.error);
          return;
        }
        addSuccess(t('update.success'));
        onSuccess && onSuccess();
        onClose();
      })
      .catch(errorHandler);
  };

  const doSubmit = isUpdateMode ? doSubmitUpdate : doSubmitCreate;

  return (
    <FormContext {...form}>
      <form onSubmit={handleSubmit(doSubmit)}>
        <FormGroup>
          <PredefinedImageInputControlled name="predefinedImageId" />
        </FormGroup>
        <FormGroup>
          <InputLabel label={t('employeeLabel')} />
          <SelectMultipleEmployeeOrTeamConnectedControlled name="recipients" />
        </FormGroup>
        <FormGroup>
          <CelebrationMessageInputControlled name="celebrationMessage" />
        </FormGroup>
        <FormGroup>
          <InputLabel label={t('publicLabel')} />
          <div className="mb-2 text-dark">
            <small>{t('publicDescription')}</small>
          </div>
          <small>{t('publicCheckboxLabel')}</small>
          <ControlledSwitchInput
            id="public-switch"
            name="public"
            className="d-inline-block ms-2"
          />
        </FormGroup>
        <div className="d-flex justify-content-end mt-2">
          <CancelButton className="me-2" onClick={onClose} />
          <SubmitButton size="sm" disabled={hasAnyErrors}>
            {isUpdateMode ? t('update.sendCheerBtn') : t('create.sendCheerBtn')}
          </SubmitButton>
        </div>
      </form>
    </FormContext>
  );
};
