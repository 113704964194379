import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/l10n/de';
import 'flatpickr/dist/l10n/fr';
import 'flatpickr/dist/l10n/it';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/l10n/ru';
import flatpickr from 'flatpickr';

const locale = flatpickr.l10ns.en;

if (locale) {
  locale.firstDayOfWeek = 1; // Monday
}
