import React from 'react';
import { Absence } from '../../__generated__/graphql';
import AbsenceDateTime from '../../pages/Absences/components/AbsenceDateTime';
import moment from 'moment';
import { DateFormat } from '../../../../components/date/FormattedDate';
import { DateTimeFormat } from '../../../../components/datetime/FormattedDateTime';

type AbsenceProps = Pick<
  Absence,
  | 'flair__Type__c'
  | 'flair__Start_Date__c'
  | 'flair__Start_Date_Half_Day__c'
  | 'flair__Start_Time__c'
  | 'flair__End_Date__c'
  | 'flair__End_Date_Half_Day__c'
  | 'flair__End_Time__c'
>;

type Props = {
  absence: AbsenceProps;
  dateFormat?: DateFormat;
  dateTimeFormat?: DateTimeFormat;
};

const AbsenceDateTimeRange: React.FC<Props> = ({
  absence,
  dateFormat = 'compact',
  dateTimeFormat = 'compact',
}) => {
  const startDateTimeProps = {
    date: absence.flair__Start_Date__c,
    time: absence.flair__Start_Time__c,
    type: absence.flair__Type__c,
    halfDay: absence.flair__Start_Date_Half_Day__c,
    dateFormat,
    dateTimeFormat,
  };

  const endDateTimeProps = {
    date: absence.flair__End_Date__c,
    time: absence.flair__End_Time__c,
    type: absence.flair__Type__c,
    halfDay: absence.flair__End_Date_Half_Day__c,
    dateFormat,
    dateTimeFormat: moment(absence.flair__Start_Date__c).isSame(
      absence.flair__End_Date__c,
      'day',
    )
      ? 'time'
      : dateTimeFormat,
  };

  return (
    <>
      <AbsenceDateTime {...startDateTimeProps} />
      &nbsp;-&nbsp;
      <AbsenceDateTime {...endDateTimeProps} />
    </>
  );
};

export default AbsenceDateTimeRange;
