import * as React from 'react';
import FlairBreadcrumb from '../../../../../atomic/molecules/FlairBreadcrumb';
import { useContext } from 'react';
import { FlairBreadcrumbContext } from '../../../../../context/FlairBreadcrumbContext';

const TopNavBreadcrumb: React.FC = () => {
  const { breadcrumbItems } = useContext(FlairBreadcrumbContext);
  return <FlairBreadcrumb items={breadcrumbItems} />;
};

export default TopNavBreadcrumb;
