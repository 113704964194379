import { getApiBaseUrl } from '../../../../utils/config';

const STORAGE_KEY_LOOM_JWT = 'loom-jwt';

const GAP_BEFORE_EXPIRED_SECONDS = 30 * 60;

type LoomJwtTokenInfo = {
  loomAppId: string;
  expiredAt: Date;
};

export const getLoomJwtToken = async (options: {
  apiToken: string;
}): Promise<string> => {
  let jwtToken: string | null = localStorage.getItem(STORAGE_KEY_LOOM_JWT);
  if (jwtToken == null || isExpired(jwtToken)) {
    jwtToken = await getTokenFromServer(options.apiToken);
    localStorage.setItem(STORAGE_KEY_LOOM_JWT, jwtToken);
  }
  return jwtToken;
};

const getTokenFromServer = (apiToken: string): Promise<string> => {
  const url = getApiBaseUrl() + '/loom/jwt';
  return fetch(url, {
    headers: {
      Authorization: apiToken,
    },
  }).then((resp) => resp.text());
};

const isExpired = (jwtToken: string): boolean => {
  const tokenInfo = getLoomJwtTokenInfo(jwtToken);
  if (tokenInfo === null) {
    return true;
  }
  const now = new Date();
  if (
    now.getTime() >=
    tokenInfo.expiredAt.getTime() - GAP_BEFORE_EXPIRED_SECONDS * 1000
  ) {
    return true;
  }
  return false;
};

export const getLoomJwtTokenInfo = (
  jwtToken: string,
): LoomJwtTokenInfo | null => {
  const payload = parseJwtPayload(jwtToken);
  if (!payload) {
    return null;
  }
  return {
    loomAppId: payload['iss'],
    expiredAt: new Date(payload['exp']),
  };
};

const parseJwtPayload = (jwtToken: string): Record<any, any> | null => {
  try {
    return JSON.parse(atob(jwtToken.split('.')[1]));
  } catch (e) {
    return null;
  }
};
