import { SortingRule } from 'react-table';
import { AscDescEnum, SortInput } from '../../../__generated__/graphql';

export const mapSortBy = (sortBy: SortingRule<unknown>[]): SortInput | null => {
  if (sortBy.length === 0) {
    return null;
  }

  let field = null;
  switch (sortBy[0].id) {
    case 'name':
      field = 'flair__Full_Name__c';
      break;
    case 'applicationDate':
      field = 'CreatedDate';
      break;
    case 'score':
      field = 'flair__Final_Score__c';
      break;
    case 'funnelStage':
      field = 'flair__Funnel_Stage__c';
      break;
    case 'applicationSource':
      field = 'flair__Application_Source__c';
      break;
  }

  if (field) {
    return {
      field,
      order: sortBy[0].desc ? AscDescEnum.Desc : AscDescEnum.Asc,
    };
  }

  return null;
};
