import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Employee } from '..';
import { EmployeeSkillsCard } from '../../../pages/Skills';
import { EmployeeFeedbacks } from './EmployeeFeedbacks';

type Props = {
  employee: Employee;
};

export const EmployeePerformaceReview: React.FC<Props> = ({ employee }) => {
  return (
    <Row>
      <Col lg={{ span: 4, order: 'last' }}>
        <EmployeeSkillsCard
          employeeId={employee.Id}
          employeeName={employee.Name}
        />
      </Col>
      <Col lg={8}>
        <EmployeeFeedbacks employee={employee} />
      </Col>
    </Row>
  );
};
