import React from 'react';

import { useArchiveNotification } from './useArchiveNotification';
import Button from '../../../../../../../components/button';
import { NotificationItem } from '../../../types';

const ArchiveAction: React.FC<{
  notification: NotificationItem;
}> = ({ notification }) => {
  const [archiveNotification] = useArchiveNotification();
  return (
    <Button
      variant="link"
      label="Archive"
      className="py-0 pe-0"
      onClick={() => {
        archiveNotification({
          variables: {
            id: notification.id,
          },
        });
      }}
    />
  );
};
export default ArchiveAction;
