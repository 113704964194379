import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';

type Props = {
  startLabel: string;
  endLabel: string;
};

const DashedLine = styled.div({
  flexGrow: 1,
  borderBottom: `dashed 1px ${Theme.color.paper3}`,
});

export const RangeDashedHint: React.FC<Props> = ({ startLabel, endLabel }) => {
  return (
    <>
      {!!startLabel && !!endLabel && (
        <div className="d-flex justify-content-between align-items-center mt-2 small text-dark">
          <div className="pe-4">{startLabel}</div>
          <DashedLine />
          <div className="ps-4">{endLabel}</div>
        </div>
      )}
    </>
  );
};
