import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import PageHeader from '../../components/PageHeader';
import { useManagerReportsMode } from '../../hooks/useManagerReportsMode';
import { ManagerReportsModeSwitch } from '../components/ManagerReportsModeSwitch';

import GlobalTableFilter from '../../components/GlobalTableFilter';
import { TimeSheetNavigationConnected } from './Navigation/TimeSheetNavigationConnected';
import _ from 'lodash';
import { CurrentTimeSheetsTab } from './CurrentTimeSheetsTab/CurrentTimeSheetsTab';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';

const EMPLOYEE_NAME_FILTER_DEBOUNCE = 600;

export const TimeSheetsControllingWeekPage: React.FC = () => {
  const t = useNamespacedTranslation('timeSheetsControlling');
  const tNavigation = useNamespacedTranslation(
    'navigation.menuItems.timeManagement',
  );

  useFlairBreadcrumbHook([
    { label: tNavigation('title') },
    { label: tNavigation('subMenuItems.controlling.title') },
  ]);

  const [reportsMode, setReportsMode] = useManagerReportsMode(
    'timeTrackingControlling',
  );
  const [filter, setFilter] = useState<string>('');

  const setFilterDebounced = _.debounce(
    setFilter,
    EMPLOYEE_NAME_FILTER_DEBOUNCE,
  );

  return (
    <>
      <PageHeader
        title={t('title')}
        actions={
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        }
      />
      <Card className="h7-regular">
        <Card.Header>
          <Row>
            <Col>
              <GlobalTableFilter
                placeholder={t('filter.employeePlaceholder')}
                onFilterChange={setFilterDebounced}
              />
            </Col>
            <Col className="col-auto d-flex align-items-center">
              <TimeSheetNavigationConnected
                reportsMode={reportsMode}
                selectedMode="week"
              />
            </Col>
          </Row>
        </Card.Header>
        <CurrentTimeSheetsTab employeeNameFilter={filter} />
      </Card>
    </>
  );
};
