import React from 'react';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { Spinner } from 'react-bootstrap';

export const SubRowDaysLoading: React.FC = () => {
  const t = useNamespacedTranslation('components.timeSheetsTable');
  return (
    <div className="d-flex align-items-center ms-4">
      <span className="me-2">{t('loadingDays')}</span>
      <Spinner animation="border" size="sm" />
    </div>
  );
};
