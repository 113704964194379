import React from 'react';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  commentsCount: number;
};

export const TimeSheetCommentsCell: React.FC<Props> = ({ commentsCount }) => {
  const t = useNamespacedTranslation(
    'components.timeSheetsTable.timeSheetCommentsCell',
  );

  return (
    <div
      className="d-flex gap-2 align-items-center"
      title={t('commentsViewTooltip')}>
      <FlairIcon icon="comments-outline" />
      <span>({commentsCount})</span>
    </div>
  );
};
