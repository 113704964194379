import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useUserInfo } from '../../../../../context/UserInfo';
import { EmployeeFeedbackState } from '../../../../../__generated__/graphql';
import { FeedbackProps } from '../../../types';
import { isViewer } from '../utils';
import Button from '../../../../../../../components/button';
import { isFeedbackEnded, useReviewerSignAndFinish } from '../../../helpers';
import { FeedbackSignButton } from '../../EmployeePerformanceReviewMeeting/FeedbackStatusSection';
import ChangeReviewerButton from '../NextPerformanceReview/ChangeReviewer';
import { useFeedbackRestriction } from '../../../../../hooks/performanceReview/feedbackCycle/useFeedbackRestriction';
import { ReviewDescription } from './components/ReviewDescription';
import { EndedReview } from './components/EndedReview';
import { PerformanceReviewLink } from './components/PerformanceReviewLink';
import { ReviewDueDate } from './components/ReviewDueDate';

import './styles.css';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import { EmployeesAvatarGroup } from '../../../../../../../atomic/molecules/EmployeesAvatarGroup/EmployeeAvatarGroup';

import { RecipientEmployee } from '../../../../../components/PastPerformanceReviewCard/types';
import { mapToRecipientEmployee } from './mappings';

type Props = {
  feedbacks: FeedbackProps[];
  canChangeReviewer: boolean;
};

const isCompletedFeedbacks = (feedbacks: FeedbackProps[]) =>
  feedbacks.filter(isFeedbackEnded).length > 0;

type ReviewProps = {
  feedback: FeedbackProps;
  canChangeReviewer: boolean;
};

type ActiveReviewProps = {
  isFeedbackInactive: boolean;
  feedback: FeedbackProps;
  canChangeReviewer: boolean;
  canAssignPeers: boolean;
};

const ActiveReview: React.FC<ReviewProps> = (props) => {
  const { isFeedbackCycleIsInactive } = useFeedbackRestriction({
    isReviewer: props.canChangeReviewer,
  });
  const feedbackTimelineType =
    props.feedback.feedbackCycle.flair__Timeline_Type__c;
  const employeeFeedbackState =
    props.feedback.employeeFeedback?.flair__State__c;

  const isFeedbackInactive = !!isFeedbackCycleIsInactive(
    feedbackTimelineType,
    employeeFeedbackState,
  );

  return props.feedback.feedbackCycle.flair__Meeting_Required__c ? (
    <ActiveReviewWithMeeting
      isFeedbackInactive={isFeedbackInactive}
      canAssignPeers={props.feedback.feedbackCycle.flair__With_Peers__c}
      {...props}
    />
  ) : (
    <ActiveReviewWithoutMeeting
      isFeedbackInactive={isFeedbackInactive}
      canAssignPeers={props.feedback.feedbackCycle.flair__With_Peers__c}
      {...props}
    />
  );
};

const PeerReviewers: React.FC<{ peers: RecipientEmployee[] }> = ({ peers }) => {
  const i18Path =
    'employeePage.performanceReview.nextPerformanceReview.peerReviewers';
  const t = useNamespacedTranslation(i18Path);

  if (!peers.length) {
    return (
      <div className="d-flex gap-2 flex-row align-items-center">
        <FlairIcon icon="user-circle-plus" size="lg" />
        <span className="h5-regular text-gray-900">{t('noPeersAssigned')}</span>
        <FlairIcon
          icon="alert-circle-outline"
          tooltip={t('noPeersAssignedTooltip')}
          tooltipPlacement="top"
          size="sm"
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row gap-3 align-items-center">
      <EmployeesAvatarGroup
        avatarSize="xs"
        gapSize={20}
        recipients={peers}
        showAvatarOnly={true}
      />
      <span className="h5-regular text-gray-900">
        {t('hasPeersAssigned', {
          peersQuantity: peers.length,
        })}
      </span>
    </div>
  );
};

const ActiveReviewWithMeeting: React.FC<ActiveReviewProps> = ({
  feedback,
  canChangeReviewer,
  isFeedbackInactive,
  canAssignPeers,
}) => {
  const { t } = useTranslation();
  const { id: myId } = useUserInfo();

  const peers = feedback.peerFeedbacks.map((p) =>
    mapToRecipientEmployee(p.reviewer),
  );

  return (
    <Row className="align-items-center">
      <Col xl={6}>{canAssignPeers && <PeerReviewers peers={peers} />}</Col>
      <Col xl={6} className="text-end">
        {canChangeReviewer && (
          <span className="me-2">
            <ChangeReviewerButton
              participantId={
                feedback.outcome.flair__Employee_Feedback_Participant__c
              }
              reviewerId={feedback.outcome.reviewer.Id}
              employeeId={feedback.reviewee.Id}
            />
          </span>
        )}

        {!isViewer(feedback.flair__Feedback_From__c, myId) &&
          !isFeedbackInactive && (
            <PerformanceReviewLink feedback={feedback}>
              <Button
                size="sm"
                disabled={
                  feedback.flair__State__c ===
                    EmployeeFeedbackState.Submitted &&
                  feedback.employeeFeedback?.flair__State__c !==
                    EmployeeFeedbackState.Submitted
                }
                variant="primary"
                label={t(
                  feedback.flair__State__c === EmployeeFeedbackState.Submitted
                    ? 'employeePage.performanceReview.nextPerformanceReview.startMeeting.title'
                    : 'employeePage.performanceReview.nextPerformanceReview.startReview',
                )}
              />
            </PerformanceReviewLink>
          )}
      </Col>
    </Row>
  );
};

const ActiveReviewWithoutMeeting: React.FC<ActiveReviewProps> = ({
  feedback,
  canChangeReviewer,
  isFeedbackInactive,
  canAssignPeers,
}) => {
  const { t } = useTranslation();
  const { id: myId } = useUserInfo();

  const [isFinishing, signAndFinish] = useReviewerSignAndFinish();

  const handleFinish = () => {
    return signAndFinish(feedback.outcome.Id);
  };

  const peers = feedback.peerFeedbacks.map((p) =>
    mapToRecipientEmployee(p.reviewer),
  );

  return (
    <Row className="align-items-center">
      <Col xl={6}>{canAssignPeers && <PeerReviewers peers={peers} />}</Col>
      <Col xl={6} className="text-end">
        {!isViewer(feedback.flair__Feedback_From__c, myId) &&
          (feedback.flair__State__c === EmployeeFeedbackState.Submitted ? (
            <FeedbackSignButton
              variant="primary"
              onFinish={handleFinish}
              isFinishing={isFinishing}
            />
          ) : (
            <>
              {canChangeReviewer && (
                <span className="me-2">
                  <ChangeReviewerButton
                    participantId={
                      feedback.outcome.flair__Employee_Feedback_Participant__c
                    }
                    reviewerId={feedback.outcome.reviewer.Id}
                    employeeId={feedback.reviewee.Id}
                  />
                </span>
              )}
              {!isFeedbackInactive && (
                <PerformanceReviewLink feedback={feedback}>
                  <Button
                    size="sm"
                    variant="primary"
                    label={t(
                      'employeePage.performanceReview.nextPerformanceReview.startReview',
                    )}
                  />
                </PerformanceReviewLink>
              )}
            </>
          ))}
      </Col>
    </Row>
  );
};

const NextPerformanceReview: React.FC<Props> = ({
  feedbacks,
  canChangeReviewer,
}) => {
  const { t } = useTranslation();
  const { id: myId } = useUserInfo();

  if (!feedbacks.length) {
    return null;
  }

  const isCompleted = isCompletedFeedbacks(feedbacks);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>
          {isCompleted
            ? t('employeePage.performanceReview.lastPerformanceReviews.title')
            : t(
                'employeePage.performanceReview.activePerformanceReviews.title',
              )}
        </h2>
      </div>
      {feedbacks.map((feedback) => (
        <Card key={feedback.Id}>
          <Card.Body>
            <Row className="d-flex justify-content-between">
              <Col>
                <PerformanceReviewLink feedback={feedback}>
                  <h3>{feedback.feedbackCycle.Name}</h3>
                </PerformanceReviewLink>
              </Col>
            </Row>

            <Row className="mb-3-5 d-flex justify-content-between">
              {feedback.outcome.flair__Ended_At__c ? (
                <Col>
                  <Trans
                    i18nKey="employeePage.performanceReview.nextPerformanceReview.acceptedReviewerDescription"
                    values={{
                      employee: feedback.reviewee.Name,
                      interpolation: { escapeValue: false },
                    }}
                    components={[<strong />]}
                  />
                </Col>
              ) : (
                <Col>
                  <ReviewDescription feedback={feedback} myId={myId} />
                </Col>
              )}
              <Col className="d-flex justify-content-end">
                <ReviewDueDate
                  dueDate={feedback.feedbackCycle.flair__Deadline__c}
                />
              </Col>
            </Row>

            {feedback.outcome.flair__Ended_At__c ? (
              <EndedReview feedback={feedback} />
            ) : (
              <ActiveReview
                feedback={feedback}
                canChangeReviewer={canChangeReviewer}
              />
            )}
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

export default NextPerformanceReview;
