// Returns true if the target is an element with className or any ancestor has className (up to body)
export const clickedInElementWithClassOrItsChild = (
  target: EventTarget | null,
  className: string,
): boolean => {
  if (target instanceof Element) {
    if (target.matches(`.${className}`)) {
      return true;
    }
    // While loop to check if the target is inside element with className
    let parent = target.parentElement;
    while (parent && parent !== document.body) {
      if (parent.matches(`.${className}`)) {
        return true;
      }
      parent = parent.parentElement;
    }
  }
  return false;
};
