import React from 'react';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { useMyAbsencesQuery } from '../../__generated__/graphql';
import { toMonthRange } from '../../../../utils/date';
import DefaultLoading from './Loading';
import ServerError from '../../../../components/ServerError';
import { Col, Row } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import CategoriesAccordion from './CategoriesAccordion';
import { UpcomingAbsencesCard } from './UpcomingAbsecesCard';
import NoAbsencesCard from './NoAbsencesCard';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import { AbsencesContext } from './AbsencesContext';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';

type Props = {
  tab?: string;
};

const Content: React.FC<Props> = () => {
  const params = useQueryParams();
  const { data, error } = useMyAbsencesQuery({
    variables: {
      ...toMonthRange(params.month),
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return <ServerError />;
  }

  if (!data) return <DefaultLoading />;

  const hasUpcomingAbsences =
    data.me.upcomingAbsences && data.me.upcomingAbsences.length > 0;

  return (
    <Row>
      <Col xl="6">
        <Row>
          <Col>
            {hasUpcomingAbsences ? (
              <UpcomingAbsencesCard absences={data.me.upcomingAbsences} />
            ) : (
              <NoAbsencesCard categories={data.me.absenceCategories} />
            )}
          </Col>
        </Row>
      </Col>
      <Col xl="6">
        <AbsencesContext.Provider
          value={{
            employeeId: data.me.Id,
            timeSheets: data.myTimeSheets,
          }}>
          <CategoriesAccordion
            categories={data.me.absenceCategories}
            absencesCurrentYear={data.me.absencesCurrentYear}
          />
        </AbsencesContext.Provider>
      </Col>
    </Row>
  );
};

const i18Path = 'absences';

export const Absences: React.FC<Props> = ({ tab }) => {
  const t = useNamespacedTranslation(i18Path);
  const titleToDisplay = t('subMenuItems.overview.title');
  const needsPageHeader = tab !== 'all';

  useFlairBreadcrumbHook([{ label: t('title') }, { label: titleToDisplay }]);

  return (
    <>
      {needsPageHeader ? <PageHeader title={titleToDisplay} /> : null}
      <Content />
    </>
  );
};
