import { StringBoolean } from '../../../../../../hooks/usePersistentFilter';
import { TimeSheetStatus } from '../../../../__generated__/graphql';
import { ViewBy } from '../types';

export type ProjectsTimeSheetsFilter = {
  employeeIds: string[];
  projectsIds: string[];
  viewBy: ViewBy | null;
  status: TimeSheetStatus | null;
  onlyWithComments: StringBoolean | null;
};

export const defaultFilter: ProjectsTimeSheetsFilter = {
  employeeIds: [],
  projectsIds: [],
  viewBy: null,
  status: TimeSheetStatus.Pending,
  onlyWithComments: null,
};
