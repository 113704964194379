export const getCategoryTitleKey: (category: string | null) => string = (
  category,
) => {
  switch (category) {
    case 'Electronic Device':
      return 'electronicDevice';
    case 'Office Equipment':
      return 'officeEquipment';
    case 'Other':
      return 'other';
    default:
      return 'defaultTitle';
  }
};
