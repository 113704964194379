import React from 'react';
import { OptionBase } from '../../../../../components/Select/types';
import { DocumentFileTypeIcon } from '../DocumentFileType';
import { Theme } from '../../../../../theme';
import { FileRowType } from './DocumentUploadModal';
import styled from '@emotion/styled';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import { TOptions } from 'i18next';

type FileRowProps = {
  t: (key: string, options?: TOptions) => string;
  fileRow: FileRowType;
  categories: OptionBase[];
  removeFile: (fileRow: FileRowType) => void;
  updateFileName: (fileRow: FileRowType, name: string) => void;
  setFileCategory: (fileRow: FileRowType, category: string | null) => void;
};

export const ModalFileRow: React.FC<FileRowProps> = React.memo(
  ({ t, fileRow, categories, removeFile, updateFileName, setFileCategory }) => {
    return (
      <>
        <FileRowItem className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <DocumentFileTypeIcon fileType={fileRow.type} />
          </div>
          <DocumentNameInput
            value={fileRow.label}
            onChange={(e) => updateFileName(fileRow, e.target.value)}
            name="documentName"
            placeholder={t('inputPlaceholder')}
          />
          <SingleFilterStyled
            placeholder={t('selectPlaceholder')}
            isSearchable={false}
            isClearable={false}
            options={categories}
            value={fileRow.category}
            heightMode="small"
            onChange={(value) => setFileCategory(fileRow, value)}
          />
          <FlairIcon
            icon="close-outline"
            onClick={() => removeFile(fileRow)}
            color={Theme.color.danger}
            className="ms-2 me-2 cu-pointer"
            size="sm"
          />
        </FileRowItem>
      </>
    );
  },
);

const DocumentNameInput = styled.input`
  border-radius: 0.375rem;
  width: 12.625rem;
  height: 1.5rem;
  border: 1px ${Theme.color.paper1} solid;
  padding: 0.313rem 0.5rem;
  outline: none;
  font-size: ${Theme.font.size.xs};
`;

const FileRowItem = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${Theme.color.paper4};
`;

const SingleFilterStyled = styled(SingleFilter)`
  width: 9.75rem;
  height: 1.5rem;
  border-bottom: 1px solid ${Theme.color.paper4};
  span {
    font-size: ${Theme.font.size.xs};
  }
`;
