import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';

type Props = {
  disabled?: boolean;
  size?: 'sm' | 'lg';
  block?: boolean;
  className?: string;
  onClick?: () => void;
};

const SubmitButton: React.FC<Props> = ({
  disabled,
  size,
  children,
  className,
  block,
  ...rest
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return React.useMemo(
    () => (
      <Button
        type="submit"
        disabled={disabled || isSubmitting}
        size={size}
        {...rest}
        className={[
          disabled ? '' : 'lift',
          block ? 'w-100' : undefined,
          className,
        ].join(' ')}>
        {isSubmitting && (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"></span>
        )}
        {children}
      </Button>
    ),
    [disabled, children, block, isSubmitting, rest, size, className],
  );
};

export default SubmitButton;
