import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ServerError from '../../../../../../components/ServerError';
import SkeletonCard from '../../../../components/Skeleton/Card';
import { EmptyGoals } from '../EmptyGoals';
import { GoalFilter } from '../GoalsFilter/GoalsFilter';
import { GoalsFilterConnected } from '../GoalsFilter/GoalsFilterConnected';
import { GoalBase, GoalId, GoalsFilterType } from '../types';
import { useObjectives } from '../useObjectives';
import { GoalsList } from './GoalsList';

type Props = {
  isReviewGoals?: boolean;
  isManagerView?: boolean;
  onCreateGoal: () => void;
  onGoalClick: (goalId: GoalId) => void;
  filter: GoalFilter;
  onFilterChange: (filter: GoalFilter) => void;
};

export const GoalsListWithFilter: React.FC<Props> = ({
  isManagerView = false,
  isReviewGoals = false,
  filter,
  onFilterChange,
  onCreateGoal,
  onGoalClick,
}) => {
  const { goals, loading, error } = useObjectives(
    filter,
    isReviewGoals ? ['employee'] : 'all', // show only employee goals when we are in feedback cycle page
  );

  if (error) {
    return <ServerError />;
  }

  const renderGoals = (goals: GoalBase[]) => {
    if (goals !== undefined && !goals.length) {
      return <EmptyGoals createBtnVisible={true} onCreateGoal={onCreateGoal} />;
    }
    return <GoalsList goals={goals} onGoalClick={onGoalClick} />;
  };

  return (
    <Row>
      <Col>
        <GoalsFilterConnected
          goals={goals}
          filterTypes={getFilterTypes(isManagerView, isReviewGoals)}
          filter={filter}
          onChange={onFilterChange}
          onCreateGoal={onCreateGoal}
          isReviewGoals={isReviewGoals}
        />
        {loading || goals === undefined ? (
          <>
            <SkeletonCard height={400} />
            <SkeletonCard height={400} />
          </>
        ) : (
          renderGoals(goals)
        )}
      </Col>
    </Row>
  );
};

const getFilterTypes = (
  isManager: boolean,
  isReviewGoals: boolean,
): GoalsFilterType[] => {
  if (isReviewGoals) {
    return ['allGoals'];
  }
  if (!isManager) {
    return ['myGoals', 'colleaguesGoals', 'allGoals'];
  } else {
    return ['myReportsGoals', 'allGoals'];
  }
};
