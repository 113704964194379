import * as React from 'react';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import SingleFilter from '../../../../../../components/SelectFilters/SingleFilter';
import {
  LastUpdateFilterByCategory,
  LastUpdateFilterByTeam,
} from '../../../../__generated__/graphql';
import { getFilterByCategoryOption, getFilterByTeamOption } from './logic';
import { FlairMultiSelectDropdown } from '../../../../../../atomic/molecules/FlairDropdown/FlairMultiSelectDropdown/FlairMultiSelectDropdown';
import { FilterSelectOption } from '../../../../../../atomic/molecules/FlairDropdown/types';
import { MultiValue } from 'react-select';
import { Maybe } from '../../../../../../utils/maybe';
import { useServerFeatures } from '../../../../../../hooks/useServerFeature';
import { useUserInfo } from '../../../../context/UserInfo';

type Props = {
  filterByTeam: LastUpdateFilterByTeam;
  setFilterByTeam: (filterByTeam: LastUpdateFilterByTeam) => void;
  filterByCategory: LastUpdateFilterByCategory[];
  setFilterByCategory: (
    filterByCategories: LastUpdateFilterByCategory[],
  ) => void;
};

const i18Path = 'dashboard.lastUpdates.filters';

const LastUpdateFilters: React.FC<Props> = ({
  filterByTeam,
  setFilterByTeam,
  filterByCategory,
  setFilterByCategory,
}) => {
  const tLastUpdatesFilters = useNamespacedTranslation(i18Path);
  const tWidgetFilter = useNamespacedTranslation(
    'dashboard.common.widgetFilter',
  );

  const { isActive } = useUserInfo();
  const isFeatureVisible = useServerFeatures();

  const isAnnouncementsVisible = isActive && isFeatureVisible('Announcements');
  const isCheersVisible = isActive && isFeatureVisible('Cheers');
  const isNewHiresVisible = isActive && isFeatureVisible('Home_New_Hires_Card');

  const teamOptions = [
    { label: tWidgetFilter('myTeam'), value: LastUpdateFilterByTeam.MyTeam },
    {
      label: tWidgetFilter('allCompany'),
      value: LastUpdateFilterByTeam.AllCompany,
    },
  ];

  const categoryOptions: Array<FilterSelectOption> = [
    isAnnouncementsVisible && {
      label: tLastUpdatesFilters('announcements'),
      value: LastUpdateFilterByCategory.Announcements,
    },
    isCheersVisible && {
      label: tLastUpdatesFilters('cheers'),
      value: LastUpdateFilterByCategory.Cheers,
    },
    isNewHiresVisible && {
      label: tLastUpdatesFilters('newJoiners'),
      value: LastUpdateFilterByCategory.NewJoiners,
    },
  ].filter(Boolean) as FilterSelectOption[];

  const handleTeamChange = (value: Maybe<string>) => {
    if (value) {
      setFilterByTeam(getFilterByTeamOption(value));
    }
  };

  const handleCategoryChange = (
    selectedCategories: MultiValue<FilterSelectOption>,
  ) => {
    const categories = selectedCategories.map((c) =>
      getFilterByCategoryOption(c.value),
    );

    setFilterByCategory(categories);
  };

  return (
    <div className="d-flex-all-center">
      <FlairMultiSelectDropdown
        className="me-3"
        options={categoryOptions}
        selectedOptions={filterByCategory}
        onChange={handleCategoryChange}
      />
      <SingleFilter
        options={teamOptions}
        value={filterByTeam.toString()}
        onChange={handleTeamChange}
        isClearable={false}
        minWidth="8.75rem"
      />
    </div>
  );
};

export default LastUpdateFilters;
