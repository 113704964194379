import React from 'react';
import AbsencePopover from '../../../components/AbsencePopover';
import AbsenceCategoryIcon from '../../../components/AbsenceCategoryIcon';
import { DashboardAbsentToday_ColleagueFragment } from '../../../__generated__/graphql';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { OverlayTrigger } from 'react-bootstrap';
import RelativeDateLabel from '../../../../../atomic/molecules/RelativeDateLabel';
import { FlairDateFormat } from '../../../../../atomic/atoms/FlairFormattedDate/types';

type AdditionalInfoProps = {
  absence: DashboardAbsentToday_ColleagueFragment;
  t: Function;
  isModal?: boolean;
};

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  absence,
  t,
  isModal = false,
}) => {
  const renderChildContent = () => {
    if (isModal) {
      return (
        <RelativeDateLabel
          endDate={new Date(absence.flair__End_Date__c)}
          displayFormat={FlairDateFormat.DayNumberWithMonth}
        />
      );
    }
    return (
      <>
        {absence.flair__Category_Name__c}
        <FlairIcon
          icon="ellipse-filled"
          width="0.25rem"
          color="var(--bs-flair-gray-2)"
          className="align-middle ms-2 me-2"
        />
        <span>
          {t(absence.flair__Type__c, {
            count: absence.flair__Amount__c,
          })}
        </span>
      </>
    );
  };

  return (
    <div
      className="mt-1 d-flex align-middle"
      data-testid={`${absence.employee?.Name}-additional-info`}>
      <OverlayTrigger
        placement="top"
        overlay={(props) => <AbsencePopover absence={absence} {...props} />}>
        <div>
          <AbsenceCategoryIcon
            icon={absence.flair__Category_Icon__c}
            className="me-2"
          />
        </div>
      </OverlayTrigger>
      {renderChildContent()}
    </div>
  );
};

export default AdditionalInfo;
