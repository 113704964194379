import { EmployeeField, FieldInfo } from '../../../components/EmployeeData';
import {
  WorkflowItemDataFieldFragment,
  WorkflowItemFileFormat,
  WorkflowItemUploadedFileFragment,
} from '../../../__generated__/graphql';
import { FileOnServer } from './types';

export const mapWorkflowItemDataField = (
  src: WorkflowItemDataFieldFragment,
): EmployeeField => ({
  value: src.value,
  fieldInfo: mapFieldInfo(src.fieldInfo),
});

export const mapFieldInfo = (
  src: WorkflowItemDataFieldFragment['fieldInfo'],
): FieldInfo => ({
  name: src.name,
  type: src.type,
  label: src.label,
  disabled: false,
  required: false,
  options: src.picklistValues.map((x) => ({
    label: x.label,
    value: x.value,
  })),
});

// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers
export const mapWorkflowItemFileFormatToFileSpecifier = (
  src: WorkflowItemFileFormat,
): string | undefined => {
  switch (src) {
    case WorkflowItemFileFormat.Pdf:
      return '.pdf';
    case WorkflowItemFileFormat.Image:
      return 'image/*';
    case WorkflowItemFileFormat.Zip:
      return '.zip';
    default:
      return undefined;
  }
};

export const mapFileOnServer = (
  src: WorkflowItemUploadedFileFragment,
): FileOnServer => ({
  id: src.Id,
  name: [src.Title, src.FileExtension].join('.'),
});
