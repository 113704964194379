import * as React from 'react';

const StopWatchOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M7.99805 6.95972V3.98169"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.30307 3.23792L2.84863 2.79121M7.99893 1.6V1.00439V1.6Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99797 9.64121C8.66725 9.64121 9.2098 9.10789 9.2098 8.45C9.2098 7.79211 8.66725 7.25879 7.99797 7.25879C7.32869 7.25879 6.78613 7.79211 6.78613 8.45C6.78613 9.10789 7.32869 9.64121 7.99797 9.64121Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
      <path
        d="M7.9981 1.89819C6.67987 1.89819 5.39124 2.28244 4.29518 3.00235C3.19911 3.72225 2.34483 4.74549 1.84036 5.94265C1.3359 7.13981 1.2039 8.45713 1.46108 9.72803C1.71825 10.9989 2.35304 12.1663 3.28517 13.0826C4.2173 13.9989 5.40491 14.6228 6.69781 14.8756C7.99071 15.1284 9.33084 14.9987 10.5487 14.5028C11.7666 14.0069 12.8076 13.1672 13.5399 12.0898C14.2723 11.0124 14.6632 9.74566 14.6632 8.44986C14.6632 6.71225 13.961 5.04581 12.711 3.81713C11.4611 2.58846 9.76579 1.89819 7.9981 1.89819Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default StopWatchOutlineIcon;
