import { WorkloadTrackingType } from '../../__generated__/graphql';
import { useMyTimeTrackingSettings } from './useMyTimeTrackingSettings';

type TimeTrackingType = {
  isTimeTrackingEnabled: boolean;
  isProjectTrackingEnabled: boolean;
};

export const useMyTrackingTypes = (): TimeTrackingType => {
  const { data } = useMyTimeTrackingSettings();

  if (!data || data.me.workloads.length < 1) {
    return {
      isTimeTrackingEnabled: false,
      isProjectTrackingEnabled: false,
    };
  }

  const trackingType = data.me.workloads[0].flair__Time_Tracking_Type__c;
  return {
    isTimeTrackingEnabled: [
      WorkloadTrackingType.TimeTracking,
      WorkloadTrackingType.TimeTrackingAndProjectTracking,
    ].includes(trackingType),
    isProjectTrackingEnabled: [
      WorkloadTrackingType.ProjectTracking,
      WorkloadTrackingType.TimeTrackingAndProjectTracking,
    ].includes(trackingType),
  };
};

export const useIsMyTimeTrackingEnabled = () => {
  const { isTimeTrackingEnabled } = useMyTrackingTypes();
  return isTimeTrackingEnabled;
};

export const useIsMyProjectTrackingEnabled = () => {
  const { isProjectTrackingEnabled } = useMyTrackingTypes();
  return isProjectTrackingEnabled;
};
