import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';

type Props = {
  location: string;
};

const InvitedEmployeeIcon: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="invited-location-tooltip">
          {t('shiftPlanner.invitedLocation', {
            location,
          })}
        </Tooltip>
      }>
      <span>
        <FlairIcon icon="information-circle-filled" className="ms-2" />
      </span>
    </OverlayTrigger>
  );
};

export default InvitedEmployeeIcon;
