import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../theme';
import Button from '../../button';
import FlairIcon from '../../../atomic/atoms/FlairIcon';

export type TInputPropsBase = {
  value: string | number | undefined;
  onChange: (value: string | number | undefined) => void;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
};

type Props = {
  type?: string;
  containerClassName?: string;
  className?: string;
  labelComponent?: React.ReactNode;
  renderInput: (inputProps: TInputPropsBase) => React.ReactNode;
  value?: string;
  onChange: (value: string | number | undefined) => void;
  isInEditModeInitial?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

export function InputWithSaveBase({
  containerClassName,
  className,
  labelComponent,
  renderInput,
  value,
  onChange,
  isInEditModeInitial,
  disabled,
  readOnly,
  type,
  ...restProps
}: Props) {
  const [isEditMode, setIsEditMode] = useState(isInEditModeInitial === true);
  const [curValue, setCurValue] = useState<string | number | undefined>(value);
  const { t } = useTranslation();

  const handleEditClick = useCallback(() => {
    if (disabled || readOnly) {
      return;
    }
    setIsEditMode(true);
  }, [setIsEditMode, disabled, readOnly]);

  const handleCancel = useCallback(() => {
    setIsEditMode(false);
    setCurValue(value);
  }, [setIsEditMode, setCurValue, value]);

  const handleSave = useCallback(() => {
    switch (type) {
      case 'number':
        onChange(curValue && curValue !== '' ? curValue : undefined);
        break;

      default:
        onChange(curValue);
        break;
    }

    setIsEditMode(false);
  }, [onChange, setIsEditMode, curValue, type]);
  return (
    <div className={containerClassName}>
      <ButtonsContainer className="d-flex align-items-center justify-content-between">
        <div>{labelComponent}</div>
        {!readOnly && (
          <div>
            {!isEditMode ? (
              <FlairIcon
                size="sm"
                icon="pencil-outline"
                onClick={handleEditClick}
                color={Theme.color.dark}
                role="button"
              />
            ) : (
              <div className="d-flex">
                <Button
                  label={t('form.general.cancelButton')}
                  size="sm"
                  className="me-2"
                  variant="outline-primary"
                  onClick={handleCancel}
                />
                <Button
                  size="sm"
                  label={t('form.general.saveButton')}
                  onClick={handleSave}
                />
              </div>
            )}
          </div>
        )}
      </ButtonsContainer>
      {renderInput({
        ...restProps,
        value: curValue,
        onChange: setCurValue,
        disabled: disabled || !isEditMode,
        readOnly: readOnly || !isEditMode,
        className,
      })}
    </div>
  );
}

const ButtonsContainer = styled('div')({
  height: '2.15rem',
});
