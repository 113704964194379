import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAllActiveEmployeesMatrixQuery,
  useEmployeeSkillsMatrixQuery,
  useMySubordinatesSkillsLazyQuery,
  useSkillNamesQuery,
} from '../../../__generated__/graphql';
import { mapSkill } from '../common/mappings';
import { Employee, Skill } from '../common/types';
import { mapEmployee, mapEmployeeSkillMatrix } from './mappings';
import { EmployeeSkillMatrix } from './types';
import _ from 'lodash';
import { useFlairLocalStorage } from '../../../hooks/useFlairLocalStorage';

export const useSkillsMatrix = () => {
  const {
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectedSkillIds,
    setSelectedSkillIds,
    error: loadSelectedIdsError,
    resetSelection,
  } = useSelectedIds();

  const { data: allSkillsData, error: allSkillsError } = useSkillNamesQuery();
  const { data: allEmployeesData, error: allEmployeesError } =
    useAllActiveEmployeesMatrixQuery();
  const { data: employeeSkillsData, error: employeeSkillsError } =
    useEmployeeSkillsMatrixQuery({
      skip: selectedEmployeeIds === null || selectedEmployeeIds.length === 0,
      variables: {
        employeeIds: selectedEmployeeIds ?? [],
      },
    });

  const allSkills: Skill[] | undefined = useMemo(
    () => allSkillsData?.skills.map(mapSkill),
    [allSkillsData],
  );

  const selectedSkills = useMemo(() => {
    if (!allSkills || !selectedSkillIds) {
      return undefined;
    }
    return selectedSkillIds
      .map((selectedId) => allSkills.find((skill) => skill.id === selectedId))
      .filter((x): x is Skill => x !== undefined);
  }, [selectedSkillIds, allSkills]);

  const selectedEmployeeInfos: Employee[] | undefined = useMemo(() => {
    if (!allEmployeesData || !selectedEmployeeIds) {
      return undefined;
    }
    return allEmployeesData.activeEmployees
      .filter((x) => selectedEmployeeIds.includes(x.Id))
      .map(mapEmployee);
  }, [allEmployeesData, selectedEmployeeIds]);

  const notSelectedSkills: Skill[] | undefined = useMemo(() => {
    if (!allSkills || !selectedSkillIds) {
      return undefined;
    }
    const res = allSkills.filter((x) =>
      selectedSkillIds.every((selectedSkillId) => selectedSkillId !== x.id),
    );
    return _.sortBy(res, (x) => x.name.toLowerCase());
  }, [allSkills, selectedSkillIds]);

  const employeeSkills: EmployeeSkillMatrix[] | undefined = useMemo(() => {
    if (selectedEmployeeIds?.length === 0) {
      return [];
    }
    return employeeSkillsData?.activeEmployees.flatMap((employee) =>
      employee.employeeSkills.map(mapEmployeeSkillMatrix),
    );
  }, [employeeSkillsData, selectedEmployeeIds]);

  return {
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectedSkillIds,
    setSelectedSkillIds,
    notSelectedSkills,
    selectedEmployeeInfos,
    selectedSkills,
    employeeSkills,
    resetSelection,
    error:
      allSkillsError ||
      allEmployeesError ||
      employeeSkillsError ||
      loadSelectedIdsError,
  };
};

// On the first load it returns all manager's reports and they skills
// Otherwise just return saved data from localStorage
const useSelectedIds = () => {
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useFlairLocalStorage(
    'skillsMatrix_employeeIds',
    null,
  );
  const [selectedSkillIds, setSelectedSkillIds] = useFlairLocalStorage(
    'skillsMatrix_skillIds',
    null,
  );

  const [isMySubordinatesSkillsApplied, setIsMySubordinatesSkillsApplied] =
    useState<boolean>(false);

  const [
    getMySubordinatesSkills,
    {
      data: mySubordinatesSkillsData,
      loading: mySubordinatesSkillsLoading,
      error: mySubordinatesSkillsError,
    },
  ] = useMySubordinatesSkillsLazyQuery();

  useEffect(() => {
    if (isMySubordinatesSkillsApplied) {
      return;
    }
    if (selectedSkillIds !== null || selectedEmployeeIds !== null) {
      return;
    }
    if (mySubordinatesSkillsLoading) {
      return;
    }
    getMySubordinatesSkills();
  }, [
    selectedEmployeeIds,
    selectedSkillIds,
    mySubordinatesSkillsLoading,
    getMySubordinatesSkills,
    isMySubordinatesSkillsApplied,
  ]);

  const resetSelection = useCallback(() => {
    setSelectedEmployeeIds(null);
    setSelectedSkillIds(null);
    setIsMySubordinatesSkillsApplied(false);
    getMySubordinatesSkills();
  }, [
    setIsMySubordinatesSkillsApplied,
    getMySubordinatesSkills,
    setSelectedEmployeeIds,
    setSelectedSkillIds,
  ]);

  useEffect(() => {
    if (isMySubordinatesSkillsApplied) {
      return;
    }
    if (!mySubordinatesSkillsData) {
      return;
    }
    const mySubordinatesIds = mySubordinatesSkillsData.manager.employees.map(
      (x) => x.Id,
    );
    const mySubordinatesSkillIds = _.uniq(
      mySubordinatesSkillsData.manager.employees.flatMap((x) =>
        x.employeeSkills.map((empSkill) => empSkill.flair__Skill__c),
      ),
    );
    setSelectedEmployeeIds(mySubordinatesIds);
    setSelectedSkillIds(mySubordinatesSkillIds);
    setIsMySubordinatesSkillsApplied(true);
  }, [
    mySubordinatesSkillsData,
    setSelectedSkillIds,
    setSelectedEmployeeIds,
    isMySubordinatesSkillsApplied,
    setIsMySubordinatesSkillsApplied,
  ]);

  return {
    isMySubordinatesSkillsApplied,
    setIsMySubordinatesSkillsApplied,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    selectedSkillIds,
    setSelectedSkillIds,
    error: mySubordinatesSkillsError,
    resetSelection,
  };
};
