import { FlairLocalStorageKey } from '../apps/dashboard/hooks/useFlairLocalStorage';
import {
  Filter,
  usePersistentFilter,
  UsePersistentFilterArg,
} from './usePersistentFilter';
export const useFlairPersistentFilter = <T extends Filter>(
  a: UsePersistentFilterArg<T, FlairLocalStorageKey>,
) =>
  /* eslint-disable-next-line no-restricted-syntax */
  usePersistentFilter(a);
