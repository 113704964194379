import React from 'react';
import { Card, Row } from 'react-bootstrap';
import ServerError from '../../../../../../components/ServerError';

import { LoadingPerformanceReview } from '../../Overview/LoadingPerformanceReview';

import {
  collectCycleNameFilterOptions,
  collectRevieweeFilterOptions,
  collectStatusFilterOptions,
  collectYearFilterOptions,
  FeedbackFilter,
} from '../Filter/FeedbackFilter';

import EmptyState from '../EmptyState';
import { useReviewsListQuery } from '../../../../__generated__/graphql';
import { mapFeedback } from '../mappings';
import { useFlairPersistentFilter } from '../../../../../../hooks/useFlairPersistentFilter';
import { applyFeedbackFilter } from '../filters';
import { emptyFeedbackFilter } from '../types';
import { routes as mainRoutes } from '../../../../routes';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { PerformanceReviewTable } from '../ReviewTable/PerformanceReviewTable';

const ForOthers: React.FC = () => {
  const { data, loading, error } = useReviewsListQuery();
  const mappedFeedbacks = data?.me.feedbacksAsReviewer.map(mapFeedback) || [];
  const t = useNamespacedTranslation('performanceReview.table.forOthersPage');

  const [filter, setFilter] = useFlairPersistentFilter({
    defaultFilter: emptyFeedbackFilter,
    storageKey: 'feedback_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.performanceReviewsForReviewer.route
        .withQueryParams(queryParams)
        .create({}),
  });

  const mainReviews = mappedFeedbacks.filter(
    (feedback) => !feedback.isPeerReviewer,
  );
  const peerReviews = mappedFeedbacks.filter(
    (feedback) => feedback.isPeerReviewer,
  );

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingPerformanceReview />;
  }

  const filteredMainReviews = applyFeedbackFilter(filter, mainReviews);
  const filteredPeerReviews = applyFeedbackFilter(filter, peerReviews);

  const noFilteredMainReviews = filteredMainReviews.length === 0;
  const noFilteredPeerReviews = filteredPeerReviews.length === 0;
  const noFilteredReviews = noFilteredMainReviews && noFilteredPeerReviews;
  const hasBothReviews = !noFilteredMainReviews && !noFilteredPeerReviews;

  if (!mappedFeedbacks?.length) {
    return <EmptyState />;
  }

  return (
    <Row>
      <Card>
        <Card.Header className="h-auto">
          <FeedbackFilter
            onChange={setFilter}
            filter={filter}
            cycleNameOptions={collectCycleNameFilterOptions(mappedFeedbacks)}
            reviewerOptions={null}
            yearOptions={collectYearFilterOptions(mappedFeedbacks)}
            statusOptions={collectStatusFilterOptions(mappedFeedbacks)}
            revieweeOptions={collectRevieweeFilterOptions(mappedFeedbacks)}
          />
        </Card.Header>
        {noFilteredReviews && <EmptyState />}
        {!noFilteredMainReviews && (
          <PerformanceReviewTable
            isReviewer={true}
            feedbacks={filteredMainReviews}
          />
        )}
        {hasBothReviews && <hr />}
        {!noFilteredPeerReviews && (
          <>
            <h3 className="ms-3 mt-3">{t('peerReviewsSectionSectionTitle')}</h3>
            <PerformanceReviewTable
              isReviewer={true}
              feedbacks={filteredPeerReviews}
            />
          </>
        )}
      </Card>
    </Row>
  );
};

export default ForOthers;
