import React, { useState } from 'react';
import { ModalSidebarContent } from '../../../components/ModalSidebar';
import {
  FileInput,
  useUpdateWorkflowItemFileRequestMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';

import { mapFileOnServer } from './mappings';
import { WorkflowItemCommonFields } from './WorkflowItemCommonFields';
import { WorkflowItemStatusAutoSave } from './WorkflowItemStatusAutoSave';
import { WorkflowItemFileField } from './WorkflowItemFileField';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import {
  WorkflowItemFileRequest,
  WorkflowItemStatus,
} from '../WorkflowItems/types';
import { FileOnServer } from './types';

type Props = {
  workflowItem: WorkflowItemFileRequest;
  fileOnServer: FileOnServer | null;
};

type SaveState = {
  saving: boolean;
  saved: boolean;
};

type FileSaveState = SaveState & {
  value: FileOnServer | null;
};

export const WorkflowItemFileRequestForm: React.FC<Props> = ({
  workflowItem,
  fileOnServer,
}) => {
  const [updateItem] = useUpdateWorkflowItemFileRequestMutation();

  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const [fileState, setFileState] = useState<FileSaveState>({
    saving: false,
    saved: false,
    value: fileOnServer,
  });

  const errorHandler = useMutationErrorHandler();

  const handleStatusChange = (status: WorkflowItemStatus) => {
    setIsStatusLoading(true);
    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
          uploadFile: null,
          deleteFileId: null,
        },
      },
    })
      .catch(errorHandler)
      .finally(() => {
        setIsStatusLoading(false);
      });
  };

  const handleSelectFile = (uploadFile: FileInput) =>
    updateFile(uploadFile, null);

  const handleDeleteFile = (deleteFileId: string) =>
    updateFile(null, deleteFileId);

  const updateFile = (
    uploadFile: FileInput | null,
    deleteFileId: string | null,
  ) => {
    setFileState((fileState) => ({ ...fileState, saving: true, saved: false }));

    let status = workflowItem.status;
    if (
      !workflowItem.readonly &&
      status === WorkflowItemStatusGenerated.NotStarted
    ) {
      status = WorkflowItemStatusGenerated.InProgress;
      setIsStatusLoading(true);
    }

    return updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          uploadFile,
          deleteFileId,
          status: status as WorkflowItemStatusGenerated,
        },
      },
    })
      .then((response) => {
        const record =
          response.data?.workflow.updateWorkflowItemFileRequest.record;

        if (!record) {
          return;
        }

        const uploadedFile = record.uploadedFile;
        setFileState({
          value: uploadedFile ? mapFileOnServer(uploadedFile) : null,
          saving: false,
          saved: true,
        });

        setIsStatusLoading(false);
      })
      .catch((error) => {
        setFileState((fileState) => ({
          ...fileState,
          saving: false,
          saved: false,
        }));
        errorHandler(error);
      });
  };

  const onStatusLoading = (isStatusLoading: boolean) => {
    setIsStatusLoading(isStatusLoading);
  };

  return (
    <ModalSidebarContent
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={onStatusLoading}
          />
          <WorkflowItemFileField
            fileFormat={workflowItem.fileFormat}
            file={fileState.value}
            saving={fileState.saving}
            saved={fileState.saved}
            readonly={workflowItem.readonly}
            onSelectFile={handleSelectFile}
            onDeleteFile={handleDeleteFile}
          />

          <WorkflowItemStatusAutoSave
            value={workflowItem.status}
            onChange={handleStatusChange}
            readonly={workflowItem.readonly}
            saving={isStatusLoading}
            saved={!isStatusLoading}
          />
        </>
      }
    />
  );
};
