import React from 'react';
import { TimeBalanceSettings } from './types';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { useTranslation } from 'react-i18next';
import { formattedDuration } from '../DurationFormat';
import {
  TimeBalanceDetailsRow,
  Props as RowProps,
} from './TimeBalanceDetailsRow';
import { TimeBalanceAutoResetMode } from '../../__generated__/graphql';
import { formatNextResetDate } from './helpers';
import { DifferenceWithSign } from '../DifferenceWithSign';

type Props = {
  timeBalance: number;
  settings: TimeBalanceSettings;
};

type TimeBalanceKey = keyof TimeBalanceSettings;

export const TimeBalanceDetails: React.FC<Props> = ({
  timeBalance,
  settings,
}) => {
  const t = useNamespacedTranslation('components.timeBalanceDetails');
  const formatDuration = formattedDuration(useTranslation().t);

  const labelAndHint = (key: TimeBalanceKey) => ({
    label: t(`fields.${key}.label`),
    hint: t(`fields.${key}.hint`),
  });

  const rows: RowProps[] = [
    // calculationMode
    {
      ...labelAndHint('calculationMode'),
      hint: t(
        `enumsHints.TimeBalanceCalculationMode.${settings.calculationMode}`,
      ),
      value: t(`enums.TimeBalanceCalculationMode.${settings.calculationMode}`, {
        after: settings.calculationAfter
          ? formatDuration(settings.calculationAfter)
          : undefined,
      }),
    },
    // updateMode
    {
      ...labelAndHint('updateMode'),
      hint: t(`enumsHints.TimeBalanceUpdateMode.${settings.updateMode}`),
      value: t(`enums.TimeBalanceUpdateMode.${settings.updateMode}`),
    },
    // maxDailyWorking
    settings.maxDailyWorking !== null
      ? {
          ...labelAndHint('maxDailyWorking'),
          value: formatDuration(settings.maxDailyWorking) ?? '',
        }
      : null,
    // threshold
    settings.threshold !== null
      ? {
          ...labelAndHint('threshold'),
          value: formatDuration(settings.threshold) ?? '',
        }
      : null,
    // dailyThreshold
    settings.dailyThreshold !== null
      ? {
          ...labelAndHint('dailyThreshold'),
          value: formatDuration(settings.dailyThreshold) ?? '',
        }
      : null,
    // notDeductDeficit
    settings.notDeductDeficit === true
      ? {
          ...labelAndHint('notDeductDeficit'),
          value: t('on'),
        }
      : null,
    // positiveCap
    settings.positiveCap !== null
      ? {
          ...labelAndHint('positiveCap'),
          value: formatDuration(settings.positiveCap) ?? '',
        }
      : null,
    // negativeCap
    settings.negativeCap !== null
      ? {
          ...labelAndHint('negativeCap'),
          value: formatDuration(settings.negativeCap) ?? '',
        }
      : null,
    // premiumLevel1
    settings.premiumLevel1 !== null
      ? {
          label: t(`fields.premiumLevel1.label`),
          hint: t(`fields.premiumLevel1.hint`, {
            after: formatDuration(settings.premiumLevel1.afterMinutes),
            multiplier: settings.premiumLevel1.multiplier,
          }),
          value: t('multiplierValue', {
            after: formatDuration(settings.premiumLevel1.afterMinutes),
            multiplier: settings.premiumLevel1.multiplier,
          }),
        }
      : null,
    // premiumLevel2
    settings.premiumLevel2 !== null
      ? {
          label: t(`fields.premiumLevel2.label`),
          hint: t(`fields.premiumLevel2.hint`, {
            after: formatDuration(settings.premiumLevel2.afterMinutes),
            multiplier: settings.premiumLevel2.multiplier,
          }),
          value: t('multiplierValue', {
            after: formatDuration(settings.premiumLevel2.afterMinutes),
            multiplier: settings.premiumLevel2.multiplier,
          }),
        }
      : null,
    // autoResetMode
    // todo: we need to update hint based on auto withdrawal settings
    // implement it after https://linear.app/flair/issue/CHR-36/milestone-3-auto-withdrawal-sf-ui
    settings.autoResetMode !== null &&
    settings.autoResetMode !== TimeBalanceAutoResetMode.None
      ? {
          ...labelAndHint('autoResetMode'),
          value: formatNextResetDate(settings.autoResetMode, new Date()),
        }
      : null,
  ].filter((row) => row !== null) as RowProps[]; //todo: fix ts

  return (
    <div>
      <div className="d-flex justify-content-between h4-medium">
        <div>{t('currentTimeBalance')}</div>
        <DifferenceWithSign value={timeBalance} withColor={true} />
      </div>
      <hr className="my-3" />
      <div className="h4-regular">
        {rows.map((curRow, i) => (
          <TimeBalanceDetailsRow key={i.toString()} {...curRow} />
        ))}
      </div>
    </div>
  );
};
