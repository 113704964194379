import React from 'react';
import { useSlackStatusAutoUpdateSettingsQuery } from '../../../__generated__/graphql';
import {
  SlackStatusAutoUpdateSettingsLayout,
  SlackStatusAutoUpdateSettingsView,
  SlackStatusAutoUpdateSettingsViewLoading,
} from './SlackStatusAutoUpdateSettingsView';
import ServerError from '../../../../../components/ServerError';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useSetSlackStatusAutoUpdate } from './useSetSlackStatusAutoUpdate';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes';

type Props = {
  pageUrl: string;
};

const i18prefix = 'components.slackStatusAutoUpdateSettings';

export const SlackStatusAutoUpdateSettings: React.FC<Props> = ({ pageUrl }) => {
  const t = useNamespacedTranslation(i18prefix);
  const history = useHistory();

  const { data, loading, error } = useSlackStatusAutoUpdateSettingsQuery();

  const errorHandler = useMutationErrorHandler();
  const [setStatusAutoUpdate] = useSetSlackStatusAutoUpdate();

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return <SlackStatusAutoUpdateSettingsViewLoading />;
  }

  const slackInfo = data?.slack;

  if (!slackInfo.orgHasSlackEnabled) {
    return (
      <SlackStatusAutoUpdateSettingsLayout>
        <div>{t('slackNotEnabledForOrganization')}</div>
      </SlackStatusAutoUpdateSettingsLayout>
    );
  }

  const employeeSlackConnection = slackInfo.employeeSlackConnection;

  if (employeeSlackConnection == null) {
    return (
      <SlackStatusAutoUpdateSettingsLayout>
        <div>{t('employeeNotConnected')}</div>
      </SlackStatusAutoUpdateSettingsLayout>
    );
  }

  const isEmployeeAuthorized = employeeSlackConnection.userScopes.length > 0;

  const handleChanged = () => {
    setStatusAutoUpdate({
      variables: {
        autoUpdate: !employeeSlackConnection.slackStatusAutoUpdate,
      },
    }).catch(errorHandler);
  };

  const handleAuthorizeEmployee = () => {
    setStatusAutoUpdate({
      variables: {
        autoUpdate: true,
      },
    }).then(() => {
      history.push(
        routes.slackAuthorizeEmployee.route
          .withQueryParams({
            redirectUrl: pageUrl,
          })
          .create({}),
      );
    });
  };

  return (
    <SlackStatusAutoUpdateSettingsView
      enabled={
        employeeSlackConnection.slackStatusAutoUpdate && isEmployeeAuthorized
      }
      isEmployeeAuthorized={isEmployeeAuthorized}
      onChange={handleChanged}
      onAuthorizeEmployee={handleAuthorizeEmployee}
    />
  );
};
