import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { Form } from '../../../../../components/form/Form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../components/form/Input';
import Button from '../../../../../components/button';
import styled from '@emotion/styled';

const MAX_COMMENT_LENGTH = 254;

type Props = {
  requestId: string | null;
  inProgress?: boolean;
  onClose: () => void;
  onRejected: (requestId: string, reason: string | null) => void;
};

type FormData = {
  reason?: string;
};

const validationSchema = yup.object().shape<FormData>({
  reason: yup.string().notRequired(),
});

export const ApproverCommentModal: React.FC<Props> = ({
  requestId,
  inProgress = false,
  onClose,
  onRejected,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (form: FormData) => {
    if (requestId) {
      onRejected(requestId, form.reason ?? null);
    }
  };

  return (
    <Modal
      show={requestId !== null}
      onHide={onClose}
      centered
      contentClassName="border">
      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Header
          closeButton
          className="card-header mt-4 d-flex flex-column-reverse align-items-start">
          <Title className="card-header-title">
            {t('absences.commentModal.title')}
          </Title>
          <h4 className="card-header-title text-danger font-weight-bold small">
            {t('absences.commentModal.subtitle')}
          </h4>
        </Header>
        <Modal.Body className="py-0">
          <Input
            type="textarea"
            name="reason"
            minRows={4}
            maxLength={MAX_COMMENT_LENGTH}
            placeholder={t('absences.commentModal.placeholder')}
          />
        </Modal.Body>

        <ModalFooter className="border-top-0">
          <Button
            type="submit"
            label={t('absences.commentModal.confirm')}
            disabled={inProgress}
            isProcessing={inProgress}
            className="py-3 px-5"
            variant="primary"
            size="lg"></Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const Title = styled.h4`
  font-size: 20px;
`;
const Header = styled(Modal.Header)`
  height: 45px;
`;
