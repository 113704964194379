import React from 'react';
import { Popover } from '../../../../../../components/popover';
import {
  DayTimeEntriesChangesPopoverContent,
  Props as DayTimeEntriesChangesPopoverContentProps,
} from './DayTimeEntriesChangesPopoverContent';
import { useSingleVisiblePopoverContext } from '../../../../../../context/SingleVisiblePopoverContext';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { getAllChangeRequestIds } from '../mappings';
import {
  TimeTrackingMutationsContext,
  useRejectTimeEntryChangeRequest,
} from '../../../../hooks/timeTracking';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { TimeBalancesInvalidateContext } from '../../../../components/TimeSheetsTable/TimeBalanceProvider/TimeBalancesProvider';

type Props = Omit<
  DayTimeEntriesChangesPopoverContentProps,
  | 'isRejecting'
  | 'isApproving'
  | 'onApprove'
  | 'onReject'
  | 'singleChangeRequestId'
> & { fieldKey: string };

export const DayTimeEntriesChangesPopover: React.FC<Props> = ({
  fieldKey,
  ...props
}) => {
  const t = useNamespacedTranslation('timeSheetsControlling.popovers.changes');
  const errorHandler = useMutationErrorHandler();

  const changeRequestIds = getAllChangeRequestIds(props.dayChanges);

  const singleChangeRequestId =
    changeRequestIds.size === 1 ? changeRequestIds.values().next().value : null;

  const { approveMutation } = React.useContext(TimeTrackingMutationsContext);
  const invalidateTimeBalance = React.useContext(TimeBalancesInvalidateContext);

  const isApproving = approveMutation.processingIds.some((processingId) =>
    changeRequestIds.has(processingId),
  );

  const handleChanged = () => {
    invalidateTimeBalance({
      employeeId: props.employeeId,
      day: props.day,
    });
  };

  const [rejectChangeRequest, { loading: isRejecting }] =
    useRejectTimeEntryChangeRequest();

  const { timeSheetId, day } = props;
  const popoverId = `${timeSheetId}-${day}-changes-${fieldKey}`;

  const { visiblePopoverId, onPopoverToggle } =
    useSingleVisiblePopoverContext();

  return (
    <Popover
      headerText={t('header', { count: changeRequestIds.size })}
      show={visiblePopoverId === popoverId}
      onToggle={(visible) => onPopoverToggle(popoverId, visible)}
      popoverContent={
        <DayTimeEntriesChangesPopoverContent
          {...props}
          singleChangeRequestId={singleChangeRequestId}
          isApproving={isApproving}
          isRejecting={isRejecting}
          onApprove={(changeRequestId) =>
            approveMutation.processItem(changeRequestId, handleChanged)
          }
          onReject={(changeRequestId) =>
            rejectChangeRequest({
              variables: {
                input: {
                  ids: [changeRequestId],
                },
              },
            })
              .then(handleChanged)
              .catch(errorHandler)
          }
        />
      }>
      <FlairIcon
        icon="alert-circle-outline"
        size="sm"
        className="text-warning"
      />
    </Popover>
  );
};
