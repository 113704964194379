import React from 'react';
import { Form } from 'react-bootstrap';
import Hint from '../hint';
import AutoSaveInputLabel from './AutoSaveInputLabel';
import { FormGroup } from './FormGroup';

type Item = {
  name: string | number;
  label: string;
  hint: string | null;
};

export type RadioGroupParams = {
  groupLabel?: string;
  groupName?: string;
  items: Item[];
  defaultValue: string | number | null;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  hint?: string | null;
  groupClassName?: string;
  itemClassName?: string;
  loading?: boolean;
  isComplete?: boolean;
  onChange?: (selectedItem: Item['name']) => void;
};

const AutoSaveRadioGroup: React.FC<RadioGroupParams> = ({
  items,
  groupName,
  groupLabel,
  groupClassName,
  itemClassName = '',
  defaultValue,
  disabled,
  readOnly,
  required,
  hint,
  onChange,
  loading,
  isComplete,
}) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <FormGroup className={groupClassName}>
      {groupLabel && (
        <AutoSaveInputLabel
          label={groupLabel}
          required={required}
          loading={loading}
          completed={isComplete}
          hint={hint && <Hint text={hint} id={`hint_${groupName}`} />}
        />
      )}

      {items.map((item) => (
        <Form.Check
          key={item.name}
          className={itemClassName}
          type="radio"
          label={
            <small>
              {item.label}{' '}
              {item.hint && <Hint text={item.hint} id={`hint_${item.name}`} />}
            </small>
          }
          disabled={disabled}
          readOnly={readOnly}
          name={groupName}
          id={'id_' + item.name}
          value={item.name}
          defaultChecked={defaultValue === item.name}
          onChange={handleRadioChange}
        />
      ))}
    </FormGroup>
  );
};

export default AutoSaveRadioGroup;
