import React, { useState } from 'react';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { ModalSidebarContent } from '../../../components/ModalSidebar';
import {
  useUpdateWorkflowItemRegularMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
} from '../../../__generated__/graphql';
import { WorkflowItemBase, WorkflowItemStatus } from '../WorkflowItems/types';
import { WorkflowItemCommonFields } from './WorkflowItemCommonFields';
import { WorkflowItemStatusAutoSave } from './WorkflowItemStatusAutoSave';

type Props = {
  workflowItem: WorkflowItemBase;
};

export const WorkflowItemRegularForm: React.FC<Props> = ({ workflowItem }) => {
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const [updateItem] = useUpdateWorkflowItemRegularMutation();

  const errorHandler = useMutationErrorHandler();

  const handleStatusChange = (status: WorkflowItemStatus) => {
    setIsStatusLoading(true);
    updateItem({
      variables: {
        input: {
          id: workflowItem.id,
          status: status as WorkflowItemStatusGenerated,
        },
      },
    })
      .catch(errorHandler)
      .finally(() => setIsStatusLoading(false));
  };

  const onStatusLoading = (isStatusLoading: boolean) => {
    setIsStatusLoading(isStatusLoading);
  };

  return (
    <ModalSidebarContent
      body={
        <>
          <WorkflowItemCommonFields
            workflowItem={workflowItem}
            onStatusLoading={onStatusLoading}
          />
          <WorkflowItemStatusAutoSave
            value={workflowItem.status}
            onChange={handleStatusChange}
            readonly={workflowItem.readonly}
            saving={isStatusLoading}
            saved={!isStatusLoading}
          />
        </>
      }
    />
  );
};
