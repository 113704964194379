import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import GlobalTableFilter from '../../components/GlobalTableFilter';
import PageSizeSelect from '../../components/PageSizeSelect';

const PAGE_SIZES = [5, 10, 15, 20, 50, 100] as const;
export type PageSize = (typeof PAGE_SIZES)[number];

type Props = {
  pageSize: PageSize;
  onPageSizeChange: (pageSize: PageSize) => void;
  onFilterChange: (value: string) => void;
  placeholder?: string;
  value?: string;
};

export const CardHeaderFilter: React.FC<Props> = ({
  onFilterChange,
  placeholder,
  pageSize,
  onPageSizeChange,
  value,
}) => {
  return (
    <Card.Header>
      <Row className="align-items-center">
        <Col>
          <GlobalTableFilter
            value={value}
            onFilterChange={onFilterChange}
            placeholder={placeholder}
          />
        </Col>
        <Col className="col-auto">
          <PageSizeSelect
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            options={PAGE_SIZES}
          />
        </Col>
      </Row>
    </Card.Header>
  );
};
