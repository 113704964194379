import * as React from 'react';
import { allGoalStatuses, getStatusColor, GoalBase } from '../types';
import styled from '@emotion/styled';
import { groupBy } from 'lodash';
import { getStatusStr } from '../translations';
import { Theme } from '../../../../../../theme';

type Props = {
  goals: GoalBase[];
};

export const StatusStackedBarChart: React.FC<Props> = ({ goals }) => {
  if (!goals.length) {
    return <StatusStackedBarChartNoGoals />;
  }
  const groupedGoals = groupBy(goals, (x) => x.status);

  const statuses = allGoalStatuses.filter((x) => x in groupedGoals);

  return (
    <StatusBarContainer id="goals-status-stacked-bar-chart">
      {statuses.map((status, index) => {
        const statusStr = getStatusStr(groupedGoals[status][0].status);
        return (
          <VerticalFlex key={status} count={groupedGoals[status].length}>
            <StatusLabel title={statusStr}>{statusStr}</StatusLabel>
            <StatusBar
              title={statusStr}
              isStart={index === 0}
              isEnd={index === statuses.length - 1}
              color={getStatusColor(status)}
            />
            <StatusCount className="h7-regular">
              {groupedGoals[status].length}
            </StatusCount>
          </VerticalFlex>
        );
      })}
    </StatusBarContainer>
  );
};

const StatusStackedBarChartNoGoals: React.FC = () => {
  return (
    <StatusBarContainer id="goals-status-stacked-bar-chart">
      <StatusBar isStart={true} isEnd={true} color={EMPTY_COLOR} />
    </StatusBarContainer>
  );
};

const EMPTY_COLOR = Theme.color.blue4;

const StatusBarContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const VerticalFlex = styled.div<{
  count: number;
}>`
  display: flex;
  flex-direction: column;
  flex: ${(item) => item.count};
`;

const StatusLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${Theme.color.dark};
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 11px;
  line-height: 12px;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StatusCount = styled.div`
  color: ${Theme.color.dark};
`;

const StatusBar = styled.div<{
  isStart?: boolean;
  isEnd?: boolean;
  color: string;
}>`
  height: 0.25rem;
  width: 100%;
  margin-bottom: 0.25rem;
  background-color: ${(item) => item.color};
  border-radius: ${(item) =>
    `${
      item.isStart ? `100px 0 0 100px` : item.isEnd ? '0 100px 100px 0' : '0'
    }`};
`;
