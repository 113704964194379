import React from 'react';
import { routes as r } from '../../../routes';
import { Celebrations } from './Celebrations';

export const routes = () => ({
  all: {
    route: r.celebrationsFeed.route,
    render: () => <Celebrations view="feed" />,
  },
  received: {
    route: r.celebrationsReceived.route,
    render: () => <Celebrations view="received" />,
  },
  receivedDetails: {
    route: r.celebrationsReceivedDetails.route,
    render: () => <Celebrations view="received" />,
  },
  sent: {
    route: r.celebrationsSent.route,
    render: () => <Celebrations view="sent" />,
  },
});
