import React from 'react';

import { Project, ProjectViewType, Timesheet } from '../types';

import { ProjectTimesheetByProjects } from './ProjectTimesheetsByProjects';
import { ProjectTimesheetByDays } from './ProjectTimesheetsByDays';
import { CostCenter } from '../../../__generated__/graphql';

type Props = {
  viewType: ProjectViewType;
  projects: Project[];
  costCenters: ReadonlyArray<CostCenter>;
  timesheet: Timesheet;
  onProjectClick: (project: Project) => void;
};

export const ProjectsTimesheets: React.FC<Props> = ({
  viewType,
  projects,
  costCenters,
  timesheet,
  onProjectClick,
}) => {
  if (viewType === 'BY_DAYS') {
    return (
      <ProjectTimesheetByDays
        timesheet={timesheet}
        projects={projects}
        costCenters={costCenters}
      />
    );
  } else if (viewType === 'BY_PROJECTS') {
    return (
      <ProjectTimesheetByProjects
        key={getTimesheetKey(timesheet)}
        timesheet={timesheet}
        projects={projects}
        onProjectClick={onProjectClick}
      />
    );
  } else {
    return <></>;
  }
};

function getTimesheetKey(timesheet: Timesheet) {
  // Key is created to rerender ProjectTimesheetByProjects when we are creating a new Project Time Entry
  return `${timesheet.id}_${timesheet.projectTimeTracked.length}`;
}
