import React, { useRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentLocale } from '../../context/I18n';
import { toFlatpickrLocale } from './flatpickrUtils';
import { FormGroup } from './FormGroup';
import InputError from './InputError';
import InputLabel from './InputLabel';
import ScrollToError from './ScrollToError';
import { getError } from './utils';
import FlairIcon from '../../atomic/atoms/FlairIcon';

type Props = Pick<DateTimePickerProps, 'options'> & {
  type?: 'date' | 'datetime';
  label: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  required?: boolean;
};

type ControlledDateInputProps = Omit<Props, 'label' | 'required'>;

export const ControlledDateInput: React.FC<ControlledDateInputProps> = ({
  type = 'date',
  name,
  options,
  disabled,
  children,
  ...rest
}) => {
  const locale = toFlatpickrLocale(useCurrentLocale());
  const { t } = useTranslation();
  const { control, errors } = useFormContext();

  const error = getError(name, errors);

  const defaultOptions = {
    locale,
    dateFormat:
      type === 'datetime'
        ? t('flatpickr.datetimeFormat')
        : t('flatpickr.dateFormat'),
    enableTime: type === 'datetime',
  };

  return (
    <InputGroup className="input-group-merge">
      {disabled ? (
        <Controller
          as={Flatpickr}
          name={name}
          control={control}
          className="form-control form-control-appended"
          options={{
            ...defaultOptions,
            ...options,
            clickOpens: false,
          }}
          disabled
          {...rest}
        />
      ) : (
        <Controller
          as={Flatpickr}
          name={name}
          control={control}
          className={`form-control form-control-appended ${
            error ? 'is-invalid' : ''
          }`}
          options={{
            ...defaultOptions,
            ...options,
          }}
          {...rest}
          onChange={([dates]) => dates[0] ?? null}
        />
      )}
      <InputGroup.Text className={disabled ? 'disabled' : ''}>
        <FlairIcon icon="calendar-clear-outline" />
      </InputGroup.Text>
      <InputError error={error} />
    </InputGroup>
  );
};

export const Datepicker: React.FC<Props> = ({
  name,
  label,
  required,
  ...rest
}) => {
  const ref = useRef(null);

  return React.useMemo(
    () => (
      <FormGroup ref={ref}>
        <InputLabel label={label} required={required} />
        <ControlledDateInput name={name} {...rest} />
        <ScrollToError name={name} inputContainerRef={ref} />
      </FormGroup>
    ),
    [name, label, required, ref, rest],
  );
};
