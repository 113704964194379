import React from 'react';
import { times } from 'lodash';
import { Table as BootstrapTable } from 'react-bootstrap';
import { AbsenceCalendarContainer } from '../../AbsenceCalendar/AbsenceCalendarContainer';
import {
  calcAbsencesByDays,
  calcHolidaysByDay,
  calcWorkloadsByDays,
  workloadIncludesDay,
} from '../../AbsenceCalendar/Utils';
import {
  CalendarAbsence,
  CalendarDay,
  CalendarHoliday,
  CalendarWorkload,
} from '../../AbsenceCalendar/types';
import Content from './Content';
import moment from 'moment';
import { CalendarMonth } from './types';

type Props = {
  absences?: ReadonlyArray<CalendarAbsence>;
  workloads?: ReadonlyArray<CalendarWorkload>;
  holidays?: ReadonlyArray<CalendarHoliday>;
  year: number;
  loading: boolean;
};

const Calendar: React.FC<Props> = ({
  year,
  absences = [],
  workloads = [],
  holidays = [],
  loading,
}) => {
  const absencesByDay = calcAbsencesByDays(absences);
  const workloadsByDay = calcWorkloadsByDays(
    workloads,
    moment().year(year).endOf('year'),
  );
  const holidaysByDay = calcHolidaysByDay(holidays);

  const months: CalendarMonth[] = times(12, (i) => {
    const month = moment().year(year).month(i).startOf('month');
    const daysInMonth = month.daysInMonth();

    const days: CalendarDay[] = times(daysInMonth, (i) => {
      const day = moment(month).add(i, 'days');
      const absences = absencesByDay.get(day.format('YYYYMMDD')) || [];
      const workload = workloadsByDay.get(day.format('YYYYMMDD'));
      const holiday = holidaysByDay.get(day.format('YYYYMMDD'));

      return {
        day: day,
        hover: false,
        absences: absences,
        workday: (workload && workloadIncludesDay(workload, day)) || false,
        holiday: holiday,
      };
    });

    return {
      month: month,
      daysInMonth: daysInMonth,
      days: days,
    };
  });

  return (
    <AbsenceCalendarContainer>
      <BootstrapTable size="sm" responsive>
        <Content months={months} loading={loading} />
      </BootstrapTable>
    </AbsenceCalendarContainer>
  );
};

export default Calendar;
