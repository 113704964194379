import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmModalSimple,
  ConfirmModalSimpleProps,
} from '../../../../components/ConfirmModalSimple';

type Props = Omit<ConfirmModalSimpleProps, 'message'>;

export const ExpenseDeleteConfirmation = ({ ...restProps }: Props) => {
  const { t } = useTranslation();

  const message = t('expenses.deleteModal.confirmText');

  return <ConfirmModalSimple {...restProps} message={message} />;
};
