import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import FlairIcon from '../../atomic/atoms/FlairIcon';
import { Theme } from '../../theme';
import styled from '@emotion/styled';
import { getRowSize } from './mappings';
import { SelectableRowSize, SelectableRowType } from './types';

export type SelectableRowProps = Omit<SelectableRowType, 'onButtonClick'> & {
  onClick: () => void;
  size?: SelectableRowSize;
};
export const SelectableRow: React.FC<SelectableRowProps> = ({
  iconName,
  label,
  isActive,
  hasError,
  onClick,
  size,
  disabled,
}) => (
  <StyledContainer
    disabled={disabled}
    {...getRowSize(size)}
    isActive={isActive}
    hasError={hasError}
    onClick={onClick}
    className={classNames(
      'd-flex align-items-center text-center width-auto rounded-1 border cu-pointer user-select-none',
      {
        'pe-none': disabled,
      },
    )}>
    <Row className="ms-1 me-1 ps-4 w-100 g-0">
      {iconName && (
        <Col className="pr-1" sm={12}>
          <FlairIcon
            color={
              disabled
                ? Theme.color.gray5
                : isActive
                ? Theme.color.blue3
                : Theme.color.paper1
            }
            size="lg"
            icon={iconName}
          />
        </Col>
      )}
      <Col className="d-flex p-0">
        <h6 className="h6-medium p-0 m-0 color-paper-1">{label}</h6>
      </Col>
    </Row>
  </StyledContainer>
);

const StyledContainer = styled.div<{
  isActive?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  height: string;
}>`
  ${({ height }) => ({ height })};
  ${({ isActive, hasError }) => `
    background: ${isActive ? Theme.color.blue6 : Theme.color.paper5};
    color: ${isActive ? Theme.color.blue3 : Theme.color.paper1};
    border-color: ${
      hasError
        ? Theme.color.red2
        : isActive
        ? Theme.color.blue3
        : Theme.color.paper3
    } !important;
  `}
  ${({ disabled }) =>
    disabled
      ? `
    background: ${Theme.color.gray3};
    color: ${Theme.color.gray5};
    border-color: ${Theme.color.gray5} !important;
  `
      : ''}
  max-height: 6.25rem;
`;
