import { omitBy, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { FiltrationRoute } from '../../atomic/molecules/FlairDropdown/types';

export const useSelectOptions = (
  key: string,
  filtrationRoute: FiltrationRoute,
) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const selectedOption: string | null = queryParams[key] ?? null;

  const onChange = useCallback(
    (value: string | null) => {
      history.push(
        filtrationRoute(
          omitBy(
            {
              ...queryParams,
              [key]: value,
            },
            isEmpty,
          ),
        ),
      );
    },
    [key, history, queryParams, filtrationRoute],
  );

  return {
    selectedOption,
    onChange,
  };
};
