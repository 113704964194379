import { useContext, useEffect } from 'react';
import { FlairBreadcrumbContext } from '../context/FlairBreadcrumbContext';
import { BreadcrumbItem } from '../atomic/molecules/FlairBreadcrumb/types';

export const useFlairBreadcrumbHook = (
  items: BreadcrumbItem[],
  isLoading: boolean = false,
) => {
  const { setBreadcrumbItems } = useContext(FlairBreadcrumbContext);

  useEffect(() => {
    if (!isLoading) {
      setBreadcrumbItems(items);
    }

    return () => setBreadcrumbItems([]);
  }, [setBreadcrumbItems, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
};
