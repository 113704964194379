import classNames from 'classnames';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getStatusStr } from '../translations';
import { GoalBase, GoalId, GoalStatus } from '../types';
import { GoalCardItem } from './GoalCardItem';

export type Props = {
  status: GoalStatus;
  goals: GoalBase[];
  onGoalClick: (goalId: GoalId) => void;
};

const i18prefix = 'performanceReview.goals2.goalStatusCard.';

export const GoalStatusCard: React.FC<Props> = ({
  status,
  goals,
  onGoalClick,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between mb-3">
          <Card.Title as="h3">{getStatusStr(status)}</Card.Title>
          <GoalsCounter>
            {goals.length +
              ' ' +
              t(i18prefix + 'goal', { count: goals.length })}
          </GoalsCounter>
        </div>
        <div className={classNames('d-flex', 'flex-column', 'gap-3')}>
          {goals.map((goal) => (
            <GoalCardItem key={goal.id} goal={goal} onClick={onGoalClick} />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

const GoalsCounter = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...rest} className={classNames('fs-4', 'fw-bold', className)} />
);
