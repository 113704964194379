import React from 'react';
import DurationFormat from '../DurationFormat';
import DurationDiff from './DurationDiff';
import { hoursToMinutes } from '../../../../utils/time';

type Props = {
  oldValue: number | null;
  newValue: number;
};

const WorkingTimeDiff: React.FC<Props> = ({ oldValue, newValue }) => {
  const diff = oldValue ? newValue - oldValue : 0;

  return (
    <span>
      <DurationFormat minutes={hoursToMinutes(newValue)} />
      <DurationDiff minutes={hoursToMinutes(diff)} className="ms-2" />
    </span>
  );
};

export default WorkingTimeDiff;
