import React from 'react';
import { FeedbackProps } from '../../../../types';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment/moment';

export const EndedReview: React.FC<{ feedback: FeedbackProps }> = ({
  feedback,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="align-items-center">
      {feedback.feedbackCycle.flair__Signature_required__c &&
        !feedback.outcome.flair__Accepted_At__c && (
          <Col xl={8}>
            <Trans
              i18nKey="employeePage.performanceReview.nextPerformanceReview.signedDescription"
              values={{
                acceptedTime: moment(
                  feedback.outcome.flair__Ended_At__c,
                ).format(t('date.short')),
              }}
              components={[<strong />]}
            />
          </Col>
        )}
    </Row>
  );
};
