import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';

const PersonalDataHeader: React.FC = () => {
  const { t } = useTranslation();
  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.profile.title') },
    { label: t('personalData.subMenuTitle') },
  ]);

  return <PageHeader title={t('personalData.subMenuTitle')} />;
};

export default PersonalDataHeader;
