import React from 'react';
import moment from 'moment';
import ShiftsWeeklyFilter from './ShiftsWeeklyFilter';
import { calculateMinMaxHours, defaultShiftHours } from '../shiftsLogic';
import ShiftsWeeklyCalendar from './ShiftsWeeklyCalendar';
import { groupByPods, groupPodsByDay, WeeklyFilter } from './weeklyShiftsLogic';
import {
  gelAllEmployeeExceptMe,
  getShifts,
  mapMultiDayWeeklyShifts,
  mergeShiftsForWeeklyView,
} from '../shiftsMappings';
import {
  CalendarQueryDocument,
  CalendarQueryQuery,
  useCalendarQueryQuery,
} from '../../../__generated__/graphql';
import ServerError from '../../../../../components/ServerError';
import { SkeletonTable } from '../../Skeleton/Table';
import { useHistory, useLocation } from 'react-router-dom';
import {
  weeklyFilterFromQuery,
  weeklyFilterToQueryParams,
} from '../shiftsQueryUtils';
import { routes } from '../../../routes';
import { Card } from 'react-bootstrap';
import { EmployeeShiftInfo, OpenShiftInfo } from '../Common/types';

type Props = {};

const buildPath = (weeklyFilter: WeeklyFilter) =>
  routes.personalShifts.route
    .withQueryParams(weeklyFilterToQueryParams(weeklyFilter))
    .create({});

const Loading: React.FC = () => <SkeletonTable columns={7} rows={15} />;

const ShiftsWeekly: React.FC<Props> = () => {
  const query: URLSearchParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const filter = weeklyFilterFromQuery(query);

  const variables = {
    from: filter.weekStart.toISOString(),
    to: filter.weekStart.clone().add(7, 'days').toISOString(),
  };

  const { loading, data } = useCalendarQueryQuery({
    variables,
  });
  const refetchQueries = [
    {
      query: CalendarQueryDocument,
      variables,
    },
  ];

  const handleFilterChanged = (newFilter: WeeklyFilter) =>
    history.push(buildPath(newFilter));

  const shiftsWeeklyCalendar = (data: CalendarQueryQuery | undefined) => {
    if (!data) return <ServerError />;
    const { allOpenShifts, allEmployeeShifts } = getShifts(
      data,
      filter.additionalEmployeeIds,
      'weekly',
    );
    const employeeShifts = mapMultiDayWeeklyShifts(
      mergeShiftsForWeeklyView(
        allOpenShifts as OpenShiftInfo[],
        allEmployeeShifts as EmployeeShiftInfo[],
      ),
    );

    const pods = groupByPods(employeeShifts);
    const podsGroupedByDays = groupPodsByDay(pods);

    const openShifts = mapMultiDayWeeklyShifts(
      (allOpenShifts as OpenShiftInfo[]).filter(
        (x) => x.state !== 'requested' && x.state !== 'confirmed',
      ),
    );

    const openShiftsPods = groupByPods(openShifts);
    const openShiftPodsGroupedByDays = groupPodsByDay(openShiftsPods);

    const { min, max } = Array.from(podsGroupedByDays.entries())
      .map(([day, pods]) =>
        calculateMinMaxHours(
          moment(day),
          pods.flatMap((x) => x.shifts),
        ),
      )
      .reduce(
        (res, cur) => ({
          min: Math.min(res.min, cur.min),
          max: Math.max(res.max, cur.max),
        }),
        defaultShiftHours,
      );

    return (
      <ShiftsWeeklyCalendar
        weekStart={filter.weekStart}
        minHour={min}
        maxHour={max}
        openShiftPodsGroupedByDays={openShiftPodsGroupedByDays}
        podsGroupedByDay={podsGroupedByDays}
        refetchQueries={refetchQueries}
      />
    );
  };

  const allEmployees = data ? gelAllEmployeeExceptMe(data) : [];

  return (
    <Card>
      <Card.Header className="h-auto">
        <ShiftsWeeklyFilter
          filter={filter}
          allEmployees={allEmployees}
          onFilterChanged={handleFilterChanged}
        />
      </Card.Header>
      {!loading ? shiftsWeeklyCalendar(data) : <Loading />}
    </Card>
  );
};

export default ShiftsWeekly;
