import React from 'react';
import DaysWorked from './DaysWorked';
import moment from 'moment';
import {
  TimesheetTrackedTimeFieldsFragment,
  WorkingDay,
  WorkloadType,
} from '../../../__generated__/graphql';

type Workload = {
  flair__Start_Date__c: string;
  flair__End_Date__c?: string | null;
  flair__Hours_Per_Day__c: number | null;
  flair__Working_Days__c: ReadonlyArray<WorkingDay>;
  flair__Type__c: WorkloadType;
};

type TimeSheetEntry = {
  flair__Start_Date__c: string;
  flair__End_Date__c: string;
  flair__Target_Hours__c: number;
  workloads: ReadonlyArray<Workload>;
  trackedTime: TimesheetTrackedTimeFieldsFragment;
};

type Props = {
  timeSheet: TimeSheetEntry;
};

export const dayOfWeekToWokingDay = (dayOfWeek: number) => {
  if (dayOfWeek === 0) {
    return WorkingDay.Sunday;
  } else if (dayOfWeek === 1) {
    return WorkingDay.Monday;
  } else if (dayOfWeek === 2) {
    return WorkingDay.Tuesday;
  } else if (dayOfWeek === 3) {
    return WorkingDay.Wednesday;
  } else if (dayOfWeek === 4) {
    return WorkingDay.Thursday;
  } else if (dayOfWeek === 5) {
    return WorkingDay.Friday;
  } else {
    return WorkingDay.Saturday;
  }
};

const workingDaysIncludes =
  (workingDays: ReadonlyArray<WorkingDay>) => (day: moment.Moment) =>
    workingDays.includes(dayOfWeekToWokingDay(day.day()));

const calcDailyHours = (timeSheet: TimeSheetEntry) => {
  if (timeSheet.workloads.length === 0) {
    return 0;
  }

  if (timeSheet.workloads.length === 1) {
    return timeSheet.workloads[0].flair__Hours_Per_Day__c ?? 0;
  }

  return timeSheet.workloads.reduce((result, workload) => {
    const startDate = moment.max(
      moment(workload.flair__Start_Date__c),
      moment(timeSheet.flair__Start_Date__c),
    );
    const endDate = workload.flair__End_Date__c
      ? moment.min(
          moment(workload.flair__End_Date__c),
          moment(timeSheet.flair__End_Date__c),
        )
      : moment(timeSheet.flair__End_Date__c);
    const isWorkingDay = workingDaysIncludes(workload.flair__Working_Days__c);

    let totalDaysTracked = 0;
    for (
      const day = startDate.clone();
      day.isSameOrBefore(endDate, 'day');
      day.add(1, 'days')
    ) {
      if (isWorkingDay(day)) {
        totalDaysTracked++;
      }
    }

    const totalWorkingDays = workload.flair__Working_Days__c.length;
    const dailyHours =
      totalWorkingDays > 0 && workload.flair__Hours_Per_Day__c
        ? (workload.flair__Hours_Per_Day__c / totalWorkingDays) *
          totalDaysTracked
        : 0;

    return result + dailyHours;
  }, 0);
};

const TimeSheetEntryDaysWorked: React.FC<Props> = ({ timeSheet }) => {
  const dailyHours = calcDailyHours(timeSheet);
  const target =
    dailyHours > 0
      ? Math.ceil(timeSheet.flair__Target_Hours__c / dailyHours)
      : 0;
  const trackedDays = timeSheet.trackedTime.totalDaysTracked;

  return <DaysWorked target={target} tracked={trackedDays} />;
};

export default TimeSheetEntryDaysWorked;
