import React, { useMemo } from 'react';
import { TicketsFilter } from './types';
import {
  DictionaryItemType,
  useTicketFilterQuery,
} from '../../../../__generated__/graphql';
import TicketsFiltersLoading from './TicketsFiltersLoading';
import ServerError from '../../../../../../components/ServerError';
import { OptionBase } from '../../../../../../components/Select/types';
import { TicketsFilters } from './TicketsFilters';
import { getCurrentLanguage } from '../../../../../../locales/locale';

type Props = {
  filter: TicketsFilter;
  onChange: (filter: TicketsFilter) => void;
};

export const TicketsFiltersConnected: React.FC<Props> = ({
  filter,
  onChange,
}) => {
  const locale = getCurrentLanguage();
  const { data, loading, error } = useTicketFilterQuery({
    variables: {
      lang: locale,
      type: DictionaryItemType.TicketCategory,
    },
  });
  const categories = useMemo<OptionBase[]>(() => {
    return (
      data?.dictionaryItemsByType
        .map((category) => ({
          value: category.Id,
          label: category.label ? category.label : category.Name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [data]);

  const assignees: OptionBase[] = useMemo(() => {
    return (
      data?.activeEmployees.map((employee) => ({
        value: employee.Id,
        label: employee.Name,
      })) || []
    );
  }, [data]);

  if (loading) {
    return <TicketsFiltersLoading />;
  }
  if (error) return <ServerError />;

  return (
    <TicketsFilters
      categoryOptions={categories}
      assigneeOptions={assignees}
      filter={filter}
      onChange={onChange}
    />
  );
};
