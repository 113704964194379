import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import { WorkflowItemStatus } from '../../WorkflowItems/types';
import { WorkflowItemStatusFieldReadonly } from '../WorkflowItemStatusFieldReadonly';
import { WorkflowItemStatusSelect } from '../WorkflowItemStatusSelect';
import StatusFieldContainer from './StatusFieldContainer';

type Props = {
  name: string;
  readonly: boolean;
  readonlyStatus: WorkflowItemStatus;
  onChange?: (value: WorkflowItemStatus) => void;
};

export const WorkflowItemStatusField: React.FC<Props> = ({
  name,
  readonly,
  readonlyStatus,
  onChange,
}) => {
  return (
    <StatusFieldContainer>
      {readonly ? (
        <WorkflowItemStatusFieldReadonly status={readonlyStatus} />
      ) : (
        <WorkflowItemStatusFieldInput name={name} onChange={onChange} />
      )}
    </StatusFieldContainer>
  );
};

type WorkflowItemStatusFieldInputProps = {
  name: string;
  onChange?: (value: WorkflowItemStatus) => void;
};

const WorkflowItemStatusFieldInput: React.FC<
  WorkflowItemStatusFieldInputProps
> = ({ name, onChange }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <FormGroup>
      <Form.Label>
        {t('performanceReview.workflowsItems.sidebar.status')}
      </Form.Label>
      <Controller
        name={name}
        control={control}
        onChange={(v) => onChange && onChange(v[0])}
        as={(props) => (
          <WorkflowItemStatusSelect
            autoFocus={false}
            isSearchable={false}
            {...props}
          />
        )}
      />
    </FormGroup>
  );
};
