import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';
import * as Icons from './icons';
import { Placement } from 'react-bootstrap/types';

export type FlairIconName =
  | 'add-circle-outline'
  | 'alert-outline'
  | 'add-outline'
  | 'alert-circle-outline'
  | 'arrow-back-circle-outline'
  | 'arrow-back-outline'
  | 'arrow-forward-outline'
  | 'arrow-down-filled'
  | 'arrow-down-outline'
  | 'arrow-up-square-outline'
  | 'bar-chart-outline'
  | 'bell-outline'
  | 'briefcase-outline'
  | 'bullseye-outline'
  | 'business-outline'
  | 'cafe-outline'
  | 'calendar-check-outline'
  | 'calendar-clear-outline'
  | 'call-outline'
  | 'caret-down-outline'
  | 'caret-up-outline'
  | 'chatbubble-ellipses-outline'
  | 'chatbubble-outline'
  | 'checkbox-outline'
  | 'checkmark-circle-outline'
  | 'checkmark-done-outline'
  | 'checkmark-outline'
  | 'chevron-back-outline'
  | 'chevron-down-outline'
  | 'chevron-forward-outline'
  | 'chevron-up-outline'
  | 'location-circle'
  | 'flag-circle'
  | 'information-circle'
  | 'person-circle'
  | 'company-circle'
  | 'money-circle'
  | 'dollar-circle'
  | 'calendar-circle'
  | 'star-circle'
  | 'time-circle'
  | 'circle-notch-outline'
  | 'clipboard-outline'
  | 'close-outline'
  | 'compress-outline'
  | 'document-outline'
  | 'document-solid'
  | 'download-outline'
  | 'ellipse-filled'
  | 'ellipse-outline'
  | 'ellipsis-vertical-circle-outline'
  | 'expand-outline'
  | 'expand'
  | 'external-link-outline'
  | 'eye-filled'
  | 'eye-off-outline'
  | 'eye-outline'
  | 'folder-outline'
  | 'frown-outline'
  | 'file-arrow-up'
  | 'folder-simple-plus'
  | 'frown-solid'
  | 'gift-outline'
  | 'happy-filled'
  | 'happy-outline'
  | 'hashtag-outline'
  | 'help-circle-outline'
  | 'home-outline'
  | 'information-circle-filled'
  | 'information-circle-outline'
  | 'kebab-solid'
  | 'link-outline'
  | 'location-outline'
  | 'lock-open-outline'
  | 'lock-closed-outline'
  | 'loom'
  | 'mail-outline'
  | 'mail-unread-outline'
  | 'meh-outline'
  | 'meh-solid'
  | 'mic-outline'
  | 'navigate-circle-outline'
  | 'navigate-outline'
  | 'pause-circle-outline'
  | 'pause-outline'
  | 'pencil-outline'
  | 'people-outline'
  | 'person-circle-outline'
  | 'person-outline'
  | 'play-outline'
  | 'pricetag'
  | 'question-circle-outline'
  | 'remove-outline'
  | 'remove-square-outline'
  | 'sad-tear-outline'
  | 'sad-tear-solid'
  | 'search-filled'
  | 'search-filled-new'
  | 'settings-outline'
  | 'smile-outline'
  | 'smile-solid'
  | 'spinner-outline'
  | 'star-filled'
  | 'star-outline'
  | 'sticky-note-outline'
  | 'stop-circle-outline'
  | 'stop-outline'
  | 'stopwatch-outline'
  | 'thumbs-down-outline'
  | 'thumbs-up-filled'
  | 'thumbs-up-outline'
  | 'tilde-outline'
  | 'time-outline'
  | 'trash-outline'
  | 'trending-up-outline'
  | 'umbrella-outline'
  | 'image-solid'
  | 'image-outline'
  | 'comments-outline'
  | 'multiple-files'
  | 'folder'
  | 'xls-file'
  | 'home-folder-icon'
  | 'file'
  | 'pdf-file'
  | 'ppt-file'
  | 'image-file'
  | 'video-file'
  | 'audio-file'
  | 'zip-file'
  | 'doc-file'
  | 'three-dots'
  | 'thermometer-outline'
  | 'left-arrow'
  | 'right-arrow'
  | 'sunny-icon'
  | 'home-filled-icon'
  | 'stopwatch-filled'
  | 'calendar-filled'
  | 'wrapped-gift'
  | 'megaphone-outline'
  | 'two-cards'
  | 'app-software-icon'
  | 'electronic-device-icon'
  | 'office-equipment-icon'
  | 'inventory-other-icon'
  | 'vehicle-icon'
  | 'uniforms-icon'
  | 'handle-icon'
  | 'support-icon'
  | 'expenses-outline'
  | 'team-outline'
  | 'recruitment-outline'
  | 'performance-outline'
  | 'notification-outline-icon'
  | 'notification-new-outline-icon'
  | 'status-approved-icon'
  | 'status-rejected-icon'
  | 'status-pending-icon'
  | 'dashboard-outline'
  | 'profile-outline'
  | 'company-outline'
  | 'workflow-outline'
  | 'absence-outline'
  | 'engagement-outline'
  | 'exclamation-triangle'
  | 'help-desk-outline'
  | 'magnifying-glass-outline'
  | 'team-filled'
  | 'time-filled'
  | 'workflow-filled'
  | 'profile-filled'
  | 'performance-filled'
  | 'help-desk-filled'
  | 'expenses-filled'
  | 'engagement-filled'
  | 'dashboard-filled'
  | 'company-filled'
  | 'absence-filled'
  | 'recruitment-filled'
  | 'hamburger-menu'
  | 'confetti'
  | 'calendar'
  | 'potted-plant'
  | 'user-circle-plus'
  | 'flag-banner'
  | 'users-three'
  | 'calendar-plus'
  | 'like'
  | 'unlike'
  | 'chart-bar-filled'
  | 'chart-bar-outline'
  | 'alert-triangle-outline'
  | 'hour-glass-outline';

export type SizeProp =
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'lg'
  | 'sm'
  | 'xxl'
  | 'xl'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

export type FlairIconProps = React.SVGProps<SVGSVGElement> & {
  icon: FlairIconName;
  size?: SizeProp;
  tooltip?: string;
  tooltipPlacement?: Placement;
  popover?: OverlayChildren;
};

const getIcon = (icon: FlairIconName) => {
  switch (icon) {
    case 'ellipse-outline':
      return Icons.EllipseOutlineIcon;
    case 'ellipse-filled':
      return Icons.EllipseFilledIcon;
    case 'help-circle-outline':
      return Icons.HelpCircleOutlineIcon;
    case 'checkmark-circle-outline':
      return Icons.CheckmarkCircleOutlineIcon;
    case 'information-circle':
      return Icons.InformationCircleIcon;
    case 'information-circle-outline':
      return Icons.InformationCircleOutlineIcon;
    case 'information-circle-filled':
      return Icons.InformationCircleFilledIcon;
    case 'add-circle-outline':
      return Icons.AddCircleOutlineIcon;
    case 'happy-filled':
      return Icons.HappyFilledIcon;
    case 'eye-outline':
      return Icons.EyeOutlineIcon;
    case 'eye-filled':
      return Icons.EyeFilledIcon;
    case 'eye-off-outline':
      return Icons.EyeOffOutlineIcon;
    case 'thumbs-up-filled':
      return Icons.ThumbsUpFilledIcon;
    case 'thumbs-up-outline':
      return Icons.ThumbsUpOutlineIcon;
    case 'thumbs-down-outline':
      return Icons.ThumbsDownOutlineIcon;
    case 'arrow-back-outline':
      return Icons.ArrowBackOutlineIcon;
    case 'arrow-forward-outline':
      return Icons.ArrowForwardOutlineIcon;
    case 'arrow-down-outline':
      return Icons.ArrowDownOutlineIcon;
    case 'chevron-back-outline':
      return Icons.ChevronBackOutlineIcon;
    case 'chevron-forward-outline':
      return Icons.ChevronForwardOutlineIcon;
    case 'chevron-down-outline':
      return Icons.ChevronDownOutlineIcon;
    case 'chevron-up-outline':
      return Icons.ChevronUpOutlineIcon;
    case 'location-circle':
      return Icons.LocationCircleIcon;
    case 'flag-circle':
      return Icons.FlagCircleIcon;
    case 'person-circle':
      return Icons.PersonCircleIcon;
    case 'company-circle':
      return Icons.CompanyCircleIcon;
    case 'money-circle':
      return Icons.MoneyCircleIcon;
    case 'dollar-circle':
      return Icons.DollarCircleIcon;
    case 'calendar-circle':
      return Icons.CalendarCircleIcon;
    case 'star-circle':
      return Icons.StarCircleIcon;
    case 'time-circle':
      return Icons.TimeCircleIcon;
    case 'checkmark-outline':
      return Icons.CheckmarkOutlineIcon;
    case 'caret-down-outline':
      return Icons.CaretDownOutlineIcon;
    case 'caret-up-outline':
      return Icons.CaretUpOutlineIcon;
    case 'download-outline':
      return Icons.DownloadOutlineIcon;
    case 'star-outline':
      return Icons.StarOutlineIcon;
    case 'star-filled':
      return Icons.StarFilledIcon;
    case 'calendar-clear-outline':
      return Icons.CalendarClearOutline;
    case 'document-outline':
      return Icons.DocumentOutlineIcon;
    case 'document-solid':
      return Icons.DocumentSolidIcon;
    case 'trash-outline':
      return Icons.TrashOutlineIcon;
    case 'pencil-outline':
      return Icons.PencilOutlineIcon;
    case 'folder-outline':
      return Icons.FolderOutlineIcon;
    case 'briefcase-outline':
      return Icons.BriefcaseOutlineIcon;
    case 'call-outline':
      return Icons.CallOutlineIcon;
    case 'chatbubble-outline':
      return Icons.ChatBubbleOutlineIcon;
    case 'link-outline':
      return Icons.LinkOutlineIcon;
    case 'alert-circle-outline':
      return Icons.AlertCircleOutlineIcon;
    case 'alert-outline':
      return Icons.AlertOutlineIcon;
    case 'cafe-outline':
      return Icons.CafeOutlineIcon;
    case 'business-outline':
      return Icons.BusinessOutlineIcon;
    case 'pricetag':
      return Icons.PriceTagIcon;
    case 'search-filled':
      return Icons.SearchFilledIcon;
    case 'search-filled-new':
      return Icons.SearchFilledNewIcon;
    case 'lock-open-outline':
      return Icons.LockOpenOutlineIcon;
    case 'lock-closed-outline':
      return Icons.LockClosedOutlineIcon;
    case 'chatbubble-ellipses-outline':
      return Icons.ChatBubbleOutlineIcon;
    case 'time-filled':
      return Icons.TimeFilledIcon;
    case 'time-outline':
      return Icons.TimeOutlineIcon;
    case 'settings-outline':
      return Icons.SettingsOutlineIcon;
    case 'home-outline':
      return Icons.HomeOutlineIcon;
    case 'person-outline':
      return Icons.PersonOutlineIcon;
    case 'people-outline':
      return Icons.PeopleOutlineIcon;
    case 'umbrella-outline':
      return Icons.UmbrellaOutlineIcon;
    case 'stopwatch-outline':
      return Icons.StopWatchOutlineIcon;
    case 'checkbox-outline':
      return Icons.CheckBoxOutlineIcon;
    case 'gift-outline':
      return Icons.GiftOutlineIcon;
    case 'mail-outline':
      return Icons.MailOutlineIcon;
    case 'mail-unread-outline':
      return Icons.MailUnreadOutlineIcon;
    case 'hashtag-outline':
      return Icons.HashTagOutlineIcon;
    case 'stop-circle-outline':
      return Icons.StopCircleOutlineIcon;
    case 'close-outline':
      return Icons.CloseOutlineIcon;
    case 'navigate-outline':
      return Icons.NavigateOutlineIcon;
    case 'frown-outline':
      return Icons.FrownOutlineIcon;
    case 'happy-outline':
      return Icons.HappyOutlineIcon;
    case 'meh-outline':
      return Icons.MehOutlineIcon;
    case 'sad-tear-outline':
      return Icons.SadTearOutlineIcon;
    case 'smile-outline':
      return Icons.SmileOutlineIcon;
    case 'frown-solid':
      return Icons.FrownSolidIcon;
    case 'meh-solid':
      return Icons.MehSolidIcon;
    case 'sad-tear-solid':
      return Icons.SadTearSolidIcon;
    case 'smile-solid':
      return Icons.SmileSolidIcon;
    case 'bullseye-outline':
      return Icons.BullseyeOutlineIcon;
    case 'tilde-outline':
      return Icons.TildeOutlineIcon;
    case 'checkmark-done-outline':
      return Icons.CheckmarkDoneOutlineIcon;
    case 'spinner-outline':
      return Icons.SpinnerOutlineIcon;
    case 'circle-notch-outline':
      return Icons.CircleNotchOutlineIcon;
    case 'pause-circle-outline':
      return Icons.PauseCircleOutlineIcon;
    case 'navigate-circle-outline':
      return Icons.NavigateCircleOutlineIcon;
    case 'sticky-note-outline':
      return Icons.StickyNoteOutlineIcon;
    case 'person-circle-outline':
      return Icons.personCircleOutlineIcon;
    case 'calendar-check-outline':
      return Icons.CalendarCheckOutlineIcon;
    case 'external-link-outline':
      return Icons.ExternalLinkOutlineIcon;
    case 'arrow-down-filled':
      return Icons.ArrowDownFilledIcon;
    case 'compress-outline':
      return Icons.CompressOutlineIcon;
    case 'remove-outline':
      return Icons.RemoveOutlineIcon;
    case 'add-outline':
      return Icons.AddOutlineIcon;
    case 'remove-square-outline':
      return Icons.RemoveSquareOutlineIcon;
    case 'arrow-up-square-outline':
      return Icons.ArrowUpSquareOutlineIcon;
    case 'clipboard-outline':
      return Icons.ClipBoardOutlineIcon;
    case 'question-circle-outline':
      return Icons.QuestionCircleOutlineIcon;
    case 'mic-outline':
      return Icons.MicOutlineIcon;
    case 'expand-outline':
      return Icons.ExpandOutlineIcon;
    case 'expand':
      return Icons.ExpandIcon;
    case 'location-outline':
      return Icons.LocationOutlineIcon;
    case 'arrow-back-circle-outline':
      return Icons.ArrowBackCircleOutlineIcon;
    case 'play-outline':
      return Icons.PlayOutlineIcon;
    case 'pause-outline':
      return Icons.PauseOutlineIcon;
    case 'stop-outline':
      return Icons.StopOutlineIcon;
    case 'bar-chart-outline':
      return Icons.BarChartOutlineIcon;
    case 'trending-up-outline':
      return Icons.TrendingUpOutlineIcon;
    case 'ellipsis-vertical-circle-outline':
      return Icons.EllipsisVerticalCircleOutlineIcon;
    case 'loom':
      return Icons.LoomIcon;
    case 'image-solid':
      return Icons.ImageSolidIcon;
    case 'image-outline':
      return Icons.ImageOutlineIcon;
    case 'comments-outline':
      return Icons.CommentsOutlineIcon;
    case 'three-dots':
      return Icons.ThreeDotsIcon;
    case 'kebab-solid':
      return Icons.KebabSolidIcon;
    //TODO::Remove BellOutlineIcon after releasing the new menubar (and removing the old sidebar)
    case 'bell-outline':
      return Icons.BellOutlineIcon;
    case 'file-arrow-up':
      return Icons.FileArrowUpIcon;
    case 'folder-simple-plus':
      return Icons.FolderSimplePlusIcon;
    case 'multiple-files':
      return Icons.MultipleFilesIcon;
    case 'folder':
      return Icons.FolderIcon;
    case 'xls-file':
      return Icons.XLSFileIcon;
    case 'file':
      return Icons.FileIcon;
    case 'image-file':
      return Icons.FileImageIcon;
    case 'home-folder-icon':
      return Icons.HomeFolderIcon;
    case 'ppt-file':
      return Icons.FilePowerPointIcon;
    case 'video-file':
      return Icons.FileVideoIcon;
    case 'audio-file':
      return Icons.FileAudioIcon;
    case 'zip-file':
      return Icons.FileZipIcon;
    case 'pdf-file':
      return Icons.PdfFileIcon;
    case 'doc-file':
      return Icons.DocFileIcon;
    case 'thermometer-outline':
      return Icons.ThermometerOutline;
    case 'left-arrow':
      return Icons.LeftArrowIcon;
    case 'right-arrow':
      return Icons.RightArrowIcon;
    case 'sunny-icon':
      return Icons.SunnyIcon;
    case 'home-filled-icon':
      return Icons.HomeFilledIcon;
    case 'stopwatch-filled':
      return Icons.StopwatchFilled;
    case 'calendar-filled':
      return Icons.CalendarFilled;
    case 'wrapped-gift':
      return Icons.PresentIcon;
    case 'megaphone-outline':
      return Icons.MegaphoneOutlineIcon;
    case 'two-cards':
      return Icons.TwoCardsIcon;
    case 'handle-icon':
      return Icons.HandleIcon;
    case 'app-software-icon':
      return Icons.AppAndSoftwareIcon;
    case 'office-equipment-icon':
      return Icons.OfficeEquipmentIcon;
    case 'electronic-device-icon':
      return Icons.ElectronicDeviceIcon;
    case 'inventory-other-icon':
      return Icons.InventoryOtherIcon;
    case 'uniforms-icon':
      return Icons.UniformsIcon;
    case 'vehicle-icon':
      return Icons.VehicleIcon;
    //TODO::Remove support-icon after releasing the new menubar (and removing the old sidebar)
    case 'support-icon':
      return Icons.SupportIcon;
    case 'expenses-outline':
      return Icons.ExpensesOutlineIcon;
    case 'team-outline':
      return Icons.TeamOutlineIcon;
    case 'recruitment-outline':
      return Icons.RecruitmentOutlineIcon;
    case 'performance-outline':
      return Icons.PerformanceOutlineIcon;
    case 'notification-outline-icon':
      return Icons.NotificationOutlineIcon;
    case 'notification-new-outline-icon':
      return Icons.NotificationNewOutlineIcon;
    case 'dashboard-outline':
      return Icons.DashboardOutlineIcon;
    case 'profile-outline':
      return Icons.ProfileOutlineIcon;
    case 'company-outline':
      return Icons.CompanyOutlineIcon;
    case 'workflow-outline':
      return Icons.WorkflowOutlineIcon;
    case 'absence-outline':
      return Icons.AbsenceOutlineIcon;
    case 'engagement-outline':
      return Icons.EngagementOutlineIcon;
    case 'exclamation-triangle':
      return Icons.ExclamationTriangleIcon;
    case 'help-desk-outline':
      return Icons.HelpDeskOutlineIcon;
    case 'magnifying-glass-outline':
      return Icons.MagnifyingGlassOutlineIcon;
    case 'status-approved-icon':
      return Icons.StatusApprovedIcon;
    case 'status-rejected-icon':
      return Icons.StatusRejectedIcon;
    case 'status-pending-icon':
      return Icons.StatusPendingIcon;
    case 'team-filled':
      return Icons.TeamFilledIcon;
    case 'workflow-filled':
      return Icons.WorkflowFilledIcon;
    case 'profile-filled':
      return Icons.ProfileFilledIcon;
    case 'absence-filled':
      return Icons.AbsenceFilledIcon;
    case 'engagement-filled':
      return Icons.EngagementFilledIcon;
    case 'dashboard-filled':
      return Icons.DashboardFilledIcon;
    case 'company-filled':
      return Icons.CompanyFilledIcon;
    case 'expenses-filled':
      return Icons.ExpensesFilledIcon;
    case 'recruitment-filled':
      return Icons.RecruitmentFilledIcon;
    case 'help-desk-filled':
      return Icons.HelpDeskFilledIcon;
    case 'performance-filled':
      return Icons.PerformanceFilledIcon;
    case 'hamburger-menu':
      return Icons.HamburgerMenuIcon;
    case 'confetti':
      return Icons.ConfettiIcon;
    case 'calendar':
      return Icons.CalendarIcon;
    case 'potted-plant':
      return Icons.PottedPlantIcon;
    case 'user-circle-plus':
      return Icons.UserCirclePlus;
    case 'flag-banner':
      return Icons.FlagBannerIcon;
    case 'users-three':
      return Icons.UsersThreeIcon;
    case 'calendar-plus':
      return Icons.CalendarPlusIcon;
    case 'like':
      return Icons.LikeIcon;
    case 'unlike':
      return Icons.UnlikeIcon;
    case 'chart-bar-filled':
      return Icons.ChartBarFilledIcon;
    case 'chart-bar-outline':
      return Icons.ChartBarOutlineIcon;
    case 'alert-triangle-outline':
      return Icons.AlertTriangleOutlineIcon;
    case 'hour-glass-outline':
      return Icons.HourGlassOutlineIcon;
  }
};

const getSize = (size?: SizeProp) => {
  const defaultSize = 16;
  if (!size) {
    return defaultSize;
  }

  if (size === 'xxl') {
    return 32;
  } else if (size === 'xl') {
    return 24;
  } else if (size === 'lg') {
    return 20;
  } else if (size === 'sm') {
    return 14;
  } else if (size === 'xs') {
    return 12;
  } else if (size === 'xxs') {
    return 8;
  } else if (size === 'xxxs') {
    return 4;
  } else {
    return parseInt(size.substring(0, size.indexOf('x'))) * defaultSize;
  }
};

const FlairIcon = React.forwardRef<SVGSVGElement, FlairIconProps>(
  ({ icon, size, tooltip, tooltipPlacement, popover, ...props }, ref) => {
    const sizeNumber = getSize(size);
    const Icon = getIcon(icon);
    const IconWithProps = (
      <Icon
        className="flair-icon"
        width={sizeNumber}
        height={sizeNumber}
        data-testid={icon}
        {...props}
        ref={ref}
        role={`${props.onClick != null ? 'button' : ''}`}
      />
    );

    if (tooltip || popover) {
      return (
        <OverlayTrigger
          key={`icon-${icon}`}
          placement={tooltipPlacement}
          overlay={
            popover ?? <Tooltip id={`tooltip-${icon}`}>{tooltip}</Tooltip>
          }>
          {IconWithProps}
        </OverlayTrigger>
      );
    }

    return IconWithProps;
  },
);

export default FlairIcon;
