import * as React from 'react';
import { DropdownActionItem } from '../../../../../../atomic/molecules/DropdownActionItem';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import { Action } from './types';
import { DropdownActions } from '../../../../../../atomic/templates/DropdownActions';

type Props = {
  actions: Action[];
};

const Actions: React.FC<Props> = ({ actions }) => {
  return (
    <DropdownActions
      id="comment-actions"
      icon={<FlairIcon icon="three-dots" />}>
      {actions.map((a, i) => (
        <DropdownActionItem key={i} onClick={a.onClick}>
          {a.label}
        </DropdownActionItem>
      ))}
    </DropdownActions>
  );
};

export default Actions;
