import React from 'react';
import { HeaderAmount } from './HeaderAmount';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { CategoryType } from '../../../types';
import { PeriodType } from '../types';

const i18Path = 'absences.cards.absenceCategories';

type Props = {
  amount: number | null;
  periodType: PeriodType;
  category: CategoryType;
};
export const AmountLabel: React.FC<Props> = ({
  amount,
  periodType,
  category,
}) => {
  const t = useNamespacedTranslation(i18Path);

  return (
    <StyledRow className="position-absolute align-items-center m-auto bottom-0 top-0">
      <Col className="pe-2">
        <h1 className="display-2 mb-0">
          {!category?.flair__Unlimited__c ? amount : t('unlimited')}
        </h1>
      </Col>
      {!category?.flair__Unlimited__c && (
        <Col className="ps-2">
          <Row>
            <h2 className="mb-0">
              {category && (
                <HeaderAmount category={category} labelValue={amount} />
              )}
            </h2>
          </Row>
          <Row>
            <span className="time-text me-3">{t(`label.${periodType}`)}</span>
          </Row>
        </Col>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  right: 2rem;
`;
