import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { SkeletonButton } from '../../../components/Skeleton/Button';

const Loading: React.FC = () => (
  <Row className="text-center">
    <Col>
      <h3>
        <Skeleton />
      </h3>
      <p>
        <Skeleton width={200} />
      </p>
      <SkeletonButton />
    </Col>
  </Row>
);

export default Loading;
