import React, { useCallback } from 'react';
import CheckboxGroup, {
  CheckboxGroupParams,
} from '../../../../../../components/form/CheckboxGroup';
import { EvaluationQuestionParams } from './types';
import {
  EvaluationTemplateQuestion,
  EvaluationTemplateQuestionType,
} from '../../../../__generated__/graphql';
import { isAnswerDelete, getDiffValues } from './utils';

const mapToCheckboxGroup = (
  question: EvaluationTemplateQuestion,
): CheckboxGroupParams => ({
  groupLabel: question.Name,
  groupName: question.Id,
  hint: question.flair__Hint__c,
  items: question.options.map((option) => ({
    name: option.Id,
    label: option.Name,
    hint: option.flair__Hint__c,
  })),
});

type MultipleChoiceParams = EvaluationQuestionParams & {
  defaultValue: string[];
  onDeleteAnswer: (optionsToDelete: string[]) => void;
};

const MultipleChoice: React.FC<MultipleChoiceParams> = ({
  question,
  defaultValue,
  disabled,
  loading,
  onUpdateAnswer,
  onDeleteAnswer,
}) => {
  const handleChange = useCallback(
    (selectedItems: string[]) => {
      if (isAnswerDelete(selectedItems, defaultValue)) {
        onDeleteAnswer(getDiffValues(defaultValue, selectedItems));
      } else {
        onUpdateAnswer({
          questionType: EvaluationTemplateQuestionType.MultipleChoice,
          value: selectedItems[selectedItems.length - 1],
        });
      }
    },
    [defaultValue, onDeleteAnswer, onUpdateAnswer],
  );

  return (
    <CheckboxGroup
      itemClassName="ms-3 my-1"
      disabled={disabled || loading}
      required={question.flair__Required__c}
      onChange={(selectedItems) =>
        handleChange &&
        handleChange(selectedItems.map((selectedItem) => selectedItem + ''))
      }
      defaultValue={defaultValue}
      isComplete={defaultValue.length > 0}
      loading={loading}
      {...mapToCheckboxGroup(question)}
    />
  );
};

export default MultipleChoice;
