import _ from 'lodash';
import {
  AscDescEnum,
  EmployeesQuery,
  WorkflowEmployeeFieldsFragment,
  WorkflowItemStatus,
  WorkflowListSortFieldEnum,
  WorkflowListSortInput,
  WorkflowsFilterInput,
  WorkflowsQuery,
  WorkflowTableFieldsFragment,
} from '../../../__generated__/graphql';
import {
  isWorkflowStatusGraphql,
  isWorkflowType,
  Workflow,
  WorkflowEmployee,
  WorkflowFilterByEmployeeOption,
  WorkflowStepStatusInfo,
} from '../types';
import { parseDate, toISODateOnly } from '../../../../../utils/dateUtils';
import { startOfYear } from 'date-fns';
import { WorkflowFilterBase } from '../Filters/types';
import { SortingRule } from 'react-table';
import { WorkflowResponse } from 'server/src/apps/internal/__generated__/graphql';

export const mapWorkflows = (src: WorkflowsQuery): Workflow[] => {
  return _.uniqBy(src.workflows.items, 'Id').map(
    (x: WorkflowTableFieldsFragment) => mapWorkflow(x),
  );
};

const mapWorkflow = (src: WorkflowTableFieldsFragment): Workflow => {
  return {
    id: src.Id,
    workflowType: src.flair__Type__c,
    workflowTemplateId: src.flair__Workflow_Template__c,
    name: src.Name,
    closestItemDueDate: src.flair__Closest_Item_Due_Date__c,
    employee: mapEmployee(src.employee),
    stepStatusInfos: src.steps.map((step) =>
      mapStepStatusInfo(step, src.items),
    ),
  };
};

export const mapEmployee = (
  src: WorkflowEmployeeFieldsFragment,
): WorkflowEmployee => ({
  type: 'employee',
  id: src.Id,
  name: src.Name,
  position: src.flair__Position__c,
  department: src.department?.Name ?? null,
  avatarUrl: src.avatar?.url ?? undefined,
  startDate: src.flair__Start_Date__c ?? null,
  endDate: src.flair__End_Date__c ?? null,
});

export const mapFilterByEmployee = (
  src: EmployeesQuery | undefined,
): WorkflowFilterByEmployeeOption[] => {
  return _.uniqBy(src?.manager.employees, 'Id').map((item) => {
    return {
      type: 'employee',
      value: item.Id,
      label: item.Name,
    };
  });
};

const mapStepStatusInfo = (
  stepToMap: WorkflowTableFieldsFragment['steps'][0],
  allWorkflowItems: WorkflowTableFieldsFragment['items'],
): WorkflowStepStatusInfo => {
  const stepItems = allWorkflowItems.filter(
    (item) => item.flair__Workflow_Step__c === stepToMap.Id,
  );

  return {
    id: stepToMap.Id,
    name: stepToMap.flair__Step_Name__c,
    itemsCount: stepItems.length,
    completedItemsCount: stepItems.filter(
      (stepItem) => stepItem.flair__Status__c === WorkflowItemStatus.Completed,
    ).length,
  };
};

export const mapWorkflowsFilterInput = (
  filter: WorkflowFilterBase,
): WorkflowsFilterInput => ({
  employeeId: filter.employeeId ?? null,
  notCompleted: false, // @deprecated on 24-5-2024
  workflowType: isWorkflowType(filter.workflowType)
    ? filter.workflowType
    : null,
  workflowStatus: isWorkflowStatusGraphql(filter.workflowStatus)
    ? filter.workflowStatus
    : null,
  workflowTemplateId: filter.workflowTemplateId,
  year: filter.year ? toISODateOnly(startOfYear(parseDate(filter.year))) : null,
});

const defaultSorting: WorkflowListSortInput = {
  field: WorkflowListSortFieldEnum.Workflow,
  order: AscDescEnum.Desc,
};

const mapSortField = (sortId: string): WorkflowListSortFieldEnum => {
  switch (sortId) {
    case 'name':
      return WorkflowListSortFieldEnum.Workflow;
    case 'employee':
      return WorkflowListSortFieldEnum.EmployeeName;
    case 'workflowType':
      return WorkflowListSortFieldEnum.WorkflowType;
    case 'dueDate':
      return WorkflowListSortFieldEnum.DueDate;
    case 'itemStatuses':
      return WorkflowListSortFieldEnum.WorkflowStatus;
    default:
      return WorkflowListSortFieldEnum.Workflow;
  }
};

export const mapSortBy = (
  sortBy: SortingRule<WorkflowResponse>[],
): WorkflowListSortInput => {
  if (sortBy.length < 1) {
    return defaultSorting;
  }
  return {
    field: mapSortField(sortBy[0].id),
    order: sortBy[0].desc ? AscDescEnum.Desc : AscDescEnum.Asc,
  };
};
