import React from 'react';
import { routes as mainRoutes } from '../../../routes';
import { WorkflowContext } from '../../../__generated__/graphql';
import { WorkflowItems } from './WorkflowItems';

export const WorkflowItemsForOthers: React.FC = () => {
  return (
    <WorkflowItems
      workflowContext={WorkflowContext.Other}
      route={mainRoutes.workflowsOthersWorkflowItemsAll.route}
    />
  );
};
