import * as React from 'react';

const CircleNotchOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00319 2.53955C6.64589 2.53877 5.33624 3.0399 4.32616 3.94653C3.31608 4.85317 2.67692 6.10129 2.53162 7.45078C2.38632 8.80028 2.74516 10.1558 3.53909 11.2567C4.33303 12.3576 5.50599 13.126 6.83235 13.4142C8.1587 13.7023 9.54477 13.4899 10.7239 12.8177C11.9031 12.1455 12.792 11.061 13.2198 9.77285C13.6475 8.48471 13.5838 7.0839 13.041 5.83988C12.4982 4.59587 11.5145 3.5965 10.2792 3.03404C10.0279 2.9196 9.91695 2.6231 10.0314 2.37179C10.1458 2.12047 10.4423 2.00951 10.6936 2.12394C12.1535 2.78868 13.316 3.96974 13.9575 5.43994C14.5991 6.91015 14.6743 8.56565 14.1688 10.088C13.6633 11.6103 12.6127 12.892 11.2192 13.6864C9.82563 14.4808 8.18756 14.7319 6.62004 14.3914C5.05253 14.0508 3.6663 13.1427 2.72802 11.8417C1.78973 10.5406 1.36565 8.9386 1.53737 7.34374C1.70908 5.74887 2.46445 4.27383 3.65819 3.20235C4.85192 2.13087 6.39969 1.53863 8.00376 1.53955C8.27991 1.53971 8.50363 1.7637 8.50348 2.03984C8.50332 2.31598 8.27933 2.53971 8.00319 2.53955Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default CircleNotchOutlineIcon;
