import React from 'react';

type Props = {
  salesforceUserId: string | null;
  flairHubPermissionSet: boolean;
};

export const OrganizationInfo: React.FC<Props> = ({
  salesforceUserId,
  flairHubPermissionSet,
}) => {
  return (
    <div>
      <h3 className="display-4 text-center mb-3">Organization info</h3>
      <div className="d-flex justify-content-between">
        <label htmlFor="salesforceUserId" className="col-sm-2 text-nowrap">
          Salesforce User Id
        </label>
        <div id="salesforceUserId" className="text-success">
          {salesforceUserId}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <label htmlFor="flairHubPermissionSet" className="col-sm-2 text-nowrap">
          Has "Flair Hub" permission set
        </label>
        <div
          id="flairHubPermissionSet"
          className={flairHubPermissionSet ? 'text-success' : 'text-danger'}>
          {flairHubPermissionSet ? 'true' : 'false'}
        </div>
      </div>
    </div>
  );
};
