import React from 'react';
import { Options, useToasts as useLibToasts } from 'react-toast-notifications';

type Toasts = {
  addError: (e: React.ReactNode, options?: Omit<Options, 'appearance'>) => void;
  addSuccess: (
    e: React.ReactNode,
    options?: Omit<Options, 'appearance'>,
  ) => void;
  addWarning: (
    e: React.ReactNode,
    options?: Omit<Options, 'appearance'>,
  ) => void;
};

export const useToasts = (): Toasts => {
  const { addToast } = useLibToasts();

  const addError = React.useCallback(
    (e: React.ReactNode, options?: Omit<Options, 'appearance'>) =>
      addToast(e, {
        appearance: 'error',
        autoDismiss: true,
        ...(options ? options : {}),
      }),
    [addToast],
  );

  const addSuccess = React.useCallback(
    (e: React.ReactNode, options?: Omit<Options, 'appearance'>) =>
      addToast(e, {
        appearance: 'success',
        autoDismiss: true,
        ...(options ? options : {}),
      }),
    [addToast],
  );

  const addWarning = React.useCallback(
    (e: React.ReactNode, options?: Omit<Options, 'appearance'>) =>
      addToast(e, {
        appearance: 'warning',
        autoDismiss: true,
        ...(options ? options : {}),
      }),
    [addToast],
  );

  return { addError, addSuccess, addWarning };
};
