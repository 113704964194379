import React, { useContext } from 'react';
import { useNamespacedTranslation } from '../../../../../../../../../hooks/useNamespacedTranslation';
import { i18Path } from '../../constants';
import { InventoryModalContext } from '../../InventoryModalContext';
import { Input } from '../../../../../../../../../components/form/Input';

export const PurchasePriceFormField: React.FC = () => {
  const t = useNamespacedTranslation(i18Path);
  const { onChange: onFieldChange } = useContext(InventoryModalContext);
  return (
    <Input
      type="number"
      onChange={(value) => onFieldChange({ netPurchasedPrice: value })}
      label={t('form.fields.netPurchasedPrice')}
      name="netPurchasedPrice"
      placeholder="119"
      size="md"
    />
  );
};
