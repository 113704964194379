import React, { useMemo } from 'react';
import { OptionBase } from '../../../../../../components/Select/types';
import ServerError from '../../../../../../components/ServerError';
import {
  EmployeeOption,
  mapEmployeeOption,
} from '../../../../components/Selects/EmployeeOptionLabel';
import { useAbsenceRequestFilterQuery } from '../../../../__generated__/graphql';
import {
  AbsenceRequestFilters,
  Props as AbsenceRequestFiltersType,
} from './AbsenceRequestFilters';
import FiltersLoading from './FiltersLoading';

type Props = Omit<
  AbsenceRequestFiltersType,
  'employeeOptions' | 'categoryOptions'
>;

export const AbsenceRequestFiltersConnected: React.FC<Props> = (props) => {
  const { data, loading, error } = useAbsenceRequestFilterQuery();
  const employeeOptions: EmployeeOption[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.activeEmployees.map(mapEmployeeOption);
  }, [data]);

  const categoryOptions: OptionBase[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.absencesCategories.map((x) => ({ value: x.Id, label: x.Name }));
  }, [data]);

  if (!data || loading) {
    return <FiltersLoading />;
  }

  if (error) return <ServerError />;

  return (
    <AbsenceRequestFilters
      employeeOptions={employeeOptions}
      categoryOptions={categoryOptions}
      {...props}
    />
  );
};
