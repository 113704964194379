import * as React from 'react';

const HappyOutlineIcon = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}>
      <path
        d="M5.74862 8.00114C6.16369 8.00114 6.50017 7.66466 6.50017 7.24959C6.50017 6.83453 6.16369 6.49805 5.74862 6.49805C5.33355 6.49805 4.99707 6.83453 4.99707 7.24959C4.99707 7.66466 5.33355 8.00114 5.74862 8.00114Z"
        fill="currentColor"
      />
      <path
        d="M7.99863 11.997C6.57959 11.997 5.38613 11.0744 5.0084 9.81532C4.99784 9.77786 4.99617 9.73844 5.00354 9.70021C5.0109 9.66198 5.0271 9.62601 5.05083 9.59515C5.07456 9.56428 5.10517 9.53939 5.14022 9.52246C5.17527 9.50552 5.21379 9.497 5.25272 9.49759H10.7417C10.7807 9.497 10.8192 9.50552 10.8542 9.52246C10.8893 9.53939 10.9199 9.56428 10.9436 9.59515C10.9674 9.62601 10.9836 9.66198 10.9909 9.70021C10.9983 9.73844 10.9966 9.77786 10.9861 9.81532C10.6115 11.0744 9.41767 11.997 7.99863 11.997Z"
        fill="currentColor"
      />
      <path
        d="M10.2496 8.00114C10.6647 8.00114 11.0011 7.66466 11.0011 7.24959C11.0011 6.83453 10.6647 6.49805 10.2496 6.49805C9.83453 6.49805 9.49805 6.83453 9.49805 7.24959C9.49805 7.66466 9.83453 8.00114 10.2496 8.00114Z"
        fill="currentColor"
      />
      <path
        d="M7.99944 14.496C11.5884 14.496 14.4979 11.5865 14.4979 7.99749C14.4979 4.40849 11.5884 1.49902 7.99944 1.49902C4.41044 1.49902 1.50098 4.40849 1.50098 7.99749C1.50098 11.5865 4.41044 14.496 7.99944 14.496Z"
        stroke="currentColor"
        strokeWidth="0.999764"
        strokeMiterlimit="10"
      />
    </svg>
  );
});

export default HappyOutlineIcon;
