import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from '@emotion/styled';

type Props = {
  height?: number;
};

const Container = styled.div({
  marginBottom: '1.5rem',
});

const SkeletonCard: React.FC<Props> = ({ height }) => (
  <Container>
    <Skeleton height={height} />
  </Container>
);

export default SkeletonCard;
