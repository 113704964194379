import React from 'react';
import {
  AiProcessingState,
  JobCandidate,
} from '../../../../__generated__/graphql';
import { useNamespacedTranslation } from '../../../../../../hooks/useNamespacedTranslation';
import { safeJsonParse } from '../../../../components/Notifications/RenderInfoMappings/NotificationJSONBodyParser';
import {
  JobKeyRequirementMatches,
  JobKeyRequirements,
  top10keyRequirementsFields,
} from './types';
import { Card, Collapse, Popover, Spinner } from 'react-bootstrap';
import FlairAILogo from './FlairAILogo';
import FlairAIMatchScoreChart from './FlairAIMatchScoreChart';
import FlairIcon from '../../../../../../atomic/atoms/FlairIcon';
import Hint from '../../../../../../components/hint';
import MatchingBadge from './MatchingBadge';
import Button from '../../../../../../components/button';
import { useFlairAiFeature } from '../../../../../../context/FeaturesContext';

type Props = {
  candidate: JobCandidate;
};

// TODO enter the correct url when the content team make it
const learnMoreUrl = 'https://flair.hr/';

export const CandidateFlairAiCard: React.FC<Props> = ({ candidate }) => {
  const hasFlairAIRecruitingEnabled = useFlairAiFeature();

  const [collapsed, setCollapsed] = React.useState(true);

  const t = useNamespacedTranslation('recruitment.candidate.detailsPage');

  const tCandidateTable = useNamespacedTranslation(
    'recruitment.jobCandidatesPage.table',
  );

  const isErrorAiScore =
    candidate.flair__FlairAI_KR_Matching_Status__c === AiProcessingState.Error;
  const isDoneAiScore =
    candidate.flair__FlairAI_KR_Matching_Status__c === AiProcessingState.Done;
  const isProcessingAiScore =
    candidate.flair__FlairAI_KR_Matching_Status__c ===
    AiProcessingState.Processing;

  const showFlairAiCard =
    (isErrorAiScore || isProcessingAiScore || isDoneAiScore) &&
    hasFlairAIRecruitingEnabled;

  if (!showFlairAiCard) {
    return null;
  }

  const candidateScore =
    candidate.flair__FlairAI_Total_KR_Matching_Score__c ?? undefined;

  const krMatches = safeJsonParse<JobKeyRequirementMatches>(
    candidate.flair__FlairAI_KR_Matching_Info_JSON__c || '',
  );
  const jobKRs = safeJsonParse<JobKeyRequirements>(
    candidate.job?.flair__FlairAI_Key_Requirements_JSON__c || '',
  );

  const showCollapseAction =
    !isErrorAiScore && !isProcessingAiScore && !!krMatches && !!jobKRs;

  return (
    <Card>
      <Card.Header className="mx-2 flair-ai-card-header">
        <div className="d-flex justify-content-between my-2">
          <div className="d-flex flex-column align-self-center">
            <FlairAILogo beta />
            <div className="flair-ai-card-about-text mt-2">
              <span>{t('flairAI.about')}&nbsp;</span>
              <a href={learnMoreUrl}>{t('flairAI.learnMore')}</a>
            </div>
          </div>
          <div className="flair-ai-score-chart">
            {isDoneAiScore && (candidateScore || candidateScore === 0) && (
              <FlairAIMatchScoreChart score={candidateScore} />
            )}
            {isErrorAiScore && (
              <div>
                <span className="color-red-1">
                  <Hint
                    id={`${candidate.Id}_error`}
                    text={tCandidateTable('candidateAiScoreError')}
                    flairIconName={'exclamation-triangle'}
                    flairIconSize={'xl'}></Hint>
                </span>
              </div>
            )}
            {isProcessingAiScore && (
              <div>
                <span className="color-paper-1">
                  <Hint
                    id={`${candidate.Id}_processing`}
                    text={tCandidateTable('candidateAiScoreProcessing')}>
                    <Spinner animation={'border'} size={'sm'} />
                  </Hint>
                </span>
              </div>
            )}
          </div>
        </div>
      </Card.Header>
      {showCollapseAction && (
        <Collapse in={!collapsed}>
          <Card.Body className="p-0">
            <CandidateFlairAiCardMatchingTable
              jobKeyRequirements={jobKRs}
              keyRequirementsMatching={
                krMatches
              }></CandidateFlairAiCardMatchingTable>
          </Card.Body>
        </Collapse>
      )}
      {showCollapseAction && (
        <Card.Footer className="mx-2">
          <Button
            onClick={() => setCollapsed(!collapsed)}
            variant="link"
            size="sm"
            className="p-0 w-100"
            label={
              collapsed ? t('flairAI.showMore') : t('flairAI.showLess')
            }></Button>
        </Card.Footer>
      )}
    </Card>
  );
};

type TableProps = {
  jobKeyRequirements: JobKeyRequirements | undefined;
  keyRequirementsMatching: JobKeyRequirementMatches | undefined;
};

const CandidateFlairAiCardMatchingTable: React.FC<TableProps> = ({
  jobKeyRequirements,
  keyRequirementsMatching,
}) => {
  const t = useNamespacedTranslation(
    'recruitment.candidate.detailsPage.flairAI',
  );

  if (!jobKeyRequirements || !keyRequirementsMatching) {
    return null;
  }

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>
            <div className="d-flex">
              <span>{t('tableHeaders.keyRequirements')}</span>
              <div className="ms-2">
                <FlairIcon
                  icon="alert-circle-outline"
                  tooltip={t('tableHeaders.keyRequirementsTooltip')}
                />
              </div>
            </div>
          </th>
          <th>{t('tableHeaders.match')}</th>
          <th>
            <div className="d-flex">
              <span>{t('tableHeaders.details')}</span>
              <div className="ms-2">
                <FlairIcon
                  icon="alert-circle-outline"
                  tooltip={t('tableHeaders.detailsTooltip')}
                />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {top10keyRequirementsFields
          .map((key) => ({
            key,
            jobKR: jobKeyRequirements[key],
            krMatch: keyRequirementsMatching[key],
          }))
          .map(
            ({ key, jobKR, krMatch }) =>
              jobKR &&
              krMatch && (
                <tr key={key}>
                  <td title={key}>{jobKR.key_requirement_description}</td>
                  <td title={String(jobKR.key_requirement_importance_score)}>
                    <MatchingBadge
                      isMatched={krMatch.does_candidate_satisfies}
                    />
                  </td>
                  <td title={krMatch.reference_from_resume}>
                    {krMatch.explanation}
                  </td>
                  <td>
                    {krMatch.does_candidate_satisfies && (
                      <FlairIcon
                        icon="alert-circle-outline"
                        popover={
                          <Popover>
                            <Popover.Header>
                              <span className="ms-2">
                                {t('extractFromCandidate')}
                              </span>
                            </Popover.Header>
                            <Popover.Body>
                              {krMatch.reference_from_resume}
                            </Popover.Body>
                          </Popover>
                        }
                      />
                    )}
                  </td>
                </tr>
              ),
          )}
      </tbody>
    </table>
  );
};
