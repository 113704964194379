import { useCallback } from 'react';
import { useCurrentLocale } from '../context/I18n';

type Props = {
  value: number;
  currency: string;
};

export const useCurrencyFormatter = () => {
  const locale = useCurrentLocale();

  return useCallback(
    ({ value, currency }: Props) =>
      Intl.NumberFormat(locale, { style: 'currency', currency }).format(value),
    [locale],
  );
};
