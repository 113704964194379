import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Link } from '../../../../../Router';
import { routes } from '../../../routes';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { TeamCalendarFiltersType } from './Table/TeamCalendarFilters/types';

type Props = {
  fromDate: Moment;
  dateFromName: string;
  filterData: TeamCalendarFiltersType;
};

const Nav: React.FC<Props> = ({ fromDate, dateFromName, filterData }) => {
  const { t } = useTranslation();

  const previous = fromDate.clone().add(-1, 'month');
  const next = fromDate.clone().add(1, 'month');

  return (
    <ButtonGroup size="sm" className="bg-gray rounded p-1">
      <Link
        to={routes.teamCalendar.route.withQueryParams({
          [dateFromName]: previous.format('YYYY-MM-DD'),
          ...filterData,
        })}
        className="btn btn-gray">
        ←
      </Link>
      <Link
        to={routes.teamCalendar.route.withQueryParams({
          [dateFromName]: fromDate.format('YYYY-MM-DD'),
          ...filterData,
        })}
        className="btn btn-sm btn-white rounded text-nowrap">
        {fromDate.format(t('absences.team.nav.format'))}
      </Link>
      <Link
        to={routes.teamCalendar.route.withQueryParams({
          [dateFromName]: next.format('YYYY-MM-DD'),
          ...filterData,
        })}
        className="btn btn-gray">
        →
      </Link>
    </ButtonGroup>
  );
};

export default Nav;
